import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import OurPlansList from "./OurPlansList";
import CreateNewPlan from "./CreateNewPlan";
import EditPlan from "./EditPlan";
import SocialMediaPlan from "./SocialMediaPlan";
// import SocialMediaPlanList from "./SocialMediaPlanList";
import EditSocialPlan from "./EditSocialPlan";
import PrivateRoute from "./PrivateRoute";
import PrivateRoute1 from "./PrivateRoute1";
import CopySocial from "./CopySocial";

export default () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={OurPlansList} />
            <PrivateRoute component={CreateNewPlan} path={`${path}/create-new/:planId?`} />
            <PrivateRoute1 path={`${path}/social-media-plans`} component={SocialMediaPlan} />
            <PrivateRoute path={`${path}/edit-plan/:planId`} component={EditPlan} />
            <PrivateRoute1 path={`${path}/edit-social/:planId`} component={EditSocialPlan} />
            <PrivateRoute1 path={`${path}/copy-social/:planId`} component={CopySocial} />
        </Switch>
    )
}