import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from "axios";
import { API } from "../../../../config";
import { CALL_LOADING } from "../../../../config/constant";
import { apiErrors } from "../../../../common/HandleAPIErrors";

export const actionTypes = {
  SetPayoutSummaryHeaderDetail: "[SetPayoutSummaryHeaderDetail] Action",
  SetPayoutSummaryGraphDetail: "[SetPayoutSummaryGraphDetail] Action",
  SetTransactionListing: "[SetTransactionListing] Action",
  SetStatementHeaderDetail: "[SetStatementHeaderDetail] Action",
  setAgentReportDetails: "[setAgentReportDetails] Action",
  setCustomerReportDetails: "[setCustomerReportDetails] Action",
  setCustomerTotalDetails: "[setCustomerTotalDetails] Action",
  setCustomerGraphicalData: "[setCustomerGraphicalData] Action",
  setCustomerTransactionDetails: "[setCustomerTransactionDetails] Action",
  setPijenTotalvalues: "[setPijenTotalvalues] Action",
  setPijenDetails: "[setPijenDetails] Action",
  setPijenTransactionDetails: "[setPijenTransactionDetails] Action",
  setCusomerWithrespecttoAgent: "[setCusomerWithrespecttoAgent] Action",
  setAgentGraphdata: "[setAgentGraphdata] Action",
  setPublisherGraphData: "[setPublisherGraphData] Action",
  setPublisherTotalvalues: "[setPublisherTotalvalues] Action",
  setPublisherPayoutTotalDetails: "[setPublisherPayoutTotalDetails] Action",
  setDetailsofAgentWRTpublisher: "[setDetailsofAgentWRTpublisher] Action",
};

const headers = {
  "Content-Type": "application/json",
};

const initialAuthState = {
  headerData: undefined,
  payoutSummaryGraphDetail: undefined,
  transactionListing: undefined,
  statementDeta: undefined,
  agentDeatils: undefined,
  customerDetails: undefined,
  customerTotalDetails: undefined,
  customergraphicaldata: undefined,
  customerTransactionDetails: undefined,
  pijenTotalvalues: undefined,
  pijenDetails: undefined,
  pijenTransactionDetails: undefined,
  cusomerWithrespecttoAgent: undefined,
  agentGraphdata: undefined,
  publisherGraphData: undefined,
  publisherTotalvalues: undefined,
  publisherPayoutTotalDetails: undefined,
  detailsofAgentWRTpublisher: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      /** get all records of company list */
      case actionTypes.SetPayoutSummaryHeaderDetail: {
        const { headerData } = action.payload;
        return { ...state, headerData: headerData && headerData };
      }

      /** get all records of company list */
      case actionTypes.SetPayoutSummaryGraphDetail: {
        const { payoutSummaryGraphDetail } = action.payload;
        return { ...state, payoutSummaryGraphDetail: payoutSummaryGraphDetail };
      }

      /** get all records of company list */
      case actionTypes.SetTransactionListing: {
        const { transactionListing } = action.payload;
        return { ...state, transactionListing: transactionListing };
      }

      /** get all records of company list */
      case actionTypes.SetStatementHeaderDetail: {
        const { statementDeta } = action.payload;
        return { ...state, statementDeta: statementDeta };
      }

      case actionTypes.setAgentReportDetails: {
        const { agentDeatils } = action.payload;
        return { ...state, agentDeatils: agentDeatils };
      }

      case actionTypes.setCustomerReportDetails: {
        const { customerDetails } = action.payload;
        return { ...state, customerDetails: customerDetails };
      }

      case actionTypes.setCustomerTotalDetails: {
        const { customerTotalDetails } = action.payload;
        return { ...state, customerTotalDetails: customerTotalDetails };
      }
      case actionTypes.setCustomerGraphicalData: {
        const { customergraphicaldata } = action.payload;
        return { ...state, customergraphicaldata: customergraphicaldata };
      }

      case actionTypes.setCustomerTransactionDetails: {
        const { customerTransactionDetails } = action.payload;
        return {
          ...state,
          customerTransactionDetails: customerTransactionDetails,
        };
      }
      case actionTypes.setPijenTotalvalues: {
        const { pijenTotalvalues } = action.payload;
        return { ...state, pijenTotalvalues: pijenTotalvalues };
      }

      case actionTypes.setPijenDetails: {
        const { pijenDetails } = action.payload;
        return { ...state, pijenDetails: pijenDetails };
      }

      case actionTypes.setPijenTransactionDetails: {
        const { pijenTransactionDetails } = action.payload;
        return { ...state, pijenTransactionDetails: pijenTransactionDetails };
      }
      case actionTypes.setCusomerWithrespecttoAgent: {
        const { cusomerWithrespecttoAgent } = action.payload;
        return {
          ...state,
          cusomerWithrespecttoAgent: cusomerWithrespecttoAgent,
        };
      }
      case actionTypes.setAgentGraphdata: {
        const { agentGraphdata } = action.payload;
        return { ...state, agentGraphdata: agentGraphdata };
      }
      case actionTypes.setPublisherGraphData: {
        const { publisherGraphData } = action.payload;
        return { ...state, publisherGraphData: publisherGraphData };
      }

      case actionTypes.setPublisherTotalvalues: {
        const { publisherTotalvalues } = action.payload;
        return { ...state, publisherTotalvalues: publisherTotalvalues };
      }
      case actionTypes.setPublisherPayoutTotalDetails: {
        const { publisherPayoutTotalDetails } = action.payload;
        return {
          ...state,
          publisherPayoutTotalDetails: publisherPayoutTotalDetails,
        };
      }

      case actionTypes.setDetailsofAgentWRTpublisher: {
        const { detailsofAgentWRTpublisher } = action.payload;
        return {
          ...state,
          detailsofAgentWRTpublisher: detailsofAgentWRTpublisher,
        };
      }

      /** set default state */
      default:
        return state;
    }
  }
);

/** Actions creator */
export const actions = {
  /**
   * @method getPayoutSummaryGraphDetail
   * @description get all payout summary graph detail
   */
  getPayoutSummaryGraphDetail: (requestData, callback) => {
    const {
      section = "",
      data = "",
      type = "",
      sort = "",
      column = "",
      month = "",
      year = "",
      from_date = "",
      to_date = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getPayoutSummary}?data=${data}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}&statement=${statement}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          if (section === 1) {
            return dispatch({
              type: actionTypes.SetPayoutSummaryHeaderDetail,
              payload: { headerData: response.data },
            });
          } else {
            return dispatch({
              type: actionTypes.SetPayoutSummaryGraphDetail,
              payload: { payoutSummaryGraphDetail: response.data },
            });
          }
        })
        .catch((error) => {
          console.log("error: payout", error);
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  /**
   * @method getPayoutSummaryListing
   * @description get all payout summary graph detail
   */
  getPayoutSummaryListing: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      column = "",
      month = "",
      year = "",
      from_date = "",
      to_date = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getPayoutSummary}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetTransactionListing,
            payload: { transactionListing: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getCustomerListing: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getCustomerListing}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setCustomerReportDetails,
            payload: { customerDetails: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getPublisherTotalPayoutListing: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getpublisherPayoutTotalDetails}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setPublisherPayoutTotalDetails,
            payload: { publisherPayoutTotalDetails: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getCustomerWithrespectoAgent: (requestData, callback) => {
    const {
      id = "",
      date = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      formDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
      newspaper_type=""
    } = requestData;
    let url = `${API.getCustomerWithrespectoAgent}?id=${id}&date=${date}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}&newspaper_type=${newspaper_type}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setCusomerWithrespecttoAgent,
            payload: { cusomerWithrespecttoAgent: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getDetailsWithrespectoPublisher: (requestData, callback) => {
    const {
      id = "",
      date = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      formDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
      newspaper_type = ""
    } = requestData;
    let url = `${API.getDetailsWithrespectoPublisher}?id=${id}&date=${date}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}&newspaper_type=${newspaper_type}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setDetailsofAgentWRTpublisher,
            payload: { detailsofAgentWRTpublisher: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getCustomergraphicalData: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getCustomergraphicalData}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setCustomerGraphicalData,
            payload: { customergraphicaldata: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getAgentGraphDetails: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getAgentGraphDetails}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setAgentGraphdata,
            payload: { agentGraphdata: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getPublisherGraphDetails: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getPublisherGraphDetails}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setPublisherGraphData,
            payload: { publisherGraphData: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getcustomertotalValues: (requestData, callback) => {
    let url = `${API.getcustomertotalValues}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setCustomerTotalDetails,
            payload: { customerTotalDetails: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getCustomerTransactionDetails: (requestData, callback) => {
    const {
      id = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      formDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
      newspaper_type=""
    } = requestData;
    let url = `${API.getCustomerTransactionDetails}?id=${id}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}&newspaper_type=${newspaper_type}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setCustomerTransactionDetails,
            payload: { customerTransactionDetails: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getPijentotalValues: (requestData, callback) => {
    // const { data='', type='', sort='', agentTotalFilterOption='', month='', year='', formDate='', toDate='', page='', row='', search='', statement='' } = requestData;
    let url = `${API.getPijentotalValues}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setPijenTotalvalues,
            payload: { pijenTotalvalues: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getPublishertotalValues: (requestData, callback) => {
    // const { data='', type='', sort='', agentTotalFilterOption='', month='', year='', formDate='', toDate='', page='', row='', search='', statement='' } = requestData;
    let url = `${API.getPublishertotalValues}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setPublisherTotalvalues,
            payload: { publisherTotalvalues: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getPijenDetails: (requestData, callback) => {
    const {
      id = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getPijenDetails}?id=${id}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setPijenDetails,
            payload: { pijenDetails: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },
  getPijenTransactionDetails: (requestData, callback) => {
    const {
      id = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      formDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getPijenTransactionDetails}?id=${id}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setPijenTransactionDetails,
            payload: { pijenTransactionDetails: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  /**
   * @method getPayoutSummaryDetail
   * @description get all payout summary  detail by id
   */
  getPayoutSummaryDetail: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      column = "",
      month = "",
      year = "",
      from_date = "",
      to_date = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getDetailsofPublisher}?data=${data}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetStatementHeaderDetail,
            payload: { statementDeta: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },

  getAgentSummaryDetail: (requestData, callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      toDate = "",
      year = "",
      fromDate = "",
      to_date = "",
      page = "",
      row = "",
      search = "",
      statement = "",
    } = requestData;
    let url = `${API.getDetailsofAgent}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&toDate=${toDate}&year=${year}&fromDate=${fromDate}&to=${to_date}&row=${row}&page=${page}&search=${search}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.setAgentReportDetails,
            payload: { agentDeatils: response.data },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          return callback(error);
        });
    };
  },
};

export function* saga() {
  yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {});
}
