import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { convertISOToUtcDate } from "../../../../../common";
import { CSVLink } from "react-csv";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import {
  renderText,
  renderSelectField,
  renderNumberField,
} from "../../../../../common/layout/FormInput";
import { PayoutHistoryHeaders } from "../../../../../common/CsvFormate";
import FooterLoader from "../../../../../common/FooterLoader";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import { ViewIconLink } from "../../../../../common/Buttons";
import moment from "moment";
import "./Listing.scss";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import pdf_icon from "../../../../../../assets/images/pdf-icon.svg";

class PendingTransactionListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      payoutList: [],
      companyList: [],
      filterid: 0,
      filterDropdownlist: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
      localLoading: true,
      financeDetail: undefined,
      openModal: false,
      sortFlag: {
        client_name: { sort: false },
        createDate: { sort: false },
        ad_name: { sort: false },
        price: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutRecords(25, 1, "", "", 0);
  }

  /**
   * @method handleListCount
   * @description  handle total record display
   */
  handleListCount = (res) => {
    let temp = [];
    
      this.setState({ payoutList: res });
    
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (row, page, sort, column, filterid) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 4,
      filterid: filterid ? filterid : 0,
    };
    this.props.getPayoutHistoryList(requestData, (res) => {
      console.warn("Checking", res.status);
      this.setState({ localLoading: false });
      if (res.status === STATUS_CODES.CREATED) {
        console.warn("CheckingInner>>>>>");
        Array.isArray(res.data.result) && this.handleListCount(res.data.result);
      }
    });

    // var drpdownlist = [];

    // let currentYear = new Date().getFullYear();

    // drpdownlist = [
    //   {
    //     id: "0",
    //     value: "Activity Since Last Statement",
    //   },
    // ];
    // for (let i = 11; i >= 0; i--) {
    //   let firstDay = new Date(currentYear, i, 1);
    //   let day = firstDay.toString().substring(4, 15);
    //   let obj = {
    //     id: i + 1,
    //     value: day,
    //   };

    //   drpdownlist.push(obj);
    // }

    // var drpdownlistTemp = [];

    // this.props.getTransactionDetail(requestData, (res) => {
    //   console.warn("standard checking", res);
    //   if (res.status === STATUS_CODES.CREATED) {
    //     console.warn("Disco", res.data.totalRows.length);
    //     console.warn("Disco2", drpdownlist.length);
    //     drpdownlistTemp.push(drpdownlist[0]);
    //     for (let j = 0; j < res.data.totalRows.length; j++) {
    //       for (let i = 1; i < drpdownlist.length; i++) {
    //         if (
    //           new Date(drpdownlist[i].value).getMonth() + 1 ==
    //           res.data.totalRows[j].createdAt
    //         ) {
    //           drpdownlistTemp.push(drpdownlist[i]);
    //         }
    //       }
    //     }
    //   }
    //   // drpdownlist.length = 0;
    //   drpdownlist = drpdownlistTemp;
    //   this.setState({ filterDropdownlist: drpdownlist });
    //   //console.warn("finaltesting>>>>>>>>>>>>>>>",filterDropdownlist);
    // });
  };

  // getDropdownOptions = () => {
  //   return Array.isArray(this.state.filterDropdownlist) &&
  //     this.state.filterDropdownlist.length > 0
  //     ? this.state.filterDropdownlist.map((ad) => ({
  //         label: ad.value,
  //         value: ad.id,
  //       }))
  //     : [];
  // };

  // getEditionName = (id) => {
  //   console.warn("asdfghj>>>>>>>", id);
  //   this.setState({ filterid: id });
  //   this.getPayoutRecords(this.state.pageSize, this.state.page, "", "", id);
  // };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getPayoutRecords(
      this.state.pageSize,
      this.state.page,
      "",
      "",
      this.state.filterid
    );
  };

  generatePdf = () => {
    console.log("Download Section");
    var doc = new jsPDF("l", "pt", "a4");
    doc.html(document.querySelector(".download-section"), {
      callback: function(pdf) {
        // eslint-disable-next-line no-unused-expressions
        doc.setFontSize(18);
        doc.text("Pending Transaction", 10, 30);
        pdf.save("pending-trasaction.pdf");
      },
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getPayoutRecords(
          this.state.pageSize,
          this.state.page,
          "",
          "",
          this.state.filterid
        );
      }
    );
  };

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const { pageSize, count, entries, viewAll } = this.state;
    const { historyDetail } = this.props;
    if (viewAll) {
      if (historyDetail.length) {
        this.setState({ loader: false }, () => {
          const { user_detail } = this.props.loggedInUser;
          let requestData = {
            npc_id: user_detail.newspaper.id,
            row: pageSize + entries,
            page: 1,
            sort: "",
            column: "",
            type: 4,
          };
          historyDetail.length < count &&
            this.props.getPayoutHistoryList(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  count: res.data.payout.rows.length,
                });
                setTimeout(() => {
                  this.setState({
                    localLoading: false,
                    payoutList: [...res.data.payout.rows],
                  });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    if (column.sortable !== false) {
      const { sortFlag } = this.state;
      let temp = sortFlag;
      temp[column.id].sort = !sortFlag[column.id].sort;
      let sortBy = sortFlag[column.id].sort ? "ASC" : "DESC";
      this.setState({ sortFlag: temp });
      column.sortable !== false && instance.sortColumn(column);
      column.sortable !== false &&
        this.getPayoutRecords(this.state.pageSize, 1, sortBy, column.id, "");
    }
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Company Name",
        accessor: "client_name",
        Cell: (row) => {
          const { original } = row;
          return <span>{original.company_name}</span>;
        },
      },
      {
        Header: "Booking Date",
        accessor: "createDate",
        Cell: (row) => {
          const { original } = row;
          let date = original.createDate;
          return <span>{date ? moment(date).format("M/D/YYYY") : ""}</span>;
        },
      },
      {
        Header: "Ad Type",
        accessor: "ad_name",
        Cell: (row) => {
          const { original } = row;
          let ad_name=original.ad_name;
          let payment_order = original.payment_order;
          let howmanytimes= original.how_many_times;
          let discount=original.is_discounted?original.is_discounted:0;
          if(discount>0){
          return <span>{ad_name}<p className="table-para">{payment_order} of {howmanytimes}</p> </span>;
          }else{
            return <span>{ad_name}</span>;
          }
        },
      },

      {
        Header: "Amount",
        accessor: "price",
        Cell: (row) => {
          const { original } = row;
          var price = original.commission;
          price= price.toFixed(2);
          return <span>${price}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "payment_status",
        Cell: (row) => {
          const { original } = row;
          let payment_status=original.payment_status;
          
          if(payment_status=='Declined'){
          return <span>{payment_status} </span>;
          }else{
            return <span>{"Approved"}</span>;
          }
        },
        
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { historyDetail } = this.props;
    const { viewAll, page, pageSize, localLoading, payoutList } = this.state;
    const headers = PayoutHistoryHeaders;
    return (
      <div style={{ marginTop: -15 }}>
        <div fluidHeight={true}>
          <div className="px-4" ref={(el) => (this.componentRef = el)}>
            <div className="row my-3">
                  <div className="col-sm-12 payment-heading">
                    <h5>Pending Transaction</h5>
                  </div>
                  {/* <div className="col-sm-7 d-flex-center justify-content-between justify-content-sm-end">
                    <h5 className="mr-3">
                      <CSVLink
                        filename={"PayoutDetails.csv"}
                        data={payoutList}
                        headers={headers}
                        style={{ borderBottom: "1px solid" }}
                      >
                        Export CSV
                      </CSVLink>
                    </h5>
                    <h5>
                      <Link>
                        <ReactToPrint
                          trigger={() => (
                            <a href="#" style={{ borderBottom: "1px solid" }}>
                              Print
                            </a>
                          )}
                          content={() => this.componentRef}
                        />
                      </Link>
                    </h5>
                  </div> */}


              <div className="row mx-0 w-100 align-items-center">
              <div className="col-sm-6 col-lg-4">
                  {/* <Field
                        name="edition_id"
                        component={renderSelectField}
                        options={this.getDropdownOptions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        onChange={(e) => this.getEditionName(e.target.value)}
                      /> */}
              </div>
                
              <div className="col-sm-6 col-lg-8 text-right">
                <div className="Download_pdf pending_pdf">
                  <span className="" style={{color:"rgb(88, 103, 221)", borderBottom:"1px solid"}} onClick={this.generatePdf}>
                  <img
                    src={pdf_icon}
                    alt="master-logo"
                    style={{
                      height: "20px",
                      position: "relative",
                      left: "-3px",
                      top: "-2px",
                    }}
                  />
                    Download PDF</span>
                </div>
              </div>
              </div>
              <div className="col-12 download-section">
                <StandardTable
                  handelSearch={this.handleSearch}
                  columnOrder={this.renderRecords()}
                  handleSort={this.handleSort}
                  sortable={false}
                  onPageSizeChange={this.onPageSizeChange}
                  showSearch={false}
                  data={payoutList}
                  pagination={false}
                  className="react_customTable"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { paymentReportDuck } = store;
  const { historyDetail } = paymentReportDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    historyDetail:
      historyDetail !== undefined && Array.isArray(historyDetail.rows)
        ? historyDetail.rows
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "PendingTransactionListing",
    })(PendingTransactionListing)
  )
);
