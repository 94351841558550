import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import Dropzone from "react-dropzone";
import '../pages/user-types/npc/settings/Settings.scss';
import { FILE_SIZE } from '../config';
import * as NewsPaperCompanyDuck from '../store/ducks/super-admin/NewsPaperCompany.duck'
import { MESSAGES } from '../config/message';
import { injectIntl } from 'react-intl';
import { STATUS_CODES } from '../common/StatusCode';
import { fileTypes } from '../common/constant';

// for profile picture
class ImageUpload extends React.Component {
  state = { warningMsg: "", uploadedFile: '' };

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getDataById();
  }

  /**
   * @method getDataById
   * @description get company details by id
   */
  getDataById = () => {
    const { user_detail } = this.props.loggedInUser;
    if (user_detail.newspaper) {
      let requestData = {
        company_id: user_detail.newspaper.id
      }
      this.props.getCompanyById(requestData,
        res => {
          if (res.data !== undefined) {
            const type = res.data.sample_newspaper !== '' && typeof res.data.sample_newspaper
            if (type === 'string') {
              this.setState({ uploadedFile: JSON.parse(res.data.sample_newspaper) && JSON.parse(res.data.sample_newspaper).name })
            } else {
              this.setState({ uploadedFile: res.data.sample_newspaper && res.data.sample_newspaper.name })
            }
          }
        }
      );
    }
  }

  /**
   * @method onDrop
   * @description handle image drag and drop
   */
  onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = MESSAGES.VALID_TYPE;
      this.setState({ warningMsg: message });
    } else {
      this.props.addFile(accepted);
      this.setState({ warningMsg: "" });
      let size = accepted[0] && accepted[0].size;
      let fileType = accepted[0] && accepted[0].type;
      let filename = accepted[0] && accepted[0].name;
      let validType = fileTypes.includes(fileType);
      this.props.validImg(validType);
      if (size <= FILE_SIZE && validType) {
        this.setState({
          overSizedImage: false,
          invalidImage: false
        }, () => {
          const reader = new window.FileReader();
          reader.readAsDataURL(accepted[0]);
          reader.onloadend = () => {
            const base64data = reader.result;
            const { user_detail } = this.props.loggedInUser;
            const requestData = {
              user_id: user_detail.id,
              id: user_detail.newspaper.id,
              role_id: user_detail.role_id,
              email: user_detail.email,
              status: user_detail.status,
              active: true,
              sample_newspaper: {
                name: filename,
                sample_newspaper: base64data
              }
            }
            this.props.updateComapnyProfile(requestData, '', res => {
              if (res.status === STATUS_CODES.CREATED) {
                toastr.success(MESSAGES.FILE_UPLOAD_SUCCESS)
              }
            });
          };
        })
      } else if (size >= FILE_SIZE && validType) {
        this.setState({
          overSizedImage: true,
          invalidImage: false,
        });
      } else {
        this.setState({
          invalidImage: true,
          overSizedImage: false,
        });
      }
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { files } = this.props;
    const { overSizedImage, invalidImage } = this.state;
    const thumbs = files.map(file => (
      <div className='form-control'>
        <label><i className="fa fa-picture-o" aria-hidden="true">&nbsp;&nbsp; &nbsp;</i>
          {file.name}
           &nbsp;&nbsp;<label className="fileName" htmlFor='file'>Or browse for PDF</label>
        </label>
      </div>
    ));

    const render =
      Object.keys(files).length !== 0 ? (
        files.map(file => <aside>{thumbs}</aside>)
      ) : (
          <div className='form-control'>
            <label><i class="fa fa-picture-o" aria-hidden="true">&nbsp;&nbsp; &nbsp;</i>
              {this.state.uploadedFile ? this.state.uploadedFile : 'Drag and drop the PDF file or Image file'}
           &nbsp;&nbsp;<label className="fileName" htmlFor='file'>Or browse for PDF</label>
            </label>
          </div>
        );

    return (
      <div>
        <p>{this.state.warningMsg}</p>
        <div>
          <Dropzone
            style={{
              width: "100%",
              //height: "50px",
              objectFit: "cover",
              objectPosition: "center",
            }}
            multiple={false}
            onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
          >
            {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
              // for drag and drop warning statement
              if (isDragReject) return MESSAGES.VALID_FILE;
              return render;
            }}
          </Dropzone>
        </div>
        <div>
          {overSizedImage && (
            <div className="text-help" htmlFor='yetyur'>
              {MESSAGES.FILE_SIZE_VALIDATION}
            </div>
          )}
          {invalidImage && (
            <div className='text-help' htmlFor='yetyur'>
              {MESSAGES.FILE_TYPE_VALIDATION}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, authToken } = store
  return ({
    loggedInUser: auth.user,
    authToken: authToken,
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    NewsPaperCompanyDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'ImageUpload'
    })(ImageUpload)
  )
);