import React, { useEffect, useState } from 'react'
import { LayoutContextProvider } from '../../../../../_metronic'
import { Portlet, PortletBody } from '../../../../partials/content/Portlet'
import NewEmailListing from './NewEmailListing'
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import { connect } from 'react-redux'

const NewEmail = ({ getUnapprovedSecondaryEmails }) => {
    const [count, setCount] = useState(0)
    var allEmails = []

    const requestGetData = {
        approved: 0,
        isDeleted: 0
    }

    useEffect(() => {
        getUnapprovedSecondaryEmails(requestGetData, (res) => {
            
            allEmails = res.data
            setCount( allEmails.length )
            


            
        })

        

        

    }, [count])
    return (
        <div>
        <h4 className="kt-subheader__title col-md-6">
                <span>New Email</span>
        </h4>
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletBody>
                <div 
                    className="row1" 
                    style={{ 
                        border: "1px solid #dbdbdb", 
                        borderRadius: 5+"px", 
                        padding: 5 + "px", 
                        paddingTop: 5 + "px", 
                        margin: 5 + "px", 
                        display: "flex" 
                        }}
                >
                    <div 
                        className="colum1" 
                        style={{ 
                            flex: 10 + "%", 
                            paddingRight: 0 + "px", 
                            paddingLeft: 5 + "px" 
                            }}
                    >
                        <i 
                            class="far fa-envelope fa-4x"
                            style={{ 
                                color: "#FF6347",
                                marginLeft: 20 + "px",
                                fontWeight: 100 + "px"
                             }}
                        ></i>
                    </div>

                    <div 
                        className="colum1" 
                        style={{ 
                            flex: 90 + "%"  
                            }}
                    >
                        <p 
                            style={{ 
                                color: "black", 
                                fontWeight: "bold", 
                                paddingTop: 10 + "px", 
                                paddingLeft: 0 + "px",  
                                flex: 10 + "%" 
                                }}
                        >
                            New Email Added <br />
                            <span 
                                style={{ 
                                    color: "black", 
                                    fontWeight: "normal" 
                                    }}
                            > 
                                { count } New email addresses added!
                            </span>
                        </p>
                    </div>
                  </div>
                </PortletBody>
                <PortletBody>
                  <div style={{ borderBottom: "1px solid grey" }}></div>
                  <NewEmailListing />
                </PortletBody>
              </Portlet>
            </div>
          </div>
      </div>
    )
}

export default connect(null, { ...NPCDuck.actions })(NewEmail)

