import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import count1 from "../../../../../_metronic/_assets/media/images/landing-page/count-1.png";
import count2 from "../../../../../_metronic/_assets/media/images/landing-page/count-2.png";
import count3 from "../../../../../_metronic/_assets/media/images/landing-page/count-3.png";
import count4 from "../../../../../_metronic/_assets/media/images/landing-page/count-4.png";
import Fade from "react-reveal/Fade";

const Counton = () => {
  return (
    <>
      <div className='lp_counton_section'>
        <Container>
          <div className='counton_section_main'>
            <div className='heading_part'>
              <Fade bottom duration={1000} delay={300}>
                <h2>You can count on:</h2>
              </Fade>
            </div>
            <div className='content_part'>
              <Row className='lp_counton_row'>
                <Col lg={3} sm={6} xs={6}>
                  <div className='count_grid text-center my-4'>
                    <Fade Fade bottom duration={1000} delay={300}>
                      <div className='img_content'>
                        <img src={count1} alt='count' />
                      </div>
                    </Fade>
                    <Fade bottom duration={1000} delay={400}>
                      <div className='desc_content'>
                        <p>
                          Email / chat <br /> support
                        </p>
                      </div>
                    </Fade>
                  </div>
                </Col>
                <Col lg={3} sm={6} xs={6} >
                  <div className='count_grid text-center my-4'>
                    <Fade bottom duration={1000} delay={300}>
                      <div className='img_content'>
                        <img src={count2} alt='count' />
                      </div>
                    </Fade>
                    <Fade bottom duration={1000} delay={400}>
                      <div className='desc_content'>
                        <p>
                          Request publications <br /> to be added
                        </p>
                      </div>
                    </Fade>
                  </div>
                </Col>
                <Col lg={3} sm={6} xs={6}>
                  <div className='count_grid text-center my-4'>
                    <Fade bottom duration={1000} delay={300}>
                      <div className='img_content'>
                        <img src={count3} alt='count' />
                      </div>
                    </Fade>
                    <Fade bottom duration={1000} delay={400}>
                      <div className='desc_content'>
                        <p>
                          10GB+ <br /> storage
                        </p>
                      </div>
                    </Fade>
                  </div>
                </Col>
                <Col lg={3} sm={6} xs={6}>
                  <div className='count_grid text-center my-4'>
                    <Fade bottom duration={1000} delay={300}>
                      <div className='img_content'>
                        <img src={count4} alt='count' />
                      </div>
                    </Fade>
                    <Fade bottom duration={1000} delay={400}>
                      <div className='desc_content'>
                        <p>
                          Regular access to <br /> new features
                        </p>
                      </div>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Counton;
