import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as auth from './ducks/auth.duck';
import * as commonDuck from './ducks/Common.duck';
import * as superAdminDuck from './ducks/SuperAdmin.duck';
import * as myProfileDuck from './ducks/super-admin/MyProfile.duck';
import * as subscriptionDuck from './ducks/super-admin/Subscription.duck';
import * as adminManagementDuck from './ducks/super-admin/AdminManagement.duck';
import * as customerDuck from './ducks/super-admin/Customer.duck';
import * as registrationDuck from './ducks/super-admin/Registration.duck';
import * as settingsDuck from './ducks/super-admin/Settings.duck';
import * as adManagementDuck from './ducks/super-admin/AdManagement.duck';
import * as companyProfileDuck from './ducks/npc/CompanyProfile.duck';
import * as roleManagementDuck from './ducks/super-admin/RoleManagement.duck';
import * as npcDuck from './ducks/npc.duck';
import * as newsPaperCompanyDuck from './ducks/super-admin/NewsPaperCompany.duck';
import * as emailConfigurationDuck from './ducks/super-admin/EmailConfiguration.duck ';
import * as notificationDuck from './ducks/Notifications.duck ';
import * as orderManagementDuck from './ducks/npc/OrderManagement.duck';
import * as paymentReportDuck from './ducks/npc/PaymentReport.duck';
import * as companySummaryDuck from './ducks/super-admin/CompanySummary.duck';
import * as payoutSummaryDuck from './ducks/super-admin/report-management/PayoutSummary.duck'
import * as salesReportDuck from './ducks/super-admin/report-management/SalesReport.duck';
import * as orderReportDuck from './ducks/super-admin/report-management/OrderReport.duck';
import * as adTypeReportDuck from './ducks/super-admin/report-management/AdtypeReport';
import * as customerSummaryDuck from './ducks/super-admin/CustomerSummary.duck';
import * as AdminDashboardRecord from './ducks/super-admin/AdminDashboard.duck';
import * as npcDashboardRecord from './ducks/npc/Dashboard.duck';
import * as customerAccessDuck from './ducks/npc/CustomerAccess.duck'
import * as adminCustomerAccessDuck from './ducks/super-admin/AdminCustomerAccess.duck';
import * as bussinessHoursDuck from './ducks/npc/BussinessHours.duck'

import { metronic } from '../../_metronic';
import { reducer as toastrReducer } from 'react-redux-toastr';
import * as seasonalIssuesDuck from './ducks/npc/SeasonalIssues.duck';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  commonDuck: commonDuck.reducer,
  superAdminDuck: superAdminDuck.reducer,
  myProfileDuck: myProfileDuck.reducer,
  subscriptionDuck: subscriptionDuck.reducer,
  companyProfileDuck: companyProfileDuck.reducer,
  adminManagementDuck: adminManagementDuck.reducer,
  adManagementDuck: adManagementDuck.reducer,
  roleManagementDuck: roleManagementDuck.reducer,
  customerDuck: customerDuck.reducer,
  registrationDuck: registrationDuck.reducer,
  newsPaperCompanyDuck: newsPaperCompanyDuck.reducer,
  settingsDuck: settingsDuck.reducer,
  npcDuck: npcDuck.reducer,
  emailConfigurationDuck: emailConfigurationDuck.reducer,
  notificationDuck: notificationDuck.reducer,
  orderManagementDuck: orderManagementDuck.reducer,
  paymentReportDuck: paymentReportDuck.reducer,
  companySummaryDuck:companySummaryDuck.reducer,
  payoutSummaryDuck: payoutSummaryDuck.reducer,
  salesReportDuck: salesReportDuck.reducer,
  customerSummaryDuck:customerSummaryDuck.reducer,
  orderReportDuck: orderReportDuck.reducer,
  adTypeReportDuck: adTypeReportDuck.reducer,
  AdminDashboardRecord: AdminDashboardRecord.reducer,
  npcDashboardRecord: npcDashboardRecord.reducer,
  customerAccessDuck: customerAccessDuck.reducer,
  adminCustomerAccessDuck: adminCustomerAccessDuck.reducer,
  bussinessHoursDuck: bussinessHoursDuck.reducer,

  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  form: formReducer, // <- Passing Redux form to our Reducer
  toastr: toastrReducer,
  SeasonalIssuesDuck: seasonalIssuesDuck.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
