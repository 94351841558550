import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import { injectIntl } from 'react-intl';
import ImageUpload from '../../../../common/ImageUpload'

class SampleNewsPaper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
    }

     /**
     * @method componentWillUnmount
     * @description unmount component
     */
    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
    }

     /**
     * @method addFile
     * @description add uploaded file
     */
    addFile = file => {
        this.setState({
            files: file.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        });
    };

    /**
     * @method render
     * @description render the component
     */
    render() {
        const { files } = this.state;
        return (
            <div className="row my-5">
                <div className='col-md-12'>
                    <LayoutContextConsumer className='row'>
                        {({ subheader: { title } }) => (
                            <div className='row'>
                                <h5 className='kt-subheader__title col-md-6'>
                                    <span>Sample Newspaper</span>
                                </h5>
                            </div>
                        )}
                    </LayoutContextConsumer>
                    <Portlet fluidHeight={true}>
                        <PortletBody >
                            <h6>Upload PDF or Image</h6>
                            <ImageUpload addFile={this.addFile} files={files} validImg={this.props.validImg} />
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    const { auth, authToken } = store;
    return ({
        loggedInUser: auth.user,
        authToken: authToken,
    })
};

export default injectIntl(
    connect(
        mapStateToProps
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'SampleNewsPaper'
        })(SampleNewsPaper)
    )
);

