import React, { Suspense, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { connect } from 'react-redux'
import axios from 'axios';
import { injectIntl } from 'react-intl'
import { Upgradation } from '../errors/Upgradtion.js';
import { superAdminId } from '../../config';
import { langs } from '../../config/localization';
import { LayoutSplashScreen } from '../../../_metronic';
import { ErrorPage1 } from '../errors/ErrorPage1';
import { UnAuthorized } from '../errors/UnAuthorized';
// import WelcomePage from '../errors/WelcomePage';
import Builder from './Builder';
import Dashboard from './Dashboard';
import DocsPage from './docs/DocsPage';
import MyProfiles from './super-admin/my-profile';
import AllAdminList from './super-admin/admin-management/Listing';
import AddAdmin from './super-admin/admin-management/AddAdmin';
import EditAdmin from './super-admin/admin-management/EditAdmin';
import ViewCompany from './super-admin/newspaper-management/ViewCompany';
import ViewRequest from './super-admin/newspaper-management/ViewRequest';
import * as commonDuck from '../../store/ducks/Common.duck'
import AllNewspaperList from './super-admin/newspaper-management/Listing';
import AllAgentseditList from './super-admin/newspaper-management/AgentListing';
import AllRequestList from './super-admin/registration/Listing';
import CompanyProfile from './npc/company-profile';
import SeasonalIssue from './npc/seasonal-issues';
import AddSeasonalissueName from './npc/seasonal-issues/AddSeasonalissueName';
import EditSeasonalIssues from './npc/seasonal-issues/EditSeasonalIssues';
import UpdatePaymentMethode from './npc/settings/UpdatePaymentMethode';
import CustomerListing from './super-admin/customer-management/index';
import EditCustomer from './super-admin/customer-management/EditCustomer'
import Roles from './super-admin/roles'
import ManageAddType from './super-admin/ad-management/ManageAddType'
import SuperAdminSetting from './super-admin/settings'
import NavigateModal from './NavigateModal';
import npcSetting from './npc/settings';


// M-4 Routes---
import OrderListing from './super-admin/order-management';
import DisplayOrderDetail from './super-admin/order-management/DisplayOrderDetail';
import ClassifiedOrderDetail from './super-admin/order-management/ClassifiedOrderDetail';
import AllOrderListing from './npc/order-management';
import NpcDisplayOrderDetail from './npc/order-management/DisplayOrderDetail';
import NpcClassifiedOrderDetail from './npc/order-management/ClassifiedOrderDetail';
import RecievedOrderListing from './npc/order-management/recieved-order';
import RecievedOrderDetail from './npc/order-management/recieved-order/RecievedOrderDetail';
import ViewOrderList from './super-admin/customer-management/ViewOrderList';
import ViewOrderDetail from './super-admin/customer-management/ViewOrderDetail';
import EmailTemplateList from './super-admin/email-configuration/TemplateListing';
import ViewTempalte from './super-admin/email-configuration/ViewEmailTempalte'
import ourPlans from './npc/ourPlans';
import NotificationList from '../notification/NotificationListing'
import CustomerAccess from './npc/customer-access'
import CustomerAccessList from './super-admin/customer-access';
import AdTypeDetail from './super-admin/customer-access/AdTypeDetail';
import AddDiscount from './npc/customer-access/AddDiscount'
import Agentdiscount from './npc/customer-access/Agentdiscount'

// M-5 Routes---
import PayoutHistory from './npc/reports-management/payout-history'
import PayoutReports from './npc/reports-management/payout-reports'
import SalesReports from './npc/reports-management/sales-reports'
import ViewStatement from './npc/reports-management/payout-history/ViewStatement'
import AdTypeReports from './npc/reports-management/adtype-reports'
import NPCDashboard from './DashBoard/newspaper-company'
import AdminDashBoard from './DashBoard/admin-dashboard';
// admin reports

import OrderReports from './super-admin/reports-management/orders-report'
import AdminAdTypeReports from './super-admin/reports-management/adtype-reports'
import CustomerReports from './super-admin/reports-management/customer-reports'
import AdminSalesReports from './super-admin/reports-management/sales-reports'
import PayoutSummary from './super-admin/reports-management/payout-summary'
import OpenStatement from './super-admin/reports-management/payout-summary/OpenStatement'
import AllStatements from './super-admin/reports-management/payout-summary/AllStatements'
import ComapnySummary from './super-admin/reports-management/company-summary'
import PublisherSummary from './super-admin/reports-management/publisher-summary'
import CustomerSummary from './super-admin/reports-management/customer-summary'
import PijenSummary from './super-admin/reports-management/pijen-summary'
import AgentSummary from './super-admin/reports-management/agent-summary'


import Publisherpdf from './super-admin/reports-management/publisher-summary/Publisherpdf'
import Agentpdf from './super-admin/reports-management/agent-summary/agentpdf';
import Customersummarypdf  from './super-admin/reports-management/customer-summary/Customersummarypdf'
import PijenSummarypdf  from './super-admin/reports-management/pijen-summary/PijenSummarypdf'


import AdTypeOrderListing from './super-admin/reports-management/adtype-reports/OrderListing'
import CreditListing from './super-admin/credit/CreditListing';
import NpcCreditListing from './npc/credit/CreditListing';
import NewEmail from './super-admin/newspaper-management/NewEmail.js';
import DownloadCSV from './super-admin/newspaper-management/DownloadCSV.js';
import Milestones from './super-admin/newspaper-management/Milestones.js';
import AddMilestones from './super-admin/newspaper-management/AddMilestones.js';
import EditMilestones from './super-admin/newspaper-management/EditMilestones.js';
import WelcomePage from '../errors/WelcomePage.js';
import BankInfo from '../user-types/npc/customer-access/BankInfo';
import GeneralSettings from './super-admin/settings/GeneralSettings.js';
import UpdateEmailsettings from './super-admin/settings/UpdateEmailsettings.js';
import UpdateWhatsappsettings from './super-admin/settings/UpdateWhatsappsettings.js'; 
import UpdatePayoutsettings from './super-admin/settings/UpdatePayoutsettings.js';
import UpdateCustomURLsettings from './super-admin/settings/UpdateCustomURLsettings.js';
import UpdateSendEmailList from './super-admin/settings/UpdateSendEmailList.js';
import ActiveSubscription from './super-admin/subscription/ActiveSubscription';
import CancelledSubscription from './super-admin/subscription/CancelledSubscription';
import IncompleteSubscription from './super-admin/subscription/IncompleteSubscription';
import UsersActivity from './super-admin/activity-logs/UsersActivity.js';

function HomePage(props) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${props.authToken}`;
  const [isSessionExpires, setIsSessionExpires] = useState(false);

  window.addEventListener('storage', () => {
    const serializedState = localStorage.getItem('persist:demo1-auth');
    let loginRes = JSON.parse(serializedState);
    if (loginRes.user === undefined) {
      setIsSessionExpires(true)
    }
  });

  const checkPermission = (key, permit) => {
    const serializedState = localStorage.getItem('persist:demo1-auth');
    let loginRes = JSON.parse(serializedState);
    if (loginRes.user === undefined) {
      setIsSessionExpires(true)
    } else {
      let user = JSON.parse(loginRes.user);
      if (user.user_detail.role_Data === undefined) {
        setIsSessionExpires(true)
      } else {
        let permissions = user.user_detail.role_Data.permissions
        let role = user.user_detail.role_Data.name
        if (role === "superadmin") {
          return true;
        } else {
          return permissions[key][permit].value;
        }
      }
    }
  }
  if (!props.authorized || props.sessionExpired || isSessionExpires) {
    return (
      <NavigateModal
        show={true}
        alertHeading={'Session Expired !!'}
        showCancel={false}
        alertMessage={'Your session has been expired.Please login again.'}
        onOkClick={() => window.location.assign('/logout')}
        okButtonText='Ok'
      />
    )
  }
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {!props.authorized && (
        <Switch>
          <Redirect to='/auth/login' />
        </Switch>
      )}
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from='/' to='/dashboard' />
        }
        <Route path='/builder' component={Builder} />

        {/* Super-Admin Routes */}
        {/* {(props.role_id === 0 || adminRoles.includes(props.role_id)) && ( */}
        {(props.isAdminOrSuperAdmin) && (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from='/error/error-v1' to='/dashboard' />
            }
            {!props.dashboardAccessAdmin && <Route path='/dashboard' component={WelcomePage} />}
            {props.dashboardAccessAdmin && <Route path='/dashboard' component={AdminDashBoard} />}

            {!props.dashboardAccessAdmin && <Route path='/myProfile' component={UnAuthorized} />}
            {props.dashboardAccessAdmin && <Route path='/myProfile' component={MyProfiles} />}

            <Route exact path='/addAdmin' render={(props) => {
              if (checkPermission(langs.permissionKeys.admin, langs.permissionKeys.create)) {
                return <AddAdmin {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/editAdmin/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.admin, langs.permissionKeys.create)) {
                return <EditAdmin {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/adminManagement' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <AllAdminList {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/addSeasonalIssue' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <AddSeasonalissueName {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/active-subscription' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <ActiveSubscription {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/cancelled-subscription' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <CancelledSubscription {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/incomplete-subscription' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <IncompleteSubscription {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/users-activity' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <UsersActivity {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/newspaperManagement' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <AllNewspaperList {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/allAgentseditList' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <AllAgentseditList {...props} />
              }
              return <UnAuthorized />
            }} />
            


            {/* <Route exact path='/files/newsPaperSample.csv' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <DownloadCSV {...props} />
              }
              return <UnAuthorized />
            }} /> */}

            <Route exact path='/allRequestListing' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <AllRequestList {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/customerManagement' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <CustomerListing {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/editCustomer/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.customer, langs.permissionKeys.create)) {
                return <EditCustomer {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/viewcompany/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.newspaperCompany, langs.permissionKeys.view)) {
                return <ViewCompany {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/viewRequest/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.newspaperCompanyModeration, langs.permissionKeys.view)) {
                return <ViewRequest {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/adManagement' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <ManageAddType {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/settings' render={(props) => {
              if (checkPermission(langs.permissionKeys.settings, langs.permissionKeys.accessRight)) {
                return <SuperAdminSetting {...props} />
              }
              return <UnAuthorized />
            }} />
            {/* <Route exact path='/orderManagement' render={(props) => {
              if (checkPermission(langs.permissionKeys.orders, langs.permissionKeys.accessRight)) {
                return <OrderListing {...props} />
              }
              return <UnAuthorized />
            }} /> */}
            <Route exact path='/displayOrderDetail/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.orders, langs.permissionKeys.accessRight)) {
                return <DisplayOrderDetail {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/classifiedOrderDetail/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.orders, langs.permissionKeys.accessRight)) {
                return <ClassifiedOrderDetail {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/viewOrderList/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.orders, langs.permissionKeys.accessRight)) {
                return <ViewOrderList {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/viewOrderDetail/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.orders, langs.permissionKeys.accessRight)) {
                return <ViewOrderDetail {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/email-template' render={(props) => {
              console.warn(props)
              if (checkPermission(langs.permissionKeys.emailTemplate, langs.permissionKeys.accessRight)) {
                return <EmailTemplateList {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/edit-template/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.emailTemplate, langs.permissionKeys.create)) {
                return <ViewTempalte {...props} />
              }
              return <UnAuthorized />
            }} />

            {/* <Route exact path='/bankinforequest/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.create)) {
                return <BankInfo {...props} />
              }
              return <UnAuthorized />
            }} /> */}

            <Route exact path='/notifications' render={(props) => {
              if (checkPermission(langs.permissionKeys.notifications, langs.permissionKeys.accessRight)) {
                return <NotificationList {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/customer-reports' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <CustomerReports {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/customer-access' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <CustomerAccessList {...props} />
              }
              return <UnAuthorized />
            }} />          

            <Route exact path='/order-report' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <OrderReports {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/adtype-report' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <AdminAdTypeReports {...props} />
              }
              return <UnAuthorized />
            }} />

            {/* New Email */}
            <Route exact path='/new-email' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <NewEmail {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/publisher-pdf/' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <Publisherpdf {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/customer-pdf/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <Customersummarypdf {...props} />
              }
              return <UnAuthorized />
            }} />
            <Route exact path='/pijen-pdf/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <PijenSummarypdf {...props} />
              }
              return <UnAuthorized />
            }} />


        <Route exact path='/agent-pdf/' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <Agentpdf {...props} />
              }
              return <UnAuthorized />
            }} />

        {/* GeneralSettings */}
        <Route exact path='/gsettings' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <GeneralSettings {...props} />
              }
              return <UnAuthorized />
            }} />

      <Route exact path='/UpdateEmailsettings' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <UpdateEmailsettings {...props} />
              }
              return <UnAuthorized />
            }} />

      <Route exact path='/UpdateWhatsappsettings' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <UpdateWhatsappsettings {...props} />
              }
              return <UnAuthorized />
            }} />

      <Route exact path='/UpdatePayoutsettings' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <UpdatePayoutsettings {...props} />
              }
              return <UnAuthorized />
            }} />

      <Route exact path='/UpdateCustomURLsettings' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <UpdateCustomURLsettings {...props} />
              }
              return <UnAuthorized />
            }} />

      <Route exact path='/UpdateSendEmailList' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <UpdateSendEmailList {...props} />
              }
              return <UnAuthorized />
            }} />

            {/* Milestones */}
            <Route exact path='/milestones' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <Milestones {...props} />
              }
              return <UnAuthorized />
            }} />

            

            <Route exact path='/add-milestones' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <AddMilestones {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/edit-milestones/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.adManagement, langs.permissionKeys.accessRight)) {
                return <EditMilestones {...props} />
              }
              return <UnAuthorized />
            }} />


            <Route exact path='/adtype-report-order-listing/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.reportsAdType, langs.permissionKeys.view)) {
                return <AdTypeOrderListing {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/sales-reports' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <AdminSalesReports {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/payout-summary' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <PayoutSummary {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/open-statement/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.paymentHistory, langs.permissionKeys.view)) {
                return <OpenStatement {...props} />
              }
              return <UnAuthorized />
            }} />

            <Route exact path='/allStatements' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <AllStatements {...props} />
              }
              return <UnAuthorized />
            }} />


            <Route exact path='/company-summary' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <ComapnySummary {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/publisher-summary' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <PublisherSummary {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/pijen-summary' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <PijenSummary {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/customer-summary' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <CustomerSummary {...props} />
              }
              return <UnAuthorized />
            }} />

          <Route exact path='/agent-summary' render={(props) => {
              if (checkPermission(langs.permissionKeys.reports, langs.permissionKeys.accessRight)) {
                return <AgentSummary {...props} />
              }
              return <UnAuthorized />
            }} />


            <Route exact path='/adtype-detail/:id' render={(props) => {
              if (checkPermission(langs.permissionKeys.customerSummary, langs.permissionKeys.view)) {
                return <AdTypeDetail {...props} />
              }
              return <UnAuthorized />
            }} />
            {/* <Route exact path='/credits' render={(props) => {
              if (checkPermission(langs.permissionKeys.userManagement, langs.permissionKeys.accessRight)) {
                return <CreditListing {...props} />
              }
              return <UnAuthorized />
            }} />
            {props.role_id === superAdminId && <Route exact path='/roles' component={Roles} />}
            <Route exact path='/upgradation' component={Upgradation} /> */}
            <Route exact path='/unAuthorized' component={UnAuthorized} />
            {props.role_id === superAdminId && <Route exact path='/roles' component={Roles} />}
            {/* <Redirect to='/error/error-v1' /> */}
            <Route path='*' component={ErrorPage1} />
          </Switch >
        )
        }

        {/* Newspaper Company Routes */}
        {
          (props.role_id !== 0 && props.isNewspaper) && (
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                <Redirect exact from='/error/error-v1' to='/dashboard' />
              }
              <Route path='/dashboard' component={NPCDashboard} />
              <Route exact path='/companyProfile' component={CompanyProfile} />
              <Route exact path='/seasonalIssue' component={SeasonalIssue} />
              <Route exact path='/seasonalIssue/:id' component={EditSeasonalIssues} />
              <Route exact path='/settings' component={npcSetting} />
              <Route exact path='/UpdatePaymentMethode' component={UpdatePaymentMethode} />

              {/* M-3 Routes */}
              <Route path="/ourPlans" component={ourPlans} />

              {/* M-4 Routes */}
              <Route exact path='/notifications' component={NotificationList} />
              <Route exact path='/allOrders' component={AllOrderListing} />
              <Route exact path='/displayOrderDetail/:id' component={NpcDisplayOrderDetail} />
              <Route exact path='/classifiedOrderDetail/:id' component={NpcClassifiedOrderDetail} />
              {/* <Route exact path='/recievedOrderListing' component={RecievedOrderListing} /> */}
              {/* <Route exact path='/recievedOrderListingDetail/:id' component={RecievedOrderDetail} /> */}

              {/* M-5 Routes */}
              <Route exact path='/payout-reports' component={PayoutReports} />
              <Route exact path='/payoutHistory' component={PayoutHistory} />
              <Route exact path='/sales-reports' component={SalesReports} />
              <Route exact path='/viewStatement/:id' component={ViewStatement} />
              <Route exact path='/adtype-reports' component={AdTypeReports} />
              {/* <Route exact path='/customer-access' component={CustomerAccess} /> */}
              <Route exact path='/agent-discount' component={Agentdiscount} />
              {/* <Route exact path='/bankinforequest/:id' component={BankInfo} /> */}
              <Route exact path='/create-discount/:id' component={AddDiscount} />
              {/* <Route path="/credits" component={NpcCreditListing} /> */}


              {/* <Route exact path='/upgradation' component={Upgradation} /> */}
              <Route path='*' component={ErrorPage1} />
              <Redirect to='/error/error-v1' />
            </Switch >
          )
        }
        {
          props.authorized && (
            <Switch>
              <Redirect exact to='/dashboard' />
            </Switch>
          )
        }
        {/* <Route exact path='/upgradation' component={Upgradation} /> */}
        <Route exact path='/customer-access' component={CustomerAccess} />
        <Route exact path='/agent-discount' component={Agentdiscount} />
        <Route exact path='/create-discount/:id' component={AddDiscount} />

        {/* <Route path="/credits" component={NpcCreditListing} /> */}
        <Redirect to='/error/error-v1' />
        {/* </Switch> */}
        )
      {props.authorized && (
          <Switch>
            <Redirect exact to='/dashboard' />
          </Switch>
        )}
        {/* <Route exact path='/addNewspaper' component={addNewspaper} /> */}
        <Route exact path='/upgradation' component={Upgradation} />
        <Route path='/docs' component={DocsPage} />
        {/* <Redirect to='/error/error-v1' /> */}
      </Switch >
    </Suspense >
  );
}
const mapStateToProps = store => {
  const { superAdminDuck } = store
  const { user_detail } = store.auth.user
  let role_id = (store.auth.user && user_detail) ? user_detail.role_id : undefined;
  // ----------------------Route Access Management-------------------------------------------
  let roles = Array.isArray(user_detail.all_roles) ? user_detail.all_roles.map(item => item.id) : []
  let isAdminOrSuperAdmin = false;
  let dashboardAccessAdmin = false;
  if (role_id === superAdminId) {
    isAdminOrSuperAdmin = true;
    dashboardAccessAdmin = true
  } else if (roles.includes(role_id)) {
    isAdminOrSuperAdmin = true
    if (user_detail.role_Data) {
      dashboardAccessAdmin = user_detail.role_Data.permissions['dashboard'].accessRight.value
    }
  }
  //------------------------------------------------------------------
  return ({
    role_id: role_id,
    authorized: role_id !== undefined ? true : false,
    sessionExpired: superAdminDuck.sessionExpired,
    isNewspaper: (user_detail && user_detail.newspaper) ? true : false,
    authToken: store.auth.authToken ? store.auth.authToken : undefined,
    isAdminOrSuperAdmin,
    dashboardAccessAdmin
  })
}
export default injectIntl(
  connect(
    mapStateToProps,
    commonDuck.actions,
  )(HomePage));

