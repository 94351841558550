import React from "react";
import {
    Button,
    Modal,
} from "react-bootstrap";
import { MESSAGES } from '../../../../config/message'

export default class AgentModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false
        };
    }
    componentWillReceiveProps(nextprops, props) {
        if (props.isOpen !== nextprops.isOpen) {
        this.setState({ show: nextprops.isOpen });
        }
    }

    handleClose() {
        this.setState({ show: false });
       this.props.onCancelClick();
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        // const { keyname } = this.props;
        const { message } = this.props;
        const { show } = this.state
        return (
            <>
                <Modal aria-labelledby="example-modal-sizes-title-sm" show={show} onHide={this.handleClose} centered={true}>
                    <Modal.Body>
                    <h3 style={{textAlign:"center"}}> List of Agents using the Edition </h3>
                    <ul style={{paddingTop: 5 + "px"}}>
                        {message.map(function(name, index){
                            return <li key={ index }>{name}</li>;
                        })}
                    </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                
                </Modal>
            </>
        );
    }
}
