import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import * as orderManagementDuck from "../../../../../store/ducks/npc/OrderManagement.duck";
import { toastr } from "react-redux-toastr";
import { Redirect } from "react-router-dom";
import { Loader } from "../../../../../common/Loader";
import { MESSAGES } from "../../../../../config/message";
import { langs } from "../../../../../config/localization";
import { REJECTED_STATUS } from "../../../../../config/constant";
import { Button, Form } from "react-bootstrap";
import {
  renderSelectField,
  renderTextAreaField,
} from "../../../../../common/layout/FormInput";
import { required } from "../../../../../config/validation";
import { STATUS_CODES } from "../../../../../common/StatusCode";

class DeclineOrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      isSubmitted: true,
      flag: false,
      loading: false,
      showOtherReason: false,
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.props.getAllReasonList(langs.keyname.reject_reason);
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.onCancel();
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    //console.warn("values: ", values);
    const { orderDetail } = this.props;
    const { showOtherReason } = this.state;
    const { user_detail } = this.props.loggedInUser;
    let reqData = {
      id: orderDetail.id,
      npc_id: user_detail.newspaper.id,
      user_id: orderDetail.user.id,
      newspaper_name: orderDetail.newspaper.newspaper_name,
      first_name: orderDetail.user.first_name,
      email: orderDetail.user.email,
      status: REJECTED_STATUS,
      reject_reason_type: values.reason_subtype,
      reject_reason_sub_type: showOtherReason ? values.reason_message : "",
    };

    this.setState({ isSubmitted: false });
    this.props.acceptorder(reqData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        this.setState({ show: false, isRedirect: true });
        this.toggleModel();
        toastr.success(MESSAGES.DECLINED_ORDER);
      }
    });
  }

  render() {
    const { handleSubmit, loading, isOpen, reasonList } = this.props;
    const { isRedirect, isSubmitted, showOtherReason } = this.state;
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/recievedOrderListing",
          }}
        />
      );
    }
    return (
      <Modal isOpen={isOpen} toggle={this.toggleModel} styles={{ height: 10 }}>
        {loading && <Loader />}
        <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
          Decline Order
        </ModalHeader>
        <ModalBody>
          <Row>
            <Container>
              <form
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="12">
                    <Field
                      name="reason_subtype"
                      label="Select Reason"
                      // options={reasonList}
                      options={[...reasonList, { id: 0, name: "Other Reason" }]}
                      onChange={(e) => {
                        e.target.value === "Other Reason"
                          ? this.setState({ showOtherReason: true })
                          : this.setState({ showOtherReason: false });
                      }}
                      required={true}
                      validate={required}
                      isSelect={false}
                      optionValue={"name"}
                      optionLabel={"name"}
                      component={renderSelectField}
                    />
                  </Col>
                </Row>
                {showOtherReason && (
                  <Row>
                    <Col md="12">
                      <Field
                        name="reason_message"
                        component={renderTextAreaField}
                        required={true}
                        label="Other Reason"
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="12 float-right text-right">
                    <Button
                      style={{ margin: 10, background: "black", width: 100 }}
                      disabled={isSubmitted ? false : true}
                      size="md"
                      type="submit"
                    >
                      Send
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  const { commonDuck } = store;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    selectedCompany: store.registrationDuck.selectedCompany,
    reasonList: commonDuck.reasonList ? commonDuck.reasonList : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...orderManagementDuck.actions,
    ...commonDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "DeclineOrderModal",
    })(DeclineOrderModal)
  )
);
