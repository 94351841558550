import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./All.scss";

const Moreads = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <section className="planning_sec MoreAds_sec">
        <div className="container">
          <div className="planning_sec_inner">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="planning_content"
                  data-aos-duration="1500"
                  data-aos-delay="300"
                  data-aos="fade-right"
                >
                  <div className="pagehd">
                    <h1>
                      More ads
                      <br />
                      <b>Less lags</b>
                    </h1>
                    <p>
                      The Pijen platform helps agencies plan, place
                      <br />
                      and buy media in an efficient, organized
                      <br /> manner. The software’s advanced features facilitate
                      <br /> transactions for all parties.
                    </p>
                  </div>
                  {/* <a href="contact-us.html" className="pgbtn">
                    Send To Publisher
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="planning_images banner_dashbaord_img"
                  data-aos-delay="300"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <img
                    className="main_shadow"
                    src="assets/images/banner-dashboard.png"
                    alt=""
                  />
                  <img
                    className="main_shadow banner_position"
                    src="assets/images/upload-artwork.png"
                    alt=""
                  />
                  <img
                    className="main_shadow send_to_publish"
                    src="assets/images/send-to-publisher.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Moreads;
