import React from "react";
import { connect } from "react-redux";
import { Col, Button } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import * as customerDuck from "../../../../store/ducks/super-admin/Customer.duck";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { MESSAGES } from "../../../../config/message";
import { langs } from "../../../../config/localization";
import NavigationPrompt from "react-router-navigation-prompt";
import NavigateModal from "../../NavigateModal";
import { Label, Input } from "reactstrap";
import { Loader } from "../../../../common/Loader";
import { toastr } from "react-redux-toastr";
import "../../Modal.scss";
import {
  renderText,
  renderNumberInputField,
  renderSelectField,
  focusOnError,
} from "../../../../common/layout/FormInput";
import {
  required,
  PhoneNumber,
  maxLength50,
  minLength5,
  maxLength100,
  maxLengthC100,
  minLength2,
  maxLength5,
  email,
  normalizePhone,
  alphabetsOnly,
  addressOnly,
  minLength3,
  noSpecialCharacterOnly,
  noNumberOnly
} from "../../../../config/validation";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { phoneNumberFormateOnChange } from "../../../../common";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";

class AddCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgent: true,
      allowCustomer: false,
      countryType: [{ label: "USA", value: "USA" }],
      loading: false,
      isSubmitted: true,
      is_deadline_yes:false,
      is_deadline_no:true,
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.props.getAllRoleList(langs.keyname.customer);
    this.props.getAllRoleList(langs.keyname.agent);
  }

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { isAgent, allowCustomer, is_deadline_yes } = this.state;
    const { customerRole, agentRole } = this.props;
    values.as_agent = isAgent;
    values.is_deadline = is_deadline_yes;
    values.allow_cheque = allowCustomer;
    values.role_id = isAgent === true ? agentRole.id : customerRole.id;
    values.status = 1;
    values.active = 1;
    values.country = "USA";
    
    values.city = values?.city?.trim()?? '';
    values.state = values?.state?.trim()?? '';
    values.address = values?.address?.trim()?? '';
    this.setState({ isSubmitted: false });
    this.props.createCustomer(values, (res) => {
      if (
        res.status === STATUS_CODES.CREATED ||
        res.status === STATUS_CODES.OK
      ) {
        toastr.success(MESSAGES.ADD_CUSTOMER_SUCCESS);
        this.toggleModel();
      } else {
        this.setState({ isSubmitted: true });
      }
    });
  }

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    this.setState({
      isEditCompanyFlag: true,
      isEditFlag: true,
      number: type === "number" ? e.target.value : this.state.number,
    });
  };

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
      isEditFlag: true,
      isEditCompanyFlag: true,
    });
  };

  /**
   * @method handleChange
   * @description handle checked or unchecked
   */
  handleChange = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  /**
   * @method handleToggleChange
   * @description handle toggle change
   */
  handleToggleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked, isEditCompanyFlag: true });
  };

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.onCancel();
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit, isEditFlag, loading } = this.props;
    const {
      isRedirect,
      isEditCompanyFlag,
      number,
      billingCity,
      billingState,
      allowCustomer,
      isAgent,
      isSubmitted,
      is_deadline_yes,
      is_deadline_no,
    } = this.state;
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/customerManagement",
          }}
        />
      );
    }
    return (
      <Container className="top-margin">
        {isEditFlag && (
          <NavigationPrompt when={isEditCompanyFlag}>
            {(props) => {
              const { onCancel } = props;
              return (
                <NavigateModal
                  show={true}
                  alertHeading={"Changes made will be lost."}
                  cancleButtonText="Cancel"
                  okButtonText="Leave Anyway"
                  showCancel={true}
                  onOkClick={() => this.props.onCancel()}
                  onClickCancel={() => {
                    onCancel();
                  }}
                  contentClassName="modalShadow"
                />
              );
            }}
          </NavigationPrompt>
        )}
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggleModel}
          styles={{ height: 10 }}
          size="lg"
        >
          {loading && <Loader />}

          <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
            <h5 className="kt-portlet__head-title">Add Customer</h5>
          </ModalHeader>
          <ModalBody>
            <Container>
              <form
                noValidate
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="6">
                    <Field
                      name="first_name"
                      value={"adminProfile.first_name"}
                      label="First Name"
                      validate={[required, minLength2, maxLengthC100, alphabetsOnly]}
                      required={true}
                      placeholder="Enter Your First Name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="last_name"
                      label="Last Name"
                      validate={[required, minLength2, maxLengthC100, alphabetsOnly]}
                      required={true}
                      placeholder="Enter Your Last Name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="email"
                      label="Email"
                      validate={[required, minLength5, maxLength100, email]}
                      required={true}
                      placeholder="Enter Email Id"
                      component={renderText}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="phone_number"
                      label="Phone Number"
                      type="text"
                      maxLength={10}
                      autoComplete="off"
                      onChange={this.onChange}
                      Value={number}
                      validate={[required, PhoneNumber]}
                      required={true}
                      placeholder="Enter Phone Number"
                      component={renderNumberInputField}
                      normalize={normalizePhone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Field
                      name="company_name"                
                      label="Company Name"
                      validate={[required, minLength3, maxLength100, noSpecialCharacterOnly,noNumberOnly]}
                      required={true}
                      placeholder="Company name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Field
                      name="address"
                      value={"adminProfile.first_name"}
                      label="Address"
                      validate={[ minLength2, maxLength100, addressOnly]}
                      placeholder="Enter Address"
                      component={renderText}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="city"
                      value={billingCity}
                      label="City"
                      validate={[ maxLength50, alphabetsOnly]}
                      placeholder="Enter City"
                      component={renderText}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="state"
                      value={billingState}
                      label="State"
                      validate={[ maxLength50, alphabetsOnly]}
                      placeholder="Enter State"
                      component={renderText}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="zip_code"
                      value={"adminProfile.first_name"}
                      label="Zip Code"
                      validate={[ minLength5, maxLength5]}
                      type="text"
                      style={{ width: "100%" }}
                      maxLength={5}
                      placeholder="Enter Zip Code"
                      component={renderText}
                      normalize={normalizePhone}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="country"
                      label="Country"
                      options={[{ label: "USA", value: "USA" }]}
                      component={renderSelectField}
                      placeholder="Enter Country"
                      optionValue={"value"}
                      optionLabel={"label"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="checkbox-inline">
                      <CheckboxRP label="Allow customer to pay by check">
                        <Input
                          type="checkbox"
                          id="checkbox1"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={allowCustomer ? true : false}
                          onChange={(e) =>
                            this.setState({
                              allowCustomer: !allowCustomer,
                            })
                          }
                          name="allow_cheque"
                        />
                      </CheckboxRP>
                      <CheckboxRP label="As a Agent">
                        <Input
                          type="checkbox"
                          id="checkbox2"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={isAgent ? true : false}
                          onChange={(e) =>
                            this.setState({
                              isAgent: !isAgent,
                            })
                          }
                          name="as_agent"
                        />
                      </CheckboxRP>
                    </div>
                  </Col>
                </Row>
                &nbsp;&nbsp;
                <Row>
                <p style={{width:'100%',
                marginLeft:"10px",
                color:"#48465b",
                fontWeight:"500",
                fontSize:"1rem"
                }}>Allow Deadline Extension?</p>
                <Col md={6}>
                    <div className="checkbox-inline">
                      <CheckboxRP label="Yes">
                        <Input
                          type="checkbox"
                          id="deadlineyes"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={is_deadline_yes? true : false}
                          onChange={(e) =>
                            this.setState({
                              is_deadline_yes:true,
                              is_deadline_no:false
                            })
                          }
                        />
                      </CheckboxRP>
                      <CheckboxRP label="No">
                        <Input
                          type="checkbox"
                          id="deadlineno"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={is_deadline_no? true : false}
                          onChange={(e) =>
                            this.setState({
                              is_deadline_yes:false,
                              is_deadline_no:true
                            })
                          }
                        />
                      </CheckboxRP>
                    </div>
                  </Col> 
               </Row>
               &nbsp;&nbsp;
                <Row className="float-right">
                  <Col>
                    <div className="d-flex-center j-end">
                      <ButtonRP
                        text="Cancel"
                        cancel={true}
                        className="mx-2"
                        onClick={this.props.onCancel}
                      />
                      <ButtonRP
                        type="submit"
                        text="Save"
                        disabled={isSubmitted ? false : true}
                      />
                    </div>
                  </Col>
                </Row>
              </form>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
  let errors = {};

  //Check the value should not be empty
  //Checks password & confirm password validation both are same or not
  if (!values.password) {
    errors.password = langs.validation_messages.password_required;
  } else if (!values.confirmPassword) {
    errors.confirmPassword =
      langs.validation_messages.confirm_password_required;
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = langs.messages.password_unmatch;
  }
  return errors;
}

const mapStateToProps = (store) => {
  const { loading, roles } = store.commonDuck;
  let initialValues = {};
  initialValues = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zip_code: "",
    company_name:"",
    
  };
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    customerRole: roles && roles.customer ? roles.customer[0] : [],
    agentRole: roles && roles.agent ? roles.agent[0] : [],
    loading,
    initialValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...commonDuck.actions, ...customerDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "AddCompany",
      validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(AddCustomer)
  )
);
