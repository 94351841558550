import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import {
    required,
    number,
    greaterThanZeroWithTwoDecimalPlace
} from '../../../../config/validation'
import { renderSelectField, renderNumberField } from '../../../../common/layout/FormInput'

const AddCharacterForm = ({
    onAddCharacter,
    handleSubmit,
    reset,
    getfromNPCSetting,
})=> {
    const handleOnSubmit = (values) => {
        if(values.character !== "" && values.cost !== "" && parseInt(values.cost) >= 0)
        {
            onAddCharacter({character: values.character, cost: values.cost})
            reset();
        }
    }

    return (
        <div className="form-row align-items-center">
           
           <div className="col-md-3 mt-3" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="character"
                        component={renderNumberField}
                        placeholder="Character"
                        min="0"
                        helpText={'Only Numeric value'}
                    />
                </div>
            </div>
            <div className="col-md-3 mt-3" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="cost"
                        component={renderNumberField}
                        validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                        ]}
                        placeholder="Cost"
                        min="0"
                        step=".01"
                        helpText={'Only Numeric value with up to 2 decimal places'}
                    />
                </div>
            </div>
            <div className="col-md-3 mt-4" style={{ height: 105 }}>
                <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={handleSubmit(handleOnSubmit)}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        character: '',
        cost: ''
    }
})
export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addCharacterForm',
})(AddCharacterForm))