
import { SESSION_EXPIRED_ACTION } from '../config/constant'

/**
  * @method checkPermission
  * @description check access permission 
  */
export const checkPermission = (key, permit) => {
    const serializedState = localStorage.getItem('persist:demo1-auth');
    let loginRes = JSON.parse(serializedState);
    if (loginRes.user !== undefined) {
        let user = JSON.parse(loginRes.user);
        let permissions = user.user_detail.role_Data.permissions
        let role = user.user_detail.role_Data.name
        if (role === 'superadmin') {
            return true;
        } else {
            return permissions[key] === undefined ? false : permissions[key][permit].value
        }
    }
}

/**
  * @method tokenCheck
  * @description check token is valid or not 
  */
export const tokenCheck = (dispatch, err) => {
    if (err && err.response && err.response.status === 411) {
        dispatch({ type: SESSION_EXPIRED_ACTION })
    } else {
        return true;
    }
}
