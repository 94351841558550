import React from "react";
import { connect } from "react-redux";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import CKEditor from "ckeditor4-react";
import { Field, reduxForm } from "redux-form";
import { Alert } from "react-bootstrap";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { renderThemeToggleButton } from "../../FormLayout";
import { Row, Col, Label, Tooltip } from "reactstrap";
import { injectIntl } from "react-intl";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import NavigationPrompt from "react-router-navigation-prompt";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as emailConfigurationDuck from "../../../../store/ducks/super-admin/EmailConfiguration.duck ";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { toastr } from "react-redux-toastr";
import "./template.scss";
import { focusOnError } from "../../FormLayout";
import { renderText } from "../../../../common/layout/FormInput";
import { required, email } from "../../../../config/validation";
import { STATUS_CODES } from "../../../../common/StatusCode";
import EmailModal from "./EmailModal";
import { NoContentFound } from "../../../../common/NoContentFound";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import ReactHtmlParser from 'react-html-parser';
import renderHTML from 'react-render-html';

class ViewTempalte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      openModal: false,
      isEditFlag: false,
      status: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      isSubmitted: false,
      isRedirect: false,
      tooltipOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {

    this.getTemplateList(this.state.pageSize, 1);
  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getTemplateList = (row, page) => {
    
    const templateId = this.props.match.params.id;
    let requestData = {
      id: templateId,
      is_deleted: 0,
      row: row !== undefined ? row : 50,
      page: page !== undefined ? page : 1,
      name: "",
      sort: "",
      column: "",
    };
    this.props.getTemplateList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          templateList: res.data.rows,
          totalCount: Math.ceil(pages),
          data: res.data.rows && res.data.rows[0].body,
          status: res.data.rows && !res.data.rows[0].active,
        });
      } else if (res) {
        this.setState({ isRedirect: true });
      }
    });
  };

  /**
   * @method findCommonKey
   * @description used to find common key which are not editable in email template
   */
  findCommonKey = () => {
    const { data, templateList } = this.state;
    const key = JSON.parse(templateList[0].key);
    let find = key.map((item, index) => data.includes(item));
    if (find.includes(false)) {
      window.scroll(0, 0);
      this.setState({
        show: true,
        message: MESSAGES.KEYS_NOT_MATCH,
      });
      setTimeout(() => {
        this.setState({ show: false });
      }, 5000);
      return true;
    } else if (!find.includes(false)) {
      const isExist = "find";
      return isExist;
    }
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { data, status } = this.state;
    // client changes 26/05/2020
    // let find = this.findCommonKey();
    // if (find === "find") {
   // console.warn("Email content", renderHTML(data)); 
      const templateId = this.props.match.params.id;
      let requestData = {
        id: templateId,
        name: values.name,
        from: values.emailId ? values.emailId : "",
        sender_mail: values.sendersMail ? values.sendersMail : "",
        company_name: values.companyName ? values.companyName : null,
        subject: values.subject,
        body: data,
        active: !status,
        is_deleted: "0",
      };
      this.props.updateEmailTempalte(requestData, (res) => {
        if (
          res.status === STATUS_CODES.CREATED ||
          res.status === STATUS_CODES.OK
        ) {
          toastr.success(MESSAGES.EMAIL_TEMPLATE_UPDATE);
          this.setState({ isEditFlag: false, isSubmitted: true });
          this.getTemplateList(this.state.pageSize, 1);
        } else {
          this.setState({ isEditFlag: true, isSubmitted: false });
        }
      });
    // }
  }

  /**
   * @method onEditorChange
   * @description  Called on OnChange Event of Editor
   */
  onEditorChange(evt, editor, data) {
    this.setState({
      data: evt.editor.getData(),
      isEditFlag: true,
    });
  }

  /**
   * @method handleToggleChange
   * @description used to handle toggle
   */
  handleToggleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked, isEditFlag: true });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit, loading } = this.props;
    const {
      isEditFlag,
      data,
      openModal,
      status,
      templateList,
      show,
      message,
      isRedirect,
    } = this.state;
    const key = templateList && JSON.parse(templateList[0].key);
    let config = {
      toolbar: [
        [ 'Source' ],
        { name: "save", items: ["savebtn", "Undo", "Redo"] },
        {
          name: "clipboard",
          items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord"],
        },
        { name: "document", items: ["Find", "Replace"] },
        {
          name: "lists",
          items: ["NumberedList", "BulletedList", "Outdent", "Indent"],
        },
        { name: "insert", items: ["Image", "Table", "Smiley", "SpecialChar"] },
        { name: "link", items: ["Link", "Unlink"] },
        {
          name: "basicstyles",
          items: [
            "Font",
            "FontSize",
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "Subscript",
            "Superscript",
          ],
        },
        {
          name: "align",
          items: [
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
          ],
        },
      ],
    };
    config.extraPlugins = "font,justify,showblocks,sourcedialog";
    config.allowedContent = {
      $1: {
          elements: CKEditor.dtd,
          attributes: true,
          styles: true,
          classes: true
      }
  };
  config.disallowedContent = 'script; *[on*]';
    if (isRedirect) {
      return (
        <div>
          <NoContentFound />
        </div>
      );
    } else {
      return (
        <div>
          <NavigationPrompt when={isEditFlag}>
            {(props) => {
              const { onConfirm, onCancel } = props;
              return (
                <NavigateModal
                  show={true}
                  alertHeading={"Changes made will be lost."}
                  cancleButtonText="Keep Changes"
                  okButtonText="Discard Changes"
                  showCancel={true}
                  onOkClick={() => onConfirm()}
                  onClickCancel={() => {
                    onCancel();
                  }}
                />
              );
            }}
          </NavigationPrompt>
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div>
                <div className="row">
                  <div className="col">
                    <h3 className="kt-subheader__title">
                      <span style={{ marginLeft: 20 }}>Email Templates</span>
                    </h3>
                    <div className="col">
                      <Form.Group
                        style={{ textAlign: "end" }}
                        controlId="validationCustom01"
                        className="float-right"
                      >
                        <Form.Label style={{ color: "green" }}>
                          Active
                        </Form.Label>
                        <Field
                          name="active"
                          checked={status}
                          onChange={this.handleToggleChange("status")}
                          required={true}
                          component={renderThemeToggleButton}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Label style={{ color: "red" }}>
                          Inactive
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </LayoutContextConsumer>
          <div className="col-md-12">
            {show && (
              <Alert
                variant="danger"
                onClose={() => this.setState({ show: false })}
                dismissible
              >
                {message}
              </Alert>
            )}
            <Portlet fluidHeight={true}>
              {loading && <Loader />}
              <PortletBody>
                <div className="warning">
                  <label className="labelnote">
                    <strong>
                    Keys available for this email template :{" "}
                      {key && key.join()}
                    </strong>
                  </label>
                </div>
                <br />
                <Form
                  noValidate
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Row>
                    <Col md="4">
                      <Field
                        name="name"
                        label="Template Name"
                        validate={[required]}
                        required={true}
                        placeholder="Enter Template Name"
                        component={renderText}
                        onChange={(e) => {
                          this.setState({
                            isEditFlag: true,
                          });
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Field
                        name="subject"
                        label="Subject"
                        validate={[required]}
                        required={true}
                        placeholder="Enter Subject"
                        component={renderText}
                        onChange={(e) => {
                          this.setState({
                            isEditFlag: true,
                          });
                        }}
                      />
                      <Field
                        name="companyName"
                        label="Company Name"
                        placeholder="Enter Company Name"
                        component={renderText}
                        onChange={(e) =>
                          this.setState({
                            isEditFlag: true,
                          })
                        }
                      />
                    </Col>
                    <Col md="4">
                      <Field
                        name="emailId"
                        label="Reply To"
                        validate={[required, email]}
                        required={true}
                        placeholder="Enter Email Id"
                        component={renderText}
                        onChange={(e) =>
                          this.setState({
                            isEditFlag: true,
                          })
                        }
                      />
                       <Field
                        name="sendersMail"
                        label="Senders Mail"
                        id="DisabledAutoHideExample"
                        validate={[email]}
                        placeholder="Enter Email Id"
                        component={renderText}
                        onChange={(e) =>
                          this.setState({
                            isEditFlag: true,
                          })
                        }
                      />
                      {this.props.initialValues.sendersMail && 
                          <Tooltip positionFixed arrowClassName="arrowClass" style={{ color:"#fff", backgroundColor:"#000"}} placement="top" isOpen={this.state.tooltipOpen} autohide={false} target="DisabledAutoHideExample" toggle={this.toggle}>
                            Sender's mail has been modified.
                          </Tooltip>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Label>Content</Label>
                    </Col>
                    <Col>
                      <div className="editor-popup">
                        <div
                          className="editor-block" 
                          style={{ marginTop: "4px" }}
                        >
                          <div>
                            {data && (
                              <CKEditor
                                data={data}
                                // config={{
                                //   toolbar: [
                                //     {
                                //       name: 'basicstyles',
                                //       items: ['Bold', 'Italic']
                                //     },
                                //     {
                                //       name: 'links',
                                //       items: ['Link', 'Unlink', 'Anchor']
                                //     },
                                //     { name: 'styles', items: ['Styles', 'Format'] },
                                //     {
                                //       name: 'paragraph',
                                //       items: ['NumberedList', 'BulletedList']
                                //     }
                                //   ]
                                // }}
                                config={config}
                                onChange={this.onEditorChange}
                                onBeforeLoad={(CKEDITOR) =>
                                  (CKEDITOR.disableAutoInline = true)
                                }
                              />
                            )}
                            {data === "" && (
                              <CKEditor
                                // config={{
                                //   toolbar: [
                                //     {
                                //       name: 'basicstyles',
                                //       items: ['Bold', 'Italic']
                                //     },
                                //     {
                                //       name: 'links',
                                //       items: ['Link', 'Unlink', 'Anchor']
                                //     },
                                //     { name: 'styles', items: ['Styles', 'Format'] },
                                //     {
                                //       name: 'paragraph',
                                //       items: ['NumberedList', 'BulletedList']
                                //     }
                                //   ]
                                // }}
                                onBeforeLoad={(CKEDITOR) =>
                                  (CKEDITOR.disableAutoInline = true)
                                }
                              />
                            )}
                            <label></label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="my-4">
                        <Button
                          type="submit"
                          disabled={isEditFlag ? false : true}
                          className="commonBtn float-right"
                        >
                          Save
                        </Button>
                        <Button
                          type="button"
                          //disabled={isSubmitted ? false : true}
                          onClick={() => this.setState({ openModal: true })}
                          style={{
                            backgroundColor: "rgb(219, 219, 219)",
                            color: "black",
                          }}
                          className="btn btn-secondary float-right px-3 mx-3"
                          variant="dark"
                        >
                          Send Test Email
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </PortletBody>
            </Portlet>
            <EmailModal
              isOpen={openModal}
              onCancel={() => this.setState({ openModal: false })}
              getData={templateList}
            />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => {
  const { emailConfigurationDuck } = store;
  const { loading } = store.commonDuck;
  const data = emailConfigurationDuck.templateList;
  let initialValues = {};
  if (data && data[0] !== undefined) {
    initialValues = {
      name: data && data[0].name,
      subject: data && data[0].subject,
      sendersMail: data && data[0].sender_mail,
      emailId: data && data[0].from,
      companyName: data && data[0].company_name,
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
    getDataById:
      emailConfigurationDuck.templateList &&
      emailConfigurationDuck.templateList,
    initialValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...emailConfigurationDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "ViewTempalte",
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(ViewTempalte)
  )
);
