import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../../config';
import {
    CALL_LOADING
} from '../../../../config/constant';
import { apiErrors } from '../../../../common/HandleAPIErrors'


export const actionTypes = {
    SetSalesHeaderDetail: "[SetSalesHeaderDetail] Action",
    SetSalesGraphDetail: "[SetSalesGraphDetail] Action",
    SetSalesListing: "[SetSalesListing] Action",
    SetSampleCsv: "[SetSampleCsv] Action",
    SetAgentpdf:"[SetAgentpdf] Action",
    setCustomerTransactionDetails: "[setCustomerTransactionDetails] Action",
    setPijenTransactionDetails:"[setPijenTransactionDetails] Action",
    setDetailsofAgentWRTpublisher: "[setDetailsofAgentWRTpublisher] Action",
    setCusomerWithrespecttoAgent:"[setCusomerWithrespecttoAgent] Action"
    

};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    salesHeaderDetail: undefined,
    salesGraphDetail: undefined,
    salesReportList: undefined,
    sampleCsv: undefined,
    agentpdf:undefined,
    customerTransactionDetails:undefined,
    pijenTransactionDetails:undefined,
    detailsofAgentWRTpublisher:undefined,
    cusomerWithrespecttoAgent:undefined
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of company list */
            case actionTypes.SetSalesHeaderDetail: {
                const { salesHeaderDetail } = action.payload;
                return { ...state, salesHeaderDetail: salesHeaderDetail };
            }

            /** get all records of company list */
            case actionTypes.SetSalesGraphDetail: {
                const { salesGraphDetail } = action.payload;
                return { ...state, salesGraphDetail: salesGraphDetail };
            }

            /** get all records of company list */
            case actionTypes.SetSalesListing: {
                const { salesReportList } = action.payload;
                return { ...state, salesReportList: salesReportList };
            }

            case actionTypes.SetSampleCsv: {
                const { sampleCsv } = action.payload;
                return { ...state, sampleCsv: sampleCsv };
            }

            case actionTypes.SetSampleCsv: {
                const { sampleCsv } = action.payload;
                return { ...state, sampleCsv: sampleCsv };
            }

            case actionTypes.SetAgentpdf: {
                const { agentpdf } = action.payload;
                return { ...state, agentpdf: agentpdf };
            }

            case actionTypes.setCustomerTransactionDetails: {
                const { customerTransactionDetails } = action.payload;
                return { ...state, customerTransactionDetails: customerTransactionDetails };
            }
            case actionTypes.setPijenTransactionDetails: {
                const { pijenTransactionDetails } = action.payload;
                return { ...state, pijenTransactionDetails: pijenTransactionDetails };
            }

            case actionTypes.setDetailsofAgentWRTpublisher: {
                const { detailsofAgentWRTpublisher } = action.payload;
                return { ...state, detailsofAgentWRTpublisher: detailsofAgentWRTpublisher };
            }
            case actionTypes.setCusomerWithrespecttoAgent: {
                const { cusomerWithrespecttoAgent } = action.payload;
                return { ...state, cusomerWithrespecttoAgent: cusomerWithrespecttoAgent };
            }
            
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getSalesDetail
    * @description get sales report detail
    */
    // getSalesDetail: (requestData,callback) => {
    //     let {section, data, type='', sort='', column='', month='', year='', from_date='', to_date='', page='', row='', search='', statement='' } = requestData;
    //     let url = `${API.getSalesReportDetail}?data=${data}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}&statement=${statement}`
    //     const request = axios.get(url, { headers });
    //     return (dispatch) => {
    //         dispatch({ type: CALL_LOADING })
    //         request.then((response) => {
    //             dispatch({ type: CALL_LOADING })
    //             callback(response);
    //             if(section === 1){
    //                 return dispatch({ type: actionTypes.SetSalesHeaderDetail, payload: { salesHeaderDetail: response.data } })
    //             }else if(section === 2){
    //                 return dispatch({ type: actionTypes.SetSalesGraphDetail, payload: { salesGraphDetail: response.data } })
    //             }else if(section === 3){
    //                 return dispatch({ type: actionTypes.SetSalesListing, payload: { salesReportList: response.data } })
    //             }
    //         }).catch((error) => {
    //             dispatch({ type: CALL_LOADING })
    //             // tokenCheck(dispatch, error)
    //             apiErrors(error,dispatch);
    //             return callback(error);
    //         });
    //     };
    // },

    getSalesDetail: (requestData,callback) => {
        const { data='', type='', sort='', column='', month='', year='', from_date='', to_date='', page='', row='', search='', statement='' } = requestData;
        let url = `${API.getDetailsofPublisher}?data=${data}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetSalesListing, payload: { salesReportList: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                //apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getAgentpdfDetail: (requestData,callback) => {
        const { data='', type='', sort='', column='', month='', year='', from_date='', to_date='', page='', row='', search='', statement='' } = requestData;
        let url = `${API.getDetailsofAgent}?data=${data}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetAgentpdf, payload: { agentpdf: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                //apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getCustomerTransactionDetails: (requestData, callback) => {
        const { id='', type='', sort='', agentTotalFilterOption='', month='', year='', formDate='', toDate='', page='', row='', search='', statement='' } = requestData;
        let url = `${API.getCustomerTransactionDetails}?id=${id}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.setCustomerTransactionDetails, payload: { customerTransactionDetails: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getPijenTransactionDetails: (requestData, callback) => {
        const { id='', type='', sort='', agentTotalFilterOption='', month='', year='', formDate='', toDate='', page='', row='', search='', statement='' } = requestData;
        let url = `${API.getPijenTransactionDetails}?id=${id}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.setPijenTransactionDetails, payload: { pijenTransactionDetails: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getDetailsWithrespectoPublisher: (requestData, callback) => {
        const { id='', date='',type='', sort='', agentTotalFilterOption='', month='', year='', formDate='', toDate='', page='', row='', search='', statement='' } = requestData;
        let url = `${API.getDetailsWithrespectoPublisher}?id=${id}&date=${date}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.setDetailsofAgentWRTpublisher, payload: { detailsofAgentWRTpublisher: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getCustomerWithrespectoAgent: (requestData, callback) => {
        const { id='', date='',type='', sort='', agentTotalFilterOption='', month='', year='', formDate='', toDate='', page='', row='', search='', statement='' } = requestData;
        let url = `${API.getCustomerWithrespectoAgent}?id=${id}&date=${date}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&formDate=${formDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.setCusomerWithrespecttoAgent, payload: { cusomerWithrespecttoAgent: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },
    


    



    /**
    * @method getSalesDetail
    * @description get sales report detail
    */
     getSampleCSV: (requestData,callback) => {
        let {first_name,
        last_name,
        // newspaper_name = requestData['Publisher-Name'] ,
        role_id,
        companycatid,
        phone_number,
        email,
        distribution_type,
        distribution_area_1,
        distribution_area_2,
        distribution_area_3,
        bill_address,
        bill_city,
        bill_state,
        bill_country,
        bill_zip_code,
        email_distribution,
        distributed_copies,
        bio_desc,
        status,
        active,
        publishcat,
        is_website_ad,
        website_ad,
        is_twitter_post,
        twitter_post,
        is_insta_post,
        insta_post,
        is_insta_stories,
        insta_stories,
        is_whatsapp_group,
        whatsapp_group,
        is_whatsapp_status,
        whatsapp_status,
        is_email_marketing,
        email_marketing,
        is_sponsored_article,
        sponsored_article,
        is_in_app_ad,
        in_app_ad,
        is_facebook_post,
        facebook_post,
        is_email_blast,
        email_blast} = requestData;
        let url = `${API.getSampleCsv}?first_name=${first_name}&last_name=${last_name}&Publisher-Name=${requestData['Publisher-Name']}&role_id=${role_id}&companycatid=${companycatid}&phone_number=${phone_number}&email=${email}&distribution_type=${distribution_type}&distribution_area_1=${distribution_area_1}&distribution_area_2=${distribution_area_2}&distribution_area_3=${distribution_area_3}&bill_address=${bill_address}&bill_city=${bill_city}&bill_state=${bill_state}&bill_country=${bill_country}&bill_zip_code=${bill_zip_code}&email_distribution=${email_distribution}&distributed_copies=${distributed_copies}&bio_desc=${bio_desc}&status=${status}&active=${active}&publishcat=${publishcat}&is_website_ad=${is_website_ad}&website_ad=${website_ad}&is_twitter_post=${is_twitter_post}&twitter_post=${twitter_post}&is_insta_post=${is_insta_post}&insta_post=${insta_post}&is_insta_stories=${is_insta_stories}&insta_stories=${insta_stories}&is_whatsapp_group=${is_whatsapp_group}&whatsapp_group=${whatsapp_group}&is_whatsapp_status=${is_whatsapp_status}&whatsapp_status=${whatsapp_status}&is_email_marketing=${is_email_marketing}&email_marketing=${email_marketing}&is_sponsored_article=${is_sponsored_article}&sponsored_article=${sponsored_article}&is_in_app_ad=${is_in_app_ad}&in_app_ad=${in_app_ad}&is_facebook_post=${is_facebook_post}&facebook_post=${facebook_post}&is_email_blast=${is_email_blast}&email_blast=${email_blast}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetSampleCsv, payload: { sampleCsv: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },
};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





