import React from "react";
export default ({
  children,
  innerClassName = "",
  className = "",
  isAutoHeight = false,
  height,
  width,
  alignStart = false,
}) => {
  return (
    <div
      className={`d-flex-center ${
        alignStart ? "align-items-start" : ""
      } count-figure-box my-2 ${className}`}
      style={{
        height: height ? height : isAutoHeight ? "auto" : "100%",
        width: width ? width : "100%",
      }}
    >
      <div className={`d-flex-center-col w-100 ${innerClassName}`}>
        {children}
      </div>
    </div>
  );
};
