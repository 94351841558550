import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  PortletBody,
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../../partials/content/Portlet";
import { Loader } from "../../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { SALES_TREND } from "../../../../../config/constant";
import LineChart from "./Chart";
import StatementListBlock from "./StatementListBlock";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import CountFigureBox from "../../../../../common/CountFigureBox";
import "./Agentsummary.scss";

class SalesReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      salesTrend: SALES_TREND,
      type: false,
      statementId: undefined,
      paymentSchedule1: "",
      monthlyPayment: "",
      monthlyPaymentDate: "",
      byWeeklyPayment: "",
      paymentSchedule3: "",
      byWeeklyPaymentDate: "",
      paymentSchedule2: "",
      byWeeklyPayment1: "",
      byWeeklyPaymentDate1: "",
      weeklyPaymentDate: "",
      weeklyPayment: "",
      headerData: "",
      totalPayoutAmount: "",
      toralEarning: "",
      higestPaidAmount: "",
      higestAmontDate: "",
      lowestPaidAmount: "",
      lowestAmountDate: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutHeaderDetail();

    console.log("State", this.state);
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutHeaderDetail = () => {
    let requestData = {
      type: 1,
      section: 1,
    };
    
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value, date) => {
    return (
      <CountFigureBox>
        <h6 className="heading">{lable}</h6>
        {value !== 0 ? (
          <h5 className="heading">
            {value ? `$ ${value}` : "No Record Found"}
          </h5>
        ) : (
          <h5 className="heading">$0</h5>
        )}
        {date !== "" && <h5 className="heading">{date ? `${date}` : ""}</h5>}
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { loading, totaldata, upcomingdata } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.paymentHistory,
      langs.permissionKeys.view
    );
    
    return (
      <div>
        {/* {loading && <Loader/>} */}
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Payout Summary To Agent</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletBody>
                  <div className="row">
                    <div className="cardWrapper col-md-3">
                      {this.renderHeaderCard(
                        "Upcoming Payout:",
                        parseFloat(upcomingdata).toFixed(2),
                        ""
                      )}
                    </div>

                    <div className="cardWrapper col-md-3">
                      {this.renderHeaderCard(
                        "Total Commission Paid Out:",
                        parseFloat(totaldata).toFixed(2),
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row my-5" style={{ minHeight: 450 }}>
                    <div className="col">
                      <LineChart />
                    </div>
                  </div>

                  <div className="row">
                    <StatementListBlock
                      callNext={(statementId) =>
                        this.setState({ statementId: statementId })
                      }
                    />
                  </div>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { payoutSummaryDuck } = store;
  const { agentDeatils } = payoutSummaryDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    totaldata:
      agentDeatils !== undefined ? agentDeatils.result[0].total_commision : 0,
    upcomingdata:
      agentDeatils !== undefined ? agentDeatils.result[1].upcoming_Payout : "",
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    payoutSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "SalesReports",
    })(SalesReports)
  )
);
