import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { renderText } from '../../../../common/layout/FormInput';
import { toastr } from 'react-redux-toastr';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import { MESSAGES } from '../../../../config/message';
import { Loader } from '../../../../common/Loader';
import { required, minLength2, maxLength100, minLength5,gminLength } from '../../../../config/validation';
import { superAdminId } from '../../../../config'

class EditBankInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      flag: false,
      isSubmitted: true,
      loading: false,
      files: [],
      pictures: [],
      adType: false,
      imageArray: [],
      images: []
    };
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.onCancel();
  };

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getCompanyDetailById(superAdminId, res => { });
  }

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { bankInformation } = this.props
    this.setState({ isSubmitted: false })
    if (bankInformation && bankInformation !== undefined) {
      values.id = bankInformation.id;
      this.props.updateBankInformation(values, (res) => {
        toastr.success(MESSAGES.UPDATE_BANK_INFORMATION)
        this.props.getCompanyDetailById(superAdminId, res => { });
        this.setState({ isSubmitted: true })
        this.toggleModel()
      })
    }
  }

  render() {
    const { handleSubmit, loading } = this.props;
    const { isSubmitted } = this.state;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.toggleModel}
        styles={{ height: 10, width: 100 }}
        size="lg"
      >
        {loading && <Loader />}
        <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
        </ModalHeader>
        <ModalBody styles={{ width: 600 }}>
          <Row>
            <Container>
              <form
                noValidate
                className='form'
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md='6'>
                    <Field
                      name='account_name'
                      label='Name on Account'
                      validate={[required, gminLength, maxLength100]}
                      required={true}
                      placeholder='Enter Name on Account'
                      component={renderText}
                    />
                  </Col>
                  <Col md='6'>
                    <Field
                      name='routing_number'
                      label='Routing Number'
                      validate={[required, minLength2, maxLength100]}
                      required={true}
                      placeholder='Enter Routing Number'
                      component={renderText}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <Field
                      name='account_number'
                      label='Account Number'
                      validate={[required, minLength2, maxLength100]}
                      required={true}
                      placeholder='Enter Account Number'
                      component={renderText}
                    />
                  </Col>
                  <Col md='6'>
                    <Field
                      name='bank_name'
                      label='Bank Name'
                      validate={[required, gminLength, maxLength100]}
                      required={true}
                      placeholder='Enter Bank Name'
                      component={renderText}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <Field
                      name='bank_address'
                      label='Bank Address'
                      validate={[required, gminLength, maxLength100]}
                      maxLength={101}
                      required={true}
                      placeholder='Enter Bank Address'
                      component={renderText}
                    />
                  </Col>
                  <Col md='6'>
                    <Field
                      name='bank_zip_code'
                      label='Zip Code'
                      validate={[required,minLength5]}
                      maxLength={5}
                      required={true}
                      placeholder='Enter Zip Code'
                      component={renderText}
                    />
                  </Col>
                </Row>
                <Row className='float-right'>
                  <Col md='12'>
                    <Button onClick={this.toggleModel} style={{
                      margin: 10, background: '#808080',
                      border: 'none',
                      width: 100
                    }} size='md' >Cancel</Button>
                    <Button
                      disabled={isSubmitted ? false : true}
                      style={{
                        margin: 10,
                        background: 'black',
                        border: 'none',
                        width: 100
                      }}
                      size='md'
                      type='submit'
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  const { commonDuck } = store;
  const { loading, selectedCompany } = commonDuck;

  let initialValues = {};
  if (selectedCompany && selectedCompany !== undefined) {
    initialValues = {
      account_name: selectedCompany.account_name,
      routing_number: selectedCompany.routing_number,
      account_number: selectedCompany.account_number,
      bank_name: selectedCompany.bank_name,
      bank_address: selectedCompany.bank_address,
      bank_zip_code: selectedCompany.bank_zip_code,

    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    bankInformation: selectedCompany,
    loading,
    initialValues,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...commonDuck.actions, ...settingsDuck.actions }
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'EditBankInformation',
    })(EditBankInformation)
  )
);
