import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { toastr } from "react-redux-toastr";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as companySummaryDuck from "../../../../../store/ducks/super-admin/CompanySummary.duck";
import Listing from "./Listing";
import FilterRecords from "./FilterRecords";
import { renderSelectField } from "../../../../../common/layout/FormInput";
import { RANGE } from "../../../../../config/constant";
import TopList from "./TopList";
import CompanyContribution from "./CompanyContribution";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import LineChart from "./Chart1";
import CompanyTrendChart from "./Chart2";
import { SALES_TREND, DATE_RANGE } from "../../../../../config/constant";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { Loader } from "../../../../../common/Loader";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import CountFigureBox from "../../../../../common/CountFigureBox";
let label = getCurrentDate();

class CompanySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal1: false,
      openCompareModal: false,
      isLowest: true,
      salesTrend: SALES_TREND,
      type: false,
      range: RANGE,
      duration: DATE_RANGE,
      selectedCheckCompare: 0,
      selectedCheck: 0,
      selection: [],
      selectedIds: [],
      selectedCompanyTrend: undefined,
      salesType: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
      initialNpcId: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getCompanyList();
    this.getCompanySummary("", 1, 1);
    this.getCompanySummary("", 2, 1);
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanySummary = (npc_id, type, data) => {
    let requestData = {
      npc_id,
      type,
      data,
    };
    this.setState({ salesType: data });
    this.props.getCompanySummaryData(requestData, type, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
  };

  /**
   * @method renderInnerCard
   * @description  render inner sales content
   */
  renderInnerCard = (label, amount, date, amtLabel) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{label}</h5>
        <h5 className="heading">
          {amount ? `${amtLabel}${amount}` : `${amtLabel}0`}
        </h5>
        <h5 className="heading text-break">{date}</h5>
      </CountFigureBox>
      // <div className="innerBox  col-md-5">
      //   <div style={{ border: "1px solid black", width: "auto", padding: 5 }}>
      //     <h5 className="heading">{label}</h5>
      //     <h5 className="heading">
      //       {amount ? `${amtLabel}${amount}` : `${amtLabel}0`}
      //     </h5>
      //     <h5 className="heading text-break">{date}</h5>
      //   </div>
      // </div>
    );
  };

  /**
   * @method renderNPCCard
   * @description  render hiegest revenue data
   */
  renderNPCCard = (label) => {
    const { range } = this.state;
    const { module2Data } = this.props;
    return (
      module2Data !== undefined && (
        <CountFigureBox>
          <div className="row">
            <div className="col d-flex-center">
              <h6 className="heading ">{label}</h6>
              <Field
                name="range"
                options={range}
                className="form-control ml-3"
                optionValue={"value"}
                optionLabel={"label"}
                component={renderSelectField}
                style={{ marginTop: "-27px !important" }}
                onChange={(e) => {
                  this.getCompanySummary("", 2, e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row" style={{ justifyContent: "space-around" }}>
            {this.renderInnerCard(
              "Order Count",
              module2Data.orders,
              module2Data.newspaper_name,
              ""
            )}
            {this.renderInnerCard(
              "Sales",
              module2Data.sales,
              module2Data.newspaper_name,
              "$"
            )}
          </div>
        </CountFigureBox>
      )
    );
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value) => {
    return (
      <CountFigureBox height={130}>
        <h6 className="heading">{lable}</h6>
        <h3 className="heading">{`${value}`}</h3>
      </CountFigureBox>
    );
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanyList = () => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: 100,
      page: 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {});
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let data = Array.isArray(res.data.rows) ? res.data.rows : "";
        this.setState({ initialNpcId: data[0].id }, () => {
          this.getCompanySummary(this.state.initialNpcId, 1, "", "", "", "");
        });
      }
    });
  };

  /**
   * @method filterRecord
   * @description  called after rendering the component
   */
  filterRecord = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const { salesType } = this.state;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    let requestData = {
      npc_id: "",
      type: 2,
      data: salesType ? salesType : 1,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.fluidHeightdate2,
    };
    label = data.label;
    this.props.getCompanySummaryData(requestData, 2, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({
          openModal1: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  }

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { module1Data, companyList, loading } = this.props;
    const { openModal1, initialNpcId, selectedOption } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.companySummary,
      langs.permissionKeys.view
    );
    return (
      <div>
        {/* {loading && <Loader/>} */}
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Company Summary</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletBody>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div
                        className="float-right"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <h5
                          className="mr-md-4 text-right cursorPointer"
                          onClick={() => this.setState({ openModal1: true })}
                        >
                          {label}
                          &nbsp;&nbsp;
                          <i
                            style={{ fontSize: 20 }}
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="cardWrapper col-md-3">
                          {module1Data !== undefined &&
                            this.renderHeaderCard(
                              "Total Active Company",
                              module1Data.active_companies
                            )}
                        </div>
                        <div className="cardWrapper col-md-3 ">
                          {module1Data !== undefined &&
                            this.renderHeaderCard(
                              "Pending Companies",
                              module1Data.pending_companies
                            )}
                        </div>
                        <div className="cardWrapper col-md-6">
                          {this.renderNPCCard("Company with")}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 my-3">
                      <div className="row">
                        <div className="col-md-5 my-2">
                          <TopList />
                        </div>
                        <div className="col-md-7 my-2">
                          <CompanyContribution />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 50, minHeight: 450 }}>
                    <div className="row col-md-12">
                      <h5 className="float-left">Compare between Companies</h5>
                    </div>
                    {initialNpcId && (
                      <div>
                        <LineChart
                          companyList={companyList && companyList}
                          initialNpcId={initialNpcId}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: 50, minHeight: 450 }}>
                    <div className="row col-md-12">
                      <h5 className="float-left">Companies Trend</h5>
                    </div>
                    {initialNpcId && (
                      <div>
                        <CompanyTrendChart
                          companyList={companyList && companyList}
                          initialNpcId={initialNpcId}
                        />
                      </div>
                    )}
                  </div>
                  <Listing />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.REPORT_PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
        {openModal1 && (
          <FilterRecords
            applyFilter={this.filterRecord}
            isOpen={openModal1}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal1: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading, companyList } = store.commonDuck;
  const { auth } = store;
  const { companySummaryDuck } = store;
  return {
    loggedInUser: auth.user,
    loading,
    companyList,
    module1Data: companySummaryDuck.moduleData1,
    module2Data: companySummaryDuck.moduleData2,
    module3Data: companySummaryDuck.moduleData3,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...companySummaryDuck.actions,
    ...commonDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "CompanySummary",
    })(CompanySummary)
  )
);
