// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  Autoplay,
  A11y,
  Controller,
} from "swiper";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "react-bootstrap";
import sliderSix1 from "../../../../../_metronic/_assets/media/images/landing-page/review-6-1.png";
import sliderSix2 from "../../../../../_metronic/_assets/media/images/landing-page/review-6-2.png";
import sliderSix3 from "../../../../../_metronic/_assets/media/images/landing-page/review-6-3.png";
import sliderFive1 from "../../../../../_metronic/_assets/media/images/landing-page/review-5-1.png";
import sliderFive2 from "../../../../../_metronic/_assets/media/images/landing-page/review-5-2.png";
import sliderFour1 from "../../../../../_metronic/_assets/media/images/landing-page/review-4-1.png";
import sliderFour2 from "../../../../../_metronic/_assets/media/images/landing-page/review-4-2.png";
import sliderFour3 from "../../../../../_metronic/_assets/media/images/landing-page/review-4-3.png";
import sliderThree1 from "../../../../../_metronic/_assets/media/images/landing-page/review-3-1.png";
import sliderThree2 from "../../../../../_metronic/_assets/media/images/landing-page/review-3-2.png";
import sliderThree3 from "../../../../../_metronic/_assets/media/images/landing-page/review-3-3.png";
import sliderTwo1 from "../../../../../_metronic/_assets/media/images/landing-page/review-2-1.png";
import sliderTwo2 from "../../../../../_metronic/_assets/media/images/landing-page/review-2-2.png";
import sliderTwo3 from "../../../../../_metronic/_assets/media/images/landing-page/review-2-3.png";
import sliderOne1 from "../../../../../_metronic/_assets/media/images/landing-page/review-1-1.png";
import sliderOne2 from "../../../../../_metronic/_assets/media/images/landing-page/review-1-2.png";
import sliderOne3 from "../../../../../_metronic/_assets/media/images/landing-page/review-1-3.png";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import Aos from "aos";
// install Swiper modules
SwiperCore.use([Navigation, EffectFade, Autoplay, Pagination, Controller]);

export default function Slider() {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const handleCustomSlide = (data) => {
    console.log(data);
    if (!firstSwiper || !setSecondSwiper) return;
    const isPrev = data?.clickedSlide?.classList?.contains(
      "swiper-slide-active"
    );
    const isActive = data?.clickedSlide?.classList?.contains(
      "swiper-slide-next"
    );
    const isNext = isPrev === false && isActive === false;
    if (isPrev) {
      firstSwiper.slidePrev();
    } else if (isNext) {
      firstSwiper.slideNext();
    }
  };

  React.useEffect(() => {
    Aos.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    Aos.refresh();
  });
  return (
    <Container>
      <div
        className="row pijenSwiperslider"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="400"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="col-12 col-lg-6 swiper-1-container">
          <Swiper
            spaceBetween={50}
            slidesPerView={3}
            onClick={handleCustomSlide}
            className="swiper-1"
            direction={"vertical"}
            onSwiper={setFirstSwiper}
            loop={true}
            autoplay={{disableOnInteraction:false}}
            controller={{ control: secondSwiper }}
          >
            <SwiperSlide>
              <div className="slider-item">
                <div className="content">
                  <h2>Notifications</h2>
                  <p>
                    Never miss a deadline again <br /> Keep track of every stop
                    of the media buying process
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                {/* one */}
                <div className="content">
                  <h2>Proposal builder</h2>
                  <p>
                    Ditch the Google sheets <br /> Increase efficiency with
                    preloaded price sheets
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* two */}
            <SwiperSlide>
              <div className="slider-item">
                <div className="content">
                  <h2>Client approval portal</h2>
                  <p>
                    Forget endless back and forth <br /> Improve communications
                    with clients
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="content">
                  <h2>Campaign scheduler</h2>
                  <p>
                    Never miss a deadline again <br /> Save time with our
                    detailed calendar
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="content">
                  <h2>Group reservations</h2>
                  <p>
                    Stop BCCing multiple publishers and avoid mix-ups <br />{" "}
                    Start reserving easily with one click
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="content">
                  <h2>Sales reports</h2>
                  <p>
                    Never lose track of commissions <br /> Increase revenue with
                    detailed sales reports
                  </p>
                </div>
              </div>
            </SwiperSlide>
            
          </Swiper>
        </div>
        <div className="col-12 col-lg-6 swiper-2-container">
          <Swiper
            spaceBetween={50}
            className="swiper-2"
            onSwiper={setSecondSwiper}
            slidesPerView={3}
            direction={"vertical"}
            effect={"fade"}
            loop={true}
          >
             <SwiperSlide>
              <div className="slider-item">
                <div className="slider-content sliderOne">
                  <div className="image_content d-flex flex-column align-items-start">
                    <img
                      className="sliderOne1Img"
                      src={sliderOne1}
                      alt="slider"
                    />
                    <img
                      className="sliderOne2Img"
                      src={sliderOne2}
                      alt="slider"
                    />
                    <img
                      className="sliderOne3Img"
                      src={sliderOne3}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="slider-content sliderTwo">
                  <div className="image_content d-flex flex-column align-items-baseline">
                    <img
                      className="sliderTwo1Img"
                      src={sliderTwo1}
                      alt="slider"
                    />
                    <img
                      className="sliderTwo2Img"
                      src={sliderTwo2}
                      alt="slider"
                    />
                    <img
                      className="sliderTwo3Img"
                      src={sliderTwo3}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* three */}
            <SwiperSlide>
              <div className="slider-item">
                <div className="slider-content sliderThree campaign-scheduler">
                  <div className="image_content d-flex flex-column align-items-start">
                    <img
                      className="SliderThree2Img"
                      src={sliderThree2}
                      alt="slider"
                    />
                    <img
                      className="SliderThree1Img mt-2"
                      src={sliderThree1}
                      alt="slider"
                    />
                    <img
                      className="SliderThree3Img"
                      src={sliderThree3}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="slider-content sliderFour group-reservation">
                  <div className="image_content d-flex align-items-center justify-content-center">
                    <img
                      className="SliderFour1Img"
                      src={sliderFour1}
                      alt="slider"
                    />
                    <img
                      className="SliderFour2Img"
                      src={sliderFour2}
                      alt="slider"
                    />
                    <img
                      className="SliderFour3Img"
                      src={sliderFour3}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="slider-content sliderFive sales-report">
                  <div className="image_content d-flex align-items-center justify-content-center">
                    <img
                      className="SliderFive1Img"
                      src={sliderFive1}
                      alt="slider"
                    />
                    <img
                      className="SliderFive2Img"
                      src={sliderFive2}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <div className="slider-content sliderSix notification-slide">
                  <div className="image_content">
                    <img
                      className="SliderSix1Img"
                      src={sliderSix1}
                      alt="slider"
                    />
                    <img
                      className="SliderSix2Img"
                      src={sliderSix2}
                      alt="slider"
                    />
                    <img
                      className="SliderSix3Img"
                      src={sliderSix3}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
           
          </Swiper>
        </div>
      </div>
    </Container>
  );
}
