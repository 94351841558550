import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  PortletBody,
  Portlet,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../../partials/content/Portlet";
import { Loader } from "../../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { SALES_TREND } from "../../../../../config/constant";
import LineChart from "./Chart";
import StatementListBlock from "./StatementListBlock";
import StatementDetailBlock from "./StatementDetailBlock";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import CountFigureBox from "../../../../../common/CountFigureBox";

class SalesReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      salesTrend: SALES_TREND,
      type: false,
      statementId: undefined,
      paymentSchedule1: "",
      monthlyPayment: "",
      monthlyPaymentDate: "",
      byWeeklyPayment: "",
      paymentSchedule3: "",
      byWeeklyPaymentDate: "",
      paymentSchedule2: "",
      byWeeklyPayment1: "",
      byWeeklyPaymentDate1: "",
      weeklyPaymentDate: "",
      weeklyPayment: "",
      headerData: "",
      totalPayoutAmount: "",
      toralEarning: "",
      higestPaidAmount: "",
      higestAmontDate: "",
      lowestPaidAmount: "",
      lowestAmountDate: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutHeaderDetail();
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutHeaderDetail = () => {
    let requestData = {
      type: 1,
      section: 1,
    };
    this.props.getPayoutSummaryGraphDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let data = res.data;
        this.setState({
          totalPayoutAmount:
            Array.isArray(data.earnings) && data.earnings.length !== 0
              ? data.earnings[0].paid_amount
              : "",
          toralEarning:
            Array.isArray(data.earnings) && data.earnings.length !== 0
              ? data.earnings[0].commission_fee
              : "",
          higestPaidAmount:
            data.highestMonth && data.highestMonth.paid_amount
              ? data.highestMonth.paid_amount
              : "",
          higestAmontDate:
            data.highestMonth && data.highestMonth.createdAt
              ? data.highestMonth.createdAt
              : "",
          lowestPaidAmount:
            data.lowestMonth && data.lowestMonth.paid_amount
              ? data.lowestMonth.paid_amount
              : "",
          lowestAmountDate:
            data.lowestMonth && data.lowestMonth.createdAt
              ? data.lowestMonth.createdAt
              : "",

          weeklyPayment:
            Array.isArray(data.weeklyPay) && data.weeklyPay.length !== 0
              ? data.weeklyPay[0].payment
              : "",
          weeklyPaymentDate:
            Array.isArray(data.weeklyPay) && data.weeklyPay.length !== 0
              ? data.weeklyPay[0].date
              : "",
          paymentSchedule1:
            Array.isArray(data.weeklyPay) && data.weeklyPay.length !== 0
              ? data.weeklyPay[0].payment_schedule
              : "",

          byWeeklyPayment:
            Array.isArray(data.BiweeklyPay) && data.BiweeklyPay.length !== 0
              ? data.BiweeklyPay[0].payment
              : "",
          byWeeklyPaymentDate:
            Array.isArray(data.BiweeklyPay) && data.BiweeklyPay.length !== 0
              ? data.BiweeklyPay[0].createdAt
              : "",
          paymentSchedule2:
            Array.isArray(data.BiweeklyPay) && data.BiweeklyPay.length !== 0
              ? data.BiweeklyPay[0].payment_schedule
              : "",
          byWeeklyPayment1:
            Array.isArray(data.BiweeklyPay) && data.BiweeklyPay.length !== 0
              ? data.BiweeklyPay[0].payment
              : "",
          byWeeklyPaymentDate1:
            Array.isArray(data.BiweeklyPay) && data.BiweeklyPay.length !== 0
              ? data.BiweeklyPay[0].date
              : "",

          monthlyPayment:
            Array.isArray(data.monthlyPay) && data.monthlyPay.length !== 0
              ? data.monthlyPay[0].payment
              : "",
          monthlyPaymentDate:
            Array.isArray(data.monthlyPay) && data.monthlyPay.length !== 0
              ? data.monthlyPay[0].date
              : "",
          paymentSchedule3:
            Array.isArray(data.monthlyPay) && data.monthlyPay.length !== 0
              ? data.monthlyPay[0].payment_schedule
              : "",
        });
      }
    });
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value, date) => {
    return (
      <CountFigureBox>
        <h6 className="heading">{lable}</h6>
        {value !== 0 ? (
          <h5 className="heading">
            {value ? `$ ${value}` : "No Record Found"}
          </h5>
        ) : (
          <h5 className="heading">$0</h5>
        )}
        {date !== "" && <h5 className="heading">{date ? `${date}` : ""}</h5>}
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { loading } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.paymentHistory,
      langs.permissionKeys.view
    );
    const {
      paymentSchedule1,
      monthlyPayment,
      monthlyPaymentDate,
      byWeeklyPayment,
      paymentSchedule3,
      byWeeklyPaymentDate,
      paymentSchedule2,
      byWeeklyPayment1,
      byWeeklyPaymentDate1,
      weeklyPaymentDate,
      weeklyPayment,
      headerData,
      totalPayoutAmount,
      toralEarning,
      higestPaidAmount,
      higestAmontDate,
      lowestPaidAmount,
      lowestAmountDate,
    } = this.state;
    return (
      <div>
        {/* {loading && <Loader/>} */}
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Payout Summary</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletHeader
                  toolbar={
                    <PortletHeaderToolbar>
                      <h5>Upcoming Payout</h5>
                    </PortletHeaderToolbar>
                  }
                />
                <PortletBody>
                  <div className="row">
                    <div className="cardWrapper col-md-3">
                      {this.renderHeaderCard(
                        weeklyPaymentDate,
                        weeklyPayment,
                        paymentSchedule1
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        byWeeklyPaymentDate,
                        byWeeklyPayment,
                        paymentSchedule2
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        byWeeklyPaymentDate1,
                        byWeeklyPayment1,
                        paymentSchedule2
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        monthlyPaymentDate,
                        monthlyPayment,
                        paymentSchedule3
                      )}
                    </div>
                    <div className="cardWrapper col-md-3">
                      {this.renderHeaderCard(
                        "Total Payout Amount",
                        totalPayoutAmount,
                        ""
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        "Total Earnings",
                        toralEarning,
                        ""
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        "Heigest Payout Month",
                        higestPaidAmount,
                        higestAmontDate
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        "Lowest Payout Month",
                        lowestPaidAmount,
                        lowestAmountDate
                      )}
                    </div>
                  </div>
                  <div className="row my-5" style={{ minHeight: 450 }}>
                    <div className="col">
                      <LineChart />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <StatementListBlock
                        callNext={(statementId) =>
                          this.setState({ statementId: statementId })
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <StatementDetailBlock
                        statementId={this.state.statementId}
                      />
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    payoutSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "SalesReports",
    })(SalesReports)
  )
);
