import React, { useState } from "react";
import "./Formup.scss";
import Shieldlogo from "../../../../assets/images/Secure-Icon.png";
import * as npcDuck from '../../../store/ducks/npc.duck';
import * as commonDuck from '../../../store/ducks/Common.duck';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { STATUS_CODES } from '../../../common/StatusCode'
//import * as NewsPaperCompanyDuck from '../../../store/ducks/super-admin/NewsPaperCompany.duck';
import { toastr } from 'react-redux-toastr';
import { Modal, Button } from "react-bootstrap";
import "../WelcomePage.css";
import ModalLogo from "../../../../assets/images/Logingraphics.png";
import AfterSubmit from "./AfterSubmit";
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

function Formup({selectedCompany,addPaymentCompany}) {
  const [bankName, setbankName] = useState(selectedCompany.bank_name);
  const [accName, setaccName] = useState(selectedCompany.account_name);
  const [routNo, setroutNo] = useState(selectedCompany.routing_number);
  const [accNo, setaccNo] = useState(selectedCompany.account_number);
  const [bankAdd, setbankAdd] = useState(selectedCompany.bank_address);
  const [zip, setzip] = useState(selectedCompany.bank_zip_code);
  const [show, setShow] = useState(false);
  const [pageClose, setpageClose] = useState(0)
  
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(!bankName){
			console.log("Please enter First Name")
		}else if(!accName){
			console.log("Please enter Company Name")
		}else if(!routNo){
			console.log("Please enter Message")
		}else if(!accNo){
			console.log("Please enter an Email")
		}else if(!bankAdd){
			console.log("Please enter Phone")
		}else if(!zip){

    }
		else{
      const requestData = {
		    bank_name : bankName,
        account_name : accName,
        routing_number : routNo,
        account_number : accNo,
        bank_address : bankAdd,
        bank_zip_code : zip,
        consumer_unique_key : selectedCompany.consumer_unique_key,
        id : selectedCompany.id,
        phone : selectedCompany.user.phone_number
			};
      addPaymentCompany(requestData, (res) => {
        console.warn('requestData',requestData)
        if (res.status === STATUS_CODES.CREATED) {
          //console.warn(bankName,accName,routNo,accNo,bankAdd,zip)
            setbankName("")
            setaccName("")
            setroutNo("")
            setaccNo("")
            setbankAdd("")
            setzip("")
            //toastr.success("Success", "ayment details updated succesfully");
            //setShow(true);
            setpageClose(1);
        }else{
          console.warn('wrong')
        }
    })
    }
  }

  const ModalcloseHandler = ()=> {
    setShow(false);
    setpageClose(1);
    // <Switch>
    //   <Route exact path='/' component={AfterSubmit} />
    // </Switch>
  }

  return (
    <React.Fragment>
      { pageClose==1 ?
      <div className="container">
        <section className="fix_height2">
          <div className="formup_card">
          <div class="row justify-content-center">
                                <div className="col-md-7">
                                  <div className="modal_image">
                                    <img
                                      alt=""
                                      src={ModalLogo}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 text-center">
                                  <div className="modal_customcontent">
                                    <h2>Thank You!</h2>
                                    <p>Your payment details got updated.</p>
                                  </div>
                                </div>
                              </div>
          </div>
                              
        </section>

      </div>
        :
      <section className="contact_sec contact_secpaymentinfo">
        <div className="container">
          <div className="contact_inner_sec">
            <div className="row">
              <div className="col-md-12">
                <div className="contact_box">
                  <div
                    className="pagehd text-center"
                    data-aos="fade-down"
                    data-aos-duration="1500"
                  >
                    <h1>{selectedCompany.newspaper_name} bank info</h1>
                    <p>Please enter your bank information below so that Pijen can deposit payment
for ads reserved on our platform.</p>
                  </div>
                  <div
                    className="conact_form"
                    data-aos="zoom-in"
                    data-aos-offset="300"
                    data-aos-duration="1500"
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input type="text" placeholder="Bank Name" value={bankName} onChange={(e)=>setbankName(e.target.value)} />
                            {/* {
                                                        
                                                        formSubmitStat === true &&
                                                        !firstName &&
                                                        <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter Name</span>
                                                    } */}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                                                <div className="cu-form">
                                                    <input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)} placeholder="Last Name" />
                                                    {
                                                        formSubmitStat === true &&
                                                        !lastName &&
                                                        <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter Last Name</span>
                                                    }
                                                </div>
                                            </div> */}
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input type="text" placeholder="Name On Account" value={accName} onChange={(e)=>setaccName(e.target.value)} />
                            {/* {
                                                        
                                                        formSubmitStat === true &&
                                                        !email &&
                                                        <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter an Email</span>
                                                } */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input type="text" placeholder="Routing Number" value={routNo} onChange={(e)=>setroutNo(e.target.value)} />
                            {/* {
                                                        formSubmitStat === true &&
                                                        !phone &&
                                                        <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter Phone</span>
                                                    } */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input type="text" placeholder="Account Number" value={accNo} onChange={(e)=>setaccNo(e.target.value)} />
                            {/* {
                                                        formSubmitStat === true &&
                                                        !companyName &&
                                                        <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter Company Name</span>
                                                    } */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input type="text" placeholder="Bank Address" value={bankAdd} onChange={(e)=>setbankAdd(e.target.value)} />
                            {/* {
                                                        formSubmitStat === true &&
                                                        !companySize &&
                                                        <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter Your Company Size</span>
                                                    } */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input type="Number" placeholder="Zip Code" value={zip} onChange={(e)=>setzip(e.target.value)} />
                            {/* {
                                                        formSubmitStat === true &&
                                                        !companySize &&
                                                           <span className="error"><i className="fas fa-exclamation-triangle"></i>*Enter Your Company Size</span>
                                                    } */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="cu-form">
                            <input
                              type="submit"
                              value="Submit"
                              className="pgbtn"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/* bannk info footer part */}
                  <div className="bankinfo_footerpart">
                    <p className="text-center">
                      Your privacy is super important to us - we'll only use
                      your information as described in our
                      <a href="#"> terms of use</a> and
                      <a href="#"> Privacy Policy.</a>
                    </p>
                    <div className="Securtiy_part">
                      <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-6">
                          <div className="security_content">
                              
                              <img src={Shieldlogo} alt={Shieldlogo} />
                              <div>
                              <h3>Safe & Secure</h3>
                              <p>Information is protected
                                  <br /> and kept confidential</p>
                              </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal className="ContactUSMOdal" show={show} onHide={ModalcloseHandler} keyboard={true}>
                            <Modal.Body>
                              <div class="row justify-content-center">
                                <div className="col-md-7">
                                  <div className="modal_image">
                                    <img
                                      alt=""
                                      src={ModalLogo}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 text-center">
                                  <div className="modal_customcontent">
                                    <h2>Thank You!</h2>
                                    <p>Your payment details got updated.</p>
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                            <i className="cross fa fa-times" onClick={ModalcloseHandler}></i>
                          </Modal> */}
      </section>
      } 
    </React.Fragment>
  );
}

const mapStateToProps = store => {
  const { commonDuck } = store
  const selectedCompany = commonDuck.selectedCompany;
  let initialValues = {};
  if (selectedCompany && selectedCompany !== undefined) {
      initialValues = {
          payment_mode: selectedCompany.payment_mode,
          account_name: selectedCompany.account_name,
          account_number: selectedCompany.account_number,
          bank_name: selectedCompany.bank_name,
          bank_address: selectedCompany.bank_address,
          bank_zip_code: selectedCompany.bank_zip_code,
          routing_number: selectedCompany.routing_number
      };
  }
  return {
      loggedInUser: store.auth.user,
      authToken: store.auth.authToken,
      selectedCompany: selectedCompany ? selectedCompany : undefined,
      loading: commonDuck.loading,
      initialValues
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...npcDuck.actions, ...commonDuck.actions})(Formup)
);