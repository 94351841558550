import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Col, Row, Button } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { selectRequired } from "../../../../config/validation";
import { renderSelectField } from "../../../../common/layout/FormInput";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as bussinessHoursDuck from "../../../../store/ducks/npc/BussinessHours.duck";
import { MESSAGES } from "../../../../config/message";
import { toastr } from "react-redux-toastr";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { Label, Input } from "reactstrap";
import moment from "moment-timezone";
import CheckboxRP from "../../../../common/CheckboxRP";
import RadioButtonRP from "../../../../common/RadioButtonRP";

var timezones = moment.tz.names();

class BusinessHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekDays: [
        { key: "Monday", value: false },
        { key: "Tuesday", value: false },
        { key: "Wednesday", value: false },
        { key: "Thurstday", value: false },
        { key: "Friday", value: false },
        { key: "Saturday", value: false },
        { key: "Sunday", value: false },
      ],
      allTime: false,
      businessHours: false,
      timeZoneList: [],
      selectedBusinessHours: [],
      selectedTimeZone: "",
    };
  }

  /**
   * @method componentDidMount
   * @description called after render component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    const { weekDays } = this.state;
    const tempArray = [];

    Array.isArray(timezones) &&
      timezones.map((item, index) => {
        tempArray.push({ label: item, value: item });
      });
    this.setState({ timeZoneList: tempArray });
    const requestData = {
      npc_id: user_detail.newspaper.id,
    };
    this.props.getBussinessHours(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        if (res.data.daily == 1) {
          this.setState({ allTime: true });
        } else {
          this.setState({ allTime: false });
        }
        if (res.data && res.data.time_zone) {
          this.setState({ selectedTimeZone: res.data.time_zone });
        }
        if (
          res.data &&
          res.data.business_hour &&
          res.data.business_hour.length
        ) {
          this.setState(
            { selectedBusinessHours: res.data.business_hour },
            () => {
              Array.isArray(weekDays) &&
                weekDays.map((el, i) => {
                  let flag = res.data.business_hour.some(
                    (day) => day.day === weekDays[i].key
                  );
                  if (flag) {
                    let temp = weekDays;
                    temp[i].value = true;
                    this.setState({ weekDays: [...temp] });
                  }
                });
            }
          );
        }
      }
    });
  }

  compareTime = () => {
    let flag = "";
    const { selectedBusinessHours } = this.state;
    selectedBusinessHours.map((el, i) => {
      flag = el.open > el.close;
    });
    return flag;
  };

  /**
   * @method onSubmit
   * @description called api to state and area
   */
  onSubmit = (values) => {
    const { user_detail } = this.props.loggedInUser;
    const { allTime, selectedTimeZone, selectedBusinessHours } = this.state;
    //Validation
    let index = selectedBusinessHours.findIndex(
      (hour) => hour.open === undefined || hour.close === undefined
    );
    if (!allTime && index >= 0) {
      toastr.error(MESSAGES.TIME_FORMATE_ERROR_BUSSINESS_HOURS);
      return;
    }
    const { bussinessHoursDetail } = this.props;
    const requestData = {
      newspaperId: user_detail.newspaper.id,
      time_zone: selectedTimeZone,
      business_hour: allTime ? [] : selectedBusinessHours,
      daily: allTime ? true : false,
    };
    this.setState({ isSubmitted: false });
    // Create API Calling
    if (!allTime && selectedBusinessHours.length === 0) {
      toastr.warning(MESSAGES.SELECT_DAYS);
      return true;
    } else if (this.compareTime()) {
      toastr.warning(MESSAGES.TIME_MICMATCH);
      return true;
    } else if (bussinessHoursDetail && bussinessHoursDetail.id !== undefined) {
      requestData.id = bussinessHoursDetail.id;
      this.props.updateBussinessHours(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(MESSAGES.UPDATE_BUSSINESS_HOURS_SUCCESS);
        } else {
          this.setState({ isSubmitted: true });
        }
      });
    } else {
      // Update API Calling
      this.props.createBussinessHours(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(MESSAGES.ADD_BUSSINESS_HOURS_SUCCESS);
        } else {
          this.setState({ isSubmitted: true });
        }
      });
    }
  };

  /**
   * @method renderWorkingdays
   * @description render working day's within a week
   */
  renderWorkingdays = () => {
    const { selectedBusinessHours, weekDays } = this.state;
    let currentDay = [];
    return (
      Array.isArray(weekDays) &&
      weekDays.map((el, i) => {
        let selectedIndex = selectedBusinessHours.findIndex(
          (day) => day.day === weekDays[i].key
        );
        let selectedValue = selectedBusinessHours[selectedIndex];
        let defaultValueOpen = "00:00";
        let defaultValueClose = "00:00";

        if (selectedValue !== undefined) {
          defaultValueOpen = selectedValue.open;
        }
        if (selectedValue !== undefined) {
          defaultValueClose = selectedValue.close;
        }
        return (
          <Row key={i} className="my-5">
            <Col xs="4">
              <CheckboxRP label={el.key}>
                <Input
                  type="checkbox"
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  checked={el.value}
                  onChange={(e) => {
                    let weekDays = [...this.state.weekDays];
                    let day = { ...this.state.weekDays[i] };
                    day.value = !this.state.weekDays[i].value;
                    weekDays[i] = day;
                    if (day.value) {
                      currentDay.push({
                        i,
                        day: day.key,
                      });
                      this.setState({
                        weekDays,
                        selectedBusinessHours: [
                          ...selectedBusinessHours,
                          ...currentDay,
                        ],
                      });
                    } else {
                      let filteredArray = selectedBusinessHours.filter(
                        (e) => e.i !== i
                      );
                      this.setState({
                        weekDays,
                        selectedBusinessHours: [...filteredArray],
                      });
                    }
                  }}
                  name="allow_cheque"
                />
              </CheckboxRP>
            </Col>
            {el.value && (
              <Col xs="3" md="3">
                <input
                  className="w-100"
                  type="time"
                  id="appt"
                  name="appt"
                  defaultValue={defaultValueOpen}
                  onChange={(e) => {
                    let index = selectedBusinessHours.findIndex(
                      (x) => x.i === i
                    );
                    if (index >= 0) {
                      let temp = selectedBusinessHours;
                      temp[index].open = e.target.value;
                      this.setState({ selectedBusinessHours: [...temp] });
                    }
                  }}
                ></input>
              </Col>
            )}
            {el.value && (
              <Col xs="1" md="1" className="text-center">
                to
              </Col>
            )}
            {el.value && (
              <Col xs="3" md="3">
                <input
                  className="w-100"
                  type="time"
                  id="appt"
                  name="appt"
                  defaultValue={defaultValueClose}
                  onChange={(e) => {
                    let index = selectedBusinessHours.findIndex(
                      (x) => x.i === i
                    );
                    if (index >= 0) {
                      let temp = selectedBusinessHours;
                      temp[index].close = e.target.value;
                      this.setState({ selectedBusinessHours: [...temp] });
                    }
                  }}
                ></input>
              </Col>
            )}
          </Row>
        );
      })
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit,data } = this.props;
    const { selectedTimeZone, timeZoneList, allTime } = this.state;
    return (
      <div className="row my-5">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>Business Days & Hours</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div
                className="ml-3"
                style={{
                  marginTop: 25,
                  marginLeft: 2,
                }}
              >
                <form
                  noValidate
                  className="form"
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Row>
                    <Col md="4">
                      <Label className="mt-4">Time Zone</Label>
                    </Col>
                    <Col md="4">
                      <Field
                        name="time_zone"
                        value={selectedTimeZone}
                        validate={[selectRequired]}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={false}
                        onChange={(e) => {
                          this.setState({ selectedTimeZone: e.target.value });
                        }}
                        options={timeZoneList}
                        component={renderSelectField}
                        placeholder="Enter Distribution State"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Label>Business Days/Hours</Label>
                    </Col>
                    <Col md="8">
                      <div className="radio-inline">
                        <RadioButtonRP label="24 hrs * 7 days">
                          <Input
                            type="radio"
                            id="checkbox1"
                            style={{
                              width: "1.25rem",
                              height: "1.25rem",
                            }}
                            checked={allTime ? true : false}
                            onChange={(e) => {
                              this.setState({
                                allTime: true,
                                businessHours: false,
                                selectedBusinessHours: [],
                              });
                            }}
                            name="allow_cheque"
                          />
                        </RadioButtonRP>
                        <RadioButtonRP label="Working Days/Hours">
                          <Input
                            type="radio"
                            id="checkbox1"
                            style={{
                              width: "1.25rem",
                              height: "1.25rem",
                            }}
                            checked={!allTime ? true : false}
                            onChange={(e) => {
                              this.setState({
                                allTime: false,
                                businessHours: true,
                              });
                            }}
                            name="allow_cheque"
                          />
                        </RadioButtonRP>
                      </div>
                    </Col>
                    
                  </Row>
                  {!allTime && (
                    <Row>
                      <Col md="4"></Col>
                      <Col md="8">{this.renderWorkingdays()}</Col>
                    </Row>
                  )}

                  <Row className="float-right">
                    <Button
                      style={{
                        background: "black",
                        border: "none",
                        width: 100,
                        marginTop: 25,
                      }}
                      size="md"
                      type="submit"
                      disabled={data.validImage ? false : true}
                    >
                      Save
                    </Button>
                  </Row>
                </form>
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, authToken, bussinessHoursDuck } = store;
  const { bussinessHoursDetail } = bussinessHoursDuck;
  let initialValues = {};
  if (bussinessHoursDetail && bussinessHoursDetail !== undefined) {
    initialValues = {
      time_zone: bussinessHoursDetail.time_zone,
    };
  }
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    initialValues,
    bussinessHoursDetail,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    bussinessHoursDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "BusinessHours",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(BusinessHours)
  )
);
