import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    AllAdTypeList: "[AllAdTypeList] Action",
    AllAccountList:"[AllAccountList] Action",
    AllColorList: "[AllColorList] Action",
    AllPaperList: "[AllPaperList] Action",
    SetCategoryList: "[SetCategoryList] Action",
    SetSelectedTemplates: "[SetSelectedTemplates] Action",
    SetSelectedAdType: "[SetSelectedAdType] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    colorList: [],
    PapertypeList:[],
    adTypeList: [],
    categoryList: [],
    templateList: [],
    accountList:[],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {


            /** get all records of adtype  list */
            case actionTypes.AllAdTypeList: {
                const { adTypeList } = action.payload;
                return { ...state, adTypeList: adTypeList };
            }

            /** get all records of admin list */
            case actionTypes.AllColorList: {
                const { colorList } = action.payload;
                return { ...state, colorList: colorList };
            }
            case actionTypes.AllPaperList: {
                const { PapertypeList } = action.payload;
                return { ...state, PapertypeList: PapertypeList };
            }
            case actionTypes.AllAccountList: {
                const { accountList } = action.payload;
                return { ...state, accountList: accountList };
            }

            /** set data for customer List */
            case actionTypes.SetCategoryList: {
                const { categoryList } = action.payload;
                return { ...state, categoryList: [...categoryList] };
            }

            /** set data for customer List */
            case actionTypes.SetSelectedTemplates: {
                const { templateList } = action.payload;
                return { ...state, selectedTemplates: [...templateList] };
            }

            /** get data of individualad type detail by id */
            case actionTypes.SetSelectedAdType: {
                const { selectedAdType } = action.payload;
                return { ...state, selectedAdType: selectedAdType };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getAllAdType
    * @description Update admin details 
    */
    getAllAdType: (id, callback) => {
        const request = axios.get(`${API.generalAPI}/advertisement?id=${""}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.AllAdTypeList, payload: { adTypeList: response.data } })

            }).catch((error) => {
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },

    /**
      * @method getAllAdType
      * @description Update admin details
      */
    getAllColor: (callback) => {
        const request = axios.get(`${API.generalAPI}/color?id=0`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.AllColorList, payload: { colorList: response.data } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        }
    },
    getAllAccount: (callback) => {
        const request = axios.get(`${API.generalAPI}/account?id=0`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.AllAccountList, payload: { accountList: response.data } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        }
    },

    getAllPaper: (callback) => {
        const request = axios.get(`${API.generalAPI}/papertype?id=0`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.AllPaperList, payload: { PapertypeList: response.data } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        }
    },

    /**
    * @method getCustomerListing
    * @description get all records based on advance search
    */
    getAllCategoryListing: (callback) => {
        const request = axios.get(`${API.generalAPI}/ad_type`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetCategoryList, payload: { categoryList: response.data } })

            }).catch((error) => {
                callback(error)
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    /**
 * @method getCustomerListing
 * @description get all records based on advance search
 */
    getTemplatesOfCategory: (reqData, callback) => {
        const request = axios.get(`${API.getClassifiedTemplate}?ad_type_id=${reqData.catId}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetSelectedTemplates, payload: { templateList: response.data } })

            }).catch((error) => {
                callback(error)
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    /**
     * @method addAdType
     * @description Add Ad type
     */
    addAdType: (requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/advertisement`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                callback(error);
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
  * @method createTemplate
  * @description get all records based on advance search
  */
    createTemplate: (requestData, callback) => {
        let api_call = `${API.genericAPI}/classified_template`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
               // dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
    * @method createCustomer
    * @description get all records based on advance search
    */
    createCategory: (requestData, callback) => {
        let api_call = `${API.generalAPI}/ad_type`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
               // dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
    * @method updateAdType
    * @description Add Ad type
    */
    updateAdType: (requestData, callback) => {
        const request = axios.put(`${API.generalAPI}/advertisement`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
     * @method updateTemplateById
     * @description update template 
     */
    updateTemplateById: (reqData, callback) => {
        let api_call = `${API.getClassifiedTemplate}`
        const request = axios.put(api_call, reqData, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
     * @method deleteTemplateById
     * @description delete template type by id
     */
    deleteTemplateById: (templateId, callback) => {
        const request = axios.delete(`${API.genericAPI}/classified_template?id=${templateId}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },


    /**
    * @method deleteCategoryById
    * @description delete category by id
    */
    deleteCategoryById: (Id, callback) => {
        const request = axios.delete(`${API.generalAPI}/ad_type?id=${Id}&active=true`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
   * @method addColorAPI
     * @description Add color api
     */
    addColorAPI: (requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/color`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
    addAccountAPI: (requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/account`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },


    addPaperTypeAPI: (requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/papertype`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },


    /**
    * @method deleteColorById
    * @description delete color by id
    */
    deleteColorById: (colorId, callback) => {
        const request = axios.delete(`${API.genericAPI}/color?id=${colorId}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },
    deleteAccountById: (colorId, callback) => {
        const request = axios.delete(`${API.genericAPI}/account?id=${colorId}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },
    deletePaperById: (colorId, callback) => {
        const request = axios.delete(`${API.genericAPI}/paper_type?id=${colorId}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
     * @method deleteAdTypeById
     * @description delete ad type by id
     */
    deleteAdTypeById: (reqData, callback) => {
        const { user_id, advertisement_id, name, id } = reqData;
        //API changes
        const request = axios.delete(`${API.generalAPI}/advertisement?id=${id}&user_id=${user_id}&name=${name}&advertisement_id=${advertisement_id}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },
    /**
     * @method getCompanyDetailById
     * @description Update admin details 
     */
    getAdTypeDetailById: (adTypeId, authToken, callback) => {
        const request = axios.get(`${API.genericAdTypeAPI}?id=${adTypeId}&&is_deleted=${0}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                //dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetSelectedAdType, payload: { selectedAdType: response.data.result } })
            }).catch((error) => {
                apiErrors(error, dispatch)
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





