import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Image } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import StandardTable from "../../StandardTable";
import { convertISOToUtcDate } from "../../../../common";
import { MESSAGES } from "../../../../config/message";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { langs } from "../../../../config/localization";
import { GrantIconButton } from "../../../../common/Buttons";

/**
 * @method columnOrder
 * @description define the table columns order and format data accordingly
 * @param {*} activatePlan
 */
const columnOrder = (activatePlan) => [
  // {
  //   Header: "Plan Image",
  //   id: "plan_image",
  //   //width: 90,
  //   sortable: false,
  //   Cell: (row) => {
  //     const { original } = row;
  //     const { advertisements } = original;
  //     let image = "";
  //     // extract plan image
  //     if (advertisements) {
  //       Array.isArray(advertisements) &&
  //         advertisements.map((ad) => {
  //           if (ad.npc_advertisements && ad.npc_advertisements.length > 0) {
  //             const npcAd = ad.npc_advertisements[0];
  //             if (
  //               parseInt(npcAd.newspaperId) === parseInt(original.npc_id) &&
  //               parseInt(npcAd.advertisementId) === parseInt(original.ad_type)
  //             ) {
  //               image = JSON.parse(npcAd.ad_urls)[0];
  //             }
  //           }
  //         });
  //     }
  //     return (
  //       <div>
  //         <Image
  //           className="ml-2 mr-2"
  //           src={
  //             image
  //               ? `${PROFILE_MEDIA_URL}/${image}`
  //               : "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
  //           }
  //           style={{ borderRadius: "100%", height: 35, width: 35 }}
  //         />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   Header: "Plan Name",
  //   //width: 150,
  //   accessor: "plan_name",
  //   Cell: (row) => {
  //     const { original } = row;
  //     return (
  //       <span>
  //         {original && original.plan_name !== null ? original.plan_name : ""}
  //       </span>
  //     );
  //   },
  // },
  {
    Header: "Ad Type",
    //width: 100,
    id: "ad_type",
    accessor: (d) => {
      const { advertisements } = d;
      let advertisement = {};
      // extract advertisement type
      if (advertisements) {
        Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            if (parseInt(ad.id) === parseInt(d.ad_type)) {
              advertisement = ad;
            }
          });
      }
      return <div>{advertisement ? advertisement.ad_name : ""}</div>;
    },
  },
  {
    Header: "Price",
    accessor: "deadline_price",
    //width: 60,
    Cell: (row) => {
      const { original } = row;
      const { deadline_price } = original;
      return <span>{deadline_price ? `$${deadline_price}` : ""}</span>;
    },
  },
  {
    Header: "Ad Size",
    id: "width",
    accessor: (d) => (d.width && d.height ? `${d.width}*${d.height}` : ""),
    //width: 80,
  },
  {
    Header: "Date of Deletion",
    //width: 150,
    id: "updatedAt",
    accessor: (d) => d.updatedAt,
    Cell: (row) => {
      const { original } = row;
      return <span>{convertISOToUtcDate(original.updatedAt)}</span>;
    },
  },
  {
    Header: "Discount Available",
    id: "discount",
    accessor: "discount",
    //width: 100,
    Cell: (row) => {
      const { original } = row;
      return original.discount ? "Yes" : "No";
    },
  },
  {
    Header: "Status",
    accessor: "active",
    Cell: (row) => {
      return <span style={{ color: "red" }}>De-activated</span>;
    },
  },
  {
    Header: "Action",
    sortable: false,
    id: "action",
    tdProps: () => {
      return {
        style: {
          whiteSpace: "nowrap",
        },
      };
    },
    Cell: (row) => (
      <div>
        <GrantIconButton
          title="Activate"
          onClick={() => activatePlan(row.original)}
        />
      </div>
    ),
  },
];

const ArchiveOutOfHome = ({
  loggedInUser,
  searchResults,
  /** actions */
  getArchiveOutOfHome,
  activatePlanById,
  searchInPlansList,
}) => {
  // extract logged in user details
  const { user_detail } = loggedInUser;

  // basic search key input state
  const [searchKey, setSearchKey] = useState("");

  // pagination states
  const [pageSize, setPageSize] = useState(langs.paginationKeys.pageSize);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(100);

  // total plans state
  const [plansList, setPlansList] = useState([]);

  // sorting order state true===ASC and false===DESC
  const [sortedOrder, setSortedOrder] = useState(true);

  /**
   * @method fetchPlansList
   * @description fetch archive plans list
   * @param {*} row
   * @param {*} page
   */
  const fetchPlansList = (row, page, sortBy = "ASC", column = "") => {
    // request data
    const requestData = {
      id: "",
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : langs.paginationKeys.pageSize,
      page: page !== undefined ? page : 1,
      isDeleted: 1,
      sort: sortBy,
      column: column,
    };

    // fetch archive plans list action
    getArchiveOutOfHome(requestData, (res) => {
      if (res && String(res.status) === String(STATUS_CODES.OK)) {
        let pages = res.data.count / pageSize;
        let plans = res.data.rows;

        // set plans list state
        setPlansList(plans);
        // set total page count state
        setTotalCount(Math.ceil(pages));
      }
    });
  };

  /**
   * @method onPageChange
   * @description used for handle pagination
   */
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  /**
   * @method onPageSizeChange
   * @description used for handle pagination
   */
  const onPageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  /**
   * @method handelSearch
   * @description handle search
   * @param {*} e
   */
  const handleSearch = (e) => {
    const key = e.target.value;
    if (!key || key.trim() === "" || key.length <= 2) {
      setSearchKey("");
    } else if (key.trim().length >= 3) {
      // generate search request only on hit of more than 2 characters
      setSearchKey(key);

      // search request data
      const requestData = {
        plan_name: encodeURIComponent(
          key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 1,
        npc_id: user_detail.newspaper.id,
        row: pageSize !== undefined ? pageSize : langs.paginationKeys.pageSize,
        page: page !== undefined ? page : 1,
      };

      // basic search action
      searchInPlansList(requestData);
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  const handleSort = (e, { sorted }, rowInfo, column, instance) => {
    // prevent sort with plan image and action
    if (column.id === "action" || column.id === "plan_image") {
      return;
    }
    instance.sortColumn(column);
    let sortBy = sortedOrder ? "ASC" : "DESC";
    setSortedOrder(!sortedOrder);
    fetchPlansList(pageSize, page, sortBy, column.id);
  };

  /**
   * @method activatePlan
   * @description handle activation of a plan
   * @param {*} plan
   */
  const activatePlan = (plan) => {
    // activation action
    activatePlanById(plan.id, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        // re-fetching entire data
        fetchPlansList(pageSize, page);
        toastr.success("Success", MESSAGES.PLAN_ACTIVE_SUCCESS);

        if (searchKey.trim() !== "" && searchKey.trim().length >= 3) {
          // if data is filtered then again filter it with the search key
          // search request data
          const requestData = {
            plan_name: encodeURIComponent(
              searchKey.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            ),
            is_deleted: 1,
            npc_id: user_detail.newspaper.id,
            row:
              pageSize !== undefined ? pageSize : langs.paginationKeys.pageSize,
            page: page !== undefined ? page : 1,
          };

          // basic search action
          searchInPlansList(requestData);
        }
      }
    });
  };

  /**
   * runs on change in dependencies
   */
  useEffect(() => {
    fetchPlansList(pageSize, page);
  }, [pageSize, page]);

  /**
   * run on mount to fetch all the archive plans list
   */
  useEffect(() => {
    fetchPlansList();
  }, []);

  /**
   * If there is search result show it otherwise show all data
   */
  let results;
  if (searchResults && searchResults.length && searchKey.trim() !== "") {
    results = searchResults;
  } else if (searchResults === undefined) {
    results = [];
  } else if (searchResults.length === 0 && searchKey.trim() !== "") {
    results = [];
  } else {
    results = plansList;
  }

  return (
    <div>
      <StandardTable
        handelSearch={handleSearch}
        columnOrder={columnOrder(activatePlan)}
        totalCount={totalCount}
        allData={plansList}
        data={results}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        handleSort={handleSort}
      />
    </div>
  );
};

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 */
const mapStateToProps = (state) => {
  const { auth, npcDuck } = state;
  return {
    loggedInUser: auth.user,
    searchResults: npcDuck.searchResultArchive
      ? npcDuck.searchResultArchive
      : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
  })(ArchiveOutOfHome)
);
