import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from "axios";
import { API } from "../../../config";
import { apiErrors } from "../../../common/HandleAPIErrors";

export const actionTypes = {
  GetActiveSubscriptions: "[GetActiveSubscriptions] Action",
  GetActiveSubscriptionsDetails: "[GetActiveSubscriptionsDetails] Action",
  GetActiveSubscriptionsInvoices: "[GetActiveSubscriptionsInvoices] Action",
  SubscriptionInvoiceDownload: "[SubscriptionInvoiceDownload] Action",
  SubscriptionStatusCheck: "[SubscriptionStatusCheck] Action",
  AgentLoginFromAdmin: "[AgentLoginFromAdmin] Action",
};

const headers = {
  "Content-Type": "application/json",
};

const initialAuthState = {
  profile: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.GetActiveSubscriptions: {
        const { allActiveSubscriptions } = action.payload;
        return { ...state, allActiveSubscriptions: allActiveSubscriptions };
      }

      case actionTypes.GetActiveSubscriptionsDetails: {
        const { allActiveSubscriptionsDetails } = action.payload;
        return {
          ...state,
          allActiveSubscriptionsDetails: allActiveSubscriptionsDetails,
        };
      }

      case actionTypes.GetActiveSubscriptionsInvoices: {
        const { allActiveSubscriptionsInvoices } = action.payload;
        return {
          ...state,
          allActiveSubscriptionsInvoices: allActiveSubscriptionsInvoices,
        };
      }

      case actionTypes.SubscriptionInvoiceDownload: {
        const { subscriptionInvoiceDownload } = action.payload;
        return {
          ...state,
          subscriptionInvoiceDownload: subscriptionInvoiceDownload,
        };
      }
        
      case actionTypes.SubscriptionStatusCheck: {
        const { subscriptionStatusCheck } = action.payload;
        return {
          ...state,
          subscriptionStatusCheck: subscriptionStatusCheck,
        };
      }

      case actionTypes.AgentLoginFromAdmin: {
        const { agentLoginFromAdmin } = action.payload;
        return {
          ...state,
          agentLoginFromAdmin: agentLoginFromAdmin,
        };
      }

      /** set default state */
      default:
        return state;
    }
  }
);

/** Actions creator */
export const actions = {
  getActiveSubscriptions: (id, callback) => {
    let request = axios.get(
      `${API.getActiveSubscriptions}?subscriptionId=${id}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      request
        .then((response) => {
          callback(response.data);
          return dispatch({
            type: actionTypes.GetActiveSubscriptions,
            payload: { allActiveSubscriptions: response.data },
          });
        })
        .catch((err) => {
          apiErrors(err, dispatch);
        });
    };
  },
  getActiveSubscriptionsDetails: (id, subscriptionId, callback) => {
    let request = axios.get(
      `${API.getActiveSubscriptionsDetails}?id=${id}&subscriptionId=${subscriptionId}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      request
        .then((response) => {
          callback(response.data);
          return dispatch({
            type: actionTypes.GetActiveSubscriptionsDetails,
            payload: { allActiveSubscriptionsDetails: response.data },
          });
        })
        .catch((err) => {
          apiErrors(err, dispatch);
        });
    };
  },
  getActiveSubscriptionsInvoices: (id, callback) => {
    let request = axios.get(`${API.getActiveSubscriptionsInvoices}?id=${id}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response.data);
          return dispatch({
            type: actionTypes.GetActiveSubscriptionsInvoices,
            payload: { allActiveSubscriptionsInvoices: response.data },
          });
        })
        .catch((err) => {
          apiErrors(err, dispatch);
        });
    };
  },
  subscriptionInvoiceDownload: (id, callback) => {
    let request = axios.get(`${API.subscriptionInvoiceDownload}?id=${id}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response.data);
          return dispatch({
            type: actionTypes.SubscriptionInvoiceDownload,
            payload: { subscriptionInvoiceDownload: response.data },
          });
        })
        .catch((err) => {
          apiErrors(err, dispatch);
        });
    };
  },
  subscriptionStatusCheck: (id, callback) => {
    let request = axios.get(`${API.subscriptionStatusOfAgent}?id=${id}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response.data);
          return dispatch({
            type: actionTypes.SubscriptionStatusCheck,
            payload: { subscriptionStatusCheck: response.data },
          });
        })
        .catch((err) => {
          apiErrors(err, dispatch);
        });
    };
  },
  agentLoginFromAdmin: (id, callback) => {
    const param = {id:id};
    let request = axios.post(`${API.agentLoginFromAdmin}`,param, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response.data);
          return dispatch({
            type: actionTypes.SubscriptionStatusCheck,
            payload: { AgentLoginFromAdmin: response.data },
          });
        })
        .catch((err) => {
          apiErrors(err, dispatch);
        });
    };
  },
};

export function* saga() {
  yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {});
}
