import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { LayoutContextConsumer } from '../../../../../../_metronic/layout/LayoutContext';
import { Portlet, PortletBody } from '../../../../../partials/content/Portlet';
import * as npcDuck from '../../../../../store/ducks/npc.duck';
import { injectIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import { MESSAGES } from '../../../../../config/message';
import '../../../super-admin/ad-management/AdManagement.scss';
import AddTypeModel from './AddTypeModel';
import ConformModel from '../ConfirmModel';
import UpdateAdType from './UpdateAdType';
import { toastr } from 'react-redux-toastr';
import { STATUS_CODES } from '../../../../../common/StatusCode';

class ManageAddType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: '',
      adTypeFlag: false,
      colorFlag: false,
      color_name: '',
      newsPaperAdTypeList: []
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.apiResponse();
  }

  /**
   * @method apiResponse
   * @description called to get data from api
   */
  apiResponse = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getAllAdType(user_detail.newspaper.id, res => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.rows) &&
          this.setState({
            newsPaperAdTypeList: res.data.rows[0].advertisements
        
          });
      }
      //console.warn("modal; api respose",res.data.rows[0].advertisements)
    });
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { user_detail } = this.props.loggedInUser;
    const deletedItem = this.state.deletedItem;   
    this.props.deleteAdTypeById(user_detail.newspaper.id, deletedItem, res => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_ADD_TYPE_SUCCESS);
        this.setState({
          isSubmitted: false,
          showConfirmModal: false
        });
        this.apiResponse();
      } else {
        this.setState({ showConfirmModal: false });
      }
    });
  };

  /**
   * @method setData
   * @description set selected item detail
   */
  setData = data => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getAdTypeById(data.id, user_detail.newspaper.id, res => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ itemDetail: res.data });
      }
    });
    this.setState({
      isUpdateModel: true,
      showConfirmModal: false,
      openModel: false,
      selectedItem: data
    });
  };

  /**
   * @method renderCardItem
   * @description render adtype card item
   */
  renderCardItem = typeOfListing => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            key={i}
            bg='light'
            style={{
              width: 'auto',
              height: '3.5rem',
              marginRight: 10,
              marginBottom: 10
            }}
          >
            <Card.Body>
              <Card.Title>
                <label onClick={() => this.setData(data)}>{data.ad_name}</label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                <i
                  className='fa fa-close crossIcon'
                  onClick={() =>
                    this.setState({
                      showConfirmModal: true,
                      openModel: false,
                      isUpdateModel: false,
                      deletedItem: data.npc_advertisement.id,
                      message: MESSAGES.AD_TYPE_DELETE
                    })
                  }
                ></i>
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };

  getValue = () => {
    const { itemDetail, selectedItem, newsPaperAdTypeList } = this.state;
    return { itemDetail, selectedItem, newsPaperAdTypeList };
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      newsPaperAdTypeList,
      itemDetail,
      openModel,
      selectedItem,
      isUpdateModel,
      showConfirmModal
    } = this.state;
    return (
      <div className="row my-5">
        {/* <div className='col-md-12'>
          <LayoutContextConsumer className='row'>
            {({ subheader: { title } }) => (
              <div className='row'>
                <h5 className='kt-subheader__title col-md-6'>
                  <span>Preview Ad Type</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className='cardWrapper'>
                {this.renderCardItem(newsPaperAdTypeList)}
              </div>
              <div
                className='row'
                style={{
                  borderBottom: '1px solid #ccc',
                  marginTop: 25,
                  marginLeft: 2
                }}
              >
                <label
                  style={{ color: 'black', marginTop: 25, cursor: 'pointer' }}
                  onClick={() => this.setState({ openModel: true })}
                >
                  <i
                    className='fa fa-plus'
                    aria-hidden='true'
                    onClick={() => this.setState({ openModel: true })}
                  ></i>
                  &nbsp;&nbsp;Add More
                </label>
                <hr />
              </div>
            </PortletBody>
          </Portlet>
          {newsPaperAdTypeList !== undefined && openModel && (
            <AddTypeModel
              isOpen={openModel}
              onCancel={() => this.setState({ openModel: false })}
              adTypeList={this.props.adTypeList}
              adTypeId={selectedItem}
              callList={this.apiResponse}
              getValues={this.getValue}
            />
          )}
          { isUpdateModel &&  (
            <UpdateAdType
              isOpen={isUpdateModel}
              onCancel={() => this.setState({ isUpdateModel: false })}
              selectedItem={selectedItem}
              adTypeList={this.props.adTypeList}
              callList={this.apiResponse}
              itemDetail={itemDetail}
              getValues={this.getValue}
            />
          )}
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            keyname={'Ad-Type'}
          />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, npcDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    adTypeList: Array.isArray(npcDuck.adTypeList)
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    npcDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'ManageAddType'
    })(ManageAddType)
  )
);
