import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { DATE_RANGE } from "../../../../../config/constant";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import StatementTable from "./StatementTable";
import { PROFILE_MEDIA_URL } from "../../../../../config";

class StatementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      duration: DATE_RANGE,
      defaultStatementId: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getTransactionList();
  }

  /**
   * @method componentWillReceiveProps
   * @description receive props from component
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.statementId !== nextProps.statementId) {
      this.getTransactionList();
    }
  }

  /**
   * @method getTransactionList
   * @description  render transaction list
   */
  getTransactionList = () => {
    let requestData = {
      data: "",
      type: 3,
      row: "",
      page: "",
      sort: "",
      column: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      search: "",
      statement: "",
    };
    this.setData(requestData);
  };

  /**
   * @method setData
   * @description  set requestdata
   */
  setData = (requestData) => {
    this.props.getPayoutSummaryListing(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState(
          { defaultStatementId: res.data.dataGrid[0].statement_id },
          () => this.getPayoutRecords(this.state.defaultStatementId)
        );
      }
    });
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (id) => {
    const { statementId } = this.props;
    let requestData = {
      data: "",
      type: 4,
      row: "",
      page: "",
      sort: "",
      column: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      search: "",
      statement: statementId !== undefined ? statementId : id,
    };
    this.props.getPayoutSummaryDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        //const  npc_id = res.data.statementHeader[0].newspaper_id;
        const npc_id = res.data.statementHeader[0].id;
        this.getStatementList(id, npc_id);
      }
    });
  };

  /**
   * @method getStatementList
   * @description  used to get all order  statement listing
   */
  getStatementList = (id, npcId) => {
    let requestData = {
      npc_id: npcId,
      row: 25,
      page: 1,
      sort: "",
      column: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      type: 5,
      statement: id,
    };

    this.props.getStatementDetail(requestData, (res) => {});
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { statementData, orderData } = this.props;
    const imageURL =
      statementData.sample_newspaper &&
      statementData.sample_newspaper.sample_newspaper;
    return (
      <div
        className="column"
        style={{ margin: 5, height: "auto" }}
      >
        <div style={{ border: "1px solid black", width: "auto", padding: 20 }}>
          <div className="row">
            <div className=" col-md-6 mb-3">
              <div className="row">
                <div className="col-3">
                  <img
                    src={
                      imageURL
                        ? `${PROFILE_MEDIA_URL}/${imageURL}`
                        : require("../../../../../../assets/images/defaultUser.png")
                    }
                    alt=""
                    style={{
                      border: "1px solid",
                      borderRadius: 100,
                      height: 40,
                      width: 40,
                    }}
                  />
                </div>
                <div className="col-9" style={{ textAlign: "initial" }}>
                  <h6>{statementData.newspaper_name}</h6>
                  <h6 className="text-truncate">
                    {statementData.first_name &&
                      statementData.last_name &&
                      statementData.first_name + " " + statementData.last_name}
                  </h6>
                  <h6>{statementData.name}</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-6 col-md-12 d-flex-center">
                  <h6>Statement Id</h6>
                  <h6 className="ml-3">
                    {statementData.statement_id}
                  </h6>
                </div>
                <div className="col-6 col-md-12 d-flex-center">
                  <h6>Payout</h6>
                  <h6 className="ml-3">
                    {statementData.payout && `$${statementData.payout}`}
                  </h6>
                </div>
              </div>            
            </div>
          </div>
          <div
            className="row p-2"
            style={{ borderTop: "1px solid", borderBottom: "1px solid" }}
          >
            <div className="col-md-6">
              <div className="row">
                <h6 className="col-3">Period</h6>
                <h6 className="col-9">
                  {statementData.min_date &&
                    statementData.max_date &&
                    `${statementData.min_date} to ${statementData.max_date}`}
                </h6>
              </div>
              <div className="row">
                <h6 className="col-6" style={{ textAlign: "initial" }}>
                  Total Orders
                </h6>
                <h6 className="col-6" style={{ textAlign: "initial" }}>
                  {statementData.total_orders &&
                    `${statementData.total_orders}`}
                </h6>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <h6 className="col-6">Total Sales</h6>
                <h6 className="col-6">
                  {statementData.sales && `$${statementData.sales}`}
                </h6>
              </div>
              <div className="row">
                <h6 className="col-6">Total Fees</h6>
                <h6 className="col-6">
                  {statementData.fees && `$${statementData.fees}`}
                </h6>
              </div>
            </div>
          </div>
          <div>
            {orderData && <StatementTable orderData={orderData.rows} />}
          </div>
          <div style={{ marginBottom: 20 }}>
            {statementData.statement_id && (
              <Link
                to={`/open-statement/${statementData.statement_id}`}
                target="blank"
                className="float-right"
                style={{
                  borderBottom: "1px solid",
                }}
              >
                Open Statement
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { payoutSummaryDuck, paymentReportDuck } = store;
  const { statementDeta } = payoutSummaryDuck;
  const { statementDetail } = paymentReportDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    statementData:
      statementDeta !== undefined &&
      Array.isArray(statementDeta.statementHeader)
        ? statementDeta.statementHeader[0]
        : [],
    orderData: statementDetail !== undefined ? statementDetail.orderData : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...payoutSummaryDuck.actions,
    ...paymentReportDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "StatementList",
    })(StatementList)
  )
);
