import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

export default class SendEmailList extends Component {
    render() {
        const { data } = this.props;
        return (
            <Portlet fluidHeight={true} style={{ height: "auto" }}>
                <PortletBody>
                    <div
                        style={{
                            borderBottom: "1px solid #ccc"
                        }}
                        className="row payoutHeader"
                    >
                        <h4 className="col-md-8">Send Email List</h4>
                        <Link to="/UpdateSendEmailList" className="col-md-4">
                            <h6 className="text-md-right">Update Send Email List &#62; </h6>
                        </Link>
                    </div>
                    <div
                        className="row ml-3"
                        style={{
                            marginTop: 25,
                        }}
                    >
                        <i
                            style={{ fontSize: 40 }}
                            className="fa fa-university mr-4"
                            aria-hidden="true"
                        ></i>
                        {data !== undefined &&
                            data.SMTP_SERVICE !== "" ? (
                            <div
                                className="text-break"
                                style={{ marginTop: 4 }}
                            >
                                <h6>{"FROM EMAIL:  "}{data.FORMEMAIL}</h6>
                                <h6>{"SUBJECT:  "}{data.SUBJECT}</h6>
                                <h6>{"TO MAIL:  "}{data.TOMAIL}</h6>
                                <h6>{"BCC:  "}{data.BCC}</h6>
                                <h6>{"ALTERNATE BCC:  "}{data.ALTERNATE_BCC}</h6>
                                <h6>{"TEST MAIL:  "}{data.TEST_MAIL}</h6>
                                <h6>{"ADMIN MAIL:  "}{data.ADMIN_MAIL}</h6>
                            </div>
                        ) : (
                            <h6 style={{ margin: 10 }}>No Send Email record found</h6>
                        )}
                    </div>
                </PortletBody>
            </Portlet>
        )
    }
}