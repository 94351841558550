import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { toastr } from "react-redux-toastr";
import { Form, Col, Button } from "react-bootstrap";
import { Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import NavigationPrompt from "react-router-navigation-prompt";
import NavigateModal from "../../NavigateModal";
import { Label, Input } from "reactstrap";
import {
    renderThemeSelectOptions,
    renderThemeToggleButton,
} from "../../FormLayout";
import {
    renderText,
    renderFileInputField,
    renderNumberInputField,
    renderSelectField,
    focusOnError,
} from "../../../../common/layout/FormInput";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import { injectIntl } from "react-intl";
import {
    required,
    PhoneNumber,
    maxLength50,
    minLength5,
    maxLength100,
    maxLengthC100,
    minLength2,
    maxLength5,
    email,
    selectRequired,
    normalizePhone,
} from "../../../../config/validation";
import { MESSAGES } from "../../../../config/message";
import { langs } from "../../../../config/localization";
import "../../Modal.scss";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";
import {
    phoneNumberFormate,
    phoneNumberFormateOnChange,
} from "../../../../common";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";

import axios from 'axios';
import { any } from "prop-types";
class AddMultipleCompany extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            uniquesState: [],
            newspaper_id: "",
            sendEmail: false,
            status: false,
            success: false,
            error: false,
            isRedirect: false,
            states: [],
            isEditCompanyFlag: false,
            message: "",
            loading: false,
            isSubmitted: true,
            resposeData  : [],
        };
    }

    /**
     * @method componentDidMount
     * @description called after render the component
     */
    componentDidMount() {
        this.props.getfromNPCSetting("distribution_area_count", 0, (res) => {
            if (Array.isArray(res.data)) {
              const unique = [
                ...new Set(res.data.map((item) => item["us_states.state_name"])),
              ];
              let uniqueArray = [];
              unique.map((el, i) => {
                uniqueArray.push({ id: i, lable: el });
              });
              this.setState({ uniquesState: uniqueArray });
            }
          });
    }


    /**
     * Submit the login form
     * @param values
     * @desc Calling a login actions && redirecting page based on response and set session Items
     */
    onSubmit(values) {
       
        this.setState({ isSubmitted: true });
        var file = this.fileInput.current.files[0];
        const formData = new FormData();
        formData.append("csvFile", file);
        this.props.addMultipleNewCompany(formData, (res) => {
            
            //console.warn("request data", res)
            //this.props.onCancel();
            if (res.status === STATUS_CODES.OK) {
              this.setState({
                isSubmitted: true,
                resposeData:res.data
              });

             // toastr.success(MESSAGES.COMPANY_ADD_SUCCESS);
            //   setTimeout(() => {
            //     this.props.onCancel();
            //   }, 5000);
            } else {
            //   this.setState({
            //     isSubmitted: true,
            //   });
              //this.props.onCancel();
              toastr.success(MESSAGES.SERVER_ERROR);
            }
        });
    }

    /**
     * @method onChangeDetailHandeler
     * @description called for handling prompt
     */
    onChangeDetailHandeler = (e, type) => {
        this.setState({
            isEditCompanyFlag: true,
            isEditFlag: true,
            number: type === "number" ? e.target.value : this.state.number,
        });
        this.stateHandeler(e);
    };

    /**
     * @method onChangeStateHandeler
     * @description called to handle state chage
     */
    onChangeStateHandeler = (e, type) => {
        if (String(e.target.value) === "Local") {
            this.setState({
                showStateSelection: true,
                selectedState: e.target.value,
                isEditCompanyFlag: true,
            });
        } else {
            this.setState({
                showStateSelection: false,
                isEditCompanyFlag: true,
            });
        }
    };

    /**
     * @method stateHandeler
     * @description called to render list of state
     */
    stateHandeler = (e) => {
        if (e.target.value !== "") {
            this.props.getDistributionAreaFromState(String(e.target.value), (res) => {
                if (res.data) {
                    this.setState({
                        areasByState: res.data,
                        selectedState: e.target.value,
                        isSubmitted: true,
                        isEditCompanyFlag: true,
                    });
                }
            });
        } else if (e.target.value === "") {
            this.setState({ areasByState: [], selectedState: undefined });
        }
    };


    /**
     * @method handleToggleChange
     * @description used to handle toggle
     */
    handleToggleChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked, isEditCompanyFlag: true });
    };

    /**
     * @method onChangeTypeHandeler
     * @description handle distribution type change
     */
    onChangeTypeHandeler = (e, type) => {
        if (String(e.target.value) === "Local") {
            this.setState({
                showStateSelection: true,
                //selectedState: e.target.value,
                isEditCompanyFlag: true,
            });
        } else {
            this.setState({
                showStateSelection: false,
                isEditCompanyFlag: true,
                area1: undefined,
                area2: undefined,
                area3: undefined,
                selectedState: undefined,
            });
        }
    };

    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleModel = () => {
        this.props.onCancel();
    };
    /**
     * @method render
     * @description  used to render component
     */
    render() {
        const { handleSubmit, rangeList, loading } = this.props;
        const {
            uniquesState,
            number,
            billingCity,
            billingState,
            distributionType,
            showStateSelection,
            selectedState,
            area1,
            area2,
            area3,
            isRedirect,
            isEditCompanyFlag,
            sendEmail,
            selectedCity,
            status,
            isSubmitted,
            resposeData
        } = this.state;
        if (isRedirect) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: "/adminManagement",
                    }}
                />
            );
        }
        const { isEditFlag } = this.props;
        return (
            <Container className="top-margin">
                {isEditFlag && (
                    <NavigationPrompt when={isEditCompanyFlag}>
                        {(props) => {
                            const { onCancel } = props;
                            return (
                                <NavigateModal
                                    show={true}
                                    alertHeading={"Changes made will be lost."}
                                    cancleButtonText="Cancel"
                                    okButtonText="Leave Anyway"
                                    showCancel={true}
                                    onOkClick={() => this.props.onCancel()}
                                    onClickCancel={() => {
                                        onCancel();
                                    }}
                                    contentClassName="modalShadow"
                                />
                            );
                        }}
                    </NavigationPrompt>
                )}
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModel}
                    styles={{ height: 10 }}
                    size="lg"
                >
                    <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
                        <h5 className="kt-portlet__head-title">Add Multiple Company</h5>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            {loading && <Loader />}
                            <form
                                noValidate
                                className="form"
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >

                                <Row>
                                    <Col md="12">
                                       
                                        <input
                                            type="file"
                                            accept=".csv"
                                            ref={this.fileInput}
                                        //onChange={handleFileUpload}
                                        />
                                    </Col>

                                </Row>
                                <Row className="float-right">
                                    <Col>
                                        <div className="d-flex-center j-end">
                                            <ButtonRP
                                                text="Cancel"
                                                cancel={true}
                                                className="mx-2"
                                                onClick={this.props.onCancel}
                                            />
                                            {!this.props.isEditFlag && (
                                                <ButtonRP
                                                    type="submit"
                                                    text="Submit"
                                                    disabled={isSubmitted ? false : true}
                                                />
                                            )}
                                            {this.props.isEditFlag && (
                                                <ButtonRP
                                                    type="submit"
                                                    text="Update"
                                                    disabled={isEditCompanyFlag ? false : true}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            {resposeData.map(data => (
                                <li>
                                    <div>{data.Email?"Email: ":""} {data.Email}</div>
                                    <div>Result: {data.result?data.result.toString():""}</div>
                                </li>
                            ))  
                            }           
                        </Container>
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (store) => {
    const selectedCompany =
        store.newsPaperCompanyDuck && store.newsPaperCompanyDuck.selectedCompany;
    const { loading, roles, superAdminSettingList } = store.commonDuck;
    const { npcDuck } = store;

    let initialValues = {};
    if (selectedCompany && selectedCompany !== undefined) {
        let areaArray = [];
        if (
            selectedCompany.distribution_areas &&
            selectedCompany.distribution_areas !== [] &&
            selectedCompany.distribution_areas !== undefined
        ) {
            var str = selectedCompany.distribution_areas;
            areaArray = str;
        }
        initialValues = {
            first_name: selectedCompany.user.first_name
                ? selectedCompany.user.first_name.trim()
                : "",
            last_name: selectedCompany.user.last_name
                ? selectedCompany.user.last_name.trim()
                : "",
            phone_number: selectedCompany.user.phone_number,
            email: selectedCompany.user.email,
            role_id: selectedCompany.user.role_id,
            newspaper_name: selectedCompany.newspaper_name
                ? selectedCompany.newspaper_name.trim()
                : "",
            bill_address: selectedCompany.bill_address,
            bill_state: selectedCompany.bill_state
                ? selectedCompany.bill_state.trim()
                : "",
            bill_city: selectedCompany.bill_city
                ? selectedCompany.bill_city.trim()
                : "",
            bill_country: selectedCompany.bill_country,
            bill_zip_code: selectedCompany.bill_zip_code,
            distribution_type: selectedCompany.distribution_type,
            distribution_state:
                selectedCompany.distribution_state !== "" &&
                    selectedCompany.distribution_type === "Local" &&
                    selectedCompany.distribution_areas &&
                    selectedCompany.distribution_areas[0]
                    ? selectedCompany.distribution_areas[0].state_name
                    : "",
            distribution_area: selectedCompany.distribution_area,
            distributed_copies:
                selectedCompany.distributed_copies !== undefined &&
                    selectedCompany.distributed_copies
                    ? selectedCompany.distributed_copies.id
                    : "",
            email_distribution: selectedCompany.email_distribution,
            area1: areaArray && areaArray[0] && areaArray[0].id,
            area2: areaArray && areaArray[1] && areaArray[1].id,
            area3: areaArray && areaArray[2] && areaArray[2].id,
        };
    }
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        companyRoles: roles && roles.company ? roles.company : [],
        distribution_area_List: npcDuck.npcSettingList.distribution_area,
        rangeList:
            superAdminSettingList && superAdminSettingList.copies_range
                ? superAdminSettingList.copies_range
                : [],
        initialValues,
        loading,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        ...commonDuck.actions,
        ...NewsPaperCompanyDuck.actions,
        ...npcDuck.actions,
    })(
        reduxForm({
            // Assign unique name for the form
            form: "AddMultipleCompany",
            enableReinitialize: true,
            destroyOnUnmount: false,
            onSubmitFail: (errors) => {
                focusOnError(errors);
            },
        })(AddMultipleCompany)
    )
);
