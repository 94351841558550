import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { Table, Input } from "reactstrap";
import { DATE_RANGE } from "../../../../../config/constant";
import { Button } from "@material-ui/core";
import FilterRecords from "../FilterRecords";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import NoRecordFoundContent from "../../../../../common/NoRecordFoundContent";

let label = getCurrentDate();
class StatementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      duration: DATE_RANGE,
      openModal: false,
      statementCount: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getTransactionList();
  }

  /**
   * @method getTransactionList
   * @description  render transaction list
   */
  getTransactionList = () => {
    let requestData = {
      type: 3,
    };
    this.setData(requestData);
  };

  /**
   * @method setData
   * @description  set requestdata
   */
  setData = (requestData) => {
    this.props.getPayoutSummaryListing(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({
          statementCount: res.data.statement_count,
          openModal: false,
        });
      } else {
        this.setState({ openModal: false });
      }
    });
  };

  /**
   * @method handleSearch
   * @description handle searching
   */
  handleSearch = (e) => {
    const { fromDate, toDate, month, year } = this.state;
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
      this.getTransactionList();
    } else if (e.target.value.length >= 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let requestData = {
        search: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        type: 3,
        month: month ? month : "",
        year: year ? year : "",
        from_date: fromDate ? fromDate : "",
        to_date: toDate ? toDate : "",
      };
      this.setData(requestData);
    }
  };

  /**
   * @method handleFilter
   * @description  used to handle filtered records
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  used to apply filter on graph data
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 3,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.setData(requestData);
  }

  /**
   * @method setDetail
   * @description set statement id
   */
  setDetail = (id) => {
    this.props.callNext(id);
  };

  /**
   * @method renderTransactionRecord
   * @description render the transaction records
   */
  renderTransactionRecord = (transactionListing) => {
    if (
      transactionListing &&
      transactionListing !== undefined &&
      transactionListing.length > 0
    ) {
      return transactionListing.map((data, i) => {
        return (
          <tbody>
            <tr
              onClick={() => this.props.callNext(data.statement_id)}
              style={{ cursor: "pointer" }}
            >
              <td>{data.payment_date}</td>
              <td>{data.newspaper_name}</td>
              <td>{data.statement_id}</td>
              <td>${data.paid_amount}</td>
            </tr>
          </tbody>
        );
      });
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="4">
              <NoRecordFoundContent />
            </td>
          </tr>
        </tbody>
      );
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { openModal, statementCount, selectedOption } = this.state;
    const { transationList } = this.props;
    return (
      <div
        className="column"
        style={{ textAlign: "-webkit-center", margin: 5, height: "100%" }}
      >
        <div style={{ border: "1px solid black", width: "auto", padding: 20 }}>
          <div className="row">
            <h6
              className=" col-md-8 cursorPointer mb-3"
              style={{ textAlign: "initial", cursur: "pointer" }}
              onClick={() => this.setState({ openModal: true })}
            >
              {label}
              &nbsp;&nbsp;
              <i
                style={{ fontSize: 15 }}
                className="fa fa-angle-down"
                aria-hidden="true"
              ></i>
            </h6>
            <div className="col-md-4">
              <Input
                style={{ height: 30, borderRadius: 25 }}
                placeholder="Search"
                type="text"
                onChange={(e) => this.handleSearch(e)}
              ></Input>
            </div>
          </div>
          <Table className="mt-4" responsive>
            <thead style={{ backgroundColor: "#dcdcdccc" }}>
              <tr>
                <th>Date</th>
                <th>Newspaper Company</th>
                <th>Statement Id</th>
                <th>Amount</th>
              </tr>
            </thead>
            {this.renderTransactionRecord(transationList)}
          </Table>
          <div className=" row my-3">
            <h5 style={{ textAlign: "left" }} className=" mt-1">
              {statementCount &&
                `${"Total Statement("} ${statementCount} ${")"}`}
            </h5>
            {transationList && transationList.length > 0 && (
              <Link to="/allStatements" style={{ marginLeft: "auto" }}>
                <Button className="cursorPointer">View More</Button>
              </Link>
            )}
          </div>
          {openModal && (
            <FilterRecords
              applyFilter={this.handleFilter}
              isOpen={openModal}
              selectedOption={selectedOption}
              onCancel={() => this.setState({ openModal: false })}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { payoutSummaryDuck, auth } = store;
  const { transactionListing } = payoutSummaryDuck;
  return {
    loggedInUser: auth.user,
    loading,
    transationList:
      transactionListing !== undefined &&
      Array.isArray(transactionListing.dataGrid)
        ? transactionListing.dataGrid
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    payoutSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "StatementList",
    })(StatementList)
  )
);
