import React from "react";
import { connect } from "react-redux";
import { Button, Form, Col, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import {
  renderSelectField,
  renderText,
} from "../../../../common/layout/FormInput";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import { injectIntl } from "react-intl";
import { renderThemeSelectOptions } from "../../FormLayout";
import { langs } from "../../../../config/localization";
import { STATUS_CODES } from "../../../../common/StatusCode";
import {
  PAGES_25,
  DISTRIBUTION_TYPE,
  EMAIL_DISTRIBUTION,
  STATUS,
} from "../../../../config/constant";
import { ButtonRP } from "../../../../common/Buttons";

class AdvanceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uniquesState: [],
      areasByState: [],
      selectedState: undefined,
      area1: undefined,
      area2: undefined,
      area3: undefined,
      companyRoles: [],
      emailDistribution: EMAIL_DISTRIBUTION,
      status: STATUS,
      distributionType: DISTRIBUTION_TYPE,
      page: 1,
      pageSize: PAGES_25,
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getMasterData();
  }

  /**
   * @method getMasterData
   * @description called to get all master data
   */
  getMasterData = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getAllRoleList(langs.keyname.company);
    this.props.getfromSuperAdminSetting(
      langs.keyname.copies_range,
      user_detail.id,
      (res) => {}
    );
    this.props.getfromSuperAdminSetting(
      langs.keyname.language,
      "",
      (res) => {}
    );
    this.props.getfromSuperAdminSetting(
      langs.keyname.npc_category,
      "",
      (res) => {}
    );
    this.props.getfromNPCSetting("distribution_area_count", 0, (res) => {
      if (Array.isArray(res.data)) {
        const unique = [
          ...new Set(res.data.map((item) => item["us_states.state_name"])),
        ];
        let uniqueArray = [];
        unique.map((el, i) => {
          uniqueArray.push({ id: i, lable: el });
        });
        this.setState({ uniquesState: uniqueArray });
      }
    });
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanyList = (row, page) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          companyList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
        this.props.history.listen((location, action) => {
          this.props.reset();
          this.props.resetAdvanceSearch(res);
        });
      }
    });
  };

  /**
   * @method renderTypeOfListing
   * @description Used show listing
   */
  renderTypeOfListing = (type) => {
    let options = [];
    const states = [];
    if (type === 1) {
      this.props.selectedState &&
        this.props.selectedState !== undefined &&
        this.props.selectedState.map((item, index) => {
          states.push({
            label: item.state,
            value: item.state,
          });
        });
      options = states;
    }
    return options;
  };

  /**
   * @method resetList
   * @description  used to reset list
   */
  resetList = (row, page) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          companyList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
        this.props.resetAdvanceSearch(res);
      }
    });
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { authToken } = this.props.loggedInUser;
    const requestData = {
      newspaper_name: values.newspaper_name ? values.newspaper_name : "",
      language:
        values.language && values.language !== -1 ? values.language : "",
      distribution_type:
        values.distribution_type && values.distribution_type !== -1
          ? values.distribution_type
          : "",
      distribution_state:
        values.distribution_state && values.distribution_state !== -1
          ? values.distribution_state
          : "",
      distribution_area:
        values.distribution_area && values.distribution_area !== -1
          ? values.distribution_area
          : "",
      category:
        values.category && values.category !== -1 ? values.category : "",
      email_distribution:
        values.email_distribution && values.email_distribution !== -1
          ? values.email_distribution
          : "",
      role_id: values.role_id && values.role_id !== -1 ? values.role_id : "",
      distributed_copies:
        values.distributed_copies && values.distributed_copies !== -1
          ? values.distributed_copies
          : "",
      active: values.active && values.active !== -1 ? values.active : "",
    };
    const empty_fields =
      values && Object.keys(values).length === 0 ? true : false;
    if (!empty_fields) {
      this.props.getListingFromAdvanceSearch(
        authToken,
        requestData,
        (res) => {}
      );
    } else {
      this.resetList();
    }
  }

  /**
   * @method resetFilter
   * @description  used to reset filter
   */
  resetFilter = () => {
    this.props.reset();
    this.getCompanyList(this.state.pageSize, 1);
  };

  /**
   * @method stateHandeler
   * @description called to render list of state
   */
  onChangeStateHandeler = (e) => {
    if (e.target.value !== "") {
      this.props.getDistributionAreaFromState(String(e.target.value), (res) => {
        if (res.data) {
          this.setState({
            areasByState: res.data,
            selectedState: e.target.value,
            isSubmitted: true,
          });
        }
      });
    } else if (e.target.value === "") {
      this.setState({ areasByState: [], selectedState: undefined });
    }
  };

  /**
   * @method getArea
   * @description called to render list of area's
   */
  getArea = (e, name) => {
    const { areasByState } = this.state;
    let index;
    if (areasByState) {
      index = areasByState.findIndex((el) => el.id === Number(e.target.value));
      if (index >= 0) {
        this.setState({
          [name]: {
            name: areasByState[index].area,
            id: areasByState[index].id,
          },
          isSubmitted: true,
          isEditCompanyFlag: true,
          isEditFlag: true,
        });
      }
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      handleSubmit,
      companyRoles,
      rangeList,
      languageList,
      categoryList,
    } = this.props;
    const {
      uniquesState,
      areasByState,
      status,
      distributionType,
      selectedState,
      area1,
      emailDistribution,
      selectedCity,
    } = this.state;
    return (
      <div className="col-md-12 RP my-5 pb-5">
        <form
          noValidate
          className="form"
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          <Row>
            <Col md="3" sm="4">
              <Field
                name="newspaper_name"
                label="Newspaper Name"
                placeholder="Enter Newspaper name"
                component={renderText}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="language"
                options={languageList}
                label={"Language"}
                isSelect={false}
                optionValue={"id"}
                optionLabel={"name"}
                component={renderSelectField}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="distribution_type"
                options={distributionType}
                label={"Distribution Type"}
                isSelect={false}
                optionValue={"value"}
                optionLabel={"label"}
                component={renderSelectField}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="distribution_state"
                value={selectedState}
                label="State"
                optionValue={"lable"}
                optionLabel={"lable"}
                isSelect={false}
                options={uniquesState && uniquesState}
                onChange={this.onChangeStateHandeler}
                component={renderSelectField}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="distribution_area"
                value={area1}
                label="Distribution Area"
                optionValue={"id"}
                optionLabel={"area"}
                options={areasByState}
                isSelect={false}
                onChange={(e) => this.getArea(e, "distribution_area")}
                component={renderSelectField}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="category"
                label="Category"
                optionValue={"id"}
                optionLabel={"name"}
                isSelect={false}
                options={categoryList}
                component={renderSelectField}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="email_distribution"
                label="Email Distribution"
                isSelect={false}
                options={emailDistribution}
                onChange={this.onChangeDetailHandeler}
                component={renderSelectField}
                optionValue={"value"}
                optionLabel={"label"}
              />
            </Col>
            <Col md="3" sm="4">
              <Form.Label>Roles</Form.Label>
              <Field
                name="role_id"
                data={companyRoles}
                isSelect={false}
                component={renderThemeSelectOptions}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="distributed_copies"
                label={"No. of Copies"}
                selected={selectedCity}
                isSelect={false}
                component={renderSelectField}
                options={rangeList}
                optionValue={"id"}
                optionLabel={"copies_range"}
              />
            </Col>
            <Col md="3" sm="4">
              <Field
                name="active"
                label="Status"
                options={status}
                isSelect={false}
                onChange={this.onChangeDetailHandeler}
                component={renderSelectField}
                optionValue={"value"}
                optionLabel={"label"}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row className="float-right">
            <Col>
              <div className="d-flex-center j-end">
                <ButtonRP
                  text="Reset"
                  cancel={true}
                  className="mx-2"
                  onClick={this.resetFilter}
                />
                <ButtonRP type="submit" text="Apply" />
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading, roles, superAdminSettingList } = store.commonDuck;
  const { npcDuck } = store;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    companyRoles: roles && roles.company ? roles.company : [],
    distribution_area_List: npcDuck.npcSettingList.distribution_area,
    rangeList:
      superAdminSettingList && superAdminSettingList.copies_range
        ? superAdminSettingList.copies_range
        : [],
    selectedState: store.superAdminDuck.selectedState,
    languageList: superAdminSettingList.language
      ? superAdminSettingList.language
      : [],
    categoryList: superAdminSettingList.npc_category
      ? superAdminSettingList.npc_category
      : [],
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...NewsPaperCompanyDuck.actions,
    ...npcDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "AdvanceSearch",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(AdvanceSearch)
  )
);
