import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from "axios";
import { API } from "../../config";
import { apiErrors } from "../../common/HandleAPIErrors";
import { CALL_LOADING } from "../../config/constant";
const headers = {
  "Content-Type": "application/json",
};

export const actionTypes = {
  SetLoading: "[SetLoading] Action",
  SetRoles: "[SetRoles] Action",
  SetAllPublisherCategory: "[SetAllPublisherCategory] Action",
  SetAllCompanyCategory: "[SetAllCompanyCategory] Action",
  SetAllSocialMediaCategory: "[SetAllSocialMediaCategory] Action",
  SetAllAdTypeCategory: "[SetAllAdTypeCategory] Action",
  SetAllAdPlacementCategory: "[SetAllAdPlacementCategory] Action",
  SetAllAdFeatureCategory: "[SetAllAdFeatureCategory] Action",
  SetAllSizeOfAdCategory: "[SetAllSizeOfAdCategory] Action",
  SetAllPriceRunTimeCategory: "[SetAllPriceRunTimeCategory] Action",
  SetUniversalSates: "[SetUniversalSates] Action",
  SetDistributionAreas: "[SetDistributionAreas] Action",
  SetSelectedAdmin: "[SetSelectedAdmin] Action",
  SetCompanyDetail: "[SetCompanyDetail] Action",

  AllReasonList: "[AllReasonList] Action",
  SetSelectedCompany: "[SetSelectedCompany] Action",
  SetSelectedEmail: "[SetSelectedEmail] Action",
  SetSelectedWhatsapp: "[SetSelectedWhatsapp] Action",
  SetSelectedPayout: "[SetSelectedPayout] Action",
  SetSelectedCustomURL: "[SetSelectedCustomURL] Action",
  SetSelectedSendEmailList: "[SetSelectedSendEmailList] Action",

  SetNPCSetingList: "[SetNPCSetingList] Action",
  SetSuperAdminSetingList: "[SetSuperAdminSetingList] Action",
  SetAllOrderList: "[SetAllOrderList] Action",
  SetAllCreditList: "[SetAllCreditList] Action",
  SetOrderDetail: "[SetOrderDetail] Action",
  SetAllCompanyList: "[SetAllCompanyList] Action",

};

const initialAuthState = {
  loading: false,
  selectedAdmin: undefined,
  roles: {},
  reasonList: [],
  areaList: [],
  selectedCompany: undefined,
  selectedEmail: undefined,
  superAdminSettingList: {},
  orderList: [],
  companyList: [],
  creditList: [],
  publisherCategoryList: [],
  companyCategoryList: [],
  socialMediaCategoryList: [],
  adTypeCategoryList: [],
  adPlacementCategoryList: [],
  adFeatureCategoryList: [],
  sizeOfAdCategoryList: [],
  priceRunTimeCategoryList: [],
  orderDetail: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      /** SET a Global Message for Success & Error */
      case actionTypes.SetLoading: {
        return {
          ...state,
          loading: !state.loading,
        };
      }

      /** get all records of company list */
      case actionTypes.SetAllCompanyList: {
        const { companyList, totalCompany } = action.payload;
        return {
          ...state,
          companyList: companyList,
          totalCompany: totalCompany,
        };
      }

      /** get data of individualad role type detail by key */
      case actionTypes.SetRoles: {
        const { roleKey, result } = action.payload;
        let roles = state.roles;
        roles[roleKey] = result;
        roles = { ...roles, [roleKey]: roles[roleKey] };
        return { ...state, roles };
      }

      case actionTypes.SetAllPublisherCategory: {
        const { publisherCategoryList } = action.payload;
        //console.warn("%%%%% publisherCategoryList %%%%%",action.payload)
        return { ...state, publisherCategoryList };
      }

      case actionTypes.SetAllCompanyCategory: {
        const { companyCategoryList } = action.payload;
        //console.warn("%%%%%%%%%%",action.payload)
        return { ...state, companyCategoryList };
      }

      case actionTypes.SetAllSocialMediaCategory: {
        const { socialMediaCategoryList } = action.payload;
        // //console.warn("%%%% socialMediaCategoryList %%%%%%",action.payload)
        return { ...state, socialMediaCategoryList };
      }

      case actionTypes.SetAllAdTypeCategory: {
        const { adTypeCategoryList } = action.payload;
        //console.warn("%%%% adTypeCategoryList %%%%%%",action.payload)
        return { ...state, adTypeCategoryList };
      }

      case actionTypes.SetAllAdPlacementCategory: {
        const { adPlacementCategoryList } = action.payload;
        //console.warn("%%%% adPlacementCategoryList %%%%%%",action.payload)
        return { ...state, adPlacementCategoryList };
      }

      case actionTypes.SetAllAdFeatureCategory: {
        const { adFeatureCategoryList } = action.payload;
        //console.warn("%%%% adFeatureCategoryList %%%%%%",action.payload)
        return { ...state, adFeatureCategoryList };
      }

      case actionTypes.SetAllSizeOfAdCategory: {
        const { sizeOfAdCategoryList } = action.payload;
        //console.warn("%%%% sizeOfAdCategoryList %%%%%%",action.payload)
        return { ...state, sizeOfAdCategoryList };
      }

      case actionTypes.SetAllPriceRunTimeCategory: {
        const { priceRunTimeCategoryList } = action.payload;
        //console.warn("%%%% priceRunTimeCategoryList %%%%%%",action.payload)
        return { ...state, priceRunTimeCategoryList };
      }

      /** get data of individualad role type detail by key */
      case actionTypes.SetUniversalSates: {
        const { stateList } = action.payload;
        return { ...state, stateList };
      }

      /** get data of individual admin by id */
      case actionTypes.SetSelectedAdmin: {
        const { selectedAdmin } = action.payload;
        return { ...state, selectedAdmin: selectedAdmin };
      }

      /** set data of individual Company by id */
      case actionTypes.SetCompanyDetail: {
        const { company } = action.payload;
        return { ...state, selectedCompany: company };
      }

      /** get all records of reason list */
      case actionTypes.AllReasonList: {
        const { reasonList } = action.payload;
        return { ...state, reasonList: reasonList };
      }

      /** get all records of reason list */
      case actionTypes.SetDistributionAreas: {
        const { areaList } = action.payload;
        return { ...state, areaList };
      }

      /** get data of individual admin by id */
      case actionTypes.SetSelectedCompany: {
        const { selectedCompany } = action.payload;
        return { ...state, selectedCompany: selectedCompany };
      }

      case actionTypes.SetSelectedEmail: {
        const { selectedEmail } = action.payload;
        return { ...state, selectedEmail: selectedEmail };
      }

      case actionTypes.SetSelectedWhatsapp: {
        const { selectedWhatsapp } = action.payload;
        return { ...state, selectedWhatsapp: selectedWhatsapp };
      }

      case actionTypes.SetSelectedPayout: {
        const { selectedPayout } = action.payload;
        return { ...state, selectedPayout: selectedPayout };
      }

      case actionTypes.SetSelectedCustomURL: {
        const { selectedCustomURL } = action.payload;
        return { ...state, selectedCustomURL: selectedCustomURL };
      }

      case actionTypes.SetSelectedSendEmailList: {
        const { selectedSendEmailList } = action.payload;
        return { ...state, selectedSendEmailList: selectedSendEmailList };
      }

      /** get data of individualad type detail by id */
      case actionTypes.SetNPCSetingList: {
        const { apiKey, result } = action.payload;
        let npcSettingList = state.npcSettingList;
        npcSettingList[apiKey] = result;
        npcSettingList = {
          ...npcSettingList,
          [apiKey]: npcSettingList[apiKey],
        };
        return { ...state, npcSettingList };
      }

      /** get data of individualad type detail by id */
      case actionTypes.SetSuperAdminSetingList: {
        const { apiKey, result } = action.payload;
        let superAdminSettingList = state.superAdminSettingList;
        superAdminSettingList[apiKey] = result;
        superAdminSettingList = {
          ...superAdminSettingList,
          [apiKey]: superAdminSettingList[apiKey],
        };
        return { ...state, superAdminSettingList };
      }

      /** get all records of company list */
      case actionTypes.SetAllOrderList: {
        const { orderList } = action.payload;
        return { ...state, orderList: orderList };
      }

      /** get all records of company list */
      case actionTypes.SetAllCreditList: {
        const { creditList } = action.payload;
        return { ...state, creditList: creditList };
      }

      /** get all records of company list */
      case actionTypes.SetOrderDetail: {
        const { orderDetail } = action.payload;
        return { ...state, orderDetail };
      }

      /** set default state */
      default:
        return state;
    }
  }
);

/** Actions creator */
export const actions = {
  /**
   * @method requestAPI
   * @description call loading true
   */
  callLoading: () => {
    return (dispatch) => {
      return dispatch({ type: actionTypes.SetLoading });
    };
  },

  /**
   * @method getAllNewspaperList
   * @description get all company listing/records
   */
  getAllNewspaperList: (requestData, callback) => {
    const { row, page, is_deleted, status } = requestData;
    const request = axios.get(
      `${API.getAllNewspaperList}?row=${row}&page=${page}&is_deleted=${is_deleted}&status=${status}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetAllCompanyList,
            payload: {
              companyList: response.data.rows,
              totalCompany: response.data.count,
            },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  /**
   * @method getAllRoleList
   * @description get all role list By key
   */
  getAllRoleList: (roleKey) => {
    const request = axios.get(`${API.generalAPI}/roles?role=${roleKey}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          return dispatch({
            type: actionTypes.SetRoles,
            payload: { roleKey, result: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllPublisherCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/npc_category?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$publisher_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllPublisherCategory,
            payload: { publisherCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllCompanyCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/companycategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$",response)
          return dispatch({
            type: actionTypes.SetAllCompanyCategory,
            payload: { companyCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllSocialMediaCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/socialmediacategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          // //console.warn("$$$$$social_media_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllSocialMediaCategory,
            payload: { socialMediaCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },


  getAllAdTypeCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/adtypecategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$ad_type_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllAdTypeCategory,
            payload: { adTypeCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllAdPlacementCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/adplacementcategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$ad_placement_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllAdPlacementCategory,
            payload: { adPlacementCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllAdFeatureCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/adfeaturecategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$ad_feature_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllAdFeatureCategory,
            payload: { adFeatureCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllSizeOfAdCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/sizeofadcategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$size_of_ad_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllSizeOfAdCategory,
            payload: { sizeOfAdCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  getAllPriceRunTimeCategoryList: (role) => {
    const request = axios.get(`${API.generalAPI}/priceruntimecategory?id=`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          //console.warn("$$$$$price_run_time_category$$$",response)
          return dispatch({
            type: actionTypes.SetAllPriceRunTimeCategory,
            payload: { priceRunTimeCategoryList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  /**
   * @method getCustomerRoleList
   * @description get all customer role list
   */
  getCustomerRoleList: (roleKey, callback) => {
    const request = axios.get(`${API.generalAPI}/roles?role=${roleKey}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(error);
          apiErrors(error, dispatch);
        });
    };
  },

  /**
   * @method getAllUniversalStates
   * @description get all state list By key
   */
  getAllUniversalStates: () => {
    const request = axios.get(`${API.generalAPI}/distribution_area_state`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          return dispatch({
            type: actionTypes.SetUniversalSates,
            payload: { stateList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },

  /**
   * @method viewAdminById
   * @description view admin details
   */
  viewAdminById: (adminId, callback) => {
    const request = axios.get(`${API.getAdminDetail}?id=${adminId}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedAdmin,
            payload: { selectedAdmin: response.data },
          });
        })
        .catch((error) => {
          callback(error);
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
        });
    };
  },

  /**
   * @method getCompanyById
   * @description get Company Details by id
   */
  getCompanyById: (requestData, callback) => {
    const { company_id } = requestData;
    const request = axios.get(`${API.getCompanyById}?id=${company_id}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          console.warn(response)
          dispatch({
            type: actionTypes.SetCompanyDetail,
            payload: { company: response.data },
          });
          callback(response.data);
        })
        .catch((error) => {
          // tokenCheck(dispatch, error)
          callback(undefined);
          return error;
        });
    };
  },

  /**
    * @method getBankDetailsById
    * @description get Company Details by id
    */
  getBankDetailsById: (requestData, callback) => {
    const { company_id } = requestData
    const request = axios.get(`${API.getBankDetailsById}?id=${company_id}`, {
      headers,
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
        console.warn(response)
      }).catch((error) => {
        dispatch({ type: CALL_LOADING })
        //tokenCheck(dispatch, error)
        apiErrors(error, dispatch)
        callback(error);
      });
    };
  },

  deleteCompanyCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/companycategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  deleteSocialMediaCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/socialmediacategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  deleteAdTypeCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/adtypecategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  deleteAdPlacementCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/adplacementcategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  deleteAdFeatureCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/adfeaturecategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  deleteSizeOfAdCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/sizeofadcategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  deletePriceRunTimeCustomerById: (data, callback) => {
    const { id } = data
    const request = axios.delete(`${API.generalAPI}/priceruntimecategory?id=${id}&active=${true}`, {
      headers
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING })
      request.then((response) => {
        dispatch({ type: CALL_LOADING })
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch)
        dispatch({ type: CALL_LOADING })
        // tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
   * @method getAllReasonList
   * @description get all reason list
   */
  getAllReasonList: (api) => {
    const request = axios.get(`${API.genericAPI}/${api}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          return dispatch({
            type: actionTypes.AllReasonList,
            payload: { reasonList: response.data.result },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
          // tokenCheck(dispatch, error)
        });
    };
  },

  /**
   * @method getAllDistributionAreas
   * @description get all reason list
   */
  getAllDistributionAreas: () => {
    const request = axios.get(`${API.generalAPI}/distribution_area_count`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          return dispatch({
            type: actionTypes.SetDistributionAreas,
            payload: { areaList: response.data },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
          // tokenCheck(dispatch, error)
        });
    };
  },

  getfromNPCSetting: (apiKey, id, callback) => {
    const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetNPCSetingList,
            payload: { apiKey: apiKey, result: response.data },
          });
        })
        .catch((error) => {
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },

  /**
   * @method getCompanyDetailById
   * @description Update admin details
   */

  getCompanyDetailById: (companyId, callback) => {

    const request = axios.get(`${API.getAllNewspaperById}?id=${companyId}`, {
      headers,
    });

    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedCompany,
            payload: { selectedCompany: response.data },
          });
        })
        .catch((error) => {
          // tokenCheck(dispatch, error)
          //apiErrors(error, dispatch);
          callback(error);
        });
    };

  },
  getemailSettings: (companyId, callback) => {
    const request = axios.get(`${API.getemailSettings}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedEmail,
            payload: { selectedEmail: response.data },
          });
        })
        .catch((error) => {
          callback(error);
        });
    };
  },
  getWhatsappSettings: (companyId, callback) => {
    const request = axios.get(`${API.getWhatsappSettings}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedWhatsapp,
            payload: { selectedWhatsapp: response.data },
          });
        })
        .catch((error) => {
          callback(error);
        });
    };
  },
  getPayoutSettings: (companyId, callback) => {
    const request = axios.get(`${API.getPayoutSettings}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedPayout,
            payload: { selectedPayout: response.data },
          });
        })
        .catch((error) => {
          callback(error);
        });
    };
  },
  getCustomURLSettings: (companyId, callback) => {
    const request = axios.get(`${API.getCustomURLSettings}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedCustomURL,
            payload: { selectedCustomURL: response.data },
          });
        })
        .catch((error) => {
          callback(error);
        });
    };
  },
  getSendEmailList: (companyId, callback) => {
    const request = axios.get(`${API.getSendEmailList}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedSendEmailList,
            payload: { selectedSendEmailList: response.data },
          });
        })
        .catch((error) => {
          callback(error);
        });
    };
  },

  /**
   * @method getfromSuperAdminSetting
   * @description get all distribution areas list
   */
  getfromSuperAdminSetting: (apiKey, id, callback) => {
    const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
      headers,
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
          return dispatch({
            type: actionTypes.SetSuperAdminSetingList,
            payload: { apiKey: apiKey, result: response.data },
          });
        })
        .catch((error) => {
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },

  /**
   * @method getAllOrderList
   * @description get all order listing/records
   */
  getAllOrderList: (requestData, callback) => {
    const {
      row,
      page,
      sort,
      column,
      searchKey,
      npc_id,
      status,
      customer_id,
    } = requestData;
    let url =
      npc_id !== undefined
        ? `${API.getAllOrderList}?npc_id=${npc_id}&status=${status}&id=&sort=${sort}&column=${column}&row=${row}&page=${page}&search=${searchKey}&customer_id=${customer_id}`
        : `${API.getAllOrderList}?npc_id=&id=&sort=${sort}&status=${status}&column=${column}&row=${row}&page=${page}&search=${searchKey}&customer_id=${customer_id}`;

    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetAllOrderList,
            payload: { orderList: response.data.rows },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },

  /**
   * @method getOrderDetail
   * @description get  order detail
   */
  getOrderDetail: (requestData, callback) => {
    const {
      id,
      newspaper_id = "",
      user_id = "",
      customer_id = "",
    } = requestData;
    const request = axios.get(
      `${API.getAllOrderList
      }?npc_id=${newspaper_id}&customer_id=${customer_id}&newspaper_id=${newspaper_id}&user_id=${user_id}&id=${id}&sort=&column=&row=${25}&page=${1}&search=`,
      { headers }
    );

    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetOrderDetail,
            payload: { orderDetail: response.data.rows },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },

  /**
   * @method getListingFromAdvanceSearch
   * @description get all records based on advance search
   */
  getOrderListingFromAdvanceSearch: (requestData, callback) => {
    let api_call;
    api_call = `${API.getAdvanceSearchRecordOrderList}?id=${requestData.id
      }&npc_id=${requestData.npc_id}&newspaper_name=${requestData.newspaper_name
      }&plan_name=${requestData.plan_name}&customer_name=${requestData.customer_name
      }&status=${requestData.status}&from_order_date=${requestData.from_order_date
      }&to_order_date=${requestData.to_order_date}&from_price=${requestData.from_price
      }&to_price=${requestData.to_price}&ad_type=${requestData.ad_type
      }&ad_frequency=${requestData.ad_frequency}&from_publish_dates=${requestData.from_publish_dates
      }&to_publish_dates=${requestData.to_publish_dates}&row=${100}&page=${1}`;

    const request = axios.get(api_call, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetAllOrderList,
            payload: { orderList: response.data.rows },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          callback(error);
        });
    };
  },
  /**
   * @method getAlCreditList
   * @description get all credit listing/records
   */
  getAllCreditList: (requestData, callback) => {
    const { row, page, sort, column, searchKey, npc_id } = requestData;
    let url =
      npc_id !== undefined
        ? `${API.getAllCreditList}?npc_id=${npc_id}&id=&sort=${sort}&column=${column}&row=${row}&page=${page}&search=${searchKey}`
        : `${API.getAllCreditList}?npc_id=&id=&sort=${sort}&column=${column}&row=${row}&page=${page}&search=${searchKey}`;

    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetAllCreditList,
            payload: { creditList: response.data.rows },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },

  /**
   * @method getListingFromAdvanceSearch
   * @description get all records based on advance search
   */
  getCreditListingFromAdvanceSearch: (requestData, callback) => {
    const {
      npc_id,
      newspaper_name,
      customer_name,
      from_amount,
      from_expiry_date,
      from_cancel_date,
      to_amount,
      to_cancel_date,
      to_expiry_date,
      order_id,
    } = requestData;

    let api_call = `${API.getAdvanceSearchRecordCreditList
      }?npc_id=${npc_id}&newspaper_name=${newspaper_name}&order_id=${order_id}&customer_name=${customer_name}&from_cancel_date=${from_cancel_date}&to_cancel_date=${to_cancel_date}&from_amount=${from_amount}&to_amount=${to_amount}&from_expiry_date=${from_expiry_date}&to_expiry_date=${to_expiry_date}&row=${100}&page=${1}`;

    const request = axios.get(api_call, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetAllCreditList,
            payload: { creditList: response.data.rows },
          });
        })
        .catch((error) => {
          apiErrors(error, dispatch);
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          callback(error);
        });
    };
  },
};

export function* saga() {
  yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() { });
}
