import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Table, Row, Col, Input, Label } from "reactstrap";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../../common/layout/FormInput";
import { DATE_RANGE } from "../../../../../config/constant";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import NoRecordFoundContent from "../../../../../common/NoRecordFoundContent";
import { topRecordsFilterKeys } from "../../../../../common/CommonFilterFunction";
import { langs } from "../../../../../config/localization";
import CountFigureBox from "../../../../../common/CountFigureBox";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

class AdTypeRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type1: false,
      duration: DATE_RANGE,
      salesType: "",
      dateVisible: false,
      startDate: "",
      endDate: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getadTypeReport(4, 1, "", "", langs.keyname.revenue);
  }

  /**
   * @method getadTypeReport
   * @description  used to get adtype report data
   */
  getadTypeReport = (row, page, sort, column, salesType) => {
    this.setState({ salesType: salesType });
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 2,
      sales: salesType,
      section: 1,
    };
    this.props.getAdTypeGraphData(requestData, (res) => {});
  };

  /**
   * @method renderAdTypePlanRecord
   * @description  render adtype plan listing
   */
  renderAdTypePlanRecord = (adTypePlanList) => {
    const { salesType } = this.state;
    if (adTypePlanList && adTypePlanList.length) {
      return (
        adTypePlanList &&
        Array.isArray(adTypePlanList) &&
        adTypePlanList.map((item, index) => {
          return (
            <tbody>
              <tr>
                <th scope="row">{index}</th>
                <td>{item.plan_name}</td>
                <td>
                  ${" "}
                  {salesType === langs.keyname.revenue
                    ? item.sales_revenue
                    : item.total_orders}{" "}
                </td>
              </tr>
            </tbody>
          );
        })
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="3">
              <NoRecordFoundContent />
            </td>
          </tr>
        </tbody>
      );
    }
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilterOnAdType(values) {
    const { startDate, endDate, dateVisible, salesType } = this.state;
    const { user_detail } = this.props.loggedInUser;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      npc_id: user_detail.newspaper.id,
      row: 5,
      page: 1,
      month: data.this_month,
      year: data.this_year,
      type: 2,
      from_date: data.date,
      to_date: data.date2,
      ad_type: "",
      sales: salesType ? salesType : langs.keyname.revenue,
      section: 1,
    };
    this.props.getAdTypeGraphData(requestData, (res) => {});
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { type1, duration, dateVisible, startDate, endDate } = this.state;
    const { adTypeReportData } = this.props;
    return (
      <CountFigureBox>
        <Row className="w-100">
          <Col xs="12 mb-2">
            <h5>Top 5 Ad Type Plan</h5>
          </Col>

          <Col xs="12" className="d-flex-center">
            <span className="mr-2">By</span>
            <div className="radio-inline">
              <RadioButtonRP label="Revenue">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={!type1 ? true : ""}
                  onChange={(e) => {
                    this.setState({ type1: false });
                    this.getadTypeReport(4, 1, "", "", langs.keyname.revenue);
                  }}
                  name="top_adtype"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Orders">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={type1 ? true : ""}
                  onChange={(e) => {
                    this.setState({ type1: true });
                    this.getadTypeReport(4, 1, "", "", langs.keyname.orders);
                  }}
                  name="top_adtype"
                />
              </RadioButtonRP>
            </div>
          </Col>
          <div className="col-md-12">
            <Field
              name="date_range"
              options={duration}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                if (e.target.value === "1") {
                  this.setState({ dateVisible: true });
                } else {
                  this.setState({ dateVisible: false });
                  this.props.change("from", "");
                  this.props.change("to", "");
                  this.applyFilterOnAdType(e.target.value);
                }
              }}
              component={renderSelectField}
            />
          </div>
          {dateVisible && (
            <Col xs="12">
              <Row>
                <Col md="6">
                  <Field
                    name="from"
                    label="From"
                    //minDate={endDate}
                    onChange={(e) => {
                      this.props.change("to", "");
                      this.setState(
                        { startDate: e, endDate: "", dateVisible: true },
                        () => {
                          this.applyFilterOnAdType(e);
                        }
                      );
                    }}
                    component={renderDatePicker}
                  />
                </Col>
                <Col md="6">
                  <Field
                    name="to"
                    label="To "
                    minDate={startDate}
                    onChange={(e) => {
                      this.setState({ endDate: e, dateVisible: true }, () => {
                        this.applyFilterOnAdType(e);
                      });
                    }}
                    component={renderDatePicker}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="w-100">
          <Col>
            <Table responsive>
              <thead style={{ backgroundColor: "#dcdcdccc" }}>
                <tr>
                  <th>#</th>
                  <th>Plan Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              {this.renderAdTypePlanRecord(adTypeReportData)}
            </Table>
          </Col>
        </Row>
      </CountFigureBox>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { paymentReportDuck } = store;
  const { auth } = store;
  const { npcTopAdtype } = paymentReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    adTypeReportData:
      npcTopAdtype && Array.isArray(npcTopAdtype.topAdType)
        ? npcTopAdtype.topAdType
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeRecords",
    })(AdTypeRecords)
  )
);
