import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import { toastr } from "react-redux-toastr";
import "../Chart.scss";
import { Input, Label, Row, Col } from "reactstrap";
import { renderSelectField } from "../../../../../common/layout/FormInput";
import FilterRecords from "../FilterRecords";
import { DATE_RANGE1 } from "../../../../../config/constant";
import * as companySummaryDuck from "../../../../../store/ducks/super-admin/CompanySummary.duck";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import FooterLoader from "../../../../../common/FooterLoader";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import CountFigureBox from "../../../../../common/CountFigureBox";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

let label = getCurrentDate();
let chartDetail = ["Total Company"];
class CompanyContribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localLoading: false,
      totalRevenue: ["Total"],
      type: false,
      duration: DATE_RANGE1,
      dateVisible: false,
      startDate: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
      selectedCompany: this.props.companyList.length
        ? this.props.companyList[0]
        : 4,
      initialNpcId: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentWillMount() {
    this.getCompanyList();
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanyList = () => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: 100,
      page: 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ initialNpcId: res.data.rows[0].id }, () => {
          this.getCompanySummary(4, 1, this.state.initialNpcId, "", "", "", "");
        });
      }
    });
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanySummary = (
    type,
    data,
    npcType,
    month = "",
    year = "",
    from_date = "",
    to_date = ""
  ) => {
    this.setState(
      { salesType: data, npcType: npcType, localLoading: true },
      () => {
        chartDetail = ["Total Company"];
        let requestData = {
          npc_id: npcType ? npcType : 4,
          month: month,
          year: year,
          from_date: from_date,
          to_date: to_date,
          type,
          data,
        };
        this.props.getCompanySummaryData(requestData, type, (res) => {
          this.setState({ localLoading: false });
          if (res.status === STATUS_CODES.OK) {
            this.setState({ openModal: false });
          }
        });
      }
    );
  };

  /**
   * @method FilterRecords
   * @description  called after rendering the component
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const { npcType, salesType } = this.state;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    this.setState({
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    label = data.label;
    this.getCompanySummary(
      4,
      salesType,
      npcType,
      data.this_month,
      data.this_year,
      data.date,
      data.date2
    );
  }

  /**
   * @method render
   * @description  render component
   */
  render() {
    const {
      type,
      openModal,
      npcType,
      salesType,
      selectedOption,
      localLoading,
    } = this.state;
    const { module4Data, companyList } = this.props;
    chartDetail = ["Total Company", module4Data && module4Data];
    return (
      <CountFigureBox alignStart={true}>
        <h5>Companies Contribution in Total Sales</h5>
        <div className="row">
          <div className="col-md-8">
            <Row>
              <Col md="4" className="boldText mt-5 text-left">
                Select Ad Type
              </Col>
              <Col md="6" className="mt-3 text-left">
                <Field
                  name="adtype_list"
                  options={companyList && companyList}
                  isSelect={false}
                  optionValue={"id"}
                  optionLabel={"newspaper_name"}
                  onChange={(e) => {
                    this.getCompanySummary(
                      4,
                      salesType,
                      e.target.value,
                      "",
                      "",
                      "",
                      ""
                    );
                  }}
                  component={renderSelectField}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4" className="boldText text-left">
                Select criteria
              </Col>
              <Col md="8" className="boldText text-left">
                <div className="radio-inline">
                  <RadioButtonRP label="Revenue">
                    <Input
                      type="radio"
                      id="checkbox2"
                      checked={!type ? true : ""}
                      onChange={(e) => {
                        this.setState({ type: false });
                        this.getCompanySummary(4, 1, npcType, "", "", "", "");
                      }}
                      name="ad_type"
                    />
                  </RadioButtonRP>
                  <RadioButtonRP label="Orders">
                    <Input
                      type="radio"
                      id="checkbox2"
                      checked={type ? true : ""}
                      onChange={(e) => {
                        this.setState({ type: true });
                        this.getCompanySummary(4, 0, npcType, "", "", "", "");
                      }}
                      name="ad_type"
                    />
                  </RadioButtonRP>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="4" className="boldText text-left">
                Select Duration
              </Col>
              <Col md="8" className="text-left">
                <h6
                  className="cursorPointer"
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}
                  &nbsp;&nbsp;
                  <i
                    style={{ fontSize: 15 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </h6>
              </Col>
            </Row>
          </div>
          <div className="col-md-4">
            {localLoading && (
              <div style={{ marginTop: 50 }}>
                <h4 style={{ textAlign: "center" }}>Loading...</h4>
                <FooterLoader />
              </div>
            )}
            <div>
              {!localLoading && chartDetail.length > 1 && chartDetail[1] ? (
                <C3Chart
                  unloadBeforeLoad={true}
                  size={{
                    height: 300,
                  }}
                  data={{
                    columns: [chartDetail, ["Other", 100 - chartDetail[1]]],
                    type: "pie",
                  }}
                  pie={{
                    label: {
                      format: function(value, ratio, id) {
                        return Math.round(value);
                      },
                    },
                  }}
                />
              ) : (
                <div style={{ marginTop: 50 }}>
                  {localLoading === false && "No Record Found"}
                </div>
              )}
            </div>
          </div>
        </div>

        {openModal && (
          <FilterRecords
            applyFilter={this.FilterRecords}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </CountFigureBox>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading, companyList } = store.commonDuck;
  const { auth } = store;
  const { companySummaryDuck } = store;
  return {
    loggedInUser: auth.user,
    loading,
    companyList,
    module4Data: companySummaryDuck.moduleData4,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...companySummaryDuck.actions,
    ...commonDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "CompanyContribution",
      destroyOnUnmount: false,
    })(CompanyContribution)
  )
);
