import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { Button } from "@material-ui/core";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { MESSAGES } from "../../../../config/message";
import { checkPermission } from "../../../../common/CheckPermissions";
import { langs } from "../../../../config/localization";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { phoneNumberFormate } from "../../../../common";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { ViewIconLink, GrantIconButton } from "../../../../common/Buttons";

class ArchiveListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditFlag: true,
      isRedirect: false,
      rows: [],
      renderNewAdminForm: false,
      adminList: [],
      show: false,
      error: false,
      success: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      showSearchResults: false,
      message: "",
      searchKey: "",
    };
  }

  /**
   * @method componentDidMount
   * @description Called after rendering the component
   */
  componentDidMount() {
    this.getArchiveCompanyListing(this.state.pageSize, 1);
  }

  /**
   * @method onPageChange
   * @description called on page change
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getArchiveCompanyListing(this.state.pageSize, page + 1);
  };

  /**
   * @method onPageSizeChange
   * @description called on page size change
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize });
    this.getArchiveCompanyListing(pageSize, this.state.page);
  };

  /**
   * @method handleSearch
   * @description called to handle search
   */
  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
    } else if (e.target.value.length >= 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 1,
        status: 2,
      };
      this.props.searchInNewspaperList(requestData);
    }
  };

  /**
   * @method getArchiveCompanyListing
   * @description Called for getting listing of newspaper company
   */
  getArchiveCompanyListing = (row, page) => {
    const { user_detail } = this.props.loggedInUser;
    let temp = [];
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      status: 0,
      is_deleted: 1,
    };
    this.props.getAllCompanyArchieveList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          companyList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method activateAdmin
   * @description Activate Admin from Archive Listing and put It into  All admin List
   */
  activateCompany = (companyDetails) => {
    const { pageSize, searchKey, showSearchResults } = this.state;
    let requestData = {
      user_id: companyDetails.user_id,
      id: companyDetails.id,
    };
    this.props.activateCompanyById(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ACTIVE_COMPANY_SUCCESS);
        this.getArchiveCompanyListing(pageSize, 1);
        if (showSearchResults) {
          let requestData = {
            searchKey: searchKey,
            is_deleted: 1,
            status: 2,
          };
          this.props.searchInNewspaperList(requestData);
        }
      }
    });
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = () => {
    const createPermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.create
    );
    const columnOrder = [
      {
        Header: "Newspaper",
        Cell: (row) => {
          const { original } = row;
          const imageURL = original && original.user && original.user.image_url;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="mr-2 ml-2"
                src={
                  imageURL
                    ? `${PROFILE_MEDIA_URL}/${imageURL}`
                    : require("../../../../../assets/images/defaultUser.png")
                }
                alt=""
                style={{
                  borderRadius: "100%",
                  height: 30,
                  width: 35,
                  marginLeft: 6,
                }}
              />
              &nbsp;&nbsp;
              {/* <font className='text-truncate'>{`${original.newspaper_name}`}</font> */}
              {original.status === 2 ? (
                <Link
                  className="text-truncate"
                  to={`/viewRequest/${original.id}`}
                >
                  {original.newspaper_name}
                </Link>
              ) : (
                <Link
                  className="text-truncate"
                  to={`/viewcompany/${original.id}`}
                >
                  {original.newspaper_name}
                </Link>
              )}
            </div>
          ); // Custom cell components!)
        },
      },
      {
        Header: "Name",
        Cell: (row) => {
          const { original } = row;
          return (
            <span>{`${original.user.first_name} ${original.user.last_name}`}</span>
          ); // Custom cell components!)
        },
      },
      {
        Header: "Email",
        Cell: (row) => {
          const { original } = row;
          return <span>{`${original.user.email}`}</span>; // Custom cell components!)
        },
      },
      {
        Header: "Phone-number",
        accessor: "role",
        Cell: (row) => {
          const { original } = row;
          return (
            <span>{`${phoneNumberFormate(original.user.phone_number)}`}</span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.status === 2 ? (
            <span style={{ color: "red" }}>Rejected</span>
          ) : (
            <span style={{ color: "red" }}>De-active</span>
          );
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        Cell: (row) => {
          const { original } = row;
          if (createPermission) {
            return original.status === 2 ? (
              <ViewIconLink to={`/viewRequest/${row.original.id}`} />
            ) : (
              <GrantIconButton
                title="Activate"
                onClick={() => this.activateCompany(row.original)}
              />
            );
          } else {
            return <div></div>;
          }
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { loading, searchResults, archiveCompanyList } = this.props;
    const { showSearchResults, totalCount } = this.state;
    const viewPermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.view
    );

    let results;
    if (searchResults && searchResults.length && showSearchResults === true) {
      results = searchResults;
    } else if (searchResults === undefined) {
      results = [];
    } else if (searchResults.length === 0 && showSearchResults === true) {
      results = [];
    } else {
      results = archiveCompanyList;
    }

    return (
      <div>
        {loading && <Loader />}
        {viewPermission ? (
          <StandardTable
            handelSearch={this.handleSearch}
            columnOrder={this.renderRecords()}
            totalCount={totalCount}
            allData={this.state.archiveCompanyList}
            data={results}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          />
        ) : (
          <div className="text-center" style={{ marginTop: "10px" }}>
            <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
          </div>
        )}
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, newsPaperCompanyDuck, authToken } = store;
  const { loading } = store.commonDuck;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    archiveCompanyList:
      newsPaperCompanyDuck && newsPaperCompanyDuck.archiveCompanyList,
    searchResults:
      newsPaperCompanyDuck &&
      newsPaperCompanyDuck.searchResultsforArchieveNewspaper,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, NewsPaperCompanyDuck.actions)(ArchiveListing)
);
