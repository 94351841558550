import React, { useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { merge } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';
import Notice from '../../../../partials/content/Notice';
import AllAdminListing from './AllAdminListing'
import ArchiveListing from './ArchiveListing'
import { langs } from '../../../../config/localization';
import { checkPermission } from '../../../../common/CheckPermissions';
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import { metronic, initLayoutConfig, LayoutConfig } from '../../../../../_metronic';
import {
    Portlet,
    PortletBody,
} from '../../../../partials/content/Portlet';

const localStorageActiveTabKey = 'builderActiveTab';
export default function AdminListing() {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const createPer = checkPermission(langs.permissionKeys.admin, langs.permissionKeys.create)
    const [tab, setTab] = useState(activeTab ? +0 : 0);
    const dispatch = useDispatch();
    const { layoutConfig } = useSelector(
        ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
        shallowEqual
    );
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [loadingButtonPreviewStyle, setLoadingButtonPreviewStyle] = useState({
        paddingRight: '2.5rem'
    });
    const [loadingReset, setLoadingReset] = useState(false);
    const [loadingButtonResetStyle, setLoadingButtonResetStyle] = useState({
        paddingRight: '2.5rem'
    });

    const enableLoadingPreview = () => {
        setLoadingPreview(true);
        setLoadingButtonPreviewStyle({ paddingRight: '3.5rem' });
    };
    const enableLoadingReset = () => {
        setLoadingReset(true);
        setLoadingButtonResetStyle({ paddingRight: '3.5rem' });
    };
    const updateLayoutConfig = _config => {
        dispatch(metronic.builder.actions.setLayoutConfigs(_config));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const initialValues = useMemo(
        () =>
            merge(
                // Fulfill changeable fields.
                LayoutConfig,
                layoutConfig
            ),
        [layoutConfig]
    );
   

    return (
        <>
            <Notice>
                <Tabs
                    component='div'
                    className='builder-tabs'
                    value={tab}
                    onChange={(_, nextTab) => {
                        setTab(nextTab);
                        localStorage.setItem(localStorageActiveTabKey, nextTab);
                    }}
                >
                    <Tab label='All Admins' />
                    {/* <Tab label='Archive Listing' /> */}
                    <LayoutContextConsumer >
                        {({ subheader: { title } }) => (
                            <div style={{ marginTop: 25, position: 'absolute', right: 10 }}>
                                {createPer && <h5 className='kt-subheader__title col-md-12  d-flex justify-content-end' ><Link style={{ color: 'black' }} to='/addAdmin'><span >+ New Admin</span></Link></h5>}
                            </div>
                        )}
                    </LayoutContextConsumer>
                </Tabs>
            </Notice>
            <Portlet>
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                        enableLoadingPreview();
                        updateLayoutConfig(values);
                    }}
                    onReset={() => {
                        enableLoadingReset();
                        updateLayoutConfig(initLayoutConfig);
                    }}
                >
                    {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
                        <div className='kt-form kt-form--label-right'>
                            <Portlet>
                                {tab === 0 && (
                                    <PortletBody>
                                        <AllAdminListing                                         
                                        />
                                    </PortletBody>
                                )}

                                {/* {tab === 1 && (
                                    <PortletBody>
                                        <ArchiveListing                                    
                                        />
                                    </PortletBody>
                                )} */}
                            </Portlet>
                        </div>
                    )}
                </Formik>
            </Portlet>
        </>
    );
}
