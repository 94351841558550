import React from "react";
import { BASE_URL } from "../config";
import moment from "moment";
import { Button } from "react-bootstrap";
import { convertISOToUtcDateformate2 } from "./index";

/**
 * @method download
 * @description  download image module
 */
export const download = (imageName) => {
  var pom = document.createElement("a");
  pom.setAttribute(
    "href",
    "data:application/octet-stream," + encodeURIComponent(imageName)
  );
  pom.setAttribute("download", imageName);
  pom.style.display = "none";
  document.body.appendChild(pom);
  pom.click();
  document.body.removeChild(pom);

  return (
    <a href={`${BASE_URL}/logo192.png`} download>
      <img
        src={`${BASE_URL}/logo192.png`}
        alt="W3Schools"
        width="104"
        height="142"
      />
    </a>
  );
};

/**
 * @method DownloadImageButton
 * @description  download image button module
 */
export const DownloadImageButton = (props) => {
  const downloadImage = () => {
    fetch(`${BASE_URL}/${props.imageName}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "fileName.pdf";
        a.click();
      });
    });
  };
  if (!props.imageName) return null;
  return (
    <Button className="btn-rp-primary mt-3 mx-4" onClick={downloadImage}>
      Download Ad
    </Button>
  );
};

/**
 * @method selectedFilterValue
 * @description return selected date values
 */
export const selectedFilterValue = (value) => {
  let today = new Date();
  var currentDate = new Date();
  var dd = String(currentDate.getDate()).padStart(2, "0");
  var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = currentDate.getFullYear();

  currentDate = mm + "/" + dd + "/" + yyyy;
  var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6
  var firstday = new Date(today.setDate(first)).toUTCString();
  var lastday = new Date(today.setDate(last)).toUTCString();
  firstday = convertISOToUtcDateformate2(firstday);
  lastday = convertISOToUtcDateformate2(lastday);
  var this_week = {
    firstday,
    lastday,
  };

  //Change it so that it is 7 days in the past.
  var pastDate = today.getDate() - 7;
  var lastWeek = today.setDate(pastDate);
  var lastWeekFirstDay = moment(
    moment(lastWeek).format("MM/DD/YYYY"),
    "MM/DD/YYYY"
  )
    .subtract(6, "days")
    .format("MM/DD/YYYY");
  var lastWeekLastDay = moment(lastWeek).format("MM/DD/YYYY");
  var last_week = {
    lastWeekFirstDay,
    lastWeekLastDay,
  };

  if (value === "0") {
    return today.getMonth() + 1;
  }
  if (value === "1") {
    return "Custom";
  }
  if (value === "2") {
    return currentDate;
  }
  if (value === "3") {
    return this_week;
  }
  if (value === "4") {
    return last_week;
  }
  if (value === "5") {
    return today.getFullYear();
  }
  if (value === "6") {
    return today.getFullYear() - 1;
  }
  if (value === "7") {
    return currentDate;
  }
  if (value === "8") {
    return "";
  }
  if (value === "9") {
    return "";
  }
  if (value === "10") {
    return today.getMonth();
  }
};

/**
 * @method getCurrentMonth
 * @description return current month
 */
export const getCurrentMonth = () => {
  var my_date = new Date();
  var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
  var f_date = convertISOToUtcDateformate2(first_date);
  var last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
  var l_date = convertISOToUtcDateformate2(last_date);

  return f_date + " to " + l_date;
};

/**
 * @method getCurrentDate
 * @description return current date
 */
export const getCurrentDate = () => {
  var currentDate = new Date();
  var dd = String(currentDate.getDate()).padStart(2, "0");
  var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = currentDate.getFullYear();

  currentDate = mm + "/" + dd + "/" + yyyy;
  return currentDate;
};

/**
 * @method getCurrentWeek
 * @description return current week
 */
export const getCurrentWeek = () => {
  let today = new Date();
  var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6
  var firstday = new Date(today.setDate(first)).toUTCString();
  var lastday = new Date(today.setDate(last)).toUTCString();
  firstday = convertISOToUtcDateformate2(firstday);
  lastday = convertISOToUtcDateformate2(lastday);
  return firstday + " to " + lastday;
};
