import React, {useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import {
    Portlet,
    PortletBody,
} from '../../../../partials/content/Portlet';
import Notice from '../../../../partials/content/Notice';
import Alleditagenteditlisiting from './Alleditagenteditlisiting'
import Alldisabledagentlisting from './Alldisabledagentlisting'

const localStorageActiveTabKey = 'builderNewspaperActiveTab';

export default function AdminListing(props) {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [tab, setTab] = useState(activeTab ? +0 : 0);
   
    return (
        <>
            <Notice>
                <Tabs
                    component='div'
                    className='builder-tabs'
                    value={tab}
                    onChange={(_, nextTab) => {                   
                        setTab(nextTab);
                    }}
                >
                    <Tab label='Edited Price List' />
                    <Tab label='Disabled Accounts' />
                </Tabs>
             </Notice>
             <Portlet>
                <div className='kt-form kt-form--label-right'>
                    <Portlet>
                        {tab === 0 && (
                            <PortletBody>
                                < Alleditagenteditlisiting
                                    tab={0}
                                    {...props} 
                                />
                            </PortletBody>
                        )}
                        {tab === 1 && (
                            <PortletBody>
                                <Alldisabledagentlisting 
                                {...props} 
                                />
                            </PortletBody>
                        )}
                    </Portlet>
                </div>
            </Portlet>
        </>
    )
}