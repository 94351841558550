import React, { useEffect, useState } from "react";

import Nav from "./Nav";
import Footer from "./Footer";
import FormupTwo from "./Bankinfo/FormupTwo";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as NewsPaperCompanyDuck from "../../store/ducks/super-admin/NewsPaperCompany.duck";
import * as npcDuck from "../../store/ducks/npc.duck";
import * as commonDuck from "../../store/ducks/Common.duck";
import { STATUS_CODES } from "../../common/StatusCode";
import { injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { rest } from "lodash";
import AfterSubmit from "./Bankinfo/AfterSubmit";

function BankInfoTwo(props) {
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search)
  const linkToken = queryParams.get("link-token");
  let [isPayment, setisPayment] = useState(1);
  let [data, setdata] = useState([]);
  let [isLinkExpired, setIsLinkExpired] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setisPayment("true");
    const requestData = {
      company_id: id,
    };
    props.getBankDetailsById(requestData, (res) => {
      setdata(res);
    });
    props.getLinkExpired(linkToken, (res) => {
      setIsLinkExpired(res.data);
    });
  }, []);

  return (
    <div>
      <Nav isPayment={isPayment} />
      {props.selectedCompany != undefined && isLinkExpired ? (
        <FormupTwo selectedCompany={props.selectedCompany} />
      ) : (
        <AfterSubmit />
      )}
      <Footer />
    </div>
  );
}

const mapStateToProps = (store) => {
  const { auth, authToken } = store;
  console.warn(store.newsPaperCompanyDuck.selectedCompany);
  const selectedCompany = store.newsPaperCompanyDuck.selectedCompany;
  console.warn("Selected Company", selectedCompany);
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    selectedCompany: selectedCompany,
  };
};

export default connect(
  mapStateToProps,
  NewsPaperCompanyDuck.actions
)(BankInfoTwo);
