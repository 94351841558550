import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { toastr } from "react-redux-toastr";
import { CSVLink } from "react-csv";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { OrderReportHeaders } from "../../../../../common/CsvFormate";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";
import * as orderReportDuck from "../../../../../store/ducks/super-admin/report-management/OrderReport.duck";
import FilterRecords from "../FilterRecords";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { PROFILE_MEDIA_URL } from "../../../../../config";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";

let label = getCurrentDate();
class OrderReportList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
      openModal: false,
      sortBy: "",
      column: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getOrderReport(this.state.pageSize, 1, "", "");
  }

  /**
   * @method handleListCount
   * @description  ReArrabge API res according to length count
   */
  handleListCount = (res) => {
    let temp = [];
    if (res.length <= 4) {
      temp = res.slice(0, res.length);
      this.setState({ orderList: temp });
    } else {
      let len = !this.state.viewAll ? 4 : res.length;
      temp = res.slice(0, len);
      this.setState({ orderList: temp });
    }
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderReport = (row, page, sort, column) => {
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 5,
      section: 3,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.props.getOrderReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.dataGrid) &&
          this.handleListCount(res.data.dataGrid);
      }
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getOrderReport(this.state.pageSize, 1, "", "");
      }
    );
  };

  /**
   * @method filterRecord
   * @description  called after rendering the component
   */
  filterRecord = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      row: this.state.pageSize,
      page: this.state.page,
      month: data.this_month,
      year: data.this_year,
      type: 5,
      section: 3,
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.props.getOrderReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.dataGrid) &&
          this.handleListCount(res.data.dataGrid);
        this.setState({
          fromDate: data.date,
          toDate: data.date2,
          month: data.this_month,
          year: data.this_year,
          openModal: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  }

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const {
      pageSize,
      count,
      entries,
      fromDate,
      toDate,
      month,
      year,
    } = this.state;
    const { orderList } = this.props;
    if (this.state.viewAll) {
      if (orderList.length) {
        this.setState({ loader: false }, () => {
          let requestData = {
            row: pageSize + entries,
            page: 1,
            type: 5,
            section: 3,
            month: month ? month : "",
            year: year ? year : "",
            from_date: fromDate ? fromDate : "",
            to_date: toDate ? toDate : "",
          };
          orderList.length < count &&
            this.props.getOrderReportDetail(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  count: res.data.orderList.length,
                });
                setTimeout(() => {
                  this.setState({ localLoading: false });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({
      sortedOrder: !sortedOrder,
      sortBy: sortBy,
      column: column.id,
    });
    this.getOrderReport(this.state.pageSize, 1, sortBy, column.id);
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Order#",
        accessor: "order_id",
        Cell: (row) => {
          const { original } = row;
          return original && original.order_id;
        }, // String-based value accessors!
      },
      {
        Header: "Customer Name",
        accessor: "first_name",
        Cell: (row) => {
          const { original } = row;
          return original && original.first_name
            ? original.first_name + " " + original.last_name
            : "";
        },
      },
      {
        Header: "Newspaper Company",
        accessor: "newspaper_name",
        Cell: (row) => {
          const { original } = row;
          const imageURL = original && original.image_url;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="mr-2 ml-2"
                src={
                  imageURL
                    ? `${PROFILE_MEDIA_URL}/${imageURL}`
                    : require("../../../../../../assets/images/defaultUser.png")
                }
                alt=""
                style={{
                  borderRadius: "100%",
                  height: 30,
                  width: 35,
                  marginLeft: 6,
                }}
              />
              {original.newspaper_name}
            </div>
          );
        },
      },
      {
        Header: "Total Order Amount",
        accessor: "order_amount",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>
              ${original && original.order_amount}
            </div>
          );
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.payment_status;
        },
      },
      {
        Header: "Order Status",
        accessor: "order_status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.order_status;
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      viewAll,
      page,
      pageSize,
      localLoading,
      openModal,
      selectedOption,
      orderList,
    } = this.state;
    // const { orderList } = this.props;
    const printPer = checkPermission(
      langs.permissionKeys.order,
      langs.permissionKeys.print
    );
    const headers = OrderReportHeaders;
    let data =
      Array.isArray(orderList) &&
      orderList.map((item) => {
        return {
          ...item,
          ...{ full_name: item.first_name + " " + item.last_name },
        };
      });

    return (
      <div style={{ marginTop: -15 }}>
        <Portlet fluidHeight={true}>
          <PortletBody className="px-4" ref={(el) => (this.componentRef = el)}>
            <div className="row ">
              <div className="col-md-12 mt-5">
                <h5 className="float-left">Order Details</h5>
                <h5 className="float-right border-bottom-0 border-primary">
                  <CSVLink
                    filename={"OrderDetails.csv"}
                    data={data}
                    headers={headers}
                    style={{ borderBottom: "1px solid" }}
                  >
                    Export CSV
                  </CSVLink>
                </h5>
                {printPer && (
                  <h5 className="float-right mr-4">
                    <Link>
                      <ReactToPrint
                        trigger={() => (
                          <a href="#" style={{ borderBottom: "1px solid" }}>
                            Print
                          </a>
                        )}
                        content={() => this.componentRef}
                      />
                    </Link>
                  </h5>
                )}
                <h5
                  className="float-right mr-4 cursorPointer"
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}
                  &nbsp;&nbsp;
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </h5>
              </div>
            </div>
            <div className="row" style={{ border: "1px solid black" }}></div>
            <div className="row">
              <div className="col-12">
                <InfiniteScroll
                  dataLength={orderList.length}
                  next={this.fetchMoreData}
                  loader={
                    localLoading && (
                      <h4 style={{ textAlign: "center" }}>Loading...</h4>
                    )
                  }
                  hasMore={true}
                  style={{ overflow: "unset" }}
                >
                  <StandardTable
                    handelSearch={this.handleSearch}
                    columnOrder={this.renderRecords()}
                    handleSort={this.handleSort}
                    sortable={false}
                    onPageSizeChange={this.onPageSizeChange}
                    showSearch={false}
                    data={data}
                    pagination={false}
                  />
                </InfiniteScroll>
                {data && data.length >= 4 && !viewAll && (
                  <div
                    className="my-4"
                    onClick={() => {
                      this.getOrderReport(pageSize, page, "", "");
                      this.setState({ viewAll: true, localLoading: true });
                      setTimeout(() => {
                        this.setState({ localLoading: false });
                      }, 3000);
                    }}
                  >
                    <p className="cursorPointer float-right">View All</p>
                  </div>
                )}
                {localLoading && <FooterLoader />}
              </div>
            </div>
          </PortletBody>
        </Portlet>
        {openModal && (
          <FilterRecords
            applyFilter={this.filterRecord}
            isOpen={openModal}
            onCancel={() => this.setState({ openModal: false })}
            type={3}
            selectedOption={selectedOption}
            resetList={() => this.getOrderReport()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { orderReportDuck } = store;
  const { orderReportList } = orderReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    orderList:
      orderReportList &&
      orderReportList.dataGrid &&
      Array.isArray(orderReportList.dataGrid)
        ? orderReportList.dataGrid
        : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...orderReportDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "OrderReportList",
    })(OrderReportList)
  )
);
