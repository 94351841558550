import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./Modal.scss";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import { injectIntl } from "react-intl";
import NewspaperAdvanceSearch from "./super-admin/newspaper-management/AdvanceSearch";
import NPCAdvanceSearch from "./npc/order-management/OrderListAdvanceSearch";
import SuperAdminAdvanceSearch from "./super-admin/order-management/OrderListAdvanceSearch";
import PlanAdvancedSearch from "./npc/ourPlans/PlanAdvancedSearch";
import CreditAdvancedSearch from "./npc/credit/AdvancedSearchCredit";
import { UncontrolledCollapse } from "reactstrap";
import { PAGES_25, PAGES_50, PAGES_100 } from "../../config/constant";

const tableStyle = {
  border: "none",
  boxShadow: "none",
};

class StandardTabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: [],
      searchTempResult: [],
      pageSize: PAGES_25,
      inputFocus: false,
      pageNo: 0,
    };
  }

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    this.setState({ searchParam: [...this.state.searchParam, e.target.value] });
    this.props.handelSearch(e);
  };

  /**
   * @method renderPageSize
   * @description used to render page size
   */
  renderPageSize = () => {
    const { pageSize } = this.state;
    const { data } = this.props;
    if (data && data.length) {
      if (pageSize > data.length) {
        return data.length;
      } else {
        return pageSize;
      }
    } else {
      return 10;
    }
  };

  /**
   * @method renderAdvanceSearch
   * @description used to render Advance Search condition by props
   */
  renderAdvancedSearchView = () => {
    const { advancedSearchFor, history } = this.props;
    switch (advancedSearchFor) {
      case "SUPERADMIN_ORDER_LIST":
        return <SuperAdminAdvanceSearch history={history} />;
      case "NPC_ORDER_LIST":
        return <NPCAdvanceSearch history={history} />;
      case "PLAN":
        return <PlanAdvancedSearch history={history} />;
      case "CREDIT":
        return <CreditAdvancedSearch history={history} />;
      default:
        return <NewspaperAdvanceSearch history={this.props.history} />;
    }
  };
  checkShowPagination = () => {
    const { pagination, data } = this.props;
    const { pageSize, pageNo } = this.state;

    if (!pagination) return false;
    let show =
      pageNo > 0
        ? true
        : Array.isArray(data) && data.length && data.length >= pageSize
        ? true
        : false;
    return show;
  };
  /**
   * @method render
   * @description render the component
   */
  render() {
    const { pageSize, pageNo } = this.state;
    const {
      showPagination = true,
      isVisible = true,
      customerRequestList = false,
      display = "block",
      pagination,
      showPageSizeOptions = true,
      showSearch = true,
      isAdvanceSearch,
      data,
      columnOrder,
      onPageChange,
      totalCount,
      history,
      handleSort,
    } = this.props;
    let show =
      pageNo > 0
        ? true
        : Array.isArray(data) && data.length && data.length >= pageSize
        ? true
        : false;
    if (!pagination) show = false;

    const {
      sortable = false,
      getTrProps = (state, rowInfo, column) => {
        return {
          style: {
            alignItems: "center",
          },
        };
      },
    } = this.props;
    return (
      <div className="w-100">
        <div className=" row my-3">
          {showPagination && (
            <div className="col-sm-6 mb-2">
              <div className="table-page-size">
                <span>Show</span>
                <div>
                  <select
                    className="form-control"
                    style={{ width: 70, height: 40 }}
                    value={pageSize}
                    onChange={(e) => {
                      this.setState({ pageSize: e.target.value });
                      this.props.onPageSizeChange(e.target.value);
                    }}
                  >
                    <option value={PAGES_25}>{PAGES_25}</option>
                    <option value={PAGES_50}>{PAGES_50}</option>
                    <option value={PAGES_100}>{PAGES_100}</option>
                  </select>
                </div>
                <span>entries</span>
              </div>
            </div>
          )}
          {customerRequestList && (
            <div className="col-sm-6 mb-2">
              <div className="table-page-size">
                <span>Show</span>
                <div>
                  <select
                    className="form-control"
                    style={{ width: 70, height: 40 }}
                    value={pageSize}
                    onChange={(e) => {
                      this.setState({ pageSize: e.target.value });
                      this.props.onPageSizeChange(e.target.value);
                    }}
                  >
                    <option value={PAGES_25}>{PAGES_25}</option>
                    <option value={PAGES_50}>{PAGES_50}</option>
                    <option value={PAGES_100}>{PAGES_100}</option>
                  </select>
                </div>
                <span>entries</span>
              </div>
            </div>
          )}
          {!isAdvanceSearch && (
            <>
              <div className="col-sm-6 mb-2">
                {showSearch && (
                  <InputGroup
                    size="sm"
                    className="d-flex justify-content-sm-end justify-content-center search-bar"
                    onFocus={() => {
                      this.setState({ inputFocus: true });
                    }}
                  >
                    <FormControl
                      style={{ marginRight: 20, height: 35, maxWidth: '100%' }}
                      placeholder="Search"
                      aria-label="search"
                      aria-describedby="basic-addon1"
                      onChange={(e) => this.handleSearch(e)}
                    />
                    <i className="flaticon-search"></i>
                  </InputGroup>
                )}
              </div>
            </>
          )}
          {this.props.isAdvanceSearch && (
            <div className="col-sm-6 d-flex align-items-center">
              <InputGroup
                size="sm"
                className="d-flex justify-content-end search-bar"
                onFocus={() => {
                  this.setState({ inputFocus: true });
                }}
              >
                <FormControl
                  style={{ marginRight: 20, height: 35, maxWidth: '100%' }}
                  placeholder="Search"
                  aria-label="search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => this.handleSearch(e)}
                />
                <i className="flaticon-search"></i>
              </InputGroup>
              <Button id="toggler">Advance Search</Button>
            </div>
          )}
        </div>
        <div className="row">
          {this.props.isAdvanceSearch && (
            <UncontrolledCollapse toggler="#toggler" className="w-100">
              {this.renderAdvancedSearchView()}
            </UncontrolledCollapse>
          )}
          <hr />
        </div>
        <Divider />
        <div className="row">
          <div className="col-xl-12" style={{ margin: 5 }}>
            <ReactTable
              disableResizing={false}
              data={data}
              columns={columnOrder && columnOrder}
              showPagination={
                isVisible
                  ? pageNo > 0
                    ? true
                    : Array.isArray(data) &&
                      data.length &&
                      data.length >= pageSize
                    ? true
                    : false
                  : false
              }
              defaultPageSize={25}
              pageSize={this.renderPageSize()}
              showPageSizeOptions={false}
              onPageChange={onPageChange}
              manual
              style={tableStyle}
              className={data && data.length ? "" : "noborder"}
              noDataText={"No Records Found"}
              pages={totalCount}
              sortable={sortable}
              resizable={false}
              styles={{
                border: "none",
                boxShadow: "none",
              }}
              onFetchData={(pageSize, pageIndex) => {
                this.setState({ pageNo: pageSize.page });
              }}
              getTrProps={getTrProps}
              getTheadThProps={(state, rowInfo, column, instance) => {
                return {
                  style: {
                    height: "auto",
                    whiteSpace: "normal",
                    textAlign: "left",
                    display: display,
                  },
                  onClick: (e) =>
                    handleSort
                      ? handleSort(e, state, rowInfo, column, instance)
                      : "",
                };
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                if (column.Header === "Action") {
                  return {};
                }
                return {
                  style: {
                    height: "auto",
                    borderBottom: "hidden",
                    textAlign: "left",
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    searchResults: store.superAdminDuck.searchResults,
    loading,
  };
};

export default injectIntl(connect(mapStateToProps)(StandardTabel));
