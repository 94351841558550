import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import moment from "moment";
import Calendar from "react-full-event-calendar";
import * as NpcDashboardRecord from "../../../../store/ducks/npc/Dashboard.duck";

class NPCCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getDashboardRecord();
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record
   */
  getDashboardRecord = () => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      type: 4,
      npc_id: user_detail.newspaper.id,
    };
    this.props.getTypeData(requestData, 4, (res) => {});
  };

  /**
   * @method createEvents
   * @description  get all calender events
   */
  createEvents = () => {
    const { npcModule4 } = this.props;
    let events = [];
    npcModule4 !== undefined &&
      npcModule4.calenderView.map((el) => {
        events.push({
          startTime: new Date(moment(el.date)),
          endTime: new Date(moment(el.date)),
          title: "order placed",
        });
      });
    return events;
  };

  render() {
    return (
      <div className="rp-event-calendar">
        <Calendar
          events={this.createEvents()}
          defautlCalendarView="month"
          dayOrientation="vertical"
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { npcModule4, npcModule5 } = store.npcDashboardRecord;

  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    authToken: store.auth.authToken,
    npcModule4,
    npcModule5,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    NpcDashboardRecord.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "NPCCalendar",
      destroyOnUnmount: false,
    })(NPCCalendar)
  )
);
