import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { displayDateTimeFormate } from "../../../../common";
import * as notificationDuck from "../../../../store/ducks/Notifications.duck ";
import { injectIntl } from "react-intl";
import { Media, Row } from "reactstrap";
import NotificationView from "../../../../common/NotificationView";

class NotificationList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  /**
   * @method componentDidMount
   * @description Renders after the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    const requestData = {
      id: "",
      receiver_id: user_detail.id,
    };
    this.props.getNotificationList(requestData, (res) => {});
  }

  /**
   * @method renderList
   * @description used to render list of notification popover
   */
  renderList = () => {
    const { notificationList } = this.props;
    const { showMore } = this.state;
    return (
      notificationList &&
      notificationList.map((data, index) => {
        if (!showMore ? index < 5 : notificationList.length) {
          return (
            <div key={index}>
              <Row>
                <NotificationView
                  data={data}
                  imgSrc={require("../../../../../assets/images/notification-icon5.jpeg")}
                />
              </Row>
              <hr />
            </div>
          );
        }
      })
    );
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    return (
      <div>
        {this.renderList()}
        <Link to="/notifications" onClick={this.closePopOver}>
          <h6
            className="float-right"
            style={{ cursor: "pointer", borderBottom: "1px solid blue" }}
          >
            View All
          </h6>
        </Link>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, authToken, notificationDuck } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    notificationList: notificationDuck.notificationList,
  };
};

export default injectIntl(
  connect(mapStateToProps, notificationDuck.actions)(NotificationList)
);
