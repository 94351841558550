import React, { Fragment, useEffect } from "react";
import Nav from "./Nav";
import Heading from "./landingpage/components/Heading";
import HeroSection from "./landingpage/components/HeroSection";
import Buying from "./landingpage/components/Buying";
import Familier from "./landingpage/components/Familier";
import Process from "./landingpage/components/Process";
import Hype from "./landingpage/components/Hype";
import Counton from "./landingpage/components/Counton";
import FooterSec from "./landingpage/components/FooterSec";
import Video from "./landingpage/components/Video";
import "aos/dist/aos.css";
import Effeciency from "./landingpage/components/Effeciency";
import Slider from "./landingpage/components/Slider";
import MobileEffeciency from "./landingpage/components/MobileEffeciency";
const WelcomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Pijen.com – An end-to-end solution for your media department";
    const linkElement = document.querySelector("link[rel=icon]");
    linkElement.href =
      "https://pijen.com/email-campaign/assets/media/logos/favicon.ico";
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=8aa84ff2-cf5f-4c96-ae2a-bfde6bfdbd6f";
    script.async = true;
    script.id = "ze-snippet";
    document.body.appendChild(script);
    return () => {
      const element = document.getElementById("ze-snippet");
      element.remove();
    };
  });
  return (
    // NEW PAGE LANDING
    <Fragment>
      <Nav />
      <Heading />
      <HeroSection />
      <Buying />
      <Familier />
      <Process />
      <Effeciency />
      <MobileEffeciency />
      <Video />
      <Counton />
      <Hype />
      <FooterSec />
    </Fragment>
  );
};

export default WelcomePage;
