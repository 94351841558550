import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import { toastr } from "react-redux-toastr";
import "../Chart.scss";
import FilterRecords from "../../../npc/reports-management/FilterRecords";
import * as salesReportDuck from "../../../../../store/ducks/super-admin/report-management/SalesReport.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { convertISOToUtcDateformate3 } from "../../../../../common";
import { getCurrentMonth } from "../../../../../common/CommonFunction";
import { Input, Label } from "reactstrap";
import BlankChart from "../../../../../common/BlankChart";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

let dateRange = [];
let label = getCurrentMonth();
class SalesChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ["Revenue"],
      dateAxis: [],
      type: false,
      d: [],
      totalOrders: ["Orders"],
      linevisible: true,
      barvisible: false,
      openModal: false,
      filterType: 0,
      selectedOption: {
        option: 0,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getGraphData(0, 2);
  }

  /**
   * @method getGraphData
   * @description  render graph statical records
   */
  getGraphData = (data, type) => {
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      data: data,
      type: type,
      section: 2,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.setData(requestData, data);
  };

  /**
   * @method setData
   * @description set chart records
   */
  setData = (requestData, data) => {
    this.props.getSalesDetail(requestData, (res) => {

      console.warn("checking Graphical values", res); 
      if (res.status === STATUS_CODES.OK) {
        const { totalOrders, totalRevenue } = this.state;
        let temp = totalRevenue;
        let temp1 = totalOrders;
        let temp2 = totalRevenue;
        let temp3 = totalOrders;
        res.data &&
          Array.isArray(res.data.salesTrend) &&
          res.data.salesTrend.map((item, i) => {
            if (data === 0) {
              temp.push(item.total_amount);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            } else if (data === 1) {
              temp1.push(item.total_orders);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            } else if (data === 2) {
              temp2.push(item.total_amount);
              temp3.push(item.total_orders);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            }
          });
        if (data === 0) {
          this.setState({
            totalRevenue: [...temp],
            linevisible: true,
            barvisible: false,
            filterType: 0,
            openModal: false,
          });
        } else if (data === 1) {
          this.setState({
            totalOrders: [...temp1],
            barvisible: true,
            linevisible: false,
            filterType: 1,
            openModal: false,
          });
        } else if (data === 2) {
          this.setState({
            totalOrders: [...temp2],
            totalRevenue: [...temp3],
            linevisible: true,
            barvisible: true,
            filterType: 2,
            openModal: false,
          });
        }
      } else {
        this.setState({ openModal: false });
      }
    });
  };

  /**
   * @method handleFilter
   * @description  used to handle filtered records
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  used to apply filter on graph data
   */
  applyFilter(values, props) {
    const { filterType } = this.state;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 2,
      data: filterType,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      totalRevenue: ["Revenue"],
      totalOrders: ["Orders"],
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.setData(requestData, filterType);
  }

  /**
   * @method dateAxis
   * @description  used to formate date
   */
  dateAxis = () => {
    return dateRange;
  };

  /**
   * @method revenueSelection
   * @description  used to handle no. of transaction
   */
  revenueSelection = () => {
    this.setState(
      {
        totalRevenue: ["Revenue"],
        totalOrders: ["Orders"],
      },
      () => {
        this.getGraphData(0, 2);
      }
    );
  };

  /**
   * @method orderSelection
   * @description  used to handle amount selection
   */
  orderSelection = () => {
    this.setState(
      {
        totalOrders: ["Orders"],
        totalRevenue: ["Revenue"],
      },
      () => {
        this.getGraphData(1, 2);
      }
    );
  };

  /**
   * @method bothSelection
   * @description  used to handle no. of transaction and amount selection
   */
  bothSelection = () => {
    this.setState(
      {
        totalOrders: ["Orders"],
        totalRevenue: ["Revenue"],
      },
      () => {
        this.getGraphData(2, 2);
      }
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const {
      totalRevenue,
      openModal,
      type,
      totalOrders,
      linevisible,
      barvisible,
      selectedOption,
    } = this.state;
    return (
      <div>
        <div className="row col-md-12">
          <div className="col-12">
            <h5 className="float-left">Sales Trend</h5>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 d-flex-center">
                <div className="mr-4">Select Criteria</div>
                <div className="radio-inline">
                  <RadioButtonRP label="Revenue">
                    <Input
                      type="radio"
                      id="checkbox2"
                      checked={!type ? true : ""}
                      onChange={(e) => {
                        this.setState({
                          type: false,
                          linevisible: true,
                          barvisible: false,
                        });
                        this.revenueSelection();
                      }}
                      name="order_type"
                    />
                  </RadioButtonRP>
                  <RadioButtonRP label="Orders">
                    <Input
                      type="radio"
                      id="checkbox2"
                      onChange={(e) => {
                        this.setState({
                          type: true,
                          barvisible: true,
                          linevisible: false,
                        });
                        this.orderSelection();
                      }}
                      name="order_type"
                    />
                  </RadioButtonRP>
                  <RadioButtonRP label="Both">
                    <Input
                      type="radio"
                      id="checkbox2"
                      onChange={(e) => {
                        this.setState({
                          type: true,
                          linevisible: true,
                          barvisible: true,
                        });
                        this.bothSelection();
                      }}
                      name="order_type"
                    />
                  </RadioButtonRP>
                </div>
              </div>
              <div className="col-md-6 d-flex-center justify-content-md-end">
                <Label className="mr-3 mb-2">Select Durations</Label>
                <h5
                  className="cursorPointer"
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}
                  &nbsp;&nbsp;
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {totalRevenue.length > 1 || totalOrders.length > 1 ? (
          <div style={{ marginTop: 30 }} className="row w-100">
            {linevisible && !barvisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Revenue",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [totalRevenue],
                  type: "line",
                }}
              />
            )}
            {barvisible && !linevisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Orders",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [totalOrders],
                  type: "spline",
                }}
              />
            )}
            {linevisible && barvisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Revenue",
                      position: "outer-middle",
                    },
                  },
                  y2: {
                    show: true,
                    label: {
                      text: "Orders",
                      position: "outer-middle",
                    },
                    min: 0,
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [totalOrders, totalRevenue],
                  axes: {
                    Orders: "y2",
                  },
                  type: "line",
                  types: {
                    Orders: "spline",
                  },
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ marginTop: 30 }} className="row w-100">
            <BlankChart />
          </div>
        )}
        {openModal && (
          <FilterRecords
            applyFilter={this.handleFilter}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    salesReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "SalesChart",
      destroyOnUnmount: false,
    })(SalesChart)
  )
);
