import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";
import { UncontrolledCollapse } from "reactstrap";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import StandardTable from "../../StandardTable";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { Table } from "reactstrap";
import { langs } from "../../../../config/localization";
import { Loader } from "../../../../common/Loader";
import { MESSAGES } from "../../../../config/message";
import { STATUS_CODES } from "../../../../common/StatusCode";
import * as adminCustomerAccessDuck from "../../../../store/ducks/super-admin/AdminCustomerAccess.duck";
import { convertISOToUtcDate } from "../../../../common";
import {
  AddIconButton,
  ViewIconLink,
  DeclineIconButton,
  GrantIconButton,
} from "../../../../common/Buttons";

class AllCustomerList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      customerList: [],
      dummyData: [],
      sortedOrder: true,
      expandable: false,
      sorting: false,
      totalCount: 100,
      page: 1,
      pageSize: 25,
      sortFlag: {
        customer_name: { sort: false },
        customer_number: { sort: false },
        customer_email: { sort: false },
        createdAt: { sort: false },
        status: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.getCustomerList(this.state.pageSize, 1, "", "");
  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getCustomerList = (row, page, sortBy, column) => {
    let requestData = {
      status: "",
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sortBy,
      column: column,
    };
    this.props.getCustomerRequestList(requestData, (res) => {
      let tempArray = [];
      let npcCompany = [];
      if (res.status === STATUS_CODES.OK && res.data && res.data.requests) {
        Array.isArray(res.data.requests) &&
          res.data.requests.map((item, index) => {
            npcCompany.push({ lable: item.newspaper_name, viewDetail: false });
            Object.keys(item.object).map((key) => {
              tempArray.push(res.data.requests[index].object[key]);
            });
          });

        let pages = res.data.count / this.state.pageSize;
        this.setState({ totalCount: Math.ceil(pages) });
      }
      this.setState({ dummyData: npcCompany });
    });
  };

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getSortedCustomerList = (row, page, sortBy, column) => {
    let requestData = {
      status: "",
      sort: sortBy,
      column: column,
      page: page !== undefined ? page : 1,
      row: row !== undefined ? row : 25,
    };
    this.props.getCustomerRequestList(requestData, (res) => {
      let tempArray = [];
      if (res.data) {
        Array.isArray(res.data.requests) &&
          res.data.requests.map((item, index) => {
            Object.keys(item.object).map((key) => {
              tempArray.push(res.data.requests[index].object[key]);
            });
          });
      }
      this.setState({ customerList: tempArray });
    });
  };

  /**
   * @method renderCustomerRecords
   * @description render all order list
   */
  renderCustomerRecords = () => {
    const columnOrder = [
      {
        Header: "Customer Name",
        accessor: "customer_name",
        Cell: (row) => {
          const { original } = row;
          return original.customer_name;
        }, // String-based value accessors!
      },
      {
        Header: "Phone Number",
        accessor: "customer_number",
        Cell: (row) => {
          const { original } = row;
          return original.customer_number;
        },
      },
      {
        Header: "Email",
        accessor: "customer_email",
        Cell: (row) => {
          const { original } = row;
          return <span className="cust_mail">{original.customer_email}</span>;
        },
      },
      {
        Header: "Date of Request",
        accessor: "createdAt",
        Cell: (row) => {
          const { original } = row;
          return convertISOToUtcDate(original.createdAt);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          const { original } = row;
          return original.status;
        },
      },

      {
        Header: (props) => <span>Action</span>,
        // Custom header components!
        sortable: false,
        minWidth: 120,
        Cell: (row) => {
          const { original } = row;
          if (
            original &&
            original.user_table_id !== null &&
            row.original.status === "Pending"
          ) {
            return (
              <div>
                <GrantIconButton
                  title="Grant Access"
                  onClick={() =>
                    this.customerAccessRequest(
                      original,
                      "Approved",
                      MESSAGES.CUSTOMER_REQUEST_ACCEPT
                    )
                  }
                />
                <DeclineIconButton
                  onClick={(e) => {
                    toastr.confirm(`${MESSAGES.REQUEST_DELETE_CONFIRM}`, {
                      onOk: () =>
                        this.customerAccessRequest(
                          original,
                          "Declined",
                          MESSAGES.CUSTOMER_REQUEST_REJECT
                        ),
                      onCancel: () => {
                        console.log("cancel");
                      },
                    });
                  }}
                />
              </div>
            );
          } else if (
            original &&
            original.user_table_id === null &&
            row.original.status === "Pending"
          ) {
            return (
              <AddIconButton
                title="Add New Customer"
                onClick={() => this.checkCustomerExits(original)}
              />
            );
          } else if (
            original &&
            original.user_table_id !== null &&
            row.original.status === "Approved"
          ) {
            return (
              <ViewIconLink
                title="View Detail"
                to={{
                  pathname: `/adtype-detail/${original.permission_id}`,
                  state: {
                    customerDetail: row.original,
                  },
                }}
              />
            );
          } else {
            return <div></div>;
          }
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method customerAccessRequest
   * @description apply action for customer access request
   */
  customerAccessRequest = (data, status, message) => {
    const { user_detail } = this.props.loggedInUser;
    if (data && data !== undefined) {
      let requestData = {
        id: data.permission_id,
        admin_id: user_detail.id,
        npc_id: data.npc_id,
        npc_name: data.newspaper_name,
        user_name: data.customer_name,
        status: status,
        email: data.customer_email,
        reject_reason: "reason",
      };

      this.props.accessPermissiom(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(message);
          this.getCustomerList(this.state.pageSize, 1, "", "");
        }
      });
    }
  };

  /**
   * @method checkCustomerExits
   * @description check email id exits or not
   */
  checkCustomerExits = (data) => {
    let requestData = {
      email: data.customer_email,
    };
    this.props.checkCustomerExist(requestData, (res) => {
      if (res.status === STATUS_CODES.OK && res.data.success === true) {
        setTimeout(() => {
          window.location.assign("/customerManagement");
        }, 1000);
      } else if (res.status === STATUS_CODES.OK && res.data.success === false) {
        toastr.success(MESSAGES.CUSTOMER_ALREADY_EXIST);
      }
    });
  };

  /**
   * @method diplayCustomerRecords
   * @description Used to render record list
   */
  diplayCustomerRecords = (requestList) => {
    return (
      <div className="w-100 overflow-auto">
        <StandardTable
          columnOrder={this.renderCustomerRecords()}
          data={requestList}
          handleSort={this.handleSort}
          showPageSizeOptions={false}
          //showPagination={false}
          showSearch={false}
          totalCount={this.state.totalCount}
          onPageSizeChange={this.onPageSizeChange}
          onPageChange={this.onPageChange}
        />
      </div>
    );
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    if (column.sortable !== false) {
      const { sortFlag } = this.state;
      let temp = sortFlag;
      temp[column.id].sort = !sortFlag[column.id].sort;
      let sortBy = sortFlag[column.id].sort
        ? langs.sortBy.ASC
        : langs.sortBy.DESC;
      this.setState({ sortFlag: temp });
      column.sortable !== false && instance.sortColumn(column);
      column.sortable !== false &&
        this.getSortedCustomerList("", "", sortBy, column.id);
    }
  };

  /**
   * @method onPageSizeChange
   * @description called on change of page size
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getCustomerList(pageSize, this.state.page, "", "");
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getCustomerList(this.state.pageSize, page + 1, "", "");
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = (requestList) => {
    return this.state.dummyData.map((el, i) => {
      return (
        <tr>
          <td
            onClick={() => {
              let dummyData = [...this.state.dummyData];
              let data = { ...this.state.dummyData[i] };
              data.viewDetail = !this.state.dummyData[i].viewDetail;
              dummyData[i] = data;
              this.setState({ dummyData });
            }}
          >
            <div
              className="w-100"
              id={`${el.lable.substring(0, 2)}${i.toString()}`}
              style={{
                backgroundColor: "rgb(245, 244, 244)",
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                cursor: "pointer",
              }}
            >
              <div className="d-flex-center justify-content-between w-100 p-2">
                <h6>{el.lable}</h6>
                <label className="m-0">
                  <i className="fa fa-angle-down fa-2x" aria-hidden="true"></i>
                </label>
              </div>
            </div>
            <UncontrolledCollapse
              toggler={`#${el.lable.substring(0, 2)}${i.toString()}`}
              className="w-100"
            >
              {this.diplayCustomerRecords(requestList)}
            </UncontrolledCollapse>
          </td>
        </tr>
      );
    });
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const { loading, requestList } = this.props;
    return (
      <div>
        {loading && <Loader />}
        <Portlet fluidHeight={true}>
          <PortletBody>
            <Table responsive>
              <thead style={{ backgroundColor: "#dcdcdccc" }}>
                <tr>
                  <th>Company Name</th>
                </tr>
              </thead>
              <tbody>{this.renderRecords(requestList)}</tbody>
            </Table>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, authToken, adminCustomerAccessDuck } = store;
  const { customerList, npcCompanyArray } = adminCustomerAccessDuck;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    requestList: customerList && customerList,
    npcCompanyList: npcCompanyArray && npcCompanyArray,
  };
};

export default injectIntl(
  connect(mapStateToProps, adminCustomerAccessDuck.actions)(AllCustomerList)
);
