import React,{useEffect} from 'react'
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import headerlOgo from "../../../assets/images/footlogo.png";

export default function Footer() {
	useEffect(() => {
		AOS.init({
			easing: 'ease-in-out-sine',
			once: true
		  });
		AOS.refresh();
	  }, []);
	return (
		<React.Fragment>
			<footer className="ftrsec">
				<div className="container">
					<div className="ftrmidl">
						<div className="row">
							<div className="col-lg-3 col-6">
								<div className="footer_logo">
								<Link to="/">
									<img src={headerlOgo} alt="" />
								</Link>
								</div>
							</div>
							<div className="col-lg-2 col-6">
								<div className="footer_text">
									<h4>Contact Us</h4>
									<ul>
										<li>4403 15th Avenue<br />
											Brooklyn, NY 11219</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-6">
								<div className="footer_text">
									<ul>
										<li>Tel, <a href="tel:2124704400">212-470-4400</a></li>
										<li><a href="mailto:info@pijen.com">info@pijen.com</a></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-6">
								<div className="social_icon">
									<ul>
										<li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
										<li><a href="#"><i className="fab fa-instagram"></i></a></li>
										<li><a href="#"><i className="fab fa-twitter"></i></a></li>
										<li><a href="#"><i className="fab fa-whatsapp"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</React.Fragment>
	)
}
