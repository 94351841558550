import React, { Fragment } from "react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import DataTable from "./DataTable";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddSeasonalIissue from "./AddSeasonalIissue";
import * as SeasonalIssuesDuck from "../../../../store/ducks/npc/SeasonalIssues.duck";
import ConformModel from "../../super-admin/ad-management/ConfirmModel";
import { MESSAGES } from "../../../../config/message";
import { renderThemeToggleButton } from "../../FormLayout";
import { Loader } from "../../../../common/Loader";
class AddSeasonalissueName extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfirmModal: false,
      modalShow: false,
      seasonalIssueList: [],
      deletedItem: "",
      message: "",
      status: 1,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.getSeasonalIssuesList();
  }
  cancelDelete = () => {
    this.setState({ showConfirmModal: false, deletedItem: "" });
  };
  getSeasonalIssuesList = () => {
    this.setState({ isLoading: true });
    this.props.getAllSeasonalIssue("", (res) => {
      this.setState({ seasonalIssueList: res.data });
      this.setState({ isLoading: false });
    });
  };
  editSeasonalIssue = (id, calendar_name, fromDate, toDate) => {
    this.props.getSeasonalIssueById({
      id: id,
      calendar_name: calendar_name,
      fromDate: fromDate,
      toDate: toDate,
    });
    this.setState({
      modalShow: true,
    });
  };
  hidePopup = () => {
    this.getSeasonalIssuesList();
    this.setState({ modalShow: false });
    this.props.getSeasonalIssueById({});
  };
  confirmDelete = () => {
    this.props.deleteSeasonalIssues(this.state.deletedItem, (res) => {
      if (res) {
        this.getSeasonalIssuesList();
        toastr.success(MESSAGES.DELETE_SEASONAL_ISSUE);
        this.setState({
          showConfirmModal: false,
        });
      }
    });
  };
  handleActivation = (ev, id) => {
    const data = {
      status: ev.target.checked ? 0 : 1,
      id: id,
    };
    this.props.changeSeasonalIssueStatus(data, (res) => {
      if (res) {
        toastr.success(MESSAGES.STATUS_UPDATE_SEASONAL_ISSUE);
        this.getSeasonalIssuesList();
      }
    });
  };
  columns = [
    {
      Header: "Calendar Name",
      accessor: "calendar_name",
      Cell: (row) => {
        const { original } = row;
        return <span className="text-lowercase">{original.calendar_name}</span>;
      },
    },
    {
      Header: "Actions",
      accessor: "action",
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="icon_flex_table d-flex align-items-center">
            <MdDelete
              size="20"
              className="mr-2 cursor-pointer"
              onClick={() =>
                this.setState({
                  deletedItem: original.id,
                  showConfirmModal: true,
                  message: MESSAGES.MESSAGE_DELETE_SEASONAL_ISSUE,
                })
              }
            />
            <RiPencilFill
              size="20"
              className="cursor-pointer"
              onClick={() =>
                this.editSeasonalIssue(
                  original.id,
                  original.calendar_name,
                  original.fromDate,
                  original.toDate
                )
              }
            />
          </div>
        );
      },
    },
    {
      accessor: "status",
      Cell: (row) => {
        const { original } = row;
        return (
          <span>
            <div>
              <Form.Label style={{ color: "green" }}>Active</Form.Label>
              <Field
                name={`selected.${original.id}`}
                id={`selected.${original.id}`}
                checked={parseInt(original.status) === 0 ? true : false}
                required={true}
                onChange={(ev) => this.handleActivation(ev, original.id)}
                component={renderThemeToggleButton}
                type="checkbox"
              />
              <Form.Label style={{ color: "red" }}>Inactive</Form.Label>
            </div>
          </span>
        );
      },
    },
  ];
  render() {
    const {
      modalShow,
      seasonalIssueList,
      showConfirmModal,
      message,
    } = this.state;

    return (
      <Fragment>
        <div className="row seasonal_issue_main seasonal_issue_admin">
          <div className="col-12 kt-form kt-form--label-right">
            <div className="tab_main mb-2 align-items-center">
              <h4 className="kt-subheader__title">
                <span>Seasonal Issues</span>
              </h4>
              <Button
                className="add_btn"
                onClick={() => this.setState({ modalShow: true })}
              >
                <AiOutlinePlusCircle className="mr-1" />
                Add seasonal issue
              </Button>
            </div>
            <Portlet>
              <PortletBody>
                <div className="superadmin_issue_table">
                  {this.state.isLoading ? (
                    <Loader />
                  ) : (
                    <DataTable
                      data={seasonalIssueList}
                      columns={this.columns}
                    />
                  )}
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
        <AddSeasonalIissue
          show={modalShow}
          onHide={() => this.hidePopup()}
          role="admin"
        />
        <ConformModel
          isOpen={showConfirmModal}
          onCancelClick={this.cancelDelete}
          onOkClick={this.confirmDelete}
          message={message}
          totalCount={""}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => {};
export default injectIntl(
  connect(
    mapStateToProps,
    SeasonalIssuesDuck.actions
  )(
    reduxForm({
      form: "AddSeasonalIssue",
      destroyOnUnmount: false,
    })(AddSeasonalissueName)
  )
);
