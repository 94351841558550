import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetCustomerListRequest: "[SetCustomerListRequest] Action",
    SetSearchResultCustomerRequest: "[SetSearchResultCustomerRequest] Action",
    SetSelectedCompany: "[SetSelectedCompany] Action",
    DecreaseRequestCount: "[DecreaseRequestCount] Action"
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    customerRequestList: [],
    searchResultsforCustomerRequest: [],
    pendingCount: 0
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for customer Request List */
            case actionTypes.SetCustomerListRequest: {
                const { customerList, count } = action.payload;
                return { ...state, customerRequestList: [...customerList], pendingCount: count };
            }

            case actionTypes.SetSearchResultCustomerRequest: {
                const { customerList } = action.payload;
                return { ...state, searchResultsforCustomerRequest: [...customerList] };
            }


            /** get data of individual admin by id */
            case actionTypes.SetSelectedCompany: {
                const { selectedCompany } = action.payload;
                return { ...state, selectedCompany: selectedCompany };
            }

            // /** get data of individual admin by id */
            // case actionTypes.DecreaseRequestCount: {
            //     let loggedInuser = state.user.user_detail
            //     loggedInuser.pendingNpc = loggedInuser.pendingNpc - 1
            //     state.user.user_detail = loggedInuser
            //     return { ...state };
            // }


            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    getCustomerRequestList: (requestData, callback) => {
        const { page, is_deleted, status, row } = requestData
        const request = axios.get(`${API.getCustomerRequestList}?row=${row}&page=${page}&status=${status}&is_deleted=${is_deleted}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                if(response.data == "Data not found"){
                    return dispatch({ type: actionTypes.SetCustomerListRequest, payload: { customerList: [], count: 0 } })

                }else{
                    return dispatch({ type: actionTypes.SetCustomerListRequest, payload: { customerList: response.data.rows, count: response.data.count } })

                }
            }).catch((error) => {
                console.log('error: ', error);
                apiErrors(error, dispatch)
                // callback(error)
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    searchInCustomerRequestList: (requestData) => {
        const { searchKey, is_deleted, status } = requestData
        const request = axios.get(`${API.searchInNewspaperList}?search=${searchKey}&is_deleted=${is_deleted}&status=${status}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                return dispatch({ type: actionTypes.SetSearchResultCustomerRequest, payload: { customerList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },
    /**
    * @method rejectRequest
    * @description reject request by super admin
    */
    rejectRequest: (requestData, callback) => {
        const request = axios.patch(`${API.rejectRequest}`, requestData, {
            headers
        });
        return (dispatch) => {
            //  return dispatch({ type: actionTypes.DecreaseRequestCount })

            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                //return dispatch({ type: actionTypes.SetCustomerListRequest, payload: { customerList: response.data.rows } })

                // return dispatch({ type: actionTypes.DecreaseRequestCount })

            }).catch((error) => {
                apiErrors(error, dispatch)
                console.log('error: ', error);
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                callback(error);
            });
        };
    },
    /**
     * @method getCompanyDetailById
     * @description Update admin details 
     */
    getCompanyDetailById: (superAdminId, companyId, isEditFlag, authToken, callback) => {
        if (isEditFlag) {
            const request = axios.get(`${API.getAllNewspaperById}?id=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });
            return (dispatch) => {
                request.then((response) => {
                    callback(response);
                    return dispatch({ type: actionTypes.SetSelectedCompany, payload: { selectedCompany: response.data } })

                }).catch((error) => {
                    apiErrors(error, dispatch)
                    // tokenCheck(dispatch, error)
                    callback(error);
                });
            };
        } else {
            return (dispatch) => {
                callback({});
                return dispatch({ type: actionTypes.SetSelectedCompany, payload: {} })
            };
        }
    },

    /**
        * @method rejectRequest
        * @description reject request by super admin
        */
    messageCompany: (requestData, authToken, callback) => {
        const request = axios.patch(`${API.messageCompany}`, requestData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
            });
        };
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





