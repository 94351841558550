import React, { Component } from "react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import RevenueChart from "../AdminRevenueLineChart";
import FilterRevenue from "./FilterRevenue";
import CountFigureBox from "../../../../common/CountFigureBox";

export default class Revenue extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openModal: false,
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method renderCard
   * @description Renders revenue data
   */
  renderCard = (lable, value) => {
    return (
      <CountFigureBox isAutoHeight={true}>
        <h5 className="heading">{lable}</h5>
        <h6 className="heading">{value ? `$ ${value}` : "$0"}</h6>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const {
      iscreadit,
      total_revenue,
      total_payout,
      total_earning,
      heigestRevenueEarned,
      npcModule1,
    } = this.props;
    const { openModal, selectedOption } = this.state;
    return (
      <Portlet fluidHeight={true}>
        <PortletBody>
          <div>
            {iscreadit === undefined && (
              <div className="row">
                <div className="col-md-4">
                  {this.renderCard("Total Sales Amount", total_revenue)}
                  {this.renderCard("Total Payout", total_payout)}
                  {this.renderCard("Total Revenue Earned", total_earning)}
                  {this.renderCard(
                    "Highest Revenue Earned",
                    heigestRevenueEarned
                  )}
                </div>
                <div className="col-md-8 float-right float-top">
                  <RevenueChart iscreadit={iscreadit} />
                </div>
              </div>
            )}
          </div>
          {openModal && (
            <FilterRevenue
              isOpen={openModal}
              selectedOption={selectedOption}
              onCancel={() => this.setState({ openModal: false })}
            />
          )}
        </PortletBody>
      </Portlet>
    );
  }
}
