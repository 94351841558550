import React from 'react';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { Input } from 'reactstrap';
import { Link } from 'react-router-dom'
import StandardTable from '../../../StandardTable';
import {
    PortletBody, Portlet, PortletHeader,
    PortletHeaderToolbar
} from '../../../../../partials/content/Portlet';
import * as payoutSummaryDuck from '../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck'
import { STATUS_CODES } from '../../../../../common/StatusCode';
import FilterRecords from '../FilterRecords';
import {  getCurrentDate } from '../../../../../common/CommonFunction';
import { setDateFilterKeys } from '../../../../../common/CommonFilterFunction'
import { MESSAGES } from '../../../../../config/message';

let label = getCurrentDate()
class AllStatements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            statementCount: false,
            sortFlag: {
                payment_date: { sort: false }, newspaper_name: { sort: false }, statement_id: { sort: false }, paid_amount: { sort: false },
            },
            selectedOption: {
                option: 8,
                startDate: '',
                endDate: ''
            }
        }
    }

    /**
     * @method componentDidMount
     * @description  called after rendering the component 
     */
    componentDidMount() {
        this.getTransactionList()
    }

    /**
    * @method getTransactionList
    * @description  render transaction list 
    */
    getTransactionList = () => {
        const { fromDate, toDate, month, year } = this.state;
        let requestData = {
            type: 3,
            month: month ? month : '',
            year: year ? year : '',
            from_date: fromDate ? fromDate : '',
            to_date: toDate ? toDate : '',
        }
        this.setData(requestData)
    }

    /**
    * @method setData
    * @description  set requestdata  
    */
    setData = (requestData) => {
        this.props.getPayoutSummaryListing(requestData, res => {
            if (res.status === STATUS_CODES.OK) {
                this.setState({ statementCount: res.data.statement_count, openModal: false })
            } else {
                this.setState({ openModal: false })
            }
        })
    }

    /**
    * @method renderRecords
    * @description render all order list
    */
    renderRecords = () => {
        const columnOrder = [
            {
                Header: 'Date',
                accessor: 'payment_date', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return original.payment_date
                })
            },
            {
                Header: 'Newpaper Company',
                accessor: 'newspaper_name',
                Cell: (row) => {
                    const { original } = row;
                    return <font className='text-truncate'>{original.newspaper_name}</font>
                },
            },
            {
                Header: 'Statement Id',
                accessor: 'statement_id',
                Cell: (row) => {
                    const { original } = row;
                    return `${original.statement_id}`
                },
            },
            {
                Header: 'Amount',
                accessor: 'paid_amount',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.paid_amount}`
                },
            },
            {
                Header: 'Action',
                sortable: false,
                Cell: (row) => {
                    const { original } = row;
                    return <Link
                        to={`/open-statement/${original.statement_id}`}
                        style={{
                            borderBottom: '1px solid'
                        }}>View Statement</Link>
                },
            },
        ];
        return columnOrder
    }

    /**
    * @method handleSearch
    * @description handle searching
    */
    handleSearch = (e) => {
        const { fromDate, toDate, month, year } = this.state;
        if (!e.target.value || e.target.value.length <= 2) {
            this.setState({ showSearchResults: false })
            this.getTransactionList()
        } else if (e.target.value.length >= 2) {
            this.setState({ showSearchResults: true, searchKey: e.target.value })
            let requestData = {
                search: encodeURIComponent(e.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')),
                type: 3,
                month: month ? month : '',
                year: year ? year : '',
                from_date: fromDate ? fromDate : '',
                to_date: toDate ? toDate : '',
            }
            this.setData(requestData)
        }
    }

    /**
    * @method handleFilter
    * @description  used to handle filtered records 
    */
    handleFilter = (values, b) => {
        this.applyFilter(values, this.props)
    }

    /**
    * @method applyFilter
    * @description  used to apply filter on graph data 
    */
    applyFilter(values, props) {
        const data = setDateFilterKeys(values, label)
        if ((data.date && !data.date2) || (data.date2 && !data.date)) {
            return toastr.error(MESSAGES.DATE_VALIDATION)
        }
        const requestData = {
            type: 3,
            month: data.this_month,
            year: data.this_year,
            from_date: data.date,
            to_date: data.date2,
            sortedOrder: true,
        }
        label = data.label
        this.setState({ 
            fromDate: data.date,
            toDate: data.date2,
            month: data.this_month,
            year: data.this_year, 
            selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to
        }})
        this.setData(requestData)
    }

    /**
     * @method handleSort
     * @description perform sorting
     */
    handleSort = (e, { sorted }, rowInfo, column, instance) => {
        if (column.sortable !== false) {
            const { fromDate, toDate, month, year, sortFlag } = this.state;
            let temp = sortFlag
            temp[column.id].sort = !sortFlag[column.id].sort
            let sortBy = sortFlag[column.id].sort ? 'ASC' : 'DESC';
            this.setState({ sortFlag: temp })
            column.sortable !== false && instance.sortColumn(column)
            const requestData = {
                type: 3,
                sort: sortBy,
                column: column.id,
                sortedOrder: true,
                month: month ? month : '',
                year: year ? year : '',
                from_date: fromDate ? fromDate : '',
                to_date: toDate ? toDate : '',
            }
            column.sortable !== false && this.setData(requestData)
        }
    };

    /**
    * @method render
    * @description render component
    */
    render() {
        const { transationList } = this.props;
        const { statementCount, openModal, selectedOption } = this.state;
        return (
            <div>
                <Portlet fluidHeight={true}>
                    <PortletHeader
                        toolbar={
                            <PortletHeaderToolbar >
                                <h5>All Statements </h5>
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        <div className='row'>
                            <h5
                                className=' col-md-8 cursorPointer'
                                style={{ textAlign: 'initial', cursor: 'pointer' }}
                                onClick={() => this.setState({ openModal: true })}
                            >
                                {label}
                                &nbsp;&nbsp;
                                <i
                                    style={{ fontSize: 20 }}
                                    className='fa fa-angle-down'
                                    aria-hidden='true'
                                ></i>
                            </h5>
                            <div className='col-md-4'>
                                <Input style={{ height: 30, borderRadius: 25 }} placeholder='Search' type='text' onChange={e => this.handleSearch(e)}></Input>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='w-100 '>
                                <StandardTable showSearch={false} handleSort={this.handleSort} columnOrder={this.renderRecords()} allData={this.state.orderList}
                                    data={transationList} sortable={true} showPageSizeOptions={false} showPagination={false}
                                />
                            </div>
                        </div>
                        <div className='row w-100' style={{ borderBottom: '2px solid' }}></div>
                        <div className=' row w-100' >
                            <h5 style={{ textAlign: 'left', marginTop: 20 }} className='ml-6'>Total Statement ({statementCount})</h5>
                        </div>
                    </PortletBody>
                </Portlet>
                {openModal && <FilterRecords
                    applyFilter={this.handleFilter}
                    isOpen={openModal}
                    selectedOption={selectedOption}
                    onCancel={() => this.setState({ openModal: false })}
                />}
            </div>
        )
    }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */
const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    const { payoutSummaryDuck, auth } = store;
    const { transactionListing } = payoutSummaryDuck
    return ({
        loggedInUser: auth.user,
        loading,
        transationList: transactionListing !== undefined && Array.isArray(transactionListing.dataGrid) ? transactionListing.dataGrid : []
    })
}

export default injectIntl(
    connect(
        mapStateToProps,
        payoutSummaryDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'AllStatements',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(AllStatements)
    )
);