import React, { useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { merge } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';
import { metronic, initLayoutConfig, LayoutConfig } from '../../../../../_metronic';
import {
    Portlet,
    PortletBody
} from '../../../../partials/content/Portlet';
import Notice from '../../../../partials/content/Notice';
import AddressBar from './AddressBar'
import AddEmailForm from '../../npc/ourPlans/AddEmailForm';
import EmailBar from '../../npc/ourPlans/EmailBar';

import Adminpayment from '../../npc/ourPlans/Adminpayment';
const localStorageActiveTabKey = 'builderNewspaperActiveTab';

export default function ViewCompanyBody() {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [tab, setTab] = useState(activeTab ? +0 : 0);
    const dispatch = useDispatch();

    const [ setLoadingPreview] = useState(false);
    const [ setLoadingReset] = useState(false);
    const [ setLoadingButtonPreviewStyle] = useState({
        paddingRight: '2.5rem'
    });
    const [setLoadingButtonResetStyle] = useState({
        paddingRight: '2.5rem'
    });

    const { layoutConfig } = useSelector(
        ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
        shallowEqual
    );

    const enableLoadingPreview = () => {
        setLoadingPreview(true);
        setLoadingButtonPreviewStyle({ paddingRight: '3.5rem' });
    };
    const enableLoadingReset = () => {
        setLoadingReset(true);
        setLoadingButtonResetStyle({ paddingRight: '3.5rem' });
    };
    const updateLayoutConfig = _config => {
        dispatch(metronic.builder.actions.setLayoutConfigs(_config));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const initialValues = useMemo(
        () =>
            merge(
                // Fulfill changeable fields.
                LayoutConfig,
                layoutConfig
            ),
        [layoutConfig]
    );
    return (
        <>
            <Notice>
                <Tabs
                    component='div'
                    className='builder-tabs'
                    value={tab}
                    onChange={(_, nextTab) => {
                        setTab(nextTab);
                    }}
                >
                    <Tab label='Address ' />
                    <Tab label='Invoices' />
                    <Tab label='Payment Method' />
                    <Tab label='Add Communication Emails' />

                </Tabs>
            </Notice>
            <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    enableLoadingPreview();
                    updateLayoutConfig(values);
                }}
                onReset={() => {
                    enableLoadingReset();
                    updateLayoutConfig(initLayoutConfig);
                }}
            >
                <div className='kt-form kt-form--label-right'>
                    <Portlet>
                        {tab === 0 && (
                            < AddressBar
                                tab={0}
                            />
                        )}

                        {tab === 1 && (
                            <PortletBody>
                                <h4>Will cover in Later Milestone</h4>
                            </PortletBody>
                        )}
                        {tab === 2 && (
                            <Adminpayment/>
                              
                        )}
                       {tab === 3 && (
                           <EmailBar  />
                        )}
                    </Portlet>
                </div>
            </Formik>
        </>
    )
}