import React, { Fragment } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { Field } from "redux-form";
import {
  renderDatePicker,
  renderText,
  renderSelectField,
} from "../../../../common/layout/FormInput";
import * as SeasonalIssuesDuck from "../../../../store/ducks/npc/SeasonalIssues.duck";
import { MESSAGES } from "../../../../config/message";
class AddSeasonalIissue extends React.Component {
  constructor() {
    super();
    this.state = {
      calendar_name: "",
      inactive_date: "",
      isCalendarNameEdited: false,
      fromDate: null,
      toDate: null,
    };
  }
  addSeasonalIssue = () => {
    const requestData = {
      calendar_name: this.state.calendar_name,
      fromDate: this.state.fromDate
        ? moment(this.state.fromDate).format("yyyy-MM-DD")
        : this.state.fromDate,
      toDate: this.state.toDate
        ? moment(this.state.toDate).format("yyyy-MM-DD")
        : this.state.toDate,
    };
    this.props.addSeasonalIssues(requestData, (res) => {
      if (res.data) {
        toastr.success(MESSAGES.ADD_SEASONAL_ISSUE);
        this.props.onHide();
        this.setState({ calendar_name: "", fromDate: null, toDate: null });
        this.props.reset();
      }
    });
  };
  updateSeasonalIssue = () => {
    const { seasonalIssue } = this.props;
    const requestData = {
      id: seasonalIssue.id,
      calendar_name: this.state.calendar_name
        ? this.state.calendar_name
        : seasonalIssue.calendar_name,
      fromDate: this.state.fromDate
        ? moment(this.state.fromDate).format("yyyy-MM-DD")
        :null,
      toDate: this.state.toDate
        ? moment(this.state.toDate).format("yyyy-MM-DD")
        : null,
      isCalendarNameEdited: this.state.calendar_name
        ? this.state.calendar_name === seasonalIssue.calendar_name
          ? false
          : true
        : false,
    };
    this.props.updateSeasonalIssues(requestData, (res) => {
      if (res.data) {
        toastr.success(MESSAGES.UPDATE_SEASONAL_ISSUE);
        this.props.onHide();
        this.setState({ calendar_name: "", fromDate: null, toDate: null });
        this.props.reset();
      }
    });
  };
  updateInactiveDate = () => {
    const { inactiveDate } = this.props;
    const requestData = {
      id: inactiveDate.id,
      isInactive: true,
      issueDate: this.state.inactive_date,
    };
    this.props.updateSeasonalIssueOverrideEvent(requestData, (res) => {
      if (res.data) {
        toastr.success(MESSAGES.UPDATE_INACTIVE_ISSUE_DATE);
        this.props.onHide();
        this.setState({ inactive_date: "" });
        this.props.reset();
      }
    });
  };
  addInactiveDate = () => {
    const requestData = {
      isInactive: true,
      issueDate: this.state.inactive_date,
    };
    this.props.addSeasonalIssueOverrideEvent(requestData, (res) => {
      if (res.data) {
        toastr.success(MESSAGES.INACTIVE_ISSUE_DATE_ADDED);
        this.props.onHide();
        this.setState({ inactive_date: "" });
        this.props.reset();
      }
    });
  };

  render() {
    const { role, pristine, seasonalIssue, inactiveDate } = this.props;
    return (
      <Fragment>
        <Modal
          {...this.props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="seasonaladd_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {role === "admin"
                ? seasonalIssue.calendar_name
                  ? "Update seasonal issue"
                  : "Add seasonal issue"
                : inactiveDate.issueDate
                ? "Update inactive issue"
                : "Add inactive issue"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {role === "admin" ? (
              <>
                <Row>
                  <Col md={12} className="calendar_name_seasonal">
                    <Field
                      name="calendarName"
                      label=""
                      component={renderText}
                      maxLength={40}
                      disabled={false}
                      onChange={(e) =>
                        this.setState({
                          calendar_name: e.target.value,
                        })
                      }
                    />
                  </Col>
                  {/* <Col sm={6}>
                    <Field
                      name="fromDate"
                      label="From"
                      minDate={new Date()}
                      onChange={(e) => {
                        this.setState({ fromDate: e});
                      }}
                      component={renderDatePicker}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      name="toDate"
                      label="To "
                      minDate={new Date()}
                      onChange={(e) => {
                        this.setState({ toDate: e });
                      }}
                      component={renderDatePicker}
                    />
                  </Col> */}
                  {/* <Col md={12}>
                    <Field
                      name={`issueName`}
                      component={renderSelectField}
                      label="Publishers"
                      required={true}
                      value={this.state.new_publish_date}
                      options={this.state.allIssues}
                      optionValue={"value"}
                      optionLabel={"label"}
                      isSelect={false}
                    />
                  </Col> */}
                </Row>
              </>
            ) : (
              <Field
                name={
                  inactiveDate.issueDate
                    ? "update_inactive_issue"
                    : "add_inactive_issue"
                }
                component={renderDatePicker}
                label=""
                minDate={moment().toDate()}
                showTime={false}
                disabled={false}
                required={true}
                onChange={(e) =>
                  this.setState({
                    inactive_date: moment(e).format("yyyy-MM-DD"),
                  })
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {role === "admin" ? (
              seasonalIssue.calendar_name ? (
                <Button
                  onClick={() => this.updateSeasonalIssue()}
                  disabled={pristine}
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={() => this.addSeasonalIssue()}
                  disabled={pristine}
                >
                  Save
                </Button>
              )
            ) : inactiveDate.issueDate ? (
              <Button
                onClick={() => this.updateInactiveDate()}
                disabled={pristine}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={() => this.addInactiveDate()}
                disabled={pristine}
              >
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  const { seasonalIssue, inactiveDate } = store.SeasonalIssuesDuck;
  let initialValues = {};
  if (
    seasonalIssue &&
    seasonalIssue !== undefined &&
    seasonalIssue.calendar_name
  ) {
    initialValues = {
      calendarName: seasonalIssue.calendar_name,
      fromDate: seasonalIssue.fromDate
        ? moment(seasonalIssue.fromDate).format("MM/DD/yyyy")
        : seasonalIssue.fromDate,
      toDate: seasonalIssue.toDate
        ? moment(seasonalIssue.toDate).format("MM/DD/yyyy")
        : seasonalIssue.toDate,
    };
  }
  if (inactiveDate && inactiveDate !== undefined && inactiveDate.issueDate) {
    initialValues = {
      add_inactive_issue: inactiveDate.issueDate,
      update_inactive_issue: inactiveDate.issueDate,
    };
  }
  return {
    seasonalIssue: seasonalIssue,
    inactiveDate: inactiveDate,
    initialValues,
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    SeasonalIssuesDuck.actions
  )(
    reduxForm({
      form: "AddSeasonalIssue",
      enableReinitialize: true,
      destroyOnUnmount: true,
    })(AddSeasonalIissue)
  )
);
