import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import PayoutHeader from "./PayoutHeader";
import FinanaceDetail from "./FinanceDetail";
import PayoutListing from "./PayoutListing";

class PayoutReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 1,
      pageSize: 25,
      headerData: undefined,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutRecords(4, 1, "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 1,
    };
    this.props.getPayoutOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
  };
  /**
   * @method render
   * @description render the component
   */
  render() {
    const { headerData } = this.state;
    return (
      <div>
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h3 className="kt-subheader__title col-md-6">
                  <span>Payouts</span>
                </h3>
              </div>
            )}
          </LayoutContextConsumer>
          {headerData !== undefined && <PayoutHeader headerData={headerData} />}
          <FinanaceDetail resetList={() => this.getPayoutRecords()} />
          <PayoutListing />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "PayoutReports",
    })(PayoutReports)
  )
);
