
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Portlet, PortletBody } from '../../../../partials/content/Portlet'
import * as NPCDuck from '../../../../store/ducks/npc.duck'

import EmailListItem from './EmailListItem'

const NewEmailListing = ({ getUnapprovedSecondaryEmails }) => {
    const [getEmail, setEmail] = useState([])
    var allEmails = []

    const requestGetData = {
        approved: 0,
        isDeleted: 0
    }

    useEffect(() => {
        getUnapprovedSecondaryEmails(requestGetData, (res) => {
            
            allEmails = res.data
            setEmail( allEmails )
            


            
        })

    

        

    }, [])

    const list = [
        {
            id: 1,
            email: "Mark",
            publisher: "Otto"
        },
        {
            id: 2,
            email: "Jacob",
            publisher: "Thornton"
        },
        {
            id: 3,
            email: "Larry",
            publisher: "the Bird"
        }
    ]

    return (
        <div>
            <Portlet fluidHeight={true}>
                <PortletBody className="px-4">
                <table class="table">
                    <thead>
                        <tr
                            style={{ backgroundColor: "#f4f4f4" }}
                        >
                            <th scope="col">Email Address</th>
                            <th scope="col">Publisher</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getEmail.map((l) => (
                            <EmailListItem key={l.id} post={l} />
                        ))}
                    </tbody>
                </table>
                </PortletBody>
            </Portlet>
        </div>
    )
}



export default connect(
    null,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addColorForm',
})(NewEmailListing))
