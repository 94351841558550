/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {
  convertISOToUtcDateformate1,
  splitAndFormatMultipleDates,
} from "../../../../common";
import { InfoToolTipButton } from "../../../../common/ToolTips";

export default function OrderDetail(props) {
  const { detail } = props;
  let publishDates;
  const { newspaper_ads_plan } = detail;
  let editionName = detail && detail.edition ? detail.edition.name : "";
  let editionFrequency =
    detail !== undefined &&
    detail.edition.print_frequency &&
    detail.edition.print_frequency.print_frequency
      ? detail.edition.print_frequency.print_frequency
      : "";
  let adType =
    newspaper_ads_plan && newspaper_ads_plan.single_advertisement
      ? newspaper_ads_plan.single_advertisement.ad_type
      : "";
  let adSize = newspaper_ads_plan
    ? `${newspaper_ads_plan.height} X ${newspaper_ads_plan.width}`
    : "";
  let color =
    detail !== undefined && detail.color ? detail.color.color_name : "";

  const renderPublishDates = () => {
    if (
      Array.isArray(detail.order_publish_dates) &&
      detail.order_publish_dates.length
    ) {
      let temp = [];
      detail.order_publish_dates.map((el) => {
        temp.push(convertISOToUtcDateformate1(el.date));
      });
      publishDates = temp;
      // return publishDates.map((el, i) => {
      //     return <p style={{ margin: 0 }} key={i}>{el}  <InfoToolTipButton
      //     title="Ad Squad Media Administrator has discontinued/removed the Ad Type/ color which was used in the plan. Kindly update the plan."
      // /></p>
      // })

      let threePlusEntry = [];
      return (
        <div>
          {publishDates.map((el, i) => {
            return (
              <span>
                {i <= 2 && (
                  <p style={{ margin: 0 }} key={i}>
                    {el}
                    {i === 2 || publishDates.length - 1 === i ? `` : `,`}
                  </p>
                )}
              </span>
            );
          })}

          {publishDates.map((el, i) => {
            i >= 3 && threePlusEntry.push(`${el}`);
          })}
          {publishDates.length >= 3 && (
            <span>
              +3 <InfoToolTipButton title={threePlusEntry.toString()} />
            </span>
          )}
        </div>
      );

      //

      {
        /* {publishDates.length >= 1 && <span>+3 <InfoToolTipButton title="Ad Squad Media Administrator has discontinued/removed the Ad Type/ color which was used in the plan. Kindly update the plan." /></span>} */
      }
    } else {
      return (publishDates = "");
    }
  };
  const renderKey = (value, key) => {
    if ((!value || value === undefined) && value !== 0) {
      return;
    }
    return <p style={{ margin: 0 }}>{key}</p>;
  };
  const renderValue = (value) => {
    if (!value || value === undefined) {
      return;
    }
    return <p style={{ margin: 0 }}>{value === true ? `Yes` : `${value}`} </p>;
  };
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid text-dark">
        <div className="kt-portlet__head" style={{ background: "#E8E8E8" }}>
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Order Detail</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
            <div className="kt-widget4__info ">
              <h5>Customer</h5>
              <div className="row order-detail-info">
                <div className="col-md-6">
                  <div className="info">
                    <div className="title">Order Amount</div>
                    <div className="value">
                      {renderValue("$" + detail.price)}
                    </div>
                  </div>
                  <div className="info">
                    <div className="title">Discount</div>
                    <div className="value">
                      {detail.discount
                        ? renderValue("$" + detail.discount)
                        : "$0"}
                    </div>
                  </div>
                  <div className="info total">
                    <div className="title">Total</div>
                    <div className="value">
                      {renderValue("$" + detail.price)}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div className="kt-widget4">
              <div className="kt-widget4__info ">
                <div className="row order-detail-info">
                  <div className="col-md-6">
                    {editionName && (
                      <div className="info">
                        <div className="title">Edition</div>
                        <div className="value">{renderValue(editionName)}</div>
                      </div>
                    )}
                    {editionFrequency && (
                      <div className="info">
                        <div className="title">Edition Freq.</div>
                        <div className="value">
                          {renderValue(editionFrequency)}
                        </div>
                      </div>
                    )}
                    {adType && (
                      <div className="info">
                        <div className="title">Ad Type</div>
                        <div className="value">{renderValue(adType)}</div>
                      </div>
                    )}
                    {color && (
                      <div className="info">
                        <div className="title">Color Type</div>
                        <div className="value">{renderValue(color)}</div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    {detail.ad_frequency && (
                      <div className="info">
                        <div className="title">No. of time Advertised</div>
                        <div className="value">
                          {renderValue(detail.ad_frequency)}
                        </div>
                      </div>
                    )}
                    {detail.ad_date && (
                      <div className="info">
                        <div className="title">Publishing date</div>
                        <div className="value">
                          {splitAndFormatMultipleDates(detail.ad_date)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
