import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
// import { //tokenCheck } from '../../../common/CheckPermissions'
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetBussinessHoursDetail: "[SetBussinessHoursDetail] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    bussinessHoursDetail: undefined,
  
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for customer Request List */
            case actionTypes.SetBussinessHoursDetail: {
                const { bussinessHoursDetail } = action.payload;
                return { ...state, bussinessHoursDetail: bussinessHoursDetail };
            }
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
   * @method getBussinessHours
   * @description get bussiness hours detail
   */
    getBussinessHours: (requestData, callback) => {
        const { npc_id } = requestData
        const request = axios.get(`${API.bussinessHours}?id=${npc_id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetBussinessHoursDetail, payload: { bussinessHoursDetail: response.data } })

            }).catch((error) => {
                apiErrors(error, dispatch);
                callback(error)
                //tokenCheck(dispatch, error)
                console.log(error);
            });
        };
    },

    /**
   * @method createBussinessHours
   * @description create bussiness hours
   */
    createBussinessHours: (requestData, callback) => {
        let api_call = `${API.bussinessHours}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        }
    },


    /**
     * @method updateBussinessHours
     * @description update bussiness hours
     */
    updateBussinessHours: (requestData, callback) => {
        let api_call = `${API.bussinessHours}`
        const request = axios.put(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        }
    },

    /**
     * @method getDiscountDetailById
     * @description get discount detail by id
     */
    getDiscountDetailById: (requestData) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.SetCustomerDiscount, payload: { discountData: requestData } })
        }
    },

    /**
     * @method resetDetail
     * @description reset discount detail
     */
    resetDetail: (requestData) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.SetCustomerDiscount, payload: { discountData: [] } })
        }
    },

    /**
     * @method addCustomerDiscount
     * @description add customer discount
     */
    addCustomerDiscount: (requestData, callback) => {
        let api_call = `${API.addCustomerDiscount}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        }
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





