import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import { toastr } from "react-redux-toastr";
import "../../../../../common/CommonChart.scss";
import { renderSelectField } from "../../../../../common/layout/FormInput";
import FilterRecords from "../../../npc/reports-management/FilterRecords";
import * as orderReportDuck from "../../../../../store/ducks/super-admin/report-management/OrderReport.duck";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { convertISOToUtcDateformate3 } from "../../../../../common";
import { getCurrentMonth } from "../../../../../common/CommonFunction";
import { COMPANY } from "../../../../../config/constant";
import BlankChart from "../../../../../common/BlankChart";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";

let dateRange = [],
  trendData = [];
let label = getCurrentMonth();
class OrderTrend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ["Last Year"],
      orders: ["Current Year"],
      company: COMPANY,
      dateAxis: [],
      data: "",
      npcId: "",
      openModal: false,
      selectedOption: {
        option: 0,
        startDate: "",
        endDate: "",
      },
      fromDate: "",
      toDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getCompanyList(); // get all company record
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanyList = () => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: 100,
      page: 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        const npc_id =
          Array.isArray(res.data.rows) && res.data.rows[0]
            ? res.data.rows[0].id
            : 4;
        this.setState({ npcId: npc_id });
        this.getOrderReport(4, 2, 0, npc_id); // getting order header detail
      }
    });
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderReport = (type, section, data, npc_id) => {
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      type: type,
      section: section,
      data: data,
      npc_id: npc_id,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.setState({ data: data, npcId: npc_id });
    this.props.getOrderReportDetail(requestData, (res) => {});
  };

  /**
   * @method handleFilter
   * @description  called after rendering the component
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    trendData = [];
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      npc_id: this.state.npcId,
      type: 4,
      data: 0,
      section: 2,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.props.getOrderReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({
          fromDate: data.date,
          toDate: data.date2,
          month: data.this_month,
          year: data.this_year,
          openModal: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  }

  /**
   * @method dateAxis
   * @description  used to formate date
   */
  dateAxis = () => {
    return dateRange;
  };

  /**
   * @method orderList
   * @descriptionget total order trends
   */
  orderList = (orderData) => {
    let temp = [];
    orderData &&
      Array.isArray(orderData) &&
      orderData.map((item, i) => {
        temp.push(item.order_count);
        dateRange.push(convertISOToUtcDateformate3(item.createdAt));
      });
    return {
      temp,
      dateRange,
    };
  };

  render() {
    const { openModal, selectedOption } = this.state;
    const { companyList, orderTrend } = this.props;
    trendData = this.orderList(orderTrend);
    return (
      <div>
        <h5 className="float-left">Order Trend</h5>
        <div className="row col-md-12">
          <div className="w-100  mt-5">
            <label className="float-left mr-4">Select Durations</label>
            <h5
              className="float-right cursorPointer"
              onClick={() => this.setState({ openModal: true })}
            >
              {label}
              &nbsp;&nbsp;
              <i
                style={{ fontSize: 20 }}
                className="fa fa-angle-down"
                aria-hidden="true"
              ></i>
            </h5>

            <div className="d-flex-center justify-content-between mt-4 w-100">
              <div className="mr-4">Select Company</div>

              <label>
                <Field
                  name="sales"
                  options={companyList}
                  optionValue={"id"}
                  optionLabel={"newspaper_name"}
                  isSelect={false}
                  onChange={(e) => {
                    this.getOrderReport(4, 2, 0, e.target.value); // getting order header detail
                  }}
                  component={renderSelectField}
                />
              </label>
            </div>
          </div>
        </div>
        {trendData && trendData.temp.length > 1 ? (
          <div style={{ marginTop: 30 }} className="row w-100">
            {trendData && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    //categories: this.dateAxis(),
                    categories: trendData.dateRange,
                  },
                  y: {
                    label: {
                      text: "Sales Revenue",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [
                    trendData.temp,
                    // orders
                  ],
                  type: "spline",
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ marginTop: 30 }} className="row w-100">
            <BlankChart />
          </div>
        )}
        {openModal && (
          <FilterRecords
            applyFilter={this.handleFilter}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading, companyList } = store.commonDuck;
  const { orderReportDuck } = store;
  const { orderGraphDetail } = orderReportDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    companyList,
    orderTrend:
      orderGraphDetail && Array.isArray(orderGraphDetail.orderTrend)
        ? orderGraphDetail.orderTrend
        : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...orderReportDuck.actions,
    ...commonDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "OrderTrend",
      destroyOnUnmount: false,
    })(OrderTrend)
  )
);
