import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from "axios";
import { API } from "../../../config";
import { CALL_LOADING } from "../../../config/constant";
import { apiErrors } from "../../../common/HandleAPIErrors";

export const actionTypes = {
  SetAllEditionList: "[SetAllEditionList] Action",
  SetAllSeasonalIssue: "[SetAllSeasonalIssue] Action",
  GetSeasonalIssueById: "[GetSeasonalIssueById] Action",
  GetInactiveIssueDate: "[GetInactiveIssueDate] Action",
  GetInactiveDateById: "[GetInactiveDateById] Action",
};

const headers = {
  "Content-Type": "application/json",
};

const initialAuthState = {
  editionList: [],
  seasonalIssue: {},
  inactiveDate: {},
};

/** set data of api's into reducer */
export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      /** get all edition list */
      case actionTypes.SetAllEditionList: {
        const { editionList } = action.payload;
        return { ...state, editionList: editionList };
      }
      /** get all seasonal issue list */
      case actionTypes.SetAllSeasonalIssue: {
        const { seasonalIssueList } = action.payload;
        return { ...state, seasonalIssueList: seasonalIssueList };
      }
      /** get edition by id */
      case actionTypes.GetSeasonalIssueById: {
        const { seasonalIssue } = action.payload;
        return { ...state, seasonalIssue: seasonalIssue };
      }
      /** get all inactive issue date list */
      case actionTypes.GetInactiveIssueDate: {
        const { inactiveIssueDateList } = action.payload;
        return { ...state, inactiveIssueDateList: inactiveIssueDateList };
      }
      /** get inactive date by id */
      case actionTypes.GetInactiveDateById: {
        const { inactiveDate } = action.payload;
        return { ...state, inactiveDate: inactiveDate };
      }
      /** set default state */
      default:
        return state;
    }
  }
);

/** Actions creator */
export const actions = {
  getAllEditionList: (requestData, callback) => {
    const { npc_id, date } = requestData;
    let url = `${API.getAllEditionList}?npc_id=${npc_id}&date=${date}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetAllEditionList,
            payload: { editionList: response.data.rows },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  getSeasonalIssueById: (requestData, callback) => {
    return (dispatch) => {
      return dispatch({
        type: actionTypes.GetSeasonalIssueById,
        payload: { seasonalIssue: requestData },
      });
    };
  },
  addSeasonalIssues: (requestData, callback) => {
    let request = axios.post(`${API.seasonalIssues}`, requestData, {
      headers,
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  updateSeasonalIssues: (requestData, callback) => {
    let request = axios.put(`${API.seasonalIssues}`, requestData, {
      headers,
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  deleteSeasonalIssues: (id, callback) => {
    const request = axios.delete(`${API.seasonalIssues}?id=${id}`, {
      headers,
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          apiErrors(error, dispatch);
          dispatch({ type: CALL_LOADING });
          callback(error);
        });
    };
  },
  changeSeasonalIssueStatus: (requestData, callback) => {
    const request = axios.patch(`${API.seasonalIssues}`, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },
  getAllSeasonalIssue: (data, callback) => {
    const request = axios.get(`${API.seasonalIssues}?get=${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (dispatch) => {
      request
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          apiErrors(error, dispatch);
        });
    };
  },
  addSeasonalIssueOverrideEvent: (requestData, callback) => {
    let request = axios.post(`${API.seasonalIssueOverrideEvent}`, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  getSeasonalIssuesByPublisher: (requestData, callback) => {
    const {isInactive,overRideSeasonalId} = requestData
    let request = axios.get(`${API.getSeasonalIssuesByPublisher}?isInactive=${isInactive}&overRideSeasonalId=${overRideSeasonalId||""}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  getInactiveIssueDates: (requestData, callback) => {
    const { isInactive } = requestData;
    let url = `${API.getSeasonalIssuesByPublisher}?isInactive=${isInactive}`;
    const request = axios.get(url, { headers });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.GetInactiveIssueDate,
            payload: { inactiveIssueDateList: response.data.rows },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  deleteSeasonalIssueOverrideEvent: (requestData, callback) => {
    let request = axios.post(
      `${API.deleteSeasonalIssueOverrideEvent}`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  getInactiveIssueDateById: (requestData, callback) => {
    return (dispatch) => {
      return dispatch({
        type: actionTypes.GetInactiveDateById,
        payload: { inactiveDate: requestData },
      });
    };
  },
  updateSeasonalIssueOverrideEvent: (requestData, callback) => {
    let request = axios.post(`${API.updateSeasonalIssueOverrideEvent}`, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: CALL_LOADING });
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
};

export function* saga() {
  yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {});
}
