import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'
export const actionTypes = {
    ResetAllAdminList: "[ResetAllAdminList] Action",
    ResetAllArchiveAdminList: "[ResetAllArchiveAdminList] Action",
    RemoveAdmin: "[RemoveAdmin] Action",
    SetSearchResultAdmin: "[SetSearchResultAdmin] Action",
    SetSearchResultArchieveAdmin: "[SetSearchResultArchieveAdmin] Action",
    RemoveArchieveAdmin: "[RemoveArchieveAdmin] Action",

};
const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    adminList: [],
    archiveAdminList: [],
    searchResultsforAdmin: [],
    searchResultsforArchieveAdmin: [],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of admin list */
            case actionTypes.ResetAllAdminList: {
                const { adminList } = action.payload;
                return { ...state, adminList: adminList };
            }

            /** get all records of admin archieve list */
            case actionTypes.ResetAllArchiveAdminList: {
                const { archiveAdminList } = action.payload;
                return { ...state, archiveAdminList: archiveAdminList };
            }

            /** remove selected admin from admin records */
            case actionTypes.RemoveAdmin: {
                const { adminId } = action.payload;
                let deleteIndex = state.adminList.findIndex((el) => el.id === adminId)
                if (deleteIndex !== -1) {
                    let temp = state.adminList && state.adminList.filter(function (item, index) {
                        return index != deleteIndex
                    });
                    return { ...state, adminList: [...temp] };
                } else {
                    return state;
                }
            }

            /** set results from search */
            case actionTypes.SetSearchResultAdmin: {
                const { result } = action.payload;
                return { ...state, searchResultsforAdmin: result };
            }

            /** set results from search */
            case actionTypes.SetSearchResultArchieveAdmin: {
                const { result } = action.payload;
                return { ...state, searchResultsforArchieveAdmin: result };
            }

            /** Activate selected admin from archieved admin records */
            case actionTypes.RemoveArchieveAdmin: {
                const { archieveAdminId } = action.payload;
                let deleteIndex = state.archiveAdminList.findIndex((el) => el.id === archieveAdminId)
                if (deleteIndex !== -1) {
                    let temp = state.archiveAdminList && state.archiveAdminList.filter(function (item, index) {
                        return index != deleteIndex
                    });
                    return { ...state, archiveAdminList: [...temp] };
                } else {
                    return state;
                }
            }
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {


    /**
    * @method addNewAdmin
    * @description Update admin details 
    */
    addNewAdmin: (requestData, callback) => {
        const request = axios.post(API.registerAdmin, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },

    /**
   * @method updateAdminProfile
   * @description Update admin details 
   */
    updateAdminProfile: (requestData, callback) => {
        let request = axios.put(`${API.updateAdminDetails}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method getAllAdminList
     * @description get Listing of All Admins 
     */
    getAllAdminList: (requestData, callback) => {
        const { id, row, page, isDeleted } = requestData;
        const request = axios.get(`${API.getAllAdmin}?id=${id}&row=${row}&page=${page}&is_deleted=${isDeleted}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                //dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.ResetAllAdminList, payload: { adminList: response.data.rows } })

            }).catch((error) => {
                // tokenCheck(dispatch, error)
                callback(error);
                //dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
    * @method getAllAdminArchieveList
    * @description getting AllAdmin ArchieveList  
    */
    getAllAdminArchieveList: (requestData, callback) => {
        const { id, row, page, isDeleted } = requestData;
        const request = axios.get(`${API.getAllAdmin}?id=${id}&row=${row}&page=${page}&is_deleted=${isDeleted}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.ResetAllArchiveAdminList, payload: { archiveAdminList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method deleteAdminAPI
     * @description Update admin details 
     */
    deleteAdminById: (requestData, callback) => {
        const { id, email, first_name } = requestData
        const request = axios.delete(`${API.deleteAdminDetails}?id=${id}&email=${email}&first_name=${first_name}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.RemoveAdmin, payload: { adminId: id } })
            }).catch((error) => {
                //dispatch({ type: CALL_LOADING })
                apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method searchInUserList
    * @description search API Call to find admin details 
    */
    searchInUserList: (requestData) => {
        const { searchKey, is_deleted } = requestData
        const request = axios.get(`${API.searchInUserList}?search=${searchKey}&is_deleted=${is_deleted}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                if (!is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResultAdmin, payload: { result: response.data.rows } })
                } else if (is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResultArchieveAdmin, payload: { result: response.data.rows } })
                }

            }).catch((error) => {
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                return error;
            });
        };
    },

    /**
    * @method ActivateAdminById
    * @description Activate admin By Id 
    */
    activateAdminById: (requestData, authToken, callback) => {
        const request = axios.patch(`${API.activateAdmin}`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.RemoveArchieveAdmin, payload: { archieveAdminId: requestData.id } })

            }).catch((error) => {
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





