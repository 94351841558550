import React from "react";
import { Container } from "reactstrap";
import Hero from "../../../../../_metronic/_assets/media/images/landing-page/Hero.png";
import Phone from "../../../../../_metronic/_assets/media/images/landing-page/Phone.png";
import Deadline from "../../../../../_metronic/_assets/media/images/landing-page/deadline.png";
import Email from "../../../../../_metronic/_assets/media/images/landing-page/Email.png";
import Bleed from "../../../../../_metronic/_assets/media/images/landing-page/add-bleed.png";
import Avability from "../../../../../_metronic/_assets/media/images/landing-page/page-avaliable.png";
import ChatIcon1 from "../../../../../_metronic/_assets/media/images/landing-page/chat-1.png";
import ChatIcon2 from "../../../../../_metronic/_assets/media/images/landing-page/chat-2.png";
import ChatIcon3 from "../../../../../_metronic/_assets/media/images/landing-page/chat-3.png";
import ChatIcon4 from "../../../../../_metronic/_assets/media/images/landing-page/chat-4.png";
import Fade from "react-reveal/Fade";
import AOS from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
  React.useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    AOS.refresh();
  });
  return (
    <>
      <div className='lp_herosection'>
        <Container>
          <div className='lp_main_content'>
            <img
              data-aos='fade-up'
              data-aos-duration='1500'
              className='HeroImage'
              src={Hero}
              alt='calender'
            />
            <img
              data-aos='fade-right'
              data-aos-easing='ease-in-sine'
              data-aos-duration='1700'
              className='phoneImage'
              src={Phone}
              alt='phone'
            />
            <img
              data-aos='fade-left'
              data-aos-easing='ease-in-sine'
              data-aos-duration='1700'
              data-aos-anchor-placement="top-bottom"
              className='deadlineImage'
              src={Deadline}
              alt='deadline'
            />
            <img
              data-aos='fade-up'
              data-aos-duration='1700'
              data-aos-anchor-placement='top-bottom'
              className='emailImage'
              src={Email}
              alt='email'
            />

            <div className='letterImage'>
              <div className='images'>
                <img
                  className='bleedImage'
                  data-aos-delay='1550'
                  data-aos='fade-in'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1500'
                  src={Bleed}
                  alt='bleed'
                />
                <img
                  className='avaliableImage'
                  data-aos-delay='1600'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1600'
                  src={Avability}
                  alt='avability'
                />
              </div>
            </div>
            <div className='chat-part'>
              <div className='chats'>
                <img
                  className='ChatIcon1'
                  data-aos-delay='1500'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1000'
                  src={ChatIcon1}
                  alt='bleed'
                />
                <img
                  className='ChatIcon2'
                  data-aos-delay='1550'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1100'
                  src={ChatIcon2}
                  alt='avability'
                />
                <img
                  className='ChatIcon3'
                  data-aos-delay='1600'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1200'
                  src={ChatIcon3}
                  alt='avability'
                />
                <img
                  className='ChatIcon4'
                  data-aos-delay='1650'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1300'
                  src={ChatIcon4}
                  alt='avability'
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;
