import React, { Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { Table, Input } from "reactstrap";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { DATE_RANGE } from "../../../../../config/constant";
import { Button } from "@material-ui/core";
import FilterRecords from "../FilterRecords";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import {
  setDateFilterKeys,
  setDateFilterKeysCustom,
} from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import NoRecordFoundContent from "../../../../../common/NoRecordFoundContent";
import "./Customersummary.scss";
import StandardTable from "../../../StandardTable";
import { convertISOToUtcDateformate4 } from "../../../../../common";
import mastercard from "../../../../../../assets/images/mastercard.png";
import visa from "../../../../../../assets/images/visa.png";
import pdf_icon from "../../../../../../assets/images/pdf-icon.svg";
import discover from "../../../../../../assets/images/discover.png";
import american_express from "../../../../../../assets/images/amex.png";

// let label = getCurrentDate();
let label = "All";
class StatementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      duration: DATE_RANGE,
      srch: "",
      data: "",
      active: "",
      openModal: false,
      statementCount: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
        customer_id: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    label = "All";
    this.getTransactionList();
  }

  /**
   * @method getTransactionList
   * @description  render transaction list
   */
  getTransactionList = () => {
    let requestData = {
      type: 3,
    };
    this.setData(requestData);
  };

  generateprint = (id) => {
    html2canvas(document.getElementById("pdfSection")).then(function(canvas) {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "letter");
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save(`customer_transaction.pdf`);
    });
  };

  /**
   * @method setData
   * @description  set requestdata
   */
  setData = (requestData) => {
    this.props.getCustomerListing(requestData, (res) => {
      var firstEventid;
      if (res.status === STATUS_CODES.CREATED) {
        if (res.data.firstEventid.length > 0) {
          this.setState({
            customer_id: res.data.firstEventid[0].id,
            openModal: false,
            active: res.data.firstEventid[0].id,
          });

          var requestData = {
            id: res.data.firstEventid[0].id,
            newspaper_type:res.data.firstEventid[0].newspaper_type,
          };
          this.props.getCustomerTransactionDetails(requestData, (res) => {
            if (res.status === STATUS_CODES.CREATED) {
              //alert("We are testing");
            }
          });
        } else {
          var requestData = {
            id: 0,
          };
          this.props.getCustomerTransactionDetails(requestData, (res) => {
            if (res.status === STATUS_CODES.CREATED) {
              //alert("We are testing");
            }
          });

          this.setState({ customer_id: "", openModal: false });
        }

        //alert("We are testing");
      }
    });
  };

  /**
   * @method handleSearch
   * @description handle searching
   */
  handleSearch = (e) => {
    const { fromDate, toDate, month, year, data } = this.state;
    const { orderData } = this.props;
    var val = e.target.value.toLowerCase().trim();
    this.setState({ srch: val });
    if (!val) {
      this.setState({ data: orderData });
    } else {
      const filterData = orderData.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key]
            .toString()
            .toLowerCase()
            .includes(val);
        });
      });
      this.setState({ data: filterData });
    }
    // if (!e.target.value || e.target.value.length <= 2) {
    //   this.setState({ showSearchResults: false });
    //   this.getTransactionList();
    // } else if (e.target.value.length >= 2) {
    //   this.setState({ showSearchResults: true, searchKey: e.target.value });
    //   let requestData = {
    //     search: encodeURIComponent(
    //       e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    //     ),
    //     type: 3,
    //     month: month ? month : "",
    //     year: year ? year : "",
    //     from_date: fromDate ? fromDate : "",
    //     to_date: toDate ? toDate : "",
    //   };
    //   this.setData(requestData);
    // }
  };

  /**
   * @method handleFilter
   * @description  used to handle filtered records
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
    this.setState({ data: "", srch: "" });
  };

  ajaxcall = (id,type) => {
    this.setState({ customer_id: id, active: id });
    let requestData = {
      id: id,
      newspaper_type:type
    };
    this.props.getCustomerTransactionDetails(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        //alert("We are testing");
      }
    });
  };

  /**
   * @method applyFilter
   * @description  used to apply filter on graph data
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    const requestfield = setDateFilterKeysCustom(
      values.date_range,
      values.from,
      values.to
    );
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 3,
      agentTotalFilterOption: requestfield.agentTotalFilterOption,
      toDate: requestfield.toDate,
      fromDate: requestfield.fromDate,
    };
    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.setData(requestData);
  }

  /**
   * @method setDetail
   * @description set statement id
   */
  setDetail = (id) => {
    this.props.callNext(id);
  };

  /**
   * @method renderTransactionRecord
   * @description render the transaction records
   */
  renderTransactionRecord = (orderData) => {
    const { active } = this.state;
    if (orderData && orderData !== undefined && orderData.length > 0) {
      return orderData.map((data, i) => {
        return (
          <tr
            onClick={() => this.ajaxcall(data.id,data.newspaper_type)}
            style={{
              cursor: "pointer",
              backgroundColor: active == data.id ? "#f7f4f4" : "#fff",
            }}
          >
            <td style={{ width: "20%" }}>
              {moment(data.payout_date).format("MM-DD-YYYY")}
            </td>
            <td style={{ width: "30%", textTransform: "capitalize" }}>
              {data.company_name}
            </td>
            <td style={{ width: "30%", textTransform: "capitalize" }}>
              {data.agent_name}
            </td>
            <td style={{ width: "20%" }}>${parseFloat(data.amount).toFixed(2)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="4">
              <NoRecordFoundContent />
            </td>
          </tr>
        </tbody>
      );
    }
  };
  /**
   * @method render
   * @description render the component
   */
  render() {
    const { openModal, statementCount, selectedOption, data } = this.state;
    const {
      transationList,
      orderData,
      creditcarddata,
      customerData,
    } = this.props;
    return (
      <React.Fragment>
        <div className="col-md-5 adjust_colwidth5">
          <div className="column">
            <div className="box_publisherSummary">
              <div className="row align-items-center">
                <div
                  className="col-md-6 cursorPointer"
                  style={{ textAlign: "initial", cursur: "pointer" }}
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}
                  &nbsp;&nbsp;
                  <i
                    style={{ fontSize: 15 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="col-md-6">
                  <Input
                    style={{ height: 30, borderRadius: 25 }}
                    placeholder="Search"
                    type="text"
                    value={this.state.srch}
                    onChange={(e) => this.handleSearch(e)}
                  ></Input>
                </div>
              </div>
              <Table className="mt-4 mb-0 w-100" responsive>
                <thead style={{ backgroundColor: "#dcdcdccc" }}>
                  <tr>
                    <th style={{ width: "20%" }}>Date</th>
                    <th style={{ width: "30%" }}>COMPANY NAME</th>
                    <th style={{ width: "30%" }}>AGENT</th>
                    <th style={{ width: "20%" }}>AMOUNT</th>
                  </tr>
                </thead>
              </Table>
              <div
                style={{ maxHeight: "200px", overflowY: "auto" }}
                className="scrollableTable"
              >
                <Table responsive>
                  <tbody>
                    {this.renderTransactionRecord(
                      data && data.length >= 0 ? data : orderData
                    )}
                  </tbody>
                </Table>
              </div>
              {openModal && (
                <FilterRecords
                  applyFilter={this.handleFilter}
                  isOpen={openModal}
                  selectedOption={selectedOption}
                  onCancel={() => this.setState({ openModal: false })}
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-md-7 adjust_colwidth7">
          <div className="box_publisherSummary">
            {customerData ? (
              <div id="pdfSection">
                <div className="row">
                  <div className="col-md-5">
                    <div className="customer_summaryheading">
                      <h3>Transaction Information</h3>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="customer_summaryheading">
                      <div>
                        <h3>Transaction Details</h3>
                        <p>(Order #{customerData.orderid})</p>
                      </div>
                      <div className="pdf_linkdiv">
                        <h5
                          data-html2canvas-ignore="true"
                          style={{
                            borderBottom: "1px solid",
                            cursor: "pointer",
                            color: "#5867dd",
                          }}
                          onClick={() =>
                            this.generateprint(customerData.orderid)
                          }
                        >
                          <img
                            src={pdf_icon}
                            alt="master-logo"
                            style={{
                              height: "17px",
                              position: "relative",
                              left: "-2px",
                              top: "-1px",
                            }}
                          />
                          Download Pdf
                          {/* </Link> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="customertransaction_left">
                      <h4>{customerData.company_name}</h4>
                      <p>{customerData.email}</p>
                    </div>
                    <div className="customertransaction_left2">
                      <img
                        src={
                          creditcarddata.cardTypes == "american-express"
                            ? american_express
                            : creditcarddata.cardTypes == "visa"
                            ? visa
                            : creditcarddata.cardTypes == "mastercard"
                            ? mastercard
                            : discover
                        }
                        alt={creditcarddata.cardTypes}
                      />
                      <h4>{creditcarddata.CreditCard}</h4>
                      <p>Expiration Date: {creditcarddata.ExpiryDate}</p>
                    </div>
                    <div className="customertransaction_left">
                      <h4>Campaign Name</h4>
                      <p>{customerData.campaign_name}</p>
                    </div>
                    <div className="customertransaction_left">
                      <h4>Publisher</h4>
                      <p>{customerData.publisher_name}</p>
                    </div>
                    <div className="customertransaction_left">
                      <h4>Ad Type</h4>
                      <p>{customerData.ad_name}</p>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div
                      className="colcustomerHeight"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="customertransaction_right">
                        <h4>PUBLISHER</h4>
                        {customerData.publisher_amount != undefined && (
                          <p>${customerData.publisher_amount.toFixed(2)}</p>
                        )}
                        <h2 style={{ marginLeft: "10px" }}>
                          TRANSACTION FEE:&nbsp;
                          {customerData.publisher_amount != undefined && (
                            <span>
                              -$
                              {(
                                (customerData.publisher_amount * 0) /
                                100
                              ).toFixed(2)}
                            </span>//	Processing fee is zero now
                          )}
                        </h2>

                        <h2 style={{ marginLeft: "10px" }}>
                          SUBTOTAL:&nbsp;
                          {customerData.publisher_amount != undefined && (
                            <span>
                              $
                              {(
                                customerData.publisher_amount.toFixed(2) -
                                (customerData.publisher_amount * 0) / 100
                              ).toFixed(2)}
                            </span> //	Processing fee is zero now
                          )}
                        </h2>
                      </div>
                      <div className="customertransaction_right">
                        <h4>AGENT COMMISSION</h4>
                        {customerData.agent_commision != undefined && (
                          <p>${customerData.agent_commision.toFixed(2)}</p>
                        )}
                      </div>
                      <div className="customertransaction_right">
                        <h4>PIJEN</h4>
                        {customerData.pijen_amount != undefined && (
                          <p>${parseFloat(customerData.pijen_amount).toFixed(2)}</p>
                        )}
                      </div>
                      <div className="customertransaction_righttotal">
                        <h4>TOTAL AMOUNT</h4>
                        <p>${parseFloat(customerData.total_amount).toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center", color: "#212529" }}>
                <strong>No Data Found</strong>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { payoutSummaryDuck, auth } = store;
  const { transactionListing } = payoutSummaryDuck;
  const { customerDetails, customerTransactionDetails } = payoutSummaryDuck;
  return {
    loggedInUser: auth.user,
    loading,
    orderData:
      customerDetails !== undefined
        ? customerDetails.customerPayoutTotalDetails
        : [],
    customerData:
      customerTransactionDetails !== undefined
        ? customerTransactionDetails.result[0]
        : "",
    creditcarddata:
      customerTransactionDetails !== undefined
        ? customerTransactionDetails.card_details
        : "",
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    payoutSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "StatementList",
    })(StatementList)
  )
);
