import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../../config';
import {
    CALL_LOADING
} from '../../../../config/constant';
import { apiErrors } from '../../../../common/HandleAPIErrors'


export const actionTypes = {
    SetAdtypeHeaderDetail: "[SetAdtypeHeaderDetail] Action",
    SetEnrollAdtypeDetail: "[SetEnrollAdtypeDetail] Action",
    SetDisplayAdtypeDetail: "[SetDisplayAdtypeDetail] Action",
    SetClassifiedAdtypeDetail: "[SetClassifiedAdtypeDetail] Action",
    SetTopAdtypeList: "[SetTopAdtypeList] Action",
    SetAdtypeContribution: "[SetAdtypeContribution] Action",
    SetAdtypeTrend: "[SetAdtypeTrend] Action",
    SetAdtypeGrid: "[SetAdtypeGrid] Action",
    SetOrderGrid: "[SetOrderGrid] Action",
    SetAdTypeDropdownList: "[SetAdTypeDropdownList] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    adtypeHeader: undefined,
    enrollAdtype: undefined,
    displayAdtype: undefined,
    classifiedAdtype: undefined,
    topAdtype: undefined,
    adTypeContribution: undefined,
    adTypeTrend: undefined,
    adTypeGrid: undefined,
    orderGrid: undefined,
    adtypeList: []
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of company list */
            case actionTypes.SetAdtypeHeaderDetail: {
                const { adtypeHeader } = action.payload;
                return { ...state, adtypeHeader: adtypeHeader && adtypeHeader.adTypeReport[0] }
            }

            /** get all records of company list */
            case actionTypes.SetEnrollAdtypeDetail: {
                const { enrollAdtype } = action.payload;
                return { ...state, enrollAdtype: enrollAdtype && enrollAdtype.enrolledAdType };
            }

            /** get all records of company list */
            case actionTypes.SetDisplayAdtypeDetail: {
                const { displayAdtype } = action.payload;
                return { ...state, displayAdtype: displayAdtype && displayAdtype.DisplayAdType };
            }

            /** get all records of company list */
            case actionTypes.SetClassifiedAdtypeDetail: {
                const { classifiedAdtype } = action.payload;
                return { ...state, classifiedAdtype: classifiedAdtype && classifiedAdtype.ClassifiedAdType };
            }

            /** get all records of company list */
            case actionTypes.SetTopAdtypeList: {
                const { topAdtype } = action.payload;
                return { ...state, topAdtype: topAdtype && topAdtype.topAdType };
            }

            /** get all records of company list */
            case actionTypes.SetAdtypeContribution: {
                const { adTypeContribution } = action.payload;
                return { ...state, adTypeContribution: adTypeContribution && adTypeContribution };
            }

            /** get all records of company list */
            case actionTypes.SetAdtypeTrend: {
                const { adTypeTrend } = action.payload;
                return { ...state, adTypeTrend: adTypeTrend && adTypeTrend };
            }

            /** get all records of company list */
            case actionTypes.SetAdtypeGrid: {
                const { adTypeGrid } = action.payload;
                return { ...state, adTypeGrid: adTypeGrid && adTypeGrid.adTypeDetails };
            }

            /** get all records of company list */
            case actionTypes.SetOrderGrid: {
                const { orderGrid } = action.payload;
                return { ...state, orderGrid: orderGrid && orderGrid.adTypeDetails };
            }

             /** get all records of company list */
             case actionTypes.SetAdTypeDropdownList: {
                const { adtypeList } = action.payload;
                return { ...state, adtypeList: adtypeList && adtypeList };
            }
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getAdTypeReportDetail
    * @description get adtype report detail
    */
    getAdTypeReportDetail: (requestData, callback) => {
        let { ad_type = '', sales = '', npc_id = '', print = '', pdf = '', section, data = '', type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', search = '', statement = '' } = requestData;
        let url = `${API.getAdtypeReportDetail}?data=${data}&module=${type}&sales=${sales}&ad_type=${ad_type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}&statement=${statement}&npc_id=${npc_id}&print=${print}&pdf=${pdf}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                if (section === 1) {
                    return dispatch({ type: actionTypes.SetAdtypeHeaderDetail, payload: { adtypeHeader: response.data } })
                } else if (section === 2) {
                    return dispatch({ type: actionTypes.SetEnrollAdtypeDetail, payload: { enrollAdtype: response.data } })
                } else if (section === 3) {
                    return dispatch({ type: actionTypes.SetDisplayAdtypeDetail, payload: { displayAdtype: response.data } })
                } else if (section === 4) {
                    return dispatch({ type: actionTypes.SetClassifiedAdtypeDetail, payload: { classifiedAdtype: response.data } })
                } else if (section === 5) {
                    return dispatch({ type: actionTypes.SetTopAdtypeList, payload: { topAdtype: response.data } })
                } else if (section === 6) {
                    return dispatch({ type: actionTypes.SetAdtypeContribution, payload: { adTypeContribution: response.data } })
                } else if (section === 7) {
                    return dispatch({ type: actionTypes.SetAdtypeTrend, payload: { adTypeTrend: response.data } })
                } else if (section === 8) {
                    return dispatch({ type: actionTypes.SetAdtypeGrid, payload: { adTypeGrid: response.data } })
                } else if (section === 9) {
                    return dispatch({ type: actionTypes.SetOrderGrid, payload: { orderGrid: response.data } })
                }
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
   * @method getAllAdType
   * @description Update admin details 
   */
    getAllAdTypeReportList: (callback) => {
        const request = axios.get(`${API.generalAPI}/advertisement?id=${""}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.SetAdTypeDropdownList, payload: { adtypeList: response.data } })

            }).catch((error) => {
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                callback(error);
            });
        };
    },

};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





