import React, { useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { merge } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';
import { metronic, initLayoutConfig, LayoutConfig } from '../../../../../_metronic';
import Notice from '../../../../partials/content/Notice';
import AllCustomerListing from './AllCustomerListing'
import ArchiveCustomerListing from './ArchiveCustomerListing'
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import {
    Portlet,
    PortletBody,
} from '../../../../partials/content/Portlet';

const localStorageActiveTabKey = 'builderNewspaperActiveTab';
export default function CustomerListing(props) {

    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [tab, setTab] = useState(activeTab ? +0 : 0);
    const dispatch = useDispatch();
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [loadingReset, setLoadingReset] = useState(false);
    const [loadingButtonPreviewStyle, setLoadingButtonPreviewStyle] = useState({
        paddingRight: '2.5rem'
    });
    const [loadingButtonResetStyle, setLoadingButtonResetStyle] = useState({
        paddingRight: '2.5rem'
    });

    const { layoutConfig } = useSelector(
        ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
        shallowEqual
    );

    const enableLoadingPreview = () => {
        setLoadingPreview(true);
        setLoadingButtonPreviewStyle({ paddingRight: '3.5rem' });
    };
    const enableLoadingReset = () => {
        setLoadingReset(true);
        setLoadingButtonResetStyle({ paddingRight: '3.5rem' });
    };
    const updateLayoutConfig = _config => {
        dispatch(metronic.builder.actions.setLayoutConfigs(_config));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const initialValues = useMemo(
        () =>
            merge(
                // Fulfill changeable fields.
                LayoutConfig,
                layoutConfig
            ),
        [layoutConfig]
    );

    return (
        <>
            <LayoutContextConsumer className='row'>
                {({ subheader: { title } }) => (
                    <div className='row'>
                        <h4 className='kt-subheader__title col-md-6'>
                            <span>Customer Listing</span>
                        </h4>
                    </div>
                )}
            </LayoutContextConsumer>
            <Notice>
                <Tabs
                    component='div'
                    className='builder-tabs'
                    value={tab}
                    onChange={(_, nextTab) => {
                        setTab(nextTab);
                    }}
                >
                    <Tab label='All Customer' />
                    <Tab label='Archive Listing' />
                </Tabs>

            </Notice>
            <Portlet>
                <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                        enableLoadingPreview();
                        updateLayoutConfig(values);
                    }}
                    onReset={() => {
                        enableLoadingReset();
                        updateLayoutConfig(initLayoutConfig);
                    }}
                >
                    {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
                        <div className='kt-form kt-form--label-right'>
                            <Portlet>
                                {tab === 0 && (
                                    <PortletBody>
                                        < AllCustomerListing
                                            tab={0}
                                            {...props}
                                        />
                                    </PortletBody>
                                )}

                                {tab === 1 && (
                                    <PortletBody>
                                        <ArchiveCustomerListing
                                            {...props}
                                        />
                                    </PortletBody>
                                )}
                            </Portlet>
                        </div>
                    )}
                </Formik>
            </Portlet>
        </>
    )
}