import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import * as adManagementDuck from "../../../../../store/ducks/super-admin/AdManagement.duck";
import { injectIntl } from "react-intl";
import { MESSAGES } from "../../../../../config/message";
import "./categoryList.scss";
import EditorModal from "./EditerModal";
import ConformModel from "../../ConformModel";
import { toastr } from "react-redux-toastr";
import { langs } from "../../../../../config/localization";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { STATUS_CODES } from "../../../../../common/StatusCode";

class TemplateListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      cat_name: "",
      selectedCategory: 0,
      deletedCategory: undefined,
      isEdit: false,
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      templateArray: [],
      templateList: [],
      flag: false,
      openEditModal: false,
      selectedTemplateForEdit: undefined,
    };
  }

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { deletedCategory, selectedCategory } = this.state;
    const deletedItem = deletedCategory;
    this.props.deleteTemplateById(deletedItem, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_TEMPLATE_SUCCESS);
        this.getAllTemplatesOfCategory(
          this.props.seletedTemplateCategory,
          selectedCategory
        );
        this.setState({ showConfirmModal: false });
      }
    });
  };

  /**
   * @method getAllTemplatesOfCategory
   * @description  getAllTemplatesOfCategory called API to get all Templates of Category
   */
  getAllTemplatesOfCategory = (category, index) => {
    let reqData = { catId: category };
    this.props.getTemplatesOfCategory(reqData, (res) => {});
  };

  /**
   * @method rawMarkup
   * @description  this methode converts HTML inside of Template into raw
   */
  rawMarkup(el) {
    var rawMarkup = el;
    return { __html: rawMarkup };
  }

  /**
   * @method renderDangrousHtml
   * @description  this renders HTML inside of Template
   */
  renderDangrousHtml = (content) => {
    return (
      <div className="modal-body">
        <span dangerouslySetInnerHTML={this.rawMarkup(content)} />
      </div>
    );
  };

  /**
   * @method renderTemplateList
   * @description  this renders Listing of Template by Iterations
   */
  renderTemplateList = (deletePer, createPer) => {
    const { templateList } = this.props;
    return (
      templateList &&
      templateList.map((el, index) => {
        return (
          <div className="col-md-4 my-3" key={index}>
            <div className="templateCard ">
              <div style={{ height: 22, width: "100%" }}>
                {deletePer && (
                  <i
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        showConfirmModal: true,
                        deletedCategory: el.id,
                        message: MESSAGES.COLOR_DELETE,
                      });
                    }}
                    className="fa fa-times float-right"
                    aria-hidden="true"
                  ></i>
                )}
                {createPer && (
                  <i
                    className="fa fa-pencil-square float-right"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        openEditModal: true,
                        selectedTemplateForEdit: el,
                      });
                    }}
                    aria-hidden="true"
                  ></i>
                )}
              </div>
              <div>
                <div style={{ height: 200, overflowY: "auto" }}>
                  {this.renderDangrousHtml(el.temp_body)}
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      showConfirmModal,
      message,
      openEditModal,
      selectedTemplateForEdit,
    } = this.state;
    const { getSelectedCategory, getAllCategoryListing } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.manageClassifiedTemplate,
      langs.permissionKeys.view
    );
    const deletePer = checkPermission(
      langs.permissionKeys.manageClassifiedTemplate,
      langs.permissionKeys.delete
    );
    const createPer = checkPermission(
      langs.permissionKeys.manageClassifiedTemplate,
      langs.permissionKeys.create
    );

    return (
      <div className="container">
        <div>
          <div className="row container">
            {viewPer ? (
              this.renderTemplateList(deletePer, createPer)
            ) : (
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
              </div>
            )}
          </div>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            message={message}
          />
          {openEditModal && (
            <EditorModal
              isOpen={openEditModal}
              data={selectedTemplateForEdit}
              onCancelClick={() => this.setState({ openEditModal: false })}
              getSelectedCategory={getSelectedCategory}
              getAllCategoryListing={getAllCategoryListing}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, superAdminDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    adTypeList: superAdminDuck.adTypeList,
    categoryList: superAdminDuck.categoryList,
    templateList: superAdminDuck.selectedTemplates,
    seletedTemplateCategory:
      Array.isArray(superAdminDuck.selectedTemplates) &&
      superAdminDuck.selectedTemplates.length
        ? superAdminDuck.selectedTemplates[0].ad_type_id
        : "",
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adManagementDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "TemplateListing",
    })(TemplateListing)
  )
);
