import React, { useEffect } from "react";
import "./Formup.scss";
const AfterSubmit = () => {
  useEffect(() => {}, []);
  return (
    <div className="container">
      <section className="fix_height">
        <div className="formup_card form_upcard2">
          <div class="row justify-content-center">
            <div className="col-12">
              <h2>The link has been expired!</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AfterSubmit;
