import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import GoogleSheet1 from "../../../../../_metronic/_assets/media/images/landing-page/google-sheet-1.png";
import GoogleSheet2 from "../../../../../_metronic/_assets/media/images/landing-page/google-sheet-2.png";
import GoogleSheet3 from "../../../../../_metronic/_assets/media/images/landing-page/google-sheet-3.png";
import Mailsheet1 from "../../../../../_metronic/_assets/media/images/landing-page/email-1.png";
import Mailsheet2 from "../../../../../_metronic/_assets/media/images/landing-page/email-2.png";
import Mailsheet3 from "../../../../../_metronic/_assets/media/images/landing-page/email-3.png";
import Aos from "aos";

const Familier = () => {
  React.useEffect(() => {
    Aos.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    Aos.refresh();
  });
  return (
    <>
      <div className='lp-familiermain'>
        <div className='lp_Familier_section'>
          <Container>
            <Row >
              <Col xl={12}>
                <div className='heading'>
                  <h3
                    data-aos='fade-up'
                    data-aos-duration='1000'
                    data-aos-delay='300'
                    data-aos-anchor-placement='top-bottom'>
                    If this sounds familiar...
                  </h3>
                  <h2
                    data-aos='fade-up'
                    data-aos-duration='1000'
                    data-aos-delay='400'
                    data-aos-anchor-placement='top-bottom'>
                    Then you need Pijen!
                  </h2>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-lg-center justify-content-md-center justify-content-sm-center">
              <Col xl={9} lg={10} md={11} sm={12} className='content_col'>
                <div className='mail_content'>
                  <div className='head_content'>
                    <h2
                      data-aos='fade-in'
                      data-aos-duration='800'
                      data-aos-delay='300'
                      data-aos-anchor-placement='top-bottom' className="text-center">
                      Endless back and <br/> forth emails
                    </h2>
                  </div>
                  <div className='image_content'>
                    <img
                      className='Mailsheet1'
                      data-aos='fade-in'
                      data-aos-duration='1000'
                      data-aos-delay='500'
                      data-aos-anchor-placement='top-bottom'
                      src={Mailsheet1}
                      alt='Mailsheet1'
                    />
                    <img
                      className='Mailsheet2'
                      data-aos='zoom-in-up'
                      data-aos-duration='700'
                      data-aos-delay='800'
                      data-aos-anchor-placement='top-bottom'
                      data-aos-easing='ease-in-sine'
                      src={Mailsheet2}
                      alt='Mailsheet2'
                    />
                    <img
                      className='Mailsheet3'
                      data-aos='fade-up'
                      data-aos-duration='1000'
                      data-aos-delay='700'
                      data-aos-anchor-placement='top-bottom'
                      data-aos-easing='linear'
                      src={Mailsheet3}
                      alt='Mailsheet3'
                    />
                  </div>
                </div>
              </Col>
              <Col xl={9} lg={10} md={11} sm={12} className='content_col'>
                <div className='sheet_content'>
                  <div className='head_content'>
                    <h2
                      data-aos='fade-in'
                      data-aos-duration='800'
                      data-aos-delay='300'
                      data-aos-anchor-placement='top-bottom'>
                      Confusing Google sheets
                    </h2>
                  </div>
                  <div className='image_content'>
                    <img
                      className='GoogleSheet1'
                      data-aos='fade-up'
                      data-aos-duration='1000'
                      data-aos-delay='600'
                      data-aos-anchor-placement='top-bottom'
                      data-aos-easing='linear'
                      src={GoogleSheet1}
                      alt='GoogleSheet1'
                    />
                    <img
                      className='GoogleSheet2'
                      data-aos='zoom-in-up'
                      data-aos-duration='800'
                      data-aos-delay='600'
                      data-aos-anchor-placement='top-bottom'
                      data-aos-easing='ease-in-sine'
                      src={GoogleSheet2}
                      alt='GoogleSheet1'
                    />
                    <img
                      className='GoogleSheet3'
                      data-aos='fade-up'
                      data-aos-duration='600'
                      data-aos-delay='700'
                      data-aos-anchor-placement='top-bottom'
                      data-aos-easing='linear'
                      src={GoogleSheet3}
                      alt='GoogleSheet1'
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Familier;
