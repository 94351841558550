import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as customerSummaryDuck from "../../../../../store/ducks/super-admin/CustomerSummary.duck";
import Listing from "./Listing";
import FilterRecords from "../FilterRecords";
import { SALES_TREND } from "../../../../../config/constant";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { Loader } from "../../../../../common/Loader";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import CountFigureBox from "../../../../../common/CountFigureBox";

class SalesReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      salesTrend: SALES_TREND,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getCustomerSummary(1, "");
    this.getCustomerSummary(2, "");
    this.getCustomerSummary(3, "");
    this.getCustomerSummary(4, "");
  }

  /**
   * @method getCustomerSummary
   * @description  used to get all admin listing
   */
  getCustomerSummary = (type, data) => {
    let requestData = {
      type,
      data,
    };
    this.props.getCustomerSummaryData(requestData, type, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value) => {
    return (
      <CountFigureBox>
        <h6 className="heading">{lable}</h6>
        {value === undefined ? "" : <h3 className="heading">{`${value}`}</h3>}
      </CountFigureBox>
    );
  };

  /**
   * @method renderInnerCard
   * @description  render inner sales content
   */
  renderInnerCard = (label, amount, name) => {
    return (
      <CountFigureBox className="mx-2">
        <h6 className="heading">{label}</h6>
        <h4 className="heading">{amount}</h4>
        {name === undefined ? (
          ""
        ) : (
          <h4 className="heading text-break">{name}</h4>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method renderRevenueCardHeigest
   * @description  render hiegest revenue data
   */
  renderRevenueCardHeigest = (
    label,
    insideLable1,
    insideLable2,
    val1,
    val2,
    val3
  ) => {
    return (
      <CountFigureBox>
        <h6 className="heading">{label}</h6>
        <div className="row w-100" style={{ justifyContent: "space-around" }}>
          {val1 === undefined
            ? ""
            : this.renderInnerCard(insideLable1, val1, val3)}
          {val2 === undefined
            ? ""
            : this.renderInnerCard(insideLable2, val2, val3)}
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method renderRevenueCardLowest
   * @description  render lowest revenue data
   */
  renderRevenueCardLowest = (
    label,
    insideLable1,
    insideLable2,
    val1,
    val2,
    val3
  ) => {
    return (
      <CountFigureBox>
        <h6 className="heading">{label}</h6>
        <div className="row w-100" style={{ justifyContent: "space-around" }}>
          {val1 === undefined
            ? ""
            : this.renderInnerCard(insideLable1, val1, val3)}
          {val2 === undefined
            ? ""
            : this.renderInnerCard(insideLable2, val2, val3)}
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { openModal } = this.state;
    const {
      module1Data,
      module2Data,
      module3Data,
      module4Data,
      loading,
    } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.customerSummary,
      langs.permissionKeys.view
    );
    return (
      <div>
        {/* {loading && <Loader/>} */}
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Customer Summary</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletBody>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="cardWrapper col-md-6">
                          {module1Data &&
                            this.renderHeaderCard(
                              "Total Active Customers",
                              module1Data.customers
                            )}
                        </div>
                        <div className="cardWrapper col-md-6 ">
                          {module1Data &&
                            this.renderHeaderCard(
                              "Total Active Agents",
                              module1Data.agents
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="cardWrapper col-md-6">
                      {module2Data &&
                        Array.isArray(module2Data.newCustomers) &&
                        this.renderRevenueCardHeigest(
                          "New Customers",
                          "No of Customer Registered",
                          "Sales Generated",
                          module2Data.newCustomers[0].customers,
                          module2Data.newCustomers[0].sales
                        )}
                    </div>
                    <div className="cardWrapper col-md-6">
                      {module2Data &&
                        Array.isArray(module2Data.newAgents) &&
                        this.renderRevenueCardLowest(
                          "New Agents",
                          "No of Customer Registered",
                          "Sales Generated",
                          module2Data.newAgents[0].agents,
                          module2Data.newAgents[0].sales
                        )}
                    </div>
                  </div>
                  <div className="row">
                    {module3Data !== undefined && (
                      <div className="cardWrapper col-md-6">
                        {this.renderRevenueCardHeigest(
                          "Customer with",
                          "Order Count",
                          "Sales",
                          module3Data.customer_count,
                          module3Data.sales,
                          module3Data.customer_name
                        )}
                      </div>
                    )}
                    {module4Data !== undefined && (
                      <div className="cardWrapper col-md-6">
                        {this.renderRevenueCardLowest(
                          "Agents with",
                          "Order Count",
                          "Sales",
                          module4Data.agent_count,
                          module4Data.sales,
                          module4Data.customer_name
                        )}
                      </div>
                    )}
                  </div>
                  <div style={{ borderBottom: "1px solid grey" }}></div>
                  <Listing />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.REPORT_PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
        {openModal && (
          <FilterRecords
            isOpen={openModal}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, customerSummaryDuck } = store;
  return {
    loggedInUser: auth.user,
    loading,
    module1Data: customerSummaryDuck.moduleData1,
    module2Data: customerSummaryDuck.moduleData2,
    module3Data: customerSummaryDuck.moduleData3,
    module4Data: customerSummaryDuck.moduleData4,
    compareData: customerSummaryDuck.compareData,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    customerSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "SalesReports",
    })(SalesReports)
  )
);
