import React, { Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { jsPDF } from "jspdf";
import { toastr } from "react-redux-toastr";
import { Table, Input } from "reactstrap";
import { DATE_RANGE } from "../../../../../config/constant";
import { Button } from "@material-ui/core";
import FilterRecords from "../FilterRecords";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import {
  setDateFilterKeys,
  setDateFilterKeysCustom,
} from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import NoRecordFoundContent from "../../../../../common/NoRecordFoundContent";
import "./Publicsummary.scss";
import StandardTable from "../../../StandardTable";
import pdf_icon from "../../../../../../assets/images/pdf-icon.svg";
import {
  convertISOToUtcDateformate4,
  convertISOToUtcDateformate3,
} from "../../../../../common";

let label = "All";
class StatementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      duration: DATE_RANGE,
      openModal: false,
      data: "",
      srch: "",
      active: "",
      activeDt: "",
      statementCount: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
        publisher_id: "",
        publisher_date: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    label = "All";
    this.getTransactionList();
  }

  /**
   * @method getTransactionList
   * @description  render transaction list
   */
  getTransactionList = () => {
    let requestData = {
      type: 3,
    };
    this.setData(requestData);
  };

  generateprint = (clientData) => {
    console.log("clientData", clientData);
    const doc = new jsPDF("p", "pt", "letter");
    const tableColumn = [
      "AGENT",
      "RESERVE DATE",
      "AD TYPE",
      "AD COST",
      "AGENT COMISSION",
      "TRANSACTION FEE",
      "TOTAL",
    ];
    const tableRows = [];
    clientData.forEach((ticket) => {
      let agent_name = ticket.agent_name.split(" ");
      for (let i = 0; i < agent_name.length; i++) {
        agent_name[i] =
          agent_name[i].charAt(0).toUpperCase() + agent_name[i].slice(1);
      }
      let ad_type = ticket.ad_type.split(" ");
      for (let i = 0; i < ad_type.length; i++) {
        ad_type[i] = ad_type[i].charAt(0).toUpperCase() + ad_type[i].slice(1);
      }
      const ticketData = [
        agent_name.join(" "),
        moment(ticket.reserve_date).format("MM-DD-YYYY"),
        ad_type.join(" "),
        `$${ticket.ad_cost}`,
        `$${ticket.agent_commision}`,
        `$${ticket.transaction_fee}`,
        `$${ticket.total}`,
      ];
      tableRows.push(ticketData);
    });
    doc.autoTable(tableColumn, tableRows, {
      startY: 60,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [33, 37, 41],
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255],
      },
    });
    let finalY = doc.lastAutoTable.finalY;
    doc.text("Publisher Payout Details", 40, 40);
    doc.setLineWidth(1);
    doc.rect(235, finalY + 10, 335, 30);
    doc.setFontSize(12);
    doc.text("Total Payout", 245, finalY + 30);
    doc.text(`$${this.props.totaldata}`, 520, finalY + 30);
    doc.save(`publishers_transaction.pdf`);
  };

  /**
   * @method setData
   * @description  set requestdata
   */
  setData = (requestData) => {
    this.props.getPublisherTotalPayoutListing(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        if (res.data.firstPublisherId.length > 0) {
          var agent_id = res.data.firstPublisherId[0].id;
          var date = convertISOToUtcDateformate3(
            res.data.firstPublisherId[0].payout_date
          );
          this.setState({
            publisher_id: agent_id,
            openModal: false,
            publisher_date: date,
            active: agent_id,
            activeDt: date,
          });
          var requestData = {
            id: agent_id,
            date: date,
            newspaper_type: res.data.firstPublisherId[0].newspaper_type
          };
          this.props.getDetailsWithrespectoPublisher(requestData, (res) => {
            if (res.status === STATUS_CODES.CREATED) {
              //alert("We are testing");
            }
          });
        } else {
          var requestData = {
            id: 0,
            date: "",
            newspaper_type: res.data.firstPublisherId[0].newspaper_type
          };
          this.props.getDetailsWithrespectoPublisher(requestData, (res) => {
            if (res.status === STATUS_CODES.CREATED) {
              //alert("We are testing");
            }
          });
          this.setState({
            publisher_id: "",
            openModal: false,
            publisher_date: "",
          });
        }
      }
    });
  };

  /**
   * @method handleSearch
   * @description handle searching
   */
  handleSearch = (e) => {
    const { fromDate, toDate, month, year, data } = this.state;
    const { orderData } = this.props;
    var val = e.target.value.toLowerCase().trim();
    this.setState({ srch: val });
    if (!val) {
      this.setState({ data: orderData });
    } else {
      const filterData = orderData.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key]
            .toString()
            .toLowerCase()
            .includes(val);
        });
      });
      this.setState({ data: filterData });
    }
    // if (!e.target.value || e.target.value.length <= 2) {
    //   this.setState({ showSearchResults: false });
    //   this.getTransactionList();
    // } else if (e.target.value.length >= 2) {
    //   this.setState({ showSearchResults: true, searchKey: e.target.value });
    //   let requestData = {
    //     search: encodeURIComponent(
    //       e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    //     ),
    //     type: 3,
    //     month: month ? month : "",
    //     year: year ? year : "",
    //     from_date: fromDate ? fromDate : "",
    //     to_date: toDate ? toDate : "",
    //   };
    //   this.setData(requestData);
    // }
  };

  /**
   * @method handleFilter
   * @description  used to handle filtered records
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
    this.setState({ data: "", srch: "" });
  };

  /**
   * @method applyFilter
   * @description  used to apply filter on graph data
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    const requestfield = setDateFilterKeysCustom(
      values.date_range,
      values.from,
      values.to
    );

    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 3,
      agentTotalFilterOption: requestfield.agentTotalFilterOption,
      toDate: requestfield.toDate,
      fromDate: requestfield.fromDate,
    };

    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.setData(requestData);
  }

  /**
   * @method setDetail
   * @description set statement id
   */
  setDetail = (id) => {
    this.props.callNext(id);
  };

  /**
   * @method renderTransactionRecord
   * @description render the transaction records
   */

  renderTransactionRecord = (orderData) => {
    const { active, activeDt } = this.state;
    if (orderData && orderData !== undefined && orderData.length > 0) {
      return orderData.map((data, i) => {
        return (
          <tr
            onClick={() =>
              this.ajaxCall(
                data.id,
                convertISOToUtcDateformate3(data.payout_date),
                data.newspaper_type
              )
            }
            style={{
              cursor: "pointer",
              backgroundColor:
                active == data.id &&
                activeDt == convertISOToUtcDateformate3(data.payout_date)
                  ? "#f7f4f4"
                  : "#fff",
            }}
          >
            <td style={{ width: "25%" }}>
              {moment(data.payout_date).format("MM-DD-YYYY")}
            </td>
            <td style={{ width: "50%", textTransform: "capitalize" }}>
              {data.publisher_name}
            </td>
            <td style={{ width: "25%" }}>${data.amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="4">
              <NoRecordFoundContent />
            </td>
          </tr>
        </tbody>
      );
    }
  };

  renderRecords = () => {
    const columnOrder = [
      {
        Header: "AGENT",
        accessor: "agent", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.agent_name;
        },
      },
      {
        Header: "RESERVE DATE",
        accessor: "reserveDate", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return moment(original.reserve_date).format("MM-DD-YYYY");
        },
      },
      {
        Header: "AD TYPE",
        accessor: "agent", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.ad_type;
        },
      },
      {
        Header: "AD COST",
        accessor: "agent", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return `$${original.ad_cost}`;
        },
      },
      {
        Header: "AGENT COMISSION",
        Cell: (row) => {
          const { original } = row;
          return `$${original.agent_commision}`;
        },
      },

      {
        Header: "TRANSACTION FEE",
        Cell: (row) => {
          const { original } = row;
          return `$${original.transaction_fee}`;
        },
      },

      {
        Header: "TOTAL",
        Cell: (row) => {
          const { original } = row;
          return `$${original.total}`;
        },
      },
    ];
    return columnOrder;
  };

  ajaxCall = (id, date,type) => {
    this.setState({
      publisher_id: id,
      publisher_date: date,
      active: id,
      activeDt: date,
    });
    var requestData = {
      id: id,
      date: date,
      newspaper_type:type
    };
    this.props.getDetailsWithrespectoPublisher(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        //alert("We are testing");
      }
    });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { openModal, statementCount, selectedOption, data } = this.state;
    const { transationList, orderData, totaldata, clientData } = this.props;
    return (
      <Fragment>
        <div className="col-12">
          <div className="summary_head d-flex align-items-center justify-content-between flex-wrap">
            <h4 className="kt-subheader__title" style={{ marginLeft: 0 }}>
              <span>Publisher Details</span>
            </h4>
            <h5
              style={{
                borderBottom: "1px solid",
                cursor: "pointer",
                color: "#5867dd",
              }}
              onClick={() => this.generateprint(clientData)}
            >
              {" "}
              {/* <Link
                to={`/publisher-pdf/?id=${this.state.publisher_id}&date=${this.state.publisher_date}`}
                style={{
                  borderBottom: "1px solid",
                }}
              > */}
              {/* <i class="fa fa-file-pdf-o mr-2" aria-hidden="true"></i>{" "} */}
              <img
                src={pdf_icon}
                alt="pdf_icon"
                style={{
                  height: "20px",
                  position: "relative",
                  left: "-2px",
                  top: "-1px",
                }}
              />
              Download Pdf
              {/* </Link> */}
            </h5>
          </div>
        </div>

        <div className="col-md-5">
          <div className="box_publisherSummary">
            <div className="row align-items-center">
              <div
                className="col-md-6 cursorPointer"
                style={{ textAlign: "initial", cursur: "pointer" }}
                onClick={() => this.setState({ openModal: true })}
              >
                {label}
                &nbsp;&nbsp;
                <i
                  style={{ fontSize: 15 }}
                  className="fa fa-angle-down"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="col-md-6">
                <Input
                  style={{ height: 30, borderRadius: 25 }}
                  placeholder="Search"
                  type="text"
                  value={this.state.srch}
                  onChange={(e) => this.handleSearch(e)}
                ></Input>
              </div>
            </div>
            <Table className="mt-4 mb-0 w-100" responsive>
              <thead style={{ backgroundColor: "#dcdcdccc" }}>
                <tr>
                  <th style={{ width: "25%" }}>DATE</th>
                  <th style={{ width: "50%" }}>PUBLISHER</th>
                  <th style={{ width: "25%" }}>AMOUNT</th>
                </tr>
              </thead>
            </Table>
            <div
              style={{ maxHeight: "200px", overflowY: "auto" }}
              className="scrollableTable"
            >
              <Table responsive>
                <tbody>
                  {this.renderTransactionRecord(
                    data && data.length >= 0 ? data : orderData
                  )}
                </tbody>
              </Table>
            </div>
            {openModal && (
              <FilterRecords
                applyFilter={this.handleFilter}
                isOpen={openModal}
                selectedOption={selectedOption}
                onCancel={() => this.setState({ openModal: false })}
              />
            )}
          </div>
        </div>
        {/* COL-MD-7 */}
        <div className="col-md-7">
          <div className="box_publisherSummary">
            <div className="Publish_standardTableThree">
              <StandardTable
                showSearch={false}
                handleSort={false}
                columnOrder={this.renderRecords()}
                //display={'none'}
                data={clientData}
                sortable={false}
                showPageSizeOptions={false}
                showPagination={false}
              />
            </div>
            <div className="col-12 pr-0">
              <div className="total_payout d-flex align-items-center justify-content-between">
                <h4>Total Payout</h4>
                <h3>${parseFloat(totaldata).toFixed(2)}</h3>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { payoutSummaryDuck, auth } = store;
  const { transactionListing } = payoutSummaryDuck;
  const {
    publisherPayoutTotalDetails,
    detailsofAgentWRTpublisher,
  } = payoutSummaryDuck;
  return {
    loggedInUser: auth.user,
    loading,
    transationList:
      transactionListing !== undefined &&
      Array.isArray(transactionListing.totalRows)
        ? transactionListing.totalRows
        : [],
    totaldata:
      detailsofAgentWRTpublisher !== undefined
        ? detailsofAgentWRTpublisher.total[0].total
        : 0,
    orderData:
      publisherPayoutTotalDetails !== undefined
        ? publisherPayoutTotalDetails.newspaperPayoutTotalDetails
        : [],
    clientData:
      detailsofAgentWRTpublisher !== undefined
        ? detailsofAgentWRTpublisher.result
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    payoutSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "StatementList",
    })(StatementList)
  )
);
