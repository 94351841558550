import React from "react";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import Listing from "./Listing";
import LineChart from "./Chart";

class PayoutHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
       
        <div className="row">
          <div className="col-md-12">
            <Portlet fluidHeight={true}>
              <PortletBody style={{ height: "auto" }}>
                {/* <div style={{ minHeight: 500 }}>
                  <LineChart />
                  <div style={{ borderBottom: "1px solid grey" }}></div>
                </div> */}
                <Listing />
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </div>
    );
  }
}

export default PayoutHistory;
