import Moment from "moment";
import { DATE_FORMATE } from "../config/constant";

/**
 * @method convertISOToUtcDate
 * @description convert date into MMM DD YYYY HH:mm A formate
 */
export function convertISOToUtcDate(date) {
  return Moment.utc(date).subtract(4, "hours").format(DATE_FORMATE.FORMATE1);
}

/**
 * @method convertISOToUtcDateformate1
 * @description convert date into MMM DD YYYY  formate
 */
export function convertISOToUtcDateformate1(date) {
  return Moment.utc(date).format(DATE_FORMATE.FORMATE2);
}

/**
 * @method convertISOToUtcDateformate2
 * @description convert date into MMM/DD/YYYY  formate
 */
export function convertISOToUtcDateformate2(date) {
  date = Moment(date).format(DATE_FORMATE.FORMATE3);
  return date;
}

/**
 * @method convertISOToUtcDateformate3
 * @description convert date into YYYY-MM-DD  formate
 */
export function convertISOToUtcDateformate3(date) {
  return Moment.utc(date).format(DATE_FORMATE.FORMATE4);
}

/**
 * @method convertISOToUtcDateformate4
 * @description convert date into DD-MM-YYYY  formate
 */
export function convertISOToUtcDateformate4(date) {
  return Moment.utc(date).format(DATE_FORMATE.FORMATE5);
}

/**
 * @method convertISOToUtcDateFormate
 * @description convert date into MMM DD, YYYY  formate
 */
export function convertISOToUtcDateFormate(date) {
  return Moment.utc(date).format(DATE_FORMATE.FORMATE6);
}

export function splitAndFormatMultipleDates(dates) {
  if (!dates) return "";
  let ds = dates.split(",");
  ds = ds.map((d) => Moment(String(d)).format("MM/DD/YYYY"));
  return ds.join(", ");
}

// ------ this all date formate display diffrent type of formatting -----------

/**
 * @method phoneNumberFormate
 * @description used for phone number formatting
 */
export function phoneNumberFormate(phoneNo) {
  //Filter only numbers from the input
  const number =
    phoneNo !== undefined
      ? phoneNo.replace(/\D/g, "")
      : phoneNo.replace(/\D/g, "");
  //Check if the input is of correct length
  let match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
  let phoneNumber = "";
  if (match) {
    return (phoneNumber = match[1] + "-" + match[2] + "-" + match[3]);
  } else {
    return phoneNumber;
  }
}

/**
 * @method phoneNumberFormateOnChange
 * @description used for formate phone number at onchange event
 */
export function phoneNumberFormateOnChange(e) {
  const number =
    e !== undefined && e.target !== undefined
      ? e.target.value.replace(/\D/g, "")
      : e.replace(/\D/g, "");
  //Check if the input is of correct length
  let match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  } else {
    return number;
  }
}

/**
 * @method displayDateTimeFormate
 * @description used for display date or tome formate conditionally
 */
export function displayDateTimeFormate(date) {
  const currentDate = Moment();
  const dateObj = Moment(date);
  if (
    Moment(
      Moment(date).format(DATE_FORMATE.FORMATE4),
      DATE_FORMATE.FORMATE4,
      true
    ).isValid()
  ) {
    // check day difference is not less or grater then zero
    if (checkNumberOfDayDiff(date, currentDate) === 0) {
      const remainingTimeInMinutes = currentDate.diff(dateObj, "minutes");
      if (remainingTimeInMinutes > 720) {
        return `Today ${Moment(date, DATE_FORMATE.FORMATE7).format(
          DATE_FORMATE.FORMATE1
        )}`;
      } else if (remainingTimeInMinutes >= 60 && remainingTimeInMinutes < 720) {
        const remainingTimeInHours = currentDate.diff(dateObj, "hours");
        return `${remainingTimeInHours} hours ago`;
      } else if (remainingTimeInMinutes < 60 && remainingTimeInMinutes > 0) {
        return `${remainingTimeInMinutes} min(s) ago`;
      } else if (remainingTimeInMinutes === 0) {
        return "few seconds ago";
      } else {
        return Moment(date, DATE_FORMATE.FORMATE7).format("hh:mm A");
      }
    } else if (
      checkNumberOfDayDiff(date, currentDate) >= 1 &&
      checkNumberOfDayDiff(date, currentDate) <= 7
    ) {
      return Moment(date).format("ddd hh:mm A");
    } else {
      return Moment(date, DATE_FORMATE.FORMATE7).format(DATE_FORMATE.FORMATE1);
    }
  } else {
    return "N/A";
  }
}

/**
 * @method checkNumberOfDayDiff
 * @description used for check number of day difference
 */
function checkNumberOfDayDiff(date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}
