import React from "react";
import { connect } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import { injectIntl } from "react-intl";
import { Alert } from "react-bootstrap";
import { Label, Input } from "reactstrap";
import { renderText } from "../../../../common/layout/FormInput";
import * as superAdminDuck from "../../../../store/ducks/SuperAdmin.duck";
import * as adManagementDuck from "../../../../store/ducks/super-admin/AdManagement.duck";
import { MESSAGES } from "../../../../config/message";
import {
  required,
  gminLength,
  maxLength100,
} from "../../../../config/validation";
import { Loader } from "../../../../common/Loader";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { toastr } from "react-redux-toastr";
import { FILE_SIZE } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import RadioButtonRP from "../../../../common/RadioButtonRP";

class UpdateAddTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      show: false,
      isRedirect: false,
      message: "",
      flag: false,
      isSubmitted: true,
      loading: false,
      files: [],
      pictures: [],
      adType: false,
      imageArray: [],
      images: [],
      initialImages: [],
      imageLoading: false,
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    const { authToken } = this.props.loggedInUser;
    let result = this.props.getValues();
    const { adTypeId } = result;
    if (adTypeId && adTypeId !== undefined) {
      this.props.getAdTypeDetailById(adTypeId, authToken, (res) => {
        if (res.status === STATUS_CODES.OK) {
          if (res.data.result.ad_type === "Classified") {
            this.setState({
              adType: true,
              initialImages: res.data.result.ad_urls,
            });
          } else {
            this.setState({
              adType: false,
              initialImages: res.data.result.ad_urls,
            });
          }
        }
      });
    }
  }
  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    const { selectedAdType } = this.props;
    this.setState({ overSizedImage: false, invalidImage: false });
    if (selectedAdType && selectedAdType !== undefined) {
      this.props.change("ad_name", selectedAdType.ad_name);
      if (selectedAdType.ad_type === "Classified") {
        this.setState({ adType: true });
      } else {
        this.setState({ adType: false });
      }
      this.setState({ pictures: [] });
    }
    this.props.onCancel();
  };

  /**
   * @method checkvalidation
   * @description Check validation and display alert
   */
  checkvalidation = () => {
    const { initialImages, pictures, adTypeName } = this.state;
    const total = [...initialImages, ...pictures];
    console.log("total", total);
    if (total.length < 1 && adTypeName === 'Display') {
      this.setState({
        show: true,
        success: false,
        message: MESSAGES.SELECT_IMAGE,
      });
      setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
      return true;
    } 
    else 
    if (total.length > 10) {
      this.setState({
        show: true,
        success: false,
        message: MESSAGES.IMAGE_MAX_LENGTH,
      });
      setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
      return true;
    }
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    if (this.checkvalidation()) {
    } else {
      const { adType, initialImages, pictures } = this.state;
      const total = [...initialImages, ...pictures];
      const { selectedAdType } = this.props;
      if (
        selectedAdType &&
        selectedAdType !== undefined &&
        total.length <= 10
      ) {
        const requestData = {
          id: "",
          advertisementId: selectedAdType.id,
          ad_name: values.ad_name,
          ad_type: adType === true ? "Classified" : "Display",
          ad_urls: [...initialImages, ...pictures],
          active: 1,
          is_deleted: 0,
        };

        this.setState({ isSubmitted: false, loading: true });
        this.props.updateAdType(requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED) {
            toastr.success(MESSAGES.UPDATE_AD_TYPE_SUCCESS);
            this.props.callList();
            this.setState({ loading: false });
            setTimeout(() => {
              this.toggleModel();
              this.setState({ loading: false });
            }, 3000);
          } else {
            this.setState({ loading: false });
          }
        });
      }
    }
  }
  /**
   * @method removeItem
   * @description Used to remove item from image list
   */
  removeItem(index) {
    const list = this.state.pictures;
    const removeFile = this.state.images;
    removeFile.splice(index, 1);
    list.splice(index, 1);
    this.setState({ pictures: list, images: removeFile });
  }

  /**
   * @method removeInitialItem
   * @description Used to remove previous images from list
   */
  removeInitialItem(index) {
    const removeFile = this.state.initialImages;
    removeFile.splice(index, 1);
    this.setState({ initialImages: removeFile, isSubmitted: true });
  }

  /**
   * @method renderPreviewImage
   * @description Used to render selected images
   */
  renderPreviewImage = () => {
    const { pictures, imageLoading } = this.state;
    return pictures.map((e, i) => {
      if (imageLoading && i === pictures.length - 1) {
        return (
          <div
            key={i}
            className="my-3 ml-3"
            style={{
              position: "relative",
              height: 100,
              width: 125,
              marginLeft: 20,
              marginTop: 20,
            }}
          >
            <div
              className="spinner-border text-primary"
              role="status"
              style={{ position: "absolute", top: "30%", left: "48%" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={i}
            className="my-3"
            style={{ position: "relative", width: 125 }}
          >
            <img
              src={e}
              style={{ height: 100, width: 100, marginLeft: 20, marginTop: 20 }}
              alt=""
            ></img>
            <i
              className="fa fa-close"
              style={{ position: "absolute", cursor: "pointer" }}
              onClick={() => this.removeItem(i)}
            ></i>
          </div>
        );
      }
    });
  };

  /**
   * @method renderInitialPreviewImage
   * @description Used to render initial preview image
   */
  renderInitialPreviewImage = () => {
    const { initialImages } = this.state;
    if (initialImages && initialImages !== undefined) {
      return (
        Array.isArray(initialImages) &&
        initialImages.map((e, i) => {
          return (
            <div
              key={i}
              className="my-3"
              style={{ position: "relative", width: 125 }}
            >
              <img
                src={`${PROFILE_MEDIA_URL}/${e}`}
                style={{
                  height: 100,
                  width: 100,
                  marginLeft: 20,
                  marginTop: 20,
                }}
                alt=""
              ></img>
              <i
                className="fa fa-close"
                style={{ position: "absolute", cursor: "pointer" }}
                onClick={() => this.removeInitialItem(i)}
              ></i>
            </div>
          );
        })
      );
    }
  };

  /**
   * @method fileSelectedHandler
   * @description used to handle file selection
   */
  fileSelectedHandler = (e) => {
    let file = e.target.files[0];
    let me = this;
    let fileType = "";
    let size = "";
    let validType = "";
    if (file && file !== "undefined") {
      fileType = file.type;
      size = file.size;
      validType =
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/gif";
    }
    if (size <= FILE_SIZE && validType) {
      this.setState(
        {
          overSizedImage: false,
          invalidImage: false,
          imagePreviewUrl: "",
          imgSrc: "",
          isSubmitted: true,
        },
        () => {
          this.getBase64(file, function(base64Data) {
            me.setState({ imageLoading: false });
            let encodedImage = me.state.images;
            encodedImage.push(base64Data);
            me.setState(
              {
                pictures: [
                  ...me.state.pictures,
                  encodedImage[encodedImage.length - 1],
                ],
                //  initialImages:[... me.state.initialImages]
              },
              () => {
                // me.setState({ initialImages: [] })
              }
            );
          });
        }
      );
    } else if (size >= FILE_SIZE && validType) {
      this.setState({
        overSizedImage: true,
        invalidImage: false,
        imagePreviewUrl: "",
        imgSrc: "",
        isSubmitted: false,
      });
    } else {
      this.setState({
        invalidImage: true,
        overSizedImage: false,
        imagePreviewUrl: "",
        imgSrc: "",
        isSubmitted: false,
      });
    }
  };

  /**
   * @method getBase64
   * @description convert into base 64 formate
   */
  getBase64 = (file, callback) => {
    const reader = new FileReader();
    this.setState({ imageLoading: true });
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  render() {
    const { handleSubmit, selectedAdType, isOpen } = this.props;
    const {
      show,
      success,
      overSizedImage,
      invalidImage,
      isSubmitted,
      initialImages,
      loading,
      message,
      adType,
      file,
      adTypeName
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggleModel}
        styles={{ height: 10, width: 100 }}
        size="lg"
      >
        {loading && <Loader />}
        {show && !success && (
          <Alert
            variant="danger"
            onClose={() => this.setState({ show: false })}
            dismissible
          >
            {message}
          </Alert>
        )}
        <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
          Update Ad Type
        </ModalHeader>
        <ModalBody styles={{ width: 600 }}>
          <Row>
            <Container>
              <form
                noValidate
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="12">
                    <Field
                      name="ad_name"
                      label={"Ad Type Name"}
                      placeholder="Enter Ad Type Name"
                      validate={[required, gminLength, maxLength100]}
                      required={true}
                      component={renderText}
                      onChange={() => this.setState({ isSubmitted: true })}
                    />
                  </Col>
                </Row>
                <Row className="ml-1">
                  <Form.Label md="4">
                    <h6>
                      Ad Classification
                      <span className="asterisk-required">*</span>
                    </h6>
                  </Form.Label>
                  <Col md="8">
                    <div className="radio-inline">
                      <RadioButtonRP label="Classified Ad Type">
                        <Input
                          type="radio"
                          id="checkbox2"
                          checked={adType ? true : ""}
                          onChange={(e) =>
                            this.setState({
                              adType: true,
                              adTypeName: "Classified",
                              isSubmitted: true,
                            })
                          }
                          name="ad_type"
                        />
                      </RadioButtonRP>
                      <RadioButtonRP label="Display Ad Type">
                        <Input
                          type="radio"
                          id="checkbox2"
                          checked={!adType ? true : ""}
                          onChange={(e) =>
                            this.setState({
                              adType: false,
                              adTypeName: "Display",
                              isSubmitted: true,
                            })
                          }
                          name="ad_type"
                        />
                      </RadioButtonRP>
                    </div>
                  </Col>
                </Row>
                <Row className="ml-1 align-items-baseline">
                  <Form.Label>
                    <h6>
                      Ad Preview Images
                      { adTypeName === 'Display' && <span className='asterisk-required'>*</span>}
                    </h6>
                  </Form.Label>
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  <input
                    type="file"
                    onChange={this.fileSelectedHandler}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    src={file}
                    style={{ display: "none" }}
                    id="fileButton"
                  />
                  <label
                    htmlFor="fileButton"
                    style={{ marginBottom: "auto", cursor: "pointer" }}
                    className="btn btn-secondary"
                  >
                    Upload
                  </label>
                  {overSizedImage && (
                    <div className="text-help" style={{ marginLeft: "10px" }}>
                      {"File size should be less then 5 MB"}
                    </div>
                  )}
                  {invalidImage && (
                    <div className="text-help" style={{ marginLeft: "10px" }}>
                      {"Only JPEG and PNG, GIF files are accepted."}
                    </div>
                  )}
                </Row>
                <Row
                  className="my-2"
                  style={{
                    height:
                      initialImages && initialImages.length > 0 ? 200 : "auto",
                    overflowY: "auto",
                  }}
                >
                  {selectedAdType &&
                    selectedAdType !== undefined &&
                    this.renderInitialPreviewImage()}
                  {this.renderPreviewImage()}
                </Row>
                <Row></Row>
                <Row className="float-right">
                  <Col md="12">
                    <Button
                      disabled={isSubmitted ? false : true}
                      style={{
                        margin: 10,
                        background: "black",
                        border: "none",
                        width: 100,
                      }}
                      size="md"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  const selectedAdType = store.superAdminDuck.selectedAdType;
  const { loading } = store.commonDuck;
  let initialValues = {};
  if (selectedAdType && selectedAdType !== undefined) {
    initialValues = {
      ad_name: selectedAdType.ad_name,
      ad_type: selectedAdType.ad_type,
      ad_url: "",
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    selectedAdType: store.superAdminDuck.selectedAdType,
    initialValues,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...superAdminDuck.actions,
    ...adManagementDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "UpdateAddTypeModal",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(UpdateAddTypeModal)
  )
);
