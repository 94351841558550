import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import * as commonDuck from '../../../../store/ducks/Common.duck';
import StandardTable from "../../StandardTable";
import { Image } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import { convertISOToUtcDate } from "../../../../common";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";
import ConformModal from "../../super-admin/ConformModel";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { langs } from "../../../../config/localization";
import { InfoToolTipButton } from "../../../../common/ToolTips";
import { EditIconLink, DeleteIconButton, CopyIconLink } from "../../../../common/Buttons";
import CampaignModal from "./CampaignModal";

/**
 * @method columnOrder
 * @description define the table columns order and format data accordingly
 * @param {*} activatePlan
 */
const columnOrder = (setItemToDelete, url, colors, socialMediaCategoryList) => [
  // {
  //   Header: "Plan Image",
  //   //width: 90,
  //   sortable: false,
  //   id: "plan_image",
  //   Cell: (row) => {
  //     const { original } = row;
  //     const { advertisements } = original;
  //     let image = "";
  //     // extract plan image
  //     if (advertisements) {
  //       advertisements.map((ad) => {
  //         if (ad.npc_advertisements && ad.npc_advertisements.length > 0) {
  //           const npcAd = ad.npc_advertisements[0];
  //           if (
  //             parseInt(npcAd.newspaperId) === parseInt(original.npc_id) &&
  //             parseInt(npcAd.advertisementId) === parseInt(original.ad_type)
  //           ) {
  //             image = JSON.parse(npcAd.ad_urls)[0];
  //           }
  //         }
  //       });
  //     }

  //     return (
  //       <div>
  //         <Image
  //           className="ml-2 mr-2"
  //           src={
  //             image
  //               ? `${PROFILE_MEDIA_URL}/${image}`
  //               : "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
  //           }
  //           style={{ borderRadius: "100%", height: 35, width: 35 }}
  //         />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   Header: "Plan Name",
  //   //width: 150,
  //   accessor: "plan_name",
  //   Cell: (row) => {
  //     const { original } = row;
  //     return (
  //       <Link to={`${url}/edit-plan/${row.original.id}`}>
  //         <span>
  //           {original && original.plan_name !== null ? original.plan_name : ""}
  //         </span>
  //       </Link>
  //     );
  //   },
  // },


  {
    Header: (props) => (
      <span>
        Ad Type
      </span>
    ),
    accessor: "ad_type",
    //width: 60,
    Cell: (row) => {
      const adtype = [
        {
          name: "WebsiteAd",
          id: 1
        },
        {
          name: "TwitterPost",
          id: 2
        },
        {
          name: "InstagramPost",
          id: 3
        },
        {
          name: "WhatsappPost",
          id: 4
        },
        {
          name: "WhatsappStatus",
          id: 5
        },
        {
          name: "EmailMarketing",
          id: 6
        },
        {
          name: "SponsoredArticle",
          id: 7
        },
        {
          name: "BrowserPushNotification",
          id: 8
        },
        {
          name: "InstagramStories",
          id: 9
        },
        {
          name: "SponsoredPosts",
          id: 10
        },
        {
          name: "FacebookPost",
          id: 11
        },
        {
          name: "In App Ad",
          id: 12
        },
        {
          name: "Email Blast",
          id: 13
        }
      ];
      const { original } = row;
      const { ad_type } = original;
      // //console.warn(socialMediaCategoryList)
      let ad_name = ""
      for (let i = 0; i < socialMediaCategoryList.length; i++) {
        if (socialMediaCategoryList[i].id == ad_type) {
          ad_name = socialMediaCategoryList[i].name
          break;
        }
      }
      return <span>{ad_name ? `${ad_name}` : ""}</span>;
    },
  },
  //   {
  //     Header: "Edition",
  //     //width: 100,
  //     id: "edition_name",
  //     accessor: (d) => {


  //       const { editions, color_option } = d;
  //       let edition = {};
  //       // //console.warn('advertisements',advertisements);
  //       // extract advertisement type
  //       if (editions.length > 0) {
  //         editions.map((ad) => {
  //           if (parseInt(ad.id) === parseInt(d.edition_id)) {
  //             edition = ad;
  //           }
  //         });

  //         return <div>{edition ? edition.name : ""}</div>;
  //       }

  //     },
  //   },
  {
    Header: (props) => (
      <span>
        Price
        <br />
        (in $)
      </span>
    ),
    accessor: "deadline_price",
    //width: 60,
    Cell: (row) => {
      const { original } = row;
      const { deadline_price } = original;
      return <span>{deadline_price ? `$${deadline_price}` : ""}</span>;
    },
  },
  {
    Header: (props) => (
      <span>
        Desktop Ad Size
        <br />
        (in Pixels)
      </span>
    ),
    id: "width",
    accessor: (d) => (`${d.width}*${d.height}`),
    //width: 80,
  },
  {
    Header: (props) => (
      <span>
        Mobile Ad Size
        <br />
        (in Pixels)
      </span>
    ),
    id: "m_width",
    accessor: (d) => (`${d.m_width}*${d.m_height}`),
    //width: 80,
  },
  {
    Header: "Date of Creation/ Modification",
    //width: 120,
    id: "updatedAt",
    accessor: (d) => (d.updatedAt ? d.updatedAt : d.createdAt),
    Cell: (row) => {
      const { original } = row;
      let createdAt, updatedAt;
      createdAt = original.createdAt;
      updatedAt = original.updatedAt;
      return (
        <span>
          {updatedAt
            ? convertISOToUtcDate(updatedAt)
            : convertISOToUtcDate(createdAt)}{" "}
        </span>
      );
    },
  },
  {
    Header: "Discount Applicable",
    id: "discount",
    accessor: "discount",
    //width: 80,
    Cell: (row) => {
      const { original } = row;
      return original.discount ? "Yes" : "No";
    },
  },
  {
    Header: "Status",
    accessor: "active",
    //width: 90,
    Cell: (row) => {
      const { original } = row;
      const { advertisements, color_option } = original;
      let isRelatedDataDeleted = false;

      if (advertisements) {
        advertisements.map((ad) => {
          if (parseInt(ad.id) === parseInt(original.ad_type)) {
            if (ad.is_deleted === true) {
              isRelatedDataDeleted = true;
            }
          }
        });
      }

      //   const colorOptions = JSON.parse(JSON.parse(color_option));

      //   if (Array.isArray(colorOptions) && colorOptions.length > 0) {
      //     colorOptions.map((col) => {
      //       if (!col.id) {
      //         return;
      //       }

      //       let matchedColor = colors.find(
      //         (c) => parseInt(c.id) === parseInt(col.id)
      //       );
      //       if (!matchedColor) {
      //         isRelatedDataDeleted = true;
      //       } else {
      //         if (matchedColor.is_deleted === true) {
      //           isRelatedDataDeleted = true;
      //         }
      //       }
      //     });
      //   }

      return (
        <div className="d-flex justify-content-between align-items-center">
          {original.active ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          )}
          {isRelatedDataDeleted && (
            <InfoToolTipButton title="Ad Squad Media Administrator has discontinued/removed the Ad Type/ color which was used in the plan. Kindly update the plan." />
          )}
        </div>
      );
    },
  },
  {
    Header: "Action",
    sortable: false,
    minWidth: 150,
    id: "action",
    tdProps: () => {
      return {
        style: {
          whiteSpace: "nowrap",
        },
      };
    },
    Cell: (row) => (
      <div style={{ padding: 5 }}>
        {/* <EditIconLink to={`${url}/edit-plan/${row.original.id}`} />
        <CopyIconLink to={`/ourPlans/create-new/${row.original.id}`} />
        <DeleteIconButton
          onClick={() => {
            setItemToDelete(row.original);
          }}
        /> */}
        <EditIconLink to={`${url}/edit-social/${row.original.id}`} />
        <CopyIconLink to={`${url}/copy-social/${row.original.id}`} />
        <DeleteIconButton
          onClick={() => {
            setItemToDelete(row.original);
          }}
        />
        {/* {row.original.id} */}

      </div>
    ),
  },
];

const SocialPlansList = ({
  loggedInUser,
  searchResults,
  advancedSearchResult,
  socialMediaCategoryList,
  /** actions */
  getAllSocialMediaCategoryList,
  getSocialPlansList,
  deleteSocialPlanById,
  searchInPlansList,
  getfromNPCSetting,
  socialPlanDelete
}) => {
  const ad_type = [
    {
      name: "WebsiteAd",
      id: 1
    },
    {
      name: "TwitterPost",
      id: 2
    },
    {
      name: "InstagramPost",
      id: 3
    },
    {
      name: "WhatsappPost",
      id: 4
    },
    {
      name: "WhatsappStatus",
      id: 5
    },
    {
      name: "EmailMarketing",
      id: 6
    },
    {
      name: "SponsoredArticle",
      id: 7
    },
    {
      name: "BrowserPushNotification",
      id: 8
    },
    {
      name: "InstagramStories",
      id: 9
    },
    {
      name: "SponsoredPosts",
      id: 10
    },
    {
      name: "FacebookPost",
      id: 11
    }
  ]
  const { url } = useRouteMatch();

  // extract logged in user details
  const { user_detail } = loggedInUser;

  // colors option list
  const [colors, setColors] = useState([]);

  // basic search key input state
  const [searchKey, setSearchKey] = useState("");

  // pagination states
  const [pageSize, setPageSize] = useState(langs.paginationKeys.pageSize);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(100);

  // total plans state
  const [plansList, setPlansList] = useState([]);

  // item to delete state
  const [itemToDelete, setItemToDelete] = useState(null);

  // sorting order state true===ASC and false===DESC
  const [sortedOrder, setSortedOrder] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [deletedId, setDeletedId] = useState(null);

  /**
   * @method fetchPlansList
   * @description fetch plans list
   * @param {*} row
   * @param {*} page
   */
  const fetchPlansList = (row, page, sortBy = "ASC", column = "") => {
    // request data
    const requestData = {
      id: "",
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : langs.paginationKeys.pageSize,
      page: page !== undefined ? page : 1,
      isDeleted: 0,
      sort: sortBy,
      column: column,
    };

    // fetch plans list action
    getSocialPlansList(requestData, (res) => {
      // //console.warn('++++++++++plan list',res);
      if (res && String(res.status) === String(STATUS_CODES.OK)) {
        let pages = res.data.count / pageSize;
        let plans = res.data.rows;

        // set plans list state
        setPlansList(plans);
        // set total page count state
        setTotalCount(Math.ceil(pages));
      }
    });
  };

  /**
   * @method onPageChange
   * @description used for handle pagination
   */
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  /**
   * @method onPageSizeChange
   * @description used for handle pagination
   */
  const onPageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  /**
   * @method handelSearch
   * @description handle search
   * @param {*} e
   */
  const handleSearch = (e) => {
    const key = e.target.value;
    if (!key || key.trim() === "" || key.length <= 2) {
      setSearchKey("");
    } else if (key.trim().length >= 3) {
      // generate search request only on hit of more than 2 characters
      setSearchKey(key);

      // search request data
      const requestData = {
        plan_name: encodeURIComponent(
          key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 0,
        npc_id: user_detail.newspaper.id,
        row: pageSize !== undefined ? pageSize : langs.paginationKeys.pageSize,
        page: page !== undefined ? page : 1,
      };

      // basic search action
      searchInPlansList(requestData);
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  const handleSort = (e, { sorted }, rowInfo, column, instance) => {
    // prevent sort with plan image and action
    if (column.id === "action" || column.id === "plan_image") {
      return;
    }
    instance.sortColumn(column);
    let sortBy = sortedOrder ? "ASC" : "DESC";
    setSortedOrder(!sortedOrder);
    fetchPlansList(pageSize, page, sortBy, column.id);
  };

  /**
   * @method confirmDelete
   * @description handle delete plan
   */
  const confirmDelete = () => {
    // delete plan action
    deleteSocialPlanById(itemToDelete.id, (res) => {
      setDeletedId(itemToDelete.id);
      setItemToDelete(null);
      if (res && res.data && res.data.showmodal) {
        setShowModal(true);
        setMessage(res.data.message);
      }
      else {
        // re-fetching entire data
        fetchPlansList(pageSize, page);
        if (res.status === STATUS_CODES.OK)
          toastr.success("Success", res.data);
        if (searchKey.trim() !== "" && searchKey.trim().length >= 3) {
          // if data is filtered then again filter it with the search key
          // request data
          const requestData = {
            plan_name: encodeURIComponent(
              searchKey.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            ),
            is_deleted: 0,
            npc_id: user_detail.newspaper.id,
            row:
              pageSize !== undefined ? pageSize : langs.paginationKeys.pageSize,
            page: page !== undefined ? page : 1,
          };
          // search action
          searchInPlansList(requestData);
        }
      }
    });
  };

  let cancelDelete = () => {
    setShowModal(false);
  };

  let confirmDeletePlan = () => {
    let dt = {
      id: deletedId,
      active: false,
      is_deleted: true
    }
    socialPlanDelete(dt, (resp) => {
      setShowModal(false);
      // re-fetching entire data
      fetchPlansList(pageSize, page);
      toastr.success("Success", resp.data);
      if (searchKey.trim() !== "" && searchKey.trim().length >= 3) {
        // if data is filtered then again filter it with the search key
        // request data
        const requestData = {
          plan_name: encodeURIComponent(
            searchKey.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
          ),
          is_deleted: 0,
          npc_id: user_detail.newspaper.id,
          row:
            pageSize !== undefined ? pageSize : langs.paginationKeys.pageSize,
          page: page !== undefined ? page : 1,
        };
        // search action
        searchInPlansList(requestData);
      }
    })
  }

  /**
   * runs on change in dependencies
   */
  useEffect(() => {
    fetchPlansList(pageSize, page);
  }, [pageSize, page]);

  /**
   * runs on mount to fetch all the plans list
   */
  useEffect(() => {

    getAllSocialMediaCategoryList(langs.keyname.socialmediacategory);

    // getfromNPCSetting("color", 0, (res) => {
    //   if (res.status === STATUS_CODES.OK) {
    //     setColors(res.data);
    //   }
    // });
    fetchPlansList();
  }, []);

  /**
   * If there is search result show it otherwise show all data
   */
  let results = plansList;

  if (advancedSearchResult && advancedSearchResult.length === 0) {
    results = [];
  } else if (advancedSearchResult && advancedSearchResult.length > 0) {
    results = advancedSearchResult;
  } else if (
    searchResults &&
    searchKey.trim() !== "" &&
    searchResults.length == 0
  ) {
    results = [];
  } else if (
    searchResults &&
    searchResults.length > 0 &&
    searchKey.trim() !== ""
  ) {
    results = searchResults;
  } else if (searchResults === undefined) {
    results = [];
  } else if (searchResults.length === 0 && searchKey.trim() !== "") {
    results = [];
  } else {
    results = plansList;
  }

  return (
    <div>
      <ConformModal
        isOpen={itemToDelete !== null}
        onOkClick={confirmDelete}
        onCancelClick={() => setItemToDelete(null)}
      />
      <StandardTable
        handelSearch={handleSearch}
        columnOrder={columnOrder(setItemToDelete, url, colors, socialMediaCategoryList)}
        totalCount={totalCount}
        allData={plansList}
        data={results}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        isAdvanceSearch={true}
        advancedSearchFor="PLAN"
        handleSort={handleSort}
        getTrProps={(state, rowInfo, column) => {
          if (rowInfo) {
            const { original } = rowInfo;
            const { ad_type, advertisements, color_option } = original;
            let isRelatedDataDeleted = false;
            // advertisements.map((ad) => {
            //   if (
            //     parseInt(ad.id) === parseInt(ad_type) &&
            //     ad.is_deleted === true
            //   ) {
            //     isRelatedDataDeleted = true;
            //   }
            // });

            // const colorOptions = JSON.parse(JSON.parse(color_option));

            // if (Array.isArray(colorOptions) && colorOptions.length > 0) {
            //   colorOptions.map((col) => {
            //     if (!col.id) {
            //       return;
            //     }

            //     let matchedColor = colors.find(
            //       (c) => parseInt(c.id) === parseInt(col.id)
            //     );
            //     if (!matchedColor) {
            //       isRelatedDataDeleted = true;
            //     } else {
            //       if (matchedColor.is_deleted === true) {
            //         isRelatedDataDeleted = true;
            //       }
            //     }
            //   });
            // }

            return {
              style: {
                background:
                  isRelatedDataDeleted === true
                    ? "#ccc"
                    : rowInfo.original.active
                      ? ""
                      : "#ccc",
                alignItems: "center",
              },
            };
          }
          return {
            style: {
              alignItems: "center",
            },
          };
        }}
      />
      <CampaignModal
        isOpen={showModal}
        onCancelClick={cancelDelete}
        onOkClick={confirmDeletePlan}
        keyname={"Plan"}
        message={message}
      />
    </div>
  );
};

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 */
const mapStateToProps = (state) => {
  const { auth, npcDuck, commonDuck } = state;
  const { loading, socialMediaCategoryList } = commonDuck;

  return {
    loggedInUser: auth.user,
    searchResults: npcDuck.searchResult ? npcDuck.searchResult : [],
    advancedSearchResult: npcDuck.advancedSearchResult
      ? npcDuck.advancedSearchResult
      : undefined,
    socialMediaCategoryList: socialMediaCategoryList ? socialMediaCategoryList : [],
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
    ...commonDuck.actions
  })(SocialPlansList)
);
