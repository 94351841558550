import React from "react";
import { connect } from "react-redux";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { Field, reduxForm, untouch, change } from "redux-form";
import { Col, Row, Button, Card } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { Multiselect } from 'multiselect-react-dropdown';
import {
  required,
  selectRequired,
  gminLength,
  maxLength100,
} from "../../../../config/validation";
import {
  renderText,
  renderSelectField,
} from "../../../../common/layout/FormInput";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import { MESSAGES } from "../../../../config/message";
import ConformModel from "./ConfirmModel";
import { superAdminId } from "../../../../config";
import { toastr } from "react-redux-toastr";
import { langs } from "../../../../config/localization";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { PUBLISH_DAY } from "../../../../config/constant";
import AgentModal from "./AgentModal";

class Edition extends React.Component {
  // let a = parseInt(new Date().toDateString().split(" ")[3]);
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayClickMultiple = this.handleDayClickMultiple.bind(this);
    this.handleDayClickMultiMonth = this.handleDayClickMultiMonth.bind(this);
    this.state = {
      showAgentModal: false,
      agent_names : [],
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      name: "",
      days:[],
      publishDayArray: PUBLISH_DAY,
      selectedFrequency: "",
      selectedMonthFrequency:"",
      selectedDay: "",
      selectedDays: [],
      selectedDayMulti:[],
      selectedDayMultiMonth: [],
      selectedValue: [],
      isSubmitted: true,
      showResults:false,
      showMonth:false,
      showMultiDates: false,
      showMultiMonths: false,
      showMonthWeek: false,
      monthFrequency:false,
      biWeekly:false,
      daily:false,
      monthOption: [
        {name: "First day of the month", id: "Firstday_of_Month"},
        {name: 'Weekday of the month', id: "Weekday_of_Month"}
      ],
      options: [
      {name: 'Sunday', id: 1},
      {name: 'Monday', id: 2},
      {name: 'Tuesday',id: 3},
      {name: 'Wednesday',id:4},
      {name: 'Thursday',id: 5},
      {name: 'Friday',id: 6},
      {name: 'Saturday',id: 7} 
      ],

      optionsTwo: [
        {name: 'Sunday', id: 1},
        {name: 'Monday', id: 2},
        {name: 'Tuesday',id: 3},
        {name: 'Wednesday',id:4},
        {name: 'Thursday',id: 5},
        {name: 'Friday',id: 6},
        {name: 'Saturday',id: 7} 
        ],

      optionsDay: [
        {name: '1', id: 1},
        {name: '2', id: 2},
        {name: '3',id: 3},
        {name: '4',id:4},
        {name: '5',id: 5},
        {name: '6',id: 6},
        {name: '7',id: 7},
        {name: '8',id: 8},
        {name: '9',id: 9},
        {name: '10',id: 10},
        {name: '11', id: 11},
        {name: '12', id: 12},
        {name: '13',id: 13},
        {name: '14',id:14},
        {name: '15',id: 15},
        {name: '16',id: 16},
        {name: '17',id: 17},
        {name: '18',id: 18},
        {name: '19',id: 19},
        {name: '20',id: 20},
        {name: '21', id: 21},
        {name: '22', id: 22},
        {name: '23',id: 23},
        {name: '24',id:24},
        {name: '25',id: 25},
        {name: '26',id: 26},
        {name: '27',id: 27},
        {name: '28',id: 28},
        // {name: '9',id: 9},
        // {name: '10',id: 10}    
        ]
    };

    if(new Date().toDateString().split(" ")[1] != "Feb"){
      this.state.optionsDay.push(
        { name: '29',id: 29 }
      )
      this.state.optionsDay.push(
        { name: '30',id: 30 }
      )
    }
    
    
  }



  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.getAllEdition();
    this.getAllFrequencies();
  }

  handleDayClick(day, { selected }) {
    //console.warn(">>>Inside handledayclick>>>>>",day)
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  handleDayClickMultiple(day, { selected }) {
    console.log(`Inside Multiple Click ${day}`)
    const selectedDayMulti = this.state.selectedDayMulti.concat();
    // const allDays = this.state.allDays.concat();
    if (selected) {
      const selectedIndex = selectedDayMulti.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDayMulti.splice(selectedIndex, 1);
    } else {
      if(selectedDayMulti.length < 4){
        selectedDayMulti.push(day);
        // allDays.push(day.toLocaleDateString())
      }
      // else{
      //   for(let i = 0; i < selectedDays.length ; i++)
      //   {
      //     selectedDays[i] = selectedDays[i].toLocaleDateString()
      //   }
      //   console.log(selectedDays)
      // }
      
    }
    // console.log(selectedDays)
    this.setState({ selectedDayMulti });
  }



  handleDayClickMultiMonth(day, { selected }) {
    console.log(`Inside Multiple Click ${day}`)
    const selectedDayMulti = this.state.selectedDayMulti.concat();
    // const allDays = this.state.allDays.concat();
    if (selected) {
      const selectedIndex = selectedDayMulti.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDayMulti.splice(selectedIndex, 1);
    } else {
      // if(selectedDayMulti.length == 0){
      //   selectedDayMulti.push(day);
      //   // allDays.push(day.toLocaleDateString())
      // }
      // else if(selectedDayMulti.length == 1){
      //   if(JSON.stringify(day).split("-")[1] == JSON.stringify(selectedDayMulti[0]).split("-")[1]){
      //     selectedDayMulti.push(day);
      //   }else{
      //     selectedDayMulti.pop();
      //     // console.log(selectedDayMulti.length)
      //     // console.log(JSON.stringify(day).split("-")[1])
      //     // console.log(month)
      //   }
      // }
      // else{
      //   for(let i = 0; i < selectedDays.length ; i++)
      //   {
      //     selectedDays[i] = selectedDays[i].toLocaleDateString()
      //   }
      //   console.log(selectedDays)
      // }

      selectedDayMulti.push(day);
      
    }
    // console.log(selectedDays)
    this.setState({ selectedDayMulti });
  }



  getAllEdition = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getfromNPCSetting(
      langs.keyname.edition,
      user_detail.newspaper.id,
      (res) => {}
    );
  };

  getAllFrequencies = () => {
    this.props.getfromNPCSetting(
      langs.keyname.print_frequency,
      superAdminId,
      (res) => {}
    );
  };
  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };
  cancelAgent = () => {
    this.setState({ showAgentModal: false });
  };

  changeOption = (e) => {


    //Daily
    if(e.target.value==19){
      this.setState({
        selectedDayMulti: this.state.options,
      });
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: false })
      this.setState({showResults:false});
      this.setState({showMonth:false});
      this.setState({monthFrequency:false})
      this.setState({daily:true})
      this.setState({selectedValue:this.state.options})
    }
    //Monthly
    else if(e.target.value==20){
      this.setState({
        selectedDay: "",
      });
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({showMonth:true});
      this.setState({ biWeekly: false })
      this.setState({showResults:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }
    //Bi-Monthly
    else if(e.target.value==23){
      this.setState({
        selectedDayMulti: [],
      });
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: true })
      this.setState({showMonth:false});
      this.setState({ biWeekly: false })
      this.setState({showResults:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }
    //Quarterly
    else if(e.target.value == 25){
      this.setState({
        selectedDayMulti: [],
      });
      this.setState({ showMultiDates: true })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: false })
      this.setState({showMonth:false});
      this.setState({showResults:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }
    //Weekly
    else if(e.target.value == 12){
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: false })
      this.setState({showResults:false});
      this.setState({showMonth:false});
      this.setState({monthFrequency:true});
      this.setState({daily:false});
    }
    //Bi-Weekly
    else if(e.target.value == 21){
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: true })
      this.setState({showResults:false});
      this.setState({showMonth:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }
    //Bi-Monthly
    else if(e.target.value == 22){
      this.setState({
        selectedDayMulti: [],
      });
      this.setState({
        selectedDay: "",
      });
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: false })
      this.setState({showResults:true});
      this.setState({showMonth:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }
    //Annually
    else if(e.target.value == 24){
      this.setState({
        selectedMonthFrequency:"",
      });
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: false })
      this.setState({showResults:true});
      this.setState({showMonth:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }else{
      this.setState({
        selectedDayMulti: [],
      });
      this.setState({
        selectedDay: "",
      });
      this.setState({
        selectedMonthFrequency:"",
      });
      
      this.setState({ showMultiDates: false })
      this.setState({ showMonthWeek: false })
      this.setState({ showMultiMonths: false })
      this.setState({ biWeekly: false })
      this.setState({showResults:false});
      this.setState({showMonth:false});
      this.setState({monthFrequency:false});
      this.setState({daily:false});
    }
    this.setState({
      selectedFrequency: e.target.value,
      isSubmitted: true,
    });
    

  };
 

  changeMonthOption = (e) => {

    // alert("checking"+e.target.value);

    if(e.target.value=='Firstday_of_Month'){
      this.setState({
        selectedDay: "",
      });
      this.setState({
        selectedDayMulti: [],
      });
      this.setState({showMonthWeek:false});
    }else{
      // alert("checking"+e.target);
      this.setState({
        selectedDay: "",
      });
      this.setState({
        selectedDayMulti: [],
      });
      this.setState({showMonthWeek:true});
    }
    this.setState({
      selectedMonthFrequency:e.target.value,
      isSubmitted: true,
    });
    
  };

 
  

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { authToken } = this.props.loggedInUser;
    const deletedItem = this.state.deletedItem;
    this.props.deleteEdition(
      langs.keyname.edition,
      deletedItem,
      authToken,
      (res) => {
        console.warn(res.data);
        if (res.status === STATUS_CODES.NO_CONTENT) {
          toastr.success(MESSAGES.DELETE_EDITION_SUCCESS);
          this.getAllEdition();
          this.setState({
            showConfirmModal: false,
          });
        }else{
          var user_names = res.data.result;
          var fresh_names = [];
          fresh_names.sort().reduce(function(a, b){ if (b != a[0]) a.unshift(b); return a }, [])
          
          // fresh_names.push(res.data.result[0]);
          // for(let i = 0 ;i < user_names.length; i ++){

          // }
          toastr.error("This Edition can't be deleted. Agents already use this Edition");
          
          this.setState({
            showAgentModal: true,
            agent_names: user_names
          });
          console.warn(user_names);
          this.getAllEdition();
          this.setState({
            showConfirmModal: false,
          });
        }
      }
    );
  };

  /**
   * @method renderEditionCardItem
   * @description render the color card item
   */
  renderEditionCardItem = (typeOfListing) => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data) => {
        return (
          <Card
            key={data.id}
            bg="light"
            style={{
              width: "auto",
              height: "auto",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <Card.Body>
              <Card.Title>
                <label>Edition:&nbsp;{data.name}</label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
                <i
                  className="fa fa-close crossIcon"
                  style={{ width: "auto", height: "auto" }}
                  onClick={() =>
                    this.setState({
                      showConfirmModal: true,
                      openModel: false,
                      deletedItem: data.id,
                    })
                  }
                ></i>
                <br />
                <label>Frequency:&nbsp;{data.print_frequency}</label>
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };

  /**
   * @method resetFields
   * @description reset ALl field Values
   */
  resetFields = (values) => {
    Object.keys(values).map((fName) => {
      this.props.dispatch(change("Edition", fName, null));
      this.props.dispatch(untouch("Edition", fName));
    });
  };

  /**
   * @method onSubmit
   * @description called api to state and area
   */
  onSubmit = (values) => {
    // alert('hello');
    const { user_detail } = this.props.loggedInUser;
    const { name, selectedDay,selectedDayMulti , selectedFrequency,selectedMonthFrequency } = this.state;
    //console.warn(`This is inside onSubmit: ${selectedDayMulti}`)
    //console.warn(">>>Selected Day>>>>", selectedDay)
    // let selectedDayMulti1;
    // //console.warn(selectedFrequency)
    // if(selectedFrequency == "22"){
    //   selectedDayMulti = selectedDayMultiMonth
    // }
    let flag;
    if(selectedFrequency == "20" && selectedDayMulti.length == 0){
      flag = true
    }else{
      flag = this.state.showResults;
    }
    let data = [];
    
    if(!flag){
      for(let i in selectedDayMulti){
        if(selectedDayMulti[i].name){
          data.push(selectedDayMulti[i].name);
        }else{
          data.push(selectedDayMulti[i].toJSON());
        }
        console.log(data)
        
      }
      //console.warn("fg111",data);
    }
    //console.warn("fg",this.state.showResults);
    const requestData = {
      npc_id: user_detail.newspaper.id,
      name: name,
      publish_frequency: selectedFrequency,
      publish_day:JSON.stringify(selectedDay),
      month_frequency:selectedMonthFrequency?selectedMonthFrequency:""
    };
    const requestDataMultiDay = {
      npc_id: user_detail.newspaper.id,
      name: name,
      publish_frequency: selectedFrequency,
      publish_day: data.toString(),
      month_frequency:selectedMonthFrequency?selectedMonthFrequency:""
    };
    this.setState({
        isSubmitted: false,
        showResults:false, 
        showMonth:false,
        monthFrequency:false,
        showMultiDates: false,
        showMultiMonths: false,
        showMonthWeek: false,
        biWeekly:false,
        daily:false
    });
   
    if(flag){
      // console.warn("Flag", flag);
      if(JSON.parse(requestData.publish_day).length == 0 && 
        requestData.month_frequency != "Firstday_of_Month"){
        toastr.error(MESSAGES.EMPTY_ERROR);
        console.warn(this.state.optionsTwo)
        for(let i = 0; i < this.state.optionsTwo.length; i ++){
          // console.warn(this.state.optionsTwo)
          this.state.selectedValue.push(this.state.optionsTwo[i]);
        }
        this.resetFields(values);
        return;
      }else{
        // console.warn("C",flag?requestData:requestDataMultiDay);
        this.props.addEdition(langs.keyname.edition, flag?requestData:requestDataMultiDay, (res) => {
          //console.warn('!!!', res);
          if (res.status === STATUS_CODES.CREATED) {
            this.setState({
              selectedDayMulti: "",
              selectedDay: ""
            });
            console.warn(this.state.optionsTwo)
            for(let i = 0; i < this.state.optionsTwo.length; i ++){
              // console.warn(this.state.optionsTwo)
              this.state.selectedValue.push(this.state.optionsTwo[i]);
            }
            this.getAllEdition();
            toastr.success(MESSAGES.ADD_EDITION_SUCCESS);
            this.resetFields(values);
            console.warn(this.state)
          } else {
            this.setState({ isSubmitted: true });
          }
        });
      }
    }else{
      // console.warn("Flag", flag);
      if((requestDataMultiDay.publish_day).length == 0){
        toastr.error(MESSAGES.EMPTY_ERROR);
        console.warn(this.state.optionsTwo)
        for(let i = 0; i < this.state.optionsTwo.length; i ++){
          // console.warn(this.state.optionsTwo)
          this.state.selectedValue.push(this.state.optionsTwo[i]);
        }
        // this.setState({selectedValue:this.state.optionsTwo});
        this.resetFields(values);
        return;
      }else{
        // console.warn("D",flag?requestData:requestDataMultiDay);
        this.props.addEdition(langs.keyname.edition, flag?requestData:requestDataMultiDay, (res) => {
          //console.warn('!!!', res);
          if (res.status === STATUS_CODES.CREATED) {
            this.setState({
              selectedDayMulti: "",
              selectedDay: ""
            });
            console.warn(this.state.selectedValue);
            if(this.state.selectedValue.length == 0){
              for(let i = 0; i < this.state.optionsTwo.length; i ++){
                // console.warn(this.state.optionsTwo)
                this.state.selectedValue.push(this.state.optionsTwo[i]);
              };
            }else{
              this.state.selectedValue.splice(0,this.state.selectedValue.length)
              // for(let i = 0; i<this.state.selectedValue.length; i ++){
              //   this.state.selectedValue.pop();
              //   console.warn(this.state.selectedValue[i]);
              // }
              console.warn(this.state.selectedValue);
              for(let i = 0; i < this.state.optionsTwo.length; i ++){
                // console.warn(this.state.optionsTwo)
                this.state.selectedValue.push(this.state.optionsTwo[i]);
              };

            }
            
            this.getAllEdition();
            toastr.success(MESSAGES.ADD_EDITION_SUCCESS);
            this.resetFields(values);
            //console.warn(this.state)
          } else {
            this.setState({ isSubmitted: true });
          }
        });
      }
    }
    // this.props.addEdition(langs.keyname.edition, flag?requestData:requestDataMultiDay, (res) => {
    //   //console.warn('!!!', res);
    //   if (res.status === STATUS_CODES.CREATED) {
    //     this.getAllEdition();
    //     toastr.success(MESSAGES.ADD_EDITION_SUCCESS);
    //     this.resetFields(values);
    //     console.log(this.state)
    //   } else {
    //     this.setState({ isSubmitted: true });
    //   }
    // });
    
  };

  onRemove1 = e => {
    // console.warn("Selected", this.state.selectedValue);
    // console.warn("options", this.state.options);
    while(this.state.selectedValue.length > 0) {
      this.state.selectedValue.pop();
    }
    // console.warn("Selected option after pop", this.state.options);
    // console.warn("Selected option 2", this.state.optionsTwo);
    // console.warn("Selected after pop", this.state.selectedValue);
    // console.warn("E:",e);
    for(let i=0; i< e.length; i ++){
      this.state.selectedValue.push(e[i])
    }
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { editionList, printFrequencyList, handleSubmit } = this.props;
    const {
      showAgentModal,
      agent_names,
      publishDayArray,
      isSubmitted,
      name,
      selectedFrequency,
      selectedDay,
      selectedMonthFrequency,
      selectedDayMulti,
      selectedDayMultiMonth,
      showConfirmModal,
      showResults,
      showMonth,
      showMultiDates,
      monthFrequency,
      showMultiMonths,
      biWeekly,
      daily,
      showMonthWeek
    } = this.state;
    return (
      <div className="row my-5">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>Edition</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="cardWrapper">
                {this.renderEditionCardItem(editionList)}
              </div>
              <div
                className="my-3"
                style={{
                  borderBottom: "1px solid #ccc",
                }}
              >
                <form
                  noValidate
                  className="form"
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Row>
                    <Col md="3">
                      <Field
                        name="name"
                        value={name}
                        label="Edition Name"
                        validate={[required, gminLength, maxLength100]}
                        required={true}
                        placeholder="Enter Edition Name"
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value.trim(),
                            isSubmitted: true,
                          });
                        }}
                        component={renderText}
                      />
                    </Col>
                    <Col md="3">
                      <Field
                        name="print_frequency"
                        value={selectedFrequency}
                        label="Print Frequency"
                        validate={[selectRequired]}
                        optionValue={"id"}
                        optionLabel={"print_frequency"}
                        isSelect={false}
                        
                        options={printFrequencyList}
                        onChange={(e) => { this.changeOption(e)
                          
                        }}
                        component={renderSelectField}
                        placeholder="Enter Distribution State"
                      />
                    </Col>
                    {showMonth && (
                    <Col md="3">
                      <Field
                        name="print_month_frequency"
                        value={selectedMonthFrequency}
                        label="Month Frequency"
                        validate={[selectRequired]}
                        optionValue={"id"}
                        optionLabel={"name"}
                        isSelect={false}
                        options={this.state.monthOption}
                        onChange={(e) => { this.changeMonthOption(e)
                        }}
                        component={renderSelectField}
                        placeholder="Enter Distribution State"
                      />
                    </Col>
                    )}
                  
                  {/* -----------------Show Calendar based on Annual Selection---------------- */}
                    {showResults && (
                    <Col md="3">
                      <DayPicker
                        selectedDays={this.state.selectedDay}
                        onDayClick={this.handleDayClick}
                      />
                       
                       {this.state.selectedDay
            ? this.state.selectedDay.toLocaleDateString()
            : 'Please select a day 👻'}
                      
                    </Col>
                    )}

                    {/* -----------------Show Calendar based on Annual Selection End---------------- */}
                    
                  {/* Quarterly Selection */}
                  {showMultiDates && (
                    <Col md="3">
                      <DayPicker
                      selectedDays={this.state.selectedDayMulti}
                      onDayClick={this.handleDayClickMultiple}
                    />
                    </Col>
                  )}
                  {/* Quarterly selection end */}

                  {/* ----------------Show calendar based on Bi-Monthly--------------------- */}
                  {showMultiMonths && (
                    <Col md="3">
                    {/* <label>Multiple Publish day</label>
                    <Multiselect 
                    label="Mutiple Publish day"
                    name="publish_day"
                    options={this.state.optionsDay}
                    showCheckbox={true} // Options to display in the dropdown
                    selectionLimit = "2"
                    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  
                  onSelect={(selectedValues)=>{
                    this.setState({
                      selectedDayMulti: selectedValues,
                    });
                  }} // Function will trigger on select event
                  
                  onRemove={this.onRemove}
                
                  displayValue="name" // Property name to display in the dropdown options
                  />
                  <p>{this.state.selectedValue}</p> */}
                    <DayPicker
                      month={new Date(parseInt(new Date().toDateString().split(" ")[3]), (parseInt(new Date().toLocaleDateString().split("/")[0]))-1)}
                      fromMonth={new Date(parseInt(new Date().toDateString().split(" ")[3]), (parseInt(new Date().toLocaleDateString().split("/")[0]))-1)}
                      toMonth = {new Date(parseInt(new Date().toDateString().split(" ")[3]), 11)}
                      selectedDays={this.state.selectedDayMulti}
                      onDayClick={this.handleDayClickMultiMonth}
                    />

                    </Col>
                  )}

                  {/*---------------  Show calendar based on Bi-Monthly End ----------------- */}


                  {/* ----------------Show calendar based on Monthly Week--------------------- */}
                  {showMonthWeek && (
                    <Col md="3">
                    {/* <label>Multiple Publish day</label>
                    <Multiselect 
                    label="Mutiple Publish day"
                    name="publish_day"
                    options={this.state.options}
                    showCheckbox={true} // Options to display in the dropdown
                    selectionLimit = "1"
                    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  
                  onSelect={(selectedValues)=>{
                    this.setState({
                      selectedDayMulti: selectedValues,
                    });
                  }} // Function will trigger on select event
                  
                  onRemove={this.onRemove}
                
                  displayValue="name" // Property name to display in the dropdown options
                  /> */}
                      <DayPicker
                        selectedDays={this.state.selectedDayMulti}
                        onDayClick={this.handleDayClickMultiMonth}
                      />
                    </Col>
                  )}

                  {/*---------------  Show calendar based on Monthly Week End ----------------- */}


                {/* ----------------- Daily Calendar View ------------------- */}

                {monthFrequency  && (
                    <Col md="3">
                    <label>Publish Day</label>
                    <Multiselect 
                    label="Mutiple Publish day"
                    name="publish_day"
                    options={this.state.options}
                    selectionLimit="1"
                    
                    showCheckbox={true} // Options to display in the dropdown
                    // selectedValues={this.state.options} // Preselected value to persist in dropdown
                    // disablePreSelectedValues={true}
                    

                  
                  onSelect={(selectedValues)=>{
                    this.setState({
                      selectedDayMulti: selectedValues,
                    });
                  }} // Function will trigger on select event
                  
                  onRemove={this.onRemove}
                
                  displayValue="name" // Property name to display in the dropdown options
                  />
                </Col> )}

               

                {/* ----------------- Daily Calendar View End ------------------- */}


                {daily  && (
                    <Col md="3">
                    <label>Multiple Publish day</label>
                    <Multiselect 
                    label="Mutiple Publish day"
                    name="publish_day"
                    options={this.state.optionsTwo}
                    showCheckbox={true} // Options to display in the dropdown
                    selectionLimit = "7"
                    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  
                  onSelect={(selectedValues)=>{
                    this.setState({
                      selectedDayMulti: selectedValues,
                    });
                  }} // Function will trigger on select event
                  
                  onRemove={this.onRemove1}
                
                  displayValue="name" // Property name to display in the dropdown options
                  />
                  {/* <p>{this.state.selectedValue}</p> */}
                </Col> )}

                  {/* -------------------- Bi-Weekly Dropdown ------------------- */}
                  {biWeekly  && (
                    <Col md="3">
                    <label>Multiple Publish day</label>
                    <Multiselect 
                    label="Mutiple Publish day"
                    name="publish_day"
                    options={this.state.options}
                    showCheckbox={true} // Options to display in the dropdown
                    selectionLimit = "2"
                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  
                  onSelect={(selectedValues)=>{
                    this.setState({
                      selectedDayMulti: selectedValues,
                    });
                  }} // Function will trigger on select event
                  
                  onRemove={this.onRemove}
                
                  displayValue="name" // Property name to display in the dropdown options
                  />
                  {/* <p>{this.state.selectedValue}</p> */}
                </Col> )}

                {/* ----------------------- Bi-Weekly Dropdown ------------------------ */}

                    <Col md="3 my-3 my-md-auto">
                      <Button
                        className="float-right float-md-left"
                        style={{
                          background: "black",
                          border: "none",
                          width: 100,
                        }}
                        size="md"
                        type="submit"
                        disabled={isSubmitted ? false : true}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </PortletBody>
          </Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            keyname={"edition"}
          />

          <AgentModal
            isOpen={showAgentModal}
            message = {agent_names}
            onCancelClick={this.cancelAgent}
            // onOkClick={this.confirmDelete}
            // keyname={"edition"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, npcDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    printFrequencyList: Array.isArray(npcDuck.npcSettingList.print_frequency)
      ? npcDuck.npcSettingList.print_frequency
      : [],
    editionList: Array.isArray(npcDuck.npcSettingList.edition)
      ? npcDuck.npcSettingList.edition
      : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    npcDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "Edition",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(Edition)
  )
);
