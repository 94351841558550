import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import { MESSAGES } from '../../../../config/message';
import '../ad-management/AdManagement.scss';
import ConformModel from '../ad-management/ConfirmModel';
import { Form, Col, Row } from 'react-bootstrap';
import { langs } from '../../../../config/localization';
import { renderText } from '../../../../common/layout/FormInput';
import { required, gminLength, maxLengthC100 } from '../../../../config/validation';
import { toastr } from 'react-redux-toastr';
import { checkPermission } from '../../../../common/CheckPermissions';
import { STATUS_CODES } from '../../../../common/StatusCode'

class Language extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: '',
      adTypeFlag: false,
      colorFlag: false,
      language: '',
      key: ''
    };
  }

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getAllLanguageList(langs.keyname.language, "", res => { })
  }

  /** 
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
  * @method confirmDelete
  * @description confirm delete bought out part
  */
  confirmDelete = () => {
    const deletedItem = this.state.deletedItem;
    const { user_detail } = this.props.loggedInUser;
    let reqData = {
      id: deletedItem.id,
      user_id: user_detail.id,
      advertisement_id: deletedItem.id,
      name: encodeURIComponent(deletedItem.name)
    }
    this.props.commonDeleteAPI(langs.keyname.language, reqData, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        this.props.getAllLanguageList(langs.keyname.language, "", res => { })
        if (res.status === STATUS_CODES.NO_CONTENT) {
          toastr.success(MESSAGES.DELETE_LANGUAGE_SUCCESS)
          this.setState({
            showConfirmModal: false
          });
        }
      }
    })
  }

  /**
  * @method addLanguage
  * @description called api to add language
  */
  addLanguage = (e) => {
    const { language } = this.state;
    const match = language !== '' && language.trim() !== '' && language.length >=2 && language.length <=100;
    if (match) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          name: language,
        }
        this.props.addLanguageAPI(langs.keyname.language, requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
            toastr.success(MESSAGES.ADD_LANGUAGE_SUCCESS)
            this.setState({language: ''})
            document.activeElement.blur();
            this.props.getAllLanguageList(langs.keyname.language, "", res => { })
            this.props.dispatch(change('Language', 'language', ''));
            this.props.dispatch(untouch('Language', 'language'));
          }
        })
      }
    }
  }

  /**
   * @method renderCardItem
   * @description render the color card item
   */
  renderCardItem = (typeOfListing, per) => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        return (
          <Card key={i} bg='light' style={{ width: 'auto', height: '3.5rem', marginRight: 10, marginBottom: 10 }} >
            <Card.Body>
              <Card.Title><label>{data.name}</label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                { per && <i className='fa fa-close crossIcon' onClick={() =>
                  this.setState({ showConfirmModal: true, deletedItem: data, message: MESSAGES.LANGUAGE, key: 'language', count: data.newspaperCount })
                }></i>}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };


  /**
   * @method render
   * @description render the component
   */
  render() {
    const { languageList, handleSubmit } = this.props;
    const { showInput, language, showConfirmModal, message, key, count } = this.state;
    const viewPermission = checkPermission(langs.permissionKeys.language, langs.permissionKeys.view)
    const createPermission = checkPermission(langs.permissionKeys.language, langs.permissionKeys.create)
    const deletePermission = checkPermission(langs.permissionKeys.language, langs.permissionKeys.delete)
    if(!viewPermission && !createPermission && !deletePermission){
      return <div></div>
    }
    return (
      <div className="row">
        <div className='col-md-12'>
          <Portlet fluidHeight={true}>
            <PortletBody >
             { (viewPermission || createPermission) &&  <Form.Row>
                <Form.Group as={Col} md='2' controlId='validationCustom01'>
                  <h5
                    style={{ color: '#5d5757', marginBottom: '100' }}
                  >
                    Language
                </h5>
                </Form.Group>
              </Form.Row>}
              <div className='cardWrapper'>
                {viewPermission ? this.renderCardItem(languageList, deletePermission):  <div className='text-center' style={{ marginTop: '10px' }}>
                        <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                    </div>}
              </div>
              <div
                className='row'
                style={{
                  borderBottom: '1px solid #ccc', marginTop: 25,
                  marginLeft: 2
                }}
              >
                { createPermission && <label style={{ color: 'black', marginTop: 25, cursor: 'pointer' }} onClick={() => this.setState({ showInput: !showInput })} data-toggle='collapse'><i className='fa fa-plus' aria-hidden='true'></i>&nbsp;&nbsp;Add More
                  </label>}
              </div>
              {showInput &&
                <form
                  noValidate
                  className='form'
                  onSubmit={handleSubmit(this.addLanguage.bind(this))}
                >
                  <Row>
                    <Col md='6'>
                      <Field
                        name='language'
                        validate={[required, gminLength, maxLengthC100]}
                        value={language}
                        placeholder='Enter Language'
                        component={renderText}
                        onChange={e => this.setState({ language: e.target.value.trim() })}
                        onKeyDown={this.addLanguage}
                      />
                    </Col>
                  </Row>
                </form>
              }
            </PortletBody>
          </Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            message={message}
            key={key}
            totalCount={count}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, settingsDuck, authToken } = store;
  const { superAdminSettingList } = settingsDuck;

  return ({
    loggedInUser: auth.user,
    authToken: authToken,
    languageList: Array.isArray(superAdminSettingList.language) ? superAdminSettingList.language : [],
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    settingsDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'Language'
    })(Language)
  )
);
