import React from "react";
import { DonutChart } from "@carbon/charts-react";
import "./common.scss";
import "./chart.scss";

class BlankDonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const colors = ["#5d5757", "#e0e0da"];
    let temp = [50, 30];
    const simpleBarData = {
      labels: ["", ""],
      datasets: [
        {
          label: "Dataset 1",
          backgroundColors: colors,
          data: temp.length && temp[0] !== "" && temp[1] !== "" ? temp : [],
        },
      ],
    };
    const demoDonutOptions = {
      accessibility: false,
      //legendClickable: true,
      containerResizable: true,
      colors,
      center: {
        number: "0",
        label: "Orders",
      },
      // legend: {
      //   position: "right",
      // },
      resizable: true,
    };
    const isvisible =
      simpleBarData.datasets[0].data &&
      simpleBarData.datasets[0].data.length > 1;
    return (
      <div className="row">
        <div className="col-md-12">
          {isvisible && (
            <DonutChart data={simpleBarData} options={demoDonutOptions} />
          )}
        </div>
      </div>
    );
  }
}

export default BlankDonutChart;
