import React, { Fragment, useEffect, useState } from "react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import DataTable from "./DataTable";
import { RiPencilFill } from "react-icons/ri";
import { connect } from "react-redux";
import { MdDelete } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../../store/ducks/npc/SeasonalIssues.duck";
import moment from "moment";
import { MESSAGES } from "../../../../config/message";
import ConformModel from "../../super-admin/ad-management/ConfirmModel";
import * as SeasonalIssuesDuck from "../../../../store/ducks/npc/SeasonalIssues.duck";

const Seasonalissue = (props) => {
  const disPatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [deletedItem, setDeletedItem] = useState("");
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    getAllSeasonalIssueList();
  }, []);
  const getAllSeasonalIssueList = () => {
    const requestData = {
      isInactive: false,
    };
    const result = actions.getSeasonalIssuesByPublisher(requestData, (res) => {
      if (res?.status == 200) {
        setData(res?.data?.allResult || []);
      } else {
        setData([]);
      }
    });
    result(disPatch);
  };
  const deleteSeasonalIssue = (id) => {
    setDeletedItem(id);
    setShowConfirmModal(true);
    setMessage(MESSAGES.MESSAGE_DELETE_SEASONAL_ISSUE_DATE);
  };
  const cancelDelete = () => {
    setDeletedItem("");
    setShowConfirmModal(false);
  };
  const confirmDelete = () => {
    const requestData = {
      isInactive: false,
      id: deletedItem,
    };
    props.deleteSeasonalIssueOverrideEvent(requestData, (res) => {
      if (res) {
        toastr.success(MESSAGES.DELETE_SEASONAL_ISSUE);
        setShowConfirmModal(false);
        getAllSeasonalIssueList();
      }
    });
  };

  let columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original?.seasonal_issue?.calendar_name}</span>;
      },
    },
    {
      Header: "Override publish date",
      accessor: "override_publish_date",
      Cell: (row) => {
        const { original } = row;
        return (
          <span>
            {moment(original.override_publish_date).format("MM/DD/YYYY")}
          </span>
        );
      },
    },
    {
      Header: "New publish date",
      accessor: "new_publish_date",
      Cell: (row) => {
        const { original } = row;
        return (
          <span>{moment(original.new_publish_date).format("MM/DD/YYYY")}</span>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "action",
      Cell: (row) => {
        const { original } = row;
        return (
          <>
            {original.isActive ? (
              <div className="icon_flex_table d-flex align-items-center">
                <MdDelete
                  size="20"
                  className="mr-2 cursor-pointer"
                  onClick={() =>
                    deleteSeasonalIssue(original.overRideSeasonalId)
                  }
                />
                <RiPencilFill
                  size="20"
                  className="cursor-pointer"
                  onClick={() =>
                    history.push(
                      `/seasonalIssue/1?id=${original.overRideSeasonalId}`
                    )
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const disableRow = (state, rowInfo, column) => {
    console.log("rowInfo", rowInfo);
    if (rowInfo && !rowInfo?.original?.isActive) {
      return {
        style: { opacity: 0.4, },
        onClick: (e) => {
          e.preventDefault();
        },
      };
    } else return {};
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-12 kt-form kt-form--label-right">
          <Portlet>
            <PortletBody>
              <div className="admin_issue_table">
                <DataTable
                  data={data}
                  columns={columns}
                  extraProps={{ getTrProps: disableRow }}
                />
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </div>
      <ConformModel
        isOpen={showConfirmModal}
        onCancelClick={cancelDelete}
        onOkClick={confirmDelete}
        message={message}
        totalCount={""}
      />
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  const { seasonalIssue } = store.SeasonalIssuesDuck;
  return {
    seasonalIssue: seasonalIssue,
  };
};
export default connect(
  mapStateToProps,
  SeasonalIssuesDuck.actions
)(Seasonalissue);
