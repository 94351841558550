import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { Button } from "react-bootstrap";
import { AiOutlineFilePdf } from "react-icons/ai";
import { HiOutlineDownload } from "react-icons/hi";
import { BiLoaderAlt } from "react-icons/bi";
import "./Subscription.scss";
import * as SubscriptionDuck from "../../../../store/ducks/super-admin/Subscription.duck";

class SubscriptionInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloding: [],
    };
  }

  downloadInvoice = async (id, key) => {
    if (
      this.props.isDownloadingInvoice &&
      this.props.isDownloadingInvoice.length > 0
    ) {
      for (let i = 0; i < this.props.isDownloadingInvoice.length; i++)
        this.props.isDownloadingInvoice[i] = false;
      let dummy_arr = this.props.isDownloadingInvoice;
      dummy_arr[key] = true;
      this.setState({ isDownloding: dummy_arr });
      this.props.subscriptionInvoiceDownload(id, async (res) => {
        const result = await this.downloadPdf(res);
        this.setState({ isDownloding: [] });
      });
    }
  };

  downloadPdf = (data) => {
    const pdfBlob = data.pdfHtml[0].data;
    let result = [];
    for (let i in pdfBlob) {
      let d = pdfBlob[i];
      result.push(d);
    }
    let buffer = new Uint8Array(result);
    let file = new Blob([buffer], { type: "application/pdf" });
    let fileURL = URL.createObjectURL(file);
    let a = document.createElement("a");
    a.href = fileURL;
    a.download = data.fileName;
    a.click();
  };

  render() {
    return (
      <Fragment>
        <Modal
          {...this.props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="subscriptionInvoice_Modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoices
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="invoices_row">
              {this.props.data &&
                this.props.data.length > 0 &&
                this.props.data.map((item, key) => {
                  return (
                    <div
                      className="invoices_col"
                      key={key}
                      onClick={() => this.downloadInvoice(item.id, key)}
                    >
                      <div className="invoices_name">
                        <AiOutlineFilePdf /> <span>{item.name}.pdf</span>
                      </div>
                      <div className="invoices_date">
                        <span>
                          Invoice date{" "}
                          {moment(item.createdAt).format("MMM DD, YYYY")}
                        </span>
                      </div>
                      {this.state.isDownloding[key] ? (
                        <div className="Invoice_pdf_loader">
                          <BiLoaderAlt size={24} color="black" />
                        </div>
                      ) : (
                        <button>
                          <HiOutlineDownload />
                        </button>
                      )}
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-dark" onClick={this.props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { allActiveSubscriptions } = store.subscriptionDuck;

  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, SubscriptionDuck.actions)(SubscriptionInvoice)
);
