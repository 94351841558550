import React from 'react';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import StandardTable from '../../../StandardTable';
import * as commonDuck from '../../../../../store/ducks/Common.duck';
import { convertISOToUtcDateformate4 } from '../../../../../common'

class TableView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            financeDetail: undefined,
            openModal: false
        }
    }

    /**
   * @method renderRecords
   * @description render all order list
   */
    renderRecords = () => {
        const columnOrder = [
            {
                Header: 'Order ID',
                accessor: 'id', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return original.id
                })
            },
            {
                Header: 'Date',
                accessor: 'order_date', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return convertISOToUtcDateformate4(original.order_date)
                })
            },
            {
                Header: 'Ad Plan',
                Cell: ((row) => {
                    const { original } = row;
                    const { newspaper_ads_plan } = original;
                    let adPlan = ''
                    if (!newspaper_ads_plan) return ''
                    if (Array.isArray(newspaper_ads_plan.advertisements) && newspaper_ads_plan.advertisements.length) {
                        adPlan = `${newspaper_ads_plan.advertisements[0].ad_type} ${newspaper_ads_plan.height} X ${newspaper_ads_plan.width}`
                    }
                    return <div style={{ display: '-webkit-box' }}>
                        {adPlan}
                    </div>
                })
            },
            {
                Header: 'Customer Name',
                Cell: (row) => {
                    const { original } = row;
                    return <font className='text-truncate'>{(original && original.user.first_name ? original.user.first_name + ' ' + original.user.last_name : '')}</font>
                },
            },
            {
                Header: 'Price',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.payment_report.total_revenue}`
                },
            },

            {
                Header: 'Earning',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.payment_report.paid_amount}`
                },
            },
            {
                Header: 'Fee',
                Cell: (row) => {
                    const { original } = row;
                    return <b>{`$${original.payment_report.commission_fee}`}</b>
                },
            },
        ];
        return columnOrder
    }

    render() {
        const { orderData } = this.props
        return (
            <div>
                <div className='col-md-12'>
                    <div className='w-100 '>
                        <StandardTable showSearch={false} handleSort={false} columnOrder={this.renderRecords()} allData={this.state.orderList}
                            //display={'none'}
                            data={orderData} sortable={false} showPageSizeOptions={false}
                            showPagination={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    const { auth } = store;
    return ({
        loggedInUser: auth.user,
        loading,
    })
}


export default injectIntl(
    connect(
        mapStateToProps,
        commonDuck.actions,
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'TableView',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(TableView)
    )
);