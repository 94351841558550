import React from "react";
import { Button, Modal } from "react-bootstrap";

export default class NavigateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const {
            onClickCancel,
            onOkClick,
            alertMessage,
            alertHeading,
            cancleButtonText,
            okButtonText,
            show,
            showCancel,
            contentClassName = ''
        } = this.props
        return (
            <>
                <Modal
                    aria-labelledby="example-modal-sizes-title-sm"
                    show={show}
                    centered={true}
                    className={contentClassName}
                    dialogClassName="nav-prompt-modal"
                >
                    <Modal.Header><h5>{alertHeading}</h5></Modal.Header>
                    {alertMessage && <Modal.Body >{alertMessage}</Modal.Body>}
                    <Modal.Footer>
                        {showCancel && <Button variant="secondary" onClick={onClickCancel}>{cancleButtonText}</Button>}
                        <Button variant="primary" onClick={onOkClick}>{okButtonText}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
