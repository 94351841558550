import React from 'react';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import {
    renderText,
} from '../../../../common/layout/FormInput';
import CodeExample from '../../../../partials/content/CodeExample';
import * as superAdminDuck from '../../../../store/ducks/SuperAdmin.duck';
import { injectIntl } from 'react-intl';
import {
    required,
    PhoneNumber,
    maxLength10,
    minLength5,   
    maxLength100,
} from '../../../../config/validation';

class AddressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleChanepassword: false,
            isEditFlag: false,
            value: '',
            number: '',
            email: '',
            success: false,
            error: false,
            isSubmitted: false
        };
    }

    /**
    * @method render
    * @description render the component
    */
    render() {
        return (
            <div>               
                <div className='col-md-12'>
                    <CodeExample beforeCodeTitle='Billing Information'>
                        <Form
                            noValidate
                        >
                            <Form.Row>
                                <Form.Group as={Col} md='12' controlId='validationCustom01'>
                                    <Field
                                        name='bill_address'
                                        label='Address'
                                        validate={[required, minLength5, maxLength100]}
                                        required={true}
                                        placeholder='Enter Address'
                                        disabled={true}
                                        component={renderText}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md='6' controlId='validationCustom01'>
                                    <Field
                                        name='city'
                                        label='City'                             
                                        disabled={true}
                                        validate={[required, PhoneNumber, maxLength10]}
                                        required={true}
                                        placeholder='Enter city'
                                        component={renderText}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md='6' controlId='validationCustom02'>
                                    <Field
                                        name='state'
                                        label='State'
                                        validate={[required, minLength5, maxLength100]}
                                        required={true}
                                        placeholder='Enter state'
                                        component={renderText}
                                    disabled={true}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md='6' controlId='validationCustom01'>
                                    <Field
                                        name='zip_code'
                                        label='Zip Code'
                                        disabled={true}                                 
                                        validate={[required, minLength5, maxLength10]}
                                        required={true}
                                        placeholder='Enter Zip Code'
                                        component={renderText}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md='6' controlId='validationCustom02'>
                                    <Field
                                        name='country'
                                        label='Country'
                                        validate={[required, minLength5, maxLength100]}
                                        required={true}
                                        placeholder='Enter Country'
                                        component={renderText}
                                        disabled={true}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </CodeExample>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    const { selectedCompany } = store.superAdminDuck
    let initialValues = {};
    if (selectedCompany && selectedCompany !== undefined) {
        initialValues = {
            bill_address: selectedCompany.bill_address,
            city: selectedCompany.bill_city,
            state: selectedCompany.bill_state,
            zip_code: selectedCompany.bill_zip_code,
            country: selectedCompany.bill_country
        };
    }
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        selectedCompany: selectedCompany,
        initialValues
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        superAdminDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'AddressBar',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(AddressBar)
    )
);
