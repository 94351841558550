import React from "react";
import { connect } from "react-redux";
import { Button, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import {
  renderText,
  renderNumberInputField,
  renderSelectField,
  focusOnError,
} from "../../../../common/layout/FormInput";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import { injectIntl } from "react-intl";
import {
  required,
  maxLength100,
  minLength5,
  minLength2,
  maxLength5,
  selectRequired,
  normalizePhone,
} from "../../../../config/validation";
import { MESSAGES } from "../../../../config/message";
import { Redirect } from "react-router-dom";
import { Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Loader } from "../../../../common/Loader";
import "../../Modal.scss";
import { toastr } from "react-redux-toastr";
import { STATUS_CODES } from "../../../../common/StatusCode";

class PaymentMehode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isRedirect: false,
      states: [],
      percentArray: [
        { value: 0.1, label: "0.1" },
        { value: 0.2, label: "0.2" },
        { value: 0.3, label: "0.3" },
        { value: 0.4, label: "0.4" },
        { value: 0.5, label: "0.5" },
        { value: 0.6, label: "0.6" },
      ],
      paymentMode: [
        { value: "Wire", label: "Wire" },
        { value: "Cheque", label: "Cheque" },
      ],
      schedulePayment: [
        { label: "Select", value: -1 },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-Weekly", value: "bi-weekly" },
        { label: "Monthly", value: "monthly" },
      ],
      isSubmitted: true,
      loading: false,
    };
  }

  componentDidMount() {
    this.getCompanyDetail();
  }

  getCompanyDetail = () => {

    
    this.props.getCompanyDetailById(this.props.newspaperId, true, (res) => {
      if (res.data) {
        console.warn(">>Response from edit form admin>>",res.data);
        this.setState({
          compCatVal: res.data
        })

      }
    });
  }

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { newspaperId } = this.props;
    // console.warn("Props details >>",this.props);

    // console.warn(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    // console.warn("checking", this.state);
    values.id = newspaperId;
    values.active = true;
    values.name=this.state.compCatVal.user.first_name+" "+this.state.compCatVal.user.last_name;
    values.email=this.state.compCatVal.user.email;
    values.phone=this.state.compCatVal.user.phone_number;
    
    values.created_by = "superAdmin";
    this.setState({ isSubmitted: false });
    this.props.addPaymentCompany(values, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.PATMENT_SUCCESS);
        this.setState({ isSubmitted: false });
        setTimeout(() => {
          this.toggleModel();
        }, 5000);
      } else {
        this.setState({ isSubmitted: true });
      }
    });
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.onCancelPaymentModel();
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const { handleSubmit, loading } = this.props;
    const {
      isRedirect,
      paymentMode,
      schedulePayment,
      percentArray,
      isSubmitted,
    } = this.state;
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/adminManagement",
          }}
        />
      );
    }
    return (
      <Container className="top-margin">
        <Modal
          isOpen={this.props.isOpenpaymentModel}
          toggle={this.toggleModel}
          styles={{ height: 10 }}
          size="lg"
        >
          {loading && <Loader />}
          <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
            Payment Method{" "}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Container>
                <form
                  noValidate
                  className="form"
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Row>
                    {/* <Col md="12">
                      <Field
                        name="payment_mode"
                        label="Payment Mode"
                        options={paymentMode}
                        isSelect={false}
                        optionValue={"value"}
                        optionLabel={"label"}
                        component={renderSelectField}
                      />
                    </Col> */}
                    <Col md="6">
                      <Field
                        name="account_name"
                        label="Name Of Account"
                        validate={[required, minLength2, maxLength100]}
                        required={true}
                        placeholder="Enter Name Of Account"
                        component={renderText}
                      />
                    </Col>
                    <Col md="6">
                      <Field
                        name="routing_number"
                        label="Routing Number"
                        validate={[required, minLength2, maxLength100]}
                        required={true}
                        placeholder="Enter Routing Number"
                        component={renderNumberInputField}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Field
                        name="account_number"
                        label="Account Number"
                        validate={[required, maxLength100]}
                        required={true}
                        placeholder="Enter Your Account Number"
                        component={renderNumberInputField}
                      />
                    </Col>
                    <Col md="6">
                      <Field
                        name="bank_name"
                        label="Bank Name"
                        validate={[required, maxLength100]}
                        required={true}
                        placeholder="Enter Your Bank Name"
                        component={renderText}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Field
                        name="bank_address"
                        value={"adminProfile.first_name"}
                        label="Bank Address"
                        validate={[required, minLength2, maxLength100]}
                        required={true}
                        placeholder="Enter Your Bank Address"
                        component={renderText}
                      />
                    </Col>
                    <Col md="6">
                      <Field
                        name="bank_zip_code"
                        value={"adminProfile.first_name"}
                        label="Zip Code"
                        validate={[required, minLength5, maxLength5]}
                        maxLength={5}
                        required={true}
                        placeholder="Enter Your Zip Code"
                        component={renderText}
                        normalize={normalizePhone}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md="6">
                      <Field
                        name="payment_schedule"
                        label="Schedule Payment"
                        validate={[required, selectRequired]}
                        optionValue={"value"}
                        optionLabel={"label"}
                        required={true}
                        options={schedulePayment}
                        component={renderSelectField}
                      />
                    </Col> */}
                    {/* <Col md="6">
                      <Field
                        name="commission_percent"
                        options={percentArray}
                        validate={[selectRequired]}
                        label={"Set Percent to deduct from Earning"}
                        required={true}
                        isSelect={false}
                        optionValue={"value"}
                        optionLabel={"label"}
                        component={renderSelectField}
                      />
                    </Col> */}
                  </Row>
                  <Row>
                    <div className="col-12 mt-3 d-flex-center j-end">
                      <Button
                        style={{
                          marginRight: 10,
                          width: 140,
                          backgroundColor: "black",
                        }}
                        onClick={this.props.onCancelPaymentModel}
                        type={"button"}
                        className="btn btn-secondary"
                      >
                        {"Cancel"}
                      </Button>
                      <Button
                        style={{                          
                          width: 140,
                          backgroundColor: "black",
                        }}
                        className="btn btn-secondary"
                        onClick={() => {}}
                        type="submit"
                        disabled={isSubmitted ? false : true}
                      >
                        save
                      </Button>
                    </div>
                  </Row>
                </form>
              </Container>
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const selectedCompany =
    store.newsPaperCompanyDuck && store.newsPaperCompanyDuck.selectedCompany;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    selectedCompany: selectedCompany,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    NewsPaperCompanyDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "PaymentMethode",
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(PaymentMehode)
  )
);
