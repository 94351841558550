import React from "react";
import { Media } from "reactstrap";
import { displayDateTimeFormate } from "../common";

export default ({ data, imgSrc = "" }) => {
  return (
    <Media className="media-main w-100 px-3">
      <Media left top>
        <Media
          style={{
            height: 30,
            width: 30,
          }}
          object
          src={imgSrc}
          className="rounded-circle mr-4 mt-1"
        />
      </Media>
      {/* <Media body>
        <label style={{ color: "black" }}>{data.message}</label>
        <div>
          <span className="text float-right" style={{ color: "grey" }}>
            {displayDateTimeFormate(data.createdAt)}
          </span>
        </div>
      </Media> */}

      <Media body>
        {data.action === true ? (
          <>
            <label
              style={{
                color: "black",
                cursor: "pointer",
              }}
              onClick={() =>
                this.onClickNotificationTile(data.sender_id, data.type)
              }
            >
              {data.message}
            </label>
            <div>
              <span className="text float-right" style={{ color: "grey" }}>
                {displayDateTimeFormate(data.createdAt)}
              </span>
            </div>
          </>
        ) : (
          <>
            <label style={{ color: "black" }}>{data.message}</label>
            <div>
              <span className="text float-right" style={{ color: "grey" }}>
                {displayDateTimeFormate(data.createdAt)}
              </span>
            </div>
          </>
        )}
      </Media>
    </Media>
  );
};
