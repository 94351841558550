import React from "react";
import { connect } from "react-redux";
import { renderText } from "../../../../common/layout/FormInput";
import { Button } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Form, Col } from "react-bootstrap";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as settingsDuck from "../../../../store/ducks/super-admin/Settings.duck";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import EditBankInformation from "./EditBankInformation";
import { superAdminId } from "../../../../config";
import { checkPermission } from "../../../../common/CheckPermissions";
import { langs } from "../../../../config/localization";

class ViewBankInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      flag: true,
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getCompanyDetailById(superAdminId, (res) => {});
  }
  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { isUpdateModel, selectedItem } = this.state;
    const viewPermission = checkPermission(
      langs.permissionKeys.bankInformation,
      langs.permissionKeys.view
    );
    const createPermission = checkPermission(
      langs.permissionKeys.bankInformation,
      langs.permissionKeys.create
    );
    if (!viewPermission && !createPermission) {
      return <div></div>;
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-">
            <Portlet fluidHeight={true}>
              <PortletBody>
                <div className="d-flex-center justify-content-between mb-3">
                  {(viewPermission || createPermission) && (
                    <h5 style={{ color: "#5d5757" }}>Bank Information</h5>
                  )}
                  {createPermission && (
                    <div>
                      <Button
                        style={{
                          background: "black",
                          border: "none",
                          width: 100,
                        }}
                        size="md"
                        className="float-right"
                        onClick={() => this.setState({ isUpdateModel: true })}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>
                {viewPermission && (
                  <Form.Group>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Field
                          name="account_name"
                          label="Name on Account"
                          placeholder="Enter Name on Account"
                          component={renderText}
                          disabled={true}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Field
                          name="routing_number"
                          label="Routing Number"
                          placeholder="Enter Routing Number"
                          component={renderText}
                          disabled={true}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Field
                          name="account_number"
                          label="Account Number"
                          placeholder="Enter Account Number"
                          component={renderText}
                          disabled={true}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Field
                          name="bank_name"
                          label="Bank Name"
                          placeholder="Enter Bank Name"
                          component={renderText}
                          disabled={true}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Field
                            name="bank_address"
                            label="Bank Address"
                            placeholder="Enter Bank Address"
                            component={renderText}
                            disabled={true}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Field
                            name="bank_zip_code"
                            label="Zip Code"
                            placeholder="Enter Zip Code"
                            component={renderText}
                            disabled={true}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Form.Group>
                  </Form.Group>
                )}
              </PortletBody>
            </Portlet>
          </div>
        </div>
        {isUpdateModel && (
          <EditBankInformation
            isOpen={isUpdateModel}
            onCancel={() => this.setState({ isUpdateModel: false })}
            adTypeId={selectedItem}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, authToken, commonDuck } = store;
  const { selectedCompany } = commonDuck;
  let initialValues = {};
  if (selectedCompany && selectedCompany !== undefined) {
    initialValues = {
      account_name: selectedCompany.account_name,
      routing_number: selectedCompany.routing_number,
      account_number: selectedCompany.account_number,
      bank_name: selectedCompany.bank_name,
      bank_address: selectedCompany.bank_address,
      bank_zip_code: selectedCompany.bank_zip_code,
    };
  }
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    initialValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...settingsDuck.actions, ...commonDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "ViewBankInformation",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(ViewBankInformation)
  )
);
