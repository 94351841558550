import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

export default function DataTable({ data, columns }) {
  return (
    <>
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={25}
        showPageSizeOptions={false}
        sortable={true}
        disableResizing={false}
        noDataText={"No Records Found"}
        resizable={false}
      />
    </>
  );
}
