import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form';
import * as adManagementDuck from '../../../../../store/ducks/super-admin/AdManagement.duck';
import { injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CKEditor from 'ckeditor4-react';
import { MESSAGES } from '../../../../../config/message';
import { required } from '../../../../../config/validation';
import { renderSelectField } from '../../../../../common/layout/FormInput';
import { toastr } from 'react-redux-toastr';
import { Alert } from 'react-bootstrap';
import { STATUS_CODES } from '../../../../../common/StatusCode'

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditorModal: false,
            isSubmitted: true,
            isUpdateModel: false,
            message: '',
            adTypeFlag: false,
            colorFlag: false,
            show: false,
            success: false,
            data: '',
            selectedCategoryId: undefined
        };
        this.handleChange = this.handleChange.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
    }

    /**
    * @name createTemplate
    * @desc Calling actions & API to Craete Template Corresponding to Category
    */
    createTemplate = () => {
        const { data, selectedCategoryId } = this.state;
        let reqData = {
            temp_body: data,
            ad_type_id: selectedCategoryId
        }
        if (data === '') {
            this.setState({ show: true })
            setTimeout(() => {
                this.setState({ show: false })
            }, 5000)
        }
        else if (selectedCategoryId !== '') {
            this.setState({isSubmitted: false})
            this.props.createTemplate(reqData, res => {
                if (res.status === STATUS_CODES.CREATED) {
                    this.setState({isSubmitted: false})
                    toastr.success(MESSAGES.CREATE_TEMPLATE_SUCCESS)
                    this.props.dispatch(change('ManageCategory', 'category', ''));
                    this.props.dispatch(untouch('ManageCategory', 'category'));
                    this.setState({ data: '' })
                    this.props.onCancelClick()
                    this.props.getSelectedCategory(res.data.result.ad_type_id)
                    this.getAllTemplatesOfCategory(res.data.result.ad_type_id)
                }else{
                    this.setState({isSubmitted: true})
                }
            })
        }
    }

    /** 
    * @method getAllTemplatesOfCategory
    * @description  getAllTemplatesOfCategory called API to get all Templates of Category
    */
    getAllTemplatesOfCategory = (categoryId) => {
        let reqData = { catId: categoryId }
        this.props.getTemplatesOfCategory(reqData, (res) => {
        })
    }

    /** 
      * @method onEditorChange
      * @description  Called on OnChange Event of Editor 
      */
    onEditorChange(evt) {
        this.setState({
            data: evt.editor.getData()
        });
    }

    /**
    * Submit the login form
    * @param values
    * @desc Calling actions && redirecting page based on response 
    */
    onSubmit(values) {
        this.createTemplate()
    }

    /** 
     * @method handleChange
     * @description  Called on OnChange Event of field 
     */
    handleChange(changeEvent) {
        this.setState({
            data: changeEvent.target.value
        });
    }

    /** 
      * @method handleCloseEditor
      * @description  Called to close Editor byt state change 
      */
    handleCloseEditor = () => {
        this.props.onCancelClick()
    }

    /**
      * @method render
      * @description render the component
      */
    render() {
        const { handleSubmit, isOpen, categoryList } = this.props;
        const { show, data, isSubmitted } = this.state;
        let config = {
            toolbar: [
                { name: 'save', items: ['savebtn', 'Undo', 'Redo'] },
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'] },
                { name: 'document', items: ['Find', 'Replace'] },
                { name: 'lists', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent'] },
                // { name: 'insert', items: ['Image', 'Table', 'Smiley', 'SpecialChar'] },
                { name: 'link', items: ['Link', 'Unlink'] },
                { name: 'basicstyles', items: ['Font', 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
                { name: 'align', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] }
            ]
        }
        config.extraPlugins = 'font,justify,showblocks';

        return (
            <div className='row'>
                <Modal
                    isOpen={isOpen}
                    toggle={this.handleCloseEditor}
                    styles={{ height: 500, width: 900 }}
                    className='editor-popup'
                    size="lg"
                >
                    {show && (
                        <Alert
                            variant='danger'
                            onClose={() => this.setState({ show: false })}
                            dismissible
                        >
                            Template body should not empty.
                        </Alert>
                    )}
                    <ModalHeader className='mdl-filter-text' toggle={this.handleCloseEditor}>
                        <h1 style={{ color: 'black' }}>Add Template</h1>
                    </ModalHeader>
                    <ModalBody>
                        <form
                            noValidate
                            className='form'
                            onSubmit={handleSubmit(this.onSubmit.bind(this))}

                        >
                            <Field
                                name='category'
                                label='Category'
                                validate={[required]}
                                isSelect={false}
                                required={true}
                                options={categoryList}
                                component={renderSelectField}
                                optionValue={'id'}
                                optionLabel={'name'}
                                onChange={(e) => {
                                    this.setState({ selectedCategoryId: e.target.value })
                                }}
                            />
                            <div className='editor-popup' >
                                <div className='editor-block'>
                                    <div>
                                        <CKEditor
                                            data={data}
                                            config={config}
                                            onChange={this.onEditorChange}
                                            onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                        />
                                        <label>
                                        </label>
                                    </div>
                                </div>
                                <Button
                                    style={{
                                        marginRight: 10,
                                        width: 140,
                                        marginTop: 30,
                                        backgroundColor: 'black'
                                    }}
                                    className='btn btn-secondary float-right'
                                    variant='dark'
                                    type='submit'
                                    disabled={isSubmitted ? false : true}
                                >
                                    Save
                                </Button>
                                <Button onClick={this.handleCloseEditor}
                                    style={{
                                        marginRight: 10,
                                        background: '#808080',
                                        border: 'none',
                                        width: 140,
                                        marginTop: 30,
                                        float: 'right'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = store => {
    const { auth, adManagementDuck, authToken } = store;

    return ({
        loggedInUser: auth.user,
        authToken: authToken,
        adTypeList: adManagementDuck.adTypeList,
        categoryList: adManagementDuck.categoryList,
        templateList: adManagementDuck.selectedTemplates,
    })
};

export default injectIntl(
    connect(
        mapStateToProps,
        adManagementDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'CreateModal'
        })(CreateModal)
    )
);
