/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Collapse } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch, Grid } from "@material-ui/core";
import { Input } from 'reactstrap';
import { toastr } from "react-redux-toastr";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import { connect } from "react-redux";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import { StateChangeTypes } from 'downshift';
import './Whatsapp.scss'; 
import { numberOnly, PhoneNumber,whatsappnumber } from '../../../../config/validation';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { isNaN } from 'lodash';

const useStyles = makeStyles((theme) => ({
    customWidth: {
      maxWidth: 200,
    },
  }));

function EditWhatsappNo(props) {
    let [state, setState] = useState({checkedA: false});
    let [show, setShow] = useState(false);
    let [wNo, setwNo] = useState(props.WhatsNo.result.whatsapp_number);
    let [id, setid] = useState(0);
    const [open, setOpen] = useState(false);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgCountryCode, seterrorMsgCountryCode] = useState(false);
    const [correctCountryCode, setcorrectCountryCode] = useState(false);
    const [tooltipShow, settooltipShow] = useState(false);
    let prefix = '+'
    const [countryCode,setcountryCode] = useState(prefix+props.WhatsNo.result.country_code);
    useEffect(() => {
        setid(props.id)
        generateData();
    }, [])

    const generateData=()=>{
        if(!wNo){
            setState({ ...state, ["checkedA"]: false })
            setShow(false)
        }else{
            setState({ ...state, ["checkedA"]: true })
            setShow(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let requestData;
        setOpen(false);
        if(!wNo){
            requestData = { whatsapp_number: wNo, id: id, country_code: countryCode };
            props.addWhatsappNumber(requestData, (res) => {
                if(res){
                    toastr.success("Success", "Whatsapp number updated succesfully");
                    props.getWhatsappNumber(id, (res) => {
                        // console.log('callback response edit',res)
                      });
                }
            });
            setState({ ...state, ["checkedA"]: false })
            setShow(false)
        }else{
            requestData = { whatsapp_number: wNo, id: id, country_code: countryCode };
            props.addWhatsappNumber(requestData, (res) => {
              if(res){
                toastr.success("Success", "Whatsapp number updated succesfully");
                props.getWhatsappNumber(id, (res) => {
                    // console.log('callback response edit',res)
                  });
              }
            });
            setState({["checkedA"]: true })
            setShow(true)
        }
        
      };

    const handleChange = (event) => {
        if(props.WhatsNo.result.whatsapp_number == null ||  props.WhatsNo.result.whatsapp_number == "" ){
            setState({ ...state, [event.target.name]: event.target.checked })        
            setOpen(!open)
            setwNo("")
        }else{
            setState({["checkedA"]: true })
        }
        //setState({ ...state, [event.target.name]: event.target.checked });
    };

    const validatewNo=(e)=>{
        if(numberOnly(e)){
            setwNo(e);
            if(whatsappnumber(e)){
                seterrorMsg(true);
            }else{
                seterrorMsg(false);  
            }
        }
    }
    const storeCountryCodeValue=value=>{
        setcountryCode(value);
        seterrorMsgCountryCode(false);
        if(value.length>3){
            setcorrectCountryCode(true);
        }else{
            setcorrectCountryCode(false);
        }
    }
    const validateCountryCode=(value)=>{
        if(value==='+'){
            setcountryCode('')
            seterrorMsgCountryCode(true);
        }else{
            if(value.length===1){
                if(numberOnly(value)){
                    value = prefix + value;
                    storeCountryCodeValue(value)
                }
            }else if(numberOnly(value.substr(1))){
                value = prefix + value.substr(1);
                storeCountryCodeValue(value)
            }
        }
    }
    const classes = useStyles();
    const tooltipClose = () => {
        if(props.WhatsNo.result.whatsapp_number != null && props.WhatsNo.result.whatsapp_number != ""){
            settooltipShow(false);
        }
      };
    
      const tooltipOpen = () => {
        if(props.WhatsNo.result.whatsapp_number != null && props.WhatsNo.result.whatsapp_number != ""){
            settooltipShow(true);
        }
      };

    const editNo = () => {
        setOpen(!open);
    };

    return (
        <div>
            <Grid component="label" className='flex-whatsapptools' container alignItems="center" spacing={1}>
                <p Item className="text-danger">Inactive</p>
                <Tooltip title="To disable, you have to delete your whatsapp number" arrow placement="bottom" open={tooltipShow} onClose={tooltipClose} onOpen={tooltipOpen} classes={{ tooltip: classes.customWidth }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={state.checkedA}                            onChange={handleChange} 
                            name="checkedA" 
                            color="primary"
                        />
                    }
                />
                </Tooltip>
                <p item className="text-success">Active</p>
                {
                    props.WhatsNo.result.whatsapp_number == null || props.WhatsNo.result.whatsapp_number == "" ? <></> : !open && <Button onClick={editNo}>Edit</Button> 
                }
            </Grid>
        <Collapse in={open} className="whatsapp_edit_collapse">
            <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <label>Enter Whatsapp Number</label>
                        <div className="d-flex">
                        <Input type="text" value={countryCode} onChange={(e)=>validateCountryCode(e.target.value)} className="mr-2 text-center" style={{width: "53px"}}
                         />
                        <Input type="text" value={wNo} onChange={(e)=>validatewNo(e.target.value)}  />
                        </div>
                        {
                            errorMsg && <p className="text-danger">Give a valid whatsapp number</p>
                        }
                        {
                            errorMsgCountryCode && <p className="text-danger">Please put a country code, eg: usa=1</p>
                        }
                        {
                            correctCountryCode && <p className="text-danger">Give a valid country code</p>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Button disabled={(errorMsg || errorMsgCountryCode || correctCountryCode)?true:false} type="submit" >Save</Button>
                    </FormGroup>
            </Form>
        </Collapse>
            
        </div>
    )
}

const mapStateToProps = (store) => {
    const { auth, authToken } = store;
    let WhatsNo = store.npcDuck.WhatsNo;
    if(WhatsNo!==undefined){
    //   console.log('WhatsNo',WhatsNo.result.whatsapp_number)
    }
    return {
      loggedInUser: auth.user,
      authToken: authToken,
      WhatsNo: WhatsNo,
    };
  };
  

export default connect(mapStateToProps, {
    ...NPCDuck.actions,
    ...NewsPaperCompanyDuck.actions,
  })(EditWhatsappNo);
