import React, { Component } from "react";
import { Field, reduxForm, change } from "redux-form";
import { Form, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import * as NewsPaperCompanyDuck from "../../store/ducks/super-admin/NewsPaperCompany.duck";
import { resetPassword, forgetPasswordToken } from "../../crud/auth.crud";
import NavigateModal from "../user-types/NavigateModal";
import OutsideClickHandler from "react-outside-click-handler";
import { required, maxLength18, minLength6 } from "../../config/validation";
import { renderThemeText } from "../../common/layout/FormInput";
import clsx from "clsx";
import { MESSAGES } from "../../config/message";
import CommonAlert from "../../common/CommonAlert";
import AfterSubmit from "../errors/Bankinfo/AfterSubmit";

class ResetPassword extends Component {
  state = {
    validaToken: true,
    message: "",
    password: "",
    confirmPassword: "",
    success: false,
    error: false,
    isLinkExpired: [],
    show: false,
    resetFlag: false,
    showAlert: {
      isShow: false,
      alertMessage: "",
    },
    loading: false,
    loadingButtonStyle: {
      paddingRight: "2.5rem",
    },
  };

  componentDidMount() {
    const linkToken =
      this.props.location.pathname &&
      this.props.location.pathname.split("&link-token=")[1];
    console.log("linkToken: ", linkToken);
    this.props.getLinkExpired(linkToken, (res) => {
      this.setState({ isLinkExpired: res.data });
    });
  }
  /**
   * @method componentWillMount
   * @description called before render the component
   */
  componentWillMount() {
    let token = this.props.match.params.token;
    if (token) {
      /** Check valid token from database. */
      forgetPasswordToken(token)
        .then((res) => {
          this.setState({ validaToken: true });
        })
        .catch((error) => {
          this.setState({ validaToken: false });
          setTimeout(() => {
            this.setState({
              showAlert: {
                isShow: true,
                alertMessage: error.response.data.message,
              },
            });
          }, 5000);
        });
    }
  }

  /**
   * @method enableLoading
   * @description enable loading on button onsubmit the form
   */
  enableLoading = () => {
    this.setState({
      loading: true,
      loadingButtonStyle: { paddingRight: "3.5rem" },
    });
  };

  /**
   * @method enableLoading
   * @description disable loading on button onsubmit the form
   */
  disableLoading = () => {
    this.setState({
      loading: false,
      loadingButtonStyle: { paddingRight: "2.5rem" },
    });
  };

  /**
   * @method resetField
   * @description clear form fields
   */
  resetField = () => {
    this.props.dispatch(change("ResetPassword", "password", ""));
    this.props.dispatch(change("ResetPassword", "confirmPassword", ""));
  };

  setShow = () => {
    this.setState({ show: false });
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    this.enableLoading();
    if (values.password && values.confirmPassword) {
      const data = {
        emailToken: this.props.match.params.token,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      resetPassword(data)
        .then((res) => {
          this.setState({
            isRequested: true,
            show: true,
            variant: "success",
            message: MESSAGES.PASSWORD_CHANGE,
          });
          this.disableLoading();
          setTimeout(() => {
            window.location.assign("/auth/login");
          }, 5000);
        })
        .catch(() => {
          this.setState({
            show: true,
            variant: "danger",
            message: MESSAGES.SERVER_ERROR,
          });
          this.disableLoading();
        });
    }
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      validaToken,
      show,
      variant,
      loadingButtonStyle,
      loading,
      message,
      isLinkExpired,
    } = this.state;
    const { handleSubmit } = this.props;
    return (
      <>
        {!isLinkExpired ? (
          <AfterSubmit />
        ) : (
          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
            <div className="kt-login__body">
              {!validaToken && (
                <OutsideClickHandler
                  onOutsideClick={() => window.location.assign("/auth/login")}
                >
                  <NavigateModal
                    show={true}
                    alertHeading={"Token Expired!"}
                    showCancel={false}
                    alertMessage={MESSAGES.TOKEN_EXPIRE_MESSAGE}
                    onOkClick={() => window.location.assign("/auth/login")}
                    okButtonText="Ok"
                  />
                </OutsideClickHandler>
              )}
              <div className="kt-login__form">
                <div className="kt-login__title">
                  <h3>
                    <FormattedMessage id="Reset Password" />
                  </h3>
                </div>
                {show && (
                  <CommonAlert
                    show={show}
                    variant={variant}
                    message={message}
                    setShow={this.setShow}
                  />
                )}
                <Form
                  noValidate
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Form.Group as={Col} md="12">
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                      >
                        <Field
                          name="password"
                          label="Password"
                          type={"password"}
                          validate={[required, minLength6, maxLength18]}
                          required={true}
                          placeholder="Enter Your Password"
                          component={renderThemeText}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                      >
                        <Field
                          name="confirmPassword"
                          label="Confirm Password"
                          type={"password"}
                          validate={[required, minLength6, maxLength18]}
                          required={true}
                          placeholder="Confirm Your Password"
                          component={renderThemeText}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                  <div className="kt-login__actions">
                    <button
                      type="button"
                      onClick={this.resetField}
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                    >
                      Clear
                    </button>
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Set
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
  let errors = {};

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Password and confirm password are not match";
  }
  return errors;
}

const mapStateToProps = (store) => {
  let initialValues = {
    password: "",
    confirmPassword: "",
  };
  return {
    initialValues,
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    // auth.actions,
    NewsPaperCompanyDuck.actions
  )(
    reduxForm({
      form: "ResetPassword",
      validate,
      //destroyOnUnmount: false
    })(ResetPassword)
  )
);
