import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

/**
 * @method InfoToolTipButton
 * @description Info button tooltip
 * @param {*} param0 
 */
export const InfoToolTipButton = ({
    title
}) => {
    return (
        < Tooltip title={title} aria-label="add" className="mx-1 info-pulse-button" arrow>
            <InfoIcon fontSize="small" />
        </Tooltip >
    )
}