import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import C3Chart from 'react-c3js';
import { toastr } from 'react-redux-toastr';
import '../Chart.scss';
import FilterRecords from '../FilterRecords';
import * as paymentReportDuck from '../../../../../store/ducks/npc/PaymentReport.duck';
import { STATUS_CODES } from '../../../../../common/StatusCode';
import { convertISOToUtcDateformate3 } from '../../../../../common';
import BlankChart from '../../../../../common/BlankChart'
import { getCurrentMonth } from '../../../../../common/CommonFunction';
import { setDateFilterKeys } from '../../../../../common/CommonFilterFunction'
import { MESSAGES } from '../../../../../config/message';

let dateRange = []
let label = getCurrentMonth()
class Chart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalRevenue: ['Total'],  //,'400','700','100','800
            dateAxis: [],
            d: [],
            selectedOption: {
                option: 0,
                startDate: '',
                endDate: ''
            },
            openModal: false
        };
    }

    /**
    * @method componentDidMount
    * @description  called after rendering the component 
    */
    componentDidMount() {
        this.getPayoutRecords(4, 1, '', '');
    }

    /**
     * @method getCompanyList
     * @description  used to get all admin listing
     */
    getPayoutRecords = (row, page, sort, column) => {
        const { user_detail } = this.props.loggedInUser
        let requestData = {
            npc_id: user_detail.newspaper.id,
            row: row !== undefined ? row : 25,
            page: page !== undefined ? page : 1,
            sort: sort,
            column: column,
            type: 2
        }
        this.setData(requestData)
    }

    /**
    * @method setData
    * @description set requestdata
    */
    setData = (requestData) => {
        this.props.getPayoutGraphData(requestData, res => {
            if (res.status === STATUS_CODES.OK) {
                const { totalRevenue } = this.state
                let temp = totalRevenue;
                let data = res.data && Array.isArray(res.data.graphData) ? res.data.graphData : []
                data && data.length && data.map((item, i) => {
                    temp.push(item.paid_amount)
                    dateRange.push(convertISOToUtcDateformate3(item.createdAt))
                })
                let permittedValues = data && data.map(value => (convertISOToUtcDateformate3(value.createdAt)));
                this.setState({ totalRevenue: [...temp], dateAxis: permittedValues })
            }
        })
    }

    /**
   * @method filterRecords
   * @description filter records
   */
    filterRecords = (values, b) => {
        this.applyFilter(values, this.props)
    }

    /**
   * @method applyFilter
   * @description apply filter on graph
   */
    applyFilter(values, props) {
        const { user_detail } = this.props.loggedInUser;
        const data = setDateFilterKeys(values, label)
        if ((data.date && !data.date2) || (data.date2 && !data.date)) {
            return toastr.error(MESSAGES.DATE_VALIDATION)
        }
        const requestData = {
            npc_id: user_detail.newspaper.id,
            row: 25,
            page: 1,
            sort: '',
            column: '',
            month: data.this_month,
            year: data.this_year,
            type: 2,
            from_date: data.date,
            to_date: data.date2,
        }
        label = data.label
        this.setState({
            openModal: false,
            totalRevenue: ['Total'],
            selectedOption: {
                option: values.date_range,
                startDate: values.from,
                endDate: values.to
            }
        }, () => this.setData(requestData))
    }

    /**
   * @method render
   * @description render component
   */
    render() {
        const { totalRevenue, dateAxis, selectedOption } = this.state
        const { openModal } = this.state
        return (
            <div>
                <div className='row'>
                    <h4>Finance</h4><hr />
                    <h5
                        className='cursorPointer'
                        onClick={() => this.setState({ openModal: true })}
                    >{label}&nbsp;&nbsp;
                        <i
                            style={{ fontSize: 20 }}
                            className='fa fa-angle-down'
                            aria-hidden='true'
                        ></i>
                    </h5>
                </div>

                <div style={{ marginTop: 50 }}>
                    {totalRevenue.length > 1 ? <C3Chart
                        type='line'
                        data={{
                            columns: [
                                totalRevenue
                            ],
                        }}
                        axis={{
                            x: {
                                type: 'category',
                                show: true,
                                categories: dateAxis
                            },
                            y: {
                                label: {
                                    text: 'Orders',
                                    position: 'outer-middle'
                                }
                            },
                            y2: {
                                show: true,
                                label: {
                                    text: 'Amount',
                                    position: 'outer-middle'
                                },
                                max: 90,
                                min: 0
                            }
                        }}
                        unloadBeforeLoad={true}
                    /> : <div style={{ marginTop: 30 }} className='row w-100'><BlankChart /></div>}
                    {openModal && <FilterRecords
                        applyFilter={this.filterRecords}
                        isOpen={openModal}
                        onCancel={() => this.setState({ openModal: false })}
                        selectedOption={selectedOption}
                    />}
                </div>
            </div>
        );
    }
}
const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    const { auth } = store;
    return ({
        loggedInUser: auth.user,
        loading,
    })
};

export default injectIntl(
    connect(
        mapStateToProps,
        paymentReportDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'Chart',
            destroyOnUnmount: false
        })(Chart)
    )
);
