import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, untouch } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as settingsDuck from "../../../../store/ducks/super-admin/Settings.duck";
import { injectIntl } from "react-intl";
import { Card } from "react-bootstrap";
import "../ad-management/AdManagement.scss";
import ConformModel from "../ad-management/ConfirmModel";
import { Form, Col, Row } from "react-bootstrap";
import { langs } from "../../../../config/localization";
import { renderText } from "../../../../common/layout/FormInput";
import {
  required,
  minLength2,
  maxLength100,
} from "../../../../config/validation";
import { MESSAGES } from "../../../../config/message";
import { toastr } from "react-redux-toastr";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";

class RangeOfCopies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      message: "",
      rangeOfCopies: "",
      key: "",
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    this.props.getfromSuperAdminSetting(
      langs.keyname.copies_range,
      user_detail.id,
      (res) => {}
    );
  }

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { user_detail } = this.props.loggedInUser;
    const deletedItem = this.state.deletedItem;
    let reqData = {
      id: deletedItem.id,
      user_id: user_detail.id,
      advertisement_id: deletedItem.id,
      name: encodeURIComponent(deletedItem.copies_range),
    };
    this.props.commonDeleteAPI(langs.keyname.copies_range, reqData, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        this.props.getfromSuperAdminSetting(
          langs.keyname.copies_range,
          user_detail.id,
          (res) => {}
        );
        toastr.success(MESSAGES.DELETE_RANGE_SUCCESS);
        this.setState({
          showConfirmModal: false,
        });
      }
    });
  };

  /**
   * @method addRangeOfCopies
   * @description called api to add range of copies
   */
  addRangeOfCopies = (e) => {
    const { user_detail } = this.props.loggedInUser;
    const { rangeOfCopies } = this.state;
    const match =
      rangeOfCopies !== "" &&
      rangeOfCopies.trim() !== "" &&
      rangeOfCopies.length >= 2 &&
      rangeOfCopies.length <= 100;
    if (match) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          copies_range: rangeOfCopies,
        };
        this.props.commonAddAPI(
          langs.keyname.copies_range,
          requestData,
          (res) => {
            if (
              res.status === STATUS_CODES.CREATED ||
              res.status === STATUS_CODES.OK
            ) {
              this.props.getfromSuperAdminSetting(
                langs.keyname.copies_range,
                user_detail.id,
                (res) => {}
              );
              toastr.success(MESSAGES.ADD_RANGE_SUCCESS);
              this.setState({ rangeOfCopies: "" });
              document.activeElement.blur();
              this.props.dispatch(change("RangeOfCopies", "copies_range", ""));
              this.props.dispatch(untouch("RangeOfCopies", "copies_range"));
            }
          }
        );
      }
    }
  };

  /**
   * @method renderCardItem
   * @description render the  card item
   */
  renderCardItem = (typeOfListing) => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            key={i}
            bg="light"
            style={{
              width: "auto",
              height: "3.5rem",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <Card.Body>
              <Card.Title>
                <label>{data.copies_range}</label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                <i
                  className="fa fa-close crossIcon"
                  onClick={() =>
                    this.setState({
                      showConfirmModal: true,
                      deletedItem: data,
                      message: MESSAGES.RANGE_OF_COPIES,
                      count: data.newspaperCount,
                      key: "range of copies",
                    })
                  }
                ></i>
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { rangeList, handleSubmit, loading } = this.props;
    const { showInput, showConfirmModal, message, count, key } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <Portlet fluidHeight={true}>
            <PortletBody>
              {loading && <Loader />}
              <Form.Row className="ml-1">
                <h5 style={{ color: "#5d5757", marginBottom: "100" }}>
                  Range Of Copies
                </h5>
              </Form.Row>
              <div className="cardWrapper">
                {this.renderCardItem(rangeList)}
              </div>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid #ccc",
                  marginTop: 25,
                  marginLeft: 2,
                }}
              >
                <label
                  style={{ color: "black", marginTop: 25, cursor: "pointer" }}
                  onClick={() => this.setState({ showInput: !showInput })}
                  data-toggle="collapse"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  &nbsp;&nbsp;Add More
                </label>
              </div>
              {showInput && (
                <form
                  noValidate
                  className="form"
                  onSubmit={handleSubmit(this.addRangeOfCopies.bind(this))}
                >
                  <Row>
                    <Col md="6">
                      <Field
                        name="copies_range"
                        validate={[required, minLength2, maxLength100]}
                        placeholder="Enter Range of Copies"
                        component={renderText}
                        onChange={(e) =>
                          this.setState({
                            rangeOfCopies: e.target.value.trim(),
                          })
                        }
                        onKeyDown={this.addRangeOfCopies}
                      />
                    </Col>
                  </Row>
                </form>
              )}
            </PortletBody>
          </Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            message={message}
            totalCount={count}
            key={key}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, settingsDuck, authToken } = store;
  const { superAdminSettingList } = settingsDuck;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    rangeList: Array.isArray(superAdminSettingList.copies_range)
      ? superAdminSettingList.copies_range
      : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    settingsDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "RangeOfCopies",
    })(RangeOfCopies)
  )
);
