import React from "react";

export default ({
  label = "",
  children,
  className = "",
  checkBoxStyle = {},
}) => {
  return (
    <label class={`checkbox ${className}`}>
      {children}
      {label}
      <span style={checkBoxStyle}></span>
    </label>
  );
};
