import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { HiOutlineDownload } from "react-icons/hi";
import { AiOutlinePrinter } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import { TbCalendarStats } from "react-icons/tb";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import logo from "../../../../../_metronic/_assets/media/images/landing-page/pijen-tm-logo.png";
import Facebook from "../../../../../_metronic/_assets/media/images/landing-page/facebook.png";
import Twiiter from "../../../../../_metronic/_assets/media/images/landing-page/twitter.png";
import Instagram from "../../../../../_metronic/_assets/media/images/landing-page/instagram.png";
import Linkedin from "../../../../../_metronic/_assets/media/images/landing-page/linkedin.png";
import Youtube from "../../../../../_metronic/_assets/media/images/landing-page/youtube.png";
import { CircularProgress } from "@material-ui/core";

function getRandomColor(_id) {
  const id = (_id * _id)?.toString();
  const hash_ = () => {
    let hashK = 5382;
    let i = id.length;
    while (i) {
      hashK = (hashK * 33) ^ id.charCodeAt(--i);
    }
    return hashK >>> 0;
  };
  const hash = hash_(id);
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  const color = `rgba(${r}, ${g}, ${b},.6)`;
  return color;
}
function getContrastColor(rgb) {
  const rgbArr = rgb.split(",").map(Number);
  const luminance =
    (0.299 * rgbArr[0] + 0.587 * rgbArr[1] + 0.114 * rgbArr[2]) / 255;
  if (luminance > 0.5) {
    return "rgb(0,0,0)";
  } else {
    return "rgb(255,255,255)";
  }
}

const MainCalender = ({
  activeView = "publisher",
  setActiveView = () => {},
  data = [],
  activeEditionName,
}) => {
  const docRef = useRef();
  const iframeRef = useRef();
  const promiseResolveRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dataFormat = (_data) => {
    let output = [];
    _data.forEach((e) => {
      const publishers = e.onePublisher;
      const event = publishers.map((k) => {
        const bgColor = getRandomColor(e.userId);
        return {
          id: k.id,
          groupId: e.userId,
          title: k.newspaper_name,
          start: k.newPublisherDate || k.deadlineDate,
          backgroundColor: bgColor,
          textColor: "black",
        };
      });
      output = [...event, ...output];
    });
    return output;
  };
  const eventData = useMemo(() => dataFormat(data), [data]);
  const handleDownload = () => {
    setIsPrinting(true);
    setIsLoading(true);
    setTimeout(() => {
      html2canvas(docRef.current, { scale: 3 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF({
            orientation: "l",
            format: "a4",
            unit: "px",
          });
          pdf.addImage(imgData, "JPEG", 20, 20, 590, 400);
          pdf.save(activeEditionName + "Calender.pdf");
        })
        .then(() => {
          setIsPrinting(false);
          setIsLoading(false);
        });
    }, 0);
  };
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);
  const handlePrint = useReactToPrint({
    content: () => docRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
        setIsLoading(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
      setIsLoading(false);
    },
  });

  return (
    <Fragment>
      <div
        className={`calender_content${isPrinting ? " printMode" : ""} `}
        ref={docRef}
      >
        {isPrinting ? (
          <div className="printing_calender">
            <div className="calendar-heading">
              <h1>{activeEditionName} Calendar</h1>
              <span className="c-view">
                {activeView === "publisher" ? (
                  <span className="c-view-item">
                    <FaRegCalendarAlt size="15" />
                    <span> Publish view</span>
                  </span>
                ) : (
                  <span className="c-view-item">
                    <TbCalendarStats size="15" />
                    <span> Deadline view</span>
                  </span>
                )}
              </span>
            </div>
          </div>
        ) : (
          <div className="tab_bar">
            <div className="tabs">
              <button
                className={activeView === "publisher" ? "active" : ""}
                onClick={() => setActiveView("publisher")}
              >
                <FaRegCalendarAlt />
                <span>Publish view</span>
              </button>
              <button
                className={activeView === "deadline" ? "active" : ""}
                onClick={() => setActiveView("deadline")}
              >
                <TbCalendarStats />
                <span>Deadline view</span>
              </button>
            </div>
            <div className="events-btn">
              <button onClick={handlePrint}>
                <AiOutlinePrinter color="#ee5158" />
              </button>
              <button onClick={handleDownload}>
                <HiOutlineDownload color="#ee5158" />
              </button>
            </div>
          </div>
        )}

        <Fullcalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={"dayGridMonth"}
          headerToolbar={{
            start: isPrinting ? "title" : "prev,title,next",
            center: "",
            end: "",
          }}
          events={eventData}
          eventTextColor="black"
          height={isPrinting ? "auto" : "630px"}
          width={"100%"}
        />

        {isPrinting && (
          <div className="pdf_footer">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="right_side">
              <div className="contacts">
                <p>(212) 470-4400</p>
                <p>support@pijen.com</p>
              </div>
              <div className="social_links">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/Pijensystem/">
                      <img src={Facebook} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Pijensystem">
                      <img src={Twiiter} alt="twitter" />
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://www.instagram.com/pijensystems/">
                      <img src={Instagram} alt="instagram" />
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/pijen/about/">
                      <img src={Linkedin} alt="linkedin" />
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="print_loader">
          <CircularProgress size={50} color="secondary" />
        </div>
      )}
    </Fragment>
  );
};
export default MainCalender;
