import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import "./Card.scss";
import { Loader } from "../../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import * as orderManagementDuck from "../../../../../store/ducks/npc/OrderManagement.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { MESSAGES } from "../../../../../config/message";
import { langs } from "../../../../../config/localization";
import { APPROVE_STATUS } from "../../../../../config/constant";
import DeclineModal from "./DeclineOrderModal";
import { toastr } from "react-redux-toastr";
import { convertISOToUtcDateformate1 } from "../../../../../common";
import { NoContentFound } from "../../../../../common/NoContentFound";
import { DownloadImageButton } from "../../../../../common/CommonFunction";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../../partials/content/Portlet";

class RecievedOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeclineModal: false,
      declinedOrder: null,
      noContentFound: false,
      isSubmitted: true,
      loading: false,
      isRedirect: false,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    const { id } = this.props.match.params;
    this.getOrderList(id);
  }

  /**
   * @method getOrderList
   * @description  used to get all order listing
   */
  getOrderList = (id) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: id,
      customer_id: "",
      npc_id: user_detail.newspaper.id,
      newspaper_id: user_detail.newspaper.id,
    };
    this.props.getOrderDetail(requestData, (res) => {
      if (res.status !== STATUS_CODES.OK) {
        this.setState({ noContentFound: true });
      }
    });
  };

  /**
   * @method renderPublishDates
   * @description render publish dates records
   */
  renderPublishDates = (orderDetail) => {
    let publishDates;
    if (
      Array.isArray(orderDetail.order_publish_dates) &&
      orderDetail.order_publish_dates.length
    ) {
      let temp = [];
      orderDetail.order_publish_dates.map((el) => {
        temp.push(convertISOToUtcDateformate1(el.date));
      });
      publishDates = temp;
      return (
        <Card className="fullCard">
          <b className="mt-4 mb-2">Publish Dates</b>
          {publishDates.map((el, i) => {
            return (
              <p style={{ margin: 0 }} key={i}>
                <i className="fa fa-calendar" aria-hidden="true"></i>&nbsp;
                {el}
              </p>
            );
          })}
        </Card>
      );
    } else {
      return (publishDates = "");
    }
  };

  /**
   * @method acceptOrder
   * @description accepting order
   */
  acceptOrder = (orderDetail) => {
    const { user_detail } = this.props.loggedInUser;
    let reqData = {
      id: orderDetail.id,
      npc_id: user_detail.newspaper.id,
      user_id: orderDetail.user.id,
      newspaper_name: orderDetail.newspaper.newspaper_name,
      first_name: orderDetail.user.first_name,
      email: orderDetail.user.email,
      status: APPROVE_STATUS,
    };
    this.setState({ loading: true });
    this.props.acceptorder(reqData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ACCEPT_ORDER);
        this.setState({ isSubmitted: false, loading: false, isRedirect: true });
      } else {
        this.setState({ isSubmitted: true, loading: false });
      }
    });
  };

  /**
   * @method renderCard
   * @description render detail
   */
  renderCard = (title, value) => {
    if (value === undefined || value[0] === "" || !value[0]) {
      return "";
    }
    return (
      <Card className="fullCard col-md-3 col-sm-6 col-xs-12">
        <b className="mt-4 mb-2">{title}</b>
        {value.map((el, i) => (
          <p style={{ margin: 0 }}>{el === true ? `yes` : `${el}`} </p>
        ))}
      </Card>
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { orderDetail } = this.props;
    const {
      openDeclineModal,
      declinedOrder,
      noContentFound,
      isSubmitted,
      loading,
      isRedirect,
    } = this.state;
    let name =
      orderDetail && orderDetail.length && orderDetail[0].user
        ? orderDetail[0].user.first_name + " " + orderDetail[0].user.last_name
        : "";

    if (noContentFound) {
      return (
        <div>
          <NoContentFound />
        </div>
      );
    } else if (orderDetail === undefined) {
      return <Loader />;
    }
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/recievedOrderListing",
          }}
        />
      );
    }
    return (
      <div>
        <LayoutContextConsumer>
          {({ subheader: { title } }) => (
            <div>
              <div className="row">
                <h3 className="kt-subheader__title col-md-6">
                  <span>
                    <Link to="/recievedOrderListing">
                      <i
                        onClick={() => {
                          return this.props.history.push(
                            "/recievedOrderListing"
                          );
                        }}
                        style={{ fontSize: 20 }}
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </span>
                  <span className="ml-2">Order Detail</span>
                  <br />
                </h3>
              </div>
            </div>
          )}
        </LayoutContextConsumer>
        <Portlet>
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar className="w-100 row">
                {orderDetail && (
                  <h5 className="float-left col-md-10 ml-2">
                    Order no : {orderDetail[0].id}
                  </h5>
                )}
                {orderDetail !== undefined && (
                  <h5 className="float-right">
                    Status : {orderDetail[0].status}
                  </h5>
                )}
              </PortletHeaderToolbar>
            }
          />
          <PortletBody style={{ minHeight: 700, height: "auto" }}>
            {loading && <Loader />}
            {openDeclineModal && declinedOrder !== null && (
              <DeclineModal
                isOpen={openDeclineModal}
                orderDetail={declinedOrder}
                onCancel={() =>
                  this.setState({
                    openDeclineModal: false,
                    declinedOrder: null,
                  })
                }
              />
            )}
            {orderDetail &&
              orderDetail.length &&
              orderDetail.map((o) => {
                let adType = o.newspaper_ads_plan
                  ? o.newspaper_ads_plan.single_advertisement.ad_type
                  : "";
                return (
                  <div className="mb-4 p-3 bg-light shadow-sm">
                    <div className="row">
                      {o.user && this.renderCard("Customer Id", [o.user.id])}
                      {o.edition &&
                        this.renderCard("Edition", [o.edition.name])}
                      {o.edition &&
                        o.edition.print_frequency &&
                        this.renderCard("Edition Frequency", [
                          o.edition.print_frequency.print_frequency,
                        ])}
                      {this.renderCard("Ad type", [adType])}
                      {this.renderCard("No of Times Advertized", [
                        o.ad_frequency,
                      ])}
                      {o.user &&
                        this.renderCard("Customer details", [
                          name,
                          o.user.email,
                          o.user.phone_number,
                        ])}
                      {/* { this.renderCard('Ad details', [2])} */}
                      {this.renderPublishDates(o)}
                      {this.renderCard("Total amount", ["$" + o.price])}

                      {adType === langs.adType.Display &&
                        o.color &&
                        this.renderCard("Color Mode", [o.color.color_name])}

                      {adType === langs.adType.Classified &&
                        o.category &&
                        this.renderCard("Category", [o.category.name])}
                      {adType === langs.adType.Classified &&
                        this.renderCard("Colores Text", [o.color_text])}
                      {adType === langs.adType.Classified &&
                        this.renderCard("Bold", [o.bold])}
                      {adType === langs.adType.Classified &&
                        this.renderCard("Image", [o.image])}
                      {adType === langs.adType.Classified &&
                        this.renderCard("Border", [o.border])}
                      {adType === langs.adType.Classified &&
                        this.renderCard("Background Color", [
                          o.background_color,
                        ])}
                    </div>

                    <div className="row my-4 ">
                      <div className="col-12">
                        <Button
                          className="  btn-rp-secondary  float-right"
                          style={{
                            border: "none",
                            width: 150,
                            marginLeft: 20,
                          }}
                          onClick={() =>
                            this.setState({
                              openDeclineModal: true,
                              declinedOrder: o,
                            })
                          }
                        >
                          {" "}
                          Decline
                        </Button>

                        <Button
                          className=" standardBtn float-right"
                          onClick={() => this.acceptOrder(o)}
                          disabled={isSubmitted ? false : true}
                        >
                          {" "}
                          Accept{" "}
                        </Button>
                      </div>
                    </div>

                    <div className="row mt-5">
                      {/* --------------------- For Display ------------------- */}
                      {adType === langs.adType.Display && o.upload_pdf && (
                        <>
                          <div className="col-md-2 text-center font-weight-bold">
                            Ad Detail:
                          </div>
                          <div
                            className="col-md-2 d-flex align-items-center "
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              style={{
                                borderRadius: 100,
                                background: "#E8E8E8",
                                height: 130,
                                width: 130,
                              }}
                            >
                              <i
                                className="fa fa-file text-center"
                                style={{ fontSize: 100, display: "block" }}
                                aria-hidden="true"
                              ></i>
                            </div>

                            {o.upload_pdf && (
                              <DownloadImageButton imageName={o.upload_pdf} />
                            )}
                          </div>
                        </>
                      )}

                      {/* --------------------- For Classified ------------------- */}
                      {adType === langs.adType.Classified && o.content && (
                        <>
                          <div className="col-md-2 text-center font-weight-bold">
                            Ad Detail:
                          </div>
                          <div className="col">
                            <div className="classifiedAdDetailText">
                              <div
                                dangerouslySetInnerHTML={{ __html: o.content }}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading, orderDetail } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    orderDetail,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...orderManagementDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "RecievedOrderDetail",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(RecievedOrderDetail)
  )
);
