/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { phoneNumberFormate } from "../../../../common";

export default function OrderInfo(props) {
  const { info } = props;

  let name =
    info !== undefined && info.user
      ? info.user.first_name + " " + info.user.last_name
      : "";
  let newspaperName =
    info !== undefined && info.newspaper ? info.newspaper.newspaper_name : "";
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid text-dark">
        <div
          className="kt-portlet__head"
          style={{ background: "#E8E8E8", color: "black" }}
        >
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Order Information</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
            <div className="kt-widget4__info ">
              <h5>Customer</h5>
              <p className="kt-widget4__text ">
                {name} <br />
                {info.user && info.user.email} <br />
                {info.user && phoneNumberFormate(info.user.phone_number)}
              </p>
            </div>
          </div>
          <div className="kt-widget4">
            <div className="kt-widget4__info ">
              <h5>Newspaper Company</h5>
              <p className="kt-widget4__text ">
                {newspaperName} <br />
                {info.newspaper ? (
                  <span>
                    {info.newspaper.user.email} <br />
                    {phoneNumberFormate(info.newspaper.user.phone_number)}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
