import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { CSVLink } from "react-csv";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { NPCSalesHeaders } from "../../../../../common/CsvFormate";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import FilterRecords from "../FilterRecords";
import { getCurrentMonth } from "../../../../../common/CommonFunction";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";

class PrintDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
    };
  }

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Order#",
        accessor: "order_id",
        Cell: (row) => {
          const { original } = row;
          return original && original.id;
        }, // String-based value accessors!
      },
      {
        Header: "Customer Name",
        accessor: "first_name",
        Cell: (row) => {
          const { original } = row;
          return original.user && original.user.first_name
            ? original.user.first_name + " " + original.user.last_name
            : "";
        },
      },
      {
        Header: "Order Amount",
        accessor: "order_amount",
        Cell: (row) => {
          const { original } = row;
          return <div style={{ marginLeft: 10 }}>${original.order_amount}</div>;
        },
      },
      {
        Header: "Fee",
        accessor: "unpaid_amount",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>
              $
              {original &&
                original.payment_report &&
                original.payment_report.unpaid_amount}
            </div>
          );
        },
      },
      {
        Header: "Revenue",
        accessor: "total_revenue",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>
              $
              {original &&
                original.payment_report &&
                original.payment_report.total_revenue}
            </div>
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({
      sortedOrder: !sortedOrder,
      sortBy: sortBy,
      column: column.id,
    });
    this.props.callSorting(this.state.pageSize, 1, sortBy, column.id);
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getPayoutRecords(this.state.pageSize, 1, "", "");
        this.props.callPagination(this.state.pageSize, 1, "", "");
      }
    );
  };

  render() {
    const { payoutOrderList } = this.props;
    return (
      <div>
        <StandardTable
          handelSearch={this.handleSearch}
          columnOrder={this.renderRecords()}
          handleSort={this.handleSort}
          sortable={false}
          onPageSizeChange={this.onPageSizeChange}
          showSearch={false}
          data={payoutOrderList && payoutOrderList}
          pagination={false}
        />
      </div>
    );
  }
}

let label = getCurrentMonth();
class SalesRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
      openModal: false,
      sortBy: "",
      column: "",
      selectedOption: {
        option: 0,
        startDate: "",
        endDate: "",
      },
      fromDate: "",
      ToDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutRecords(25, 1, "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
      type: 3,
      section: 2,
    };
    this.props.getPayoutOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.orderData.rows) &&
          this.handleListCount(res.data.orderData.rows);
        let pages = res.data.orderData.count / this.state.pageSize;
        this.setState({
          payoutOrderList: [...res.data.orderData.rows],
          totalCount: Math.ceil(pages),
          count: res.data.orderData.count,
        });
      }
    });
  };

  /**
   * @method handleListCount
   * @description  handle total record display
   */
  handleListCount = (res) => {
    let temp = [];
    if (res.length <= 4) {
      temp = res.slice(0, res.length);
      this.setState({ orderList: temp });
    } else {
      let len = !this.state.viewAll ? 4 : res.length;
      temp = res.slice(0, len);
      this.setState({ orderList: temp });
    }
  };

  /**
   * @method FilterRecords
   * @description  called after rendering the component
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const { user_detail } = this.props.loggedInUser;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      npc_id: user_detail.newspaper.id,
      row: 25,
      page: 1,
      sort: "",
      column: "",
      month: data.this_month,
      year: data.this_year,
      type: 3,
      from_date: data.date,
      to_date: data.date2,
      section: 2,
    };
    label = data.label;
    this.props.getPayoutOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.orderData.rows) &&
          this.handleListCount(res.data.orderData.rows);
        this.setState({
          fromDate: data.date,
          ToDate: data.date2,
          month: data.this_month,
          year: data.this_year,
          openModal: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  }

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const {
      viewAll,
      pageSize,
      count,
      entries,
      month,
      year,
      toDate,
      fromDate,
    } = this.state;
    const { payoutOrderList } = this.props;
    if (viewAll) {
      if (payoutOrderList.length) {
        this.setState({ loader: false }, () => {
          const { user_detail } = this.props.loggedInUser;
          let requestData = {
            npc_id: user_detail.newspaper.id,
            row: pageSize + entries,
            page: 1,
            sort: "",
            column: "",
            month: month ? month : "",
            year: year ? year : "",
            from_date: fromDate ? fromDate : "",
            to_date: toDate ? toDate : "",
            type: 3,
            section: 2,
          };
          payoutOrderList.length < count &&
            this.props.getPayoutOrderList(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                let pages = res.data.orderData.count / this.state.pageSize;
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  totalCount: Math.ceil(pages),
                  count: res.data.orderData.count,
                });
                setTimeout(() => {
                  this.setState({
                    localLoading: false,
                    payoutOrderList: [...res.data.orderList.rows],
                  });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method getCSV data
   * @description render filet CSV keys
   */
  getCSVdata = () => {
    const { payoutOrderList } = this.props;
    return (
      Array.isArray(payoutOrderList) &&
      payoutOrderList.map((item) => {
        return {
          ...item,
          ...{ full_name: item.user.first_name + " " + item.user.last_name },
          ...{ orderAmount: item.total_amount },
          ...{ commissionFee: item.payment_report.total_revenue },
          ...{ totalRevenue: item.payment_report.commission_fee },
        };
      })
    );
  };

  /**
   * @method viewAllRecord
   * @description view all records
   */
  viewAllRecord = () => {
    this.setState({ viewAll: true });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      viewAll,
      page,
      pageSize,
      localLoading,
      openModal,
      selectedOption,
      orderList,
    } = this.state;
    const headers = NPCSalesHeaders;
    let data = this.getCSVdata();
    return (
      <div style={{ marginTop: -15 }}>
        <Portlet fluidHeight={true}>
          <PortletBody className="px-4" content={() => this.componentRef}>
            <div className="row ">
              <div className="col-md-12 mt-5">
                <h5 className="float-left">Sales History</h5>
                <h5 className="float-right border-bottom-0 border-primary">
                  <CSVLink
                    filename={"SalesDetails.csv"}
                    data={data}
                    headers={headers}
                    style={{ borderBottom: "1px solid" }}
                  >
                    Export CSV
                  </CSVLink>
                </h5>
                <h5 className="float-right mr-4">
                  <Link>
                    <ReactToPrint
                      trigger={() => (
                        <a href="#" style={{ borderBottom: "1px solid" }}>
                          Print
                        </a>
                      )}
                      content={() => this.componentRef}
                    />
                  </Link>
                </h5>
                <h5
                  className="mt-3 mt-sm-auto float-right mr-sm-4 cursorPointer"
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}
                  &nbsp;&nbsp;
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </h5>
              </div>
            </div>
            <div className="row" style={{ border: "1px solid black" }}></div>
            <div className="row">
              <div className="col-12">
                <InfiniteScroll
                  dataLength={orderList.length}
                  next={this.fetchMoreData}
                  loader={
                    localLoading && (
                      <h4 style={{ textAlign: "center" }}>Loading...</h4>
                    )
                  }
                  hasMore={true}
                  style={{ overflow: "unset" }}
                >
                  <PrintDocument
                    ref={(el) => (this.componentRef = el)}
                    callSorting={(row, page, sortBy, column) =>
                      this.getPayoutRecords(row, page, sortBy, column)
                    }
                    callPagination={(row, page, sortBy, column) =>
                      this.getPayoutRecords(row, page, sortBy, column)
                    }
                    payoutOrderList={orderList}
                    viewAll={viewAll}
                    viewAllRecord={this.viewAllRecord}
                  />
                </InfiniteScroll>
                {orderList && orderList.length >= 4 && !viewAll && (
                  <div
                    onClick={() => {
                      this.setState(
                        { viewAll: true, localLoading: true },
                        () => {
                          this.getPayoutRecords(pageSize, page);
                        }
                      );
                      setTimeout(() => {
                        this.setState({ localLoading: false });
                      }, 3000);
                    }}
                  >
                    <label style={{ cursor: "pointer", color: "#0000ff" }}>
                      View All
                    </label>
                  </div>
                )}
                {localLoading && <FooterLoader />}
              </div>
            </div>
          </PortletBody>
        </Portlet>
        {openModal && (
          <FilterRecords
            applyFilter={this.FilterRecords}
            isOpen={openModal}
            onCancel={() => this.setState({ openModal: false })}
            type={3}
            resetList={() => this.getPayoutRecords()}
            selectedOption={selectedOption}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { paymentReportDuck } = store;
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { salesOrderList } = paymentReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    payoutOrderList:
      salesOrderList &&
      salesOrderList.orderData &&
      Array.isArray(salesOrderList.orderData.rows)
        ? salesOrderList.orderData.rows
        : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...paymentReportDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "SalesRecordList",
    })(SalesRecordList)
  )
);
