import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
// import {
//     CALL_LOADING
// } from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SuperAdminBankInformation: "[SuperAdminBankInformation] Action",
    SetSuperAdminSetingList: "[SetSuperAdminSetingList] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    superAdminSettingList: {},
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get data of individual admin by id */
            case actionTypes.SuperAdminBankInformation: {
                const { bankInformation } = action.payload;
                return { ...state, bankInformation: bankInformation };
            }

            /** get data of individualad type detail by id */
            case actionTypes.SetSuperAdminSetingList: {
                const { apiKey, result } = action.payload;
                let superAdminSettingList = state.superAdminSettingList;
                superAdminSettingList[apiKey] = result;
                superAdminSettingList = { ...superAdminSettingList, [apiKey]: superAdminSettingList[apiKey] }
                return { ...state, superAdminSettingList };
            }


            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
     * @method updateBankInformation
     * @description update Bank Information
     */
    updateBankInformation: (requestData, callback) => {
        const request = axios.put(API.genericNewsPaper, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    updateEmailInformation: (requestData, callback) => {
        const request = axios.put(API.updateEmailInfo, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    updateWhatsappInformation: (requestData, callback) => {
        const request = axios.put(API.updateWhatsappInfo, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    updatePayoutInformation: (requestData, callback) => {
        const request = axios.put(API.updatePayoutInfo, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    updateCustomURLInformation: (requestData, callback) => {
        const request = axios.put(API.updateCustomURLInfo, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    updateSendEmailList: (requestData, callback) => {
        const request = axios.put(API.updateSendEmailList, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method viewAdminBankInformationById
     * @description view admin bank information 
     */
    viewAdminBankInformationById: (callback) => {
        const request = axios.get(`${API.genericNewsPaper}?id=${0}&is_deleted=${0}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.SetSuperAdminSetingList, payload: { apiKey: 'bankInformation', result: response.result } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method getfromSuperAdminSetting
    * @description get all distribution areas list 
    */
    getfromSuperAdminSetting: (apiKey, id, callback) => {
        const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.SetSuperAdminSetingList, payload: { apiKey: apiKey, result: response.data } })
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method addRoleAPI
    * @description Add role
    */
    addRoleAPI: (requestData, callback) => {
        const request = axios.post(API.genericRoleAPI, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
    /**
     * @method commonAddAPI
     * @description Add inSttings
     */
    commonAddAPI: (key, requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/${key}`, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
               callback(error);
            });
        };
    },

    /**
    * @method commonDeleteAPI
    * @description delete common by id
    */
    commonDeleteAPI: (api, reqData, callback) => {
        const { user_id, name, id } = reqData;
        const request = axios.delete(`${API.genericAPI}/${api}?id=${id}&user_id=${user_id}&name=${name}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method deleteRejectReason
     * @description delete reason by id
     */
    deleteRejectReason: (api, id, callback) => {
        const request = axios.delete(`${API.genericAPI}/${api}?id=${id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method deleteArea
     * @description delete area by id
     */
    deleteArea: (api, reqData, callback) => {
        const { user_id, area, state, id } = reqData;
        const request = axios.delete(`${API.genericAPI}/${api}?id=${id}&user_id=${user_id}&area=${area}&state=${state}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
    /**
    * @method deleteRoleById
    * @description delete role by id
    */
    deleteRoleById: (data, callback) => {
    const {id, user_id, name} = data
        const request = axios.delete(`${API.genericRoleAPI}?id=${id}&user_id=${user_id}&name=${name}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method getAllLanguageList
    * @description get all language list
    */
    getAllLanguageList: (key, id) => {
        const request = axios.get(`${API.generalAPI}/${key}?id=${id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                return dispatch({ type: actionTypes.SetSuperAdminSetingList, payload: { apiKey: 'language', result: response.data } })
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
            });
        };
    },
    /**
    * @method addLanguageAPI
    * @description Add language api
    */
    addLanguageAPI: (api, requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/${api}`, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method addDistributionArea
    * @description Add area and state
    */
    addDistributionArea: (api, requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/${api}`, requestData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





