import React from "react";

export function NoContentFound() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root" style={{ height: "100%" }}>
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
        >
          <div className="kt-error-v1__container">
            <h1 style={{textAlign: 'center', marginTop: '18rem'}}>No Record associated for this id</h1>
          </div>
        </div>
      </div>
    </>
  );
}