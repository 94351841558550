import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetAllOrderList: "[SetAllOrderList] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    recievedOrderList: [],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {


            /** get all records of company list */
            case actionTypes.SetAllOrderList: {
                const { orderList } = action.payload;
                return { ...state, recievedOrderList: orderList };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
     * @method acceptorder
     * @description acceptorder By Npc
     */
    acceptorder: (requestData, callback) => {
        let request = axios.patch(`${API.ordersApi}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                callback(error);
            });
        };
    },
    /**
* @method getAllRecievedOrderList
* @description get all order listing/records 
*/
    getAllRecievedOrderList: (requestData, callback) => {
        const { row, page, sort, column, searchKey, npc_id } = requestData;
        let url = `${API.getAllRecivedOrderList}?npc_id=${npc_id}&status=Pending&sort=${sort}&column=${column}&row=${row}&page=${page}&search=${searchKey}`


        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetAllOrderList, payload: { orderList: response.data.rows } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                callback(error);
            });
        };
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





