import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, untouch } from "redux-form";
import { injectIntl } from "react-intl";
import * as adManagementDuck from "../../../../../store/ducks/super-admin/AdManagement.duck";
import { MESSAGES } from "../../../../../config/message";
import { renderText } from "../../../../../common/layout/FormInput";
import { Col } from "reactstrap";
import "./categoryList.scss";
import ConformModel from "../ConfirmModel";
import { toastr } from "react-redux-toastr";
import { langs } from "../../../../../config/localization";
import { checkPermission } from "../../../../../common/CheckPermissions";
import {
  required,
  gminLength,
  maxLengthC100,
} from "../../../../../config/validation";
import { superAdminId } from "../../../../../config";
import { STATUS_CODES } from "../../../../../common/StatusCode";

class CategoryListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      cat_name: "",
      selectedCategory: 0,
      deletedCategory: undefined,
      isEdit: false,
      showConfirmModal: false,
      message: "",
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.getCategories();
  }

  /**
   * @method getCategories
   * @description getCategories
   */
  getCategories = () => {
    this.props.getAllCategoryListing((res) => {
      if (Array.isArray(res.data) && res.data.length > 0) {
        this.getAllTemplatesOfCategory(res.data[0], superAdminId);
      }
    });
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const deletedItem = this.state.deletedCategory;
    this.props.deleteCategoryById(deletedItem, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_CATEGORY_SUCCESS);
        this.getCategories();
        this.setState({ showConfirmModal: false });
      }
    });
  };

  /**
   * @method addCategory
   * @description called api to add addCategory
   */
  addCategory = (e) => {
    this.props.dispatch(untouch("CategoryListing", "category"));
    const { cat_name } = this.state;
    const match =
      cat_name !== "" &&
      cat_name.trim() !== "" &&
      cat_name.length >= 2 &&
      cat_name.length <= 100;
    if (match) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          name: cat_name,
        };
        this.setState({ cat_name: "" });
        this.props.createCategory(requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED) {
            document.activeElement.blur();
            this.props.dispatch(change("CategoryListing", "category", ""));
            this.props.dispatch(untouch("CategoryListing", "category"));
            toastr.success(MESSAGES.ADD_CATEGORY_SUCCESS);
            this.getCategories();
          }
        });
      }
    }
  };

  /**
   * @method getAllTemplatesOfCategory
   * @description called api to get All Template of Category
   */
  getAllTemplatesOfCategory = (category, index) => {
    this.setState({ selectedCategory: index });
    this.props.getSelectedCategory(category);
    let reqData = { catId: category.id };
    this.props.getTemplatesOfCategory(reqData, (res) => {});
  };

  /**
   * @method renderCategoryList
   * @description render Category List
   */
  renderCategoryList = (per) => {
    const { selectedCategoryId, categoryList } = this.props;
    return (
      categoryList &&
      categoryList.map((el, i) => {
        let extStyle = "";
        extStyle =
          Number(el.id) === Number(selectedCategoryId)
            ? "row categoryListItemSelected container"
            : "row categoryListItem container";
        return (
          <div
            key={i}
            className={extStyle}
            onClick={() => {
              this.getAllTemplatesOfCategory(el, i);
            }}
          >
            <p className="col-md-10 text-truncate">{el.name}</p>
            {per && this.state.isEdit && (
              <i
                className="fa fa-close  col-md-2"
                onClick={() =>
                  this.setState({
                    showConfirmModal: true,
                    openModel: false,
                    deletedCategory: el.id,
                    message: MESSAGES.TEMPLATE_CATEGORY,
                    count: el.templateCount,
                  })
                }
              ></i>
            )}
          </div>
        );
      })
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit } = this.props;
    const { isEdit, showInput, showConfirmModal, message, count } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.manageClassifiedTemplate,
      langs.permissionKeys.view
    );
    const createPer = checkPermission(
      langs.permissionKeys.manageClassifiedTemplate,
      langs.permissionKeys.create
    );
    const deletePer = checkPermission(
      langs.permissionKeys.manageClassifiedTemplate,
      langs.permissionKeys.delete
    );
    return (
      <div className="container">
        <div className="row" style={{ color: "rgb(93, 87, 87)" }}>
          <h5>Categories</h5>
          {deletePer && (
            <p
              onClick={() => this.setState({ isEdit: !isEdit })}
              style={{
                cursor: "pointer",
                right: 25,
                position: "absolute",
                fontSize: 14,
                color: "black",
              }}
            >
              edit
            </p>
          )}
        </div>
        <div
          className="categoryList overflow-hidden py-2"
          style={{ borderBottom: "1px solid #ccc" }}
        >
          <div style={{ maxHeight: 200, overflowY: "auto" }}>
            {viewPer ? (
              this.renderCategoryList(deletePer)
            ) : (
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
              </div>
            )}
          </div>
          <div
            className="row my-2"
            style={{
              marginTop: 50,
            }}
          >
            {createPer && (
              <div className="col my-2">
                <label
                  style={{ color: "black", marginTop: 25, cursor: "pointer" }}
                  onClick={() => this.setState({ showInput: true })}
                >
                  <i
                    className="fa fa-plus"
                    aria-hidden="true"
                    onClick={() => this.setState({ showInput: true })}
                  ></i>
                  &nbsp;&nbsp;Add New Category
                  {showInput && (
                    <form
                      noValidate
                      className="form"
                      onSubmit={handleSubmit(this.addCategory.bind(this))}
                    >
                      <Col md="12">
                        <Field
                          name="category"
                          placeholder="Enter Category"
                          validate={[required, gminLength, maxLengthC100]}
                          component={renderText}
                          onChange={(e) =>
                            this.setState({ cat_name: e.target.value.trim() })
                          }
                          onKeyDown={this.addCategory}
                        />
                      </Col>
                    </form>
                  )}
                </label>
              </div>
            )}
            <hr />
          </div>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            message={message}
            totalCount={count}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, adManagementDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    adTypeList: adManagementDuck.adTypeList,
    categoryList: adManagementDuck.categoryList,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adManagementDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "CategoryListing",
    })(CategoryListing)
  )
);
