import React, { useState, useEffect } from "react";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import { connect } from "react-redux";
import EditWhatsappNo from "./EditWhatsappNo";
import { injectIntl } from 'react-intl';
import { Card } from "react-bootstrap";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";

const Whatsapp = (props) => {

  useEffect(() => {
    const { user_detail } = props.user_detail;
    console.log()
    const requestData = {
      id: user_detail.id
    }
    props.getWhatsappNumber(requestData.id, (res) => {
    });
  }, []);

  return (
    <div>
      <LayoutContextConsumer className="row">
        {({ subheader: { title } }) => (
          <div className="row">
            <h5 className="kt-subheader__title col-md-6">
              <span>Whatsapp Number</span>
            </h5>
          </div>
        )}
      </LayoutContextConsumer>
      <Portlet fluidHeight={true}>
        <PortletBody className='whatsCard_body'>
        {props.WhatsNo != undefined ? (
        <EditWhatsappNo
          whatsapp_number={props.WhatsNo.result.whatsapp_number}
          id={props.user_detail.user_detail.id}
          countryCode={props.WhatsNo.result.country_code}
        /> 
      )
      :
      'Please Wait'}
        </PortletBody>
      </Portlet>
      
    </div>
  );
};

const mapStateToProps = (store) => {
  const { auth, authToken } = store;
  let WhatsNo = store.npcDuck.WhatsNo;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    WhatsNo: WhatsNo,
  };
};

// const mapStateToProps = state => ({
//   WhatsNo : state.npcDuck.WhatsNo
//  // isAuthenticate: state.auth.isAuthenticate
//  })

export default injectIntl(
  connect(mapStateToProps, NPCDuck.actions)(Whatsapp)
);
// export default connect(mapStateToProps, 
//   NPCDuck.actions,
//   NewsPaperCompanyDuck.actions,
// )(Whatsapp);
