import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import ImgsViewer from "react-images-viewer";
import { connect } from "react-redux";
import CampaignModal from "./CampaignModal";
import moment from 'moment';
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
  change as changeFieldValue,
  untouch as untouchField,
} from "redux-form";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import NavigationPrompt from "react-router-navigation-prompt";
import {
  required,
  maxLengthC100,
  number,
  greaterThanZeroWithTwoDecimalPlace,
  minLengthC3,
  normalizeCharacterLimit
} from "../../../../config/validation";
import {
  renderText,
  renderSelectField,
  renderNumberField,
} from "../../../../common/layout/FormInput";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import { MakeTable, getDays, getTimes } from "../../../../config/utils";
import AddColorForm from "./AddColorForm";
import CheckActivity from "./CheckActivity";
import AddCharacterForm from "./AddCharacterForm";
import AddPremiumForm from "./AddPremiumForm";
import AddLineForm from "./AddLineForm";
import AddPaymentDiscountOptionForm from "./AddPaymentDiscountOptionForm";
import { langs } from "../../../../config/localization";
import { NoContentFound } from "../../../../common/NoContentFound";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";
import RadioButtonRP from "../../../../common/RadioButtonRP";

const EditPlan = ({
  formValues,
  handleSubmit,
  submitting,
  pristine,
  /** store states */
  loggedInUser,
  /**Actions */
  getfromNPCSetting,
  getAllAdType,
  updatePlan,
  getPlanById,
  getAllPaperType,
  getAllAccount,
  resetPlan,
  changeFieldValue,
  untouchField,
  reset,
  advertisePlanUpdate,
  getVersions,
  getPlanVersionById
}) => {
  const history = useHistory();

  // fetch plan id from url
  const { planId } = useParams();

  // fetch logged in user details
  const { user_detail } = loggedInUser;

  /** state to detect is discount_option and color_option changed */
  const [isChanged, setIsChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [characterLimit, setDeadlineCharacter] = useState(0);
  const [lineLimit, setDeadlineLine] = useState(0);
  const [message, setMessage] = useState("");
  const [planVersion, setPlanVersion] = useState([]);
  const [deadlinePrice, setDeadlinePrice] = useState(0.0);
  // Ad Type states
  // all the advertisements type list of the logged in NPC user
  const [advertisementsTypeList, setAdvertisementsTypeList] = useState([]);
  const [displayAdPremium, setDisplayAdPremium] = useState([]);
  // selected Ad Type state
  const [selectedAdType, setSelectedAdType] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [versionId, setVersionId] = useState(0.0);

  // selected Ad Type type state i.e. Display, classified etc.
  const [typeOfSelectedAdType, setTypeOfSelectedAdType] = useState("");

  // selected Ad Type image state
  const [selectedAdTypeImage, setSelectedAdTypeImage] = useState("");

  const [allAddtype, setAllAddType] = useState([]);
  const [accountType, setAccountType] = useState([]);
  // Edition list state
  const [editions, setEditions] = useState([]);
  const [editionsName, setEditionsName] = useState("");

  // image preview state
  const [openImage, setOpenImage] = useState(false);

  const [paperOption, setPaperOption] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperTypeData, setPaperTypeData] = useState("");
  const [paperTypeCost, setPaperTypeCost] = useState("");
  const [bleedwidth, setBleedwidth] = useState("");
  const [bleedheight, setBleedheight] = useState("");
  const [check, setcheck] = useState(true);
  // States when type of Ad Type is Display
  // All the colors list which are added into table
  const [displayAdColors, setDisplayAdColors] = useState([]);
  const [displayAdCharacter, setDisplayAdCharacter] = useState([]);
  const [displayAdLine, setDisplayAdLine] = useState([]);

  /** payment discount options states */
  const [paymentDiscountOptions, setPaymentDiscountOptions] = useState([]);
  const [paymentDiscountOptionError, setPaymentDiscountOptionError] = useState(
    null
  );

  const [accountTypeName, setAccountTypeName] = useState(0);

  const [AddLinerError, setAddLinerError] = useState(null);
  const [AddCharacterError, setAddCharacterError] = useState(null);

  // redirect state when no data found
  const [isRedirect, setIsRedirect] = useState(false);

  const [val, setVal] = useState();
  const [fmval, setfmVal] = useState();
  const [disableFormField, setDisableFormField] = useState(false);
  const [planActivity, setPlanActivity] = useState();
  const [planDeatils, setPlanDeatils] = useState({});
  const [invalidPlan, setInvalidPlan] = useState(false);

  var letestVersion;

  /**
   * @method getAdType
   * @description return type of selected Ad Type
   */
  const getAdType = (advertisementType = "") => {
    const add = allAddtype.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    if (!add) return "";
    const type = advertisementType !== "" ? advertisementType : add.ad_type;
    switch (type) {
      case "Classified":
        return "CLASSIFIED";

      case "Display":
        return "DISPLAY";
      default:
        return "";
    }
  };

  /**
   * @method getAdTypeOptions
   * @description return dropdown options for Ad Type
   */
  const getAdTypeOptions = () => {
    return allAddtype.length > 0
      ? allAddtype.map((ad) => ({
        label: ad.ad_name,
        value: ad.id,
      }))
      : [];
  };

  const changeVersions = (value) => {
    getPlanVersionById(({ value: value, versionId: versionId }), (res) => {
      history.push(`${res.data.rows[0].id}`);
    });
  }

  const onClickNew = () => {
    if (showResults) {
      setShowResults(false);
      changeFieldValue("edit-plan", "bleed_width", "");
      changeFieldValue("edit-plan", "bleed_height", "");
      untouchField("edit-plan", "bleed_width");
      untouchField("edit-plan", "bleed_height");
    } else {
      setShowResults(true);
    }
  };

  /**
   * @method getEditionOptions
   * @description returns drop down options for edition
   */
  const getEditionOptions = () => {
    return editions.length > 0
      ? editions.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  let getEditionName = (id) => {
    let add = editions.find((ad) => parseInt(ad.id) === parseInt(id));
    if (add.name != "") {
      setEditionsName(add.name);
    }
  };

  /**
   * @method handleAddDiscountOptions
   * @description handle add a discount option
   */
  const handleAddDiscountOptions = (v) => {
    setIsChanged(true);
    setPaymentDiscountOptionError(null);
    // set discount options to state
    setPaymentDiscountOptions([...paymentDiscountOptions, v]);
  };

  /**
   * @method handleDeleteDiscountOptions
   * @description handle delete discount option from table
   * @param {*} option
   */
  const handleDeleteDiscountOptions = (payDisOpt) => {
    setIsChanged(true);
    const updatedPaymentDiscountOptions = paymentDiscountOptions.filter(
      (p) =>
        !(
          p.runs == payDisOpt.runs &&
          p.within == payDisOpt.within &&
          p.typeOfDiscount == payDisOpt.typeOfDiscount &&
          p.discountAmount == payDisOpt.discountAmount
        )
    );
    setPaymentDiscountOptions(updatedPaymentDiscountOptions);
  };

  /**
   * @method handleAddDisplayAdColor
   * @description handle add color for display Ad
   */
  const handleAddDisplayAdColor = (color) => {
    setIsChanged(true);
    setDisplayAdColors([...displayAdColors, color]);
  };

  const handleDeleteDisplayAdPremium = (disAdCol) => {
    const updatedPremium = displayAdPremium.filter(
      (c) =>
        !(
          c.premium === disAdCol.premium &&
          c.premium_cost === disAdCol.premium_cost
        )
    );
    setDisplayAdPremium(updatedPremium);
  };

  const handleAddDisplayAdCharacter = (character) => {
    // set character option to state
    setDisplayAdCharacter([...displayAdCharacter, character]);
    setAddCharacterError(null);
  };
  const handleAddDisplayAdLine = (line) => {
    // set character option to state
    setDisplayAdLine([...displayAdLine, line]);
    setAddLinerError(null);
  };

  const handleAddDisplayAdPremium = (premium) => {
    // set color option to state
    setDisplayAdPremium([...displayAdPremium, premium]);
  };

  let getCharacterLimit = (value) => {
    setDeadlineCharacter(value);
  };

  let cancelDelete = () => {
    setShowModal(false);
  };

  let confirmDelete = () => {
    const selectedEdition =
      editions && editions.length === 1 ? editions[0].id : val.edition_id;
    let classified_conditional_errors = {};
    let display_height_width_errors = {};
    /**
     * checking conditional mandatory fields
     */
    if (val.isColoredText && !val.color_text_cost) {
      classified_conditional_errors.color_text_cost =
        langs.validation_messages.required;
    }
    if (val.isBold && !val.bold_cost) {
      classified_conditional_errors.bold_cost =
        langs.validation_messages.required;
    }
    if (val.isAddImage && !val.image_cost) {
      classified_conditional_errors.image_cost =
        langs.validation_messages.required;
    }
    if (val.isAddBorder && !val.border_cost) {
      classified_conditional_errors.border_cost =
        langs.validation_messages.required;
    }
    if (val.isAddBackgroundColor && !val.background_cost) {
      classified_conditional_errors.background_cost =
        langs.validation_messages.required;
    }

    if (Object.keys(classified_conditional_errors).length !== 0) {
      if (typeOfSelectedAdType === "CLASSIFIED") {
        throw new SubmissionError(classified_conditional_errors);
      }
    }

    if (Object.keys(display_height_width_errors).length !== 0) {
      if (typeOfSelectedAdType === "DISPLAY") {
        throw new SubmissionError(display_height_width_errors);
      }
    }

    if (fmval.discount && paymentDiscountOptions.length === 0) {
      setPaymentDiscountOptionError("Please add payment discount options");
      return;
    }

    if (
      val.isAddCharacter == true &&
      typeOfSelectedAdType === "CLASSIFIED" &&
      displayAdCharacter.length === 0
    ) {
      setAddCharacterError("Please add character limit");
      return;
    }
    if (
      val.isAddLine == true &&
      typeOfSelectedAdType === "CLASSIFIED" &&
      displayAdLine.length === 0
    ) {
      setAddLinerError("Please add line limit");
      return;
    }
    // formated date to send
    let data = {
      id: val.id,
      npc_id: user_detail.newspaper.id,
      edition_id: selectedEdition ? selectedEdition : 0,
      edition_name: val.editions && val.editions.length === 1 ? val.editions[0].name : "",
      version: val.version,
      versionId: val.versionId,
      bleed_needed: val.bleed_needed ? val.bleed_needed : 0,
      isAddLine: false,
      isAddCharacter: false,
      ad_type: val.ad_type,
      approval: val.approval,
      bleed_width: val.bleed_width,
      bleed_height: val.bleed_height,
      deadline_day: val.deadline_day,
      deadline_time: val.deadline_time,
      deadline_price:
        String(val.deadline_price).trim() !== "" ? val.deadline_price : 0,
      discount: val.discount,
      discount_option: val.discount
        ? JSON.stringify(paymentDiscountOptions)
        : JSON.stringify([]),
      active: !val.active,
      premium_option: JSON.stringify([]),

      /** optional */
      width: 0,
      height: 0,
      color_option: JSON.stringify([]),
      color_text_cost: 0,
      bold_cost: 0,
      image_cost: 0,
      border_cost: 0,
      background_cost: 0,
      paper_option: JSON.stringify([]),
      character_option: JSON.stringify([]),
      line_option: JSON.stringify([]),

      character_limit: 0,
      lines_limit: 0,
      account_type: val.account_type == "" ? 0 : val.account_type,
      account_type_price:
        val.account_type == ""
          ? 0.0
          : val.account_type_price
            ? val.account_type_price
            : 0.0,
    };
    // data when type of Ad Type is display
    if (typeOfSelectedAdType === "DISPLAY") {
      data = {
        ...data,
        width: String(val.width).trim() !== "" ? val.width : 0,
        height: String(val.height).trim() !== "" ? val.height : 0,
        color_option: JSON.stringify(displayAdColors),
        premium_option: JSON.stringify(displayAdPremium),
        paper_option: JSON.stringify(paperOption),
      };
    }
    // data when type of Ad Type is classified
    if (typeOfSelectedAdType === "CLASSIFIED") {
      data = {
        ...data,
        color_text_cost:
          fmval.isColoredText && String(val.color_text_cost).trim() !== ""
            ? parseFloat(val.color_text_cost)
            : 0,
        bold_cost:
          fmval.isBold && String(val.bold_cost).trim() !== ""
            ? parseFloat(val.bold_cost)
            : 0,
        image_cost:
          fmval.isAddImage && String(val.image_cost).trim() !== ""
            ? parseFloat(val.image_cost)
            : 0,
        border_cost:
          fmval.isAddBorder && String(val.border_cost).trim() !== ""
            ? parseFloat(val.border_cost)
            : 0,
        background_cost:
          fmval.isAddBackgroundColor &&
            String(val.background_cost).trim() !== ""
            ? parseFloat(val.background_cost)
            : 0,

        character_option: JSON.stringify(displayAdCharacter),
        line_option: JSON.stringify(displayAdLine),
        premium_option: JSON.stringify(displayAdPremium),
        character_limit:
          String(val.character_limit).trim() !== "" ? val.character_limit : 0,
        lines_limit:
          String(val.lines_limit).trim() !== "" ? val.lines_limit : 0,
        isAddLine: val.isAddLine ? val.isAddLine : false,
        isAddCharacter: val.isAddCharacter ? val.isAddCharacter : false,
      };
    }

    let color_price = 0.0;
    let total_price = 0.0;
    if (displayAdColors) {
      for (let i = 0; i < displayAdColors.length; i++) {
        color_price = color_price + parseFloat(displayAdColors[i].cost);
      }
    }

    total_price =
      color_price +
      parseFloat(data.deadline_price) +
      parseFloat(data.account_type_price);

    for (let i = 0; i < paymentDiscountOptions.length; i++) {
      if (paymentDiscountOptions[i].typeOfDiscount == "Fixed Amount") {
        if (
          total_price < parseFloat(paymentDiscountOptions[i].discountAmount)
        ) {
          const errors = {};
          errors.discountAmount =
            "Discount can not be more than the total of ad price and color price";
          toastr.error(
            "Error",
            "Discount can not be more than the total of ad price and color price"
          );
          throw new SubmissionError(errors);
        }
      }
    }

    advertisePlanUpdate(data, (res) => {
      setShowModal(false);

      if (!res.data.showmodal) {
        if (
          String(res.status) === String(STATUS_CODES.CREATED) ||
          res.status === STATUS_CODES.OK
        ) {
          setIsChanged(false);
          setIsSubmitSuccess(true);
          toastr.success("Success", res.data);
          history.replace(`/ourPlans`);
        }
      } else {
        setMessage(res.data.message);

        setShowModal(true);
      }
    });
  };
  let getlineLimit = (value) => {
    setDeadlineLine(value);
  };
  let getdeadlineprice = (value) => {
    setDeadlinePrice(value);
  };

  /**
   * @method handleDeleteDisplayAdColor
   * @description handle delete display Ad color
   * @param {*} disAdCol
   */
  const handleDeleteDisplayAdColor = (disAdCol) => {
    setIsChanged(true);
    const updatedColors = displayAdColors.filter(
      (c) => !(c.color === disAdCol.color && c.cost === disAdCol.cost)
    );
    setDisplayAdColors(updatedColors);
  };

  const handleDeleteDisplayAdCharacter = (disAdChar) => {
    const updatedChar = displayAdCharacter.filter(
      (c) => !(c.character === disAdChar.character && c.cost === disAdChar.cost)
    );
    setDisplayAdCharacter(updatedChar);
  };

  const handleDeleteDisplayAdLine = (disAdLine) => {
    const updatedLine = displayAdLine.filter(
      (c) => !(c.line === disAdLine.line && c.cost === disAdLine.cost)
    );
    setDisplayAdLine(updatedLine);
  };

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values) => {
    setVal(values);
    setfmVal(formValues);
    const selectedEdition =
      editions && editions.length === 1 ? editions[0].id : values.edition_id;
    let classified_conditional_errors = {};
    let display_height_width_errors = {};
    /**
     * checking conditional mandatory fields
     */
    if (values.isColoredText && !values.color_text_cost) {
      classified_conditional_errors.color_text_cost =
        langs.validation_messages.required;
    }
    if (values.isBold && !values.bold_cost) {
      classified_conditional_errors.bold_cost =
        langs.validation_messages.required;
    }
    if (values.isAddImage && !values.image_cost) {
      classified_conditional_errors.image_cost =
        langs.validation_messages.required;
    }
    if (values.isAddBorder && !values.border_cost) {
      classified_conditional_errors.border_cost =
        langs.validation_messages.required;
    }
    if (values.isAddBackgroundColor && !values.background_cost) {
      classified_conditional_errors.background_cost =
        langs.validation_messages.required;
    }
    if (Object.keys(classified_conditional_errors).length !== 0) {
      if (typeOfSelectedAdType === "CLASSIFIED") {
        throw new SubmissionError(classified_conditional_errors);
      }
    }
    if (Object.keys(display_height_width_errors).length !== 0) {
      if (typeOfSelectedAdType === "DISPLAY") {
        throw new SubmissionError(display_height_width_errors);
      }
    }

    /**
     * check and set error if payment discount option is YES but
     * no payment discount option is added
     */
    if (formValues.discount && paymentDiscountOptions.length === 0) {
      setPaymentDiscountOptionError("Please add payment discount options");
      return;
    }

    if (
      values.isAddCharacter == true &&
      typeOfSelectedAdType === "CLASSIFIED" &&
      displayAdCharacter.length === 0
    ) {
      setAddCharacterError("Please add character limit");
      return;
    }
    if (
      values.isAddLine == true &&
      typeOfSelectedAdType === "CLASSIFIED" &&
      displayAdLine.length === 0
    ) {
      setAddLinerError("Please add line limit");
      return;
    }
    // formated date to send
    let data = {
      id: values.id,
      npc_id: user_detail.newspaper.id,
      edition_id: selectedEdition ? selectedEdition : 0,
      edition_name: editionsName,
      bleed_needed: values.bleed_needed ? values.bleed_needed : 0,
      isAddLine: false,
      isAddCharacter: false,
      ad_type: values.ad_type,
      approval: values.approval,
      bleed_width: values.bleed_width,
      bleed_height: values.bleed_height,
      deadline_day: values.deadline_day,
      deadline_time: values.deadline_time,
      deadline_price: String(values.deadline_price).trim() !== "" ? values.deadline_price : 0,
      discount: values.discount,
      discount_option: values.discount
        ? JSON.stringify(paymentDiscountOptions)
        : JSON.stringify([]),
      active: !values.active,
      premium_option: JSON.stringify([]),
      version: planVersion.length > 0 && planVersion[0].version,
      versionId: values.versionId,

      /** optional */
      width: 0,
      height: 0,
      color_option: JSON.stringify([]),
      color_text_cost: 0,
      bold_cost: 0,
      image_cost: 0,
      border_cost: 0,
      background_cost: 0,
      paper_option: JSON.stringify([]),
      character_option: JSON.stringify([]),
      line_option: JSON.stringify([]),

      character_limit: 0,
      lines_limit: 0,
      account_type: values.account_type == "" ? 0 : values.account_type,
      account_type_price:
        values.account_type == ""
          ? 0.0
          : values.account_type_price
            ? values.account_type_price
            : 0.0,
    };
    // data when type of Ad Type is display
    if (typeOfSelectedAdType === "DISPLAY") {
      data = {
        ...data,
        width: String(values.width).trim() !== "" ? values.width : 0,
        height: String(values.height).trim() !== "" ? values.height : 0,
        color_option: JSON.stringify(displayAdColors),
        premium_option: JSON.stringify(displayAdPremium),
        paper_option: JSON.stringify(paperOption),
      };
    }
    // data when type of Ad Type is classified
    if (typeOfSelectedAdType === "CLASSIFIED") {
      data = {
        ...data,
        color_text_cost:
          formValues.isColoredText &&
            String(values.color_text_cost).trim() !== ""
            ? parseFloat(values.color_text_cost)
            : 0,
        bold_cost:
          formValues.isBold && String(values.bold_cost).trim() !== ""
            ? parseFloat(values.bold_cost)
            : 0,
        image_cost:
          formValues.isAddImage && String(values.image_cost).trim() !== ""
            ? parseFloat(values.image_cost)
            : 0,
        border_cost:
          formValues.isAddBorder && String(values.border_cost).trim() !== ""
            ? parseFloat(values.border_cost)
            : 0,
        background_cost:
          formValues.isAddBackgroundColor &&
            String(values.background_cost).trim() !== ""
            ? parseFloat(values.background_cost)
            : 0,

        character_option: JSON.stringify(displayAdCharacter),
        line_option: JSON.stringify(displayAdLine),
        premium_option: JSON.stringify(displayAdPremium),
        character_limit:
          String(values.character_limit).trim() !== ""
            ? values.character_limit
            : 0,
        lines_limit:
          String(values.lines_limit).trim() !== "" ? values.lines_limit : 0,
        isAddLine: values.isAddLine ? values.isAddLine : false,
        isAddCharacter: values.isAddCharacter ? values.isAddCharacter : false,
      };
    }

    let color_price = 0.0;
    let total_price = 0.0;
    if (displayAdColors) {
      for (let i = 0; i < displayAdColors.length; i++) {
        color_price = color_price + parseFloat(displayAdColors[i].cost);
      }
    }

    total_price =
      color_price +
      parseFloat(data.deadline_price) +
      parseFloat(data.account_type_price);

    for (let i = 0; i < paymentDiscountOptions.length; i++) {
      if (paymentDiscountOptions[i].typeOfDiscount == "Fixed Amount") {
        if (
          total_price < parseFloat(paymentDiscountOptions[i].discountAmount)
        ) {
          const errors = {};
          errors.discountAmount =
            "Discount can not be more than the total of ad price and color price";
          toastr.error(
            "Error",
            "Discount can not be more than the total of ad price and color price"
          );
          throw new SubmissionError(errors);
        }
      }
    }
    // update a plan action
    updatePlan(data, (res) => {
      if (!res.data.showmodal) {
        if (
          String(res.status) === String(STATUS_CODES.CREATED) ||
          res.status === STATUS_CODES.OK
        ) {
          setIsChanged(false);
          setIsSubmitSuccess(true);
          toastr.success("Success", res.data);
          history.replace(`/ourPlans`);
        }
      } else {
        setMessage(res.data.message);

        setShowModal(true);
      }
    });
  };

  /**
   * @method fetchPlan
   * @description fetch plan details
   */
  const fetchPlan = () => {
    // request data
    //alert(planId);
    const requestData = {
      id: planId,
      npc_id: user_detail.newspaper.id,
    };

    /** get single plan using same api to get all the plans by sending single plan id */
    getPlanById(requestData, (res) => {

      if (res && String(res.status) === String(200)) {
        const plan = res.data.rows[0];
        const color_options =
          plan.color_option.trim() !== ""
            ? JSON.parse(JSON.parse(plan.color_option))
            : [];
        const paperOption =
          plan.paper_option !== null && plan.paper_option.trim() !== ""
            ? JSON.parse(JSON.parse(plan.paper_option))
            : [];
        const premium_option =
          plan.premium_option.trim() !== ""
            ? JSON.parse(JSON.parse(plan.premium_option))
            : [];
        const discount_options =
          plan.discount_option.trim() !== "" && plan.discount
            ? JSON.parse(JSON.parse(plan.discount_option))
            : [];
        const character_options =
          plan.character_option.trim() !== ""
            ? JSON.parse(JSON.parse(plan.character_option))
            : [];
        const line_options =
          plan.line_option.trim() !== ""
            ? JSON.parse(JSON.parse(plan.line_option))
            : [];
        // set display Ad color options state
        setDisplayAdColors(color_options);
        setDisplayAdCharacter(character_options);
        setDisplayAdLine(line_options);
        setEditionsName(plan.edition_name);
        setDisplayAdPremium(premium_option);
        // set payment discount options state
        setPaymentDiscountOptions(discount_options);
        setShowResults(JSON.parse(plan.bleed_needed));
        setDeadlineCharacter(plan.character_limit);
        setDeadlineLine(plan.lines_limit);
        setDeadlinePrice(plan.deadline_price);
        setAccountTypeName(plan.account_type);
        // setBleedheight(JSON.parse(plan.bleed_height));
        // setBleedwidth(JSON.parse(plan.bleed_width));
        //setEditionsName(JSON.parse(plan.edition_name));
        setPaperOption(paperOption); //new
        if (paperOption.length > 0) {
          // alert('length 1');
          setcheck(true);
        } else {
          // alert('length 0');
          setcheck(false);
        }
        // fetching all versions
        getVersions(plan.versionId, (res) => {
          setPlanVersion(res.data);
          let allPlans = res.data.map(version => parseFloat(version.version));
          letestVersion = allPlans.length > 0 && Math.max(...allPlans);
          if (parseFloat(plan.version) != letestVersion || !plan.active) {
            setDisableFormField(true);
            if (!plan.active)
              setInvalidPlan(true);
          }
        });
        setVersionId(plan.versionId);
        setPlanActivity(plan.active);
        setPlanDeatils({ npc_id: plan.npc_id, id: plan.id, type: 0 });
      } else if (res) {
        setIsRedirect(true);
      }
    });
  };

  const getAllVersions = () => {
    return planVersion && planVersion.map(version => ({ label: `v.${version.version}`, value: version.version })
    )
  }

  /**
   * runs on mount to fetch necessary data
   */
  useEffect(() => {
    getfromNPCSetting("edition", user_detail.newspaper.id, (res) => {
      if (res.status === 200) {
        setEditions(res.data);
      }
    });

    getfromNPCSetting("advertisement", user_detail.newspaper.id, (res) => { });

    getAllAdType("", (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        setAllAddType(allAddType);
      }
    });

    getAllPaperType("0", (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.paper_type_name,
            };
          });
        setPaperTypes(advertisements);
      }
    });
    getAllAccount("0", (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.account_name,
            };
          });
        setAccountType(advertisements);
      }
    });
    // fetching single plan to edit
    fetchPlan();
  }, []);

  /**
   * run when either of any dependency value changes
   */
  useEffect(() => {
    /**
     *  on change of selected Ad Type or change in list of Ad Type
     *  find type of selected Ad Type ad set its state and respected image
     */
    const type = getAdType();
    setTypeOfSelectedAdType(type);
    // advertisementsTypeList
    let selectedAd = allAddtype.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    if (selectedAd) {
      setSelectedAdTypeImage(selectedAd.selectedImage);
    }
  }, [selectedAdType, allAddtype]); // [selectedAdType,advertisementsTypeList]

  /**
   * run when either of any dependency value changes
   */
  useEffect(() => {
    setSelectedAdType(formValues.ad_type);
  }, [formValues.ad_type]);

  /**
   * runs on change in dependency
   */
  useEffect(() => {
    if (!formValues.discount) {
      // setting payment option error field to null if YES is not selected
      setPaymentDiscountOptionError(null);
    }
  }, [formValues.discount]);

  useEffect(() => {
    if (displayAdLine.length == 0) {
      // setting Add line option error field to null if YES is not selected
      setAddLinerError(null);
    }
  }, [displayAdLine]);

  useEffect(() => {
    if (displayAdCharacter.length == 0) {
      // setting Add character option error field to null if YES is not selected
      setAddCharacterError(null);
    }
  }, [displayAdCharacter]);

  /**
   * runs on change in dependency
   */
  useEffect(() => {
    if (!pristine) {
      /**
       * resting cost fields to blank when respected checkbox unchecked
       */
      if (!formValues.isColoredText) {
        changeFieldValue("edit-plan", "color_text_cost", "");
        untouchField("edit-plan", "color_text_cost");
      }
      if (!formValues.isBold) {
        changeFieldValue("edit-plan", "bold_cost", "");
        untouchField("edit-plan", "bold_cost");
      }
      if (!formValues.isAddImage) {
        changeFieldValue("edit-plan", "image_cost", "");
        untouchField("edit-plan", "image_cost");
      }
      if (!formValues.isAddBorder) {
        changeFieldValue("edit-plan", "border_cost", "");
        untouchField("edit-plan", "border_cost");
      }
      if (!formValues.isAddBackgroundColor) {
        changeFieldValue("edit-plan", "background_cost", "");
        untouchField("edit-plan", "background_cost");
      }
    }
  }, [
    formValues.isColoredText,
    formValues.isBold,
    formValues.isAddImage,
    formValues.isAddBorder,
    formValues.isAddBackgroundColor,
  ]);

  if (isRedirect) {
    return (
      <div>
        <NoContentFound />
      </div>
    );
  }

  const addpaperTypes = () => {
    if (paperTypeData !== "" && paperTypeCost !== "") {
      setcheck(true);
      const index = paperTypes.findIndex(
        (obj) => obj.value == parseInt(paperTypeData)
      );
      if (index !== -1) {
        let data = {
          id: paperOption.length + 1,
          label: paperTypes[index].label,
          cost: paperTypeCost,
        };
        setPaperOption([...paperOption, data]);
        changeFieldValue("edit-plan", "paper_type", "");
        changeFieldValue("edit-plan", "paper_cost", "");
        setPaperTypeData("");
        setPaperTypeCost("");
      }
    }
  };

  const deletePaperType = (id) => {
    const index = paperOption.findIndex((obj) => obj.id == id);
    if (index !== -1) {
      setPaperOption(paperOption.filter((item) => item.id !== id));
    }
    if (paperOption.length === 1) {
      setcheck(false);
    }
  };

  return (
    <>
      <h4>
        <Link to="/ourPlans">
          <i className="fa fa-arrow-left mr-3" aria-hidden="true"></i>
        </Link>
        Edit Plan
      </h4>
      <Paper>
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="form-row">
                  {editions && editions.length > 1 ? (
                    <div className="col-md-6">
                      <Field
                        name="edition_id"
                        component={renderSelectField}
                        label="Edition"
                        disabled={disableFormField}
                        options={getEditionOptions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={editions.length > 1 ? false : true}
                        validate={required}
                        required={true}
                        onChange={(e) => getEditionName(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <Field
                        name="edition_id"
                        component={renderText}
                        type="text"
                        validate={[required]}
                        required={true}
                        label="Edition"
                      />
                    </div>
                  )}
                </div>

                <div
                  className={`form-row ${typeOfSelectedAdType === "CLASSIFIED"
                    ? "align-items-center"
                    : ""
                    }`}
                >
                  <div className="col-md-6">
                    <Field
                      name="ad_type"
                      component={renderSelectField}
                      label="Ad Type"
                      disabled={disableFormField}
                      options={getAdTypeOptions()}
                      optionValue={"value"}
                      optionLabel={"label"}
                      validate={required}
                      required={true}
                      value={selectedAdType}
                      onChange={(e) => {
                        setSelectedAdType(e.target.value);
                      }}
                      isSelect={false}
                    />
                  </div>
                  {typeOfSelectedAdType === "CLASSIFIED" && (
                    <>
                      <div className="form-row col-md-6">
                        <div className="col-md-6 mt-2">
                          <Field
                            name="character_limit"
                            label="Character Limit"
                            component={renderNumberField}
                            value={characterLimit}
                            min="0"
                            validate={[number]}
                            helpText={"Only Numeric value"}
                            onChange={(e) => getCharacterLimit(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 mt-2">
                          <Field
                            name="lines_limit"
                            label="Lines Limit"
                            component={renderNumberField}
                            value={lineLimit}
                            min="0"
                            validate={[number]}
                            helpText={"Only Numeric value"}
                            onChange={(e) => getlineLimit(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {typeOfSelectedAdType === "DISPLAY" && (
                    <div className="form-row col-md-6">
                      <div className="col-md-6">
                        <Field
                          name="width"
                          label="Ad Size (Inches)"
                          disabled={disableFormField}
                          component={renderNumberField}
                          required={true}
                          normalize={normalizeCharacterLimit}
                          placeholder="Width"
                          min="0.01"
                          step=".01"
                          helpText={
                            "Only Numeric value (Max 4) with up to 2 decimal places"
                          }
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Field
                          name="height"
                          component={renderNumberField}
                          disabled={disableFormField}
                          normalize={normalizeCharacterLimit}
                          placeholder="Height"
                          min="0.01"
                          step=".01"
                          helpText={
                            "Only Numeric value (Max 4) with up to 2 decimal places"
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-check col-md-6 text-center d-flex justify-content-center align-items-center">
                    <CheckboxRP label="Requires Approval?">
                      <Field
                        name="approval"
                        component="input"
                        disabled={disableFormField}
                        type="checkbox"
                        id="requiresApproval"
                        className="mr-1"
                      />
                    </CheckboxRP>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-3">
                    <Field
                      name="deadline_day"
                      component={renderSelectField}
                      label="Ad Deadline"
                      required={true}
                      disabled={disableFormField}
                      options={getDays()}
                      optionValue={"value"}
                      optionLabel={"label"}
                      validate={required}
                      isSelect={false}
                    />
                  </div>
                  <div className="col-md-3 mt-2">
                    <Field
                      name="deadline_time"
                      component={renderSelectField}
                      options={getTimes()}
                      optionValue={"value"}
                      disabled={disableFormField}
                      optionLabel={"label"}
                      validate={[required]}
                      isSelect={false}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="deadline_price"
                      label="Price"
                      component={renderNumberField}
                      validate={[number]}
                      disabled={disableFormField}
                      required={true}
                      min="0.00"
                      step=".01"
                      helpText={
                        "Only Numeric value with up to 2 decimal places"
                      }
                      onChange={(e) => getdeadlineprice(e.target.value)}
                    />
                  </div>
                </div>

                {typeOfSelectedAdType === "DISPLAY" && (
                  <>
                    <AddColorForm
                      onAddColor={(c) => handleAddDisplayAdColor(c)}
                      disableFormField={disableFormField}
                    />

                    {displayAdColors.length > 0 && (
                      <MakeTable
                        column={["#", "Color", "Paper Type", "Cost", "Action"]}
                      >
                        {displayAdColors.length &&
                          displayAdColors.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{disAdCol.color}</td>
                              <td>{disAdCol.papertype}</td>
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdColor(disAdCol)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )}

                <>
                  <AddPremiumForm
                    onAddPremium={(c) => handleAddDisplayAdPremium(c)}
                    disableFormField={disableFormField}
                  />

                  {displayAdPremium.length > 0 && (
                    <MakeTable column={["#", "Premium", "Cost", "Action"]}>
                      {Array.isArray(displayAdPremium) &&
                        displayAdPremium.map((disAdCol, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{disAdCol.premium}</td>
                            <td>{disAdCol.premium_cost}</td>
                            <td>
                              <span
                                className="btn btn-default btn-sm"
                                onClick={() =>
                                  handleDeleteDisplayAdPremium(disAdCol)
                                }
                              >
                                Delete
                              </span>
                            </td>
                          </tr>
                        ))}
                    </MakeTable>
                  )}
                </>
                {typeOfSelectedAdType === "DISPLAY" && check && (
                  <MakeTable column={["#", "Paper Type", "Cost", "Action"]}>
                    {Array.isArray(paperOption) &&
                      paperOption.map((disAdCol, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{disAdCol.label}</td>
                          <td>{disAdCol.cost}</td>
                          <td>
                            <span
                              className="btn btn-default btn-sm"
                              onClick={() => deletePaperType(disAdCol.id)}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))}
                  </MakeTable>
                )}

                {typeOfSelectedAdType === "CLASSIFIED" && (
                  <>
                    <div className="form-row">
                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isColoredText"
                            component="input"
                            type="checkbox"
                            disabled={disableFormField}
                            id="coloredText"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Colored Text?
                          </label>
                        </div>
                        {formValues.isColoredText && (
                          <Field
                            name="color_text_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isBold"
                            component="input"
                            type="checkbox"
                            disabled={disableFormField}
                            id="bold"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="bold">
                            Bold?
                          </label>
                        </div>
                        {formValues.isBold && (
                          <Field
                            name="bold_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddImage"
                            component="input"
                            type="checkbox"
                            disabled={disableFormField}
                            id="addImage"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addImage"
                          >
                            Add Image?
                          </label>
                        </div>
                        {formValues.isAddImage && (
                          <Field
                            name="image_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddBorder"
                            component="input"
                            disabled={disableFormField}
                            type="checkbox"
                            id="addBorder"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addBorder"
                          >
                            Add Border?
                          </label>
                        </div>
                        {formValues.isAddBorder && (
                          <Field
                            name="border_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddBackgroundColor"
                            component="input"
                            type="checkbox"
                            id="addBackgroundColor"
                            className="form-check-input"
                            disabled={disableFormField}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addBackgroundColor"
                          >
                            Add Background Color?
                          </label>
                        </div>
                        {formValues.isAddBackgroundColor && (
                          <Field
                            name="background_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddCharacter"
                            component="input"
                            type="checkbox"
                            id="AddCharacter"
                            className="form-check-input"
                            disabled={disableFormField}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="AddCharacter"
                          >
                            Add Character?
                          </label>
                        </div>
                      </div>
                    </div>
                    {formValues.isAddCharacter && (
                      <>
                        <AddCharacterForm
                          onAddCharacter={(c) => handleAddDisplayAdCharacter(c)}
                        />
                        {AddCharacterError && (
                          <div className="error text-help mb-2">
                            {AddCharacterError}
                          </div>
                        )}
                        {displayAdCharacter.length > 0 && (
                          <MakeTable
                            column={[
                              "Character limit",
                              "Cost",
                              "Total Character",
                              "Total Cost",
                              "Action",
                            ]}
                          >
                            {displayAdCharacter.length &&
                              displayAdCharacter.map((disAdChar, i) => (
                                <tr key={i}>
                                  <td>{disAdChar.character}</td>
                                  <td>{disAdChar.cost}</td>
                                  <td>
                                    {" "}
                                    {Number(characterLimit) +
                                      Number(disAdChar.character)}{" "}
                                  </td>
                                  <td>
                                    {Number(deadlinePrice) +
                                      Number(disAdChar.cost)}
                                  </td>
                                  <td>
                                    <span
                                      className="btn btn-default btn-sm"
                                      onClick={() =>
                                        handleDeleteDisplayAdCharacter(
                                          disAdChar
                                        )
                                      }
                                    >
                                      Delete
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </MakeTable>
                        )}
                      </>
                    )}

                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddLine"
                            component="input"
                            type="checkbox"
                            disabled={disableFormField}
                            id="Addline"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="AddLine">
                            Add Line?
                          </label>
                        </div>
                      </div>
                    </div>
                    {formValues.isAddLine && (
                      <>
                        <AddLineForm
                          onAddLine={(c) => handleAddDisplayAdLine(c)}
                        />
                        {AddLinerError && (
                          <div className="error text-help mb-2">
                            {AddLinerError}
                          </div>
                        )}
                        {displayAdLine.length > 0 && (
                          <MakeTable
                            column={[
                              "Line limit",
                              "Cost",
                              "Total Line",
                              "Total Cost",
                              "Action",
                            ]}
                          >
                            {displayAdLine.length &&
                              displayAdLine.map((disAdline, i) => (
                                <tr key={i}>
                                  <td>{disAdline.line}</td>
                                  <td>{disAdline.cost}</td>
                                  <td>
                                    {" "}
                                    {Number(lineLimit) +
                                      Number(disAdline.line)}{" "}
                                  </td>
                                  <td>
                                    {Number(deadlinePrice) +
                                      Number(disAdline.cost)}{" "}
                                  </td>
                                  <td>
                                    <span
                                      className="btn btn-default btn-sm"
                                      onClick={() =>
                                        handleDeleteDisplayAdLine(disAdline)
                                      }
                                    >
                                      Delete
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </MakeTable>
                        )}
                      </>
                    )}
                  </>
                )}

                {/*-------------------------------------------- Bleed Option --------- ------------*/}
                <div class="custom-code">
                  <div className="form-row align-items-center mt-4">
                    <div className="form-group col-md-3">
                      <label htmlFor="color">Bleed needed?</label>
                    </div>
                    <div className="form-group col-md-3">
                      <div className="radio-inline">
                        <CheckboxRP label="Yes">
                          <Field
                            name="bleed_needed"
                            onChange={() => {
                              onClickNew();
                            }}
                            disabled={disableFormField}
                            component="input"
                            type="checkbox"
                            id="requiresApproval"
                            className="mr-1"
                          />
                        </CheckboxRP>
                      </div>
                    </div>
                  </div>

                  {showResults && (
                    <div className="form-row align-items-center">
                      <div className="col-md-3" style={{ height: 105 }}>
                        <div className="form-group">
                          <Field
                            name="bleed_width"
                            label="Bleed width (Inches)"
                            component={renderNumberField}
                            validate={[
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            required={true}
                            placeholder="Bleed Width"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3" style={{ height: 105 }}>
                        <div className="form-group">
                          <Field
                            name="bleed_height"
                            label="Bleed height (Inches)"
                            component={renderNumberField}
                            validate={[
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            required={true}
                            placeholder="Bleed Height"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mt-4" style={{ height: 105 }}>
                        <div className="form-group mt-4"></div>
                      </div>
                    </div>
                  )}
                </div>

                {/* ------------------------------Discount Section --------------------------------- */}
                <div className="form-row align-items-center mt-4">
                  <div className="form-group col-md-3">
                    <label htmlFor="color">Payment Discount Option</label>
                  </div>

                  <div className="form-group col-md-3">
                    <div className="radio-inline">
                      <RadioButtonRP label="Yes">
                        <Field
                          component="input"
                          type="radio"
                          name="discount"
                          disabled={disableFormField}
                          label="Yes"
                          value={true}
                          normalize={(value) => value && true}
                          id="paymentDiscountOptionYes"
                        />
                      </RadioButtonRP>
                      <RadioButtonRP label="No">
                        <Field
                          component="input"
                          disabled={disableFormField}
                          type="radio"
                          name="discount"
                          label="No"
                          value={false}
                          normalize={(value) => value && false}
                          id="paymentDiscountOptionNo"
                        />
                      </RadioButtonRP>
                    </div>
                  </div>
                </div>

                {formValues.discount && (
                  <>
                    <AddPaymentDiscountOptionForm
                      displayAdColors={displayAdColors}
                      deadlinePrice={deadlinePrice}
                      onAddPayDiscountOption={(v) =>
                        handleAddDiscountOptions(v)
                      }
                      paymentDiscountOptions={paymentDiscountOptions}
                      disableFormField={disableFormField}
                    />
                    {paymentDiscountOptionError && (
                      <div className="error text-help mb-2">
                        {paymentDiscountOptionError}
                      </div>
                    )}

                    {paymentDiscountOptions.length > 0 && (
                      <MakeTable
                        column={[
                          "#",
                          "Repetition",
                          "Within",
                          "Type of Discount",
                          "Amount",
                          "Action",
                        ]}
                      >
                        {paymentDiscountOptions.length &&
                          paymentDiscountOptions.map((payDisAmt, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{payDisAmt.runs}</td>
                              <td>{payDisAmt.within}</td>
                              <td>{payDisAmt.typeOfDiscount}</td>
                              <td>
                                {payDisAmt.discountAmount}
                                {payDisAmt.typeOfDiscount === "Percentage"
                                  ? "%"
                                  : ""}
                              </td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDiscountOptions(payDisAmt)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )}

                <div className="form-group" style={{ display: "inline-block" }}>
                  <CheckActivity disableFormField={disableFormField} planActivity={planActivity} planDeatils={planDeatils} invalidPlan={invalidPlan} />
                </div>

                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-3">
                      <Field
                        name="allPlanVersion"
                        component={renderSelectField}
                        label="Version"
                        options={getAllVersions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        onChange={(event) => { changeVersions(event.target.value) }}
                        isSelect={planVersion.length > 0 ? true : false}
                      />
                    </div>
                    <div className="col-md-3">
                      <Field
                        name="version_create_date"
                        label="Created Date"
                        component={renderText}
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
                <ButtonRP
                  type="submit"
                  disabled={(isChanged === false && (submitting)) || disableFormField}
                  className="float-right"
                  rounded={false}
                  text="Save"
                />
              </form>
            </div>
          </div>
        </div>
      </Paper>

      <CampaignModal
        isOpen={showModal}
        onCancelClick={cancelDelete}
        onOkClick={confirmDelete}
        keyname={"Plan"}
        message={message}
      />

      <NavigationPrompt
        when={isSubmitSuccess === false && (!pristine || isChanged === true) && !disableFormField}
      >
        {(props) => {
          const { onConfirm, onCancel } = props;
          return (
            <NavigateModal
              show={true}
              alertHeading={"Changes made will be lost."}
              cancleButtonText="Keep Changes"
              okButtonText="Discard Changes"
              onOkClick={() => {
                resetPlan();
                onConfirm();
              }}
              onClickCancel={() => {
                onCancel();
              }}
              showCancel={true}
            />
          );
        }}
      </NavigationPrompt>
    </>
  );
};

/** redux form value selector */
const formValSelector = formValueSelector("edit-plan");
var initialValues;
/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 */
const mapStateToProps = (state) => {
  const { auth, npcDuck } = state;
  const { npcSettingList, npcPlan } = npcDuck;
  const { color, edition, advertisement } = npcSettingList || {};

  const advertisements = advertisement
    ? advertisement.rows[0].advertisements.filter(
      (ad) => ad.is_deleted === false
    )
    : [];

  /** find is the Ad Type is not deleted by super admin*/
  let isAdTypeNotDeleted = undefined;
  if (advertisements.length > 0) {
    isAdTypeNotDeleted = advertisements.find(
      (ad) => parseInt(npcPlan.ad_type) === parseInt(ad.id)
    );
  }

  initialValues = {
    ad_type: "",
    edition_id: edition && edition.length === 1 ? edition[0].name : "",
    paper_type: "",
    paper_cost: "",
  };

  // set initial values if there is a plan
  if (Object.keys(npcPlan).length > 0) {
    initialValues = {
      ...npcPlan,
      ad_type: npcPlan.ad_type,
      active: !npcPlan.active,
      isColoredText: npcPlan.color_text_cost || false,
      isBold: npcPlan.bold_cost || false,
      isAddBorder: npcPlan.border_cost || false,
      isAddImage: npcPlan.image_cost || false,
      isAddBackgroundColor: npcPlan.background_cost || false,
      edition_id: edition && edition.length === 1 ? edition[0].name : npcPlan.edition_id,
      bleed_width: npcPlan.bleed_width || false,
      bleed_height: npcPlan.bleed_height || false,
      character_limit: npcPlan.character_limit,
      lines_limit: npcPlan.lines_limit,
      isAddCharacter: npcPlan.isAddCharacter,
      isAddLine: npcPlan.isAddLine,
      account_type: npcPlan.account_type,
      account_type_price: npcPlan.account_type_price,
      allPlanVersion: npcPlan.version,
      versionId: npcPlan.versionId,
      version_create_date: moment(npcPlan.createdAt).format('YYYY-MM-DD')
    };
  }

  // fetching form fields states
  const formValues = formValSelector(
    state,
    "discount_option",
    "color_option",
    "discount",
    "ad_type",
    "color_text_cost",
    "bold_cost",
    "image_cost",
    "border_cost",
    "background_cost",
    "isColoredText",
    "isBold",
    "isAddBorder",
    "isAddImage",
    "isAddBackgroundColor",
    "character_option",
    "line_option",
    "isAddCharacter",
    "isAddLine"
  );

  return {
    loggedInUser: auth.user,
    initialValues,
    colorList: color ? color : [],
    editionList: edition ? edition : [],
    advertisementList: advertisements,
    formValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
    changeFieldValue,
    untouchField,
  })(
    reduxForm({
      form: "edit-plan", // a unique identifier for this form
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(EditPlan)
  )
);
