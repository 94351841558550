import React from "react";
import Select from "react-select";
import "./FormInput.scss";
import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertISOToUtcDateformate3 } from "../../common";
import { Multiselect } from "multiselect-react-dropdown";

/*
@method: renderLoginTextInputField
@desc: Render login page input
*/
export function renderLoginTextInputField(field) {
  const {
    input,
    meta: { touched, error },
    label,
    ...others
  } = field;
  return (
    <div className="input-form-group email-block">
      <label>{label}</label>
      <div className="inputbox input-group ">
        <input
          maxLength={field.mxlength}
          {...others}
          type="email"
          className={`form-control`}
          {...input}
        />
        <div className="input-group-prepend">
          <span className="input-group-text bg-white">
            <i className="fas fa-envelope mr-1" />
          </span>
        </div>
      </div>
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

export function renderNumberFieldWithPrepend(field) {
  const {
    input,
    meta: { touched, error },
    required,
    prepend = "",
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.value}
        {required && required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <div className="input-group">
        <div className="input-group-prepend" style={{ width: "auto" }}>
          <span className="input-group-text">{prepend}</span>
        </div>
        <input
          type="number"
          maxLength="5"
          {...input}
          {...others}
          className={InputClassName}
        />
      </div>
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderFileInputField
@desc: Render file upload input field
*/
export function renderFileInputField(field) {
  const { input, label, ...others } = field;
  return (
    <div className="input-form-group">
      <label>{label}</label>
      <div className="input-group bdr-btm">
        <input
          maxLength={field.mxlength}
          {...others}
          type="file"
          className={`form-control`}
          {...input}
        />
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary" />
        </div>
      </div>
    </div>
  );
}

/*
@method: renderLoginPasswordInputField
@desc: Render number type input
*/
export function renderLoginPasswordInputField(field) {
  const {
    input,
    meta: { touched, error },
    label,
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className="input-form-group">
      <label>{label}</label>
      <div className="input-group bdr-btm">
        <input
          maxLength={25}
          type="password"
          className={InputClassName}
          {...input}
        />
      </div>
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderCheckboxInputField
@desc: Render radio input
*/
export function renderCheckboxInputField(field) {
  const { input, ...others } = field;
  const InputClassName = `float-left ${field.className ? field.className : ""}`;
  return (
    <div className={InputClassName}>
      <input
        {...others}
        id={field.input.name}
        className="m-0 align-middle"
        {...input}
      />
      <label
        htmlFor={field.input.name}
        className="btn btn-link align-middle checkbox-label"
      >
        {field.label}
      </label>
    </div>
  );
}

export function renderCheckboxInputFieldWithValidation(field) {
  const {
    input,
    meta: { touched, error },
    label,
    ...others
  } = field;
  const InputClassName = `${field.className ? field.className : ""}`;
  return (
    <div className="input-form-group">
      <div className={InputClassName}>
        <input
          {...others}
          id={field.input.name}
          className="m-0 align-middle"
          {...input}
        />
        <label
          htmlFor={field.input.name}
          className="btn btn-link align-middle checkbox-label"
        >
          {label}
        </label>
      </div>
      <div className="text-help float-left ">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderPasswordInputField
@desc: Render password input
*/
export function renderPasswordInputField(field) {
  const {
    input,
    meta: { touched, error, active },
  } = field;
  const inputbox = `inputbox input-group ${active ? "active" : ""}`;
  const className = `form-group ${touched && error ? "has-danger" : ""}`;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  const placeholder = field.placeholder ? field.placeholder : "";
  const isPwdVisible = field.isShowHide === true ? "text" : "password";
  const eyeIcon = field.isShowHide === true ? "fa-eye" : "fa-eye-slash";
  return (
    <div className={className}>
      <label style={{ paddingBottom: 10 }}>
        {field.label}
        {field.required && field.required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <div className={inputbox}>
        <input
          maxLength={field.maxLength}
          // type={isPwdVisible}
          type="password"
          className={InputClassName}
          {...input}
          placeholder={placeholder}
        />
        {/* <Form.Control type="password" placeholder="Password" /> */}

        {field.isEyeIcon === true && (
          <div
            className={`input-group-prepend ${
              field.isShowHide === true ? "hide" : "show"
            }`}
          >
            <span
              onClick={field.showHide}
              className="input-group-text bg-white"
            >
              <i className={`fas ${eyeIcon}`} />
            </span>
          </div>
        )}

        {field.isEyeIcon === false && (
          <div className="input-group-prepend">
            <span className="input-group-text bg-white">
              <i className="fas fa-lock" />
            </span>
          </div>
        )}

        {field.isVarificationForm === true && (
          <div className="input-group-prepend">
            <span className="input-group-text bg-white"></span>
          </div>
        )}
      </div>
      {/* <span>{touched ? error : ''}</span> */}
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderMultiSelectField
@desc: Render multi select input
*/
export function renderMultiSelectField(field) {
  const {
    isTouched,
    meta: { touched, error, active },
  } = field;
  const inputbox = `inputbox ${active ? "active" : ""}`;
  const className = `form-group ${touched && error ? "has-danger" : ""}`;
  const InputClassName = `basic-multi-select ${
    field.className ? field.className : ""
  }`;
  const optionValue = field.optionValue;
  const optionLabel = field.optionLabel;
  const placeholder = field.placeholder ? field.placeholder : "";
  return (
    <div className={className}>
      <label>
        {field.label}
        {field.mendatory && field.mendatory === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <div className={inputbox} onClick={field.onTouched}>
        <Select
          className={InputClassName}
          getOptionLabel={optionLabel}
          getOptionValue={optionValue}
          value={field.selection}
          isMulti
          //  isDisabled={field.options}
          options={field.options}
          classNamePrefix="select"
          closeMenuOnSelect="false"
          onChange={field.selectionChanged}
          placeholder={placeholder}
        />
      </div>
      <div className="text-help">
        {isTouched &&
        field.mendatory &&
        field.selection &&
        field.selection.length === 0
          ? "This field is required."
          : ""}
        {/* <div className="text-help">{field.isEmpty ? 'This field is required.' : ""}</div> */}
      </div>
    </div>
  );
}

/*
@method: renderEmailInputField
@desc: Render email input
*/
export function renderEmailInputField(field) {
  const {
    input,
    isDisabled,
    meta: { touched, error, active },
    ...others
  } = field;
  const disabled = isDisabled === true ? true : false;
  const inputbox = `inputbox input-group ${active ? "active" : ""}`;
  const className = `form-group ${touched && error ? "has-danger" : ""}`;
  const InputClassName = `form-control ${
    disabled ? "disabled-control " : " "
  } ${field.className ? field.className : ""}`;
  return (
    <div className={className}>
      <label>
        {field.label}
        {field.value}
        {field.required && field.required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <div className={disabled ? `${inputbox} disabledEmail ` : inputbox}>
        <input
          maxLength={field.maxLength}
          {...input}
          {...others}
          className={InputClassName}
        />
      </div>
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderTextInputField
@desc: Render text input
*/
export function renderTextInputField(field) {
  const {
    input,
    meta: { touched, error, active },
    ...others
  } = field;
  const inputbox = `inputbox ${active ? "active" : ""}`;
  const className = `form-group ${touched && error ? "has-danger" : ""}`;
  const inputStyle = field.inputStyle ? field.inputStyle : "";
  const inputIconStyle = field.inputIconStyle ? field.inputIconStyle : "";
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className={`${className} ${inputStyle}`}>
      <label>
        {field.label}
        {field.value}
        {field.required && field.required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <div className={inputbox}>
        <input
          maxLength={field.maxLength}
          {...others}
          type="text"
          className={`form-control ${InputClassName}`}
          {...input}
        />
        {field.iconName && (
          <div className="input-group-prepend">
            <span className={`input-group-text ${inputIconStyle}`}>
              <i className={`fas fa-${field.iconName}`} />
            </span>
          </div>
        )}
      </div>
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderSelectField
@desc: Render select input
*/
export function renderSelectField(field) {
  const {
    disabled,
    input,
    meta: { touched, error, active, form },
    required,
  } = field;
  const inputbox = `inputbox ${active ? "active" : ""}`;
  const className = `form-group ${touched && error ? "has-danger" : ""}`;
  const InputClassName = `form-control  ${
    field.className ? field.className : ""
  }`;
  let optionKey = field.optionValue;
  let optionText = field.optionLabel;
  const disabledSelect = disabled ? true : false;
  return (
    <div className={className}>
      <label>
        {field.label}
        {required && required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <div className={inputbox}>
        <select
          disabled={disabledSelect}
          className={InputClassName}
          isMulti={true}
          value={field.Value}
          {...field.input}
        >
          {field.isSelect === false && !field.copiesDistributed && (
            <option value="">Select</option>
          )}
          {field.copiesDistributed && (
            <option value="">How many copies distributed</option>
          )}
          {field.flag && <option value="">{field.custumText}</option>}
          {field.options &&
            field.options.length > 0 &&
            field.options.map((data) => {
              return (
                <option key={data[optionKey]} value={data[optionKey]}>
                  {data[optionText]}
                </option>
              );
            })}
        </select>
      </div>
      {touched && error ? (
        <div className="text-help">{touched ? error : ""}</div>
      ) : (
        ""
      )}
      {touched &&
      required === true &&
      field.input.value === "" &&
      form === "EditSeasonalIssues" ? (
        <div className="text-help mb-2">This field is required.</div>
      ) : (
        ""
      )}
    </div>
  );
}

/*
@method: renderNumberInputField
@desc: Render number input
*/
export function renderDateInputField(field) {
  const {
    input,
    style,
    placeholder,
    meta: { touched, error },
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  delete input.value;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.required && field.required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <input
        {...others}
        style={style}
        //type={field.type}
        className={InputClassName}
        maxLength={field.maxLength}
        value={field.Value}
        onChange={field.input.onChange}
        placeholder={placeholder}
        {...input}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderNumberInputField
@desc: Render number input
*/
export function renderNumberInputField(field) {
  const {
    input,
    style,
    placeholder,
    meta: { touched, error },
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  delete input.value;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.required && field.required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <input
        {...others}
        style={style}
        //type={field.type}
        className={InputClassName}
        maxLength={field.maxLength}
        value={field.Value}
        onChange={field.input.onChange}
        placeholder={placeholder}
        {...input}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderTextAreaField
@desc: Render textarea input
*/
export function renderTextAreaField(field) {
  const {
    required = false,
    input,
    meta: { touched, error },
  } = field;
  const className = `form-group inputbox ${
    touched && error ? "has-danger" : ""
  }`;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;

  const placeholder = field.placeholder ? field.placeholder : "";
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.required && field.required === true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}
      </label>
      <div className="inputbox ">
        <textarea
          required={required}
          maxLength={field.maxLength}
          value={field.value}
          // rows={3}
          className="form-control withoutBorder"
          {...input}
          placeholder={placeholder}
        />
      </div>
      {field.isVisible && (
        <div className="text-help">
          {touched && field.input.value === "" ? "This field is required" : ""}
        </div>
      )}
    </div>
  );
}

/*
@method: focusOnError
@desc: focus on the error input
*/
export const focusOnError = (errors) => {
  if (typeof errors !== "undefined" && errors !== null) {
    const errorEl = document.querySelector(
      Object.keys(errors)
        .map((fieldName) => {
          return `[name="${fieldName}"]`;
        })
        .join(",")
    );

    if (errorEl && errorEl.focus) {
      errorEl.focus();
    }
  }
};

/*******************************
 Updated components
 ********************************/

export function renderText(field) {
  const {
    input,
    meta: { touched, error, form },
    required,
    ...others
  } = field;
  const InputClassName = `form-control  ${
    field.className ? field.className : ""
  }`;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.value}
        {required && required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <input
        maxLength={field.maxLength}
        {...input}
        {...others}
        className={InputClassName}
        placeholder={form === "AddSeasonalIssue" ? "Name*" : ""}
      />
      {error ? (
        <div className="text-help">{touched ? error : ""}</div>
      ) : (
        <div className="text-help">
          {touched && required === true && field.input.value === ""
            ? "This field is required."
            : ""}
        </div>
      )}
    </div>
  );
}

export function renderNumberField(field) {
  const {
    input,
    meta: { touched, error },
    required,
    helpText = "",
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.value}
        {required && required == true ? (
          <span className="asterisk-required"></span>
        ) : (
          ""
        )}{" "}
      </label>
      <input
        type="number"
        maxLength="5"
        {...input}
        {...others}
        className={InputClassName}
      />
      <small style={{ fontSize: 8 }} className="form-text text-muted">
        {helpText}
      </small>
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}
export function renderDatePicker(field) {
  const {
    input,
    placeholder,
    defaultValue,
    value,
    meta: { touched, error, form },
    extraProps,
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;

  return (
    <div className="form-group">
      <label>
        {field.label}
        {field.required &&
        field.required == true &&
        form !== "AddSeasonalIssue" ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <br></br>
      <DatePicker
        selected={input.value ? new Date(input.value) : null}
        minDate={field.minDate !== undefined ? field.minDate : null}
        placeholderText={
          form === "AddSeasonalIssue" &&
          field.input.name === "add_inactive_issue"
            ? "Select date*"
            : "Select Date"
        }
        {...input}
        {...extraProps}
        autoComplete={"off"}
        disabled={field.disabled}
        className={InputClassName}
        onChange={field.input.onChange}
        onChangeRaw={(e) => e.preventDefault()}
      />
      {touched && error ? <div className="text-help mb-2">{error}</div> : ""}
      {touched && field.required === true && input.value === "" ? (
        <div className="text-help mb-2">This field is required.</div>
      ) : (
        ""
      )}
    </div>
  );
}

export function renderDatePickerOfMaterialUI(field) {
  const {
    input,
    disabled,
    meta: { touched, error },
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className="form-control">
      <label>
        {field.label}
        {field.required && field.required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>

      <TextField
        id="date"
        type="date"
        disabled={disabled ? disabled : false}
        {...input}
        className={InputClassName}
        minDate="20/01/2020"
        inputProps={{
          min: field.minDate ? convertISOToUtcDateformate3(field.minDate) : "",
        }}
      />

      {touched ? <div className="text-help mb-2">{error}</div> : ""}
    </div>
  );
}

export function renderDatePickerOfBootstrap(field) {
  const {
    input,
    disabled,
    meta: { touched, error },
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className="form-control">
      <label>
        {field.label}
        {field.required && field.required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>

      <TextField
        id="date"
        type="date"
        disabled={disabled ? disabled : false}
        {...input}
        className={InputClassName}
        minDate="20/01/2020"
        inputProps={{
          min: field.minDate ? convertISOToUtcDateformate3(field.minDate) : "",
        }}
      />

      {touched ? <div className="text-help mb-2">{error}</div> : ""}
    </div>
  );
}

export function renderDatePickerOneDayAgo(field) {
  const {
    input,
    placeholder,
    meta: { touched, error },
  } = field;
  const d = new Date();
  return (
    <div>
      <label>
        {field.label}
        {field.required && field.required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <DatePicker
        {...input}
        dateFormat="MM/dd/yyyy"
        placeholderText={placeholder}
        //maxDate={new Date()}
        minDate={d.setDate(d.getDate() + 1)}
        showMonthDropdown
        showYearDropdown
        readonly="readonly"
        onBlur={() => null}
        selected={input.value ? new Date(input.value) : null}
        className={field.className}
      />
      <div className="text-help mb-2">{touched ? error : ""}</div>
    </div>
  );
}

export const searchableSelect = ({
  input,
  label,
  required,
  handleChangeDescription,
  valueDescription,
  options,
  meta: { touched, error, dirty, visited },
  multi,
  className,
}) => {
  return (
    <div>
      {label && (
        <label>
          {label}
          {required == true ? <span className="asterisk-required">*</span> : ""}
        </label>
      )}
      <Select
        isClearable
        options={options}
        onChange={handleChangeDescription}
        //onCreateOption={handleCreate}
        value={valueDescription}
      />
      {/* <FormHelperText>{touched && error}</FormHelperText> */}
    </div>
  );
};

export function renderThemeText(field) {
  const {
    input,
    type,
    label,
    meta: { touched, error },
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.value}
        {field.required && field.required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <TextField
        maxLength={field.maxLength}
        type={type}
        value={field.Value}
        margin="normal"
        {...input}
        {...others}
        className="kt-width-full"
      />

      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

export function renderText1(field) {
  const {
    input,
    type,
    label,
    meta: { touched, error },
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.value}
        {field.required && field.required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <input
        maxLength={field.maxLength}
        type={type}
        margin="normal"
        {...input}
        {...others}
        value={field.Value}
        className={InputClassName}
      />

      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}

/*
@method: renderNumberInputField
@desc: Render number input
*/
export function renderThemeNumberInput(field) {
  const {
    input,
    style,
    type,
    placeholder,
    meta: { touched, error },
    ...others
  } = field;
  const InputClassName = `form-control ${
    field.className ? field.className : ""
  }`;
  delete input.value;
  return (
    <div className={"form-group"}>
      <label>
        {field.label}
        {field.value}
        {field.required && field.required == true ? (
          <span className="asterisk-required">*</span>
        ) : (
          ""
        )}{" "}
      </label>
      <TextField
        {...others}
        className="kt-width-full"
        maxLength={field.maxLength}
        value={field.Value}
        onChange={field.input.onChange}
        placeholder={placeholder}
        margin="normal"
        {...input}
      />
      <div className="text-help">{touched ? error : ""}</div>
    </div>
  );
}
