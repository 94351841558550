import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import BarChart from "./Chart";
import { getCurrentMonth } from "../../../../../common/CommonFunction";

class FinanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      financeDetail: undefined,
      data: undefined,
      label: getCurrentMonth(),
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutRecords(4, 1, "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: "",
      page: "",
      sort: sort,
      column: column,
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      type: 2,
    };
    this.props.getPayoutOrderListM2(requestData, (res) => {
      this.setState({ data: res.data });
    });
  };

  /**
   * @method renderFinanceRecord
   * @description render finance report data
   */
  renderFinanceRecord = () => {
    const { financeDetail } = this.props;
    const grapfDetail = financeDetail && financeDetail.totalGraphData;
    const total_revenue = grapfDetail && grapfDetail.total_revenue;
    const total_earning = grapfDetail && grapfDetail.paid_amount;
    const total_orders = grapfDetail && grapfDetail.total_orders;
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-12">Total Revenue</div>
              <div className="col-12">
                <h3 style={{ marginBottom: 20 }}>
                  {total_revenue ? `$ ${total_revenue} ` : "$0"}
                </h3>
              </div>

              <div className="col-12">Total Earnings</div>
              <div className="col-12">
                <h3 style={{ marginBottom: 20 }}>
                  {total_earning ? `$ ${total_earning}` : "$0"}
                </h3>
              </div>
              <div
                className="col-12"
                style={{
                  border: "1px solid black",
                  marginRight: "50rem",
                  marginBottom: 10,
                }}
              ></div>

              <div className="col-12">Total Orders</div>
              <div className="col-12">
                <h3>{total_orders}</h3>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            {financeDetail !== undefined && (
              <BarChart
                getPayoutRecords={this.getPayoutRecords}
                onCancel={() => this.setState({ openModal: false })}
                financeDetail={financeDetail}
                openModal={this.state.openModal}
                type={"2"}
                callNext={(label) => this.setState({ label: label })}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    return (
      <div style={{ marginTop: -10 }}>
        <Portlet fluidHeight={true}>
          <PortletBody>
            <div className="row">
              <h4>Finance</h4>
              <hr />
              <h5
                className="cursorPointer"
                onClick={() => this.setState({ openModal: true })}
              >
                {this.state.label}&nbsp;&nbsp;
                <i
                  style={{ fontSize: 20 }}
                  className="fa fa-angle-down"
                  aria-hidden="true"
                ></i>
              </h5>
            </div>
            <div
              className="row"
              style={{ borderBottom: "1px solid black" }}
            ></div>
            <hr />

            {this.renderFinanceRecord()}
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { paymentReportDuck } = store;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    financeDetail: paymentReportDuck.financeDetail,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "FinanceDetails",
    })(FinanceDetails)
  )
);
