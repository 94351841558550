import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, untouch } from "redux-form";
import CodeExample from "../../../../partials/content/CodeExample";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as adManagementDuck from "../../../../store/ducks/super-admin/AdManagement.duck";
import { injectIntl } from "react-intl";
import { Card } from "react-bootstrap";
import { MESSAGES } from "../../../../config/message";
import { langs } from "../../../../config/localization";
import "./AdManagement.scss";
import AddTypeModel from "./AddTypeModel";
import UpdateAdType from "./UpdateAdType";
import ConformModel from "./ConfirmModel";
import ManageCategory from "./manage-category";
import { Row, Col } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { renderText } from "../../../../common/layout/FormInput";
import {
  required,
  gminLength,
  maxLengthC100,
} from "../../../../config/validation";
import { checkPermission } from "../../../../common/CheckPermissions";
import { STATUS_CODES } from "../../../../common/StatusCode";

class ManageAddType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      paperFlag:false,
      accountFlag:false,
      color_name: "",
      paper_type_name:"",
      account_name:"",

    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.apiCalling();
  }

  apiCalling = () => {
    this.props.getAllAdType("", (res) => {});
    this.props.getAllColor((res) => {});
    this.props.getAllPaper((res) => {});
    this.props.getAllAccount((res)=>{});
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    this.props.dispatch(untouch("ManageAddType", "color"));
    this.props.dispatch(untouch("ManageAddType", "paper_type_name"));
    this.props.dispatch(untouch("ManageAddType", "account_name"));
    const { adTypeFlag, colorFlag , paperFlag,accountFlag} = this.state;
    const { user_detail } = this.props.loggedInUser;
    if (adTypeFlag) {
      const deletedItem = this.state.deletedItem;
      let reqData = {
        id: "",
        user_id: user_detail.id,
        advertisement_id: deletedItem.id,
        name: encodeURIComponent(deletedItem.ad_name),
      };
      // console.warn(reqData);
      this.props.deleteAdTypeById(reqData, (res) => {
        
        if (res.status === STATUS_CODES.NO_CONTENT) {
          toastr.success(MESSAGES.DELETE_ADD_TYPE_SUCCESS);
          this.setState({
            isSubmitted: false,
            showConfirmModal: false,
          });
          this.props.getAllAdType("", (res) => {});
        }else{
          this.setState({
            isSubmitted: false,
            showConfirmModal: false,
          });
          this.props.getAllAdType("", (res) => {});
        }
      });
    } else if (colorFlag) {
    
      const deletedItem = this.state.deletedColor;
      this.props.deleteColorById(deletedItem, (res) => {
        if (res.status === STATUS_CODES.NO_CONTENT) {
          toastr.success(MESSAGES.DELETE_COLOR_SUCCESS);
          this.setState({
            isSubmitted: false,
            showConfirmModal: false,
          });
          this.props.getAllColor((res) => {});
        }
      });
    }else if(paperFlag){
     
      
      const deletedItem = this.state.deletedPaper;
      this.props.deletePaperById(deletedItem, (res) => {
        if (res.status === STATUS_CODES.NO_CONTENT) {
          toastr.success(MESSAGES.DELETE_PAPER_SUCCESS);
          this.setState({
            isSubmitted: false,
            showConfirmModal: false,
          });
          this.props.getAllPaper((res) => {});
        }
      });

    }else if(accountFlag){
      const deletedItem = this.state.deletedAccount;
      this.props.deleteAccountById(deletedItem, (res) => {
        if (res.status === STATUS_CODES.NO_CONTENT) {
          toastr.success(MESSAGES.DELETE_ACCOUNT_TYPE_SUCCESS);
          this.setState({
            isSubmitted: false,
            showConfirmModal: false,
          });
          this.props.getAllAccount((res) => {});
        }
      });

    }
  };

  /**
   * @method addColor
   * @description called api to add color
   */
  addColor = (e) => {
    const { user_detail } = this.props.loggedInUser;
    const { color_name } = this.state;
    this.props.dispatch(untouch("ManageAddType", "color"));
    if (
      color_name !== "" &&
      color_name.trim() !== "" &&
      color_name.length >= 2 &&
      color_name.length <= 100
    ) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          color_name: color_name,
          npc_id: user_detail.id,
          prototype_language_id: 0,
        };
        this.setState({ color_name: "" });
        this.props.addColorAPI(requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED) {
            toastr.success(MESSAGES.ADD_COLOR_SUCCESS);
            this.setState({ color_name: "" });
            document.activeElement.blur();
            this.props.getAllColor((res) => {});
            this.props.dispatch(change("ManageAddType", "color", ""));
            this.props.dispatch(untouch("ManageAddType", "color"));
          }
        });
      }
    }
  };

  addaddType = (e) => {
    const { user_detail } = this.props.loggedInUser;
    const { paper_type_name } = this.state;
    this.props.dispatch(untouch("ManageAddType", "paper_type_name"));
    if (
      paper_type_name !== "" &&
      paper_type_name.trim() !== "" &&
      paper_type_name.length >= 2 &&
      paper_type_name.length <= 100
    ) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          paper_type_name: paper_type_name,
          npc_id: user_detail.id,
          prototype_language_id: 0,
        };
        this.setState({ propery_type_name: "" });
        this.props.addPaperTypeAPI(requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED) {
            toastr.success(MESSAGES.ADD_ADDTYPE_SUCCESS);
            this.setState({ propery_type_name: "" });
            document.activeElement.blur();
            this.props.getAllPaper((res) => {});
            this.props.dispatch(change("ManageAddType", "paper_type_name", ""));
            this.props.dispatch(untouch("ManageAddType", "paper_type_name"));
          }
        });
      }
    }
  };
  addAccount = (e) => {
    const { user_detail } = this.props.loggedInUser;
    const { account_name } = this.state;
    this.props.dispatch(untouch("ManageAddType", "account_name"));
    if (
      account_name !== "" &&
      account_name.trim() !== "" &&
      account_name.length >= 2 &&
      account_name.length <= 100
    ) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          account_name: account_name,
          npc_id: user_detail.id,
          prototype_language_id: 0,
        };
        this.setState({ account_name: "" });
        this.props.addAccountAPI(requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED) {
            toastr.success(MESSAGES.ACCOUNT_ADD_SUCCESS);
            this.setState({ account_name: "" });
            document.activeElement.blur();
            this.props.getAllAccount((res) => {});
            this.props.dispatch(change("ManageAddType", "account_name", ""));
            this.props.dispatch(untouch("ManageAddType", "account_name"));
          }
        });
      }
    }
  };

  /**
   * @method renderCardItem
   * @description render adtype card item
   */
  renderCardItem = (typeOfListing, per) => {
    if (Array.isArray(typeOfListing) && typeOfListing.length) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            key={i}
            bg="light"
            style={{
              width: "auto",
              height: "3.5rem",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <Card.Body>
              <Card.Title>
                <label
                  style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      isUpdateModel: true,
                      showConfirmModal: false,
                      selectedItem: data.id,
                    })
                  }
                >
                  {data.ad_name}
                </label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                {per && (
                  <i
                    className="fa fa-close crossIcon"
                    onClick={() =>
                      
                      this.setState({
                        showConfirmModal: true,
                        openModel: false,
                        deletedItem: data,
                        message: MESSAGES.AD_TYPE_DELETE,
                        adTypeFlag: true,
                        colorFlag: false,
                        paperFlag:false,
                        accountFlag:false,
                        count: "",
                      })
                    }
                  ></i>
                )}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };

  /**
   * @method renderCardColorItem
   * @description render the color card item
   */
  renderCardColorItem = (typeOfListing, per) => {
    if (Array.isArray(typeOfListing) && typeOfListing.length) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            key={i}
            bg="light"
            style={{
              width: "auto",
              height: "3.5rem",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <Card.Body>
              <Card.Title>
                <label
                  className="text-truncate"
                  style={{ maxWidth: "97%", whiteSpace: "nowrap" }}
                >
                  {data.color_name}
                </label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                {per && (
                  <i
                    className="fa fa-close crossIcon"
                    onClick={() =>
                      this.setState({
                        showConfirmModal: true,
                        openModel: false,
                        deletedColor: data.id,
                        message: MESSAGES.COLOR_DELETE,
                        colorFlag: true,
                        adTypeFlag: false,
                        paperFlag:false,
                        accountFlag:false,
                        count: data.newspaperCount,
                      })
                    }
                  ></i>
                )}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };
  getValue = () => {
    const { selectedItem } = this.state;
    return { adTypeId: selectedItem };
  };

  renderCardPapreItem = (typeOfListing, per) => {
    if (Array.isArray(typeOfListing) && typeOfListing.length) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            key={i}
            bg="light"
            style={{
              width: "auto",
              height: "3.5rem",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <Card.Body>
              <Card.Title>
                <label
                  className="text-truncate"
                  style={{ maxWidth: "97%", whiteSpace: "nowrap" }}
                >
                  {data.paper_type_name}
                </label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                {per && (
                  <i
                    className="fa fa-close crossIcon"
                    onClick={() =>
                      this.setState({
                        showConfirmModal: true,
                        openModel: false,
                        deletedPaper: data.id,
                        message: MESSAGES.PAPER_DELETE,
                        paperFlag: true,
                        adTypeFlag: false,
                        colorFlag: false,
                        accountFlag:false,
                        count: data.newspaperCount,
                      })
                    }
                  ></i>
                )}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };
  getValue = () => {
    const { selectedItem } = this.state;
    return { adTypeId: selectedItem };
  };

  renderAccountItem = (typeOfListing, per) => {
    if (Array.isArray(typeOfListing) && typeOfListing.length) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            key={i}
            bg="light"
            style={{
              width: "auto",
              height: "3.5rem",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <Card.Body>
              <Card.Title>
                <label
                  className="text-truncate"
                  style={{ maxWidth: "97%", whiteSpace: "nowrap" }}
                >
                  {data.account_name}
                </label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                {per && (
                  <i
                    className="fa fa-close crossIcon"
                    onClick={() =>
                      this.setState({
                        showConfirmModal: true,
                        openModel: false,
                        deletedAccount: data.id,
                        message: MESSAGES.ACCOUNT_DELETE,
                        paperFlag: false,
                        adTypeFlag: false,
                        colorFlag: false,
                        accountFlag:true,
                        count: data.newspaperCount,
                      })
                    }
                  ></i>
                )}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };
  getValue = () => {
    const { selectedItem } = this.state;
    return { adTypeId: selectedItem };
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { adTypeList, colorList,PapertypeList, handleSubmit,accountList } = this.props;
    const {
      openModel,
      isUpdateModel,
      showConfirmModal,
      selectedItem,
      message,
      count,
      showInput,
      showPaperInput,
      showAccountInput,
      
      language,
    } = this.state;
    const adTypeViewPer = checkPermission(
      langs.permissionKeys.adType,
      langs.permissionKeys.view
    );
    const adTypeCreatePer = checkPermission(
      langs.permissionKeys.adType,
      langs.permissionKeys.create
    );
    const adTypedeletePer = checkPermission(
      langs.permissionKeys.adType,
      langs.permissionKeys.delete
    );
    const colorViewPer = checkPermission(
      langs.permissionKeys.manageColor,
      langs.permissionKeys.view
    );
    const colorCreatePer = checkPermission(
      langs.permissionKeys.manageColor,
      langs.permissionKeys.create
    );
    const colordeletePer = checkPermission(
      langs.permissionKeys.manageColor,
      langs.permissionKeys.delete
    );

    const papertypeViewPer = checkPermission(
      langs.permissionKeys.managepapertpe,
      langs.permissionKeys.view
    );
    const papertypeCreatePer = checkPermission(
      langs.permissionKeys.managepapertpe,
      langs.permissionKeys.create
    );
    const papertypedeletePer = checkPermission(
      langs.permissionKeys.managepapertpe,
      langs.permissionKeys.delete
    );

    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Ad Management</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row">
          <div className="col-md-12">
            <CodeExample>
              {!(!adTypeViewPer && !adTypedeletePer && !adTypeCreatePer) && (
                <div>
                  <h5 style={{ color: "#5d5757", marginBottom: "40" }}>
                    Ad Type
                  </h5>
                  <div className="cardWrapper">
                    {adTypeViewPer ? (
                      this.renderCardItem(adTypeList, adTypedeletePer)
                    ) : (
                      <div
                        className="text-center"
                        style={{ marginTop: "10px" }}
                      >
                        <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                      </div>
                    )}
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid #ccc",
                      marginTop: 25,
                      marginLeft: 2,
                    }}
                  >
                    {adTypeCreatePer && (
                      <label
                        style={{
                          color: "black",
                          marginTop: 25,
                          cursor: "pointer",
                        }}
                        onClick={() => this.setState({ openModel: true })}
                      >
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          onClick={() => this.setState({ openModel: true })}
                        ></i>
                        &nbsp;&nbsp;Add More
                      </label>
                    )}
                    <hr />
                  </div>
                </div>
              )}
              {!(!colorCreatePer && !colorViewPer && !colordeletePer) && (
                <div>
                  <h5
                    style={{
                      color: "#5d5757",
                      marginBottom: "40",
                      marginTop: 50,
                    }}
                  >
                    Manage Colors
                  </h5>
                  <div className="cardWrapper">
                    {colorViewPer ? (
                      this.renderCardColorItem(colorList, colordeletePer)
                    ) : (
                      <div
                        className="text-center"
                        style={{ marginTop: "10px" }}
                      >
                        <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                      </div>
                    )}
                  </div>
                  {colorCreatePer && (
                    <div
                      className="row"
                      style={{
                        borderBottom: "1px solid #ccc",
                        marginTop: 25,
                        marginLeft: 2,
                      }}
                    >
                      <label
                        style={{
                          color: "black",
                          marginTop: 25,
                          cursor: "pointer",
                        }}
                        onClick={() => this.setState({ showInput: !showInput })}
                        data-toggle="collapse"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        &nbsp;&nbsp;Add More
                      </label>
                    </div>
                  )}
                  {showInput && (
                    <form
                      noValidate
                      className="form"
                      onSubmit={handleSubmit(this.addColor.bind(this))}
                    >
                      <Row>
                        <Col md="6">
                          <Field
                            name="color"
                            validate={[required, gminLength, maxLengthC100]}
                            value={language}
                            placeholder="Enter Color"
                            component={renderText}
                            onChange={(e) =>
                              this.setState({
                                color_name: e.target.value.trim(),
                              })
                            }
                            onKeyDown={this.addColor}
                          />
                        </Col>
                      </Row>
                    </form>
                  )}
                </div>
              )}

      {/* New Section Added For Admin driven option for Paper type */ }
      {!(!colorCreatePer && !colorViewPer && !colordeletePer) && (
                <div>
                  <h5
                    style={{
                      color: "#5d5757",
                      marginBottom: "40",
                      marginTop: 50,
                    }}
                  >
                    Manage Paper Type
                  </h5>
                  <div className="cardWrapper">
                    {colorViewPer ? (
                      this.renderCardPapreItem(PapertypeList, colordeletePer)
                    ) : (
                      <div
                        className="text-center"
                        style={{ marginTop: "10px" }}
                      >
                        <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                      </div>
                    )}
                  </div>
                  {colorCreatePer && (
                    <div
                      className="row"
                      style={{
                        borderBottom: "1px solid #ccc",
                        marginTop: 25,
                        marginLeft: 2,
                      }}
                    >
                      <label
                        style={{
                          color: "black",
                          marginTop: 25,
                          cursor: "pointer",
                        }}
                        onClick={() => this.setState({ showPaperInput: !showPaperInput })}
                        data-toggle="collapse"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        &nbsp;&nbsp;Add More
                      </label>
                    </div>
                  )}
                  {showPaperInput && (
                    <form
                      noValidate
                      className="form"
                      onSubmit={handleSubmit(this.addaddType.bind(this))}
                    >
                      <Row>
                        <Col md="6">
                          <Field
                            name="paper_type_name"
                            validate={[required, gminLength, maxLengthC100]}
                            value={language}
                            placeholder="Enter Papertype"
                            component={renderText}
                            onChange={(e) =>
                              this.setState({
                                paper_type_name: e.target.value.trim(),
                              })
                            }
                            onKeyDown={this.addaddType}
                          />
                        </Col>
                      </Row>
                    </form>
                  )}
                </div>
              )}
    {/* New Section added Admin driven option for Account Type  */ }
    {!(!colorCreatePer && !colorViewPer && !colordeletePer) && (
                <div>
                  <h5
                    style={{
                      color: "#5d5757",
                      marginBottom: "40",
                      marginTop: 50,
                    }}
                  >
                    Manage Premium
                  </h5>
                  <div className="cardWrapper">
                    {colorViewPer ? (
                      this.renderAccountItem(accountList, colordeletePer)
                    ) : (
                      <div
                        className="text-center"
                        style={{ marginTop: "10px" }}
                      >
                        <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                      </div>
                    )}
                  </div>
                  {colorCreatePer && (
                    <div
                      className="row"
                      style={{
                        borderBottom: "1px solid #ccc",
                        marginTop: 25,
                        marginLeft: 2,
                      }}
                    >
                      <label
                        style={{
                          color: "black",
                          marginTop: 25,
                          cursor: "pointer",
                        }}
                        onClick={() => this.setState({ showAccountInput: !showAccountInput })}
                        data-toggle="collapse"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        &nbsp;&nbsp;Add More
                      </label>
                    </div>
                  )}
                  {showAccountInput && (
                    <form
                      noValidate
                      className="form"
                      onSubmit={handleSubmit(this.addAccount.bind(this))}
                    >
                      <Row>
                        <Col md="6">
                          <Field
                            name="account_name"
                            validate={[required, gminLength, maxLengthC100]}
                            value={language}
                            placeholder="Enter Account Name"
                            component={renderText}
                            onChange={(e) =>
                              this.setState({
                                account_name: e.target.value.trim(),
                              })
                            }
                            onKeyDown={this.addAccount}
                          />
                        </Col>
                      </Row>
                    </form>
                  )}
                </div>
              )}


    {/* End  */ }


              <div style={{ marginTop: 40 }}>
                <ManageCategory />
              </div>
            </CodeExample>
            {openModel && (
              <AddTypeModel
                isOpen={openModel}
                onCancel={() => this.setState({ openModel: false })}
                callList={this.apiCalling}
              />
            )}
            {isUpdateModel && (
              <UpdateAdType
                isOpen={isUpdateModel}
                onCancel={() => this.setState({ isUpdateModel: false })}
                adTypeId={selectedItem}
                callList={this.apiCalling}
                getValues={this.getValue}
              />
            )}
            <ConformModel
              isOpen={showConfirmModal}
              onCancelClick={this.cancelDelete}
              onOkClick={this.confirmDelete}
              message={message}
              totalCount={count}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, adManagementDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    adTypeList: adManagementDuck.adTypeList ? adManagementDuck.adTypeList : [],
    colorList: adManagementDuck.colorList ? adManagementDuck.colorList : [],
    accountList: adManagementDuck.accountList ? adManagementDuck.accountList : [],
    PapertypeList: adManagementDuck.PapertypeList ? adManagementDuck.PapertypeList : [],

  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adManagementDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "ManageAddType",
    })(ManageAddType)
  )
);
