import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Paymentsec = () => {

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
  }, []);
  
  return (
    <React.Fragment>
      <section class="payment_sec">
        <div class="container">
          <div class="inner_payment_sec">
            <div class="row align-items-stretch">
              <div class="col-lg-6 mb-lg-0 mb-5">
                <div class="dolor_sbox pijon_heading">
                  <img src="assets/images/dolor-img.png" alt="" data-aos-delay="300" data-aos="fade-up"  data-aos-duration="1000" />
                  <h4 data-aos-delay="300" data-aos="fade-up"  data-aos-duration="1000">Automatic payment system </h4>
                  <p data-aos-delay="300" data-aos="fade-up"  data-aos-duration="1000">
                    Before an agent reserves his ads, he or his client will have
                    to provide a credit card on file that will automatically be
                    charged when an ad is placed on his behalf. This will cut
                    your billing department’s work in half and reduce the
                    balances that you constantly carry.{" "}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div class="notebook_sbox dolor_sbox pijon_heading">
                  <img src="assets/images/note-book.png" alt="" data-aos-delay="300" data-aos="fade-up"  data-aos-duration="1000" />
                  <h4 data-aos-delay="300" data-aos="fade-up"  data-aos-duration="1000">More placement </h4>
                  <p data-aos-delay="300" data-aos="fade-up"  data-aos-duration="1000">
                    Because Pijen simplifies the process and allows agents to
                    view and monitor all their ad campaigns with a clever
                    dashboard, agents are motivated to sell more and place more.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Paymentsec;
