import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import {
  renderSelectField,
  renderText,
  renderDatePicker,
} from "../../../../common/layout/FormInput";
import { normalizePhone } from "../../../../config/validation";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { toastr } from "react-redux-toastr";
import { convertISOToUtcDateformate2 } from "../../../../common";
import { MESSAGES } from "../../../../config/message";
import {
  ADTYPE,
  ADFREQUENCY,
  APPROVE_STATUS,
  CANCELLED_STATUS,
  PENDING_STATUS,
  INPROGRESS_STATUS,
  COMPLETED_STATUS,
  REJECTED_STATUS,
  ONHOLD_STATUS,
  PAGES_25,
} from "../../../../config/constant";
import { Loader } from "../../../../common/Loader";
import { ButtonRP } from "../../../../common/Buttons";

class OrderListAdvanceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      status: [
        //Pending, Approved, InProgress, Completed, Rejected, On Hold, Cancelled
        { label: "Pending", value: PENDING_STATUS },
        { label: "Approved", value: APPROVE_STATUS },
        { label: "InProgress", value: INPROGRESS_STATUS },
        { label: "Completed", value: COMPLETED_STATUS },
        { label: "Rejected", value: REJECTED_STATUS },
        { label: "On Hold", value: ONHOLD_STATUS },
        { label: "Cancelled", value: CANCELLED_STATUS },
      ],
      adTypeList: ADTYPE,
      adfrequencyList: ADFREQUENCY,
      page: 1,
      pageSize: PAGES_25,
      loading: false,
    };
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderList = (row, page, sort, column, searchKey) => {
    let requestData = {
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      searchKey: searchKey,
      status: "",
      customer_id: "",
    };
    this.props.getAllOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ orderList: res.data.rows });
      }
    });
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    if (Object.keys(values).length === 0) {
      //do
      return toastr.warning(MESSAGES.PLEASE_SELECT_FILTER);
    }
    const { startDate, endDate } = this.state;
    if (
      (values.to_order_date && !values.from_order_date) ||
      (values.from_order_date && !values.to_order_date)
    ) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    } else if (
      (values.to_price && !values.from_price) ||
      (values.from_price && !values.to_price)
    ) {
      return toastr.error(MESSAGES.PRICE_VALIDATION);
    } else if (Number(values.from_price) > Number(values.to_price)) {
      return toastr.warning(MESSAGES.PRICE_RANGE_VALIDATION);
    }
    const requestData = {
      id: values.id ? values.id : "",
      newspaper_name: values.newspaper_name
        ? encodeURIComponent(values.newspaper_name)
        : "",
      customer_name: values.customer_name
        ? encodeURIComponent(values.customer_name)
        : "",
      status: values.status && values.status !== -1 ? values.status : "",
      ad_type: values.ad_type && values.ad_type !== -1 ? values.ad_type : "",
      ad_frequency:
        values.ad_frequency && values.ad_frequency !== -1
          ? values.ad_frequency
          : "",
      from_order_date: values.from_order_date
        ? convertISOToUtcDateformate2(startDate)
        : "",
      to_order_date: values.to_order_date
        ? convertISOToUtcDateformate2(endDate)
        : "",
      from_price: values.from_price
        ? encodeURIComponent(values.from_price)
        : "",
      to_price: values.to_price ? encodeURIComponent(values.to_price) : "",
      plan_name: "",
      npc_id: "",
      from_publish_dates: "",
      to_publish_dates: "",
    };
    this.setState({ loading: true });
    const empty_fields =
      values && Object.keys(values).length === 0 ? true : false;
    this.setState({ loading: true });
    if (!empty_fields) {
      this.props.getOrderListingFromAdvanceSearch(requestData, (res) => {
        this.setState({ loading: false });

        //Bottom scroll
        window.scrollTo(0, document.documentElement.scrollHeight);
      });
    } else {
      this.resetFilter();
    }
  }

  /**
   * @method resetFilter
   * @description  used to reset filter
   */
  resetFilter = () => {
    this.props.reset();
    this.setState({ startDate: "", endDate: "" });
    this.getOrderList(this.state.pageSize, 1, "", "", "");
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit } = this.props;
    const {
      loading,
      startDate,
      endDate,
      status,
      adTypeList,
      adfrequencyList,
    } = this.state;
    return (
      <div className="col-md-12 RP my-5 pb-5">
        <form
          noValidate
          className="form"
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          {loading && <Loader />}
          <Row>
            <Col md="3">
              <Field
                name="id"
                label="Order Id"
                placeholder="Order Id"
                component={renderText}
                normalize={normalizePhone}
              />
            </Col>
            <Col md="3">
              <Field
                name="newspaper_name"
                label="Newspaper Name"
                placeholder="Newspaper Name"
                component={renderText}
              />
            </Col>

            <Col md="3">
              <Field
                name="from_order_date"
                label="From Order Date"
                minDate={endDate}
                onChange={(e) => {
                  this.setState({ startDate: e });
                  this.props.change("to_order_date", "");
                }}
                component={renderDatePicker}
              />
            </Col>
            <Col md="3">
              <Field
                name="to_order_date"
                label="To Order Date"
                minDate={startDate}
                //disabled={startDate === ''}
                onChange={(e) => {
                  this.setState({ endDate: e });
                }}
                component={renderDatePicker}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Field
                name="status"
                label="Order Status"
                options={status}
                isSelect={false}
                onChange={this.onChangeDetailHandeler}
                component={renderSelectField}
                optionValue={"value"}
                optionLabel={"label"}
              />
            </Col>
            <Col md="3">
              <Field
                name="ad_type"
                label="Ad Type"
                options={adTypeList}
                isSelect={false}
                onChange={this.onChangeDetailHandeler}
                component={renderSelectField}
                optionValue={"value"}
                optionLabel={"label"}
              />
            </Col>
            <Col md="3">
              <Field
                name="from_price"
                onChange={this.onChangeDetailHandeler}
                label="From Price Range"
                type="text"
                style={{ minHeight: 40 }}
                placeholder="From Price Range"
                component={renderText}
                normalize={normalizePhone}
              />
            </Col>
            <Col md="3">
              <Field
                name="to_price"
                label="To Price Range"
                style={{ minHeight: 40 }}
                onChange={this.onChangeDetailHandeler}
                placeholder="To Price Range"
                type="text"
                component={renderText}
                normalize={normalizePhone}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Field
                name="customer_name"
                label="Customer Name"
                placeholder="Customer Name"
                component={renderText}
              />
            </Col>
            <Col md="3">
              <Field
                name="ad_frequency"
                label="Ad Frequency"
                options={adfrequencyList}
                isSelect={false}
                onChange={this.onChangeDetailHandeler}
                component={renderSelectField}
                optionValue={"key"}
                optionLabel={"label"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex-center j-end">
                <ButtonRP
                  text="Reset"
                  cancel={true}
                  className="mx-2"
                  onClick={this.resetFilter}
                />
                <ButtonRP type="submit" text="Apply" />
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,

    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "OrderListAdvanceSearch",
      // enableReinitialize: true,
      // destroyOnUnmount: false
    })(OrderListAdvanceSearch)
  )
);
