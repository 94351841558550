import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import "../Chart.scss";
import { Input, Label } from "reactstrap";
import { renderMultiSelectField } from "../../../../../common/layout/FormInput";
import FilterRecords from "../FilterRecords";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { convertISOToUtcDateformate3 } from "../../../../../common";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../../config/message";
import * as companySummaryDuck from "../../../../../store/ducks/super-admin/CompanySummary.duck";
import BlankChart from "../../../../../common/BlankChart";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

let dateRange = [];
let label = getCurrentDate();
class CompareCompanyChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ["Revenue"],
      dateAxis: [],
      type: false,
      d: [],
      totalOrders: ["Orders"],
      linevisible: true,
      barvisible: false,
      openModal: false,
      filterType: 0,
      selectedIds: [],
      selectedAdtype: [],
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
      fromDate: "",
      toDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getGraphData("", 0, 5);
  }

  /**
   * @method getGraphData
   * @description  render graph statical records
   */
  getGraphData = (npcId, salesType, type) => {
    const { initialNpcId } = this.props;
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      npc_id: npcId ? npcId : initialNpcId,
      data: salesType,
      type: type,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.setState({
      salesType: salesType,
      npcId: npcId,
      totalOrders: ["Orders"],
      totalRevenue: ["Revenue"],
    });
    this.setData(requestData, salesType);
  };

  /**
   * @method setData
   * @description set chart records
   */
  setData = (requestData, data) => {
    this.props.getCompanySummaryData(requestData, 7, (res) => {
      if (res.status === STATUS_CODES.OK) {
        const { totalOrders, totalRevenue } = this.state;
        let temp = totalRevenue;
        let temp1 = totalOrders;
        res.data &&
          Array.isArray(res.data.compareData) &&
          res.data.compareData.map((item, i) => {
            if (data === 0) {
              temp.push(item.total_amount);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            } else if (data === 1) {
              temp1.push(item.total_orders);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            }
          });
        if (data === 0) {
          this.setState({
            totalRevenue: [...temp],
            linevisible: true,
            barvisible: false,
            filterType: 0,
            openModal: false,
          });
        } else if (data === 1) {
          this.setState({
            totalOrders: [...temp1],
            barvisible: true,
            linevisible: false,
            filterType: 1,
            openModal: false,
          });
        }
      } else {
        this.setState({ openModal: false });
      }
    });
  };

  /**
   * @method handleFilter
   * @description  used to handle filtered records
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  used to apply filter on graph data
   */
  applyFilter(values, props) {
    const { salesType, npcId } = this.state;
    const { initialNpcId } = this.props;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 5,
      npc_id: npcId ? npcId : initialNpcId,
      data: salesType,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      totalRevenue: ["Revenue"],
      totalOrders: ["Orders"],
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.setData(requestData, salesType);
  }

  /**
   * @method dateAxis
   * @description  used to formate date
   */
  dateAxis = () => {
    return dateRange;
  };

  /**
   * @method revenueSelection
   * @description  used to handle no. of transaction
   */
  revenueSelection = () => {
    const { ncpId } = this.state;
    const { initialNpcId } = this.props;
    let npc_id = ncpId ? ncpId : initialNpcId;
    this.setState(
      {
        totalRevenue: ["Revenue"],
        totalOrders: ["Orders"],
      },
      () => {
        this.getGraphData(npc_id, 0, 5);
      }
    );
  };

  /**
   * @method orderSelection
   * @description  used to handle amount selection
   */
  orderSelection = () => {
    const { ncpId } = this.state;
    const { initialNpcId } = this.props;
    let npc_id = ncpId ? ncpId : initialNpcId;
    this.setState(
      {
        totalOrders: ["Orders"],
        totalRevenue: ["Revenue"],
      },
      () => {
        this.getGraphData(npc_id, 1, 5);
      }
    );
  };

  /**
   * @method handleNpcSelection
   * @description  handle multiple adtype selection
   */
  handleNpcSelection = (e) => {
    const { salesType } = this.state;
    const { initialNpcId } = this.props;
    let temp = [];
    if (e && e.length <= 5) {
      this.setState({ selectedIds: e });
      e &&
        e.map((item, i) => {
          temp.push(item.id);
        });
      if (temp.length) {
        this.setState({ npcId: temp });
        this.getGraphData(temp, salesType, 5);
      } else {
        this.getGraphData(initialNpcId, salesType, 5);
      }
    } else if (e !== null) {
      toastr.warning(MESSAGES.COMPANY_SELECTION_LIMIT_5);
    }
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const {
      selectedOption,
      totalRevenue,
      openModal,
      type,
      totalOrders,
      linevisible,
      barvisible,
    } = this.state;
    const { companyList } = this.props;
    return (
      <div>
        <div className="row my-2">
          <div className="col-md-7 d-flex-center flex-wrap">
            <div className="mr-4 mb-2">Select Company</div>
            <div className="w-100%" style={{ minWidth: 300 }}>
              <Field
                name="company_id"
                placeholder="Select"
                selection={this.state.selectedIds}
                options={companyList && companyList}
                selectionChanged={this.handleNpcSelection}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.newspaper_name}
                component={renderMultiSelectField}
              />
            </div>
          </div>
          <div className="col-md-5 d-flex-center justify-content-md-end">
            <Label className="mr-4">Select Durations</Label>
            <h5
              className="cursorPointer"
              onClick={() => this.setState({ openModal: true })}
            >
              {label}
              &nbsp;&nbsp;
              <i
                style={{ fontSize: 20 }}
                className="fa fa-angle-down"
                aria-hidden="true"
              ></i>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex-center">
            <div className="mr-4">Select Criteria</div>
            <div className="radio-inline">
              <RadioButtonRP label="Revenue">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={!type ? true : ""}
                  onChange={(e) => {
                    this.setState({
                      type: false,
                      linevisible: true,
                      barvisible: false,
                    });
                    this.revenueSelection();
                  }}
                  name="order_type"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Orders">
                <Input
                  type="radio"
                  id="checkbox2"
                  onChange={(e) => {
                    this.setState({
                      type: true,
                      barvisible: true,
                      linevisible: false,
                    });
                    this.orderSelection();
                  }}
                  name="order_type"
                />
              </RadioButtonRP>
            </div>
          </div>
        </div>
        {totalRevenue.length > 1 || totalOrders.length > 1 ? (
          <div style={{ marginTop: 30 }} className="row w-100">
            {linevisible && !barvisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Revenue",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [totalRevenue],
                  type: "line",
                }}
              />
            )}
            {barvisible && !linevisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Orders",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [totalOrders],
                  type: "spline",
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ marginTop: 30 }} className="row w-100">
            <BlankChart />
          </div>
        )}
        {openModal && (
          <FilterRecords
            applyFilter={this.handleFilter}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { companySummaryDuck } = store;
  return {
    loggedInUser: auth.user,
    loading,
    compareData: companySummaryDuck.compareData,
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    companySummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "CompanySummary",
    })(CompareCompanyChart)
  )
);
