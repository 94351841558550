import React, { Fragment } from "react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import DataTable from "./DataTable";
import { toastr } from "react-redux-toastr";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import { RiPencilFill } from "react-icons/ri";
import * as SeasonalIssuesDuck from "../../../../store/ducks/npc/SeasonalIssues.duck";
import AddSeasonalIissue from "./AddSeasonalIissue";
import { MESSAGES } from "../../../../config/message";
import ConformModel from "../../super-admin/ad-management/ConfirmModel";
import { Loader } from "../../../../common/Loader";

class Inactive extends React.Component {
  constructor() {
    super();
    this.state = {
      inactiveIssueList: [],
      modalShow: false,
      deletedItem: "",
      message: "",
      showConfirmModal: false,
      isLoading: false,
    };
  }
  editIssueDate = (id, issueDate) => {
    this.props.getInactiveIssueDateById({
      id: id,
      issueDate: issueDate,
    });
    this.setState({ modalShow: true });
  };
  cancelDelete = () => {
    this.setState({ showConfirmModal: false, deletedItem: "" });
  };
  columns = [
    {
      Header: "Issue date",
      accessor: "issueDate",
      Cell: (row) => {
        const { original } = row;
        return <span>{moment(original.issueDate).format("MM/DD/yyyy")}</span>;
      },
    },
    {
      Header: "Actions",
      accessor: "action",
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="icon_flex_table d-flex align-items-center">
            <MdDelete
              size="20"
              className="mr-2 cursor-pointer"
              onClick={() =>
                this.setState({
                  deletedItem: original.id,
                  showConfirmModal: true,
                  message: MESSAGES.MESSAGE_DELETE_INACTIVE_ISSUE_DATE,
                })
              }
            />
            <RiPencilFill
              size="20"
              className="cursor-pointer"
              onClick={() =>
                this.editIssueDate(
                  original.id,
                  moment(original.issueDate).format("MM/DD/yyyy")
                )
              }
            />
          </div>
        );
      },
    },
  ];

  componentDidMount() {
    this.getAllIssueDate();
  }
  confirmDelete = () => {
    const requestData = {
      isInactive: true,
      id: this.state.deletedItem,
    };
    this.props.deleteSeasonalIssueOverrideEvent(requestData, (res) => {
      if (res) {
        this.getAllIssueDate();
        toastr.success(MESSAGES.DELETE_INACTIVE_ISSUE_DATE);
        this.setState({
          showConfirmModal: false,
        });
      }
    });
  };
  getAllIssueDate() {
    this.setState({ isLoading: true });
    const requestData = {
      isInactive: true,
    };
    this.props.getInactiveIssueDates(requestData, (res) => {
      if (res.data) {
        this.setState({ inactiveIssueList: res.data && res.data.allResult });
        this.setState({ isLoading: false });
      }
    });
  }
  ModalShowHandler = () => {
    this.setState({ modalShow: true })
    document.getElementById("Modalelement").style.overflow = "hidden";
  }
  onHidePopup() {
    this.setState({ modalShow: false });
    document.getElementById("Modalelement").style.overflow = "inherit";
    this.getAllIssueDate();
    this.props.getInactiveIssueDateById({});
  }
  render() {
    const {
      inactiveIssueList,
      modalShow,
      showConfirmModal,
      message,
    } = this.state;
    return (
      <Fragment>
        <Button className="positionable_seasonal_btn" onClick={this.ModalShowHandler}>
          <AiOutlinePlusCircle className="mr-1" />
          Add inactive issue
        </Button>
        <div className="row">
          <div className="col-12 kt-form kt-form--label-right">
            <Portlet>
              <PortletBody>
                <div className="admin_issue_table">
                  {this.state.isLoading ? (
                    <Loader />
                  ) : (
                    <DataTable data={inactiveIssueList} columns={this.columns} />
                  )}
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
        <AddSeasonalIissue
          show={modalShow}
          onHide={() => this.onHidePopup()}
          role="publisher"
        />
        <ConformModel
          isOpen={showConfirmModal}
          onCancelClick={this.cancelDelete}
          onOkClick={this.confirmDelete}
          message={message}
          totalCount={""}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  const { seasonalIssue } = store.SeasonalIssuesDuck;
  return {
    seasonalIssue: seasonalIssue,
  };
};
export default injectIntl(
  connect(mapStateToProps, SeasonalIssuesDuck.actions)(Inactive)
);
