import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import {
    required,
    number,
    greaterThanZeroWithTwoDecimalPlace
} from '../../../../config/validation'
import { renderSelectField, renderNumberField } from '../../../../common/layout/FormInput'
import { toastr } from 'react-redux-toastr'
import { MESSAGES } from '../../../../config/message'

const AddPremiumForm = ({
    onAddPremium,
    handleSubmit,
    reset,
    getfromNPCSetting,
    getAllAccount,
    disableFormField
}) => {

    // colors option list
   // const [accountType, setAccountType] = useState([]);
    const [accountType, setAccountType] = useState([]);
    const [premiums, setPremiums] = useState([]);
    //const [paperTypes, setPaperTypes] = useState([]);

    /**
     * @method getColorOptions
     * @description returns color options for drop down
     */
  

    /**
     * @method handleOnSubmit
     * @description handle on submit form
     * @param {*} values 
     * @param {*} actions 
     */
    const getPremiumOptions = () => {
        return (Array.isArray(premiums) && premiums.length) > 0 ? premiums.map(c => ({
            label: c.account_name,
            value: c.id
        })) : []
    }

    const handleOnSubmit = (values) => {
        
        let premium_cost=values.premium_cost?values.premium_cost:0;
        if((values.account_type!== "")  && (premium_cost!==0))
        {
            
          
            const premium = premiums.find(c => parseInt(c.id) === parseInt(values.account_type));
           
            if(parseInt(values.premium_cost) >= 0 ){
                onAddPremium({ id: premium.id, premium: premium.account_name, premium_cost: values.premium_cost?values.premium_cost:0})
            }else{
                toastr.error("Failure", MESSAGES.NEGATIVE_VALUES);
            } 
            //onAddCharacter({character: values.character, cost: values.cost})
            //const papertype=paperTypes.find(p=>parseInt(p.value)==parseInt(values.papertype));
            //onAddPremium({ id: account_type.id, account_type: account_type.account_type, cost: values.premium_cost})
            reset();
        }
    }

   
    //const [paperTypes, setPaperTypes] = useState([{value:null,label:'select'},{value:1,label:'Glossy'},{value:2,label:'Matte'}]);

    /**
     * Runs on mount to fetch colors options
     */
    useEffect(() => {
        

        

          getAllAccount('0', (res) => {
            if (res.status === 200) {
              //const allAddType = res.data;
              setPremiums(res.data);
              
            }
          });
      
    }, [])

    return (
        <div className="form-row align-items-center">
            <div className="col-md-3">
                    <Field
                      label="Premium"
                      name="account_type"
                      component={renderSelectField}
                      options={getPremiumOptions()}
                      optionValue={"value"}
                      disabled={disableFormField}
                      optionLabel={"label"}
                      isSelect={false}

                    />
                  </div>
            
            <div className="col-md-3 mt-4">
                <div className="form-group">
                    <Field
                        name="premium_cost"
                        component={renderNumberField}
                        disabled={disableFormField}
                        placeholder="Cost"
                        min="0"
                        step=".01"
                        
                        helpText={'Only Numeric value with up to 2 decimal places'}
                    />
                </div>
            </div>
            <div className="col-md-3 mt-4" style={{ height: 98 }}>
                <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={handleSubmit(handleOnSubmit)}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>
    )
}

/**
 * @method mapStateToProps
 * @description map redux store state to props
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        account_type: '',
        premium_cost: 0
    }
})

export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addPremiumForm',
})(AddPremiumForm))

