import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { convertISOToUtcDate } from "../../../../common";
import * as registrationDuck from "../../../../store/ducks/super-admin/Registration.duck";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { Button } from "@material-ui/core";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import DeclineModal from "./DeclineModal";
import AcceptModal from "./AcceptModal";
import { langs } from "../../../../config/localization";
import { checkPermission } from "../../../../common/CheckPermissions";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { ViewIconLink } from "../../../../common/Buttons";

class Listing extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEditFlag: true,
      isRedirect: false,
      rows: [],
      renderNewAdminForm: false,
      customerList: [],
      isConfirm: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      success: false,
      error: false,
      openDeclineModal: false,
      openAcceptModal: false,
      show: false,
      showSearchResults: false,
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.getCustomerList(this.state.pageSize, 1);
  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getCustomerList = (row, page) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 50,
      page: page !== undefined ? page : 1,
      status: 0,
      is_deleted: 0,
    };
    this.props.getCustomerRequestList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          customerList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method onPageChange
   * @description called on change of page
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getCustomerList(this.state.pageSize, page + 1);
  };

  /**
   * @method onPageSizeChange
   * @description called on change of page size
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getCustomerList(pageSize, this.state.page);
    });
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
    } else if (e.target.value.length >= 2) {
      this.setState({ showSearchResults: true });
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 0,
        status: 0,
      };
      this.props.searchInCustomerRequestList(requestData);
    }
  };

  /**
   * @method handleClose
   * @description Pop up handeler before delete records
   */
  handleClose = () => {
    this.setState({ isConfirm: false, show: false });
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Name",
        Cell: (row) => {
          const { original } = row;
          const firstName =
            original &&
            original !== undefined &&
            original.user.first_name[0].toUpperCase() +
              original.user.first_name.slice(1);
          return firstName;
        }, // String-based value accessors!
      },
      {
        Header: "Company Name",
        accessor: "newspaper_name", // String-based value accessors!
      },
      {
        Header: "Email",
        accessor: "user.email",
      },
      {
        Header: "Phone Number",
        width: 150,
        Cell: (row) => {
          const { original } = row;
          let match = original.user.phone_number;
          
          return match;
        },
      },
      {
        Header: "Date of Registration",
        Cell: (row) => {
          const { original } = row;
          let dateOfRejection = original.createdAt;
          return (
            <span>
              {dateOfRejection ? convertISOToUtcDate(dateOfRejection) : ""}
            </span>
          );
        },
      },
      {
        Header: (props) => <span>Actions</span>, // Custom header components!
        Cell: (row) => (
          <div
            style={{
              margin: "0px, 5px, 5px, 5px",
              padding: "0px, 5px, 5px, 5px",
            }}
          >
            <ViewIconLink to={`/viewRequest/${row.original.id}`} />
          </div>
        ),
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { searchResults, requestList } = this.props;
    const {
      showSearchResults,
      openAcceptModal,
      openDeclineModal,
      totalCount,
      customerList,
    } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.newspaperCompanyModeration,
      langs.permissionKeys.view
    );

    let results;
    if (searchResults && searchResults.length && showSearchResults === true) {
      results = searchResults;
    } else if (searchResults === undefined) {
      results = [];
    } else if (searchResults.length === 0 && showSearchResults === true) {
      results = [];
    } else {
      results = requestList;
    }

    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Request Listing</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <DeclineModal
          isOpen={openDeclineModal}
          onCancel={() => this.setState({ openDeclineModal: false })}
        />
        <AcceptModal
          isOpen={openAcceptModal}
          onCancel={() => this.setState({ openAcceptModal: false })}
        />
        <Portlet fluidHeight={true}>
          <PortletBody>
            {viewPer ? (
              <StandardTable
                handelSearch={this.handleSearch}
                columnOrder={this.renderRecords()}
                totalCount={totalCount}
                allData={customerList}
                data={results}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
              />
            ) : (
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
              </div>
            )}
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, registrationDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    requestList: registrationDuck.customerRequestList,
    searchResults: registrationDuck.searchResultsforCustomerRequest,
  };
};

export default injectIntl(
  connect(mapStateToProps, registrationDuck.actions)(Listing)
);
