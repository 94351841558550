import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Portlet, PortletBody } from '../../../../../partials/content/Portlet';
import StandardTable from '../../../StandardTable';
import { STATUS_CODES } from '../../../../../common/StatusCode'
import { langs } from '../../../../../config/localization';
import FooterLoader from '../../../../../common/FooterLoader'
import * as adTypeReportDuck from '../../../../../store/ducks/super-admin/report-management/AdtypeReport'
import {convertISOToUtcDateformate1} from '../../../../../common'

class OrderListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            orderList: [],
            page: 1,
            pageSize: 25,
            sortedOrder: true,
            companyList: [],
            viewAll: false,
            totalCount: 0,
            count: 0,
            entries: 25
        };
    }

    /**
     * @method componentDidMount
     * @description  called after rendering the component 
     */
    componentDidMount() {
        this.getAdtypeGrid(4, 1, '', '');
    }

    /**
    * @method onPageChange
    * @description haldle pagination
    */
    onPageChange = (page) => {
        this.setState({ page: page + 1 });
        this.getAdtypeGrid(this.state.pageSize, 1, '', '');
    }

    /**
    * @method onPageChange
    * @description haldle pagination
    */
    onPageSizeChange = (pageSize) => {
        this.setState({ pageSize: pageSize, entries: pageSize, viewAll: true }, () => {
            this.getAdtypeGrid(this.state.pageSize, 1, '', '');
        });
    }

    /**
    * @method getAdtypeGrid
    * @description Called for getting listing of newspaper company
    */
    getAdtypeGrid = (row, page, sort, column) => {
        let requestData = {
            type:9,
            section:9, 
            row: row !== undefined ? row : 25,
            page: page !== undefined ? page : 1,
            sort:sort,
            column: column,
            localLoading: false,
            ad_type:this.props.match.params.id
        }
        this.props.getAdTypeReportDetail(requestData, res => {})
    }

    /**
      * @method fetchMoreData
      * @description  used to fetch more data from api 
      */
    fetchMoreData = () => {
        const { pageSize, count, entries, viewAll } = this.state;
        const {orderGrid} = this.props;
        if (viewAll) {
            this.setState({ loader: true });
            this.setState({ loader: false })
            if (orderGrid.length) {
                this.setState({ loader: false }, () => {
                    let requestData = {
                        row: pageSize + entries,
                        page: 1,
                        type:9,
                        section:9
                    }
                    orderGrid.length < count && this.props.getAdTypeReportDetail(requestData, res => {
                        if (res.status === STATUS_CODES.OK) {
                            let pages = res.data.adTypeDetails.length / this.state.pageSize;
                            this.setState({ entries: pageSize + entries, localLoading: true, totalCount: Math.ceil(pages), count: res.data.adTypeDetails.length })
                            setTimeout(() => {
                                this.setState({ localLoading: false})
                            }, 3000)
                        }
                    })

                });
            }
        }
    };

    /**
    * @method handleSort
    * @description perform sorting
    */
    handleSort = (e, { sorted }, rowInfo, column, instance) => {
        const { sortedOrder } = this.state;
        instance.sortColumn(column);
        let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC
        this.setState({ sortedOrder: !sortedOrder });
        this.getAdtypeGrid(this.state.pageSize, 1, sortBy, column.id, '')
    };


    /**
      * @method renderRecords
      * @description render all order list
      */
    renderRecords = () => {
        const columnOrder = [
            {
                Header: 'Date of Order',
                accessor: 'createdAt',
                Cell: row => {
                    const { original } = row;
                    return convertISOToUtcDateformate1(original.createdAt);
                } // String-based value accessors!
            },
            {
                Header: 'Order ID',
                accessor: 'order_id',
                Cell: row => {
                    const { original } = row;
                    return (
                        <span>
                            {original.order_id}
                        </span>
                    );
                }
            },
            {
                Header: 'Company Name',
                accessor: 'newspaper_name',
                Cell: ((row) => {
                    const { original } = row;
                    return original.newspaper_name;
                })
            },
            {
                Header: 'Total Sales',
                accessor: 'sales_revenue',
                Cell: ((row) => {
                    const { original } = row;
                    return original.sales_revenue;
                })
            },
            {
                Header: 'Earning to Admin',
                accessor: 'commission_fee',
                Cell: ((row) => {
                    const { original } = row;
                    return original.commission_fee;
                })
            },
            {
                Header: 'Revenue to Admin',
                accessor: 'total_revenue',
                Cell: ((row) => {
                    const { original } = row;
                    return <div style={{ marginLeft: 10 }}>
                        {original.total_revenue}
                    </div>
                })
            },
        ];
        return columnOrder;
    }

    /**
     * @method render
     * @description render the component
     */
    render() {
        const { viewAll, page, pageSize, localLoading } = this.state;
        const { orderGrid } = this.props
        return (
            <div style={{ marginTop: -15 }}>
                <Portlet fluidHeight={true}>
                    <PortletBody className='px-4' ref={el => (this.componentRef = el)}>
                        <InfiniteScroll
                            dataLength={orderGrid.length}
                            next={this.fetchMoreData}
                            loader={localLoading && <h4 style={{ textAlign: 'center' }}>Loading...</h4>}
                            hasMore={true}
                            style={{ overflow: 'unset' }}
                            useWindow={false}
                        >
                            <StandardTable
                                handelSearch={this.handleSearch}
                                columnOrder={this.renderRecords()}
                                handleSort={this.handleSort}
                                sortable={false}
                                onPageSizeChange={this.onPageSizeChange}
                                showSearch={false}
                                data={orderGrid}
                                pagination={false}

                            />
                        </InfiniteScroll>
                        {orderGrid.length > 10 && !viewAll && <div onClick={() => {
                            this.getAdtypeGrid(pageSize, page, '', '');
                            this.setState({ viewAll: true, localLoading: true })
                            setTimeout(() => {
                                this.setState({ localLoading: false })
                            }, 3000)
                        }}>View All</div>}
                        {localLoading && <FooterLoader />}
                    </PortletBody>
                </Portlet>
            </div>
        );
    }
}

const mapStateToProps = store => {
    const { adTypeReportDuck } = store;
    const { loading } = store.commonDuck;
    const { auth } = store;
    const {orderGrid} = adTypeReportDuck
    return ({
        loggedInUser: auth.user,
        loading,
        orderGrid: orderGrid !== undefined && Array.isArray(orderGrid) ? orderGrid : []
    })
};

export default injectIntl(
    connect(
        mapStateToProps,
        adTypeReportDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'OrderListing'
        })(OrderListing)
    )
);
