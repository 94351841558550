import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Fade from "react-reveal/Fade";
import "aos/dist/aos.css";
import AOS from "aos";
import { BsCheck } from "react-icons/bs";

const MonthlyPlan = (props) => {
  const history = useHistory();

  const [dataplan, setDataplan] = useState([]);
  const [winScroll, setWinScroll] = useState(0);

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
    setDataplan(props.data);
    setWinScroll(props.scrollData);
  },);

  const header_height = document.getElementsByClassName("header_sec")[0] && document.getElementsByClassName("header_sec")[0].clientHeight;

  const heading_height = document.getElementsByClassName("heading")[0] && document.getElementsByClassName("heading")[0].clientHeight;

  const tab_height = document.getElementsByClassName("tab_header")[0] && document.getElementsByClassName("tab_header")[0].clientHeight;

  const total_height = header_height + heading_height + tab_height;

  const getHeight = (index, i) => {
    const tag = document.getElementById(`features_${index}_${i}`);
    const elementOffset = tag && tag.offsetTop;
    if ((total_height + elementOffset - 50) > winScroll)
      return true;
    else
      return false;
  }

  return (
      <div className="row">
        {dataplan && dataplan.map((planitem, index) => (
          <div className="col-lg-4" key={index}>
            <div className={planitem.id === 3 ? 'plan_card most-popular-card' : 'plan_card'}>
            {planitem.id === 3 && <div className="top_header"><h5>Most popular</h5></div>}
              <div className="plan_card_header text-center">
                <Typography variant="h3">{planitem.planName}</Typography>
                <Typography variant="h6">{planitem.subPlanName}</Typography>
                <div className="pricesubheader text-center mt-4">
                  <Typography variant="h3">
                    <b>${planitem.monthlyPrice}</b> /month
                  </Typography>
                  <Typography variant="h6">
                    {planitem.monthlySavings}
                  </Typography>
                </div>
              </div>
              <div className="plan_card_content">
                {planitem.allFeatures.map((feature, i) => (
                  <div className="feature-content" key={i}>
                    <BsCheck size="22" />
                    <div className="tooltip-content">
                      <p id={`features_${index}_${i}`} tooltip={feature.tooltip}
                        flow={getHeight(index, i) ? "up" : "down"}
                        dangerouslySetInnerHTML={{ __html: feature.name }}></p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="plan_card_footer text-center">
                <a href={props.newUser?`/email-campaign/auth/new-user-subscription/${planitem.id}/month`:`/email-campaign/auth/agent-subscription/${planitem.id}/month`}>Try for free</a>
              </div>
            </div>
          </div>
        ))}
      </div>
  );
};

export default MonthlyPlan;