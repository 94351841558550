import React from "react";
import { Col } from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";

const Hype = () => {
  const hisory = useHistory();
  const PricingHandler = () => {
    hisory.push('/auth/pricing')
  }
  return (
    <>
      <div className='lp_hype_section'>
        <Container>
          <Row>
            <Col md={12}>
              <Fade duration={1000} delay={400}>
                <div className='hype_conent'>
                  <h2>
                    Jump on the bandwagon and see what all the hype is about!{" "}
                  </h2>
                  <button onClick={PricingHandler}>Start my free trial</button>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hype;
