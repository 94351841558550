import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as orderReportDuck from "../../../../../store/ducks/super-admin/report-management/OrderReport.duck";
import OrderReportList from "./OrderReportList";
import FilterRecords from "../FilterRecords";
import { renderSelectField } from "../../../../../common/layout/FormInput";
import { RANGE } from "../../../../../config/constant";
import OrderTrend from "./OrderTrend";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import CountFigureBox from "../../../../../common/CountFigureBox";
let label = getCurrentDate();

class OrderReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      range: RANGE,
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getOrderReport(1, 1, ""); // getting order header detail
    this.getOrderReport(3, 4, 1); // getting adtype plan detail
    this.getOrderReport(2, 5, 1); // getting newspaper company detail
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderReport = (type, section, data) => {
    let requestData = {
      type: type,
      section: section,
      data: data,
    };
    this.props.getOrderReportDetail(requestData, (res) => {});
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        <h4 className="heading">{`${value}`}</h4>
      </CountFigureBox>
    );
  };

  /**
   * @method renderInnerCard
   * @description  render inner sales content
   */
  renderInnerCard = (label, amount, date, amountLabel) => {
    return (
      <CountFigureBox className="mx-2">
        <h5 className="heading">{label}</h5>
        <h5 className="heading">$ {amount}</h5>
        <h5 className="heading">{date}</h5>
      </CountFigureBox>
    );
  };

  /**
   * @method renderNPCCard
   * @description  render hiegest revenue data
   */
  renderNPCCard = (label, data) => {
    const { range } = this.state;
    return (
      <CountFigureBox>
        <div className="row">
          <div className="col-md-6" style={{ marginTop: 28 }}>
            <h6 className="heading">{label}</h6>
          </div>
          <div className="col-md-6">
            <Field
              name="npc_range"
              options={range}
              style={{ marginTop: "-27px !important" }}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                this.getOrderReport(2, 5, e.target.value);
              }}
              component={renderSelectField}
            />
          </div>
        </div>
        <div className="row" style={{ justifyContent: "space-around" }}>
          {data ? (
            this.renderInnerCard(
              "Order Count",
              data.order_count,
              data.newspaper_name,
              ""
            )
          ) : (
            <h4>No record found</h4>
          )}
          {data ? (
            this.renderInnerCard(
              "Sales",
              data.sales_amount,
              data.newspaper_name,
              "$"
            )
          ) : (
            <h4>No record found</h4>
          )}
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method renderAdTypeCard
   * @description  render lowest revenue data
   */
  renderAdTypeCard = (label, data) => {
    const { range } = this.state;
    return (
      <CountFigureBox>
        <div className="row w-100">
          <div className="col-md-6" style={{ marginTop: 28 }}>
            <h6 className="heading">{label}</h6>
          </div>
          <div className="col-md-6">
            <Field
              name="adtype_range"
              options={range}
              style={{ marginTop: "-27px !important" }}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                this.getOrderReport(3, 4, e.target.value);
              }}
              component={renderSelectField}
            />
          </div>
        </div>
        <div className="row" style={{ justifyContent: "space-around" }}>
          {data ? (
            this.renderInnerCard(
              "Order Count",
              data.order_count,
              data.plan_name,
              ""
            )
          ) : (
            <h4>No record found</h4>
          )}
          {data ? (
            this.renderInnerCard(
              "Sales",
              data.sales_amount,
              data.plan_name,
              "$"
            )
          ) : (
            <h4>No record found</h4>
          )}
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method filterRecord
   * @description  called after rendering the component
   */
  filterRecord = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    label = data.label;
    this.setState({
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.filterDataNPC(data.this_month, data.this_year, data.date, data.date2);
    this.filterDataAdType(
      data.this_month,
      data.this_year,
      data.date,
      data.date2
    );
  }

  /**
   * @method filterDataNPC
   * @description  get npc filter data
   */
  filterDataNPC = (this_month, this_year, date, date2) => {
    const requestData = {
      month: this_month,
      year: this_year,
      type: 2,
      section: 5,
      from_date: date,
      to_date: date2,
    };
    this.props.getOrderReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({
          openModal: false,
        });
      }
    });
  };

  /**
   * @method filterDataAdType
   * @description  get adtype filter data
   */
  filterDataAdType = (this_month, this_year, date, date2) => {
    const requestData = {
      month: this_month,
      year: this_year,
      type: 3,
      section: 4,
      from_date: date,
      to_date: date2,
    };
    this.props.getOrderReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ openModal: false });
      }
    });
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { openModal, selectedOption } = this.state;
    const {
      headerData,
      orderAdTypeDetail,
      orderCompanyDetail,
      loading,
    } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.order,
      langs.permissionKeys.view
    );
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Orders</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              {/* {loading && <Loader/>} */}
              <Portlet fluidHeight={true}>
                <PortletBody>
                  {headerData && (
                    <div className="row my-3">
                      <div className="cardWrapper col-md-4">
                        {headerData ? (
                          this.renderHeaderCard(
                            "Total No of Orders",
                            headerData.total_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>
                      <div className="cardWrapper col-md-4 ">
                        {headerData ? (
                          this.renderHeaderCard(
                            "No. of Orders Pending",
                            headerData.pending_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>

                      <div className="cardWrapper col-md-4 ">
                        {headerData ? (
                          this.renderHeaderCard(
                            "No. of Orders Approved",
                            headerData.approved_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>
                      <div className="cardWrapper col-md-4">
                        {headerData ? (
                          this.renderHeaderCard(
                            "No. of Orders Rejected",
                            headerData.rejected_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>

                      <div className="cardWrapper col-md-4 ">
                        {headerData ? (
                          this.renderHeaderCard(
                            "No. of Orders Inprogress",
                            headerData.progress_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>
                      <div className="cardWrapper col-md-4">
                        {headerData ? (
                          this.renderHeaderCard(
                            "No. of Orders Cancelled",
                            headerData.cancelled_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>

                      <div className="cardWrapper col-md-4 ">
                        {headerData ? (
                          this.renderHeaderCard(
                            "No. of Orders Completed",
                            headerData.completed_orders
                          )
                        ) : (
                          <h4>No record found</h4>
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    <h5
                      className="float-right cursorPointer my-4"
                      onClick={() => this.setState({ openModal: true })}
                    >
                      {label}
                      &nbsp;&nbsp;
                      <i
                        style={{ fontSize: 20 }}
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></i>
                    </h5>
                  </div>
                  <div className="row w-100">
                    <div className="cardWrapper col-md-6">
                      {this.renderNPCCard(
                        "Newspaper Company with",
                        orderCompanyDetail
                      )}
                    </div>
                    <div className="cardWrapper col-md-6">
                      {this.renderAdTypeCard(
                        "Ad type plan with",
                        orderAdTypeDetail
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: 50, minHeight: 450 }}>
                    <OrderTrend />
                  </div>

                  <div style={{ borderBottom: "1px solid grey" }}></div>
                  <OrderReportList />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.REPORT_PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
        {openModal && (
          <FilterRecords
            applyFilter={this.filterRecord}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { orderReportDuck } = store;
  const {
    orderHeaderDetail,
    orderAdTypeDetail,
    orderCompanyDetail,
  } = orderReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    headerData:
      orderHeaderDetail && orderHeaderDetail !== undefined
        ? orderHeaderDetail
        : "",
    orderAdTypeDetail: orderAdTypeDetail && orderAdTypeDetail.planStats,
    orderCompanyDetail: orderCompanyDetail && orderCompanyDetail.npcStats,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    orderReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "OrderReports",
    })(OrderReports)
  )
);
