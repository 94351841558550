import React, { useEffect } from 'react'

import Nav from './Nav'
import ContactForm from './ContactForm';
import ContactAgency from './ContactAgency';
import FooterSec from './landingpage/components/FooterSec';
export default function ContactUs() {


	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	useEffect(() => {
		const script = document.createElement("script");
		script.src =
		  "https://static.zdassets.com/ekr/snippet.js?key=8aa84ff2-cf5f-4c96-ae2a-bfde6bfdbd6f";
		script.async = true;
		script.id = "ze-snippet";
		document.body.appendChild(script);
		return () => {
		  const element = document.getElementById("ze-snippet");
		  element.remove();
		};
	  });
	return (
		<div>
			<Nav />
			<ContactForm />
			<ContactAgency />
			<FooterSec />
		</div>
	)
}
