export const CompanySummaryHeaders = [
    { label: "Company Name", key: "newspaper_name" },
    { label: "Order Count", key: "orders" },
    { label: "Total Sales", key: "sales" },
    { label: "Earning to Admin", key: "commission_fee" }
];

export const CustomerSummaryHeaders = [
    { label: "order#", key: "order_id" },
    { label: "Customer Name", key: "customer_name" },
    { label: "Order Amount", key: "order_count" },
    { label: "Fee", key: "sales" },
    { label: "Revenue", key: "total_revenue" }
];

export const OrderReportHeaders = [
    { label: "Order#", key: "order_id" },
    { label: "Customer Name", key: "full_name" },
    { label: "Newspaper Company", key: "newspaper_name" },
    { label: "Total Order Amount", key: "order_amount" },
    { label: "Payment Status", key: "payment_status" },
    { label: "Order Status", key: "order_status" }
];


export const PayoutRepoStatementHeaders = [
    { label: "", key: "id" },
    { label: "", key: "full_name" },
    { label: "", key: "order_date" },
    { label: "", key: "ad_plan" },
    { label: "", key: "total_revenue" },
    { label: "", key: "commission_fee" }
];

export const SalesRepoHeaders = [
    { label: "Date", key: "sales_date" },
    { label: "Number of Sales", key: "orders" },
    { label: "Sales", key: "sales" },
    { label: "Payout of Newspaper", key: "total_revenue" },
    { label: "Earning to Admin", key: "commission_fee" },
];

export const AdTypeRepoHeaders = [
    { label: "Ad Type Name", key: "ad_name" },
    { label: "Order Count", key: "total_orders" },
    { label: "Total Sales", key: "sales_revenue" },
    { label: "Earning to Admin", key: "commission_fee" },
    { label: "Revenue To Npc", key: "total_revenue" },

];

export const PayoutHistoryHeaders = [
    { label: "Date", key: "payment_date" },
    { label: "Payment Method", key: "payment_method" },
    { label: "Amount", key: "paid_amount" },
    { label: "Statement Id", key: "statement_id" },

];

export const NPCPayoutHeaders = [
    { label: "Order#", key: "id" },
    { label: "Date", key: "order_date" },
    { label: "Ad Plan", key: "adPlan" },
    { label: "Customer", key: "full_name" },
    { label: "Price", key: "price" },
    { label: "Earning", key: "paid_amount" },
    { label: "Status", key: "status" }
];

export const NPCAdtypeHeaders = [
    { key: "ad_name", label: "Ad Plane Name" },
    { key: "total_orders", label: "Order Count" },
    { key: "sales_revenue", label: "Total Sales" },
    { key: "commission_fee", label: "Fees" },
    { key: "total_revenue", label: "Total Revenue" }
];

export const NPCSalesHeaders = [
    { label: "Order#", key: "id" },
    { label: "Customer Name", key: "full_name" },
    { label: "Order Amount", key: "orderAmount" },
    { label: "Fee", key: "commissionFee" },
    { label: "Revenue", key: "totalRevenue" }
];