import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import DataTable from "./DataTable";
import { MdInfo } from "react-icons/md";
import "./subscriptiontable.scss";
import { Button, Modal } from "react-bootstrap";
import SubscriptionInvoice from "./SubscriptionInvoice";
import PlanPopUp from "./PlanPopUp";
import * as SubscriptionDuck from "../../../../store/ducks/super-admin/Subscription.duck";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import "./Subscription.scss";
import "./subscriptiontable.scss";
import ascIcon from "../../../../../_metronic/_assets/media/icons/down-arrow.png";
import descIcon from "../../../../../_metronic/_assets/media/icons/up-arrow.png";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import {
  HiOutlineArrowNarrowUp,
  HiOutlineArrowNarrowDown,
} from "react-icons/hi";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
class CancelledSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      planPopup: false,
      allCancelledSubscriptionsData: [],
      allCancelledSubscriptionsDataDetails: {},
      allActiveSubscriptionsInvoices: [],
      isDownloadingInvoice: [],
      isRedirect: false,
      isRedirectCancel: false
    };
  }

  showSubscriptionDetails(id, subscriptionId) {
    this.props.getActiveSubscriptionsDetails(id, subscriptionId, (res) => {
      this.setState({ allCancelledSubscriptionsDataDetails: res });
    });
    this.setState({ planPopup: true });
  }

  showAllInvoices = (userId) => {
    this.props.getActiveSubscriptionsInvoices(userId, (res) => {
      this.setState({ allActiveSubscriptionsInvoices: res });
      if (res && res.length > 0) {
        res.map((item, key) => {
          this.setState({
            isDownloadingInvoice: [...this.state.isDownloadingInvoice, false],
          });
        });
      }
    });
    this.setState({ modalShow: true });
  };

  columns = [
    {
      Header: "Company Name",
      accessor: "company_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.company_name}</span>;
      },
    },
    {
      Header: "Name",
      accessor: "user_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.user_name}</span>;
      },
    },
    {
      Header: "Plan Type",
      accessor: "plan_name",
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            className={
              original.plan_name === "Premium"
                ? "plan_type premium"
                : original.plan_name === "Plus"
                  ? "plan_type pro"
                  : "plan_type starter"
            }
          >
            {original.plan_name}
          </span>
        );
      },
    },
    {
      Header: "Account Created",
      accessor: "account_created",
      Cell: (row) => {
        const { original } = row;
        return (
          <span>{moment(original.account_created).format("MMM DD, YYYY")}</span>
        );
      },
    },
    {
      Header: "Account Cancelled",
      accessor: "cancleDate",
      Cell: (row) => {
        const { original } = row;
        return (
          original.isTrial ?
          <span>During trial</span> :
          <span>{moment(original.cancleDate).format("MMM DD, YYYY")}</span>
        );
      },
    },
    {
      accessor: "action",
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="invoice_table">
            <span onClick={() => this.showAllInvoices(original.user_id)}>
              View invoices{" "}
            </span>{" "}
            <MdInfo
              onClick={() =>
                this.showSubscriptionDetails(
                  original.user_id,
                  original.subscriptionStatusId
                )
              }
              className="ml-3"
            />
          </div>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getActiveSubscriptions(JSON.stringify(["4"]), (res) => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id");
      if (id) {
        this.setState({ id: id });
        let data = res.find((data) => {
          return data.user_id == id
        });
        if (data) {
          this.showSubscriptionDetails(id, 4)
          this.setState({ allCancelledSubscriptionsData: res });
          this.setState({ isRedirect: false });
        } else {
          this.props.subscriptionStatusCheck(id, (response) => {
            if (response.subscriptionStatusId == 3) this.setState({ isRedirect: true });
            if (response.subscriptionStatusId == 1 || response.subscriptionStatusId == 2) this.setState({ isRedirectCancel: true });
          });
        }
      } else {
        this.setState({ allCancelledSubscriptionsData: res });
        this.setState({ isRedirect: false });
      }
    });
  }

  render() {
    const { loading } = this.props;
    return (
      <>
        {this.state.isRedirect ?
          <Switch>
            <Redirect exact={true} to={`/active-subscription?id=${this.state.id}&subscriptionId=3`} />
          </Switch> :
          this.state.isRedirectCancel ?
            <Switch>
              <Redirect exact={true} to={`/incomplete-subscription`} />
            </Switch> :
            <>
              {loading && <Loader />}
              <LayoutContextConsumer className="row">
                {({ subheader: { title } }) => (
                  <div className="row">
                    <h3 className="kt-subheader__title col-md-6">
                      <span>Cancelled Subscriptions</span>
                    </h3>
                  </div>
                )}
              </LayoutContextConsumer>
              <div className="row">
                <div className="col-12 kt-form subsciption_list_content kt-form--label-right">
                  <Portlet>
                    <PortletBody>
                      <div className="subscription_Table">
                        <DataTable
                          data={this.state.allCancelledSubscriptionsData}
                          columns={this.columns}
                        />
                      </div>
                      <SubscriptionInvoice
                        show={this.state.modalShow}
                        data={this.state.allActiveSubscriptionsInvoices}
                        isDownloadingInvoice={this.state.isDownloadingInvoice}
                        onHide={() => this.setState({ modalShow: false })}
                      />
                      <PlanPopUp
                        show={this.state.planPopup}
                        data={this.state.allCancelledSubscriptionsDataDetails}
                        onHide={() => this.setState({ planPopup: false })}
                      />
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
            </>
        }
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { allActiveSubscriptions } = store.subscriptionDuck;

  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, SubscriptionDuck.actions)(CancelledSubscription)
);
