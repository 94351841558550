import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { API } from '../../config';
import { apiErrors } from '../../common/HandleAPIErrors'
import { CALL_LOADING } from "../../config/constant";

const headers = {
    'Content-Type': 'application/json',
}

export const actionTypes = {
    SetNPCSettingList: "[SetSuperAdminSettingList] Action",
    AllAdTypeList: "[AllAdTypeList] Action",
    SetNPCPlansList: "[SetNPCPlansList] Action",
    SetNPCArchivePlansList: "[SetNPCArchivePlansList] Action",
    RemovePlan: "[RemovePlan] Action",
    RemovePlanFromArchive: "[RemovePlanFromArchive] Action",
    SetNPCSinglePlan: "[SetNPCSinglePlan] Action",
    RemoveNPCSinglePlan: "[RemoveNPCSinglePlan] Action",
    SetSearchResult: "[SetSearchResult] Action",
    SetSearchResultArchive: "[SetSearchResultArchive] Action",
    SetAdvancedSearchResult: "[SetAdvancedSearchResult] Action",
    SetAdvancedSearchResultArchive: "[SetAdvancedSearchResultArchive] Action",
    addDropdown: "[addDropdown] Action",
    addSecondaryEmail: "[addSecondaryEmail] Action",
    SetWhatsNo: "[SetWhatsNo] Action",
    setAllVersions: "[SetAllVersions] Action",
    setAllVersionsForSocial: "[setAllVersionsForSocial] Action",
};

const initialAuthState = {
    npcSettingList: {},
    npcPlansList: [],
    npcArchivePlansList: [],
    npcPlan: {},
    searchResult: [],
    searchResultArchive: [],
    categoryDropdown: {},
    secondaryEmails: [],
    allVersions: []
};

/** set data of API into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get data of individuals type detail by id */
            case actionTypes.SetNPCSettingList: {
                const { apiKey, result } = action.payload;
                let npcSettingList = state.npcSettingList;
                npcSettingList[apiKey] = result;
                npcSettingList = { ...npcSettingList, [apiKey]: npcSettingList[apiKey] }
                return { ...state, npcSettingList };
            }

            case actionTypes.addSecondaryEmail: {
                // //console.warn(">>Payload>>", action.payload)
                return { ...state, secondaryEmails: action.payload }
            }

            /** get all records of adtype  list */
            case actionTypes.AllAdTypeList: {
                const { adTypeList } = action.payload;
                return { ...state, adTypeList: adTypeList };
            }

            /** set plans list case */
            case actionTypes.SetNPCPlansList: {
                const { plansList } = action.payload
                return { ...state, npcPlansList: plansList }
            }

            /** set archive plans list case */
            case actionTypes.SetNPCArchivePlansList: {
                const { plansList } = action.payload
                return { ...state, npcArchivePlansList: plansList }
            }

            /** set plan case */
            case actionTypes.SetNPCSinglePlan: {
                const { plan } = action.payload
                return { ...state, npcPlan: plan }
            }

            /** ---------------------- set dropdown case ---------------------------- */
            case actionTypes.addDropdown: {
                // const { dropdown } = action.payload
                // //console.warn(dropdown)
                return { ...state, categoryDropdown: action.payload }
            }
            /*-------------------------end dropdown case----------------------------- */

            /** reset plan case*/
            case actionTypes.RemoveNPCSinglePlan: {
                return { ...state, npcPlan: {} }
            }


            /** set search result case */
            case actionTypes.SetSearchResult: {
                const { result } = action.payload
                return { ...state, searchResult: result }
            }

            case actionTypes.SetWhatsNo: {
                const { WhatsNo } = action.payload;
                return { ...state, WhatsNo: WhatsNo };
            }

            case actionTypes.setAllVersions: {
                const { allVersions } = action.payload;
                return { ...state, allVersions: allVersions };
            }

            case actionTypes.setAllVersionsForSocial: {
                const { allVersionsForSocial } = action.payload;
                return { ...state, allVersionsForSocial: allVersionsForSocial };
            }

            /** set archive search result case */
            case actionTypes.SetSearchResultArchive: {
                const { result } = action.payload
                return { ...state, searchResultArchive: result }
            }


            /** set advanced search result case */
            case actionTypes.SetAdvancedSearchResult: {
                const { result } = action.payload
                return { ...state, advancedSearchResult: result }
            }

            /** set advanced archive search result case */
            case actionTypes.SetAdvancedSearchResultArchive: {
                const { result } = action.payload
                return { ...state, advancedSearchResultArchive: result }
            }

            /** remove plan from plans list */
            case actionTypes.RemovePlan: {
                const { planId } = action.payload;
                return {
                    ...state,
                    npcPlansList: state.npcPlansList.filter(p => parseInt(p.id) !== parseInt(planId))
                }
            }

            /** remove plan from plans list */
            case actionTypes.RemovePlanFromArchive: {
                const { planId } = action.payload;
                return {
                    ...state,
                    npcArchivePlansList: state.npcArchivePlansList.filter(p => parseInt(p.id) !== parseInt(planId))
                }
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getAllDistributionAreas
    * @description global api 
    */
    getfromNPCSetting: (apiKey, id, callback) => {
        const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.SetNPCSettingList, payload: { apiKey: apiKey, result: response.data } })
            }).catch((error) => {
                // //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
* @method getAllSelectedCategory
* @description get All Selected Category api to get 
*/
    getAllSelectedCategory: (apiKey, id, authToken, callback) => {
        const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.SetNPCSettingList, payload: { apiKey: "selected_Categories", result: response.data } })
            }).catch((error) => {
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
    * @method globalAdd
    * @description add global in a list 
    */
    globalAdd: (apikey, reqData, callback) => {
        const request = axios.post(`${API.generalAPI}/${apikey}`, reqData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
   * @method languageUpdate
   * @description language update 
   */
    languageUpdate: (reqData, callback) => {
        const request = axios.put(`${API.genericAPI}/npc_language`, reqData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
    * @method addCategory
    * @description remove Category in a list 
    */
    addCategory: (apikey, requestData, callback) => {
        const request = axios.post(`${API.generalAPI}/${apikey}`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
      * @method removeCategory
      * @description remove Category in a list 
      */
    removeCategory: (apikey, id, npcId, callback) => {
        // const request = axios.delete(`${API.generalAPI}/${apikey}?id=${id}`, {
        //     headers
        // });
        //changes
        const request = axios.delete(`${API.generalAPI}/${apikey}?id=${id}&newspaperId=${npcId}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    /**
   * @method addEdition
   * @description add addEdition  list 
   */
    addEdition: (apikey, reqData, callback) => {
        const request = axios.post(`${API.generalAPI}/${apikey}`, reqData, { headers });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
            });
        };
    },

    /**
     * @method deleteEdition
     * @description add addEdition  list 
     */
    deleteEdition: (apikey, id, authToken, callback) => {
        const request = axios.delete(`${API.genericAPI}/${apikey}?id=${id}&active=${true}`, { headers });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
            });
        };
    },

    /**
   * @method getAdTypeById
   * @description get ad type by id
   */
    getAdTypeById: (id, npcID, callback) => {
        const request = axios.get(`${API.generalAPI}/advertisementById?id=${id}&newsPaperId=${npcID}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },
    /**
      * @method getAllDistributionAreas
      * @description get all distribution areas list 
      */
    getDistributionAreaFromState: (state, callback) => {
        const request = axios.get(`${API.generalAPI}/distribution_area?state=${state}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
    /**
    * @method addNewCompany
    * @description Add new newspaper company 
    */
    addPaymentCompany: (requestData, callback) => {
        const request = axios.post(API.addNewsPaperCompany, { payment: requestData }, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },

    sendMailToUpdatePaymentDetails: (requestData, callback) => {
        const request = axios.post(API.sendMailToUpdatePaymentDetails, { payment: requestData }, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        };
    },


    /**
     * @method addDistributionArea
     * @description get all distribution areas list 
     */
    addDistributionArea: (reqData, callback) => {
        const request = axios.put(`${API.generalAPI}/company_distribution_area`, reqData, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
    /**
  * @method getDataFromNPCSetting
  * @description global api 
  */
    getDataFromNPCSetting: (apiKey, authToken, callback) => {
        const request = axios.get(`${API.generalAPI}/${apiKey}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCSettingList, payload: { apiKey: apiKey, result: response.data } })
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
 * @method getSelectedLanguage
 * @description get selected language
 */
    getSelectedLanguage: (apiKey, id, callback) => {
        const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCSettingList, payload: { apiKey: "selected_Language", result: response.data } })
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method getAllAdType
     * @description Update admin details 
     */
    getAllAdType: (id, callback) => {
        const request = axios.get(`${API.generalAPI}/advertisement?id=${id}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.AllAdTypeList, payload: { adTypeList: response.data } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    getAllPaperType: (id, callback) => {
        const request = axios.get(`${API.generalAPI}/papertype?id=${id}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.AllAdTypeList, payload: { adTypeList: response.data } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    getAllAccount: (id, callback) => {
        const request = axios.get(`${API.generalAPI}/account?id=0`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.AllAdTypeList, payload: { adTypeList: response.data } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
            });
        }
    },


    /**
     * @method addAdType
     * @description Add Ad type
     */
    addAdType: (requestData, authToken, callback) => {
        const request = axios.post(`${API.generalAPI}/advertisement`, requestData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method deleteAdTypeById
     * @description delete ad type by id
     */
    deleteAdTypeById: (id, adTypeId, callback) => {
        const request = axios.delete(`${API.generalAPI}/advertisement?id=${id}&advertisement_id=${adTypeId}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },


    /**
     * @method getPlansList
     * @description fetch plans list of the NPC
     */
    getPlansList: (requestData, callback) => {
        const { id = '', npc_id, row, page, isDeleted = 0, plan_name = '', sort = 'ASC', column = '' } = requestData;
        const request = axios.get(`${API.advertisePlan}?id=${id}&npc_id=${npc_id}&row=${row}&page=${page}&is_deleted=${isDeleted}&plan_name=${plan_name}&sort=${sort}&column=${column}`, {
            headers
        });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                // dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCPlansList, payload: { plansList: response.data.rows } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                // dispatch({ type: CALL_LOADING })
            });
        };
    },

    getSocialPlansList: (requestData, callback) => {
        const { id = '', npc_id, row, page, isDeleted = 0, ad_type = '', sort = 'ASC', column = '' } = requestData;
        const request = axios.get(`${API.socialMediaPlan}?id=${id}&npc_id=${npc_id}&row=${row}&page=${page}&is_deleted=${isDeleted}&ad_type=${ad_type}&sort=${sort}&column=${column}`);
        // //console.warn(request)
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // //console.warn("Response Social",response.data.rows)

                for (let i = 0; i < response.data.rows.length; i++) {
                    if (response.data.rows[i].is_desktop == false && response.data.rows[i].width != ""
                        && response.data.rows[i].height != "" && response.data.rows[i].m_width == "" && response.data.rows[i].m_height == "" && response.data.rows[i].is_mobile == true) {
                        // //console.warn("Inside conditon")
                        response.data.rows[i].m_width = response.data.rows[i].width;
                        response.data.rows[i].m_height = response.data.rows[i].height;
                        response.data.rows[i].width = "0";
                        response.data.rows[i].height = "0";
                    }
                }

                for (let i = 0; i < response.data.rows.length; i++) {
                    if (response.data.rows[i].m_width == "" && response.data.rows[i].m_height == "") {
                        response.data.rows[i].m_width = "0";
                        response.data.rows[i].m_height = "0";
                    }
                }

                // //console.warn(" After Response Social",response.data.rows)

                callback(response);
                // dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCPlansList, payload: { plansList: response.data.rows } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                //console.warn(error)
                callback(error);
                // dispatch({ type: CALL_LOADING })
            });
        };
    },


    //out of home plans list
    getOutofHome: (requestData, callback) => {
        const { id = '', npc_id, row, page, isDeleted = 0, ad_type = '', sort = 'ASC', column = '' } = requestData;
        const request = axios.get(`${API.outOfHome}?id=${id}&npc_id=${npc_id}&row=${row}&page=${page}&is_deleted=${isDeleted}&ad_type=${ad_type}&sort=${sort}&column=${column}`);
        //console.warn(request)
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // //console.warn(response)
                callback(response);
                // dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCPlansList, payload: { plansList: response.data.rows } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                //console.warn(error)
                callback(error);
                // dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
    * @method getArchivePlansList
    * @description fetch all archive plans list
    */
    getArchivePlansList: (requestData, callback) => {
        const { id = '', npc_id, row, page, isDeleted = 1, plan_name = '', sort = 'ASC', column = '' } = requestData;
        const request = axios.get(`${API.advertisePlan}?id=${id}&npc_id=${npc_id}&row=${row}&page=${page}&is_deleted=${isDeleted}&plan_name=${plan_name}&sort=${sort}&column=${column}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCArchivePlansList, payload: { plansList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
   * @method getArchiveOutOfHome
   * @description fetch all archive plans list
   */
    getArchiveOutOfHome: (requestData, callback) => {
        const { id = '', npc_id, row, page, isDeleted = 1, plan_name = '', sort = 'ASC', column = '' } = requestData;
        const request = axios.get(`${API.outOfHome}?id=${id}&npc_id=${npc_id}&row=${row}&page=${page}&is_deleted=${isDeleted}&plan_name=${plan_name}&sort=${sort}&column=${column}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCArchivePlansList, payload: { plansList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
    * @method getArchiveSocialPlansList
    * @description fetch all archive social plans list
    */
    getArchiveSocialPlansList: (requestData, callback) => {
        const { id = '', npc_id, row, page, isDeleted = 1, ad_type = '', sort = 'ASC', column = '' } = requestData;
        const request = axios.get(`${API.socialMediaPlan}?id=${id}&npc_id=${npc_id}&row=${row}&page=${page}&is_deleted=${isDeleted}&ad_type=${ad_type}&sort=${sort}&column=${column}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {

                for (let i = 0; i < response.data.rows.length; i++) {
                    if (response.data.rows[i].is_desktop == false && response.data.rows[i].width != ""
                        && response.data.rows[i].height != "" && response.data.rows[i].m_width == "" && response.data.rows[i].m_height == "" && response.data.rows[i].is_mobile == true) {
                        //console.warn("Inside conditon")
                        response.data.rows[i].m_width = response.data.rows[i].width;
                        response.data.rows[i].m_height = response.data.rows[i].height;
                        response.data.rows[i].width = "0";
                        response.data.rows[i].height = "0";
                    }
                }

                for (let i = 0; i < response.data.rows.length; i++) {
                    if (response.data.rows[i].m_width == "" && response.data.rows[i].m_height == "") {
                        response.data.rows[i].m_width = "0";
                        response.data.rows[i].m_height = "0";
                    }
                }

                callback(response);

                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCArchivePlansList, payload: { plansList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },


    /**
     * @method getPlanById
     * @description fetch plans list of the NPC
     */
    getPlanById: (requestData, callback) => {
        const { id, npc_id, isDeleted = 0, plan_name = '' } = requestData;
        const request = axios.get(`${API.advertisePlan}?id=${id}&npc_id=${npc_id}&is_deleted=${isDeleted}&plan_name=${plan_name}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCSinglePlan, payload: { plan: response.data.rows[0] } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method getSocialPlanById
     * @description fetch plans list of the NPC
     */
    getSocialPlanById: (requestData, callback) => {
        const {
            id,
            npc_id,
            isDeleted = 0,
            ad_type = ''
        } = requestData;
        const request = axios.get(`${API.socialMediaPlan}?id=${id}&npc_id=${npc_id}&is_deleted=${isDeleted}&ad_type=${ad_type}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //console.warn("Edit Social Response", response.data)
                for (let i = 0; i < response.data.rows.length; i++) {
                    if (response.data.rows[i].is_desktop == false && response.data.rows[i].width != ""
                        && response.data.rows[i].height != "" && response.data.rows[i].m_width == "" && response.data.rows[i].m_height == "" && response.data.rows[i].is_mobile == true) {
                        //console.warn("Inside conditon")
                        response.data.rows[i].m_width = response.data.rows[i].width;
                        response.data.rows[i].m_height = response.data.rows[i].height;
                        response.data.rows[i].width = "0";
                        response.data.rows[i].height = "0";
                    }
                }

                for (let i = 0; i < response.data.rows.length; i++) {
                    if (response.data.rows[i].m_width == "" && response.data.rows[i].m_height == "") {
                        response.data.rows[i].m_width = "0";
                        response.data.rows[i].m_height = "0";
                    }
                }

                callback(response);
                dispatch({ type: CALL_LOADING })
                return dispatch({ type: actionTypes.SetNPCSinglePlan, payload: { plan: response.data.rows[0] } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method resetPlan
     * @description reset single plan in store
     */
    resetPlan: () => {
        return (dispatch) => {
            dispatch({ type: actionTypes.RemoveNPCSinglePlan })
        };
    },


    /**
     * @method deletePlanById
     * @description delete(archive) plan by Id 
     */
    deletePlanById: (planId, callback) => {
        const request = axios.delete(`${API.advertisePlan}?id=${planId}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.RemovePlan, payload: { planId: planId } })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method deleteSocialPlanById
     * @description delete(archive) social plan by Id 
     */
    deleteSocialPlanById: (planId, callback) => {
        const request = axios.delete(`${API.socialMediaPlan}?id=${planId}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.RemovePlan, payload: { planId: planId } })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method activatePlanById
    * @description Activate plan by id
    */
    activatePlanById: (planId, callback) => {
        const request = axios.patch(`${API.advertisePlan}`, { id: planId }, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.RemovePlanFromArchive, payload: { planId: planId } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method activateSocialPlanById
    * @description Activate Social plan by id
    */
    activateSocialPlanById: (planId, callback) => {
        const request = axios.patch(`${API.socialMediaPlan}`, { id: planId }, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.RemovePlanFromArchive, payload: { planId: planId } })

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method addNewPlan
    * @description add new plan
    */
    addNewPlan: (requestData, callback) => {
        const request = axios.post(API.advertisePlan, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },

    sendLandingEmail: (requestData, callback) => {
        console.warn(requestData)
        const request = axios.post(API.sendLandingEmail, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },

    addWhatsappNumber: (requestData, callback) => {
        console.warn(requestData)
        const request = axios.put(API.addWhatsappNumber, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },

    addNewSocialMediaPlan: (requestData, callback) => {
        const request = axios.post(API.socialMediaPlan, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },


    /**
   * @method updatePlan
   * @description Update plan detail 
   */
    updatePlan: (requestData, callback) => {
        let request = axios.put(`${API.advertisePlan}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },
    updatePlanActivity: (requestData, callback) => {
        let request = axios.put(`${API.planActivity}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
   * @method updateSocialPlan
   * @description Update Social plan detail 
   */
    updateSocialPlan: (requestData, callback) => {
        let request = axios.put(`${API.socialMediaPlan}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
  * @method updateSocialPlan
  * @description Demo Testing dropdown category 
  */
    getDropdown: (requestData, callback) => {
        //   //console.warn(">>>Inside action>>>", requestData)
        let request = axios.get(`${API.getCompanyCategory}`, requestData, {
            headers
        });

        return (dispatch) => {
            request.then((response) => {
                // //console.warn(">>respone>>", response)
                dispatch(
                    {
                        type: actionTypes.addDropdown,
                        payload: response.data
                    }
                )
                callback(response);
                // dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                //console.warn(error)
                // apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                // dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
      * @method deleteSocialPlanById
      * @description delete(archive) social plan by Id 
      */
    deleteSecondaryEmailById: (planId, callback) => {
        const request = axios.delete(`${API.adminSecondaryEmail}?id=${planId}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                // return dispatch({ type: actionTypes.RemovePlan, payload: { planId: planId } })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method deleteSocialPlanById
     * @description delete(archive) social plan by Id 
     */
    deleteUnapprovedSecondaryEmailById: (planId, callback) => {
        const request = axios.delete(`${API.adminSecondaryEmail1}?id=${planId}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                // return dispatch({ type: actionTypes.RemovePlan, payload: { planId: planId } })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },


    /**
  * @method updateSocialPlan
  * @description Update Social plan detail 
  */
    addToSecondaryEmailById: (requestData, callback) => {
        let request = axios.put(`${API.adminSecondaryEmail1}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },




    advertisePlanUpdate: (requestData, callback) => {
        let request = axios.put(`${API.advertisePlanUpdate}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    advertisePlanDelete: (requestData, callback) => {
        let request = axios.delete(`${API.advertisePlanUpdate}`, {
            headers,
            data:requestData
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },


    socialPlanUpdate: (requestData, callback) => {
        let request = axios.put(`${API.socialPlanUpdate}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    socialPlanDelete: (requestData, callback) => {
        let request = axios.delete(`${API.socialPlanUpdate}`, {
            headers,
            data: requestData
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                dispatch({ type: CALL_LOADING })
            });
        };
    },





    /**
  * @method getSecondaryEmails
  * @description Demo Testing dropdown category 
  */
    getSecondaryEmails: (requestData, callback) => {
        //   //console.warn(">>>Inside action>>>", requestData)
        const {
            npc_id,
            isDeleted = 0,
            approved = 1
        } = requestData;
        let request = axios.get(`${API.adminSecondaryEmail}?npc_id=${npc_id}&is_deleted=${isDeleted}&approved=${approved}`, requestData, {
            headers
        });

        return (dispatch) => {
            request.then((response) => {
                // //console.warn(">>respone>>", response)
                dispatch(
                    {
                        type: actionTypes.addSecondaryEmail,
                        payload: response.data
                    }
                )
                callback(response);
                // dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                //console.warn(error)
                // apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                // dispatch({ type: CALL_LOADING })
            });
        };
    },


    /**
  * @method getSecondaryEmails
  * @description Demo Testing dropdown category 
  */
    getUnapprovedSecondaryEmails: (requestData, callback) => {

        const {
            approved,
            isDeleted
        } = requestData;
        let request = axios.get(`${API.adminSecondaryEmail1}?approved=${approved}&is_deleted=${isDeleted}`, requestData, {
            headers
        });

        return (dispatch) => {
            request.then((response) => {

                callback(response);

            }).catch((error) => {

            });
        };
    },


    /**
  * @method getSecondaryEmails
  * @description Demo Testing dropdown category 
  */
    getNewspaperById: (requestData, callback) => {
        //   //console.warn(">>>Inside action>>>", requestData)
        const {
            npc_id,
        } = requestData;
        let request = axios.get(`${API.getNewspaperById}?npc_id=${npc_id}`, requestData, {
            headers
        });

        return (dispatch) => {
            request.then((response) => {
                // //console.warn(">>respone>>", response)
                callback(response);
                // dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                //console.warn(error)
                // apiErrors(error, dispatch);
                //tokenCheck(dispatch, error)
                // dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
* @method updateSocialPlan
* @description Demo Testing dropdown category 
*/
    addSecondaryEmails: (requestData, callback) => {
        //console.warn(">>>Inside action>>>", requestData)
        const request = axios.post(API.adminSecondaryEmail, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //console.warn("Response>", response.data)
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    },

    /**
    * @method searchInPlansList
    * @description search API Call to find admin details 
    */
    searchInPlansList: (requestData) => {
        const { id = "", npc_id, plan_name, is_deleted, row = 25, page = 1, } = requestData
        const request = axios.get(`${API.advertisePlan}?id=${id}&npc_id=${npc_id}&plan_name=${plan_name}&is_deleted=${is_deleted}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                if (!is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResult, payload: { result: response.data.rows } })
                } else if (is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResultArchive, payload: { result: response.data.rows } })
                }

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                return error;
            });
        };
    },

    /**
    * @method searchInPlansList
    * @description search API Call to find admin details 
    */
    searchInSocialPlansList: (requestData) => {
        const { id = "", npc_id, ad_type, is_deleted, row = 25, page = 1, } = requestData
        const request = axios.get(`${API.socialMediaPlan}?id=${id}&npc_id=${npc_id}&ad_type=${ad_type}&is_deleted=${is_deleted}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                if (!is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResult, payload: { result: response.data.rows } })
                } else if (is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResultArchive, payload: { result: response.data.rows } })
                }

            }).catch((error) => {
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                return error;
            });
        };
    },

    /**
     * @method advancedSearchInPlansList
     * @description advanced search in plans list
     */
    advancedSearchInPlansList: (requestData) => {
        const {
            ad_type = "",
            discount = "2",
            id = "",
            npc_id,
            is_deleted = 0,
            row = 25,
            page = 1,
            active = "2"
        } = requestData

        let queryString = `active=${active}&ad_type=${ad_type}&discount=${discount}&id=${id}&npc_id=${npc_id}&is_deleted=${is_deleted}&row=${row}&page=${page}`;

        if (requestData.width) {
            queryString = queryString + `&width=${requestData.width}`
        }
        if (requestData.height) {
            queryString = queryString + `&height=${requestData.height}`
        }

        const request = axios.get(`${API.advertisePlanAdvancedSearch}?${queryString}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                if (!is_deleted) {
                    return dispatch({ type: actionTypes.SetAdvancedSearchResult, payload: { result: response.data.rows } })
                } else if (is_deleted) {
                    return dispatch({ type: actionTypes.SetAdvancedSearchResultArchive, payload: { result: response.data.rows } })
                }
            }).catch((error) => {
                //tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                return error;
            });
        };
    },

    getWhatsappNumber: (companyId, callback) => {
        const request = axios.get(`${API.getWhatsappNumber}?id=${companyId}`, { headers });
        return (dispatch) => {
            request
                .then((response) => {
                    callback(response);
                    return dispatch({
                        type: actionTypes.SetWhatsNo,
                        payload: { WhatsNo: response.data },
                    });
                })
                .catch((error) => {
                    // tokenCheck(dispatch, error)
                    //apiErrors(error, dispatch);
                    callback(error);
                });
        };
    },

    getVersions: (requestData, callback) => {
        let request = axios.get(`${API.getVersions}?versionId=${requestData}`, {
            headers
        });
        return (dispatch) => {
            request
                .then((response) => {
                    callback(response);
                    return dispatch({
                        type: actionTypes.setAllVersions,
                        payload: { allVersions: response.data },
                    });
                })
                .catch((error) => {
                    callback(error);
                });
        };
    },

    getVersionsForSocial: (requestData, callback) => {
        let request = axios.get(`${API.getVersionsForSocial}?versionId=${requestData}`, {
            headers
        });
        return (dispatch) => {
            request
                .then((response) => {
                    callback(response);
                    return dispatch({
                        type: actionTypes.setAllVersionsForSocial,
                        payload: { allVersionsForSocial: response.data },
                    });
                })
                .catch((error) => {
                    callback(error);
                });
        };
    },

    /**
    * @method getPlanVersion
    * @description Update plan detail 
    */
    getPlanVersionById: (requestData, callback) => {
        let request = axios.post(`${API.getPlanByversionId}`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    getPlanByversionIdForSocial: (requestData, callback) => {
        let request = axios.post(`${API.getPlanByversionIdForSocial}`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
     * @method resetAdvancedSearch
     * @description reset advanced search
     */
    resetAdvancedSearch: () => {
        return (dispatch) => {
            dispatch({ type: actionTypes.SetAdvancedSearchResult, payload: { result: undefined } })
            dispatch({ type: actionTypes.SetAdvancedSearchResultArchive, payload: { result: undefined } })
        };
    },


};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





