import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Upperfooter = () => {

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <section class="money_sec upperFooter">
        <div class="container">
          <div class="inner_money_sec2">
            <div class="row">
              <div class="col-md-12">
                <div class="money_sbox pijon_heading">
                  <div class="pagehd">
                    <h2>
                      Pijen database features over 100
                      <br /> leading Jewish Publishers.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="schedule_demo_sec">
        <div class="container">
          <div class="schedule_demo_inner chat_demo_inner" data-aos-delay="300" data-aos="zoom-in"  data-aos-duration="1000">
            <div class="row">
              <div class="col-sm-12">
                <div class="schedule_demo_content chat_demo_content">
                  <div class="pagehd">
                    <h4>Let's Chat</h4>
                  </div>
                  <p>
                    To learn more about how your magazine or platform can
                    benefit from Pijen or to
                    <br /> review your listing, please email <a className='white_link' href="mailto:publisher@pijen.com">publisher@pijen.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Upperfooter;
