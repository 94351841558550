import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Table, Row, Col, Input, Label } from "reactstrap";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../../common/layout/FormInput";
import { DATE_RANGE1 } from "../../../../../config/constant";
import * as companySummaryDuck from "../../../../../store/ducks/super-admin/CompanySummary.duck";
import NoRecordFoundContent from "../../../../../common/NoRecordFoundContent";
import { topRecordsFilterKeys } from "../../../../../common/CommonFilterFunction";
import CountFigureBox from "../../../../../common/CountFigureBox";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

class TopList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      duration: DATE_RANGE1,
      selectedCheck: 0,
      openModal: false,
      salesType: "",
      endDate: "",
      fromDate: "",
      toDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getCompanySummary(3, 0);
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanySummary = (type, data) => {
    const { fromDate, toDate, month, year } = this.state;
    this.setState({ salesType: data });
    let requestData = {
      npc_id: "",
      type,
      data,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.props.getCompanySummaryData(requestData, type, (res) => {});
  };

  /**
   * @method render Records
   * @description render the component
   */
  renderRecord = (companyList) => {
    const { salesType } = this.state;
    if (companyList && companyList.length) {
      return (
        companyList &&
        companyList.map((el) => {
          return (
            <tr>
              <th scope="row">{el.id}</th>
              <td>{el.newspaper_name}</td>
              <td>{salesType === 0 ? el.total_amount : el.total_orders}</td>
              <td>{el.contibution.toFixed(2)}</td>
            </tr>
          );
        })
      );
    } else {
      return (
        <tr>
          <td colSpan="4">
            <NoRecordFoundContent />
          </td>
        </tr>
      );
    }
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  appyFilterOnComapny(values) {
    const { startDate, endDate, salesType, dateVisible } = this.state;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      type: 3,
      data: salesType,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
    };
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
    });
    this.props.getCompanySummaryData(requestData, 3, (res) => {});
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      duration,
      selectedCheck,
      dateVisible,
      startDate,
      endDate,
    } = this.state;
    const { module3Data } = this.props;
    return (
      <CountFigureBox>
        <h5>Top 5 Companies</h5>
        <Row className="mt-1 w-100">
          <Col md="12" className="d-flex-center mb-3 mb-md-0">
            <span className="mr-4">By</span>
            <div className="radio-inline">
              <RadioButtonRP label="Revenue">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={selectedCheck === 0 ? true : false}
                  onClick={(e) => {
                    this.setState({ selectedCheck: 0 });
                    this.getCompanySummary(3, 0);
                  }}
                  name="topList_check"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Order">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={selectedCheck === 1 ? true : false}
                  onClick={(e) => {
                    this.setState({ selectedCheck: 1 });
                    this.getCompanySummary(3, 1);
                  }}
                  name="topList_check"
                />
              </RadioButtonRP>
            </div>
          </Col>
          <div className="float-right col-md-12">
            <Field
              name="date_range"
              options={duration}
              optionValue={"value"}
              optionLabel={"label"}
              component={renderSelectField}
              onChange={(e) => {
                if (e.target.value === "1") {
                  this.setState({ dateVisible: true });
                } else {
                  this.setState({ dateVisible: false });
                  this.props.change("from", "");
                  this.props.change("to", "");
                  this.appyFilterOnComapny(e.target.value);
                }
              }}
            />
          </div>
        </Row>

        {dateVisible && (
          <Row>
            <Col md="6">
              <Field
                name="from"
                label="From"
                //minDate={endDate}
                onChange={(e) => {
                  this.props.change("to", "");
                  this.setState(
                    { startDate: e, endDate: "", dateVisible: true },
                    () => {
                      this.appyFilterOnComapny(e);
                    }
                  );
                }}
                component={renderDatePicker}
              />
            </Col>
            <Col md="6">
              <Field
                name="to"
                label="To "
                minDate={startDate}
                onChange={(e) => {
                  this.setState({ endDate: e, dateVisible: true }, () => {
                    this.appyFilterOnComapny(e);
                  });
                }}
                component={renderDatePicker}
              />
            </Col>
          </Row>
        )}
        <div className="table-responsive">
          <Table>
            <thead style={{ backgroundColor: "#dcdcdccc" }}>
              <tr>
                <th>#</th>
                <th>Company Name</th>
                <th>Amount</th>
                <th>Contribution</th>
              </tr>
            </thead>
            <tbody>{module3Data && this.renderRecord(module3Data)}</tbody>
          </Table>
        </div>
      </CountFigureBox>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { companySummaryDuck } = store;
  return {
    loggedInUser: auth.user,
    loading,
    module3Data: companySummaryDuck.moduleData3,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    companySummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "TopList",
    })(TopList)
  )
);
