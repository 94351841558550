import React from "react";
import { DonutChart } from "@carbon/charts-react";
import "../../../../common/common.scss";
import BlankDonutChart from "../../../../common/BlankDonutChart";

class DonutChartDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { chartData } = this.props;
    let pending_orders = "";
    let progress_orders = "";
    let completed_orders = "";
    let total_orders = "";
    if (chartData && chartData !== undefined) {
      pending_orders = chartData.multipleCounts.pending_orders;
      progress_orders = chartData.multipleCounts.progress_orders;
      completed_orders = chartData.multipleCounts.completed_orders;
      total_orders = chartData.multipleCounts.total_orders;
    }
    const colors = ["#00a68f", "#ecd823", "#ec391a"];
    let temp = [pending_orders, progress_orders, completed_orders];
    const simpleBarData = {
      labels: ["Pending Orders", "Processing", "Published"],
      datasets: [
        {
          label: "Dataset 1",
          backgroundColors: colors,
          data:
            temp.length && temp[0] !== "" && temp[1] !== "" && temp[2] !== ""
              ? temp
              : [],
        },
      ],
    };
    const demoDonutOptions = {
      accessibility: false,
      legendClickable: true,
      containerResizable: true,
      colors,
      center: {
        number: total_orders,
        label: "Orders",
      },
      // legend: {
      //   position: "right",
      // },
      resizable: true,
    };
    const isvisible =
      simpleBarData.datasets[0].data &&
      simpleBarData.datasets[0].data.length > 1;
    const blankRecord =
      simpleBarData.datasets[0].data.length > 0 &&
      simpleBarData.datasets[0].data[0] === 0 &&
      simpleBarData.datasets[0].data[1] === 0 &&
      simpleBarData.datasets[0].data[2] === 0;
    return (
      <div className="row h-100 align-items-stretch w-100">
        {isvisible && !blankRecord && (
          <div className="col-6 d-flex-center my-3">
            <DonutChart data={simpleBarData} options={demoDonutOptions} />
          </div>
        )}
        {((isvisible && blankRecord) ||
          simpleBarData.datasets[0].data.length === 0) && (
          <div className="col-md-12 d-flex-center my-3">
            <BlankDonutChart />
          </div>
        )}
        {isvisible && !blankRecord && (
          <div className="col-6 d-flex-center my-3">
            <ul className="pl-1" style={{ listStyleType: "none" }}>
              <li>
                <span className="dot1"></span>&nbsp;&nbsp;&nbsp;&nbsp;Pending
                Orders
              </li>
              <li>
                <span className="dot2"></span>&nbsp;&nbsp;&nbsp;&nbsp;Processing
              </li>
              <li>
                <span className="dot3"></span>&nbsp;&nbsp;&nbsp;&nbsp;Published
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default DonutChartDashboard;
