import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import C3Chart from 'react-c3js';
import '../../../common/common.scss';
import FilterRevenue from './admin-dashboard/FilterRevenue'
import * as AdminDashboardRecord from '../../../store/ducks/super-admin/AdminDashboard.duck';
import { STATUS_CODES } from '../../../common/StatusCode';
import { convertISOToUtcDateformate2 } from '../../../common';
import BlankChart from '../../../common/BlankChart'
import { getCurrentMonth } from '../../../common/CommonFunction';
import { setDateFilterKeys } from '../../../common/CommonFilterFunction'
import { MESSAGES } from '../../../config/message';

let dateRange = [];
let label = getCurrentMonth()
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ['Total'],
      dateAxis: [],
      openModal: false,
      selectedOption: {
        option: 0,
        startDate: '',
        endDate: ''
      }
    };
  }

  /**
  * @method componentDidMount
  * @description  called after rendering the component 
  */
  componentDidMount() {
    this.getDashboardRecord();
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record
   */
  getDashboardRecord = () => {
    let requestData = {
      type: 2,
    }
    this.setData(requestData)
  }

  /**
   * @method setData
   * @description set request data
   */
  setData = (requestData) => {
    this.props.getAdminDashBoardGraphDetail(requestData, res => {
      if (res.status === STATUS_CODES.OK) {
        const { totalRevenue } = this.state
        let temp = totalRevenue;
        const data = res.data && Array.isArray(res.data.salesData) ? res.data.salesData : []
        data && data.length && data.map((item, i) => {
          temp.push(item.sales_amount)
          dateRange.push(convertISOToUtcDateformate2(item.createdAt))
        })
        let permittedValues = data.map(value => (convertISOToUtcDateformate2(value.createdAt)));
        this.setState({ totalRevenue: [...temp], dateAxis: permittedValues, openModal: false })
      }
    })
  }

  /**
   * @method FilterRecords
   * @description filter records
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props)
  }

  /**
  * @method applyFilter
  * @description apply filter
  */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label)
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION)
    }
    const requestData = {
      month: data.this_month,
      year: data.this_year,
      type: 2,
      from_date: data.date,
      to_date: data.date2,
    }
    label = data.label
    this.setState({
      totalRevenue: ['Total'],
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to
      }
    }, () => this.setData(requestData))
  }

  /**
   * @method render
   * @description render record
   */
  render() {
    const { totalRevenue, dateAxis, openModal, selectedOption } = this.state
    return (
      <div className="row">
        <div className="col-12 mt-2">
          <h5
            className='cursorPointer'
            onClick={() => this.setState({ openModal: true })}
          >
            {label}
            &nbsp;&nbsp;
            <i
              style={{ fontSize: 20 }}
              className='fa fa-angle-down'
              aria-hidden='true'
            ></i>
          </h5>
        </div>

        <div className="col-12 mt-5">
          {totalRevenue.length > 1 ? <C3Chart
            type='line'
            data={{
              columns: [
                totalRevenue
              ],
            }}
            axis={{
              x: {
                type: 'category',
                show: true,
                categories: dateAxis,
                text: 'Duration',
              },
              y: {
                label: {
                  text: 'Sales Revenue',
                  position: 'outer-middle'
                }
              },
            }}
            unloadBeforeLoad={true}
          /> : <div style={{ marginTop: 30 }} className='row w-100'><BlankChart /></div>}
        </div>
        {openModal && <FilterRevenue
          applyFilter={this.FilterRecords}
          selectedOption={selectedOption}
          isOpen={openModal}
          onCancel={() => this.setState({ openModal: false })}
        />}
      </div>
    );
  }
}
const mapStateToProps = store => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return ({
    loggedInUser: auth.user,
    loading,
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    AdminDashboardRecord.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'Chart',
      destroyOnUnmount: false
    })(Chart)
  )
);

