import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form'
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../../store/ducks/auth.duck';
import { requestPassword } from '../../crud/auth.crud';
import { MESSAGES } from '../../config/message'
import { renderThemeText } from '../../common/layout/FormInput'
import { Form, Col } from 'react-bootstrap';
import {
  required,
  email,
} from '../../config/validation';
import { STATUS_CODES } from '../../common/StatusCode';
import CommonAlert from '../../common/CommonAlert'

class ForgotPassword extends Component {
  state = { isRequested: false };
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      show: false,
      loading: false,
      loadingButtonStyle: {
        paddingRight: '2.5rem'
      },
      message: '',
      alwaysShow: false
    }
  }

  /** 
  * @method componentDidMount
  * @description called after render the component
  */
  componentDidMount() {
    this.props.dispatch(change('ForgotPassword', 'email', ''));
    this.props.dispatch(untouch('ForgotPassword', 'email'));
  }

  /** 
  * @method enableLoading
  * @description called enable the loading
  */
  enableLoading = () => {
    this.setState({ loading: false, loadingButtonStyle: { paddingRight: '3.5rem' } });
  };

  /** 
  * @method disableLoading
  * @description called for disable loading
  */
  disableLoading = () => {
    this.setState({ loading: false, loadingButtonStyle: { paddingRight: '2.5rem' } });
  };

  setShow = () => {
    this.setState({ show: false });
  }
  /**
    * Submit the login form
    * @param values
    * @desc Calling a login actions && redirecting page based on response and set session Items 
    */
  onSubmit(values) {
    this.enableLoading();
    requestPassword(values.email)
      .then((res) => {
        this.setState({ isRequested: true, show: true, variant: 'success', message: MESSAGES.EMAIL_SENT });
        this.disableLoading();
        setTimeout(() => {
          window.location.assign('/auth/login');
        }, 5000);
      })
      .catch((error) => {
        this.disableLoading();
        console.log('error.response: ', error.response);
        if (error.response && error.response.data.success == false) {          
          this.setState({ alwaysShow: true, show: true, success: false, variant: 'danger', message: error.response.data.message })
        } else if (error.response && error.response.status === STATUS_CODES.PRECONDITION_FAILED) {
          this.setState({ show: true, variant: 'danger', message: error.response.data.message })
        } else {
          this.setState({ show: true, variant: 'danger', message: MESSAGES.SERVER_ERROR })
        }
      });
  }

  /** 
 * @method render
 * @description render the component
 */
  render() {
    const { show, alwaysShow, variant, message, loadingButtonStyle, loading } = this.state;
    const { handleSubmit } = this.props;
    return (
      <div className='kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper'>
        <div className='kt-login__body'>
          <div className='kt-login__form'>
            <div className='kt-login__title'>
              {show && <CommonAlert show={show} variant={variant} message={message} setShow={this.setShow} alwaysShow={alwaysShow} />}
              <h3>
                <FormattedMessage id='Forgot Password' />
              </h3>
            </div>
            <Form
              noValidate
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <Form.Group as={Col} md='12'>
                <Form.Row>
                  <Form.Group as={Col} md='12' controlId='validationCustom01'>
                    <Field
                      name='email'
                      label='Email'
                      type={'email'}
                      validate={[required, email]}
                      required={true}
                      placeholder='Enter Your Email Id'
                      component={renderThemeText} />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <div className='kt-login__actions'>
                <Link
                  to='/auth/login'
                  className='btn btn-secondary btn-elevate kt-login__btn-secondary'
                >
                  Back
              </Link>
                <button
                  id='kt_login_signin_submit'
                  type='submit'
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  Submit
            </button>
              </div>
            </Form>

          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = store => {
  let initialValues = {
    email: ''
  }
  return {
    initialValues
  }
}
export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(reduxForm({
    // Assign unique name for the form
    form: 'ForgotPassword',
    destroyOnUnmount: false
  })(ForgotPassword))
);