import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as salesReportDuck from "../../../../../store/ducks/super-admin/report-management/SalesReport.duck";
import Listing from "./Listing";
import LineChart from "./SalesChart";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { Loader } from "../../../../../common/Loader";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import CountFigureBox from "../../../../../common/CountFigureBox";

class SalesReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getSalesReportHeader();
  }

  /**
   * @method getSalesReportHeader
   * @description  used to get sales report header record
   */
  getSalesReportHeader = () => {
    let requestData = {
      type: 1,
      section: 1,
    };
    this.props.getSalesDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data.salesData[0] });
      }
    });
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value) => {
    return (
      <CountFigureBox>
        <h6 className="heading">{lable}</h6>
        <h3 className="heading">{`$ ${value}`}</h3>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { headerData } = this.state;
    const { loading } = this.props;
    const total_revenue = headerData && headerData.total_revenue;
    const company_sales = headerData && headerData.company_sales;
    const commission_fee = headerData && headerData.commission_fee;
    const viewPer = checkPermission(
      langs.permissionKeys.sales,
      langs.permissionKeys.view
    );
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Sales</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                {/* {loading && <Loader/>} */}
                <PortletBody>
                  <div className="row">
                    <div className="cardWrapper col-md-3">
                      {this.renderHeaderCard(
                        "Total Sales Amount",
                        company_sales
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        "Total Earning to Admin",
                        commission_fee
                      )}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {this.renderHeaderCard(
                        "Total Revenue to Company",
                        total_revenue
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: 50, minHeight: 450 }}>
                    <LineChart />
                  </div>
                  <div style={{ borderBottom: "1px solid grey" }}></div>
                  <Listing />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.REPORT_PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};
export default injectIntl(
  connect(mapStateToProps, salesReportDuck.actions)(SalesReports)
);
