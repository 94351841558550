import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import { toastr } from "react-redux-toastr";
import "../Chart.scss";
import { renderSelectField } from "../../../../../common/layout/FormInput";
import FilterRecords from "../FilterRecords";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { getCurrentMonth } from "../../../../../common/CommonFunction";
import { SALES_TREND } from "../../../../../config/constant";
import BlankChart from "../../../../../common/BlankChart";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";

let label = getCurrentMonth();
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ["Sales Trend"],
      orders: ["Current Year"],
      salesTrend: SALES_TREND,
      openModal: false,
      selectedOption: {
        option: 0,
        startDate: "",
        endDate: "",
      },
      salesType: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getSalesReport(4, 1, "", "", langs.keyname.revenue);
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getSalesReport = (row, page, sort, column, salesType) => {
    const { user_detail } = this.props.loggedInUser;
    this.setState({ salesType: salesType });
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 2,
      sales: salesType,
    };
    this.setData(requestData, salesType);
  };

  /**
   * @method setData
   * @description  set requestdata
   */
  setData = (requestData, salesType) => {
    const { totalRevenue } = this.state;
    this.props.getSalesGraphData(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        if (salesType === langs.keyname.orders) {
          let temp = totalRevenue;
          res.data &&
            Array.isArray(res.data.salesTrend) &&
            res.data.salesTrend.map((item, i) => {
              temp.push(item.total_orders);
            });
          this.setState({ totalRevenue: [...temp] });
        } else if (salesType === langs.keyname.revenue) {
          let temp = totalRevenue;
          res.data &&
            Array.isArray(res.data.salesTrend) &&
            res.data.salesTrend.map((item, i) => {
              temp.push(item.sales_revenue);
            });
          this.setState({ totalRevenue: [...temp] });
        }
      }
    });
  };

  /**
   * @method revenueSelection
   * @description  used to get records of revenue
   */
  revenueSelection = () => {
    this.setState(
      {
        totalRevenue: ["Sales Trend"],
      },
      () => {
        this.getSalesReport(4, 1, "", "", langs.keyname.revenue);
      }
    );
  };

  /**
   * @method orderSelection
   * @description  used to get records of orders
   */
  orderSelection = () => {
    this.setState(
      {
        totalRevenue: ["Sales Trend"],
      },
      () => {
        this.getSalesReport(4, 1, "", "", langs.keyname.orders);
      }
    );
  };

  /**
   * @method test
   * @description  called after rendering the component
   */
  test = (values, b) => {
    this.setState(
      {
        totalRevenue: ["Sales Trend"],
      },
      () => {
        this.applyFilter(values, this.props);
      }
    );
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const { user_detail } = this.props.loggedInUser;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const { salesType } = this.state;
    const requestData = {
      npc_id: user_detail.newspaper.id,
      row: 25,
      page: 1,
      month: data.this_month,
      year: data.this_year,
      type: 2,
      from_date: data.date,
      to_date: data.date2,
      statement: "",
      sales: salesType ? salesType : langs.keyname.revenue,
    };
    this.setData(requestData, salesType);
    this.setState({
      openModal: false,
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
  }

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { totalRevenue, salesTrend, openModal, selectedOption } = this.state;
    return (
      <div className="my-5">
        <div className="row ">
          <div className="col-6 col-md-4 d-flex-center">
            <h5 className="float-left">Sales Trend</h5>
          </div>
          <div className="col-md-4 col-6 d-flex-center justify-content-end justify-content-md-center">
            <label>
              <Field
                name="sales"
                options={salesTrend}
                style={{ marginTop: "-27px !important" }}
                optionValue={"value"}
                optionLabel={"label"}
                onChange={(e) => {
                  if (e.target.value === "orders") {
                    this.orderSelection();
                  } else {
                    this.revenueSelection();
                  }
                }}
                component={renderSelectField}
              />
            </label>
          </div>
          <div className="col-md-4 d-flex-center j-end">
            <h5
              className="cursorPointer"
              onClick={() => this.setState({ openModal: true })}
            >
              {label}
              &nbsp;&nbsp;
              <i
                style={{ fontSize: 20 }}
                className="fa fa-angle-down"
                aria-hidden="true"
              ></i>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {totalRevenue.length > 1 ? (
              <C3Chart
                axis={{
                  x: {
                    label: {
                      text: "Duration",
                      position: "outer-middle",
                    },
                  },
                  y: {
                    label: {
                      text: "Sales Revenue",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [
                    totalRevenue,
                    // orders
                  ],
                  type: "line",
                }}
              />
            ) : (
              <div style={{ marginTop: 30 }} className="row w-100">
                <BlankChart />
              </div>
            )}
            {openModal && (
              <FilterRecords
                applyFilter={this.test}
                isOpen={openModal}
                onCancel={() => {
                  this.setState({ openModal: false });
                }}
                type={2}
                selectedOption={selectedOption}
                setSelectedOption={(e) => {
                  this.setState({ selectedOption: e });
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { paymentReportDuck } = store;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    salesDetail: paymentReportDuck.salesDetail,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "Chart",
      destroyOnUnmount: false,
    })(Chart)
  )
);
