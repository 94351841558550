import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'
import { Button, Form, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form'
import { toastr } from 'react-redux-toastr';
import { injectIntl } from 'react-intl'
import { renderThemeSelectOptions, renderThemeToggleButton } from '../../FormLayout'
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import * as adminManagementDuck from '../../../../store/ducks/super-admin/AdminManagement.duck'
import * as commonDuck from '../../../../store/ducks/Common.duck'
import { MESSAGES } from '../../../../config/message'
import { langs } from '../../../../config/localization';
import { Loader } from '../../../../common/Loader';
import {
    renderText,
    renderNumberInputField,
    renderPasswordInputField,
    focusOnError
} from '../../../../common/layout/FormInput';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from '../../../../partials/content/Portlet';
import {
    required,
    PhoneNumber,
    minLength5,
    maxLength18,
    minLength6,
    maxLength100,
    maxLengthC100,
    minLength2,
    email,
    selectRequired,
} from '../../../../config/validation';
import { STATUS_CODES } from '../../../../common/StatusCode'
import { phoneNumberFormateOnChange } from "../../../../common";

class AddAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleChanepassword: false,
            companyRoles: [],
            status: false,
            value: '',
            number: '',
            isRedirect: false,
            username: '',
            password: '',
            uniqueEmailId: false,
            lowerCaseCheck: false,
            upperCaseCheck: false,
            numberCheck: false,
            lengthCheck: false,
            specialCharacterCheck: false,
            isSubmitted: true
        }
    }

    /**
     * @method componentDidMount
     * @description called after render the component
     */
    componentDidMount() {
        this.props.getAllRoleList(langs.keyname.admin);
    }

    /**
    * Submit the login form
    * @param values
    * @desc Calling a login actions && redirecting page based on response and set session Items 
    */
    onSubmit(values) {
        const { status, number } = this.state
        values.active = !status;
        values.phone_number = number.replace(/-/g, '');
        values.first_name && values.first_name.trim()
        values.last_name && values.last_name.trim()
        values.password && values.password.trim()
        values.confirmPassword && values.confirmPassword.trim()

        this.setState({ isSubmitted: false })
        this.props.addNewAdmin(values, (res) => {
            if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
                this.setState({ isSubmitted: false, isEditFlag: false,isRedirect: true });
                toastr.success(MESSAGES.ADD_ADMIN_SUCCESS)
            } else {
                this.setState({ isSubmitted: true, isRedirect: false });
            }
        })
    }

    /**
     * @method onChange
     * @description mobile number formatting
     */
    onChange = e => {
        //Filter only numbers from the input
        let number = phoneNumberFormateOnChange(e.target.value);
        this.setState({
        number,
        isEditFlag: true,
        });
    };
   
    handleChange = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    render() {
        const { handleSubmit, loading, companyRoles } = this.props;
        const { isRedirect, number, status, isSubmitted } = this.state;
        if (isRedirect) {
            return (
                <Redirect push
                    to={{
                        pathname: '/adminManagement'
                    }}
                />
            );
        }
        return (
            <div >
                {loading && <Loader />}
                <LayoutContextConsumer className='row'>
                    {({ subheader: { title } }) => (
                        <div>
                            <div className='row'>
                                <h3 className='kt-subheader__title col-md-6'>
                                    <span>
                                        <Link to='/adminManagement'>
                                            <i
                                                onClick={() => {
                                                    return this.props.history.push('/adminManagement');
                                                }}
                                                style={{ fontSize: 20 }}
                                                className='fa fa-arrow-left'
                                                aria-hidden='true'
                                            ></i>
                                        </Link>
                                    </span>
                                    <span style={{ marginLeft: 20 }}>Add Admin</span>
                                </h3>
                            </div>
                        </div>
                    )}
                </LayoutContextConsumer>
                <div className='col-md-12'>
                    <Portlet fluidHeight={true}>
                        <PortletHeader
                            title='Contact Info'
                        />
                        <PortletBody >
                            <Form
                                noValidate
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Form.Group as={Col} md='12'>
                                    <Form.Row>
                                        <Form.Group as={Col} md='6' controlId='validationCustom01'>
                                            <Field
                                                name='first_name'
                                                value={'adminProfile.first_name'}
                                                label='First Name'
                                                validate={[required, minLength2, maxLengthC100]}
                                                required={true}
                                                placeholder='Enter Your First Name'
                                                component={renderText} />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md='6' controlId='validationCustom02'>
                                            <Field
                                                name='last_name'
                                                label='Last Name'
                                                validate={[required, minLength2, maxLengthC100]}
                                                required={true}
                                                placeholder='Enter Your Last Name'
                                                component={renderText} />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md='6' controlId='validationCustom01'>
                                            <Field
                                                name='phone_number'
                                                label='Phone Number'
                                                type='text'
                                                maxLength={10}
                                                onChange={this.onChange}
                                                Value={number}
                                                validate={[required, PhoneNumber]}
                                                required={true}
                                                style={{ width: '100%' }}
                                                placeholder='Enter Your Phone Number'
                                                component={renderNumberInputField}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md='6' controlId='validationCustom02'>
                                            <Field
                                                name='email'
                                                label='Email'
                                                validate={[required, minLength5, maxLength100, email]}
                                                required={true}
                                                placeholder='Enter Email Id'
                                                component={renderText} />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group>
                                        <Form.Row style={{ marginRight: 5 }}>
                                            <div>
                                                <div className='kt-portlet__head-label'>
                                                    <h5 className='kt-portlet__head-title'>Password</h5>
                                                </div>
                                            </div>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md='4' controlId='validationCustom01'>
                                                <Field
                                                    name='password'
                                                    width={300}
                                                    label='Password'
                                                    onChange={this.passwordPatternHandler}
                                                    validate={[required, minLength6, maxLength18]}
                                                    required={true}
                                                    placeholder='Enter Password'
                                                    component={renderPasswordInputField}
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md='4' controlId='validationCustom02'>
                                                <Field
                                                    name='confirmPassword'
                                                    width={300}
                                                    label='Confirm Password'
                                                    validate={[required, minLength6, maxLength18]}
                                                    required={true}
                                                    placeholder='Confirm Your Password'
                                                    component={renderPasswordInputField}
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Row>
                                            <div>
                                                <div className='kt-portlet__head-label'>
                                                    <h5 className='kt-portlet__head-title'>Role</h5>
                                                </div>
                                            </div>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md='12' controlId='validationCustom01'>
                                                <Form.Label> Select Role Type<span className='asterisk-required'>*</span></Form.Label>
                                                <Field
                                                    name='role_id'
                                                    data={companyRoles}
                                                    validate={[selectRequired]}
                                                    required={true}
                                                    isSelect={false}
                                                    component={renderThemeSelectOptions} />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} md='6' controlId='validationCustom01'>
                                            <Form.Label>Status</Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md='6' controlId='validationCustom01'>
                                            <Form.Label style={{ color: 'green' }}>Active</Form.Label>
                                            <Field
                                                name='active'
                                                checked={status}
                                                onChange={this.handleChange('status')}
                                                required={true}
                                                component={renderThemeToggleButton} />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            <Form.Label style={{ color: 'red' }}>Inactive</Form.Label>

                                        </Form.Group>
                                    </Form.Row>

                                    <Button
                                        type='submit'
                                        className='commonBtn float-right'
                                        disabled={isSubmitted ? false : true}
                                    >
                                        Save
                                    </Button>
                                </Form.Group>
                            </Form>
                        </PortletBody>

                    </Portlet>
                </div >
            </div >
        );
    }

};

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
    let errors = {};

    //Check the value should not be empty
    //Checks password & confirm password validation both are same or not
    if (!values.password) {
        errors.password = langs.validation_messages.password_required;
    } else if (!values.confirmPassword) {
        errors.confirmPassword = langs.validation_messages.confirm_password_required;
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = langs.messages.password_unmatch;
    }
    return errors;
}

const mapStateToProps = store => {
    const { loading, roles } = store.commonDuck;
    let initialValues = {};
    initialValues = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        role_id: ''
    };

    return ({
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        companyRoles: roles && roles.admin ? roles.admin : [],
        loading,
        initialValues
    })
}


export default injectIntl(
    connect(
        mapStateToProps,
        { ...commonDuck.actions, ...adminManagementDuck.actions }
    )(reduxForm({
        // Assign unique name for the form
        form: 'AddAdmin',
        validate,
        enableReinitialize: true,
        destroyOnUnmount: false,
        onSubmitFail: (errors) => {
            focusOnError(errors)
        }
    })(AddAdmin))
);
