import React from 'react';
import { injectIntl } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv"
import { reduxForm } from 'redux-form';
import { PortletBody, Portlet } from '../../../../../partials/content/Portlet';
import { LayoutContextConsumer } from '../../../../../../_metronic/layout/LayoutContext';
import * as paymentReportDuck from '../../../../../store/ducks/npc/PaymentReport.duck';
import { PayoutRepoStatementHeaders } from '../../../../../common/CsvFormate'
import { convertISOToUtcDateformate4 } from '../../../../../common'
import StandardTable from '../../../StandardTable';
import { Loader } from '../../../../../common/Loader';

class ViewStatement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            orderList: [],
            page: 1,
            pageSize: 25
        }
    }
    /**
  * @method componentDidMount
  * @description  called after rendering the component 
  */
    componentDidMount() {
        this.getPayoutRecords(25, 1, '', '');
    }

    /**
     * @method getCompanyList
     * @description  used to get all admin listing
     */
    getPayoutRecords = (row, page, sort, column) => {
        const { user_detail } = this.props.loggedInUser
        let requestData = {
            npc_id: user_detail.newspaper.id,
            row: row !== undefined ? row : 25,
            page: page !== undefined ? page : 1,
            sort: sort,
            column: column,
            type: 5,
            statement: this.props.match.params.id
        }

        this.props.getStatementDetail(requestData, res => {})
    }
    /**
       * @method getCSVData
       * @description return  csv data list
       */
    getCSVData = () => {
        const { orderData } = this.props
        let csvData = Array.isArray(orderData.rows) && orderData.rows.map(item => {
            let adPlan = (Array.isArray(item.newspaper_ads_plan.advertisements) && item.newspaper_ads_plan.advertisements.length) ?
                adPlan = `${item.newspaper_ads_plan.advertisements[0].ad_type} ${item.newspaper_ads_plan.height} X ${item.newspaper_ads_plan.width}` : '';
            let total_revenue = (item.payment_report.total_revenue && item.payment_report.total_revenue !== undefined) ? `$ ${item.payment_report.total_revenue}` : '';
            let paid_amount = (item.payment_report.paid_amount && item.payment_report.paid_amount !== undefined) ? `$ ${item.payment_report.paid_amount}` : ''
            let commission_fee = (item.payment_report.commission_fee && item.payment_report.commission_fee !== undefined) ? `$ ${item.payment_report.commission_fee}` : ''

            let order_date = convertISOToUtcDateformate4(item.order_date)
            return {
                ...item,
                ...{ full_name: item.user.first_name + ' ' + item.user.last_name },
                ...{ order_date: order_date },
                ...{ ad_plan: adPlan },
                ...{ total_revenue: total_revenue },
                ...{ paid_amount: paid_amount },
                ...{ commission_fee: commission_fee }
            };
        });
        return csvData;
    }

    /**
   * @method renderRecords
   * @description render all order list
   */
    renderRecords = () => {
        const columnOrder = [
            {
                Header: 'Order ID',
                accessor: 'id', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return original.id
                })
            },
            {
                Header: 'Date',
                accessor: 'order_date', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return convertISOToUtcDateformate4(original.order_date)
                })
            },
            {
                Header: 'Ad Plan',
                Cell: ((row) => {
                    const { original } = row;
                    const { newspaper_ads_plan } = original;
                    let adPlan = ''
                    if (!newspaper_ads_plan) return ''
                    if (Array.isArray(newspaper_ads_plan.advertisements) && newspaper_ads_plan.advertisements.length) {
                        adPlan = `${newspaper_ads_plan.advertisements[0].ad_type} ${newspaper_ads_plan.height} X ${newspaper_ads_plan.width}`
                    }
                    return <div style={{ display: '-webkit-box' }}>
                        {adPlan}
                    </div>
                })
            },
            {
                Header: 'Customer Name',
                Cell: (row) => {
                    const { original } = row;
                    return <font className='text-truncate'>{(original && original.user.first_name ? original.user.first_name + ' ' + original.user.last_name : '')}</font>
                },
            },
            {
                Header: 'Price',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.payment_report.total_revenue}`
                },
            },

            {
                Header: 'Earning',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.payment_report.paid_amount}`
                },
            },
            {
                Header: 'Fee',
                Cell: (row) => {
                    const { original } = row;
                    return <b>{`$${original.payment_report.commission_fee}`}</b>
                },
            },
        ];
        return columnOrder
    }

    render() {
        const { id } = this.props.match.params;
        const { totalPayOut, orderData, loading } = this.props;
        const csvData = this.getCSVData()
        const headers = PayoutRepoStatementHeaders;
        return (
            <div>
                {loading && <Loader />}
                <LayoutContextConsumer className='row'>
                    {({ subheader: { title } }) => (
                        <div className='row'>
                            <h4 className='kt-subheader__title'>
                                <span>
                                    <Link to='/payoutHistory'>
                                        <i
                                            onClick={() => {
                                                return this.props.history.push('/payoutHistory');
                                            }}
                                            style={{ fontSize: 15 }}
                                            className='fa fa-arrow-left'
                                            aria-hidden='true'
                                        ></i></Link>
                                </span>

                            </h4>
                            <h5 className='ml-3'>Statement</h5>
                        </div>
                    )}
                </LayoutContextConsumer>
                {totalPayOut !== undefined && <div className='col-md-12'>
                    <Portlet fluidHeight={true}>
                        <PortletBody className='px-4' ref={el => (this.componentRef = el)}>
                            <div className='w-100  mt-2' style={{ borderBottom: '1px solid grey' }}>
                                <h5 className='float-left'>Statement Id : {id}</h5>
                                <h5 className='float-right border-bottom-0 border-primary'>
                                    <CSVLink filename={'StatementDetails.csv'} data={csvData} headers={headers} style={{ borderBottom: '1px solid' }}>
                                        Export CSV
                                    </CSVLink>
                                </h5>
                                <h5 className='float-right mr-4'>
                                    <Link>
                                        <ReactToPrint
                                            trigger={() => <a href="#" style={{ borderBottom: '1px solid' }}>Print</a>}
                                            content={() => this.componentRef}
                                        />
                                    </Link>
                                </h5>
                            </div>

                            <div className='w-100 row  mt-2' style={{ borderBottom: '1px solid grey', minHeight: 200 }}>
                                <div className='col-md-6'>
                                    <font style={{ fontSize: '-webkit-xxx-large', fontWeight: 400 }}>${totalPayOut.total_revenue}</font><br />
                                    <font>Payout</font>
                                </div>
                                <div className='col-md-6 mt-4' style={{ color: 'black' }}>
                                    <span className='row' style={{ color: 'grey' }}>
                                        <h5 className='col-md-4'>Period </h5>
                                        <h5 className='col-md-8'>{`${convertISOToUtcDateformate4(totalPayOut.min_date)} to ${convertISOToUtcDateformate4(totalPayOut.max_date)}`} </h5>
                                    </span>
                                    <span className='row' >
                                        <h5 className='col-md-4'>Total Order</h5>
                                        <h5 className='col-md-6'>{totalPayOut.total_orders}</h5>
                                    </span>
                                    <span className='row' >
                                        <h5 className='col-md-4'>Total Sales</h5>
                                        <h5 className='col-md-6'>${2500}</h5>
                                    </span>
                                    <span className='row' >
                                        <h5 className='col-md-4'>Fees</h5>
                                        <h5 className='col-md-6'>${totalPayOut.commission_fee}</h5>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className='col-md-12'>
                                        <div className='w-100 '>
                                            <StandardTable
                                                showSearch={false}
                                                handleSort={false}
                                                columnOrder={this.renderRecords()}
                                                allData={this.state.orderList}
                                                data={orderData.rows}
                                                sortable={false}
                                                showPageSizeOptions={false}
                                                showPagination={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PortletBody>
                    </Portlet>
                </div>}
                {totalPayOut === undefined && <Portlet fluidHeight={true}>
                    <PortletBody className='px-4' ref={el => (this.componentRef = el)}>
                        <h4 className='text-centre'>No Record Found</h4>
                    </PortletBody>
                </Portlet>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    const { paymentReportDuck } = store;
    const { statementDetail } = paymentReportDuck
    const { auth } = store;
    return ({
        loggedInUser: auth.user,
        loading,
        financeDetail: paymentReportDuck.financeDetail,
        totalPayOut: statementDetail !== undefined ? statementDetail.totalPayOut : undefined,
        orderData: statementDetail !== undefined ? statementDetail.orderData : []
    })
};

export default injectIntl(
    connect(
        mapStateToProps,
        paymentReportDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'ViewStatement'
        })(ViewStatement)
    )
);
