import React, { Fragment, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import "./SeasonalIssue.scss";
import { Button } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Inactive from "./Inactive";
import Seasonalissue from "./Seasonalissue";
import AddSeasonalIissue from "./AddSeasonalIissue";
import { useHistory } from "react-router-dom";

const localStorageActiveTabKey = "builderNewspaperActiveTab";

function SeasonalIssue() {
  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [tab, setTab] = useState(activeTab ? +0 : 0);
  const [modalShow, setModalShow] = useState(false);
  const [isHidePopup, setIsHidePopup] = useState(false);
  const history = useHistory();
  const onHidePopup = ()=>{
    setModalShow(false);
    setIsHidePopup(true);
  }

  return (
    <Fragment>
      <div className="seasonal_issue_main">
        <Portlet>
          <PortletBody className="position-relative">
            <div className="tab_main">
              <Tabs
                component="div"
                className="builder-tabs"
                value={tab}
                onChange={(_, nextTab) => {
                  setTab(nextTab);
                }}
              >
                <Tab label="Seasonal Issues" />
                <Tab label="Inactive Issues" />
              </Tabs>
              {tab === 0 && (
                <Button className="seasonal_issue_btn" onClick={() => history.push("/seasonalIssue/1")}>
                  <AiOutlinePlusCircle className="mr-1"/>
                  Add Seasonal issue
                </Button>
              )}
            </div>

            <div className="kt-form kt-form--label-right mt-5">
              <Portlet>
                {tab === 0 && <Seasonalissue />}
                {tab === 1 && <Inactive isHidePopup={isHidePopup}/>}
              </Portlet>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    </Fragment>
  );
}

export default SeasonalIssue;