import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Card } from "react-bootstrap";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import { langs } from "../../../../config/localization";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import "./Settings.scss";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";
import { STATUS_CODES } from "../../../../common/StatusCode";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      language: "",
      key: "",
      selectedItem: [],
      allSelectedItemArray: [],
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getfromNPCSetting(langs.keyname.npc_category, "", (res) => {});
    this.getSelectedCategories();
  }

  /**
   * @method getSelectedCategories
   * @description called to get selected category
   */
  getSelectedCategories = () => {
    const { authToken, user_detail } = this.props.loggedInUser;
    this.props.getAllSelectedCategory(
      langs.keyname.npc_category,
      user_detail.newspaper.id,
      authToken,
      (res) => {
        if (
          res.status === STATUS_CODES.OK &&
          Array.isArray(res.data) &&
          res.data[0] !== undefined
        ) {
          let temp = res.data[0].categories.map((el) => el.id);
          this.setState({
            selectedItem: temp,
            allSelectedItemArray: res.data[0].categories,
          });
        }
      }
    );
  };

  /**
   * @method onSelection
   * @description perform selection operation
   */
  onSelection = (item, index) => {
    const { allSelectedItemArray } = this.state;
    const { user_detail } = this.props.loggedInUser;
    const { selectedItem } = this.state;
    let isSelected = selectedItem.includes(item.id);
    if (isSelected) {
      let i = allSelectedItemArray.findIndex((el) => el.id === item.id);
      let junctionId = allSelectedItemArray[i].npc_categories.id;
      this.setState({
        selectedItem: [...selectedItem.filter((e) => e !== item.id)],
        isSubmitted: false,
      });
      this.props.removeCategory(
        langs.keyname.npc_category,
        junctionId,
        user_detail.newspaper.id,
        (res) => {
          if (res.status === STATUS_CODES.NO_CONTENT) {
            toastr.success(MESSAGES.DELETE_CATEGORY_SUCCESS);
            this.getSelectedCategories();
          }
        }
      );
    } else {
      this.setState({
        selectedItem: [...selectedItem, item.id],
        isSubmitted: false,
      });
      const requestData = {
        id: user_detail.newspaper.id,
        newspaperId: user_detail.newspaper.id,
        categoryId: item.id,
      };

      this.props.addCategory(langs.keyname.npc_category, requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(MESSAGES.ADD_CATEGORY_SUCCESS);
          this.getSelectedCategories();
        }
      });
    }
  };

  /**
   * @method renderCardItem
   * @description render the color card item
   */
  renderCardItem = (typeOfListing) => {
    const { selectedItem } = this.state;
    if (Array.isArray(typeOfListing) && typeOfListing.length === 0) {
      return (
        <div style={{ marginLeft: "360px", marginTop: "-18px" }}>
          {MESSAGES.CATEGORY_NOT_FOUND}
        </div>
      );
    } else if (typeOfListing && typeOfListing !== undefined) {
      let style = {
        width: "32%",
        height: "auto",
        minHeight: "4rem",
        marginRight: 10,
        marginBottom: 10,
      };
      return typeOfListing.map((data, i) => {
        let isSelected = selectedItem.includes(data.id);
        return (
          <Card
            key={i}
            style={style}
            bg={isSelected ? "dark" : "light"}
            onClick={() => this.onSelection(data, i)}
          >
            <Card.Body>
              <Card.Title>
                <label htmlFor={`checkbox'-${i}`} className="textOverflow">
                  {data.name}
                  {isSelected && (
                    <label className="round">
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={true}
                        onChange={() => console.log()}
                      />{" "}
                      <label htmlFor={`checkbox'-${i}`}></label>
                    </label>
                  )}
                </label>
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { categoryList } = this.props;
    return (
      <div className="row my-5">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>Category</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="cardWrapper">
                {this.renderCardItem(categoryList)}
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, npcDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    categoryList: Array.isArray(npcDuck.npcSettingList.npc_category)
      ? npcDuck.npcSettingList.npc_category
      : [],
    selected_Categories:
      Array.isArray(npcDuck.npcSettingList.selected_Categories) &&
      npcDuck.npcSettingList.selected_Categories.length
        ? npcDuck.npcSettingList.selected_Categories[0].npc_categories
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    npcDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "CategoryList",
    })(CategoryList)
  )
);
