import React, { Component } from "react";
import { connect } from "react-redux";
import { convertISOToUtcDateformate1 } from "../../../../common";
import { Link } from "react-router-dom";
import * as emailConfigurationDuck from "../../../../store/ducks/super-admin/EmailConfiguration.duck ";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { langs } from "../../../../config/localization";
import { checkPermission } from "../../../../common/CheckPermissions";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import { PAGES_25, TOTAL_COUNT } from '../../../../config/constant'

class TemplateListing extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rows: [],
      templateList: [],
      isConfirm: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      showSearchResults: false,
      sortedOrder: true,
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.getTemplateList(this.state.pageSize, 1, "", "");
  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getTemplateList = (row, page, sortBy, column) => {
    let requestData = {
      id: "",
      is_deleted: 0,
      row: row !== undefined ? row : 50,
      page: page !== undefined ? page : 1,
      name: "",
      sort: sortBy ? sortBy : "",
      column: column ? column : ""
    };
    this.props.getTemplateList(requestData, res => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          templateList: res.data.rows,
          totalCount: Math.ceil(pages)
        });
      }
    });
  };

  /**
   * @method onPageChange
   * @description called on change of page
   */
  onPageChange = page => {
    this.getTemplateList(this.state.pageSize, page + 1, "", "");
  };

  /**
   * @method onPageSizeChange
   * @description called on change of page size
   */
  onPageSizeChange = pageSize => {
    this.setState({ pageSize: pageSize },()=>{
      this.getTemplateList(pageSize, this.state.page, "", "");
    });
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = e => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
    } else if (e.target.value.length >= 2) {
      const { pageSize } = this.state;
      const row = 1;
      this.setState({ showSearchResults: true });
      let requestData = {
        id: "",
        name: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 0,
        row: pageSize !== undefined ? pageSize : 50,
        page: row !== undefined ? row : 1,
        sort: "",
        column: ""
      };
      this.props.searchInTemplateList(requestData);
    }
  };

  /**
   * @method handleClose
   * @description Pop up handeler before delete records
   */
  handleClose = () => {
    this.setState({ isConfirm: false, show: false });
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e,{ sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? 'ASC' : 'DESC'
    this.setState({ sortedOrder: !sortedOrder });
    this.getTemplateList(this.state.pageSize, 1, sortBy, column.id);
  };

  /**
   * @method renderRecords
   * @description Used to render record list 
   */
  renderRecords = () => {
    const createPer = checkPermission(
      langs.permissionKeys.emailTemplate,
      langs.permissionKeys.create
    );
    const columnOrder = [
      {
        Header: "Template Name",
        accessor: "name",
        Cell: row => {
          const { original } = row;
          const firstName = original && original !== undefined && original.name;
          return (
            <div>
              {createPer ? (
                <Link to={`/edit-template/${original.id}`}>
                  <font>{`${firstName}`}</font>
                </Link>
              ) : (
                  <font>{`${firstName}`}</font>
                )}
            </div>
          );
        } // String-based value accessors!
      },
      {
        Header: "Subject",
        accessor: "subject",
        Cell: row => {
          const { original } = row;
          let subject = original.subject;
          return (
            <span>
              {subject}
            </span>
          );
        }
      },
      {
        Header: "Date of Modification",
        accessor: "updatedAt",
        Cell: row => {
          const { original } = row;
          let dateOfRejection = original.updatedAt;
          return (
            <span>
              {dateOfRejection
                ? convertISOToUtcDateformate1(dateOfRejection)
                : ""}
            </span>
          );
        }
      },

      {
        Header: "Status",
        //sortable:false,
        accessor: "active", // String-based value accessors!
        Cell: row => {
          const { original } = row;
          return original.active ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
              <span style={{ color: "red" }}>Inactive</span>
            );
        }
      }
    ];
    return columnOrder
  }

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { searchResults, requestList } = this.props;
    const { showSearchResults, totalCount, templateList } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.emailTemplate,
      langs.permissionKeys.view
    );
    let results;
    if (searchResults && searchResults.length && showSearchResults === true) {
      results = searchResults;
    } else if (
      searchResults !== undefined &&
      searchResults.length === 0 &&
      showSearchResults === true
    ) {
      results = [];
    } else {
      results = requestList;
    }
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Email Configuration</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <Portlet fluidHeight={true}>
          <PortletBody>
            {viewPer ? (
              <StandardTable
                handelSearch={this.handleSearch}
                columnOrder={this.renderRecords()}
                totalCount={totalCount}
                allData={templateList}
                data={results}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                //sortable={true}
                handleSort={this.handleSort}
              />
            ) : (
              <div className='text-center' style={{ marginTop: '10px' }}>
                  <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                </div>
              )}
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = store => {
  const { auth, emailConfigurationDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    requestList:
      emailConfigurationDuck.templateList &&
      emailConfigurationDuck.templateList,
    searchResults: emailConfigurationDuck.searchResults
  };
};

export default injectIntl(
  connect(mapStateToProps, emailConfigurationDuck.actions)(TemplateListing)
);
