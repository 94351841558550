import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import '../ad-management/AdManagement.scss';
import { langs } from '../../../../config/localization';
import { renderText } from '../../../../common/layout/FormInput';
import { required, gminLength, maxLengthC100 } from '../../../../config/validation';
import { MESSAGES } from '../../../../config/message';
import ConformModel from '../ad-management/ConfirmModel';
import { checkPermission } from '../../../../common/CheckPermissions';
import { STATUS_CODES } from '../../../../common/StatusCode'

class CompanyRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: '',
      companyRole: '',
      key: ''
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getAllRoleList(langs.keyname.company);
  }

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { user_detail } = this.props.loggedInUser;
    const deletedItem = this.state.deletedItem;
     let reqData = {
      id: deletedItem.id,
      user_id: user_detail.id,
      name:encodeURIComponent(deletedItem.name)
    }
    this.props.deleteRoleById(reqData, res => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_ROLE_SUCCESS)
        this.props.getAllRoleList(langs.keyname.company);
        this.setState({
          showConfirmModal: false
        });
      }
    });
  };

  /**
   * @method addCompanyRole
   * @description called api to add company role
   */
  addCompanyRole = (e) => {
    const { companyRole } = this.state;
    const match = companyRole !== '' && companyRole.trim() !== '' && companyRole.length >= 2 && companyRole.length <= 100;
    if (match) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          name: companyRole,
          section: 'company'
        };
        this.props.commonAddAPI(langs.keyname.roles, requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
            toastr.success(MESSAGES.ADD_ROLE_SUCCESS)
            this.setState({ companyRole: '' })
            document.activeElement.blur();
            this.props.getAllRoleList(langs.keyname.company);
            this.props.dispatch(change('CompanyRole', 'company_role', ''));
            this.props.dispatch(untouch('CompanyRole', 'company_role'));
          }
        });
      }
    }
  };

  /**
   * @method renderCardItem
   * @description render the card item
   */
  renderCardItem = (typeOfListing, per) => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        return (
          <Card
            bg='light'
            style={{
              width: 'auto',
              height: '3.5rem',
              marginRight: 10,
              marginBottom: 10
            }}
            key={data.id}
            value={data.id}
          >
            <Card.Body key={i}>
              <Card.Title>
                <label>{data.name}</label>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                {per && <i
                  className='fa fa-close crossIcon'
                  onClick={() =>
                    this.setState({
                      showConfirmModal: true,
                      openModel: false,
                      deletedItem: data,
                      message: MESSAGES.COMPANY_ROLE_DELETE,
                      key: 'role',
                      count: data.count
                    })
                  }
                ></i>}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { roleList, handleSubmit } = this.props;
    const { showInput, language, showConfirmModal, message, key, count } = this.state;
    const viewPermission = checkPermission(langs.permissionKeys.companyRole, langs.permissionKeys.view)
    const createPermission = checkPermission(langs.permissionKeys.companyRole, langs.permissionKeys.create)
    const deletePermission = checkPermission(langs.permissionKeys.companyRole, langs.permissionKeys.delete)
    if (!viewPermission && !createPermission && !deletePermission) {
      return <div></div>
    }
    return (
      <div className="row">
        <div className='col-md-12'>
          <Portlet fluidHeight={true}>
            <PortletBody>
              {(viewPermission || createPermission) && <Form.Row>
                <Form.Group as={Col} md='4' controlId='validationCustom01'>
                  <h5 style={{ color: '#5d5757', marginBottom: '100' }}>
                    Define Company Role
                  </h5>
                </Form.Group>
              </Form.Row>}
              <div className='cardWrapper'>
                {viewPermission ? this.renderCardItem(roleList, deletePermission) :  <div className='text-center' style={{ marginTop: '10px' }}>
                  <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                </div>}
              </div>
              <div
                className='row'
                style={{
                  borderBottom: '1px solid #ccc', marginTop: 25,
                  marginLeft: 2
                }}
              >
                {createPermission && <label style={{ color: 'black', marginTop: 25, cursor: 'pointer' }} onClick={() => this.setState({ showInput: !showInput })} data-toggle='collapse'><i className='fa fa-plus' aria-hidden='true'></i>&nbsp;&nbsp;Add More
                  </label>}
              </div>
              {showInput &&
                <form
                  noValidate
                  className='form'
                  onSubmit={handleSubmit(this.addCompanyRole.bind(this))}
                >
                  <Row>
                    <Col md='6'>
                      <Field
                        name='company_role'
                        validate={[required, gminLength, maxLengthC100]}
                        value={language}
                        placeholder='Enter Company Role'
                        component={renderText}
                        onChange={e => this.setState({ companyRole: e.target.value.trim() })}
                        onKeyDown={this.addCompanyRole}
                      />
                    </Col>
                  </Row>
                </form>
              }
            </PortletBody>
          </Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            message={message}
            key={key}
            totalCount={count}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, commonDuck, authToken } = store;
  const { loading, roles } = commonDuck;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    roleList: roles && Array.isArray(roles.company) ? roles.company : [],
    loading
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...settingsDuck.actions, ...commonDuck.actions }
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'CompanyRole'
    })(CompanyRole)
  )
);
