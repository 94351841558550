import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import {
    required,
    number,
    greaterThanZeroWithTwoDecimalPlace
} from '../../../../config/validation'
import { renderSelectField, renderNumberField } from '../../../../common/layout/FormInput'
import { toastr } from 'react-redux-toastr'
import { MESSAGES } from '../../../../config/message'

const AddColorForm = ({
    onAddColor,
    handleSubmit,
    reset,
    getfromNPCSetting,
    getAllPaperType,
    disableFormField
}) => {

    // colors option list
    const [colors, setColors] = useState([]);
    const [paperTypes, setPaperTypes] = useState([]);

    /**
     * @method getColorOptions
     * @description returns color options for drop down
     */
    const getColorOptions = () => {
        return (Array.isArray(colors) && colors.length) > 0 ? colors.map(c => ({
            label: c.color_name,
            value: c.id
        })) : []
    }

    /**
     * @method handleOnSubmit
     * @description handle on submit form
     * @param {*} values 
     * @param {*} actions 
     */
    const handleOnSubmit = (values) => {
        if (values.color !== "" && values.cost !== "") {
            const color = colors.find(c => parseInt(c.id) === parseInt(values.color));
            const papertype = paperTypes.find(p => parseInt(p.value) == parseInt(values.papertype));
            if (parseInt(values.cost) >= 0) {
                onAddColor({ id: color.id, color: color.color_name, cost: values.cost, papertype: papertype.label, pid: papertype.value })
            } else {
                toastr.error("Failure", MESSAGES.NEGATIVE_VALUES);
            }
            reset();
        }
    }


    //const [paperTypes, setPaperTypes] = useState([{value:null,label:'select'},{value:1,label:'Glossy'},{value:2,label:'Matte'}]);

    /**
     * Runs on mount to fetch colors options
     */
    useEffect(() => {
        getfromNPCSetting('color', 0, res => {
            if (res.status === 200) {
                setColors(res.data)
            }
        });

        getAllPaperType('0', (res) => {
            if (res.status === 200) {
                const allAddType = res.data;
                let advertisements = allAddType;
                advertisements = advertisements.filter((ad) => ad.is_deleted === false);

                advertisements =
                    Array.isArray(advertisements) &&
                    advertisements.map((ad) => {
                        return {
                            value: ad.id,
                            label: ad.paper_type_name,
                        };
                    });
                setPaperTypes(advertisements);

            }
        });

    }, [])

    return (
        <div className="form-row align-items-center">
            <div className="col-md-3" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="color"
                        component={renderSelectField}
                        label="Color"
                        disabled={disableFormField}
                        options={getColorOptions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        // validate={required}
                        isSelect={false}
                    />
                </div>
            </div>
            <div className="col-md-3" style={{ height: 105 }}>
                <div className="form-group">

                    <Field
                        name="papertype"
                        component={renderSelectField}
                        label="Paper Type"
                        options={paperTypes}
                        disabled={disableFormField}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={false}
                    //Value={paperTypeData}
                    // onChange={(e)=>{setPaperTypeData(e.target.value)}}
                    />
                </div>
            </div>
            <div className="col-md-3 mt-3" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="cost"
                        disabled={disableFormField}
                        component={renderNumberField}
                        validate={[
                            number
                        ]}
                        placeholder="Cost"
                        min="0"
                    />
                </div>
            </div>
            <div className="col-md-3 mt-4" style={{ height: 105 }}>
                <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={handleSubmit(handleOnSubmit)}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>
    )
}

/**
 * @method mapStateToProps
 * @description map redux store state to props
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        color: '',
        cost: ''
    }
})

export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addColorForm',
})(AddColorForm))

