import React from 'react';
import { Alert } from 'react-bootstrap';

class CommonAlert extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: true
        };
    }

    /**
     * @method componentWillReceiveProps
     * @description with recieve props
     */
    componentWillReceiveProps(nextprops, props) {
        if (props.show !== nextprops.show) {
            this.setState({ show: nextprops.show });
        }
    }

    render() {
        const { show } = this.state;
        const { variant, message, alwaysShow } = this.props;
        if (show && !alwaysShow) {
            setTimeout(() => {
                this.setState({ show: false })
                this.props.setShow()
            }, 5000);
        }
        return (
            <div>
                {show && <Alert variant={variant} dismissible onClose={() => this.setState({ show: false })}>
                    {message}
                </Alert>}
            </div>
        )
    }
}

export default CommonAlert;