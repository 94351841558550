import React from "react";
import ReactTable from "react-table";
import moment from "moment";
import "react-table/react-table.css";
import DataTable from "./DataTable";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { MdInfo } from "react-icons/md";
import { RiClockwise2Fill } from "react-icons/ri";
import PlanPopUp from "./PlanPopUp";
import SubscriptionInvoice from "./SubscriptionInvoice";
import * as SubscriptionDuck from "../../../../store/ducks/super-admin/Subscription.duck";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import "./Subscription.scss";
import "./subscriptiontable.scss";
import ascIcon from "../../../../../_metronic/_assets/media/icons/down-arrow.png";
import descIcon from "../../../../../_metronic/_assets/media/icons/up-arrow.png";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import {
  HiOutlineArrowNarrowUp,
  HiOutlineArrowNarrowDown,
} from "react-icons/hi";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
  appVersion,
  USERDATA_KEY,
  Auth_KEY,
  SUPERADMIN_EMAIL,
  SUPERADMIN_ROLE_ID,
} from "../../../../config";

class ActiveSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      planPopup: false,
      allActiveSubscriptionsData: [],
      allActiveSubscriptionsDataDetails: {},
      allActiveSubscriptionsInvoices: [],
      isDownloadingInvoice: [],
      isRedirect: false,
      isRedirectCancel: false,
      isSuperAdmin: false,
    };
  }

  userToken = `${appVersion}-${USERDATA_KEY}`;
  authLocalStorageToken = `${appVersion}-${Auth_KEY}`;

  showSubscriptionDetails(id, subscriptionId) {
    this.props.getActiveSubscriptionsDetails(id, subscriptionId, (res) => {
      this.setState({ allActiveSubscriptionsDataDetails: res });
    });
    this.setState({ planPopup: true });
  }

  showAllInvoices = (userId) => {
    this.props.getActiveSubscriptionsInvoices(userId, (res) => {
      this.setState({ allActiveSubscriptionsInvoices: res });
      if (res && res.length > 0) {
        res.map((item, key) => {
          this.setState({
            isDownloadingInvoice: [...this.state.isDownloadingInvoice, false],
          });
        });
      }
    });
    this.setState({ modalShow: true });
  };

  columns = [
    {
      Header: "Company Name",
      accessor: "company_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.company_name}</span>;
      },
    },
    {
      Header: "Name",
      accessor: "user_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.user_name}</span>;
      },
    },
    {
      Header: "Plan Type",
      accessor: "plan_name",
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            className={
              original.plan_name === "Premium"
                ? "plan_type premium"
                : original.plan_name === "Plus"
                ? "plan_type pro"
                : "plan_type starter"
            }
          >
            {original.plan_name}
          </span>
        );
      },
    },
    {
      Header: "Trial",
      accessor: "dateDiff",
      Cell: (row) => {
        const { original } = row;
        return original.dateDiff > 0 ? (
          <span>
            {original.dateDiff} {original.dateDiff == 1 ? "day" : "days"} left
          </span>
        ) : null;
      },
    },
    {
      Header: "Account Created",
      accessor: "account_created",
      Cell: (row) => {
        const { original } = row;
        return (
          <span>{moment(original.account_created).format("MMM DD, YYYY")}</span>
        );
      },
    },
    {
      Header: "Next Payment Due",
      accessor: "next_payment_date",
      Cell: (row) => {
        const { original } = row;
        return (
          <span>
            {moment(original.next_payment_date).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      accessor: "action",
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="invoice_table">
            <span onClick={() => this.showAllInvoices(original.user_id)}>
              View invoices{" "}
            </span>{" "}
            <MdInfo
              onClick={() =>
                this.showSubscriptionDetails(
                  original.user_id,
                  original.subscriptionStatusId
                )
              }
              className="ml-3"
            />
            {this.state.isSuperAdmin && (
              <RiClockwise2Fill
                size={20}
                onClick={() =>
                  this.props.agentLoginFromAdmin(
                    original.user_id,
                    (response) => {
                      const auth = {
                        token: response.token,
                        expiresIn: response.expiresIn,
                        currentTime: new Date(),
                      };
                      localStorage.setItem(
                        this.authLocalStorageToken,
                        JSON.stringify(auth)
                      );
                      if (response.user_detail.email)
                        delete response.user_detail.email;
                      if (response.user_detail.phone_number)
                        delete response.user_detail.phone_number;
                      localStorage.setItem(
                        this.userToken,
                        JSON.stringify(response.user_detail)
                      );
                      localStorage.removeItem(EDITED_AGENT_NAME);
                      window.open("/email-campaign/dashboard").focus();
                    }
                  )
                }
                className="ml-3"
              />
            )}
          </div>
        );
      },
    },
  ];
  componentDidMount() {
    this.props.getActiveSubscriptions(JSON.stringify(["3"]), (res) => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id");
      const subscriptionId = queryParams.get("subscriptionId");
      if (id && subscriptionId) {
        this.setState({ id: id });
        let data = res.find((data) => {
          return data.user_id == id;
        });
        if (data) {
          this.showSubscriptionDetails(id, subscriptionId);
          this.setState({ allActiveSubscriptionsData: res });
          this.setState({ isRedirect: false });
        } else {
          this.props.subscriptionStatusCheck(id, (response) => {
            if (response.subscriptionStatusId == 4)
              this.setState({ isRedirect: true });
            if (
              response.subscriptionStatusId == 1 ||
              response.subscriptionStatusId == 2
            )
              this.setState({ isRedirectCancel: true });
          });
        }
      } else {
        this.setState({ allActiveSubscriptionsData: res });
        this.setState({ isRedirect: false });
      }
    });
    const getUserData = localStorage.getItem("persist:demo1-auth");
    const loggedUserData = JSON.parse(JSON.parse(getUserData).user).user_detail;
    if (
      loggedUserData.email == SUPERADMIN_EMAIL &&
      loggedUserData.role_id == SUPERADMIN_ROLE_ID
    )
      this.setState({ isSuperAdmin: true });
    else this.setState({ isSuperAdmin: false });
  }
  render() {
    const {
      modalShow,
      planPopup,
      allActiveSubscriptionsData,
      allActiveSubscriptionsDataDetails,
      allActiveSubscriptionsInvoices,
      isDownloadingInvoice,
    } = this.state;
    const { loading } = this.props;
    return (
      <>
        {this.state.isRedirect ? (
          <Switch>
            <Redirect
              exact={true}
              to={`/cancelled-subscription?id=${this.state.id}`}
            />
          </Switch>
        ) : this.state.isRedirectCancel ? (
          <Switch>
            <Redirect exact={true} to={`/incomplete-subscription`} />
          </Switch>
        ) : (
          <>
            {loading && <Loader />}
            <LayoutContextConsumer className="row">
              {({ subheader: { title } }) => (
                <div className="row">
                  <h3 className="kt-subheader__title col-md-6">
                    <span>Active Subscriptions</span>
                  </h3>
                </div>
              )}
            </LayoutContextConsumer>
            <div className="row">
              <div className="col-12 kt-form subsciption_list_content kt-form--label-right">
                <Portlet>
                  <PortletBody>
                    <div className="subscription_Table">
                      <DataTable
                        data={allActiveSubscriptionsData}
                        columns={this.columns}
                      />
                    </div>
                    <SubscriptionInvoice
                      show={modalShow}
                      data={allActiveSubscriptionsInvoices}
                      isDownloadingInvoice={isDownloadingInvoice}
                      onHide={() => this.setState({ modalShow: false })}
                    />
                    <PlanPopUp
                      show={planPopup}
                      data={allActiveSubscriptionsDataDetails}
                      onHide={() =>
                        this.setState({
                          planPopup: false,
                          allActiveSubscriptionsDataDetails: {},
                        })
                      }
                    />
                  </PortletBody>
                </Portlet>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { allActiveSubscriptions } = store.subscriptionDuck;

  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, SubscriptionDuck.actions)(ActiveSubscription)
);

export const EDITED_AGENT_NAME = "editedAgentName";
