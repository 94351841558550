import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import * as adManagementDuck from "../../../../store/ducks/super-admin/AdManagement.duck";
import { injectIntl } from "react-intl";
import DistributionAreas from "./DistributionAreas";
import Edition from "./Edition";
import Payout from "./Payout";
import Language from "./Language";
import CatagoryList from "./CatagoryList";
import ManageAddType from "./manage-ad-type";
import SampleNewsPaper from "./SampleNewsPaper";
import BusinessHours from "./BusinessHours";
import Whatsapp from "./Whatsapp";

class NPCSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      data:{
        validImage:true
      }
    };
  }


validImg = (val)=>{
    this.setState({data: {
      validImage: val,
    }
    });
}

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { showConfirmModal } = this.state;
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Settings</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row">
          <div className="col-12">
            <DistributionAreas showConfirmModal={showConfirmModal} />
            <CatagoryList showConfirmModal={showConfirmModal} />
            <Whatsapp user_detail={this.props.loggedInUser} />
            <Language />
            <Edition showConfirmModal={showConfirmModal} />
            <Payout showConfirmModal={showConfirmModal} />
            <SampleNewsPaper validImg={this.validImg} />
            <ManageAddType />
            <BusinessHours data={this.state.data} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, commonDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    loading: commonDuck.loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...npcDuck.actions, ...adManagementDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "NPCSetting",
    })(NPCSetting)
  )
);
