import React from "react";
import { connect } from "react-redux";
import { Button, Form, Col, Card } from "react-bootstrap";
import { Field, reduxForm, change, untouch } from "redux-form";
import { injectIntl } from "react-intl";
import NavigationPrompt from "react-router-navigation-prompt";
import { Input } from "reactstrap";
import { Row } from "react-bootstrap";
import { renderText } from "../../../../common/layout/FormInput";
import { focusOnError } from "../../FormLayout";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as roleManagementDuck from "../../../../store/ducks/super-admin/RoleManagement.duck";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import {
  required,
  minLength5,
  maxLengthC100,
} from "../../../../config/validation";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import { langs } from "../../../../config/localization";
import { Loader } from "../../../../common/Loader";
import ConformModel from "../ConformModel";
import { toastr } from "react-redux-toastr";
import { permissions } from "./Permissions";
import "./table.scss";
import { STATUS_CODES } from "../../../../common/StatusCode";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditFlag: false,
      value: "",
      number: "",
      email: "",
      success: false,
      error: false,
      isSubmitted: false,
      isNavigate: false,
      showModal: false,
      message: "",
      deletedItem: undefined,
      loading: false,
      loadingButtonStyle: {
        paddingRight: "2.5rem",
      },
      showConfirmModal: false,
      selectedRole: undefined,
      roleName: "",
      items: JSON.parse(JSON.stringify(permissions)),
      copy: Object.assign({}, permissions),
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getAllRoleList();
    this.props.dispatch(change("Roles", "role_name", ""));
    this.props.dispatch(untouch("Roles", "role_name"));
  }

  /**
   * Submit the login form
   * @param values
   * @desc Calling a API to add role
   */
  onSubmit(values) {
    const { items, roleName, selectedRole } = this.state;
    let temp = items;
    Object.keys(items).map((el, index) => {
      if (
        items[el].subsection === "adManagement" ||
        items[el].subsection === "orders" ||
        items[el].subsection === "userManagement" ||
        items[el].subsection === "settings"
      ) {
        if (!items[items[el].subsection].accessRight.value) {
          temp[el].create.value = false;
          temp[el].delete.value = false;
          temp[el].view.value = false;
        }
      }
      if (
        items[el].subsection === "financials" ||
        items[el].subsection === "reports"
      ) {
        if (!items[items[el].subsection].accessRight.value) {
          items[el].print.value = false;
          items[el].view.value = false;
        }
      }
    });
    let reqData = {
      name: roleName.trim(),
      section: "admin",
      permissions: temp,
    };

    if (selectedRole === undefined) {
      this.props.createRole(reqData, (res) => {
        if (
          res.status === STATUS_CODES.CREATED ||
          res.status === STATUS_CODES.OK
        ) {
          toastr.success(MESSAGES.CREATE_Role_SUCCESS);
          this.setState({ isEditFlag: false });
          this.resetPermission();
          this.getAllRoleList();
        }
      });
    } else if (selectedRole.id) {
      reqData.id = selectedRole.id;
      this.props.updateRoleById(reqData, (res) => {
        if (
          res.status === STATUS_CODES.CREATED ||
          res.status === STATUS_CODES.OK
        ) {
          toastr.success(MESSAGES.UPDATE_Role_SUCCESS);
          this.setState({ selectedRole: undefined, isEditFlag: false });
          this.resetPermission();
          this.getAllRoleList();
        }
      });
    }
  }

  /**
   * @method getAllRoleList
   * @description API called to get Role List
   */
  getAllRoleList = () => {
    this.props.getAllRoleList(langs.keyname.admin);
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { user_detail } = this.props.loggedInUser;
    const deletedItem = this.state.deletedItem;
    let reqData = {
      id: deletedItem.id,
      user_id: user_detail.id,
      name: encodeURIComponent(deletedItem.name),
    };
    this.props.deleteRoleById(reqData, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_Role_SUCCESS);
        this.getAllRoleList();
        this.resetPermission();
        this.setState({ showConfirmModal: false, selectedRole: undefined });
      }
    });
  };

  /**
   * @method resetPermission
   * @description Reset ALl of the Checkbox Table
   */
  resetPermission = () => {
    const copiedObj = JSON.parse(JSON.stringify(permissions));
    this.props.dispatch(change("Roles", "role_name", ""));
    this.props.dispatch(untouch("Roles", "role_name"));
    this.setState({ items: { ...copiedObj } });
  };

  /**
   * @method setSelectedRoleValue
   * @description get Selected value and update Checkbox Table as prefilled value
   */
  setSelectedRoleValue = (el) => {
    const { selectedRole } = this.state;
    this.props.change("role_name", selectedRole.name);
    this.setState({
      roleName: selectedRole.name,
      items: { ...el.permissions },
    });
  };

  /**
   * @method renderHorizontalListView
   * @description it render Horizontal List
   */
  renderHorizontalListView = () => {
    return this.props.allRolesListForSuperAdmin.map((el) => {
      if (el.permissions !== "") {
        return (
          <Card
            bg="light"
            style={{
              width: "auto",
              height: "3.5rem",
              marginRight: 10,
              marginBottom: 10,
              marginTop: 10,
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              this.props.change("role_name", el.name);
              this.setState({
                selectedRole: el,
                roleName: el.name,
                items: { ...el.permissions },
              });
            }}
          >
            <Card.Body>
              <Card.Title>
                <label
                  style={{ marginRight: 15 }}
                  onClick={() =>
                    this.setState({ openModel: true, showConfirmModal: false })
                  }
                >
                  {el.name}
                </label>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i
                  className="fa fa-close crossIcon"
                  onClick={() => {
                    this.setState({
                      showConfirmModal: true,
                      openModel: false,
                      deletedItem: el,
                    });
                  }}
                ></i>
              </Card.Title>
            </Card.Body>
          </Card>
        );
      }
    });
  };

  /**
   * @method renderBlankCheckTd
   * @description render Blank table data
   */
  renderBlankCheckTd = (arrayOfBlankBox) => {
    return arrayOfBlankBox.map((el) => {
      return <td></td>;
    });
  };

  /**
   * @method renderBlankTdWithoutBottonBorder
   * @description render Blank table data with border bottom
   */
  renderBlankTdWithoutBottonBorder = (arrayOfBlankBox) => {
    return arrayOfBlankBox.map((el) => {
      return <td style={{ borderBottom: "none" }}></td>;
    });
  };

  /**
   * @method renderCheckBox
   * @description render checkbox inside the table data
   */
  renderCheckBox = (keyName, colName, value, arrayOfCheckbox, checkBoxName) => {
    const { items } = this.state;
    return arrayOfCheckbox.map((el) => {
      return (
        <td>
          <CheckboxRP
            checkBoxStyle={{
              width: 25,
              height: 25,
            }}
            className=""
          >
            <Input
              type="checkbox"
              id={`${keyName}-${checkBoxName}`}
              name={`${keyName}-${checkBoxName}`}
              checked={items[keyName][colName].value ? true : false}
              onChange={(e) => {
                let existValue = items[keyName][colName].value;
                let currentState = items;
                currentState[keyName][colName].value = !existValue;
                this.setState({ items: { ...currentState }, isEditFlag: true });
              }}
              className="position-relative"
            />
          </CheckboxRP>
        </td>
      );
    });
  };

  /**
   * @method renderTable
   * @description render dynamic table
   */
  renderTable = (tableArray) => {
    const { items } = this.state;
    return Object.keys(tableArray).map((el, index) => {
      let key = items[el];
      let show = true;

      // -----------Subsection Access Management----------------
      if (
        key.name === "adType" ||
        key.name === "manageColor" ||
        key.name === "manageClassifiedTemplate"
      ) {
        show = !items["adManagement"].accessRight.value ? false : true;
      } else if (key.name === "orderListing" || key.name === "orderDetails") {
        show = !items["orders"].accessRight.value ? false : true;
      } else if (
        key.name === "admin" ||
        key.name === "newspaperCompany" ||
        key.name === "customer" ||
        key.name === "newspaperCompanyModeration"
      ) {
        show = !items["userManagement"].accessRight.value ? false : true;
      } else if (
        key.name === "bankInformation" ||
        key.name === "companyRole" ||
        key.name === "printFrequency" ||
        key.name === "newspaperCategory" ||
        key.name === "distributionAreas" ||
        key.name === "reasonforRejection" ||
        key.name === "language"
      ) {
        show = !items["settings"].accessRight.value ? false : true;
      } else if (key.name === "payments" || key.name === "receipts") {
        show = !items["financials"].accessRight.value ? false : true;
      } else if (
        key.name === "sales" ||
        key.name === "order" ||
        key.name === "paymentHistory" ||
        key.name === "customerSummary" ||
        key.name === "reportsAdType" ||
        key.name === "companySummary"
      ) {
        show = !items["reports"].accessRight.value ? false : true;
      }

      if (items[el] === true) {
        return (
          <tr>
            {this.renderBlankCheckTd([1])}
            {this.renderBlankCheckTd([1])}
            <th>Access Rights</th>
            <th>View</th>
            <th>Print</th>
          </tr>
        );
      }
      if (!show) {
        return true;
      }
      return (
        <tr>
          {/* Column - 1 */}
          {key.lable && key.lable.show === true ? (
            <td style={{ textAlign: "left" }}>
              <b>{key.lable.lable}</b>
            </td>
          ) : (
            this.renderBlankCheckTd([1])
          )}

          {/* Column - 2 */}
          {key.section && key.section.show === true ? (
            <td style={{ textAlign: "left" }}>
              <b>{key.section.lable}</b>
            </td>
          ) : (
            this.renderBlankCheckTd([1])
          )}

          {/* Column - 3 */}
          {key.accessRight && key.accessRight.show === true
            ? this.renderCheckBox(
                key.name,
                "accessRight",
                key.accessRight,
                [1],
                "accesRight"
              )
            : this.renderBlankCheckTd([1])}

          {key.view && key.view.show === true && key.view.section2 === true
            ? this.renderCheckBox(key.name, "view", key.create, [1], "view")
            : key.create && key.create.show === true
            ? this.renderCheckBox(key.name, "create", key.create, [1], "create")
            : this.renderBlankCheckTd([1])}

          {/* Column - 5 */}
          {key.print && key.print.show === true && key.print.section2 === true
            ? this.renderCheckBox(key.name, "print", key.print, [1], "print")
            : key.delete && key.delete.show === true
            ? this.renderCheckBox(key.name, "delete", key.delete, [1], "delete")
            : this.renderBlankCheckTd([1])}

          {/* Column - 6 */}
          {key.view && key.view.show === true && key.view.section2 === undefined
            ? this.renderCheckBox(key.name, "view", key.view, [1], "view")
            : this.renderBlankCheckTd([1])}
        </tr>
      );
    });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit, loading } = this.props;
    const {
      isEditFlag,
      selectedRole,
      roleName,
      items,
      showConfirmModal,
      message,
    } = this.state;
    return (
      <div>
        {loading && <Loader />}
        <NavigationPrompt when={isEditFlag}>
          {(props) => {
            const { onConfirm, onCancel } = props;
            return (
              <NavigateModal
                show={true}
                alertHeading={"Changes made will be lost."}
                cancleButtonText="Cancel"
                okButtonText="Leave Anyway"
                onOkClick={() => onConfirm()}
                onClickCancel={() => {
                  onCancel();
                }}
                showCancel={true}
              />
            );
          }}
        </NavigationPrompt>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Add Role Type</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="col-md-12">
          <Portlet fluidHeight={true}>
            <PortletBody>
              <Form
                noValidate
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Form.Row>
                  <Form.Group as={Col} md="10" controlId="validationCustom01">
                    <Field
                      name="role_name"
                      value={roleName}
                      label="Add New Role"
                      validate={[required, minLength5, maxLengthC100]}
                      required={true}
                      placeholder="Enter Role"
                      onChange={(e) => {
                        this.setState({
                          roleName: e.target.value.trim(),
                          isEditFlag: true,
                        });
                      }}
                      component={renderText}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <div className="table-responsive">
                  <table id="roles">
                    <thead>
                      <tr>
                        <th
                          colSpan="2"
                          style={{
                            textAlign: "left",
                            width: 225,
                            borderBottom: "none",
                          }}
                        >
                          Modules
                        </th>
                        <th style={{ borderBottom: "none" }}></th>
                        <th colSpan="4" style={{ textAlign: "left" }}>
                          <b style={{ marginLeft: 65 }}>Permissions</b>
                        </th>
                      </tr>

                      <tr>
                        <th></th>
                        <th style={{ textAlign: "left" }}>Sub Sections</th>
                        <th>Access Rights</th>
                        <th>Create/Edit</th>
                        <th>Delete</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderTable(items)}</tbody>
                  </table>
                </div>
                <Form.Row className="j-end">
                  {selectedRole === undefined && (
                    <ButtonRP text="ADD ROLE" type="submit" className="my-3" />
                  )}
                  {selectedRole !== undefined && (
                    <ButtonRP
                      text="UPDATE ROLE"
                      type="submit"
                      className="my-3"
                      disabled={isEditFlag ? false : true}
                    />
                  )}
                </Form.Row>
              </Form>
              <Row style={{ marginTop: 40 }}>Existing Define Roles</Row>
              <Row>{this.renderHorizontalListView()}</Row>
              <ConformModel
                isOpen={showConfirmModal}
                onCancelClick={this.cancelDelete}
                onOkClick={this.confirmDelete}
                message={message}
              />
            </PortletBody>
          </Portlet>
        </div>
      </div>
    );
  }
}

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
  let errors = {};

  if (!values.role_name) {
    errors.newPassword = "Role Name is Required.";
  }

  return errors;
}
const mapStateToProps = (store) => {
  const { commonDuck } = store;
  const { roles } = commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading: commonDuck.loading,
    allRolesListForSuperAdmin: Array.isArray(roles.admin) ? roles.admin : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...roleManagementDuck.actions,
    ...commonDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "Roles",
      validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(Roles)
  )
);
