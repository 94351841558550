import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { apiErrors } from '../../common/HandleAPIErrors'
import {
  API
} from '../../config';

export const actionTypes = {
  GetSuperAdminProfile: "[GetSuperAdminProfile] Action",
  UpdateSuperAdminProfile: "[UpdateSuperAdminProfile] Action",
  UpdateAdminProfile: "[UpdateAdminProfile] Action",
  ResetAllAdminList: "[ResetAllAdminList] Action",
  ResetAllCompanyList: "[ResetAllCompanyList] Action",
  ResetAllArchiveAdminList: "[ResetAllArchiveAdminList] Action",
  ResetAllArchiveCompanyList: "[ResetAllArchiveCompanyList] Action",
  SetRoleList: "[SetRoleList] Action",
  getLocationList: "[getLocationList] Action",
  RemoveArchieveAdmin: "[RemoveArchieveAdmin] Action",
  RemoveAdmin: "[RemoveAdmin] Action",
  RemoveCompany: "[RemoveCompany] Action",
  RemoveArchieveCompany: "[RemoveArchieveCompany] Action",
  SetSelectedCompany: "[SetSelectedCompany] Action",
  SetCompanyDetail: "[SetCompanyDetail] Action",
  UpdateCompanyProfile: "[UpdateCompanyProfile] Action",
  SetSearchResultAdmin: "[SetSearchResultAdmin] Action",
  SetSearchResultArchieveAdmin: "[SetSearchResultArchieveAdmin] Action",
  SetSearchResultForCompany: "[SetSearchResultForCompany] Action",
  SetSearchResultForArchieveCompany: "[SetSearchResultForArchieveCompany] Action",
  SetSearchResultCustomerRequest: "[SetSearchResultCustomerRequest] Action",
  RejectRequest: "[RejectRequest] Action",
  uniqueEmail: "[uniqueEmail] Action",
  AdvanceSearchRecords: "[AdvanceSearchRecords] Action",
  ResetAdvanceSearch: "[ResetAdvanceSearch] Action",
  ResetAdvanceSearch2: "[ResetAdvanceSearch2] Action",
  AllAdTypeList: "[AllAdTypeList] Action",
  AllColorList: "[AllColorList] Action",
  AllRoleList: "[AllRoleList] Action",
  GetAllRecords: "[GetAllRecords] Action",
  AllPrintFrequencyList: "[AllPrintFrequencyList] Action",
  AllCategoryList: "[AllCategoryList] Action",
  SetSuperAdminSetingList: "[SetSuperAdminSetingList] Action",

  AllReasonList: "[AllReasonList] Action",
  AllLanguageList: "[AllLanguageList] Action",
  SessionExpired: "[SessionExpired] Action",
  SetSelectedAdType: "[SetSelectedAdType] Action",
  //---------------Milestone-2 -------------------------
  SetGlobalMessage: "[SetGlobalMessage] Action",
  SetSelectedCustomer: "[SetSelectedCustomer] Action",
  SetSearchResultCustomer: "[SetSearchResultCustomer] Action",
  SetSearchResultArchieveCustomer: "[SetSearchResultArchieveCustomer] Action",
  SetCategoryList: "[SetCategoryList] Action",
  SetSelectedTemplates: "[SetSelectedTemplates] Action",
  SetRoleListForSuperAdmin: "[SetRoleListForSuperAdmin] Action",
  ManageGlobalRes: "[ManageGlobalRes] Action",
  SetUsersActivityLog: "[SetUsersActivityLog] Action",
};

const initialAuthState = {
  profile: undefined,
  adminList: [],
  archiveAdminList: [],
  roleList: undefined,
  selectedAdmin: undefined,
  selectedState: [],
  selectedCities: [],
  selectedCompany: undefined,
  customerRequestList: [],
  searchResultsforAdmin: [],
  searchResultsforArchieveAdmin: [],
  searchResultsforNewspaper: [],
  searchResultsforArchieveNewspaper: [],
  searchResultsforCustomerRequest: [],
  searchResultsforCustomer: [],
  searchResultsforArchieveCustomer: [],
  sessionExpired: false,
  globalMessage: {},
  selectedCustomer: undefined,
  categoryList: [],
  roleListForSuperAdmin: [],
  selectedTemplates: [],
  superAdminSettingList: {},

  toastMsg: {
    showGlobalMsg: false,
    successType: false,
    globalMessages: "",
    isSubmitted: true
  }

};

/** set data of api's into reducer */
export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {

      case actionTypes.SessionExpired: {
        return { ...state, sessionExpired: true };
      }

      /** set results from search */
      case actionTypes.SetSearchResultAdmin: {
        const { result } = action.payload;
        return { ...state, searchResultsforAdmin: result };
      }

      /** set results from search */
      case actionTypes.SetSearchResultArchieveAdmin: {
        const { result } = action.payload;
        return { ...state, searchResultsforArchieveAdmin: result };
      }

      /** set results from search */
      case actionTypes.SetSearchResultForCompany: {
        const { companyList } = action.payload;
        return { ...state, searchResultsforNewspaper: companyList };
      }

      /** set results from search */
      case actionTypes.SetSearchResultForArchieveCompany: {
        const { companyList } = action.payload;
        return { ...state, searchResultsforArchieveNewspaper: companyList };
      }

      /** set results from search */
      case actionTypes.SetSearchResultCustomer: {
        const { result } = action.payload;
        return { ...state, searchResultsforCustomer: result };
      }

      /** set results from search */
      case actionTypes.SetSearchResultArchieveCustomer: {
        const { result } = action.payload;
        return { ...state, searchResultsforArchieveCustomer: result };
      }


      /** get all records of list */
      case actionTypes.GetAllRecords: {
        const { List } = action.payload;
        return { ...state, List: List };
      }

      /** get all records of role list */
      case actionTypes.AllRoleList: {
        const { roleList } = action.payload;
        return { ...state, roleList: roleList };
      }

      /** get all records of frequency list */
      case actionTypes.AllPrintFrequencyList: {
        const { frequencyList } = action.payload;
        return { ...state, frequencyList: frequencyList };
      }

      /** get all records of category list */
      case actionTypes.AllCategoryList: {
        const { categoryList } = action.payload;
        return { ...state, categoryList: categoryList };
      }

      /** get all records of reason list */
      case actionTypes.AllReasonList: {
        const { reasonList } = action.payload;
        return { ...state, reasonList: reasonList };
      }

      /** get all records of language list */
      case actionTypes.AllLanguageList: {
        const { languageList } = action.payload;
        return { ...state, languageList: languageList };
      }

      /** get all records of company list */
      case actionTypes.ResetAllCompanyList: {
        const { companyList, totalCompany } = action.payload;
        return { ...state, companyList: companyList, totalCompany: totalCompany };
      }

      /** get all records of company archieve list */
      case actionTypes.ResetAllArchiveCompanyList: {
        const { archiveCompanyList } = action.payload;
        return { ...state, archiveCompanyList: archiveCompanyList };
      }

      /** get all records of user roles list */
      case actionTypes.SetRoleList: {
        const { roles } = action.payload;
        return { ...state, roles: roles };
      }


      /** set data for adding new admin */
      case actionTypes.AddAdmin: {
        const { admin } = action.payload;
        return { ...state, roles: [admin, ...state.adminList] };
      }

      case actionTypes.AdvanceSearchRecords: {
        const { advanceSearchRecords } = action.payload;
        return { ...state, advanceSearchRecords: [...advanceSearchRecords] };
      }
      case actionTypes.ResetAdvanceSearch: {
        const { advanceSearchRecords } = action.payload;
        return { ...state, advanceSearchRecords: advanceSearchRecords };

      }
      case actionTypes.ResetAdvanceSearch2: {
        return { ...state, advanceSearchRecords: [] };
      }

      /** get data of individual admin by id */
      case actionTypes.SetSelectedCompany: {
        const { selectedCompany } = action.payload;
        return { ...state, selectedCompany: selectedCompany };
      }

      /** get data of individualad type detail by id */
      case actionTypes.SetSelectedAdType: {
        const { selectedAdType } = action.payload;
        return { ...state, selectedAdType: selectedAdType };
      }

      /** set data of individual Company by id */
      case actionTypes.SetCompanyDetail: {
        const { company } = action.payload;
        return { ...state, selectedCompany: company };
      }

      /** get data of individualad type detail by id */
      case actionTypes.SetSuperAdminSetingList: {
        const { apiKey, result } = action.payload;
        let superAdminSettingList = state.superAdminSettingList;
        superAdminSettingList[apiKey] = result;
        superAdminSettingList = { ...superAdminSettingList, [apiKey]: superAdminSettingList[apiKey] }
        return { ...state, superAdminSettingList };
      }

      /** remove selected admin from admin records */
      case actionTypes.RemoveAdmin: {
        const { adminId } = action.payload;
        let deleteIndex = state.adminList.findIndex((el) => el.id === adminId)
        if (deleteIndex !== -1) {
          let temp = state.adminList && state.adminList.filter(function (item, index) {
            return index != deleteIndex
          });
          return { ...state, adminList: [...temp] };
        } else {
          return state;
        }
      }

      /** remove selected company from company records */
      case actionTypes.RemoveCompany: {
        const { companyId } = action.payload;
        let deleteIndex = state.companyList.findIndex((el) => el.id === companyId)
        if (deleteIndex !== -1) {
          let temp = state.companyList && state.companyList.filter(function (item, index) {
            return index != deleteIndex
          });
          return { ...state, companyList: [...temp] };
        } else {
          return state;
        }
      }

      /** Activate selected admin from archieved admin records */
      case actionTypes.RemoveArchieveAdmin: {
        const { archieveAdminId } = action.payload;
        let deleteIndex = state.archiveAdminList.findIndex((el) => el.id === archieveAdminId)
        if (deleteIndex !== -1) {
          let temp = state.archiveAdminList && state.archiveAdminList.filter(function (item, index) {
            return index != deleteIndex
          });
          return { ...state, archiveAdminList: [...temp] };
        } else {
          return state;
        }
      }
      /** Activate selected company from archieved company records */
      case actionTypes.RemoveArchieveCompany: {
        const { archieveCompanyId } = action.payload;
        let deleteIndex = state.archiveCompanyList.findIndex((el) => el.id === archieveCompanyId)
        if (deleteIndex !== -1) {
          let temp = state.archiveCompanyList && state.archiveCompanyList.filter(function (item, index) {
            return index != deleteIndex
          });
          return { ...state, archiveCompanyList: [...temp] };
        } else {
          return state;
        }
      }

      /** SET a Global Message for Success & Error */
      case actionTypes.SetGlobalMessage: {
        const { isError, message, show } = action.payload;
        if (show) {
          return {
            ...state, globalMessage: {
              isError: isError,
              message: message
            }
          };
        } else {
          return {
            ...state, globalMessage: {}
          };
        }
      }

      /** SET a Global Message for Success & Error */
      case actionTypes.SetSelectedCustomer: {
        const { selectedCustomer } = action.payload;
        return {
          ...state, selectedCustomer: selectedCustomer
        };
      }

      /** set data for customer List */
      case actionTypes.SetCategoryList: {
        const { categoryList } = action.payload;
        return { ...state, categoryList: [...categoryList] };
      }

      /** set data for role List */
      case actionTypes.SetRoleListForSuperAdmin: {
        const { roleList } = action.payload;
        return { ...state, roleListForSuperAdmin: [...roleList] };
      }

      /** set data for customer List */
      case actionTypes.SetSelectedTemplates: {
        const { templateList } = action.payload;
        return { ...state, selectedTemplates: [...templateList] };
      }

      /** set data for customer List */
      case actionTypes.ManageGlobalRes: {
        const { globalMessages, showGlobalMsg, isSubmittedGlobal, successType } = action.payload;
        let toastMsg = {
          showGlobalMsg: showGlobalMsg,
          successType: successType,
          globalMessages: globalMessages,
          isSubmittedGlobal: isSubmittedGlobal
        }
        return { ...state, toastMsg: { ...toastMsg } };
      }

      /** get data of individualad type detail by id */
      case actionTypes.SetUsersActivityLog: {
        const { result } = action.payload;
        let usersActivitylog = result;
        return { ...state, usersActivitylog };
      }

      /** set default state */
      default:
        return state;
    }
  },

);

/** Actions creator */
export const actions = {

  /**
 * @method getAllRoleList
 * @description get all company role list 
 */
  getAllRoleList: (authToken, callback) => {
    const request = axios.get(`${API.generalAPI}/roles?role=company`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.AllRoleList, payload: { roleList: response.data } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
    * @method getAllPrintFrequencyList
    * @description get all print frequency list 
    */
  getAllPrintFrequencyList: (api, id, authToken, callback) => {
    const request = axios.get(`${API.generalAPI}/${api}?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.AllPrintFrequencyList, payload: { frequencyList: response.data } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },


  /**
    * @method getAllCategoryList
    * @description get all Category list 
    */
  getAllCategoryList: (apiKey, id, authToken, callback) => {
    const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.AllCategoryList, payload: { categoryList: response.data } })
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method getAllReasonList
  * @description get all reason list 
  */
  getAllReasonList: (api, authToken, callback) => {
    const request = axios.get(`${API.genericAPI}/${api}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.AllReasonList, payload: { reasonList: response.data.result } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method getAllLanguageList
  * @description get all language list
  */
  getAllLanguageList: (key, id, authToken, callback) => {
    const request = axios.get(`${API.generalAPI}/${key}?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.AllLanguageList, payload: { languageList: response.data } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
 * @method getAllNewspaperList
 * @description get all company listing/records 
 */
  getAllNewspaperList: (requestData, authToken, callback) => {
    const { row, page, is_deleted, status } = requestData;
    const request = axios.get(`${API.getAllNewspaperList}?row=${row}&page=${page}&is_deleted=${is_deleted}&status=${status}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.ResetAllCompanyList, payload: { companyList: response.data.rows, totalCompany: response.data.count } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
   * @method getAllNewspaperList
   * @description get all company listing/records 
   */
  resetAllNewspaperList: (companyList) => {
    return (dispatch) => {
      return dispatch({ type: actionTypes.ResetAllCompanyList, payload: { companyList: companyList.data.rows, totalCompany: companyList.data.count } })

    }
  },

  /**
  * @method getAllCompanyArchieveList
  * @description getting All Company ArchieveList  
  */
  getAllCompanyArchieveList: (requestData, authToken, callback) => {
    const { id, row, page, is_deleted, status } = requestData;
    const request = axios.get(`${API.getAllNewspaperList}?row=${row}&page=${page}&is_deleted=${is_deleted}&status=${status}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.ResetAllArchiveCompanyList, payload: { archiveCompanyList: response.data.rows } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },


  /**
  * @method get All Role List API
  * @description Update admin details 
  */
  getRoleList: () => {
    const request = axios.get(`${API.getRole}`);
    return (dispatch) => {
      request.then((response) => {
        return dispatch({ type: actionTypes.SetRoleList, payload: { roles: response.data } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
      });
    };
  },

  /**
     * @method updateBankInformation
     * @description update Bank Information
     */
  updateBankInformation: (requestData, authToken, callback) => {
    const request = axios.put(API.genericNewsPaper, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
   * @method addPrintFrequencyAPI
   * @description Add print frequency api
   */
  addPrintFrequencyAPI: (api, requestData, authToken, callback) => {
    const request = axios.post(`${API.genericAPI}/${api}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
 * @method addReasonAPI
 * @description Add reason api
 */
  addReasonAPI: (api, requestData, authToken, callback) => {
    const request = axios.post(`${API.genericAPI}/${api}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
 * @method addCategoryAPI
 * @description Add category api
 */
  addCategoryAPI: (api, requestData, authToken, callback) => {
    const request = axios.post(`${API.generalAPI}/${api}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method addLocationAPI
  * @description Add area and state
  */
  addLocationAPI: (api, requestData, authToken, callback) => {
    const request = axios.post(`${API.generalAPI}/${api}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method addNewCompany
  * @description Add new newspaper company 
  */
  addNewCompany: (requestData, authToken, callback) => {
    const request = axios.post(API.addNewsPaperCompany, { newspaper: requestData }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
   * @method getCompanyDetailById
   * @description Update admin details 
   */
  getCompanyDetailById: (superAdminId, companyId, isEditFlag, authToken, callback) => {
    if (isEditFlag) {
      const request = axios.get(`${API.getAllNewspaperById}?id=${companyId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });

      return (dispatch) => {
        request.then((response) => {
          callback(response);
          return dispatch({ type: actionTypes.SetSelectedCompany, payload: { selectedCompany: response.data } })

        }).catch((error) => {
          apiErrors(error, dispatch);
          //tokenCheck(dispatch, error)
          callback(error);
        });
      };
    } else {
      return (dispatch) => {
        callback({});
        return dispatch({ type: actionTypes.SetSelectedCompany, payload: {} })
      };
    }
  },

  // /**
  // * @method getCompanyDetailById
  // * @description Update admin details 
  // */
  // getAdTypeDetailById: (adTypeId, authToken, callback) => {
  //   const request = axios.get(`${API.genericAdTypeAPI}?id=${adTypeId}&&is_deleted=${0}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${authToken}`
  //     }
  //   });

  //   return (dispatch) => {
  //     request.then((response) => {
  //       callback(response);
  //       return dispatch({ type: actionTypes.SetSelectedAdType, payload: { selectedAdType: response.data.result } })

  //     }).catch((error) => {
  //apiErrors(error, dispatch);//       
  //tokenCheck(dispatch, error)
  //       callback(error);
  //     });
  //   };
  // },

  /**
   * @method deleteCompanyById
   * @description delete newspaper company details
   */
  deleteCompanyById: (loggedInSuperAdminId, companyId, user_id, authToken, callback) => {
    const request = axios.delete(`${API.deleteCompanyDetails}?id=${companyId}&user_id=${user_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.RemoveAdmin, payload: { companyId: companyId } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method deletePrintFrequencyById
  * @description delete print frequency by id
  */
  deletePrintFrequencyById: (api, Id, authToken, callback) => {
    const request = axios.delete(`${API.genericAPI}/${api}?id=${Id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method deleteLanguageById
  * @description delete language by id
  */
  deleteLanguageById: (api, Id, authToken, callback) => {
    const request = axios.delete(`${API.genericAPI}/${api}?id=${Id}&active=true`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
   * @method deleteReasonById
   * @description delete reason by id
   */
  deleteReasonById: (api, Id, authToken, callback) => {
    const request = axios.delete(`${API.genericAPI}/${api}?id=${Id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method activateCompanyById
  * @description Activate company by id
  */
  activateCompanyById: (requestData, authToken, callback) => {
    const request = axios.patch(`${API.updateCompanyDetails}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.RemoveArchieveAdmin, payload: { archieveCompanyId: requestData.id } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  searchInNewspaperList: (requestData, authToken) => {
    const { searchKey, is_deleted, status } = requestData
    const request = axios.get(`${API.searchInNewspaperList}?search=${searchKey}&is_deleted=${is_deleted}&status=${status}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        if (!is_deleted) {
          return dispatch({ type: actionTypes.SetSearchResultForCompany, payload: { companyList: response.data.rows } })
        } else if (is_deleted) {
          return dispatch({ type: actionTypes.SetSearchResultForArchieveCompany, payload: { companyList: response.data.rows } })
        }

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        return error;
      });
    };
  },

  /**
  * @method addNewCompanyFromFrontEnd
  * @description Add new newspaper company  from front
  */
  addNewCompanyFromFrontEnd: (requestData, callback) => {
    const request = axios.post(API.addNewsPaperCompany, { newspaper: requestData }, {

    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },


  /**
  * @method uniqueEmail
  * @description check unique email
  */
  uniqueEmail: (requestData, callback) => {
    const request = axios.post(API.uniqueEmail, requestData);
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method uniqueEmail
  * @description check unique email
  */
  uniqueEmailForEdit: (requestData, authToken, callback) => {
    const request = axios.post(API.uniqueEmailForEdit, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
    * @method rejectRequest
    * @description reject request by super admin
    */
  messageCompany: (requestData, authToken, callback) => {
    const request = axios.patch(`${API.messageCompany}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
    * @method getListingFromAdvanceSearch
    * @description get all records based on advance search
    */
  getListingFromAdvanceSearch: (authToken, requestData, callback) => {
    let api_call;
    if (requestData.newspaper_name || requestData.language || requestData.distribution_type || requestData.distribution_state || requestData.distribution_area || requestData.role_id || requestData.distributed_copies || requestData.email_distribution || requestData.category || requestData.active) {
      api_call = `${API.getRecordsFromAdvanceSearch}?newspaper_name=${requestData.newspaper_name}&active=${requestData.active}&language=${requestData.language}&distribution_type=${requestData.distribution_type}&distribution_state=${requestData.distribution_state}&distribution_area=${requestData.distribution_area}&role_id=${requestData.role_id}&distributed_copies=${requestData.distributed_copies}&email_distribution=${requestData.email_distribution}&category=${requestData.category}`
      const request = axios.get(api_call, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      return (dispatch) => {
        request.then((response) => {
          callback(response);
          return dispatch({ type: actionTypes.AdvanceSearchRecords, payload: { advanceSearchRecords: response.data.rows } })
        }).catch((error) => {
          apiErrors(error, dispatch);
          //tokenCheck(dispatch, error)
          callback(error);
        });
      };
    }
  },

  /**
  * @method getRangeOfCopiesList
  * @description get all range odf copies list 
  */
  getRangeOfCopiesList: (api, id, authToken, callback) => {
    const request = axios.get(`${API.generalAPI}/${api}?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.GetAllRecords, payload: { List: response.data } })

      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method deleteRangeOfCopiesById
  * @description delete range of copies by id
  */
  deleteRangeOfCopiesById: (api, roleId, authToken, callback) => {
    const request = axios.delete(`${API.genericAPI}/${api}?id=${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
   * @method addRangeOfCopiesAPI
     * @description Add range of api
     */
  addRangeOfCopiesAPI: (api, requestData, authToken, callback) => {
    const request = axios.post(`${API.genericAPI}/${api}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  /**
  * @method getfromSuperAdminSetting
  * @description get all distribution areas list 
  */
  getfromSuperAdminSetting: (apiKey, id, authToken, callback) => {
    const request = axios.get(`${API.generalAPI}/${apiKey}?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.SetSuperAdminSetingList, payload: { apiKey: apiKey, result: response.data } })
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },
  /**
    * @method getListingFromAdvanceSearch
    * @description get all records based on advance search
    */
  resetAdvanceSearch: (res) => {
    return (dispatch) => {
      return dispatch({ type: actionTypes.ResetAdvanceSearch, payload: { advanceSearchRecords: res.data.rows } })
    }
  },
  resetAdvanceSearch2: (res) => {
    return (dispatch) => {
      return dispatch({ type: actionTypes.ResetAdvanceSearch2, payload: { advanceSearchRecords: res.data.rows } })
    }
  },
  /**
   * @method updateTemplateById
   * @description update template 
   */
  updateTemplateById: (reqData, authToken, callback) => {
    let api_call = `${API.getClassifiedTemplate}`
    const request = axios.put(api_call, reqData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    }
  },

  /**
   * @method deleteTemplateById
   * @description delete template type by id
   */
  deleteTemplateById: (templateId, authToken, callback) => {
    const request = axios.delete(`${API.genericAPI}/classified_template?id=${templateId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },

  getUsersActivityLog: (requestData,callback) => {
    const {
      data = "",
      type = "",
      sort = "",
      agentTotalFilterOption = "",
      month = "",
      year = "",
      fromDate = "",
      toDate = "",
      page = "",
      row = "",
      search = "",
    } = requestData;
    const request = axios.get(`${API.getUsersActivityLog}?data=${data}&module=${type}&sort=${sort}&agentTotalFilterOption=${agentTotalFilterOption}&month=${month}&year=${year}&fromDate=${fromDate}&toDate=${toDate}&row=${row}&page=${page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${authToken}`
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
        return dispatch({ type: actionTypes.SetUsersActivityLog, payload: { result: response.data } })
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },


  getAllActiveSeasonalIssueForUser: (callback) => {
    const request = axios.get(`${API.getAllActiveSeasonalIssueForUser}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    };
  },
  getAllPublisherSeasonalIssue: (requestData,callback) => {
    const request = axios.post(`${API.getAllPublisherForParticularSeasonalIssue}`,requestData, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return (dispatch) => {
      request.then((response) => {
        callback(response);
      }).catch((error) => {
        apiErrors(error, dispatch);
        //tokenCheck(dispatch, error)
        callback(error);
      });
    }
  }
};


export function* saga() {
  yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
  });
}





