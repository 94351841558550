import React, { useState, useEffect } from 'react';
import { Paper, Switch, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { STATUS_CODES } from "../../../../common/StatusCode";
import { Field, reduxForm } from 'redux-form';
import { toastr } from "react-redux-toastr";
import * as NPCDuck from '../../../../store/ducks/npc.duck';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckActivity = ({
    disableFormField,
    planActivity,
    planDeatils,
    updatePlanActivity,
    invalidPlan
}) => {
    const history = useHistory();
    const [activity, setActivity] = useState();
    const [count, setCount] = useState(0);
    const [disableField, setDisableField] = useState(false);

    useEffect(() => {
        if (count == 0)
            setActivity(planActivity);
        if (invalidPlan)
            setDisableField(false);
        else
            setDisableField(disableFormField);
    });

    const handleChange = (event) => {
        if (!event.target.checked)
            setActivity(true);
        else
            setActivity(false);
        setCount(1);
        let data = {
            npc_id: planDeatils.npc_id,
            id: planDeatils.id,
            active: !activity,
            type: planDeatils.type
        }
        updatePlanActivity(data, (res) => {
            if (
                String(res.status) === String(STATUS_CODES.CREATED) ||
                res.status === STATUS_CODES.OK
            ) {
                toastr.success("Success", res.data);
                history.push(`/ourPlans`);
            }
        }
        );
    };

    return (
        <>
            <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
            >
                <Grid item className="mr-4">
                    Status
                </Grid>
                <p item className="text-success m-0">Active</p>
                <FormControlLabel
                    style={{ margin: "0px" }}
                    control={
                        <Switch
                            checked={!activity}
                            onChange={handleChange}
                            disabled={disableField}
                            name="activity"
                            color="secondary"
                        />
                    }
                />
                <p Item className="text-danger m-0">Inactive</p>
            </Grid>
        </>
    )
}

/**
 * @method mapStateToProps
 * @description map redux store state to props
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state) => {
    const { npcDuck } = state;
    const { npcPlan } = npcDuck;
}

export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'check-activity',
})(CheckActivity))

