import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

export default class WhatsappCredentials extends Component {
    render() {
        const { data } = this.props;
        return (
            <Portlet fluidHeight={true} style={{ height: "auto" }}>
                <PortletBody>
                    <div
                        style={{
                            borderBottom: "1px solid #ccc"
                        }}
                        className="row payoutHeader"
                    >
                        <h4 className="col-md-8">Whatsapp Settings</h4>
                        <Link to="/UpdateWhatsappsettings" className="col-md-4">
                            <h6 className="text-md-right">Update Whatsapp Settings &#62; </h6>
                        </Link>
                    </div>
                    <div
                        className="row ml-3"
                        style={{
                            marginTop: 25,
                        }}
                    >
                        <i
                            style={{ fontSize: 40 }}
                            className="fa fa-university mr-4"
                            aria-hidden="true"
                        ></i>
                        {data !== undefined &&
                            data.SMTP_SERVICE !== "" ? (
                            <div
                                className="text-break"
                                style={{ marginTop: 4 }}
                            >
                                <h6>{"ACCOUNT SID:  "}{data.ACCOUNT_SID}</h6>
                                <h6>{"AUTH TOKEN:  "}{data.AUTH_TOKEN}</h6>
                                <h6>{"FORM PHONE NUMBER:  "}{data.FORM_PHONE_NUMBER}</h6>
                                <h6>{"FRONTEND URL:  "}{data.FRONTEND_URL}</h6>
                                <h6>{"TO PHONE NUMBER:  "}{data.TO_PHONE_NUMBER}</h6>
                            </div>
                        ) : (
                            <h6 style={{ margin: 10 }}>No Whatsapp record found</h6>
                        )}
                    </div>
                </PortletBody>
            </Portlet>
        )
    }
}