import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import { MESSAGES } from '../../../../config/message';
import '../ad-management/AdManagement.scss';
import ConformModel from '../ad-management/ConfirmModel';
import { langs } from '../../../../config/localization';
import { renderText } from '../../../../common/layout/FormInput';
import { required, gminLength, maxLengthC100 } from '../../../../config/validation';
import { checkPermission } from '../../../../common/CheckPermissions';
import { STATUS_CODES } from '../../../../common/StatusCode'

class PrintFrequency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: '',
      adTypeFlag: false,
      colorFlag: false,
      printFrequency: '',
      key: ''
    };
  }

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getfromSuperAdminSetting(langs.keyname.print_frequency, '', res => { })
  }


  /** 
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
  * @method confirmDelete
  * @description confirm delete bought out part
  */
  confirmDelete = () => {
    const deletedItem = this.state.deletedItem;
    const { user_detail } = this.props.loggedInUser;
    let reqData = {
      id: deletedItem.id,
      user_id: user_detail.id,
      advertisement_id: deletedItem.id,
      name: encodeURIComponent(deletedItem.print_frequency)
    }
    this.props.commonDeleteAPI(langs.keyname.print_frequency, reqData, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        this.props.getfromSuperAdminSetting(langs.keyname.print_frequency, '', res => { })
        toastr.success(MESSAGES.DELETE_PRINT_FREQUENCY_SUCCESS)
        this.setState({
          showConfirmModal: false,
        });
      }
    })
  }

  /**
  * @method addPrintFrequency
  * @description called api to add print frequency
  */
  addPrintFrequency = (e) => {
    const { printFrequency } = this.state;
    const match = printFrequency !== '' && printFrequency.trim() !== '' && printFrequency.length >= 2 && printFrequency.length <= 100;
    if (match) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          print_frequency: printFrequency
        }
        this.props.commonAddAPI(langs.keyname.print_frequency, requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
            this.props.getfromSuperAdminSetting(langs.keyname.print_frequency, '', res => { })
            toastr.success(MESSAGES.ADD_PRINT_FREQUENCY_SUCCESS)
            this.setState({ printFrequency: '' })
            document.activeElement.blur();
            this.props.dispatch(change('PrintFrequency', 'print_frequency', ''));
            this.props.dispatch(untouch('PrintFrequency', 'print_frequency'));
          }
        })
      }
    }
  }

  /**
   * @method renderCardItem
   * @description render the card item
   */
  renderCardItem = (typeOfListing, per) => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        return (
          <Card key={i} bg='light' style={{ width: 'auto', height: '3.5rem', marginRight: 10, marginBottom: 10 }} >
            <Card.Body>
              <Card.Title><label>{data.print_frequency}</label>
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                {per && <i className='fa fa-close crossIcon' onClick={() =>
                  this.setState({ showConfirmModal: true, deletedItem: data, message: MESSAGES.PRINT_FREQUENCY, count: data.newspaperCount, key: 'print frequency' })
                }></i>}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };


  /**
   * @method render
   * @description render the component
   */
  render() {
    const { printFrequencyList, handleSubmit } = this.props;
    const { showInput, showConfirmModal, key, count, message } = this.state;
    const viewPermission = checkPermission(langs.permissionKeys.printFrequency, langs.permissionKeys.view)
    const createPermission = checkPermission(langs.permissionKeys.printFrequency, langs.permissionKeys.create)
    const deletePermission = checkPermission(langs.permissionKeys.printFrequency, langs.permissionKeys.delete)
    if (!viewPermission && !createPermission && !deletePermission) {
      return <div></div>
    }
    return (
      <div className="row">
        {/* {loading && <Loader />} */}
        <div className='col-md-12'>
          <Portlet fluidHeight={true}>
            <PortletBody >
              {(viewPermission || createPermission) && <Form.Row>
                <Form.Group as={Col} md='12' controlId='validationCustom01'>
                  <h5
                    style={{ color: '#5d5757', marginBottom: '100' }}
                  >
                    Print Frequency
                </h5>
                </Form.Group>
              </Form.Row>}
              <div className='cardWrapper'>
                {viewPermission ? this.renderCardItem(printFrequencyList, deletePermission) :  <div className='text-center' style={{ marginTop: '10px' }}>
                  <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                </div>}
              </div>
              <div
                className='row'
                style={{
                  borderBottom: '1px solid #ccc', marginTop: 25,
                  marginLeft: 2
                }}
              >
                {createPermission && <label style={{ color: 'black', marginTop: 25, cursor: 'pointer' }} onClick={() => this.setState({ showInput: !showInput })} data-toggle='collapse'><i className='fa fa-plus' aria-hidden='true'></i>&nbsp;&nbsp;Add More
                  </label>}
              </div>
              {showInput &&
                <form
                  noValidate
                  className='form'
                  onSubmit={handleSubmit(this.addPrintFrequency.bind(this))}
                >
                  <Row>
                    <Col md='6'>
                      <Field
                        name='print_frequency'
                        validate={[required, gminLength, maxLengthC100]}
                        placeholder='Enter Print Frequency'
                        component={renderText}
                        onChange={e => this.setState({ printFrequency: e.target.value.trim() })}
                        onKeyDown={this.addPrintFrequency}
                      />
                    </Col>
                  </Row>
                </form>
              }
            </PortletBody>
          </Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            message={message}
            totalCount={count}
            key={key}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, settingsDuck, authToken, commonDuck } = store;
  const { superAdminSettingList } = settingsDuck
  return ({
    loggedInUser: auth.user,
    authToken: authToken,
    printFrequencyList: Array.isArray(superAdminSettingList.print_frequency) ? superAdminSettingList.print_frequency : [],
    toasMsg: settingsDuck.toastMsg,
    loading: commonDuck.loading
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    settingsDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'PrintFrequency'
    })(PrintFrequency)
  )
);
