/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { phoneNumberFormate } from '../../../../common'

export default function OrderInfo(props) {
    const { info } = props;
    let customerAccess = info !== undefined && info.newspaper ? Array.isArray(info.newspaper.customer) && info.newspaper.customer.length : 0;
    let name = info !== undefined && info.user ? info.user.first_name + ' ' + info.user.last_name : '';

    return (
        <>
            <div className='kt-portlet kt-portlet--height-fluid text-dark'>
                <div className='kt-portlet__head' style={{ background: '#E8E8E8', color: 'black' }}>
                    <div className='kt-portlet__head-label'>
                        <h3 className='kt-portlet__head-title'>Order Information</h3>
                    </div>
                </div>
                <div className='kt-portlet__body'>
                    <div className='kt-widget4'>
                        <div className='kt-widget4__info '>
                            <h5>Customer</h5>
                            {customerAccess === 0 ? <p className='kt-widget4__text '>
                                ID: {info.user.id} <br />
                                <Link to='/customer-access'>Request customer for Access the details</Link>
                            </p> : ''}
                            {customerAccess > 0 ? <p className='kt-widget4__text '>
                                ID: {info.user.id} <br />
                                {name} <br />
                                {info.user.email} <br />
                                {phoneNumberFormate(info.user.phone_number)}
                            </p> : ''}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
