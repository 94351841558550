import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import { toastr } from "react-redux-toastr";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { CSVLink } from "react-csv";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { SalesRepoHeaders } from "../../../../../common/CsvFormate";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";

import { getCurrentWeek } from "../../../../../common/CommonFunction";
import * as salesReportDuck from "../../../../../store/ducks/super-admin/report-management/SalesReport.duck";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import { convertISOToUtcDateformate4 } from '../../../../../common'
import './Agentsummary.scss';

let label = getCurrentWeek();
class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
      openModal: false,
      sortBy: "",
      column: "",
      salesReportList: [],
      selectedOption: {
        option: 9,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    //this.getSalesReportRecord(this.state.pageSize, 1, "", "");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    console.log("checking Param value", params);
    let requestData = {
      id: params.id ? params.id:0,
      date:params.date?params.date:""
    };
    this.props.getCustomerWithrespectoAgent(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        //alert("We are testing");
      }
    });

    
  }

  /**
   * @method handleListCount
   * @description  ReArrabge API res according to length count
   */
  handleListCount = (res) => {
    let temp = [];
    
      temp = res.slice(0, res.length);
      this.setState({ salesReportList: temp });
    
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getSalesReportRecord = (row, page, sort, column) => {
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 3,
      section: 3,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.setData(requestData);
  };

  /**
   * @method setData
   * @description  used to set data module wise on api
   */
  setData = (requestData) => {
    this.props.getAgentpdfDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        //console.warn("Response Sample", res)
        this.setState({ count: res.data.salesDataGrid.length });
        Array.isArray(res.data.salesDataGrid) &&
          this.handleListCount(res.data.salesDataGrid);
      }
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getSalesReportRecord(this.state.pageSize, 1, "", "");
      }
    );
  };

  /**
   * @method FilterRecords
   * @description  called after rendering the component
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      row: this.state.pageSize,
      page: 1,
      month: data.this_month,
      year: data.this_year,
      type: 3,
      from_date: data.date,
      to_date: data.date2,
      section: 3,
    };
    label = data.label;
    this.props.getSalesDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.salesDataGrid) &&
          this.handleListCount(res.data.salesDataGrid);
        this.setState({
          fromDate: data.date,
          toDate: data.date2,
          month: data.this_month,
          year: data.this_year,
          openModal: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  }

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const {
      pageSize,
      count,
      entries,
      fromDate,
      toDate,
      month,
      year,
    } = this.state;
    const { salesReportListing } = this.props;
    if (this.state.viewAll) {
      if (salesReportListing.length) {
        this.setState({ loader: false }, () => {
          let requestData = {
            row: pageSize + entries,
            page: 1,
            type: 3,
            section: 3,
            month: month ? month : "",
            year: year ? year : "",
            from_date: fromDate ? fromDate : "",
            to_date: toDate ? toDate : "",
          };
          salesReportListing.length < count &&
            this.props.getSalesDetail(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  count: res.data.salesDataGrid.length,
                });
                setTimeout(() => {
                  this.setState({ localLoading: false });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({
      sortedOrder: !sortedOrder,
      sortBy: sortBy,
      column: column.id,
    });
    this.getSalesReportRecord(this.state.pageSize, 1, sortBy, column.id);
  };

  generateprint = ()=>{
   

    window.print();


  };

  /**
   * @method renderRecords
   * @description render all order list
   */
   renderRecords = () => {
    const columnOrder = [
        {
            Header: 'CLIENT NAME',
            accessor: 'agent', // String-based value accessors!
            Cell: ((row) => {
                const { original } = row;
                return original.client_name
            })
        },
        {
            Header: 'CAMPAIGN NAME',
            accessor: 'campaign_name', // String-based value accessors!
            Cell: ((row) => {
                const { original } = row;
                return original.campaign_name
            })
        },
        {
            Header: 'PUBLISHER',
            accessor: 'publisher_name', // String-based value accessors!
            Cell: ((row) => {
                const { original } = row;
                return original.publisher_name
            })
        },
        {
            Header: 'RESERVE DATE',
            accessor: 'agent', // String-based value accessors!
            Cell: ((row) => {
                const { original } = row;
                return convertISOToUtcDateformate4(original.reserveDate)
            })
        },
        {
            Header: 'AD TYPE',
            Cell: (row) => {
                const { original } = row;
                return original.ad_name
            },
        },

        {
            Header: 'TOTAL',
            Cell: (row) => {
                const { original } = row;
                return `$${original.total}`
            },
        },
        {
            Header: 'COMMISSION',
            Cell: (row) => {
                const { original } = row;
                return `$${original.commission}`
            },
        },
       
    ];
    return columnOrder
}

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      viewAll,
      page,
      pageSize,
      localLoading,
      openModal,
      selectedOption,
      salesReportList,
    } = this.state;
    const { salesReportListing,orderData,totaldata,clientData } = this.props;
    const printPer = checkPermission(
      langs.permissionKeys.sales,
      langs.permissionKeys.print
    );
    const headers = SalesRepoHeaders;
    return (
      <div style={{ marginTop: 0 }}>
        <div className="row">
          <h4 className="kt-subheader__title col-md-6">
            <span>Payout Details</span>
          </h4>
        </div>
        <Portlet fluidHeight={true}>
          <PortletBody className="px-4" ref={(el) => (this.componentRef = el)}>
            <div className="row">
              <div className="col-12">
                <div className="Publish_standardTableTwo">
                  <StandardTable
                    handelSearch={this.handleSearch}
                    columnOrder={this.renderRecords()}
                    handleSort={this.handleSort}
                    sortable={false}
                    onPageSizeChange={this.onPageSizeChange}
                    showSearch={false}
                    data={clientData}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-sm-end mt-5">
              <div className="col-lg-5 col-md-6 col-sm-8 pr-0">
                <div className="total_payout d-flex align-items-center justify-content-between">
                  <h4>Total Commission</h4>
                  <h3>${totaldata}</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 pr-0">
                <div className="publish_pdf_button text-right">
                  <button onClick={this.generateprint}className="btn btn-dark">Print</button>
                </div>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { salesReportDuck } = store;
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { salesReportList,agentpdf,cusomerWithrespecttoAgent } = salesReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    orderData: agentpdf !== undefined ? agentpdf.client_data : [],
    totaldata:
    cusomerWithrespecttoAgent !== undefined ? cusomerWithrespecttoAgent.totalSum[0].total : 0,
    clientData:
      cusomerWithrespecttoAgent !== undefined ? cusomerWithrespecttoAgent.clientData : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    salesReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "Listing",
    })(Listing)
  )
);
