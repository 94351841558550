import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import NavigationPrompt from "react-router-navigation-prompt";
import "react-image-crop/dist/ReactCrop.css";
import "../../Modal.scss";
import Notice from "../../../../partials/content/Notice";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import * as companyProfileDuck from "../../../../store/ducks/npc/CompanyProfile.duck";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import CodeExample from "../../../../partials/content/CodeExample";
import { Portlet } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { Form, Col, Button } from "react-bootstrap";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import { Loader } from "../../../../common/Loader";
import { langs } from "../../../../config/localization";
import { toastr } from "react-redux-toastr";
import { PROFILE_MEDIA_URL } from "../../../../config";
import {
  renderText,
  renderNumberInputField,
  renderPasswordInputField,
  renderSelectField,
} from "../../../../common/layout/FormInput";
import {
  required,
  PhoneNumber,
  minLength5,
  minLength6,
  maxLength100,
  maxLengthC100,
  maxLength5,
  minLength2,
  email,
  normalizePhone,
  maxLength50,
  selectRequired,
  maxLength18,
} from "../../../../config/validation";
import { renderThemeSelectOptions, focusOnError } from "../../FormLayout";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { FILE_SIZE, superAdminId } from "../../../../config";
import {
  phoneNumberFormate,
  phoneNumberFormateOnChange,
} from "../../../../common";

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleChanepassword: false,
      isSubmitted: false,
      isEditCompanyFlag: false,
      distributionArea: "",
      showStateSelection: false,
      selectedState: "",
      selectedCity: "",
      isEditFlag: false,
      value: "",
      number: "",
      email: "",
      success: false,
      error: false,
      loading: false,
      uniquesState: [],
      areasByState: [],
      area1: undefined,
      area2: undefined,
      area3: undefined,
      emailDistribution: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      distributionType: [
        { label: "National", value: "National" },
        { label: "Local", value: "Local" },
      ],
      src: null,
      pictures: [],
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    this.props.getAllRoleList(langs.keyname.company);
    this.props.getfromSuperAdminSetting(
      langs.keyname.copies_range,
      user_detail.id,
      (res) => {}
    );
    this.getDataById();
  }

  /**
   * @method getDataById
   * @description get company details by id
   */
  getDataById = () => {
    const { user_detail } = this.props.loggedInUser;
    if (user_detail.newspaper) {
      let requestData = {
        company_id: user_detail.newspaper.id,
      };
      this.setUniqueStates();
      this.props.getCompanyById(requestData, (res) => {
        if (res !== undefined) {
          let number = res.user && phoneNumberFormate(res.user.phone_number);
          this.setState({ number });
          if (String(res.distribution_type).toLowerCase() === "local") {
            this.setState({
              showStateSelection: true,
            });
            if (
              Array.isArray(res.distribution_areas) &&
              res.distribution_areas.length &&
              res.distribution_areas[0].state_name
            ) {
              let areas = Array.isArray(res.distribution_areas)
                ? res.distribution_areas
                : [];
              if (res.distribution_areas[0].state_name !== "") {
                this.props.getDistributionAreaFromState(
                  String(res.distribution_areas[0].state_name),
                  (res) => {
                    if (res.data) {
                      this.setState({
                        areasByState: res.data,
                        selectedState: res.distribution_state,
                        area1: areas[0] ? areas[0] : undefined,
                        area2: areas[1] ? areas[1] : undefined,
                        area3: areas[2] ? areas[2] : undefined,
                        isSubmitted: true,
                      });
                    }
                  }
                );
              } else if (res.data.distribution_state === "") {
                this.setState({ areasByState: [], selectedState: undefined });
              }
            }
          }
        }
      });
    }
  };

  /**
   * @method setUniqueStates
   * @description called to set list of area's
   */
  setUniqueStates = () => {
    this.props.getfromNPCSetting(
      langs.keyname.distribution_area_count,
      superAdminId,
      (res) => {
        if (Array.isArray(res.data)) {
          const unique = [
            ...new Set(res.data.map((item) => item["us_states.state_name"])),
          ];
          let uniqueArray = [];
          unique.map((el, i) => {
            uniqueArray.push({ id: i, lable: el });
          });
          this.setState({ uniquesState: uniqueArray });
        }
      }
    );
  };

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
      isEditCompanyFlag: true,
      isSubmitted: true,
    });
  };

  /**
   * @method renderTypeOfListing
   * @description Used show listing
   */
  renderTypeOfListing = (type) => {
    const { selectedState, selectedCities } = this.state;
    let options = [];
    const states = [];
    const city = [];

    if (type === 1) {
      selectedState &&
        Array.isArray(selectedState) &&
        selectedState.map((item, index) => {
          states.push({
            label: item.state,
            value: item.state,
          });
        });
      options = states;
    }
    if (type === 2) {
      Array.isArray(selectedCities) &&
        selectedCities.map((item, index) => {
          city.push({
            label: item.city,
            value: item.city,
          });
        });
      options = city;
    }
    return options;
  };

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    this.setState({
      isEditCompanyFlag: true,
      isEditFlag: true,
      isSubmitted: true,
      number: type === "number" ? e.target.value : this.state.number,
    });
  };

  /**
   * @method onChangeTypeHandeler
   * @description handle distribution type change
   */
  onChangeTypeHandeler = (e, type) => {
    if (String(e.target.value) === "Local") {
      this.setState({
        showStateSelection: true,
        selectedState: e.target.value,
        isEditCompanyFlag: true,
      });
    } else {
      this.setState({
        showStateSelection: false,
        isEditCompanyFlag: true,
        area1: undefined,
        area2: undefined,
        area3: undefined,
        selectedState: undefined,
      });
    }
  };

  /**
   * @method onChangeStateHandeler
   * @description handle distribution state change
   */
  onChangeStateHandeler = (e) => {
    if (e.target.value !== "") {
      this.props.getDistributionAreaFromState(String(e.target.value), (res) => {
        if (res.data) {
          this.setState({
            areasByState: res.data,
            selectedState: e.target.value,
            isSubmitted: true,
            isEditCompanyFlag: true,
            area1: undefined,
            area2: undefined,
            area3: undefined,
          });
          this.props.dispatch(change("CompanyProfile", "area1", ""));
          this.props.dispatch(change("CompanyProfile", "area2", ""));
          this.props.dispatch(change("CompanyProfile", "area3", ""));
        }
      });
    } else if (e.target.value === "") {
      this.setState({ areasByState: [], selectedState: undefined });
    }
  };

  /**
   * @method getArea
   * @description get area
   */
  getArea = (e, name) => {
    const { areasByState } = this.state;
    let index;
    if (areasByState) {
      index = areasByState.findIndex((el) => el.id === Number(e.target.value));
      if (index >= 0) {
        this.setState({
          [name]: {
            name: areasByState[index].area,
            id: areasByState[index].id,
          },
          isEditCompanyFlag: true,
        });
      } else {
        this.setState({ [name]: undefined, isEditCompanyFlag: true });
      }
    }
  };

  /**
   * @method pushArea
   * @description push area
   */
  pushArea = (dist_area, value) => {
    const { user_detail } = this.props.loggedInUser;
    const companyId = user_detail.newspaper.id;
    if (value !== undefined && value !== "") {
      dist_area.push({
        newspaperId: companyId,
        distributionAreaId: value,
      });
    }
    return dist_area;
  };

  /**
   * @method filterOption
   * @description methode COnditionally Filter the the Ara option list
   */
  filterOption = (val1, val2) => {
    const { areasByState } = this.state;
    let temp;
    if (val1 === undefined && val2 === undefined) {
      return areasByState;
    } else if (val1 !== undefined && val2 !== undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val1.id && x.id !== val2.id);
    } else if (val2 === undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val1.id);
    } else if (val1 === undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val2.id);
    }
    return temp;
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { companyProfile } = this.props;
    const { number, base64Data } = this.state;

    let dist_area = [];

    this.setState({ isSubmitted: true });
    if (companyProfile && companyProfile !== undefined) {
      dist_area = this.pushArea(dist_area, values.area1);
      dist_area = this.pushArea(dist_area, values.area2);
      dist_area = this.pushArea(dist_area, values.area3);
      values.distribution_area = dist_area ? dist_area : [];

      const requestData = {
        id: companyProfile.id,
        user_id: companyProfile.user_id,
        status: companyProfile.status,
        active: companyProfile.active,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        role_id: values.role_id,
        email: values.email,
        phone_number: number.replace(/-/g, ""),
        email_distribution: values.email_distribution,
        bill_address: values.bill_address,
        bill_city: values.bill_city.trim(),
        bill_state: values.bill_state.trim(),
        bill_zip_code: values.bill_zip_code,
        bill_country: "USA",
        distributed_copies: values.distributed_copies,
        distribution_type: values.distribution_type,
        distribution_state: values.distribution_state,
        distribution_area: values.distribution_area
          ? values.distribution_area
          : [],
        currentPassword:
          values.currentPassword !== undefined ? values.currentPassword : "",
        newPassword: values.newPassword !== undefined ? values.newPassword : "",
        image_url: base64Data,
      };
      requestData.distribution_area = dist_area;
      this.props.updateComapnyProfile(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          let message =
            values.currentPassword && values.newPassword
              ? MESSAGES.UPDATE_PASSWORD_SUCCESS
              : MESSAGES.COMPANY_UPDATE_SUCCESS;
          toastr.success(message);
          this.setState({
            update: true,
            isSubmitted: false,
            isEditCompanyFlag: false,
          });
          this.getDataById();
          // if (values.currentPassword && values.newPassword) {
          //   setTimeout(() => {
          //     window.location.assign("/logout");
          //   }, 4000);
          // }
        }
      });
    }
  }

  /**
   * @method renderHeader
   * @description render header component
   */
  renderHeader = () => {
    const { companyProfile } = this.props;
    const { isEditCompanyFlag } = this.state;
    const imageURL =
      companyProfile && companyProfile.user && companyProfile.user.image_url;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div
              className="flex-center justify-content-start"
              style={{ overflow: "hidden" }}
            >
              <img
                src={
                  imageURL
                    ? `${PROFILE_MEDIA_URL}/${imageURL}`
                    : require("../../../../../assets/images/defaultUser.png")
                }
                alt=""
                className="content-header-profile-image"
              />
              <h3 className="p-4  content-header-profile-image-caption">
                {companyProfile.newspaper_name}
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="float-right flex-center align-items-end h-100">
              <Button
                style={{
                  width: 100,
                  height: 40,
                  color: "black",
                }}
                className="btn-rp-primary"
                type="submit"
                disabled={isEditCompanyFlag ? false : true}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  /**
   * @method ImageChangedHandler
   * @description called for handling image
   */
  ImageChangedHandler = (e) => {
    e.preventDefault();
    let me = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    this.setState({ file: file, isEditCompanyFlag: true });
    let fileType = "";
    let size = "";
    let validType = "";
    if (file && file !== "undefined") {
      fileType = file.type;
      size = file.size;
      validType =
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg";
    }
    if (size <= FILE_SIZE && validType) {
      reader.onloadend = () => {
        this.setState(
          {
            file: file,
            imagePreviewUrl: reader.result,
            overSizedImage: false,
            invalidImage: false,
          },
          () => {
            this.getBase64(file, function(base64Data) {
              me.setState({ base64Data });
            });
          }
        );
      };
      reader.readAsDataURL(file);
    } else if (size >= FILE_SIZE && validType) {
      this.setState({
        overSizedImage: true,
        invalidImage: false,
        imagePreviewUrl: "",
        imgSrc: "",
        isEditCompanyFlag: false,
      });
    } else {
      this.setState({
        invalidImage: true,
        overSizedImage: false,
        imagePreviewUrl: "",
        imgSrc: "",
        isEditCompanyFlag: false,
      });
    }
  };

  /**
   * @method getBase64
   * @description convert in base 64 formate
   */
  getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const { handleSubmit, loading, rangeList } = this.props;
    const {
      isEditCompanyFlag,
      uniquesState,
      area1,
      area2,
      area3,
      number,
      emailDistribution,
      billingCity,
      billingState,
      file,
      overSizedImage,
      invalidImage,
      distributionType,
      showStateSelection,
      selectedState,
    } = this.state;
    return (
      <>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Profile</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <Notice>
          <Form noValidate onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            {this.props.companyProfile && this.renderHeader()}
          </Form>
        </Notice>
        <NavigationPrompt when={isEditCompanyFlag}>
          {(props) => {
            const { onConfirm, onCancel } = props;
            return (
              <NavigateModal
                show={true}
                alertHeading={"Changes made will be lost."}
                cancleButtonText="Cancel"
                okButtonText="Leave Anyway"
                onOkClick={() => onConfirm()}
                onClickCancel={() => {
                  onCancel();
                }}
                showCancel={true}
              />
            );
          }}
        </NavigationPrompt>
        <div className="row">
          {loading && <Loader />}

          <div className="col-md-12">
            <Form noValidate onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <Portlet>
                <div className="col-md-12">
                  <CodeExample beforeCodeTitle="Contact Info">
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Field
                          name="first_name"
                          value={"adminProfile.first_name"}
                          label="First Name"
                          validate={[required, minLength2, maxLengthC100]}
                          required={true}
                          placeholder="Enter Your First Name"
                          component={renderText}
                          onChange={(e) =>
                            this.setState({
                              isEditCompanyFlag: true,
                            })
                          }
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Field
                          name="last_name"
                          label="Last Name"
                          validate={[required, minLength2, maxLengthC100]}
                          required={true}
                          placeholder="Enter your Last name"
                          component={renderText}
                          onChange={(e) =>
                            this.setState({
                              isEditCompanyFlag: true,
                            })
                          }
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Form.Label>
                          Role<span className="asterisk-required">*</span>
                        </Form.Label>
                        <Field
                          name="role_id"
                          data={this.props.companyRoles}
                          required={true}
                          validate={[required]}
                          isSelect={false}
                          component={renderThemeSelectOptions}
                          onChange={(e) =>
                            this.setState({
                              isEditCompanyFlag: true,
                            })
                          }
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Field
                          name="email"
                          label="Email"
                          validate={[required, minLength5, maxLength100, email]}
                          required={true}
                          placeholder="Enter Email Id"
                          onChange={(e) =>
                            this.setState({
                              isEditCompanyFlag: true,
                            })
                          }
                          component={renderText}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Field
                          name="phone_number"
                          label="Phone Number"
                          type="text"
                          maxLength={10}
                          style={{ width: "100%" }}
                          onChange={this.onChange}
                          Value={number}
                          validate={[required, PhoneNumber]}
                          required={true}
                          placeholder="Enter Your Phone Number"
                          component={renderNumberInputField}
                          normalize={normalizePhone}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Field
                          name="email_distribution"
                          label="Email Distribution"
                          validate={[required]}
                          isSelect={false}
                          options={emailDistribution}
                          component={renderSelectField}
                          optionValue={"value"}
                          optionLabel={"label"}
                          required={true}
                          onChange={(e) =>
                            this.setState({
                              isEditCompanyFlag: true,
                            })
                          }
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Field
                            name="bill_address"
                            value={"adminProfile.first_name"}
                            label="Address"
                            validate={[required, minLength2, maxLength100]}
                            required={true}
                            placeholder="Enter Address"
                            component={renderText}
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Field
                            name="bill_city"
                            value={billingCity}
                            label="City"
                            validate={[required, maxLength50]}
                            required={true}
                            placeholder="Enter City"
                            component={renderText}
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <Field
                            name="bill_state"
                            value={billingState}
                            label="State"
                            validate={[required, maxLength50]}
                            required={true}
                            placeholder="Enter State"
                            component={renderText}
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Field
                            name="bill_zip_code"
                            value={"adminProfile.first_name"}
                            label="Zip Code"
                            validate={[required, minLength5, maxLength5]}
                            maxLength={5}
                            required={true}
                            placeholder="Enter Zip Code"
                            component={renderText}
                            normalize={normalizePhone}
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <Field
                            name="bill_country"
                            label="Country"
                            required={true}
                            options={[{ label: "USA", value: "USA" }]}
                            component={renderSelectField}
                            placeholder="Enter Country"
                            optionValue={"value"}
                            optionLabel={"label"}
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <Field
                            name="distributed_copies"
                            label={"No. Of Copies"}
                            isSelect={false}
                            validate={[required]}
                            required={true}
                            placeholder="Enter No. Of Copies Distributed"
                            component={renderSelectField}
                            options={rangeList}
                            optionValue={"id"}
                            optionLabel={"copies_range"}
                            onChange={(e) => {
                              this.setState({
                                selectedCity: e.target.value,
                                isEditCompanyFlag: true,
                              });
                            }}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <Form.Label>Upload Cover Photo</Form.Label>
                          <div
                            style={{
                              marginRight: "15px",
                              // width: 500,
                              height: 100,
                            }}
                          >
                            <div className=" file-size">
                              <input
                                className="col-md-4"
                                placeholder="Drag and Drop PDF Here"
                                type="file"
                                id="file"
                                onChange={this.ImageChangedHandler}
                                style={{ display: "none" }}
                              />
                              <div className="form-control ">
                                <label className="row">
                                  <span
                                    style={{ width: 200 }}
                                    className="fa fa-camera text-truncate col-md-6"
                                  >
                                    {file ? (
                                      <span className="ml-1">{file.name}</span>
                                    ) : (
                                      <span className="ml-1">
                                        {" "}
                                        Drag and Drop image Here
                                      </span>
                                    )}
                                  </span>
                                  <label
                                    className="fileName col-md-4 ml-1 text-truncate"
                                    htmlFor="file"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Or browse for image
                                  </label>
                                </label>
                              </div>
                              <div className="form-group">
                                {overSizedImage && (
                                  <div className="text-help">
                                    {"File size should be less then 5 MB"}
                                  </div>
                                )}
                                {invalidImage && (
                                  <div className="text-help">
                                    {"Only JPEG and PNG files are accepted."}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Form.Group>
                  </CodeExample>
                </div>
              </Portlet>
              <div className="row">
                <div className="col-md-12">
                  <Portlet>
                    <div className="col-md-12">
                      <CodeExample beforeCodeTitle="Distribution Type">
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Field
                              name="distribution_type"
                              label="Type"
                              validate={[required]}
                              isSelect={false}
                              required={true}
                              options={distributionType}
                              onChange={this.onChangeTypeHandeler}
                              component={renderSelectField}
                              optionValue={"value"}
                              optionLabel={"label"}
                              placeholder="Enter Distribution Type"
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* <Form.Group
                            as={Col}
                            md='6'
                            controlId='validationCustom02'
                          >
                            {showStateSelection && (
                              <Field
                                name='distribution_state'
                                value={selectedState}
                                label='State'
                                validate={[selectRequired]}
                                optionValue={'lable'}
                                optionLabel={'lable'}
                                isSelect={false}
                                required={true}
                                options={uniquesState && uniquesState}
                                onChange={this.onChangeStateHandeler}
                                component={renderSelectField}
                                placeholder='Enter Distribution State'
                              />
                            )}
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group> */}
                        </Form.Row>

                        {/* <Form.Row>
                          <Form.Group
                            as={Col}
                            md='4'
                            controlId='validationCustom01'
                          >
                            {showStateSelection && (
                              <Field
                                name='area1'
                                value={area1}
                                label='Area 1'
                                validate={[selectRequired]}
                                optionValue={'id'}
                                optionLabel={'area'}
                                options={this.filterOption(area2, area3)}
                                isSelect={false}
                                required={true}
                                onChange={e => this.getArea(e, 'area1')}
                                component={renderSelectField}
                                placeholder='Enter Distribution State'
                              />
                            )}
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md='4'
                            controlId='validationCustom02'
                          >
                            {showStateSelection && (
                              <Field
                                name='area2'
                                value={area2}
                                label='Area 2'
                                optionValue={'id'}
                                optionLabel={'area'}
                                options={this.filterOption(area1, area3)}
                                isSelect={false}
                                onChange={e => this.getArea(e, 'area2')}
                                component={renderSelectField}
                                placeholder='Enter Distribution State'
                              />
                            )}
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md='4'
                            controlId='validationCustom02'
                          >
                            {showStateSelection && (
                              <Field
                                name='area3'
                                value={area3}
                                label='Area 3'
                                optionValue={'id'}
                                optionLabel={'area'}
                                options={this.filterOption(area1, area2)}
                                isSelect={false}
                                onChange={e => this.getArea(e, 'area3')}
                                component={renderSelectField}
                                placeholder='Enter Distribution State'
                              />
                            )}
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row> */}
                      </CodeExample>
                    </div>
                  </Portlet>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Portlet>
                    <div className="col-md-12">
                      <CodeExample>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Field
                              name="currentPassword"
                              width={300}
                              label="Current Password"
                              validate={[minLength6, maxLength18]}
                              required={true}
                              placeholder="Enter Password"
                              onChange={(e) =>
                                this.setState({
                                  isEditCompanyFlag: true,
                                })
                              }
                              component={renderPasswordInputField}
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom02"
                          >
                            <Field
                              name="newPassword"
                              width={300}
                              label="New Password"
                              validate={[minLength6, maxLength18]}
                              required={true}
                              placeholder="Enter Your New Password"
                              component={renderPasswordInputField}
                              onChange={(e) =>
                                this.setState({
                                  isEditCompanyFlag: true,
                                })
                              }
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </CodeExample>
                    </div>
                  </Portlet>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="float-right flex-center align-items-end h-100">
                      <Button
                        style={{
                          width: 100,
                          height: 40,
                          marginRight: 10,
                          background: "black",
                        }}
                        type="submit"
                        className="btn-rp-primary"
                        disabled={isEditCompanyFlag ? false : true}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
  let errors = {};
  if (values.currentPassword && !values.newPassword) {
    errors.newPassword = "New password is required.";
  }
  if (!values.currentPassword && values.newPassword) {
    errors.currentPassword = "Current password is required.";
  }
  if (!values.phone_number) {
    errors.phone_number = "This field is required.";
  }
  return errors;
}

const mapStateToProps = (store) => {
  const { commonDuck } = store;
  const { loading, roles, superAdminSettingList } = commonDuck;
  const companyProfile = commonDuck.selectedCompany;
  let initialValues = {};
  if (companyProfile && companyProfile !== undefined) {
    let areaArray = [];
    if (
      Array.isArray(companyProfile.distribution_areas) &&
      companyProfile.distribution_areas !== [] &&
      companyProfile.distribution_areas !== undefined
    ) {
      areaArray = companyProfile.distribution_areas;
    }
    initialValues = {
      first_name: companyProfile.user.first_name.trim(),
      last_name: companyProfile.user.last_name.trim(),
      role_id: companyProfile.user.role_id,
      phone_number: companyProfile.user.phone_number,
      email: companyProfile.user.email,
      bill_zip_code: companyProfile.bill_zip_code
        ? companyProfile.bill_zip_code
        : "",
      bill_country: companyProfile.bill_country
        ? companyProfile.bill_country
        : "",
      bill_city: companyProfile.bill_city ? companyProfile.bill_city : "",
      bill_state: companyProfile.bill_state ? companyProfile.bill_state : "",
      bill_address: companyProfile.bill_address
        ? companyProfile.bill_address
        : "",
      email_distribution: companyProfile.email_distribution,
      distribution_type: companyProfile.distribution_type
        ? companyProfile.distribution_type
        : "",
      distribution_state:
        areaArray && areaArray[0] ? areaArray[0].state_name : "",
      distribution_area: companyProfile.distribution_area
        ? companyProfile.distribution_area
        : "",
      distributed_copies: companyProfile.distributed_copies
        ? companyProfile.distributed_copies.id
        : "",
      area1: areaArray && areaArray[0] ? areaArray[0].id : "",
      area2: areaArray && areaArray[1] ? areaArray[1].id : "",
      area3: areaArray && areaArray[2] ? areaArray[2].id : "",
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    companyProfile: store.superAdminDuck.selectedCompany,
    location: store.superAdminDuck && store.superAdminDuck.location,
    selectedState: store.superAdminDuck.selectedState,
    selectedCities: store.superAdminDuck.selectedCities,
    companyRoles: roles && roles.company ? roles.company : [],
    rangeList:
      superAdminSettingList && superAdminSettingList.copies_range
        ? superAdminSettingList.copies_range
        : [],
    loading,
    initialValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...companyProfileDuck.actions,
    ...npcDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "CompanyProfile",
      validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(CompanyProfile)
  )
);
