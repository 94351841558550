import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { renderText } from '../../../../common/layout/FormInput';
import { toastr } from 'react-redux-toastr';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import { MESSAGES } from '../../../../config/message';
import { Loader } from '../../../../common/Loader';
import { required, minLength2, maxLength100, minLength5,gminLength } from '../../../../config/validation';
import { superAdminId } from '../../../../config'

class UpdateEmailsettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      flag: false,
      isSubmitted: true,
      loading: false,
      files: [],
      pictures: [],
      adType: false,
      imageArray: [],
      images: [],
      smtpintialvalues:[]
    };
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleButton = () => {
    //const history = useHistory();
    this.props.history.push('/gsettings');
    //this.props.onCancel();
  };

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getemailSettings(superAdminId, res => { 
      this.setState({smtpintialvalues:res.data});
    });
  }


  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { bankInformation } = this.props
    this.setState({ isSubmitted: false })
    if (bankInformation && bankInformation !== undefined) {

      this.props.updateEmailInformation(values, (res) => {
        toastr.success(MESSAGES.DATA_UPDATED)
        this.props.getemailSettings(superAdminId, res => { });
        this.setState({ isSubmitted: true })
        
      })
    }
  }

  render() {
    const { handleSubmit, loading, selectedEmail } = this.props;
    const { isSubmitted, smtpintialvalues} = this.state;


    
    return (
     
         <>
         {
          smtpintialvalues.SMTP_USER_EMAIL ? <Row>
          <Container>
            <form
              noValidate
              className='form'
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <Row>
                <Col md='6'>
                  <Field
                    name='smtp_user_email'
                    label='Smtp User Email'
                    validate={[required, gminLength, maxLength100]}
                    required={true}
                    placeholder='Enter Smtp User Email'
                    component={renderText}
                  />
                </Col>
                <Col md='6'>
                  <Field
                    name='smtp_paswword'
                    label='Smtp Password'
                    validate={[required, minLength2, maxLength100]}
                    required={true}
                    placeholder='Enter Smtp Password'
                    component={renderText}
                  />
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <Field
                    name='smtp_host'
                    label='Smtp Host'
                    validate={[required, minLength2, maxLength100]}
                    required={true}            
                    placeholder='Smtp Host'
                    component={renderText}
                  />
                </Col>
                <Col md='4'>
                  <Field
                    name='smtp_services'
                    label='Smtp Services'
                    validate={[required, gminLength, maxLength100]}
                    required={true}
                    placeholder='Enter Smtp Services'
                    component={renderText}
                  />
                </Col>
                <Col md='4'>
                  <Field
                    name='smtp_port'
                    label='Smtp Port'
                    validate={[required, maxLength100]}
                    maxLength={101}
                    required={true}
                    placeholder='Enter Smtp Port'
                    component={renderText}
                  />
                </Col>
              </Row>              
              <Row className='float-right'>
                <Col md='12'>
                  <Button onClick={this.toggleButton} style={{
                    margin: 10, background: '#808080',
                    border: 'none',
                    background: 'black',
                    width: 100
                  }} size='md' >Cancel</Button>
                  <Button
                    disabled={isSubmitted ? false : true}
                    style={{
                      margin: 10,
                      background: 'black',
                      border: 'none',
                      width: 100
                    }}
                    size='md'
                    type='submit'
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </form>
          </Container>
        </Row> :
        <div>Please Wait......................</div>
         }
          
        </>
    );
  }
}

const mapStateToProps = store => {
  const { commonDuck } = store;
  const { loading,selectedEmail } = commonDuck;
 
  let initialValues = {};
  if (selectedEmail && selectedEmail !== undefined) {
    initialValues = {
    smtp_user_email: selectedEmail.SMTP_USER_EMAIL,
    smtp_paswword: selectedEmail.SMTP_PASSWORD,
    smtp_host: selectedEmail.SMTP_HOST,
    smtp_services: selectedEmail.SMTP_SERVICE,
    smtp_port: selectedEmail.SMTP_PORT

    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,   
    bankInformation: selectedEmail, 
    loading,
    initialValues,
    selectedEmail
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...commonDuck.actions, ...settingsDuck.actions }
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'UpdateEmailsettings',
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(UpdateEmailsettings)
  )
);
