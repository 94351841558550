import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as adminCustomerAccessDuck from '../../../../store/ducks/super-admin/AdminCustomerAccess.duck'
import {
    Portlet,
    PortletBody,
} from "../../../../partials/content/Portlet";
import {
    renderText1,
} from '../../../../common/layout/FormInput'
import { phoneNumberFormateOnChange } from "../../../../common";

class AdTypeDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            number: '',
            companyName: '',
            customerName: '',
            email: ''
        };
    }

    /**
    * @method componentDidMount
    * @description  called after rendering the component 
    */
    componentDidMount() {
        const { history } = this.props;
        if (history.location.state !== undefined) {
            let data = history.location.state.customerDetail
            this.setState({
                companyName: data.newspaper_name,
                customerName: data.customer_name,
                email: data.customer_email,
                number: data.customer_number
            })
            const requestData = {
                email: data.customer_email,
                npc_id: data.npc_id
            }
            this.props.getCustomerDetails(requestData, res => {})
        }
    }

    /**
    * @method onChange
    * @description mobile number formatting
    */
    onChange = e => {
        //Filter only numbers from the input
        let number = phoneNumberFormateOnChange(e.target.value);
        this.setState({
            number,
        });
    };


    /**
     * @method renderDiscountRecord
     * @description  render discount listing 
     */
    renderDiscountRecord = (discountList) => {
        return discountList && discountList.map((item, index) => {
            if (item.discount_mode === 'ad_type_discount') {
                return (
                    <Row >
                        <Col md='3'>
                            <Field
                                name='customer_name'
                                component={renderText1}
                                Value={item.edition_name}
                                className='bottomBorder1'
                                disabled={true}
                            />
                        </Col>
                        <Col md='3'>
                            <Field
                                name='ad_type'
                                component={renderText1}
                                Value={item.ad_name}
                                className='bottomBorder1'
                                disabled={true}
                            />
                        </Col>
                        <Col md='3'>
                            <Field
                                name='discount_type'
                                component={renderText1}
                                Value={item.discount_type}
                                className='bottomBorder1'
                                disabled={true}
                            />
                        </Col>
                        <Col md='3'>
                            <Field
                                name='amount'
                                component={renderText1}
                                Value={item.discount_unit}
                                className='bottomBorder1'
                                disabled={true}
                            />
                        </Col>
                    </Row>
                )
            } else if (item.discount_mode === 'Sitewide_discount') {
                return (
                    <div>
                        <Row>
                            <h5 className='ml-2'>Discount SiteWide</h5>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col md='3'>
                                <Field
                                    label='Discount Type'
                                    name='customer_name'
                                    component={renderText1}
                                    Value={item.discount_type}
                                    className='bottomBorder1'
                                    disabled={true}
                                />
                            </Col>
                            <Col md='3'>
                                <Field
                                label= 'Amount'
                                    name='email'
                                    component={renderText1}
                                    Value={item.discount_unit}
                                    className='bottomBorder1'
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            }
        })
    }



    /**
    * @method render
    * @description render the component
    */
    render() {
        const { number, companyName, customerName, email } = this.state;
        const { customerDetail } = this.props;
        const discountVisible = customerDetail && customerDetail.length > 0 && customerDetail[0].discount_mode
        return (
            <div>
                <LayoutContextConsumer className='row'>
                    {({ subheader: { title } }) => (
                        <div>
                            <div className='row'>
                                <h5 className='kt-subheader__title col-md-6'>
                                    <span>
                                        <Link to='/customer-access'>
                                            <i
                                                onClick={() => {
                                                    return this.props.history.push('/customer-access');
                                                }}
                                                style={{ fontSize: 15 }}
                                                className='fa fa-arrow-left'
                                                aria-hidden='true'
                                            ></i>
                                        </Link>
                                    </span>
                                    <span style={{ marginLeft: 10 }}>Back</span>
                                </h5>
                            </div>
                        </div>
                    )}
                </LayoutContextConsumer>
                <div className="col-md-12">
                    <Portlet >
                        <PortletBody>
                            <form
                                noValidate
                                className='form'
                            >
                                <Row>
                                    <Col md='3'>
                                        <Field
                                            name='company_name'
                                            label='Company Name'
                                            Value={companyName}
                                            component={renderText1}
                                            className='bottomBorder1'
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='3'>
                                        <Field
                                            name='customer_name'
                                            label='Customer Name'
                                            Value={customerName}
                                            component={renderText1}
                                            className='bottomBorder1'
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md='3'>
                                        <Field
                                            name='email'
                                            label='Email Id'
                                            Value={email}
                                            component={renderText1}
                                            className='bottomBorder1'
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md='3'>
                                        <Field
                                            name='phone_number'
                                            label='Phone Number'
                                            type={'text'}
                                            autoComplete='off'
                                            onChange={this.onChange}
                                            Value={number}
                                            component={renderText1}
                                            className='bottomBorder1'
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                {(discountVisible === 'ad_type_discount') && <div>
                                <Row >
                                    <h5 className='ml-3'>Discount Ad Type</h5>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <Col md='3'>
                                        <h6 className='ml-2'>Edition</h6>
                                    </Col>
                                    <Col md='3'>
                                        <h6 className='ml-2'>Ad Type</h6>
                                    </Col>
                                    <Col md='3'>
                                        <h6 className='ml-2'>Discount Type</h6>
                                    </Col>
                                    <Col md='3'>
                                        <h6 className='ml-2'>Amount</h6>
                                    </Col>
                                </Row>
                                </div>}
                                {this.renderDiscountRecord(customerDetail)}
                            </form>
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        );
    }
}


const mapStateToProps = store => {
    const { auth, authToken, adminCustomerAccessDuck } = store;
    const { customerDetail } = adminCustomerAccessDuck
    return {
        loggedInUser: auth.user,
        authToken: authToken,
        customerDetail: customerDetail && Array.isArray(customerDetail) ? customerDetail : [],
    };
};

export default injectIntl(
    connect(
        mapStateToProps, adminCustomerAccessDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: "AdTypeDetail",
        })(AdTypeDetail)
    )
);
