import React from "react";
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
    Button,
    Modal,
} from "react-bootstrap";
import { Loader } from '../../../common/Loader';

class ConformModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false
        };
    }

    /**
    * @method componentWillReceiveProps
    * @description with recieve props
    */
    componentWillReceiveProps(nextprops, props) {
        if (props.isOpen !== nextprops.isOpen) {
        this.setState({ show: nextprops.isOpen });
        }
    }

    /**
     * @method handleClose
     * @description used to handle cancel
     */
    handleClose() {
        this.setState({ show: false });
        this.props.onCancelClick();
    }

    /**
     * @method handleShow
     * @description used to handle popup show
     */
    handleShow() {
        this.setState({ show: true });
    }

    /**
    * @method render
    * @description render component
    */
    render() {
        const { loading } = this.props;
        return (
            <>
                <Modal size="sm" aria-labelledby="example-modal-sizes-title-sm" show={this.state.show} onHide={this.handleClose} centered={true}>

                    <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                    {loading && <Loader />}
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                    </Button>
                        <Button variant="primary" onClick={this.props.onOkClick}>
                            Ok
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    return ({
        loading
    })
}

export default injectIntl(connect(mapStateToProps)(ConformModal));
