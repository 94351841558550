import {
  convertISOToUtcDateformate2,
  convertISOToUtcDateformate3,
} from "./index";
import moment from "moment";
import {
  selectedFilterValue,
  getCurrentMonth,
  getCurrentDate,
  getCurrentWeek,
} from "./CommonFunction";

/**
 * @method topRecordsFilterKeys
 * @description  set date keys for top 5 records
 */
export const topRecordsFilterKeys = (
  values,
  dateVisible,
  startDate,
  endDate
) => {
  let date = "";
  let date2 = "";
  console.log("values", values);
  const otherOption =
    values !== "0" &&
    values !== "10" &&
    values !== "5" &&
    values !== "6" &&
    values !== "2" &&
    values !== "7" &&
    values !== "3" &&
    values !== "4";
  const this_month =
    values === "0" || values === "10" ? selectedFilterValue(values) : "";
  const this_year =
    values === "5" || values === "6" ? selectedFilterValue(values) : "";
  if (values === "2" || values === "7") {
    date = selectedFilterValue(values);
    date2 = selectedFilterValue(values);
  } else if (values === "3") {
    let data = selectedFilterValue(values);
    date = data.firstday;
    date2 = data.lastday;
  } else if (values === "4") {
    date = selectedFilterValue(values);
    date2 = selectedFilterValue(values);
  } else if (dateVisible && otherOption) {
    date = convertISOToUtcDateformate2(startDate);
    date2 = convertISOToUtcDateformate2(endDate);
  } else {
    date = "";
    date2 = "";
  }

  return {
    this_month,
    this_year,
    date,
    date2,
  };
};

/**
 * @method setDateFilterNPCKeys
 * @description  set date keys for NPC company filter data
 */
export const setDateFilterKeys = (values, label) => {
  let date = "";
  let date2 = "";
  const this_month =
    values.date_range === "0" ? selectedFilterValue(values.date_range) : "";
  const this_year =
    values.date_range === "5" || values.date_range === "6"
      ? selectedFilterValue(values.date_range)
      : "";

  if (values.date_range === "2" || values.date_range === "7") {
    date = selectedFilterValue(values.date_range);
    date2 = selectedFilterValue(values.date_range);
    label = selectedFilterValue(values.date_range);
  } else if (values.date_range === "3") {
    let data = selectedFilterValue(values.date_range);
    date = data.firstday;
    date2 = data.lastday;
    label = data.firstday + " to " + data.lastday;
  } else if (values.date_range === "4") {
    date = selectedFilterValue(values.date_range);
    date2 = selectedFilterValue(values.date_range);
    label = date.lastWeekFirstDay + " to " + date.lastWeekLastDay;
  } else if (values.from || values.to) {
    let firstDate =
      values.from !== "" ? convertISOToUtcDateformate2(values.from) : "";
    let lastDate =
      values.to !== "" ? convertISOToUtcDateformate2(values.to) : "";
    let temp = values.from !== "" && values.to !== "" ? "to" : "";
    date = convertISOToUtcDateformate2(values.from);
    date2 = convertISOToUtcDateformate2(values.to);
    console.log("firstDate", firstDate, lastDate);
    //label = firstDate + ' to ' + lastDate
    label = `${firstDate} ${temp} ${lastDate}`;
  } else {
    date = "";
    date2 = "";
  }
  if (values.date_range === "5" || values.date_range === "6") {
    label = selectedFilterValue(values.date_range);
  }
  if (values.date_range === "0") {
    label = getCurrentMonth();
  }
  if (values.date_range === "8") {
    label = "All";
  }
  if (values.date_range === "9") {
    label = getCurrentWeek();
  }

  return {
    this_month,
    this_year,
    date: date !== String("Invalid date") ? date : "",
    date2: date2 !== String("Invalid date") ? date2 : "",
    label,
  };
};

export const setDateFilterKeysCustom = (value, from, to) => {
  let today = new Date();
  var currentDate = new Date();
  var dd = String(currentDate.getDate()).padStart(2, "0");
  var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = currentDate.getFullYear();
  currentDate = yyyy + "-" + mm + "-" + dd;
  if (value === "0") {
    return {
      agentTotalFilterOption: "thisMonth",
      toDate: "",
      fromDate: "",
    };
  }
  if (value === "1") {
    return {
      agentTotalFilterOption: "custom",
      // toDate: convertISOToUtcDateformate3(to),
      // fromDate: convertISOToUtcDateformate3(from),
      toDate: moment(to).format("YYYY-MM-DD"),
      fromDate: moment(from).format("YYYY-MM-DD"),
    };
  }
  if (value === "2") {
    return {
      agentTotalFilterOption: "toDay",
      toDate: "",
      fromDate: "",
    };
  }
  if (value === "3") {
    return {
      agentTotalFilterOption: "thisWeek",
      toDate: "",
      fromDate: "",
    };
  }
  if (value === "4") {
    return {
      agentTotalFilterOption: "lastWeek",
      toDate: "",
      fromDate: "",
    };
  }
  if (value === "5") {
    return {
      agentTotalFilterOption: "thisYear",
      toDate: "",
      fromDate: "",
    };
  }
  if (value === "6") {
    return {
      agentTotalFilterOption: "lastYear",
      toDate: "",
      fromDate: "",
    };
  }

  if (value == "8") {
    return {
      agentTotalFilterOption: "toDate",
      toDate: currentDate,
      fromDate: "",
    };
  }
};
