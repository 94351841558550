

//hosting url for api of cost-it-right

const BASE_URL = 'http://localhost:3001';
let today = new Date();
 
export const API = {
    //configure api's
    getAdminDetail: `${BASE_URL}/api/adminById`,
    updateAdminPassword: `${BASE_URL}/api/changePassword`,
    updateAdminDetails: `${BASE_URL}/api/admin`,
    getRole: `${BASE_URL}/api/roles`,
    registerAdmin: `${BASE_URL}/api/register`,
    getAllAdmin: `${BASE_URL}/api/admin`,
}
//Status Constant 
export const APPROVE_STATUS = 'Approved';
export const CANCELLED_STATUS = 'Cancelled';
export const PENDING_STATUS = 'Pending';
export const INPROGRESS_STATUS = 'InProgress';
export const COMPLETED_STATUS = 'Completed';
export const REJECTED_STATUS = 'Rejected';
export const ONHOLD_STATUS = 'On Hold';

//Page Size Constant 
export const HEIGHEST ='heigest';
export const LOWEST ='lowest';

//Page Size Constant 
export const PAGES_25 = 25;
export const PAGES_50 = 50;
export const PAGES_100 = 100;
export const TOTAL_COUNT = 100

//Api constants
export const API_REQUEST = 'API_REQUEST';
export const API_FAILURE = 'API_FAILURE';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS'
export const CALL_LOADING = '[SetLoading] Action'
export const SESSION_EXPIRED_ACTION = '[SessionExpired] Action'
export const ADTYPE = [
    { label: 'Classified', value: 'Classified' },
    { label: 'Display', value: 'Display' }
]

export const ADFREQUENCY = [
    { label: '1', key: 1 },
    { label: '2', key: 2 },
    { label: '3', key: 3 },
    { label: '4', key: 4 },
    { label: '5', key: 5 },
    { label: '6', key: 6 },
    { label: '7', key: 7 },
    { label: '8', key: 8 },
    { label: '9', key: 9 },
    { label: '10', key: 10 }
]

export const DISTRIBUTION_TYPE = [
    { label: 'National', value: 'National' },
    { label: 'Local', value: 'Local' }
]

export const EMAIL_DISTRIBUTION = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
]

export const STATUS = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 }
]

export const PUBLISH_DAY = [
    { id: 1, label: 'Sunday' },
    { id: 2, label: 'Monday' },
    { id: 3, label: 'Tuesday' },
    { id: 4, label: 'Wednesday' },
    { id: 5, label: 'Thursday' },
    { id: 6, label: 'Friday' },
    { id: 7, label: 'Saturday' },
]

export const WEEK_DAYS = [
    [{ key: 'Monday', value: false }, { key: 'Tuesday', value: false }, { key: 'Wednesday', value: false }, { key: 'Thurstday', value: false }, { key: 'Friday', value: false }, { key: 'Saturday', value: false }, { key: 'Sunday', value: false }]
]

export const PAYMENT_METHOD = [
    { value: 'Wire', label: 'Wire' }, { value: 'Cheque', label: 'Cheque' }
]


export const SCHEDULE_PAYMENT = [
    { label: 'Select', value: -1 },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Bi-Weekly', value: 'bi-weekly' },
    { label: 'Monthly', value: 'monthly' }
]

export const PERCENT_ARRAY = [
    { value: 0.1, label: '0.1' },
    { value: 0.2, label: '0.2' },
    { value: 0.3, label: '0.3' },
    { value: 0.4, label: '0.4' },
    { value: 0.5, label: '0.5' },
    { value: 0.6, label: '0.6' }
]

export const DATE_FORMATE = {
    FORMATE1:'MMM DD, YYYY HH:mm A',
    FORMATE2:'MM-DD-YYYY',
    FORMATE3:'MM/DD/YYYY',
    FORMATE4:'YYYY-MM-DD',
    FORMATE5:'DD-MM-YYYY',
    FORMATE6:'MMM DD, YYYY',
    FORMATE7:'YYYY-MM-DDTHH:mm:ss.SSSZ',
}
export const DATE_RANGE = [
    { label: 'This Month', value:0},
    { label: 'Custom', value:1 },
    { label: 'Today', value: 2 },
    { label: 'This Week', value: 3 },
    { label: 'Last Week', value: 4 },
    { label: 'This Year', value: 5 },
    { label: 'Last Year', value: 6 },
    { label: 'To Date', value: 7 }
]

export const DURATION = [
    // { label: 'Till Date', value: 7 },
    { label: 'This Month', value:0},
    { label: 'Last Month', value:10},
    { label: 'Custom', value:1 },
    { label: 'Today', value: 2 },
    { label: 'This Week', value: 3 },
    { label: 'Last Week', value: 4 },
    { label: 'This Year', value: 5 },
    { label: 'Last Year', value: 6 },
   
]

// export const DURATION = [
//     { label: 'Today', value: 'Today' },
//     { label: 'This Week', value: 'This Week' },
//     { label: 'This Month', value: 'This Month' },
//     { label: 'Last Month', value: 'Last Month' },
//     { label: 'This Year', value: 'This Year' },
//     { label: 'Last Year', value: 'Last Year' },
//     { label: 'Till Date', value: 'Till Date' },
//     { label: 'Custom', value: 'Custom' },
// ]

export const COMPARE_TO = [
    { label: 'Previous Period', value: 'Previous Period' },
    { label: 'Custom', value: 'Custom' },
    { label: 'Previous Year', value: 'Previous Year' },
]

export const SALES_TREND = [
    { label: 'Revenue', value: 'revenue' },
    { label: 'No. of Orders', value: 'orders' },
]

export const RANGE = [
    { label: 'Highest', value: 1 },
    { label: 'Lowest', value: 0 },
]

export const COMPANY = [
    { label: 'company1', value: 'company1' },
    { label: 'company2', value: 'company2' },
]

export const DATE_RANGE1 = [
    { label: 'All', value: 8 },
    { label: 'This Month', value:0},
    { label: 'Custom', value:1 },
    { label: 'Today', value: 2 },
    { label: 'This Week', value: 3 },
    { label: 'Last Week', value: 4 },
    { label: 'This Year', value: 5 },
    { label: 'Last Year', value: 6 },
]

export const DATE_RANGE2 = [
    { label: 'This Week', value: 9 },
    { label: 'This Month', value:0},
    { label: 'Custom', value:1 },
    { label: 'Today', value: 2 },
    { label: 'Last Week', value: 4 },
    { label: 'This Year', value: 5 },
    { label: 'Last Year', value: 6 },
    { label: 'To Date', value: 7 },
]

export const CSV_FILENAME = "Publisher-List-Sample.csv";

export const CSV_REQUESTDATA = {
    first_name: "Jacob23",
    last_name: "Rosen23",
    'Publisher-Name': "Chicago Test",
    role_id: "Sales",
    companycatid: "Print, Social Media, Out of Home",
    phone_number: "8006669999",
    email: "email32@chicagotest.com",
    distribution_type: "Local",
    distribution_area_1: "Monsey",
    distribution_area_2: "NYC",
    distribution_area_3: "Crown Heights",
    bill_address: "123 Main Street",
    bill_city: "Monsey",
    bill_state: "NY",
    bill_country: "USA",
    bill_zip_code: "10952",
    email_distribution: "FALSE",
    distributed_copies: "1",
    bio_desc: "",
    status: "1",
    active: "TRUE",
    publishcat: "Insurance, Finance",
    is_website_ad: "",
    website_ad: "",
    is_twitter_post: "1",
    twitter_post: "This is Twitter Post",
    is_insta_post: "",
    insta_post: "",
    is_insta_stories: "1",
    insta_stories: "Here is value for instagram stories",
    is_whatsapp_group: "",
    whatsapp_group: "",
    is_whatsapp_status: "",
    whatsapp_status: "This is whatsapp status",
    is_email_marketing: "",
    email_marketing: "",
    is_sponsored_article: "",
    sponsored_article: "",
    is_in_app_ad: "",
    in_app_ad: "",
    is_facebook_post: "",
    facebook_post: "",
    is_email_blast: "",
    email_blast: "",
}