import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form';
import DeclineModal from '../registration/DeclineModal';
import AcceptModal from '../registration/AcceptModal';
import MessageModal from '../registration/MessageModal';
import * as superAdminDuck from '../../../../store/ducks/SuperAdmin.duck';
import { langs } from '../../../../config/localization';
import { checkPermission } from '../../../../common/CheckPermissions';
import { injectIntl } from 'react-intl';
import '../../Modal.scss'
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../../partials/content/Portlet';
import { convertISOToUtcDate } from '../../../../common'
    
class ReasonBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    /**
    * @method componentDidMount
    * @description called after render the component
    */
    componentDidMount() {
        const { authToken, user_detail } = this.props.loggedInUser;
        const companyId = this.props.companyId;
        if (companyId) {
            this.props.getCompanyDetailById(user_detail.id, companyId, true, authToken, res => {
            })
        }
    }

    /**
   * @method renderFollowersTileRow
   * @description called in render opportunity for showing opportunity in card section
   */
    renderReasonRow = () => {
        const { selectedCompany } = this.props
        const reason = selectedCompany && selectedCompany.rejectReason
        return (Array.isArray(reason) && reason.length>0) && reason.map((data, index) => {
            return (
                <Form.Group style={{ borderBottom: '1px solid #ccc' }}>
                    <Form.Row key={index}>
                        <Form.Group as={Col} md='8' controlId='validationCustom01'>
                            <Form.Label><h5>{data.reason}</h5></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md='4' controlId='validationCustom01'>
                            <Form.Label>{convertISOToUtcDate(data.date)}</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md='8' controlId='validationCustom01'>
                            <Form.Label>{data.other}</Form.Label>
                        </Form.Group>
                    </Form.Row>
                </Form.Group>
            );
        })
    }

    /**
    * @method render
    * @description render the component
    */
    render() {
        const { selectedCompany } = this.props;
        const { openDeclineModal, openAcceptModal, openMessageModal } = this.state
        const createPer = checkPermission(langs.permissionKeys.newspaperCompanyModeration, langs.permissionKeys.create)
        // discussion with backend team remove parsing
        const reason = selectedCompany && selectedCompany.rejectReason

        return (
            <div>
                <div className='col-md-12'>
                <h5>Action</h5>
                    <Portlet >
                        {(Array.isArray(reason) && reason.length) ? <PortletHeader
                            toolbar={
                                <PortletHeaderToolbar className='row' style={{ width: '100%' }}>
                                    <h4 className='col-md-8'>Reason of Rejection</h4>
                                    <h6 className='col-md-4'>Date of Rejection</h6>
                                </PortletHeaderToolbar>
                            } /> : null}

                        <PortletBody>
                            <Form
                                noValidate
                            // validated={validated}
                            >
                                {openDeclineModal && <DeclineModal
                                    isOpen={openDeclineModal}
                                    onCancel={() => this.setState({ openDeclineModal: false })}
                                />}
                                {openAcceptModal && <AcceptModal
                                    isOpen={openAcceptModal}
                                    onCancel={() => this.setState({ openAcceptModal: false })}
                                />}
                                <MessageModal
                                    isOpen={openMessageModal}
                                    onCancel={() => this.setState({ openMessageModal: false })}
                                />
                                {this.renderReasonRow()}
                                <Form.Row>
                                    <Form.Group as={Col} md='6'>
                                        <Button
                                            className='float-left standardBtn'
                                            onClick={() => this.setState({ openMessageModal: true })}
                                        > Message Company </Button>
                                    </Form.Group>
                                    <Form.Group as={Col} md='6'>
                                        {createPer && <Link to='#'><Button
                                            className='float-left standardBtn'
                                            onClick={() => this.setState({ openAcceptModal: true })}> Accept </Button></Link>}
                                        {createPer && <Button
                                            style={{
                                                border: 'none',
                                                width: 150,
                                                marginLeft: 20,
                                                background: '#808080'
                                            }}
                                            onClick={() => this.setState({ openDeclineModal: true })}
                                        > Decline</Button>}
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        selectedCompany: store.superAdminDuck.selectedCompany,
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        superAdminDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'ReasonBar',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(ReasonBar)
    )
);
