import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Calender from "../../../../../_metronic/_assets/media/images/landing-page/calender.png";
import DragDrop from "../../../../../_metronic/_assets/media/images/landing-page/drag-drop.png";
import Bend from "../../../../../_metronic/_assets/media/images/landing-page/bend.png";
import SpecialStar from "../../../../../_metronic/_assets/media/images/landing-page/special-start.png";
import Stars from "../../../../../_metronic/_assets/media/images/landing-page/stars.png";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Aos from "aos";
import "aos/dist/aos.css";

const Buying = () => {
  React.useEffect(() => {
    Aos.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    Aos.refresh();
  });
  return (
    <>
      <div className='lp_Buying_section'>
        <Container>
          <Row>
            <Col lg={6}>
              <div
                className='Buying_content'
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-delay='400'
                data-aos-anchor-placement='top-bottom'>
                <h2>Media Buying can be fun! Really!</h2>
                <p>
                  Do you think ad placement is a nuisance? We designed a way to
                  make it simpler, more efficient, and more fun!
                </p>
                <p className="bold">
                  With Pijen you’ll be gaining valuable tools to make media
                  buying a breeze!
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className='Image_content text-center'>
                <img
                  className='StarsImg'
                  data-aos='zoom-in'
                  data-aos-duration='1000'
                  data-aos-delay='800'
                  data-aos-anchor-placement='top-bottom'
                  src={Stars}
                  alt='stars'
                />
                <img
                  className='CalenderImg'
                  data-aos-delay='1200'
                  data-aos='fade-in'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1200'
                  src={Calender}
                  alt='calender'
                />
                <img
                  className='dragImage'
                  data-aos-delay='1300'
                  data-aos='fade-up'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1200'
                  data-aos-easing='linear'
                  src={DragDrop}
                  alt='drag'
                />
                <img
                  className='bendImage'
                  data-aos='fade-left'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='1300'
                  data-aos-delay='1400'
                  data-aos-anchor-placement='top-bottom'
                  src={Bend}
                  alt='Bend'
                />
                <img
                  className='SpecialstarImage'
                  data-aos-delay='1600'
                  data-aos='fade-in'
                  data-aos-anchor-placement='top-bottom'
                  data-aos-duration='1000'
                  src={SpecialStar}
                  alt='SpecialStar'
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Buying;
