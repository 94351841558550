import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import CodeExample from '../../../../partials/content/CodeExample';
import { MESSAGES } from '../../../../config/message';
import { langs } from '../../../../config/localization';
import NavigateModal from '../../NavigateModal';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Loader } from '../../../../common/Loader';
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import * as adminManagementDuck from '../../../../store/ducks/super-admin/AdminManagement.duck'
import * as commonDuck from '../../../../store/ducks/Common.duck'
import { toastr } from 'react-redux-toastr';
import { phoneNumberFormate,phoneNumberFormateOnChange } from "../../../../common";
import {
  renderThemeSelectOptions,
  renderThemeToggleButton,
  focusOnError
} from '../../FormLayout';
import {
  renderText,
  renderNumberInputField,
  renderPasswordInputField,
} from '../../../../common/layout/FormInput';
import {
  required,
  PhoneNumber,
  minLength5,
  maxLength18,
  minLength6,
  maxLength100,
  maxLengthC100,
  minLength2,
  email,
  selectRequired,
} from '../../../../config/validation';
import { STATUS_CODES } from '../../../../common/StatusCode'
import { NoContentFound } from '../../../../common/NoContentFound'

class EditAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewPassword: false,
      companyRoles: [],
      status: true,
      value: '',
      number: '',
      isSubmitted: false,
      isRedirect: false,
      uniqueEmailId: false,
      disabledNotify: true,
      notifyAdmin: false,
      password: '',
      confirmPassword: '',
      flag: true,
      noContentFound: false
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.viewAdminById(id, res => {
      if (res && res.data !== undefined && res.status === STATUS_CODES.OK) {
        let number = phoneNumberFormate(res.data.phone_number);
        this.setState({ status: res.data.active === true ? true : false,number });
      }else{
        this.setState({noContentFound: true})
      }
    });
    this.props.getAllRoleList(langs.keyname.admin);
  }

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { selectedAdmin } = this.props;
    const { number, notifyAdmin, status } = this.state;
    if (selectedAdmin && selectedAdmin !== undefined) {
      const formdata = {
        id: selectedAdmin.id,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        password: values.password ? values.password.trim() : '',
        confirmPassword: values.confirmPassword ? values.confirmPassword.trim() : '',
        phone_number: number.replace(/-/g, ''),
        email: values.email,
        username: values.username,
        notification: notifyAdmin,
        role_id: values.role_id,
        active: status
      };
      this.setState({ isSubmitted: true });
      this.props.updateAdminProfile(formdata, res => {
        if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
          toastr.success(MESSAGES.UPDATE_ADMIN_SUCCESS)
          this.setState({ isSubmitted: false, isEditFlag: false, loading: false, isRedirect: true });
        } else {
          this.setState({ isSubmitted: true });
        }
      });
    }
  }

  handleChange = name => event => {
    this.setState({
      ...this.state, [name]: !event.target.checked,
      isEditFlag: event.target.checked,
      isSubmitted: true
    });
  };

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    const { name, value } = e.target;
    const { password } = this.state;
    if (name === 'password' || name === 'confirmPassword') {
      this.setState({ [name]: value })
    }
    if (password !== ' && confirmPassword !== ') {
      this.setState({ disabledNotify: false, notifyAdmin: false })
    } else {
      this.setState({ disabledNotify: true })
    }

    this.setState({
      isEditFlag: true,
      isSubmitted: true,
      number: type === 'number' ? e.target.value : this.state.number
    });
  };

  /**
   * @method renderTypeOfListing
   * @description Used show type of listing
   */
  renderTypeOfListing = () => {
    const temp = [];
    const { companyRoles } = this.props;
    companyRoles && companyRoles.isArray() &&
      companyRoles.map(item => temp.push({ id: item.id, name: item.name }));
    return temp;
  };

   /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = e => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
      this.setState({
      number,
      isEditFlag: true,
      isSubmitted: true
    });
  };


  reset = () => {
    this.setState({ isRedirect: true });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit } = this.props;
    const {
      isEditFlag,
      isSubmitted,
      isRedirect,
      loading,
      number,
      notifyAdmin,
      disabledNotify,
      status,
      noContentFound
    } = this.state;
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/adminManagement'
          }}
        />
      );
    }
    if (noContentFound) {
      return (  
          <div>
            <NoContentFound/>
          </div>
      );
    }else{
    return (
      <div>
        {loading && <Loader />}
        <NavigationPrompt when={isEditFlag}>
          {props => {
            const { onConfirm, onCancel } = props;
            return (
              <NavigateModal
                show={true}
                alertHeading={'Changes made will be lost.'}
                cancleButtonText='Cancel'
                okButtonText='Leave Anyway'
                showCancel={true}
                onOkClick={() => onConfirm()}
                onClickCancel={() => {
                  onCancel();
                }}
              />
            );
          }}
        </NavigationPrompt>
        <LayoutContextConsumer className='row'>
          {({ subheader: { title } }) => (
            <div>
              <div className='row'>
                <h3 className='kt-subheader__title col-md-6'>
                  <span>
                    <Link to='/adminManagement'>
                      <i
                        onClick={() => {
                          return this.props.history.push('/adminManagement');
                        }}
                        style={{ fontSize: 20 }}
                        className='fa fa-arrow-left'
                        aria-hidden='true'
                      ></i></Link>
                  </span>
                  <span className='ml-3'>Admin Detail</span>
                </h3>
              </div>
            </div>
          )}
        </LayoutContextConsumer>
        <div className='col-md-12'>
          <CodeExample beforeCodeTitle='Contact Info'>
            <Form
              noValidate
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <Form.Row>
                <Form.Group as={Col} md='6' controlId='validationCustom01'>
                  <Field
                    name='first_name'
                    value={'adminProfile.first_name'}
                    label='First Name'
                    validate={[required, minLength2, maxLengthC100]}
                    placeholder='Enter Your First Name'
                    required={true}
                    onChange={this.onChangeDetailHandeler}
                    component={renderText}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' controlId='validationCustom02'>
                  <Field
                    name='last_name'
                    label='Last Name'
                    validate={[required, minLength2, maxLengthC100]}
                    required={true}
                    placeholder='Enter Your Last Name'
                    onChange={this.onChangeDetailHandeler}
                    component={renderText}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md='6' controlId='validationCustom01'>
                  <Field
                    name='phone_number'
                    label='Phone Number'
                    type='text'
                    maxLength={10}
                    onChange={this.onChange}
                    Value={number}
                    validate={[required, PhoneNumber]}
                    required={true}
                    style={{ width: '100%' }}
                    placeholder='Enter Your Phone Number'
                    component={renderNumberInputField}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' controlId='validationCustom02'>
                  <Field
                    name='email'
                    label='Email'
                    validate={[required, minLength5, maxLength100, email]}
                    required={true}
                    placeholder='Enter Email Id'
                    onChange={this.onChangeDetailHandeler}
                    component={renderText}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Row style={{ marginRight: 5 }}>
                  <div >
                    <div className='kt-portlet__head-label'>
                      <h5 className='kt-portlet__head-title'>Password</h5>
                    </div>
                  </div>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='4' controlId='validationCustom01'>
                    <Field
                      name='password'
                      width={300}
                      label='Password'
                      validate={[minLength6, maxLength18]}
                      placeholder='Enter Your Password'
                      onChange={this.onChangeDetailHandeler}
                      component={renderPasswordInputField}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='4' controlId='validationCustom01'>
                    <Field
                      name='confirmPassword'
                      width={300}
                      label='Confirm Password'
                      validate={[minLength6, maxLength18]}
                      placeholder='Confirm Your Password'
                      onChange={this.onChangeDetailHandeler}
                      component={renderPasswordInputField}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    style={{ marginTop: 40 }}
                    as={Col}
                    md='4'
                    controlId='validationCustom01'
                  >
                    <Form.Check
                      inline
                      label='Notify Admin'
                      value={notifyAdmin}
                      type={'checkbox'}
                      onChange={(e) => {
                        return this.setState({ notifyAdmin: !notifyAdmin })
                      }}
                      id={`inline-${'checkbox'}-1`}
                      disabled={disabledNotify}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <div >
                    <div className='kt-portlet__head-label'>
                      <h5 className='kt-portlet__head-title'>Role</h5>
                    </div>
                  </div>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='4' controlId='validationCustom01'>
                    <Form.Label>
                      Role Type<span className='asterisk-required'>*</span>
                    </Form.Label>
                    <Field
                      name='role_id'
                      label={`Select Role Type`}
                      data={this.props.companyRoles}
                      required={true}
                      validate={[selectRequired]}
                      isSelect={false}
                      component={renderThemeSelectOptions}
                      onChange={this.onChangeDetailHandeler}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={2}></Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId='validationCustom01'>
                      <Form.Label>Status</Form.Label>
                      <div>
                        <Form.Label style={{ color: 'green' }}>
                          Active
                        </Form.Label>
                        <Field
                          name='active'
                          checked={!status}
                          onChange={this.handleChange('status')}
                          required={true}
                          component={renderThemeToggleButton}
                        />
                        <Form.Label style={{ color: 'red' }}>
                          Inactive
                        </Form.Label>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Row>
                <Button
                  onClick={this.reset}
                  className='commonBtn float-right'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  disabled={isSubmitted ? false : true}
                  style={{
                    marginRight: 10,
                    width: 140,
                    backgroundColor: 'black'
                  }}
                  className='btn btn-secondary float-right'
                  variant='dark'

                >
                  Save
                </Button>
              </Form.Group>
            </Form>
          </CodeExample>
        </div>
      </div>
    );
    }          
  }
}

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
  let errors = {};
  //Check the value should not be empty
  //Checks password & confirm password validation both are same or not
  if (values.password && !values.confirmPassword) {
    errors.confirmPassword = 'Confirm Password is Required.';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = langs.messages.password_unmatch;
  }
  return errors;
}

const mapStateToProps = store => {
  const { loading, roles, selectedAdmin } = store.commonDuck;
  let initialValues = {};
  if (selectedAdmin && selectedAdmin !== undefined) {
    initialValues = {
      first_name:
        selectedAdmin.first_name[0].toUpperCase() +
        selectedAdmin.first_name.slice(1).trim(),
      last_name: selectedAdmin.last_name,
      phone_number: selectedAdmin.phone_number,
      email: selectedAdmin.email,
      username: selectedAdmin.username,
      role_id: selectedAdmin.role_id,
      password: '',
      confirmPassword: ''
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    active: selectedAdmin !== undefined && selectedAdmin.active,
    companyRoles: roles && roles.admin ? roles.admin : [],
    loading,
    initialValues,
    selectedAdmin
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...commonDuck.actions, ...adminManagementDuck.actions }
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'EditAdmin',
      validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors)
      }
    })(EditAdmin)
  )
);
