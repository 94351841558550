import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { Link } from "react-router-dom";
import { Table, Col, Row } from "reactstrap";
import Revenue from "./Revenue";
import AdTypeList from "./AdTypeList";
import NPCCalendar from "./Calender";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../common/layout/FormInput";
import { DURATION } from "../../../../config/constant";
import { STATUS_CODES } from "../../../../common/StatusCode";
import * as NpcDashboardRecord from "../../../../store/ducks/npc/Dashboard.duck";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import { Loader } from "../../../../common/Loader";
import { MESSAGES } from "../../../../config/message";
import { topRecordsFilterKeys } from "../../../../common/CommonFilterFunction";
import CountFigureBox from "../../../../common/CountFigureBox";

class NPCDashboard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: false,
      duration: DURATION,
      dateVisible: false,
      startDate: "",
      endDate: ""
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getDashboardRecord();
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record for diffrent modules
   */
  getDashboardRecord = () => {

    const { user_detail } = this.props.loggedInUser;

   

    

    let requestData1 = {
      type: 1,
      npc_id: user_detail.newspaper.id,
    };
    this.props.getTypeData(requestData1, 1, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });

    requestData1.type = 2;
    this.props.getTypeData(requestData1, 2, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
    requestData1.type = 3;
    this.props.getTypeData(requestData1, 3, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
    requestData1.type = 4;
    this.props.getTypeData(requestData1, 4, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
    requestData1.type = 6;
    this.props.getTypeData(requestData1, 6, (res) => {});
    requestData1.type = 7;
    this.props.getTypeData(requestData1, 7, (res) => {});
  };

  /**
   * @method renderCard
   * @description  render dashboard plan cards data
   */
  renderCard = (lable, value) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        {value ? (
          <h4 className="heading">{`${value}`}</h4>
        ) : (
          <h4 className="heading">0</h4>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method renderOurPlan
   * @description render plan details
   */
  renderOurPlan = (lable, value) => {
    return (
      <CountFigureBox isAutoHeight={true}>
        <h5>
          <Link to={"/ourPlans"} className="heading">
            {lable}
          </Link>
        </h5>
        {value !== undefined ? (
          <h4 className="heading">{`${value}`}</h4>
        ) : (
          <h4 className="heading">0</h4>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method renderNoRecordFound
   * @description render no record found card
   */
  renderNoRecordFound = () => {
    return (
      <CountFigureBox isAutoHeight={true}>
        <h4 className="heading">{MESSAGES.NO_RECORD_FOUND}</h4>
      </CountFigureBox>
    );
  };

  /**
   * @method renderCreditDetail
   * @description render payout details
   */
  renderCreditDetail = (lable, date, amt, amountLabel) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        {date ? <h4 className="heading">{`${amountLabel} ${date}`}</h4> : ""}
        {amt ? (
          <h4 className="heading">{amt !== "" && `${amountLabel}${amt}`}</h4>
        ) : (
          <h4 className="heading">{`${amountLabel}0`}</h4>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method renderPublishRecords
   * @description render the publish calender records
   */
  renderPublishRecords = () => {
    const { npcModule4 } = this.props;
    if (npcModule4 && npcModule4.currentWeekOrders.length) {
      return (
        npcModule4 !== undefined &&
        npcModule4.currentWeekOrders.map((data, i) => {
          return (
            <ul key={i} className="list-group ">
              <li className="list-group-item">
                <span className="dot"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                {data.plan_name}
              </li>
            </ul>
          );
        })
      );
    } else {
      return <div>{MESSAGES.NO_RECORD_FOUND}</div>;
    }
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values) {
    const { startDate, endDate, dateVisible } = this.state;
    const { user_detail } = this.props.loggedInUser;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      npc_id: user_detail.newspaper.id,
      month: data.this_month,
      year: data.this_year,
      type: 3,
      from_date: data.date,
      to_date: data.date2,
    };
    this.props.getTypeData(requestData, 3, (res) => {});
  }

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { duration, dateVisible, startDate, endDate} = this.state;
    const {
      loading,
      npcModule1,
      npcModule2,
      npcModule3,
      credit_cancel_orders,
      credit_cancel_revenue,
      credit_amount_utilized,
      credit_amount_pending,
      credit_amount_expired,
      lastPayout,
      lastPayoutDate,
      upCommingPayout,
      upCommingPayoutDate,
      lastPayoutStatementId,
      totalPayout
    } = this.props;
    return (
      <>
        {/* {loading && <Loader />} */}
        <div>
          <h3 className="kt-subheader__title">
            <span>Our Plans</span>
          </h3>
          <Portlet>
            <PortletBody fluid={true}>
              <div className="row w-100">
                <div className="col-md-4">
                  {npcModule1
                    ? this.renderOurPlan(
                        "Total No. of Plans",
                       

                        npcModule1.plans && npcModule1.social_plans 
                          ? 
                          ((parseInt(npcModule1.plans.active_plans)) + (parseInt(npcModule1.social_plans.active_plans)))
                          :
                          npcModule1.plans
                            ?
                            npcModule1.plans.active_plans
                            :
                            npcModule1.social_plans
                              ?
                              npcModule1.social_plans.active_plans
                              :
                              0

                      )
                    : this.renderNoRecordFound()}
                  {npcModule1
                    ? this.renderOurPlan(
                        "Inactive Plan by System",
                       
                        npcModule1.plans && npcModule1.social_plans 
                          ? 
                          ((parseInt(npcModule1.plans.inactive_plans)) + (parseInt(npcModule1.social_plans.inactive_plans)))
                          :
                          npcModule1.plans
                            ?
                            npcModule1.plans.inactive_plans
                            :
                            npcModule1.social_plans
                              ?
                              npcModule1.social_plans.inactive_plans
                              :
                              0
                      )
                    : this.renderNoRecordFound()}
                </div>
                <div className="col-md-8">
                  <CountFigureBox alignStart={true} isAutoHeight={true}>
                    {npcModule2 && <AdTypeList header={"Plan Name"} />}
                  </CountFigureBox>
                </div>
              </div>
            </PortletBody>
          </Portlet>

          <div className="row mt-3 mb-1">
            <h4 className="col-md-9 mt-3 d-flex-center">Orders</h4>
            <div className="col-md-3">
              <Field
                name="date_range"
                options={duration}
                optionValue={"value"}
                optionLabel={"label"}
                onChange={(e) => {
                  if (e.target.value === "1") {
                    this.setState({ dateVisible: true });
                  } else {
                    this.setState({ dateVisible: false });
                    this.props.change("from", "");
                    this.props.change("to", "");
                    this.applyFilter(e.target.value);
                  }
                }}
                component={renderSelectField}
              />
            </div>
          </div>
          {dateVisible && (
            <div className="row">
              <h4 className="col-md-6">{""}</h4>
              <div
                className="row col-md-6"
                style={{ marginTop: -20, marginBottom: 20 }}
              >
                <Col md="6">
                  <Field
                    name="from"
                    label="From"
                    minDate={endDate}
                    onChange={(e) => {
                      this.props.change("to", "");
                      this.setState({ startDate: e }, () => {
                        this.applyFilter(e);
                      });
                    }}
                    component={renderDatePicker}
                  />
                </Col>
                <Col md="6">
                  <Field
                    name="to"
                    label="To "
                    minDate={startDate}
                    disabled={startDate === ""}
                    onChange={(e) => {
                      this.setState({ endDate: e }, () => {
                        this.applyFilter(e);
                      });
                    }}
                    component={renderDatePicker}
                  />
                </Col>
              </div>
            </div>
          )}
          <Portlet style={{ marginTop: -19 }}>
            <PortletBody fluid={true}>
              <Row className="w-100">
                <Col xs="12">
                  <div className="row my-3">
                    <div className="cardWrapper col-md-6">
                      {npcModule3 !== undefined &&
                        this.renderCard(
                          "Total No. of Orders",
                          npcModule3.orders
                            ? npcModule3.orders.total_orders
                            : ""
                        )}
                    </div>
                    <div className="cardWrapper col-md-6 ">
                      {npcModule3 !== undefined &&
                        this.renderCard(
                          "No. of Orders Pending",
                          npcModule3.orders
                            ? npcModule3.orders.pending_orders
                            : ""
                        )}
                    </div>

                    <div className="cardWrapper col-md-6 ">
                      {npcModule3 !== undefined &&
                        this.renderCard(
                          "No. of Orders In progress",
                          npcModule3.orders
                            ? npcModule3.orders.progress_orders
                            : ""
                        )}
                    </div>
                    <div className="cardWrapper col-md-6">
                      {npcModule3 !== undefined &&
                        this.renderCard(
                          "No. of Orders Completed",
                          npcModule3.orders
                            ? npcModule3.orders.completed_orders
                            : ""
                        )}
                    </div>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="row my-3">
                    <div className="col-md-3">
                      <h5 style={{ marginBottom: 25, marginTop: 16 }}>
                        Ad Publishing Calender
                      </h5>
                      {this.renderPublishRecords()}
                    </div>
                    <div className="col-md-9">
                      <NPCCalendar />
                    </div>
                  </div>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
        </div>

        <div className="row" style={{ marginTop: 23 }}>
          <div className="col-md-12">
            <h4>Revenue</h4>
            <Revenue npcModule1={npcModule1 && npcModule1} />
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-6">
            <h5>Payouts</h5>
            <Portlet>
              <PortletBody fluid={true}>
                <Table borderless responsive>
                  <tr>
                    <th>{"Last Payout"}</th>
                    <td>{lastPayoutDate ? lastPayoutDate : "No Data Found"}</td>
                    <td>
                      {lastPayoutStatementId
                        ? lastPayoutStatementId
                        : "No Data found"}
                    </td>
                    <td>{lastPayout ? `$${lastPayout}` : "$0"}</td>
                  </tr>
                  <tr>
                    <th>{"Upcoming Payout"}</th>
                    <td colSpan="2">
                      {upCommingPayoutDate
                        ? upCommingPayoutDate
                        : "No Data found"}
                    </td>

                    <td>{upCommingPayout ? `$${upCommingPayout}` : "$0"}</td>
                  </tr>
                  <tr>
                    <th>{"Total No. Of Payout"}</th>
                    <td colSpan="2">
                      {totalPayout ? totalPayout : "No Data Found"}
                    </td>

                    {(lastPayoutDate ||
                      lastPayoutStatementId ||
                      lastPayout ||
                      upCommingPayoutDate ||
                      upCommingPayout ||
                      totalPayout) && (
                      <td>
                        <Link to="/payoutHistory">
                          <label
                            style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                          >
                            View All
                          </label>
                        </Link>
                      </td>
                    )}
                  </tr>
                </Table>
              </PortletBody>
            </Portlet>
          </div>
          <div className="col-md-6">
            <h5>Credits</h5>
            <Portlet>
              <PortletBody fluid={true}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    {this.renderCreditDetail(
                      "No. of Orders Cancelled",
                      credit_cancel_orders,
                      credit_cancel_revenue,
                      ""
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    {this.renderCreditDetail(
                      "Credit Amount Utilized",
                      credit_amount_utilized,
                      "",
                      "$"
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    {this.renderCreditDetail(
                      "Credit Amount Pending",
                      credit_amount_pending,
                      "",
                      "$"
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    {this.renderCreditDetail(
                      "Expired Credit Amount",
                      credit_amount_expired,
                      "",
                      "$"
                    )}
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const {
    npcModule1,
    npcModule2,
    npcModule3,
    npcModule4,
    dashboardCreditData,
    dashboardPayoutData,
  } = store.npcDashboardRecord;
  const { loading } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
    npcModule1,
    npcModule2,
    npcModule3,
    npcModule4,

    credit_cancel_orders:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.cancelled_orders[0].cancelled_orders
        : "",
    credit_cancel_revenue:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.cancelled_orders[0].cancelled_revenue
        : "",
    credit_amount_utilized:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.credits[0].utilized_amount
        : "",
    credit_amount_expired:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.credits[0].credit_expired
        : "",
    credit_amount_pending:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.credits[0].pending_credit
        : "",

    lastPayout:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.lastPayOut)
        ? dashboardPayoutData.lastPayOut[0].payment
        : "",
    lastPayoutStatementId:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.lastPayOut)
        ? dashboardPayoutData.lastPayOut[0].statement_id
        : "",

    lastPayoutDate:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.lastPayOut)
        ? dashboardPayoutData.lastPayOut[0].payment_date
        : "",

    totalPayout:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.totalPayouts)
        ? dashboardPayoutData.totalPayouts[0].count
        : "",

    upCommingPayout:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.upcomingPayOut)
        ? dashboardPayoutData.upcomingPayOut[0].payment
        : "",
    upCommingPayoutDate:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.upcomingPayOut)
        ? dashboardPayoutData.upcomingPayOut[0].date
        : "",
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...NpcDashboardRecord.actions, ...NPCDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "NPCDashboard",
    })(NPCDashboard)
  )
);
