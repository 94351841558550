import React, { useState } from 'react'
import { Link } from "react-router-dom";


const Navtwo = () => {

    let [toggleCollapse, setToggleCollapse] = useState(true)

	const handleToggle = () => {
		toggleCollapse = setToggleCollapse(!toggleCollapse)
		console.log(toggleCollapse);
		
	}

    return (
        <React.Fragment>
			<header className="header_sec">
				<div className="hdrbtm headFxd">
					<div className="container">
						<nav className="navbar navbar-expand-lg navbar-light nav_top">
							<Link to="/" className="navbar-brand"><img src="assets/images/logo.png" alt="logo" /></Link>
							{
								toggleCollapse ? (
									<button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={handleToggle}>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</button>
								) : (
									<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={handleToggle}>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</button>
								)
							}

							{
								toggleCollapse ? (
									<div className="collapse navbar-collapse justify-content-end hide" id="navbarSupportedContent">
										<ul className="navbar-nav menu_sec">
											<li>
												{/* <Link to={{ pathname: "/email-campaign/auth/login" }} ></Link> */}
												<a href="/email-campaign/auth/login"></a>
											</li>
											<li class="nav-item LoginItem">
												<a class="nav-link mr-3" href="/publisher-benefit" role="button" aria-haspopup="true" aria-expanded="false">
												Publisher Benefit
												</a>
											</li>
											
										</ul>
									</div>
								) : (
									<div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
										<ul className="navbar-nav menu_sec">
											<li>
												<Link to={{ pathname: "/email-campaign/auth/login" }} >Publishers Benefits</Link>
											</li>
										</ul>
									</div>
								)
							}

						</nav>
					</div>
				</div>
			</header>

		</React.Fragment>
    )
}

export default Navtwo
