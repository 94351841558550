import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import * as commonDuck from '../../../../store/ducks/Common.duck'
import * as registrationDuck from '../../../../store/ducks/super-admin/Registration.duck';
import { toastr } from 'react-redux-toastr'
import { Redirect } from 'react-router-dom';
import { Loader } from '../../../../common/Loader';
import { MESSAGES } from '../../../../config/message'
import { langs } from "../../../../config/localization";
import {
    Button,
    Form,
} from 'react-bootstrap';
import {
    renderSelectField, renderTextAreaField
} from '../../../../common/layout/FormInput';
import {
    required,
} from '../../../../config/validation'
import { STATUS_CODES } from '../../../../common/StatusCode'

class DeclineModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false,
            isSubmitted: true,
            flag: false,
            loading: false,
            showOtherReason: false
        }
    }

    /**
    * @method componentDidMount
    * @description called after mounting the component
    */
    componentDidMount() {
        this.props.getAllReasonList(langs.keyname.reject_reason)
    }

    /**
    * @method toggleModel
    * @description Used to cancel modal
    */
    toggleModel = () => {
        this.props.onCancel();
    }

    /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items 
   */
    onSubmit(values) {
        const { selectedCompany } = this.props;
        const { showOtherReason } = this.state
        if (selectedCompany && selectedCompany !== undefined) {
            const data = {
                newspaper_id: selectedCompany.id,
                user_id: selectedCompany.user_id,
                status: 2,
                active: false,
                reason_type: showOtherReason ? '' : values.reason_subtype,
                reason_subtype: values.reason_message,
                first_name: selectedCompany.user.first_name,
                newspaper_name: selectedCompany.newspaper_name,
                email: selectedCompany.user.email,

            }
            this.setState({ isSubmitted: false })
            this.props.rejectRequest(data, res => {
                if (res.status === STATUS_CODES.CREATED) {
                    this.setState({ show: false, isRedirect: true })
                    this.toggleModel();
                    toastr.success(MESSAGES.REQUEST_REJECT_SUCCESS)
                    return <Redirect to='/newspaperManagement' />
                }
            })
        }
    }


    render() {
        const { handleSubmit, loading, isOpen, reasonList } = this.props;
        const { isRedirect, isSubmitted, showOtherReason } = this.state;

        // reasonList.push({ id: 0, name: 'Other Reason' })

        if (isRedirect) {
            return (
                <Redirect push
                    to={{
                        pathname: '/newspaperManagement'
                    }}
                />
            );
        }
        return (
            <Modal
                isOpen={isOpen} toggle={this.toggleModel} styles={{ height: 10 }}>
                {loading && <Loader />}
                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                    Decline Company
                                </ModalHeader>
                <ModalBody >
                    <Row>
                        <Container>
                            <form
                                className='form'
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='reason_subtype'
                                            label='Select Reason'
                                            options={[...reasonList, { id: 0, name: 'Other Reason' }]}
                                            required={true}
                                            validate={required}
                                            isSelect={false}
                                            onChange={(e) => {
                                                e.target.value === 'Other Reason' ? this.setState({ showOtherReason: true }) : this.setState({ showOtherReason: false })
                                            }}
                                            optionValue={'name'}
                                            optionLabel={'name'}
                                            component={renderSelectField}
                                        />
                                    </Col>
                                </Row>
                                {showOtherReason && <Row>
                                    <Col md='12'>
                                        <Form.Label>Other Reason</Form.Label>
                                        <Field
                                            name='reason_message'
                                            component={renderTextAreaField}
                                        />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col md='12 float-right text-right'>
                                        <Button style={{ margin: 10, background: 'black', width: 100 }} disabled={isSubmitted ? false : true} size='md' type='submit'>Send</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

const mapStateToProps = store => {
    const { commonDuck } = store

    return ({
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        selectedCompany: store.registrationDuck.selectedCompany,
        reasonList: commonDuck.reasonList ? commonDuck.reasonList : []
    })
}

export default injectIntl(
    connect(
        mapStateToProps,
        { ...registrationDuck.actions, ...commonDuck.actions }
    )(reduxForm({
        // Assign unique name for the form
        form: 'DeclineModal'
    })(DeclineModal))
);
