import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import MainCalender from "./MainCalender";
import "aos/dist/aos.css";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { BsCheckSquare } from "react-icons/bs";
import { BiSquareRounded } from "react-icons/bi";
import Aos from "aos";
import * as superAdminDuck from "../../../../store/ducks/SuperAdmin.duck";
import { toastr } from "react-redux-toastr";
import { Loader } from "../../../../common/Loader";
import { connect } from "react-redux";

class CalenderContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeView: "publisher",
      allEditions: [],
      activeEditions: null,
      activeEditionName: "",
      publishers: [],
      selectedPublishers: [],
      calenderData: [],
    };
    Aos.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    Aos.refresh({});
  }
  componentDidMount() {
    this.props.getAllActiveSeasonalIssueForUser((res) => {
      if (res.status == 200) {
        this.setState({
          allEditions: res.data?.activeSeasonalIssues,
          activeEditions: res.data?.activeSeasonalIssues?.length
            ? res.data?.activeSeasonalIssues?.[0].id
            : null,
          activeEditionName: res.data?.activeSeasonalIssues?.length
            ? res.data?.activeSeasonalIssues?.[0].calendar_name
            : "",
        });
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeEditions !== this.state.activeEditions) {
      const data = {
        issueId: this.state?.activeEditions?.toString(),
        type: "publisher",
        // NOTE
        userId: [1236],
      };
      this.props.getAllPublisherSeasonalIssue(data, (res) => {
        if (res.status == 200) {
          this.setState({
            publishers: res.data.allPublishers[0]?.onePublisher,
          });
        }
      });
    }
    if (
      this.state.selectedPublishers?.length &&
      (prevState.activeView !== this.state.activeView ||
        prevState.selectedPublishers.length !==
          this.state.selectedPublishers?.length)
    ) {
      const data = {
        issueId: this.state?.activeEditions?.toString(),
        type: this.state.activeView == "deadline" ? "deadline" : "calendar",
        userId: this.state.selectedPublishers,
      };
      this.props.getAllPublisherSeasonalIssue(data, (res) => {
        if (res.status == 200) {
          this.setState({ calenderData: res.data.allPublishers });
        }
      });
    }
    if (
      prevState.selectedPublishers?.length !==
        this.state.selectedPublishers?.length &&
      this.state.selectedPublishers?.length == 0
    ) {
      this.setState({ calenderData: [] });
    }
  }
  handlePublisherSelection = (ev, id) => {
    let copyAr = Array.from(this.state.selectedPublishers);
    if (ev.target.checked) {
      copyAr.push(id);
    } else {
      copyAr = copyAr.filter((e) => e !== id);
    }
    this.setState({ selectedPublishers: copyAr });
  };
  render() {
    return (
      <Fragment>
        <div className="calender_content_main">
          <Container>
            <div className="main_heading">
              <h2
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
                data-aos-anchor-placement="top-bottom"
              >
                Stay in sync with the time
              </h2>
              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="400"
                data-aos-anchor-placement="top-bottom"
              >
                Find all the upcoming holiday deadlines in one easy calendar.{" "}
                <br />
                Just select which publishers to display, and you'll have all the
                information you need at your fingertips.
              </p>
            </div>
            <div className="body_content">
              <div className="publisher_sidebar">
                <div className="addition_heading">
                  <h5>Seasonal Editions</h5>
                  <div className="additions">
                    {this.state.allEditions?.length > 0 ? (
                      this.state.allEditions.map((e) => {
                        return (
                          <span
                            className={
                              this.state.activeEditions === e.id ? "active" : ""
                            }
                            key={e.id}
                            onClick={() =>
                              this.setState({
                                activeEditions: e.id,
                                activeEditionName: e.calendar_name,
                                selectedPublishers: [],
                                calenderData: [],
                              })
                            }
                          >
                            {e.calendar_name}
                          </span>
                        );
                      })
                    ) : (
                      <span>No data available</span>
                    )}
                  </div>
                </div>
                <div className="publishers">
                  <div className="heading">
                    <h5>Publishers</h5>
                  </div>
                  <div className="publishers_name">
                    {this.state.publishers.length > 0 ? (
                      this.state.publishers.map((e, index) => {
                        return (
                          <div class="form-group" key={index}>
                            <input
                              type="checkbox"
                              id={`${e.newspaper_name}-${index}-${e.createdBy}`}
                              onChange={(ev) =>
                                this.handlePublisherSelection(ev, e.createdBy)
                              }
                              checked={this.state.selectedPublishers.includes(
                                e.createdBy
                              )}
                            />
                            <label
                              for={`${e.newspaper_name}-${index}-${e.createdBy}`}
                            >
                              <span>{e.newspaper_name}</span>
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <span>No data available</span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="calender"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
                data-aos-anchor-placement="top-bottom"
              >
                <MainCalender
                  activeView={this.state.activeView}
                  setActiveView={(newView) => {
                    if (this.state.activeView !== newView) {
                      this.setState({ activeView: newView, calenderData: [] });
                    }
                  }}
                  data={this.state.calenderData}
                  activeEditionName={this.state.activeEditionName}
                />
              </div>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    loading,
  };
};

export default connect(
  mapStateToProps,
  superAdminDuck.actions
)(CalenderContent);
