import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { injectIntl } from "react-intl";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { MESSAGES } from "../../../../config/message";
import { Button } from "@material-ui/core";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import * as salesReportDuck from "../../../../store/ducks/super-admin/report-management/SalesReport.duck";
import StandardTable from "../../StandardTable";
import AddCompany from "./AddCompany";
import ConformModel from "../ConformModel";
import PaymentMethode from "./PaymentMethode";
import { checkPermission } from "../../../../common/CheckPermissions";
import { langs } from "../../../../config/localization";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { CSV_FILENAME, CSV_REQUESTDATA, PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { DeleteIconButton, EditIconLink } from "../../../../common/Buttons";
import AddMultipleCompany from "./AddMultipleCompany";
import sampleCSV from './newPaperBulk.csv'
import DownloadCSV from "./DownloadCSV";
import { CSVLink } from "react-csv";

class AllNewspaperList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEditFlag: false,
      isRedirect: false,
      rows: [],
      renderNewAdminForm: false,
      companyList: [],
      isConfirm: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      isOpenModal1: false,
      isOpenModalForMultiCom: false,
      isOpenModal2: false,
      showPaymentModal: false,
      showConfirmModal: false,
      companyId: "",
      userId: "",
      showSearchResults: false,
      isAdvanceSearch: true,
      newspaperId: undefined,
      message: "",
      advanceSerchResults: false,
      allList: [],
      sampleCsvList: [],
      searchKey: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getCompanyList(this.state.pageSize, 1);
    this.getSampleCSV();
  }

   /**
   * @method getSampleCSV
   * @description  used to get all admin listing
   */
    getSampleCSV = () => {
      let requestData =  CSV_REQUESTDATA 
      this.setData(requestData);
    };


    /**
   * @method setData
   * @description  used to set data module wise on api
   */
  setData = (requestData) => {
    this.props.getSampleCSV(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        //console.warn("Response Sample", res)
        Array.isArray(res.data.sampleCSV) &&
         this.setState({ sampleCsvList :res.data.sampleCSV })
      }
    });
  };

  /**
   * @method openModel
   * @description  used to open add company model
   */
  openModel = () => {
    this.getCompanyList(this.state.pageSize);
    this.setState({
      isOpenModal1: true,
      isEditFlag: false,
      showConfirmModal: false,
    });
  };
   /**
   * @method openMultipleCompanyModel
   * @description  used to open add multiple company model
   */
  openMultipleCompanyModel = () => {
    this.setState({
      isOpenModalForMultiCom: true,
    });
  };
  /**
   * @method openModel
   * @description  used to open payment model
   */
  togglePaymentModel = () => {
    const { pageSize, showPaymentModal } = this.state;
    this.getCompanyList(pageSize);
    this.setState({
      showPaymentModal: !showPaymentModal,
      isEditFlag: false,
      showConfirmModal: false,
    });
  };

  /**
   * @method onCancel
   * @description  used to cancel filter form
   */
  onCancel = () => {
    this.getCompanyList(this.state.pageSize);
    this.setState({ isOpenModal1: false });
  };

    /**
   * @method onCancelMultiCom
   * @description  used to cancel mutiple company modal
   */
  onCancelMultiCom = () => { 
    this.getCompanyList(this.state.pageSize);
    this.setState({ isOpenModalForMultiCom: false });
  };
  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getCompanyList = (row, page) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.props.resetAllNewspaperList(res);
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          companyList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { deletedItem, pageSize, searchKey, showSearchResults } = this.state;
    const adminDetails = deletedItem;
    const requestData = {
      companyId: adminDetails.id,
      user_id: adminDetails.user_id,
      email: encodeURIComponent(adminDetails.user.email),
      first_name: encodeURIComponent(adminDetails.user.first_name),
    };
    this.props.deleteCompanyById(requestData, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        this.setState({
          showConfirmModal: false,
        });
        toastr.success(MESSAGES.DELETE_COMPANY_SUCCESS);
        this.getCompanyList(pageSize);
        if (showSearchResults) {
          const { authToken } = this.props.loggedInUser;
          let requestData = {
            searchKey: searchKey,
            is_deleted: 0,
            status: 1,
          };
          this.props.searchInNewspaperList(requestData, authToken);
        }
      }
    });
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getCompanyList(this.state.pageSize, page + 1);
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getCompanyList(pageSize, this.state.page);
    });
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
    } else if (e.target.value.length >= 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      const { authToken } = this.props.loggedInUser;
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 0,
        status: 1,
      };
      this.props.searchInNewspaperList(requestData, authToken);
    }
  };

  /**
   * @method againCalledList
   * @description used to reset list
   */
  againCalledList = () => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: 25,
      page: 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.props.resetAllNewspaperList(res);
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          companyList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
        this.props.resetAdvanceSearch(res);
      }
    });
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = () => {
    const createPermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.create
    );
    const deletePermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.delete
    );
    const columnOrder = [
      {
        Header: "Newspaper",
        Cell: (row) => {
          const { original } = row;
          const imageURL = original && original.user && original.user.image_url;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="mr-2 ml-2"
                src={
                  imageURL
                    ? `${PROFILE_MEDIA_URL}/${imageURL}`
                    : require("../../../../../assets/images/defaultUser.png")
                }
                alt=""
                style={{
                  borderRadius: "100%",
                  height: 30,
                  width: 35,
                  marginLeft: 6,
                }}
              />
              <Link
                className="text-truncate"
                to={`/viewcompany/${original.id}`}
              >
                {original.newspaper_name}
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Name",
        Cell: (row) => {
          const { original } = row;
          return original.user && original.user.first_name
            ? original.user.first_name + " " + original.user.last_name
            : "";
        },
      },
      {
        Header: "Email",
        accessor: "email", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return <span className="cust_mail">{original.user.email}</span>;
        },
      },
      {
        Header: "Phone",
        accessor: "Phone", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          // let match = original.user.phone_number.match(
          //   /^(\d{3})(\d{3})(\d{4})$/
          // );
          return original.user.phone_number;
        },
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.active ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          );
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        accessor: "friend.age",   
        Cell: (row) => (
          <div style={{ padding: 5 }}>
            {createPermission && (
              <EditIconLink to={`/viewcompany/${row.original.id}`} />
            )}

            {deletePermission && (
              <DeleteIconButton
                onClick={() =>
                  this.setState({
                    showConfirmModal: true,
                    deletedItem: row.original,
                  })
                }
              />
            )}
          </div>
        ),
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const {
      isOpenModal1,
      isOpenModalForMultiCom,
      newspaperId,
      pageSize,
      totalCount,
      isAdvanceSearch,
      showSearchResults,
      showConfirmModal,
      userId,
      companyId,
      showPaymentModal,
      isEditFlag,
      sampleCsvList
    } = this.state;
    const {
      advanceSearchRecords,
      companyList,
      searchResults,
      loading,
    } = this.props;
    const viewPermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.view
    );
    const createPermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.create
    );

    let results;
    if (
      searchResults &&
      showSearchResults === true &&
      searchResults.length == 0
    ) {
      results = [];
    } else if (
      searchResults &&
      searchResults.length &&
      showSearchResults === true
    ) {
      results = searchResults;
    } else if (
      advanceSearchRecords &&
      advanceSearchRecords.length === 0 &&
      advanceSearchRecords !== ""
    ) {
      results = [];
    } else if (advanceSearchRecords && advanceSearchRecords.length) {
      results = advanceSearchRecords;
    } else if (searchResults === undefined) {
      results = [];
    } else if (searchResults.length === 0 && showSearchResults === true) {
      results = [];
    } else {
      results = companyList;
    }
    return (
      <div>
        <ConformModel
          isOpen={showConfirmModal}
          onCancelClick={this.cancelDelete}
          onOkClick={this.confirmDelete}
        />
        {this.props.tab === 0 && (
          <LayoutContextConsumer>
            {({ subheader: { title } }) => (
              <div className="row">
                <div className="col-md-4">
                  {viewPermission && (
                    <div>
                      <div>
                        <h1
                          style={{
                            fontSize: 100,
                            color: "#696969",
                            marginBottom: -20,
                          }}
                        >
                          {this.props.totalCompany
                            ? this.props.totalCompany
                            : 0}
                        </h1>
                      </div>
                      <div>
                        <h6
                          style={{
                            fontSize: 15,
                            color: "#696969",
                            fontWeight: "bold",
                          }}
                        >
                          Total Companies
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{ marginTop: 20 }}
                  className="row row-full-height col-md-8"
                >
                  {createPermission && (
                    <h5 className="kt-subheader__title col-md-12" style={{ display: "flex", right: 0 }}>
                      <div onClick={this.openModel} style={{ display: "inline-block",float: "right" }}>
                        <Link to="#">
                          <span 
                            style={{ 
                                  color: "black",
                                  fontSize: 0.7 + "em",
                                  paddingRight: 10 + "px"
                                  }}
                          >
                            <i class="fas fa-plus"></i> ADD NEW COMPANY
                          </span>
                        </Link>
                      </div>
                     
                      <div onClick={this.openMultipleCompanyModel} style={{ display: "inline-block", float: "right" }}>
                        <Link to="#">
                          <span style={{ 
                              color: "black", 
                              fontSize: 0.7 + "em",
                              paddingRight: 10 + "px"   
                            }}
                          >
                            <i class="fas fa-upload"></i> UPLOAD CSV COMPANY
                          </span>
                        </Link>
                      </div>
                      <div style={{ display: "inline-block",float: "right" }}>
                        
                      {/* <Link to="/files/newsPaperSample.csv" target="_blank" download>
                        <span style={{ color: "black" }}>
                          <i class="fas fa-long-arrow-alt-down"></i> DOWNLOAD SAMPLE CSV 
                        </span>
                      </Link> */}
                      
                      <CSVLink
                        filename={CSV_FILENAME}
                        data={sampleCsvList}
                        // headers={headers}
                        style={{ 
                          color: "black",  
                          fontSize: 0.7 + "em",
                          paddingRight: 10 + "px"  
                        }}
                      >
                        <i class="fas fa-download"></i> DOWNLOAD SAMPLE CSV 
                      </CSVLink>
                      {/* <DownloadCSV /> */}
                      
                        {/* <a href={{ sampleCSV }} download>
                          <span style={{ color: "black" }}>
                            + SAMPLE CSV DOWNLOAD
                          </span>
                        </a> */}
                      </div>
                    </h5>
                    
                  )}
                </div>
              </div>
            )}
          </LayoutContextConsumer>
        )}
        {viewPermission ? (
          <StandardTable
            handelSearch={this.handleSearch}
            columnOrder={this.renderRecords()}
            totalCount={totalCount}
            allData={this.state.companyList}
            data={results}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
            isAdvanceSearch={isAdvanceSearch}
            history={this.props.history}
          />
        ) : (
          <div className="text-center" style={{ marginTop: "10px" }}>
            <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
          </div>
        )}
        {isOpenModal1 && (
          <AddCompany
            isOpen={isOpenModal1}
            onCancel={this.onCancel}
            isEditFlag={isEditFlag}
            companyId={companyId}
            callList={this.againCalledList}
            userId={userId}
            callNext={(newspaperId) =>
              this.setState({
                showPaymentModal: true,
                newspaperId: newspaperId,
              })
            }
          />
        )}
        {isOpenModalForMultiCom && (
          <AddMultipleCompany
            isOpen={isOpenModalForMultiCom}
            onCancel={this.onCancelMultiCom}
            userId={userId}
            callNext={() =>{

              }
            }
          />
        )}
        {showPaymentModal && (
          <PaymentMethode
            newspaperId={newspaperId}
            isOpenpaymentModel={showPaymentModal}
            callList={() => {
              this.getCompanyList(pageSize, 1);
            }}
            onCancelPaymentModel={() => {
              this.getCompanyList(pageSize);
              this.setState({ showPaymentModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, newsPaperCompanyDuck, salesReportDuck } = store;
  const { sampleCsv } = salesReportDuck;
  return {
    loggedInUser: auth.user,
    authToken: auth.authToken,
    loading,
    companyList: newsPaperCompanyDuck && newsPaperCompanyDuck.companyList,
    totalCompany:
      store.newsPaperCompanyDuck && store.newsPaperCompanyDuck.totalCompany,
    searchResults:
      store.newsPaperCompanyDuck &&
      store.newsPaperCompanyDuck.searchResultsforNewspaper,
    advanceSearchRecords:
      store.newsPaperCompanyDuck &&
      store.newsPaperCompanyDuck.advanceSearchRecords,
    salesReportListing:
      sampleCsv &&
      sampleCsv !== undefined &&
      Array.isArray(sampleCsv.salesDataGrid)
        ? sampleCsv.salesDataGrid
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps, {
      ...salesReportDuck.actions,
      ...NewsPaperCompanyDuck.actions
    }
    
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AllNewspaperList",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(AllNewspaperList)
  )
);
