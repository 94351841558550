import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import AdTypeListing from "./AdTypeListing";
import { toastr } from "react-redux-toastr";
import FilterRecords from "../FilterRecords";
import { renderSelectField } from "../../../../../common/layout/FormInput";
import { SALES_TREND } from "../../../../../config/constant";
import AdTypeChart from "./AdTypeChart";
import AdTypeRecords from "../adtype-reports/AdTypeRecords";
import AdTypeContribution from "./AdTypeContribution";
import { RANGE } from "../../../../../config/constant";
import * as adTypeReportDuck from "../../../../../store/ducks/super-admin/report-management/AdtypeReport";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { langs } from "../../../../../config/localization";
import { MESSAGES } from "../../../../../config/message";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import CountFigureBox from "../../../../../common/CountFigureBox";
let label = getCurrentDate();

class AdTypeReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      salesTrend: SALES_TREND,
      type: false,
      range: RANGE,
      adtype: "",
      section: "",
      data: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getAdtypeReport(1, 1, 1); // getting adtype header detail
    this.getAdtypeReport(2, 2, 1); // getting enrolled adtype detail
    this.getAdtypeReport(3, 3, 1); // getting display adtype detail
    this.getAdtypeReport(4, 4, 1); // getting classified adtype detail
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getAdtypeReport = (type, section, data) => {
    let requestData = {
      type: type,
      section: section,
      data: data,
    };
    this.setState({ adtype: type, section: section, data: data });
    this.props.getAdTypeReportDetail(requestData, (res) => {});
  };

  /**
   * @method renderHeaderCard
   * @description render header card
   */
  renderHeaderCard = (lable, value) => {
    return (
      <CountFigureBox>
        <h5 className="heading" style={{ marginTop: 40 }}>
          {lable}
        </h5>
        <h4 className="heading">{`${value}`}</h4>
      </CountFigureBox>
    );
  };

  /**
   * @method renderFilterCard
   * @description  render header card with filter data
   */
  renderFilterCard = (label, type, amount, adtype, section) => {
    const { range } = this.state;
    return (
      <CountFigureBox>
        <div className="row">
          <div className="col-md-6 cursorPointer" style={{ marginTop: 28 }}>
            <h6 className="heading">{label}</h6>
          </div>
          <div className="col-md-6">
            <Field
              name={label}
              options={range}
              style={{ marginTop: "-27px !important" }}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                this.getAdtypeReport(adtype, section, e.target.value);
              }}
              component={renderSelectField}
            />
          </div>
        </div>
        <h5 className="heading">{type}</h5>
        <h4 className="heading">{`${amount}`}</h4>
      </CountFigureBox>
    );
  };

  /**
   * @method renderNoRecordCard
   * @description  render no record found component
   */
  renderNoRecordCard = () => {
    return (
      <CountFigureBox>
        <h5 className="heading" style={{ marginTop: 40 }}>
          No record found
        </h5>
      </CountFigureBox>
    );
  };

  /**
   * @method filterRecord
   * @description  called after rendering the component
   */
  filterRecord = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    label = data.label;
    this.filterEnrollAdtype(
      data.this_month,
      data.this_year,
      data.date,
      data.date2,
      values
    );
    this.filterDisplayAdtype(
      data.this_month,
      data.this_year,
      data.date,
      data.date2,
      values
    );
    this.filterClassifiedAdtype(
      data.this_month,
      data.this_year,
      data.date,
      data.date2,
      values
    );
  }

  /**
   * @method requestFilterData
   * @description request api for get filter data for diffrent classification
   */
  requestFilterData = (requestData, values) => {
    this.props.getAdTypeReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({
          openModal: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  };

  /**
   * @method filterEnrollAdtype
   * @description request data for enrolled adtype
   */
  filterEnrollAdtype = (this_month, this_year, date, date2, values) => {
    const { data } = this.state;
    const requestData = {
      month: this_month,
      year: this_year,
      type: 2,
      section: 2,
      data: data ? data : 1,
      from_date: date,
      to_date: date2,
    };
    this.requestFilterData(requestData, values);
  };

  /**
   * @method filterDisplayAdtype
   * @description request data for display adtype
   */
  filterDisplayAdtype = (this_month, this_year, date, date2, values) => {
    const { data } = this.state;
    const requestData = {
      month: this_month,
      year: this_year,
      type: 3,
      section: 3,
      data: data ? data : 1,
      from_date: date,
      to_date: date2,
    };
    this.requestFilterData(requestData, values);
  };

  /**
   * @method filterClassifiedAdtype
   * @description request data for classified adtype
   */
  filterClassifiedAdtype = (this_month, this_year, date, date2, values) => {
    const { data } = this.state;
    const requestData = {
      month: this_month,
      year: this_year,
      type: 4,
      section: 4,
      data: data ? data : 1,
      from_date: date,
      to_date: date2,
    };
    this.requestFilterData(requestData, values);
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { openModal, selectedOption } = this.state;
    const { headerData, enrollAd, displayAd, classifiedAd } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.reportsAdType,
      langs.permissionKeys.view
    );
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Ad type</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        {viewPer ? (
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletBody>
                  <div>
                    <h5
                      className="float-right cursorPointer"
                      onClick={() => this.setState({ openModal: true })}
                    >
                      {label}
                      &nbsp;&nbsp;
                      <i
                        style={{ fontSize: 20 }}
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></i>
                    </h5>
                  </div>
                  <div className="row">
                    <div className="cardWrapper col-md-3">
                      {headerData
                        ? this.renderHeaderCard(
                            "Total No. of Active Ad Type",
                            headerData.total_advertisement
                          )
                        : this.renderNoRecordCard()}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {enrollAd
                        ? this.renderFilterCard(
                            "Enrolled Ad Type",
                            enrollAd.ad_name,
                            enrollAd.order_count,
                            2,
                            2
                          )
                        : this.renderNoRecordCard()}
                    </div>

                    <div className="cardWrapper col-md-3 ">
                      {displayAd
                        ? this.renderFilterCard(
                            "Display Ad Type with",
                            displayAd.ad_name,
                            displayAd.order_count,
                            3,
                            3
                          )
                        : this.renderNoRecordCard()}
                    </div>
                    <div className="cardWrapper col-md-3 ">
                      {classifiedAd
                        ? this.renderFilterCard(
                            "Classified Ad Type with",
                            classifiedAd.ad_name,
                            classifiedAd.order_count,
                            4,
                            4
                          )
                        : this.renderNoRecordCard()}
                    </div>
                  </div>

                  <div className="row my-5">
                    <div className="col-md-5">
                      <AdTypeRecords />
                    </div>
                    <div className="col-md-7 ">
                      <AdTypeContribution />
                    </div>
                  </div>

                  <div
                    className="mt-3"
                    style={{ borderBottom: "1px solid grey" }}
                  ></div>
                  <div style={{ marginTop: 50 }}>
                    <h5>Ad Type Trend</h5>
                  </div>
                  <AdTypeChart />
                  <div style={{ borderBottom: "1px solid grey" }}></div>
                  <AdTypeListing />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        ) : (
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div className="text-center" style={{ marginTop: "10px" }}>
                <h4>{MESSAGES.REPORT_PERMISSION_ACCESS}</h4>
              </div>
            </PortletBody>
          </Portlet>
        )}
        {openModal && (
          <FilterRecords
            applyFilter={this.filterRecord}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { adTypeReportDuck } = store;
  const {
    adtypeHeader,
    enrollAdtype,
    displayAdtype,
    classifiedAdtype,
  } = adTypeReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    headerData: adtypeHeader && adtypeHeader,
    enrollAd: enrollAdtype && enrollAdtype,
    displayAd: displayAdtype && displayAdtype,
    classifiedAd: classifiedAdtype && classifiedAdtype,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adTypeReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeReports",
    })(AdTypeReports)
  )
);
