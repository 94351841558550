import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {  Card } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'
import { reduxForm } from 'redux-form';
import { Loader } from '../../../../common/Loader';
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import * as orderManagementDuck from '../../../../store/ducks/npc/OrderManagement.duck';
import { STATUS_CODES } from '../../../../common/StatusCode';
import { langs } from '../../../../config/localization';
import { convertISOToUtcDateformate1 } from '../../../../common'
import { NoContentFound } from '../../../../common/NoContentFound'
import {
    Portlet,
    PortletBody,
} from '../../../../partials/content/Portlet';

class ViewOrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeclineModal: false,
            noContentFound: false,
            isSubmitted: true,
            loading: false
        };
    }

    /**
    * @method componentDidMount
    * @description  called after rendering the component 
    */
    componentDidMount() {
        const { id } = this.props.match.params;
        this.getOrderList(id)
    }

    /**
    * @method getOrderList
    * @description  used to get all order listing
    */
    getOrderList = (id) => {
        let requestData = {
            id: id,
            customer_id: ''
        }
        this.props.getOrderDetail(requestData, res => {
            if (res.status !== STATUS_CODES.OK) {
                this.setState({ noContentFound: true })
            }
        })
    }

    /**
    * @method renderPublishDates
    * @description render publish dates records
    */
    renderPublishDates = (orderDetail) => {
        let publishDates;
        if (Array.isArray(orderDetail.order_publish_dates) && orderDetail.order_publish_dates.length) {
            let temp = []
            orderDetail.order_publish_dates.map((el) => {
                temp.push(convertISOToUtcDateformate1(el.date))
            })
            publishDates = temp;
            return <div className='fullCardViewOrder'>
                <b className='mt-4 mb-2'>Publish Dates</b>
                {
                    publishDates.map((el, i) => {
                        return <p style={{ margin: 0 }} key={i}>
                            <i className="fa fa-calendar" aria-hidden="true"></i>&nbsp;
                            {el}
                        </p>
                    })
                }
            </div>

        } else {
            return publishDates = ''
        }
    }


    /**
     * @method renderCard
     * @description render detail
     */
    renderCard = (title, value) => {
        if (value === undefined || value[0] === '' || value[0] === null) {
            return ''
        }
        return <div className='fullCardViewOrder'>
            <b className='mt-4 mb-2'>{title}</b>
            {value.map((el, i) =>
                <p style={{ margin: 0 }} key={i}>{`${el}`}</p>
            )}
        </div>
    }

    
    /**
     * @method render
     * @description render the component
     */
    render() {
        const { orderDetail } = this.props;
        const { noContentFound, loading } = this.state;
        const { history } = this.props
        const { location } = history
        let name = orderDetail !== undefined && orderDetail.user ? orderDetail.user.first_name + ' ' + orderDetail.user.last_name : '';
        let adType = orderDetail !== undefined && Array.isArray(orderDetail.newspaper_ads_plan.advertisements) && orderDetail.newspaper_ads_plan.advertisements[0].ad_type ? orderDetail.newspaper_ads_plan.advertisements[0].ad_type : ''
        let color = orderDetail !== undefined && orderDetail.color ? orderDetail.color.color_name : '';
        const { state } = this.props.history.location;
        if (state === undefined) {
            return <Redirect to='/customerManagement' />
        }
        if (noContentFound) {
            return (
                <div>
                    <NoContentFound />
                </div>
            );
        } else if (orderDetail === undefined) {
            return <Loader />
        }
        return (
            <div>
                <LayoutContextConsumer >
                    {({ subheader: { title } }) => (
                        <div>
                            <div className='row'>
                                <h3 className='kt-subheader__title col-md-6'>
                                    <span>
                                        <Link 
                                           to={{
                                            pathname: `/viewOrderList/${location.state.customerDetail.id}`,
                                            state: {
                                                customerDetail: location.state.customerDetail
                                            }
                                        }}>
                                            <i
                                                onClick={() => {
                                                    return this.props.history.push(`/viewOrderList/${location.state.customerDetail.id}`);
                                                }}
                                                style={{ fontSize: 20 }}
                                                className='fa fa-arrow-left'
                                                aria-hidden='true'
                                            ></i>
                                        </Link>
                                    </span>
                                    <span style={{ marginLeft: 20 }}>Order Detail</span><br />
                                </h3>
                            </div>
                        </div>
                    )}
                </LayoutContextConsumer>
                <Portlet >
                    <PortletBody style={{ minHeight: 700, height: 'auto' }}>
                        <div className='w-100 row px-4'>
                            <div className='col-md-9'>
                                < h5 className=" float-left mr-1">Order no:</h5>
                                {orderDetail !== undefined && <span>{orderDetail.id}</span>}
                            </div>
                            <div className='row'>
                                < h5 >Date Of Order:</h5>
                                {orderDetail !== undefined && <span className='ml-2'>{convertISOToUtcDateformate1(orderDetail.order_date)}</span>}
                            </div>
                        </div>
                        <div className='w-100 row px-4' style={{ borderTop: '1px solid black' }} >
                            <div className='mt-4 w-100'>
                                < h5 className=" float-left ">Order Information</h5>
                                <h5 className='float-right' >Order Total</h5>
                            </div>
                        </div>
                        {loading && <Loader />} 

                        {orderDetail !== undefined && <div className='row'>
                            <Card className='fullCard col-10'>
                                <div className='row'>
                                    {this.renderCard('Company Name', [orderDetail.newspaper.newspaper_name])}
                                    {this.renderCard('Edition', [orderDetail.edition.print_frequency.print_frequency])}
                                    {this.renderCard('Ad Type', [adType])}
                                    {this.renderCard('No of Times Advertized', [orderDetail.ad_frequency])}
                                    {adType === langs.adType.Display && this.renderCard('Color Mode', [color])}
                                    {this.renderCard('Client Name', [name])}
                                    {this.renderCard('Payment Method', [orderDetail.newspaper.payment_mode])}
                                    {this.renderCard('Ad details', [orderDetail.ad_details])}
                                </div>
                            </Card>
                            <div className="col-1 ml-1">
                                <Card className='totalOrderCard '>
                                    <h1 className='mt-5'>{orderDetail.total_amount + '$'}</h1>
                                </Card>

                                <div >
                                    {this.renderPublishDates(orderDetail)}
                                </div>
                            </div>
                        </div>}
                    </PortletBody>
                </Portlet>
            </div>
        );
    }
}


const mapStateToProps = store => {
    const { loading, orderDetail } = store.commonDuck;
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        orderDetail,
        loading
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        { ...commonDuck.actions, ...orderManagementDuck.actions }
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'ViewOrderDetail',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(ViewOrderDetail)
    )
);
