import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import {
    required,
    number,
    greaterThanZeroWithTwoDecimalPlace
} from '../../../../config/validation'
import { renderSelectField, renderNumberField, renderTextInputField } from '../../../../common/layout/FormInput'

const AddEmailForm = ({
    onAddEmail,
    handleSubmit,
    reset,
    getfromNPCSetting,
    getAllPaperType
}) => {

    

    /**
     * @method handleOnSubmit
     * @description handle on submit form
     * @param {*} values 
     * @param {*} actions 
     */
    const handleOnSubmit = (values) => {
        //console.warn('#############',values);
        // //console.warn('>>Params>>', match.params.id)
        if(values.email !== "" )
        {
            onAddEmail({ email: values.email })
            reset();
        }
        // //console.warn("Values >>",values.email)
    }

   
    //const [paperTypes, setPaperTypes] = useState([{value:null,label:'select'},{value:1,label:'Glossy'},{value:2,label:'Matte'}]);

    /**
     * Runs on mount to fetch colors options
     */
   

    return (
        <div className="form-row align-items-center">
            <div className="col-md-3" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="email"
                        component={renderTextInputField}
                        label="Email"
                    />
                </div>
            </div>
            <div className="col-md-3 mt-4" style={{ height: 105 }}>
                <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={handleSubmit(handleOnSubmit)}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>
    )
}

/**
 * @method mapStateToProps
 * @description map redux store state to props
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        email: ''
    }
})

export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addColorForm',
})(AddEmailForm))

