import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import { apiErrors } from '../../../common/HandleAPIErrors'
import {
    CALL_LOADING
} from '../../../config/constant';


export const actionTypes = {
    ResetAllCompanyList: "[ResetAllCompanyList] Action",
    AdvanceSearchRecords: "[AdvanceSearchRecords] Action",
    ResetAllArchiveCompanyList: "[ResetAllArchiveCompanyList] Action",
    RemoveArchieveAdmin: "[RemoveArchieveAdmin] Action",
    SetSearchResultForCompany: "[SetSearchResultForCompany] Action",
    SetSearchResultForArchieveCompany: "[SetSearchResultForArchieveCompany] Action",
    SetSelectedCompany: "[SetSelectedCompany] Action",
    SetWhatsNo: "[SetWhatsNo] Action",
    SetCompanyDetail: "[SetCompanyDetail] Action",
    SetLinkExpired: "[SetLinkExpired] Action",
    ResetAdvanceSearch: "[ResetAdvanceSearch] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    profile: undefined,
    searchResultsforNewspaper: [],
    searchResultsforArchieveNewspaper: [],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {


            /** get all records of company list */
            case actionTypes.ResetAllCompanyList: {
                const { companyList, totalCompany } = action.payload;
                return { ...state, companyList: companyList, totalCompany: totalCompany };
            }

            /** get all records of company archieve list */
            case actionTypes.ResetAllArchiveCompanyList: {
                const { archiveCompanyList } = action.payload;
                return { ...state, archiveCompanyList: archiveCompanyList };
            }

            /** set results from search */
            case actionTypes.SetSearchResultForArchieveCompany: {
                const { companyList } = action.payload;
                return { ...state, searchResultsforArchieveNewspaper: companyList };
            }

            /** set results from search */
            case actionTypes.SetSearchResultForCompany: {
                const { companyList } = action.payload;
                return { ...state, searchResultsforNewspaper: companyList };
            }

            case actionTypes.AdvanceSearchRecords: {
                const { advanceSearchRecords } = action.payload;
                return { ...state, advanceSearchRecords: [...advanceSearchRecords] };
            }

            case actionTypes.ResetAdvanceSearch: {
                const { advanceSearchRecords } = action.payload;
                return { ...state, advanceSearchRecords: advanceSearchRecords };

            }
            case actionTypes.ResetAdvanceSearch2: {
                return { ...state, advanceSearchRecords: [] };
            }

            /** Activate selected admin from archieved admin records */
            case actionTypes.RemoveArchieveAdmin: {
                const { archieveAdminId } = action.payload;
                let deleteIndex = state.archiveAdminList.findIndex((el) => el.id === archieveAdminId)
                if (deleteIndex !== -1) {
                    let temp = state.archiveAdminList && state.archiveAdminList.filter(function (item, index) {
                        return index != deleteIndex
                    });
                    return { ...state, archiveAdminList: [...temp] };
                } else {
                    return state;
                }
            }

            /** get data of individual admin by id */
            case actionTypes.SetSelectedCompany: {
                const { selectedCompany } = action.payload;
                return { ...state, selectedCompany: selectedCompany };
            }

            case actionTypes.SetWhatsNo: {
                const { WhatsNo } = action.payload;
                return { ...state, WhatsNo: WhatsNo };
            }

            /** set data of individual Company by id */
            case actionTypes.SetCompanyDetail: {
                const { company } = action.payload;
                return { ...state, selectedCompany: company };
            }

            /** get link expired details */
            case actionTypes.SetLinkExpired: {
                const { result } = action.payload;
                return { ...state, linkExpired: result };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getAllNewspaperList
    * @description get all company listing/records 
    */
    getAllNewspaperList: (requestData, callback) => {
        const { row, page, is_deleted, status } = requestData;
        const request = axios.get(`${API.getAllNewspaperList}?row=${row}&page=${page}&is_deleted=${is_deleted}&status=${status}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.ResetAllCompanyList, payload: { companyList: response.data.rows, totalCompany: response.data.count } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method getAllCompanyArchieveList
    * @description getting All Company ArchieveList  
    */
    getAllCompanyArchieveList: (requestData, callback) => {
        const { id, row, page, is_deleted, status } = requestData;
        const request = axios.get(`${API.getAllNewspaperList}?row=${row}&page=${page}&is_deleted=${is_deleted}&status=${status}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.ResetAllArchiveCompanyList, payload: { archiveCompanyList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method deleteCompanyById
    * @description delete newspaper company details
    */
    deleteCustomerById: (loggedInSuperAdminId, customerId, user_id, callback) => {
        const request = axios.delete(`${API.genericUsers}?id=${customerId}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
 * @method deleteCompanyById
 * @description delete newspaper company details
 */
    deleteCompanyById: (requestData, callback) => {
        const { companyId, user_id, email, first_name } = requestData;
        const request = axios.delete(`${API.deleteCompanyDetails}?id=${companyId}&user_id=${user_id}&email=${email}&first_name=${first_name}`, { headers });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method activateCompanyById
     * @description Activate company by id
     */
    activateCompanyById: (requestData, callback) => {
        const request = axios.patch(`${API.updateCompanyDetails}`, requestData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method searchInNewspaperList
     * @description Search result in newspaper company
     */
    searchInNewspaperList: (requestData) => {
        const { searchKey, is_deleted, status } = requestData
        const request = axios.get(`${API.searchInNewspaperList}?search=${searchKey}&is_deleted=${is_deleted}&status=${status}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                if (!is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResultForCompany, payload: { companyList: response.data.rows } })
                } else if (is_deleted) {
                    return dispatch({ type: actionTypes.SetSearchResultForArchieveCompany, payload: { companyList: response.data.rows } })
                }
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
            });
        };
    },

    /**
    * @method getListingFromAdvanceSearch
    * @description get all records based on advance search
    */
    getListingFromAdvanceSearch: (authToken, requestData, callback) => {
        let api_call;
        if (requestData.newspaper_name || requestData.language || requestData.distribution_type || requestData.distribution_state || requestData.distribution_area || requestData.role_id || requestData.distributed_copies || requestData.email_distribution || requestData.category || requestData.active) {
            api_call = `${API.getRecordsFromAdvanceSearch}?newspaper_name=${requestData.newspaper_name}&active=${requestData.active}&language=${requestData.language}&distribution_type=${requestData.distribution_type}&distribution_state=${requestData.distribution_state}&distribution_area=${requestData.distribution_area}&role_id=${requestData.role_id}&distributed_copies=${requestData.distributed_copies}&email_distribution=${requestData.email_distribution}&category=${requestData.category}`
            const request = axios.get(api_call, { headers });
            return (dispatch) => {
                dispatch({ type: CALL_LOADING })
                request.then((response) => {
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    return dispatch({ type: actionTypes.AdvanceSearchRecords, payload: { advanceSearchRecords: response.data.rows } })
                }).catch((error) => {
                    apiErrors(error, dispatch)
                    dispatch({ type: CALL_LOADING })
                    // tokenCheck(dispatch, error)
                    callback(error);
                });
            };
        }
    },

    /**
    * @method getListingFromAdvanceSearch
    * @description get all records based on advance search
    */
    resetAdvanceSearch: (res) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.ResetAdvanceSearch, payload: { advanceSearchRecords: res.data.rows } })
        }
    },
    resetAdvanceSearch2: (res) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.ResetAdvanceSearch2, payload: { advanceSearchRecords: res.data.rows } })
        }
    },

    /**
 * @method getAllNewspaperList
 * @description get all company listing/records 
 */
    resetAllNewspaperList: (companyList) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.ResetAllCompanyList, payload: { companyList: companyList.data.rows, totalCompany: companyList.data.count } })

        }
    },

    /**
    * @method addNewCompany
    * @description Add new newspaper company 
    */
    addNewCompany: (requestData, callback) => {
        const request = axios.post(API.addNewsPaperCompany, { newspaper: requestData }, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);

            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                callback(error);
                // tokenCheck(dispatch, error)
            });
        };
    },

    /**
  * @method addMultipleNewCompany
  * @description Add Multiple New Company
  */
  addMultipleNewCompany: (requestData, callback) => {
    const request = axios.post(API.addMultipleNewCompany, requestData, { headers });
    return (dispatch) => {
        dispatch({ type: CALL_LOADING })
        request.then((response) => {
            //console.warn("Response>>", response)
            dispatch({ type: CALL_LOADING })
            callback(response);

        }).catch((error) => {
            apiErrors(error, dispatch)
            dispatch({ type: CALL_LOADING })
            callback(error);
            // tokenCheck(dispatch, error)
        });
    };
},
    /**
    * @method updateComapnyProfile
    * @description Update news paper company details 
    */
    updateComapnyProfile: (requestData, authToken, callback) => {
        let request = axios.put(`${API.updateCompanyDetails}`, requestData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method getCompanyDetailById
     * @description Update admin details 
     */
    getCompanyDetailById: (companyId, isEditFlag, callback) => {
        if (isEditFlag) {
            const request = axios.get(`${API.getAllNewspaperById}?id=${companyId}`, { headers });
            return (dispatch) => {
                dispatch({ type: CALL_LOADING })
                request.then((response) => {
                    //console.warn("Newspaper details>>", response.data)
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    dispatch({ type: actionTypes.SetSelectedCompany, payload: { selectedCompany: response.data } })
                }).catch((error) => {
                    apiErrors(error, dispatch)
                    // tokenCheck(dispatch, error)
                    callback(error);
                    dispatch({ type: CALL_LOADING })
                });
            };
        } else {
            return (dispatch) => {
                callback({});
                return dispatch({ type: actionTypes.SetSelectedCompany, payload: {} })
            };
        }
    },

    // getWhatsappNumber: (companyId,callback) => {
    //         const request = axios.get(`${API.getWhatsappNumber}?id=${companyId}`, { headers });
    //         return (dispatch) => {
    //             dispatch({ type: CALL_LOADING })
    //             request.then((response) => {
    //                 console.warn("Newspaper details>>", response.data)
    //                 dispatch({ type: CALL_LOADING })
    //                 dispatch({ type: actionTypes.SetWhatsNo, payload: { WhatsNo: response.data } })
    //                 callback(response);
    //             }).catch((error) => {
    //                 apiErrors(error, dispatch)
    //                 // tokenCheck(dispatch, error)
    //                 callback(error);
    //                 dispatch({ type: CALL_LOADING })
    //             });
    //         };
    // },

    /**
     * @method addNewCompany
     * @description Add new newspaper company 
     */
    addPaymentCompany: (requestData, callback) => {
        const request = axios.post(API.addNewsPaperCompany, { payment: requestData }, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method getCompanyById
    * @description get Company Details by id
    */
    getCompanyById: (requestData, callback) => {
        const { company_id } = requestData
        const request = axios.get(`${API.getCompanyById}?id=${company_id}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                dispatch({ type: actionTypes.SetCompanyDetail, payload: { company: response.data } })
                callback(response)
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error)
                return error;
            });
        };
    },

    /**
    * @method getBankDetailsById
    * @description get Company Details by id
    */
    getBankDetailsById: (requestData, callback) => {
        const { company_id } = requestData
        const request = axios.get(`${API.getBankDetailsById}?id=${company_id}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                dispatch({ type: actionTypes.SetCompanyDetail, payload: { company: response.data } })
                callback(response)
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                callback(error)
                return error;
            });
        };
    },

    getLinkExpired: (link, callback) => {
        const request = axios.get(`${API.getLinkExpired}?linkingToken=${link}`, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                dispatch({ type: actionTypes.SetLinkExpired, payload: { linkExpired: response.data } })
                callback(response)
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                //tokenCheck(dispatch, error)
                callback(error)
                return error;
            });
        };
    },

    /**
     * @method addNewCompanyFromFrontEnd
     * @description Add new newspaper company  from front
     */
    addNewCompanyFromFrontEnd: (requestData, callback) => {
        const request = axios.post(API.addNewsPaperCompany, { newspaper: requestData }, {});
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                //                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





