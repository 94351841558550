import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import _ from "lodash";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import {
  renderSelectField,
  renderNumberField,
} from "../../../../common/layout/FormInput";
import {
  number,
  greaterThanZeroWithTwoDecimalPlace,
} from "../../../../config/validation";
import { ButtonRP } from "../../../../common/Buttons";

const PlanAdvancedSearch = ({
  reset,
  loggedInUser,
  handleSubmit,
  getAllAdType,
  advancedSearchInPlansList,
  resetAdvancedSearch,
}) => {
  // extract logged in user details
  const { user_detail } = loggedInUser;

  // state contains all the Ad Types list of logged in NPC user
  const [advertisementsTypeList, setAdvertisementsTypeList] = useState([]);

  // selected Ad Type state
  const [selectedAdType, setSelectedAdType] = useState("");

  /**
   * @method getAdTypeOptions
   * @description return dropdown options for Ad Type
   */
  const getAdTypeOptions = () => {
    return advertisementsTypeList.length > 0
      ? _.uniqBy(advertisementsTypeList, "ad_type").map((ad) => ({
          label: ad.ad_type,
          value: ad.ad_type,
        }))
      : [];
  };

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values) => {
    let requestData = {
      npc_id: user_detail.newspaper.id,
      ad_type: values.ad_type && values.ad_type !== "" ? values.ad_type : "",
      discount:
        values.discount && values.discount.trim() !== ""
          ? values.discount
          : "2",
      active:
        values.active && values.active.trim() !== "" ? values.active : "2",
    };
    // add height and width only when thy have some value
    if (values.width && String(values.width).trim() !== "") {
      requestData.width = values.width;
    }
    if (values.height && String(values.height).trim() !== "") {
      requestData.height = values.height;
    }
    // advanced search action
    advancedSearchInPlansList(requestData, (res) => {});
  };

  /**
   * runs on mount
   */
  useEffect(() => {
    // fetch Ad Types list of logged in NPC user action
    getAllAdType(user_detail.newspaper.id, (res) => {
      if (res.status === 200) {
        let advertisements = res.data.rows[0].advertisements;

        advertisements = advertisements
          ? advertisements.map((ad) => {
              return {
                id: ad.id,
                ad_name: ad.ad_name,
                ad_type: ad.ad_type,
                selectedImage: JSON.parse(ad.npc_advertisement.ad_urls)[0],
              };
            })
          : [];

        // set advertisements list state
        setAdvertisementsTypeList(advertisements);
        //const selectedAd = advertisements && advertisements.length > 0 ? advertisements[0].id : '';
        // extract and set selected Ad Type initially of display type
        // setSelectedAdType(selectedAd);
      }
    });
  }, []);

  /**
   * Runs on un mount
   */
  useEffect(() => {
    return () => resetAdvancedSearch();
  }, []);

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="form-row">
              <div className="col-6">
                <Field
                  name="ad_type"
                  component={renderSelectField}
                  label="Ad Type"
                  options={getAdTypeOptions()}
                  optionValue={"value"}
                  optionLabel={"label"}
                  value={selectedAdType}
                  onChange={(e) => setSelectedAdType(e.target.value)}
                  isSelect={false}
                />
              </div>
              <div className="form-row col-md-6">
                <div className="col-md-6">
                  <Field
                    name="width"
                    label="Ad Size"
                    component={renderNumberField}
                    validate={[number, greaterThanZeroWithTwoDecimalPlace]}
                    placeholder="Width"
                    min="0"
                    step=".01"
                    helpText={"Only Numeric value with up to 2 decimal places"}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <Field
                    name="height"
                    component={renderNumberField}
                    validate={[number, greaterThanZeroWithTwoDecimalPlace]}
                    placeholder="Height"
                    min="0"
                    step=".01"
                    helpText={"Only Numeric value with up to 2 decimal places"}
                  />
                </div>
              </div>
              <div className="col-6">
                <Field
                  name="discount"
                  component={renderSelectField}
                  label="Discount Available"
                  options={[
                    { label: "Yes", value: "1" },
                    { label: "No", value: "0" },
                  ]}
                  optionValue={"value"}
                  optionLabel={"label"}
                  isSelect={false}
                />
              </div>
              <div className="col-6">
                <Field
                  name="active"
                  component={renderSelectField}
                  label="Status"
                  options={[
                    { label: "Active", value: 1 },
                    { label: "Inactive", value: 0 },
                  ]}
                  optionValue={"value"}
                  optionLabel={"label"}
                  isSelect={false}
                />
              </div>
              <div className="col-12">
                <div className="d-flex-center j-end">
                  <ButtonRP
                    cancel
                    text="Reset"
                    onClick={() => {
                      reset();
                      resetAdvancedSearch();
                    }}
                    className="mx-2"
                  />
                  <ButtonRP type="submit" text="Apply" />
                </div>

                {/* <button type="submit" className="btn btn-dark float-right">Apply</button> */}
                {/* <button
                                    type="button"
                                    className="btn btn-secondary float-right mr-3"
                                    onClick={() => { reset(); resetAdvancedSearch() }}
                                >
                                    Reset
                                </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 * @param {*} ownProps
 */
const mapStateToProps = (state, ownProps) => {
  const { auth, npcDuck } = state;
  return {
    loggedInUser: auth.user,
    searchResults: npcDuck.searchResult ? npcDuck.searchResult : [],
  };
};

export default connect(mapStateToProps, {
  ...NPCDuck.actions,
})(
  reduxForm({
    form: "planAdvancedSearchForm", // a unique identifier for this form
  })(PlanAdvancedSearch)
);
