import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Table, Row, Col, Input, Label } from "reactstrap";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../common/layout/FormInput";
import { DURATION } from "../../../../config/constant";
import * as NpcDashboardRecord from "../../../../store/ducks/npc/Dashboard.duck";
import NoRecordFoundContent from "../../../../common/NoRecordFoundContent";
import { topRecordsFilterKeys } from "../../../../common/CommonFilterFunction";
import { langs } from "../../../../config/localization";
import RadioButtonRP from "../../../../common/RadioButtonRP";

class AdTypeList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: false,
      duration: DURATION,
      dateVisible: false,
      startDate: "",
      endDate: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getDashboardRecord(langs.keyname.revenue);
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record of ad type
   */
  getDashboardRecord = (salesType) => {
    const { user_detail } = this.props.loggedInUser;
    this.setState({ salesType: salesType });
    let requestData1 = {
      type: 2,
      npc_id: user_detail.newspaper.id,
      sales: salesType,
    };
    requestData1.type = 2;
    this.props.getTypeData(requestData1, 2, (res) => {});
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilterOnAdType(values) {
    const { user_detail } = this.props.loggedInUser;
    const { startDate, endDate, dateVisible, salesType } = this.state;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      npc_id: user_detail.newspaper.id,
      month: data.this_month,
      year: data.this_year,
      type: 2,
      from_date: data.date,
      to_date: data.date2,
      sales: salesType ? salesType : langs.keyname.revenue,
    };
    this.props.getTypeData(requestData, 2, (res) => {});
  }

  /**
   * @method renderListRow
   * @description  render adtype list
   */
  renderListRow = () => {
    const { npcModule2 } = this.props;
    const { salesType } = this.state;
    if (
      npcModule2 &&
      Array.isArray(npcModule2.topAdType) &&
      npcModule2.topAdType.length
    ) {
      return npcModule2.topAdType.map((el, i) => {
        return (
          <tr>
            <th scope="row">{i + 1}</th>
            <td>{el.ad_name}</td>
            <td>
              ${" "}
              {salesType === langs.keyname.revenue
                ? el.total_amount
                : el.total_orders}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="3">
            <NoRecordFoundContent />
          </td>
        </tr>
      );
    }
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { header } = this.props;
    const { type, duration, dateVisible, startDate, endDate } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md="12">
            <h5>Top 5 Ad Type Plan</h5>
          </Col>
          <Col md="1" xs="1" className="d-flex-center mb-2">
            By
          </Col>
          <Col md="6" xs="11" className="d-flex-center">
            <div className="radio-inline">
              <RadioButtonRP label="Revenue">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={!type ? true : ""}
                  onChange={(e) => {
                    this.setState({
                      type: false,
                    });
                    this.getDashboardRecord(langs.keyname.revenue);
                  }}
                  name="order_type"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Orders">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={type ? true : ""}
                  onChange={(e) => {
                    this.getDashboardRecord(langs.keyname.orders);
                    this.setState({
                      type: true,
                    });
                  }}
                  name="order_type"
                />
              </RadioButtonRP>
            </div>
          </Col>
          <div className="col-md-5 mt-1 mt-md-auto">
            <Field
              name="date_range"
              options={duration}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                if (e.target.value === "1") {
                  this.setState({ dateVisible: true });
                } else {
                  this.setState({ dateVisible: false });
                  this.props.change("from", "");
                  this.props.change("to", "");
                  this.applyFilterOnAdType(e.target.value);
                }
              }}
              component={renderSelectField}
            />
          </div>
          {dateVisible && (
            <Col>
              <Row>
                <Col md="6">
                  <Field
                    name="from"
                    label="From"
                    //minDate={endDate}
                    onChange={(e) => {
                      this.props.change("to", "");
                      this.setState(
                        { startDate: e, endDate: "", dateVisible: true },
                        () => {
                          this.applyFilterOnAdType(e);
                        }
                      );
                    }}
                    component={renderDatePicker}
                  />
                </Col>
                <Col md="6">
                  <Field
                    name="to"
                    label="To "
                    minDate={startDate}
                    onChange={(e) => {
                      this.setState({ endDate: e, dateVisible: true }, () => {
                        this.applyFilterOnAdType(e);
                      });
                    }}
                    component={renderDatePicker}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Table responsive>
          <thead style={{ backgroundColor: "#dcdcdccc" }}>
            <tr>
              <th>#</th>
              <th>{header}</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>{this.renderListRow()}</tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { npcModule2 } = store.npcDashboardRecord;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    npcModule2,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    NpcDashboardRecord.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeList",
    })(AdTypeList)
  )
);
