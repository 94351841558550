import React, { Component } from 'react';
import * as auth from '../../store/ducks/auth.duck';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    localStorage.setItem("loggedIn", "0");
    return <Redirect to='/auth/login' />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
