import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Table, Row, Col, Input, Label } from "reactstrap";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../../common/layout/FormInput";
import { DATE_RANGE1 } from "../../../../../config/constant";
import * as adTypeReportDuck from "../../../../../store/ducks/super-admin/report-management/AdtypeReport";
import NoRecordFoundContent from "../../../../../common/NoRecordFoundContent";
import { topRecordsFilterKeys } from "../../../../../common/CommonFilterFunction";
import CountFigureBox from "../../../../../common/CountFigureBox";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

class AdTypeRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type1: false,
      duration: DATE_RANGE1,
      salesType: "",
      dateVisible: false,
      startDate: "",
      endDate: "",
      fromDate: "",
      toDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getadTypeReport(5, 0);
  }

  /**
   * @method getadTypeReport
   * @description  used to get adtype report data
   */
  getadTypeReport = (type, salesType) => {
    const { fromDate, toDate, month, year } = this.state;
    this.setState({ salesType: salesType });
    let requestData = {
      type: type,
      sales: salesType,
      section: 5,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.props.getAdTypeReportDetail(requestData, (res) => {});
  };

  /**
   * @method renderAdTypePlanRecord
   * @description  render adtype plan listing
   */
  renderAdTypePlanRecord = (adTypePlanList) => {
    const { salesType } = this.state;
    if (adTypePlanList && adTypePlanList.length) {
      return (
        adTypePlanList &&
        Array.isArray(adTypePlanList) &&
        adTypePlanList.map((item, index) => {
          return (
            <tbody>
              <tr>
                <th scope="row">{index}</th>
                <td>{item.ad_name}</td>
                <td>
                  $ {salesType === 0 ? item.sales_revenue : item.total_orders}{" "}
                </td>
              </tr>
            </tbody>
          );
        })
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="3">
              <NoRecordFoundContent />
            </td>
          </tr>
        </tbody>
      );
    }
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilterOnAdType(values) {
    const { startDate, endDate, salesType, dateVisible } = this.state;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      type: 5,
      sales: salesType,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
      section: 5,
    };
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
    });
    this.props.getAdTypeReportDetail(requestData, (res) => {
      //this.setState({startDate: endDate, endDate: ''})
    });
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { type1, duration, dateVisible, startDate, endDate } = this.state;
    const { adTypeReportData } = this.props;
    return (
      <CountFigureBox height="350px" className="my-3" alignStart={true}>
        <h5>Top 5 Ad Type Plan</h5>
        <Row className="w-100">
          <Col md="12" className="d-flex-center mb-1">
            <span className="mr-4">By</span>
            <div className="radio-inline">
              <RadioButtonRP label="Revenue">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={!type1 ? true : ""}
                  onChange={(e) => {
                    this.setState({ type1: false });
                    this.getadTypeReport(5, 0);
                  }}
                  name="topadType"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Orders">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={type1 ? true : ""}
                  onChange={(e) => {
                    this.setState({ type1: true });
                    this.getadTypeReport(5, 1);
                  }}
                  name="topadType"
                />
              </RadioButtonRP>
            </div>
          </Col>
          <div className="col-md-12">
            <Field
              name="date_range"
              options={duration}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                if (e.target.value === "1") {
                  this.setState({ dateVisible: true });
                } else {
                  this.setState({ dateVisible: false });
                  this.props.change("from", "");
                  this.props.change("to", "");
                  this.applyFilterOnAdType(e.target.value);
                }
              }}
              component={renderSelectField}
            />
          </div>
          {dateVisible && (
            <Row>
              <Col md="6">
                <Field
                  name="from"
                  label="From"
                  //minDate={endDate}
                  onChange={(e) => {
                    this.props.change("to", "");
                    this.setState(
                      { startDate: e, endDate: "", dateVisible: true },
                      () => {
                        this.applyFilterOnAdType(e);
                      }
                    );
                  }}
                  component={renderDatePicker}
                />
              </Col>
              <Col md="6">
                <Field
                  name="to"
                  label="To "
                  minDate={startDate}
                  onChange={(e) => {
                    this.setState({ endDate: e, dateVisible: true }, () => {
                      this.applyFilterOnAdType(e);
                    });
                  }}
                  component={renderDatePicker}
                />
              </Col>
            </Row>
          )}
        </Row>

        <Table>
          <thead style={{ backgroundColor: "#dcdcdccc" }}>
            <tr>
              <th>#</th>
              <th>Plan Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          {this.renderAdTypePlanRecord(adTypeReportData)}
        </Table>
      </CountFigureBox>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { adTypeReportDuck } = store;
  const { topAdtype } = adTypeReportDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    adTypeReportData: topAdtype && Array.isArray(topAdtype) ? topAdtype : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adTypeReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeRecords",
    })(AdTypeRecords)
  )
);
