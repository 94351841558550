import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import ReactToPrint from "react-to-print";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { CSVLink } from "react-csv";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { AdTypeRepoHeaders } from "../../../../../common/CsvFormate";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";
import * as adTypeReportDuck from "../../../../../store/ducks/super-admin/report-management/AdtypeReport";
import { checkPermission } from "../../../../../common/CheckPermissions";

class AdTypeListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      adTypeList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getAdtypeGrid(this.state.pageSize, 1, "", "");
  }

  /**
   * @method handleListCount
   * @description  ReArrabge API res according to length count
   */
  handleListCount = (res) => {
    console.log("handleListCount: ", res);
    let temp = [];
    if (res.length <= 4) {
      temp = res.slice(0, res.length);
      this.setState({ adTypeList: temp });
    } else {
      let len = !this.state.viewAll ? 4 : res.length;
      temp = res.slice(0, len);
      this.setState({ adTypeList: temp });
    }
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getAdtypeGrid(this.state.pageSize, 1, "", "");
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getAdtypeGrid(this.state.pageSize, 1, "", "");
      }
    );
  };

  /**
   * @method getAdtypeGrid
   * @description Called for getting listing of newspaper company
   */
  getAdtypeGrid = (row, page, sort, column) => {
    let requestData = {
      type: 8,
      section: 8,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      localLoading: false,
    };
    this.props.getAdTypeReportDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.adTypeDetails) &&
          this.handleListCount(res.data.adTypeDetails);
      }
    });
  };

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const { pageSize, count, entries, viewAll } = this.state;
    const { adTypeGrid } = this.props;
    if (viewAll) {
      this.setState({ loader: true });
      this.setState({ loader: false });
      if (adTypeGrid.length) {
        this.setState({ loader: false }, () => {
          let requestData = {
            row: pageSize + entries,
            page: 1,
            type: 8,
            section: 8,
          };
          adTypeGrid.length < count &&
            this.props.getAdTypeReportDetail(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                let pages = res.data.adTypeDetails.length / this.state.pageSize;
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  totalCount: Math.ceil(pages),
                  count: res.data.adTypeDetails.length,
                });
                setTimeout(() => {
                  this.setState({ localLoading: false });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({ sortedOrder: !sortedOrder });
    this.getAdtypeGrid(this.state.pageSize, 1, sortBy, column.id, "");
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Ad Type Name",
        accessor: "ad_name",
        Cell: (row) => {
          const { original } = row;
          return original.ad_name;
        }, // String-based value accessors!
      },
      {
        Header: "Order Count",
        accessor: "total_orders",
        Cell: (row) => {
          const { original } = row;
          return (
            <span>
              <Link
                to={`/adtype-report-order-listing/${original.id}`}
                style={{ borderBottom: " 1px solid" }}
              >
                {original.total_orders}
              </Link>
            </span>
          );
        },
      },
      {
        Header: "Total Sales",
        accessor: "sales_revenue",
        Cell: (row) => {
          const { original } = row;
          return <div>${original.sales_revenue}</div>;
        },
      },
      {
        Header: "Earning to Admin",
        accessor: "commission_fee",
        Cell: (row) => {
          const { original } = row;
          return <div>${original.commission_fee}</div>;
        },
      },
      {
        Header: "Revenue to NPC",
        accessor: "total_revenue",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>${original.total_revenue}</div>
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { viewAll, page, pageSize, localLoading, adTypeList } = this.state;
    const { adTypeGrid } = this.props;
    const headers = AdTypeRepoHeaders;
    const printPer = checkPermission(
      langs.permissionKeys.reportsAdType,
      langs.permissionKeys.print
    );
    return (
      <div style={{ marginTop: -15 }}>
        <Portlet fluidHeight={true}>
          <PortletBody className="px-4" ref={(el) => (this.componentRef = el)}>
            <div className="row ">
              <div className="col-md-12 container mt-5">
                <h5 className="float-left">Ad Type Report</h5>
                <h5 className="float-right border-bottom-0 border-primary">
                  <CSVLink
                    filename={"AdtypeDetails.csv"}
                    data={adTypeGrid}
                    headers={headers}
                    style={{ borderBottom: "1px solid" }}
                  >
                    Export CSV
                  </CSVLink>
                </h5>
                {printPer && (
                  <h5 className="float-right mr-4">
                    <Link>
                      <ReactToPrint
                        trigger={() => (
                          <a href="#" style={{ borderBottom: "1px solid" }}>
                            Print
                          </a>
                        )}
                        content={() => this.componentRef}
                      />
                    </Link>
                  </h5>
                )}
              </div>
            </div>
            <div className="row" style={{ border: "1px solid black" }}></div>
            <div className="row">
              <div className="col-12">
                <InfiniteScroll
                  dataLength={adTypeList.length}
                  next={this.fetchMoreData}
                  loader={
                    localLoading && (
                      <h4 style={{ textAlign: "center" }}>Loading...</h4>
                    )
                  }
                  hasMore={true}
                  style={{ overflow: "unset" }}
                  useWindow={false}
                >
                  <StandardTable
                    handelSearch={this.handleSearch}
                    columnOrder={this.renderRecords()}
                    handleSort={this.handleSort}
                    sortable={false}
                    onPageSizeChange={this.onPageSizeChange}
                    showSearch={false}
                    data={adTypeList}
                    pagination={false}
                  />
                </InfiniteScroll>
                {adTypeList && adTypeList.length >= 4 && !viewAll && (
                  <div
                    onClick={() => {
                      this.getAdtypeGrid(pageSize, page, "", "");
                      this.setState({ viewAll: true, localLoading: true });
                      setTimeout(() => {
                        this.setState({ localLoading: false });
                      }, 3000);
                    }}
                  >
                    <label style={{ cursor: "pointer", color: "#0000ff" }}>
                      View All
                    </label>
                  </div>
                )}
                {localLoading && <FooterLoader />}
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { adTypeReportDuck } = store;
  const { loading } = store.commonDuck;
  const { auth } = store;
  const { adTypeGrid } = adTypeReportDuck;
  return {
    loggedInUser: auth.user,
    loading,
    adTypeGrid:
      adTypeGrid !== undefined && Array.isArray(adTypeGrid) ? adTypeGrid : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adTypeReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeListing",
    })(AdTypeListing)
  )
);
