import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Table, Row, Col, Input, Label } from "reactstrap";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../common/layout/FormInput";
import { DURATION } from "../../../../config/constant";
import * as AdminDashboardRecord from "../../../../store/ducks/super-admin/AdminDashboard.duck";
import NoRecordFoundContent from "../../../../common/NoRecordFoundContent";
import { topRecordsFilterKeys } from "../../../../common/CommonFilterFunction";
import { langs } from "../../../../config/localization";
import RadioButtonRP from "../../../../common/RadioButtonRP";

class TopCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type1: false,
      duration: DURATION,
      salesType: "",
      dateVisible: false,
      startDate: "",
      endDate: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getDashboardRecord(langs.keyname.revenue);
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record of ad type
   */
  getDashboardRecord = (salesType) => {
    this.setState({ salesType: salesType });
    let requestData = {
      type: 4,
      sales: salesType,
    };
    this.props.getTopCompany(requestData, (res) => {});
  };

  /**
   * @method renderCompanyRecord
   * @description  render company listing
   */
  renderCompanyRecord = (companyList) => {
    const { salesType } = this.state;
    if (companyList && companyList.length) {
      return (
        companyList &&
        Array.isArray(companyList) &&
        companyList.map((item, index) => {
          return (
            <tbody>
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{item.newspaper_name}</td>
                <td>
                  ${" "}
                  {salesType === langs.keyname.revenue
                    ? item.total_amount
                    : item.total_orders}{" "}
                </td>
              </tr>
            </tbody>
          );
        })
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan="3">
              <NoRecordFoundContent />
            </td>
          </tr>
        </tbody>
      );
    }
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilterOnAdType(values) {
    const { startDate, endDate, dateVisible, salesType } = this.state;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      month: data.this_month,
      year: data.this_year,
      type: 4,
      from_date: data.date,
      to_date: data.date2,
      sales: salesType ? salesType : langs.keyname.revenue,
    };
    this.props.getTopCompany(requestData, (res) => {});
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { type1, duration, dateVisible, startDate, endDate } = this.state;
    const { companyReportData } = this.props;
    return (
      <div>
        <Row className="table-header-withSelector">
          <Col xs="6" className="mb-2">
            <span className="mr-5">By</span>
            <div className="radio-inline">
              <RadioButtonRP label="Sales">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={!type1 ? true : ""}
                  onChange={(e) => {
                    this.setState({ type1: false });
                    this.getDashboardRecord(langs.keyname.revenue);
                  }}
                  name="companyType"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Orders">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={type1 ? true : ""}
                  onChange={(e) => {
                    this.setState({ type1: true });
                    this.getDashboardRecord(langs.keyname.orders);
                  }}
                  name="companyType"
                />
              </RadioButtonRP>
            </div>
          </Col>
          <div className="col-6 mb-2 j-end">
            <Field
              name="company"
              options={duration}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                if (e.target.value === "1") {
                  this.setState({ dateVisible: true });
                } else {
                  this.setState({ dateVisible: false });
                  this.props.change("from", "");
                  this.props.change("to", "");
                  this.applyFilterOnAdType(e.target.value);
                }
              }}
              component={renderSelectField}
            />
          </div>
          {dateVisible && (
            <Row>
              <Col md="6">
                <Field
                  name="from"
                  label="From"
                  //minDate={endDate}
                  onChange={(e) => {
                    this.props.change("to", "");
                    this.setState(
                      { startDate: e, endDate: "", dateVisible: true },
                      () => {
                        this.applyFilterOnAdType(e);
                      }
                    );
                  }}
                  component={renderDatePicker}
                />
              </Col>
              <Col md="6">
                <Field
                  name="to"
                  label="To "
                  minDate={startDate}
                  onChange={(e) => {
                    this.setState({ endDate: e, dateVisible: true }, () => {
                      this.applyFilterOnAdType(e);
                    });
                  }}
                  component={renderDatePicker}
                />
              </Col>
            </Row>
          )}
        </Row>

        <Table>
          <thead style={{ backgroundColor: "#dcdcdccc" }}>
            <tr>
              <th>#</th>
              <th>Company Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          {this.renderCompanyRecord(companyReportData)}
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { AdminDashboardRecord } = store;
  const { auth } = store;
  const { topCompanyData } = AdminDashboardRecord;
  return {
    loggedInUser: auth.user,
    loading,
    companyReportData:
      topCompanyData && Array.isArray(topCompanyData.topCompany)
        ? topCompanyData.topCompany
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    AdminDashboardRecord.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "TopCompany",
    })(TopCompany)
  )
);
