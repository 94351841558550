import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form'
import {
    required,
    percentageOrFixedAmountValidation
} from '../../../../config/validation'
import { getNumbersRange } from '../../../../config/utils'
import { renderSelectField, renderNumberField } from '../../../../common/layout/FormInput'

const AddPaymentDiscountOptions = ({
    deadlinePrice,
    displayAdColors,
    onAddPayDiscountOption,
    paymentDiscountOptions,
    handleSubmit,
    formValues,
    reset,
    disableFormField
}) => {
    /**
     * @method getRunsOptions
     * @description return select options for runs
     */
    const getRunsOptions = () => {
        return getNumbersRange(10).map(run => (
            {
                label: run,
                value: run
            }
        ))
    }

    /**
     * @method getWithinOptions
     * @description return select options for within
     */
    const getWithinOptions = () => {
        return getNumbersRange(12).map(within => (
            {
                label: within,
                value: within
            }
        ))
    }

    /**
     * @method handleOnSubmit
     * @description handle on submit form
     * @param {*} values 
     * @param {*} actions 
     */
    const handleOnSubmit = (values) => {
        let color_cost_ad_cost = 0.00;
        let colorPrice=0.00;
        if(displayAdColors){
            for(let i = 0; i < displayAdColors.length; i ++){
                colorPrice = colorPrice + parseFloat(displayAdColors[i].cost)
            }
        }
        color_cost_ad_cost = colorPrice + parseFloat(deadlinePrice);
        const isAlreadyIn = paymentDiscountOptions.some(op => op.runs === values.runs && op.within === values.within)
        if (isAlreadyIn) {
            const errors = {};
            errors.runs = 'Already in the list';
            errors.within = 'Already in the list';
            throw new SubmissionError(errors);
        } 
        else if(parseInt(values.runs) < 0 && parseInt(values.within) < 0 ){
            const errors = {};
            errors.runs = 'Can\'t Have negative Values';
            errors.within = 'Can\'t Have negative Values';
            throw new SubmissionError(errors);
        }
        else if( values.typeOfDiscount=="Fixed Amount" && 
            parseFloat(color_cost_ad_cost) < parseFloat(values.discountAmount)){
            const errors = {};
            if(displayAdColors){
                errors.discountAmount = 'Discount can not be more than the total of ad price and color price';
            }else{
                errors.discountAmount = 'Discount can not be more than the ad price';
            }
            
            throw new SubmissionError(errors);
        }
        else {
            // console.warn("deadlinePrice",deadlinePrice)
            // console.warn("displayAdColors", displayAdColors)
            // console.warn("Value",values)
            onAddPayDiscountOption(values)
            reset();
        }
    }

    return (

        <div className="form-row align-items-center">
            <div className="col-md-2" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="runs"
                        component={renderNumberField}
                        label="How Many Runs?"
                        disabled={disableFormField}
                    />
                </div>
            </div>
            <div className="col-md-2" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="within"
                        component={renderNumberField}
                        label="Within Weeks"
                        disabled={disableFormField}
                    />
                </div>
            </div>
            <div className="col-md-4" style={{ height: 105 }}>
                <div className="form-group">
                    <Field
                        name="typeOfDiscount"
                        component={renderSelectField}
                        label="Type of Discount"
                        options={[{ label: 'Percentage', value: 'Percentage' }, { label: 'Fixed Amount', value: 'Fixed Amount' }]}
                        optionValue={"value"}
                        disabled={disableFormField}
                        optionLabel={"label"}
                        validate={required}
                        isSelect={false}
                    />
                </div>
            </div>
            <div className="col-md-3" style={{ height: 105 }}>
                <div className="form-group">

                    <Field
                        name="discountAmount"
                        label="Amount"
                        component={renderNumberField}
                        disabled={disableFormField}
                        validate={percentageOrFixedAmountValidation}
                        min={formValues.typeOfDiscount === 'Percentage' ? '1' : '0'}
                        step=".01"
                        helpText={'Only Numeric value with up to 1 (for %) or 2 decimal places'}
                    />
                </div>
            </div>
            <div className="col-md-1 mt-4" style={{ height: 105 }}>
                <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={handleSubmit(handleOnSubmit)}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>

    )
}

/** redux form value selector */
const formValSelector = formValueSelector('addPaymentDiscountOptions')

/**
 * @method mapStateToProps
 * @description map redux store state to props
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
    // fetching form fields states
    const formValues = formValSelector(state,
        'runs',
        'within',
        'typeOfDiscount',
        'discountAmount'
    );

    return {
        initialValues: {
            runs: '', within: '', typeOfDiscount: '', discountAmount: ''
        },
        formValues,
    }
}

export default connect(
    mapStateToProps,
)(reduxForm({
    form: 'addPaymentDiscountOptions',
    enableReinitialize: true,
    destroyOnUnmount: false,
})(AddPaymentDiscountOptions))