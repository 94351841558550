import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../../config';
import {
    CALL_LOADING
} from '../../../../config/constant';
import { apiErrors } from '../../../../common/HandleAPIErrors'


export const actionTypes = {
    SetOrderHeaderDetail: "[SetOrderHeaderDetail] Action",
    SetOrderGraphDetail: "[SetOrderGraphDetail] Action",
    SetOrderListing: "[SetOrderListing] Action",
    SetAdTypeDetail: "[SetAdTypeDetail] Action",
    SetCompanyDetail: "[SetCompanyDetail] Action"
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    orderHeaderDetail: undefined,
    orderGraphDetail: undefined,
    orderReportList: undefined,
    orderAdTypeDetail: undefined,
    orderCompanyDetail: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of company list */
            case actionTypes.SetOrderHeaderDetail: {
                const { orderHeaderDetail } = action.payload;
                return { ...state, orderHeaderDetail: (orderHeaderDetail.multipleCounts !== undefined) ? orderHeaderDetail.multipleCounts : undefined }
            }

            /** get all records of company list */
            case actionTypes.SetOrderGraphDetail: {
                const { orderGraphDetail } = action.payload;
                return { ...state, orderGraphDetail: orderGraphDetail && orderGraphDetail};
            }

            /** get all records of company list */
            case actionTypes.SetOrderListing: {
                const { orderReportList } = action.payload;
                return { ...state, orderReportList: orderReportList && orderReportList };
            }

            /** get all records of company list */
            case actionTypes.SetAdTypeDetail: {
                const { orderAdTypeDetail } = action.payload;
                return { ...state, orderAdTypeDetail: orderAdTypeDetail && orderAdTypeDetail };
            }

            /** get all records of company list */
            case actionTypes.SetCompanyDetail: {
                const { orderCompanyDetail } = action.payload;
                return { ...state, orderCompanyDetail: orderCompanyDetail && orderCompanyDetail};
            }
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getOrderReportDetail
    * @description get order report detail
    */
    getOrderReportDetail: (requestData, callback) => {
        let { npc_id='', print='', pdf='',section, data = '', type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', search = '', statement = '' } = requestData;
        let url = `${API.getOrderReportDetail}?value=${data}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&search=${search}&statement=${statement}&npc_id=${npc_id}&print=${print}&pdf=${pdf}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                if (section === 1) {
                    return dispatch({ type: actionTypes.SetOrderHeaderDetail, payload: { orderHeaderDetail: response.data } })
                } else if (section === 2) {
                    return dispatch({ type: actionTypes.SetOrderGraphDetail, payload: { orderGraphDetail: response.data } })
                } else if (section === 3) {
                    return dispatch({ type: actionTypes.SetOrderListing, payload: { orderReportList: response.data } })
                }else if (section === 4) {
                    return dispatch({ type: actionTypes.SetAdTypeDetail, payload: { orderAdTypeDetail: response.data } })
                }else if (section === 5) {
                    return dispatch({ type: actionTypes.SetCompanyDetail, payload: { orderCompanyDetail: response.data } })
                }
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },
};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





