import React, { useEffect, useState } from 'react'

import Nav from './Nav'
import Footer from './Footer'
import Formup from './Bankinfo/Formup';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import * as NewsPaperCompanyDuck from '../../store/ducks/super-admin/NewsPaperCompany.duck';
import * as npcDuck from '../../store/ducks/npc.duck';
import * as commonDuck from '../../store/ducks/Common.duck';
import { STATUS_CODES } from '../../common/StatusCode';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { rest } from 'lodash';
import AfterSubmit from './Bankinfo/AfterSubmit';

function BankInfo(props) {
    const { id } = useParams();
//    const getCompanyDetail = () => {
//     const { id } = this.props.match.params;
//     const requestData = {
//       company_id: id
//     };
//     this.props.getCompanyById(requestData, (res) => {
//       if (res.status !== STATUS_CODES.OK) {
//         this.setState({ noContentFound: true })
//       }
//     });
//   }
   let [isPayment, setisPayment] = useState(1)
   let [data, setdata] = useState([]);
   useEffect(() => {
       window.scrollTo(0, 0);
       console.warn('id',props.selectedCompany)
       //  const data=props.NewsPaperCompanyDuck.actions.getCompanyById({id});
    setisPayment('true')
     const requestData = {
        company_id: id
      };
      props.getBankDetailsById(requestData, (res) => {
          setdata(res)
        });
	}, [])

	return (
		<div>
			<Nav isPayment={isPayment} />
      {
        props.selectedCompany!=undefined ? 
            <Formup selectedCompany={props.selectedCompany} />
            :
            <AfterSubmit />
      }
			<Footer />
		</div>
	)
}

const mapStateToProps = store => {
    const { auth, authToken } = store;
    console.warn(store.newsPaperCompanyDuck.selectedCompany)
     const selectedCompany =  store.newsPaperCompanyDuck.selectedCompany;
    console.warn("Selected Company", selectedCompany)
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    selectedCompany: selectedCompany
  };
};
// const mapStateToProps = store => {
//     const { commonDuck } = store
//     const selectedCompany = commonDuck.selectedCompany;
//     console.warn(commonDuck)
//     let initialValues = {};
//     if (selectedCompany && selectedCompany !== undefined) {
//         initialValues = {
//             payment_mode: selectedCompany.payment_mode,
//             account_name: selectedCompany.account_name,
//             account_number: selectedCompany.account_number,
//             bank_name: selectedCompany.bank_name,
//             bank_address: selectedCompany.bank_address,
//             bank_zip_code: selectedCompany.bank_zip_code,
//             routing_number: selectedCompany.routing_number
//         };
//     }
//     return {
//         loggedInUser: store.auth.user,
//         authToken: store.auth.authToken,
//         selectedCompany: selectedCompany,
//         loading: commonDuck.loading,
//         initialValues : initialValues
//     };
// };
// export default injectIntl(
//     connect(
//         mapStateToProps,
//         { ...npcDuck.actions, ...commonDuck.actions }
//     )(
//         reduxForm({
//             // Assign unique name for the form
//             form: 'UpdatePaymentMethode',
//             enableReinitialize: true,
//             destroyOnUnmount: false
//         })(BankInfo)
//     )
// );

export default connect(mapStateToProps, NewsPaperCompanyDuck.actions)(BankInfo)