import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import {
  renderText,
  renderNumberInputField,
} from "../../../../common/layout/FormInput";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as superAdminDuck from "../../../../store/ducks/SuperAdmin.duck";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import {
  required,
  PhoneNumber,
  minLength5,
  maxLength100,
  maxLengthC100,
  email,
  normalizePhone,
} from "../../../../config/validation";
import ReasonBar from "./ReasonBar";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { NoContentFound } from "../../../../common/NoContentFound";
import CheckboxRP from "../../../../common/CheckboxRP";

class ViewRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditFlag: false,
      value: "",
      number: "",
      email: "",
      isSubmitted: false,
      sendEmail: true,
      noContentFound: false,
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    const { authToken, user_detail } = this.props.loggedInUser;
    const companyId = this.props.match.params.id;
    if (companyId) {
      this.props.getCompanyDetailById(
        user_detail.id,
        companyId,
        true,
        authToken,
        (res) => {
          if (res.status === STATUS_CODES.OK && res.data && res.data) {
            this.onChange(res.data.user.phone_number);
            this.setState({ sendEmail: res.data.email_distribution });
          } else {
            this.setState({ noContentFound: true });
          }
        }
      );
    }
  }

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    this.setState({
      isEditFlag: true,
      isSubmitted: true,
      number: type === "number" ? e.target.value : this.state.number,
    });
  };

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    const number =
      e !== undefined && e.target !== undefined
        ? e.target.value.replace(/\D/g, "")
        : e.replace(/\D/g, "");
    //Check if the input is of correct length
    let match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      this.setState({
        number: match[1] + "-" + match[2] + "-" + match[3],
      });
    } else {
      this.setState({
        number,
        isEditFlag: true,
        isSubmitted: true,
      });
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { number, sendEmail, noContentFound } = this.state;
    if (noContentFound) {
      return (
        <div>
          <NoContentFound />
        </div>
      );
    } else {
      return (
        <div>
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h3 className="kt-subheader__title col-md-6">
                  <span>Newspaper Request</span>
                </h3>
              </div>
            )}
          </LayoutContextConsumer>
          <div className="col-md-12">
            <Portlet>
              <PortletBody>
                <Form noValidate>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Field
                        name="first_name"
                        value={"adminProfile.first_name"}
                        label="First Name"
                        validate={[required, minLength5, maxLengthC100]}
                        disabled
                        onChange={this.onChangeDetailHandeler}
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Field
                        name="last_name"
                        label="Last Name"
                        validate={[required, minLength5, maxLengthC100]}
                        disabled
                        onChange={this.onChangeDetailHandeler}
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Field
                        name="newspaper_name"
                        label="Newspaper Name"
                        type="text"
                        disabled
                        style={{ width: "100%" }}
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Field
                        name="roleName"
                        label="Role"
                        disabled
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Field
                        name="phone_number"
                        label="Phone Number"
                        type="text"
                        style={{ width: "100%" }}
                        disabled
                        maxLength={10}
                        onChange={(e) => {
                          this.onChange(e);
                        }}
                        Value={number}
                        validate={[required, PhoneNumber]}
                        required={true}
                        component={renderNumberInputField}
                        normalize={normalizePhone}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Field
                        name="email"
                        label="Email"
                        disabled
                        validate={[required, minLength5, maxLength100, email]}
                        onChange={this.onChangeDetailHandeler}
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Field
                        name="distribution_location"
                        label="Location Of Distribution"
                        type="text"
                        style={{ width: "100%" }}
                        disabled
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Field
                        name="distribution_type"
                        label="Type"
                        disabled
                        onChange={this.onChangeDetailHandeler}
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Field
                        name="distributed_copies"
                        label="No Of Copies Distributed"
                        type="text"
                        disabled
                        style={{ width: "100%" }}
                        component={renderText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Row>
                        <Col md="12">
                          <div class="form-group">
                            <label>Email distribution</label>
                            <div class="checkbox-inline mt-2">
                              <CheckboxRP label="Yes">
                                <input
                                  type="checkbox"
                                  name="email_distribition"
                                  checked={sendEmail ? true : ""}
                                  disabled
                                />
                              </CheckboxRP>
                              <CheckboxRP label="No">
                                <input
                                  type="checkbox"
                                  name="email_distribition"
                                  checked={!sendEmail ? true : ""}
                                  disabled
                                />
                              </CheckboxRP>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </PortletBody>
            </Portlet>
            <ReasonBar
              companyId={
                this.props.match.params.id && this.props.match.params.id
              }
            />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => {
  const selectedCompany = store.superAdminDuck.selectedCompany;
  let initialValues = {};
  if (selectedCompany && selectedCompany !== undefined) {
    initialValues = {
      first_name:
        selectedCompany.user.first_name[0].toUpperCase() +
        selectedCompany.user.first_name.slice(1),
      last_name: selectedCompany.user.last_name,
      phone_number: selectedCompany.user.phone_number,
      email: selectedCompany.user.email,
      newspaper_name: selectedCompany.newspaper_name,
      roleName: selectedCompany.user.roleName,
      publish_frequency: selectedCompany.publish_frequency,
      distributed_copies:
        selectedCompany.distributed_copies &&
        selectedCompany.distributed_copies.copies_range,
      email_distribution: selectedCompany.email_distribution,
      distribution_type: selectedCompany.distribution_type,
      distribution_location:
        selectedCompany.distribution_location &&
        selectedCompany.distribution_location,
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    selectedCompany: store.superAdminDuck.selectedCompany,
    initialValues,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    superAdminDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "MyProfile",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(ViewRequest)
  )
);
