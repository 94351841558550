import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import "../Chart.scss";
import { Input, Label, Row, Col } from "reactstrap";
import {
  renderSelectField,
  renderDatePicker,
} from "../../../../../common/layout/FormInput";
import FilterRecords from "../FilterRecords";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { DATE_RANGE } from "../../../../../config/constant";
import FooterLoader from "../../../../../common/FooterLoader";
import { topRecordsFilterKeys } from "../../../../../common/CommonFilterFunction";
import { langs } from "../../../../../config/localization";
import CountFigureBox from "../../../../../common/CountFigureBox";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ["Total"],
      type: false,
      duration: DATE_RANGE,
      dateVisible: false,
      startDate: "",
      endDate: "",
      localLoading: false,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentWillMount() {
    this.getAdTypeReport(4, 1, "", "", langs.keyname.revenue, "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getAdTypeReport = (row, page, sort, column, salesType, adType) => {
    this.setState({
      totalRevenue: ["Total"],
    });
    this.setState({ salesType: salesType, adType: adType });
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 2,
      ad_type: adType,
      sales: salesType,
    };
    this.setData(requestData, salesType);
  };

  /**
   * @method setData
   * @description  set requestdata on api
   */
  setData = (requestData, salesType) => {
    const { totalRevenue } = this.state;
    this.setState(
      {
        totalRevenue: ["Total"],
        localLoading: true,
      },
      () => {
        this.props.getAdTypeGraphData(requestData, (res) => {
          this.setState({ localLoading: false });
          if (res.status === STATUS_CODES.OK) {
            let data = res.data && res.data.percentage;
            let temp = totalRevenue;
            if (
              salesType === langs.keyname.orders &&
              res.data &&
              Object.keys(data).length !== 0
            ) {
              temp.push(data.planPercentage);
              this.setState({ totalRevenue: [...temp] });
            } else if (
              salesType === langs.keyname.revenue &&
              res.data &&
              Object.keys(data).length !== 0
            ) {
              temp.push(data.planPercentage);
              this.setState({ totalRevenue: [...temp] });
            }
          }
        });
      }
    );
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilterOnAdType(values) {
    const { startDate, endDate, dateVisible, salesType } = this.state;
    const { user_detail } = this.props.loggedInUser;
    const data = topRecordsFilterKeys(values, dateVisible, startDate, endDate);
    const requestData = {
      npc_id: user_detail.newspaper.id,
      row: 25,
      page: 1,
      month: data.this_month,
      year: data.this_year,
      type: 2,
      from_date: data.date,
      to_date: data.date2,
      ad_type: "",
      sales: "",
    };
    this.setData(requestData, salesType);
  }

  /**
   * @method revenueSelection
   * @description  called to apply filter
   */
  revenueSelection = () => {
    this.setState(
      {
        totalRevenue: ["Total"],
      },
      () => {
        this.getAdTypeReport(4, 1, "", "", langs.keyname.revenue, "");
      }
    );
  };

  /**
   * @method orderSelection
   * @description  called to apply filter
   */
  orderSelection = () => {
    this.setState(
      {
        totalRevenue: ["Total"],
      },
      () => {
        this.getAdTypeReport(4, 1, "", "", langs.keyname.orders, "");
      }
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const {
      type,
      duration,
      totalRevenue,
      dateVisible,
      startDate,
      endDate,
      localLoading,
    } = this.state;
    const { openModal } = this.props;
    return (
      <CountFigureBox alignStart={true}>
        <div className="row w-100">
          <h6 className="col-md-6 mt-5 text-truncate">{`Ad plan Contribution`}</h6>
          <div className="col-md-6 mt-3">
            <Field
              name="date_range"
              options={duration}
              style={{ marginTop: "-27px !important" }}
              optionValue={"value"}
              optionLabel={"label"}
              onChange={(e) => {
                if (e.target.value === "1") {
                  this.setState({ dateVisible: true });
                } else {
                  this.setState({ dateVisible: false });
                  this.props.change("from", "");
                  this.props.change("to", "");
                  this.applyFilterOnAdType(e.target.value);
                }
              }}
              component={renderSelectField}
            />
          </div>
          {dateVisible && (
            <Col>
              <Row className="w-100">
                <Col md="6">
                  <Field
                    name="from"
                    label="From"
                    minDate={endDate}
                    onChange={(e) => {
                      this.setState({ startDate: e, dateVisible: true }, () => {
                        this.applyFilterOnAdType(e);
                        this.props.change("to", "");
                      });
                    }}
                    component={renderDatePicker}
                  />
                </Col>
                <Col md="6">
                  <Field
                    name="to"
                    label="To "
                    minDate={startDate}
                    onChange={(e) => {
                      this.setState({ endDate: e, dateVisible: true }, () => {
                        this.applyFilterOnAdType(e);
                      });
                    }}
                    component={renderDatePicker}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </div>
        <div className="row w-100">
          <h6 className="col-md-5 text-left text-truncate ">{`Select Criteria`}</h6>
          <div className="col-md-7 d-flex-center">
            <div className="radio-inline">
              <RadioButtonRP label="Revenue">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={!type ? true : ""}
                  onChange={(e) => {
                    this.setState({ type: false });
                    this.revenueSelection();
                  }}
                  name="type1"
                />
              </RadioButtonRP>
              <RadioButtonRP label="Order">
                <Input
                  type="radio"
                  id="checkbox2"
                  checked={type ? true : ""}
                  onChange={(e) => {
                    this.setState({ type: true });
                    this.orderSelection();
                  }}
                  name="type1"
                />
              </RadioButtonRP>
            </div>
          </div>
        </div>
        {localLoading && (
          <div>
            <h4 style={{ textAlign: "center" }}>Loading...</h4>
            <FooterLoader />
          </div>
        )}
        {!localLoading && totalRevenue.length > 1 ? (
          <C3Chart
            unloadBeforeLoad={true}
            size={{
              height: 180,
            }}
            data={{
              columns: [totalRevenue, ["Remaining", 100 - totalRevenue[1]]],
              type: "pie",
            }}
            pie={{
              label: {
                format: function(value, ratio, id) {
                  return Math.round(value);
                },
              },
            }}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            {localLoading === false && "No Record Found"}
          </div>
        )}

        {openModal && (
          <FilterRecords
            applyFilter={this.applyFilter}
            isOpen={openModal}
            onCancel={this.props.onCancel}
          />
        )}
      </CountFigureBox>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { paymentReportDuck } = store;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    salesDetail: paymentReportDuck.salesDetail,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "PieChart",
      destroyOnUnmount: false,
    })(PieChart)
  )
);
