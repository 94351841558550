import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import C3Chart from 'react-c3js';

class Chart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <C3Chart
                    type='line'
                    data={{
                        columns: [
                        ],
                    }}
                    axis={{                    
                        x: {
                            label: {
                                text: 'No Content Found',
                                position: 'inner-center'
                            }
                        },
                        y: {
                            label: {
                                text: 'Orders',
                                position: 'outer-middle'
                            }
                        },
                        y2: {
                            show: true,
                            label: {
                                text: 'Amount',
                                position: 'outer-middle'
                            },
                            max: 90,
                            min: 0
                        }
                    }}
                    unloadBeforeLoad={true}
                />             
            </div>
        );
    }
}


export default Chart 
