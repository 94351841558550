import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Reservation = () => {

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <section class="reserve_sec Reservation_sec reserve_inner_sec ">
        <div class="container">
          <div class="reserve_inner reserve_inner2">
            <div class="row align-items-stretch">
              <div class="col-lg-6">
                <div class="reserve_sec_img reserve_sec_img2">
                  <img
                    class="main_shadow main_img"
                    src="assets/images/calender-banner.png"
                    alt=""
                  />
                  <div class="rocket approval approvalTwo" data-aos-delay="300" data-aos="slide-left"  data-aos-duration="700">
                    <img alt="" className="main_shadow" src="assets/images/killingtop.png" />
                  </div>
                  <div class="reserve_bootm reserve_bootm2 reservation_bttm" data-aos-delay="300" data-aos="slide-right"  data-aos-duration="700">
                    <img className="main_shadow" alt="" src="assets/images/reserve1.png" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center" data-aos-delay="300" data-aos="slide-left"  data-aos-duration="1000">
                <div class="reserve_content">
                  <div class="pagehd">
                    <h4>Early reservation process</h4>
                    <p>
                      Once an agent creates a plan, he can with a click of a
                      button request reservation for all of his insertion
                      orders. This will give you ample time to respond,
                      negotiate and plan your issue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Reservation;
