export const faqData =  [
    {
        id: "panel1",
        heading: "Does Pijen replace my one on one relationship with the publisher?",
        content: "The one on one relationships that you have with a publisher will only get better. When all the technicals are out of the way, your conversations can center around building a rapport and getting better deals. You will also have insight to share with publishers and show them your history so that they value their relationship with you."
    },
    {
        id:"panel2",
        heading: "What happens with the commission agreements I have with a publisher?",
        content: "If you have commission agreements with a publisher you will love Pijen! Pijen allows you to set up your commission agreements so that you can easily track your earnings and make sure that your media buying department is profitable."
    }, 
    {
        id:"panel3",
        heading: "How can I make sure that my client’s ad gets prime placement?",
        content: "We heard about this concern from other agencies. That’s why we added a comment section under each insertion order. If you’d like to specify any specific instructions you can note it there. When the publisher reviews your insertion order they can leave a comment back so you will know if your request was honored. "
    },
    {
        id: "panel4",
        heading: "I have a good system right now with our task management system of Google Sheets. How is Pijen different?",
        content: "Great Question! Besides having all the information built into the system and allowing you to automate a huge part of the process, Pijen makes you look good. Your clients will receive professional placement plans, they will be able to track their campaign and get the feeling that “You’ve got this!”"
    },
    {
        id:"panel5",
        heading: "Am I locked into the monthly subscriptions?",
        content: "We offer a 14 day free trial and no commitment subscription model afterwards. If at any point you decide that Pijen is not for you, you can simply cancel the subscription and your card will no longer be charged."
    },
    {
        id: "panel6",
        heading: "What is Pijen.com",
        content: "Pijen is a smart media-buying tool that will maximize your efficiency, client satisfaction, and increase your sales. <br/> With its practical, easy-to-use tools to help you with ad placement, proposals, and deadlines, you'll experience smoother media buying and minimize errors."
    },

    {
        id:"panel7",
        heading: "Why should I use Pijen.com?",
        content: "If you're tired of juggling multiple deadlines, keep all the publishers' price sheets on Google sheets, and are busy fielding emails between clients and publishers all day, you might benefit from a smart media buying tool.<br /> With Pijen's powerful auto-fill features, smart calendar, and client approval portal, you'll be saving yourself lots of time spent on media placement and making more time for the fun stuff!"
    }, 

    {
        id:"panel8",
        heading: "Can I get a free trial?",
        content: "Yes, there's a free trial to Pijen.com that's available to all new subscribers.After the free trial is over, your card will automatically be charged for the basic plan. You can cancel at any time."
    },

    {
        id: "panel9",
        heading: "Can I get a demo?",
        content: "Sure! Click on the link at the top of the page to schedule a free demo with one of our customer service representatives. They'll show you how to maximize Pijen's features, so that you get the most out of it for your business."
    },

    {
        id: "panel10",
        heading: "Which Pijen.com subscription is right for me?",
        content: " If you're a freelancer who doesn't place too many ads per month, you'll be fine starting off with the starter subscription. Mid-sized agencies will appreciate the larger storage capacity of the plus and premium plans.<br /> If you're unhappy with your subscription choice, you can always upgrade or downgrade."
    },
    {
        id: "panel11",
        heading:"How is Pijen different than Google sheets?",
        content: "Besides for storing all the information about every ad type available, Pijen automates the process from start to finish. Once you build your plan, Pijen will help you schedule, reserve, confirm, and upload your artwork with very minimal effort."
    },

    {
        id: "panel12",
        heading: "How can I cancel my subscription?",
        content: "Under your 'Profile' setting, and 'Subscription Details' you'll see the option to 'Cancel your Subscription.' We'd love to hear the reason for your cancellation and would be happy to work with you if you're unhappy with any of our services."
    }

]