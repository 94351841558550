import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { renderText, renderSelectField, renderNumberField } from '../../../../common/layout/FormInput';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext';
import * as npcDuck from '../../../../store/ducks/npc.duck';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import { MESSAGES } from '../../../../config/message'
import NavigateModal from '../../NavigateModal';
import NavigationPrompt from 'react-router-navigation-prompt';
import '../../Modal.scss'
import { Loader } from '../../../../common/Loader';
import {
    required,
    minLength5,
    maxLength100,
    minLength2,
    maxLength5,
    normalizePhone,
    gminLength
} from '../../../../config/validation';
import { STATUS_CODES } from '../../../../common/StatusCode'
import { PAYMENT_METHOD,SCHEDULE_PAYMENT,PERCENT_ARRAY,PUBLISH_DAY } from '../../../../config/constant'

class UpdatePaymentMethode extends React.Component {
    constructor(props) {
        super(props);
        const monthDates = [{ label: '1', value: '1' }, { label: '2', value: '2' }];
        this.state = {
            visibleChanepassword: false,
            companyRoles: [],
            status: true,
            value: '',
            number: '',
            success: false,
            error: false,
            isRedirect: false,
            showStateSelection: false,
            showCitySelection: false,
            states: [],
            percentArray: PERCENT_ARRAY,
            paymentMode: PAYMENT_METHOD,
            schedulePayment:SCHEDULE_PAYMENT,
            countryType: [
                { label: 'USA', value: 'USA' }
            ],
            selectedState: '',
            selectedCity: '',
            billingState: '',
            billingCity: '',
            publishType: [{
                value: -1,
                label: 'Select publish Type',
            }, { label: 'Daily', value: 0 }, { label: 'Weekly', value: 1 }, { label: 'Monthly', value: 2 }],
            publishWeekDay: PUBLISH_DAY,
            publishMonthDate: monthDates,
            selectedPublishDate: '',
            selectedPublishType: '',
            message: '',
            isSubmitted: true,
            loading: false,
            isEditFlag: false
        }
    }

    /** 
     * @method componentDidMount
     * @description called before rendering the component
     */
    componentDidMount() {
        const { user_detail } = this.props.loggedInUser;

        const requestData = {
            company_id: user_detail.newspaper.id
        }
        this.props.getCompanyById(requestData, (res) => {
        })
    }
   
    /**
      * Submit the login form
      * @param values
      * @desc Calling a login actions && redirecting page based on response and set session Items 
      */
    onSubmit(values) {
        const { user_detail } = this.props.loggedInUser;
        values.id = user_detail.newspaper.id;
        values.name=user_detail.first_name+" "+user_detail.last_name;
        values.email=user_detail.email;
        values.phone=user_detail.phone_number;
        values.active = true;
        values.created_by = 'superAdmin'
        this.setState({ isSubmitted: false })
        this.props.addPaymentCompany(values, (res) => {
            if (res.status === STATUS_CODES.CREATED) {
                toastr.success(MESSAGES.UPDATE_PAYMENT_SUCCESS)
                this.setState({ isSubmitted: false });
            }else{
                this.setState({ isSubmitted: true });
            }
        })
    }

    /**
     * @method onChangeDetailHandeler
     * @description called for handling prompt
     */
    onChangeDetailHandeler = () => {
        this.setState({
            isSubmitted: true,
        });
    };

    /**
    * @method render
    * @description render the component
    */
    render() {
        const { handleSubmit, loading } = this.props;
        const { isEditFlag, paymentMode, isSubmitted } = this.state;
        return (
            <div>
                {loading && <Loader />}
                <NavigationPrompt when={isEditFlag}>
                    {(props) => {
                        const { onConfirm, onCancel } = props;
                        return (
                            <NavigateModal
                                show={true}
                                alertHeading={'Changes made will be lost.'}
                                cancleButtonText='Cancel'
                                okButtonText='Leave Anyway'
                                onOkClick={() => onConfirm()}
                                onClickCancel={() => { onCancel() }}
                                showCancel={true}
                            />
                        )
                    }}
                </NavigationPrompt>
                <LayoutContextConsumer className='row'>
                    {({ subheader: { title } }) => (
                        <div className='row'>
                            <h3 className='kt-subheader__title col-md-6'>
                                <span>Payment Setup</span>
                            </h3>
                        </div>
                    )}
                </LayoutContextConsumer>
                <div className='col-md-12'>
                    <Portlet fluidHeight={true}>
                        <PortletBody >
                            <Container className='top-margin'>                           
                                <Row>
                                    <Container>
                                        <form
                                            noValidate
                                            className='form'
                                            onSubmit={handleSubmit(this.onSubmit.bind(this))}
                                        >
                                            {/* <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='payment_mode'
                                                        label='Payment Mode'
                                                        options={paymentMode}
                                                        isSelect={false}
                                                        optionValue={'value'}
                                                        optionLabel={'label'}
                                                        onChange={this.onChangeDetailHandeler}
                                                        component={renderSelectField}
                                                    />
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='account_name'
                                                        label='Name Of Account'
                                                        validate={[required, gminLength, maxLength100]}
                                                        required={true}
                                                        placeholder='Enter Name Of Account'
                                                        onChange={this.onChangeDetailHandeler}
                                                        component={renderText}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='routing_number'
                                                        label='Routing Number'
                                                        validate={[required, minLength2, maxLength100]}
                                                        required={true}
                                                        placeholder='Enter Routing Number'
                                                        onChange={this.onChangeDetailHandeler}
                                                        component={renderNumberField}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='account_number'
                                                        label='Account Number'
                                                        validate={[required, minLength2, maxLength100]}
                                                        required={true}
                                                        placeholder='Enter Your Account Number'
                                                        onChange={this.onChangeDetailHandeler}
                                                        component={renderNumberField}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='bank_name'
                                                        label='Bank Name'
                                                        validate={[required, gminLength, maxLength100]}
                                                        required={true}
                                                        placeholder='Enter Your Bank Name'
                                                        onChange={this.onChangeDetailHandeler}
                                                        component={renderText}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='bank_address'
                                                        value={'adminProfile.first_name'}
                                                        label='Bank Address'
                                                        validate={[required, gminLength, maxLength100]}
                                                        required={true}
                                                        placeholder='Enter Your Bank Address'
                                                        onChange={this.onChangeDetailHandeler}
                                                        component={renderText}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='8'>
                                                    <Field
                                                        name='bank_zip_code'
                                                        value={'adminProfile.first_name'}
                                                        label='Zip Code'
                                                        validate={[required, minLength5, maxLength5]}
                                                        maxLength={5}
                                                        required={true}
                                                        placeholder='Enter Your Zip Code'
                                                        component={renderText}
                                                        onChange={this.onChangeDetailHandeler}
                                                        normalize={normalizePhone}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row >
                                                <Col md='8'>
                                                    <Button style={{ float: 'right', marginRight: 10, width: 'auto', backgroundColor: 'black',color:'white' }} className='btn btn-secondary' onClick={() => {
                                                    }}
                                                        type='submit'
                                                        disabled={isSubmitted ? false : true}
                                                    >SAVE PAYMENT METHOD</Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Container>
                                </Row>
                            </Container >
                        </PortletBody>
                    </Portlet>
                </div>
            </div >
        );
    }
}


const mapStateToProps = store => {
    const { commonDuck } = store
    const selectedCompany = commonDuck.selectedCompany;
    let initialValues = {};
    if (selectedCompany && selectedCompany !== undefined) {
        initialValues = {
            payment_mode: selectedCompany.payment_mode,
            account_name: selectedCompany.account_name,
            account_number: selectedCompany.account_number,
            bank_name: selectedCompany.bank_name,
            bank_address: selectedCompany.bank_address,
            bank_zip_code: selectedCompany.bank_zip_code,
            routing_number: selectedCompany.routing_number
        };
    }
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        selectedCompany: selectedCompany ? selectedCompany : undefined,
        loading: commonDuck.loading,
        initialValues
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        { ...npcDuck.actions, ...commonDuck.actions }
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'UpdatePaymentMethode',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(UpdatePaymentMethode)
    )
);
