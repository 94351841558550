import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { STATUS_CODES } from "../../../../common/StatusCode";
import CreditModal from "./CreditModal";
import { convertISOToUtcDateformate1 } from "../../../../common";
import { ViewIconButton } from "../../../../common/Buttons";

/**
 * @method columnOrder
 * @description define the table columns order and format data accordingly
 * @param {*} activatePlan
 */

const CreditListing = (props) => {
  // pagination states
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(100);
  // sorting order state true===ASC and false===DESC
  const [sortedOrder, setSortedOrder] = useState(true);
  const [isOpen, setModalOpen] = useState(false);
  const [modelData, setModalData] = useState();

  const { creditList } = props;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getCreditList(pageSize, 1, "", "", "");
  }, []);

  const columnOrder = () => [
    {
      Header: "Customer ID",
      accessor: "user_id",
      Cell: (row) => {
        const { original } = row;
        return original.user && original.user.id;
      },
    },
    {
      Header: "Customer",
      accessor: "first_name",
      Cell: (row) => {
        const { original } = row;
        return original.user && original.user.first_name
          ? original.user.first_name + " " + original.user.last_name
          : "";
      },
    },

    {
      Header: "Newspaper",
      accessor: "newspaper_name",
      width: 200,
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="d-flex align-items-center">
            <div>
              {original && original.newspaper === null
                ? ""
                : original.newspaper.newspaper_name}
            </div>
          </div>
        );
      },
    },

    {
      Header: "Amount",
      accessor: "credit_amount",
      Cell: (row) => {
        const { original } = row;
        return `$ ${original.credit_amount}`;
      },
    },
    {
      Header: "Order#",
      accessor: "order_id",
      Cell: (row) => {
        const { original } = row;
        return `${original.order.id}`;
      },
    },
    {
      Header: "Expiration Date",
      accessor: "expiry_date",
      Cell: (row) => {
        const { original } = row;
        return convertISOToUtcDateformate1(original.expiry_date);
      },
    },
    {
      Header: "Credit Used",
      accessor: "credit_used",
      Cell: (row) => {
        const { original } = row;
        if (original.type === "debit") {
          return (
            <div className="d-flex align-items-center">
              <div>$ {original.credit_used}</div>
              <div>
                <ViewIconButton onClick={() => setDetail(original)} />
              </div>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Credit Available", //credit_available
      accessor: "credit_available",
      Cell: (row) => {
        const { original } = row;
        return `$ ${original.credit_available}`;
      },
    },
  ];

  /**
   * @method setDetail
   * @description  set credit detail
   */
  const setDetail = (data) => {
    setModalOpen(true);
    if (data && data !== undefined) {
      setModalData(data);
    }
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  const getCreditList = (row, page, sort, column, searchKey) => {
    let requestData = {
      npc_id: "",
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      searchKey: searchKey,
    };
    props.getAllCreditList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / pageSize;
        setTotalCount(Math.ceil(pages));
      }
    });
  };

  /**
   * @method onPageChange
   * @description used for handle pagination
   */
  const onPageChange = (page) => {
    setPage(page + 1);
    getCreditList(pageSize, page + 1, false, false, "");
  };

  /**
   * @method onPageSizeChange
   * @description used for handle pagination
   */
  const onPageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    getCreditList(pageSize, page, false, false, "");
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  const handleSearch = (e) => {
    if (e.target.value.length > 2) {
      let searchKey = encodeURIComponent(
        e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      );
      getCreditList(pageSize, 1, false, false, searchKey);
    } else {
      getCreditList(pageSize, 1, false, false, "");
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  const handleSort = (e, { sorted }, rowInfo, column, instance) => {
    if (column.id === "action" || column.id === "plan_image") {
      return;
    }
    instance.sortColumn(column);
    let sortBy = sortedOrder ? "ASC" : "DESC";
    setSortedOrder(!sortedOrder);
    getCreditList(pageSize, page, sortBy, column.id, "");
  };

  return (
    <div className="container-fluid credit-listing">
      <h3 class="kt-subheader__title col-md-6">
        <span>Credits</span>
      </h3>
      <Portlet>
        <PortletBody>
          <div className="row">
            <div className="col">
              <StandardTable
                handelSearch={handleSearch}
                columnOrder={columnOrder()}
                totalCount={totalCount}
                //allData={data}
                data={creditList}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                isAdvanceSearch={true}
                advancedSearchFor="CREDIT"
                handleSort={handleSort}
              />
            </div>
          </div>
        </PortletBody>
      </Portlet>
      {isOpen && modelData && (
        <CreditModal
          isOpen={isOpen}
          creditDetail={modelData && modelData}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  const { loading, creditList } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    creditList: Array.isArray(creditList) ? creditList : [],
  };
};
export default injectIntl(
  connect(mapStateToProps, commonDuck.actions)(CreditListing)
);
