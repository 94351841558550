import React, { Fragment } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { Field, FieldArray, change } from "redux-form";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  renderSelectField,
  renderText,
  renderDatePicker,
} from "../../../../common/layout/FormInput";
import { getTimes } from "../../../../config/utils";
import { Button } from "react-bootstrap";
import EditionDetailsSection from "./EditionDetailsSection";
import * as SeasonalIssuesDuck from "../../../../store/ducks/npc/SeasonalIssues.duck";
import { AiOutlineCalendar } from "react-icons/ai";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";

class EditSeasonalIssues extends React.Component {
  constructor() {
    super();
    this.state = {
      rowCount: [{}],
      allEditionList: [],
      allIssues: [],
      issueName: "",
      isUpdate: false,
      removeEdition: [],
      updateData: null,
      isDisabled: true,
      updatePrevData: {
        // issueName: "",
        // override_publish_date: "",
        // new_publish_date: "",
        // members: [],
      },
    };
  }
  addEditionRow = (edition_id) => {
    const { rowCount, allEditionList } = this.state;
    const { issueName, override_publish_date, new_publish_date } = this.props;
    let currentEdition = Array.from(rowCount);
    if (issueName && override_publish_date && new_publish_date) {
      currentEdition.unshift(
        allEditionList &&
          allEditionList.length > 0 &&
          allEditionList.filter((item) => {
            return item.edition_id === parseInt(edition_id);
          })[0]
      );
      this.setState({ rowCount: currentEdition });
      return true;
    } else {
      return false;
    }
  };
  removeEditionRow = (index) => {
    let currentEdition = Array.from(this.state.rowCount);
    currentEdition.splice(index, 1);
    this.setState({ rowCount: currentEdition });
  };
  getAllEditions(e, clear = true) {
    const requestData = {
      npc_id: this.props.loggedInUser.user_detail.newspaper.id,
      date: moment(e).format("yyyy-MM-DD"),
    };
    this.props.getAllEditionList(requestData, (res) => {
      if (res) {
        this.setState({
          allEditionList: res.data,
          // override_publish_date: moment(e).format("yyyy-MM-DD"),
        });
        if (clear) {
          this.props.dispatch(
            change(
              "EditSeasonalIssues",
              "override_publish_date",
              moment(e).format("MM/DD/YYYY")
            )
          );
          if (this.state.isUpdate) {
            const editionIds = res?.data?.map((e) => e?.edition_id);
            this.props.members.forEach((e, i) => {
              if (e.hasOwnProperty('edition_name') &&!editionIds.includes(Number(e?.edition_name))) {
                this.props.dispatch(
                  change("EditSeasonalIssues", `members[${i}].edition_name`, "")
                );
              }
            });
          }
        }
      }
    });
    if (clear) {
      this.props.dispatch(change("EditSeasonalIssues", "new_publish_date", ""));
    }
  }
  setEdition = (edition_id) => {
    // const { rowCount, allEditionList } = this.state;
    // rowCount.push(
    //   allEditionList &&
    //     allEditionList.length > 0 &&
    //     allEditionList.filter((item) => {
    //       return item.edition_id === parseInt(edition_id);
    //     })[0]
    // );
    // console.log("rowCount: ",rowCount);
  };

  componentDidMount() {
    this.props.getAllSeasonalIssue("all", (res) => {
      if (res?.data) {
        const allCalenders = res.data.map((item) => ({
          label: item.calendar_name,
          value: item.id,
        }));
        this.setState({
          allIssues: allCalenders,
        });
      }
    });
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.id) {
      this.setState({ isUpdate: true });
      const requestData = {
        overRideSeasonalId: params.id,
        isInactive: false,
      };
      this.props.getSeasonalIssuesByPublisher(requestData, (resp) => {
        if (resp?.status == 200) {
          const res_data = resp?.data;
          if (res_data) {
            this.setState({ updateData: res_data });
            const issueDetails = res_data.allResult;
            const new_publish_date = moment(
              issueDetails.new_publish_date,
              "YYYY-MM-DD"
            ).toDate();
            const override_publish_date = moment(
              issueDetails.override_publish_date,
              "YYYY-MM-DD"
            ).toDate();
            this.getAllEditions(override_publish_date, true);
            this.props.dispatch(
              change("EditSeasonalIssues", "new_publish_date", new_publish_date)
            );
            this.props.dispatch(
              change(
                "EditSeasonalIssues",
                "override_publish_date",
                override_publish_date
              )
            );
            this.props.dispatch(
              change(
                "EditSeasonalIssues",
                "issueName",
                issueDetails?.seasonal_issue?.seasonalIssueId?.toString()
              )
            );
            const allEditionResult = res_data.allEditionResult;
            const formattedMembers = allEditionResult.map((e, i) => {
              this.props.dispatch(
                change(
                  "EditSeasonalIssues",
                  `members[${i}].edition_name`,
                  e?.edition?.id.toString()
                )
              );
              this.props.dispatch(
                change(
                  "EditSeasonalIssues",
                  `members[${i}].ad_deadline`,
                  e?.deadline_day
                )
              );
              this.props.dispatch(
                change(
                  "EditSeasonalIssues",
                  `members[${i}].ad_deadline_time`,
                  e?.deadline_time
                )
              );
              this.props.dispatch(
                change(
                  "EditSeasonalIssues",
                  `members[${i}].id`,
                  e?.overrideEditionId
                )
              );
              return {
                edition_name: e?.edition?.id.toString(),
                ad_deadline: e?.deadline_day,
                ad_deadline_time: e?.deadline_time,
                id: e?.overrideEditionId,
              };
            });
            this.setState({ isDisabled: true });
            const k = {
              issueName: issueDetails?.seasonal_issue?.seasonalIssueId.toString(),
              new_publish_date: new_publish_date,
              override_publish_date: moment(override_publish_date).format(
                "MM/DD/YYYY"
              ),
              members: formattedMembers,
            };
            const stringifyData = JSON.stringify(k);
            this.setState({
              updatePrevData: stringifyData,
            });
          }
        }
      });
    }
  }
  componentWillUnmount() {
    if (this.props?.form === "EditSeasonalIssues") {
      this.props.destroy();
    }
  }

  handleSubmit = () => {
    const members = this.props?.members;
    let allEditionIdNotAvailable = false;
    members.forEach((e, index) => {
      if (!e.edition_name) {
        allEditionIdNotAvailable = true;
      }
    });
    if (allEditionIdNotAvailable) {
      toastr.error(MESSAGES.ALL_FIELDS_ARE_REQUIRED);
      return;
    }
    const { issueName, override_publish_date, new_publish_date } = this.props;
    if (
      !issueName ||
      !override_publish_date ||
      !new_publish_date ||
      members < 1
    ) {
      return;
    }
    if (this.state.isUpdate) {
      const notDeleted = members.map((e) => {
        const data = {
          editionId: e.edition_name,
          deadlineDay: e.ad_deadline,
          deadlineTime: e.ad_deadline_time,
          isDeleted: false,
        };
        if (e.id) {
          data.id = e.id;
        }
        return data;
      });
      const deletedItems = this.state.removeEdition.map((e) => ({
        id: e.id,
        editionId: e.edition_name,
        deadlineDay: e.ad_deadline,
        deadlineTime: e.ad_deadline_time,
        isDeleted: true,
      }));
      const allEdition = notDeleted.concat(deletedItems);
      const requestData = {
        isInactive: false,
        issueId: this.state?.updateData?.allResult?.seasonal_issue
          ?.seasonalIssueId,
        overRideDate: moment(override_publish_date).format("MM/DD/YYYY"),
        newPublisherDate: moment(new_publish_date).format("MM/DD/YYYY"),
        id: this.state?.updateData?.allResult?.overRideSeasonalId,
        editions: allEdition,
      };
      this.props.updateSeasonalIssueOverrideEvent(requestData, (res) => {
        if (res && res.data) {
          toastr.success(MESSAGES.SEASONAL_ISSUE_OVER_RIDE_EVENT);
          this.props.history.push({
            pathname: `/seasonalIssue`,
          });
        }
      });
    } else {
      const filteredMembers = members.reduce((p, c) => {
        if (c.edition_name !== "" && c.ad_deadline && c.ad_deadline_time) {
          p.push({
            editionId: c.edition_name,
            deadlineDay: c.ad_deadline,
            deadlineTime: c.ad_deadline_time,
            isDeleted: false,
          });
        }
        return p;
      }, []);
      const requestData = {
        isInactive: false,
        issueId: issueName,
        overRideDate: override_publish_date,
        newPublisherDate: moment(new_publish_date).format("MM/DD/YYYY"),
        editions: filteredMembers,
      };
      this.props.addSeasonalIssueOverrideEvent(requestData, (res) => {
        if (res && res.data) {
          toastr.success(MESSAGES.SEASONAL_ISSUE_OVER_RIDE_EVENT);
          this.props.history.push({
            pathname: `/seasonalIssue`,
          });
          this.props.reset();
        }
      });
    }
  };
  removeEdition = (item) => {
    const copy = Array.from(this.state.removeEdition);
    copy.push(item);
    this.setState({ removeEdition: copy });
  };
  componentDidUpdate(prevProps) {
    const {
      issueName,
      override_publish_date,
      new_publish_date,
      members,
    } = this.props;

    if (prevProps != this.props) {
      if (this.props.loading) this.setState({ isDisabled: true });
      const member = this.props?.members;
      const lastItem = member?.[member?.length - 1];
      if (
        !this.state.isUpdate &&
        lastItem &&
        lastItem?.edition_name &&
        lastItem?.ad_deadline &&
        lastItem?.ad_deadline_time &&
        issueName &&
        override_publish_date &&
        new_publish_date
      ) {
        this.setState({ isDisabled: false });
      } else this.setState({ isDisabled: true });

      const current_data = {
        issueName: issueName,
        new_publish_date: new_publish_date,
        override_publish_date: override_publish_date,
        members: members,
      };
      if (
        this.state.isUpdate &&
        this.state.updatePrevData != JSON.stringify(current_data) &&
        lastItem?.edition_name &&
        lastItem?.ad_deadline &&
        lastItem?.ad_deadline_time &&
        issueName &&
        override_publish_date &&
        new_publish_date
      ) {
        this.setState({ isDisabled: false });
      } else if (this.state.isUpdate) {
        this.setState({ isDisabled: true });
      }
    }
  }
  render() {
    const { rowCount, allEditionList } = this.state;
    return (
      <Fragment>
        <div className="edit_seasonal_issue">
          <h4 className="my-3">
            <Link to="/seasonalIssue">
              <i className="fa fa-arrow-left mr-3" aria-hidden="true"></i>
            </Link>
            Seasonal Issues
          </h4>
          <Portlet>
            <PortletBody className="edit_seasonal_issue_body">
              <div className="row">
                <div className="col-md-3">
                  <Field
                    name={`issueName`}
                    component={renderSelectField}
                    label="Add to calendar"
                    required={true}
                    value={this.state.new_publish_date}
                    disabled={
                      this.props?.members?.length > 1 && !this.state.isUpdate
                    }
                    options={this.state.allIssues}
                    optionValue={"value"}
                    optionLabel={"label"}
                    isSelect={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="field_grp">
                    <Field
                      name="override_publish_date"
                      component={renderDatePicker}
                      label="Override publish date"
                      required={true}
                      minDate={new Date()}
                      showTime={false}
                      disabled={
                        this.props?.members?.length > 1 && !this.state.isUpdate
                      }
                      onChange={(e) => this.getAllEditions(e)}
                    />
                    <AiOutlineCalendar color="#4c4c4f" size="16" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="field_grp">
                    <Field
                      name="new_publish_date"
                      component={renderDatePicker}
                      label="New publish date"
                      required={true}
                      showTime={false}
                      disabled={
                        this.props?.members?.length > 1 && !this.state.isUpdate
                      }
                      // extraProps={{
                      //   excludeDates: [
                      //     new Date(this.props.override_publish_date),
                      //   ],
                      // }}
                      minDate={new Date()}
                    />
                    <AiOutlineCalendar color="#4c4c4f" size="16" />
                  </div>
                </div>
              </div>
              <FieldArray
                name="members"
                component={EditionDetailsSection}
                allEditionList={this.state.allEditionList}
                addEditionRow={this.addEditionRow}
                value={allEditionList}
                setEdition={this.setEdition}
                isUpdate={this.state.isUpdate}
                removeEdition={this.removeEdition}
              />
              <div className="row footer-row mt-3">
                <div className="col-12">
                  <Button
                    variant="dark"
                    onClick={() => this.handleSubmit()}
                    disabled={this.state.isDisabled}
                  >
                    {this.state.isUpdate ? "Update" : "Save"}
                  </Button>
                </div>
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </Fragment>
    );
  }
}
const selector = formValueSelector("EditSeasonalIssues");
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    issueName: selector(store, "issueName"),
    new_publish_date: selector(store, "new_publish_date"),
    override_publish_date: selector(store, "override_publish_date"),
    members: selector(store, "members"),
    loading,
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    SeasonalIssuesDuck.actions
  )(
    reduxForm({
      form: "EditSeasonalIssues",
      // destroyOnUnmount: false,
    })(EditSeasonalIssues)
  )
);
