import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import { MESSAGES } from '../../../../config/message';
import '../ad-management/AdManagement.scss';
import ConformModel from '../ad-management/ConfirmModel';
import { renderText, renderSelectField } from '../../../../common/layout/FormInput';
import { langs } from '../../../../config/localization';
import { required, maxLength50, gminLength } from '../../../../config/validation';
import { toastr } from 'react-redux-toastr';
import { checkPermission } from '../../../../common/CheckPermissions';
import { STATUS_CODES } from '../../../../common/StatusCode'

class DistributionAreas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmModal: false,
            isUpdateModel: false,
            message: '',
            adTypeFlag: false,
            colorFlag: false,
            showMore: false,
            selectedState: undefined,
            key: ''
        };
    }

    /** 
     * @method componentDidMount
     * @description called before rendering the component
     */
    componentDidMount() {
        this.props.getAllUniversalStates()
        this.props.getAllDistributionAreas()
    }

    /** 
     * @method cancelDelete
     * @description cancelDelete bought out part
     */
    cancelDelete = () => {
        this.setState({ showConfirmModal: false });
    };

    /**
    * @method confirmDelete
    * @description confirm delete bought out part
    */
    confirmDelete = () => {
        const deletedItem = this.state.deletedItem;
        const { user_detail } = this.props.loggedInUser;
        let reqData = {
            id: deletedItem.id,
            user_id: user_detail.id,
            advertisement_id: deletedItem.id,
            area: encodeURIComponent(deletedItem.area),
            state: encodeURIComponent(deletedItem['us_states.state_name'])
        }
        this.props.deleteArea(langs.keyname.distribution_area_generic, reqData, (res) => {
            if (res.status === STATUS_CODES.NO_CONTENT) {
                this.props.getAllDistributionAreas()
                toastr.success(MESSAGES.DELETE_AREA_SUCCESS)
                this.setState({
                    showConfirmModal: false,
                });
            }
        })
    }

    /**
    * @method onSubmit
    * @description called api to state and area
    */
    onSubmit = (values) => {
        values.state = this.state.selectedState;
        this.props.addDistributionArea(langs.keyname.distribution_area_generic, values, (res) => {
            if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
                this.props.getAllDistributionAreas()
                toastr.success(MESSAGES.ADD_AREA_SUCCESS)
                document.activeElement.blur();
                this.props.dispatch(change('DistributionAreas', 'state', ''));
                this.props.dispatch(change('DistributionAreas', 'area', ''));
                this.props.dispatch(untouch('DistributionAreas', 'state'));
                this.props.dispatch(untouch('DistributionAreas', 'area'));
            }
            if (res.status === STATUS_CODES.CREATED) {
                values.state = '';
                values.area = '';
            }
        })
    }

    /**
     * @method renderCardColorItem
     * @description render the color card item
     */
    renderCardItem = (deletePermission) => {
        const { distribution_area_List } = this.props;
        if (distribution_area_List && distribution_area_List !== undefined) {
            return distribution_area_List.map((el, index) => {
                if (!this.state.showMore ? index < 5 : distribution_area_List.length) {
                    return (
                        <Card key={el.id} bg='light' style={{ width: 'auto', height: 'auto', marginRight: 10, marginBottom: 10 }} >
                            <Card.Body>
                                <Card.Title><label>State:&nbsp;{el['us_states.state_name']}</label>
                                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    {deletePermission && <i className='fa fa-close crossIcon' style={{ width: 'auto', height: 'auto', }} onClick={() =>
                                        this.setState({ showConfirmModal: true, deletedItem: el, message: MESSAGES.DISTRIBUTION_AREA, key: 'state/area', count: el.newspaperCount })
                                    }></i>}
                                    <br />
                                    <label>City:&nbsp;{el.area}</label>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    )
                }
            })
        }
    };


    /**
     * @method render
     * @description render the component
     */
    render() {
        const { handleSubmit, distribution_area_List, universalStateList } = this.props;
        const { showMore, showConfirmModal, message, key, count, showInput } = this.state;
        const viewPermission = checkPermission(langs.permissionKeys.distributionAreas, langs.permissionKeys.view)
        const createPermission = checkPermission(langs.permissionKeys.distributionAreas, langs.permissionKeys.create)
        const deletePermission = checkPermission(langs.permissionKeys.distributionAreas, langs.permissionKeys.delete)

        if (!viewPermission && !createPermission && !deletePermission) {
            return <div></div>
        }
        return (
            <div className="row">
                <div className='col-md-12'>
                    <Portlet fluidHeight={true}>
                        <PortletBody >
                            {(viewPermission || createPermission) && <Form.Row>
                                <Form.Group as={Col} md='4' controlId='validationCustom01'>
                                    <h5
                                        style={{ color: '#5d5757', marginBottom: '100' }}
                                    >
                                        Number of Areas <b>({distribution_area_List && distribution_area_List.length ? distribution_area_List.length : 0})</b>
                                    </h5>
                                </Form.Group>
                            </Form.Row>}
                            <div className='cardWrapper'>
                                {viewPermission ? this.renderCardItem(deletePermission) :  <div className='text-center' style={{ marginTop: '10px' }}>
                                    <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                                </div>}
                            </div>
                            {viewPermission && ((distribution_area_List && distribution_area_List.length > 5)) && (
                                <div onClick={() => this.setState({ showMore: !showMore })}
                                    style={{ width: '100%', height: 35, backgroundColor: '#ccc', cursor: 'pointer' }}
                                    className='showMorebtn'
                                >
                                    <h5 style={{ textAlign: 'center', marginTop: 8 }}>{!showMore ? 'Show More' : 'Show Less'}</h5>
                                </div>
                            )}
                            <form
                                noValidate
                                className='form'
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                {showInput &&
                                    <Row>
                                        <Col md='5'>
                                            <Field
                                                name="state"
                                                options={universalStateList}
                                                component={renderSelectField}
                                                placeholder="Enter Country"
                                                validate={[required, maxLength50]}
                                                optionValue={"id"}
                                                optionLabel={"state_name"}
                                                isSelect={false}
                                                onChange={(e) => {
                                                    this.setState({ selectedState: e.target.value })
                                                }}
                                            />
                                        </Col>
                                        <Col md='5'>
                                            <Field
                                                name='area'
                                                validate={[required, gminLength, maxLength50]}
                                                placeholder='Enter City / Area'
                                                component={renderText}
                                            />
                                        </Col>
                                        <Col md='2'>
                                            <Button
                                                style={{
                                                    background: 'black',
                                                    border: 'none',
                                                    width: 100,
                                                    marginTop: 19
                                                }}
                                                size='md'
                                                type='submit'
                                            >
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </form>
                            <div
                                className='ml-2'
                                style={{
                                    borderBottom: '1px solid #ccc', marginTop: 25,
                                    marginLeft: 2
                                }}
                            >
                                {createPermission && <label style={{ color: 'black', marginTop: 25, cursor: 'pointer' }} onClick={() => this.setState({ showInput: !showInput })} data-toggle='collapse'><i className='fa fa-plus' aria-hidden='true'></i>&nbsp;&nbsp;Add Area</label>}
                            </div>
                        </PortletBody>
                    </Portlet>
                    <ConformModel
                        isOpen={showConfirmModal}
                        onCancelClick={this.cancelDelete}
                        onOkClick={this.confirmDelete}
                        message={message}
                        key={key}
                        totalCount={count}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    const { auth, authToken, commonDuck } = store;
    return ({
        loggedInUser: auth.user,
        authToken: authToken,
        universalStateList: Array.isArray(commonDuck.stateList) ? commonDuck.stateList : [],
        distribution_area_List: Array.isArray(commonDuck.areaList) ? commonDuck.areaList : [],
    })
};

export default injectIntl(
    connect(
        mapStateToProps,
        { ...commonDuck.actions, ...settingsDuck.actions }
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'DistributionAreas'
        })(DistributionAreas)
    )
);
