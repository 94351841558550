import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import AdTypeListing from "./AdTypeListing";
import FilterRecords from "../FilterRecords";
import { SALES_TREND, DATE_RANGE } from "../../../../../config/constant";
import AdTypeLineChart from "./AdTypeLineChart";
import PieChart from "./PIeChart";
import AdTypeRecords from "../adtype-reports/AdTypeRecords";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import CountFigureBox from "../../../../../common/CountFigureBox";

class AdTypeReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      salesTrend: SALES_TREND,
      type: false,
      duration: DATE_RANGE,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getadTypeReport(4, 1, "", "");
  }

  /**
   * @method getadTypeReport
   * @description  used to get adtype report data
   */
  getadTypeReport = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      type: 1,
      ad_type: "",
      sales: "",
    };
    this.props.getAdTypeRecord(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
  };

  /**
   * @method renderHeaderCard
   * @description  render header details
   */
  renderHeaderCard = (lable, value) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        <h4 className="heading">{value ? `${value}` : 0}</h4>
      </CountFigureBox>
    );
  };

  /**
   * @method renderHeaderCard
   * @description  render header details
   */
  renderNoRecordFoundCard = () => {
    return (
      <CountFigureBox>
        <h5 className="heading">{"No Record Found"}</h5>
      </CountFigureBox>
    );
  };

  /**
   * @method renderRevenueCard
   * @description  render revenue details
   */
  renderRevenueCard = (lable, value, total) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable} Revenue generated by Ad Type</h5>
        <h6>(till now)</h6>
        <div className="row w-100">
          <h6 className="heading col-md-6 text-center">
            {value ? `${value}` : "No data found"}
          </h6>
          <h6 className="col-md-6 text-center">
            {total ? `$ ${total}` : "$0"}
          </h6>
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { openModal, headerData } = this.state;
    let totalAdPlan = "";
    let mostPopularAdType = "";
    let leastPopularAdType = "";
    let heigestRevenue = "";
    let lowestRevenue = "";
    let heigestPlan = "";
    let lowestPlan = "";
    if (headerData && headerData !== undefined) {
      totalAdPlan =
        Array.isArray(headerData.adTypeReport) &&
        headerData.adTypeReport.length !== 0 &&
        headerData.adTypeReport[0].total_ad_plans;
      mostPopularAdType =
        headerData.popularAdType && headerData.popularAdType.mostPopular;
      leastPopularAdType =
        headerData.popularAdType && headerData.popularAdType.leastPopular;
      heigestRevenue =
        headerData.revenue &&
        headerData.revenue.highestRevenue &&
        headerData.revenue.highestRevenue.sales_revenue;
      lowestRevenue =
        headerData.revenue &&
        headerData.revenue.lowestRevenue &&
        headerData.revenue.lowestRevenue.sales_revenue;
      heigestPlan =
        headerData.revenue &&
        headerData.revenue.highestRevenue &&
        headerData.revenue.highestRevenue.plan_name;
      lowestPlan =
        headerData.revenue &&
        headerData.revenue.lowestRevenue &&
        headerData.revenue.lowestRevenue.plan_name;
    }
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Reports (Ad type)</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row">
          <div className="col-md-12">
            {/* {loading && <Loader/>} */}
            <Portlet fluidHeight={true}>
              <PortletBody>
                <div className="row my-3">
                  <div className="cardWrapper col-md-4">
                    {this.renderHeaderCard(
                      "Total No. of ad Type Plan",
                      totalAdPlan
                    )}
                  </div>
                  <div className="cardWrapper col-md-4 ">
                    {this.renderHeaderCard(
                      "Most Popular Ad Type",
                      mostPopularAdType
                    )}
                  </div>

                  <div className="cardWrapper col-md-4 ">
                    {this.renderHeaderCard(
                      "Least Popular Ad Type",
                      leastPopularAdType
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="cardWrapper col-md-6">
                    {this.renderRevenueCard(
                      "Highest",
                      heigestPlan,
                      heigestRevenue
                    )}
                  </div>
                  <div className="cardWrapper col-md-6">
                    {this.renderRevenueCard(
                      "Lowest",
                      lowestPlan,
                      lowestRevenue
                    )}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-6">
                    <AdTypeRecords />
                  </div>
                  <div className="col-md-6">
                    <PieChart />
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{ borderBottom: "1px solid grey" }}
                ></div>
                <div style={{ marginTop: 50 }}>
                  <h5>Ad Type Trend</h5>
                </div>
                <AdTypeLineChart />
                <div style={{ borderBottom: "1px solid grey" }}></div>
                <AdTypeListing />
              </PortletBody>
            </Portlet>
          </div>
        </div>
        {openModal && (
          <FilterRecords
            isOpen={openModal}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeReports",
    })(AdTypeReports)
  )
);
