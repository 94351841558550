import React, { Component } from "react";
import { connect } from "react-redux";
import * as adminManagementDuck from "../../../../store/ducks/super-admin/AdminManagement.duck";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import StandardTable from "../../StandardTable";
import { Button } from "@material-ui/core";
import { Image } from "react-bootstrap";
import { MESSAGES } from "../../../../config/message";
import { langs } from "../../../../config/localization";
import { checkPermission } from "../../../../common/CheckPermissions";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { PAGES_25 } from "../../../../config/constant";
import { GrantIconButton } from "../../../../common/Buttons";

class ArchiveListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditFlag: true,
      isRedirect: false,
      rows: [],
      renderNewAdminForm: false,
      adminList: [],
      page: 1,
      pageSize: PAGES_25,
      showSearchResults: false,
      searchKey: "",
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getArchievesListing(this.state.pageSize, 1);
  }

  getArchievesListing = (row, page) => {
    let requestData = {
      id: 0,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      isDeleted: 1,
    };
    this.props.getAllAdminArchieveList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ adminList: res.data.rows });
      }
    });
  };

  /**
   * @method activateAdmin
   * @description Activate Admin from Archive Listing and put It into  All admin List
   */
  activateAdmin = (adminDetails) => {
    const { authToken } = this.props.loggedInUser;
    const { pageSize, showSearchResults, searchKey } = this.state;

    let requestData = {
      id: adminDetails.id,
      active: true,
      is_deleted: false,
    };
    this.props.activateAdminById(requestData, authToken, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ACTIVE_ADMIN_SUCCESS);
        this.getArchievesListing(pageSize, 1);
        if (showSearchResults) {
          let requestData = {
            searchKey: searchKey,
            is_deleted: 1,
          };
          this.props.searchInUserList(requestData);
        }
      }
    });
  };

  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getArchievesListing(this.state.pageSize, page + 1);
  };

  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getArchievesListing(pageSize, this.state.page);
    });
  };

  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
    } else if (e.target.value.length >= 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 1,
      };
      this.props.searchInUserList(requestData);
    }
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = () => {
    const createPer = checkPermission(
      langs.permissionKeys.admin,
      langs.permissionKeys.create
    );
    const columnOrder = [
      {
        Header: "Name",
        Cell: (row) => {
          const { original } = row;
          const firstName =
            original &&
            original !== undefined &&
            original.first_name[0].toUpperCase() + original.first_name.slice(1);
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                className="mr-2 ml-2"
                style={{ borderRadius: "100%", height: 30, width: 35 }}
              />
              <font>{`${firstName} ${original.last_name}`}</font>
            </div>
          ); // Custom cell components!)
        },
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: (row) => {
          const { original } = row;
          let roleString =
            original && original.role !== null ? original.role.name : "";
          return <span>{roleString}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email", // String-based value accessors!
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          return <span style={{ color: "red" }}>De-active</span>;
        },
      },
      {
        Header: (props) => <span>Actions</span>, // Custom header components!
        accessor: "friend.age",
        Cell: (row) => (
          <div>
            {createPer && (
              <GrantIconButton
                title="Activate"
                onClick={() => this.activateAdmin(row.original)}
              />
            )}
          </div>
        ),
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { loading, searchResults, archiveAdminList } = this.props;
    const { showSearchResults, totalCount } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.admin,
      langs.permissionKeys.view
    );

    let results;
    if (searchResults && searchResults.length && showSearchResults === true) {
      results = this.props.searchResults;
    } else if (searchResults === undefined && showSearchResults === true) {
      results = [];
    } else if (
      searchResults !== undefined &&
      searchResults.length === 0 &&
      showSearchResults === true
    ) {
      results = [];
    } else {
      results = archiveAdminList;
    }

    return (
      <div>
        {loading && <Loader />}
        {viewPer ? (
          <StandardTable
            handelSearch={this.handleSearch}
            columnOrder={this.renderRecords()}
            totalCount={totalCount}
            allData={this.state.archiveAdminList}
            data={results}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          />
        ) : (
          <div className="text-center" style={{ marginTop: "10px" }}>
            <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
          </div>
        )}
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, adminManagementDuck, authToken } = store;
  const { loading } = store.commonDuck;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    loading,
    archiveAdminList: adminManagementDuck.archiveAdminList,
    searchResults: adminManagementDuck.searchResultsforArchieveAdmin,
  };
};

export default injectIntl(
  connect(mapStateToProps, adminManagementDuck.actions)(ArchiveListing)
);
