import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import C3Chart from 'react-c3js';
import '../../../common/common.scss';
import FilterRevenue from './admin-dashboard/FilterRevenue'
import * as NpcDashboardRecord from '../../../store/ducks/npc/Dashboard.duck';
import { STATUS_CODES } from '../../../common/StatusCode';
import { convertISOToUtcDateformate2 } from '../../../common';
import BlankChart from '../../../common/BlankChart'
import { getCurrentMonth } from '../../../common/CommonFunction';
import { setDateFilterKeys } from '../../../common/CommonFilterFunction'
import { MESSAGES } from '../../../config/message';

let dateRange = [];
let label = getCurrentMonth()
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRevenue: ['Total'],
      dateAxis: [],
      openModal: false,
      selectedOption: {
        option: 0,
        startDate: '',
        endDate: ''
      }
    };
  }

  /**
  * @method componentDidMount
  * @description  called after rendering the component 
  */
  componentDidMount() {
    this.getDashboardRecord();
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record
   */
  getDashboardRecord = () => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      type: 5,
      npc_id: user_detail.newspaper.id
    }

    this.setData(requestData)
  }

  /**
   * @method setData
   * @description  set request data
   */
  setData = (requestData) => {
    this.props.getTypeData(requestData, 5, res => {
      if (res.status === STATUS_CODES.OK) {
        const { totalRevenue } = this.state
        let temp = totalRevenue;
        const data = res.data && Array.isArray(res.data.graphData) ? res.data.graphData : []
        data && data.length && data.map((item, i) => {
          temp.push(item.sales_amount)
          dateRange.push(convertISOToUtcDateformate2(item.createdAt))
        })
        let permittedValues = data && data.length && data.map(value => (convertISOToUtcDateformate2(value.createdAt)));
        this.setState({ totalRevenue: [...temp], dateAxis: permittedValues, openModal: false })
      }
    })
  }

  /**
   * @method FilterRecords
   * @description  filter records
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props)
  }

  /**
   * @method applyFilter
   * @description apply filter
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label)
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION)
    }
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      type: 5,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
      npc_id: user_detail.newspaper.id
    }
    label = data.label
    this.setState({
      totalRevenue: ['Total'],
      openModal: false,
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to
      }
    }, () => this.setData(requestData))
  }

  /**
   * @method render
   * @description render component
   */
  render() {
    const { totalRevenue, dateAxis, openModal, selectedOption } = this.state
    return (
      <div>
        <h5
          className='cursorPointer mt-4 mt-md-auto text-right'
          onClick={() => this.setState({ openModal: true })}
        >
          {label}
          &nbsp;&nbsp;
          <i
            style={{ fontSize: 20 }}
            className='fa fa-angle-down'
            aria-hidden='true'
          ></i>
        </h5>
        {totalRevenue.length > 1 ? <C3Chart
          type='line'
          data={{
            columns: [
              totalRevenue
            ],
          }}
          axis={{
            x: {
              type: 'category',
              show: true,
              categories: dateAxis,
              text: 'Duration',
            },
            y: {
              label: {
                text: 'Sales Revenue',
                position: 'outer-middle'
              }
            },
          }}
          unloadBeforeLoad={true}
        /> : <div style={{ marginTop: 30 }} className='row w-100'><BlankChart /></div>}
        {openModal && <FilterRevenue
          isOpen={openModal}
          applyFilter={this.FilterRecords}
          selectedOption={selectedOption}
          onCancel={() => this.setState({ openModal: false })}
        />}
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { loading } = store.commonDuck;
  const { npcModule4, npcModule5 } = store.npcDashboardRecord
  const { auth } = store;
  return ({
    loggedInUser: auth.user,
    loading,
    authToken: store.auth.authToken,
    npcModule4,
    npcModule5,
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    NpcDashboardRecord.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'Chart',
      destroyOnUnmount: false
    })(Chart)
  )
);

