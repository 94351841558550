import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { MESSAGES } from "../../../../config/message";
import { Button } from "@material-ui/core";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import StandardTable from "../../StandardTable";
import ConformModel from "../ConformModel";
import { checkPermission } from "../../../../common/CheckPermissions";
import { langs } from "../../../../config/localization";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { convertISOToUtcDateformate1 } from "../../../../common";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../partials/content/Portlet";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { ViewIconLink } from "../../../../common/Buttons";

class ViewOrderList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      isOpenModal1: false,
      showPaymentModal: false,
      showConfirmModal: false,
      companyId: "",
      userId: "",
      showSearchResults: false,
      newspaperId: undefined,
      message: "",
      advanceSerchResults: false,
      allList: [],
      searchKey: "",
      sortFlag: {
        id: { sort: false },
        newspaper_name: { sort: false },
        first_name: { sort: false },
        order_date: { sort: false },
        status: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getOrderList(this.state.pageSize, 1, "", "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderList = (row, page, sort, column, searchKey) => {
    let requestData = {
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      searchKey: searchKey,
      status: "",
      customer_id: this.props.match.params.id,
    };
    this.props.getAllOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          orderList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getOrderList(this.state.pageSize, page + 1, "", "", "");
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getOrderList(pageSize, this.state.page, "", "", "");
    });
  };
  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    if (e.target.value.length > 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let searchKey = encodeURIComponent(
        e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      );
      this.getOrderList(this.state.pageSize, 1, false, false, searchKey);
    } else {
      this.getOrderList(this.state.pageSize, 1, false, false, "");
    }
  };

  /**
   * @method renderRecords
   * @description render all records
   */
  renderRecords = () => {
    const detailViewPermission = checkPermission(
      langs.permissionKeys.orderDetails,
      langs.permissionKeys.view
    );
    const columnOrder = [
      {
        Header: "Order #",
        sorted: { id: "id", desc: true },
        accessor: "id",
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          const adType = newspaper_ads_plan.advertisements[0].ad_type;
          let url =
            adType === langs.adType.Display
              ? `/viewOrderDetail/${row.original.id}`
              : `/classifiedOrderDetail/${row.original.id}`;

          let str = original.id;
          let StringToShow =
            str.length > 6 ? `${str.substring(1, 6) + "....."}` : str;
          return (
            <div>
              {detailViewPermission ? (
                <Link
                  to={{
                    pathname: url,
                    state: {
                      customerDetail: original.user,
                    },
                  }}
                >
                  {StringToShow}
                </Link>
              ) : (
                <font>{StringToShow}</font>
              )}
            </div>
          );
        },
      },
      {
        Header: "Company Name",
        accessor: "newspaper_name",
        Cell: (row) => {
          const { original } = row;
          const imageURL =
            original && original.newspaper && original.newspaper.user.image_url
              ? original.newspaper.user.image_url
              : undefined;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  imageURL !== undefined
                    ? `${PROFILE_MEDIA_URL}/${imageURL}`
                    : require("../../../../../assets/images/defaultUser.png")
                }
                alt=""
                className="mr-2 ml-2"
                style={{
                  borderRadius: "100%",
                  height: 30,
                  width: 35,
                  marginLeft: 6,
                }}
              />
              <font className="text-truncate">
                {original.newspaper.newspaper_name}
              </font>
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "order_date", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return convertISOToUtcDateformate1(original.order_date);
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        show: detailViewPermission,
        sortable: false,
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          const adType = newspaper_ads_plan
            ? newspaper_ads_plan.advertisements[0].ad_type
            : "";
          let url = `/viewOrderDetail/${row.original.id}`;
          // : `/classifiedOrderDetail/${row.original.id}`

          return (
            <div style={{ padding: 5 }}>
              {detailViewPermission && (
                <ViewIconLink
                  to={{
                    pathname: url,
                    state: {
                      customerDetail: original.user,
                    },
                  }}
                  title="View Order"
                />
              )}
            </div>
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const { totalCount, showConfirmModal } = this.state;
    const { orderList, history } = this.props;
    const { location } = history;
    const listViewPermission = checkPermission(
      langs.permissionKeys.orderListing,
      langs.permissionKeys.view
    );
    const { state } = this.props.history.location;
    if (state === undefined) {
      return <Redirect to="/customerManagement" />;
    }
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div>
              <div className="row">
                <h3 className="kt-subheader__title col-md-6">
                  <span>
                    <Link to="/customerManagement">
                      <i
                        onClick={() => {
                          return this.props.history.push("/customerManagement");
                        }}
                        style={{ fontSize: 20 }}
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </span>
                  <span className="ml-3">Orders List</span>
                </h3>
              </div>
            </div>
          )}
        </LayoutContextConsumer>
        <Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
          />
          <PortletHeader
            toolbar={
              <PortletHeaderToolbar>
                <div className="m-4">
                  <font>Name</font>
                  <h4>
                    {location.state.customerDetail.first_name +
                      " " +
                      location.state.customerDetail.last_name}
                  </h4>
                </div>
              </PortletHeaderToolbar>
            }
          />

          <PortletBody>
            <div className="container">
              {listViewPermission ? (
                <StandardTable
                  handelSearch={this.handleSearch}
                  columnOrder={this.renderRecords()}
                  totalCount={totalCount}
                  allData={orderList}
                  data={orderList}
                  sortable={false}
                  onPageSizeChange={this.onPageSizeChange}
                  onPageChange={this.onPageChange}
                  showSearch={false}
                  history={this.props.history}
                />
              ) : (
                <div className="text-center" style={{ marginTop: "10px" }}>
                  <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                </div>
              )}
            </div>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading, orderList } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    authToken: auth.authToken,
    loading,
    orderList: Array.isArray(orderList) ? orderList : [],
    searchResults:
      store.newsPaperCompanyDuck &&
      store.newsPaperCompanyDuck.searchResultsforNewspaper,
    advanceSearchRecords:
      store.newsPaperCompanyDuck &&
      store.newsPaperCompanyDuck.advanceSearchRecords,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    commonDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "ViewOrderList",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(ViewOrderList)
  )
);
