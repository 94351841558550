/** All messages used in application */
export const MESSAGES = {
  TOKEN_EXPIRE_MESSAGE:
    "Link expired. Kindly follow the password recovery process by clicking on forgot password link and login again.",
  SESSION_EXPIRE_MESSAGE: "Your Session has been expired.Please login again.",
  SESSION_EXPIRE_LABEL: "Session Expired !!",

  NEGATIVE_VALUES: "Value should be greater than or equal to 0",

  PASSWORD_CHANGE: "Password changed successfully",
  TERMS_CONDITION: `Please accept terms and condition.`,
  UPDATE_SUCCESS: "Data updated successfully.",
  PROFILE_UPDATE_SUCCESS: "Profile has been updated successfully.",
  SESSION_EXPIRE: `Your session has been expires.`,

  ADD_ADMIN_SUCCESS: "Admin has been registered successfully.",
  UPDATE_ADMIN_SUCCESS: "Admin has been updated successfully.",
  CURRENT_PASSWORD_NOT_MATCHED: "Your current password does not matched.",

  COMPANY_SELECTION_LIMIT_5: "Only 5 company can be selected at a time",
  COMPANY_SELECTION_LIMIT_12: "Only 12 company can be selected at a time",

  ADD_CUSTOMER_SUCCESS: "Customer has been registered successfully.",
  UPDATE_CUSTOMER_SUCCESS: "Customer has been updated successfully.",
  DELETE_CUSTOMER_SUCCESS: "Customer has been deleted successfully.",

  ADD_ROLE_SUCCESS: "Role has been added successfully.",
  DELETE_ROLE_SUCCESS: "Role has been deleted successfully.",

  ADD_COMPANYCATEGORY_SUCCESS: "Company Category has been added successfully.",
  DELETE_COMPANYCATEGORY_SUCCESS: "Company Category has been deleted successfully.",

  ADD_SOCIALMEDIACATEGORY_SUCCESS: "Social Media Category has been added successfully.",
  DELETE_SOCIALMEDIACATEGORY_SUCCESS: "Social Media Category has been deleted successfully.",

  ADD_ADTYPECATEGORY_SUCCESS: "Ad Type Category has been added successfully.",
  DELETE_ADTYPECATEGORY_SUCCESS: "Ad Type Category has been deleted successfully.",

  ADD_ADPLACEMENTCATEGORY_SUCCESS: "Ad PLACEMENT Category has been added successfully.",
  DELETE_ADPLACEMENTCATEGORY_SUCCESS: "Ad PLACEMENT Category has been deleted successfully.",

  ADD_ADFEATURECATEGORY_SUCCESS: "Ad Feature Category has been added successfully.",
  DELETE_ADFEATURECATEGORY_SUCCESS: "Ad Feature Category has been deleted successfully.",

  ADD_SIZEOFADCATEGORY_SUCCESS: "Size of Ad Category has been added successfully.",
  DELETE_SIZEOFADCATEGORY_SUCCESS: "Size of Ad Category has been deleted successfully.",

  ADD_PRICERUNTIMECATEGORY_SUCCESS: "Price Run Time Category has been added successfully.",
  DELETE_PRICERUNTIMECATEGORY_SUCCESS: "Price Run Time Category has been deleted successfully.",

  ADD_AREA_SUCCESS: "Distribution Area has been added successfully.",
  DELETE_AREA_SUCCESS: "Distribution Area has been deleted successfully.",

  ADD_EDITION_SUCCESS: "Edition Area has been added successfully.",
  EMPTY_ERROR: "Fields are Empty",
  DELETE_EDITION_SUCCESS: "Edition Area has been deleted successfully.",

  ADD_BUSSINESS_HOURS_SUCCESS: "Bussiness hours has been added successfully.",
  UPDATE_BUSSINESS_HOURS_SUCCESS:
    "Bussiness hours has been updated successfully",
  TIME_FORMATE_ERROR_BUSSINESS_HOURS:
    "Please select open & close time schedule correctly.",

  ADD_LANGUAGE_SUCCESS: "Language has been added successfully.",
  DELETE_LANGUAGE_SUCCESS: "Language has been deleted successfully.",

  ADD_PRINT_FREQUENCY_SUCCESS: "Frequency has been added successfully.",
  DELETE_PRINT_FREQUENCY_SUCCESS: "Frequency has been deleted successfully.",

  ADD_CATEGORY_SUCCESS: "Category has been added successfully.",
  DELETE_CATEGORY_SUCCESS: "Category has been deleted successfully.",

  ADD_RANGE_SUCCESS: "Range of copies has been added successfully.",
  DELETE_RANGE_SUCCESS: "Range of copies has been deleted successfully.",

  ADD_REJECT_REASON_SUCCESS: "Reject reason has been added successfully.",
  DELETE_REJECT_REASON_SUCCESS: "Reject reason has been deleted successfully.",

  ACCEPT_ORDER: "Order has been accepted successfully.",
  DECLINED_ORDER: "Order has been declined successfully.",

  DATA_UPDATED: "Successfully Updated",

  COMPANY_ADD_SUCCESS: "News paper company registered successfully.",
  ADD_SUCCESS: "User registered successfully.",
  UPDATE_PASSWORD_SUCCESS: "Your password has been re-set successfully.",
  UPDATE_FAILURE: "Data not updated, please try again.",
  COMPANY_UPDATE_SUCCESS: `News paper company record updated successfully.`,
  ADD_FAILURE: "News paper company is not register, please try again.",
  DELETE_SUCCESS: "Deleted successfully.",
  DELETE_FAILURE: "User not deleted, please try again.",
  DELETE_COMPANY_SUCCESS: "News paper company deleted successfully.",
  DELETE_COMPANY_FAILURE:
    "News paper company not deleted, please try again later.",
  ACTIVE_COMPANY_SUCCESS: "News paper company activated successfully.",
  ACTIVE_COMPANY_FAILURE:
    "News paper company not activated, please try again later.",
  ACTIVE_ADMIN_SUCCESS: "User activated successfully.",
  ACTIVE_ADMIN_FAILURE: "User not activated, please try again.",
  EMAILID_EXIST: "This email id already exist, please try with another.",
  PATMENT_SUCCESS: `Payment successfully.`,
  UPDATE_PAYMENT_SUCCESS: "Payment has been updated successfully.",

  SERVER_ERROR: `Something went wrong, please try again later.`,
  REQUEST_REJECT_SUCCESS: `Request has been rejected successfully.`,
  REQUEST_ACCEPT_SUCCESS: `Request has been accepted successfully.`,
  EMAIL_SENT: "Email has been sent, please check your inbox",
  EMAIL_NOT_SENT: "Email not sent, Something went wrong.",
  EMAIL_SENT_FOR_RESET: "Reset Password Link has been sent via Email.",
  MESSAGE_SENT_SUCCESS: `Message sent successfully`,
  ADD_TYPE_ADD_SUCCESS: "Ad type successfully added.",
  DELETE_ADD_TYPE_SUCCESS: "Ad type deleted successfully.",
  ACCOUNT_ADD_SUCCESS:  "Account successfully added.",
  DELETE_ACCOUNT_TYPE_SUCCESS:  "Account deleted successfully.",

  AD_TYPE_DELETE: ` Are you sure, you want to delete ?`,
  COLOR_DELETE: `companies are using this color. On removal, the associated plan with the color will get disabled. Are you sure?`,
  PAPER_DELETE: `companies are using this Paper type. On removal, the associated plan with the Paper type will get disabled. Are you sure?`,
  ACCOUNT_DELETE: `companies are using this Account. On removal, the associated plan with the Account will get disabled. Are you sure?`,
  
  DELETE_COLOR_SUCCESS: "Color deleted successfully.",
  DELETE_PAPER_SUCCESS: "Paper type deleted successfully",
  ADD_COLOR_SUCCESS: "Color successfully added.",
  ADD_ADDTYPE_SUCCESS: "Addtype successfully added.",
  UPDATE_AD_TYPE_SUCCESS: "Ad type successfully updated.",
  CUSTOMER_ADD_SUCCESS: "Customer has been registered successfully.",
  ACTIVE_CUSTOMER_SUCCESS: "Customer has been activated successfully.",
  UPDATE_TEMPLATE_SUCCESS: "Template has been updated successfully.",
  CREATE_TEMPLATE_SUCCESS: "Template has been created successfully.",
  DELETE_TEMPLATE_SUCCESS: "Template has been deleted successfully.",
  UPDATE_Role_SUCCESS: "Role has been updated successfully.",
  CREATE_Role_SUCCESS: "Role has been created successfully.",
  DELETE_Role_SUCCESS: "Role has been deleted successfully.",

  DATE_VALIDATION: "Both From & To dates are required.",
  PUBLISH_DATE_VALIDATION: "Both From & To publish dates are required.",
  AMOUNT_VALIDATION: "Both Minimum & Maximum amount are required.",

  PRICE_VALIDATION: "Both From & To price range are required.",
  PRICE_RANGE_VALIDATION: "From price should be less than to price.",
  PLEASE_SELECT_FILTER: "Please select any one of the filter.",

  // COMPANY_ROLE_DELETE: `no. of companies is using this company role. Are you sure, you want to remove this role?`,
  //mssage change according to client feedback
  COMPANY_CUSTOM_DELETE: `Companies are using this company category. Are you sure you want to remove this company category ?`,
  COMPANY_COMPANYCATEGORY_ADD_SUCCESS: `Company Category added successfully`,

  SOCIALMEDIA_CUSTOM_DELETE: `Are you sure you want to remove this social media category ?`,

  ADTYPE_CUSTOM_DELETE: `Are you sure you want to remove this ad type category ?`,

  ADPLACEMENT_CUSTOM_DELETE: `Companies are using this ad placement category. Are you sure you want to remove this ad placement category ?`,

  ADFEATURE_CUSTOM_DELETE: `Companies are using this ad feature category. Are you sure you want to remove this ad feature category ?`,

  SIZEOFAD_CUSTOM_DELETE: `Companies are using this size of ad category. Are you sure you want to remove this size of ad category ?`,

  PRICERUNTIME_CUSTOM_DELETE: `Companies are using this price run time category. Are you sure you want to remove this price run time category ?`,

  COMPANY_ROLE_DELETE: `companies are using this company role. Are you sure you want to remove this role ?`,
  COMPANY_ROLE_ADD_SUCCESS: `Role added successfully`,



  RANGE_OF_COPIES: `companies are using this range. Are you sure, you want to remove this range   ?`,
  DELETE_RANGE_OF_COPIES: `Successfully deleted`,
  PRINT_FREQUENCY: `companies are using this frequency. Are you sure, you want to remove this frequency ?`,
  ADDED_SUCCESS: `Successfully added`,
  UPDATE_BANK_INFORMATION: `Your Bank Information updated successfully`,
  DELETED_SUCCESS: `Successfully deleted`,
  CATEGORY: `no. of templates are using this category. Are you sure, you want to remove this category ?`,
  // CATEGORY: `companies are using this category. Are you sure, you want to remove this category ?`,
  TEMPLATE_CATEGORY: `no.of templates are associated with this category. Are you sure, you want to remove this category ?`,
  DISTRIBUTION_AREA: `companies are using this state and area. Are you sure, you want to remove this state and area mapping ?`,
  LANGUAGE: `companies are using this language. Are you sure, you want to remove this language ?`,
  LANGUAGE_UPDATE: `Language has been successfully updated.`,
  LANGUAGE_ADD: `Language has been successfully added`,
  FILE_UPLOAD_SUCCESS: `File has been uploaded successfully`,
  CATEGORY_NOT_FOUND: `No category is created by super-admin yet.`,
  LANGUAGE_NOT_FOUND: `No language is created by super-admin yet.`,
  DELETE_CONFIRM: `Are you sure, you want to remove this `,
  SELECT_ADTYPE: "Please select Ad Type",
  SELECT_IMAGE: `Please select atleast one image`,
  CHANGES_LOST: `Changes made will be lost.`,
  IMAGE_SELECTION: `Please select atleast one image`,
  PERMISSION_ACCESS: `Oops! You do not have permission to view this listing. Please contact your Admin.`,
  PERMISSION_ACCESS_TEMPLATE_LISTING: `Oop's you dosenot have a permission to view this Template Listing .`,
  EMAIL_TEMPLATE_UPDATE: `Email template has been updated successfully`,
  EMAIL_ID_ADDED: `Email is sent successfully`,
  ADD_EMAIL: `Please add email id.`,
  KEYS_NOT_MATCH: `Keys are mentioned above are not match, please do not update above unique keys.`,
  ACTIVE_SUCCESS: "Activated successfully",
  ACTIVE_FAILURE: "Activation failed, please try again",
  CREATE_SUCCESS: "Added successfully",
  CREATE_FAIL: "Faild to create, please try again",
  UPDATE_FAIL: "Update failed, please try again",
  EMPTY_NOTIFICATION: `No records found`,
  IMAGE_MAX_LENGTH: `You have not permit to upload more than 10 images`,
  IMAGE_UPLOADED_SUCCESS: `Profile has been updated successfully.`,
  CUSTOMER_ACCESS_REQUEST: `Customer have been requested sucessfully`,
  CUSTOMER_REQUEST_REJECT: `Customer request has been rejected successfully`,
  CUSTOMER_REQUEST_ACCEPT: `Customer request has been accepted successfully`,
  DISCOUNT_ADD_SUCCESS: `Customer discount added successfully`,
  SELECT_DAYS: "Please select working day hours.",
  REQUEST_DELETE_CONFIRM: `Are you sure you want to delete this customer request?`,
  REQUEST_ALREADY_ADDED: `You have already added the request for `,
  EMPTY_FIELD_CHECK: `Field values can't be empty.`,
  DISCOUNT_VALIDATION: `Please add discount before submitted the form.`,
  PERCENTAGE_RANGE: `% range should be 1 to 100.`,
  SESSION_EXPIRE_VALIDATION: `Your Session has been expired.Please login again.`,

  // Valid file type message

  VALID_TYPE: `Please submit valid file type`,
  FILE_SIZE_VALIDATION: `File size should be less then 5 MB`,
  FILE_TYPE_VALIDATION: "Only JPEG and PNG, PDF files are accepted.",
  VALID_FILE: `Please submit a valid file`,

  CUSTOMER_ALREADY_EXIST: "Customer id already exits",

  REPORT_PERMISSION_ACCESS: `Oops! You do not have permission to view this report. Please contact your Admin.`,
  NO_RECORD_FOUND: "No Record Found.",
  TIME_MICMATCH: `from time should be less than to time`,

  ACTION_CREATE_SUCCESS: "Action created successfully",
  ACTION_UPDATE_SUCCESS: "Action updated successfully",
  // plan
  PLAN_CREATE_SUCCESS: "Plan created successfully",
  PLAN_UPDATE_SUCCESS: "Plan updated successfully",
  PLAN_DELETE_SUCCESS: "Plan deleted successfully",
  PLAN_ACTIVE_SUCCESS: "Plan activated successfully",
  SECONDARY_EMAIL_FAIL: "Email could not be added",
  SECONDARY_EMAIL_SUCCESS: "Email added successfully",

  // seasonal issue
  ADD_SEASONAL_ISSUE: "Seasonal issues added successfully",
  UPDATE_SEASONAL_ISSUE: "Seasonal issues updated successfully",
  DELETE_SEASONAL_ISSUE: "Seasonal issues deleted successfully",
  DELETE_INACTIVE_ISSUE_DATE: "Inactive issue date deleted successfully",
  MESSAGE_DELETE_SEASONAL_ISSUE: "Are you sure, you want to remove this seasonal issue?",
  STATUS_UPDATE_SEASONAL_ISSUE: "Seasonal issue status update successfully",
  SEASONAL_ISSUE_OVER_RIDE_EVENT: "Override event update successfully",
  INACTIVE_ISSUE_DATE_ADDED: "Inactive issue date added successfully",
  MESSAGE_DELETE_INACTIVE_ISSUE_DATE: "Are you sure, you want to remove this inactive issue date?",
  UPDATE_INACTIVE_ISSUE_DATE: "Inactive issue date updated successfully",
  MESSAGE_DELETE_SEASONAL_ISSUE_DATE: "Are you sure, you want to remove this seasonal issue?",
  ALL_FIELDS_ARE_REQUIRED:"All fields are required."
};
