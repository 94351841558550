import React from 'react';
import Notice from '../../../../partials/content/Notice';
import * as NewsPaperCompanyDuck from '../../../../store/ducks/super-admin/NewsPaperCompany.duck';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ViewCompanyBody from './ViewCompanyBody';
import { convertISOToUtcDateformate1 } from '../../../../common';
import { Button } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { requestForResetPassword } from '../../../../crud/auth.crud';
import { MESSAGES } from '../../../../config/message';
import { toastr } from 'react-redux-toastr';
import '../../Modal.scss';
import { Switch } from '@material-ui/core';
import { Portlet } from '../../../../partials/content/Portlet';
import AddCompany from './AddCompany';
import { PROFILE_MEDIA_URL } from '../../../../config';
import { checkPermission } from '../../../../common/CheckPermissions';
import { langs } from '../../../../config/localization';
import { Loader } from '../../../../common/Loader';
import { STATUS_CODES } from '../../../../common/StatusCode'
import { NoContentFound } from '../../../../common/NoContentFound'
import { FILE_SIZE } from '../../../../config';

class ViewCompany extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEditFlag: false,
      companyId: '',
      userId: '',
      isOpenModal: false,
      loading: false,
      noContentFound: false,
      isRedirect: false,
      file: [],
      overSizedImage: false,
      invalidImage: false
    };
  }

  /**
   * @method componentDidMount
   * @description render after mounting the component
   */
  componentDidMount() {
    this.getCompanyDetail()
  }

  /**
   * @method getCompanyDetail
   * @description get all company records
   */
  getCompanyDetail = () => {
    const { id } = this.props.match.params;
    const requestData = {
      company_id: id
    };
    this.props.getCompanyById(requestData, (res) => {
      if (res.status !== STATUS_CODES.OK) {
        this.setState({ noContentFound: true })
      }
    });
  }

  /**
   * @method openModel
   * @description  used to open filter form
   */
  openModelForEdit = (companyId, userId) => {
    this.setState({
      isOpenModal: true,
      isEditFlag: true,
      companyId: this.props.selectedCompany.id,
      userId: userId,
      showConfirmModal: false
    });
    //console.warn("selectedCompany", this.props.selectedCompany);
  };

  /**
   * @method phoneNumberFormate
   * @description used formate phone number
   */
  phoneNumberFormate = number => {
    if (number && number !== undefined) {
      let match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
      return match[1] + '-' + match[2] + '-' + match[3];
    }
  };

  /**
   * @method renderArea
   * @description used to render area
   */
  renderArea = distribution_areas => {
    let areaString = [];
    Array.isArray(distribution_areas) &&
      distribution_areas.map(el => {
        areaString.push(el.area)
      });
    return areaString.join(' , ');
  };

  renderCompanyCategory = companyCategory => {
    let areaString = [];
    Array.isArray(companyCategory) &&
    companyCategory.map(el => {
        areaString.push(el.name)
      });
    return areaString.join(' , ');
  };

  renderSecondaryEmails = emails => {
    let areaString = [];
    Array.isArray(emails) &&
    emails.map(el => {
        areaString.push(el.email)
      });
    return areaString.join(' , ');
  };

  /**
  * @method activateAdmin
  * @description Activate Admin from Archive Listing and put It into  All admin List
  */
  activateCompany = (companyDetails) => {
    let requestData = {
      user_id: companyDetails.user_id,
      id: companyDetails.id,
    }
    this.props.activateCompanyById(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ACTIVE_COMPANY_SUCCESS)
        this.setState({ isRedirect: true })
      }
    })
  }

  /**
   * @method onCancel
   * @description  used to cancel filter form
   */
  onCancel = () => {
    this.setState({ isOpenModal: false });
  };

  /**
  * @method ImageChangedHandler
  * @description called for handling image
  */
  ImageChangedHandler = e => {
    const { selectedCompany } = this.props;
    e.preventDefault();
    let me = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    this.setState({ file: file, isEditCompanyFlag: true });
    let fileType = '';
    let size = '';
    let validType = '';
    if (file && file !== 'undefined') {
      fileType = file.type;
      size = file.size;
      validType =
        fileType === 'image/png' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg';
    }
    if (size <= FILE_SIZE && validType) {
      reader.onloadend = () => {
        this.setState(
          {
            file: file,
            imagePreviewUrl: reader.result,
            overSizedImage: false,
            invalidImage: false
          },
          () => {
            this.getBase64(file, function (base64Data) {
              const requestData = {
                id: selectedCompany.id,
                user_id: selectedCompany.user_id,
                role_id: selectedCompany.user.role_id,
                email: selectedCompany.user.email,
                status: selectedCompany.status,
                active: selectedCompany.active,
                image_url: base64Data
              }
              me.setState({ base64Data });
              me.props.updateComapnyProfile(requestData, '', res => {
                if (res.status === STATUS_CODES.CREATED) {
                  toastr.success(MESSAGES.IMAGE_UPLOADED_SUCCESS);
                  me.getCompanyDetail();
                }
              })
            });
          }
        );
      };
      reader.readAsDataURL(file);
    } else if (file && size >= FILE_SIZE && validType) {
      this.setState({
        overSizedImage: true,
        invalidImage: false,
      });
    } else if (file && !validType) {
      this.setState({
        invalidImage: true,
        overSizedImage: false,
      });
    } else {
      this.setState({
        invalidImage: false,
        overSizedImage: false,
      });
    }
  };

  /**
   * @method getBase64
   * @description convert in base 64 formate
   */
  getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
  };


  /**
   * @method renderHeader
   * @description  used to render header
   */
  renderHeader = () => {
    const { selectedCompany } = this.props;
    const { isOpenModal, isEditFlag, isRedirect, overSizedImage, invalidImage } = this.state;
    const createPermission = checkPermission(
      langs.permissionKeys.newspaperCompany,
      langs.permissionKeys.create
    );

    if (isRedirect) {
      return (
        <Redirect push
          to={{
            pathname: '/newspaperManagement'
          }}
        />
      );
    }
    return (
      <div className='row'>
        <div className='col-md-3 flex-center'>
          <div
            className='flex-center flex-column w-100'
            style={{ overflow: 'hidden' }}
          >
            <img
              src={
                selectedCompany.user.image_url
                  ? `${PROFILE_MEDIA_URL}/${selectedCompany.user.image_url}`
                  : require('../../../../../assets/images/defaultUser.png')
              }
              alt=''
              className='content-header-profile-image'
            />
            <input
              type='file'
              id='fileinput'
              onChange={this.ImageChangedHandler}
              style={{ display: 'none' }}
            />
            {!selectedCompany.is_deleted && <label
              className='col-md-4'
              htmlFor='fileinput'
              style={{ left: '-80px' }}
            >
              <span style={{ width: 200, left: '10rem', top: '-16px', marginRight: '26px', cursor: 'pointer' }} className='fa fa-camera  col-md-6' ></span>
            </label>}
            {overSizedImage && (
              <div className='text-help '>
                {'File size should be less then 5 MB'}
              </div>
            )}
            {invalidImage && (
              <div className='text-help'>
                {'Only JPEG and PNG files are accepted.'}
              </div>
            )}
            {/* {!selectedCompany.is_deleted && 'Upload Cover Photo'} */}
            <h5
              title={selectedCompany.newspaper_name}
              className='w-100 content-header-profile-image-caption py-1 text-center text-truncate'>
              {selectedCompany.newspaper_name}
            </h5>
            <p>Member Since : {convertISOToUtcDateformate1(selectedCompany.createdAt)}</p>
          </div>
        </div>
        <div className='col-md-9 border-left'>
          <div
            className='my-2 flex-center justify-content-between'
            style={{ position: 'relative' }}
          >
            <h5 className='my-auto text-left'>Company Information</h5>

            {createPermission && <div>
              {!selectedCompany.is_deleted ?
                <Button
                  className='btn-circle btn-sm'
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 100,
                    height: 30,
                    minWidth: 60,
                    display: 'inline-block',
                    right: '0'
                  }}
                  onClick={() =>
                    this.openModelForEdit(
                      selectedCompany.id,
                      selectedCompany.user_id
                    )
                  }
                >
                  Edit
              </Button>
                :
                <Button
                  className='btn-circle btn-sm'
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: 100,
                    height: 30,
                    minWidth: 60,
                    display: 'inline-block',
                    right: '0'
                  }}
                  onClick={() =>
                    this.activateCompany(selectedCompany)
                  }
                >
                  Activate
          </Button>}
            </div>}
          </div>
          <div className='row my-2'>
            <div className='col-md-3'>
              <h6>Name</h6>
              <p className='textOverflow text-truncate'>{selectedCompany.newspaper_name}</p>
            </div>
            <div className='col-md-3'>
              <h6 className='textOverflow'>Role in Co.</h6>
              <p className='textOverflow text-truncate'>{selectedCompany.user.roleName}</p>
            </div>
            {/* <div className='col-md-3'>
              <h6 className='textOverflow'>Role in Co.</h6>
              <p className='textOverflow text-truncate'>{selectedCompany.companyca}</p>
            </div> */}
            
            
            <div className='col-md-3'>
              <h6 className='textOverflow'>Company Category Name</h6>
              <p className='textOverflow text-truncate'>{this.renderCompanyCategory(selectedCompany.user.copanycategoryName)}</p>
            </div>
            
            
            <div className='col-md-3'>
              <h6>Phone No</h6>
              <p className='textOverflow text-truncate'>
                {this.phoneNumberFormate(selectedCompany.user.phone_number)}
              </p>
            </div>

            <div className='col-md-3'>
              <h6 className='textOverflow'>Primary Email</h6>
              <p className='textOverflow text-truncate'>{selectedCompany.user.email}</p>
            </div>


          </div>
              <div className='row my-2'>
            <div className='col-md-3'>
              <h6>Distribution Type</h6>
              <p className='textOverflow text-truncate'>
                {selectedCompany.distribution_type}
              </p>
            </div>
           {Array.isArray(selectedCompany.distribution_areas) && selectedCompany.distribution_areas.length > 0 && <div className='col-md-3'>
              <h6>Distribution areas</h6>
              <p className='textOverflow text-truncate'>
                { selectedCompany.distribution_type == "" || selectedCompany.distribution_type == undefined ? this.renderArea([]) : this.renderArea(selectedCompany.distribution_areas)
                }
              </p>
            </div>}
            <div className='col-md-3'>
              <h6>No of Copies</h6>
              <p className='textOverflow text-truncate'>
                {selectedCompany.distributed_copies &&
                  selectedCompany.distributed_copies.copies_range}
              </p>
            </div>
            <div className='col-md-3'>
              <h6>{selectedCompany.distribution_location && 'Location of Distribution'}</h6>
              <p className='textOverflow text-truncate'>
                {selectedCompany.distribution_location && selectedCompany.distribution_location}
              </p>
            </div>




          </div>

          <div className="row my-2">
            <div className='col-md-6 d-flex align-items-baseline'>
              <h6 className="mr-5">Status</h6>
              <div className='row'>
                <h6 style={{ color: 'green' }}>Active</h6>
                <div style={{ marginTop: -10 }}>
                  <Switch
                    checked={!selectedCompany.active}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
                <h6 style={{ color: 'red' }}>Inactive</h6>
              </div>
            </div>
            <div className='col-md-6'>
              <Link
                to='#'
                onClick={() => {
                  this.setState({ loading: true })
                  requestForResetPassword(selectedCompany.user.email, res => {
                    if (res.status === STATUS_CODES.OK) {
                      this.setState({ loading: false })
                      toastr.success(MESSAGES.EMAIL_SENT_FOR_RESET);
                    }
                    else {
                      this.setState({ loading: false })
                    }
                  });
                }}
                className="float-md-right"
                style={!selectedCompany.active ? { pointerEvents: 'none', color: 'grey' } : null}
              >
                Reset password
              </Link>
            </div>
          </div>
        </div>
        {isOpenModal && (
          <AddCompany
            isOpen={isOpenModal}
            onCancel={this.onCancel}
            onNext={() => {
              this.setState({ showPaymentModal: true });
            }}
            isEditFlag={isEditFlag}
            companyId={selectedCompany && selectedCompany.id}
            userId={selectedCompany && selectedCompany.user_id}
          />
        )}
      </div>
    );
  };

  render() {
    const { loading, noContentFound } = this.state;
    if (noContentFound) {
      return (
        <div>
          <NoContentFound />
        </div>
      );
    } else {
      return (
        <>
          {loading && <Loader />}
          <Notice>{this.props.selectedCompany && this.renderHeader()}</Notice>
          <div className='row'>
            <div className='col-md-12'>
              <Portlet>
                <ViewCompanyBody selectedCompnay={this.props.selectedCompnay} />
              </Portlet>
            </div>
          </div>
        </>
      );
    }
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = store => {
  const { auth, authToken } = store;
  const selectedCompany =
    store.newsPaperCompanyDuck && store.newsPaperCompanyDuck.selectedCompany;
    //console.warn("Selected Company", selectedCompany)
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    selectedCompany: selectedCompany
  };
};

export default injectIntl(
  connect(mapStateToProps, NewsPaperCompanyDuck.actions)(ViewCompany)
);
