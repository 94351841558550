import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import { Redirect } from 'react-router-dom'
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap'
import { injectIntl } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import * as registrationDuck from '../../../../store/ducks/super-admin/Registration.duck'
import { MESSAGES } from '../../../../config/message'
import { Loader } from '../../../../common/Loader'
import {
    renderSelectField, renderText,renderNumberField
} from '../../../../common/layout/FormInput'
import {
    required,normalizePhone
} from '../../../../config/validation'
import { STATUS_CODES } from '../../../../common/StatusCode'

class AcceptModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleArray: [{ value: 1, label: 'Weekly' }, { value: 2, label: 'Bi-Weekly' }, { value: 3, label: 'Monthly' }],
            percentArray: [{ value: 0.1, label: '0.1' }, { value: 0.2, label: '0.2' }, { value: 0.3, label: '0.3' }, { value: 0.4, label: '0.4' }],
            success: false,
            error: false,
            show: false,
            isRedirect: false,
            message: '',
            flag: false,
            isSubmitted: true,
            loading: false
        }
    }

    /**
     * @method componentWillMount
     * @description called before mounting the component
     */
    componentWillMount() {
        this.setState({ flag: !this.state.flag })
    }

    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleModel = () => {
        this.props.onCancel();
    }

    /**
  * @method getCustomerList
  * @description get customer list
  */
    getCustomerList = (row, page) => {
        const { user_detail } = this.props.loggedInUser;
        let requestData = {
            id: user_detail.id,
            row: row !== undefined ? row : 50,
            page: page !== undefined ? page : 1,
            status: 0,
            is_deleted: 0
        };
        this.props.getCustomerRequestList(requestData, res => {
        });
    };

    /**
       * Submit the login form
       * @param values
       * @desc Calling a login actions && redirecting page based on response and set session Items 
       */
    onSubmit(values) {
        const { selectedCompany } = this.props;
        if (selectedCompany && selectedCompany !== undefined) {
            const data = {
                newspaper_id: selectedCompany.id,
                user_id: selectedCompany.user_id,
                payment_schedule: values.payment_schedule,
                commission_percent: values.commission_percent,
                first_name: selectedCompany.user.first_name,
                newspaper_name: selectedCompany.newspaper_name,
                email: selectedCompany.user.email,
                status: 1,
                active: true
            }
            this.setState({ isSubmitted: false })
            this.props.rejectRequest(data, res => {
                if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
                    this.getCustomerList(100, 1);
                    toastr.success(MESSAGES.REQUEST_ACCEPT_SUCCESS)
                    this.setState({ isRedirect: true });
                    this.toggleModel();
                } else {
                    this.toggleModel();
                }
            })
        }
    }

    /**
     * @method render
     * @description render the component
     */
    render() {
        const { handleSubmit, loading, isOpen } = this.props;
        const { isRedirect, scheduleArray, percentArray, isSubmitted } = this.state;
        if (isRedirect) {
            return (
                <Redirect push
                    to={{
                        pathname: '/newspaperManagement'
                    }}
                />
            );
        }
        return (
            <Modal
                isOpen={isOpen} toggle={this.toggleModel} styles={{ height: 10, width: 100 }}>
                {loading && <Loader />}

                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                    Accept Company
                                </ModalHeader>
                <ModalBody styles={{ width: 600 }}>
                    <Row>
                        <Container>
                            <form
                                noValidate
                                className='form'
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='payment_schedule'
                                            options={scheduleArray}
                                            label={'Schedule Payment'}
                                            required={true}
                                            validate={required}
                                            isSelect={false}
                                            optionValue={'value'}
                                            optionLabel={'label'}
                                            component={renderSelectField}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='commission_percent'
                                            label={'Set Percent to deduct from Earning'}
                                            required={true}
                                            validate={required}                                         
                                            type='text'
                                            // isSelect={false}
                                            // optionValue={'value'}
                                            // optionLabel={'label'}
                                            normalize={normalizePhone}
                                            component={renderText}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12 text-right float-right'>
                                        <Button disabled={isSubmitted ? false : true} style={{ margin: 10, background: 'black', border: 'none', width: 100 }} size='md' type='submit'>Save</Button>
                                        <Button onClick={this.toggleModel} style={{
                                            margin: 10, background: '#808080',
                                            border: 'none',
                                            width: 100
                                        }} size='md' >Cancel</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    return ({
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        selectedCompany: store.registrationDuck.selectedCompany,
        loading
    })
}

export default injectIntl(
    connect(
        mapStateToProps,
        registrationDuck.actions
    )(reduxForm({
        // Assign unique name for the form
        form: 'AcceptModal',

    })(AcceptModal))
);
