import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { Field, reduxForm, change, untouch } from 'redux-form'
import { injectIntl } from 'react-intl'
import { renderText, renderTextAreaField } from '../../../../common/layout/FormInput';
import { MESSAGES } from '../../../../config/message'
import { required } from '../../../../config/validation'
import * as registrationDuck from '../../../../store/ducks/super-admin/Registration.duck'
import { toastr } from 'react-redux-toastr';
import { STATUS_CODES } from '../../../../common/StatusCode'

class MessageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            show: false,
            isSubmitted: true
        }
    }
    /**
     * @method componentDidMount
     * @description called after render the component
     */
    componentDidMount() {
        const { authToken, user_detail } = this.props.loggedInUser;
        if (this.props.companyId) {
            this.props.getCompanyDetailById(user_detail.id, this.props.companyId, true, authToken, res => { });
        }
    }

    /**
       * Submit the login form
       * @param values
       * @desc Calling a login actions && redirecting page based on response and set session Items 
       */
    onSubmit(values) {
        const { authToken } = this.props.loggedInUser;
        const { selectedCompany } = this.props;
        if (selectedCompany && selectedCompany !== undefined) {
            const data = {
                newspaper_id: selectedCompany.id,
                email: selectedCompany.user.email,
                newspaper_name: values.newspaper_name,
                message: values.message,
                subject:values.subject
            }
            this.props.messageCompany(data, authToken, res => {
                if (res.status === STATUS_CODES.CREATED) {
                    toastr.success(MESSAGES.MESSAGE_SENT_SUCCESS)
                    this.setState({ isSubmitted: false });
                    this.props.dispatch(change('MessageModal', 'message', ''));
                    this.props.dispatch(untouch('MessageModal', 'message'));
                    setTimeout(() => {
                        this.toggleModel();
                    }, 5000)
                } else {
                    this.setState({ isSubmitted: true })
                }
            })
        }
    }


    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleModel = () => {
        this.props.dispatch(change('MessageModal', 'message', ''));
        this.props.dispatch(untouch('MessageModal', 'message'));
        this.props.onCancel();
    }
    render() {
        const { isSubmitted } = this.state;
        return (
            <Modal
                isOpen={this.props.isOpen} toggle={this.toggleModel} styles={{ height: 10 }}>
                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                    Message                </ModalHeader>
                <ModalBody >
                    <Row>
                        <Container>
                            <form
                                className='form'
                                onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='newspaper_name'
                                            label={'Company Name'}
                                            required={true}
                                            component={renderText}
                                            disabled={true}
                                            validate={[required]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='subject'
                                            required={true}
                                            label={'Subject'}
                                            component={renderText}
                                            isVisible={true}
                                            placeholder='Type your message Subject'
                                            validate={[required]}
                                        />

                                    </Col>
                                </Row>

                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='message'
                                            required={true}
                                            label={'Message'}
                                            component={renderTextAreaField}
                                            isVisible={true}
                                            placeholder='Type your message here'
                                            validate={[required]}
                                        />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                        <Button style={{ margin: 10, background: 'black', width: 100 }}
                                            size='md'
                                            type='submit'
                                            disabled={isSubmitted ? false : true}
                                        >Send</Button>
                                        <Button onClick={this.toggleModel} style={{
                                            margin: 10, background: '#808080',
                                            border: 'none',
                                            width: 100
                                        }} size='md' >Cancel</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

const mapStateToProps = store => {
    const selectedCompany = store.registrationDuck && store.registrationDuck.selectedCompany;
    let initialValues = {};
    const { loading } = store.commonDuck;
    if (selectedCompany && selectedCompany !== undefined) {
        initialValues = {
            newspaper_name: selectedCompany.newspaper_name,
            message: ''
        };
    }
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        initialValues,
        selectedCompany: store.registrationDuck.selectedCompany,
        loading
    };
};


export default injectIntl(
    connect(
        mapStateToProps,
        registrationDuck.actions
    )(reduxForm({
        // Assign unique name for the form
        form: 'MessageModal',
        enableReinitialize: true,
        destroyOnUnmount: false
    })(MessageModal))
);
