import React from "react";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import CountFigureBox from "../../../../../common/CountFigureBox";

export default class PayoutHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @method renderCard
   * @description render the header card details
   */
  renderHeaderCard = (lable, value, isAmount) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        <h4 className="heading">
          {isAmount ? "$" : ""}
          {`${value}`}
        </h4>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { headerData } = this.props;
    const total_revenue =
      headerData && headerData.sumData && headerData.sumData.total_revenue;
    const total_earning =
      headerData && headerData.sumData && headerData.sumData.paid_amount;
    const total_orders =
      headerData && headerData.sumData && headerData.sumData.total_orders;
    const earning_balance =
      headerData && headerData.sumData && headerData.sumData.unpaid_amount;
    return (
      <div>
        <Portlet fluidHeight={true}>
          <PortletBody>
            <div className="row">
              <div className="cardWrapper col-md-6">
                {this.renderHeaderCard("Total Revenue", total_revenue, true)}
              </div>
              <div className="cardWrapper col-md-6 ">
                {this.renderHeaderCard("Total Earning", total_earning, true)}
              </div>
              <div className="cardWrapper col-md-6 ">
                {this.renderHeaderCard("Total Orders", total_orders, false)}
              </div>
              <div className="cardWrapper col-md-6">
                {this.renderHeaderCard(
                  "Earning Balance",
                  earning_balance,
                  true
                )}
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}
