import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { Link } from "react-router-dom";
import NotificationList from "./NotificationList";
import DonutChart from "./DonutChart";
import Revenue from "./Revenue";
import TopAdType from "./TopAdType";
import TopCompany from "./TopCompany";
import TopCustomer from "./TopCustomers";
import TopAgent from "./TopAgents";
import * as AdminDashboardRecord from "../../../../store/ducks/super-admin/AdminDashboard.duck";

import { STATUS_CODES } from "../../../../common/StatusCode";
import { Loader } from "../../../../common/Loader";
import CountFigureBox from "../../../../common/CountFigureBox";

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      headerData: "",
      CompanyCount:0
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getDashboardRecord(1); // get header data
    this.getDashboardRecord(6); // get payout details
    this.getDashboardRecord(7); // get credit details
    this.getcompanyDaetails();
    
  }

  /**
   * @method getDashboardRecord
   * @description  get dashboard record
   */

   getcompanyDaetails = ()=>{
    const { user_detail } = this.props.loggedInUser;
    let requestData2 = {
      id: user_detail.id,
      row: 25,
      page: 1,
      status: 1,
      is_deleted: 0,
    };
    this.props.getAllNewspaperList(requestData2, (res) => {
      if (res.status === STATUS_CODES.OK) {
        //console.warn("GGG----->",res.data.count);
        this.setState({CompanyCount:res.data.count});
      }
    });
   }


  getDashboardRecord = (type) => {
    let requestData = {
      type,
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      row: "",
      page: "",
      role: "",
    };
    this.props.getAdminDashBoardData(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        type === 1 && this.setState({ headerData: res.data });
      }
    });

    
     

    


  };

  /**
   * @method renderHeaderCard
   * @description render header card details
   */
  renderHeaderCard = (lable, total, amt) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        {total ? (
          <h4 className="heading">{amt !== "" ? `$ ${total}` : `${total}`}</h4>
        ) : (
          <h4 className="heading">{amt !== "" ? "$0" : 0}</h4>
        )}
      </CountFigureBox>
    );
  };


  /**
   * @method renderPayoutDetail
   * @description render payout details
   */
  renderPayoutDetail = (lable, date, amt, amountFlag) => {
    return (
      <CountFigureBox>
        <h5 className="heading">{lable}</h5>
        {date !== undefined ? <h4 className="heading">{`${date}`}</h4> : ""}
        {amt ? (
          <h4 className="heading">{` ${amountFlag} ${amt}`}</h4>
        ) : (
          <h4 className="heading">{`${amountFlag}0`}</h4>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { headerData } = this.state;
    const {
      loading,
      credit_cancel_orders,
      credit_cancel_revenue,
      credit_amount_utilized,
      credit_amount_pending,
      credit_amount_expired,
      lastPayout,
      lastPayoutDate,
      upCommingPayout,
      upCommingPayoutDate,
      
    } = this.props;
    let active_companies = "",
      active_customer = "",
      active_agent = "",
      total_users = "",
      pending_companies = "",
      total_revenue = "",
      total_payout = "",
      total_earning = "",
      heigestRevenueEarned = "";
    if (headerData && headerData !== undefined) {
      active_companies = headerData.multipleCounts.active_companies;
      active_customer = headerData.multipleCounts.active_customer;
      active_agent = headerData.multipleCounts.active_agent;
      total_users = headerData.multipleCounts.total_users;
      pending_companies = headerData.multipleCounts.pending_companies;
      total_revenue = headerData.revenue[0].total_revenue;
      total_payout = headerData.revenue[0].total_payout;
      total_earning = headerData.revenue[0].total_earning;
      heigestRevenueEarned = headerData.highestRevenue.total_earning;
    }
    return (
      <>
        {/* {loading && <Loader/>} */}
        <div className="row dashboard">
          <div className="col-xl-12">
            <div className="row row-full-height">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <Portlet>
                  <PortletBody fluid={true}>
                    <div className="row">
                      <div className="cardWrapper col-sm-3">
                        {this.renderHeaderCard(
                          "Total Active Companies",
                          this.state.CompanyCount,""
                        )}
                      </div>
                      <div className="cardWrapper col-sm-3 ">
                        {this.renderHeaderCard(
                          "Total Active Customers",
                          active_customer,
                          ""
                        )}
                      </div>

                      <div className="cardWrapper col-sm-3">
                        {this.renderHeaderCard(
                          "Total Active Agents",
                          active_agent,
                          ""
                        )}
                      </div>
                      <div className="cardWrapper col-sm-3 ">
                        {this.renderHeaderCard(
                          "Total Active Ad Squad Users",
                          total_users,
                          ""
                        )}
                      </div>

                      <div className="cardWrapper col-sm-3">
                        {this.renderHeaderCard(
                          "No. of Companies pending for approval",
                          pending_companies,
                          ""
                        )}
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
            </div>
          </div>

          <div className="col-xl-6 mt-5">
            <h4>Orders</h4>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <DonutChart chartData={headerData} />
              </PortletBody>
            </Portlet>
          </div>
          <div className="col-xl-6 mt-5">
            <h4>Notifications</h4>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <NotificationList />
              </PortletBody>
            </Portlet>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <h4>Revenue</h4>
            <Revenue
              total_revenue={total_revenue}
              total_payout={total_payout}
              total_earning={total_earning}
              heigestRevenueEarned={heigestRevenueEarned}
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <h5>Top 5 Newspaper companies</h5>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <TopCompany />
              </PortletBody>
            </Portlet>
          </div>
          <div className="col-md-6">
            <h5>Top 5 Customers</h5>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <TopCustomer />
              </PortletBody>
            </Portlet>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <h5>Top 5 Agents</h5>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <TopAgent />
              </PortletBody>
            </Portlet>
          </div>
          <div className="col-md-6">
            <h5>Top 5 Ad Types</h5>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <TopAdType header={"Ad Type Name"} moduleType={3} />
              </PortletBody>
            </Portlet>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <h5>Payouts</h5>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <div className="row align-items-stretch">
                  <div className="col-sm-12 mb-3">
                    {this.renderPayoutDetail(
                      "Last Payout",
                      lastPayoutDate,
                      lastPayout,
                      "$"
                    )}
                  </div>
                  <div className="col-sm-12 mb-3">
                    {this.renderPayoutDetail(
                      "Upcoming Payout",
                      upCommingPayoutDate,
                      upCommingPayout,
                      "$"
                    )}
                  </div>
                </div>
                <Link to="/payout-summary" onClick={this.closePopOver}>
                  <h6
                    className=" mt-3 float-right"
                    style={{
                      cursor: "pointer",                    
                      borderBottom: "1px solid blue",
                    }}
                  >
                    View All
                  </h6>
                </Link>
              </PortletBody>
            </Portlet>
          </div>
          <div className="col-md-6">
            <h5>Credits</h5>
            <Portlet fluidHeight={true}>
              <PortletBody>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    {this.renderPayoutDetail(
                      "No. of Orders Cancelled",
                      credit_cancel_orders,
                      credit_cancel_revenue,
                      ""
                    )}
                  </div>
                  <div className="col-sm-6 mb-3">
                    {this.renderHeaderCard(
                      "Credit Amount Utilized",
                      credit_amount_utilized,
                      "amt"
                    )}
                  </div>

                  <div className="col-sm-6 mb-3">
                    {this.renderHeaderCard(
                      "Credit Amount Pending",
                      credit_amount_pending,
                      "amt"
                    )}
                  </div>
                  <div className="col-sm-6 mb-3">
                    {this.renderHeaderCard(
                      "Expired Credit Amount",
                      credit_amount_expired,
                      "amt"
                    )}
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, AdminDashboardRecord,newsPaperCompanyDuck } = store;
  const { dashboardCreditData, dashboardPayoutData } = AdminDashboardRecord;
  return {
    loggedInUser: auth.user,
    companyList: newsPaperCompanyDuck && newsPaperCompanyDuck.companyList,
    totalCompany:
      store.newsPaperCompanyDuck && store.newsPaperCompanyDuck.totalCompany,
    credit_cancel_orders:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.cancelled_orders[0].cancelled_orders
        : "",
    credit_cancel_revenue:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.cancelled_orders[0].cancelled_revenue
        : "",
    credit_amount_utilized:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.credits[0].utilized_amount
        : "",
    credit_amount_expired:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.credits[0].credit_expired
        : "",
    credit_amount_pending:
      dashboardCreditData !== undefined &&
      Array.isArray(dashboardCreditData.cancelled_orders)
        ? dashboardCreditData.credits[0].pending_credit
        : "",
    lastPayout:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.lastPayOut)
        ? dashboardPayoutData.lastPayOut[0].payment
        : "",
    lastPayoutDate:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.lastPayOut)
        ? dashboardPayoutData.lastPayOut[0].payment_date
        : "",
    upCommingPayout:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.upcomingPayOut)
        ? dashboardPayoutData.upcomingPayOut[0].payment
        : "",
    upCommingPayoutDate:
      dashboardPayoutData !== undefined &&
      Array.isArray(dashboardPayoutData.upcomingPayOut)
        ? dashboardPayoutData.upcomingPayOut[0].date
        : "",
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    AdminDashboardRecord.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdminDashboard",
    })(AdminDashboard)
  )
);
