import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { Col, Row, Button } from "react-bootstrap";
import { injectIntl } from "react-intl";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { selectRequired } from "../../../../config/validation";
import { renderSelectField } from "../../../../common/layout/FormInput";
import { langs } from "../../../../config/localization";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";
import { superAdminId } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";

class DistributionAreas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      showMore: false,
      uniquesState: [],
      areasByState: [],
      selectedState: undefined,
      area1: undefined,
      area2: undefined,
      area3: undefined,
      isSubmitted: true,
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    this.setUniqueStates();
    this.props.getfromNPCSetting(
      langs.keyname.company_distribution_area,
      user_detail.newspaper.id,
      (res) => {
        if (
          res.status === STATUS_CODES.OK &&
          Array.isArray(res.data) &&
          res.data.length
        ) {
          let state = res.data[0].distribution_areas[0].us_states[0].state_name;
          if (state !== undefined) {
            let areas = Array.isArray(res.data[0].distribution_areas)
              ? res.data[0].distribution_areas
              : [];
            this.props.getDistributionAreaFromState(String(state), (res) => {
              if (res.data) {
                this.setState({
                  areasByState: res.data,
                  selectedState: res.distribution_state,
                  area1: areas[0],
                  area2: areas[1],
                  area3: areas[2],
                  isSubmitted: true,
                });
              }
            });
          } else if (res.distribution_state === "") {
            this.setState({ areasByState: [], selectedState: undefined });
          }
        }
      }
    );
    this.getDataById();
  }
  /**
   * @method setUniqueStates
   * @description called to set list of area's
   */
  setUniqueStates = () => {
    this.props.getfromNPCSetting(
      langs.keyname.distribution_area_count,
      superAdminId,
      (res) => {
        if (Array.isArray(res.data)) {
          const unique = [
            ...new Set(res.data.map((item) => item["us_states.state_name"])),
          ];
          let uniqueArray = [];
          unique.map((el, i) => {
            uniqueArray.push({ id: i, lable: el });
          });
          this.setState({ uniquesState: uniqueArray });
        }
      }
    );
  };

  /**
   * @method getDataById
   * @description get company details by id
   */
  getDataById = () => {
    const { user_detail } = this.props.loggedInUser;
    if (user_detail.newspaper) {
      let requestData = {
        company_id: user_detail.newspaper.id,
      };
      this.props.getCompanyById(requestData, (res) => {});
    }
  };

  pushArea = (dist_area, value) => {
    const { user_detail } = this.props.loggedInUser;
    const companyId = user_detail.newspaper.id;
    if (value !== undefined && value !== "") {
      dist_area.push({
        newspaperId: companyId,
        distributionAreaId: value,
      });
    }
    return dist_area;
  };

  /**
   * @method onSubmit
   * @description called api to state and area
   */
  onSubmit = (values) => {
    this.setState({ isSubmitted: false });
    let dist_area = [];
    dist_area = this.pushArea(dist_area, values.area1);
    dist_area = this.pushArea(dist_area, values.area2);
    dist_area = this.pushArea(dist_area, values.area3);
    values.distribution_area = dist_area ? dist_area : [];

    let reqData = {};
    reqData.distribution_area = values.distribution_area;
    this.props.addDistributionArea(reqData, (res) => {
      if (
        res.status === STATUS_CODES.CREATED ||
        res.status === STATUS_CODES.OK
      ) {
        toastr.success(MESSAGES.ADD_AREA_SUCCESS);
      } else {
        this.setState({ isSubmitted: true });
      }
    });
  };

  /**
   * @method onChangeStateHandeler
   * @description called onchange state
   */
  onChangeStateHandeler = (e) => {
    if (e.target.value !== "") {
      this.props.getDistributionAreaFromState(String(e.target.value), (res) => {
        if (res.data) {
          this.setState({
            areasByState: res.data,
            selectedState: e.target.value,
            isSubmitted: true,
            area1: undefined,
            area2: undefined,
            area3: undefined,
          });

          this.props.dispatch(change("DistributionAreas", "area1", ""));
          this.props.dispatch(change("DistributionAreas", "area2", ""));
          this.props.dispatch(change("DistributionAreas", "area3", ""));
        }
      });
    } else if (e.target.value === "") {
      this.setState({ areasByState: [], selectedState: undefined });
    }
  };

  /**
   * @method getArea
   * @description called to get all area's
   */
  getArea = (e, name) => {
    const { areasByState } = this.state;
    let index;
    if (areasByState) {
      index = areasByState.findIndex((el) => el.id === Number(e.target.value));
      if (index >= 0) {
        this.setState({
          [name]: {
            name: areasByState[index].area,
            id: areasByState[index].id,
          },
          isSubmitted: true,
        });
      } else {
        this.setState({ [name]: undefined, isSubmitted: true });
      }
    }
  };

  /**
   * @method filterOption
   * @description methode COnditionally Filter the the Ara option list
   */
  filterOption = (val1, val2) => {
    const { areasByState } = this.state;
    let temp;
    if (val1 === undefined && val2 === undefined) {
      return areasByState;
    } else if (val1 !== undefined && val2 !== undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val1.id && x.id !== val2.id);
    } else if (val2 === undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val1.id);
    } else if (val1 === undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val2.id);
    }
    return temp;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit } = this.props;
    const {
      uniquesState,
      isSubmitted,
      area1,
      area2,
      area3,
      selectedState,
    } = this.state;
    return (
      <div className="row my-4">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>Distribution Area</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody>
              <form
                noValidate
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="6">
                    <Field
                      name="distribution_state"
                      value={selectedState}
                      label="State"
                      validate={[selectRequired]}
                      optionValue={"lable"}
                      optionLabel={"lable"}
                      isSelect={false}
                      required={true}
                      options={uniquesState && uniquesState}
                      onChange={this.onChangeStateHandeler}
                      component={renderSelectField}
                      placeholder="Enter Distribution State"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Field
                      name="area1"
                      value={area1}
                      label="Area 1"
                      validate={[selectRequired]}
                      optionValue={"id"}
                      optionLabel={"area"}
                      options={this.filterOption(area2, area3)}
                      isSelect={false}
                      required={true}
                      onChange={(e) => this.getArea(e, "area1")}
                      component={renderSelectField}
                      placeholder="Enter Distribution State"
                    />
                  </Col>
                  <Col md="3">
                    <Field
                      name="area2"
                      value={area2}
                      label="Area 2"
                      optionValue={"id"}
                      optionLabel={"area"}
                      options={this.filterOption(area1, area3)}
                      isSelect={false}
                      onChange={(e) => this.getArea(e, "area2")}
                      component={renderSelectField}
                      placeholder="Enter Distribution State"
                    />
                  </Col>
                  <Col md="3">
                    <Field
                      name="area3"
                      value={area3}
                      label="Area 3"
                      optionValue={"id"}
                      optionLabel={"area"}
                      options={this.filterOption(area1, area2)}
                      isSelect={false}
                      onChange={(e) => this.getArea(e, "area3")}
                      component={renderSelectField}
                      placeholder="Enter Distribution State"
                    />
                  </Col>
                  <Col md="3 my-4 my-md-auto">
                    <Button
                    className="float-right float-md-left"
                      style={{
                        background: "black",
                        border: "none",
                        width: 100,
                       
                      }}
                      size="md"
                      type="submit"
                      disabled={isSubmitted ? false : true}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </form>
            </PortletBody>
          </Portlet>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, npcDuck, authToken, commonDuck } = store;

  const companyProfile = commonDuck.selectedCompany;
  let initialValues = {};
  if (companyProfile && companyProfile !== undefined) {
    let areaArray = [];
    let state = "";
    if (
      Array.isArray(npcDuck.npcSettingList.company_distribution_area) &&
      npcDuck.npcSettingList.company_distribution_area.length
    ) {
      areaArray =
        npcDuck.npcSettingList.company_distribution_area[0].distribution_areas;
      state = areaArray[0].us_states[0].state_name;
    }
    initialValues = {
      distribution_state: state !== "" ? state : "",
      area1: areaArray && areaArray[0] ? areaArray[0].id : "",
      area2: areaArray && areaArray[1] ? areaArray[1].id : "",
      area3: areaArray && areaArray[2] ? areaArray[2].id : "",
    };
  }
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    distribution_area_List: npcDuck.npcSettingList.distribution_area,
    initialValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...commonDuck.actions, ...npcDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "DistributionAreas",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(DistributionAreas)
  )
);
