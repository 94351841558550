import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { Label, Input } from "reactstrap";
import { injectIntl } from "react-intl";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as customerDuck from "../../../../store/ducks/super-admin/Customer.duck";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { requestForResetPassword } from "../../../../crud/auth.crud";
import CodeExample from "../../../../partials/content/CodeExample";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import NavigationPrompt from "react-router-navigation-prompt";
import { Loader } from "../../../../common/Loader";
import { toastr } from "react-redux-toastr";
import { focusOnError } from "../../FormLayout";
import { Switch } from "@material-ui/core";
import {
  renderText,
  renderNumberInputField,
  renderSelectField,
} from "../../../../common/layout/FormInput";
import {
  required,
  PhoneNumber,
  maxLength50,
  normalizePhone,
  maxLength5,
  minLength5,
  maxLength100,
  maxLengthC100,
  minLength2,
  email,
  alphabetsOnly,
  addressOnly,
  minLength3,
  noSpecialCharacterOnly,
  noNumberOnly
} from "../../../../config/validation";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { colorCode } from "../../../../common/constant";
import {
  phoneNumberFormate,
  phoneNumberFormateOnChange,
} from "../../../../common";
import { NoContentFound } from "../../../../common/NoContentFound";
import { langs } from "../../../../config/localization";
import CheckboxRP from "../../../../common/CheckboxRP";

class EditCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      isSubmitted: false,
      isRedirect: false,
      uniqueEmailId: false,
      message: "",
      password: "",
      confirmPassword: "",
      loadingButtonStyle: {
        paddingRight: "2.5rem",
      },
      flag: true,
      allowCustomer: false,
      isAgent: false,
      isNewUser: false,
      isactive: false,
      loading: false,
      noContentFound: false,
      isEmailValid:true,
	    is_deadline_yes:false,
      is_deadline_no:true,    
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getRoleIdOfCustomer();
    const { id } = this.props.match.params;
    this.getRoleIdOfCustomer();
    this.props.viewCustomerById(id, (res) => {
      if (res && res.data !== undefined) {
        let number = phoneNumberFormate(res.data.result.phone_number);
        this.setState({
          isAgent: res.data.result.as_agent === 1 ? true : false,
          isNewUser: res.data.result.as_new_user === 1 ? true : false,
          isactive: res.data.result.active === 1 ? true : false,
          is_deadline_yes: res.data.result.is_deadline === 1 ? true : false,
          is_deadline_no: res.data.result.is_deadline === 1 ? false : true,
          number,
          allowCustomer: res.data.result.allow_cheque === 1 ? true : false,
        });
      } else {
        this.setState({ noContentFound: true });
      }
    });
  }

  getRoleIdOfCustomer = () => {
    this.props.getAllRoleList(langs.keyname.customer);
    this.props.getAllRoleList(langs.keyname.agent);
    this.props.getAllRoleList(langs.keyname.newuser);
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { selectedCustomer, customerRole, agentRole, newUserRole } = this.props;
    const { number } = this.state;
    const { isAgent, allowCustomer, isactive, is_deadline_yes, isNewUser } = this.state;
    if (selectedCustomer && selectedCustomer !== undefined) {
      const formdata = {
        id: selectedCustomer.id,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        phone_number: number.replace(/-/g, ""),
        email: values.email,
        address: values?.address?.trim()?? '',
        city: values?.city?.trim()?? '',
        state: values?.state?.trim()?? '',
        zip_code: values.zip_code,
        country: values.country,
        as_agent: isAgent,
        as_new_user: isNewUser,
        active: isactive,
        allow_cheque: allowCustomer,
        company_name: values.company_name,
        role_id: isAgent === true ? agentRole.id : isNewUser === true ? newUserRole.id : customerRole.id,
        is_deleted: 0,
        is_deadline: is_deadline_yes,
      };
      this.setState({ isSubmitted: true });
      this.props.updateCustomerProfile(formdata, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(MESSAGES.UPDATE_CUSTOMER_SUCCESS);
          setTimeout(() => {
            this.setState({
              isSubmitted: false,
              isRedirect: true,
            });
          }, 3000);
        }
      });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: !event.target.checked,
      isEditFlag: event.target.checked,
      isSubmitted: true,
    });
  };

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    const { name, value } = e.target;
    const { password, number } = this.state;

    if (name === "password" || name === "confirmPassword") {
      this.setState({ [name]: value });
    }
    if (password !== " && confirmPassword !== ") {
      this.setState({ disabledNotify: false, notifyAdmin: false });
    } else {
      this.setState({ disabledNotify: true });
    }

    this.setState({
      isEditFlag: true,
      isSubmitted: true,
      number: type === "number" ? e.target.value : number,
    });
  };

  /**
   * @method renderTypeOfListing
   * @description Used show type of listing
   */
  renderTypeOfListing = () => {
    const temp = [];
    const { companyRoles } = this.props;
    companyRoles &&
      companyRoles.map((item) => temp.push({ id: item.id, name: item.name }));
    return temp;
  };

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
      isEditFlag: true,
      isSubmitted: true,
    });
  };

  reset = () => {
    this.setState({ isRedirect: true });
  };

  validEmail = (e)=>{
    this.setState({
      isEditFlag: true,
    })
    if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/i.test(e.target.value))
      this.setState({isEmailValid:true})
    else
      this.setState({isEmailValid:false})
  }

  render() {
    const { handleSubmit, selectedCustomer, loading } = this.props;
    const {
      isEditFlag,
      isRedirect,
      number,
      billingCity,
      billingState,
      allowCustomer,
      isAgent,
      isNewUser,
      isactive,
      isEmailValid,
      noContentFound,
      is_deadline_no,
      is_deadline_yes
    } = this.state;
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/customerManagement",
          }}
        />
      );
    }
    if (noContentFound) {
      return (
        <div>
          <NoContentFound />
        </div>
      );
    } else {
      return (
        <div>
          {loading && <Loader />}
          <NavigationPrompt when={isEditFlag}>
            {(props) => {
              const { onConfirm, onCancel } = props;
              return (
                <NavigateModal
                  show={true}
                  alertHeading={"Changes made will be lost."}
                  cancleButtonText="Cancel"
                  okButtonText="Leave Anyway"
                  showCancel={true}
                  onOkClick={() => onConfirm()}
                  onClickCancel={() => {
                    onCancel();
                  }}
                />
              );
            }}
          </NavigationPrompt>
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div>
                <div className="row">
                  <h3 className="kt-subheader__title col-md-6">
                    <span>
                      <Link to="/customerManagement">
                        <i
                          onClick={() => {
                            return this.props.history.push(
                              "/customerManagement"
                            );
                          }}
                          style={{ fontSize: 20 }}
                          className="fa fa-arrow-left"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </span>
                    <span style={{ marginLeft: 20 }}>Edit Customers</span>
                  </h3>
                </div>
              </div>
            )}
          </LayoutContextConsumer>
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Contact Info">
              <form
                noValidate
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="6">
                    <Field
                      name="first_name"
                      value={"adminProfile.first_name"}
                      label="First Name"
                      validate={[required, minLength2, maxLengthC100, alphabetsOnly]}
                      required={true}
                      placeholder="Enter Your First Name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="last_name"
                      label="Last Name"
                      validate={[required, minLength2, maxLengthC100, alphabetsOnly]}
                      required={true}
                      placeholder="Enter Your Last Name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="email"
                      label="Email"
                      validate={[required, minLength5, maxLength100, email]}
                      required={true}
                      placeholder="Enter Email Id"
                      component={renderText}
                      onChange={(e) => this.validEmail(e)}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="phone_number"
                      label="Phone Number"
                      type="text"
                      maxLength={10}
                      autoComplete="off"
                      onChange={this.onChange}
                      Value={number}
                      validate={[required, PhoneNumber]}
                      required={true}
                      placeholder="Enter Phone Number"
                      style={{ width: "100%" }}
                      component={renderNumberInputField}
                      normalize={normalizePhone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Field
                      name="company_name"
                      value={"company_name"}
                      label="Company Name"
                      placeholder="Company name"
                      validate={[required, minLength3, maxLength100, noSpecialCharacterOnly,noNumberOnly]}
                      required={true}
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Field
                      name="address"
                      value={"adminProfile.first_name"}
                      label="Address"
                      // validate={[required, minLength2, maxLength100]}
                      validate={[minLength2, maxLength100, addressOnly]}
                      // required={true}
                      placeholder="Enter Address"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="city"
                      value={billingCity}
                      label="City"
                      // validate={[required, maxLength50]}
                      validate={[maxLength50, alphabetsOnly]}
                      // required={true}
                      placeholder="Enter City"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="state"
                      value={billingState}
                      label="State"
                      // validate={[required, maxLength50]}
                      validate={[maxLength50, alphabetsOnly]}
                      // required={true}
                      placeholder="Enter State"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="zip_code"
                      value={"adminProfile.first_name"}
                      label="Zip Code"
                      // validate={[required, minLength5, maxLength5]}
                      validate={[minLength5, maxLength5]}
                      type="text"
                      style={{ width: "100%" }}
                      maxLength={5}
                      // required={true}
                      placeholder="Enter Zip Code"
                      component={renderText}
                      normalize={normalizePhone}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="country"
                      label="Country"
                      // required={true}
                      options={[{ label: "USA", value: "USA" }]}
                      component={renderSelectField}
                      placeholder="Enter Country"
                      optionValue={"value"}
                      optionLabel={"label"}
                      onChange={(e) =>
                        this.setState({
                          isEditFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <div className="checkbox-inline">
                      <CheckboxRP label="Allow customer to pay by check">
                        <Input
                          type="checkbox"
                          id="checkbox1"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={allowCustomer ? true : false}
                          onChange={(e) =>
                            this.setState({
                              allowCustomer: true,
                              isEditFlag: true,
                              isAgent:false,
                              isNewUser: false
                            })
                          }
                        />
                      </CheckboxRP>
                      <CheckboxRP label="As a Agent">
                        <Input
                          type="checkbox"
                          id="checkbox2"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={isAgent ? true : false}
                          onChange={(e) =>
                            this.setState({
                              isAgent: true,
                              isEditFlag: true,
                              allowCustomer: false,
                              isNewUser: false
                            })
                          }
                        />
                      </CheckboxRP>
                      <CheckboxRP label="As a New User Type">
                        <Input
                          type="checkbox"
                          id="checkbox3"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={isNewUser ? true : false}
                          onChange={(e) =>
                            this.setState({
                              isNewUser: true,
                              isEditFlag: true,
                              isAgent: false,
                              allowCustomer: false,
                            })
                          }
                        />
                      </CheckboxRP>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="d-flex w-100 justify-content-end">
                      <div className="d-flex">
                        <h6 style={{ color: "green" }}>Active</h6>
                        <div style={{ marginTop: -10 }}>
                          <Switch
                            checked={isactive ? false : true}
                            onChange={(e) =>
                              this.setState({
                                isactive: !isactive,
                                isEditFlag: true,
                              })
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </div>
                        <h6 style={{ color: "red" }}>Inactive</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <Row>
                <p style={{width:'100%',
                marginLeft:"10px",
                color:"#48465b",
                fontWeight:"500",
                fontSize:"1rem"
                }}>Allow Deadline Extension?</p>
                <Col md={6}>
                    <div className="checkbox-inline">
                      <CheckboxRP label="Yes">
                        <Input
                          type="checkbox"
                          id="deadlineyes"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={is_deadline_yes? true : false}
                          onChange={(e) =>
                            this.setState({
                              is_deadline_yes:true,
                              is_deadline_no:false,
                              isEditFlag: true,
                            })
                          }
                        />
                      </CheckboxRP>
                      <CheckboxRP label="No">
                        <Input
                          type="checkbox"
                          id="deadlineno"
                          style={{
                            width: "1.25rem",
                            height: "1.25rem",
                          }}
                          checked={is_deadline_no? true : false}
                          onChange={(e) =>
                            this.setState({
                              is_deadline_yes:false,
                              is_deadline_no:true,
                              isEditFlag: true,
                            })
                          }
                        />
                      </CheckboxRP>
                    </div>
                  </Col> 
               </Row>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                <Row className="ml-2">
                {
                  isEmailValid ?
                    <h6
                      style={{ cursor:"pointer", color: colorCode.link}}
                      onClick={() => {
                        this.setState({ loading: true });
                         requestForResetPassword(
                          selectedCustomer.email,
                          (res) => {
                            if (res.status === STATUS_CODES.OK) {
                              this.setState({ loading: false });
                              toastr.success(MESSAGES.EMAIL_SENT_FOR_RESET);
                            } else {
                              this.setState({ loading: false });
                            }
                          }
                        );
                      }}
                    >
                      Reset password
                    </h6> :
                  <h6 style={{color:colorCode.linkInactive}}>
                    Reset password
                  </h6>
                }
                  
                </Row>
                <Row className="float-right">
                  <div className="col">
                    <Button
                      className="btn btn-dark px-5"
                      onClick={() => {}}
                      type="submit"
                      disabled={isEditFlag ? false : true}
                    >
                      Save
                    </Button>
                  </div>
                </Row>
              </form>
            </CodeExample>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => {
  const { loading, roles } = store.commonDuck;
  const selectedCustomer = store.superAdminDuck.selectedCustomer;
  const selectedAgentCompany = store.customerDuck.selectedAgentCompany;
  let initialValues = {};
  if (selectedCustomer && selectedAgentCompany) {
    initialValues = {
      first_name:
        selectedCustomer.first_name[0].toUpperCase() +
        selectedCustomer.first_name.slice(1).trim(),
      last_name: selectedCustomer.last_name,
      phone_number: selectedCustomer.phone_number
        ? selectedCustomer.phone_number
        : "",
      email: selectedCustomer.email ? selectedCustomer.email : "",
      address: selectedCustomer.address ? selectedCustomer.address : "",
      city: selectedCustomer.city ? selectedCustomer.city : "",
      state: selectedCustomer.state ? selectedCustomer.state : "",
      zip_code: selectedCustomer.zip_code ? selectedCustomer.zip_code : "",
      company_name: selectedAgentCompany.company_name
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
    initialValues,
    selectedCustomer,
    customerRole: roles && roles.customer ? roles.customer[0] : [],
    agentRole: roles && roles.agent ? roles.agent[0] : [],
    newUserRole: roles && roles.newuser ? roles.newuser[0] : [],
    selectedAgentCompany
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...commonDuck.actions, ...customerDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "EditCustomer",
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(EditCustomer)
  )
);
