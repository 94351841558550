import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import moment from "moment";
import { Button, Col, Modal, Row } from "react-bootstrap";

const PlanPopUp = (props) => {
  const { data } = props;
  let mob_num = "";
  if (
    data &&
    data.active_subscription_details &&
    data.active_subscription_details.length > 0
  ) {
    mob_num = `(${data.active_subscription_details[0].phone_number.substr(
      0,
      3
    )}) ${data.active_subscription_details[0].phone_number.substr(
      3,
      3
    )}.${data.active_subscription_details[0].phone_number.substr(6, 4)}`;
  }
  return (
    <Fragment>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="subscriptionInvoice_Modal planPopup-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data &&
          data.active_subscription_details &&
          data.active_subscription_details.length > 0 ? (
            <div className="profile_modal">
              <Row>
                <Col sm={6}>
                  <div className="left_part">
                    <div className="details_col">
                      <label>Company Name</label>
                      <span>
                        {data.active_subscription_details[0].company_name}
                      </span>
                    </div>
                    <div className="details_col">
                      <label>Email Address</label>
                      <span>{data.active_subscription_details[0].email}</span>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="right_part">
                    <div className="details_col">
                      <label>Name</label>
                      <span>
                        {data.active_subscription_details[0].user_name}
                      </span>
                    </div>
                    <div className="details_col">
                      <label>Phone Number</label>
                      <span>{mob_num}</span>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <Typography variant="h5">Subscription Details</Typography>
                </Col>
                <Col sm={6}>
                  <div className="left_part">
                    <div className="details_col">
                      <label>Plan Type</label>
                      <span
                        className={
                          data.active_subscription_details[0].plan_name ==
                          "Premium"
                            ? "plan_type premium"
                            : data.active_subscription_details[0].plan_name ==
                              "Plus"
                            ? "plan_type pro"
                            : "plan_type starter"
                        }
                      >
                        {data.active_subscription_details[0].plan_name}
                      </span>
                    </div>
                    <div className="details_col">
                      {data &&
                        data.subscriptionName &&
                        data.subscriptionName == "Active" && (
                          <>
                            <label>Price</label>
                            <span>
                              ${data.amount && data.amount.toFixed(2)}
                            </span>
                          </>
                        )}
                    </div>
                    <div className="details_col">
                      {data &&
                        data.subscriptionName &&
                        data.subscriptionName == "Active" && (
                          <>
                            <label>Payment Method</label>
                            <span>
                              <span className="text-capitalize d-inline">
                                {data.cardTypes && data.cardTypes}
                              </span>{" "}
                              ending in {data.card_no && data.card_no}
                            </span>
                          </>
                        )}
                    </div>
                    <div className="details_col">
                      <label>User Type</label>
                      <span>
                        <span className="text-capitalize d-inline">
                          {data.active_subscription_details[0].user_type}
                        </span>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="right_part">
                    <div className="details_col">
                      <label>Account Created</label>
                      <span>
                        {moment(
                          data.active_subscription_details[0].account_created
                        ).format("MMM DD, YYYY")}
                      </span>
                    </div>
                    <div className="details_col">
                      {data &&
                      data.subscriptionName &&
                      data.subscriptionName == "Active" ? (
                        <>
                          <label>Date of next billing cycle</label>
                          <span>
                            {moment(
                              data.active_subscription_details[0]
                                .next_payment_date
                            ).format("MMM DD, YYYY")}
                          </span>
                        </>
                      ) : (
                        <>
                          <label>Account Cancelled</label>
                          <span>
                            {moment(
                              data.active_subscription_details[0].cancleDate
                            ).format("MMM DD, YYYY")}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="details_col">
                      {data &&
                        data.subscriptionName &&
                        data.subscriptionName == "Active" && (
                          <>
                            <label>Billed</label>
                            <span>
                              {data &&
                              data.active_subscription_details[0] &&
                              data.active_subscription_details[0].duration ===
                                "Annually"
                                ? "Yearly"
                                : data.active_subscription_details[0].duration}
                            </span>
                          </>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center loading">
              Please Wait.....
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-dark" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PlanPopUp;
