import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import {
    required,
    number,
    greaterThanZeroWithTwoDecimalPlace
} from '../../../../config/validation'
import { renderSelectField, renderNumberField, renderTextInputField } from '../../../../common/layout/FormInput'
import { toastr } from 'react-redux-toastr'
import { MESSAGES } from '../../../../config/message'

const AddValueForm = ({
    onAddColor,
    handleSubmit,
    reset,
    getfromNPCSetting,
    getAllPaperType,
    disableFormField
}) => {


    /**
     * @method handleOnSubmit
     * @description handle on submit form
     * @param {*} values 
     * @param {*} actions 
     */
    const handleOnSubmit = (values) => {
        // //console.warn('#############',values);
        if(values.color !== "" && values.cost !== "")
        {
            // const color = colors.find(c => parseInt(c.id) === parseInt(values.color));
            // const papertype=paperTypes.find(p=>parseInt(p.value)==parseInt(values.papertype));
            if(parseInt(values.cost) >= 0 ){
                onAddColor({ value: values.color, cost: values.cost})
                reset();
            }
            // else{
            //     toastr.error("Failure", MESSAGES.NEGATIVE_VALUES);
            // }
            
        }
    }

   
    //const [paperTypes, setPaperTypes] = useState([{value:null,label:'select'},{value:1,label:'Glossy'},{value:2,label:'Matte'}]);


    return (
        <div className="form-row ">
            <div className="col-md-5">
                <div className="form-group">
                    <Field
                        name="color"
                        component={renderTextInputField}
                        label="Value"
                        placeholder="Some Value"
                        disabled={disableFormField}
                    />
                </div>
            </div>
            <div className="col-md-4 mt-1">
                <div className="form-group">
                    <Field
                        name="cost"
                        component={renderNumberField}
                        validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                        ]}
                        disabled={disableFormField}
                        placeholder="Cost"
                        min="0"
                        step=".01"
                        helpText={'Only Numeric value with up to 2 decimal places'}
                    />
                </div>
            </div>
            <div className="col-md-1 mt-4">
                <div className="form-group">
                    <button
                        type="button"
                        onClick={handleSubmit(handleOnSubmit)}
                        className="btn btn-sm active btn-secondary rounded-0">
                        +
                    </button>
                </div>
            </div>
        </div>
    )
}

/**
 * @method mapStateToProps
 * @description map redux store state to props
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        color: '',
        cost: ''
    }
})

export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addColorForm',
})(AddValueForm))

