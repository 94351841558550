import Aos from "aos";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "./Slider";

const Effeciency = () => {
  React.useEffect(() => {
    Aos.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    Aos.refresh();
  });
  return (
    <>
      <div className="lp_Effeciency_main">
        <Container>
          <div className="heading">
            <h2
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >
              Maximize efficiency, client satisfaction, and sales
            </h2>
          </div>
          <Row>
            <Col md={12}>
              <Slider />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Effeciency;
