import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, untouch } from "redux-form";
import { injectIntl } from "react-intl";
import { Row, Col, Table } from "reactstrap";
import { toastr } from "react-redux-toastr";
import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import { renderSelectField } from "../../../../common/layout/FormInput";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { STATUS_CODES } from "../../../../common/StatusCode";
import * as customerAccessDuck from "../../../../store/ducks/npc/CustomerAccess.duck";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import {
  renderText1,
  renderNumberField,
} from "../../../../common/layout/FormInput";
import { MESSAGES } from "../../../../config/message";
import { phoneNumberFormateOnChange } from "../../../../common";
import { langs } from "../../../../config/localization";
import { normalizePhone } from "../../../../config/validation";
import { required } from "../../../../config/validation";
import CheckboxRP from "../../../../common/CheckboxRP";

let requiredValue = false;
class AddDiscount extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      allowCustomer: false,
      tempArray: [],
      discountArray: [],
      isEditFlag: false,
      newsPaperAdTypeList: [],
      listArray: [],
      discountList: [],
      alreadyExist: false,
      discountType: [
        { label: "Percentage", value: "Percentage" },
        { label: "Fixed Amount", value: "Fixed Amount" },
      ],
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    const { history } = this.props;
    if (history.location.state !== undefined) {
      let data = history.location.state.customerDetail;
      this.setState({
        customerName: data.customer_name,
        email: data.customer_email,
        number: data.customer_number,
        permisssionId: data.permission_id,
        npcId: data.npc_id,
      });
    }
    this.getAllEdition();
    this.getAdType();
  }

  /**
   * @method getAllEdition
   * @description  get all bpc edition record
   */
  getAllEdition = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getfromNPCSetting(
      langs.keyname.edition,
      user_detail.newspaper.id,
      (res) => {}
    );
  };

  /**
   * @method getAdType
   * @description called to get data from api
   */
  getAdType = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getAllAdType(user_detail.newspaper.id, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.rows) &&
          this.setState({
            newsPaperAdTypeList: res.data.rows[0].advertisements,
          });
      }
    });
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { discountArray, allowCustomer, sitewideDiscount } = this.state;
    if (
      values.sitewide_discount_type === "Percentage" &&
      (values.discount < 1 || values.discount > 100)
    ) {
      toastr.warning(MESSAGES.PERCENTAGE_RANGE);
    } else if (discountArray.length > 0 && allowCustomer) {
      const requestData = {
        discounts: discountArray,
      };
      this.props.addCustomerDiscount(requestData, (res) => {
        if (
          res.status === STATUS_CODES.CREATED ||
          res.status === STATUS_CODES.OK
        ) {
          toastr.success(MESSAGES.DISCOUNT_ADD_SUCCESS);
          this.setState({
            isSubmitted: false,
            discountArray: [],
            allowCustomer: false,
            discountList: [],
          });
        } else {
          this.setState({ isSubmitted: true });
        }
      });
    } else if (
      sitewideDiscount &&
      values.sitewide_discount_type !== undefined &&
      values.discount !== undefined
    ) {
      const { email, customerName, permisssionId, npcId } = this.state;
      const requestData = {
        discounts: [
          {
            permission_id: permisssionId,
            customer_email: email,
            customer_name: customerName,
            newspaperId: npcId,
            discount_mode: "Sitewide_discount",
            discount_type: values.sitewide_discount_type,
            discount_unit: values.discount,
          },
        ],
      };
      this.props.addCustomerDiscount(requestData, (res) => {
        if (
          res.status === STATUS_CODES.CREATED ||
          res.status === STATUS_CODES.OK
        ) {
          toastr.success(MESSAGES.DISCOUNT_ADD_SUCCESS);
          this.setState({
            isSubmitted: false,
            discountArray: [],
            sitewideDiscount: false,
          });
          this.props.reset();
        } else {
          this.setState({ isSubmitted: true });
        }
      });
    } else if (
      discountArray.length === 0 &&
      allowCustomer &&
      !sitewideDiscount
    ) {
      toastr.warning(MESSAGES.DISCOUNT_VALIDATION);
    } else if (
      discountArray.length === 0 &&
      sitewideDiscount &&
      values.sitewide_discount_type === undefined &&
      values.discount === undefined
    ) {
      toastr.warning(MESSAGES.DISCOUNT_VALIDATION);
    }
  }

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
    });
  };

  /**
   * @method renderDiscountRecord
   * @description  render discount listing
   */
  renderDiscountRecord = (discountList) => {
    return (
      discountList &&
      Array.isArray(discountList) &&
      discountList.map((item, index) => {
        return (
          <tbody>
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{item.edition_name}</td>
              <td>{item.ad_name}</td>
              <td>{item.discountName}</td>
              <td>{item.discount_unit}</td>
              <td>
                <Button
                  type="button"
                  className="btn-rp-primary table-action-btn "
                  onClick={() => this.getDiscountDetail(index)}
                >
                  Edit
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="button"
                  className="btn-rp-primary table-action-btn "
                  onClick={(e) => {
                    if (this.state.isEditFlag) {
                      this.setState({ isEditFlag: false }, () => {
                        this.props.resetDetail();
                        this.removeDiscount(index);
                        this.resetFields();
                      });
                    } else {
                      this.removeDiscount(index);
                    }
                  }}
                >
                  Delete
                </Button>
              </td>
            </tr>
          </tbody>
        );
      })
    );
  };

  /**
   * @method getDiscountDetail
   * @description get discount detail by id
   */
  getDiscountDetail = (index) => {
    const tempArray = this.state.discountArray;
    this.props.getDiscountDetailById(tempArray[index]);
    this.setState({
      isEditFlag: true,
      indexPosition: index,
      discount_unit: tempArray[index].discount_unit,
      edition_id: tempArray[index].edition_id,
      ad_type_id: tempArray[index].ad_type_id,
      discount: tempArray[index].discount_type,
    });
  };

  /**
   * @method removeEmail
   * @description Used to remove email id
   */
  removeDiscount = (index) => {
    const tempArray = this.state.discountList;
    tempArray.splice(index, 1);
    this.setState({ discountList: tempArray });
  };

  resetFields = () => {
    this.props.resetDetail();
    this.props.change("edition_id", "");
    this.props.change("ad_type", "");
    this.props.change("discount_type", "");
    this.props.change("discount_unit", "");
    this.props.dispatch(untouch("AddDiscount", "edition_id"));
    this.props.dispatch(untouch("AddDiscount", "ad_type"));
    this.props.dispatch(untouch("AddDiscount", "discount_type"));
    this.props.dispatch(untouch("AddDiscount", "discount_unit"));
    this.setState({
      edition_id: "",
      ad_type_id: "",
      discount_unit: "",
      discount_type: "",
      isEditFlag: false,
    });
  };

  /**
   * @method addDiscountHandler
   * @description  add discount data into  local formData object
   */
  addDiscountHandler = () => {
    const { discountList } = this.state;
    const {
      discountName,
      email,
      alreadyExist,
      customerName,
      permisssionId,
      npcId,
      listArray,
      tempArray,
      edition_name,
      ad_name,
      edition_id,
      ad_type_id,
      discount_type,
      discount_unit,
      isEditFlag,
      indexPosition,
    } = this.state;
    requiredValue =
      required(edition_name) ||
      required(ad_name) ||
      required(discountName) ||
      required(discount_unit);
    console.log("requiredValue", requiredValue);
    if (requiredValue) {
      console.log(
        "ad_type_id",
        required(ad_type_id),
        ad_type_id,
        required(edition_id),
        required(discount_type)
      );
      toastr.warning("Mandatory fields are required");
      return true;
    } else if (
      discount_type === "Percentage" &&
      (discount_unit < 1 || discount_unit > 100)
    ) {
      toastr.warning(MESSAGES.PERCENTAGE_RANGE);
    } else if (
      discountList.length !== -1 &&
      !isEditFlag &&
      discountList.some(
        (person) =>
          person.edition_id === edition_id && person.ad_type_id === ad_type_id
      )
    ) {
      toastr.warning(
        `Record containing ${edition_name} and ${ad_name} already exist.`
      );
    } else if (
      !alreadyExist &&
      edition_id !== "" &&
      ad_type_id !== "" &&
      discount_type !== "" &&
      discount_unit !== "" &&
      !isEditFlag &&
      requiredValue === undefined
    ) {
      tempArray.push({
        permission_id: permisssionId,
        customer_email: email,
        customer_name: customerName,
        discount_mode: "ad_type_discount",
        newspaperId: npcId,
        edition_id: edition_id,
        ad_type_id: ad_type_id,
        discount_type: discountName,
        discount_unit: discount_unit,
      });
      listArray.push({
        edition_name: edition_name,
        ad_name: ad_name,
        edition_id: edition_id,
        ad_type_id: ad_type_id,
        discount_type: discount_type,
        discount_unit: discount_unit,
        discountName: discountName,
      });
      this.resetFields();
      this.setState({ discountList: listArray, discountArray: tempArray });
    } else if (isEditFlag && requiredValue === undefined) {
      tempArray[indexPosition].edition_id = edition_id;
      tempArray[indexPosition].ad_type_id = ad_type_id;
      tempArray[indexPosition].discount_type = discountName;
      tempArray[indexPosition].discount_unit = discount_unit;
      listArray[indexPosition].edition_name = edition_name;
      listArray[indexPosition].ad_name = ad_name;
      listArray[indexPosition].edition_id = edition_id;
      listArray[indexPosition].ad_type_id = ad_type_id;
      listArray[indexPosition].discount_unit = discount_unit;
      listArray[indexPosition].discount_type = discountName;
      listArray[indexPosition].discountName = discountName;
      this.resetFields();
      this.setState({ discountList: listArray, discountArray: tempArray });
    }
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { handleSubmit, pristine, submitting, editionList } = this.props;
    const {
      discountType,
      number,
      newsPaperAdTypeList,
      allowCustomer,
      isSubmitted,
      discountList,
      sitewideDiscount,
      email,
      customerName,
      ad_type_id,
      discount_unit,
    } = this.state;
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Create Discount</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <Portlet fluidHeight={true}>
          <PortletBody>
            <form
              noValidate
              className="form"
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <Row>
                <Col md="6">
                  <label className="ml-4">Customer Name</label>
                  <Field
                    name="customer_name"
                    Value={customerName}
                    component={renderText1}
                    className="bottomBorder"
                    disabled={true}
                  />
                </Col>
                <Col md="3">
                  <label className="ml-4">Email Id</label>
                  <Field
                    name="email"
                    Value={email}
                    component={renderText1}
                    className="bottomBorder"
                    disabled={true}
                  />
                </Col>
                <Col md="3">
                  <label className="ml-4">Phone Number</label>
                  <Field
                    name="phone_number"
                    type={"text"}
                    autoComplete="off"
                    onChange={this.onChange}
                    Value={number}
                    component={renderText1}
                    className="bottomBorder"
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <h3>Discount</h3>
              </Row>
              <Row>
                <div
                  style={{
                    backgroundColor: "#dcdcdccc",
                    cursor: "pointer",
                  }}
                  className="showMorebtn d-flex align-items-center mt-4 py-3 w-100"
                >
                  <CheckboxRP
                    label={<h4 className="m-0">AD TYPE DISCOUNT</h4>}
                    className="ml-4"
                  >
                    <Input
                      type="checkbox"
                      style={{
                        width: "1.25rem",
                        height: "1.25rem",
                        color: "white",
                      }}
                      checked={allowCustomer ? true : false}
                      onChange={(e) =>
                        this.setState({
                          allowCustomer: !allowCustomer,
                          sitewideDiscount: false,
                          isSubmitted: true,
                        })
                      }
                      name="allow_cheque"
                    />
                  </CheckboxRP>
                </div>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Col md="3">
                  <Field
                    name="edition_id"
                    options={editionList}
                    // validate={requiredValue && [required]}
                    component={renderSelectField}
                    optionValue={"id"}
                    optionLabel={"name"}
                    flag={true}
                    onChange={(e) => {
                      editionList &&
                        editionList.findIndex((el) => {
                          if (el.id == e.target.value) {
                            return this.setState({
                              edition_id: el.id ? el.id : "",
                              edition_name: el.name ? el.name : "",
                            });
                          }
                        });
                    }}
                    disabled={allowCustomer ? false : true}
                    custumText={"Choose which Edition (if applicable)"}
                    placeholder="Distribution Type"
                    className="bottomBorder"
                  />
                </Col>
                <Col md="3">
                  <Field
                    name="ad_type"
                    options={newsPaperAdTypeList}
                    // validate={requiredValue && [required]}
                    component={renderSelectField}
                    optionValue={"id"}
                    optionLabel={"ad_name"}
                    flag={true}
                    custumText={"Ad Type"}
                    onChange={(e) => {
                      newsPaperAdTypeList &&
                        newsPaperAdTypeList.findIndex((el) => {
                          if (el.id == e.target.value) {
                            return this.setState({
                              ad_type_id: el.id,
                              ad_name: el.ad_name,
                            });
                          }
                        });
                    }}
                    disabled={allowCustomer ? false : true}
                    placeholder="Distribution Type"
                    className="bottomBorder"
                  />
                </Col>
                <Col md="3">
                  <Field
                    name="discount_type"
                    options={discountType}
                    // validate={requiredValue && [required]}
                    component={renderSelectField}
                    optionValue={"label"}
                    optionLabel={"label"}
                    flag={true}
                    custumText={"Type of Discount"}
                    // onChange={e =>
                    //     this.setState({
                    //         discount_type: e.target.value
                    //     })
                    // }
                    onChange={(e) => {
                      discountType.findIndex((el) => {
                        if (el.label === e.target.value) {
                          return this.setState({
                            discount_type: e.target.value,
                            discountName: el.label,
                          });
                        }
                      });
                    }}
                    disabled={allowCustomer ? false : true}
                    placeholder="Distribution Type"
                    className="bottomBorder"
                  />
                </Col>
                <Col md="2">
                  <Field
                    name="discount_unit"
                    placeholder="Amount"
                    type="text"
                    // validate={requiredValue && [required]}
                    normalize={normalizePhone}
                    onChange={(e) =>
                      this.setState({
                        discount_unit: e.target.value,
                      })
                    }
                    Value={discount_unit}
                    disabled={allowCustomer ? false : true}
                    component={renderNumberField}
                    className="bottomBorder"
                  />
                </Col>
                {allowCustomer && (
                  <Col md="1">
                    <button
                      type="button"
                      onClick={this.addDiscountHandler}
                      disabled={pristine || submitting}
                      style={{
                        color: "black",
                        marginTop: 32,
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  </Col>
                )}
              </Row>
              {allowCustomer && discountList.length > 0 && (
                <Row>
                  <Table bordered>
                    <thead style={{ backgroundColor: "#dcdcdccc" }}>
                      <tr>
                        <th>#</th>
                        <th>Edition</th>
                        <th>Ad Type</th>
                        <th>Discount Type</th>
                        <th>Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {discountList.length &&
                      this.renderDiscountRecord(discountList)}
                  </Table>
                </Row>
              )}
              <Row>
                <div
                  style={{
                    backgroundColor: "#dcdcdccc",
                    cursor: "pointer",
                  }}
                  className="showMorebtn d-flex align-items-center mt-4 py-3 w-100"
                >
                  <CheckboxRP
                    label={<h4 className="m-0">SITEWIDE DISCOUNT</h4>}
                    className="ml-4"
                  >
                    <Input
                      type="checkbox"
                      style={{
                        width: "1.25rem",
                        height: "1.25rem",
                        color: "white",
                      }}
                      checked={sitewideDiscount ? true : false}
                      onChange={(e) =>
                        this.setState({
                          sitewideDiscount: !sitewideDiscount,
                          allowCustomer: false,
                          isSubmitted: true,
                          discountArray: [],
                          discountList: [],
                        })
                      }
                      name="sitewide"
                    />
                  </CheckboxRP>
                </div>
              </Row>
              {sitewideDiscount && (
                <Row style={{ marginTop: 5 }}>
                  <Col md="3">
                    <Field
                      name="sitewide_discount_type"
                      options={[
                        { label: "Percentage", value: "Percentage" },
                        { label: "Fixed Amount", value: "Fixed Amount" },
                      ]}
                      component={renderSelectField}
                      optionValue={"label"}
                      optionLabel={"label"}
                      flag={true}
                      custumText={"Type of Discount"}
                      placeholder="Distribution Type"
                      className="bottomBorder"
                    />
                  </Col>
                  <Col md="2">
                    <Field
                      name="discount"
                      placeholder="discount_unit"
                      type="text"
                      normalize={normalizePhone}
                      component={renderNumberField}
                      className="bottomBorder"
                    />
                  </Col>
                </Row>
              )}
              <Row style={{ marginTop: 100 }}>
                <Col md="12 text-right float-right">
                  <Button
                    disabled={isSubmitted ? false : true}
                    style={{ background: "black", border: "none" }}
                    size="md"
                    type="submit"
                  >
                    Give Discount
                  </Button>
                </Col>
              </Row>
            </form>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, customerAccessDuck, authToken, npcDuck } = store;
  const { discountData } = customerAccessDuck;
  let initialValues = {};
  if (discountData && discountData !== undefined) {
    initialValues = {
      edition_id: discountData.edition_id,
      ad_type: discountData.ad_type_id,
      discount_type: discountData.discount_type,
      discount_unit: discountData.discount_unit,
    };
  }
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    initialValues,
    editionList: Array.isArray(npcDuck.npcSettingList.edition)
      ? npcDuck.npcSettingList.edition
      : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...customerAccessDuck.actions,
    ...npcDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "AddDiscount",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(AddDiscount)
  )
);
