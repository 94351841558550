import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
    API
} from '../../config';
import {
    CALL_LOADING
} from '../../config/constant';
import { apiErrors } from '../../common/HandleAPIErrors'


export const actionTypes = {
    SetNotificationList: '[SetNotificationList] Action',
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    notificationList: [],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: 'demo1-auth', whitelist: ['user', 'authToken'] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for customer Request List */
            case actionTypes.SetNotificationList: {
                const { notificationList } = action.payload;
                return { ...state, notificationList: [...notificationList] };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
   * @method getNotificationList
   * @description get all template listing
   */
    getNotificationList: (requestData, callback) => {
        const { id, receiver_id } = requestData
        const request = axios.get(`${API.getNotification}?id=${id}&receiver_id=${receiver_id}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response)
                return dispatch({ type: actionTypes.SetNotificationList, payload: { notificationList: response.data.rows } })

            }).catch((error) => {
                callback(error)
                //  tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                dispatch({ type: CALL_LOADING })
                callback(error);
            });
        };
    },

    /**
     * @method updateNotificationCount
     * @description Update notification count
     */
    updateNotificationCount: (requestData, callback) => {
        let request = axios.put(`${API.getNotification}`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },
};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





