import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../../../_metronic/_assets/media/images/landing-page/pijen-tm-logo.png";
import Facebook from "../../../../../_metronic/_assets/media/images/landing-page/facebook.png";
import Twiiter from "../../../../../_metronic/_assets/media/images/landing-page/twitter.png";
import Instagram from "../../../../../_metronic/_assets/media/images/landing-page/instagram.png";
import Linkedin from "../../../../../_metronic/_assets/media/images/landing-page/linkedin.png";
import Youtube from "../../../../../_metronic/_assets/media/images/landing-page/youtube.png";

const FooterSec = () => {
  return (
    <>
      <footer className="lp_footer_sec">
        <Container>
          <Row>
            <Col xl={4} lg={4} className="logo_col">
              <div className="footer-logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </Col>
            <Col xl={4} lg={4} className="page_links_col">
              <div className="page_links">
                <ul>
                  <li>
                    <a href="email-campaign/auth/login">Log in</a>
                  </li>
                  <li>
                    <Link to="/auth/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ’s</Link>
                  </li>
                  <li>
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} lg={4} className="social_links_col">
              <div className="social_links">
                <ul>
                  <li>
                    <a href="https://www.youtube.com/@pijensystems">
                      <img src={Youtube} alt="youtube" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/Pijensystem/">
                      <img src={Facebook} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Pijensystem">
                      <img src={Twiiter} alt="twitter" />
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://www.instagram.com/pijensystems/">
                      <img src={Instagram} alt="instagram" />
                    </a>{" "}
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/pijen/about/">
                      <img src={Linkedin} alt="linkedin" />
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default FooterSec;
