import React from "react";
import { Button, Modal } from "react-bootstrap";

export default class ConformModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  /**
   * @method componentWillReceiveProps
   * @description receive props from component
   */
  componentWillReceiveProps(nextprops, props) {
    if (props.isOpen !== nextprops.isOpen) {
      this.setState({ show: nextprops.isOpen });
    }
  }

  /**
   * @method handleClose
   * @description used to close model
   */
  handleClose() {
    this.setState({ show: false });
    this.props.onCancelClick();
  }

  /**
   * @method handleShow
   * @description used for show model
   */
  handleShow() {
    this.setState({ show: true });
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { totalCount, message, rejectionMessage } = this.props;
    return (
      <>
        <Modal
          aria-labelledby="example-modal-sizes-title-sm"
          show={this.props.isOpen}
          onHide={this.handleClose}
          centered={true}
        >
          <Modal.Body>
            <strong>{totalCount !== undefined && totalCount}</strong> &nbsp;
            {totalCount !== undefined && message}
            {rejectionMessage && `${rejectionMessage}`}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              No, cancel
            </Button>
            <Button variant="primary" onClick={this.props.onOkClick}>
              Yes, I’m Sure
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
