import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute1 = ({component: Component, ...rest}) => {

    // const { user_detail } = loggedInUser
    // const [category, setCategory] = useState([]);
    // const [print, setPrint] = useState("");
    // const [social_media, setSocial] = useState("");
    // const [out_of_box, setOutOfBox] = useState("");
    // const [count, setCount] = useState(0);

    // const getCatName = (value) => {
    //     const a = value.split(" ")
    //     var new_val="";
    //     for(let i = 0; i< a.length; i++){
    //       if(a[i] != ""){
    //         // //console.warn(a[i])
    //         new_val = new_val + a[i]
    //       }
    //     }
    //     // //console.warn(new_val)
    //     return new_val;
        
    //   }

    // useEffect(() => {
    //     getDropdown(user_detail.newspaper.id, (res) => {
    //       const planType = res.data;
    
    //       setCategory(planType)
    //       //console.warn("111111",category)
    //       if(category){
    //         for(let i = 0; i< categoryDropdown.length; i++){
    //           var cat_name = getCatName(categoryDropdown[i].name);
    //           // //console.warn("Cat Name", cat_name)
    //           if(cat_name == "Print"){
    //             // setPrint(cat_name)
                
    //             //Print
    //             setPrint(cat_name)
    //             //console.warn(">>Print>>", print)
    //             // //console.warn(cat_name)
    //             setCount(1)
    //           }
    //           else if(cat_name == "SocialMediaPlan"){
    //             setSocial(cat_name)
    //             // //console.warn(">>Social>>",social_media)
    //             //Social Media Plan
    //           }
    //           else if(cat_name == "OutofHome"){
    //             setOutOfBox(cat_name)
    //             // //console.warn(">>Out>>",out_of_box)
    //           }
    //         }
    //       }
    
    //     })
    
    //   },[category, user_detail.newspaper.id, categoryDropdown, getDropdown])


      return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            localStorage.getItem("Social") ?
                <Component {...props} />
            : <Redirect to="/ourPlans" />
        )} />
    );
};


export default PrivateRoute1;