import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import C3Chart from "react-c3js";
import { toastr } from "react-redux-toastr";
import "../Chart.scss";
import FilterRecords from "../FilterRecords";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { convertISOToUtcDateformate3 } from "../../../../../common";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { Input, Label } from "reactstrap";
import BlankChart from "../../../../../common/BlankChart";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";
import RadioButtonRP from "../../../../../common/RadioButtonRP";

let dateRange = [];
let label = getCurrentDate();
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesTrend: ["Orders"],
      dateAxis: [],
      type: false,
      d: [],
      amount: ["Payout_Amount"],
      linevisible: true,
      barvisible: false,
      openModal: false,
      filterType: 0,
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getGraphData(0, 2);
  }

  /**
   * @method getGraphData
   * @description  render graph statical records
   */
  getGraphData = (data, type) => {
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      data: data,
      type: type,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.setData(requestData, data);
  };

  /**
   * @method setData
   * @description set chart records
   */
  setData = (requestData, data) => {
    this.props.getPayoutSummaryGraphDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        const { amount, salesTrend } = this.state;
        let temp = salesTrend;
        let temp1 = amount;
        let temp2 = salesTrend;
        let temp3 = amount;
        res.data &&
          Array.isArray(res.data.payoutTrend) &&
          res.data.payoutTrend.map((item, i) => {
            if (data === 0) {
              temp.push(item.no_of_transaction);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            } else if (data === 1) {
              temp1.push(item.payout_amount);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            } else if (data === 2) {
              temp2.push(item.no_of_transaction);
              temp3.push(item.payout_amount);
              dateRange.push(convertISOToUtcDateformate3(item.createdAt));
            }
          });
        if (data === 0) {
          this.setState({
            salesTrend: [...temp],
            linevisible: true,
            barvisible: false,
            filterType: 0,
            openModal: false,
          });
        } else if (data === 1) {
          this.setState({
            amount: [...temp1],
            barvisible: true,
            linevisible: false,
            filterType: 1,
            openModal: false,
          });
        } else if (data === 2) {
          this.setState({
            amount: [...temp2],
            salesTrend: [...temp3],
            linevisible: true,
            barvisible: true,
            filterType: 2,
            openModal: false,
          });
        }
      } else {
        this.setState({ openModal: false });
      }
    });
  };

  /**
   * @method handleFilter
   * @description  used to handle filtered records
   */
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  used to apply filter on graph data
   */
  applyFilter(values, props) {
    const { filterType } = this.state;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 2,
      data: filterType,
      month: data.this_month,
      year: data.this_year,
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      salesTrend: ["Orders"],
      amount: ["Payout_Amount"],
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
    });
    this.setData(requestData, filterType);
  }

  /**
   * @method dateAxis
   * @description  used to formate date
   */
  dateAxis = () => {
    return dateRange;
  };

  /**
   * @method transactionSelection
   * @description  used to handle no. of transaction
   */
  transactionSelection = () => {
    this.setState(
      {
        salesTrend: ["Orders"],
        amount: ["Payout_Amount"],
      },
      () => {
        this.getGraphData(0, 2);
      }
    );
  };

  /**
   * @method amountSelection
   * @description  used to handle amount selection
   */
  amountSelection = () => {
    this.setState(
      {
        amount: ["Payout_Amount"],
        salesTrend: ["Orders"],
      },
      () => {
        this.getGraphData(1, 2);
      }
    );
  };

  /**
   * @method bothSelection
   * @description  used to handle no. of transaction and amount selection
   */
  bothSelection = () => {
    this.setState(
      {
        amount: ["Payout_Amount"],
        salesTrend: ["Orders"],
      },
      () => {
        this.getGraphData(2, 2);
      }
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const {
      salesTrend,
      openModal,
      type,
      amount,
      linevisible,
      barvisible,
      selectedOption,
    } = this.state;
    return (
      <div>
        <div className="row col-md-12">
          <h5 className="float-left">Payout Trend</h5>
        </div>
        <div className="row ">
          <div className="col-md-7 d-flex-center flex-wrap">
            <div className="mr-2">Select Criteria</div>
            <div>
              <div className="radio-inline">
                <RadioButtonRP label="No. of Transactions">
                  <Input
                    type="radio"
                    id="checkbox2"
                    checked={!type ? true : ""}
                    onChange={(e) => {
                      this.setState({
                        type: false,
                        linevisible: true,
                        barvisible: false,
                      });
                      this.transactionSelection();
                    }}
                    name="order_type"
                  />
                </RadioButtonRP>
                <RadioButtonRP label="Payout Amount">
                  <Input
                    type="radio"
                    id="checkbox2"
                    onChange={(e) => {
                      this.setState({
                        type: true,
                        barvisible: true,
                        linevisible: false,
                      });
                      this.amountSelection();
                    }}
                    name="order_type"
                  />
                </RadioButtonRP>
                <RadioButtonRP label="Both">
                  <Input
                    type="radio"
                    id="checkbox2"
                    onChange={(e) => {
                      this.setState({
                        type: true,
                        linevisible: true,
                        barvisible: true,
                      });
                      this.bothSelection();
                    }}
                    name="order_type"
                  />
                </RadioButtonRP>
              </div>
            </div>
          </div>

          <div className="col-md-5 d-flex-center justify-content-end">
            <div className="mr-2 mb-2">Select Durations</div>
            <h5
              className="cursorPointer"
              onClick={() => this.setState({ openModal: true })}
            >
              {label}
              &nbsp;&nbsp;
              <i
                style={{ fontSize: 20 }}
                className="fa fa-angle-down"
                aria-hidden="true"
              ></i>
            </h5>
          </div>
        </div>
        {amount.length > 1 || salesTrend.length > 1 ? (
          <div style={{ marginTop: 30 }} className="row w-100">
            {linevisible && !barvisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Orders",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [salesTrend],
                  type: "line",
                }}
              />
            )}
            {barvisible && !linevisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Amount",
                      position: "outer-middle",
                    },
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [amount],
                  type: "bar",
                }}
              />
            )}
            {linevisible && barvisible && (
              <C3Chart
                axis={{
                  x: {
                    type: "category",
                    show: true,
                    categories: this.dateAxis(),
                  },
                  y: {
                    label: {
                      text: "Orders",
                      position: "outer-middle",
                    },
                  },
                  y2: {
                    show: true,
                    label: {
                      text: "Amount",
                      position: "outer-middle",
                    },
                    min: 0,
                  },
                }}
                unloadBeforeLoad={true}
                data={{
                  columns: [amount, salesTrend],
                  axes: {
                    Payout_Amount: "y2",
                  },
                  type: "line",
                  types: {
                    Payout_Amount: "bar",
                  },
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ marginTop: 30 }} className="row w-100">
            <BlankChart />
          </div>
        )}
        {openModal && (
          <FilterRecords
            applyFilter={this.handleFilter}
            isOpen={openModal}
            selectedOption={selectedOption}
            onCancel={() => this.setState({ openModal: false })}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    payoutSummaryDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "Chart",
      destroyOnUnmount: false,
    })(Chart)
  )
);
