import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { CSVLink } from "react-csv";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { NPCAdtypeHeaders } from "../../../../../common/CsvFormate";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";

class AdTypeListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      adTypeList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getadTypeReport(25, 1, "", "");
  }

  /**
   * @method getadTypeReport
   * @description  used to get adtype report data
   */
  getadTypeReport = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 4,
    };
    this.props.getAdTypeReportListing(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.topAdType) &&
          this.handleListCount(res.data.topAdType);
      }
    });
  };

  /**
   * @method handleListCount
   * @description  handle total record display
   */
  handleListCount = (res) => {
    let temp = [];
    if (res.length <= 4) {
      temp = res.slice(0, res.length);
      this.setState({ adTypeList: temp });
    } else {
      let len = !this.state.viewAll ? 4 : res.length;
      temp = res.slice(0, len);
      this.setState({ adTypeList: temp });
    }
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getadTypeReport(this.state.pageSize, 1, "", "");
      }
    );
  };

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const { pageSize, count, entries } = this.state;
    const { adTypeReportListing } = this.props;
    if (this.state.viewAll) {
      this.setState({ loader: true });
      this.setState({ loader: false });
      if (adTypeReportListing.length) {
        this.setState({ loader: false }, () => {
          const { user_detail } = this.props.loggedInUser;
          let requestData = {
            npc_id: user_detail.newspaper.id,
            row: pageSize + entries,
            page: 1,
            type: 4,
          };
          adTypeReportListing.length < count &&
            this.props.getAdTypeReportListing(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                let pages = res.data.count / this.state.pageSize;
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  totalCount: Math.ceil(pages),
                  count: res.data.count,
                });
                setTimeout(() => {
                  this.setState({
                    localLoading: false,
                    companyList: [...res.data.rows],
                  });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method getAllRecords
   * @description  get all adtype record
   */
  getAllRecords = (pageSize, page) => {
    this.getadTypeReport(pageSize, page, "", "");
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({ sortedOrder: !sortedOrder });
    this.getadTypeReport(this.state.pageSize, 1, sortBy, column.id);
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Ad Plan Name",
        accessor: "ad_name",
        Cell: (row) => {
          const { original } = row;
          return original.ad_name;
        },
      },
      {
        Header: "Order Count",
        accessor: "total_orders",
        Cell: (row) => {
          const { original } = row;
          return <span>{original.total_orders}</span>;
        },
      },
      {
        Header: "Total Sales",
        accessor: "sales_revenue",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ display: "-webkit-box" }}>
              ${original.sales_revenue}
            </div>
          );
        },
      },
      {
        Header: "Fees",
        accessor: "commission_fee",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ display: "-webkit-box" }}>
              ${original.commission_fee}
            </div>
          );
        },
      },
      {
        Header: "Total Revenue",
        accessor: "total_revenue",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>${original.total_revenue}</div>
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { viewAll, page, pageSize, localLoading, adTypeList } = this.state;
    const { adTypeReportListing } = this.props;
    const headers = NPCAdtypeHeaders;
    return (
      <div style={{ marginTop: -15 }}>
        <Portlet fluidHeight={true}>
          <PortletBody className="px-4" ref={(el) => (this.componentRef = el)}>
            <div className="row ">
              <div className="col-md-12 mt-5">
                <h5 className="float-left">Ad Type Report</h5>
                <h5 className="float-right border-bottom-0 border-primary">
                  <CSVLink
                    filename={"AdtypeDetails.csv"}
                    data={adTypeReportListing}
                    headers={headers}
                    style={{ borderBottom: "1px solid" }}
                  >
                    Export CSV
                  </CSVLink>
                </h5>
                <h5 className="float-right mr-4">
                  <Link>
                    <ReactToPrint
                      trigger={() => (
                        <a href="#" style={{ borderBottom: "1px solid" }}>
                          Print
                        </a>
                      )}
                      content={() => this.componentRef}
                    />
                  </Link>
                </h5>
              </div>
            </div>
            <div className="row" style={{ border: "1px solid black" }}></div>
            <div className="row">
              <div className="col-12">
                {adTypeList && (
                  <InfiniteScroll
                    dataLength={adTypeList.length}
                    next={this.fetchMoreData}
                    loader={
                      localLoading && (
                        <h4 style={{ textAlign: "center" }}>Loading...</h4>
                      )
                    }
                    hasMore={true}
                    style={{ overflow: "unset" }}
                    useWindow={false}
                  >
                    <StandardTable
                      handelSearch={this.handleSearch}
                      columnOrder={this.renderRecords()}
                      handleSort={this.handleSort}
                      sortable={false}
                      onPageSizeChange={this.onPageSizeChange}
                      showSearch={false}
                      data={adTypeList}
                      pagination={false}
                    />
                  </InfiniteScroll>
                )}
                {adTypeList && adTypeList.length >= 4 && !viewAll && (
                  <div
                    onClick={() => {
                      this.getAllRecords(pageSize, page);
                      this.setState({ viewAll: true, localLoading: true });
                      setTimeout(() => {
                        this.setState({ localLoading: false });
                      }, 3000);
                    }}
                  >
                    <label className="labelContent">View All</label>
                  </div>
                )}
                {localLoading && <FooterLoader />}
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { paymentReportDuck } = store;
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    adTypeReportListing:
      Array.isArray(paymentReportDuck.adTypeListing) &&
      paymentReportDuck.adTypeListing,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AdTypeListing",
    })(AdTypeListing)
  )
);
