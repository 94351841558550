import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as adManagementDuck from '../../../../../store/ducks/super-admin/AdManagement.duck';
import { injectIntl } from 'react-intl';
import CategoryListing from './CategoryListing'
import TemplateListing from './TemplateListing'
import CreateModal from './CreateModal'
import { langs } from '../../../../../config/localization';
import { checkPermission } from '../../../../../common/CheckPermissions';
import { superAdminId } from '../../../../../config'

class ManageCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditorModal: false,
            isUpdateModel: false,
            message: '',
            adTypeFlag: false,
            colorFlag: false,
            data: '',
            selectedCategoryId: ''
        };
    }

    /** 
    * @method getAllTemplatesOfCategory
    * @description  getAllTemplatesOfCategory called API to get all Templates of Category
    */
    getAllTemplatesOfCategory = () => {
        const { selectedCategoryId } = this.state;
        let reqData = { catId: selectedCategoryId }
        selectedCategoryId && this.props.getTemplatesOfCategory(reqData, (res) => {
        })
    }

    /** 
       * @method getSelectedCategory
       * @description  Called to set Category on State 
       */
    getSelectedCategoryByAdType = (adTypeId) => {
        this.setState({ selectedCategoryId: adTypeId })
    }

    /** 
    * @method getSelectedCategory
    * @description  Called to set Category on State 
    */
    getSelectedCategory = (cat) => {
        this.setState({ selectedCategoryId: cat.id })
    }


    /** 
     * @method getCategories
     * @description getCategories 
     */
    getCategories = () => {
        this.props.getAllCategoryListing(res => {
            console.log('res: &', res);
            return          
        });
    }

    /**
      * @method render
      * @description render the component
      */
    render() {
        const { selectedCategoryId, showEditorModal } = this.state;
        const createPer = checkPermission(langs.permissionKeys.manageClassifiedTemplate, langs.permissionKeys.create)
        const viewPer = checkPermission(langs.permissionKeys.manageClassifiedTemplate, langs.permissionKeys.view)
        const deletePer = checkPermission(langs.permissionKeys.manageClassifiedTemplate, langs.permissionKeys.delete)
        if (!viewPer && !createPer && !deletePer) {
            return <></>
        }
        return (
            <div className='row'>
                <div className='col-md-3' style={{ color: 'rgb(93, 87, 87)' }} >
                    <h5>Classified Templates</h5>
                    <br />
                    <CategoryListing getSelectedCategory={this.getSelectedCategory} selectedCategoryId={selectedCategoryId} />
                </div>
                <div className='col-md-9'>
                    {createPer && <div className='row' style={{ color: 'rgb(93, 87, 87)', marginTop: 20 }} >
                        <p onClick={() => this.setState({ showEditorModal: true })} style={{ cursor: 'pointer', right: 25, position: 'absolute', fontSize: 14, color: 'black' }}>+ Add New Template</p>
                    </div>}
                    <div style={{ marginTop: 40 }}>
                        <TemplateListing getSelectedCategory={this.getSelectedCategoryByAdType} getAllCategoryListing={this.getCategories()}
                        />
                    </div>
                    {showEditorModal && (
                        <CreateModal
                            isOpen={showEditorModal}
                            onCancelClick={() => this.setState({ showEditorModal: false })}
                            getSelectedCategory={this.getSelectedCategoryByAdType}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    const { auth, adManagementDuck, authToken } = store;
    return ({
        loggedInUser: auth.user,
        authToken: authToken,
        adTypeList: adManagementDuck.adTypeList,
        categoryList: adManagementDuck.categoryList,
        templateList: adManagementDuck.selectedTemplates,
        selectedCategoryId: Array.isArray(adManagementDuck.selectedTemplates) && adManagementDuck.selectedTemplates[0] && adManagementDuck.selectedTemplates[0].ad_type_id
    })
};

export default injectIntl(
    connect(
        mapStateToProps,
        adManagementDuck.actions
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'ManageCategory'
        })(ManageCategory)
    )
);
