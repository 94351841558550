import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Field } from "redux-form";
import { renderSelectField } from "../../../../common/layout/FormInput";
import { getDays, getTimes } from "../../../../config/utils";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";
const EditionDetailsSection = (props) => {
  const { fields } = props;
  const [state, setState] = useState({
    editionName: "",
    deadlineDay: "",
    deadlineTime: "",
  });

  const getOptions = useCallback(
    (index) => {
      const Fields = fields.getAll();
      const { allEditionList } = props;
      const filteredList = allEditionList?.filter((item, i) => {
        return !Fields.find((k, i) => {
          if (index == i) return false;
          return k.edition_name == item.edition_id;
        });
      });
      return (
        filteredList &&
        filteredList.map((item) => ({
          label: item.edition_name,
          value: item.edition_id,
        }))
      );
    },
    [props?.allEditionList, fields]
  );

  const setEditionName = (e, index) => {
    const Id = e.target.value;
    setState({ ...state, editionName: Id });
    props.setEdition(Id);
  };
  const addEditionRowFromButton = () => {
    const Field = fields.getAll();
    const lastItem = Field[Field.length - 1];
    const { ad_deadline, ad_deadline_time, edition_name } = lastItem;
    if (edition_name && ad_deadline && ad_deadline_time) {
      const isValid = props.addEditionRow(edition_name);
      if (Field.length !== props.allEditionList?.length && isValid) {
        props.fields.push({});
        setState({
          editionName: "",
          deadlineDay: "",
          deadlineTime: "",
        });
      } else {
        toastr.error(MESSAGES.ALL_FIELDS_ARE_REQUIRED);
      }
    } else {
      toastr.error(MESSAGES.ALL_FIELDS_ARE_REQUIRED);
    }
  };

  useEffect(() => {
    const { fields } = props;
    if (fields.length === 0) {
      props.fields.push({});
    }
  }, [props.fields.length]);

  const handleRemover = (index) => {
    const Fields = fields.getAll();
    const matched = Fields[index];
    if (props.isUpdate) {
      props.removeEdition(matched);
    }
    fields.remove(index);
  };
  return (
    <Fragment>
      {fields.map((item, index) => {
        return (
          <div className="row align-items-center" key={index}>
            <div className="col-md-3">
              <Field
                name={`${item}.edition_name`}
                component={renderSelectField}
                label="Edition"
                required={true}
                value={""}
                disabled={false}
                options={getOptions(index)}
                optionValue={"value"}
                optionLabel={"label"}
                isSelect={false}
                onChange={(e) => setEditionName(e, index)}
              />
            </div>
            <div className="col-md-3">
              <Field
                name={`${item}.ad_deadline`}
                component={renderSelectField}
                label="Ad Deadline"
                required={true}
                disabled={false}
                options={getDays()}
                optionValue={"value"}
                optionLabel={"label"}
                isSelect={false}
                onChange={(e) =>
                  setState({ ...state, deadlineDay: e.target.value })
                }
              />
            </div>
            <div className="col-md-3">
              <Field
                name={`${item}.ad_deadline_time`}
                label="Ad deadline time"
                component={renderSelectField}
                options={getTimes()}
                optionValue={"value"}
                disabled={false}
                required={true}
                optionLabel={"label"}
                isSelect={false}
                onChange={(e) =>
                  setState({ ...state, deadlineTime: e.target.value })
                }
              />
            </div>
            <div className="col-md-3">
              {fields?.length == index + 1 ? (
                <button variant="link" className="btn-link mr-3">
                  <AiOutlinePlusCircle
                    size="24"
                    onClick={() => addEditionRowFromButton()}
                  />
                </button>
              ) : (
                <button variant="link" className="btn-link red">
                  <AiOutlineMinusCircle
                    size="24"
                    onClick={() => {
                      handleRemover(index);
                    }}
                  />
                </button>
              )}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default EditionDetailsSection;
