
import React, { Fragment, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import Navtwo from './pijenhomelanding/Navtwo'
import Moreads from './pijenhomelanding/Moreads';
import Marketing from './pijenhomelanding/Marketing';
import Size from './pijenhomelanding/Size';
import Reservation from './pijenhomelanding/Reservation';
import Paymentsec from './pijenhomelanding/Paymentsec';
import Upperfooter from './pijenhomelanding/Upperfooter';
import Footer from './Footer';
import ApprovalSection from './ApprovalSection';

import '../../../_metronic/landing_assets/css/fonts.css';
import '../../../_metronic/landing_assets/css/common.css';
// import '../../../_metronic/landing_assets/css/doc.css';
import '../../../_metronic/landing_assets/js/bootstrap';

import './pijenhomelanding/All.scss';
import './pijenhomelanding/Responsive.scss';


const PijenLanding = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.title = "Pijen - Media Planning and Buying Systemized";
        const linkElement = document.querySelector('link[rel=icon]');
        linkElement.href = 'https://pijen.com/email-campaign/assets/media/logos/favicon.ico';
    },[])


    return (
        <Fragment>
            <Navtwo />
            <Moreads />
            <Marketing />
            <Size />
            <Reservation />
            <Paymentsec />
            {/* <MoreProfit /> */}
            {/* <ScheduleDemo /> */}
            <Upperfooter />
            <Footer />
        </Fragment>
    )
}

export default PijenLanding