import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import ReactToPrint from "react-to-print";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { CSVLink } from "react-csv";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import { CustomerSummaryHeaders } from "../../../../../common/CsvFormate";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";
import * as customerSummaryDuck from "../../../../../store/ducks/super-admin/CustomerSummary.duck";
import FilterRecords from "../FilterRecords";
import { getCurrentDate } from "../../../../../common/CommonFunction";
import { checkPermission } from "../../../../../common/CheckPermissions";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";

let label = getCurrentDate();
class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      customerList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
      openModal: false,
      sortBy: "",
      column: "",
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
      },
      fromDate: "",
      toDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getCustomerSummary(5, "", "", this.state.pageSize, 1, "", "");
  }

  /**
   * @method handleListCount
   * @description  ReArrabge API res according to length count
   */
  handleListCount = (res) => {
    let temp = [];
    if (res.length <= 4) {
      temp = res.slice(0, res.length);
      this.setState({ customerList: temp });
    } else {
      let len = !this.state.viewAll ? 4 : res.length;
      temp = res.slice(0, len);
      this.setState({ customerList: temp });
    }
  };

  /**
   * @method getCustomerSummary
   * @description  used to get all admin listing
   */
  getCustomerSummary = (
    type,
    data,
    print = "",
    pageSize,
    page,
    sort = "",
    column = ""
  ) => {
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      type,
      data,
      print,
      row: pageSize,
      page,
      sort,
      column,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
    };
    this.props.getCustomerSummaryData(requestData, type, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({
          headerData: res.data,
          customerList: Array.isArray(res.data.customerDetails)
            ? res.data.customerDetails
            : [],
        });
      }
    });
  };
  /**
   * @method filterRecord
   * @description  called after rendering the component
   */
  filterRecord = (values, b) => {
    this.applyFilter(values, b);
  };
  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      row: 25,
      page: 1,
      sort: "",
      column: "",
      month: data.this_month,
      year: data.this_year,
      type: 5,
      data: "",
      from_date: data.date,
      to_date: data.date2,
    };
    label = data.label;
    this.props.getCustomerSummaryData(requestData, 5, (res) => {
      if (res.status === STATUS_CODES.OK) {
        props.onCancel();
        this.setState({
          fromDate: data.date,
          toDate: data.date2,
          month: data.this_month,
          year: data.this_year,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
          customerList: Array.isArray(res.data.customerDetails)
            ? res.data.customerDetails
            : [],
        });
      }
    });
  }

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const {
      pageSize,
      count,
      entries,
      fromDate,
      toDate,
      month,
      year,
    } = this.state;
    const { payoutOrderList } = this.props;
    if (this.state.viewAll) {
      if (payoutOrderList.length) {
        this.setState({ loader: false }, () => {
          let requestData = {
            row: pageSize + entries,
            page: 1,
            sort: "",
            column: "",
            month: month ? month : "",
            year: year ? year : "",
            from_date: fromDate ? fromDate : "",
            to_date: toDate ? toDate : "",
            type: 5,
          };
          payoutOrderList.length < count &&
            this.props.getCustomerSummaryData(requestData, 5, (res) => {
              if (res.status === STATUS_CODES.OK) {
                setTimeout(() => {
                  this.setState({ localLoading: false });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({
      sortedOrder: !sortedOrder,
      sortBy: sortBy,
      column: column.id,
    });
    this.getCustomerSummary(
      5,
      "",
      "",
      this.state.pageSize,
      1,
      sortBy,
      column.id
    );
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getCustomerSummary(5, "", "", this.state.pageSize, 1, "", "");
      }
    );
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Order#",
        accessor: "order_id",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
      },
      {
        Header: "Order Amount",
        accessor: "order_count",
        Cell: (row) => {
          const { original } = row;
          return <div style={{ marginLeft: 10 }}>${original.order_count}</div>;
        },
      },
      {
        Header: "Fee",
        accessor: "sales",
        Cell: (row) => {
          const { original } = row;
          return <div style={{ marginLeft: 10 }}>${original.sales}</div>;
        },
      },
      {
        Header: "Revenue",
        accessor: "total_revenue",
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      viewAll,
      page,
      pageSize,
      localLoading,
      openModal,
      selectedOption,
      customerList,
    } = this.state;
    const { module5Data } = this.props;
    const headers = CustomerSummaryHeaders;
    const printPer = checkPermission(
      langs.permissionKeys.customerSummary,
      langs.permissionKeys.print
    );

    return (
      <div style={{ marginTop: -15 }}>
        <Portlet fluidHeight={true}>
          <PortletBody className="px-4" ref={(el) => (this.componentRef = el)}>
            <div className="row ">
              <div className="col-md-12 mt-5">
                <h5 className="float-left">Customer Details</h5>
                <h5 className="float-right border-bottom-0 border-primary">
                  <CSVLink
                    filename={"CustomerDetails.csv"}
                    data={module5Data}
                    headers={headers}
                    style={{ borderBottom: "1px solid" }}
                  >
                    Export CSV
                  </CSVLink>
                </h5>
                {printPer && (
                  <h5 className="float-right mr-4">
                    <Link>
                      <ReactToPrint
                        trigger={() => (
                          <a href="#" style={{ borderBottom: "1px solid" }}>
                            Print
                          </a>
                        )}
                        content={() => this.componentRef}
                      />
                    </Link>
                  </h5>
                )}
                <h5
                  className="float-right mx-sm-4 cursorPointer d-flex-center"
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}
                  &nbsp;&nbsp;
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </h5>
              </div>
            </div>
            <div className="row " style={{ border: "1px solid black" }}></div>
            <div className="row">
              <div className="col-12">
                <InfiniteScroll
                  dataLength={customerList.length}
                  next={this.fetchMoreData}
                  loader={
                    localLoading && (
                      <h4 style={{ textAlign: "center" }}>Loading...</h4>
                    )
                  }
                  hasMore={true}
                  style={{ overflow: "unset", padding: 10 }}
                >
                  <div>
                    <StandardTable
                      handelSearch={this.handleSearch}
                      columnOrder={this.renderRecords()}
                      handleSort={this.handleSort}
                      sortable={false}
                      onPageSizeChange={this.onPageSizeChange}
                      showSearch={false}
                      data={customerList}
                      pagination={false}
                    />
                  </div>
                </InfiniteScroll>
                {customerList && customerList.length >= 4 && !viewAll && (
                  <div
                    className="my-4"
                    onClick={() => {
                      this.getCustomerSummary(
                        5,
                        "",
                        "",
                        pageSize,
                        page,
                        "",
                        ""
                      );
                      this.setState({ viewAll: true, localLoading: true });
                      setTimeout(() => {
                        this.setState({ localLoading: false });
                      }, 3000);
                    }}
                  >
                    <label
                      className="float-right"
                      style={{ cursor: "pointer", color: "#0000ff" }}
                    >
                      View All
                    </label>
                  </div>
                )}
                {localLoading && <FooterLoader />}
              </div>
            </div>
          </PortletBody>
        </Portlet>
        {openModal && (
          <FilterRecords
            applyFilter={this.filterRecord}
            isOpen={openModal}
            onCancel={() => this.setState({ openModal: false })}
            type={5}
            selectedOption={selectedOption}
            resetList={() => this.getPayoutRecords()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { paymentReportDuck } = store;
  const { loading } = store.commonDuck;
  const { auth, customerSummaryDuck } = store;
  return {
    loggedInUser: auth.user,
    loading,
    payoutOrderList:
      paymentReportDuck &&
      paymentReportDuck.payoutReportList &&
      paymentReportDuck.payoutReportList.orderData
        ? paymentReportDuck.payoutReportList.orderData.rows
        : [],
    module5Data: customerSummaryDuck.moduleData5,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...customerSummaryDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "Listing",
    })(Listing)
  )
);
