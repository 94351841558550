import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetCustomerAccessList: "[SetCustomerAccessList] Action",
    SetCustomerDetail: "[SetCustomerDetail] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    customerRequestList: undefined,
    listArray: [],
    npcCompanyArray: [],
    customerDetail: [],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for customer Request List */
            case actionTypes.SetCustomerAccessList: {
                let tempArray = []
                let npcCompany = []
                const { customerRequestList } = action.payload;
                if (customerRequestList) {
                    Array.isArray(customerRequestList.requests) && customerRequestList.requests.map((item, index) => {
                        npcCompany.push({ lable: item.newspaper_name, viewDetail: false })
                        Object.keys(item.object).map((key) => {
                            tempArray.push(customerRequestList.requests[index].object[key]);
                        });
                    })
                }
                return { ...state, customerList: tempArray, npcCompanyArray: npcCompany };
            }

            /** set data for customer detail */
            case actionTypes.SetCustomerDetail: {
                const { customerDetail } = action.payload;
                return { ...state, customerDetail: customerDetail };
            }
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    getCustomerRequestList: (requestData, callback) => {
        const { status, sort, column, row, page } = requestData
        const request = axios.get(`${API.getCustomerAccessRequestList}?status=${status}&sort=${sort}&column=${column}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetCustomerAccessList, payload: { customerRequestList: response.data } })

            }).catch((error) => {
                callback(error)
                apiErrors(error, dispatch)
               // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    /**
    * @method accessPermissiom
    * @description admin gives permision for customer access
    */
    accessPermissiom: (requestData, callback) => {
        const request = axios.post(API.customerAcessPermission, requestData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                callback(error);
               // tokenCheck(dispatch, error)
            });
        };
    },

    /**
   * @method checkCustomerExist
   * @description check customer exists or not for customer access
   */
    checkCustomerExist: (requestData, callback) => {
        const { email } = requestData
        const request = axios.get(`${API.checkCustomerExits}?email=${email}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
            }).catch((error) => {
                apiErrors(error, dispatch)
                callback(error)
              //  tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    /**
     * @method getCustomerDetails
     * @description get customer details
     */
    getCustomerDetails: (requestData, callback) => {
        const { email, npc_id } = requestData
        const request = axios.get(`${API.customerDetail}?customer_email=${email}&npc_id=${npc_id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetCustomerDetail, payload: { customerDetail: response.data } })
            }).catch((error) => {
                callback(error)
                apiErrors(error, dispatch)
              //  tokenCheck(dispatch, error)
                return error;
            });
        };
    },

};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





