import React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { renderSelectField } from '../../../../../common/layout/FormInput';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import * as adManagementDuck from '../../../../../store/ducks/super-admin/AdManagement.duck';
import { MESSAGES } from '../../../../../config/message';
import { Loader } from '../../../../../common/Loader';
import { required } from '../../../../../config/validation';
import { PROFILE_MEDIA_URL } from '../../../../../config';
import { toastr } from 'react-redux-toastr';
import { superAdminId } from '../../../../../config';
import { STATUS_CODES } from '../../../../../common/StatusCode';
import CommonAlert from '../../../../../common/CommonAlert'

class AddTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      show: false,
      isRedirect: false,
      message: '',
      flag: false,
      isSubmitted: true,
      loading: false,
      files: [],
      pictures: [],
      adType: false,
      imageArray: [],
      images: [],
      selectedItem: '',
      adTypeArray: [],
      adTypeList: [],
      imageSelected: undefined
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    //console.warn('hit');
    let result = this.props.getValues();
    //console.warn('!!!!!!!!!!',result);
    const { newsPaperAdTypeList } = result;
    //console.warn('@@@@@@@@@@@',newsPaperAdTypeList);
    this.props.getAllAdType(superAdminId, res => {
      //console.warn('response---',res);
      const allRecords = res.data;
      let temp = allRecords

      Array.isArray(res.data) && res.data.map((item) => {
        if (newsPaperAdTypeList.some(i => i.id === item.id)) {
          temp = temp.filter((f, i) => f.id !== item.id)
        }
      })
      
      this.setState({ adTypeList: temp });
      //console.warn('########',this.state.adTypeList);
    });
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.dispatch(change('AddTypeModal', 'ad_name', ''));
    this.props.dispatch(untouch('AddTypeModal', 'ad_name'));
    this.setState({
      selectedItem: '',
      adType: false,
      imageSelected: undefined
    });
    this.props.onCancel();
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    this.setState({ isSubmitted: false });
    const { images } = this.state;
    // if (images.length < 1) {
    //   this.setState({
    //     show: true,
    //     message: MESSAGES.IMAGE_SELECTION,
    //     variant: 'danger',
    //     isSubmitted: false
    //   });
    //   return true;
    // } else {
    //   this.setState({ isSubmitted: false });
    // }
    const { user_detail } = this.props.loggedInUser;
    const { selectedItem } = this.state;
    const requestData = {
      id: user_detail.newspaper.id,
      newspaperId: user_detail.newspaper.id,
      ad_name: selectedItem.ad_name,
      ad_type: selectedItem.ad_type,
      ad_urls: images ? images : [],
      advertisementId: selectedItem.id
    };
    this.props.addAdType(requestData, res => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ADD_TYPE_ADD_SUCCESS);
        this.props.dispatch(change('AddTypeModal', 'ad_name', ''));
        this.props.dispatch(untouch('AddTypeModal', 'ad_name'));
        this.setState({ selectedItem: '' });
        this.props.callList();
        setTimeout(() => {
          this.props.onCancel();
        }, 3000);
      } else {
        this.setState({ isSubmitted: true });
      }
    });
  }

  /**
   * @method selectItem
   * @description Used to select item from image
   */
  selectItem(index, item) {
    this.setState({ isSubmitted: true, imageSelected: index, images: item.split() });
  }

  /**
   * @method renderPreviewImage
   * @description Used to render preview images
   */
  renderPreviewImage = () => {
    const { selectedItem, imageSelected } = this.state;
    return (
      selectedItem &&
      JSON.parse(selectedItem.ad_urls).map((e, i) => {
        let custumStyle = i === imageSelected;
        let style1 = {
          height: 100,
          width: 100,
          marginLeft: 20,
          marginTop: 20,
          objectFit: 'cover'
        };
        return (
          <div
            key={i}
            onClick={() => this.selectItem(i, e)}
            className='my-3'
            style={{ position: 'relative' }}
          >
            <img
              src={`${PROFILE_MEDIA_URL}/${e}`}
              style={style1}
              alt='RRR'
            ></img>
            {custumStyle && (
              <i className='fa fa-check' style={{ position: 'absolute' }}></i>
            )}
          </div>
        );
      })
    );
  };

  /**
   * @method render
   * @description Used to render component
   */
  render() {
    const { handleSubmit } = this.props;
    const { show, variant, selectedItem, adTypeList, loading, message, isSubmitted } = this.state;
    console.log('*****', adTypeList);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.toggleModel}
        styles={{ height: 10, width: 100 }}
        size="lg"
      >
        {loading && <Loader />}
        {show && <CommonAlert show={show} variant={variant} message={message} />}
        <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
          Ad Type
        </ModalHeader>
        <ModalBody styles={{ width: 600 }}>
          <Row>
            <Container>
              <form
                noValidate
                className='form'
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md='12'>
                    <Field
                      name='ad_name'
                      label='Ad Type Name'
                      validate={[required]}
                      optionValue={'id'}
                      optionLabel={'ad_name'}
                      isSelect={false}
                      required={true}
                      options={adTypeList ? adTypeList : []}
                      onChange={e => {
                        adTypeList &&
                          adTypeList.findIndex(el => {
                            if (el.id == e.target.value) {
                              console.log('el', el, el && el.ad_type)
                              return this.setState({
                                selectedItem: el,
                                isSubmitted: el.ad_type === 'Classified' ? true : false
                              });
                            }
                          });
                      }}
                      component={renderSelectField}
                      placeholder='Enter Distribution State'
                    />
                  </Col>
                </Row>
                <Row className='ml-1'>
                  <Form.Label>
                    <h6>Select Ad Preview Images</h6>
                  </Form.Label>
                </Row>
                {selectedItem && JSON.parse(selectedItem.ad_urls).length === 0 && 
                    <div className='text-center' style={{ marginTop: '10px' }}>
                      <h4>No image found.</h4>
                    </div>}
                <Row
                  className='my-2'
                  style={{
                    height:
                      selectedItem &&
                        JSON.parse(selectedItem.ad_urls).length > 0
                        ? 200
                        : 'auto',
                    overflowY: 'auto'
                  }}
                >
                  {selectedItem && this.renderPreviewImage()}
                </Row>
                <Row className='float-right'>
                  <Col md='12'>
                    <Button
                      disabled={isSubmitted ? false : true}
                      style={{
                        margin: 10,
                        background: 'black',
                        border: 'none',
                        width: 100
                      }}
                      size='md'
                      type='submit'
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  const { adManagementDuck } = store;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    adTypeList: adManagementDuck.adTypeList ? adManagementDuck.adTypeList : []
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...adManagementDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: 'AddTypeModal'
    })(AddTypeModal)
  )
);
