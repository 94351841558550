import React from 'react';
import { Field, reduxForm, change, untouch } from 'redux-form'
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Table } from 'reactstrap';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { injectIntl } from 'react-intl'
import { renderText } from '../../../../common/layout/FormInput';
import { email } from '../../../../config/validation'
import * as emailConfigurationDuck from "../../../../store/ducks/super-admin/EmailConfiguration.duck ";
import { toastr } from 'react-redux-toastr';
import { STATUS_CODES } from '../../../../common/StatusCode'
import { MESSAGES } from "../../../../config/message";
import './template.scss'
import { Alert } from 'react-bootstrap';
import { Loader } from '../../../../common/Loader';
import { langs } from '../../../../config/localization';


class EmailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            show: false,
            isSubmitted: false,
            newEmailId: "",
            newArray: [],
            emailArray: [],
            invalidId: false,
            isEmpty: false,
            maxLength: false
        }
    }

    /**
     * Submit the login form
     * @param values
     * @desc Calling a login actions && redirecting page based on response and set session Items 
     */
    submitEmailTemplate=()=> {
        const { newArray } = this.state;
        const { getData } = this.props;
        if (getData && getData[0] && newArray.length > 0) {
            this.props.dispatch(change('EmailModal', 'email', ''));
            this.props.dispatch(untouch('EmailModal', 'email'));
            let requestData = {
                from: getData && getData[0].from,
                name: getData && getData[0].name,
                subject: getData && getData[0].subject,
                body: getData && getData[0].body,
                email_ids: newArray,
                senderMail: getData && getData[0].sender_mail,
                companyName: getData && getData[0].company_name
            };
            this.props.addTestEmail(requestData, res => {
                if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
                    this.setState({ isSubmitted: false, newArray: [], emailArray: [] });
                    toastr.success(MESSAGES.EMAIL_ID_ADDED);
                    this.toggleModel();
                } else {
                    this.setState({ isSubmitted: true });
                }
            })
        }
    }

    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleModel = () => {
        this.props.change('email', '')
        this.setState({ emailArray: [], newArray: [], newEmailId: '', isSubmitted: false })
        this.props.onCancel();
    }

    /**
    * @method addEmailHandler
    * @description  add new email ids in local formData object
    */
    addEmailHandler = () => {
        const { emailArray, newEmailId } = this.state;
       let temp = validate({ email: newEmailId })
        if (newEmailId !== "" && temp.show === false) {
            emailArray.push(newEmailId);
            this.props.change('email', null)
            this.props.dispatch(untouch('EmailModal', 'email'));
            this.setState({ newEmailId: '', newArray: emailArray })
        }
    };

    /** 
     * @method renderAwards
     * @description  Used to render award 
     */
    renderEmail = () => {
        const { newArray } = this.state;
        if (newArray && newArray.length > 0) {
            return newArray.map((val, i) => {
                return (
                    <div className='emailCard'>
                        <i
                            className='fa fa-close crossicon'
                            onClick={() => this.removeEmail(i)}
                        ></i>
                        <div >
                            {val}
                        </div>
                    </div>
                );
            });
        }
    };

    /**
     * @method removeEmail 
     * @description Used to remove email id
     */
    removeEmail = index => {
        const emailArray = this.state.newArray;
        emailArray.splice(index, 1);
        this.setState({ emailArray: emailArray });
    };

    /**
     * @method addEmail
     * @description add email 
     */
    addEmail = (e) => {
        this.setState({ newEmailId: e.target.value.trim() ,  isSubmitted: true})
    };

    /**
    * @method render
    * @description render the component
    */
    render() {
        const { show, message, isSubmitted,} = this.state;
        const {  pristine, submitting } = this.props;

        const { loading } = this.props;
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.toggleModel}
                styles={{ height: 10 }}
                size='lg'
            >
                {loading && <Loader />}
                {show && (
                    <Alert
                        variant='danger'
                        onClose={() => this.setState({ show: false })}
                        dismissible
                    >
                        {message}
                    </Alert>
                )}
                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                    <h4 className='kt-portlet__head-title'>Enter Email Ids on which test email need to be sent</h4>
                </ModalHeader>
                <ModalBody >
                    <Row>
                        <Container>
                            <form
                                className='form'
                            >
                                <Row>
                                    <Col md='10'>
                                        <Field
                                            name='email'
                                            label={'Email Id'}
                                            value={this.state.newEmailId}
                                            placeholder='Enter Email Id'
                                            onChange={this.addEmail}
                                            required={true}
                                            component={renderText}
                                        />
                                    </Col>

                                    <Col md='2'>
                                        <button type="button" onClick={this.addEmailHandler}
                                        disabled={pristine || submitting}
                                            style={{ color: 'black', marginTop: 32, cursor: 'pointer' }}
                                        >
                                            <i className='fa fa-plus' aria-hidden='true'></i>
                                        </button>
                                    </Col>
                                </Row>
                                <div className='cardWrapper'>
                                    {this.renderEmail()}
                                </div>
                                </form>
                                <Row>
                                    <Col md='12'>
                                        <Button style={{ margin: 10, background: 'black', width: 100 }}
                                            size='md'
                                            type='button'
                                            onClick={() => this.submitEmailTemplate()}
                                            className='btn btn-secondary float-right'
                                            disabled={isSubmitted ? false : true}
                                        >Send</Button>
                                        <Button onClick={this.toggleModel} style={{
                                            margin: 10, background: '#808080',
                                            border: 'none',
                                            width: 100
                                        }}
                                            size='md'
                                            className='btn btn-secondary float-right'
                                        >
                                            Cancel</Button>
                                    </Col>
                                </Row>
                            
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}


/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
    let errors = {show:false};
    if (!values.email) {
        errors.email = langs.validation_messages.required;
        errors.show = true;

    } else if (values.email.length > 50) {
        errors.email = langs.validation_messages.maxLength;
        errors.show = true;
    }
    else if (email(values.email)) {
        errors.email = langs.validation_messages.invalid_email;
        errors.show = true;
    }
    return errors;
}


const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        loading,
    };
};


export default injectIntl(
    connect(
        mapStateToProps,
        emailConfigurationDuck.actions
    )(reduxForm({
        // Assign unique name for the form
        form: 'EmailModal',
        // touchOnBlur: false,
        validate
    })(EmailModal))
);
