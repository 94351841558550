import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import InfiniteScroll from "react-infinite-scroll-component";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import StandardTable from "../../../StandardTable";
import { convertISOToUtcDate } from "../../../../../common";
import { CSVLink } from "react-csv";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { langs } from "../../../../../config/localization";
import FooterLoader from "../../../../../common/FooterLoader";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import FilterRecords from "../FilterRecords";
import { getCurrentMonth } from "../../../../../common/CommonFunction";
import { NPCPayoutHeaders } from "../../../../../common/CsvFormate";
import { setDateFilterKeys } from "../../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../../config/message";

let label = getCurrentMonth();
class PayoutListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: 25,
      sortedOrder: true,
      companyList: [],
      viewAll: false,
      totalCount: 0,
      count: 0,
      entries: 25,
      openModal: false,
      sortBy: "",
      column: "",
      selectedOption: {
        option: 0,
        startDate: "",
        endDate: "",
      },
      fromDate: "",
      ToDate: "",
      month: "",
      year: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getPayoutRecords(25, 1, "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    const { fromDate, toDate, month, year } = this.state;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row,
      page: page,
      sort: sort,
      column: column,
      month: month ? month : "",
      year: year ? year : "",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
      type: 3,
      section: 1,
    };
    this.props.getPayoutOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.orderData.rows) &&
          this.handleListCount(res.data.orderData.rows);
        this.setState({
          payoutOrderList: [...res.data.orderData.rows],
          count: res.data.orderData.count,
        });
      }
    });
  };

  /**
   * @method handleListCount
   * @description  handle total record display
   */
  handleListCount = (res) => {
    let temp = [];
    if (res.length <= 4) {
      temp = res.slice(0, res.length);
      this.setState({ orderList: temp });
    } else {
      let len = !this.state.viewAll ? 4 : res.length;
      temp = res.slice(0, len);
      this.setState({ orderList: temp });
    }
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState(
      { pageSize: pageSize, entries: pageSize, viewAll: true },
      () => {
        this.getPayoutRecords(this.state.pageSize, 1, "", "");
      }
    );
  };

  /**
   * @method FilterRecords
   * @description  called after rendering the component
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props);
  };

  /**
   * @method applyFilter
   * @description  called to apply filter
   */
  applyFilter(values, props) {
    const { user_detail } = this.props.loggedInUser;
    const data = setDateFilterKeys(values, label);
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      npc_id: user_detail.newspaper.id,
      row: 25,
      page: 1,
      sort: "",
      column: "",
      month: data.this_month,
      year: data.this_year,
      type: 3,
      from_date: data.date,
      to_date: data.date2,
      section: 1,
    };
    label = data.label;
    this.props.getPayoutOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        Array.isArray(res.data.orderData.rows) &&
          this.handleListCount(res.data.orderData.rows);
        this.setState({
          fromDate: data.date,
          ToDate: data.date2,
          month: data.this_month,
          year: data.this_year,
          openModal: false,
          selectedOption: {
            option: values.date_range,
            startDate: values.from,
            endDate: values.to,
          },
        });
      }
    });
  }

  /**
   * @method fetchMoreData
   * @description  used to fetch more data from api
   */
  fetchMoreData = () => {
    const {
      fromDate,
      toDate,
      month,
      year,
      pageSize,
      count,
      entries,
    } = this.state;
    const { payoutOrderList } = this.props;
    if (this.state.viewAll) {
      if (payoutOrderList.length) {
        this.setState({ loader: false }, () => {
          const { user_detail } = this.props.loggedInUser;
          let requestData = {
            npc_id: user_detail.newspaper.id,
            row: pageSize + entries,
            page: 1,
            sort: "",
            column: "",
            month: month ? month : "",
            year: year ? year : "",
            from_date: fromDate ? fromDate : "",
            to_date: toDate ? toDate : "",
            type: 3,
            section: 1,
          };
          payoutOrderList.length < count &&
            this.props.getPayoutOrderList(requestData, (res) => {
              if (res.status === STATUS_CODES.OK) {
                let pages = res.data.orderData.count / this.state.pageSize;
                this.setState({
                  entries: pageSize + entries,
                  localLoading: true,
                  totalCount: Math.ceil(pages),
                  count: res.data.orderData.count,
                });
                setTimeout(() => {
                  this.setState({
                    localLoading: false,
                    payoutOrderList: [...res.data.orderList.rows],
                  });
                }, 3000);
              }
            });
        });
      }
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortedOrder } = this.state;
    instance.sortColumn(column);
    let sortBy = sortedOrder ? langs.sortBy.ASC : langs.sortBy.DESC;
    this.setState({
      sortedOrder: !sortedOrder,
      sortBy: sortBy,
      column: column.id,
    });
    this.getPayoutRecords(this.state.pageSize, 1, sortBy, column.id);
  };

  /**
   * @method getCSVdata
   * @description get csv data
   */
  getCSVdata = () => {
    const { payoutOrderList } = this.props;
    let data =
      Array.isArray(payoutOrderList) &&
      payoutOrderList.map((item) => {
        let adPlan;
        if (
          Array.isArray(item.newspaper_ads_plan.advertisements) &&
          item.newspaper_ads_plan.advertisements.length
        ) {
          adPlan = item.newspaper_ads_plan.advertisements[0].ad_type;
        } else {
          adPlan = "";
        }
        //
        return {
          ...item,
          ...{ full_name: item.user.first_name + " " + item.user.last_name },
          ...{ adPlan: adPlan },
          ...{ order_date: convertISOToUtcDate(item.order_date) },
          ...{ status: item.status },
          ...{ paid_amount: item.payment_report.paid_amount },
          ...{ price: item.order_amount },
        };
      });
    return data;
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Order#",
        accessor: "id",
        Cell: (row) => {
          const { original } = row;
          //return original && original.payment_report && original.payment_report.order_id;
          return original && original.id;
        }, // String-based value accessors!
      },
      {
        Header: "Date",
        accessor: "order_date",
        Cell: (row) => {
          const { original } = row;
          let date = original.order_date;
          return <span>{date ? convertISOToUtcDate(date) : ""}</span>;
        },
      },
      {
        Header: "Ad Plan",
        accessor: "ad_type",
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          if (!newspaper_ads_plan) return "";
          return (
            Array.isArray(newspaper_ads_plan.advertisements) && (
              <div style={{ display: "-webkit-box" }}>
                {newspaper_ads_plan.advertisements[0].ad_type}
              </div>
            )
          );
        },
      },
      {
        Header: "Customer",
        accessor: "first_name",
        Cell: (row) => {
          const { original } = row;
          return original.user && original.user.first_name
            ? original.user.first_name + " " + original.user.last_name
            : "";
        },
      },
      {
        Header: "Price",
        accessor: "order_amount",
        Cell: (row) => {
          const { original } = row;
          return <div style={{ marginLeft: 10 }}>${original.order_amount}</div>;
        },
      },
      {
        Header: "Earning",
        accessor: "paid_amount",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>
              $
              {original &&
                original.payment_report &&
                original.payment_report.paid_amount}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.status;
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const {
      viewAll,
      page,
      pageSize,
      localLoading,
      openModal,
      selectedOption,
      orderList,
    } = this.state;
    const headers = NPCPayoutHeaders;
    let csvData = this.getCSVdata();

    return (
      <div style={{ marginTop: -10 }}>
        <Portlet fluidHeight={true}>
          <PortletBody>
            <div className="row">
              <div className="col-sm-4 col-md-6">
                <h4>Orders</h4>
              </div>
              <div className="col-sm-8 col-md-6 d-flex-center justify-content-between justify-content-md-end">
                <label
                  className="mr-3"
                  style={{ padding: 3, borderBottom: "1px solid" }}
                >
                  <CSVLink
                    filename={"PayoutDetails.csv"}
                    data={csvData}
                    headers={headers}
                  >
                    Export as CSV
                  </CSVLink>
                </label>

                <h5
                  className="cursorPointer float-right"
                  onClick={() => this.setState({ openModal: true })}
                >
                  {label}&nbsp;&nbsp;
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-angle-down"
                    aria-hidden="true"
                  ></i>
                </h5>
              </div>
            </div>
            <div className="row" style={{ border: "1px solid black" }}></div>
            <InfiniteScroll
              dataLength={orderList.length}
              next={this.fetchMoreData}
              loader={
                localLoading && (
                  <h4 style={{ textAlign: "center" }}>Loading...</h4>
                )
              }
              hasMore={true}
              style={{ overflow: "unset" }}
              //useWindow={false}
            >
              <StandardTable
                handelSearch={this.handleSearch}
                columnOrder={this.renderRecords()}
                handleSort={this.handleSort}
                sortable={false}
                onPageSizeChange={this.onPageSizeChange}
                showSearch={false}
                data={orderList}
                pagination={false}
              />
            </InfiniteScroll>
            {orderList && orderList.length >= 4 && !viewAll && (
              <div
                onClick={() => {
                  this.setState({ viewAll: true, localLoading: true }, () => {
                    this.getPayoutRecords(pageSize, page);
                  });
                  setTimeout(() => {
                    this.setState({ localLoading: false });
                  }, 3000);
                }}
              >
                <label style={{ cursor: "pointer", color: "#0000ff" }}>
                  View All
                </label>
              </div>
            )}
            {localLoading && <FooterLoader />}
          </PortletBody>
        </Portlet>
        {openModal && (
          <FilterRecords
            applyFilter={this.FilterRecords}
            isOpen={openModal}
            onCancel={() => this.setState({ openModal: false })}
            type={3}
            selectedOption={selectedOption}
            resetList={() => this.getPayoutRecords()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { paymentReportDuck } = store;
  const { loading } = store.commonDuck;
  const { payoutReportList } = paymentReportDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    payoutOrderList:
      payoutReportList &&
      payoutReportList.orderData &&
      Array.isArray(payoutReportList.orderData.rows)
        ? payoutReportList.orderData.rows
        : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "PayoutListing",
    })(PayoutListing)
  )
);
