import React from 'react';
import { connect } from 'react-redux';
import { Button, } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr'
import NavigateModal from './NavigateModal'
import NavigationPrompt from 'react-router-navigation-prompt';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import * as adManagementDuck from '../../../../../store/ducks/super-admin/AdManagement.duck'
import { MESSAGES } from '../../../../../config/message';
import { Loader } from '../../../../../common/Loader';
import { PROFILE_MEDIA_URL } from '../../../../../config'
import { STATUS_CODES } from '../../../../../common/StatusCode'

class UpdateAddTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      show: false,
      isRedirect: false,
      message: '',
      flag: false,
      isSubmitted: true,
      loading: false,
      files: [],
      pictures: [],
      adType: false,
      imageArray: [],
      images: [],
      selectedItem: '',
      adTypeArray: [],
      adTypeList: [],
      uploadedImages: [],
      isEditFlag: false,
      imageSelected: undefined,
      nevigationModel: false
    };
  }

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    let result = this.props.getValues()
    const { selectedItem } = result;    
    let array1 = selectedItem && JSON.parse(selectedItem.ad_urls);
    let array2 = selectedItem && JSON.parse(selectedItem.npc_advertisement.ad_urls)
    let isFounded = array1.some((ai, i) => ai.includes(array2[0] ,this.setState({ imageSelected: i })));
  }



  leaveChanges = () => {
    this.setState({ nevigationModel: false, isEditFlag: false })
    this.props.onCancel();
  }

  onCancelNavigation = () => {
    this.setState({ nevigationModel: false })
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    const { isEditFlag } = this.state;
    if (isEditFlag) {
      this.setState({ pictures: [], isEditFlag: true, nevigationModel: true })
    } else {
      this.setState({ nevigationModel: false, isEditFlag: false })
      this.props.onCancel();
    }
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { user_detail } = this.props.loggedInUser;
    const { selectedItem } = this.props;
    this.setState({ isSubmitted: false })
    if (selectedItem && selectedItem !== undefined) {
      const requestData = {
        id: user_detail.newspaper.id,
        advertisementId: selectedItem.id,
        newspaperId: user_detail.newspaper.id,
        ad_urls: this.state.images,
        active: 1,
        is_deleted: 0,
        npc_advertisement: selectedItem.npc_advertisement.id
      }
      this.props.updateAdType(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(MESSAGES.UPDATE_AD_TYPE_SUCCESS);
          this.setState({ isEditFlag: false })
          setTimeout(() => {
            this.props.onCancel();
          }, 3000)
        } else {
          this.setState({ isSubmitted: true })
        }
      })
    }
  }

  /**
   * @method renderInitialPreviewImage
   * @description render initial images 
   */
  renderInitialPreviewImage = () => {
    const { selectedItem } = this.props;
    if (selectedItem && selectedItem !== undefined) {
      let item = JSON.parse(selectedItem.ad_urls)
      return item.map((e, i) => {
        let custumStyle = (i === this.state.imageSelected)
        return (
          <div
            key={i}
            onClick={() => this.selectItem(i, e)}
            className="my-3" style={{ position: 'relative' }}
          >
            <img
              src={`${PROFILE_MEDIA_URL}/${e}`}
              style={{ height: 100, width: 100, marginLeft: 20, marginTop: 20 }}
              alt=''
            ></img>
            {custumStyle &&
              <i
                className='fa fa-check'
                style={{ position: 'absolute' }}
              ></i>}
          </div>
        );
      });
    }
  };

  /**
  * @method selectItem
  * @description perform on select operation on image 
  */
  selectItem(index, item) {
    this.setState({ isSubmitted: true, imageSelected: index, isEditFlag: true, images: item.split() })
  }

  /**
  * @method renderPreviewImage
  * @description render images of super admin uploaded 
  */
  renderPreviewImage = () => {
    const { selectedItem } = this.props;
    const { adTypeList } = this.state;
    if (adTypeList && adTypeList !== undefined && selectedItem && selectedItem !== undefined) {
      let index = adTypeList.findIndex((el) => el.id === selectedItem.prototype_advertisement_id);
      return adTypeList[index] && adTypeList[index].ad_urls.map((e, i) => {
        return (
          <div key={i} onClick={() => this.selectItem(i, e)}>
            <img
              src={`${PROFILE_MEDIA_URL}/${e}`}
              style={{ height: 100, width: 100, marginLeft: 20, marginTop: 20 }}
              alt=''
            ></img>
          </div>
        );
      });
    }
  };

  /**
   * @method render
   * @description render component 
   */
  render() {
    const { handleSubmit, selectedItem } = this.props;
    const { isEditFlag, nevigationModel, loading } = this.state;

    return (
      <div>
        <NavigationPrompt when={isEditFlag}>
          {(props) => {
            const { onConfirm, onCancel } = props;
            if (!nevigationModel) {
              return (
                <NavigateModal
                  show={true}
                  alertHeading={'Changes made will be lost.'}
                  cancleButtonText='Cancel'
                  okButtonText='Leave Anyway'
                  onOkClick={() => onConfirm()}
                  onClickCancel={() => { onCancel() }}
                  showCancel={true}
                />
              )
            }
          }
          }
        </NavigationPrompt>
        {nevigationModel && (
          <NavigateModal
            isOpen={nevigationModel}
            onCancelClick={this.onCancelNavigation}
            onOkClick={this.leaveChanges}
          />)}
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggleModel}
          styles={{ height: 10, width: 100 }}
          backdrop={'static'}
          size="lg"
        >
          {loading && <Loader />}
          <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
            Update Ad Type
          </ModalHeader>
          <ModalBody styles={{ width: 600 }}>
            <Row>
              <Container>
                <form
                  noValidate
                  className='form'
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Row>
                    <Col md='12'>
                      <label>Ad Type Name</label>
                      <input
                        label='Ad Type Name'
                        name={'ad_name'}
                        type='text'
                        value={selectedItem && selectedItem.ad_name}
                        className='form-control'
                        style={{ width: '100%' }}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row >
                    <Col md='12'>
                      <label style={{ marginTop: '10px' }}>Select Ad Preview Images</label>
                    </Col>
                  </Row>
                  {selectedItem && JSON.parse(selectedItem.ad_urls).length === 0 && 
                    <div className='text-center' style={{ marginTop: '10px' }}>
                      <h4>No image found.</h4>
                    </div>}
                  <Row className="my-2" style={{ height: selectedItem && JSON.parse(selectedItem.ad_urls).length > 0 ? 200 : 'auto', overflowY: 'auto' }}>{selectedItem && this.renderInitialPreviewImage()}</Row>
                  <Row></Row>
                  <Row className='float-right'>
                    <Col md='12'>
                      <Button
                        disabled={isEditFlag ? false : true}
                        style={{
                          margin: 10,
                          background: 'black',
                          border: 'none',
                          width: 100
                        }}
                        size='md'
                        type='submit'
                      >
                        Update
                    </Button>
                    </Col>
                  </Row>
                </form>
              </Container>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...adManagementDuck.actions }
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'UpdateAddTypeModal',
      enableReinitialize: true,
      destroyOnUnmount: false
    })(UpdateAddTypeModal)
  )
);
