import React, { Fragment } from 'react'
import Nav from '../../Nav'
import FooterSec from './FooterSec'
import CalenderContent from './CalenderContent';
import "./../styles/calender.scss";

const CalenderPage = () => {
  return (
    <Fragment>
        <Nav />
        <CalenderContent />
        <FooterSec />
    </Fragment>
  )
}

export default CalenderPage