import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as customerDuck from "../../../../store/ducks/super-admin/Customer.duck";
import { MESSAGES } from "../../../../config/message";
import StandardTable from "../../StandardTable";
import AddCustomer from "./AddCustomer";
import ConformModel from "../ConformModel";
import { langs } from "../../../../config/localization";
import { checkPermission } from "../../../../common/CheckPermissions";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import {
  EditIconLink,
  DeleteIconButton,
  ViewIconLink,
} from "../../../../common/Buttons";

class AllCustomerList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEditFlag: false,
      isRedirect: false,
      rows: [],
      customerList: [],
      isConfirm: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      showConfirmModal: false,
      showSearchResults: false,
      searchKey: "",
      customerCount: 0,
      agentCount: 0,
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.getCustomerList(this.state.pageSize, 1);
  }

  /**
   * @method openModel
   * @description  used to open filter form
   */
  openModel = () => {
    this.getCustomerList(this.state.pageSize); //added
    this.setState({
      isOpenModal1: true,
      isEditFlag: false,
      showConfirmModal: false,
    });
  };

  /**
   * @method onCancel
   * @description  used to cancel filter form
   */
  onCancel = () => {
    this.getCustomerList(this.state.pageSize); //added
    this.setState({ isOpenModal1: false });
  };

  /**
   * @method getCustomerList
   * @description  used to get all admin listing
   */
  getCustomerList = (row, page, search) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      search: search !== undefined ? search : "",
      status: 1,
      is_deleted: 0,
    };
    this.props.getCustomerListing(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let customerCount = res.data.customerCount;
        let agentCount = res.data.agentCount;
        let pages = (customerCount + agentCount) / this.state.pageSize;
        this.setState({
          customerList: res.data.rows,
          totalCount: Math.ceil(pages),
          customerCount: Math.ceil(customerCount),
          agentCount: Math.ceil(agentCount),
        });
      }
    });
  };

  /**
   * @method deleteCompany
   * @description Delete news paper company from All company list and put It into Archive Listing
   */
  deleteCompany = (adminDetails) => {
    const toastrConfirmOptions = {
      onOk: () => {
        this.confirmDelete(adminDetails);
      },
      onCancel: () => console.log("CANCEL: clicked"),
    };
    return toastr.confirm(
      ` Are you sure you want to delete ${adminDetails.newspaper_name} ?`,
      toastrConfirmOptions
    );
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const { deletedItem, searchKey, showSearchResults, pageSize } = this.state;
    const customerDetails = deletedItem;
    const requestData = {
      customerId: customerDetails.id,
      email: encodeURIComponent(customerDetails.email),
      first_name: encodeURIComponent(customerDetails.first_name),
    };
    this.props.deleteCustomerById(requestData, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_CUSTOMER_SUCCESS);
        this.setState({
          isSubmitted: false,
          showConfirmModal: false,
        });
        if (showSearchResults) {
          let requestData = {
            searchKey: searchKey,
            is_deleted: 0,
            status: 1,
          };
          this.props.searchInCustomerList(requestData);
        }
        this.getCustomerList(pageSize);
      }
    });
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getCustomerList(this.state.pageSize, page + 1, this.state.searchKey);
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getCustomerList(pageSize, this.state.page);
    });
  };

  handleSearch = (e) => {
    if (!e.target.value) {
      this.setState({ showSearchResults: false, searchKey: e.target.value });
      this.getCustomerList(this.state.pageSize, 1, "");
    } else {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 0,
        status: 1,
      };
      this.getCustomerList(this.state.pageSize, 1, requestData.searchKey);
    }
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = () => {
    const createPer = checkPermission(
      langs.permissionKeys.customer,
      langs.permissionKeys.create
    );
    const deletePer = checkPermission(
      langs.permissionKeys.customer,
      langs.permissionKeys.delete
    );

    const columnOrder = [
      {
        Header: "Customer Name",
        Cell: (row) => {
          const { original } = row;
          return createPer ? (
            <Link to={`/editCustomer/${row.original.id}`}>
              {original && original.first_name
                ? original.first_name + " " + original.last_name
                : ""}
            </Link>
          ) : (
            <font className="text-truncate">
              {original && original.first_name
                ? original.first_name + " " + original.last_name
                : ""}
            </font>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return <span className="cust_mail">{original.email}</span>;
        },
      },
      {
        Header: "Phone Number",
        accessor: "Phone", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          let match =
            original && original.phone_number
              ? original.phone_number.match(/^(\d{3})(\d{3})(\d{4})$/)
              : "";
          return match ? match[1] + "-" + match[2] + "-" + match[3] : "";
        },
      },
      {
        Header: "User Type",
        accessor: "role.name", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original["role.name"];
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        width: 250,
        Cell: (row) => (
          <div style={{ padding: 0 }}>
            {createPer && (
              <ViewIconLink
                to={{
                  pathname: `/viewOrderList/${row.original.id}`,
                  state: {
                    customerDetail: row.original,
                  },
                }}
              />
            )}
            {createPer && (
              <EditIconLink to={`/editCustomer/${row.original.id}`} />
            )}

            {deletePer && (
              <DeleteIconButton
                onClick={() =>
                  this.setState({
                    showConfirmModal: true,
                    deletedItem: row.original,
                  })
                }
              />
            )}
          </div>
        ),
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const {
      isOpenModal1,
      showSearchResults,
      showConfirmModal,
      customerCount,
      agentCount,
      totalCount,
      isEditFlag,
      companyId,
      userId,
    } = this.state;
    const { loading, searchResults } = this.props;
    const viewPer = checkPermission(
      langs.permissionKeys.customer,
      langs.permissionKeys.view
    );
    const createPer = checkPermission(
      langs.permissionKeys.customer,
      langs.permissionKeys.create
    );

    let results;
    if (searchResults && searchResults.length && showSearchResults === true) {
      results = this.props.searchResults;
    } else if (searchResults === undefined) {
      results = [];
    } else if (searchResults.length === 0 && showSearchResults === true) {
      results = this.props.customerList;
    } else {
      results = this.props.customerList;
    }

    return (
      <div>
        <ConformModel
          isOpen={showConfirmModal}
          onCancelClick={this.cancelDelete}
          onOkClick={this.confirmDelete}
        />
        {loading && <Loader />}
        {this.props.tab === 0 && (
          <LayoutContextConsumer>
            {({ subheader: { title } }) => (
              <div className="row" style={{ marginBottom: 40 }}>
                <div className="col-md-8">
                  {viewPer && (
                    <div className="row">
                      <div
                        style={{
                          border: "1px solid black",
                          width: 200,
                          padding: 10,
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: 30, color: "#696969" }}>
                            {customerCount ? customerCount : 0}
                          </h1>
                        </div>
                        <div>
                          <h6
                            style={{
                              fontSize: 15,
                              color: "#696969",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            Customers
                          </h6>
                        </div>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          width: 200,
                          padding: 10,
                          marginLeft: 10,
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: 30, color: "#696969" }}>
                            {agentCount ? agentCount : 0}
                          </h1>
                        </div>
                        <div>
                          <h6
                            style={{
                              fontSize: 15,
                              color: "#696969",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            Agents
                          </h6>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {createPer && (
                  <div
                    style={{ marginTop: 20 }}
                    className="row row-full-height col-md-4"
                  >
                    <h5 className="kt-subheader__title col-md-12  d-flex justify-content-end">
                      <div onClick={this.openModel}>
                        <Link to="#">
                          <span style={{ color: "black" }}>
                            + Add New Customer
                          </span>
                        </Link>
                      </div>
                    </h5>
                  </div>
                )}
              </div>
            )}
          </LayoutContextConsumer>
        )}
        <div>
          {viewPer ? (
            <StandardTable
              handelSearch={this.handleSearch}
              columnOrder={this.renderRecords()}
              totalCount={totalCount}
              allData={this.state.customerList}
              data={results}
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              history={this.props.history}
            />
          ) : (
            <div className="text-center" style={{ marginTop: "10px" }}>
              <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
            </div>
          )}
        </div>
        {isOpenModal1 && (
          <AddCustomer
            isOpen={isOpenModal1}
            onCancel={this.onCancel}
            isEditFlag={isEditFlag}
            companyId={companyId}
            userId={userId}
            callNext={(newspaperId) =>
              this.setState({
                showPaymentModal: true,
                newspaperId: newspaperId,
              })
            }
          />
        )}
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, customerDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    customerList: customerDuck.customerList,
    totalCompany: customerDuck.totalCompany,
    searchResults: customerDuck.searchResultsforCustomer,
    advanceSearchRecords: customerDuck.advanceSearchRecords,
    globalMessage: customerDuck.globalMessage,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    customerDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AllNewspaperList",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(AllCustomerList)
  )
);
