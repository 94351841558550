import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { convertISOToUtcDate } from "../../../../common";
import * as adminCustomerAccessDuck from "../../../../store/ducks/super-admin/AdminCustomerAccess.duck";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import { langs } from "../../../../config/localization";
import {
  AddIconButton,
  DeclineIconButton,
  GrantIconButton,
} from "../../../../common/Buttons";

class PendingRequestList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isConfirm: false,
      sortedOrder: true,
      isRedirect: false,
      totalCount: 100,
      page: 1,
      pageSize: 25,
      sortFlag: {
        newspaper_name: { sort: false },
        customer_name: { sort: false },
        customer_number: { sort: false },
        customer_email: { sort: false },
        createdAt: { sort: false },
        status: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.getCustomerList(this.state.pageSize, 1, "", "");
  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getCustomerList = (row, page, sortBy, column) => {
    let requestData = {
      status: "Pending",
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sortBy,
      column: column,
    };
    this.props.getCustomerRequestList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({ totalCount: Math.ceil(pages) });
      }
    });
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    if (column.sortable !== false) {
      const { sortFlag } = this.state;
      let temp = sortFlag;
      temp[column.id].sort = !sortFlag[column.id].sort;
      let sortBy = sortFlag[column.id].sort
        ? langs.sortBy.ASC
        : langs.sortBy.DESC;
      this.setState({ sortFlag: temp });
      column.sortable !== false && instance.sortColumn(column);
      column.sortable !== false &&
        this.getCustomerList("", "", sortBy, column.id);
    }
  };

  /**
   * @method onPageSizeChange
   * @description called on change of page size
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getCustomerList(pageSize, this.state.page, "", "");
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getCustomerList(this.state.pageSize, page + 1, "", "");
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Company Name",
        accessor: "newspaper_name",
        Cell: (row) => {
          const { original } = row;
          return original.newspaper_name;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        Cell: (row) => {
          const { original } = row;
          return original.customer_name;
        },
      },
      {
        Header: "Phone Number",
        accessor: "customer_number",
        Cell: (row) => {
          const { original } = row;
          return original.customer_number;
        },
      },
      {
        Header: "Email",
        accessor: "customer_email",
        Cell: (row) => {
          const { original } = row;
          return <span className="cust_mail">{original.customer_email}</span>;
        },
      },
      {
        Header: "Date of Request",
        accessor: "createdAt",
        Cell: (row) => {
          const { original } = row;
          return convertISOToUtcDate(original.createdAt);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          const { original } = row;
          return original.status;
        },
      },

      {
        Header: (props) => <span>Actions</span>,
        // Custom header components!
        sortable: false,
        minWidth: 120,
        Cell: (row) => {
          const { original } = row;
          if (original && original.user_table_id !== null) {
            return (
              <div>
                <GrantIconButton
                  title="Grant Access"
                  onClick={() =>
                    this.customerAccessRequest(
                      original,
                      "Approved",
                      MESSAGES.CUSTOMER_REQUEST_ACCEPT
                    )
                  }
                />
                <DeclineIconButton
                  onClick={(e) => {
                    toastr.confirm(`${MESSAGES.REQUEST_DELETE_CONFIRM}`, {
                      onOk: () =>
                        this.customerAccessRequest(
                          original,
                          "Declined",
                          MESSAGES.CUSTOMER_REQUEST_REJECT
                        ),
                      onCancel: () => {
                        console.log("cancel");
                      },
                    });
                  }}
                />
              </div>
            );
          } else if (
            original &&
            original.user_table_id === null &&
            row.original.status === "Pending"
          ) {
            return (
              <AddIconButton
                title="Add New Customer"
                onClick={() => this.checkCustomerExits(original)}
              />
            );
          } else {
            return <div></div>;
          }
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method customerAccessRequest
   * @description provide permission npc to customer access
   */
  customerAccessRequest = (data, status, message) => {
    const { user_detail } = this.props.loggedInUser;
    if (data && data !== undefined) {
      let requestData = {
        id: data.permission_id,
        admin_id: user_detail.id,
        npc_id: data.npc_id,
        npc_name: data.newspaper_name,
        user_name: data.customer_name,
        status: status,
        email: data.customer_email,
        reject_reason: "reason",
      };
      this.props.accessPermissiom(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(message);
          this.getCustomerList(this.state.pageSize, 1, "", "");
        }
      });
    }
  };

  /**
   * @method checkCustomerExits
   * @description check email id exits or not
   */
  checkCustomerExits = (data) => {
    let requestData = {
      email: data.customer_email,
    };
    this.props.checkCustomerExist(requestData, (res) => {
      if (res.status === STATUS_CODES.OK && res.data.success === true) {
        setTimeout(() => {
          window.location.assign("/customerManagement");
        }, 1000);
      } else {
        toastr.success(MESSAGES.CUSTOMER_ALREADY_EXIST);
      }
    });
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { requestList } = this.props;
    const { totalCount, customerList } = this.state;

    return (
      <div>
        <Portlet fluidHeight={true}>
          <PortletBody>
            <div>
              <StandardTable
                columnOrder={this.renderRecords()}
                totalCount={totalCount}
                allData={customerList}
                data={requestList}
                handleSort={this.handleSort}
                showPageSizeOptions={false}
                //showPagination={false}
                showSearch={false}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                //isVisible={false}
              />
            </div>
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, adminCustomerAccessDuck, authToken } = store;
  const { customerList } = adminCustomerAccessDuck;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    requestList: customerList && customerList,
  };
};

export default injectIntl(
  connect(mapStateToProps, adminCustomerAccessDuck.actions)(PendingRequestList)
);
