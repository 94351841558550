import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../config/message";
import { STATUS_CODES } from "../common/StatusCode";
import { SESSION_EXPIRED_ACTION } from "../config/constant";

/**
 * @method apiErrors
 * @description handle api error
 */
export const apiErrors = (res, dispatch) => {
  const response = res ? res.response : undefined;
  if (
    response &&
    response.data &&
    String(response.status) === String(STATUS_CODES.PRECONDITION_FAILED)
  ) {
    toastr.error(response.data.message);
  } else if (
    response &&
    response.data &&
    response.status === STATUS_CODES.SESSION_EXPIRED
  ) {
    dispatch({ type: SESSION_EXPIRED_ACTION });
    toastr.warning(MESSAGES.SESSION_EXPIRE_MESSAGE);
  } else if (
    response &&
    response.data &&
    String(response.status) === String(STATUS_CODES.UNPROCESSABLE_ENTITY)
  ) {
    toastr.error(response.data.message);
  } else {
    toastr.error(MESSAGES.SERVER_ERROR);
  }
};
