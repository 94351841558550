import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetPaymentOrderList: "[SetPaymentOrderList] Action",
    SetPaidPaymentOrderList: "[SetPaidPaymentOrderList] Action",
    SetFinanceDetail: "[SetFinanceDetail] Action",
    SetHistoryDetail: "[SetHistoryDetail] Action",
    SetPayoutGraphData: "[SetPayoutGraphData] Action",
    SetStatementDetail: "[SetStatementDetail] Action",

    SetTransctiondetails: "[SetTransctiondetails] Action",

    SetSalesReport: "[SetSalesReport]  Action",
    SetSalesDetail: "[SetSalesDetail] Action",
    SetAdTypeReportDetail: "[[SetAdTypeReportDetail] Action",
    SetAdTypeGraphData: "[SetAdTypeGraphData] Action",
    SetAdTypeReportListing: "[SetAdTypeReportListing] Action",
    SetAdTypeLineGraph: "[SetAdTypeLineGraph] Action",
    AllNPCAdTypeList: "[AllNPCAdTypeList] Action",
    SetSalesOrderList: "[SetSalesOrderList] Action",
    SetTopNPCAdType: "[SetTopAdType] Action",


};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    payoutReportList: undefined,
    payoutPaidReportList: undefined,
    financeDetail: undefined,
    historyDetail: undefined,
    payoutHistoryGraphData: undefined,
    statementDetail: undefined,
    transctiondetails:undefined,
    adTypeListing: [],
    adTypeReportDetail: undefined, 
    salesOrderList: undefined,
    npcTopAdtype: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of company list */
            case actionTypes.SetPaymentOrderList: {
                const { payoutReportList } = action.payload;
                return { ...state, payoutReportList: payoutReportList };
            }

            case actionTypes.payoutPaidReportList: {
                const { payoutPaidReportList } = action.payload;
                return { ...state, payoutPaidReportList: payoutPaidReportList };
            }

            /** get all records of company list */
            case actionTypes.SetFinanceDetail: {
                const { financeData } = action.payload;
                return { ...state, financeDetail: financeData };
            }

            /** get all records of company list */
            case actionTypes.SetHistoryDetail: {
                const { historyData } = action.payload;
                return { ...state, historyDetail: historyData };
            }

            /** get all records of company list */
            case actionTypes.SetPayoutGraphData: {
                const { graphData } = action.payload;
                return { ...state, payoutHistoryGraphData: graphData };
            }

            /** get all records of company list */
            case actionTypes.SetStatementDetail: {
                const { statementDetail } = action.payload;
                return { ...state, statementDetail: statementDetail };
            }

            case actionTypes.SetTransctiondetails: {
                const { transctiondetails } = action.payload;
                return { ...state, transctiondetails: transctiondetails };
            }

            /** get all records of sales */
            case actionTypes.SetSalesReport: {
                const { salesReport } = action.payload;
                return { ...state, salesReport: salesReport };
            }

            /** get all records of sales */
            case actionTypes.SetSalesDetail: {
                const { salesDetail } = action.payload;
                return { ...state, salesReport: salesDetail };
            }

            /** get all records of sales */
            case actionTypes.SetAdTypeReportDetail: {
                const { adTypeReportDetail } = action.payload;
                return { ...state, adTypeReportDetail: adTypeReportDetail };
            }

            /** get all records of graph in adtype */
            case actionTypes.SetAdTypeGraphData: {
                const { adTypeGraphData } = action.payload;
                return { ...state, adTypeGraphData: adTypeGraphData };
            }

            /** get all records of listing in ad type */
            case actionTypes.SetAdTypeReportListing: {
                const { adTypeListing } = action.payload;
                return { ...state, adTypeListing: adTypeListing.topAdType !== undefined ? adTypeListing.topAdType : [] };
            }

            /** get all records of listing in ad type */
            case actionTypes.SetAdTypeLineGraph: {
                const { adTypeLinegraph } = action.payload;
                return { ...state, adTypeLinegraph: adTypeLinegraph };
            }

            /** get all records of listing in ad type */
            case actionTypes.AllNPCAdTypeList: {
                const { adtypeReportList } = action.payload;
                return { ...state, adtypeReportList: adtypeReportList };
            }

            /** get all records of listing in ad type */
            case actionTypes.SetSalesOrderList: {
                const { salesOrderList } = action.payload;
                return { ...state, salesOrderList: salesOrderList };
            }
            /** get all records of listing in ad type */
            case actionTypes.SetTopNPCAdType: {
                const { npcTopAdtype } = action.payload;
                return { ...state, npcTopAdtype: npcTopAdtype };
            }
            

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {


    /**
    * @method getPayoutOrderList
    * @description get all payout order listing/records 
    */
    getPayoutOrderList: (requestData, callback) => {
        const {section='', npc_id, type='', sort='', column='', month='', year='', from_date='', to_date='', page='', row='' } = requestData;
        let url = `${API.getPayoutHistory}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                if(section === 1){
                    return dispatch({ type: actionTypes.SetPaymentOrderList, payload: { payoutReportList: response.data } })
                }else if(section === 2){
                    return dispatch({ type: actionTypes.SetSalesOrderList, payload: { salesOrderList: response.data } })
                }
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                console.log('erroe', error)
                callback(error);
            });
        };
    },
    getPaidPayoutOrderList: (requestData, callback) => {
        const {filterid,section='', npc_id, type='', sort='', column='', month='', year='', from_date='', to_date='', page='', row='' } = requestData;
        let url = `${API.getPaidPayoutHistory}?npc_id=${npc_id}&filterid=${filterid}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                if(section === 1){
                    return dispatch({ type: actionTypes.SetPaidPaymentOrderList, payload: { payoutPaidReportList: response.data } })
                }else if(section === 2){
                    return dispatch({ type: actionTypes.SetSalesOrderList, payload: { salesOrderList: response.data } })
                }
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                console.log('erroe', error)
                callback(error);
            });
        };
    },

    /**
    * @method getPayoutOrderList
    * @description get all payout order listing/records 
    */
    getPayoutOrderListM2: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '' } = requestData;
        let url = `${API.getPayoutHistory}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetFinanceDetail, payload: { financeData: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                console.log('erroe', error)
                callback(error);
            });
        };
    },

    /**
  * @method getPayoutOrderList
  * @description get all payout order listing/records 
  */
    getPayoutHistoryList: (requestData, callback) => {
        const {filterid, npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '' } = requestData;
        let url = `${API.getPayoutHistory}?npc_id=${npc_id}&filterid=${filterid}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                
                return dispatch({ type: actionTypes.SetHistoryDetail, payload: { historyData: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                console.log('erroe', error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
* @method getPayoutOrderList
* @description get all payout order listing/records 
*/
    getPayoutGraphData: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '' } = requestData;
        let url = `${API.getPayoutHistory}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetPayoutGraphData, payload: { graphData: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                console.log('erroe', error)
                return callback(error);
            });
        };
    },

    /**
    * @method getSalesRecord
    * @description get all sales record 
    */
    getSalesRecord: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', statement = '', sales = '' } = requestData;
        let url = `${API.getSalesReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&statement=${statement}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetSalesReport, payload: { salesReport: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                console.log('erroe', error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
       * @method getPayoutOrderList
       * @description get all payout order listing/records 
       */
    getPayoutOrderListForFilter: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '' } = requestData;
        let url = `${API.getPayoutHistory}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetPaymentOrderList, payload: { payoutReportList: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                console.log('erroe', error)
                return callback(error);
            });
        };
    },

    /**
       * @method getPayoutOrderList
       * @description get all payout order listing/records 
       */
    getStatementDetail: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', statement = '' } = requestData;
        let url = `${API.getPayoutHistory}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&statement=${statement}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetStatementDetail, payload: { statementDetail: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getTransactionDetail: (requestData, callback) => {
        const { npc_id } = requestData;
        let url = `${API.getTransactionDetail}?npc_id=${npc_id}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetTransctiondetails, payload: { transctiondetails: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
       * @method getSalesGraphData
       * @description get all sales record statical data
       */
    getSalesGraphData: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', statement = '', sales = '' } = requestData;
        let url = `${API.getSalesReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&statement=${statement}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetSalesDetail, payload: { salesDetail: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                console.log('erroe', error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
    * @method getAdTypeRecord
    * @description get all adtype record 
    */
    getAdTypeRecord: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', sales = '', ad_type = '' } = requestData;
        let url = `${API.getAdTypeReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&sales=${sales}&ad_type=${ad_type}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetAdTypeReportDetail, payload: { adTypeReportDetail: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
   * @method getAdTypeGraphData
   * @description get all adtype record 
   */
    getAdTypeGraphData: (requestData, callback) => {
        const {section='', npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', sales = '', ad_type = '' } = requestData;
        let url = `${API.getAdTypeReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&sales=${sales}&ad_type=${ad_type}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                if(section === 1){
                    return dispatch({ type: actionTypes.SetTopNPCAdType, payload: { npcTopAdtype: response.data } })
                }else {
                    return dispatch({ type: actionTypes.SetAdTypeGraphData, payload: { adTypeGraphData: response.data } })
                }
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
    * @method getAdTypeReportListing
    * @description get all adtype record  listing
    */
    getAdTypeReportListing: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', sales = '', ad_type = '' } = requestData;
        let url = `${API.getAdTypeReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&sales=${sales}&ad_type=${ad_type}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetAdTypeReportListing, payload: { adTypeListing: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },


    /**
    * @method getAdTypeLineGraph
    * @description get statical data for line chart
    */
    getAdTypeLineGraph: (requestData, callback) => {
        const { npc_id, type = '', sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = '', row = '', sales = '', ad_type = '' } = requestData;
        let url = `${API.getAdTypeReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&sales=${sales}&ad_type=${ad_type}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetAdTypeLineGraph, payload: { adTypeLinegraph: response.data } })
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
     * @method getAdTypeReportDropdown
     * @description get all adtype list of npc list
     */
    getAdTypeReportDropdown: (requestData, callback) => {
        const { npc_id } = requestData
        const request = axios.get(`${API.getAdTypeDropdown}?npc_id=${npc_id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response);
                return dispatch({ type: actionTypes.AllNPCAdTypeList, payload: { adtypeReportList: response.data.result } })

            }).catch((error) => {
                // tokenCheck(dispatch, error)
                return callback(error);
            });
        };
    },

};




export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





