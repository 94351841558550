import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import * as customerAccessDuck from "../../../../store/ducks/npc/CustomerAccess.duck";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { langs } from "../../../../config/localization";
import { STATUS_CODES } from "../../../../common/StatusCode";
import moment from 'moment';
import Switch from "react-switch";
import { toastr } from "react-redux-toastr";
import {
  DeclineIconButton,
  StatusLabel,
  AddIconLink,
} from "../../../../common/Buttons";
import { divide } from "lodash";
import './AgentListing.scss'

const tableStyle = {
  border: "none",
  boxShadow: "none",
};
let flag = false;
class Alleditagenteditlisiting extends Component {
  constructor(props, context) {


    super(props, context);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isConfirm: false,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      customdata:[],
      page: 1,
      sortFlag: {
        agentname: { sort: false },
        clientname: { sort: false },
        campaign_name: { sort: false },
        publishername: { sort: false },
        ad_name: { sort: false },
        originalPrice: { sort: false },
        editedPrice: { sort: false },
        updatedAt: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.adminEditedPriceList(this.state.pageSize, 1, "", "");
  }
  
  handleChange(id) {

    let newChecked = !this.state.checked
    this.setState({checked: newChecked})
    console.warn("checking state", id);
    let requestData = {
      newChecked: newChecked,
      id: id,
    };

    this.props.enableandDisablediscount(requestData, (res) => {
     
      if (res.status === STATUS_CODES.OK) {
      
        this.setState({checked: res.data.rows.is_active});
        this.getCustomerList(this.state.pageSize, 1, "", "");

        toastr.success("Data updated successfully");
        
      }
    });


  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
   adminEditedPriceList = (row, page, sortBy, column) => {
    //const { user_detail } = this.props.loggedInUser;
    let requestData = {
      search: "",
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sortBy,
      column: column,
    };
    this.props.getadminEditedPriceList(requestData, (res) => {
     
      if (res.status === STATUS_CODES.OK) {
        //console.warn("state value", this.state);
        let pages = res.data.count / this.state.pageSize;
        this.setState({ totalCount: Math.ceil(pages) });
      }
    });
  };

  /**
   * @method onPageSizeChange
   * @description called on change of page size
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
    this.adminEditedPriceList(pageSize, this.state.page, "", "");
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.adminEditedPriceList(this.state.pageSize, page + 1, "", "");
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
      this.getCustomerList(this.state.pageSize, 1, "", "");
    } else if (e.target.value.length >= 2) {
      flag = true;
      this.setState({ showSearchResults: true });
      const { user_detail } = this.props.loggedInUser;
      let requestData = {
        npc_id: user_detail.newspaper.id,
        column: "",
        page: "",
        row: this.state.pageSize,
        sort: "",
        search: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
      };
      this.props.getAgentDiscountList(requestData, (res) => {});
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    if (column.sortable !== false) {
      const { sortFlag } = this.state;
      let temp = sortFlag;
      temp[column.id].sort = !sortFlag[column.id].sort;
      let sortBy = sortFlag[column.id].sort
        ? langs.sortBy.ASC
        : langs.sortBy.DESC;
      this.setState({ sortFlag: temp });
      column.sortable !== false && instance.sortColumn(column);
      column.sortable !== false &&
        this.adminEditedPriceList(this.state.pageSize, this.state.page, sortBy, column.id);
    }
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Agent",
        accessor: "agentname",
        Cell: (row) => {
          const { original } = row;
          return original.agentname;
        }, // String-based value accessors!
      },
      {
        Header: "Client",
        accessor: "clientname",
      },
      
      {
        Header: "Campaign Name",
        accessor: "campaign_name",
        Cell: (row) => {
          const { original } = row;
          return original.campaign_name;
        },
      },
      {
        Header: "Publisher Name",
        accessor: "publishername",
        Cell: (row) => {
          const { original } = row;
          return original.publishername;
        },
      },
      {
        Header: "Ad Type",
        accessor: "ad_name",
        Cell: (row) => {
          const { original } = row;
          
          return original.ad_name;
        },
      },
      {
        Header: "Org. Price",
        accessor: "originalPrice",
        Cell: (row) => {
          const { original } = row;
          return "$"+original.originalPrice;
        },
      },
      {
        Header: "Edited Price",
        accessor: "editedPrice",
        Cell: (row) => {
        
          const { original } = row;
          return "$"+original.editedPrice;
        },
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: (row) => {
          const { original } = row;
          let date = original.updatedAt;
          return <span>{date ? moment(date).format("MMM-D-YYYY") : ""}</span>;
        },
      },
      {
        Header: "Rejected",
        accessor: "createdAt",
        Cell: (row) => {
          const { original } = row;
          let date = original.createdAt;
          return <span className={!!(original.is_edit_price)?"CustomYes":"CustomNo"}>{!!(original.is_edit_price)?"Yes":"No"}</span>;
        },
      }
      
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { requestList } = this.props;

    console.warn("Sdfghjkl>>>>>", requestList);
    const { openModal, totalCount, adminEditedPriceList } = this.state;
    return (
      <div>
        <div fluidHeight={true}>
          <div>
            {/* <div className="row my-3">
              <div className="col-sm-6 order-1 order-sm-0">
                <InputGroup
                  size="sm"
                  className="d-flex justify-content-end search-bar w-100"
                  onFocus={() => {
                    this.setState({ inputFocus: true });
                  }}
                >
                  <FormControl
                    style={{ marginRight: 20, height: 35 }}
                    placeholder="Search"
                    aria-label="search"
                    aria-describedby="basic-addon1"
                    onChange={(e) => this.handleSearch(e)}
                  />
                  <i className="flaticon-search"></i>
                </InputGroup>
              </div>
              <h5
                className="col-sm-6 order-0 order-sm-1 mb-4 mb-md-0 d-flex-center j-end"
                style={{ textAlign: "right", cursor: "pointer" }}
                onClick={() => this.setState({ openModal: true })}
              >
                + Request Customer Access
              </h5>
            </div> */}
            {requestList ? (
            
              <StandardTable
                columnOrder={this.renderRecords()}
                showSearch={false}
                totalCount={totalCount}
                data={requestList.data}
                showPageSizeOptions={true}
                handelSearch={this.handleSearch}
                handleSort={this.handleSort}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                //isVisible={false}
                showPagination={false}
              />
            ) : (
              <div>
                <div className="row">
                  <div className="col-xl-12" style={{ marginTop: 10 }}>
                    <ReactTable
                      disableResizing={false}
                      columns={this.renderRecords()}
                      manual
                      style={tableStyle}
                      className={"noborder"}
                      showPagination={false}
                      NoDataComponent={() => {
                        return (
                          <div className="float-right flex-center align-items-end h-100">
                            <div
                              className="text-center"
                              style={{
                                marginBottom: 300,
                                marginTop: -500,
                                textAlign: "initial",
                                cursor: "pointer",
                              }}
                            >
                              
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
       
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, customerAccessDuck, authToken } = store;
  const { adminEditedPriceList } = customerAccessDuck;

 // console.warn("Store checking",agentdiscountList );
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    requestList: adminEditedPriceList,
  };
};

export default injectIntl(
  connect(mapStateToProps, customerAccessDuck.actions)(Alleditagenteditlisiting)
);
