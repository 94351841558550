import React, { useState } from "react";
import { Link } from "react-router-dom";
import headerlOgo from "../../../_metronic/_assets/media/images/landing-page/pijen-tm-logo.png";

export default function Nav(props) {
  let [toggleCollapse, setToggleCollapse] = useState(true);

  const handleToggle = () => {
    toggleCollapse = setToggleCollapse(!toggleCollapse);
    console.log(toggleCollapse);
  };

  return (
    <React.Fragment>
      <header className="header_sec">
        <div className="hdrbtm headFxd">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light nav_top">
              <Link to="/" className="navbar-brand">
                <img src={headerlOgo} alt="logo" />
              </Link>
              {props.isPayment == undefined &&
                (toggleCollapse ? (
                  <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={handleToggle}
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                ) : (
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={handleToggle}
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                ))}

              {props.isPayment == undefined &&
                (toggleCollapse ? (
                  <div
                    className="collapse navbar-collapse justify-content-end hide"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav menu_sec">
                      <li>
                        {/* <Link to={{ pathname: "/email-campaign/auth/login" }} ></Link> */}
                        <a href="/email-campaign/auth/login"></a>
                      </li>
                      <li className="nav-item LoginItem">
                        <a
                          className="nav-link mr-3"
                          href="/email-campaign/auth/login"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Login
                        </a>
                      </li>
                      <li className="nav-item LoginItem">
                        <a
                          className="nav-link mr-3"
                          href="/auth/pricing"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item LoginItem">
                        <a
                          className="nav-link mr-3"
                          href="/faq"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          FAQ’s
                        </a>
                      </li>
                      <li className="nav-item LoginItem">
                        <a
                          className="nav-link mr-3"
                          href="/contactus"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Contact Us
                        </a>
                      </li>
                      <li>
                        <Link to="/contactus" className="pgbtn">
                          Schedule a Demo today!
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav menu_sec">
                      <li>
                        <a
                          className="nav-link"
                          href="/email-campaign/auth/login"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: "black",
                            marginLeft: 4,
                          }}
                        >
                          Login
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          href="/auth/pricing"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: "black",
                            marginLeft: 4,
                          }}
                        >
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          href="/faq"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: "black",
                            marginLeft: 4,
                          }}
                        >
                          FAQ’s
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link mr-3"
                          href="/auth/pricing"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: "black",
                            marginLeft: 4,
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                      <li>
                        <Link to="/contactus" className="pgbtn">
                          Schedule a Demo today!
                        </Link>
                      </li>
                    </ul>
                  </div>
                ))}
            </nav>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
