import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as superAdminDuck from "../../../../store/ducks/SuperAdmin.duck";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import BankInformation from "./ViewBankInformation";
import CompanyRole from "./CompanyRole";
import CompanyCagegory from "./CompanyCategory";
import PrintFrequency from "./PrintFrequency";
import RangeOfCopies from "./RangeOfCopies";
import NewsCategory from "./Category";
import DistributionAreas from "./DistributionAreas";
import RejectionReason from "./RejectionReason";
import Language from "./Language";
import SocialMediaCategory from "./SocialMediaCategory";
import AdTypeCategory from "./AdTypeCategory";
import AdPlacementCategory from "./AdPlacementCategory";
import AdFeatureCategory from "./AdFeatureCategory";
import SizeOfAdCategory from "./SizeOfAdCategory";
import PriceRunTimeCategory from "./PriceRunTimeCategory";

class SuperAdminSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
    };
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { loading } = this.props;
    const { showConfirmModal } = this.state;
    return (
      <div>
        {loading && <Loader />}
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Settings</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row">
          <div className="col-12">
            <BankInformation />
            <CompanyRole showConfirmModal={showConfirmModal} />
            <CompanyCagegory showConfirmModal={showConfirmModal} />
            <SocialMediaCategory showConfirmModal={showConfirmModal} />
            <AdTypeCategory showConfirmModal={showConfirmModal} />
            <AdPlacementCategory showConfirmModal={showConfirmModal} />
            <AdFeatureCategory showConfirmModal={showConfirmModal} />
            <SizeOfAdCategory showConfirmModal={showConfirmModal} />
            <PriceRunTimeCategory showConfirmModal={showConfirmModal} />
            <PrintFrequency showConfirmModal={showConfirmModal} />
            <NewsCategory showConfirmModal={showConfirmModal} />
            <DistributionAreas showConfirmModal={showConfirmModal} />
            <RejectionReason showConfirmModal={showConfirmModal} />
            <Language showConfirmModal={showConfirmModal} />
            <RangeOfCopies showConfirmModal={showConfirmModal} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, superAdminDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    toasMsg: superAdminDuck.toastMsg,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...superAdminDuck.actions, ...npcDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "SuperAdminSetting",
    })(SuperAdminSetting)
  )
);
