import React, { useState,useEffect } from 'react'
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";

export default function ContactAgency() {
	useEffect(() => {
		AOS.init({
			easing: 'ease-in-out-sine',
			
		  });
		AOS.refresh();
	  }, []);
	return (
		<React.Fragment>
						<section className="agencies_sec">
				<div className="container">
					<div className="agencies_inner">
						<div className="row">
							<div className="col-md-12">
								<div className="pagehd text-center" data-aos="zoom-in"   data-aos-offset="200">
									<h4>Designed with agencies in mind:</h4>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="agencies_box" data-aos="zoom-in" data-aos-offset="300" data-aos-duration="1000" >
									<strong>Efficiency</strong>
									<ul>
										<li>Zero redundant data entry</li>
										<li>360 campaign views</li>
										<li>Template library</li>
										<li>Advanced calendar tools </li>
										<li>Customizable email correspondence</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="agencies_box" data-aos="zoom-in" data-aos-delay="100" data-aos-offset="300" data-aos-duration="2000">
									<strong>Communications</strong>
									<ul>
										<li>Customer feedback and approvals</li>
										<li>Publisher communication and confirmation</li>
										<li>Artwork submission platform</li>
										<li>Client budget management</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="agencies_box" data-aos="zoom-in" data-aos-delay="150" data-aos-offset="300" data-aos-duration="2500">
									<strong>Profitability</strong>
									<ul>
										<li>Payment authorization and processing </li>
										<li>Commission and kick-back tracking </li>
										<li>Full automated billing suite</li>
										<li>Advanced reporting views</li>
										<li>Transaction histories</li>
									</ul>
								</div>
							</div>
							<div className="col-md-12">
								<div className="partners" data-aos="zoom-in" data-aos-delay="200" data-aos-offset="300" data-aos-duration="1000">
									<p>Learn how your agency can partner with Pijen and really own your media powers!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	)
}
