import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Size = () => {

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <section class="reserve_sec size_sec approval_sec block">
        <div class="container sticky-container">
          <div class="reserve_inner reserve_inner2 killer_inner_sec approval_inner">
            <div class="row align-items-stretch flex-row-reverse">
              <div class="col-lg-6">
                <div class="approval_inner_img size_inner_img position-inherit text-md-right">
                  <img
                    class="main_shadow main_img"
                    src="assets/images/plans-banner.png"
                    alt=""
                  />
                  <div class="approval_inner_right size_inner_right" data-aos-delay="300" data-aos="slide-right"  data-aos-duration="600">
                    <img
                      class="main_shadow"
                      src="assets/images/seeall-btn.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center" data-aos-delay="200" data-aos="slide-right"  data-aos-duration="1500">
                <div class="reserve_content">
                  <div class="pagehd">
                    <h4>Size, price & specs</h4>
                    <p>
                      All the information from your media kit will be imported
                      <br /> into our system. Now media agents can find all the
                      <br /> necessary information about your platform in an
                      instant.
                      <br /> Sizing, pricing, ad deadlines and special specs.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Size;
