import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "aos/dist/aos.css";
import Aos from "aos";
import { faqData } from "./FaqData";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "100%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const FaqContent = () => {
  const classes = useStyles();
  const [Data, setData] = useState(faqData);
  const [expanded, setExpanded] = React.useState(faqData[0].id);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  console.log(Data);
  React.useEffect(() => {
    Aos.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    Aos.refresh();
  });
  return (
    <>
      <div className="lp_faq_section">
        <Container>
          <div className="heading">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
              data-aos-anchor-placement="top-bottom"
            >
              Frequently asked questions
            </h2>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >
              Have questions? We’re here to help.
            </p>
          </div>
          <div className="content">
            <div
              className="accordian-part"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="1000"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="ease-in-sine"
            >
              <div className={classes.root}>
                {Data.map((data) => {
                  return (
                    <Accordion
                      expanded={expanded === `${data.id}`}
                      onChange={handleChange(data.id)}
                      key={data.id}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls={data.id + 'bh-content'}
                        id={data.id + 'bh-header'}
                      >
                        <Typography className={classes.heading}>
                          {data.heading}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: data.content }}>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FaqContent;
