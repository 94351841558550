import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'

export const actionTypes = {
    SetDashBoardData: "[SetDashBoardData] Action",
    SetDashBoardGraphData: "[SetDashBoardGraphData] Action",
    SetCreditDashBoardData: "[SetCreditDashBoardData] Action",
    SetPayoutDashBoardData: "[SetPayoutDashBoardData] Action",
    SetTopAdType: "[SetTopAdType] Action",
    SetTopCompany: "[SetTopCompany] Action",
    SetTopCustomer: "[SetTopCustomer] Action",
    SetTopAgent: "[SetTopAgent] Action",
    SetTypeDataModule1: "[SetTypeDataModule1] Action",
    SetTypeDataModule2: "[SetTypeDataModule2] Action",
    SetTypeDataModule3: "[SetTypeDataModule3] Action",
    SetTypeDataModule4: "[SetTypeDataModule4] Action",
    SetTypeDataModule5: "[SetTypeDataModule5] Action",

};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    dashboardData: undefined,
    topAdTypeData: undefined,
    topCompanyData: undefined,
    topCustomerData: undefined,
    topAgentData: undefined,
    dashboardCreditData: undefined,
    dashboardPayoutData: undefined,
    
    npcModule1: undefined,
    npcModule2: undefined,
    npcModule3: undefined,
    npcModule4: undefined,
    npcModule5: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {


            /** get all records of company list */
            case actionTypes.SetTypeDataModule1: {
                const { dashboardData, type } = action.payload;
                return { ...state, npcModule1: dashboardData };
            }

            case actionTypes.SetCreditDashBoardData: {
                const { dashboardCreditData } = action.payload;
                return { ...state, dashboardCreditData: dashboardCreditData };
            }

            case actionTypes.SetPayoutDashBoardData: {
                const { dashboardPayoutData } = action.payload;
                return { ...state, dashboardPayoutData: dashboardPayoutData };
            }

            /** get all records of company list */
            case actionTypes.SetTypeDataModule2: {
                const { dashboardData, type } = action.payload;
                return { ...state, npcModule2: dashboardData };
            }


            /** get all records of company list */
            case actionTypes.SetTypeDataModule3: {
                const { dashboardData, type } = action.payload;
                return { ...state, npcModule3: dashboardData };
            }

            /** get all records of company list */
            case actionTypes.SetTypeDataModule4: {
                const { dashboardData } = action.payload;
                return { ...state, npcModule4: dashboardData };
            }

            /** get all records of company list */
            case actionTypes.SetTypeDataModule5: {
                const { dashboardData } = action.payload;
                return { ...state, npcModule5: dashboardData };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
* @method getAdminDashBoardData
* @description get admin dash board data
*/
    getTypeData: (requestData, module, callback) => {
        const { type='', npc_id='', role='', month='', year='', from_date='', to_date='', page='', row='', sales='' } = requestData;
        let url = `${API.getNpcDashboardRecord}?npc_id=${npc_id}&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                // callback(response);
                if (type === 1) {
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    return dispatch({ type: actionTypes.SetTypeDataModule1, payload: { type: module, dashboardData: response.data } })
                }
                if (type === 2) {
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    return dispatch({ type: actionTypes.SetTypeDataModule2, payload: { type: module, dashboardData: response.data } })
                }
                if (type === 3) {
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    return dispatch({ type: actionTypes.SetTypeDataModule3, payload: { type: module, dashboardData: response.data } })
                }
                if (type === 4) {
                    dispatch({ type: CALL_LOADING })
                    dispatch({ type: actionTypes.SetTypeDataModule4, payload: { type: module, dashboardData: response.data } })
                    callback(response);
                }
                if (type === 5) {
                    dispatch({ type: CALL_LOADING })
                    dispatch({ type: actionTypes.SetTypeDataModule5, payload: { type: module, dashboardData: response.data } })
                    callback(response);
                    // return dispatch({ type: actionTypes.SetTypeDataModule5, payload: { type: module, dashboardData: response.data } })
                }
                if (type === 7) {
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    dispatch({ type: actionTypes.SetCreditDashBoardData, payload: { dashboardCreditData: response.data } })
                } else if (type === 6) {
                    dispatch({ type: CALL_LOADING })
                    callback(response);
                    return dispatch({ type: actionTypes.SetPayoutDashBoardData, payload: { dashboardPayoutData: response.data } })

                }
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },


};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





