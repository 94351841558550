import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as NPCDuck from '../../../../store/ducks/npc.duck'

const EmailListItem = ({ post: { id, email, npc_id } , getNewspaperById, addToSecondaryEmailById, deleteUnapprovedSecondaryEmailById }) => {
    const [ pub, setPub ] = useState("")
    const len = pub.length;

    const requestGetData = {
        npc_id: npc_id
    }
    var newspaper_name = ""

    useEffect(() => {
        getNewspaperById(requestGetData, (res) => {
            
            newspaper_name = res.data[0].newspaper_name
            setPub(newspaper_name)


            
        })

        

    }, [pub])

    const addEmail = () => {
        const requestData = {
            id: id,
            approved: 1
        }
        addToSecondaryEmailById(requestData, (res) => {
            //console.warn("Approved")
        })
        
    }

    const removeEmail = () => {
        deleteUnapprovedSecondaryEmailById(id, (res) => {
            //console.warn("Succesfully Deleted")
        })
    }


    return (
        <tr>
            <td style={{textTransform: "lowercase"}}>{email}</td>
            <td>{pub}</td>
            <td>
            <button 
                    onClick={ addEmail }
                    type="button" 
                    class="btn btn-outline-primary"
                    style={{ 
                        backgroundColor: "#AFEEEE", 
                        marginRight: 5 + "px",
                        border: "none",
                        fontWeight: "bold" 
                    }}
                >
                    Add Email
                </button>
                <button 
                    onClick={ removeEmail }
                    type="button" 
                    class="btn btn-outline-danger"
                    style={{ 

                        backgroundColor: "#ffcccb", 
                        marginLeft: 5 + "px",
                        border: "none",
                        fontWeight: "bold"
                    }}
                >
                    Remove
                </button>
            </td>
        </tr>
    )
}

export default connect(null, {   ...NPCDuck.actions })(EmailListItem)
