import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LayoutContextConsumer } from '../../../../../_metronic/layout/LayoutContext'
import { checkPermission } from '../../../../common/CheckPermissions'
import { langs } from '../../../../config/localization'
import { Portlet, PortletBody } from '../../../../partials/content/Portlet'
import StandardTable from '../../StandardTable';
// import * as emailConfigurationDuck from "../../../store/ducks/super-admin/EmailConfiguration.duck";
import * as emailConfigurationDuck from '../../../../store/ducks/super-admin/EmailConfiguration.duck '
import { STATUS_CODES } from '../../../../common/StatusCode'
import { convertISOToUtcDate } from '../../../../common'
import { MESSAGES } from '../../../../config/message'
import './Milestones.css';

const Milestones = (
    {getActionList}
) => {
    const [templateList, setTemplateList] = useState([]);



    useEffect(() => {
        getActionList("", (res) => {
            console.warn("Res", res);
            let arr = [];
            arr = res.data.rows;
            setTemplateList(arr);
            console.warn("templ", res.data.rows);
        });
    }, [setTemplateList, getActionList]);



    const viewPer = checkPermission(
        langs.permissionKeys.emailTemplate,
        langs.permissionKeys.view
    );
    const renderRecords = () => {
        const createPer = checkPermission(
          langs.permissionKeys.emailTemplate,
          langs.permissionKeys.create
        );
        const columnOrder = [
          {
            Header: "Action Name",
            accessor: "action",
            Cell: row => {
              const { original } = row;
              const firstName = original && original !== undefined && original.action;
              return (
                <div>
                  {createPer ? (
                    <Link to={`/edit-milestones/${original.id}`}>
                      <font>{`${firstName}`}</font>
                     </Link>
                  ) : (
                      <font>{`${firstName}`}</font>
                    )}
                </div>
              );
            } // String-based value accessors!
          },
          
          {
            Header: "Template",
            accessor: "template",
            Cell: row => {
              const { original } = row;
              var temp = document.createElement("div");
              temp.innerHTML = original.template;
              let dateOfRejection = original.template;
              return (
                <span>
                  {`${temp.innerText}`}
                </span>
              );
            }
          }
        //   {
        //     Header: "Status",
        //     //sortable:false,
        //     accessor: "active", // String-based value accessors!
        //     Cell: row => {
        //       const { original } = row;
        //       return original.active ? (
        //         <span style={{ color: "green" }}>Active</span>
        //       ) : (
        //           <span style={{ color: "red" }}>Inactive</span>
        //         );
        //     }
        //   }
        ];
        return columnOrder
      }
    return (
        <div>
            <LayoutContextConsumer className="row">
                {({ subheader: { title } }) => (
                    <div className="row">
                        <h3 className="kt-subheader__title col-md-6">
                            <span>Milestones</span>
                        </h3>
                    </div>
                )}
            </LayoutContextConsumer>
            <Portlet fluidHeight={true}>
                <PortletBody>
                    {/* <div className="add_milestone1">
                        <Link to="/add-milestones">
                            + Add Milestones
                        </Link>
                    </div> */}
                    {viewPer ? (
              <StandardTable
                // handelSearch={this.handleSearch}
                columnOrder={renderRecords()}
                // totalCount={totalCount}
                allData={templateList}
                data={templateList}
                // onPageSizeChange={this.onPageSizeChange}
                // onPageChange={this.onPageChange}
                // //sortable={true}
                // handleSort={this.handleSort}
              />
            ) : (
              <div className='text-center' style={{ marginTop: '10px' }}>
                  <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                </div>
              )}
                </PortletBody>
            </Portlet>
        </div>
    )
}

const mapStateToProps = store => {
    const { auth, emailConfigurationDuck, authToken } = store;
    return {
        loggedInUser: auth.user,
        authToken: authToken,
        requestList:
            emailConfigurationDuck.templateList &&
            emailConfigurationDuck.templateList,
        searchResults: emailConfigurationDuck.searchResults
    };
};

export default injectIntl(
    connect(mapStateToProps, emailConfigurationDuck.actions)(Milestones)
);

