import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import Slide from "react-reveal/Slide";

const Marketing = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <section class="reserve_sec killer_sec Marketing_sec block">
        <div class="container sticky-container">
          <div class="reserve_inner killer_inner_sec marketing_section">
            <div class="row align-items-stretch">
              <div class="col-lg-6">
                <div class="killer_img marketing_img place_inner_img">
                  <img
                    class="main_shadow main_img"
                    src="assets/images/newspaper-banner.png"
                    alt=""
                  />
                  <div class="killing_top marketing_top" data-aos-delay="300" data-aos="slide-left"  data-aos-duration="600">
                    <img alt="" className="main_shadow" src="assets/images/killingtop.png" />
                  </div>
                  <div class="killingbottom marketingbottom">
                    <img
                      class="main_shadow mobile_shadow"
                      alt=""
                      src="assets/images/newpaper_mobile.png"
                    />
                  </div>
                </div>
              </div>
                <div class="col-lg-6 d-flex align-items-center" data-aos-delay="300" data-aos="slide-left"  data-aos-duration="1000">
                  <div class="killer_content">
                    <div class="pagehd">
                      <h4>Targeted marketing</h4>
                      <p>
                        If you’re receiving this email, you’re listed on the
                        Pijen platform. That means that agencies can easily
                        select your magazine or platform and include it in the
                        placement plan.
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Marketing;
