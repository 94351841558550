import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { Image } from "react-bootstrap";
import * as adminManagementDuck from "../../../../store/ducks/super-admin/AdminManagement.duck";
import StandardTable from "../../StandardTable";
import { Button } from "@material-ui/core";
import { MESSAGES } from "../../../../config/message";
import { convertISOToUtcDate } from "../../../../common";
import ConformModel from "../ConformModel";
import Moment from "moment";
import { langs } from "../../../../config/localization";
import { checkPermission } from "../../../../common/CheckPermissions";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { EditIconLink, DeleteIconButton } from "../../../../common/Buttons";

class AllAdminList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isEditFlag: true,
      isRedirect: false,
      rows: [],
      renderNewAdminForm: false,
      adminList: [],
      isConfirm: false,
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      deletedItem: undefined,
      showConfirmModal: false,
      showSearchResults: false,
      searchKey: "",
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getAdminList(this.state.pageSize, 1);
  }

  /**
   * @method getAdminList
   * @description called to get all admin records
   */
  getAdminList = (row, page) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      isDeleted: 0,
    };
    this.props.getAllAdminList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          adminList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
   * @method confirmDelete
   * @description confirm delete bought out part
   */
  confirmDelete = () => {
    const adminDetails = this.state.deletedItem;
    const requestData = {
      id: adminDetails.id,
      email: encodeURIComponent(adminDetails.email),
      first_name: encodeURIComponent(adminDetails.first_name),
    };
    this.props.deleteAdminById(requestData, (res) => {
      this.setState({
        showConfirmModal: false,
      });
      if (res.status === STATUS_CODES.NO_CONTENT) {
        toastr.success(MESSAGES.DELETE_SUCCESS);
        if (this.state.showSearchResults) {
          let requestData = {
            searchKey: this.state.searchKey,
            is_deleted: 0,
          };
          this.props.searchInUserList(requestData);
        }
      }
    });
  };

  /**
   * @method onPageChange
   * @description used for handle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getAdminList(this.state.pageSize, page + 1);
  };

  /**
   * @method onPageSizeChange
   * @description used for handle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getAdminList(pageSize, this.state.page);
    });
  };

  /**
   * @method handleClose
   * @description Pop up handeler before delete records
   */
  handleClose = () => {
    this.setState({ isConfirm: false });
  };

  /**
   * @method handleClose
   * @description Used to handlesearch
   */
  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
    } else if (e.target.value.length >= 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 0,
      };
      this.props.searchInUserList(requestData);
    }
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = () => {
    const createPer = checkPermission(
      langs.permissionKeys.admin,
      langs.permissionKeys.create
    );
    const deletePer = checkPermission(
      langs.permissionKeys.admin,
      langs.permissionKeys.delete
    );
    const columnOrder = [
      {
        Header: "Name",
        accessor: "name", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          const firstName =
            original &&
            original !== undefined &&
            original.first_name[0].toUpperCase() + original.first_name.slice(1);
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                className="mr-2 ml-2"
                style={{ borderRadius: "100%", height: 30, width: 35 }}
              />
              {createPer ? (
                <Link
                  className="text-truncate"
                  to={`/editadmin/${original.id}`}
                >
                  <font>{`${firstName} ${original.last_name}`}</font>
                </Link>
              ) : (
                <font>{`${firstName} ${original.last_name}`}</font>
              )}
            </div>
          );
        },
      },
      {
        Header: "Role",
        width: 100,
        accessor: "role",
        Cell: (row) => {
          const { original } = row;
          let roleString;
          roleString =
            original && original.role !== null ? original.role.name : "";

          return <span>{roleString}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email", // String-based value accessors!
        width: 200,
        Cell: (row) => {
          const { original } = row;
          return <span className="cust_mail">{original.email}</span>;
        },
      },
      {
        Header: "Last Login",
        width: 200,
        Cell: (row) => {
          const { original } = row;
          let lastLogin;
          let lastLogout;
          lastLogin = original.last_login;
          lastLogout = original.last_logout;
          return (
            <span>
              {lastLogin ? convertISOToUtcDate(lastLogin) : ""} <br />{" "}
              {lastLogin && lastLogout ? "to" : ""}{" "}
              {lastLogout ? Moment.utc(lastLogout).format("HH:mm A") : ""}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.active ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          );
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        accessor: "friend.age",
        minWidth: 120,
        Cell: (row) => (
          <div style={{ padding: 5 }}>
            {createPer && <EditIconLink to={`/editadmin/${row.original.id}`} />}
            {deletePer && (
              <DeleteIconButton
                onClick={() => {
                  this.setState({
                    showConfirmModal: true,
                    deletedItem: row.original,
                  });
                }}
              />
            )}
          </div>
        ),
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { loading } = this.props;
    const { showSearchResults, totalCount, showConfirmModal } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.admin,
      langs.permissionKeys.view
    );

    let results;
    if (
      this.props.searchResults &&
      this.props.searchResults.length &&
      showSearchResults === true
    ) {
      results = this.props.searchResults;
    } else if (this.props.searchResults === undefined) {
      results = [];
    } else if (
      this.props.searchResults.length === 0 &&
      showSearchResults === true
    ) {
      results = [];
    } else {
      results = this.props.adminList;
    }
    return (
      <div>
        {loading && <Loader />}
        {viewPer ? (
          <StandardTable
            handelSearch={this.handleSearch}
            columnOrder={this.renderRecords()}
            totalCount={totalCount}
            allData={this.state.adminList}
            data={results}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          />
        ) : (
          <div className="text-center" style={{ marginTop: "10px" }}>
            <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
          </div>
        )}
        <ConformModel
          isOpen={showConfirmModal}
          onOkClick={this.confirmDelete}
          onCancelClick={this.cancelDelete}
        />
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, adminManagementDuck, authToken } = store;

  return {
    loggedInUser: auth.user,
    authToken: authToken,
    loading,
    adminList: adminManagementDuck.adminList
      ? adminManagementDuck.adminList
      : [],
    searchResults: adminManagementDuck.searchResultsforAdmin
      ? adminManagementDuck.searchResultsforAdmin
      : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adminManagementDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AllAdminList",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(AllAdminList)
  )
);
