import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import * as NPCDuck from "../../store/ducks/npc.duck";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { STATUS_CODES } from "../../common/StatusCode";
import { Modal, Button } from "react-bootstrap";
import "./WelcomePage.css";
import ModalLogo from "../../../assets/images/Logingraphics.png";

import {
  required,
  PhoneNumber,
  minLength5,
  minLength6,
  maxLength100,
  maxLengthC100,
  maxLength5,
  minLength2,
  mailId,
  normalizePhone,
  maxLength50,
  selectRequired,
  maxLength18,
} from "../../config/validation";

const state = {
  firstName: "",
  companyName: "",
  companySize: null,
  comments: "",
  email: "",
  phone: "",
};

const ContactForm = ({ sendLandingEmail }) => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    AOS.refresh();
  });

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [comments, setComments] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formSubmitStat, setFormSubmitStat] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [Data, setData] = useState(state);
  const [validateEmail, setvalidateEmail] = useState(undefined);
  const [validatePhone, setvalidatePhone] = useState(undefined);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitStat(true);
    if (!Data.firstName) {
      console.log("Please enter First Name");
    } else if (!Data.companyName) {
      console.log("Please enter Company Name");
    } else if (!Data.email) {
      console.log("Please enter an Email");
    } else if (!Data.phone) {
      console.log("Please enter Phone");
    } else if (validateEmail) {
    } else if (validatePhone) {
    } else {
      const requestData = {
        firstName : Data.firstName,
        companyName : Data.companyName,
        companySize : Data.companySize,
        comments : Data.comments,
        email : Data.email,
        phone : Data.phone,
      }
      sendLandingEmail(requestData, (res) => {
        if (res && res.data.result) {
          Data.firstName = "";
          Data.companyName = "";
          Data.companySize = "";
          Data.comments = "";
          Data.email = "";
          Data.phone = "";
          setFormSubmitStat(false);
          setShow(true);
          setdisabled(true);
        }
      });
    }
  };

  const disableHandler = useCallback(
      (e) => {
        if (
          !Data.email ||
          !Data.firstName ||
          !Data.companyName ||
          !Data.phone ||
          !Data.companySize
        ) {
          setdisabled(true);
        } else {
          setdisabled(false);
        }
      },
    [Data]
  )

  const InputHandler = (e, name) => {
    setData({ ...Data, [name]: e.target.value });
    if (name === "email") {
      setvalidateEmail(mailId(e.target.value));
    }
    if (name === "phone") {
      setvalidatePhone(PhoneNumber(e.target.value));
    }
    // disableHandler();
  };

  useEffect(() => {
    disableHandler()
  }, [disableHandler])


  const ModalcloseHandler = () => {
    setShow(false);
  };

  const setEmailId = (e) => {
    const mailValue = e.target.value;
    setEmail(mailValue);
    mailId(mailValue);
    setvalidateEmail(mailId(mailValue));
  };
  const setPhoneNo = (e) => {
    const phoneValue = e.target.value;
    setPhone(phoneValue);
    PhoneNumber(phoneValue);
    setvalidatePhone(PhoneNumber(phoneValue));
  };
  return (
    <React.Fragment>
      <section className="contact_sec">
        <div className="container">
          <div className="contact_inner_sec">
            <div className="row">
              <div className="col-md-12">
                <div className="contact_box">
                  <div
                    className="pagehd text-center"
                    data-aos="fade-down"
                    data-aos-duration="2000"
                  >
                    <h1>See Pijen in Action</h1>
                    <p>
                      Get a live tour of the Pijen software to learn how your
                      agency <br />
                      can manage media in a more efficient and profitable way.
                    </p>
                  </div>
                  <div
                    className="conact_form"
                    data-aos="zoom-in"
                    data-aos-offset="300"
                    data-aos-duration="2000"
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="cu-form">
                            <input
                              type="text"
                              value={Data.firstName}
                              onChange={(e) => InputHandler(e, "firstName")}
                              placeholder="Name*"
                            />
                            {formSubmitStat === true && !Data.firstName && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>
                                *Enter Name
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input
                              type="text"
                              placeholder="Email Address*"
                              value={Data.email}
                              onChange={(e) => InputHandler(e, "email")}
                            />
                            {formSubmitStat === true && !Data.email && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>
                                *Enter an Email
                              </span>
                            )}
                            {validateEmail != undefined && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>*
                                {validateEmail}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input
                              type="text"
                              value={Data.phone}
                              onChange={(e) => InputHandler(e, "phone")}
                              placeholder="Phone Number*"
                            />
                            {formSubmitStat === true && !Data.phone && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>
                                *Enter Phone
                              </span>
                            )}
                            {validatePhone != undefined && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>*
                                {validatePhone}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <input
                              type="text"
                              value={Data.companyName}
                              onChange={(e) => InputHandler(e, "companyName")}
                              placeholder="Company Name*"
                            />
                            {formSubmitStat === true && !Data.companyName && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>
                                *Enter Company Name
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="cu-form">
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => InputHandler(e, "companySize")}
                              value={Data.companySize}
                            >
                              <option value="" disabled selected>
                                Company Size*
                              </option>
                              <option value="1 - 4">1 - 4</option>
                              <option value="5 - 9">5 - 9</option>
                              <option value="10 - 19">10 - 19</option>
                              <option value="20 - 39">20 - 39</option>
                              <option value="40+">40+</option>
                            </select>
                            {formSubmitStat === true && !Data.companySize && (
                              <span className="error">
                                <i className="fas fa-exclamation-triangle"></i>
                                *Enter Your Company Size
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="cu-form">
                            <textarea
                              value={Data.comments}
                              onChange={(e) => InputHandler(e, "comments")}
                              placeholder="Comments"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="cu-form">
                            <button
                              type="submit"
                              value="Submit"
                              className={
                                disabled ? "pgbtn disablebtn" : "pgbtn"
                              }
                              disabled={disabled}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="ContactUSMOdal"
          show={show}
          onHide={ModalcloseHandler}
          keyboard={true}
        >
          <Modal.Body>
            <div class="row justify-content-center">
              <div className="col-md-7">
                <div className="modal_image">
                  <img alt="" src={ModalLogo} />
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="modal_customcontent">
                  <h2>Thank You!</h2>
                  <p>Your request is being processed.</p>
                  <p>A pijen representive will reach out to you shortly.</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <i className="cross fa fa-times" onClick={ModalcloseHandler}></i>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default connect(null, { ...NPCDuck.actions })(ContactForm);
