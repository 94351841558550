import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CKEditor from "ckeditor4-react";
import { useHistory, useParams } from 'react-router-dom';
import * as emailConfigurationDuck from '../../../../store/ducks/super-admin/EmailConfiguration.duck '
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { MESSAGES } from '../../../../config/message';
import './Milestones.css'

const EditMilestones = ({ getActionListById, updateActionList }) => {

    const [formData, setFormData] = useState({
        action: '',
        template: ''
    });
    const { id } = useParams();
    const history = useHistory()
    // console.warn("Action id:",id);

    useEffect(()=>{
        const reqestData = {
            id
        }
        getActionListById(reqestData, (res) => {
            console.warn("Res", res);
            setFormData({
                action:  res.data.action ? res.data.action :  '',
                template:  res.data.template ? res.data.template :  ''
            })
            // let arr = [];
            // arr = res.data.rows;
            // // setTemplateList(arr);
            // console.warn("templ", formData);
        });
    }, [setFormData, getActionListById, id])

    const { action, template } = formData;
    let config = {
        toolbar: [
            { name: "save", items: ["savebtn", "Undo", "Redo"] },
            {
                name: "clipboard",
                items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord"],
            },
            { name: "document", items: ["Find", "Replace"] },
            {
                name: "lists",
                items: ["NumberedList", "BulletedList", "Outdent", "Indent"],
            },
            { name: "insert", items: ["Image", "Table", "Smiley", "SpecialChar"] },
            { name: "link", items: ["Link", "Unlink"] },
            {
                name: "basicstyles",
                items: [
                    "Font",
                    "FontSize",
                    "Bold",
                    "Italic",
                    "Underline",
                    "Strike",
                    "Subscript",
                    "Superscript",
                ],
            },
            {
                name: "align",
                items: [
                    "JustifyLeft",
                    "JustifyCenter",
                    "JustifyRight",
                    "JustifyBlock",
                ],
            },
        ],
    };
    config.extraPlugins = "font,justify,showblocks";
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: [e.target.value] })
    const onEditorChange = (evt, editor, data) => {
        setFormData({
            ...formData,
            template: evt.editor.getData(),
        })
    }

    const onSubmit = (e) => {
        // console.warn("FD:",formData)
        e.preventDefault();
        if(typeof(formData.action) == "object"){
            formData.action = formData.action[0]
        }
        console.warn(formData);
        const requestData = {
            formData,
            id
        }
        updateActionList(requestData, (res) => {
            console.warn("Res", res);
            toastr.success("Success", MESSAGES.ACTION_UPDATE_SUCCESS);
            history.replace("/milestones");
            // let arr = [];
            // arr = res.data.rows;
            // // setTemplateList(arr);
            // console.warn("templ", res.data.rows);
        });
        // addPost(action, template)
        // return <Redirect to='/view-posts' />
    }

    return (
        <div className="container m-auto mt-5">
            <form className="form" onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">Action</label>
                    <input
                        className="form-control"
                        name="action"
                        value={action}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Template</label>
                    {
                        template? (
                            <CKEditor

                        data={template}
                        // onReady={editor => {
                        //     // You can store the "editor" and use when it is needed.
                        //     console.log('Editor is ready to use!', editor);
                        // }}
                        config={config}
                        onChange={onEditorChange}
                        // onChange={ ( event, editor ) => {
                        //     const data = editor.getData();
                        //     console.log( { event, editor, data } );
                        // } }
                        // onBlur={(event, editor) => {
                        //     console.log('Blur.', editor);
                        // }}
                        // onFocus={(event, editor) => {
                        //     console.log('Focus.', editor);
                        // }}
                        onBeforeLoad={(CKEDITOR) =>
                            (CKEDITOR.disableAutoInline = true)
                          }
                    />
                        ) : ""
                    }
                    
                </div>
                {/* <div className="form-group">
                    <input className="saveBtn" type="submit" value="Submit" />
                </div> */}
            </form>
        </div>
    )
}

const mapStateToProps = store => {
    const { auth, emailConfigurationDuck, authToken } = store;
    return {
        loggedInUser: auth.user,
        authToken: authToken,
        requestList:
            emailConfigurationDuck.templateList &&
            emailConfigurationDuck.templateList,
        searchResults: emailConfigurationDuck.searchResults
    };
};

export default injectIntl(
    connect(mapStateToProps, emailConfigurationDuck.actions)(EditMilestones)
);
