export default {
  header: {
    self: {},
    items: [
    ]
  },
  commonAside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon-dashboard",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot",
        key: "dashboard"
      },
      {
        title: "Ad Management",
        root: true,
        icon: "flaticon-settings",
        translate: "MENU.AD-MANGEMENT",
        page: "adManagement",
        key: 'adManagement'
      },
      // {
      //   title: "Orders",
      //   root: true,
      //   icon: "flaticon-business",
      //   translate: "MENU.ORDERS",
      //   page: "orderManagement",
      //   //page: "upgradation",
      //   key: "orders"
      // },
      { section: "Profiles" },
      {
        title: "My Profile",
        root: true,
        bullet: "dot",
        icon: "flaticon2-user-1",
        page: "myProfile",
        // key: "myProfile",
        key: "dashboard"

      },
      { section: "Accounts" },
      {
        title: "Admin Management",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "adminManagement",
        key: "userManagement"
        // key: "adminManagement"
      },
      {
        title: "Subscriptions",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        key: "userManagement",
        // key: "adminManagement"
        page: "active-subscription",
        submenu: [
          {
            title: "Active",
            root: true,
            bullet: "dot",
            page: "active-subscription"
          },
          {
            title: "Cancelled",
            root: true,
            bullet: "dot",
            page: "cancelled-subscription"
          },
          {
            title: "Incomplete",
            root: true,
            bullet: "dot",
            page: "incomplete-subscription"
          }
        ]
      },
      {
        title: "Activity Log",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "users-activity",
        key: "userManagement"
      },
      {
        title: "Publishers",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "newspaperManagement",
        // key: "newspaperManagement"
        key: "userManagement",
      },
      {
        title: "Edited Price List",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "allAgentseditList",
        // key: "newspaperManagement"
        key: "userManagement"

      },
      {
        title: "Customer Accounts",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "customerManagement",
        // key: "customerManagement"
        key: "userManagement"

      },
      {
        title: "Customer Access",
        root: true,
        bullet: "dot",
        icon: "flaticon2-sheet",
        page: "customer-access",
        // key: "allRequestListing"
        key: "userManagement"
      },
      {
        title: "Registrations",
        root: true,
        bullet: "dot",
        icon: "flaticon-interface-10",
        page: "allRequestListing",
        // key: "allRequestListing"
        key: "userManagement"
      },
      { section: "" },
      {
        title: "Email Templates",
        root: true,
        bullet: "dot",
        icon: "flaticon-email",
        page: "email-template",
        key: "emailTemplate"
      },
      // {
      //   title: "Credits",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-piggy-bank",
      //   page: "credits",
      //   key: "userManagement"
      // },
      // {
      //   title: "Messages",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-multimedia-2",
      //   page: "upgradation",
      //   key: "messages"
      // },
      {
        title: "Notification",
        root: true,
        bullet: "dot",
        icon: "flaticon-alert",
        page: "notifications",
        //page: "upgradation",
        key: "notifications"
      },
      { section: "New Reports" },
      {
        title: "Customer Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon3-user",
        page: "customer-summary",
        key: "reports"
      },
      {
        title: "Agents Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon3-business-man",
        page: "agent-summary",
        key: "reports"
      },
      {
        title: "Publisher Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon3-newspaper",
        page: "publisher-summary",
        key: "reports"
      },
      {
        title: "Pijen Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "pijen-summary",
        key: "reports"
      },    
      { section: "Reports" },
      {
        title: "Payments",
        root: true,
        bullet: "dot",
        icon: "flaticon-coins",
        page: "payout-summary",
        key: "reports"
      },
      {
        title: "Company Summary",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic",
        page: "company-summary",
        key: "reports"
      },
      {
        title: "Sales",
        root: true,
        bullet: "dot",
        icon: "flaticon-diagram",
        page: "sales-reports",
        key: "reports"
      },
      {
        title: "Orders",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "order-report",
        key: "reports"
      },
      {
        title: "Customer Summary",
        root: true,
        bullet: "dot",
        icon: "flaticon-list-2",
        page: "customer-reports",
        key: "reports"
      },
      {
        title: "Ad Type",
        root: true,
        bullet: "dot",
        icon: "flaticon2-browser-2",
        page: "adtype-report",
        key: "reports"
      },
      {
        title: "Seasonal Issues",
        root: true,
        icon: "flaticon4-seasonal-issue",
        page: "addSeasonalIssue",
        key: "reports",
        bullet: "dot"
      },
      { section: "Settings" },
      {
        title: "Settings",
        root: true,
        bullet: "dot",
        icon: "flaticon-settings-1",
        page: "settings",
        key: "settings"
      },
      {
        title: "Email Communication",
        root: true,
        bullet: "dot",
        icon: "flaticon-email",
        page: "new-email",
        key: "settings"
      },
      {
        title: "Milestones",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "milestones",
        key: "settings"
      },
      {
        title: "General Settings",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "gsettings",
        key: "settings"
      }
    ]
  },
  superAdminAside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon-dashboard",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Ad Management",
        root: true,
        icon: "flaticon-settings",
        translate: "MENU.AD-MANGEMENT",
        page: "adManagement"
      },
      // {
      //   title: "Orders",
      //   root: true,
      //   icon: "flaticon-business",
      //   translate: "MENU.ORDERS",
      //   page: "orderManagement"
      //   //page: "upgradation",
      // },
      { section: "Profiles" },
      {
        title: "My Profile",
        root: true,
        bullet: "dot",
        icon: "flaticon2-user-1",
        page: "myProfile"
      },
      {
        title: "Roles",
        root: true,
        bullet: "dot",
        icon: "flaticon2-group",
        page: "roles"
      },
      { section: "Accounts" },
      {
        title: "Admin Management",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "adminManagement"
      },
      {
        title: "Subscriptions",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "active-subscription",
        submenu: [
          {
            title: "Active",
            root: true,
            bullet: "dot",
            page: "active-subscription"
          },
          {
            title: "Cancelled",
            root: true,
            bullet: "dot",
            page: "cancelled-subscription"
          },
          {
            title: "Incomplete",
            root: true,
            bullet: "dot",
            page: "incomplete-subscription"
          }
        ]
      },
      {
        title: "Activity Log",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "users-activity"
      },
      {
        title: "Publishers",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "newspaperManagement"
      },
      {
        title: "Edited Price List",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "allAgentseditList",
        // key: "newspaperManagement"
        key: "userManagement"

      },
      
      {
        title: "Customer Accounts",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "customerManagement"
      },
      {
        title: "Customer Access",
        root: true,
        bullet: "dot",
        icon: "flaticon2-sheet",
        page: "customer-access",
        // key: "allRequestListing"
        key: "customer-access"
      },
      {
        title: "Registrations",
        root: true,
        bullet: "dot",
        icon: "flaticon-interface-10",
        page: "allRequestListing"
      },

      { section: "" },
      {
        title: "Email Templates",
        root: true,
        bullet: "dot",
        icon: "flaticon-email",
        page: "email-template"
      },
      // {
      //   title: "Credits",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-piggy-bank",
      //   page: "credits"
      // },
      // {
      //   title: "Messages",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-multimedia-2",
      //   page: "upgradation"
      // },
      // {
      //   title: "Coupons",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-open-box",
      //   page: "upgradation"
      // },
      {
        title: "Notification",
        root: true,
        bullet: "dot",
        icon: "flaticon-alert",
        page: "notifications"
        //page: "upgradation",
      },
      { section: "New Reports" },
      {
        title: "Customer Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon3-user",
        page: "customer-summary",
        
      },
      {
        title: "Agents Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon3-business-man",
        page: "agent-summary",
        
      },
      {
        title: "Publisher Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon3-newspaper",
        page: "publisher-summary",
        
      },
      {
        title: "Pijen Transactions",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "pijen-summary",
        
      },  
      { section: "Reports" },
      {
        title: "Payout Summary",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic",
        page: "payout-summary"
      },
      {
        title: "Company Summary",
        root: true,
        bullet: "dot",
        icon: "flaticon-interface-7",
        page: "company-summary"
      },
      {
        title: "Customer Summary",
        root: true,
        bullet: "dot",
        icon: "flaticon-list-2",
        page: "customer-reports"
      },
      {
        title: "Sales",
        root: true,
        bullet: "dot",
        icon: "flaticon-diagram",
        page: "sales-reports"
      },
      {
        title: "Orders",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "order-report"
      },
      {
        title: "Ad Type",
        root: true,
        bullet: "dot",
        icon: "flaticon2-browser-2",
        page: "adtype-report"
      },
      {
        title: "Seasonal Issues",
        root: true,
        icon: "flaticon4-seasonal-issue",
        page: "addSeasonalIssue",
        translate: "MENU.COMPANYPROFILE",
        bullet: "dot"
      },
      { section: "Settings" },
      {
        title: "Settings",
        root: true,
        bullet: "dot",
        icon: "flaticon-settings-1",
        page: "settings"
      },
      {
        title: "Email Communication",
        root: true,
        bullet: "dot",
        icon: "flaticon-email",
        page: "new-email",
        key: "settings"
      },
      {
        title: "Milestones",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "milestones",
        key: "settings"
      },
      {
        title: "General Setting",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic-2",
        page: "gsettings",
        key: "gsettings"
      }
    ]
  },
  newspaperAside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon-dashboard",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Company Profile",
        root: true,
        icon: "flaticon2-user-1",
        page: "companyProfile",
        translate: "MENU.COMPANYPROFILE",
        bullet: "dot"
      },
      {
        title: "Our Plans",
        root: true,
        icon: "flaticon-list-2",
        page: "ourPlans",
        // translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Seasonal Issues",
        root: true,
        icon: "flaticon4-seasonal-issue",
        page: "seasonalIssue",
        translate: "MENU.COMPANYPROFILE",
        bullet: "dot"
      },
      // { section: "My Orders" },
      // {
      //   title: "Receive Orders",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-edit-1",
      //   page: "recievedOrderListing"
      //   //page: "upgradation"
      // },
      // {
      //   title: "All Orders",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-list-1",
      //   page: "allOrders"
      //   //page: "upgradation"
      // },
      // {
      //   title: "Customer",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-users",
      //   page: "customer-access"
      // },
      // {
      //   title: "Agent Discount",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-users",
      //   page: "agent-discount"
      // },
      { section: "Payment Details" },
      {
        title: "Payments",
        root: true,
        bullet: "dot",
        icon: "flaticon-coins",
        page: "payout-reports"
      },
      // {
      //   title: "Financials",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-interface-7",
      //   page: "upgradation"
      // },
      // {
      //   title: "Credits",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-piggy-bank",
      //   page: "credits"
      // },
      // {
      //   title: "Refunds",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-car",
      //   page: "upgradation"
      // },
      { section: "Notifications" },
      {
        title: "Notifications",
        root: true,
        bullet: "dot",
        icon: "flaticon-alert",
        page: "notifications"
        // page: "upgradation",
      },
      { section: "Reports" },
      {
        title: "Payment History",
        root: true,
        bullet: "dot",
        icon: "flaticon-statistics",
        page: "payoutHistory"
        // page: "upgradation",
      },
      {
        title: "Sales Reports",
        root: true,
        bullet: "dot",
        icon: "flaticon-diagram",
        page: "sales-reports"
        // page: "upgradation",
      },
      // {
      //   title: "Orders",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon2-browser-2",
      //   page: "order-report"
      // },
      {
        title: "Ad Type",
        root: true,
        bullet: "dot",
        icon: "flaticon-graphic",
        page: "adtype-reports",
      },
      // {
      //   title: "Send",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-danger",
      //   page: "notifications"
      // },
      { section: "Settings" },
      {
        title: "Settings",
        root: true,
        bullet: "dot",
        icon: "flaticon-settings-1",
        page: "settings"
      }
    ]
  },
  adminAside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon-dashboard",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Admin Profile",
        root: true,
        icon: "flaticon2-user-1",
        page: "upgradation",
        translate: "MENU.COMPANYPROFILE",
        bullet: "dot"
      },
      {
        title: "Our Plans",
        root: true,
        icon: "flaticon-list-2",
        page: "upgradation",
        bullet: "dot"
      },
      { section: "My Orders" },
      {
        title: "Recieve Orders",
        root: true,
        bullet: "dot",
        icon: "flaticon-edit-1",
        page: "upgradation"
      },
      {
        title: "All Orders",
        root: true,
        bullet: "dot",
        icon: "flaticon-list-1",
        page: "upgradation"
      },
      {
        title: "Customers",
        root: true,
        bullet: "dot",
        icon: "flaticon-users",
        page: "upgradation"
      },
      { section: "Payment Details" },
      {
        title: "Payments",
        root: true,
        bullet: "dot",
        icon: "flaticon-coins",
        page: "upgradation"
      },
      {
        title: "Financials",
        root: true,
        bullet: "dot",
        icon: "flaticon-interface-7",
        page: "upgradation"
      },
      {
        title: "Credits",
        root: true,
        bullet: "dot",
        icon: "flaticon-piggy-bank",
        page: "credits"
      },
      {
        title: "Refunds",
        root: true,
        bullet: "dot",
        icon: "flaticon-car",
        page: "upgradation"
      },
      { section: "Notifications" },
      {
        title: "Recieve",
        root: true,
        bullet: "dot",
        icon: "flaticon-alert",
        page: "notifications"
        //page: "upgradation",
      },
      {
        title: "Send",
        root: true,
        bullet: "dot",
        icon: "flaticon-diagram",
        page: "upgradation"
      }
    ]
  }
};
