import { Typography } from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import { Container } from "reactstrap";
import MonthlyPlan from "./MonthlyPlan";
import "./PricingPlan.scss";
import YearlyPlan from "./YearlyPlan";
import { getAllSubscriptions } from "../../../crud/auth.crud";
import { Link } from "react-router-dom";

export default function PricingPlan({ newUser }) {
  const [tab, settab] = useState(1);
  const [dataplan, setDataplan] = useState([]);
  const [winScroll, setWinScroll] = useState(0);

  const tabHandler = (index) => {
    settab(index);
  };

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setWinScroll(winScroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  });

  useEffect(() => {
    getAllSubscriptions().then((res) => {
      res &&
        res.data &&
        res.data.length > 0 &&
        res.data.map((item) => {
          let features =
            item &&
            item.allFeatures &&
            item.allFeatures.length > 0 &&
            item.allFeatures.filter((feature) => {
              if (newUser) {
                if (feature.name.includes(newUserFeatures.client)) {
                  feature.name = feature.name.replace(
                    newUserFeatures.client,
                    newUserFeatures.department
                  );
                }
                if (feature.name.includes(newUserFeatures.Client)) {
                  feature.name = feature.name.replace(
                    newUserFeatures.Client,
                    newUserFeatures.Department
                  );
                }
                if (!feature.name.includes(newUserFeatures.commissionFeature))
                  return feature;
              } else return feature;
            });
          item.allFeatures = features;
          return item;
        });
      setDataplan(res.data);
    });
  }, []);

  return (
    <Fragment>
      <Container>
        <div className="pricingplan_part">
          <div className="heading">
            <Typography variant="h1">Proposal to production.</Typography>
            <Typography variant="h1">Choose your plan.</Typography>
            <Typography variant="h6">
              Ad placements systemized. Saving you time and money.
            </Typography>
          </div>
          <div className="tab_section">
            <div className="tab_header">
              <div className="w">
                <nav>
                  <input type="radio" name="tab" id="home" />
                  <input type="radio" name="tab" id="inbox" />
                  <label
                    for="home"
                    className="home"
                    onClick={() => tabHandler(1)}
                  >
                    Monthly
                  </label>
                  <label
                    for="inbox"
                    className="inbox"
                    onClick={() => tabHandler(2)}
                  >
                    Yearly
                  </label>
                  <div className="tab"></div>
                </nav>
              </div>
            </div>
            <div
              className="pricingtab_content"
              data-aos-delay="600"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div
                className={
                  tab === 1 ? "pricingcontent active" : "pricingcontent"
                }
              >
                <MonthlyPlan
                  data={dataplan}
                  scrollData={winScroll}
                  newUser={newUser}
                />
              </div>
              <div
                className={
                  tab === 2 ? "pricingcontent active" : "pricingcontent"
                }
              >
                <YearlyPlan
                  data={dataplan}
                  scrollData={winScroll}
                  newUser={newUser}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export const newUserFeatures = {
  commissionFeature: "Commission's",
  client: "client",
  department: "department",
  Department: "Department",
  Client: "Client",
};
