import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetCustomerAccessRequestList: "[SetCustomerAccessRequestList] Action",
    SetCustomerDiscount: "[SetCustomerDiscount] Action",
    SetAgentDiscount: "[SetAgentDiscount] Action",
    SetAgentPublisherList: "[SetAgentPublisherList] Action",
    SetadminEditedPriceList: "[SetadminEditedPriceList] Action"
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    customerList: undefined,
    listArray: [],
    discountData: [],
    agentdiscountList:[],
    agentpublisherList:[],
    adminEditedPriceList:[]
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for customer Request List */
            case actionTypes.SetCustomerAccessRequestList: {
                let tempArray = []
                const { customerList } = action.payload;
               
                return { ...state, customerList: customerList };
            }
            case actionTypes.SetCustomerDiscount: {
                const { discountData } = action.payload;
                return { ...state, discountData: discountData };
            }

            case actionTypes.SetAgentDiscount: {
                const { agentdiscountList } = action.payload;
                return { ...state, agentdiscountList: agentdiscountList };
            }

            case actionTypes.SetAgentPublisherList: {
                const { agentpublisherList } = action.payload;
                return { ...state, agentpublisherList: agentpublisherList };
            }
            case actionTypes.SetadminEditedPriceList: {
                const { adminEditedPriceList } = action.payload;
                return { ...state, adminEditedPriceList: adminEditedPriceList };
            }
            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    getCustomerAccessRequestList: (requestData, callback) => {
        const { npc_id, search, sort, column, row, page } = requestData
        const request = axios.get(`${API.getCustomerAccessRequestList}?id=${npc_id}&sort=${sort}&column=${column}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetCustomerAccessRequestList, payload: { customerList: response.data } })

            }).catch((error) => {
                //callback(error)
                //apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },
    getAgentDiscountList: (requestData, callback) => {
        const { npc_id, search, sort, column, row, page } = requestData
        const request = axios.get(`${API.getAgentDiscountList}?id=${npc_id}&sort=${sort}&column=${column}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
               // console.warn("Disco dancer",response.data.rows );
                callback(response)
                return dispatch({ type: actionTypes.SetAgentDiscount, payload: { agentdiscountList: response.data } })

            }).catch((error) => {
                //callback(error)
                //apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    getAgentEditPublisherList: (requestData, callback) => {
        const { npc_id, search, sort, column, row, page } = requestData
        const request = axios.get(`${API.getAgentEditPublisherList}?sort=${sort}&column=${column}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
               // console.warn("Disco dancer",response.data.rows );
                callback(response)
                return dispatch({ type: actionTypes.SetAgentPublisherList, payload: { agentpublisherList: response.data } })

            }).catch((error) => {
                //callback(error)
                //apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    getadminEditedPriceList: (requestData, callback) => {
        const { npc_id, search, sort, column, row, page } = requestData
        const request = axios.get(`${API.getadminEditedPriceList}?sort=${sort}&column=${column}&row=${row}&page=${page}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
               // console.warn("Disco dancer",response.data.rows );
                callback(response)
                return dispatch({ type: actionTypes.SetadminEditedPriceList, payload: { adminEditedPriceList: response.data } })

            }).catch((error) => {
                //callback(error)
                //apiErrors(error, dispatch);
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    /**
   * @method addCustomer
   * @description add customer for access
   */
    addCustomer: (requestData, callback) => {
        let api_call = `${API.customerAccessRequest}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        }
    },

    enableandDisablediscount: (requestData, callback) => {
        const { id } = requestData
        let api_call = `${API.enableandDisablediscount}?id=${id}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        }
    },

    enableDisablePublisherEditable: (requestData, callback) => {
        const { id } = requestData
        let api_call = `${API.enableDisablePublisherEditable}?id=${id}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        }
    },
 

    /**
     * @method getDiscountDetailById
     * @description get discount detail by id
     */
    getDiscountDetailById: (requestData) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.SetCustomerDiscount, payload: { discountData: requestData } })
        }
    },

    /**
     * @method resetDetail
     * @description reset discount detail
     */
    resetDetail: (requestData) => {
        return (dispatch) => {
            return dispatch({ type: actionTypes.SetCustomerDiscount, payload: { discountData: [] } })
        }
    },

    /**
     * @method addCustomerDiscount
     * @description add customer discount
     */
    addCustomerDiscount: (requestData, callback) => {
        let api_call = `${API.addCustomerDiscount}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch);
                callback(error);
            });
        }
    },
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





