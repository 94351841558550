import React, { Component } from "react";
import { connect } from "react-redux";
import { displayDateTimeFormate } from "../../common";
import * as notificationDuck from "../../store/ducks/Notifications.duck ";
import { injectIntl } from "react-intl";
import { LayoutContextConsumer } from "../../../_metronic/layout/LayoutContext";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import { Media, Row } from "reactstrap";
import { MESSAGES } from "../../config/message";
import { Redirect } from "react-router-dom";
import { langs } from "../../config/localization";
import NotificationView from "../../common/NotificationView";

class NotificationList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      viewComapny: false,
      viewAdmin: false,
      viewCustomer: false,
    };
  }

  /**
   * @method componentWillMount
   * @description call before rendering the component
   */
  componentWillMount() {
    const { user_detail } = this.props.loggedInUser;
    const Data = {
      receiver_id: user_detail.id,
    };
    this.props.updateNotificationCount(Data, (res) => {});
  }

  /**
   * @method componentDidMount
   * @description Renders after the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    const requestData = {
      id: "",
      receiver_id: user_detail.id,
    };
    this.props.getNotificationList(requestData, (res) => {});
  }

  /**
   * @method onClickNotificationTile
   * @description used to specify type of notification
   */
  onClickNotificationTile = (id, notificationType) => {
    switch (notificationType) {
      case langs.notificationType.newspaper:
        this.setState({
          viewComapny: true,
          redirectID: id,
        });
        break;
      case langs.notificationType.admin:
        this.setState({
          viewAdmin: true,
          redirectID: id,
        });
        break;
      case langs.notificationType.customer:
        this.setState({
          viewCustomer: true,
          redirectID: id,
        });
        break;
      default:
        break;
    }
  };

  /**
   * @method renderList
   * @description Render notification list
   */
  renderList = () => {
    const { notificationList } = this.props;
    if (notificationList && notificationList.length) {
      return (
        Array.isArray(notificationList) &&
        notificationList.map((data, index) => {
          return (
            <div key={index}>
              <Row>
                <NotificationView
                  data={data}
                  imgSrc={require("../../../assets/images/defaultUser.png")}
                />
              </Row>
              <hr />
            </div>
          );
        })
      );
    } else {
      return (
        <div style={{ marginLeft: "400px", marginTop: "4px" }}>
          <h4>{MESSAGES.EMPTY_NOTIFICATION}</h4>
        </div>
      );
    }
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { viewComapny, redirectID, viewAdmin, viewCustomer } = this.state;
    if (viewComapny) {
      return (
        <Redirect
          push
          to={{
            pathname: `/viewRequest/${redirectID}`,
          }}
        />
      );
    }
    if (viewAdmin) {
      return (
        <Redirect
          push
          to={{
            pathname: `/adminManagement`,
          }}
        />
      );
    }
    if (viewCustomer) {
      return (
        <Redirect
          push
          to={{
            pathname: `/customerManagement`,
          }}
        />
      );
    }
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>All Notifications</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <Portlet fluidHeight={true}>
          <PortletBody>{this.renderList()}</PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, authToken, notificationDuck } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    notificationList: notificationDuck.notificationList,
  };
};

export default injectIntl(
  connect(mapStateToProps, notificationDuck.actions)(NotificationList)
);
