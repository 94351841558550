import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "@material-ui/core";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PlansList from "./PlansList";
import SocialPlansList from "./SocialPlansList";
import { Link } from "react-router-dom";
import ArchivePlansList from "./ArchivePlansList";
import Notice from "../../../../partials/content/Notice";
import { LayoutContextConsumer } from "../../../../../_metronic";
import "./ourPlanList.css"
import { Field, reduxForm } from "redux-form";
import { renderSelectField } from "../../../../common/layout/FormInput";
import { makeStyles } from '@material-ui/core';
import {InputLabel} from '@material-ui/core';
import {MenuItem} from '@material-ui/core';
import {FormHelperText} from '@material-ui/core';
import {FormControl} from '@material-ui/core';
import {Select} from '@material-ui/core';
import { Dropdown } from "bootstrap";
import { concat } from "lodash";
import ArchiveSocialPlansList from "./ArchiveSocialPlansList";
import OutofHomePlansList from "./OutofHomePlansList";
import ArchiveOutOfHome from "./ArchiveOutOfHome";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OurPlansList =  ({ loggedInUser, getDropdown, categoryDropdown, loading }) => {

  const classes = useStyles();
  const [age, setAge] = useState("");

  
  const { user_detail } = loggedInUser
  const [category, setCategory] = useState([]);
  

  if(localStorage.getItem("Print") && localStorage.getItem("OutofHome") && localStorage.getItem("Social")){
    if(parseInt(localStorage.getItem("tabIndex")) > 2 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }

  if(localStorage.getItem("Print") && localStorage.getItem("OutofHome")){
    if(parseInt(localStorage.getItem("tabIndex")) > 1 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }

  if(localStorage.getItem("Print") && localStorage.getItem("Social")){
    if(parseInt(localStorage.getItem("tabIndex")) > 1 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }

  if(localStorage.getItem("OutofHome") && localStorage.getItem("Social")){
    if(parseInt(localStorage.getItem("tabIndex")) > 1 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }

  if(localStorage.getItem("OutofHome")){
    if(parseInt(localStorage.getItem("tabIndex")) > 0 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }  

  if(localStorage.getItem("Social")){
    if(parseInt(localStorage.getItem("tabIndex")) > 0 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }

  if(localStorage.getItem("Print")){
    if(parseInt(localStorage.getItem("tabIndex")) > 0 && parseInt(localStorage.getItem("tabIndex")) < 0){
      localStorage.removeItem("tabIndex");
    }  
  }
  
  
  const [tabIndex, setTabIndex] = useState(localStorage.getItem("tabIndex") ? parseInt(localStorage.getItem("tabIndex")) : 0);
  const [tabIndex1, setTabIndex1] = useState(0);
  const [print, setPrint] = useState("");
  const [social_media, setSocial] = useState("");
  const [out_of_box, setOutOfBox] = useState("");
  const [type, setType] = useState("");
  const [count, setCount] = useState(0);

  const getCatName = (value) => {
    const a = value.split(" ")
    var new_val="";
    for(let i = 0; i< a.length; i++){
      if(a[i] != ""){
        // //console.warn(a[i])
        new_val = new_val + a[i]
      }
    }
    // //console.warn(new_val)
    return new_val;
    
  }

  useEffect(() => {

    if(category.length == 0){
      getDropdown(user_detail.newspaper.id, (res) => {
        const planType = res.data;
  
        setCategory(planType)
        // //console.warn("111111",category)
  
        // Removing localstorage
        if(localStorage.getItem("Print")){
          localStorage.removeItem("Print");
        }
        if(localStorage.getItem("Social")){
          localStorage.removeItem("Social");
        }
        if(localStorage.getItem("OutofHome")){
          localStorage.removeItem("OutofHome");
        }
        
  
        // if(category){
        //   for(let i = 0; i< categoryDropdown.length; i++){
        //     var cat_name = getCatName(categoryDropdown[i].name);
        //     // //console.warn("Cat Name", cat_name)
        //     if(cat_name == "Print"){
        //       // setPrint(cat_name)
              
        //       //Print
        //       setPrint(cat_name)
        //       localStorage.setItem("Print", print)
        //       // //console.warn(">>Print>>", print)
        //       // //console.warn(cat_name)
        //       setCount(1)
        //     }
        //     else if(cat_name == "SocialMedia"){
        //       setSocial(cat_name)
        //       localStorage.setItem("Social", social_media)
        //       // //console.warn(">>Social>>",social_media)
        //       //Social Media Plan
        //     }
        //     else if(cat_name == "OutofHome"){
        //       setOutOfBox(cat_name)
        //       localStorage.setItem("OutofHome", out_of_box)
        //       // //console.warn(">>Out>>",out_of_box)
        //     }
        //   }
        // }
  
      })
    }

    if(category.length > 0){
      for(let i = 0; i< categoryDropdown.length; i++){
        var cat_name = getCatName(categoryDropdown[i].name);
        // //console.warn("Cat Name", cat_name)
        if(cat_name == "Print"){
          // setPrint(cat_name)
          
          //Print
          setPrint(cat_name)
          localStorage.setItem("Print", print)
          // //console.warn(">>Print>>", print)
          // //console.warn(cat_name)
          setCount(1)
        }
        else if(cat_name == "SocialMedia"){
          setSocial(cat_name)
          localStorage.setItem("Social", social_media)
          // //console.warn(">>Social>>",social_media)
          //Social Media Plan
        }
        else if(cat_name == "OutofHome"){
          setOutOfBox(cat_name)
          localStorage.setItem("OutofHome", out_of_box)
          // //console.warn(">>Out>>",out_of_box)
        }
      }
    }
    

  },[category, user_detail.newspaper.id, categoryDropdown, getDropdown])

  


  // const { user_detail } = loggedInUser

  const clickedBtn = (e) => {
    // //console.warn(">>Getting logged in id>>", user_detail.newspaper.id)
      // //console.warn(">>>Action>>",getDropdown(user_detail.newspaper.id))
      if(categoryDropdown){
        // //console.warn(">>Category Dropdown>>", categoryDropdown)
        // //console.warn(">>Category Dropdown>>", loading)
          for(let i = 0; i< categoryDropdown.length; i++){
            if(categoryDropdown[i].id == "8"){
              setPrint(categoryDropdown[i].name)
              setCount(1)
            }
            else if(categoryDropdown[i].id == "10"){
              setSocial(categoryDropdown[i].name)
            }
            else if(categoryDropdown[i].id == "14"){
              setOutOfBox(categoryDropdown[i].name)
            }
          }
      }
      
  }

  


  const getCategoryDropdown = () => {
    // //console.warn('>>>>>>>>>>>>>>>>>', category);
     return Array.isArray(category) &&
     category.length > 0
       ? category.map((ad) => ({
         label: ad.name,
         value: ad.id,
       }))
       : [];
   };


   const onChanged = (event) => {
    // //console.warn(">>Event>>", event.target.value)
    setType(event.target.value)
    // //console.warn(">>Age>>", type)
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px auto",
          alignItems: "center",
        }}
      >
        <h3 className="kt-subheader__title">
          <span>Our Plans</span>
          {/* {type}{" "}{social_media} */}
        </h3>
      </div>
      <Notice>
        <Tabs
          
          value={ tabIndex }
          onChange={(event, newValue) => {
            // //console.warn(">>TabIndex>>", tabIndex)
              setTabIndex(newValue)
              localStorage.removeItem("tabIndex")
              localStorage.setItem("tabIndex", newValue)
              
            }
          }
          aria-label="disabled tabs example"
          className="builder-tabs"
        >
          {/* print social */}
          {
            print != "" && social_media != "" && out_of_box == "" && (
              
                <Tab label="Print" /> 
              
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && (
              
                <Tab label="Social Media" /> 
              
            )
          }
          {/* print social end */}

          {/* print social outofbox */}
          {
            print != "" && social_media != "" && out_of_box != "" && (
              <Tab label="Print" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && (
              <Tab label="Social Media" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && (
              <Tab label="Out of home" /> 
            )
          }
          {/* print social outbox end */}

          {/* print */}
          {
            print != "" && social_media == "" && out_of_box == "" && (
              <Tab label="Print" /> 
            )
          }
          {/* print end */}
          {/* social */}
          {
            print == "" && social_media != "" && out_of_box == "" && (
              <Tab label="Social Media" />
            )
          }
          {/* social end */}

          {/* social outofbox */}

          {
            print == "" && social_media != "" && out_of_box != "" && (
              <Tab label="Social Media" />
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && (
              <Tab label="Out of home" />
            )
          }
          {/* social outofbox end */}
          {/* outofbox */}
          {
            print == "" && social_media == "" && out_of_box != "" && (
              <Tab label="Out Of home" />
            )
          }
          {/* outofbox end */}
          {/* print outofbox */}
          {
            print != "" && social_media == "" && out_of_box != "" && (
              <Tab label="Print" /> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && (
              <Tab label="Out of home" />
            )
          }
          {/* print outofbox end */}

          {/* {
            print != "" && social_media != "" && out_of_box != "" ? (
              <>
                <Tab label="Print Plan" /> 
                <Tab label="Social Media" /> 
                <Tab label="Archive Plan" /> 
              </>
            ): ( print != "" && social_media != "" && out_of_box == ""  ? (
              <>
                <Tab label="Print Plan" /> 
                <Tab label="Social Media" /> 
              </>
            ) : ( print != "" && social_media == "" && out_of_box == "" ? (
              <>
                <Tab label="Print Plan" /> 
              </>
            ): ( print == "" && social_media != "" && out_of_box == "" ? (
              <>
                <Tab label="Social Media" /> 
              </>
            ): ( print == "" && social_media != "" && out_of_box != "" ? (
              <>
                <Tab label="Social Media" />
                <Tab label="Out of Box" />  
              </>
            ): (  print == "" && social_media == "" && out_of_box != "" ? (
              <>
                <Tab label="Social Media" /> 
              </>
            ): ( print != "" && social_media == "" && out_of_box != "" ? (
              <>
                <Tab label="Print Plan" /> 
                <Tab label="Out of Box" />  
              </>
            ): "" ) ) ))))
          } */}

          {/* {
            type == "" && count == 1 ? (
              <Tab label="Print Plan" /> 
            ): ""
          }
          
            
          {
            type == "" && count == 1 ? (
              <Tab label="Archive Plan" /> 
            ): ""
          }

          {
            type == "14" ? (
              <Tab label="Social Media" /> 
            ): (type == "8" ? (
              <Tab label="Print Plan" /> 
            ): "")
          }
          {
            type == "14" ? (
              // <Tab label="Social Media" />
              <></>
            ): (type == "8" ? (
              <Tab label="Archive Plan" /> 
            ): "")
          } */}


          {/* {
            count == 1 || age == "Print" ? (
              <>
                <Tab label={print} /> 
                <Tab label="Archive"/>
              </>
            ): (
               <Tab label={social_media} /> 
           
            )
          } */}
          
          {/* {
            age == "" ?
            social_media != "" ? (
              <Tab label={social_media}/>
            ): ""
          } */}

          {/* {
            count == 1 ? (
              <Tab label="Archive"/>
            ): ""
          } */}

          {/* <Tab label="Print" /> 
          <Tab label="Social Media"/>
          <Tab label="Out Of Box"/> */}
          {/* { category[0].name } */}


          {/* {
            <Tab label={ categoryDropdown[0].id == "8"? "Print" : "" } />
          } */}

          
        </Tabs>
      </Notice>

      

      <Notice style={{ backgroundColor: "#eef0f8", boxShadow: "none", border: "none" }}>
      <Tabs
          value={tabIndex1}
          onChange={(event, newValue) => setTabIndex1(newValue)}
          aria-label="disabled tabs example"
          className="builder-tabs"
        >
          {/* print social outofbox  */}
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 0 && (
                <Tab label="Active Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 0 && (
                <Tab label="Archive Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 0 && (
              <Link to={"/ourPlans/create-new"} id='home' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" /> 
              </Link>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 1 && (
              
              <Tab label="Active Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 1 && (
                <Tab label="Archive Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 1 && (
              <Link to={"/ourPlans/social-media-plans"} id='about' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" />  
              </Link>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 2 && (
              <Tab label="Active Plans" />  
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 2 && (
                <Tab label="Archive Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 2 && (
              <Link to="" id='home' className="large-xl">
              <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" /> 
            </Link>
            )
          }

          {/* print social outofbox */}

          {/* print social */}

          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 0 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 0 && (
                <Tab label="Archive Plan" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 0 && (
              
              <Link to={"/ourPlans/create-new"} id='home' className="large-xl">
                 <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" />  
              </Link>
              
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 1 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 1 && (
                <Tab label="Archive Plan" /> 
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 1 && (
              <Link to={"/ourPlans/social-media-plans"} id='about' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" />  
              </Link>
            )
          }
          {/* print social end */}

          {/* print  */}
          {
            print != "" && social_media == "" && out_of_box == "" && tabIndex == 0 && (
              <Tab label="Active Plans" />
            )
          }
          {
            print != "" && social_media == "" && out_of_box == "" && tabIndex == 0 && (
              <Tab label="Archive Plan" /> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box == "" && tabIndex == 0 && (
              
              <Link to={"/ourPlans/create-new"} id='home' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" /> 
              </Link> 
            )
          }
          {/* print end */}

          {/* social */}
          {
            print == "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print == "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && (
                <Tab label="Archive Plans" /> 
            )
          }
          {
            print == "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && (
              <Link to={"/ourPlans/social-media-plans"} id='about' className="large-xl">
              <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" />  
          </Link> 
            )
          }
          {/* Social End */}

          {/* social outofbox */}
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 0 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 0 && (
              <Tab label="Archive Plans" />
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 0 && (
              <Link to={"/ourPlans/social-media-plans"} id='about' className="large-xl">
                    <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" />  
                </Link>
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 1 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 1 && (
              <Tab label="Archive Plans" />
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 1 && (
              <Link to="" id='home' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" /> 
              </Link> 
            )
          }
          {/* social outofbox end */}

          {/* outofbox */}
          {
            print == "" && social_media == "" && out_of_box != "" && tabIndex == 0 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print == "" && social_media == "" && out_of_box != "" && tabIndex == 0 && (
              <Tab label="Archive Plans" />
            )
          }
          {
            print == "" && social_media == "" && out_of_box != "" && tabIndex == 0 && (
              <Link to="" id='home' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" /> 
              </Link> 
            )
          }
          {/* outofbox end */}

          {/* print outofbox */}
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 0 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 0 && (
              <Tab label="Archive Plan" /> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 0 && (
              
              <Link to={"/ourPlans/create-new"} id='home' className="large-xl">
                  <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" />  
              </Link> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 1 && (
              <Tab label="Active Plans" /> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 1 && (
              <Tab label="Archive Plan" /> 
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 1 && (
              <Link to="" id='home' className="large-xl">
                <Tab style={{ fontSize: 14 + "px", fontWeight: "bold", color: "#333" }} label="+ Add New Plan" /> 
              </Link> 
            )
          }
          {/* print outofbox end */}
           
        </Tabs>
      </Notice>

      <Portlet>

      {/* <>
            <PortletBody>
                <PlansList />
            </PortletBody>
            <PortletBody>
              <ArchivePlansList />
            </PortletBody>
            </> */}

            {/* print social outofbox  */}
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 0 &&  tabIndex1 == 0 && (
              <PortletBody>
                <PlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchivePlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 2 && (
                <>
                <Link to={"/ourPlans/create-new"} id='home' className="large-xl">
                    Create a Print Plan
                  </Link>
                </>
            )
          }

          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 1 &&  tabIndex1 == 0 && (
              <PortletBody>
                <SocialPlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchiveSocialPlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 2 && (
                // <Tab label="Create Plan" /> 
                <></>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 2 &&  tabIndex1 == 0 && (
              <PortletBody>
                <OutofHomePlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 2 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchiveOutOfHome />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box != "" && tabIndex == 2 && tabIndex1 == 2 && (
                // <Tab label="Create Plan" /> 
                <></>
            )
          }
          
          {/* print social */}
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 0 && tabIndex1 == 0 && (
              <PortletBody>
                <PlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchivePlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && tabIndex1 == 2 && (
                // <Tab label="Create Plan" />  
                <>
                <Link to={"/ourPlans/create-new"} id='home' className="large-xl">
                    Create a Print Plan
                  </Link>
                </>
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 1 && tabIndex1 == 0 && (
              <PortletBody>
                <SocialPlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box == "" && tabIndex == 1 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchiveSocialPlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media != "" && out_of_box == ""  && tabIndex == 1 && tabIndex1 == 2 && (
                // <Tab label="Create Plan" />  
                <></>
            )
          }

          {/* print social */}

          {/* print */}
          {
            print != "" && social_media == "" && out_of_box == "" && tabIndex == 0 && tabIndex1 == 0 && (
              <PortletBody>
                <PlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box == "" && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchivePlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box == "" && tabIndex == 0 && tabIndex1 == 2 && (
              <PortletBody>
                {/* create plan */}
                <></>
              </PortletBody>
            )
          }

          {/* social  */}
          {
            print == "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && tabIndex1 == 0 && (
              <PortletBody>
              <SocialPlansList />
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
              <ArchiveSocialPlansList />
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box == ""  && tabIndex == 0 && tabIndex1 == 2 && (
              <PortletBody>
              {/* <ArchivePlansList /Create Social> */}
              <></>
              </PortletBody>
            )
          }
          {/* social outofbox */}
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 0 && (
              <PortletBody>
              <SocialPlansList />
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
              <ArchiveSocialPlansList />
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 2 && (
              <PortletBody>
              {/* Create Social */}
              <></>
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 0 && (
              <PortletBody>
              {/* Outof box */}
              <OutofHomePlansList />
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 1 && (
              <PortletBody>
              {/* <ArchivePlansList /> */}
              <ArchiveOutOfHome />
            </PortletBody>
            )
          }
          {
            print == "" && social_media != "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 2 && (
              <PortletBody>
              {/* Create outofbox */}
              <></>
            </PortletBody>
            )
          }
          {/* social outofbox end */}

          {/* outofbox */}
          {
            print == "" && social_media == "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 0 && (
              <PortletBody>
              {/* Outof box */}
              <OutofHomePlansList />
            </PortletBody>
            )
          }
          {
            print == "" && social_media == "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
              {/* Outof box */}
              <ArchiveOutOfHome />
            </PortletBody>
            )
          }
          {
            print == "" && social_media == "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 2 && (
              <PortletBody>
              {/* Outof box */}
              <></>
            </PortletBody>
            )
          }

          {/* outofbox end */}

          {/* print outofbox */}
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 0 && (
              <PortletBody>
                <PlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 1 && (
              <PortletBody>
                <ArchivePlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 0 && tabIndex1 == 2 && (
              <PortletBody>
                {/* Create new plan */}
                <></>
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 0 && (
              <PortletBody>
                {/*out of box */}
                <OutofHomePlansList />
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 1 && (
              <PortletBody>
                {/* out of box  */}
                <ArchiveOutOfHome />
              </PortletBody>
            )
          }
          {
            print != "" && social_media == "" && out_of_box != "" && tabIndex == 1 && tabIndex1 == 2 && (
              <PortletBody>
                {/* Create new plan */}
                <></>
              </PortletBody>
            )
          }
          {/* print out of box end */}


          
            

        {/* { 
          count == 1 && age == "Print" ? (
            <>
            <PortletBody>
                <PlansList />
            </PortletBody>
            <PortletBody>
              <ArchivePlansList />
            </PortletBody>
            </>
          ): ( age == "Social Media Plan"  ? (
            <PortletBody>
              <SocialPlansList />
            </PortletBody>
          ): (
            age == "Out of Home" ? (
              <>
              </>
            ): ""
          ) )
        } */}
        
        {/* {tabIndex === 0 && count == 1 && (
          <PortletBody>
            <PlansList />
          </PortletBody>
          
        )}

        {tabIndex === 0 && type="14" && (
          <PortletBody>
            <SocialPlansList />
          </PortletBody>
        )}

        {tabIndex === 1 && social_media && (
          <PortletBody>
            <SocialPlansList />
          </PortletBody>
        )}

        {tabIndex === 1  && (
          <PortletBody>
            <ArchivePlansList />
          </PortletBody>
        )} */}

        
      </Portlet>
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, npcDuck, commonDuck } = state;

  return {
    loggedInUser: auth.user,
    categoryDropdown: Array.isArray(npcDuck.categoryDropdown)
    ? npcDuck.categoryDropdown
    : [],
    loading: commonDuck.loading
  };
};


export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
  })(reduxForm({
    form: "create-new-plan", // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(OurPlansList))
);
