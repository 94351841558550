import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import DataTable from "./DataTable";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as SubscriptionDuck from "../../../../store/ducks/super-admin/Subscription.duck";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import "./Subscription.scss";
import "./subscriptiontable.scss";
import ascIcon from "../../../../../_metronic/_assets/media/icons/down-arrow.png";
import descIcon from "../../../../../_metronic/_assets/media/icons/up-arrow.png";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import {
  HiOutlineArrowNarrowUp,
  HiOutlineArrowNarrowDown,
} from "react-icons/hi";
import activeSubscription from './ActiveSubscription'
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

class IncompleteSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isRedirect: false,
      allIncompleteSubscriptionsData: [],
      isRedirectCancel: false
    };
  }
  columns = [
    {
      Header: `Email Address`,
      accessor: "email",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.email}</span>;
      },
    },
    {
      Header: "Name",
      accessor: "user_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.user_name}</span>;
      },
    },
    {
      Header: "Company Name",
      accessor: "company_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.company_name}</span>;
      },
    },
    {
      Header: "Account Status",
      accessor: "account_status",
      Cell: (row) => {
        const { original } = row;
        const dataTag =
          original.subscriptionStatusId == 2 ? (
            <span className="accountstatus_tag">Account Created</span>
          ) : (
            ""
          );
        return dataTag;
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      Cell: (row) => {
        const { original } = row;
        const phoneNumber = `${original.phone_number.substr(
          0,
          3
        )}.${original.phone_number.substr(3, 3)}.${original.phone_number.substr(
          6,
          4
        )}`;
        return <span>{phoneNumber}</span>;
      },
    },
    {
      Header: "Date",
      accessor: "account_created",
      Cell: (row) => {
        const { original } = row;
        return (
          <span className="">
            {moment(original.account_created).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getActiveSubscriptions(JSON.stringify(["1", "2"]), (res) => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id");
      if (id) {
        this.setState({ id: id });
        let data = res.find((data) => {
          return data.user_id == id
        });
        if (data) {
          this.setState({ allIncompleteSubscriptionsData: res });
          this.setState({ isRedirect: false });
        } else {
          this.props.subscriptionStatusCheck(id, (response) => {
            if (response.subscriptionStatusId == 3) this.setState({ isRedirect: true });
            if (response.subscriptionStatusId == 4) this.setState({ isRedirectCancel: true });
          });
        }
      } else {
        this.setState({ allIncompleteSubscriptionsData: res });
        this.setState({ isRedirect: false });
      }
    });
  }

  render() {
    const { loading } = this.props;
    return (
      <>
        {this.state.isRedirect ?
          <Switch>
            <Redirect exact={true} to={`/active-subscription?id=${this.state.id}&subscriptionId=3`} />
          </Switch> :
          this.state.isRedirectCancel ?
            <Switch>
              <Redirect exact={true} to={`/cancelled-subscription?id=${this.state.id}`} />
            </Switch> :
            <>
              {loading && <Loader />}
              <LayoutContextConsumer className="row">
                {({ subheader: { title } }) => (
                  <div className="row">
                    <h3 className="kt-subheader__title col-md-6">
                      <span>Incomplete Subscriptions</span>
                    </h3>
                  </div>
                )}
              </LayoutContextConsumer>
              <div className="row">
                <div className="col-12 kt-form subsciption_list_content kt-form--label-right">
                  <Portlet>
                    <PortletBody>
                      <div className="subscription_Table_incomplete">
                        <DataTable
                          data={this.state.allIncompleteSubscriptionsData}
                          columns={this.columns}
                        />
                      </div>
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
            </>
        }
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { allActiveSubscriptions } = store.subscriptionDuck;

  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, SubscriptionDuck.actions)(IncompleteSubscription)
);
