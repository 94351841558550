import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

class Payout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      name: "",
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    const requestData = {
      company_id: user_detail.newspaper.id,
    };
    this.props.getCompanyById(requestData, (res) => {});
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { selectedCompany } = this.props;
    return (
      <div className="row my-5">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>Payout</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginTop: 25,
                  marginLeft: 2,
                }}
                className="row payoutHeader"
              >
                <h4 className="col-md-8">Bank and Cards</h4>
                <Link to="/UpdatePaymentMethode" className="col-md-4">
                  <h6 className="text-md-right">Update Payment Method &#62; </h6>
                </Link>
              </div>
              <div
                className="row ml-3"
                style={{
                  marginTop: 25,
                }}
              >
                <i
                  style={{ fontSize: 40 }}
                  className="fa fa-university mr-4"
                  aria-hidden="true"
                ></i>
                {selectedCompany !== undefined &&
                selectedCompany.bank_name !== "" ? (
                  <div
                    className="text-break"
                    style={{  marginTop: 4 }}
                  >
                    <h6>{selectedCompany.bank_name}</h6>
                    <h6>{selectedCompany.bank_address}</h6>
                  </div>
                ) : (
                  <h6 style={{ margin: 10 }}>No bank record found</h6>
                )}
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, superAdminDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    selectedCompany: superAdminDuck.selectedCompany
      ? superAdminDuck.selectedCompany
      : undefined,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...commonDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "Payout",
    })(Payout)
  )
);
