import React from "react";
import { Container } from "reactstrap";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";

const Heading = () => {
  const hisory = useHistory()
  const PricingHandler = () => {
    hisory.push('/auth/pricing')
  }
  return (
    <>
      <div className='lp_main_heading'>
        <Container>
          <div className='lp_main_content'>
            <Fade bottom duration={1400} delay={600}>
              <h1>Minimize media buying errors</h1>
            </Fade>
            <Fade bottom duration={1500} delay={800}>
              <h2>Maximize client satisfaction</h2>
            </Fade>
            <Fade bottom duration={1600} delay={1000}>
              <p className="desktop-version">
                Tired of juggling multiple publishing deadlines, endless
                pricesheets and back and forth between clients and publishers?
                <b>Pijen gives you the tools to go from mess to yes.</b>
              </p>
              <p className="mobile-version">
                Say goodbye to juggling deadline, price sheets and endless back and forth.Pijen gives you the tools to go from mess to yes.
              </p>
            </Fade>
            <Fade bottom duration={1700} delay={1200}>
              <button onClick={PricingHandler}>Start my free trial</button>
            </Fade>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Heading;
