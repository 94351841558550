import React from 'react';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import StandardTable from '../../../StandardTable';
import * as commonDuck from '../../../../../store/ducks/Common.duck';
import { convertISOToUtcDateformate4 } from '../../../../../common'

class TableView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            financeDetail: undefined,
            openModal: false
        }
    }

    /**
   * @method renderRecords
   * @description render all order list
   */
    renderRecords = () => {
        const columnOrder = [
            {
                Header: 'AGENT',
                accessor: 'agent', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return original.agent
                })
            },
            {
                Header: 'RESERVE DATE',
                accessor: 'reserveDate', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return convertISOToUtcDateformate4(original.reserveDate)
                })
            },
            {
                Header: 'AD TYPE',
                accessor: 'agent', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return original.ad_name
                })
            },
            {
                Header: 'AD COST',
                accessor: 'agent', // String-based value accessors!
                Cell: ((row) => {
                    const { original } = row;
                    return `$${original.adCost}`
                })
            },
            {
                Header: 'AGENT COMISSION',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.commission}`
                },
            },

            {
                Header: 'TOTAL',
                Cell: (row) => {
                    const { original } = row;
                    return `$${original.adCost}`
                },
            },
           
        ];
        return columnOrder
    }

    render() {
        const { orderData } = this.props
        console.warn("Order data", orderData);
        return (
            <div>
                <div className='col-md-12'>
                    <div className='w-100 '>
                        <StandardTable showSearch={false} handleSort={false} columnOrder={this.renderRecords()} allData={orderData}
                            //display={'none'}
                            data={orderData} sortable={false} showPageSizeOptions={false}
                            showPagination={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    const { auth } = store;
    return ({
        loggedInUser: auth.user,
        loading,
    })
}


export default injectIntl(
    connect(
        mapStateToProps,
        commonDuck.actions,
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'TableView',
            enableReinitialize: true,
            destroyOnUnmount: false
        })(TableView)
    )
);