import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Form, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import NavigationPrompt from "react-router-navigation-prompt";
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import "../../Modal.scss";
import { Loader } from "../../../../common/Loader";
import CodeExample from "../../../../partials/content/CodeExample";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as MyProfileDuck from "../../../../store/ducks/super-admin/MyProfile.duck";
import {
  renderText,
  renderNumberInputField,
  renderPasswordInputField,
} from "../../../../common/layout/FormInput";
import {
  required,
  PhoneNumber,
  minLength5,
  maxLength18,
  minLength6,
  maxLength100,
  maxLengthC100,
} from "../../../../config/validation";
import { STATUS_CODES } from "../../../../common/StatusCode";
import {
  phoneNumberFormate,
  phoneNumberFormateOnChange,
} from "../../../../common";
import { ButtonRP } from "../../../../common/Buttons";

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleChanepassword: false,
      isEditFlag: false,
      value: "",
      number: "",
      email: "",
      isSubmitted: false,
      isNavigate: false,
      showModal: false,
      loadingButtonStyle: {
        paddingRight: "2.5rem",
      },
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    const { user_detail } = this.props.loggedInUser;
    if (user_detail) {
      this.props.getSuperAdminProfile(user_detail.id, (res) => {
        if (res && res !== undefined && res.phone_number !== undefined) {
          let contact = phoneNumberFormate(res.phone_number);
          this.setState({ email: res.email, number: contact });
        }
      });
    }
  }

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { user_detail } = this.props.loggedInUser;
    const { isEditFlag, number } = this.state;
    const formdata = {
      id: user_detail.id,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      phone_number: number.replace(/-/g, ""),
      currentPassword: values.currentPassword
        ? values.currentPassword.trim()
        : "",
      newPassword: values.newPassword ? values.newPassword.trim() : "",
    };
    if (isEditFlag) {
      this.setState({ isSubmitted: false });
      this.props.updateSuperAdminProfile(formdata, (res) => {
        if (
          res.status === STATUS_CODES.OK ||
          res.status === STATUS_CODES.CREATED
        ) {
          toastr.success(MESSAGES.PROFILE_UPDATE_SUCCESS);
          this.setState({
            isSubmitted: false,
            isEditFlag: false,
            loading: false,
          });
          // if (values.currentPassword && values.newPassword) {
          //   setTimeout(() => {
          //     window.location.assign("/logout");
          //   }, 4000);
          // }
        } else if (
          res.status === STATUS_CODES.CREATED &&
          res.data.success === false
        ) {
          toastr.error(MESSAGES.CURRENT_PASSWORD_NOT_MATCHED);
        } else {
          this.setState({ isSubmitted: true });
        }
      });
    }
  }

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    this.setState({
      isEditFlag: true,
      isSubmitted: true,
      number: type === "number" ? e.target.value : this.state.number,
    });
  };

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
      isEditFlag: true,
      isSubmitted: true,
    });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit, loading } = this.props;
    const { isEditFlag, isSubmitted, number } = this.state;
    return (
      <div>
        {loading && <Loader />}
        <NavigationPrompt when={isEditFlag}>
          {(props) => {
            const { onConfirm, onCancel } = props;
            return (
              <NavigateModal
                show={true}
                alertHeading={"Changes made will be lost."}
                cancleButtonText="Cancel"
                okButtonText="Leave Anyway"
                onOkClick={() => onConfirm()}
                onClickCancel={() => {
                  onCancel();
                }}
                showCancel={true}
              />
            );
          }}
        </NavigationPrompt>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Admin profile</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle="Contact Info">
              <Form
                noValidate
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Field
                      name="first_name"
                      value={"adminProfile.first_name"}
                      label="First Name"
                      validate={[required, minLength5, maxLengthC100]}
                      required={true}
                      placeholder="Enter Your First Name"
                      onChange={this.onChangeDetailHandeler}
                      component={renderText}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Field
                      name="last_name"
                      label="Last Name"
                      validate={[required, minLength5, maxLengthC100]}
                      required={true}
                      placeholder="Enter Your Last Name"
                      onChange={this.onChangeDetailHandeler}
                      component={renderText}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Field
                      name="phone_number"
                      label="Phone Number"
                      type="text"
                      onChange={(e) => {
                        this.onChange(e);
                      }}
                      style={{ width: "100%" }}
                      maxLength={10}
                      Value={number}
                      validate={[required, PhoneNumber]}
                      required={true}
                      placeholder="Enter Your Phone Number"
                      component={renderNumberInputField}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Field
                      name="email"
                      label="Email"
                      validate={[required, minLength5, maxLength100]}
                      required={true}
                      placeholder="Enter Email Id"
                      onChange={this.onChangeDetailHandeler}
                      component={renderText}
                      disabled={true}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Row style={{ marginBottom: 20 }}>
                    <h4>&nbsp;Password</h4>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Field
                        name="currentPassword"
                        label="Current Password"
                        validate={[maxLength18, minLength6]}
                        required={true}
                        placeholder="Enter Your Current Password"
                        onChange={this.onChangeDetailHandeler}
                        component={renderPasswordInputField}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                      <Field
                        name="newPassword"
                        label="New Password"
                        validate={[maxLength18, minLength6]}
                        required={true}
                        placeholder="Enter New Password"
                        onChange={this.onChangeDetailHandeler}
                        component={renderPasswordInputField}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>

                <ButtonRP
                  text="Save"
                  type="submit"
                  className="float-right"
                  disabled={isSubmitted ? false : true}
                />
              </Form>
            </CodeExample>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Form validations
 * @param values
 * @returns errors
 */
function validate(values) {
  let errors = {};

  if (values.currentPassword && !values.newPassword) {
    errors.newPassword = "New password is required.";
  }
  if (!values.currentPassword && values.newPassword) {
    errors.currentPassword = "Current password is required.";
  }
  if (values.currentPassword && values.currentPassword.trim() === "") {
    errors.currentPassword = "This field is required.";
  }
  if (values.newPassword && values.newPassword.trim() === "") {
    errors.newPassword = "This field is required.";
  }
  return errors;
}
const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { profile } = store.myProfileDuck;

  const adminProfile = profile;
  let initialValues = {};
  if (adminProfile && adminProfile !== undefined) {
    initialValues = {
      first_name:
        adminProfile.first_name[0].toUpperCase() +
        adminProfile.first_name.slice(1).trim(),
      last_name: adminProfile.last_name.trim(),
      phone_number: adminProfile.phone_number,
      email: adminProfile.email.trim(),
      currentPassword: "",
      newPassword: "",
    };
  }
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
    initialValues,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    MyProfileDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "MyProfile",
      validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(MyProfile)
  )
);
