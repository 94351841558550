import React, { useState, useEffect } from "react";
import { Paper, Switch, Grid } from "@material-ui/core";
import { Link, useHistory,useParams } from "react-router-dom";
import ImgsViewer from "react-images-viewer";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
  change as changeFieldValue,
  untouch as untouchField,
  reset,
} from "redux-form";
import { toastr } from "react-redux-toastr";
import {
  required,
  maxLengthC100,
  number,
  greaterThanZeroWithTwoDecimalPlace,
  minLengthC3,
} from "../../../../config/validation";
import {
  renderText,
  renderSelectField,
  renderNumberField,
} from "../../../../common/layout/FormInput";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import { PROFILE_MEDIA_URL } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import { MakeTable, getDays, getTimes } from "../../../../config/utils";
import AddColorForm from "./AddColorForm";
import AddCharacterForm from "./AddCharacterForm";
import AddLineForm from "./AddLineForm";
import AddPremiumForm from "./AddPremiumForm";
import AddPaymentDiscountOptionForm from "./AddPaymentDiscountOptionForm";
import { langs } from "../../../../config/localization";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";
import RadioButtonRP from "../../../../common/RadioButtonRP";

const CreateNewPlan = ({
  handleSubmit,
  submitting,
  formValues,
  /** store states */
  loggedInUser,
  /**Actions */
  getfromNPCSetting,
  getAllAdType,
  getAllPaperType,
  getAllAccount,
  addNewPlan,
  changeFieldValue,
  untouchField,
  reset,
  getPlanById
}) => {
  const history = useHistory();
  // console.log('*************', NPCDuck.adTypeList);
  // extract logged in user details
  const { user_detail } = loggedInUser;

  const { planId } = useParams();
  const [planIdTest,setPlanIdTest] = useState('');

  
  const [showResults, setShowResults] = useState(false);
  const [addOnType,setAddOnType] = useState('');
  const [addOnPrice,setAddOnPrice] = useState('');
  // Ad Type states

  const [paperTypes, setPaperTypes] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [displayAdPremium, setDisplayAdPremium] = useState([]);
  //const [paperTypes, setPaperTypes] = useState([{value:null,label:'select'},{value:1,label:'Glossy'},{value:2,label:'Matte'}]);
  
  const [paperTypeData, setPaperTypeData] = useState("");
  const [paperTypeCost, setPaperTypeCost] = useState("");
  const [papertypeActualdata, setPapertypeActualdata] = useState([]);
  const [check, setcheck] = useState(false);
  const [count,setCount] = useState(0);
  // all the advertisements type list of the logged in NPC user
  const [advertisementsTypeList, setAdvertisementsTypeList] = useState([]);
  
  // selected Ad Type state
  const [selectedAdType, setSelectedAdType] = useState("");
  // selected Ad Type type state i.e. Display, classified etc.
  const [typeOfSelectedAdType, setTypeOfSelectedAdType] = useState("");
  
  // selected Ad Type image state
  const [selectedAdTypeImage, setSelectedAdTypeImage] = useState("");
  // const [ deadlinePrice, setDeadlinePrice ] = useState("");

  const [ characterLimit, setDeadlineCharacter ] = useState(0);
  const [ lineLimit, setDeadlineLine ] = useState(0);
  const [ deadlinePrice, setDeadlinePrice] = useState(0.00);
  

  // Edition list state
  const [editions, setEditions] = useState([]);
  const [editionsName, setEditionsName] = useState("");
  
 
  // image preview state
  const [openImage, setOpenImage] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  // States when type of Ad Type is Display
  // All the colors list which are added into table
  const [displayAdColors, setDisplayAdColors] = useState([]);
  const [displayAdCharacter, setDisplayAdCharacter] = useState([]);
  const [displayAdLine, setDisplayAdLine] = useState([]);

  const [allAddtype,setAllAddType] = useState([]);
  const [alledition,setAlledition] = useState([]);

  const [accountTypeName, setAccountTypeName] = useState(0);
  /** payment discount option states */
  // checkbox state to show hide display option
  const [showPaymentDiscountOptions, setShowPaymentDiscountOptions] = useState(
    false
  );

  // payment discount options states
  const [paymentDiscountOptions, setPaymentDiscountOptions] = useState([]);
  const [paymentDiscountOptionError, setPaymentDiscountOptionError] = useState(
    null
  );

  const [AddLinerError, setAddLinerError] = useState(
    null
  );
  const [AddCharacterError, setAddCharacterError] = useState(
    null
  );

  /**
   * @method getAdType
   * @description return type of selected Ad Type
   */


  const onClickNew = () =>{
    if(showResults){
      setShowResults(false)
       
      //alert(true);
    }else{
      setShowResults(true)
      //alert(false);
    }
  };

 

  const getAdType = () => {
    // advertisementsTypeList
    const add = allAddtype.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    // if(add === undefined) return "CLASSIFIED"; 
    if (!add) return "";
    switch (add.ad_type) {
      case "Classified":
        return "CLASSIFIED";
      case "Display":
        return "DISPLAY";
      default:
        return "";
    }
  };



  /**
   * @method getAdTypeOptions
   * @description return dropdown options for Ad Type
   */
  const getAdTypeOptions = () => {
   // //console.warn('>>>>>>>>>>>>>>>>>', allAddtype);
    return Array.isArray(allAddtype) &&
    allAddtype.length > 0
      ? allAddtype.map((ad) => ({
        label: ad.ad_name,
        value: ad.id,
      }))
      : [];
  };

  /**
   * @method getEditionOptions
   * @description returns drop down options for edition
   */
  const getEditionOptions = () => {
    return Array.isArray(editions) && editions.length > 0
      ? editions.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };


  let getEditionName = (id)=>{

    let add = editions.find(
      (ad) => parseInt(ad.id) === parseInt(id)
    );
    if (add.name!=""){
      setEditionsName(add.name);
    }

    /*if (!add){
      return "";
    }else{
      setEditionsName(add.name);
    } */

  };

  

  

  /**
   * @method handleAddDiscountOptions
   * @description handle add a discount option
   */
  const handleAddDiscountOptions = (v) => {
    setPaymentDiscountOptionError(null);
    // set discount option to state
    setPaymentDiscountOptions([...paymentDiscountOptions, v]);
  };

  /**
   * @method handleDeleteDiscountOptions
   * @description handle delete discount option from table
   * @param {*} option
   */
  const handleDeleteDiscountOptions = (option) => {
    const updatedPaymentDiscountOptions = paymentDiscountOptions.filter(
      (p) =>
        p.runs !== option.runs &&
        p.within !== option.within &&
        p.typeOfDiscount !== option.typeOfDiscount &&
        p.discountAmount !== option.discountAmount
    );
    setPaymentDiscountOptions(updatedPaymentDiscountOptions);
  };

  /**
   * @method handleAddDisplayAdColor
   * @description handle add color for display Ad
   */
  const handleAddDisplayAdColor = (color) => {
    // set color option to state
    setDisplayAdColors([...displayAdColors, color]);
  };
  let getCharacterLimit=(value)=>{
    setDeadlineCharacter(value);
  }
  let getlineLimit=(value)=>{
    setDeadlineLine(value);
  }
  let getdeadlineprice=(value)=>{
    setDeadlinePrice(value);
  }
  const handleAddDisplayAdCharacter = (character) => {
    // set character option to state
    setDisplayAdCharacter([...displayAdCharacter, character]);
    setAddCharacterError(null);
  };
  const handleAddDisplayAdLine = (line) => {
    // set character option to state
    setDisplayAdLine([...displayAdLine, line]);
    setAddLinerError(null);
  };

  const handleAddDisplayAdPremium = (premium) => {
    // set color option to state
    setDisplayAdPremium([...displayAdPremium, premium]);
  };


 
  /**
   * @method handleDeleteDisplayAdColor
   * @description handle delete display Ad color
   * @param {*} disAdCol
   */
  const handleDeleteDisplayAdColor = (disAdCol) => {
    const updatedColors = displayAdColors.filter(
      (c) => !(c.color === disAdCol.color && c.cost === disAdCol.cost && c.papertype===disAdCol.papertype)
    );
    setDisplayAdColors(updatedColors);
  };
  const handleDeleteDisplayAdCharacter = (disAdChar) => {
    const updatedChar = displayAdCharacter.filter(
      (c) => !(c.character === disAdChar.character && c.cost === disAdChar.cost)
    );
    setDisplayAdCharacter(updatedChar);
  };

  const handleDeleteDisplayAdLine = (disAdLine) => {
    const updatedLine = displayAdLine.filter(
      (c) => !(c.line === disAdLine.line && c.cost === disAdLine.cost)
    );
    setDisplayAdLine(updatedLine);
  };

  const handleDeleteDisplayAdPremium = (disAdCol) => {
    const updatedPremium = displayAdPremium.filter(
      (c) => !(c.premium === disAdCol.premium && c.premium_cost === disAdCol.premium_cost )
    );
    setDisplayAdPremium(updatedPremium);
  };

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values) => {
    console.log(values)
    const selectedEdition = editions && editions.length === 1 ? editions[0].id : values.edition_id;
    //const selectedEditionname=editions && editions.length === 1 ? editions[0].name : "";
    let classified_conditional_errors = {};
    let display_height_width_errors = {};
    /**
     * checking conditional mandatory fields
     */
    if (values.isColoredText && !values.color_text_cost) {
      classified_conditional_errors.color_text_cost =
        langs.validation_messages.required;
    }
    if (values.isBold && !values.bold_cost) {
      classified_conditional_errors.bold_cost =
        langs.validation_messages.required;
    }
    if (values.isAddImage && !values.image_cost) {
      classified_conditional_errors.image_cost =
        langs.validation_messages.required;
    }
    if (values.isAddBorder && !values.border_cost) {
      classified_conditional_errors.border_cost =
        langs.validation_messages.required;
    }
    if (values.isAddBackgroundColor && !values.background_cost) {
      classified_conditional_errors.background_cost =
        langs.validation_messages.required;
    }

    if (Object.keys(classified_conditional_errors).length !== 0) {
      if (typeOfSelectedAdType === "CLASSIFIED") {
        throw new SubmissionError(classified_conditional_errors);
      }
    }

    // if (values.width <= 0) {
    //   display_height_width_errors.width = langs.validation_messages.invalid;
    // }
    // if (values.height <= 0) {
    //   display_height_width_errors.height = langs.validation_messages.invalid;
    // }

    if (Object.keys(display_height_width_errors).length !== 0) {
      if (typeOfSelectedAdType === "DISPLAY") {
        throw new SubmissionError(display_height_width_errors);
      }
    }

    /**
     * check and set error if payment discount option is YES but
     * no payment discount option is added
     */
    if (showPaymentDiscountOptions && paymentDiscountOptions.length === 0) {
      setPaymentDiscountOptionError("Please add payment discount options");
      return;
    }
    if (values.isAddCharacter==true && typeOfSelectedAdType === "CLASSIFIED"&& displayAdCharacter.length === 0) {
      setAddCharacterError("Please add character limit");
      return;
    }
    if (values.isAddLine==true && typeOfSelectedAdType === "CLASSIFIED"&& displayAdLine.length === 0) {
      setAddLinerError("Please add line limit");
      return;
    }
   
    //console.log('>>>>>>>><<<<<<<<<<<', papertypeActualdata);
 
    // formated date to send
    let data = {  
      
      
      npc_id: user_detail.newspaper.id,
      
      // plan_name: '',
      //edition_id: values.edition_id ? values.edition_id : "0",
      wordcount_option: JSON.stringify([]),
      premium_option: JSON.stringify([]),
      edition_id: selectedEdition ? selectedEdition : '0',
      edition_name: editionsName,
      bleed_needed:values.bleed_needed ? values.bleed_needed: '0',
      isAddLine:false,
      isAddCharacter:false,
      ad_type: values.ad_type,
      approval: values.approval,
      bleed_width: values.bleed_width,
      bleed_height: values.bleed_height,
      deadline_day: values.deadline_day,
      deadline_time: values.deadline_time,
      deadline_price: String(values.deadline_price).trim() !== "" ? values.deadline_price: 0,
      discount: showPaymentDiscountOptions,
      discount_option: showPaymentDiscountOptions
        ? JSON.stringify(paymentDiscountOptions)
        : JSON.stringify([]),
      active: !values.active,
      is_deleted: false,
      /** optional */
      width: 0,
      height: 0,
      color_option: JSON.stringify([]),
      color_text_cost: 0,
      bold_cost: 0,
      image_cost: 0,
      border_cost: 0,
      background_cost: 0,
      premium_option:JSON.stringify([]),
      paper_option:JSON.stringify([]),
      character_option: JSON.stringify([]),
      line_option:JSON.stringify([]),
      character_limit: 0,
      lines_limit:0,
      account_type: values.account_type == "" ? 0 : values.account_type,
      account_type_price: values.account_type == "" ? 0.00 :
      values.account_type_price ? values.account_type_price : 0.00
    };

   //console.warn("data", data );

    // data when type of Ad Type is display
    if (typeOfSelectedAdType === "DISPLAY") {
      data = {
        ...data,
        width: String(values.width).trim() !== "" ? values.width : 0,
        height: String(values.height).trim() !== "" ? values.height : 0,
        premium_option: JSON.stringify(displayAdPremium),
        color_option: JSON.stringify(displayAdColors),
        paper_option: JSON.stringify(papertypeActualdata)
      };
    }

    // data when type of Ad Type is classified
    if (typeOfSelectedAdType === "CLASSIFIED") {
      data = {
        ...data,
        color_text_cost:
          formValues.isColoredText &&
            String(values.color_text_cost).trim() !== ""
            ? parseFloat(values.color_text_cost)
            : 0,
          bold_cost:
          formValues.isBold && String(values.bold_cost).trim() !== ""
            ? parseFloat(values.bold_cost)
            : 0,    
        image_cost:
          formValues.isAddImage && String(values.image_cost).trim() !== ""
            ? parseFloat(values.image_cost)
            : 0,
        border_cost:
          formValues.isAddBorder && String(values.border_cost).trim() !== ""
            ? parseFloat(values.border_cost)
            : 0,
        background_cost:
          formValues.isAddBackgroundColor &&
            String(values.background_cost).trim() !== ""
            ? parseFloat(values.background_cost)
            : 0,
        character_option: JSON.stringify(displayAdCharacter),
        line_option: JSON.stringify(displayAdLine),
        premium_option: JSON.stringify(displayAdPremium),
        character_limit: String(values.character_limit).trim() !== "" ? values.character_limit : 0,
        lines_limit: String(values.lines_limit).trim() !== "" ? values.lines_limit : 0,
        isAddLine:values.isAddLine? values.isAddLine: false,
        isAddCharacter:values.isAddCharacter? values.isAddCharacter: false,
      };
    }

    //console.warn(data)
    let color_price = 0.00;
    let total_price = 0.00;
    if(displayAdColors){
      for(let i = 0; i < displayAdColors.length; i ++){
        color_price = color_price + parseFloat(displayAdColors[i].cost)
      }
    }

    total_price = color_price + parseFloat(data.deadline_price) + parseFloat(data.account_type_price);
    console.warn("Total price", total_price);

    for(let i = 0; i < paymentDiscountOptions.length; i ++){
      if(paymentDiscountOptions[i].typeOfDiscount == "Fixed Amount"){
        if(total_price < parseFloat(paymentDiscountOptions[i].discountAmount)){
          const errors = {};
          errors.discountAmount = 'Discount can not be more than the total of ad price and color price';
          toastr.error("Error", 'Discount can not be more than the total of ad price and color price');
          throw new SubmissionError(errors);
        }
      }
    }
    // add new plan action
    addNewPlan(data, (res) => {
      if (
        res.status === STATUS_CODES.CREATED ||
        res.status === STATUS_CODES.OK
      ) {
        toastr.success("Success", MESSAGES.PLAN_CREATE_SUCCESS);
        history.replace("/ourPlans");
      }
    });
  };


  const fetchPlan = () => {
    // request data
    if(planId!=""){
      localStorage.setItem("setPlanIdTest",planId);
    }
    
    const requestData = {
      id: planId,
      npc_id: user_detail.newspaper.id,
    };

    /** get single plan using same api to get all the plans by sending single plan id */
       getPlanById(requestData, (res) => {
        
        if (res && String(res.status) === String(200)) {
         
          const plan = res.data.rows[0];
          //setSelectedAdType( JSON.parse(plan));
          // //console.warn("checking",res.data);
          // //console.warn("testing",JSON.parse(plan.deadline_price));
         
          let deadline_price = JSON.parse(plan.deadline_price);
          setDeadlinePrice(deadline_price);
          // //console.warn(deadlinePrice);

          const color_options =
            plan.color_option.trim() !== ""
              ? JSON.parse(JSON.parse(plan.color_option))
              : [];
              const premium_option =   
        plan.premium_option.trim() !== ""
                ? JSON.parse(JSON.parse(plan.premium_option))
                : [];
          const character_options =
              plan.character_option.trim() !== ""
                ? JSON.parse(JSON.parse(plan.character_option))
                : [];
          const line_options =
                plan.line_option.trim() !== ""
                  ? JSON.parse(JSON.parse(plan.line_option))
                  : [];
          const discount_options =
            plan.discount_option.trim() !== "" && plan.discount
              ? JSON.parse(JSON.parse(plan.discount_option))
              : [];
          // set display Ad color options state
           setDisplayAdColors(color_options);
           setDisplayAdCharacter(character_options);
           setDisplayAdLine(line_options);
           setDisplayAdPremium(premium_option);
          // set payment discount options state
          setPaymentDiscountOptions(discount_options);

          setShowPaymentDiscountOptions(JSON.parse(plan.discount))
          setShowResults(JSON.parse(plan.bleed_needed));
          setDeadlineCharacter(plan.character_limit);
          setDeadlineLine(plan.lines_limit);
        } else if (res) {
          setIsRedirect(true);
        }
      });
  };

  


  /**
   * runs on mount to fetch necessary data
   */
  useEffect(() => {
    console.log("1");
    getfromNPCSetting("edition", user_detail.newspaper.id, (res) => {
      if (res.status === 200) {
        setEditions(res.data);
      }
    });

    // getfromNPCSetting("advertisement", user_detail.newspaper.id, (res) => { });

    getAllAdType('', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
       // //console.warn('hola hola*************', allAddType);
        setAllAddType(allAddType);
        // //console.warn('hello hello',allAddType)
        // let advertisements = allAddType;
        // advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        // advertisements =
        //   Array.isArray(advertisements) &&
        //   advertisements.map((ad) => {
        //     return {
        //       id: ad.id,
        //       ad_name: ad.ad_name,
        //       ad_type: ad.ad_type,
        //       selectedImage: JSON.parse(ad.npc_advertisement.ad_urls)[0],
        //     };
        //   });
        // //console.warn('adv===',advertisements);

        // // set advertisements list state
        // setAdvertisementsTypeList(advertisements);
        // // extract Ad Type of type display if exists in list
        // const displayAd = advertisements.find((ad) => ad.ad_type === "Display");

        // // if not exists set first one
        // const selectedAd = displayAd
        //   ? displayAd.id
        //   : advertisements[0]
        //     ? advertisements[0].id
        //     : "";

        // // extract and set selected Ad Type initially of display type
        // setSelectedAdType(selectedAd);


      }
    });

    getAllPaperType('0', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.paper_type_name,
            };
          });
          setPaperTypes(advertisements);

      }
    });

    getAllAccount('0', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.account_name,
            };
          });
          setAccountType(advertisements);

      }
    });

    

    



    fetchPlan();
  }, []);

  /**
   * run when either of any dependency value changes
   */

  useEffect(() => {
    console.log("2");
    /**
     *  on change of selected Ad Type or change in list of Ad Type
     *  find type of selected Ad Type ad set its state and respected image
     */
    const type = getAdType();
    setTypeOfSelectedAdType(type);
    // advertisementsTypeList
    let selectedAd = allAddtype.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    if (selectedAd) {
      setSelectedAdTypeImage(selectedAd.selectedImage);
    }
  }, [selectedAdType, allAddtype]);

  useEffect(() => {
    console.log("3")
    // alert('hello');
    // alert(formValues.ad_type);
    // console.warn ('*****data>>>>>>>>>>>>>>', formValues);
    setSelectedAdType(formValues.ad_type);
  }, [formValues.ad_type]);

  /**
   * runs on change in dependency
   */
  useEffect(() => {
    console.log("4")
    if (!showPaymentDiscountOptions) {
      // setting payment option error field to null if YES is not selected
      setPaymentDiscountOptionError(null);
    }
  }, [showPaymentDiscountOptions]);

  useEffect(() => {
    console.log("5")
    if (displayAdLine.length==0) {
      // setting Add line option error field to null if YES is not selected
      setAddLinerError(null);
    }
  }, [displayAdLine]);

  useEffect(() => {
    console.log("6")
    if (displayAdCharacter.length==0) {
      // setting Add character option error field to null if YES is not selected
      setAddCharacterError(null);
    }
  }, [displayAdCharacter]);

  
  /**
   * runs on change in dependency
   */
  useEffect(() => {
    console.log("7")
    /**
     * resting cost fields to blank when respected checkbox unchecked
     */
    if (!formValues.isColoredText) {
      changeFieldValue("create-new-plan", "color_text_cost", "");
      untouchField("create-new-plan", "color_text_cost");
    }
    if (!formValues.isBold) {
      changeFieldValue("create-new-plan", "bold_cost", "");
      untouchField("create-new-plan", "bold_cost");
    }
    if (!formValues.isAddImage) {
      changeFieldValue("create-new-plan", "image_cost", "");
      untouchField("create-new-plan", "image_cost");
    }
    if (!formValues.isAddBorder) {
      changeFieldValue("create-new-plan", "border_cost", "");
      untouchField("create-new-plan", "border_cost");
    }
    if (!formValues.isAddBackgroundColor) {
      changeFieldValue("create-new-plan", "background_cost", "");
      untouchField("create-new-plan", "background_cost");
    }
    
  }, [
    formValues.isColoredText,
    formValues.isBold,
    formValues.isAddImage,
    formValues.isAddBorder,
    formValues.isAddBackgroundColor,
  ]);

 
  const addpaperTypes = () => {
    if(paperTypeData !== "" && paperTypeCost !== "")
    {
      setcheck(true);
      const index = paperTypes.findIndex(obj => obj.value == parseInt(paperTypeData));
      // //console.warn(paperTypes[index]);
      if(index !== -1)
      {
      setCount(count + 1);
      let data = {id:count,label:paperTypes[index].label,cost:paperTypeCost};
      setPapertypeActualdata([...papertypeActualdata, data]);
      changeFieldValue("create-new-plan", "paper_type", "");
      changeFieldValue("create-new-plan", "paper_cost", "");
      setPaperTypeData("");
      setPaperTypeCost("");
      }
      else{
        // alert('index not found');
      }
    }
    // alert('add click');
    // //console.warn('^^^^^^^^', papertypeActualdata);
  }

  const deletePaperType = (id) => {
   
    const index = papertypeActualdata.findIndex(obj=>obj.id == id);
    // //console.warn(index);
    // //console.warn(papertypeActualdata);
    if(index !== -1)
      {
        setPapertypeActualdata(papertypeActualdata.filter(item => item.id !== id));
      }
    // //console.warn('lennnn',papertypeActualdata.length);
    if(papertypeActualdata.length === 1)
    {
      setcheck(false);
    }
  }

  return (
    <>
      <h4>
        <Link to="/ourPlans">
          <i className="fa fa-arrow-left mr-3" aria-hidden="true"></i>
        </Link>
        Create New Printing Plan
      </h4>
      <Paper>
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="form-row">
                  {/* <div className="col-md-6">
                    <Field
                      name="plan_name"
                      component={renderText}
                      type="text"
                      validate={[required, minLengthC3, maxLengthC100]}
                      required={true}
                      label="Plan Name"
                    />
                  </div> */}
                  {editions && editions.length > 1 ? (
                    <div className="col-md-6">
                      <Field
                        name="edition_id"
                        component={renderSelectField}
                        label="Edition"
                        options={getEditionOptions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={editions.length > 1 ? false : true}
                        validate={required}
                        required={true}
                        onChange={(e)=>getEditionName(e.target.value)}
                      />
                    </div>
                  ) : <div className="col-md-6">
                      <Field
                        name="edition_id"
                        component={renderText}
                        type="text"
                        validate={[required]}
                        required={true}
                        label="Edition"
                      />
                    </div>}
                </div>

                <div
                  className={`form-row ${
                    typeOfSelectedAdType === "CLASSIFIED"
                      ? "align-items-center"
                      : ""
                    }`}
                >
                  <div className="col-md-6">
                    <Field
                      name="ad_type"
                      component={renderSelectField}
                      label="Ad Type"
                      options={getAdTypeOptions()}
                      optionValue={"value"}
                      optionLabel={"label"}
                      validate={required}
                      required={true}
                      value={selectedAdType}
                      onChange={(e) => setSelectedAdType(e.target.value)}
                      
                      isSelect={false}
                    />
                  </div>
                  {typeOfSelectedAdType === "CLASSIFIED" && (
                  <>
                  <div className="form-row col-md-6">
                  <div className="col-md-6 mt-2">
                      <Field
                            name="character_limit"
                            label="Character Limit"
                            component={renderNumberField}
                            value={characterLimit}
                            min="0"
                            validate={[
                              number
                              
                            ]}
                            helpText={
                              "Only Numeric value"
                            }
                            onChange={(e)=>getCharacterLimit(e.target.value)}
                          />
                    </div>
                    
                    <div className="col-md-6 mt-2">
                      <Field
                            name="lines_limit"
                            label="Lines Limit"
                            component={renderNumberField}
                            value={lineLimit}
                            min="0"
                            validate={[
                              number
                             
                            ]}
                            helpText={
                              "Only Numeric value"
                            }
                            onChange={(e)=>getlineLimit(e.target.value)}
                          />
                    </div>
                    </div>
                  </>
                  )}
                  {typeOfSelectedAdType === "DISPLAY" && (
                    <div className="form-row col-md-6">
                      <div className="col-md-6">
                        <Field
                          name="width"
                          label="Ad Size (Inches)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          required={true}
                          placeholder="Width"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <Field
                          name="height"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          placeholder="Height"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-check col-md-6 text-center d-flex justify-content-center align-items-center">
                    <CheckboxRP label="Requires Approval?">
                      <Field
                        name="approval"
                        component="input"
                        type="checkbox"
                        id="requiresApproval"
                        className="mr-1"
                      />
                    </CheckboxRP>

                    {/* <label
                      className="checkbox-inline d-flex justify-content-center align-items-center"
                      htmlFor="requiresApproval"
                    >
                      <Field
                        name="approval"
                        component="input"
                        type="checkbox"
                        id="requiresApproval"
                        className="mr-1"
                      />
                      Requires Approval?
                    </label> */}
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-3">
                    <Field
                      name="deadline_day"
                      component={renderSelectField}
                      label="Ad Deadline"
                      required={true}
                      options={getDays()}
                      optionValue={"value"}
                      optionLabel={"label"}
                      validate={required}
                      isSelect={false}
                    />
                  </div>
                  <div className="col-md-3 mt-2">
                    <Field
                      name="deadline_time"
                      component={renderSelectField}
                      options={getTimes()}
                      optionValue={"value"}
                      optionLabel={"label"}
                      validate={[required]}
                      isSelect={false}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="deadline_price"
                      label="Price"
                      value={deadlinePrice}
                      component={renderNumberField}
                      validate={[
                        
                        number
                       
                      ]}
                      required={true}
                      min="0"
                      step=".01"
                      helpText={
                        "Only Numeric value with up to 2 decimal places"
                      }
                      onChange={(e)=>getdeadlineprice(e.target.value)}
                    />
                  </div>
                </div>

                {typeOfSelectedAdType === "DISPLAY" && (
                  <>
                    <AddColorForm
                      onAddColor={(c) => handleAddDisplayAdColor(c)}
                    />

                    {displayAdColors.length > 0 && (
                      <MakeTable column={["#", "Color", "Paper Type","Cost", "Action"]}>
                        {Array.isArray(displayAdColors) &&
                          displayAdColors.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{disAdCol.color}</td>
                              <td>{disAdCol.papertype}</td>
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdColor(disAdCol)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )}

<>
                    <AddPremiumForm
                      onAddPremium={(c) => handleAddDisplayAdPremium(c)}
                    />

                    {displayAdPremium.length > 0 && (
                      <MakeTable column={["#", "Premium","Cost", "Action"]}>
                        {Array.isArray(displayAdPremium) &&
                          displayAdPremium.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{disAdCol.premium}</td>
                              <td>{disAdCol.premium_cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdPremium(disAdCol)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>



                {/* Added Option For "Bleed needed?"*/}

              
               

                   
                

                {/* new----- */}
                {/* {typeOfSelectedAdType === "DISPLAY"  && (
                  <div className="form-row align-items-center">
                 <div className="col-md-3" style={{ height: 105 }}>
                 <div className="form-group">
                    <Field
                        name="paper_type"
                        component={renderSelectField}
                        label="Paper Type"
                        options={paperTypes}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={false}
                        Value={paperTypeData}
                        onChange={(e)=>{setPaperTypeData(e.target.value)}}
                        />
                       </div>
                        </div>
                        <div className="col-md-3 mt-3" style={{ height: 105 }}>
                       <div className="form-group">
                    <Field
                        name="paper_cost"
                        component={renderNumberField}
                        validate={[number, greaterThanZeroWithTwoDecimalPlace]}
                        placeholder="Cost"
                        min="0"
                        step=".01"
                        helpText={'Only Numeric value with up to 2 decimal places'}
                        Value={paperTypeCost}
                        onChange={(e)=>{setPaperTypeCost(e.target.value)}}
                    />
                    </div>
                     </div>
                     <div className="col-md-3 mt-4" style={{ height: 105 }}>
                   <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={()=>{addpaperTypes()}}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>

                )}
        {typeOfSelectedAdType === "DISPLAY" && check && (
                      <MakeTable column={["#", "Paper Type", "Cost","Action"]}>
                        {Array.isArray(papertypeActualdata) &&
                          papertypeActualdata.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{disAdCol.label}</td>
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    deletePaperType(disAdCol.id)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )} */}

                {typeOfSelectedAdType === "CLASSIFIED" && (
                  <>
                   <div className="form-row">
                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isColoredText"
                            component="input"
                            type="checkbox"
                            id="coloredText"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Colored Text?
                          </label>
                        </div>
                        {formValues.isColoredText && (
                          <Field
                            name="color_text_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isBold"
                            component="input"
                            type="checkbox"
                            id="bold"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="bold">
                            Bold?
                          </label>
                        </div>
                        {formValues.isBold && (
                          <Field
                            name="bold_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddImage"
                            component="input"
                            type="checkbox"
                            id="addImage"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addImage"
                          >
                            Add Image?
                          </label>
                        </div>
                        {formValues.isAddImage && (
                          <Field
                            name="image_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddBorder"
                            component="input"
                            type="checkbox"
                            id="addBorder"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addBorder"
                          >
                            Add Border?
                          </label>
                        </div>
                        {formValues.isAddBorder && (
                          <Field
                            name="border_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>
                    </div>
                   
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddBackgroundColor"
                            component="input"
                            type="checkbox"
                            id="addBackgroundColor"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addBackgroundColor"
                          >
                            Add Background Color?
                          </label>
                        </div>
                        {formValues.isAddBackgroundColor && (
                          <Field
                            name="background_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>
                    </div>
                 
                    <div className="form-row">
                    <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddCharacter"
                            component="input"
                            type="checkbox"
                            id="AddCharacter"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="AddCharacter"
                          >
                            Add Character?
                          </label>
                        </div>
                      </div>
                    </div>
                     {formValues.isAddCharacter && (
                       <>
                        <AddCharacterForm
                         onAddCharacter={(c) => handleAddDisplayAdCharacter(c)}
                         />
                           {AddCharacterError && (
                       <div className="error text-help mb-2">
                        {AddCharacterError}
                       </div>
                    )}
                          {displayAdCharacter.length > 0 && (
                         <MakeTable column={["Character limit","Cost","Total Character", "Total Cost","Action"]}>
                         {displayAdCharacter.length &&
                          displayAdCharacter.map((disAdChar, i) => (
                            <tr key={i}>
                              <td>{disAdChar.character}</td>
                              <td>{disAdChar.cost}</td>
                              <td> {Number(characterLimit) + Number(disAdChar.character)} </td>
                              <td> {Number(deadlinePrice) + Number(disAdChar.cost)} </td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdCharacter(disAdChar)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                    </>
                   )}

                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddLine"
                            component="input"
                            type="checkbox"
                            id="Addline"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="AddLine"
                          >
                            Add Line?
                          </label>
                        </div>
                      </div>
                    </div>
                    {formValues.isAddLine && (
                      <>
                         <AddLineForm
                          onAddLine={(c) => handleAddDisplayAdLine(c)}
                        />
                        {AddLinerError && (
                       <div className="error text-help mb-2">
                        {AddLinerError}
                       </div>
                    )}
                       {displayAdLine.length > 0 && (
                       <MakeTable column={["Line limit","Cost","Total Line","Total Cost", "Action"]}>
                        {displayAdLine.length &&
                          displayAdLine.map((disAdline, i) => (
                            <tr key={i}>
                              <td>{disAdline.line}</td>
                              <td>{disAdline.cost}</td>
                              <td> {Number(lineLimit) + Number(disAdline.line)} </td>
                              <td> {Number(deadlinePrice) + Number(disAdline.cost)} </td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdLine(disAdline)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                      </>
                    )}
                   
                    
                  </>
                )}

{ /* -----------------------------Bleed Section --------------------------------------------*/}

  <div class="custom-code">
                <div className="form-row align-items-center mt-4">
                  <div className="form-group col-md-3">
                    <label htmlFor="color">Bleed needed?</label>
                  </div>
                  <div className="form-group col-md-3">
                    <div className="radio-inline">
                    <CheckboxRP label="Yes">
                      <Field
                        name="bleed_needed"
                       onChange={()=>{onClickNew()}}
                        component="input"
                        type="checkbox"
                        id="requiresApproval"
                        className="mr-1"
                       
                      />
                    </CheckboxRP>
                     
                    </div>
                  </div>
                </div>

                {showResults && (

                
                <div className="form-row align-items-center">
                 <div className="col-md-3" style={{ height: 105 }}>
                    <div className="form-group">
                     
                      <Field
                          name="bleed_width"
                          label="Bleed width (Inches)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          required={true}
                          placeholder="Bleed Width"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                        />
                          </div>
                        </div>
                        <div className="col-md-3" style={{ height: 105 }}>
                       <div className="form-group">
                       <Field
                          name="bleed_height"
                          label="Bleed height (Inches)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          required={true}
                          placeholder="Bleed Height"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                        />
                    </div>
                     </div>
                     <div className="col-md-3 mt-4" style={{ height: 105 }}>
                   <div className="form-group mt-4">
                   
                </div>
            </div>
          </div>
                )}
          </div>

{ /* -----------------------------Discount Section --------------------------------------------*/}
                
                <div className="form-row align-items-center mt-4">
                  <div className="form-group col-md-3">
                    <label htmlFor="color">Payment Discount Option</label>
                  </div>
                  <div className="form-group col-md-3">
                    <div className="radio-inline">
                      <RadioButtonRP label="Yes">
                        <input
                          checked={showPaymentDiscountOptions === true}
                          onChange={() => setShowPaymentDiscountOptions(true)}
                          className="form-check-input"
                          type="radio"
                          name="paymentDiscountOption"
                          id="paymentDiscountOptionYes"
                          value="yes"
                        />
                      </RadioButtonRP>
                      <RadioButtonRP label="No">
                        <input
                          checked={showPaymentDiscountOptions === false}
                          onChange={() => setShowPaymentDiscountOptions(false)}
                          className="form-check-input"
                          type="radio"
                          name="paymentDiscountOption"
                          id="paymentDiscountOptionNo"
                          value="no"
                        />
                      </RadioButtonRP>
                    </div>
                  </div>
                </div>

                {showPaymentDiscountOptions && (
                  <>
                    <AddPaymentDiscountOptionForm
                      displayAdColors={displayAdColors}
                      deadlinePrice={deadlinePrice}
                      onAddPayDiscountOption={(v) =>
                        handleAddDiscountOptions(v)
                      }
                      paymentDiscountOptions={paymentDiscountOptions}
                    />
                    {paymentDiscountOptionError && (
                      <div className="error text-help mb-2">
                        {paymentDiscountOptionError}
                      </div>
                    )}

                    {paymentDiscountOptions.length > 0 && (
                      <MakeTable
                        column={[
                          "#",
                          "Repetition",
                          "Within",
                          "Type of Discount",
                          "Amount",
                          "Action",
                        ]}
                      >
                        {paymentDiscountOptions.length &&
                          paymentDiscountOptions.map((payDisAmt, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{payDisAmt.runs}</td>
                              <td>{payDisAmt.within}</td>
                              <td>{payDisAmt.typeOfDiscount}</td>
                              <td>
                                {payDisAmt.discountAmount}
                                {payDisAmt.typeOfDiscount === "Percentage"
                                  ? "%"
                                  : ""}
                              </td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDiscountOptions(payDisAmt)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )}

                <div className="form-group">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item className="mr-4">
                      Status
                    </Grid>
                    <Grid item className="text-success">
                      Active
                    </Grid>
                    <Grid item>
                      <Field
                        name="active"
                        component={({ input, label }) => (
                          <Switch
                            checked={input.value ? true : false}
                            onChange={input.onChange}
                            color="secondary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className="text-danger">
                      Inactive
                    </Grid>
                  </Grid>
                </div>
                
                <ButtonRP
                  type="submit"
                  disabled={submitting}
                  className="float-right"
                  rounded={false}
                  text="Create"
                />
              </form>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

/** redux form value selector */
const formValSelector = formValueSelector("create-new-plan");

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 */
const mapStateToProps = (state) => {
  const { auth, npcDuck } = state;
  const { npcSettingList, npcPlan } = npcDuck;

  const { color, edition, advertisement } = npcSettingList || {};

  const advertisements = advertisement
    ? advertisement.rows[0].advertisements.filter(
      (ad) => ad.is_deleted === false
    )
    : [];

  /** find the id of first display type advertisement and set it's id to selectedAdType */
  let selectedAdType = "";

  if (advertisements.length > 0) {
    const ad = advertisements.find((ad) => ad.ad_type === "Display");
    if (ad) {
      selectedAdType = ad.id;
    }
  }

  /** If no display type advertisement found then set first one */
  if (selectedAdType === "" && advertisements.length > 0) {
    selectedAdType = advertisements[0].id;
  }

  // fetching form fields states
  const formValues = formValSelector(
    state,
    "discount_option",
    "color_option",
    "discount",
    "ad_type",
    "color_text_cost",
    "bold_cost",
    "image_cost",
    "border_cost",
    "background_cost",
    "isColoredText",
    "isBold",
    "isAddBorder",
    "isAddImage",
    "isAddBackgroundColor",
    "character_option",
    "line_option",
    "isAddCharacter",
    "isAddLine",
    "character_limit",
    "account_type",
    "account_type_price"
  );

  /*const initialValues = {
    plan_name: "",
    edition_id: edition && edition.length === 1 ? edition[0].name : "",
    ad_type: selectedAdType,
    approval: false,
    deadline_day: "",
    deadline_time: "",
    deadline_price: "",
    discount: false,
    width: "",
    height: "",
    color_text_cost: "",
    bold_cost: "",
    image_cost: "",
    border_cost: "",
    background_cost: "",
    // active: false /** Initially setting false and send reverse of active */
  // };
  //   active: false,
  // }; */

  let initialValues;

  // //console.warn("Planid", localStorage.getItem("setPlanIdTest"));
  if(localStorage.getItem("setPlanIdTest") !="undefined"){
       initialValues = {
        ad_type: "",
        edition_id: edition && edition.length === 1 ? edition[0].name : "",
        
      };

      // set initial values if there is a plan
      if (Object.keys(npcPlan).length > 0) {
        initialValues = {
          ...npcPlan,
          ad_type: npcPlan.ad_type,
          active: !npcPlan.active,
          discount:npcPlan.discount,
          width: npcPlan.width,
          height: npcPlan.height,
          isColoredText: npcPlan.color_text_cost || false,
          isBold: npcPlan.bold_cost || false,
          isAddBorder: npcPlan.border_cost || false,
          isAddImage: npcPlan.image_cost || false,
          isAddBackgroundColor: npcPlan.background_cost || false,
          edition_id: edition && edition.length === 1 ? edition[0].name : npcPlan.edition_id,
          paper_type:"",
          paper_cost:"",
          bleed_width:npcPlan.bleed_width|| false,
          bleed_height:npcPlan.bleed_height|| false,
          bleed_needed:npcPlan.bleed_needed,
          character_limit: npcPlan.character_limit,
          lines_limit: npcPlan.lines_limit,
          isAddCharacter: npcPlan.isAddCharacter,
          isAddLine: npcPlan.isAddLine,
          account_type:npcPlan.account_type,
          account_type_price: npcPlan.account_type_price
        };
      }
  }else{

    initialValues = {
      plan_name: "",
      edition_id: edition && edition.length === 1 ? edition[0].name : "",
      edition_name: edition && edition.length === 1 ? edition[0].name : "",
      ad_type:"",
      approval: false,
      deadline_day: "",
      deadline_time: "",
      deadline_price: 0,
      discount: false,
      width: "",
      height: "",
      color_text_cost: "",
      bold_cost: "",
      image_cost: "",
      border_cost: "",
      background_cost: "",
      active: false,
      paper_type:"",
      paper_cost:"",
      bleed_width:"",
      bleed_height:"",
      bleed_needed:"",
      character_limit: 0,
      lines_limit:0,
      isAddCharacter:false,
      isAddLine:false,
      account_type:"",
      account_type_price: 0.00
    };
    
  }  

  return {
    loggedInUser: auth.user,
    initialValues,
    colorList: color ? color : [],
    editionList: edition ? edition : [],
    advertisementList: advertisements,
    formValues,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
    changeFieldValue,
    untouchField,
  })(
    reduxForm({
      form: "create-new-plan", // a unique identifier for this form
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(CreateNewPlan)
  )
);

