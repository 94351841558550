import { toastr } from "react-redux-toastr";
import React from 'react';
import Moment from 'moment';
import axios from 'axios';
// import { MESSAGES } from '../config/message';
// import { reactLocalStorage } from 'reactjs-localstorage';
import NavigationPrompt from 'react-router-navigation-prompt';
import NavigateModal from "../pages/user-types/NavigateModal";

/** 
* @method  apiErrors
* @desc Response error handler.
* @param res
*/
export const apiErrors = (res) => {
    console.log('apiErrors=> ', res, res.response);
    const response = res ? res.response : undefined;
    if (response && response.data && response.data.error && response.data.error.message && response.data.error.message.value) {
        toastr.error(response.data.error.message.value);
    } else if (response && response.data && response.data.error && response.data.error.message && response.data.error.message.value) {
        toastr.error(response.data.error.message.value);
    } else if (response) {
        if (response.status && response.status === 400) {
            toastr.error('Something went wrong please try again.');
        } else if (response.status && response.status === 401) {
            toastr.error('Your session has been expired. Please login again');
        } else if (response && response.status === 403) {
            toastr.error('Server error occurred, please try again after sometime.');
        } else if (response && response.status === 412) {
            const errMsg = response && response.data && response.data.Message ? response.data.Message : 'Something went wrong please try again.';
            toastr.error(errMsg);
        } else if (response && (response.status === 500 || response.status === 501 || response.status === 503 || response.status === 502)) {
            toastr.error('Server error occurred, please try again after sometime.');
        } else if (response.status && response.status === 404) {
            toastr.error('this record does not exist.');
        } else {
            toastr.error('Something went wrong please try again.');
        }
    } else {
        toastr.error('Something went wrong please try again.');
    }
}

/**
@param {} method
* @description opens a confirmation box for cancel button
* @returns calls the cancel method
*/
export const promptNavigation = (isAllowed) => {
    return (
        <NavigationPrompt when={isAllowed}>
            {(props) => {
                const { onConfirm, onCancel } = props;
                return (
                    <NavigateModal
                        isOpen={true}
                        alertHeading={"Changes made will be lost."}
                        showCancel={true}
                        cancleButtonText='Cancel'
                        okButtonText='Leave Anyway'
                        onOkClick={() => onConfirm()} />
                )
            }}
        </NavigationPrompt>)
}


/**
 * @method MakeTable
 * @description make table view
 * @param {*} param0 
 */
export const MakeTable = ({
    column = [],
    children,
}) => {
    return (
        <div className="table-responsive my-2">
            <table className="table table-sm table-striped table-hover table-bordered custom-rp-table" style={{ tableLayout: 'fixed' }}>
                <thead className="bg-secondary">
                    <tr>
                        {
                            column.length && column.map(col => (
                                <th key={col}>{col}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    )
}

/**
 * @method getNumbersRange
 * @description return array of range of number from 0 to num
 * @param {*} num 
 */
export const getNumbersRange = (num) => {
    return Array.from({ length: num }, (v, i) => i + 1)
}

/**
 * @method getTimes
 * @description return time as array
 */
export const getTimes = () => {
    return getNumbersRange(24).map(v => {
        if (v === 24) return { label: `${12} AM`, value: `${12} AM` }
        if (v === 12) return { label: `${12} PM`, value: `${12} PM` }
        return v >= 12 ? ({ label: `${v - 12} PM`, value: `${v - 12} PM` }) : ({ label: `${v} AM`, value: `${v} AM` })
    })
}
/**
 * @method getDays
 * @description return days options as array
 */
export const getDays = () => {
    return getNumbersRange(31).map(v => ({ label: `${v} day before`, value: `${v} day before` }))
}

/**
   * @method validateMultipleFieldLevelValidation
   * @description validate multiple field level validations
   * @param {*} value 
   * @param {*} validations 
   */
export const validateMultipleFieldLevelValidation = (value, validations) => {
    for (let validation of validations) {
        const result = validation(value);
        if (result) {
            return result;
        }
    }
    return null;
};