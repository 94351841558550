import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetSummaryDataModule1: "[SetSummaryDataModule1] Action",
    SetSummaryDataModule2: "[SetSummaryDataModule2] Action",
    SetSummaryDataModule3: "[SetSummaryDataModule3] Action",
    SetSummaryDataModule4: "[SetSummaryDataModule4] Action",
    SetSummaryDataModule5: "[SetSummaryDataModule5] Action",
    SetSummaryDataModule6: "[SetSummaryDataModule6] Action",
    SetSummaryCompareData: "[SetSummaryCompareData] Action",

};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    moduleData1: undefined,
    moduleData2: undefined,
    moduleData3: [],
    moduleData4: undefined,
    moduleData5: [],
    moduleData6: [],
    compareData: []
};


/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryDataModule1: {
                const { moduleData } = action.payload;
                return { ...state, moduleData1: (moduleData.multipleCounts !== undefined) ? moduleData.multipleCounts : undefined }
            };

            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryDataModule2: {
                const { moduleData } = action.payload;
                return { ...state, moduleData2: (moduleData.quickStats !== undefined) ? moduleData.quickStats : [] }
            };

            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryDataModule3: {
                const { moduleData } = action.payload;
                return { ...state, moduleData3:(moduleData.topCompanies  !== undefined) ? moduleData.topCompanies : []}
            };

            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryDataModule4: {
                const { moduleData } = action.payload;
                return { ...state, moduleData4: moduleData.contribution !== undefined ? moduleData.contribution : 0.00 }
            };

            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryDataModule5: {
                const { moduleData } = action.payload;
                return { ...state, moduleData5: moduleData.compareData !== undefined ? moduleData.compareData : [] }
            };

            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryDataModule6: {
                const { moduleData } = action.payload;
                return { ...state, moduleData6: moduleData.companyDataGrid !== undefined ? moduleData.companyDataGrid : [] }
            };
            /** get all records of company summary data 2 */
            case actionTypes.SetSummaryCompareData: {
                const { moduleData } = action.payload;
                return { ...state, compareData: moduleData.compareData !== undefined ? moduleData.compareData : [] }
            };


            /** set default state */
            default:
                return state;
        }
    }
)


/** Actions creator */
export const actions = {
    /**
    * @method getSalesRecord
    * @description get all sales record 
    */
    getCompanySummaryData: (requestData, module, callback) => {
        const { npc_id, data, type, sort = '', column = '', month = '', year = '', from_date = '', to_date = '', page = 1, row = 25, } = requestData;
        let url = `${API.getCompanySummaryReport}?npc_id=${npc_id}&module=${type}&sort=${sort}&column=${column}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&data=${data}`
        const request = axios.get(url, { headers });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);

                if (module === 1) {
                    return dispatch({ type: actionTypes.SetSummaryDataModule1, payload: { moduleData: response.data } })
                } else if (module === 2) {
                    return dispatch({ type: actionTypes.SetSummaryDataModule2, payload: { moduleData: response.data } })
                } else if (module === 3) {
                    return dispatch({ type: actionTypes.SetSummaryDataModule3, payload: { moduleData: response.data } })
                } else if (module === 4) {
                    return dispatch({ type: actionTypes.SetSummaryDataModule4, payload: { moduleData: response.data } })
                } else if (module === 6) {
                    return dispatch({ type: actionTypes.SetSummaryDataModule6, payload: { moduleData: response.data } })
                }
                if (module === 7) {
                    // getting company trends graph detail
                    return dispatch({ type: actionTypes.SetSummaryCompareData, payload: { moduleData: response.data } })
                }
                else if (module === 5) {
                    return dispatch({ type: actionTypes.SetSummaryDataModule5, payload: { moduleData: response.data } })
                }
            }).catch((error) => {
                console.log('error:# ', error);
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

}

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}