import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap'
import { injectIntl } from 'react-intl'
import {
    renderSelectField,
    renderDatePicker
} from '../../../../common/layout/FormInput'
import { DATE_RANGE, COMPARE_TO, } from '../../../../config/constant'
import * as paymentReportDuck from '../../../../store/ducks/npc/PaymentReport.duck';

class FilterRecords extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: DATE_RANGE,
            compareTo: COMPARE_TO,
            startDate: '',
            endDate: '',
            dateVisible: false,
        }
    }

    componentDidMount() {
        const { selectedOption } = this.props;
        this.props.initialize({ date_range: selectedOption.option, from: selectedOption.startDate, to: selectedOption.endDate });
        this.setState({ dateVisible: selectedOption.option === '1' ? true : false, startDate:selectedOption.option === '1' ? selectedOption.startDate : '', endDate: selectedOption.option === '1' ? selectedOption.endDate :'' })
    }

    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleModel = () => {
        this.props.onCancel();
    }

    /**
       * Submit the login form
       * @param values
       * @desc Calling a login actions && redirecting page based on response and set session Items 
       */
    onSubmit(values) {
        this.props.applyFilter(values, this.props)
    }

    /**
     * @method render
     * @description render the component
     */
    render() {
        const { handleSubmit, isOpen } = this.props;
        const { dateRange, startDate, dateVisible } = this.state
        return (
            <Modal
                isOpen={isOpen} toggle={this.toggleModel} styles={{ height: 10, width: 100 }}>
                {/* {loading && <Loader />} */}
                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                    Apply Filter
                </ModalHeader>
                <ModalBody styles={{ width: 600 }}>
                    <Row>
                        <Container>
                            <form
                                noValidate
                                className='form'
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='date_range'
                                            options={dateRange}
                                            label={'Date Range'}
                                            optionValue={'value'}
                                            optionLabel={'label'}
                                            //isSelect={false}
                                            onChange={(e) => {
                                                if (e.target.value === '1') {
                                                    this.setState({ dateVisible: true, selectedValue: e.target.value })
                                                } else {
                                                    this.setState({ dateVisible: false, selectedValue: e.target.value })
                                                    this.props.change('from', '')
                                                    this.props.change('to', '')
                                                }
                                            }}
                                            component={renderSelectField}
                                        />
                                    </Col>
                                </Row>
                                {dateVisible && <Row>
                                    <Col md='6'>
                                        <Field
                                            name='from'
                                            label='From'
                                            //minDate={endDate}
                                            onChange={(e) => {
                                                this.setState({ startDate: e, endDate: ''})
                                                this.props.change('to', '');
                                            }}
                                            component={renderDatePicker}
                                        />
                                    </Col>
                                    <Col md='6'>
                                        <Field
                                            name='to'
                                            label='To'
                                            minDate={startDate}
                                            onChange={(e) => {
                                                this.setState({ endDate: e })
                                            }}
                                            component={renderDatePicker}
                                        />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col md='12 text-right float-right'>
                                        <Button style={{ margin: 10, background: 'black', border: 'none', width: 100 }} size='md' type='submit'>Apply</Button>
                                        <Button onClick={this.toggleModel} style={{
                                            margin: 10, background: '#808080',
                                            border: 'none',
                                            width: 100
                                        }} size='md' >Cancel</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

const mapStateToProps = store => {
    const { paymentReportDuck } = store;
    const { loading } = store.commonDuck;
    const { auth } = store;
    let initialValues = {};
    initialValues = {
        //date_range:2
    }
    return ({
        loggedInUser: auth.user,
        loading,
        initialValues,
        payoutOrderList: paymentReportDuck.payoutReportList
    })
}

export default injectIntl(
    connect(
        mapStateToProps,
        paymentReportDuck.actions
    )(reduxForm({
        // Assign unique name for the form
        form: 'FilterRecords',
    })(FilterRecords))
);
