import axios from 'axios'
import { BASE_URL } from '../config'
import { apiErrors } from '../common/HandleAPIErrors'

export const ME_URL = `${BASE_URL}/api`

export const LOGIN_URL = `${BASE_URL}/api/login`;
export const ALL_SUBSCRIPTION_PLAN_URL = `${BASE_URL}/api/getAllSubscriptions`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/api/forgotPassword`;
export const FORGOT_PASSWORD_WITHOUT_LOGIN_URL = `${BASE_URL}/api/forgotPasswordWithoutLogin`;
// open api changes
//export const FORGOT_PASSWORD_URL = `${BASE_URL}/open/forgotPassword`;
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';


export async function login(email, password) { return axios.post(LOGIN_URL, { email, password }); }
export async function getAllSubscriptions() { return axios.get(ALL_SUBSCRIPTION_PLAN_URL); }
export function requestPassword(email) {
  return axios.post(FORGOT_PASSWORD_WITHOUT_LOGIN_URL, { email });
}

export function requestForResetPassword(email, callback) {
  return axios.post(FORGOT_PASSWORD_URL, { email }).then((res) => {
    callback(res)
  }).catch((err) => {
    apiErrors(err);
    callback(err)
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
export const forgetPassword = (email) => axios.post(`${BASE_URL}/api/forgotPassword`, email);
export const resetPassword = (password) => axios.put(`${BASE_URL}/api/resetPassword`, password);
export const forgetPasswordToken = (token) => axios.get(`${BASE_URL}/forgotPasswordToken?token=${token}`);
