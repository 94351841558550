import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { Input } from "reactstrap";
import { toastr } from "react-redux-toastr";
import * as superAdminDuck from "../../../../store/ducks/SuperAdmin.duck";
import { Loader } from "../../../../common/Loader";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import DataTable from "./DataTable";
import FilterRecords from "../reports-management/FilterRecords";
import {
  setDateFilterKeys,
  setDateFilterKeysCustom,
} from "../../../../common/CommonFilterFunction";
import { MESSAGES } from "../../../../config/message";

let label = "All";
let allUsersData = [];
class UsersActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      openModal: false,
      selectedOption: {
        option: 8,
        startDate: "",
        endDate: "",
        customer_id: "",
      },
    };
  }
  handleFilter = (values, b) => {
    this.applyFilter(values, this.props);
  };
  applyFilter(values, props) {
    const data = setDateFilterKeys(values, label);
    const requestfield = setDateFilterKeysCustom(
      values.date_range,
      values.from,
      values.to
    );
    if ((data.date && !data.date2) || (data.date2 && !data.date)) {
      return toastr.error(MESSAGES.DATE_VALIDATION);
    }
    const requestData = {
      type: 3,
      agentTotalFilterOption: requestfield.agentTotalFilterOption,
      toDate: requestfield.toDate,
      fromDate: requestfield.fromDate,
    };
    label = data.label;
    this.setState({
      fromDate: data.date,
      toDate: data.date2,
      month: data.this_month,
      year: data.this_year,
      selectedOption: {
        option: values.date_range,
        startDate: values.from,
        endDate: values.to,
      },
      srch: "",
    });
    this.props.getUsersActivityLog(requestData, (res) => {
      this.setState({ usersData: res.data, openModal: false });
      allUsersData = res.data;
    });
  }
  handleSearch = (e) => {
    var val = e.target.value.toLowerCase().trimStart();
    this.setState({ srch: val });
    if (!val) {
      this.setState({ usersData: allUsersData });
    } else {
      const filterData = allUsersData.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key]
            .toString()
            .toLowerCase()
            .includes(val);
        });
      });
      this.setState({ usersData: filterData });
    }
  };
  columns = [
    {
      Header: "Company Name",
      accessor: "company_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.company_name}</span>;
      },
    },
    {
      Header: "Name",
      accessor: "user_name",
      Cell: (row) => {
        const { original } = row;
        return <span>{original.user_name}</span>;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => {
        const { original } = row;
        return (
          <span style={{ textTransform: "lowercase" }}>{original.email}</span>
        );
      },
    },
    {
      Header: "Login Date time",
      accessor: "loginTimestamp",
      Cell: (row) => {
        const { original } = row;
        return (
          <span style={{ textTransform: "lowercase" }}>
            {original.loginTimestamp &&
              moment(original.loginTimestamp).format("MM/DD/YYYY, h:mm:ss a")}
          </span>
        );
      },
    },
    {
      Header: "Logout Date time",
      accessor: "logoutTimestamp",
      Cell: (row) => {
        const { original } = row;
        return (
          <span style={{ textTransform: "lowercase" }}>
            {original.logoutTimestamp ? (
              new Date(original.logoutTimestamp) < new Date() ? (
                moment(original.logoutTimestamp).format("MM/DD/YYYY, h:mm:ss a")
              ) : (
                <span style={{ color: "#3a8252" }}>Logged In</span>
              )
            ) : (
              ""
            )}
          </span>
        );
      },
    },
  ];
  componentDidMount() {
    const requestData = {
      type: 3,
    };
    this.props.getUsersActivityLog(requestData, (res) => {
      this.setState({ usersData: res.data });
      allUsersData = res.data;
    });
  }
  render() {
    const { loading } = this.props;
    const { usersData, openModal, selectedOption } = this.state;
    return (
      <>
        {loading && <Loader />}
        <div className="row">
          <div className="col-12 kt-form subsciption_list_content kt-form--label-right">
            <Portlet>
            <PortletBody>
                <div className="row align-items-center mb-3 mr-1 justify-content-end">
                  <div
                    className="cursorPointer"
                    style={{ textAlign: "end", cursur: "pointer" }}
                    onClick={() => this.setState({ openModal: true })}
                  >
                    {label} &nbsp;&nbsp;
                    <i
                      style={{ fontSize: 15 }}
                      className="fa fa-angle-down"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="ml-3">
                    <Input
                      style={{ height: 30, borderRadius: 25 }}
                      placeholder="Search"
                      type="text"
                      value={this.state.srch}
                      onChange={(e) => this.handleSearch(e)}
                    ></Input>
                  </div>
                </div>
                <div className="activity_log_Table">
                  <DataTable data={usersData} columns={this.columns} />
                </div>
              </PortletBody>
            </Portlet>
            {openModal && (
              <FilterRecords
                applyFilter={this.handleFilter}
                isOpen={openModal}
                selectedOption={selectedOption}
                onCancel={() => this.setState({ openModal: false })}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, superAdminDuck.actions)(UsersActivity)
);
