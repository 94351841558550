import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'

export const actionTypes = {
    SetDashBoardData: "[SetDashBoardData] Action",
    SetCreditDashBoardData: "[SetCreditDashBoardData] Action",
    SetPayoutDashBoardData:"[SetPayoutDashBoardData] Action",
    SetDashBoardGraphData: "[SetDashBoardGraphData] Action",
    SetTopAdType: "[SetTopAdType] Action",
    SetTopCompany: "[SetTopCompany] Action",
    SetTopCustomer: "[SetTopCustomer] Action",
    SetTopAgent: "[SetTopAgent] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    dashboardData: undefined,
    topAdTypeData: undefined,
    topCompanyData: undefined,
    topCustomerData: undefined,
    topAgentData: undefined,
    dashboardCreditData: undefined,
    dashboardPayoutData:undefined
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of company list */
            case actionTypes.SetDashBoardData: {
                const { dashboardData } = action.payload;
                return { ...state, dashboardData: dashboardData };
            }

            case actionTypes.SetCreditDashBoardData: {
                const { dashboardCreditData } = action.payload;
                return { ...state, dashboardCreditData: dashboardCreditData };
            }

            case actionTypes.SetPayoutDashBoardData: {
                const { dashboardPayoutData } = action.payload;
                return { ...state, dashboardPayoutData: dashboardPayoutData };
            }
            /** get all records of company list */
            case actionTypes.SetDashBoardGraphData: {
                const { dashboardGraphData } = action.payload;
                return { ...state, dashboardGraphData: dashboardGraphData };
            }

            /** get all records of company list */
            case actionTypes.SetTopAdType: {
                const { topAdTypeData } = action.payload;
                return { ...state, topAdTypeData: topAdTypeData };
            }

            /** get all records of top company list */
            case actionTypes.SetTopCompany: {
                const { topCompanyData } = action.payload;
                return { ...state, topCompanyData: topCompanyData };
            }

            /** get all records of top customer list */
            case actionTypes.SetTopCustomer: {
                const { topCustomerData } = action.payload;
                return { ...state, topCustomerData: topCustomerData };
            }

            /** get all records of top agent list */
            case actionTypes.SetTopAgent: {
                const { topAgentData } = action.payload;
                return { ...state, topAgentData: topAgentData };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
    * @method getAdminDashBoardData
    * @description get admin dash board data
    */
    getAdminDashBoardData: (requestData, callback) => {
        const { type='', role='', month='', year='', from_date='', to_date='', page='', row='' } = requestData;
        let url = `${API.getAdminDashboardRecord}?&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                callback(response);
                if (type === 7) {
                    return dispatch({ type: actionTypes.SetCreditDashBoardData, payload: { dashboardCreditData: response.data } })

                } else if (type === 6) {
                    return dispatch({ type: actionTypes.SetPayoutDashBoardData, payload: { dashboardPayoutData: response.data } })

                } else {
                    return dispatch({ type: actionTypes.SetDashBoardData, payload: { dashboardData: response.data } })
                }
            }).catch((error) => {
                // dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    getAllNewspaperList: (requestData, callback) => {
        const { row, page, is_deleted, status } = requestData;
        const request = axios.get(`${API.getAllNewspaperList}?row=${row}&page=${page}&is_deleted=${is_deleted}&status=${status}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.ResetAllCompanyList, payload: { companyList: response.data.rows, totalCompany: response.data.count } })

            }).catch((error) => {
                //apiErrors(error, dispatch)
                //dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
    * @method getAdminDashBoardGraphDetail
    * @description get admin dash board graph detail
    */
    getAdminDashBoardGraphDetail: (requestData, callback) => {
        const { type='', role='', month='', year='', from_date='', to_date='', page='', row='' } = requestData;
        let url = `${API.getAdminDashboardRecord}?&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetDashBoardGraphData, payload: { dashboardGraphData: response.data } })
            }).catch((error) => {
                // dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
    * @method getTopAdType
    * @description get admin dash board graph detail
    */
    getTopAdType: (requestData, callback) => {
        const { type='', role='', month='', year='', from_date='', to_date='', page='', row='', sales='' } = requestData;
        let url = `${API.getAdminDashboardRecord}?&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetTopAdType, payload: { topAdTypeData: response.data } })
            }).catch((error) => {
                // dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
     * @method getTopCompany
     * @description get admin dash board top company detail
     */
    getTopCompany: (requestData, callback) => {
        const { type='', role='', month='', year='', from_date='', to_date='', page='', row='', sales='' } = requestData;
        let url = `${API.getAdminDashboardRecord}?&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetTopCompany, payload: { topCompanyData: response.data } })
            }).catch((error) => {
                // dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
     * @method getTopCustomer
     * @description get admin dash board top customer detail
     */
    getTopCustomer: (requestData, callback) => {
        const { type='', role='', month='', year='', from_date='', to_date='', page='', row='', sales='' } = requestData;
        let url = `${API.getAdminDashboardRecord}?&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetTopCustomer, payload: { topCustomerData: response.data } })
            }).catch((error) => {
                // dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },

    /**
    * @method getTopAgent
    * @description get admin dash board top agent detail
    */
    getTopAgent: (requestData, callback) => {
        const { type='', role='', month='', year='', from_date='', to_date='', page='', row='', sales='' } = requestData;
        let url = `${API.getAdminDashboardRecord}?&module=${type}&month=${month}&year=${year}&from=${from_date}&to=${to_date}&row=${row}&page=${page}&role=${role}&sales=${sales}`
        const request = axios.get(url, { headers });
        return (dispatch) => {
            // dispatch({ type: CALL_LOADING })
            request.then((response) => {
                // dispatch({ type: CALL_LOADING })
                callback(response);
                return dispatch({ type: actionTypes.SetTopAgent, payload: { topAgentData: response.data } })
            }).catch((error) => {
                // dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error,dispatch);
                return callback(error);
            });
        };
    },
};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





