
import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form'
import { renderThemeText } from '../../common/layout/FormInput'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import clsx from 'clsx';
import * as auth from '../../store/ducks/auth.duck';
import { login } from '../../crud/auth.crud';
import {
  required,
  email,
} from '../../config/validation';
import { MESSAGES } from '../../config/message'
import { STATUS_CODES } from "../../common/StatusCode";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      loading: false,
      loadingButtonStyle: {
        paddingRight: '2.5rem'
      },
      success: false,
      show: false,
      alwaysShow: false
    }
  }

  /**
   * @method enableLoading
   * @description enable button loader
   */
  enableLoading = () => {
    this.setState({ loading: true, loadingButtonStyle: { paddingRight: '3.5rem' } });
  };

  /**
  * @method disableLoading
  * @description disable button loader
  */
  disableLoading = () => {
    this.setState({ loading: false, loadingButtonStyle: { paddingRight: '2.5rem' } });
  };

  /**
  * Submit the login form
  * @param values
  * @desc Calling a login actions && redirecting page based on response and set session Items 
  */
  onSubmit(values) {
    // localStorage.setItem("tabIndex", 0)
    localStorage.setItem("tabIndex", "0");
    localStorage.setItem("loggedIn", "1")
    this.enableLoading();
    setTimeout(() => {
      login(values.email, values.password)
        .then(({ data }) => {
          this.disableLoading();
          this.props.login(data);
        })
        .catch((error) => {
          this.disableLoading();
          if (error.response && error.response.data.success == false) {
            console.log('error.response : ', error.response.data.success);
            this.setState({ alwaysShow: true, show: true, success: false, variant: 'danger', message: error.response.data.message })
          }
          else if (error.response && error.response.status === STATUS_CODES.PRECONDITION_FAILED) {
            this.setState({ show: true, success: false, variant: 'danger', message: error.response.data.message })
            setTimeout(() => {
              this.setState({ show: false });
            }, 5000);
          } else {
            this.setState({ show: true, success: false, variant: 'danger', message: MESSAGES.SERVER_ERROR })
            setTimeout(() => {
              this.setState({ show: false });
            }, 3000);
          }
        });
    }, 1000);
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit } = this.props;
    const { show, message, loadingButtonStyle, loading, success, alwaysShow } = this.state;
    return (
      <>
        {String(this.props.match.path) === '/auth/login' && <div className='kt-login__head'>
          <span className='kt-login__signup-label'>
            Don't have an account yet?
        </span>
          &nbsp;&nbsp;
        <Link to='/auth/registration' className='kt-link kt-login__signup-link'>
            Sign Up!
        </Link>
        </div>}
        <div className='kt-login__body'>
          <div className='kt-login__form'>
            <div className='kt-login__title'>
              <h3>
                <FormattedMessage id='Login' />
              </h3>
            </div>
            {show && !success && (
              <Alert
                variant='danger'
                onClose={() => this.setState({ show: false })}
                dismissible
                alwaysShow={alwaysShow}
              >
                {message}
              </Alert>
            )}
            <Form
              noValidate
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <Form.Group as={Col} md='12'>
                <Form.Row>
                  <Form.Group as={Col} md='12' controlId='validationCustom01'>
                    <Field
                      name='email'
                      label='Email'
                      type={'email'}
                      validate={[required, email]}
                      required={true}
                      placeholder='Enter Your Email Id'
                      component={renderThemeText} />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form.Group>

              <Form.Group as={Col} md='12'>
                <Form.Row>
                  <Form.Group as={Col} md='12' controlId='validationCustom01'>
                    <Field
                      name='password'
                      label='Password'
                      type={'password'}
                      validate={[required]}
                      required={true}
                      placeholder='Enter Your Password'
                      component={renderThemeText} />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form.Group>

              <div className='kt-login__actions'>
                <Link
                  to='/auth/forgot-password'
                  className='kt-link kt-login__link-forgot'
                >
                  <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
                </Link>

                <button
                  id='kt_login_signin_submit'
                  type='submit'
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  Login
              </button>
              </div>

            </Form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = store => {
  let initialValues = {
    email: '',
    password: ''
  }
  return {
    initialValues
  }
}
export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(reduxForm({
    // Assign unique name for the form
    form: 'Login',
    // validate,
    enableReinitialize: true,
    destroyOnUnmount: false
  })(Login))
);
