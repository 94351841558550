import React, { useState, useEffect } from "react";
import { Paper, Switch, Grid } from "@material-ui/core";
import { Link, useHistory,useParams } from "react-router-dom";
import ImgsViewer from "react-images-viewer";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Multiselect } from 'multiselect-react-dropdown';
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
  change as changeFieldValue,
  untouch as untouchField,
  reset,
} from "redux-form";
import { toastr } from "react-redux-toastr";
import {
  required,
  maxLengthC100,
  number,
  greaterThanZeroWithTwoDecimalPlace,
  minLengthC3,
} from "../../../../config/validation";
import {
  renderText,
  renderSelectField,
  renderNumberField,
  renderTextInputField,
} from "../../../../common/layout/FormInput";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import * as commonDuck from '../../../../store/ducks/Common.duck';
import { PROFILE_MEDIA_URL } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import { MakeTable, getDays, getTimes } from "../../../../config/utils";
import AddColorForm from "./AddColorForm";
import AddCharacterForm from "./AddCharacterForm";
import AddLineForm from "./AddLineForm";
import AddPaymentDiscountOptionForm from "./AddPaymentDiscountOptionForm";
import { langs } from "../../../../config/localization";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";
import RadioButtonRP from "../../../../common/RadioButtonRP";
import { SettingsInputComponent } from "@material-ui/icons";
import { Form } from "react-bootstrap";
import AddValueForm from "./AddValueForm";

const SocialMediaPlan = ({
  handleSubmit,
  submitting,
  formValues,
  loading,
  priceRunTimeCategoryList,
  sizeOfAdCategoryList,
  adPlacementCategoryList,
  adFeatureCategoryList,
  adTypeCategoryList,
  socialMediaCategoryList,
  /** store states */
  loggedInUser,
  /**Actions */
  getAllPriceRunTimeCategoryList,
  getAllSizeOfAdCategoryList,
  getAllAdPlacementCategoryList,
  getAllAdFeatureCategoryList,
  getAllAdTypeCategoryList,
  getAllSocialMediaCategoryList,
  getfromNPCSetting,
  getAllAdType,
  getAllPaperType,
  getAllAccount,
  addNewPlan,
  addNewSocialMediaPlan,
  changeFieldValue,
  untouchField,
  reset,
  getPlanById
}) => {
  const history = useHistory();
  // console.log('*************', NPCDuck.adTypeList);
  // extract logged in user details
  const { user_detail } = loggedInUser;

  sizeOfAdCategoryList.sort((x, y) => {
      let fa = x.name.toLowerCase(),
          fb = y.name.toLowerCase();

      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
  });

  socialMediaCategoryList.sort((x, y) => {
    let fa1 = x.name.toLowerCase(),
        fb1 = y.name.toLowerCase();

    if (fa1 < fb1) {
        return -1;
    }
    if (fa1 > fb1) {
        return 1;
    }
    return 0;
  });

  adPlacementCategoryList.sort((x, y) => {
    let fa2 = x.name.toLowerCase(),
        fb2 = y.name.toLowerCase();

    if (fa2 < fb2) {
        return -1;
    }
    if (fa2 > fb2) {
        return 1;
    }
    return 0;
  });

  adFeatureCategoryList.sort((x, y) => {
    let fa3 = x.name.toLowerCase(),
        fb3 = y.name.toLowerCase();

    if (fa3 < fb3) {
        return -1;
    }
    if (fa3 > fb3) {
        return 1;
    }
    return 0;
  });

  adTypeCategoryList.sort((x, y) => {
    let fa4 = x.name.toLowerCase(),
        fb4 = y.name.toLowerCase();

    if (fa4 < fb4) {
        return -1;
    }
    if (fa4 > fb4) {
        return 1;
    }
    return 0;
  });

  const ad_type = [
    {
      name: "Browser Push Notification", 
      id:8
    },
    {
      name: "Email Marketing", 
      id:6
    },
    {
      name: "Email Blast",
      id: 13
    },
    {
      name: "Facebook Post",
      id: 11
    },
    {
      name: "Instagram Stories",
      id: 9
    },
    {
      name: "Instagram Post", 
      id:3
    },
    {
      name: "In App Ad",
      id: 12
    },
    {
      name: "Sponsored Article", 
      id:7
    },
    {
      name: "Sponsored Posts",
      id: 10
    },
    {
      name: "Twitter Post", 
      id:2
    },
      {
          name:"Website Ad", 
          id:1
      },
      {
        name:  "Whatsapp Post", 
        id:4
      },
      {
        name: "Whatsapp Status", 
        id:5
      }

  ]

  const web_ad_type = [
      {
        name: "Banner",
        id: 16
      },
      {
        name: "Crawler", 
        id:8
      },
      {
        name: "Double Post", 
        id:17
      },
      {
        name: "Footer", 
        id:2
      },
      {
        name: "Full Ad",
        id: 11
      },
      {
          name:"Header", 
          id:1
      },
      
      {
        name: "Mid-Page", 
        id:3
      },
      {
        name: "Newsletter Ad",
        id: 13
      },
      {
        name: "Overlay", 
        id:7
      },
      {
        name: "Page Reel",
        id: 15
      },
      {
        name: "Post",
        id: 18
      },
      {
        name: "Quarter",
        id: 10
      },
      {
        name: "right/Left Near Logo",
        id: 12
      },
      {
        name:  "Site Take Over", 
        id:4
      },
      {
        name: "Strip",
        id: 9
    },
      {
        name: "Tower Ad", 
        id:5
      },
      {
        name: "Triple Post", 
        id:19
      },
      {
        name: "Video Ad", 
        id:6
      },
    {
        name: "Wallpaper",
        id: 14
    },
    
    
  ]


  const web_adons = [
    {
      name: "Dedicated",
      id: 10
    },
    {
      name: "Home Page Article",
      id: 9
    },
    {
      name: "Main Site",
      id: 7
    },
    {
      name: "Parallex",
      id: 2
    },
    {
      name: "Pop Up",
      id: 4
    },
    {
      name: "Premium Spot",
      id: 6
    },
    {
      name: "Premium Slot",
      id: 11
    },
    {
      name: "Right/Left",
      id: 8
    },
    {
      name: "Rotating",
      id: 5
    },
    {
      name: "Static",
      id: 3
    },
      {
        name: "Sticky Banner",
        id: 1
      }

  ]

  const price_run_time = [
    {
      name: "1 Day",
      id: 1
    },
    {
      name: "24 hrs",
      id: 2
    },
    {
      name: "48 hrs",
      id: 3
    },
    {
      name: "1 Week",
      id: 4
    },
    {
      name: "2 Weeks",
      id: 5
    },
    {
      name: "1 Month",
      id: 6
    },
    {
      name: "6 Months",
      id: 7
    },
    {
      name: "3 Months",
      id: 8
    },
    {
      name: "1 Year",
      id: 9
    }
]

const location_of_ad = [
  {
    name: "Between Posts",
    id: 13
  },
  {
    name:"Homepage",
    id: 8
  },
  {
    name: "Left",
    id: 2
  },
  {
    name: "Left Side",
    id: 15
  },
  {
    name: "Lower Side",
    id: 11
  },
  {
    name: "Near logo",
    id: 6
  },
  {
    name: "Premium spot",
    id: 7
  },
  {
    name: "Right",
    id: 1
  },
  {
    name: "Right Side",
    id: 14
  },
  {
    name: "ROS",
    id: 12
  },
  {
    name: "Slot 1",
    id: 3
  },
  {
    name: "Slot 2",
    id: 4
  },
  {
    name: "Slot 3",
    id: 5
  },
  {
    name: "Top",
    id: 10
  },
  {
    name: "Upper side",
    id: 9
  }
 
  
 
  
  
]

const size_of_ad = [
  {
    name: "Extra-large",
    id: 4
  },
  {
    name:"Full",
    id: 8
  },
  {
    name: "Half",
    id: 6
  },
  {
    name: "Large",
    id: 3
  },
  {
    name: "Medium",
    id: 2
  },
  {
    name: "Quarter",
    id: 5
  },
  {
    name: "Small",
    id: 1
  },
  {
    name: "Three-quarter",
    id: 7
  }
  
]

  const [ instaAd, setInstaAd ] = useState(false)
//   const [ webA ]

  const { planId } = useParams();
  const [planIdTest,setPlanIdTest] = useState('');

  
  const [showResults, setShowResults] = useState(false);
  const [addOnType,setAddOnType] = useState('');
  const [addOnPrice,setAddOnPrice] = useState('');
  // Ad Type states

  const [paperTypes, setPaperTypes] = useState([]);
  const [ selectedWebAdon, setSelectedWebAdon] = useState("")
  const [ selectedRunTime, setSelectedRunTime] = useState("")
  const [accountType, setAccountType] = useState([]);
  const [ selectedDayMulti , setSelectedDayMulti ] = useState([])
  const [ setectedWebAdonType, setSelectedWebAdonType ] = useState([])
  const [ selectedSize, setSelectedSize ] = useState([])
  const [ format, setFormat ] = useState("")
  const [ max_size, setMaxSize ] = useState(0)
  //const [paperTypes, setPaperTypes] = useState([{value:null,label:'select'},{value:1,label:'Glossy'},{value:2,label:'Matte'}]);
  
  const [paperTypeData, setPaperTypeData] = useState("");
  const [paperTypeCost, setPaperTypeCost] = useState("");
  const [papertypeActualdata, setPapertypeActualdata] = useState([]);
  const [check, setcheck] = useState(false);
  const [count,setCount] = useState(0);
  // all the advertisements type list of the logged in NPC user
  const [advertisementsTypeList, setAdvertisementsTypeList] = useState([]);
  
  // selected Ad Type state
  const [selectedAdType, setSelectedAdType] = useState("");
  const [ selectedWebAdType, setSelectedWebAdType ] = useState("")
  // selected Ad Type type state i.e. Display, classified etc.
  const [typeOfSelectedAdType, setTypeOfSelectedAdType] = useState("");
  
  // selected Ad Type image state
  const [selectedAdTypeImage, setSelectedAdTypeImage] = useState("");
  // const [ deadlinePrice, setDeadlinePrice ] = useState("");

  const [ characterLimit, setDeadlineCharacter ] = useState(0);
  const [ lineLimit, setDeadlineLine ] = useState(0);
  const [ deadlinePrice, setDeadlinePrice] = useState(0);
  const [ cpm, setCpm ] = useState(0);
  const [ description, setDescription ] = useState("");
  const [ noOfInstaPosts, setNoOfInstaPosts ] = useState(0)
  
  const [ noOfWhatsappPost, setNoOfWhatsappPosts ] = useState(0)
  const [ noOfWhatsappStories, setNoOfWhatsappStories ] = useState(0)
  const [ instaVidLength, setInstaVidLength ] = useState(0)
  const [ whatsappPostVidLength, setWhatsappPostVidLength ] = useState(0)
  const [ whatsappStoriesVidLength, setWhatsappStoriesVidLength ] = useState(0)

  

  // Edition list state
  const [editions, setEditions] = useState([]);
  const [editionsName, setEditionsName] = useState("");
  
 
  // image preview state
  const [openImage, setOpenImage] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  // States when type of Ad Type is Display
  // All the colors list which are added into table
  const [displayAdColors, setDisplayAdColors] = useState([]);
  const [displayAdCharacter, setDisplayAdCharacter] = useState([]);
  const [displayAdLine, setDisplayAdLine] = useState([]);

  const [allAddtype,setAllAddType] = useState([]);
  const [alledition,setAlledition] = useState([]);
  /** payment discount option states */
  // checkbox state to show hide display option
  const [showPaymentDiscountOptions, setShowPaymentDiscountOptions] = useState(
    false
  );

  // payment discount options states
  const [paymentDiscountOptions, setPaymentDiscountOptions] = useState([]);
  const [paymentDiscountOptionError, setPaymentDiscountOptionError] = useState(
    null
  );

  const [AddLinerError, setAddLinerError] = useState(
    null
  );
  const [AddCharacterError, setAddCharacterError] = useState(
    null
  );

  /**
   * @method getAdType
   * @description return type of selected Ad Type
   */


   const onChanged = value => {
    if(value == "InstagramPost"){
        setInstaAd(true)
    }
  }

  const onClickNew = () =>{
    if(showResults){
      setShowResults(false)
       
      //alert(true);
    }else{
      setShowResults(true)
      //alert(false);
    }
  };

 

  const getAdType = () => {
    // advertisementsTypeList
    const add = socialMediaCategoryList.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    // if(add === undefined) return "CLASSIFIED"; 
    if (!add) return "";
    switch (add.id) {
        case 1:
            return "WEBSITE_AD";
        case 2:
            return "INSTAGRAM_POST";
        case 3:
            return "INSTAGRAM_STORIES";
        case 4:
            return "TWITTER_POST";
        case 5:
            return "WHATSAPP_POST";
        case 6:
            return "WHATSAPP_STORIES";
        case 7:
            return "EMAIL_MARKETING";
        case 8:
            return "SPONSORED_ARTICLE";
        case 9:
            return "SPONSORED_POSTS"
        case 10:
            return "BROWSER_PUSH_NOTIFICATION";
        case 11:
          return "FACEBOOK_POST";
        case 12:
          return "IN_APP_AD";
        case 13:
          return "EMAIL_BLAST"
        default:
            return "";
    }
  };



  /**
   * @method getAdTypeOptions
   * @description return dropdown options for Ad Type
   */

  const getAdTypeOptions1 = () => {
    let new_arr = [];
   for(let i = 0; i < ad_type.length; i++){
    if(ad_type[i].name == "WebsiteAd"){
      new_arr = [ ...new_arr, { name: "Website Ad", label: ad_type[i].id} ]
    }if(ad_type[i].name == "InstagramPost"){
      new_arr = [ ...new_arr, { name: "Instagram Post", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "InstagramStories"){
      new_arr = [ ...new_arr, { name: "Instagram Stories", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "TwitterPost"){
      new_arr = [ ...new_arr, { name: "Twitter Post", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "WhatsappPost"){
      new_arr = [ ...new_arr, { name: "Whatsapp Post", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "WhatsappStatus"){
      new_arr = [ ...new_arr, { name: "Whatsapp Status", label: ad_type[i].id} ]
    }if(ad_type[i].name == "EmailMarketing"){
      new_arr = [ ...new_arr, { name: "Email Marketing", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "SponsoredArticle"){
      new_arr = [ ...new_arr, { name: "Sponsored Article", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "SponsoredPosts"){
      new_arr = [ ...new_arr, { name: "Sponsored Posts", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "BrowserPushNotification"){
      new_arr = [ ...new_arr, { name: "Browser Push Notification", label: ad_type[i].id} ]
    }
    if(ad_type[i].name == "FacebookPost"){
      new_arr = [ ...new_arr, { name: "Facebook Post", label: ad_type[i].id} ]
    }

   }
  }
  const getAdTypeOptions = () => {
   // //console.warn('>>>>>>>>>>>>>>>>>', allAddtype);

   
    return Array.isArray(socialMediaCategoryList) &&
    socialMediaCategoryList.length > 0
      ? socialMediaCategoryList.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  const getLocation = () => {
    
    return Array.isArray(adPlacementCategoryList) &&
    adPlacementCategoryList.length > 0
      ? adPlacementCategoryList.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  const getWebAdon = () => {
    
    return Array.isArray(adFeatureCategoryList) &&
    adFeatureCategoryList.length > 0
      ? adFeatureCategoryList.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  const getSize = () => {
    
    return Array.isArray(sizeOfAdCategoryList) &&
    sizeOfAdCategoryList.length > 0
      ? sizeOfAdCategoryList.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };



  /**
   * @method getEditionOptions
   * @description returns drop down options for edition
   */
  const getEditionOptions = () => {
    return Array.isArray(editions) && editions.length > 0
      ? editions.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };


  let getEditionName = (id)=>{

    let add = editions.find(
      (ad) => parseInt(ad.id) === parseInt(id)
    );
    if (add.name!=""){
      setEditionsName(add.name);
    }

    /*if (!add){
      return "";
    }else{
      setEditionsName(add.name);
    } */

  };

  

  

  /**
   * @method handleAddDiscountOptions
   * @description handle add a discount option
   */
  const handleAddDiscountOptions = (v) => {
    setPaymentDiscountOptionError(null);
    // set discount option to state
    setPaymentDiscountOptions([...paymentDiscountOptions, v]);
  };

  /**
   * @method handleDeleteDiscountOptions
   * @description handle delete discount option from table
   * @param {*} option
   */
  const handleDeleteDiscountOptions = (option) => {
    const updatedPaymentDiscountOptions = paymentDiscountOptions.filter(
      (p) =>
        p.runs !== option.runs &&
        p.within !== option.within &&
        p.typeOfDiscount !== option.typeOfDiscount &&
        p.discountAmount !== option.discountAmount
    );
    setPaymentDiscountOptions(updatedPaymentDiscountOptions);
  };

  /**
   * @method handleAddDisplayAdColor
   * @description handle add color for display Ad
   */
  const handleAddDisplayAdColor = (color) => {
    // set color option to state
    setDisplayAdColors([...displayAdColors, color]);
  };
  let getCharacterLimit=(value)=>{
    setDeadlineCharacter(value);
  }
  let getlineLimit=(value)=>{
    setDeadlineLine(value);
  }

  let getNoOfInstaPosts=(value)=>{
      setNoOfInstaPosts(value)
  }

  let getNoOfWhatsappPost=(value)=>{
      setNoOfWhatsappPosts(value)
  }

  let getNoOfWhatsappStories=(value)=>{
      setNoOfWhatsappStories(value)
  }

  let getVideoLength=(value)=>{
      setInstaVidLength(value)
  }

  let getWhatsappStoriesVideoLength=(value)=>{
      setWhatsappStoriesVidLength(value)
  }

  let getWhatsappPostsVideoLength=(value)=>{
    setWhatsappPostVidLength(value)
    }
    
  let getdeadlineprice=(value)=>{
    setDeadlinePrice(value);
  }
  let getCpm = (value) => {
    setCpm(value)
  }

  let getDescription = (value) => {
    setDescription(value)
  }

  let getFormat = (value) => {
    setFormat(value)
  }

  let getMaxSize = (value) => {
    setMaxSize(value)
  }
  const handleAddDisplayAdCharacter = (character) => {
    // set character option to state
    setDisplayAdCharacter([...displayAdCharacter, character]);
    setAddCharacterError(null);
  };
  const handleAddDisplayAdLine = (line) => {
    // set character option to state
    setDisplayAdLine([...displayAdLine, line]);
    setAddLinerError(null);
  };


 
  /**
   * @method handleDeleteDisplayAdColor
   * @description handle delete display Ad color
   * @param {*} disAdCol
   */
  const handleDeleteDisplayAdColor = (disAdCol) => {
    const updatedColors = displayAdColors.filter(
      (c) => !(c.value === disAdCol.value && c.cost === disAdCol.cost)
    );
    setDisplayAdColors(updatedColors);
  };
  const handleDeleteDisplayAdCharacter = (disAdChar) => {
    const updatedChar = displayAdCharacter.filter(
      (c) => !(c.character === disAdChar.character && c.cost === disAdChar.cost)
    );
    setDisplayAdCharacter(updatedChar);
  };

  const handleDeleteDisplayAdLine = (disAdLine) => {
    const updatedLine = displayAdLine.filter(
      (c) => !(c.line === disAdLine.line && c.cost === disAdLine.cost)
    );
    setDisplayAdLine(updatedLine);
  };

  

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values) => {
    //   console.log("This is inside submit form")
    // const selectedEdition = editions && editions.length === 1 ? editions[0].id : values.edition_id;
    //const selectedEditionname=editions && editions.length === 1 ? editions[0].name : "";
    let classified_conditional_errors = {};
    let display_height_width_errors = {};
    /**
     * checking conditional mandatory fields
     */
    if (values.isColoredText && !values.color_text_cost) {
      classified_conditional_errors.color_text_cost =
        langs.validation_messages.required;
    }
    if (values.isBold && !values.bold_cost) {
      classified_conditional_errors.bold_cost =
        langs.validation_messages.required;
    }
    if (values.isAddImage && !values.image_cost) {
      classified_conditional_errors.image_cost =
        langs.validation_messages.required;
    }
    // if (values.isAddBorder && !values.border_cost) {
    //   classified_conditional_errors.border_cost =
    //     langs.validation_messages.required;
    // }
    // if (values.isAddBackgroundColor && !values.background_cost) {
    //   classified_conditional_errors.background_cost =
    //     langs.validation_messages.required;
    // }

    // if (Object.keys(classified_conditional_errors).length !== 0) {
    //   if (typeOfSelectedAdType === "CLASSIFIED") {
    //     throw new SubmissionError(classified_conditional_errors);
    //   }
    // }

    // if (values.width <= 0) {
    //   display_height_width_errors.width = langs.validation_messages.invalid;
    // }
    // if (values.height <= 0) {
    //   display_height_width_errors.height = langs.validation_messages.invalid;
    // }

    // if (Object.keys(display_height_width_errors).length !== 0) {
    //   if (typeOfSelectedAdType === "DISPLAY") {
    //     throw new SubmissionError(display_height_width_errors);
    //   }
    // }

    /**
     * check and set error if payment discount option is YES but
     * no payment discount option is added
     */
    if (showPaymentDiscountOptions && paymentDiscountOptions.length === 0) {
      setPaymentDiscountOptionError("Please add payment discount options");
      return;
    }
    // if (values.isAddCharacter==true && typeOfSelectedAdType === "CLASSIFIED"&& displayAdCharacter.length === 0) {
    //   setAddCharacterError("Please add character limit");
    //   return;
    // }
    // if (values.isAddLine==true && typeOfSelectedAdType === "CLASSIFIED"&& displayAdLine.length === 0) {
    //   setAddLinerError("Please add line limit");
    //   return;
    // }
   
    //console.log('>>>>>>>><<<<<<<<<<<', papertypeActualdata);
 
    // formated date to send

    let data1 = [];
  
        for(let i in selectedDayMulti){
          if(selectedDayMulti[i].value){
            data1.push(selectedDayMulti[i].value);
          }
          
        }

    let data2 = [];

    for(let i in setectedWebAdonType){
      if(setectedWebAdonType[i].value){
        data2.push(setectedWebAdonType[i].value);
      }
      
    }

    let data3 = [];

    for(let i in selectedSize){
      if(selectedSize[i].value){
        data3.push(selectedSize[i].value);
      }
      
    }
    
    let data = {  
      
      
      npc_id: user_detail.newspaper.id,
      
      // plan_name: '',
      //edition_id: values.edition_id ? values.edition_id : "0",
    //   edition_id: selectedEdition ? selectedEdition : '0',
    //   edition_name: editionsName,
    //   bleed_needed:values.bleed_needed ? values.bleed_needed: '0',
    //   isAddLine:false,
    //   isAddCharacter:false,
      ad_type: values.ad_type,
    //   approval: values.approval,
    //   bleed_width: values.bleed_width,
    //   bleed_height: values.bleed_height,
    //   deadline_day: values.deadline_day,
    //   deadline_time: values.deadline_time,
      location: data1.toString(),
      web_adon_type: data2.toString(),
      size_of_ad: data3.toString(),
      deadline_price: String(values.deadline_price).trim() !== "" ? values.deadline_price: 0,
      discount: showPaymentDiscountOptions,
      discount_option: showPaymentDiscountOptions
        ? JSON.stringify(paymentDiscountOptions)
        : JSON.stringify([]),
      active: !values.active,
      is_deleted: false,
      /** optional */
      width: 0,
      height: 0,
      m_width: 0,
      m_height: 0,
      v_width: 0,
      v_height: 0,
      additional_values: JSON.stringify([]),
      color_text_cost: 0,
      bold_cost: 0,
      image_cost: 0,
    //   border_cost: 0,
    //   background_cost: 0,
    //   paper_option:JSON.stringify([]),
    //   character_option: JSON.stringify([]),
    //   line_option:JSON.stringify([]),
    //   character_limit: 0,
    //   lines_limit:0,
    //   account_type:values.account_type
    };

   //console.warn("data", data );
   if(values.m_width == undefined && values.m_height == undefined){
     values.m_width = 0;
     values.m_height = 0;
   }

    // data when type of Ad Type is display
    // if (typeOfSelectedAdType === "DISPLAY") {
      data = {
        ...data,
        width: String(values.width).trim() !== "" ? values.width : 0,
        height: String(values.height).trim() !== "" ? values.height : 0,
        
        m_width: String(values.m_width).trim() !== "" ? values.m_width : 0,
        m_height:  String(values.m_height).trim() !== "" ? values.m_height : 0,
        additional_values: JSON.stringify(displayAdColors),
        // paper_option: JSON.stringify(papertypeActualdata)
      };
    // }

    // data when type of Ad Type is classified
    // if (typeOfSelectedAdType === "CLASSIFIED") {
      data = {
        ...data,
        color_text_cost:
          formValues.isColoredText &&
            String(values.color_text_cost).trim() !== ""
            ? parseFloat(values.color_text_cost)
            : 0,
          bold_cost:
          formValues.isBold && String(values.bold_cost).trim() !== ""
            ? parseFloat(values.bold_cost)
            : 0,    
        image_cost:
          formValues.isAddImage && String(values.image_cost).trim() !== ""
            ? parseFloat(values.image_cost)
            : 0,
        is_desktop: formValues.isDesktop == true ? true : false ,
        is_mobile: formValues.isMobile == true ? true : false,
         
      }
    //     border_cost:
    //       formValues.isAddBorder && String(values.border_cost).trim() !== ""
    //         ? parseFloat(values.border_cost)
    //         : 0,
    //     background_cost:
    //       formValues.isAddBackgroundColor &&
    //         String(values.background_cost).trim() !== ""
    //         ? parseFloat(values.background_cost)
    //         : 0,
    //     character_option: JSON.stringify(displayAdCharacter),
    //     line_option: JSON.stringify(displayAdLine),
    //     character_limit: values.character_limit,
    //     lines_limit: values.lines_limit,
    //     isAddLine:values.isAddLine? values.isAddLine: false,
    //     isAddCharacter:values.isAddCharacter? values.isAddCharacter: false,
    //   };
    // }
      //console.warn(values.v_height === undefined ? values.v_height = 0: false)
      //console.warn(values.v_height)
      //console.warn(values.v_width === undefined ? values.v_width = 0: false)
      //console.warn(values.v_width)
      //console.warn(values.insta_video_length === undefined ? values.insta_video_length = 0: false)
      //console.warn(values.insta_video_length)
      //console.warn(values.insta_posts === undefined ? values.insta_posts = 0: false)
      //console.warn(values.insta_posts)
      //console.warn(values.web_ad_type === undefined ? values.web_ad_type = "": false)
      //console.warn(values.web_ad_type)
      //console.warn(values.web_adon_type === undefined ? values.web_adon_type = "": false)
      //console.warn(values.web_adon_type)
      //console.warn(values.size_of_ad === undefined ? values.size_of_ad = "": false)
      //console.warn(values.size_of_ad)
    data = {
        ...data,
        web_ad_type:values.web_ad_type != "" ?  values.web_ad_type : "",
        v_width: String(values.v_width).trim() !=="" ? values.v_width: 0,
        v_height: String(values.v_height).trim() !== "" ?values.v_height: 0,
        insta_video_length: String(values.insta_video_length).trim() !== "" ? values.insta_video_length: 0,
        insta_posts: String(values.insta_posts).trim() !== "" ? values.insta_posts: 0,
        
        price_run_time: values.price_run_time === undefined ?  "": values.price_run_time ,
        cpm: values.cpm === undefined ? 0 :  values.cpm,
        description: values.description === undefined ?  "": values.description,
        format: values.format === undefined ? "": values.format,
        max_size: values.max_size === undefined ? "" : values.max_size

    }

    //console.warn("Data before adding: ",data)
    let total_price = 0.00;

    total_price = parseFloat(data.deadline_price)

    for(let i = 0; i < paymentDiscountOptions.length; i ++){
      if(paymentDiscountOptions[i].typeOfDiscount == "Fixed Amount"){
        if(total_price < parseFloat(paymentDiscountOptions[i].discountAmount)){
          const errors = {};
          errors.discountAmount = 'Discount can not be more than the ad price';
          toastr.error("Error", 'Discount can not be more than the ad price');
          throw new SubmissionError(errors);
        }
      }
    }
    // add new plan action
    addNewSocialMediaPlan(data, (res) => {
      if (
        res.status === STATUS_CODES.CREATED ||
        res.status === STATUS_CODES.OK
      ) {
        toastr.success("Success", MESSAGES.PLAN_CREATE_SUCCESS);
        history.replace("/ourPlans");
      }
    });
  };


  const fetchPlan = () => {
    // request data
    if(planId!=""){
      localStorage.setItem("setPlanIdTest",planId);
    }
    
    const requestData = {
      id: planId,
      npc_id: user_detail.newspaper.id,
    };

    /** get single plan using same api to get all the plans by sending single plan id */
       getPlanById(requestData, (res) => {
        
        if (res && String(res.status) === String(200)) {
         
          const plan = res.data.rows[0];
          //setSelectedAdType( JSON.parse(plan));
          // //console.warn("checking",res.data);
          // //console.warn("testing",JSON.parse(plan.deadline_price));
         
          let deadline_price = JSON.parse(plan.deadline_price);
          setDeadlinePrice(deadline_price);
          // //console.warn(deadlinePrice);

          const color_options =
            plan.color_option.trim() !== ""
              ? JSON.parse(JSON.parse(plan.color_option))
              : [];
          const character_options =
              plan.character_option.trim() !== ""
                ? JSON.parse(JSON.parse(plan.character_option))
                : [];
          const line_options =
                plan.line_option.trim() !== ""
                  ? JSON.parse(JSON.parse(plan.line_option))
                  : [];
          const discount_options =
            plan.discount_option.trim() !== "" && plan.discount
              ? JSON.parse(JSON.parse(plan.discount_option))
              : [];
          // set display Ad color options state
           setDisplayAdColors(color_options);
           setDisplayAdCharacter(character_options);
           setDisplayAdLine(line_options);
          // set payment discount options state
          setPaymentDiscountOptions(discount_options);

          setShowPaymentDiscountOptions(JSON.parse(plan.discount))
          setShowResults(JSON.parse(plan.bleed_needed));
          setDeadlineCharacter(plan.character_limit);
          setDeadlineLine(plan.lines_limit);
        } else if (res) {
          setIsRedirect(true);
        }
      });
  };

  


  /**
   * runs on mount to fetch necessary data
   */
  useEffect(() => {

    // calling the action to get price run time
    getAllPriceRunTimeCategoryList(langs.keyname.priceruntimecategory);

    // calling the action to get size of ad
    getAllSizeOfAdCategoryList(langs.keyname.sizeofadcategory)

    // calling the action to get ads placement category
    getAllAdPlacementCategoryList(langs.keyname.adplacementcategory);

    // calling the action to get ads features category
    getAllAdFeatureCategoryList(langs.keyname.adfeaturecategory);

    // calling the action to get ad types category
    getAllAdTypeCategoryList(langs.keyname.adtypecategory);

    // calling the action to get social media category
    getAllSocialMediaCategoryList(langs.keyname.socialmediacategory);

    // getfromNPCSetting("edition", user_detail.newspaper.id, (res) => {
    //   if (res.status === 200) {
    //     setEditions(res.data);
    //   }
    // });

    // getfromNPCSetting("advertisement", user_detail.newspaper.id, (res) => { });

    getAllAdType('', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
       // //console.warn('hola hola*************', allAddType);
        setAllAddType(allAddType);
        // //console.warn('hello hello',allAddType)
        // let advertisements = allAddType;
        // advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        // advertisements =
        //   Array.isArray(advertisements) &&
        //   advertisements.map((ad) => {
        //     return {
        //       id: ad.id,
        //       ad_name: ad.ad_name,
        //       ad_type: ad.ad_type,
        //       selectedImage: JSON.parse(ad.npc_advertisement.ad_urls)[0],
        //     };
        //   });
        // //console.warn('adv===',advertisements);

        // // set advertisements list state
        // setAdvertisementsTypeList(advertisements);
        // // extract Ad Type of type display if exists in list
        // const displayAd = advertisements.find((ad) => ad.ad_type === "Display");

        // // if not exists set first one
        // const selectedAd = displayAd
        //   ? displayAd.id
        //   : advertisements[0]
        //     ? advertisements[0].id
        //     : "";

        // // extract and set selected Ad Type initially of display type
        // setSelectedAdType(selectedAd);


      }
    });

    getAllPaperType('0', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.paper_type_name,
            };
          });
          setPaperTypes(advertisements);

      }
    });

    getAllAccount('0', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.account_name,
            };
          });
          setAccountType(advertisements);

      }
    });

    

    



    fetchPlan();
  }, []);


  

  /**
   * run when either of any dependency value changes
   */

  useEffect(() => {
    /**
     *  on change of selected Ad Type or change in list of Ad Type
     *  find type of selected Ad Type ad set its state and respected image
     */
    const type = getAdType();
    setTypeOfSelectedAdType(type);
    // advertisementsTypeList
    let selectedAd = allAddtype.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    if (selectedAd) {
      setSelectedAdTypeImage(selectedAd.selectedImage);
    }
  }, [selectedAdType, allAddtype]);

  useEffect(() => {
    // alert('hello');
    // alert(formValues.ad_type);
    // console.warn ('*****data>>>>>>>>>>>>>>', formValues);
    setSelectedAdType(formValues.ad_type);
  }, [formValues.ad_type]);

  /**
   * runs on change in dependency
   */
  useEffect(() => {
    if (!showPaymentDiscountOptions) {
      // setting payment option error field to null if YES is not selected
      setPaymentDiscountOptionError(null);
    }
  }, [showPaymentDiscountOptions]);

  useEffect(() => {
    if (displayAdLine.length==0) {
      // setting Add line option error field to null if YES is not selected
      setAddLinerError(null);
    }
  }, [displayAdLine]);

  useEffect(() => {
    if (displayAdCharacter.length==0) {
      // setting Add character option error field to null if YES is not selected
      setAddCharacterError(null);
    }
  }, [displayAdCharacter]);

  
  /**
   * runs on change in dependency
   */
  useEffect(() => {
    /**
     * resting cost fields to blank when respected checkbox unchecked
     */
    //  if (!formValues.isDesktop) {
    //   changeFieldValue("edit-plan", "width", "");
    //   untouchField("edit-plan", "width");
    //   changeFieldValue("edit-plan", "height", "");
    //   untouchField("edit-plan", "height");
    // }
    // if (!formValues.isMobile) {
    //   changeFieldValue("edit-plan", "m_width", "");
    //   untouchField("edit-plan", "m_width");
    //   changeFieldValue("edit-plan", "m_height", "");
    //   untouchField("edit-plan", "m_height");
    // }
    if (!formValues.isColoredText) {
      changeFieldValue("create-new-plan", "color_text_cost", "");
      untouchField("create-new-plan", "color_text_cost");
    }
    if (!formValues.isBold) {
      changeFieldValue("create-new-plan", "bold_cost", "");
      untouchField("create-new-plan", "bold_cost");
    }
    if (!formValues.isAddImage) {
      changeFieldValue("create-new-plan", "image_cost", "");
      untouchField("create-new-plan", "image_cost");
    }
    if (!formValues.isAddBorder) {
      changeFieldValue("create-new-plan", "border_cost", "");
      untouchField("create-new-plan", "border_cost");
    }
    if (!formValues.isAddBackgroundColor) {
      changeFieldValue("create-new-plan", "background_cost", "");
      untouchField("create-new-plan", "background_cost");
    }
    
  }, [
    // formValues.isDesktop,
    // formValues.isMobile,
    formValues.isColoredText,
    formValues.isBold,
    formValues.isAddImage,
    formValues.isAddBorder,
    formValues.isAddBackgroundColor,
  ]);

 
  const addpaperTypes = () => {
    if(paperTypeData !== "" && paperTypeCost !== "")
    {
      setcheck(true);
      const index = paperTypes.findIndex(obj => obj.value == parseInt(paperTypeData));
      // //console.warn(paperTypes[index]);
      if(index !== -1)
      {
      setCount(count + 1);
      let data = {id:count,label:paperTypes[index].label,cost:paperTypeCost};
      setPapertypeActualdata([...papertypeActualdata, data]);
      changeFieldValue("create-new-plan", "paper_type", "");
      changeFieldValue("create-new-plan", "paper_cost", "");
      setPaperTypeData("");
      setPaperTypeCost("");
      }
      else{
        // alert('index not found');
      }
    }
    // alert('add click');
    // //console.warn('^^^^^^^^', papertypeActualdata);
  }

  const deletePaperType = (id) => {
   
    const index = papertypeActualdata.findIndex(obj=>obj.id == id);
    // //console.warn(index);
    // //console.warn(papertypeActualdata);
    if(index !== -1)
      {
        setPapertypeActualdata(papertypeActualdata.filter(item => item.id !== id));
      }
    // //console.warn('lennnn',papertypeActualdata.length);
    if(papertypeActualdata.length === 1)
    {
      setcheck(false);
    }
  }

 

  return (
    <>
      <h4>
        <Link to="/ourPlans">
          <i className="fa fa-arrow-left mr-3" aria-hidden="true"></i>
        </Link>
        Create New Social Media Plan
      </h4>
      <Paper>
      <div className="container-fluid p-4">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                {/* <div className="form-row">
                  <div className="col-md-6">
                    <Field
                      name="plan_name"
                      component={renderText}
                      type="text"
                      validate={[required, minLengthC3, maxLengthC100]}
                      required={true}
                      label="Plan Name"
                    />
                  </div>
                  {editions && editions.length > 1 ? (
                    <div className="col-md-6">
                      <Field
                        name="edition_id"
                        component={renderSelectField}
                        label="Edition"
                        options={getEditionOptions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={editions.length > 1 ? false : true}
                        validate={required}
                        required={true}
                        onChange={(e)=>getEditionName(e.target.value)}
                      />
                    </div>
                  ) : <div className="col-md-6">
                      <Field
                        name="edition_id"
                        component={renderText}
                        type="text"
                        validate={[required]}
                        required={true}
                        label="Edition"
                      />
                    </div>}
                </div> */}

                <div
                  className={`form-row ${
                    typeOfSelectedAdType === "CLASSIFIED"
                      ? "align-items-center"
                      : ""
                    }`}
                >
                  <div className="col-md-4">
                    <Field
                      name="ad_type"
                      component={renderSelectField}
                      label="Social Media Category"
                      options={getAdTypeOptions()}
                      optionValue={"value"}
                      optionLabel={"label"}
                      validate={required}
                      required={true}
                      value={selectedAdType}
                      onChange={(e) => setSelectedAdType(e.target.value)}
                      
                      isSelect={false}
                    />
                  </div>


                  {/* Website Ad Post Type */}
                  {/* {typeOfSelectedAdType === "WEBSITE_AD" && ( */}
                      <div className="col-md-4">
                        <Field
                          name="web_ad_type"
                          component={renderSelectField}
                          label="Ad Type"
                          options={adTypeCategoryList}
                          optionValue={"id"}
                          optionLabel={"name"}
                          validate={required}
                          required={true}
                          value={selectedWebAdType}
                          onChange={(e) => setSelectedWebAdType(e.target.value)}
                          
                          isSelect={false}
                        />
                      </div>
                      <div className="form-row col-md-4 ml-1 mt-4">
                        <div className="col-md-4 p-2 pl-3">
                        <RadioButtonRP>
                            <Field
                            name="isDesktop"
                            component="input"
                            type="checkbox"
                            id="coloredText1"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Desktop
                          </label>
                          </RadioButtonRP>
                          </div>
                          <div className="col-md-4 p-2 pl-3">
                            <RadioButtonRP>
                          <Field
                            name="isMobile"
                            component="input"
                            type="checkbox"
                            id="coloredText1"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Mobile
                          </label>
                          </RadioButtonRP>
                          </div>
                      </div>
                  {/* )} */}
                  </div>
                  <div className="form-row">
                  {/* Website Ad Post size */}
                  {/* {typeOfSelectedAdType === "WEBSITE_AD" && ( */}
                      <div className="col-md-4">
                        <Field
                          name="width"
                          label="Desktop Ad Size(Pixels)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          // required={true}
                          // disabled={ formValues.isDesktop == true ? false: true }
                          placeholder="Width"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value"
                          }
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Field
                          name="height"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          // disabled={ formValues.isDesktop == true ? false: true }
                          placeholder="Height"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value"
                          }
                        />
                      </div>

                     {/* Amount of Instagram Posts */}

                    {/* {typeOfSelectedAdType === "INSTAGRAM_POST" && ( */}
                      <div className="col-md-4">
                        <Field
                        name="insta_posts"
                        label="Amount"
                        value={noOfInstaPosts}
                        component={renderNumberField}
                        validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                        ]}
                        required={true}
                        min="0"
                        step=".01"
                        helpText={
                            "Only Numeric value with no decimal places"
                        }
                        onChange={(e)=>getNoOfInstaPosts(e.target.value)}
                        />
                    </div>
                    {/* )} */} 
                </div>

                {/* ========= Mobile Ad Size ============ */}
                <div className="form-row">
                      <div className="col-md-4">
                        <Field
                          name="m_width"
                          label="Mobile Ad Size(Pixels)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          // required={true}
                          // disabled={ formValues.isMobile == true ? false: true }
                          placeholder="Width"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value"
                          }
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Field
                          name="m_height"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          // disabled={ formValues.isMobile == true ? false: true }
                          placeholder="Height"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value"
                          }
                        />
                      </div>
                </div>


                <div className="form-row">
                  {/* Insta Post Video Size */}
                  {/* {typeOfSelectedAdType === "INSTAGRAM_POST" && ( */}
                      <div className="col-md-4">
                        <Field
                          name="v_width"
                          label="Video Size(Pixels)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          required={true}
                          placeholder="Width"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value"
                          }
                        />
                      </div>
                      <div className="col-md-4 mt-2">
                        <Field
                          name="v_height"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          placeholder="Height"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value"
                          }
                        />
                      </div>

                  {/* Video Length of Instagram Posts */}

                  {/* {typeOfSelectedAdType === "INSTAGRAM_POST" && ( */}
                    <div className="col-md-4">
                        <Field
                        name="insta_video_length"
                        label="Video Length"
                        value={instaVidLength}
                        component={renderNumberField}
                        validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                        ]}
                        required={true}
                        min="0"
                        step=".01"
                        helpText={
                            "Only Numeric value with no decimal places"
                        }
                        onChange={(e)=>getVideoLength(e.target.value)}
                        />
                    </div>

                      
                </div>
                <div className="form-row">
                  {/* Description */}
                    <div className="col-md-4">
                    <Field
                        name="description"
                        label="Description"
                        value={description}
                        component={renderTextInputField}
                        onChange={(e)=>getDescription(e.target.value)}
                        />
                    </div>
                    
                     {/* Format */}
                    <div className="col-md-4">
                      <Field
                          name="format"
                          label="Format"
                          value={format}
                          component={renderTextInputField}
                          onChange={(e)=>getFormat(e.target.value)}
                          />
                    </div>
                     {/* Maximum size */}
                    <div className="col-md-4">
                    <Field
                        name="max_size"
                        label="Maximum Size"
                        value={max_size}
                        component={renderNumberField}
                        onChange={(e)=>getMaxSize(e.target.value)}
                        />
                    </div>
                </div>

                <div className="form-row">
                  {/* Ad Placement */}
                  <div className="col-md-4">
                    <Form.Label>
                      Ad Placement
                    </Form.Label>
                    <Multiselect 
                      name="location"
                      displayValue="label"
                      isSelect={false}
                      options={getLocation()}
                     
                      placeholder="Ad Placement"
                      // onChange={this.onChangeTypeHandeler}
                      showCheckbox={true} // Options to display in the dropdown
                   
                    // selectedValues={this.state.preselected.map( pre => {return pre})} // Preselected value to persist in dropdown
                    onSelect={(selectedValues)=>{
                    setSelectedDayMulti(selectedValues)
                    }} // Function will trigger on select event
                  
                  
                    />
                  </div>
                 
                  <div className="col-md-4">
                    <Form.Label>
                      Ad Features
                    </Form.Label>
                    <Multiselect 
                          name="web_adon_type"
                          displayValue="label"
                          isSelect={false}
                          options={getWebAdon()}
                        
                          placeholder="Ad Features"
                          // onChange={this.onChangeTypeHandeler}
                          showCheckbox={true} // Options to display in the dropdown
                      
                        // selectedValues={this.state.preselected.map( pre => {return pre})} // Preselected value to persist in dropdown
                        onSelect={(selectedValues)=>{
                        setSelectedWebAdonType(selectedValues)
                      }} // Function will trigger on select event
                  
                  
                      />
                  </div>
                  <div className="col-md-4">
                    <Form.Label>
                     Size
                    </Form.Label>
                    <Multiselect 
                          name="size_of_ad"
                          displayValue="label"
                          isSelect={false}
                          options={getSize()}
                        
                          placeholder="Size"
                          // onChange={this.onChangeTypeHandeler}
                          showCheckbox={true} // Options to display in the dropdown
                      
                        // selectedValues={this.state.preselected.map( pre => {return pre})} // Preselected value to persist in dropdown
                        onSelect={(selectedValues)=>{
                        setSelectedSize(selectedValues)
                      }} // Function will trigger on select event
                  
                  
                      />
                  </div>
                </div>
                <br/><br />

                {/* {typeOfSelectedAdType === "DISPLAY" && (
                  <>
                    <AddColorForm
                      onAddColor={(c) => handleAddDisplayAdColor(c)}
                    />

                    {displayAdColors.length > 0 && (
                      <MakeTable column={["#", "Color", "Paper Type","Cost", "Action"]}>
                        {Array.isArray(displayAdColors) &&
                          displayAdColors.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{disAdCol.color}</td>
                              <td>{disAdCol.papertype}</td>
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdColor(disAdCol)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )} */}

                {/* Added Option For "Bleed needed?"*/}

              
               

                   
                

                {/* new----- */}
                {/* {typeOfSelectedAdType === "DISPLAY"  && (
                  <div className="form-row align-items-center">
                 <div className="col-md-3" style={{ height: 105 }}>
                 <div className="form-group">
                    <Field
                        name="paper_type"
                        component={renderSelectField}
                        label="Paper Type"
                        options={paperTypes}
                        optionValue={"value"}
                        optionLabel={"label"}
                        isSelect={false}
                        Value={paperTypeData}
                        onChange={(e)=>{setPaperTypeData(e.target.value)}}
                        />
                       </div>
                        </div>
                        <div className="col-md-3 mt-3" style={{ height: 105 }}>
                       <div className="form-group">
                    <Field
                        name="paper_cost"
                        component={renderNumberField}
                        validate={[number, greaterThanZeroWithTwoDecimalPlace]}
                        placeholder="Cost"
                        min="0"
                        step=".01"
                        helpText={'Only Numeric value with up to 2 decimal places'}
                        Value={paperTypeCost}
                        onChange={(e)=>{setPaperTypeCost(e.target.value)}}
                    />
                    </div>
                     </div>
                     <div className="col-md-3 mt-4" style={{ height: 105 }}>
                   <div className="form-group mt-4">
                    <button
                        type="button"
                        onClick={()=>{addpaperTypes()}}
                        className="btn btn-sm active btn-secondary rounded-0">
                        Add
                    </button>
                </div>
            </div>
        </div>

                )}
        {typeOfSelectedAdType === "DISPLAY" && check && (
                      <MakeTable column={["#", "Paper Type", "Cost","Action"]}>
                        {Array.isArray(papertypeActualdata) &&
                          papertypeActualdata.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{disAdCol.label}</td>
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    deletePaperType(disAdCol.id)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )} */}

                {/* Website Ad Post Adons Type */}
                {/* {typeOfSelectedAdType === "WEBSITE_AD" && ( */}
                    <div className="form-row">



                      {/* Website Ad Price Post */}

                      {/* {typeOfSelectedAdType === "WEBSITE_AD" && ( */}
                      <div className="col-md-2">
                        <Field
                          name="deadline_price"
                          label="Price"
                          value={deadlinePrice}
                          component={renderNumberField}
                          // validate={[
                          //   required,
                          //   number,
                          //   greaterThanZeroWithTwoDecimalPlace,
                          // ]}
                          required={true}
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                          onChange={(e)=>getdeadlineprice(e.target.value)}
                        />
                      </div>
                        

                      {/* Price Run Time */}

                      <div className="col-md-2">
                        <Field
                          name="price_run_time"
                          component={renderSelectField}
                          label="Price Run Time"
                          options={priceRunTimeCategoryList}
                          optionValue={"id"}
                          optionLabel={"name"}
                        //   validate={required}
                        //   required={true}
                          value={selectedRunTime}
                          onChange={(e) => setSelectedRunTime(e.target.value)}
                          
                          isSelect={false}
                        />
                      </div>

                      {/* CPM */}
                      <div className="col-md-2">
                        <Field
                          name="cpm"
                          label="CPM"
                          value={cpm}
                          component={renderNumberField}
                          onChange={(e)=>getCpm(e.target.value)}
                        />
                      </div>

                      <>
                    <AddValueForm
                      onAddColor={(c) => handleAddDisplayAdColor(c)}
                    />

                    {displayAdColors.length > 0 && (
                      <MakeTable column={[ "Value", "Cost", "Action"]}>
                        {Array.isArray(displayAdColors) &&
                          displayAdColors.map((disAdCol, i) => (
                            <tr key={i}>
                              {/* <td>{i + 1}</td> */}
                              <td>{disAdCol.value}</td>
                              {/* <td>{disAdCol.papertype}</td> */}
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdColor(disAdCol)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                    </div>
                  {/* )} */}

                  {typeOfSelectedAdType === "EMAIL_MARKETING" && (
                  <>
                   <div className="form-row">
                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isColoredText"
                            component="input"
                            type="checkbox"
                            id="coloredText"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Additional Link?
                          </label>
                        </div>
                        {formValues.isColoredText && (
                          <Field
                            name="color_text_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isBold"
                            component="input"
                            type="checkbox"
                            id="bold"
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="bold">
                            Add Embed Video?
                          </label>
                        </div>
                        {formValues.isBold && (
                          <Field
                            name="bold_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddImage"
                            component="input"
                            type="checkbox"
                            id="addImage"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addImage"
                          >
                            Add Image?
                          </label>
                        </div>
                        {formValues.isAddImage && (
                          <Field
                            name="image_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>
                      </div>
                  </>
                )}

{ /* -----------------------------Bleed Section --------------------------------------------*/}

  {/* <div class="custom-code">
                <div className="form-row align-items-center mt-4">
                  <div className="form-group col-md-3">
                    <label htmlFor="color">Bleed needed?</label>
                  </div>
                  <div className="form-group col-md-3">
                    <div className="radio-inline">
                    <CheckboxRP label="Yes">
                      <Field
                        name="bleed_needed"
                       onChange={()=>{onClickNew()}}
                        component="input"
                        type="checkbox"
                        id="requiresApproval"
                        className="mr-1"
                       
                      />
                    </CheckboxRP>
                     
                    </div>
                  </div>
                </div>

                {showResults && (

                
                <div className="form-row align-items-center">
                 <div className="col-md-3" style={{ height: 105 }}>
                    <div className="form-group">
                     
                      <Field
                          name="bleed_width"
                          label="Bleed width (Inches)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          required={true}
                          placeholder="Bleed Width"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                        />
                          </div>
                        </div>
                        <div className="col-md-3" style={{ height: 105 }}>
                       <div className="form-group">
                       <Field
                          name="bleed_height"
                          label="Bleed height (Inches)"
                          component={renderNumberField}
                          validate={[
                            number,
                            greaterThanZeroWithTwoDecimalPlace,
                          ]}
                          required={true}
                          placeholder="Bleed Height"
                          min="0"
                          step=".01"
                          helpText={
                            "Only Numeric value with up to 2 decimal places"
                          }
                        />
                    </div>
                     </div>
                     <div className="col-md-3 mt-4" style={{ height: 105 }}>
                   <div className="form-group mt-4">
                   
                </div>
            </div>
          </div>
                )}
          </div> */}

{ /* -----------------------------Discount Section --------------------------------------------*/}
                
                    <div className="form-row align-items-center mt-4">
                        <div className="form-group col-md-3">
                            <label htmlFor="color">Payment Discount Option</label>
                        </div>
                        <div className="form-group col-md-3">
                            <div className="radio-inline">
                                <RadioButtonRP label="Yes">
                                <input
                                    checked={showPaymentDiscountOptions === true}
                                    onChange={() => setShowPaymentDiscountOptions(true)}
                                    className="form-check-input"
                                    type="radio"
                                    name="paymentDiscountOption"
                                    id="paymentDiscountOptionYes"
                                    value="yes"
                                />
                                </RadioButtonRP>
                                <RadioButtonRP label="No">
                                <input
                                    checked={showPaymentDiscountOptions === false}
                                    onChange={() => setShowPaymentDiscountOptions(false)}
                                    className="form-check-input"
                                    type="radio"
                                    name="paymentDiscountOption"
                                    id="paymentDiscountOptionNo"
                                    value="no"
                                />
                                </RadioButtonRP>
                            </div>
                        </div>
                  </div>

                    {/* INSTAGRAM POSTS */}
                    {/* {typeOfSelectedAdType === "INSTAGRAM_POST" && (
                    <div className="form-row align-items-center mt-4">
                    <div className="form-group col-md-3">
                      <label htmlFor="color">Payment Discount Option</label>
                    </div>
                    <div className="form-group col-md-3">
                      <div className="radio-inline">
                        <RadioButtonRP label="Yes">
                          <input
                            checked={showPaymentDiscountOptions === true}
                            onChange={() => setShowPaymentDiscountOptions(true)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionYes"
                            value="yes"
                          />
                        </RadioButtonRP>
                        <RadioButtonRP label="No">
                          <input
                            checked={showPaymentDiscountOptions === false}
                            onChange={() => setShowPaymentDiscountOptions(false)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionNo"
                            value="no"
                          />
                        </RadioButtonRP>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* INSTGRAM STORIES */}
                {/* {typeOfSelectedAdType === "INSTAGRAM_STORIES" && (
                    <div className="form-row align-items-center mt-4">
                    <div className="form-group col-md-3">
                      <label htmlFor="color">Payment Discount Option</label>
                    </div>
                    <div className="form-group col-md-3">
                      <div className="radio-inline">
                        <RadioButtonRP label="Yes">
                          <input
                            checked={showPaymentDiscountOptions === true}
                            onChange={() => setShowPaymentDiscountOptions(true)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionYes"
                            value="yes"
                          />
                        </RadioButtonRP>
                        <RadioButtonRP label="No">
                          <input
                            checked={showPaymentDiscountOptions === false}
                            onChange={() => setShowPaymentDiscountOptions(false)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionNo"
                            value="no"
                          />
                        </RadioButtonRP>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* Watsapp Post */}
                {/* {typeOfSelectedAdType === "WHATSAPP_POST" && (
                    <div className="form-row align-items-center mt-4">
                    <div className="form-group col-md-3">
                      <label htmlFor="color">Payment Discount Option</label>
                    </div>
                    <div className="form-group col-md-3">
                      <div className="radio-inline">
                        <RadioButtonRP label="Yes">
                          <input
                            checked={showPaymentDiscountOptions === true}
                            onChange={() => setShowPaymentDiscountOptions(true)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionYes"
                            value="yes"
                          />
                        </RadioButtonRP>
                        <RadioButtonRP label="No">
                          <input
                            checked={showPaymentDiscountOptions === false}
                            onChange={() => setShowPaymentDiscountOptions(false)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionNo"
                            value="no"
                          />
                        </RadioButtonRP>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* Whatsapp Stories */}
                {/* {typeOfSelectedAdType === "WHATSAPP_STORIES" && (
                    <div className="form-row align-items-center mt-4">
                    <div className="form-group col-md-3">
                      <label htmlFor="color">Payment Discount Option</label>
                    </div>
                    <div className="form-group col-md-3">
                      <div className="radio-inline">
                        <RadioButtonRP label="Yes">
                          <input
                            checked={showPaymentDiscountOptions === true}
                            onChange={() => setShowPaymentDiscountOptions(true)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionYes"
                            value="yes"
                          />
                        </RadioButtonRP>
                        <RadioButtonRP label="No">
                          <input
                            checked={showPaymentDiscountOptions === false}
                            onChange={() => setShowPaymentDiscountOptions(false)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionNo"
                            value="no"
                          />
                        </RadioButtonRP>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* Email MarketinG */}
                {/* {typeOfSelectedAdType === "EMAIL_MARKETING" && (
                    <div className="form-row align-items-center mt-4">
                    <div className="form-group col-md-3">
                      <label htmlFor="color">Payment Discount Option</label>
                    </div>
                    <div className="form-group col-md-3">
                      <div className="radio-inline">
                        <RadioButtonRP label="Yes">
                          <input
                            checked={showPaymentDiscountOptions === true}
                            onChange={() => setShowPaymentDiscountOptions(true)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionYes"
                            value="yes"
                          />
                        </RadioButtonRP>
                        <RadioButtonRP label="No">
                          <input
                            checked={showPaymentDiscountOptions === false}
                            onChange={() => setShowPaymentDiscountOptions(false)}
                            className="form-check-input"
                            type="radio"
                            name="paymentDiscountOption"
                            id="paymentDiscountOptionNo"
                            value="no"
                          />
                        </RadioButtonRP>
                      </div>
                    </div>
                  </div>
                )} */}
                

                {showPaymentDiscountOptions && (
                  <>
                    <AddPaymentDiscountOptionForm
                      deadlinePrice={deadlinePrice}
                      onAddPayDiscountOption={(v) =>
                        handleAddDiscountOptions(v)
                      }
                      paymentDiscountOptions={paymentDiscountOptions}
                    />
                    {paymentDiscountOptionError && (
                      <div className="error text-help mb-2">
                        {paymentDiscountOptionError}
                      </div>
                    )}

                    {paymentDiscountOptions.length > 0 && (
                      <MakeTable
                        column={[
                          "#",
                          "Repetition",
                          "Within",
                          "Type of Discount",
                          "Amount",
                          "Action",
                        ]}
                      >
                        {paymentDiscountOptions.length &&
                          paymentDiscountOptions.map((payDisAmt, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{payDisAmt.runs}</td>
                              <td>{payDisAmt.within}</td>
                              <td>{payDisAmt.typeOfDiscount}</td>
                              <td>
                                {payDisAmt.discountAmount}
                                {payDisAmt.typeOfDiscount === "Percentage"
                                  ? "%"
                                  : ""}
                              </td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDiscountOptions(payDisAmt)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )}

                <div className="form-group">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item className="mr-4">
                      Status
                    </Grid>
                    <Grid item className="text-success">
                      Active
                    </Grid>
                    <Grid item>
                      <Field
                        name="active"
                        component={({ input, label }) => (
                          <Switch
                            checked={input.value ? true : false}
                            onChange={input.onChange}
                            color="secondary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className="text-danger">
                      Inactive
                    </Grid>
                  </Grid>
                </div>
                
                <ButtonRP
                  type="submit"
                  disabled={submitting}
                  className="float-right"
                  rounded={false}
                  text="Create"
                />
              </form>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

/** redux form value selector */
const formValSelector = formValueSelector("create-new-plan");

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 */
const mapStateToProps = (state) => {
  const { auth, npcDuck, commonDuck } = state;
  const { npcSettingList, npcPlan } = npcDuck;
  const {  priceRunTimeCategoryList, sizeOfAdCategoryList, adPlacementCategoryList, adFeatureCategoryList, adTypeCategoryList, socialMediaCategoryList } = commonDuck;

  const { color, edition, advertisement } = npcSettingList || {};

  const advertisements = advertisement
    ? advertisement.rows[0].advertisements.filter(
      (ad) => ad.is_deleted === false
    )
    : [];

  /** find the id of first display type advertisement and set it's id to selectedAdType */
  let selectedAdType = "";

  if (advertisements.length > 0) {
    const ad = advertisements.find((ad) => ad.ad_type === "Display");
    if (ad) {
      selectedAdType = ad.id;
    }
  }

  /** If no display type advertisement found then set first one */
  if (selectedAdType === "" && advertisements.length > 0) {
    selectedAdType = advertisements[0].id;
  }

  // fetching form fields states
  const formValues = formValSelector(
    state,
    "discount_option",
    "color_option",
    "discount",
    "ad_type",
    "color_text_cost",
    "bold_cost",
    "image_cost",
    "border_cost",
    "background_cost",
    "isColoredText",
    "isDesktop",
    "isMobile",
    "isBold",
    "isAddBorder",
    "isAddImage",
    "isAddBackgroundColor",
    "character_option",
    "line_option",
    "isAddCharacter",
    "isAddLine",
    "character_limit",
    "account_type"
  );

  /*const initialValues = {
    plan_name: "",
    edition_id: edition && edition.length === 1 ? edition[0].name : "",
    ad_type: selectedAdType,
    approval: false,
    deadline_day: "",
    deadline_time: "",
    deadline_price: "",
    discount: false,
    width: "",
    height: "",
    color_text_cost: "",
    bold_cost: "",
    image_cost: "",
    border_cost: "",
    background_cost: "",
    // active: false /** Initially setting false and send reverse of active */
  // };
  //   active: false,
  // }; */

  let initialValues;

  // //console.warn("Planid", localStorage.getItem("setPlanIdTest"));
  if(localStorage.getItem("setPlanIdTest") !="undefined"){
       initialValues = {
        ad_type: "",
        edition_id: edition && edition.length === 1 ? edition[0].name : "",
        
      };

      // set initial values if there is a plan
      // //console.warn('npcPlan: ', npcPlan);
      if (Object.keys(npcPlan).length > 0) {
        initialValues = {
          ...npcPlan,
          // ad_type: isAdTypeNotDeleted ? npcPlan.ad_type : "",
          //: npcPlan.ad_type,
          //ad_type: "",
          ad_type: npcPlan.ad_type,
          active:  !npcPlan.active,
          discount:npcPlan.discount,
          width: "",
          height: "",
          isColoredText: npcPlan.color_text_cost || false,
          isBold: npcPlan.bold_cost || false,
          isAddBorder: npcPlan.border_cost || false,
          isAddImage: npcPlan.image_cost || false,
          isAddBackgroundColor: npcPlan.background_cost || false,
          edition_id: edition && edition.length === 1 ? edition[0].name : npcPlan.edition_id,
          paper_type:"",
          paper_cost:"",
          bleed_width:npcPlan.bleed_width|| false,
          bleed_height:npcPlan.bleed_height|| false,
          bleed_needed:npcPlan.bleed_needed,
          character_limit: npcPlan.character_limit,
          lines_limit: npcPlan.lines_limit,
          isAddCharacter: npcPlan.isAddCharacter,
          isAddLine: npcPlan.isAddLine,
          account_type:npcPlan.account_type,
        };
      }
  }else{

    initialValues = {
      plan_name: "",
      edition_id: edition && edition.length === 1 ? edition[0].name : "",
      edition_name: edition && edition.length === 1 ? edition[0].name : "",
      ad_type:"",
      approval: false,
      deadline_day: "",
      deadline_time: "",
      deadline_price: 0,
      discount: false,
      width: "",
      height: "",
      color_text_cost: "",
      bold_cost: "",
      image_cost: "",
      border_cost: "",
      background_cost: "",
      active: false,
      paper_type:"",
      paper_cost:"",
      bleed_width:"",
      bleed_height:"",
      bleed_needed:"",
      character_limit: 0,
      lines_limit:0,
      isAddCharacter:false,
      isAddLine:false,
      account_type:"",
    };
    
  }  

  return {
    loggedInUser: auth.user,
    initialValues,
    colorList: color ? color : [],
    editionList: edition ? edition : [],
    advertisementList: advertisements,
    formValues,
    priceRunTimeCategoryList: priceRunTimeCategoryList  ? priceRunTimeCategoryList : [],
    sizeOfAdCategoryList: sizeOfAdCategoryList  ? sizeOfAdCategoryList : [],
    adPlacementCategoryList: adPlacementCategoryList  ? adPlacementCategoryList : [],
    adFeatureCategoryList: adFeatureCategoryList  ? adFeatureCategoryList : [],
    adTypeCategoryList: adTypeCategoryList  ? adTypeCategoryList : [],
    socialMediaCategoryList: socialMediaCategoryList  ? socialMediaCategoryList : [],
    
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
    ...commonDuck.actions,
    changeFieldValue,
    untouchField,
  })(
    reduxForm({
      form: "create-new-plan", // a unique identifier for this form
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(SocialMediaPlan)
  )
);

