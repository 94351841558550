import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useRef } from "react";
import FirstImage from "../../../../../_metronic/_assets/media/images/landing-page/review-1-mobile.png";
import SecondImage from "../../../../../_metronic/_assets/media/images/landing-page/review-2-mobile.png";
import ThirdImage from "../../../../../_metronic/_assets/media/images/landing-page/review-3-mobile.png";
import FourthImage from "../../../../../_metronic/_assets/media/images/landing-page/review-4-mobile.png";
import FifthImage from "../../../../../_metronic/_assets/media/images/landing-page/review-5-mobile.png";
import SixImage from "../../../../../_metronic/_assets/media/images/landing-page/review-6-mobile.png";

const MobileEffeciency = () => {
  return (
    <>
      <div className="mobile_effeciency">
        <div className="heading">
          <h2>Maximize efficiency, client satisfaction, and sales</h2>
        </div>
        {ProposalBuilder()}
        {ClientApproval()}
        {CampaignScheduler()}
        {GroupReserv()}
        {SalesReport()}
        {Notification()}
      </div>
    </>
  );
};

export default MobileEffeciency;

const ProposalBuilder = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);

  React.useLayoutEffect(() => {
    let Elm = sectionRef.current;

    let trigger = ScrollTrigger.create({
      trigger: Elm,
      markers: false,
      pin: true,
      pinSpacing: false,
      // start: "top+=80 top",
      // end: "bottom-=100 center", viewport fixings property
    });
    return () => {
      if (trigger) {
        trigger.kill();
      }
    };
  }, []);
  return (
    <>
      <div className="firstEffeciency bg-white" ref={sectionRef}>
        <img src={FirstImage} alt="" />
      </div>
    </>
  );
};

const ClientApproval = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);

  React.useLayoutEffect(() => {
    let Elm = sectionRef.current;

    let trigger = ScrollTrigger.create({
      trigger: Elm,
      markers: false,
      pin: true,
      scrub: true,
      pinSpacing: false,
      // start: "top+=80 top",
      // end: "bottom-=100 center", viewport fixings property
    });
    return () => {
      if (trigger) {
        trigger.kill();
      }
    };
  }, []);
  return (
    <>
      <div className="firstEffeciency bg-white" ref={sectionRef}>
        <figure>
          <img src={SecondImage} alt="" />
        </figure>
      </div>
    </>
  );
};

const CampaignScheduler = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);

  React.useLayoutEffect(() => {
    let Elm = sectionRef.current;

    let trigger = ScrollTrigger.create({
      trigger: Elm,
      markers: false,
      pin: true,
      scrub: true,
      pinSpacing: false,
      // start: "top+=80 top",
      // end: "bottom-=100 center", viewport fixings property
    });
    return () => {
      if (trigger) {
        trigger.kill();
      }
    };
  }, []);
  return (
    <>
      <div className="firstEffeciency bg-white" ref={sectionRef}>
        <img src={ThirdImage} alt="" />
      </div>
    </>
  );
};

const GroupReserv = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);

  React.useLayoutEffect(() => {
    let Elm = sectionRef.current;

    let trigger = ScrollTrigger.create({
      trigger: Elm,
      markers: false,
      pin: true,
      scrub: true,
      pinSpacing: false,
      // start: "top+=80 top",
      // end: "bottom-=100 center", viewport fixings property
    });
    return () => {
      if (trigger) {
        trigger.kill();
      }
    };
  }, []);
  return (
    <>
      <div className="firstEffeciency bg-white" ref={sectionRef}>
        <img src={FourthImage} alt="" />
      </div>
    </>
  );
};

const SalesReport = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);

  React.useLayoutEffect(() => {
    let Elm = sectionRef.current;

    let trigger = ScrollTrigger.create({
      trigger: Elm,
      markers: false,
      pin: true,
      scrub: true,
      pinSpacing: false,
      start: "top+=80 top",
      // end: "bottom-=100 center", viewport fixings property
    });
    return () => {
      if (trigger) {
        trigger.kill();
      }
    };
  }, []);
  return (
    <>
      <div className="firstEffeciency bg-white" ref={sectionRef}>
        <img src={FifthImage} alt="" />
      </div>
    </>
  );
};

const Notification = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);

  React.useLayoutEffect(() => {
    let Elm = sectionRef.current;

    ScrollTrigger.create({
      trigger: Elm,
      markers: false,
      pin: true,
      scrub: true,
      pinSpacing: false,
      // start: "top+=80 top",
      end: "bottom-=100 center",
    });
    // return () => {
    //   if (trigger) {
    //     trigger.kill();
    //   }
    // };
  }, []);
  return (
    <>
      <div className="firstEffeciency bg-white" ref={sectionRef}>
        <img src={SixImage} alt="" />
      </div>
    </>
  );
};
