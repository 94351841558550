import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

export default class EmailCredentials extends Component {
    render() {
        const { data } = this.props;
        return (
            <Portlet fluidHeight={true} style={{ height: "auto" }}>
                <PortletBody>
                    <div
                        style={{
                            borderBottom: "1px solid #ccc"
                        }}
                        className="row payoutHeader"
                    >
                        <h4 className="col-md-8">Email Settings</h4>
                        <Link to="/UpdateEmailsettings" className="col-md-4">
                            <h6 className="text-md-right">Update Email Settings &#62; </h6>
                        </Link>
                    </div>
                    <div
                        className="row ml-3"
                        style={{
                            marginTop: 25,
                        }}
                    >
                        <i
                            style={{ fontSize: 40 }}
                            className="fa fa-university mr-4"
                            aria-hidden="true"
                        ></i>
                        {data !== undefined &&
                            data.SMTP_SERVICE !== "" ? (
                            <div
                                className="text-break"
                                style={{ marginTop: 4 }}
                            >
                                <h6>{"SMTP USER EMAIL:  "}{data.SMTP_USER_EMAIL}</h6>
                                <h6>{"SMTP PASSWORD:  "}{data.SMTP_PASSWORD}</h6>
                                <h6>{"SMTP HOST:  "}{data.SMTP_HOST}</h6>
                                <h6>{"SMTP PORT:  "}{data.SMTP_PORT}</h6>
                                <h6>{"SMTP SERVICE:  "}{data.SMTP_SERVICE}</h6>
                            </div>
                        ) : (
                            <h6 style={{ margin: 10 }}>No Email record found</h6>
                        )}
                    </div>
                </PortletBody>
            </Portlet>
        )
    }
}
