import { Field, reduxForm } from 'redux-form'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { renderTextInputField } from '../../../../common/layout/FormInput';
import * as NPCDuck from '../../../../store/ducks/npc.duck'
import { MakeTable } from '../../../../config/utils';
import { useParams } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { MESSAGES } from '../../../../config/message';

const EmailBar = ({ handleSubmit, addSecondaryEmails, getSecondaryEmails, deleteSecondaryEmailById, reset }) => {

    const [color, setDisplayAdColors] = useState([])
    const [getEmail, setEmail] = useState([])
    const [count, setCount] = useState(0);
    var allEmails = []

    const { id } = useParams()

    const requestGetData = {
        npc_id: id,
        is_deleted: 0,
        approved: 1
    }

    useEffect(() => {
        getSecondaryEmails(requestGetData, (res) => {
            allEmails = res.data
            setEmail( allEmails )
            setCount(getEmail.length);
        })
    }, [count])



    const deleteEmail = value => {
        deleteSecondaryEmailById(value, (res) => {
        })
    }

      /**
     * @method handleOnSubmit
     * @description handle on submit form
     * @param {*} values 
     * @param {*} actions 
     */
    const handleOnSubmit = (values) => {
        var c = 0;
        const requestData = {
            npc_id: id,
            email: values.email,
            active: true,
            is_deleted: false,
            created_by: "",
            approved: true
        }
        for(let i = 0; i< getEmail.length; i++){
            if(getEmail[i].email == requestData.email){
                c = 1;
            }
        }
        if(c == 0){
            addSecondaryEmails(requestData, (res) => {
                toastr.success("Success", MESSAGES.SECONDARY_EMAIL_SUCCESS);
                setCount(count + 1);
            })
        }else{
            c = 0;
            toastr.error("Failure", MESSAGES.SECONDARY_EMAIL_FAIL);
        }
       
        setDisplayAdColors([...color, values.email])
        reset();
    }

    return (
        <div>
            <div className="col-md-12">
            <>
            <div className="form-row align-items-center">
                <div className="col-md-3" style={{ height: 105 }}>
                    <div className="form-group">
                        <Field
                            name="email"
                            component={renderTextInputField}
                            label="Communication Email"
                        />
                    </div>
                </div>
                <div className="col-md-3 mt-4" style={{ height: 105 }}>
                    <div className="form-group mt-4">
                        <button
                            type="button"
                            onClick={handleSubmit(handleOnSubmit)}
                            className="btn btn-sm active btn-secondary rounded-0">
                            Add
                        </button>
                    </div>
                </div>
            </div>
            {/* {allEmails} */}
            {getEmail.length > 0 ? (
                <MakeTable column={["#", "Email", "Action"]}>
                {Array.isArray(getEmail) &&
                    getEmail.map((disAdCol, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{disAdCol.email}</td>
                        <td>
                        <span
                            className="btn btn-default btn-sm"
                            onClick={() =>
                            deleteEmail(disAdCol.id)
                            }
                        >
                            Delete
                        </span>
                        </td>
                    </tr>
                    ))}
                </MakeTable>
            ): ""}
            
        </>
        </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    secondaryEmails: Array.isArray(state.npcDuck.secondaryEmails)
    ? state.npcDuck.secondaryEmails
    : [],

    initialValues: {
        email: ''
    }
})

export default connect(
    mapStateToProps,
    {
        ...NPCDuck.actions
    }
)(reduxForm({
    form: 'addColorForm',
})(EmailBar))
