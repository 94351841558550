import React, { Component } from "react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import RevenueChart from "../RevenueLineChart";
import { MESSAGES } from "../../../../config/message";
import CountFigureBox from "../../../../common/CountFigureBox";

export default class Revenue extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openModal: false,
    };
  }

  /**
   * @method renderCard
   * @description Renders revenue data
   */
  renderCard = (lable, value) => {
    return (
      <CountFigureBox isAutoHeight={true}>
        <h5 className="heading">{lable}</h5>
        {value ? (
          <h6 className="heading">{`$ ${value}`}</h6>
        ) : (
          <h6 className="heading">$0</h6>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method renderNoRecordFound
   * @description Renders No record found card
   */
  renderNoRecordFound = () => {
    return (
      <CountFigureBox isAutoHeight={true}>
        <h4 className="heading">{MESSAGES.NO_RECORD_FOUND}</h4>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { iscreadit, npcModule1 } = this.props;
    return (
      <Portlet fluidHeight={true}>
        <PortletBody>
          <div>
            {iscreadit === undefined && (
              <div className="row">
                <div className="col-md-4">
                  {npcModule1
                    ? this.renderCard(
                        "Total Sales Amount",
                        npcModule1.revenue[0].total_revenue
                      )
                    : this.renderNoRecordFound()}
                  {npcModule1
                    ? this.renderCard(
                        "Total Payout",
                        npcModule1.revenue[0].total_payout
                      )
                    : this.renderNoRecordFound()}
                  {npcModule1
                    ? this.renderCard(
                        "Total Revenue Earned",
                        npcModule1.highestRevenue.total_earning
                      )
                    : this.renderNoRecordFound()}
                  {npcModule1
                    ? this.renderCard(
                        "Highest Revenue Earned",
                        npcModule1.highestRevenue.total_earning
                      )
                    : this.renderNoRecordFound()}
                </div>
                <div className="col-md-8 float-right float-top">
                  <RevenueChart />
                </div>
              </div>
            )}
          </div>
        </PortletBody>
      </Portlet>
    );
  }
}
