import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import { Container, Row, Modal, ModalHeader, ModalBody, Col, Table } from 'reactstrap'
import { injectIntl } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import * as customerAccessDuck from '../../../../store/ducks/npc/CustomerAccess.duck'
import { MESSAGES } from '../../../../config/message'
import {
    renderText1, renderNumberInputField
} from '../../../../common/layout/FormInput'
import {
    required,
    PhoneNumber,
    minLength5,
    maxLength100,
    maxLengthC100,
    minLengthC2,
    validateEmails,
    normalizePhone
} from '../../../../config/validation';
import { STATUS_CODES } from '../../../../common/StatusCode'
import { phoneNumberFormateOnChange } from "../../../../common";

class RequestCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: true,
            loading: false,
            tempArray: [],
            customerArray: [],
            isEditFalg: false,
            indexPosition: '',
            show: false, number: ''
        }
    }

    /**
    * @method componentWillMount
    * @description called before mounting the component
    */
    componentWillMount() {
        this.setState({ flag: !this.state.flag })
    }

    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleModel = () => {
        this.setState({ customerArray: [], customer_name: '', email: '', phone_number: '' })
        this.props.reset();
        this.props.onCancel();
    }

    /**
       * Submit the login form
       * @param values
       * @desc Calling a login actions && redirecting page based on response and set session Items 
       */
    onSubmit(values) {
        const { loggedInUser, data } = this.props;
        const { customerArray } = this.state;
        let requestData = []
        if (customerArray.length > 0) {
            requestData = {
                npc_id: loggedInUser.user_detail.newspaper.id,
                npc_name: loggedInUser.user_detail.newspaper.newspaper_name,
                customers: customerArray
            };
        } else if (data && data.length > 0 && data.some(person => person.customer_email === values.email)) {
            toastr.error(`${MESSAGES.REQUEST_ALREADY_ADDED} ${values.email}`)
            return true;
        }
        else {
            requestData = {
                npc_id: loggedInUser.user_detail.newspaper.id,
                npc_name: loggedInUser.user_detail.newspaper.newspaper_name,
                customers: [{
                    customer_name: values.customer_name,
                    email: values.email,
                    phone_number: phoneNumberFormateOnChange(values.phone_number)
                }]
            };
        }
        this.setState({isSubmitted: false})
        this.props.addCustomer(requestData, res => {
            if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
                toastr.success(MESSAGES.CUSTOMER_ACCESS_REQUEST)
                this.setState({ customerArray: [], customer_name: '', email: '', phone_number: '', isSubmitted: false })
                this.props.resetList()
                this.toggleModel()

            } else {
                this.setState({ customerArray: [], customer_name: '', email: '', phone_number: '', isSubmitted: true })
                this.toggleModel()
            }
        })
    }

    /**
    * @method onChange
    * @description mobile number formatting
    */
    onChange = e => {
        //Filter only numbers from the input
        let number = phoneNumberFormateOnChange(e.target.value);
        this.setState({
            phone_number: number,
            number: e.target.value
        })
    };

    /**
      * @method renderCustomerRecord
      * @description  render customer listing 
      */
    renderCustomerRecord = (customerList) => {
        return customerList && Array.isArray(customerList) && customerList.map((item, index) => {
            return (
                <tr style={{ height: 10 }}>
                    <th scope="row">{index + 1}</th>
                    <td><p className='text-truncate' style={{width: 80}} title={item.customer_name}>{item.customer_name}</p></td>
                    <td><p className='text-truncate' style={{width: 80}} title={item.email}>{item.email}</p></td>
                    <td><p className='text-truncate'>{item.phone_number}</p></td>
                    <td>
                        <Button
                            type='button'
                            className='btn-rp-primary table-action-btn col-md-4'
                            style={{marginLeft: 10}}
                            onClick={() => this.getCustomerByIdCustomer(index)}
                        >
                            Edit
                            </Button>&nbsp;&nbsp;
                            <Button
                            type='button'
                            className='btn-rp-primary table-action-btn col-md-4'
                            onClick={e => {
                                if (this.state.isEditFalg) {
                                    this.setState({ isEditFalg: false }, () => {
                                        toastr.confirm(
                                            `${MESSAGES.REQUEST_DELETE_CONFIRM}`,
                                            {
                                                onOk: () => this.removeCustomer(index),
                                                onCancel: () => { console.log('cancel') }
                                            })
                                            this.props.reset()
                                            this.resetFields()
                                    })
                                } else {
                                    toastr.confirm(
                                        `${MESSAGES.REQUEST_DELETE_CONFIRM}`,
                                        {
                                            onOk: () => this.removeCustomer(index),
                                            onCancel: () => { console.log('cancel') }
                                        })
                                }
                            }}
                        >
                            Delete
                            </Button>
                    </td>
                </tr>
            )
        })
    }

    /**
    * @method removeCustomer 
    * @description Used to remove customer
    */
    removeCustomer = index => {
        const tempArray = this.state.customerArray;
        tempArray.splice(index, 1);
        this.setState({ customerArray: tempArray });
    };

    /**
    * @method getCustomerByIdCustomer 
    * @description Used to update customer
    */
    getCustomerByIdCustomer = index => {
        const tempArray = this.state.customerArray;
        this.setState({
            phone_number: tempArray[index].phone_number,
            customer_name: tempArray[index].customer_name,
            email: tempArray[index].email,
            isEditFalg: true,
            indexPosition: index
        })
    };

    /**
    * @method resetFields 
    * @description reset editable values
    */
    resetFields = () => {
        this.setState({ customer_name: '', phone_number: '', email: '', isEditfalg: false })
    }

    /**
     * @method addCustomerHandler
     * @description  add customer data into  local formData object
     */
    addCustomerHandler = () => {
        const { data } = this.props;
        const { tempArray, customerArray, phone_number, customer_name, email, isEditFalg, indexPosition } = this.state;
        let validEmail = validateEmails(email)
        let validPhone = PhoneNumber(phone_number)
        let minLength = minLength5(email) ||  minLengthC2(customer_name)
        let maxLength = maxLengthC100(email) || maxLengthC100(customer_name)
        let emptyCheck = (phone_number !== '' && customer_name !== '' && email !== '' && validEmail === undefined && validPhone === undefined && minLength === undefined && maxLength === undefined)
        if (customerArray.length !== -1 && !isEditFalg && customerArray.some(person => person.email === email)) {
            toastr.error(` ${MESSAGES.REQUEST_ALREADY_ADDED} ${email}`)
            return true;
        } else if (data && data.length > 0 && data.some(person => person.customer_email === email && !isEditFalg)) {
            toastr.error(`${MESSAGES.REQUEST_ALREADY_ADDED} ${email}`)
            return true;
        } else if (isEditFalg && customer_name === '' && phone_number === '' && email === '') {
            toastr.error(`${MESSAGES.EMPTY_FIELD_CHECK}`)
            return true;
        }else if(validEmail || minLength5(email) || maxLengthC100(email) ){
            return true
        }else if(validPhone){
            return true
        }else if(minLengthC2(customer_name) || maxLengthC100(customer_name)){
            return true
        }
        else
            if (emptyCheck && isEditFalg === false) {
                tempArray.push({
                    phone_number: phone_number,
                    customer_name: customer_name,
                    email: email,
                });
                this.props.reset()
                this.resetFields()
                this.setState({customerArray: tempArray})
            } else if (isEditFalg && emptyCheck) {
                tempArray[indexPosition].phone_number = phone_number
                tempArray[indexPosition].customer_name = customer_name
                tempArray[indexPosition].email = email
                this.props.reset()
                this.resetFields()
                this.setState({customerArray: tempArray, isEditFalg: false})
            }
    }

    /**
     * @method render
     * @description render the component
     */
    render() {
        const { handleSubmit, isOpen, pristine, submitting } = this.props;
        const { isSubmitted, phone_number, customer_name, email, customerArray, } = this.state;
        return (
            <Modal
                isOpen={isOpen} toggle={this.toggleModel} styles={{ height: 10 }}
                size='lg'>
                {/* {loading && <Loader />} */}
                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                    Customer Access Request
                </ModalHeader>
                <ModalBody styles={{ width: 600 }}>
                    <Row>
                        <Container>
                            <form
                                noValidate
                                className='form'
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='customer_name'
                                            label='Customer Name'
                                            validate={customerArray.length === 0 ? [required, minLengthC2, maxLengthC100] : [minLengthC2, maxLengthC100]}
                                            required={true}
                                            Value={customer_name}
                                            onChange={e =>
                                                this.setState({
                                                    customer_name: e.target.value
                                                })
                                            }
                                            placeholder='Enter Customer Name'
                                            component={renderText1}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='email'
                                            label='Email'
                                            validate={customerArray.length === 0 ? [required, minLength5, maxLength100, validateEmails] : [minLength5, maxLength100, validateEmails]}
                                            required={true}
                                            Value={email}
                                            onChange={e =>
                                                this.setState({
                                                    email: e.target.value
                                                })
                                            }
                                            placeholder='Enter Email Id'
                                            component={renderText1}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                        <Field
                                            name='phone_number'
                                            label='Phone Number'
                                            type='text'
                                            maxLength={10}
                                            autoComplete='off'
                                            onChange={this.onChange}
                                            Value={phone_number}
                                            validate={customerArray.length === 0 ? [required, PhoneNumber] : [PhoneNumber]}
                                            required={true}
                                            placeholder='Enter Phone Number'
                                            component={renderNumberInputField}
                                            normalize={normalizePhone}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12 text-left float-left'>
                                        <button type='button'
                                            onClick={this.addCustomerHandler}
                                            disabled={pristine || submitting}
                                        >
                                            + Request Another Customer
                                        </button>
                                    </Col>
                                </Row>

                                {customerArray.length > 0 && <Row style={{ marginTop: 5 }}>
                                    <div className='col-12'>
                                    <Table bordered className='w-100'>
                                        <thead style={{ backgroundColor: '#dcdcdccc' }}>
                                            <tr>
                                                <th>#</th>
                                                <th>Customer Name</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            {customerArray.length && this.renderCustomerRecord(customerArray)}
                                        </tbody>
                                    </Table>
                                    </div>
                                </Row>}
                                <Row>
                                    <Col md='12 text-right float-right'>
                                        <Button
                                            disabled={isSubmitted ? false : true}
                                            style={{ margin: 10, background: 'black', border: 'none', width: 100 }}
                                            size='md' type='submit'
                                        >
                                            Request</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}



const mapStateToProps = store => {
    const { loading } = store.commonDuck;
    return ({
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        loading,
    })
}

export default injectIntl(
    connect(
        mapStateToProps,
        customerAccessDuck.actions
    )(reduxForm({
        // Assign unique name for the form
        form: 'RequestCustomer',

    })(RequestCustomer))
);
