import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ChromePicker } from 'react-color';
import "./AddCompany.css"
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
  change as changeFieldValue,
  untouch as untouchField,
  reset,
} from "redux-form";
import { toastr } from "react-redux-toastr";
import { Form, Col, Button } from "react-bootstrap";
import { Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import NavigationPrompt from "react-router-navigation-prompt";
import NavigateModal from "../../NavigateModal";
import { Label, Input } from "reactstrap";
import { Multiselect } from 'multiselect-react-dropdown';

import {
  renderThemeSelectOptions,
  renderThemeToggleButton,
} from "../../FormLayout";
import {
  renderText,
  renderNumberInputField,
  renderSelectField,
  focusOnError,
  renderTextInputField,
  renderTextAreaField,
} from "../../../../common/layout/FormInput";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import * as NewsPaperCompanyDuck from "../../../../store/ducks/super-admin/NewsPaperCompany.duck";
import { injectIntl } from "react-intl";
import {
  required,
  PhoneNumber,
  maxLength50,
  minLength5,
  maxLength100,
  maxLengthC100,
  minLength2,
  maxLength5,
  email,
  selectRequired,
  normalizePhone,
} from "../../../../config/validation";
import { MESSAGES } from "../../../../config/message";
import { langs } from "../../../../config/localization";
import "../../Modal.scss";
import { Loader } from "../../../../common/Loader";
import { STATUS_CODES } from "../../../../common/StatusCode";
import {
  phoneNumberFormate,
  phoneNumberFormateOnChange,
} from "../../../../common";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";

class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSocial: false,
      compCatVal: "",
      pubCatVal: "",
      bio_desc: "",
      color_box: "",
      flag: 0,
      preselected: [],
      preselectedPub: [],
      uniquesState: [],
      areasByState: [],
      AllCompanyCategory:[],
      selectedState: undefined,
      area1: undefined,
      area2: undefined,
      area3: undefined,
      companyRoles: [],
      companyCategory:[],
      selectedDayMulti:[],
      selectedPublishMulti: [],
      showPaymentModal: false,
      update: false,
      newspaper_id: "",
      sendEmail: false,
      status: false,
      value: "",
      roles: "",
      number: "",
      success: false,
      error: false,
      isRedirect: false,
      showStateSelection: false,
      showCitySelection: false,
      states: [],
      distributionType: [
        { label: "National", value: "National" },
        { label: "Local", value: "Local" },
      ],

      staticCompanyCategory: [],

      countryType: [{ label: "USA", value: "USA" }],
      selectedCity: "",
      billingState: "",
      billingCity: "",
      selectedPublishDate: "",
      selectedPublishType: "",
      isEditCompanyFlag: false,
      message: "",
      loading: false,
      isSubmitted: true,
    };

    

    

    

  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {

    this.getNewspaperDetailById();

    this.props.getAllCompanyCategoryList(langs.keyname.companycategory);
    this.props.getAllPublisherCategoryList(langs.keyname.npc_category);
     
     
    if(!this.props.formValues.isWebsiteAd){
     changeFieldValue("AddCompany", "WebsiteAd", "");
     untouchField("AddCompany", "WebsiteAd");
    }
    if(!this.props.formValues.isTwitterPost){
     changeFieldValue("AddCompany", "TwitterPost", "");
     untouchField("AddCompany", "TwitterPost");
    }
    if(!this.props.formValues.isInstagramPost){
     changeFieldValue("AddCompany", "InstagramPost", "");
     untouchField("AddCompany", "InstagramPost");
    }
    if(!this.props.formValues.isInstagramStories){
     changeFieldValue("AddCompany", "InstagramStories", "");
     untouchField("AddCompany", "InstagramStories");
    }
    if(!this.props.formValues.isWhatsappGroup){
     changeFieldValue("AddCompany", "WhatsappGroup", "");
     untouchField("AddCompany", "WhatsappGroup");
    }
    if(!this.props.formValues.isWhatsappStatus){
     changeFieldValue("AddCompany", "WhatsappStatus", "");
     untouchField("AddCompany", "WhatsappStatus");
    }
    if(!this.props.formValues.isEmailMarketing){
     changeFieldValue("AddCompany", "EmailMarketing", "");
     untouchField("AddCompany", "EmailMarketing");
    }
    if(!this.props.formValues.isSponsoredArticle){
     changeFieldValue("AddCompany", "SponsoredArticle", "");
     untouchField("AddCompany", "SponsoredArticle");
    }
    if(!this.props.formValues.isInAppAd){
     changeFieldValue("AddCompany", "InAppAd", "");
     untouchField("AddCompany", "InAppAd");
    }
    if(!this.props.formValues.isFacebookpost){
     changeFieldValue("AddCompany", "Facebookpost", "");
     untouchField("AddCompany", "Facebookpost");
    }

  }

 

  

  

  /**
   * @method getNewspaperDetailById
   * @description get news paper detail
   */
  getNewspaperDetailById = () => {
    const { isEditFlag, companyId } = this.props;
    this.getAllMasterData();
    if (isEditFlag) {
      if (companyId !== undefined) {
        this.props.getCompanyDetailById(companyId, true, (res) => {
          if (res.data && res.data) {
            //console.warn(">>Response from edit form admin>>",res.data)
            this.setState({
              compCatVal: res.data.companycatid
            })
            
         
            //console.warn(">>Company cat value", this.state.compCatVal)
            //console.warn(">>All Comp Categories>>>", this.props.staticCompanyCategory)
            // //console.warn(">>All Comp Categories>>>", this.props.companyRoles)


            for(let i = 0; i < this.props.staticCompanyCategory.length; i++){
              for(let j = 0; j < this.state.compCatVal.split(",").length; j++){
                if(this.state.compCatVal.split(",")[j] == this.props.staticCompanyCategory[i].value){
                  this.state.preselected.push(this.props.staticCompanyCategory[i])
                }
              }
            }

            //console.warn(">>Preselected cat value", this.state.preselected)

            if(this.state.preselected.length == 1){
              if(this.state.preselected[0].label == "Social Media"){
                this.setState({
                  isSocial: true
                })
              }
            }

            this.setState({
              pubCatVal: res.data.publishcat
            })
            //console.warn(">>Company publish cat value", this.state.pubCatVal)
            //console.warn(">>All Publish Categories>>>", this.props.staticPublisherCategory)

            if(this.state.pubCatVal.length > 0){
              for(let i = 0; i < this.props.staticPublisherCategory.length; i++){
                for(let j = 0; j < this.state.pubCatVal.split(",").length; j++){
                  if(this.state.pubCatVal.split(",")[j] == this.props.staticPublisherCategory[i].value){
                    this.state.preselectedPub.push(this.props.staticPublisherCategory[i])
                  }
                }
              }
            }
            

            //console.warn(">>Preselected publish value", this.state.preselectedPub)

            let number = phoneNumberFormate(res.data.user.phone_number);
            // if(res.data.companycatid.length > 1){
            //   this.setState({ selectedDayMulti: res.data.companycatid.split(",") })
            // }else if(res.data.companycatid.length == 1){
            //   this.setState({ selectedDayMulti: res.data.companycatid })
            // }

            // //console.warn(">>compcatid cat value", this.state.preselected)

            if(res.data.user.roleName != ""){
              this.setState({ roles: res.data.user.roleName })
            }


            this.setState({
              bio_desc: res.data.bio_desc
            })

            this.setState({
              color_box: res.data.color_code
            })

           

            //console.warn(">>Preselected cat value", this.state.roles)
            this.setState({ status: !res.data.active, number });
            if (
              String(res.data.distribution_type).toLowerCase() === "local" ||
              String(res.data.distribution_type).toLowerCase() === "Local"
            ) {
              this.setState({
                showStateSelection: true,
                showCitySelection: true,
              });
              if (
                Array.isArray(res.data.distribution_areas) &&
                res.data.distribution_areas.length &&
                res.data.distribution_areas[0].state_name
              ) {
                let areas = Array.isArray(res.data.distribution_areas)
                  ? res.data.distribution_areas
                  : [];
                if (res.data.distribution_areas[0].state_name !== "") {
                  res.data.distribution_areas[0].state_name &&
                    this.props.getDistributionAreaFromState(
                      String(res.data.distribution_areas[0].state_name),
                      (res) => {
                        if (res.data) {
                          this.setState({
                            areasByState: res.data,
                            selectedState: res.distribution_state,
                            area1: areas[0] ? areas[0] : undefined,
                            area2: areas[1] ? areas[1] : undefined,
                            area3: areas[2] ? areas[2] : undefined,
                            isSubmitted: true,
                          });
                        }
                      }
                    );
                } else if (res.data.distribution_state === "") {
                  this.setState({ areasByState: [], selectedState: undefined });
                }
              }
            }
            this.setState({ sendEmail: res.data.email_distribution });
            if (
              String(res.data.publish_frequency).toLowerCase() === "Monthly"
            ) {
              this.setState({ selectedPublishType: true });
            }
          }
        });
      }
    } else {
      this.props.getCompanyDetailById("", false, (res) => {});
    }
  };

  /**
   * @method getAllMasterData
   * @description get master data for dropdown
   */
  getAllMasterData = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getAllRoleList(langs.keyname.company);
    this.props.getAllCompanyCategoryList(langs.keyname.companycategory);
   
    this.props.getfromSuperAdminSetting(
      langs.keyname.copies_range,
      user_detail.id,
      (res) => {}
    );
    this.props.getfromNPCSetting("distribution_area_count", 0, (res) => {
      if (Array.isArray(res.data)) {
        const unique = [
          ...new Set(res.data.map((item) => item["us_states.state_name"])),
        ];
        let uniqueArray = [];
        unique.map((el, i) => {
          uniqueArray.push({ id: i, lable: el });
        });
        this.setState({ uniquesState: uniqueArray });
      }
    });
  };

  pushArea = (dist_area, value) => {
    const { isEditFlag, companyId } = this.props;
    if (value !== undefined && value !== "") {
      dist_area.push({
        newspaperId: isEditFlag ? companyId : "",
        distributionAreaId: value,
      });
    }
    return dist_area;
  };

  /**
   * @method filterOption
   * @description methode COnditionally Filter the the Ara option list
   */
  filterOption = (val1, val2) => {
    const { areasByState } = this.state;
    let temp;

    if (val1 === undefined && val2 === undefined) {
      return areasByState;
    } else if (val1 !== undefined && val2 !== undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val1.id && x.id !== val2.id);
    } else if (val2 === undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val1.id);
    } else if (val1 === undefined) {
      temp =
        Array.isArray(areasByState) &&
        areasByState.filter((x) => x.id !== val2.id);
    }
    return temp;
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    const { authToken } = this.props.loggedInUser;
    const { companyId, userId } = this.props;
    const { sendEmail, number, status , selectedDayMulti, preselected, selectedPublishMulti, preselectedPub} = this.state;

    //console.warn(">>>Values>>", values)
    

    if(values.distribution_type == ""){
      values.distribution_area =  [];
      values.distribution_state = "";
    }

    if(values.distributed_copies == ""){
      values.distributed_copies = 0;
    }

    if(values.distribution_type != undefined){
      if (values.distribution_type === "National") {
        values.distribution_type =
          values.distribution_type && values.distribution_type;
        values.distribution_state = "";
        values.distribution_area = "";
      } else if (values.distribution_type.toLowerCase() === "local") {
        let dist_area = [];
        dist_area = this.pushArea(dist_area, values.area1);
        dist_area = this.pushArea(dist_area, values.area2);
        dist_area = this.pushArea(dist_area, values.area3);
        values.distribution_area = dist_area ? dist_area : [];
      }
    }


    values.WebsiteAd = values.WebsiteAd == undefined ?  "" : values.WebsiteAd;
    values.TwitterPost = values.TwitterPost == undefined ?  "" : values.TwitterPost;
    values.InstagramPost = values.InstagramPost == undefined ?  "" : values.InstagramPost;
    values.InstagramStories = values.InstagramStories == undefined ?  "" : values.InstagramStories;
    values.WhatsappGroup = values.WhatsappGroup == undefined ?  "" : values.WhatsappGroup;
    values.WhatsappStatus = values.WhatsappStatus == undefined ?  "" : values.WhatsappStatus;
    values.EmailMarketing = values.EmailMarketing == undefined ?  "" : values.EmailMarketing;
    values.SponsoredArticle = values.SponsoredArticle == undefined ?  "" : values.SponsoredArticle;
    values.InAppAd = values.InAppAd == undefined ?  "" : values.InAppAd;
    values.Facebookpost = values.Facebookpost == undefined ?  "" : values.Facebookpost;
    values.EmailBlast = values.EmailBlast == undefined ?  "" : values.EmailBlast;

    

    //console.warn(">>>New Values>>", values)

    //console.warn(">>>update flag>>", this.props.isEditFlag)
    if (this.props.isEditFlag) {
      //console.warn("Inside Edit")
      let data = [];
        if(selectedDayMulti.length > 0){
          for(let i in selectedDayMulti){
            if(selectedDayMulti[i].value){
              data.push(selectedDayMulti[i].value);
            }
            
          }
        }else{
          for(let i in preselected){
            if(preselected[i].value){
              data.push(preselected[i].value);
            }
            
          }
        }

        let data_publish = [];
        if(selectedPublishMulti.length > 0){
          for(let i in selectedPublishMulti){
            if(selectedPublishMulti[i].value){
              data_publish.push(selectedPublishMulti[i].value);
            }
            
          }
        }else if(preselectedPub.length > 0){
          for(let i in preselectedPub){
            if(preselectedPub[i].value){
              data_publish.push(preselectedPub[i].value);
            }
            
          }
        }
        
        //console.warn("fg111",data);
        //console.warn("multi day",selectedPublishMulti);
        //console.warn("isWebsiteAd", this.props.formValues.isWebsiteAd)
      const requestData = {
        id: companyId,
        user_id: userId,
        is_website_ad: this.props.formValues.isWebsiteAd != undefined ? this.props.formValues.isWebsiteAd : false,
        website_ad:
        this.props.formValues.isWebsiteAd &&
          (values.WebsiteAd !== "")
          ? values.WebsiteAd
          : "",
        is_twitter_post: this.props.formValues.isTwitterPost != undefined ? this.props.formValues.isTwitterPost : false,
        twitter_post:
        this.props.formValues.isTwitterPost &&
          (values.TwitterPost !== "")
          ? values.TwitterPost
          : "",
        is_insta_post: this.props.formValues.isInstagramPost != undefined ? this.props.formValues.isInstagramPost : false,
        insta_post:
        this.props.formValues.isInstagramPost &&
          (values.InstagramPost !== "")
          ? values.InstagramPost
          : "",
        is_insta_stories: this.props.formValues.isInstagramStories != undefined ? this.props.formValues.isInstagramStories : false,
        insta_stories:
        this.props.formValues.isInstagramStories &&
          (values.InstagramStories !== "")
          ? values.InstagramStories
          : "",
        is_whatsapp_group: this.props.formValues.isWhatsappGroup != undefined ? this.props.formValues.isWhatsappGroup : false,
        whatsapp_group:
        this.props.formValues.isWhatsappGroup &&
          (values.WhatsappGroup !== "")
          ? values.WhatsappGroup
          : "",
        is_whatsapp_status: this.props.formValues.isWhatsappStatus != undefined ? this.props.formValues.isWhatsappStatus : false,
        whatsapp_status:
        this.props.formValues.isWhatsappStatus &&
          (values.WhatsappStatus !== "")
          ? values.WhatsappStatus
          : "",
        is_email_marketing: this.props.formValues.isEmailMarketing != undefined ? this.props.formValues.isEmailMarketing : false,
        email_marketing:
        this.props.formValues.isEmailMarketing &&
          (values.EmailMarketing !== "")
          ? values.EmailMarketing
          : "",
        is_sponsored_article: this.props.formValues.isSponsoredArticle != undefined ? this.props.formValues.isSponsoredArticle : false,
        sponsored_article:
        this.props.formValues.isSponsoredArticle &&
          (values.SponsoredArticle !== "")
          ? values.SponsoredArticle
          : "",
        is_in_app_ad: this.props.formValues.isInAppAd != undefined ? this.props.formValues.isInAppAd : false,
        in_app_ad:
        this.props.formValues.isInAppAd &&
          (values.InAppAd !== "")
          ? values.InAppAd
          : "",
        is_facebook_post: this.props.formValues.isFacebookpost != undefined ? this.props.formValues.isFacebookpost : false,
        facebook_post:
        this.props.formValues.isFacebookpost &&
          (values.Facebookpost !== "")
          ? values.Facebookpost
          : "",
        is_email_blast: this.props.formValues.isEmailBlast != undefined ? this.props.formValues.isEmailBlast : false,
        email_blast:
        this.props.formValues.isEmailBlast &&
          (values.EmailBlast !== "")
          ? values.EmailBlast
          : "",
        first_name: values.first_name && values.first_name.trim(),
        last_name: values.last_name && values.last_name.trim(),
        newspaper_name: values.newspaper_name && values.newspaper_name.trim(),
        companycatid : data.toString(),
        publishcat : data_publish.toString(),
        role_id: values.role_id,
        phone_number: number.replace(/-/g, ""),
        email: values.email,
        color_code: this.state.color_box ,
        bio_desc: this.state.bio_desc,
        distribution_type: values.distribution_type != undefined ? values.distribution_type : "",
        distribution_state: values.distribution_state,
        distribution_area: values.distribution_area
          ? values.distribution_area
          : [],
        bill_address: values.bill_address,
        bill_city: values.bill_city && values.bill_city.trim(),
        bill_state: values.bill_state && values.bill_state.trim(),
        bill_country: "USA",
        bill_zip_code: values.bill_zip_code,
        email_distribution: sendEmail,
        distributed_copies: values.distributed_copies != undefined ? values.distributed_copies: 0,
        status: 1,
        active: !status,
      };

      //console.warn(">>edit request>>", requestData)
      this.props.updateComapnyProfile(requestData, authToken, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          toastr.success(MESSAGES.COMPANY_UPDATE_SUCCESS);
          this.setState({
            isEditCompanyFlag: false,
          });
          this.props.getCompanyDetailById(companyId, true, (res) => {});
          setTimeout(() => {
            this.props.onCancel();
          }, 3000);
        }
      });
    } else {

      //console.warn(">>Inside submit form>>")
      this.setState({ isSubmitted: false });
      let data = [];
        if(selectedDayMulti.length > 0){
          for(let i in selectedDayMulti){
            if(selectedDayMulti[i].value){
              data.push(selectedDayMulti[i].value);
            }
            
          }
        }else{
          data.push(8)
        }

        let publish_data = [];
        if(selectedPublishMulti.length > 0){
          for(let i in selectedPublishMulti){
            if(selectedPublishMulti[i].value){
              publish_data.push(selectedPublishMulti[i].value);
            }
            
          }
        }
        
      //console.warn("Website Ad", values.WebsiteAd)
      //console.warn("isWebsiteAd", this.props.formValues.isWebsiteAd)
      const requestData = {
        is_website_ad: this.props.formValues.isWebsiteAd != undefined ? this.props.formValues.isWebsiteAd : false,
        website_ad:
          this.props.formValues.isWebsiteAd &&
            (values.WebsiteAd !== "")
            ? values.WebsiteAd
            : "",
        is_twitter_post: this.props.formValues.isTwitterPost != undefined ? this.props.formValues.isTwitterPost : false,
        twitter_post:
        this.props.formValues.isTwitterPost &&
          (values.TwitterPost !== "")
          ? values.TwitterPost
          : "",
        is_insta_post: this.props.formValues.isInstagramPost != undefined ? this.props.formValues.isInstagramPost : false,
        insta_post:
        this.props.formValues.isInstagramPost &&
          (values.InstagramPost !== "")
          ? values.InstagramPost
          : "",
        is_insta_stories: this.props.formValues.isInstagramStories != undefined ? this.props.formValues.isInstagramStories : false,
        insta_stories:
        this.props.formValues.isInstagramStories &&
          (values.InstagramStories !== "")
          ? values.InstagramStories
          : "",
        is_whatsapp_group: this.props.formValues.isWhatsappGroup != undefined ? this.props.formValues.isWhatsappGroup : false,
        whatsapp_group:
        this.props.formValues.isWhatsappGroup &&
          (values.WhatsappGroup !== "")
          ? values.WhatsappGroup
          : "",
        is_whatsapp_status: this.props.formValues.isWhatsappStatus != undefined ? this.props.formValues.isWhatsappStatus : false,
        whatsapp_status:
        this.props.formValues.isWhatsappStatus &&
          (values.WhatsappStatus !== "")
          ? values.WhatsappStatus
          : "",
        is_email_marketing: this.props.formValues.isEmailMarketing != undefined ? this.props.formValues.isEmailMarketing : false,
        email_marketing:
        this.props.formValues.isEmailMarketing &&
          (values.EmailMarketing !== "")
          ? values.EmailMarketing
          : "",
        is_sponsored_article: this.props.formValues.isSponsoredArticle != undefined ? this.props.formValues.isSponsoredArticle : false,
        sponsored_article:
        this.props.formValues.isSponsoredArticle &&
          (values.SponsoredArticle !== "")
          ? values.SponsoredArticle
          : "",
        is_in_app_ad: this.props.formValues.isInAppAd != undefined ? this.props.formValues.isInAppAd : false,
        in_app_ad:
        this.props.formValues.isInAppAd &&
          (values.InAppAd !== "")
          ? values.InAppAd
          : "",
        is_facebook_post: this.props.formValues.isFacebookpost != undefined ? this.props.formValues.isFacebookpost : false,
        facebook_post:
        this.props.formValues.isFacebookpost &&
          (values.Facebookpost !== "")
          ? values.Facebookpost
          : "",
        is_email_blast: this.props.formValues.isEmailBlast != undefined ? this.props.formValues.isEmailBlast : false,
        email_blast:
        this.props.formValues.isEmailBlast &&
          (values.EmailBlast !== "")
          ? values.EmailBlast
          : "",
        first_name: values.first_name && values.first_name.trim(),
        last_name: values.last_name && values.last_name.trim(),
        newspaper_name: values.newspaper_name && values.newspaper_name.trim(),
        companycatid: data.toString(),
        publishcat: publish_data.toString(),
        role_id: values.role_id,
        phone_number: number.replace(/-/g, ""),
        email: values.email,
        color_code: values.color_code == undefined ? "": values.color_code ,
        bio_desc: this.state.bio_desc,
        distribution_type: values.distribution_type != undefined ? values.distribution_type : "",
        distribution_area: values.distribution_area
          ? values.distribution_area
          : [],
        bill_address: values.bill_address,
        bill_city: values.bill_city && values.bill_city.trim(),
        bill_state: values.bill_state && values.bill_state.trim(),
        bill_country: "USA",
        bill_zip_code: values.bill_zip_code,
        email_distribution: sendEmail,
        distributed_copies: values.distributed_copies != undefined ? values.distributed_copies: 0,
        status: 1,
        active: !status,
      };
      //console.warn("requestData>>>",requestData);
      this.props.addNewCompany(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED) {
          this.props.callList();
          this.setState({
            newspaperId: res.data.newspaper_id,
            isSubmitted: false,
          });
          toastr.success(MESSAGES.COMPANY_ADD_SUCCESS);
          setTimeout(() => {
            this.props.callNext(res.data.newspaper_id);
            this.setState({ show: false });
          }, 5000);
          setTimeout(() => {
            this.props.onCancel();
          }, 5000);
          this.setState({ showPaymentModal: true });
        } else {
          this.setState({
            isSubmitted: true,
          });
        }
      });
    }
  }

  /**
   * @method onChangeDetailHandeler
   * @description called for handling prompt
   */
  onChangeDetailHandeler = (e, type) => {
    this.setState({
      isEditCompanyFlag: true,
      isEditFlag: true,
      number: type === "number" ? e.target.value : this.state.number,
    });
    this.stateHandeler(e);
  };

  /**
   * @method onChangeStateHandeler
   * @description called to handle state chage
   */
  onChangeStateHandeler = (e, type) => {
    if (String(e.target.value) === "Local") {
      this.setState({
        showStateSelection: true,
        selectedState: e.target.value,
        isEditCompanyFlag: true,
      });
    } else {
      this.setState({
        showStateSelection: false,
        isEditCompanyFlag: true,
      });
    }
  };

  /**
   * @method stateHandeler
   * @description called to render list of state
   */
  stateHandeler = (e) => {
    if (e.target.value !== "") {
      this.props.getDistributionAreaFromState(String(e.target.value), (res) => {
        if (res.data) {
          this.setState({
            areasByState: res.data,
            selectedState: e.target.value,
            isSubmitted: true,
            isEditCompanyFlag: true,
            area1: undefined,
            area2: undefined,
            area3: undefined,
          });
          this.props.dispatch(changeFieldValue("AddCompany", "area1", ""));
          this.props.dispatch(changeFieldValue("AddCompany", "area2", ""));
          this.props.dispatch(changeFieldValue("AddCompany", "area3", ""));
        }
      });
    } else if (e.target.value === "") {
      this.setState({ areasByState: [], selectedState: undefined });
    }
  };

  /**
   * @method getArea
   * @description called to render list of area's
   */
  getArea = (e, name) => {
    const { areasByState } = this.state;
    let index;
    if (areasByState) {
      index = areasByState.findIndex((el) => el.id === Number(e.target.value));
      if (index >= 0) {
        this.setState({
          [name]: {
            name: areasByState[index].area,
            id: areasByState[index].id,
          },
          isSubmitted: true,
          isEditCompanyFlag: true,
          isEditFlag: true,
        });
      } else {
        this.setState({
          isEditCompanyFlag: true,
          [name]: undefined,
        });
      }
    } else {
      this.setState({ isEditCompanyFlag: true });
    }
  };

  /**
   * @method getAdTypeOptions
   * @description return dropdown options for Ad Type
   */
   getRoles = () => {
    //console.warn('>>>>>>>>>>>>>>>>>', this.props.companyRoles);
     return Array.isArray(this.props.companyRoles) &&
     this.props.companyRoles.length > 0
       ? this.props.companyRoles.map((ad) => ({
         label: ad.name,
         value: ad.id,
       }))
       : [];
   };

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
      isEditFlag: true,
      isEditCompanyFlag: true,
    });
  };

  /**
   * @method handleChange
   * @description used to handle check and uncheck email distribution
   */
  handleChange = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  /**
   * @method handleToggleChange
   * @description used to handle toggle
   */
  handleToggleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked, isEditCompanyFlag: true });
  };

  /**
   * @method onRemove
   * @description used to handle company category on remove item
   */
  onRemove = (e) => {
    // //console.warn(">>>Changed>>", e)
    // //console.warn(">>>changed>>", this.state.selectedDayMulti)
    // //console.warn(">>>changed>>", this.state.preselected)
    while(this.state.preselected.length > 0) {
      this.state.preselected.pop();
    }
    for(let i=0; i< e.length; i ++){
      this.state.preselected.push(e[i])
    }
    this.setState({
      isEditCompanyFlag: true
    })
    
    
  }

  onRemovePub = (e) => {
    // //console.warn(">>>Changed>>", e)
    // //console.warn(">>>changed>>", this.state.selectedDayMulti)
    // //console.warn(">>>changed>>", this.state.preselected)
    while(this.state.preselectedPub.length > 0) {
      this.state.preselectedPub.pop();
    }
    for(let i=0; i< e.length; i ++){
      this.state.preselectedPub.push(e[i])
    }
    this.setState({
      isEditCompanyFlag: true
    })
    
    
  }

  /**
   * @method onChangeTypeHandeler
   * @description handle distribution type change
   */
  onChangeTypeHandeler = (e, type) => {
    if (String(e.target.value) === "Local") {
      this.setState({
        showStateSelection: true,
        //selectedState: e.target.value,
        isEditCompanyFlag: true,
      });
    } else if (String(e.target.value) === "National") {
      this.setState({
        showStateSelection: false,
        isEditCompanyFlag: true,
        area1: undefined,
        area2: undefined,
        area3: undefined,
        selectedState: undefined,
      });
    }else{
      this.setState({
        showStateSelection: false,
        isEditCompanyFlag: true,
        isEditFlag: true,
        area1: undefined,
        area2: undefined,
        area3: undefined,
        selectedState: undefined,
      });
    }
  };

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.onCancel();
  };

  /**
   * @method onCancelPaymentModel
   * @description  used to cancel filter form
   */
  onCancelPaymentModel = () => {
    this.setState({ showPaymentModal: false });
  };

  changeHandler1 = () => {
   
    //console.warn(">>Flag>>", this.state.flag)
    
    this.setState({ flag : !this.state.flag })
    
    
  }

  /**
   * @method render
   * @description  used to render component
   */
  render() {
    const { handleSubmit, rangeList, loading, formValues } = this.props;

    
    const {
      uniquesState,
      number,
      roles,
      bio_desc,
      color_box,
      flag,
      billingCity,
      billingState,
      distributionType,
      staticCompanyCategory,
      showStateSelection,
      selectedState,
      area1,
      area2,
      area3,
      isRedirect,
      isEditCompanyFlag,
      sendEmail,
      selectedCity,
      status,
      isSubmitted,
      isSocial
    } = this.state;
    if (isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/adminManagement",
          }}
        />
      );
    }
    const { isEditFlag } = this.props;
    return (
      <Container className="top-margin">
        {isEditFlag && (
          <NavigationPrompt when={isEditCompanyFlag}>
            {(props) => {
              const { onCancel } = props;
              return (
                <NavigateModal
                  show={true}
                  alertHeading={"Changes made will be lost."}
                  cancleButtonText="Cancel"
                  okButtonText="Leave Anyway"
                  showCancel={true}
                  onOkClick={() => this.props.onCancel()}
                  onClickCancel={() => {
                    onCancel();
                  }}
                  contentClassName="modalShadow"
                />
              );
            }}
          </NavigationPrompt>
        )}
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggleModel}
          styles={{ height: 10 }}
          size="lg"
        >
          <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
            <h5 className="kt-portlet__head-title">Contact Info</h5>
          </ModalHeader>
          <ModalBody>
            <Container>
              {loading && <Loader />}
              <form
                noValidate
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="6">
                    <Field
                      name="first_name"
                      value={"adminProfile.first_name"}
                      label="First Name"
                      validate={[required, minLength2, maxLengthC100]}
                      required={true}
                      placeholder="Enter Your First Name"
                      component={renderText}
                      onChange={(e) => {
                        this.setState({
                          isEditCompanyFlag: true,
                        });
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="last_name"
                      label="Last Name"
                      validate={[required, minLength2, maxLengthC100]}
                      required={true}
                      placeholder="Enter Your Last Name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Field
                      name="newspaper_name"
                      value={"adminProfile.first_name"}
                      label="Newspaper Name"
                      validate={[required, maxLengthC100]}
                      required={true}
                      placeholder="Enter News Paper Name"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="4">
                    {/* <Form.Label>
                      Role<span className="asterisk-required">*</span>
                    </Form.Label> */}
                    <Field
                      label="Role"
                      name="role_id"
                      options={this.getRoles()}  
                      optionValue={"value"}
                      optionLabel={"label"}
                      value={roles}
                      required={true}
                      validate={[selectRequired]}
                      component={renderSelectField}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  {/* -------------------------- */}
                


                  {/* ------------------ */}
                  <Col md="4">
                  <Form.Label>
                      Company Category
                    </Form.Label>
                     <Multiselect 
                      name="companycatid"
                      onRemove={this.onRemove}
                      displayValue="label"
                      validate={[required]}
                      isSelect={false}
                      required={true}
                      options={this.props.staticCompanyCategory}
                     
                      
                      onChange={this.onChangeTypeHandeler}
                      showCheckbox={true} // Options to display in the dropdown
                   
                    selectedValues = { 
                      
                      this.state.preselected.length == 0 
                      ?
                        [{ label: "Print", value: 8 }]
                      :
                        this.state.preselected.map( pre => {return pre})
                    } // Preselected value to persist in dropdown
                    onSelect={(selectedValues)=>{
                    this.setState({
                      selectedDayMulti: selectedValues,
                      isEditCompanyFlag: true
                    });
                  }} // Function will trigger on select event
                  
                  
                    />
                   
                  
                   
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Field
                      name="phone_number"
                      label="Phone Number"
                      type="text"
                      maxLength={10}
                      autoComplete="off"
                      onChange={this.onChange}
                      Value={number}
                      validate={[required, PhoneNumber]}
                      required={true}
                      placeholder="Enter Phone Number"
                      component={renderNumberInputField}
                      normalize={normalizePhone}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="email"
                      label="Email"
                      validate={[required, minLength5, maxLength100, email]}
                      required={true}
                      placeholder="Enter Email Id"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      style={{ backgroundColor: color_box, color: color_box }}
                      readOnly
                      name="color_code"
                      label={`Color Code (${ color_box })`}
                      placeholder="Enter color code"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                      onClick = { 
                        this.changeHandler1
                       }
                      
                    />
                    {/* <button 
                      type="button"
                      style={{ 
                        backgroundColor: color_box,
                        width: 200 + "px",
                        height: 40 + "px"
                      }} 
                      value={ color_box == "" ? "Choose Color" : color_box  } 
                      onClick={ this.changeHandler1 }
                      >
                        { color_box == "" ? "Choose Color" : color_box }
                    </button> */}
                      
                      {
                        flag == 1 ? (

                          <ChromePicker
                            color={ color_box }
                            onChange= { 
                              updatedColor => this.setState({
                               color_box: updatedColor.hex,
                               isEditCompanyFlag: true
                            })
                            
                          }
                          />
                        ) : ""
                      }
                      
                  </Col>
                  {/* <Col md="2">
                    
                      
                      <span 
                        style={{ color: "red" }}
                      >
                        <div></div>
                        {this.props.initialValues.color_code = this.state.color_box}
                      </span>
                        
                      
                  </Col> */}
                </Row>
              {/* Bio Description */}
              <Row>
                <Col md="8">
              <Form.Label>Bio Description</Form.Label><br/>
                    <textarea
                      name="bio_desc"
                      value={bio_desc}
                      style={{ 
                        height: 90 + "px" ,
                        width: 100 + "%", 
                        float: "left",
                        // border: 1 + "px"  "solid"  "#e2e5ec"
                        borderRadius: 4+ "px",
                        borderColor: "#e2e5ec"
                      }}
                      row={10}
                      cols={50}
                      placeholder="Bio-Description"
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                          bio_desc: e.target.value
                        })
                      }
                      
                     />
                  </Col>

                <Col md="4">
                  <Form.Label>
                      Publisher Industry
                    </Form.Label>
                     <Multiselect 
                        name="publishcat"
                        onRemove={this.onRemovePub}
                        displayValue="label"
                        // validate={[required]}
                        isSelect={false}
                        // required={true}
                        options={this.props.staticPublisherCategory}
                      
                      
                        onChange={this.onChangeTypeHandeler}
                        showCheckbox={true} // Options to display in the dropdown
                    
                      selectedValues = { 
                          this.state.preselectedPub.map( pre => {return pre})
                      } // Preselected value to persist in dropdown
                      onSelect={(selectedValues)=>{
                      this.setState({
                        selectedPublishMulti: selectedValues,
                        isEditCompanyFlag: true
                      });
                    }} // Function will trigger on select event
                  
                  
                    />
                  </Col>
                  </Row>
                {/* <Row>
                  <Col md="12">
                  <Field
                        name="bio_desc"
                        component={renderTextAreaField}
                        style={{ height: 200 + "px" }}
                        // rows={3}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Bio-Description"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      </Col>
               </Row> */}


                <h5 className="kt-portlet_head-title">Publishers Account</h5>

                <div className="p-1 mb-4">
                <Row>
                  <Col md="4">
                  <div className="form-group form-check mb-0">
                  <CheckboxRP>
                    <Field
                      name="isWebsiteAd"
                      component="input"
                      type="checkbox"
                      id="coloredText"
                      className="form-check-input"
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="coloredText"
                    >
                      Website Ad
                    </label>
                    </CheckboxRP>
                    </div>
                    {formValues.isWebsiteAd && (
                      <Field
                        name="WebsiteAd"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Website Ad"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                      <CheckboxRP>
                      <Field
                        name="isTwitterPost"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        Twitter Post
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isTwitterPost && (
                      <Field
                        name="TwitterPost"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Twitter Post"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                      <Field
                      name="isInstagramPost"
                      component="input"
                      type="checkbox"
                      id="coloredText"
                      className="form-check-input"
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="coloredText"
                    >
                      Instagram Post
                    </label>
                    </CheckboxRP>
                  </div>
                  {formValues.isInstagramPost && (
                      <Field
                        name="InstagramPost"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Instagram Post"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                      <Field
                        name="isInstagramStories"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        Instagram Stories
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isInstagramStories && (
                      <Field
                        name="InstagramStories"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Instagram Stories"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                      <Field
                        name="isWhatsappGroup"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        Whatsapp Group
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isWhatsappGroup && (
                      <Field
                        name="WhatsappGroup"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Whatsapp Group"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                      <Field
                        name="isWhatsappStatus"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        Whatsapp Status
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isWhatsappStatus && (
                      <Field
                        name="WhatsappStatus"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Whatsapp Status"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                      <Field
                        name="isEmailMarketing"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        Email Marketing
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isEmailMarketing && (
                      <Field
                        name="EmailMarketing"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Email Marketing"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                        <Field
                        name="isSponsoredArticle"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        Sponsored Article
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isSponsoredArticle && (
                      <Field
                        name="SponsoredArticle"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Sponsored Article"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                    <CheckboxRP>
                      <Field
                        name="isInAppAd"
                        component="input"
                        type="checkbox"
                        id="coloredText"
                        className="form-check-input"
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="coloredText"
                      >
                        In-app Ad
                      </label>
                      </CheckboxRP>
                    </div>
                    {formValues.isInAppAd && (
                      <Field
                        name="InAppAd"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="In App Ad"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                    <div className="form-group form-check mb-0">
                        <CheckboxRP>
                          <Field
                            name="isFacebookpost"
                            component="input"
                            type="checkbox"
                            id="coloredText"
                            className="form-check-input"
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Facebook Post
                          </label>
                        </CheckboxRP>
                      
                    </div>
                    {formValues.isFacebookpost && (
                      <Field
                        name="Facebookpost"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Facebook Post"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                  <Col md="4">
                    <div className="form-group form-check mb-0">
                        <CheckboxRP>
                          <Field
                            name="isEmailBlast"
                            component="input"
                            type="checkbox"
                            id="coloredText"
                            className="form-check-input"
                            onChange={(e) =>
                              this.setState({
                                isEditCompanyFlag: true,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Email Blast
                          </label>
                        </CheckboxRP>
                      
                    </div>
                    {formValues.isEmailBlast && (
                      <Field
                        name="EmailBlast"
                        component={renderTextInputField}
                        // validate={[
                        //   required,
                        //   number,
                        //   greaterThanZeroWithTwoDecimalPlace,
                        // ]}
                        placeholder="Email Blast"
                        // min="0"
                        // step=".01"
                        // helpText={
                        //   "Only Numeric value with up to 2 decimal places"
                        // }
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    )}
                  </Col>
                </Row>
                </div>

                
               

               

               


               

                


               

               
                









                <h5 className="kt-portlet__head-title">Billing Information</h5>
                <Row>
                  <Col md="12">
                    <Field
                      name="bill_address"
                      value={"adminProfile.first_name"}
                      label="Address"
                      validate={[required, minLength2, maxLength100]}
                      required={true}
                      placeholder="Enter Address"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="bill_city"
                      value={billingCity}
                      label="City"
                      validate={[required, maxLength50]}
                      required={true}
                      placeholder="Enter City"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="bill_state"
                      value={billingState}
                      label="State"
                      validate={[required, maxLength50]}
                      required={true}
                      placeholder="Enter State"
                      component={renderText}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="bill_zip_code"
                      value={"adminProfile.first_name"}
                      label="Zip Code"
                      validate={[required, minLength5, maxLength5]}
                      type="text"
                      style={{ width: "100%" }}
                      maxLength={5}
                      required={true}
                      placeholder="Enter Zip Code"
                      component={renderText}
                      normalize={normalizePhone}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="bill_country"
                      label="Country"
                      required={true}
                      options={[{ label: "USA", value: "USA" }]}
                      component={renderSelectField}
                      placeholder="Enter Country"
                      optionValue={"value"}
                      optionLabel={"label"}
                      onChange={(e) =>
                        this.setState({
                          isEditCompanyFlag: true,
                        })
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Status</Form.Label>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                        className="ml-2"
                      >
                        <Form.Row>
                          <Form.Label style={{ color: "green" }}>
                            Active
                          </Form.Label>
                          <div style={{ marginTop: -7 }}>
                            <Field
                              name="active"
                              checked={status}
                              onChange={this.handleToggleChange("status")}
                              required={true}
                              component={renderThemeToggleButton}
                            />
                          </div>

                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Label style={{ color: "red" }}>
                            Inactive
                          </Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Row>
                
                { isSocial == true ? 
                  "" 
                  :
                 ( <>
                <h5 className="kt-portlet__head-title">
                  Distribution Type & Area
                </h5>
                <Row>
                  <Col md="6">
                    <Field
                      name="distribution_type"
                      label="Type"
                      // validate={[required]}
                      isSelect={false}
                      // required={true}
                      options={distributionType}
                      component={renderSelectField}
                      optionValue={"value"}
                      optionLabel={"label"}
                      placeholder="Enter Distribution Type"
                      onChange={this.onChangeTypeHandeler}
                      // onChange={(e) =>
                      //   this.setState({
                      //     isEditCompanyFlag: true,
                      //   })
                      // }
                    />
                  </Col>
                  {showStateSelection && (
                    <Col md="6">
                      <Field
                        name="distribution_state"
                        value={selectedState}
                        label="State"
                        validate={[selectRequired]}
                        optionValue={"lable"}
                        optionLabel={"lable"}
                        isSelect={false}
                        required={true}
                        options={uniquesState && uniquesState}
                        onChange={this.onChangeDetailHandeler}
                        component={renderSelectField}
                        placeholder="Enter Distribution State"
                      />
                    </Col>
                  )}
                  <Row />
                  {showStateSelection && (
                    <Col md="12">
                      <Row>
                        <Col md="4">
                          <Field
                            name="area1"
                            value={area1}
                            label="Area 1"
                            validate={[selectRequired]}
                            optionValue={"id"}
                            optionLabel={"area"}
                            options={this.filterOption(area2, area3)}
                            isSelect={false}
                            required={true}
                            onChange={(e) => this.getArea(e, "area1")}
                            component={renderSelectField}
                            placeholder="Enter Distribution State"
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            name="area2"
                            value={area2}
                            label="Area 2"
                            optionValue={"id"}
                            optionLabel={"area"}
                            options={this.filterOption(area1, area3)}
                            isSelect={false}
                            onChange={(e) => this.getArea(e, "area2")}
                            component={renderSelectField}
                            placeholder="Enter Distribution State"
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            name="area3"
                            value={area3}
                            label="Area 3"
                            optionValue={"id"}
                            optionLabel={"area"}
                            options={this.filterOption(area1, area2)}
                            isSelect={false}
                            onChange={(e) => this.getArea(e, "area3")}
                            component={renderSelectField}
                            placeholder="Enter Distribution State"
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="3">
                    <div class="form-group">
                      <label>Email distribution</label>
                      <div class="checkbox-inline">
                        <CheckboxRP label="Yes">
                          <Input
                            type="checkbox"
                            checked={sendEmail ? true : ""}
                            onChange={(e) =>
                              this.setState({
                                sendEmail: true,
                                isEditCompanyFlag: true,
                              })
                            }
                            name="email_distribition"
                          />
                        </CheckboxRP>
                        <CheckboxRP label="No">
                          <Input
                            type="checkbox"
                            id="checkbox2"
                            checked={!sendEmail ? true : ""}
                            onChange={(e) =>
                              this.setState({
                                sendEmail: false,
                                isEditCompanyFlag: true,
                              })
                            }
                            name="email_distribition"
                          />
                        </CheckboxRP>
                      </div>
                    </div>
                  </Col>
                  <Col md="5">
                    <Form.Group>
                      <Field
                        name="distributed_copies"
                        label={"No. Of Copies"}
                        isSelect={false}
                        selected={selectedCity}
                        // validate={[selectRequired]}
                        // required={true}
                        placeholder="Enter No. Of Copies Distributed"
                        component={renderSelectField}
                        options={rangeList}
                        optionValue={"id"}
                        optionLabel={"copies_range"}
                        onChange={(e) =>
                          this.setState({
                            isEditCompanyFlag: true,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>
                 </>) }   
                <Row className="float-right">
                  <Col>
                    <div className="d-flex-center j-end">
                      <ButtonRP
                        text="Cancel"
                        cancel={true}
                        className="mx-2"
                        onClick={this.props.onCancel}
                      />
                     
                      {!this.props.isEditFlag && (
                        <ButtonRP
                          type="submit"
                          text="Next"
                          disabled={isSubmitted ? false : true}
                        />
                      )}
                      {this.props.isEditFlag && (
                        <ButtonRP
                          type="submit"
                          text="Update"
                          disabled={isEditCompanyFlag ? false : true}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

const formValSelector = formValueSelector("AddCompany");

const mapStateToProps = (store) => {
  const selectedCompany =
    store.newsPaperCompanyDuck && store.newsPaperCompanyDuck.selectedCompany;
  const { loading, roles, superAdminSettingList,companyCategoryList, publisherCategoryList } = store.commonDuck;
  const { npcDuck } = store;

  // //console.warn("selected",selectedCompany);
  const getAdTypeOptions = () => {
    
     return Array.isArray(companyCategoryList) &&
     companyCategoryList.length > 0
       ? companyCategoryList.map((ad) => ({
         label: ad.name,
         value: ad.id,
       }))
       : [];
   };

   //console.warn("checking",getAdTypeOptions());

   const getPublisherOptions = () => {
    
    return Array.isArray(publisherCategoryList) &&
    publisherCategoryList.length > 0
      ? publisherCategoryList.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  //console.warn("checking",getPublisherOptions());

  const formValues = formValSelector(
    store,
    "isWebsiteAd",
    "isTwitterPost",
    "isInstagramPost",
    "isInstagramStories",
    "isWhatsappGroup",
    "isWhatsappStatus",
    "isEmailMarketing",
    "isSponsoredArticle",
    "isInAppAd",
    "isFacebookpost",
    "isEmailBlast",
    "WebsiteAd",
    "TwitterPost",
    "InstagramPost",
    "InstagramStories",
    "WhatsappGroup",
    "WhatsappStatus",
    "EmailMarketing",
    "SponsoredArticle",
    "InAppAd",
    "Facebookpost",
    "EmailBlast"
  )

  let initialValues = {};
  if (selectedCompany && selectedCompany !== undefined) {
    let areaArray = [];
    if (
      selectedCompany.distribution_areas &&
      selectedCompany.distribution_areas !== [] &&
      selectedCompany.distribution_areas !== undefined
    ) {
      var str = selectedCompany.distribution_areas;
      areaArray = str;
    }
    initialValues = {
      first_name: selectedCompany.user.first_name
        ? selectedCompany.user.first_name.trim()
        : "",
      last_name: selectedCompany.user.last_name
        ? selectedCompany.user.last_name.trim()
        : "",
      phone_number: selectedCompany.user.phone_number,
      email: selectedCompany.user.email,
      color_code: selectedCompany.color_code,
      bio_desc: selectedCompany.bio_desc,
      role_id: selectedCompany.user.role_id,
      newspaper_name: selectedCompany.newspaper_name
        ? selectedCompany.newspaper_name.trim()
        : "",

      companycatid: selectedCompany.companycatid
        ? selectedCompany.companycatid
        : null,
      publishcat: selectedCompany.publishcat
        ? selectedCompany.publishcat
        : null,
      bill_address: selectedCompany.bill_address,
      bill_state: selectedCompany.bill_state
        ? selectedCompany.bill_state.trim()
        : "",
      bill_city: selectedCompany.bill_city
        ? selectedCompany.bill_city.trim()
        : "",
      bill_country: selectedCompany.bill_country,
      bill_zip_code: selectedCompany.bill_zip_code,
      distribution_type: selectedCompany.distribution_type,
      distribution_state:
        selectedCompany.distribution_state !== "" &&
        selectedCompany.distribution_type === "Local" &&
        selectedCompany.distribution_areas &&
        selectedCompany.distribution_areas[0]
          ? selectedCompany.distribution_areas[0].state_name
          : "",
      distribution_area: selectedCompany.distribution_area,
      distributed_copies:
        selectedCompany.distributed_copies !== undefined &&
        selectedCompany.distributed_copies
          ? selectedCompany.distributed_copies.id
          : "",
      isWebsiteAd: selectedCompany.is_website_ad,
      WebsiteAd: selectedCompany.website_ad,
      isTwitterPost: selectedCompany.is_twitter_post,
      TwitterPost: selectedCompany.twitter_post,
      isInstagramPost: selectedCompany.is_insta_post,
      InstagramPost: selectedCompany.insta_post,
      isInstagramStories: selectedCompany.is_insta_stories,
      InstagramStories: selectedCompany.insta_stories,
      isWhatsappGroup: selectedCompany.is_whatsapp_group,
      WhatsappGroup: selectedCompany.whatsapp_group,
      isWhatsappStatus: selectedCompany.is_whatsapp_status,
      WhatsappStatus: selectedCompany.whatsapp_status,
      isEmailMarketing: selectedCompany.is_email_marketing,
      EmailMarketing: selectedCompany.email_marketing,
      isSponsoredArticle: selectedCompany.is_sponsored_article,
      SponsoredArticle: selectedCompany.sponsored_article,
      isInAppAd: selectedCompany.is_in_app_ad,
      InAppAd: selectedCompany.in_app_ad,
      isFacebookpost: selectedCompany.is_facebook_post,
      Facebookpost: selectedCompany.facebook_post,
      isEmailBlast: selectedCompany.is_email_blast,
      EmailBlast: selectedCompany.email_blast,
      email_distribution: selectedCompany.email_distribution,
      area1: areaArray && areaArray[0] && areaArray[0].id,
      area2: areaArray && areaArray[1] && areaArray[1].id,
      area3: areaArray && areaArray[2] && areaArray[2].id,
    };
  }

  

  //console.warn("initial Values", initialValues)
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    companyRoles: roles && roles.company ? roles.company : [],
    staticCompanyCategory: getAdTypeOptions(),
    staticPublisherCategory: getPublisherOptions(),
    distribution_area_List: npcDuck.npcSettingList.distribution_area,
    rangeList:
      superAdminSettingList && superAdminSettingList.copies_range
        ? superAdminSettingList.copies_range
        : [],
    initialValues,
    loading,
    formValues
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...NewsPaperCompanyDuck.actions,
    ...npcDuck.actions,
    changeFieldValue,
    untouchField,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "AddCompany",
      enableReinitialize: true,
      destroyOnUnmount: false,
      onSubmitFail: (errors) => {
        focusOnError(errors);
      },
    })(AddCompany)
  )
);
