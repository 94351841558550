import React from "react";
import { connect } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { Field, reduxForm, change, untouch } from "redux-form";
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import { Alert } from "react-bootstrap";
import { renderText } from "../../../../common/layout/FormInput";
import { injectIntl } from "react-intl";
import * as adManagementDuck from "../../../../store/ducks/super-admin/AdManagement.duck";
import { MESSAGES } from "../../../../config/message";
import { Loader } from "../../../../common/Loader";
import { Label, Input } from "reactstrap";
import {
  required,
  gminLength,
  maxLengthC100,
} from "../../../../config/validation";
import { toastr } from "react-redux-toastr";
import { FILE_SIZE } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import RadioButtonRP from "../../../../common/RadioButtonRP";

class AddTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      show: false,
      isRedirect: false,
      message: "",
      flag: false,
      isSubmitted: true,
      loading: false,
      files: [],
      pictures: [],
      adType: false,
      imageArray: [],
      images: [],
      imageLoading: false,
    };
  }

  /**
   * @method toggleModel
   * @description Used to cancel modal
   */
  toggleModel = () => {
    this.props.dispatch(change("AddTypeModal", "ad_name", ""));
    this.props.dispatch(untouch("AddTypeModal", "ad_name"));
    this.setState({
      pictures: [],
      adType: false,
      overSizedImage: false,
      invalidImage: false,
    });
    this.props.onCancel();
  };

  /**
   * @method checkvalidation
   * @description Check validation and display alert
   */
  checkvalidation = () => {
    const { adTypeName, pictures } = this.state;
    //console.log('adTypeName', adTypeName)
    if (!adTypeName) {
      this.setState({
        show: true,
        success: false,
        message: MESSAGES.SELECT_ADTYPE,
      });
      setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
      return true;
    }
    //  else if (pictures.length < 1 && adTypeName === 'Display') {
    //   this.setState({
    //     show: true,
    //     success: false,
    //     message: MESSAGES.SELECT_IMAGE,
    //   });
    //   setTimeout(() => {
    //     this.setState({ show: false });
    //   }, 3000);
    //   return true;
    // } else if (pictures.length > 10) {
    //   this.setState({
    //     show: true,
    //     success: false,
    //     message: MESSAGES.IMAGE_MAX_LENGTH,
    //   });
    //   setTimeout(() => {
    //     this.setState({ show: false });
    //   }, 3000);
    //   return true;
    // }
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    if(this.checkvalidation()){

    }else{
    const { adType, images } = this.state;
    const requestData = {
      id: '',
      ad_name: values.ad_name,
      ad_type: adType === true ? 'Classified' : 'Display',
      ad_urls: images.length !== 0 ? images : []
    };
    this.setState({ isSubmitted: false, loading: true });
    this.props.addAdType(requestData, res => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ADD_TYPE_ADD_SUCCESS);
        setTimeout(() => {
          this.setState({ show: false, loading: false });
          this.props.dispatch(change('AddTypeModal', 'ad_name', ''));
          this.props.dispatch(untouch('AddTypeModal', 'ad_name'));
          this.setState({ pictures: [] });
        }, 5000);
        this.props.callList();
        setTimeout(() => {
          this.props.onCancel();
        }, 3000);
      } else {
        this.setState({ isSubmitted: true, loading: false });
      }
    });
    }
  }

  /**
   * @method removeItem
   * @description Used to remove images
   */
  removeItem(index) {
    const { pictures, images } = this.state;
    const list = pictures;
    const removeFile = images;
    removeFile.splice(index, 1);
    list.splice(index, 1);
    this.setState({ pictures: list, images: removeFile });
  }

  /**
   * @method renderPreviewImage
   * @description Used to render selected images
   */
  renderPreviewImage = () => {
    const { pictures, imageLoading } = this.state;
    return pictures.map((e, i) => {
      if (imageLoading && i === pictures.length - 1) {
        return (
          <div
            key={i}
            className="my-3 ml-3"
            style={{
              position: "relative",
              height: 100,
              width: 100,
              marginLeft: 20,
              marginTop: 20,
            }}
          >
            <div
              className="spinner-border text-primary"
              role="status"
              style={{ position: "absolute", top: "30%", left: "48%" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      } else {
        return (
          <div key={i} className="my-3 ml-3" style={{ position: "relative" }}>
            <img
              src={e}
              style={{
                height: 100,
                width: 100,
                marginLeft: 20,
                marginTop: 20,
                objectFit: "cover",
              }}
              alt=""
            ></img>
            <i
              className="fa fa-close"
              style={{ position: "absolute", cursor: "pointer" }}
              onClick={() => this.removeItem(i)}
            ></i>
          </div>
        );
      }
    });
  };

  /**
   * @method fileSelectedHandler
   * @description Used to handle file selection
   */
  fileSelectedHandler = (e) => {
    const { pictures, imageArray, files } = this.state;
    let file = e.target.files[0];
    let me = this;
    let fileType = "";
    let size = "";
    let validType = "";
    this.setState({ isSubmitted: true });
    if (file && file !== "undefined") {
      fileType = file.type;
      size = file.size;
      validType =
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/gif";
    }
    if (size <= FILE_SIZE && validType) {
      this.setState(
        {
          overSizedImage: false,
          invalidImage: false,
          imagePreviewUrl: "",
          imgSrc: "",
          isSubmitted: true,
        },
        () => {
          this.getBase64(file, function(base64Data) {
            let encodedImage = me.state.images;
            encodedImage.push(base64Data);
            me.setState({ images: encodedImage, imageLoading: false });
          });
        }
      );
      let temp = pictures;
      let allImage = imageArray;
      allImage.push(e.target.files[0]);
      temp.push(URL.createObjectURL(e.target.files[0]));
      this.setState({
        files: [...files, ...e.target.files],
        pictures: temp,
        imageArray: allImage,
      });
    } else if (size >= FILE_SIZE && validType) {
      this.setState({
        overSizedImage: true,
        invalidImage: false,
        imagePreviewUrl: "",
        imgSrc: "",
        isSubmitted: false,
      });
    } else {
      this.setState({
        invalidImage: true,
        overSizedImage: false,
        imagePreviewUrl: "",
        imgSrc: "",
        isSubmitted: false,
      });
    }
  };

  /**
   * @method getBase64
   * @description Used to convert file in base 64 formate
   */
  getBase64 = (file, callback) => {
    const reader = new FileReader();
    this.setState({ imageLoading: true });
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  /**
   * @method render
   * @description Used to render component
   */
  render() {
    const { handleSubmit } = this.props;
    const {
      show,
      success,
      pictures,
      overSizedImage,
      invalidImage,
      isSubmitted,
      loading,
      message,
      file,
      adType,
      adTypeName
    } = this.state;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.toggleModel}
        styles={{ height: 10, width: 100 }}
        size="lg"
      >
        {loading && <Loader />}

        {show && !success && (
          <Alert
            variant="danger"
            onClose={() => this.setState({ show: false })}
            dismissible
          >
            {message}
          </Alert>
        )}
        <ModalHeader className="mdl-filter-text" toggle={this.toggleModel}>
          Ad Type
        </ModalHeader>
        <ModalBody styles={{ width: 600 }}>
          <Row>
            <Container>
              <form
                noValidate
                className="form"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
              >
                <Row>
                  <Col md="12">
                    <Field
                      name="ad_name"
                      label={"Ad Type Name"}
                      placeholder="Enter Ad Type Name"
                      validate={[required, gminLength, maxLengthC100]}
                      required={true}
                      component={renderText}
                    />
                  </Col>
                </Row>
                <Row className="ml-1 align-items-baseline">
                  <Form.Label md="4">
                    <h6>
                      Ad Classification
                      <span className="asterisk-required">*</span>
                    </h6>
                  </Form.Label>

                  <Col md="8">
                    <div className="radio-inline">
                      <RadioButtonRP label="Classified Ad Type">
                        <Input
                          type="radio"
                          id="checkbox2"
                          checked={adType ? true : ""}
                          onChange={(e) =>
                            this.setState({
                              adType: true,
                              adTypeName: "Classified",
                              isEditCompanyFlag: true,
                            })
                          }
                          name="ad_type"
                        />
                      </RadioButtonRP>
                      <RadioButtonRP label="Display Ad Type">
                        <Input
                          type="radio"
                          id="checkbox2"
                          onChange={(e) =>
                            this.setState({
                              adType: false,
                              adTypeName: "Display",
                              isEditCompanyFlag: true,
                            })
                          }
                          name="ad_type"
                        />
                      </RadioButtonRP>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="ml-1 align-items-center">
                  <Form.Label style={{ alignSelf: "flex-end" }}>
                    <h6>
                      Ad Preview Images
                      { adTypeName === 'Display' && <span className='asterisk-required'>*</span>}
                    </h6>
                  </Form.Label>
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  <input
                    type="file"
                    onChange={this.fileSelectedHandler}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    src={file}
                    style={{ display: "none" }}
                    id="fileButton"
                  />
                  <label
                    for="fileButton"
                    style={{ marginBottom: "auto", cursor: "pointer" }}
                    className="btn btn-secondary"
                  >
                    Upload
                  </label>{" "}
                  {overSizedImage && (
                    <div className="text-help" style={{ marginLeft: "10px" }}>
                      {"File size should be less then 5 MB"}
                    </div>
                  )}
                  {invalidImage && (
                    <div className="text-help" style={{ marginLeft: "10px" }}>
                      {"Only JPEG and PNG, GIF files are accepted."}
                    </div>
                  )}
                </Row> */}
                <Row
                  className="my-2"
                  style={{
                    height: pictures && pictures.length > 0 ? 200 : "auto",
                    overflowY: "auto",
                  }}
                >
                  {this.renderPreviewImage()}
                </Row>
                <Row className="float-right">
                  <Col md="12">
                    <Button
                      disabled={isSubmitted ? false : true}
                      style={{
                        margin: 10,
                        background: "black",
                        border: "none",
                        width: 100,
                      }}
                      size="md"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    adManagementDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AddTypeModal",
    })(AddTypeModal)
  )
);
