import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    getLocationList: "[getLocationList] Action",
    SetSelectedStates: "[SetSelectedStates] Action",
    SetSelectedCities: "[SetSelectedCities] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    profile: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get all records of location list */
            case actionTypes.getLocationList: {
                const { location } = action.payload;
                return { ...state, location: location };
            }

            case actionTypes.SetSelectedStates: {
                const { states } = action.payload;
                return { ...state, selectedState: [...states] };
            }

            case actionTypes.SetSelectedCities: {
                const { cities } = action.payload;
                return { ...state, selectedCities: [...cities] };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {



    /**
    * @method getLocationList
    * @description get all location list(country/state/city)
    */
    getLocationList: (country, state, city) => {
        let api_call;
        if (state === undefined && city === undefined) {
            api_call = `${API.getLocation}?country=${country}&state=&city=`
            const request = axios.get(api_call, {
                headers
            });
            return (dispatch) => {
                request.then((response) => {
                    return dispatch({ type: actionTypes.SetSelectedStates, payload: { states: response.data } })

                }).catch((error) => {
                    apiErrors(error, dispatch);
                    // tokenCheck(dispatch, error)
                });
            };
        }
        else if (city === undefined) {
            api_call = `${API.getLocation}?country=${country}&state=${state}&city=`
            const request = axios.get(api_call, {
                headers
            });
            return (dispatch) => {
                request.then((response) => {
                    return dispatch({ type: actionTypes.SetSelectedCities, payload: { cities: response.data } })

                }).catch((error) => {
                    apiErrors(error, dispatch);
                    // tokenCheck(dispatch, error)
                });
            };
        }
        else if (country && state && city) {
            api_call = `${API.getLocation}?country=${country}&state=${state}&city=${city}`
        }
    },
    /**
      * @method updateComapnyProfile
      * @description Update news paper company details 
      */
    updateComapnyProfile: (requestData, callback) => {
        let request = axios.put(`${API.updateCompanyDetails}`, requestData, {
            headers
        });

        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                apiErrors(error, dispatch)
                callback(error);
            });
        };
    }
};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





