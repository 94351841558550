import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  Portlet,
  PortletBody,
} from "../../../../partials/content/Portlet";
import * as npcDuck from "../../../../store/ducks/npc.duck";
import { injectIntl } from "react-intl";
import { Card } from "react-bootstrap";
import { langs } from "../../../../config/localization";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import './Settings.scss';
import { toastr } from "react-redux-toastr";
import { MESSAGES } from "../../../../config/message";
import { STATUS_CODES } from '../../../../common/StatusCode'

class Language extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: '',
      adTypeFlag: false,
      colorFlag: false,
      language: '',
      key: '',
      selectedItem: undefined,
      updatedId: ''
    };
  }

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.resetList();
  }

  /** 
   * @method resetList
   * @description reset language list
   */
  resetList = () => {
    const { authToken } = this.props.loggedInUser;
    this.props.getDataFromNPCSetting(langs.keyname.language, authToken, res => {
    })
    this.getSelectedLanguage()
  }

  /** 
   * @method getSelectedLanguage
   * @description called to get selected language 
   */
  getSelectedLanguage = () => {
    const { user_detail } = this.props.loggedInUser;
    this.props.getSelectedLanguage(langs.keyname.language, user_detail.newspaper.id, res => {
      if (res.status === STATUS_CODES.OK && Array.isArray(res.data) && res.data.length) {
        let temp = res.data[0].languages[0].id
        let updatedId = res.data[0].languages[0].npc_language.id
        this.setState({ selectedItem: temp, updatedId: updatedId });
      }
    })
  }

  /** 
  * @method onSelection
  * @description called to perform selection 
  */
  onSelection = (item, index) => {
    const { selectedItem, updatedId } = this.state;
    const { user_detail } = this.props.loggedInUser;
    if (selectedItem !== undefined) {
      this.setState({ selectedItem: item.id })
      const requestData = {
        id: updatedId,
        newspaperId: user_detail.newspaper.id,
        languageId: item.id
      }
      this.props.languageUpdate(requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
          toastr.success(MESSAGES.LANGUAGE_UPDATE)
          this.resetList();
        }
      })
    } else {
      this.setState({ selectedItem: item.id, isSubmitted: false })
      const requestData = {
        id: user_detail.newspaper.id,
        newspaperId: user_detail.newspaper.id,
        languageId: item.id
      }
      this.props.globalAdd(langs.keyname.language, requestData, (res) => {
        if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
          toastr.success(MESSAGES.LANGUAGE_ADD)
          this.resetList();
        }
      })
    }
  }

  /**
   * @method renderCardColorItem
   * @description render the color card item
   */
  renderCardColorItem = (typeOfListing) => {
    if (typeOfListing.length === 0) {
      return <div style={{ marginLeft: '360px', marginTop: '-18px' }}>
        {MESSAGES.LANGUAGE_NOT_FOUND}
      </div>
    }
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        let isSelected = (data.id === this.state.selectedItem)
        let style = { width: "32%", height: 'auto', minHeight: '4rem', marginRight: 10, marginBottom: 10 }
        return (
          <Card style={style} key={i} bg={isSelected ? 'dark' : 'light'}
            onClick={() => this.onSelection(data, i)}>
            <Card.Body>
              <Card.Title>
                <label className="textOverflow">{data.name}
                  {isSelected && <label className='round'><input type="checkbox" id="checkbox" checked={true} /> <label htmlFor="checkbox" ></label></label>}
                </label>
              </Card.Title>
            </Card.Body>
          </Card>
        )
      });
    }
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { languageList } = this.props;
    return (
      <div className="row my-5">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>Language</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet fluidHeight={true}>
            <PortletBody >
              <div className="cardWrapper">
                {this.renderCardColorItem(languageList)}
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, npcDuck, authToken } = store;
  return ({
    loggedInUser: auth.user,
    authToken: authToken,
    languageList: Array.isArray(npcDuck.npcSettingList.language) ? npcDuck.npcSettingList.language : [],
    selected_Language: npcDuck.npcSettingList.selected_Language &&  Array.isArray(npcDuck.npcSettingList.selected_Language[0]) ? npcDuck.npcSettingList.selected_Language[0].language : []
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    npcDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "Language"
    })(Language)
  )
);

