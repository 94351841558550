import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetRoleListForSuperAdmin: "[SetRoleListForSuperAdmin] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    roleListForSuperAdmin: []
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for role List */
            case actionTypes.SetRoleListForSuperAdmin: {
                const { roleList } = action.payload;
                return { ...state, roleListForSuperAdmin: [...roleList] };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
  * @method getAllRoles
  * @description get all records based on roles
  */
    getAllRolesWithCount: (superAdminId, callback) => {
        const request = axios.get(`${API.rolesWithCount}?id=${superAdminId}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response)
                return dispatch({ type: actionTypes.SetRoleListForSuperAdmin, payload: { roleList: response.data } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method createRole
     * @description get all records based on advance search
     */
    createRole: (requestData, callback) => {
        let api_call = `${API.roles}`
        const request = axios.post(api_call, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },
    /**
       * @method updateRoleById
       * @description update RoleById 
       */
    updateRoleById: (reqData, callback) => {
        let api_call = `${API.genericAPI}/roles`
        const request = axios.put(api_call, reqData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    /**
     * @method deleteRoleById
     * @description delete role type by id
     */
    deleteRoleById: (data, callback) => {
       const {id, user_id, name} = data
        const request = axios.delete(`${API.genericAPI}/roles?id=${id}&active=${true}&user_id=${user_id}&name=${name}`, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                // tokenCheck(dispatch, error)
                callback(error);
            });
        };
    },

    

};






export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





