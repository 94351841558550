import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Poster from "../../../../../_metronic/_assets/media/images/landing-page/video-thumbnail.png";
// import videomov from "../../../../../_metronic/_assets/media/videos/Pijen-video.mov";
import videomp4 from "../../../../../_metronic/_assets/media/videos/Pijen-Explainer.mp4";
// import videoogg from "../../../../../_metronic/_assets/media/videos/Pijen-video.ogg";
// import videowebm from "../../../../../_metronic/_assets/media/videos/Pijen-video.webm";
import AOS from "aos";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { IconButton } from "@material-ui/core";

//
const Video = () => {
  const [playState, setPlayState] = useState(false);
  const [hideControl, setHideControl] = useState(false);

  const videoRef = useRef();
  useEffect(() => {
    if (!videoRef?.current) return;
    if (playState) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setHideControl(false);
  }, [playState]);
  useEffect(() => {
    if (hideControl) return;
    const timer = setTimeout(() => setHideControl(true), 2000);
    return () => clearTimeout(timer);
  }, [playState, hideControl]);

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    AOS.refresh();
  });

  const HandlerPoster = () => {
    videoRef.current.load();
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  }

  return (
    <>
      <div className='lp_video_mainSection'>
        <div className='lp_video_section'>
          <Container>
            <div className='video_part'>
              <div className='heading text-center'>
                <h2
                  data-aos='fade-up'
                  data-aos-duration='800'
                  data-aos-delay='300'
                  data-aos-anchor-placement='top-bottom'>
                  Watch our video and see what Pijen can do for you
                </h2>
              </div>
              <div
                className='body_part'
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-delay='400'
                data-aos-anchor-placement='top-bottom'>
                <figure className='welcome-video'>
                  <video
                    width='1190'
                    height='650'
                    poster={Poster}
                    ref={videoRef}
                    id='welcome-page-video'
                    onMouseMove={() => setHideControl(false)} playsInline controls onPlay={() => setPlayState(true)} onPause={() => setPlayState(false)} onEnded={HandlerPoster} controlsList="nodownload">
                      <source src={videomp4} type='video/mp4'/>
                  </video>
                  <IconButton
                    onClick={() => setPlayState(!playState)}
                    className={
                      hideControl ? `w-video-control` : "w-video-control hide"
                    }>
                    {playState ? (
                      <PauseCircleFilledIcon
                        className='video_icon'
                        style={{ fontSize: 100 }}
                      />
                    ) : (
                      <PlayCircleFilledIcon
                        className='video_icon2'
                        style={{ fontSize: 100 }}
                      />
                    )}
                  </IconButton>
                </figure>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Video;
