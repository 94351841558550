import React from "react";
import "./FormInput.scss";
import { Form } from "react-bootstrap";
import {
    Switch, TextField
} from "@material-ui/core";

/*
@method: renderLoginTextInputField
@desc: Render login page input
*/
export function renderLoginTextInputField(field) {
    const { input, meta: { touched, error }, ...others } = field;
    return (
        <div className="input-form-group email-block">
            <div className="input-group" style={{ width: 350 }}>
                <input maxLength={field.mxLength} {...others} type="email" className={`form-control`} {...input} />
            </div>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderLoginPasswordInputField
@desc: Render number type input
*/
export function renderLoginPasswordInputField(field) {
    const { input, meta: { touched, error }, ...others } = field;
    const InputClassName = `form-control ${field.className ? field.className : ''}`;
    return (
        <div className="input-form-group">
            <div className="input-group" style={{ width: 350 }}>
                <input maxLength={32} type="password" className={InputClassName} {...input} />
            </div>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderPasswordInputField
@desc: Render password input
*/
export function renderPasswordInputField(field) {
    const { input, meta: { touched, error, active } } = field;
    const inputbox = `inputbox ${active ? 'active' : ''}`;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    const InputClassName = `form-control ${field.className ? field.className : ''}`;
    return (
        <div className={className}>
            <label>{field.label}{(field.required && field.required === true) ? <span className="asterisk-required">*</span> : ''}</label>
            <div className={inputbox}>
                <input type="password" maxLength={field.mxLength} className={InputClassName} {...input} placeholder={field.placeholder} />
            </div>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderTextInputField
@desc: Render text input
*/
export function renderTextInputField(field) {
    const { input, width, meta: { touched, error, active }, ...others } = field;
    const inputbox = `inputbox ${active ? 'active' : ''}`;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    const InputClassName = `form-control ${field.className ? field.className : ''}`;
    return (
        <div className={className}>
            <div className={inputbox}>
                <input style={{ width: width ? width : 400 }} maxLength={field.mxLength} {...others} type="text" className={`form-control ${InputClassName}`} {...input} />
            </div>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderEmailInputField
@desc: Render email input
*/
export function renderEmailInputField(field) {
    const { input, isDisabled, meta: { touched, error, active }, ...others } = field;
    const disabled = isDisabled === true ? true : false;
    const inputbox = `inputbox ${active ? 'active' : ''}`;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    const InputClassName = `form-control ${field.className ? field.className : ''}`;
    return (
        <div className={className}>
            <label>{field.label}{(field.required && field.required === true) ? <span className="asterisk-required">*</span> : ''}</label>
            <div className={inputbox}>
                <input {...others} disabled={disabled} type="text" className={InputClassName} {...input} />
            </div>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderFileInputField
@desc: Render file input
*/
export function renderFileInputField(field) {
    const { input, isDisabled, meta: { touched, error, active }, ...others } = field;
    const inputbox = `inputbox ${active ? 'active' : ''}`;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    const InputClassName = `form-control ${field.className ? field.className : ''}`;
    return (
        <div className={className}>
            <label>{field.label}{(field.required && field.required === true) ? <span className="asterisk-required">*</span> : ''}</label>
            <div className={inputbox}>
                <input {...others} type="file" value={field.input.value} className={InputClassName} {...input} />
            </div>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderCheckboxInputField
@desc: Render radio input
*/
export function renderCheckboxInputField(field) {
    const { input, meta: { touched, error }, ...others } = field;
    return (
        <div className="InputClassName align-items-center remember float-left">
            <input {...others} id={field.input.name} className="m-0 align-middle" {...input} onChange={field.onChange} defaultChecked={false} />
            <label htmlFor={field.input.name} className="font-weight-bold m-0 ml-1 align-middle">{field.label}</label>
            <div className="text-help">{(touched) ? error : ''}</div>
        </div>
    );
}

/*
@method: renderThemeSelectOptions
@desc: Render radio input
*/
export function renderThemeSelectOptions(field) {
    const { input, meta: { touched, error }, data, ...others } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    const InputClassName = `form-control ${
        field.className ? field.className : ""
        }`;
    return (
        <div className={className}>
            <Form.Control as="select" {...input} style={{ height: 40 }} className={InputClassName}>
                {field.textToShow === true && !field.isRegister && (
                    <option className="selectOption" value="">Select</option>
                )}
                {field.isSelect === false && !field.isRegister && (
                    <option className="selectOption" value="">Select Role Type</option>
                )}
                {field.isSelect === false && field.isRegister && (
                    <option className="selectOption" value="" style={{ paddingLeft: 0 }}>Your Role</option>
                )}
                {Array.isArray(data) && data.map((role) => <option className="selectOption" key={role.id} value={role.id}>{role.name}</option>)}
            </Form.Control>
            <div className="text-help">{touched ? error : ""}</div>
        </div>
    );
}

/*
@method: renderThemeSelectOptions
@desc: Render radio input
*/
export function renderThemeInputText(field) {
    const { input, type, label, meta: { touched, error }, data, ...others } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    const InputClassName = `form-control ${
        field.className ? field.className : ""
        }`;
    return (
        <div className="form-group">
            <TextField
                type={type}
                label={label}
                margin="normal"
                className="kt-width-full"
                name="newspaper_name"
            />
        </div>
    );
}
/*
@method: renderThemeSelectOptions
@desc: Render radio input
*/
export function renderThemeToggleButton(field) {
    const { input, meta: { touched, error }, checked, ...others } = field;
    const InputClassName = `input-form-group ${field.className ? field.className : ''}`;
    return (
        <Switch
            {...input}
            checked={checked}
            inputProps={{ "aria-label": "secondary checkbox" }}
        />
    );
}

/*
@method: focusOnError
@desc: focus on the error input
*/
export const focusOnError = (errors) => {
    if (typeof errors !== "undefined" && errors !== null) {
        const errorEl = document.querySelector(
            Object.keys(errors).map(fieldName => {
                return `[name="${fieldName}"]`
            }).join(",")
        );

        if (errorEl && errorEl.focus) {
            errorEl.focus();
        }
    }
}

