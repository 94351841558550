import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Button } from "@material-ui/core";
import * as customerDuck from "../../../../store/ducks/super-admin/Customer.duck";
import { toastr } from "react-redux-toastr";
import StandardTable from "../../StandardTable";
import { MESSAGES } from "../../../../config/message";
import { Loader } from "../../../../common/Loader";
import { langs } from "../../../../config/localization";
import { checkPermission } from "../../../../common/CheckPermissions";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { PAGES_25 } from "../../../../config/constant";
import { GrantIconButton } from "../../../../common/Buttons";

class ArchiveCustomerListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditFlag: true,
      isRedirect: false,
      rows: [],
      page: 1,
      pageSize: PAGES_25,
      showSearchResults: false,
      searchKey: "",
    };
  }

  /**
   * @method componentDidMount
   * @description Called after rendering the component
   */
  componentDidMount() {
    this.getArchiveCustomerListing(this.state.pageSize, 1);
  }

  /**
   * @method onPageChange
   * @description handle page change
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getArchiveCustomerListing(
      this.state.pageSize,
      page + 1,
      this.state.searchKey
    );
  };

  /**
   * @method onPageSizeChange
   * @description handle page
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getArchiveCustomerListing(pageSize, this.state.page);
    });
  };

  handleSearch = (e) => {
    if (!e.target.value) {
      this.setState({ showSearchResults: false, searchKey: e.target.value });
      this.getArchiveCustomerListing(this.state.pageSize, 1, "");
    } else {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let requestData = {
        searchKey: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
        is_deleted: 1,
        status: 2,
      };
      this.getArchiveCustomerListing(
        this.state.pageSize,
        1,
        requestData.searchKey
      );
    }
  };

  /**
   * @method getArchiveCustomerListing
   * @description Called for getting listing of newspaper company
   */
  getArchiveCustomerListing = (row, page, search) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      id: user_detail.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      search: search !== undefined ? search : "",
      is_deleted: 1,
    };
    this.props.getCustomerListing(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ adminList: res.data.rows });
      }
    });
  };
  /**
   * @method activateAdmin
   * @description Activate Admin from Archive Listing and put It into  All admin List
   */
  activateCompany = (customerDetails) => {
    const { searchKey, showSearchResults } = this.state;

    let requestData = {
      id: customerDetails.id,
      status: 1,
      is_deleted: 0,
      active: 1,
    };
    this.props.updateCustomerProfile(requestData, (res) => {
      if (res.status === STATUS_CODES.CREATED) {
        toastr.success(MESSAGES.ACTIVE_CUSTOMER_SUCCESS);
        this.getArchiveCustomerListing();
        if (showSearchResults) {
          let requestData = {
            searchKey: searchKey,
            is_deleted: 1,
            status: 2,
          };
          this.getArchiveCustomerListing(
            this.state.pageSize,
            1,
            this.state.searchKey
          );
        }
      }
    });
  };

  /**
   * @method renderRecords
   * @description Used to render record list
   */
  renderRecords = () => {
    const createPer = checkPermission(
      langs.permissionKeys.customer,
      langs.permissionKeys.create
    );

    const columnOrder = [
      {
        Header: "Name",
        Cell: (row) => {
          const { original } = row;
          return (
            <span className="text-truncate">{`${original.first_name} ${original.last_name}`}</span>
          ); // Custom cell components!)
        },
      },
      {
        Header: "Email",
        Cell: (row) => {
          const { original } = row;
          return <span>{`${original.email}`}</span>; // Custom cell components!)
        },
      },
      {
        Header: "Phone No.",
        accessor: "role",
        Cell: (row) => {
          const { original } = row;
          let match =
            original && original.phone_number
              ? original.phone_number.match(/^(\d{3})(\d{3})(\d{4})$/)
              : "";
          return match ? match[1] + "-" + match[2] + "-" + match[3] : "";
        },
      },
      {
        Header: "User Type",
        accessor: "role.name", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original["role.name"];
        },
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.status === 2 ? (
            <span style={{ color: "red" }}>Rejected</span>
          ) : (
            <span style={{ color: "red" }}>De-activated</span>
          );
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        Cell: (row) => {
          const { original } = row;
          return (
            createPer && (
              <GrantIconButton
                title="Activate"
                onClick={() => this.activateCompany(original)}
              />
            )
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { loading, searchResults, archiveCompanyList } = this.props;
    const { showSearchResults, totalCount } = this.state;
    const viewPer = checkPermission(
      langs.permissionKeys.customer,
      langs.permissionKeys.view
    );

    let results;
    if (searchResults && searchResults.length && showSearchResults === true) {
      results = this.props.searchResults;
    } else if (searchResults === undefined) {
      results = [];
    } else if (searchResults.length === 0 && showSearchResults === true) {
      results = archiveCompanyList;
    } else {
      results = archiveCompanyList;
    }

    return (
      <div>
        {loading && <Loader />}
        {viewPer ? (
          <StandardTable
            handelSearch={this.handleSearch}
            columnOrder={this.renderRecords()}
            totalCount={totalCount}
            allData={this.state.archiveCompanyList}
            data={results}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          />
        ) : (
          <div className="text-center" style={{ marginTop: "10px" }}>
            <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
          </div>
        )}
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth, customerDuck, authToken } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    archiveCompanyList: customerDuck.customerList,
    searchResults: customerDuck.searchResultsforArchieveCustomer,
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, customerDuck.actions)(ArchiveCustomerListing)
);
