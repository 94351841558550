import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../../../_metronic/_assets/media/images/landing-page/logo.png";
import gridOne1 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid1-1.png";
import gridOne2 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid1-2.png";
import gridOne3 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid1-3.png";
import gridTwo1 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid2-1.png";
import gridTwo2 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid2-2.png";
import Arrow1 from "../../../../../_metronic/_assets/media/images/landing-page/arrow-1.png";
import Arrow2 from "../../../../../_metronic/_assets/media/images/landing-page/arrow-2.png";
import Arrow3 from "../../../../../_metronic/_assets/media/images/landing-page/arrow-3.png";
import gridThree1 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid3-1.png";
import gridThree2 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid3-2.png";
import gridThree3 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid3-3.png";
import gridFour1 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid4-1.png";
import gridFour2 from "../../../../../_metronic/_assets/media/images/landing-page/errors-grid4-2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
const Process = () => {
  React.useEffect(() => {
    AOS.init({
      easing: "ease-in-out-sine",
      disable: "mobile",
      once: true,
    });
    AOS.refresh();
  });
  return (
    <>
      <div className="process_main_section">
        <Container>
          <Row>
            <Col xl={12}>
              <div className="heading">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                  data-aos-anchor-placement="top-bottom"
                >
                  Minimize errors that will impact your clients
                </h2>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <div className="swiper-3-container">
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                className="swiper-3"
                // loop={true}
                // autoplay={{ disableOnInteraction: false }}
              >
                <SwiperSlide>
                  <div
                    style={{
                      border: "1px sold red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="display4">Item 1</h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{
                      border: "1px sold red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="display4">Item 2</h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{
                      border: "1px sold red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="display4">Item 2</h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{
                      border: "1px sold red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="display4">Item 3</h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{
                      border: "1px sold red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="display4">Item 4</h1>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </Row> */}
          <Row className="grid_row">
            <Col xl={3} lg={6} className="position-relative col_grid">
              <div
                className="grid_col grid1"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="700"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="logo">
                  <img
                    src={Logo}
                    alt="logo"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="1000"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                  />
                </div>
                <div className="image_content">
                  <img
                    className="gridOne1"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="1200"
                    data-aos-easing="linear"
                    src={gridOne1}
                    alt="grid"
                  />
                  <img
                    className="gridOne2"
                    data-aos="zoom-in-right"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="800"
                    data-aos-delay="1400"
                    src={gridOne2}
                    alt="grid"
                  />
                  <img
                    className="gridOne3"
                    data-aos="zoom-in-left"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="800"
                    data-aos-delay="1600"
                    src={gridOne3}
                    alt="grid"
                  />
                </div>
              </div>
              <img
                className="arrowImage1"
                data-aos="zoom-in-right"
                data-aos-duration="800"
                data-aos-delay="1800"
                src={Arrow1}
                alt="arrow"
              />
            </Col>
            <Col xl={3} lg={6} className="position-relative col_grid">
              <div
                className="grid_col grid2"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="2200"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="heading">
                  <h5
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="2400"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                  >
                    Smoother media buying
                  </h5>
                </div>
                <div className="image_content">
                  <img
                    className="gridTwo1"
                    data-aos="zoom-in"
                    data-aos-duration="800"
                    data-aos-delay="2500"
                    src={gridTwo1}
                    alt="grid"
                  />
                  <img
                    className="gridTwo2"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="2700"
                    src={gridTwo2}
                    alt="grid"
                  />
                </div>
              </div>
              <img
                className="arrowImage2"
                data-aos="zoom-in-right"
                data-aos-duration="1000"
                data-aos-delay="2800"
                src={Arrow2}
                alt="arrow"
              />
            </Col>
            <Col xl={3} lg={6} className="position-relative col_grid">
              <div
                className="grid_col grid3"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="3100"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="heading">
                  <h5
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="3250"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                  >
                    Happy clients
                  </h5>
                </div>
                <div className="image_content">
                  <img
                    className="gridThree1"
                    data-aos="zoom-in-left"
                    data-aos-duration="800"
                    data-aos-delay="3400"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                    src={gridThree1}
                    alt="grid"
                  />
                  <img
                    className="gridThree2"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="3500"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                    src={gridThree2}
                    alt="grid"
                  />
                  <img
                    className="gridThree3"
                    data-aos="zoom-in-right"
                    data-aos-duration="800"
                    data-aos-delay="3650"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                    src={gridThree3}
                    alt="grid"
                  />
                </div>
              </div>
              <img
                className="arrowImage3"
                data-aos="zoom-in-right"
                data-aos-duration="800"
                data-aos-delay="3800"
                data-aos-anchor-placement="top-bottom"
                data-aos-easing="linear"
                src={Arrow3}
                alt="arrow"
              />
            </Col>
            <Col xl={3} lg={6} className="position-relative col_grid">
              <div
                className="grid_col grid4"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="4000"
                data-aos-anchor-placement="top-bottom"
                data-aos-easing="linear"
              >
                <div className="heading">
                  <h5
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="4200"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                  >
                    More ads!
                  </h5>
                </div>
                <div className="image_content">
                  <img
                    className="gridFour1"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="4400"
                    data-aos-easing="linear"
                    src={gridFour1}
                    alt="grid"
                  />

                  <img
                    className="gridFour2"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-delay="4500"
                    data-aos-anchor-placement="top-bottom"
                    src={gridFour2}
                    alt="grid"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Process;
