export const permissions = {
    //----Row1-------
    dashboard: {
        lable: {
            show: true,
            lable: 'Dashboard',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'dashboard'
    },

    //----Row2-------
    adManagement: {
        lable: {
            show: true,
            lable: 'Ad Management',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'adManagement'
    },
    //     //----Row3-------
    adType: {
        section: {
            show: true,
            lable: 'Ad Type',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'adManagement',
        name: 'adType'
    },
    //     //----Row5-------
    manageColor: {
        section: {
            show: true,
            lable: 'Manage Color',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'adManagement',
        name: 'manageColor'
    },
    manageClassifiedTemplate: {
        section: {
            show: true,
            lable: 'Manage Classified Template',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'adManagement',
        name: 'manageClassifiedTemplate'
    },


    //----Row6-------
    orders: {
        lable: {
            show: true,
            lable: 'Orders',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'orders'
    },
    //----Row7-------
    orderListing: {
        section: {
            show: true,
            lable: 'Order Listing ',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'orders',
        name: 'orderListing'
    },
    //----Row8-------
    orderDetails: {
        section: {
            show: true,
            lable: 'Order Details',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'orders',
        name: 'orderDetails'
    },

    //----Row9-------
    userManagement: {
        lable: {
            show: true,
            lable: 'User Management',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'userManagement'
    },
    //----Row10-------
    admin: {
        section: {
            show: true,
            lable: 'Admin',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'userManagement',
        name: 'admin'
    },
    //----Row11-------
    newspaperCompany: {
        section: {
            show: true,
            lable: 'Newspaper Company',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'userManagement',
        name: 'newspaperCompany'
    },
    //----Row12-------
    customer: {
        section: {
            show: true,
            lable: 'Customer',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'userManagement',
        name: 'customer'
    },
    //----Row13-------
    newspaperCompanyModeration: {
        section: {
            show: true,
            lable: 'Newspaper Company - Moderation',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'userManagement',
        name: 'newspaperCompanyModeration'
    },


    //----Row14-------
    emailTemplate: {
        lable: {
            show: true,
            lable: 'Email Template',
        },
        accessRight: {
            show: true,
            value: false,
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        name: 'emailTemplate'
    },

    //----Row15-------
    messages: {
        lable: {
            show: true,
            lable: 'Messages',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'messages'
    },

    //----Row16-------
    notifications: {
        lable: {
            show: true,
            lable: 'Notifications',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'notifications'
    },


    //----Row17-------
    settings: {
        lable: {
            show: true,
            lable: 'Settings',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'settings'
    },
    campaignList: {
        lable: {
            show: true,
            lable: 'Campaign List',
        },
        accessRight: {
            show: true,
            value: false,
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        name: 'campaignList'
    },
    campaignTemplate: {
        lable: {
            show: true,
            lable: 'Campaign Template',
        },
        accessRight: {
            show: true,
            value: false,
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        name: 'campaignTemplate'
    },
    client: {
        lable: {
            show: true,
            lable: 'Client',
        },
        accessRight: {
            show: true,
            value: false,
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        name: 'client'
    },
    billing: {
        lable: {
            show: true,
            lable: 'Billing',
        },
        accessRight: {
            show: true,
            value: false,
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        name: 'billing'
    },
    calendarList: {
        lable: {
            show: true,
            lable: 'Calendar List',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'calendarList'
    },
    //----Row18-------
    bankInformation: {
        section: {
            show: true,
            lable: 'Bank Information',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'bankInformation'
    },
    //----Row19-------
   
    companyCategory: {
        section: {
            show: true,
            lable: 'Company Category',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'companyCategory'
    },
    //----Row20-------
    printFrequency: {
        section: {
            show: true,
            lable: 'Print Frequency',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'printFrequency'
    },
    //----Row21-------
    newspaperCategory: {
        section: {
            show: true,
            lable: 'Newspaper Category',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'newspaperCategory'
    },
    //----Row22-------
    distributionAreas: {
        section: {
            show: true,
            lable: 'Distribution Areas',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'distributionAreas'
    },
    //----Row23-------
    reasonforRejection: {
        section: {
            show: true,
            lable: 'Reason for Rejection',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'reasonforRejection'
    },
    //----Row24-------
    language: {
        section: {
            show: true,
            lable: 'Language',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        subsection: 'settings',
        name: 'language'
    },

    section: true,

    //----Row25-------
    financials: {
        lable: {
            show: true,
            lable: 'financials',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'financials'
    },
    //----Row26-------
    payments: {
        section: {
            show: true,
            lable: 'Payments',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true
        },
        subsection: 'financials',
        name: 'payments'
    },
    //----Row27-------
    receipts: {
        section: {
            show: true,
            lable: 'Receipts',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true
        },
        subsection: 'financials',
        name: 'receipts'
    },

    //----Row28-------
    reports: {
        lable: {
            show: true,
            lable: 'Reports',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'reports'
    },
    newreports: {
        lable: {
            show: true,
            lable: 'NewReports',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'newreports'
    },
    //----Row29-------
    paymentHistory: {
        section: {
            show: true,
            lable: 'Payment History',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true

        },
        subsection: 'reports',
        name: 'paymentHistory'
    },
    //----Row30-------
    sales: {
        section: {
            show: true,
            lable: 'Sales',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true

        },
        subsection: 'reports',
        name: 'sales'
    },
    //----Row31-------
    order: {
        section: {
            show: true,
            lable: 'Order',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true

        },
        subsection: 'reports',
        name: 'order'
    },
    //----Row32-------
    customerSummary: {
        section: {
            show: true,
            lable: 'Customer Summary',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true

        },
        subsection: 'reports',
        name: 'customerSummary'
    },
    companySummary: {
        section: {
            show: true,
            lable: 'Company Summary',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true

        },
        subsection: 'reports',
        name: 'companySummary'
    },
    //----Row33-------
    reportsAdType: {
        section: {
            show: true,
            lable: 'Ad Type',
        },
        view: {
            show: true,
            value: false,
            section2: true
        },
        print: {
            show: true,
            value: false,
            section2: true

        },
        subsection: 'reports',
        name: 'reportsAdType'
    },
    //Row 34
    helpAndSupport: {
        lable: {
            show: true,
            lable: 'Help And Support',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'helpAndSupport'
    },
    //Row 35
    subscription: {
        lable: {
            show: true,
            lable: 'Subscription',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'subscription'
    },
    //Row 36
    mediaLibrary: {
        lable: {
            show: true,
            lable: 'Media Library',
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'mediaLibrary'
    },
    //Row 37
    publisherSecondaryMail: {
        lable: {
            show: true,
            lable: "Publishers Email",
        },
        accessRight: {
            show: true,
            value: false,
        },
        name: 'publisherSecondaryMail'
    },
    //Row 38
    seasonalIssues: {
        lable: {
            show: true,
            lable: 'Seasonal Issues',
        },
        create: {
            show: true,
            value: false,
        },
        delete: {
            show: true,
            value: false,
        },
        view: {
            show: true,
            value: false,
        },
        name: 'seasonalIssues'
    }
}