import React, { useEffect } from "react";
import Nav from "../errors/Nav";
import PricingPlan from "../errors/pijenhomelanding/PricingPlan";
import FooterSec from "../errors/landingpage/components/FooterSec";

export default function PricingPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const newUser = queryParams.get("newUser");
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=8aa84ff2-cf5f-4c96-ae2a-bfde6bfdbd6f";
    script.async = true;
    script.id = "ze-snippet";
    document.body.appendChild(script);
    return () => {
      const element = document.getElementById("ze-snippet");
      element.remove();
    };
  });
  return (
    <>
      <Nav />
      <PricingPlan newUser={newUser}/>
      <FooterSec />
    </>
  );
}
