import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import _ from "lodash";
import {
  renderNumberField,
  renderText,
  renderDatePicker,
} from "../../../../common/layout/FormInput";
import { number } from "../../../../config/validation";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { toastr } from "react-redux-toastr";
import { convertISOToUtcDateformate2 } from "../../../../common";
import { PAGES_25 } from "../../../../config/constant";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { MESSAGES } from "../../../../config/message";
import { normalizePhone } from "../../../../config/validation";
import { ButtonRP } from "../../../../common/Buttons";

const AdvancedSearchCredit = (props) => {
  // {
  //     error,
  //     reset,
  //     loggedInUser,
  //     handleSubmit,
  //     resetAdvancedSearch,
  // }) => {

  // extract logged in user details
  const { user_detail } = props.loggedInUser;

  // state contains all the Ad Types list of logged in NPC user
  const [startExpiryDate, setStartExpiryDate] = useState("");
  const [endExpiryDate, setEndExpiryDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pageSize, setPageSize] = useState(PAGES_25);

  const [loading, setLoading] = useState(false);

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  const getCreditList = (row, page, sort, column, searchKey) => {
    let requestData = {
      npc_id: user_detail.newspaper.id ? user_detail.newspaper.id : "",
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      searchKey: searchKey,
    };
    props.getAllCreditList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        //  let pages = res.data.count / pageSize
        //  this.setState({ orderList: res.data.rows, totalCount: Math.ceil(pages) })
      }
    });
  };

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values) => {
    if (Object.keys(values).length === 0) {
      //do
      return toastr.warning(MESSAGES.PLEASE_SELECT_FILTER);
    }

    //const { startDate, endDate, startPublishDate, endPublishDate } = this.state
    const { user_detail } = props.loggedInUser;

    if (
      (values.to_cancel_date && !values.from_cancel_date) ||
      (values.from_cancel_date && !values.to_cancel_date)
    ) {
      return toastr.warning(MESSAGES.DATE_VALIDATION);
    } else if (
      (values.to_expiry_date && !values.from_expiry_date) ||
      (values.from_expiry_date && !values.to_expiry_date)
    ) {
      return toastr.warning(MESSAGES.PUBLISH_DATE_VALIDATION);
    } else if (
      (values.to_amount && !values.from_amount) ||
      (values.from_amount && !values.to_amount)
    ) {
      return toastr.warning(MESSAGES.AMOUNT_VALIDATION);
    }
    const requestData = {
      newspaper_name: values.newspaper_name
        ? encodeURIComponent(values.newspaper_name)
        : "",
      customer_name: values.customer_name
        ? encodeURIComponent(values.customer_name)
        : "",
      from_cancel_date: values.from_cancel_date
        ? convertISOToUtcDateformate2(startDate)
        : "",
      to_cancel_date: values.to_cancel_date
        ? convertISOToUtcDateformate2(endDate)
        : "",
      from_expiry_date: values.from_expiry_date
        ? convertISOToUtcDateformate2(startExpiryDate)
        : "",
      to_expiry_date: values.to_expiry_date
        ? convertISOToUtcDateformate2(endExpiryDate)
        : "",
      order_id: values.order_id ? encodeURIComponent(values.order_id) : "",
      from_amount: values.from_amount
        ? encodeURIComponent(values.from_amount)
        : "",
      to_amount: values.to_amount ? encodeURIComponent(values.to_amount) : "",
      npc_id: user_detail.newspaper.id ? user_detail.newspaper.id : "",
    };
    const empty_fields =
      values && Object.keys(values).length === 0 ? true : false;
    setLoading(true);
    if (!empty_fields) {
      props.getCreditListingFromAdvanceSearch(requestData, (res) => {
        setLoading(false);

        //Bottom scroll
        window.scrollTo(0, document.documentElement.scrollHeight);
      });
    } else {
      resetFilter();
    }
  };

  /**
   * @method resetFilter
   * @description  used to reset filter
   */
  const resetFilter = () => {
    props.reset();
    setStartExpiryDate("");
    setEndExpiryDate("");
    setStartExpiryDate("");
    setEndDate("");
    getCreditList(pageSize, 1, "", "", "");
  };

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={props.handleSubmit(onSubmitForm)}>
            <div className="form-row">
              <div className="col-md-3">
                <Field
                  name="order_id"
                  label="Order No."
                  type="text"
                  component={renderNumberField}
                  normalize={normalizePhone}
                  validate={[number]}
                  placeholder="Enter order Id"
                  min="0"
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="customer_name"
                  component={renderText}
                  label="Customer Name"
                  placeholder="Enter Customer Name"
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="from_cancel_date"
                  label="From Cancel Date"
                  minDate={endDate}
                  onChange={(e) => {
                    setStartDate(e);
                    props.change("to_cancel_date", "");
                  }}
                  component={renderDatePicker}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="to_cancel_date"
                  label="To Cancel Date"
                  minDate={startDate}
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                  component={renderDatePicker}
                />
              </div>

              {(user_detail.newspaper === null ||
                user_detail.newspaper.id === undefined ||
                !user_detail.newspaper.id) && (
                <div className="col-md-3">
                  <Field
                    name="newspaper_name"
                    component={renderText}
                    label="Newspaper Company Name"
                    placeholder="Newspaper name"
                  />
                </div>
              )}
              <div className="col-md-3">
                <Field
                  name="from_amount"
                  label="Minimum Credit Amount"
                  type="text"
                  component={renderNumberField}
                  normalize={normalizePhone}
                  // validate={[number]}
                  min="0"
                  step=".01"
                  placeholder="Min Price"
                  prepend="Min Price"
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="to_amount"
                  type="text"
                  label="Maximum Credit Amount"
                  component={renderNumberField}
                  normalize={normalizePhone}
                  // validate={[number]}
                  min="0"
                  step=".01"
                  placeholder="Max Price"
                  prepend="Max Price"
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="from_expiry_date"
                  label="From Expiry Date"
                  minDate={endDate}
                  onChange={(e) => {
                    setStartExpiryDate(e);
                    props.change("to_expiry_date", "");
                  }}
                  component={renderDatePicker}
                />
              </div>

              <div className="col-md-3">
                <Field
                  name="to_expiry_date"
                  label="To Expiry Date"
                  minDate={endDate}
                  onChange={(e) => {
                    setEndExpiryDate(e);
                  }}
                  component={renderDatePicker}
                />
              </div>

              <div className="col-12 mt-3">
                {props.error && (
                  <div className="text-help">{`Error: ${props.error}`}</div>
                )}

                <div className="d-flex-center j-end">
                  <ButtonRP
                    text="Reset"
                    cancel={true}
                    className="mx-2"
                    onClick={resetFilter}
                  />
                  <ButtonRP type="submit" text="Apply" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const selector = formValueSelector("creditAdvancedSearchForm");

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 * @param {*} ownProps
 */
const mapStateToProps = (state, ownProps) => {
  const { auth, npcDuck } = state;
  const { startDate, endDate } = selector(state, "startDate", "endDate");
  return {
    loggedInUser: auth.user,
    searchResults: npcDuck.searchResult ? npcDuck.searchResult : [],
    endDate,
    startDate,
  };
};

export default connect(mapStateToProps, { ...commonDuck.actions })(
  reduxForm({
    form: "AdvancedSearchCredit", // a unique identifier for this form
  })(AdvancedSearchCredit)
);
