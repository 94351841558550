import React from "react";
import {
    Button,
    Modal,
} from "react-bootstrap";
import { MESSAGES } from '../../../../../config/message'

export default class NavigationModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false
        };
    }
    componentWillReceiveProps(nextprops, props) {
        if (props.isOpen !== nextprops.isOpen) {
        this.setState({ show: nextprops.isOpen });
        }
    }

    handleClose() {
        this.setState({ show: false });
        this.props.onCancelClick();
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            <>
                <Modal
                    aria-labelledby="example-modal-sizes-title-sm"
                    show={this.state.show} onHide={this.handleClose}
                    centered={true}
                    dialogClassName="nav-prompt-modal"
                    className="RRR"
                >
                    <Modal.Body>
                        <h5>{MESSAGES.CHANGES_LOST}</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                    </Button>
                        <Button variant="primary" onClick={this.props.onOkClick}>
                            Leave Anyway
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
