import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'
import { Table } from 'reactstrap';
import { Container, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { convertISOToUtcDateformate4 } from '../../../../common'


class CreditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    /**
   * @method toggleModel
   * @description Used to cancel modal
   */
    toggleModel = () => {
        this.props.onCancel();
    }

    render() {
        const { isOpen, creditDetail } = this.props;
        return (
            <Modal
                isOpen={isOpen} 
                toggle={this.toggleModel} 
                styles={{ height: 100 }}
                centered
                >
                <ModalHeader className='mdl-filter-text' toggle={this.toggleModel}>
                </ModalHeader>
                <ModalBody >
                    <Row>
                        <Container>
                            <Table borderless style={{marginLeft:30}}>
                                <thead>
                                    <tr>
                                        <th>Order No.</th>
                                        <th>Date of Order</th>
                                        <th>Amount Used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{creditDetail.order.id}</td>
                                        <td>{convertISOToUtcDateformate4(creditDetail.order.order_date)}</td>
                                        <td>$ {creditDetail.credit_used}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div
                                className='row'
                                style={{
                                    borderBottom: '1px solid #ccc', marginTop: 25,
                                }}
                            ></div>
                            <Row className='float-right' style={{marginTop: 20, marginBottom: 10, marginRight: 45}}>
                                <h4 className='float-right'>Credit Available</h4>&nbsp;&nbsp; &nbsp;&nbsp;
                                <h4>{`$ ${creditDetail.credit_available}.00`}</h4>
                            </Row>
                        </Container>
                    </Row>
                </ModalBody>
            </Modal >
        )
    }
}

const mapStateToProps = store => {

    return ({
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
    })
}

export default injectIntl(
    connect(
        mapStateToProps,
    )(reduxForm({
        // Assign unique name for the form
        form: 'CreditModal'
    })(CreditModal))
);
