import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    GetSuperAdminProfile: "[GetSuperAdminProfile] Action",
    UpdateSuperAdminProfile: "[UpdateSuperAdminProfile] Action",

};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    profile: undefined,
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** get super admin profile data */
            case actionTypes.GetSuperAdminProfile: {
                const { user_details } = action.payload;
                return { ...state, profile: user_details };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
     * @method getSuperAdminProfile
     * @description get super admin details By Id 
     */
    getSuperAdminProfile: (id, callback) => {
        let request = axios.get(`${API.getAdminDetail}?id=${id}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                callback(response.data)
                return dispatch({ type: actionTypes.GetSuperAdminProfile, payload: { user_details: response.data } })
            }).catch((err) => {
                //tokenCheck(dispatch, err)
                apiErrors(err, dispatch)
            })
        }
    },
    /**
   * @method updateSuperAdminProfile
   * @description Update super admin details 
   */
    updateSuperAdminProfile: (requestData, callback) => {
        let request = axios.put(`${API.updateSuperAdminDetails}`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

};


export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





