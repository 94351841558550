import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PortletBody, Portlet } from "../../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import SalesRecordList from "./SalesRecordList";
import FilterRecords from "../FilterRecords";
import { SALES_TREND } from "../../../../../config/constant";
import LineChart from "./LineChart";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { Loader } from "../../../../../common/Loader";
import CountFigureBox from "../../../../../common/CountFigureBox";

class SalesReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      salesTrend: SALES_TREND,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getSalesReport(4, 1, "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getSalesReport = (row, page, sort, column) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      type: 1,
    };
    this.props.getSalesRecord(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        this.setState({ headerData: res.data });
      }
    });
  };

  /**
   * @method renderHeaderCard
   * @description  called after header data
   */
  renderHeaderCard = (lable, value, amtLabel) => {
    return (
      <CountFigureBox>
        <h4 className="heading">{lable}</h4>
        {value ? (
          <h3 className="heading">{`${amtLabel}${value}`}</h3>
        ) : (
          <h3 className="heading">{`${amtLabel}0`}</h3>
        )}
      </CountFigureBox>
    );
  };

  /**
   * @method renderInnerCard
   * @description  render inner sales content
   */
  renderInnerCard = (label, amount, date, amtLabel) => {
    return (
      <CountFigureBox className="mx-2">
        <h4 className="heading">{label}</h4>
        <h4 className="heading">
          {amount ? `${amtLabel} ${amount}` : `${amtLabel}0`}
        </h4>
        <h5 className="heading">{date}</h5>
      </CountFigureBox>
    );
  };

  /**
   * @method renderRevenueCardHeigest
   * @description  render hiegest revenue data
   */
  renderRevenueCardHeigest = (label) => {
    const { headerData } = this.state;
    const total_revenue =
      headerData &&
      headerData.monthRevenue &&
      headerData.monthRevenue.highest &&
      headerData.monthRevenue.highest.sales_revenue;
    const total_orders =
      headerData &&
      headerData.monthRevenue &&
      headerData.monthRevenue.highest &&
      headerData.monthRevenue.highest.total_orders;
    const date =
      headerData && headerData.monthRevenue && headerData.monthRevenue.highest
        ? headerData.monthRevenue.highest.createdAt
        : "";
    return (
      <CountFigureBox>
        <h6 className="heading">{label}</h6>
        <div className="row w-100" style={{ justifyContent: "space-around" }}>
          {this.renderInnerCard("Revenue", total_revenue, date, "$")}
          {this.renderInnerCard("Orders", total_orders, date, "")}
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method renderRevenueCardLowest
   * @description  render lowest revenue data
   */
  renderRevenueCardLowest = (label) => {
    const { headerData } = this.state;
    const total_revenue =
      headerData &&
      headerData.monthRevenue &&
      headerData.monthRevenue.lowest &&
      headerData.monthRevenue.lowest.sales_revenue;
    const total_orders =
      headerData &&
      headerData.monthRevenue &&
      headerData.monthRevenue.lowest &&
      headerData.monthRevenue.lowest.total_orders;
    const date =
      headerData && headerData.monthRevenue && headerData.monthRevenue.lowest
        ? headerData.monthRevenue.lowest.createdAt
        : "";
    return (
      <CountFigureBox>
        <h6 className="heading">{label}</h6>
        <div className="row w-100" style={{ justifyContent: "space-around" }}>
          {this.renderInnerCard("Revenue", total_revenue, date, "$")}
          {this.renderInnerCard("Orders", total_orders, date, "")}
        </div>
      </CountFigureBox>
    );
  };

  /**
   * @method render
   * @description  render component
   */
  render() {
    const { openModal, headerData } = this.state;
    const { loading } = this.props;
    const total_orders =
      headerData && headerData.totalData && headerData.totalData.total_orders;
    const total_sales =
      headerData && headerData.totalData && headerData.totalData.total_sales;
    const commission_fee =
      headerData && headerData.totalData && headerData.totalData.commission_fee;
    const sales_revenue =
      headerData && headerData.totalData && headerData.totalData.sales_revenue;
    return (
      <div>
        {/* {loading && <Loader/>} */}
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h4 className="kt-subheader__title col-md-6">
                <span>Reports (Sales)</span>
              </h4>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row">
          <div className="col-md-12">
            <Portlet fluidHeight={true}>
              <PortletBody>
                <div className="row">
                  <div className="cardWrapper col-md-6">
                    {this.renderHeaderCard(
                      "Total No of Orders",
                      total_orders,
                      ""
                    )}
                  </div>
                  <div className="cardWrapper col-md-6 ">
                    {this.renderHeaderCard("Total Sales", total_sales, "$")}
                  </div>

                  <div className="cardWrapper col-md-6 ">
                    {this.renderHeaderCard("Fees", commission_fee, "$")}
                  </div>
                  <div className="cardWrapper col-md-6">
                    {this.renderHeaderCard("Sales Revenue", sales_revenue, "$")}
                  </div>
                </div>
                <div className="row ">
                  <div className="cardWrapper col-md-6">
                    {this.renderRevenueCardHeigest(
                      "Month with Highest Sales in last 1 Year"
                    )}
                  </div>
                  <div className="cardWrapper col-md-6">
                    {this.renderRevenueCardLowest(
                      "Month with Lowest Sales in last 1 Year"
                    )}
                  </div>
                </div>
                <hr className="my-2" />
                <div className="row">
                  <div className="col-12">
                    <LineChart
                      onCancel={() => this.setState({ openModal: false })}
                      openModal={this.state.openModal}
                      type={"2"}
                    />
                  </div>
                </div>
                <hr className="my-2" />
                <div className="row">
                  <div className="col-12">
                    <SalesRecordList />
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </div>
        </div>
        {openModal && (
          <FilterRecords
            isOpen={openModal}
            value={10}
            onCancel={() => this.setState({ openModal: false })}
            setSelectedOption={12}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "SalesReports",
    })(SalesReports)
  )
);
