import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button } from "@material-ui/core";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import StandardTable from "../../StandardTable";
import { convertISOToUtcDateformate1 } from "../../../../common";
import { langs } from "../../../../config/localization";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { ViewIconLink } from "../../../../common/Buttons";
import { InfoToolTipButton } from "../../../../common/ToolTips";

class AllOrders extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rows: [],
      orderList: [],
      page: 1,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      isOpenModal1: false,
      showPaymentModal: false,
      showConfirmModal: false,
      companyId: "",
      userId: "",
      showSearchResults: false,
      isAdvanceSearch: true,
      newspaperId: undefined,
      message: "",
      advanceSerchResults: false,
      allList: [],
      searchKey: "",
      sortFlag: {
        id: { sort: false },
        first_name: { sort: false },
        ad_type: { sort: false },
        date: { sort: false },
        ad_frequency: { sort: false },
        order_date: { sort: false },
        status: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getOrderList(this.state.pageSize, 1, "", "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderList = (row, page, sort, column, searchKey) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      searchKey: searchKey,
      status: "",
      customer_id: "",
    };
    this.props.getAllOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          orderList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getOrderList(this.state.pageSize, page + 1, "", "", "");
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getOrderList(pageSize, this.state.page, "", "", "");
    });
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    if (e.target.value.length > 2) {
      this.setState({ showSearchResults: true, searchKey: e.target.value });
      let searchKey = encodeURIComponent(
        e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      );
      this.getOrderList(this.state.pageSize, 1, false, false, searchKey);
    } else {
      this.getOrderList(this.state.pageSize, 1, false, false, "");
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    const { sortFlag } = this.state;
    if (column.sortable !== false) {
      let temp = sortFlag;
      temp[column.id].sort = !sortFlag[column.id].sort;
      let sortBy = sortFlag[column.id].sort
        ? langs.sortBy.ASC
        : langs.sortBy.DESC;
      this.setState({ sortFlag: temp });
      column.sortable !== false && instance.sortColumn(column);
      column.sortable !== false &&
        this.getOrderList(this.state.pageSize, 1, sortBy, column.id, "");
    }
  };

  /**
   * @method renderPublishDates
   * @description render publish dates
   */
  // renderPublishDates = (publish_dates) => {
  //   let publishDates;
  //   if (Array.isArray(publish_dates) && publish_dates.length) {
  //     let temp = [];
  //     publish_dates.map((el) => {
  //       temp.push(convertISOToUtcDateformate1(el.date));
  //     });
  //     publishDates = temp;
  //     return publishDates.map((el) => {
  //       return (
  //         <>
  //           {el} <br />
  //         </>
  //       );
  //     });
  //   } else {
  //     return (publishDates = "");
  //   }
  // };

  renderPublishDates = (publish_dates) => {
    let publishDates;
    if (Array.isArray(publish_dates) && publish_dates.length) {
      let temp = [];
      publish_dates.map((el) => {
        temp.push(convertISOToUtcDateformate1(el.date));
      });
      publishDates = temp;

      let threePlusEntry = [];
      return (
        <div>
          {publishDates.map((el, i) => {
            return (
              <span>
                {i <= 2 && (
                  <p style={{ margin: 0 }} key={i}>
                    {el}
                    {i === 2 || publishDates.length - 1 === i ? `` : `,`}
                  </p>
                )}
              </span>
            );
          })}

          {publishDates.length > 3 &&
            publishDates.map((el, i) => {
              i >= 3 && threePlusEntry.push(`${el}`);
            })}
          {publishDates.length > 3 && (
            <span>
              +3 <InfoToolTipButton title={threePlusEntry.toString()} />
            </span>
          )}
        </div>
      );
    } else {
      return (publishDates = "");
    }
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Order #",
        accessor: "id",
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          if (!newspaper_ads_plan) return "";
          const adType = newspaper_ads_plan
            ? newspaper_ads_plan.single_advertisement.ad_type
            : "";
          let url =
            adType === langs.adType.Display
              ? `/displayOrderDetail/${row.original.id}`
              : `/classifiedOrderDetail/${row.original.id}`;
          let str = original.id;
          let StringToShow =
            str.length > 6 ? `${str.substring(1, 6) + "....."}` : str;
          return (
            <div>
              <Link
                to={{
                  pathname: url,
                  state: {
                    npc_id: original.npc_id,
                    user_id: original.user_id,
                  },
                }}
              >
                {StringToShow}
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Ad Type",
        accessor: "ad_type",
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          if (!newspaper_ads_plan) return "";
          return (
            <div style={{ display: "-webkit-box" }}>
              {newspaper_ads_plan.single_advertisement.ad_type}
            </div>
          );
        },
      },
      {
        Header: "Date Of Order",
        accessor: "order_date", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return convertISOToUtcDateformate1(original.order_date);
        },
      },
      {
        Header: "Ad Frequency",
        accessor: "ad_frequency",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ marginLeft: 10 }}>
              {original.ad_frequency ? original.ad_frequency : ""}
            </div>
          );
        },
      },
      {
        Header: "Publish Dates",
        accessor: "date",
        Cell: (row) => {
          const { original } = row;
          return (
            <div style={{ display: "-webkit-box" }}>
              {original.order_date
                ? this.renderPublishDates(original.order_publish_dates)
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Customer",
        accessor: "first_name",
        Cell: (row) => {
          const { original } = row;
          return original.user && original.user.first_name
            ? original.user.first_name + " " + original.user.last_name
            : "";
        },
      },
      {
        Header: "Status",
        accessor: "status", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return original.status;
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        sortable: false,
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          if (!newspaper_ads_plan) return "";
          const adType = newspaper_ads_plan
            ? newspaper_ads_plan.single_advertisement.ad_type
            : "";
          let url =
            adType === langs.adType.Display
              ? `/displayOrderDetail/${row.original.id}`
              : `/classifiedOrderDetail/${row.original.id}`;
          return (
            <div style={{ padding: 5 }}>
              <ViewIconLink
                to={{
                  pathname: url,
                  state: {
                    npc_id: original.npc_id,
                    user_id: original.user_id,
                  },
                }}
                title="View Order"
              />
            </div>
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const { totalCount, isAdvanceSearch } = this.state;
    const { orderList } = this.props;
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span>Orders</span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <Portlet>
          <PortletBody>
            <StandardTable
              handelSearch={this.handleSearch}
              handleSort={this.handleSort}
              columnOrder={this.renderRecords()}
              totalCount={totalCount}
              allData={this.state.orderList}
              data={orderList}
              advancedSearchFor={langs.advancedSearchFor.npcOrderList}
              sortable={true}
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              isAdvanceSearch={isAdvanceSearch}
              history={this.props.history}
            />
          </PortletBody>
        </Portlet>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading, orderList } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    orderList: Array.isArray(orderList) ? orderList : [],
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    commonDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "AllOrders",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(AllOrders)
  )
);
