import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Container, Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { renderText } from '../../../../common/layout/FormInput';
import { toastr } from 'react-redux-toastr';
import * as commonDuck from '../../../../store/ducks/Common.duck';
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import { MESSAGES } from '../../../../config/message';
import { Loader } from '../../../../common/Loader';
import { required, minLength2, maxLength100, minLength5, gminLength } from '../../../../config/validation';
import { superAdminId } from '../../../../config'

class UpdatePayoutsettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false,
            flag: false,
            isSubmitted: true,
            loading: false,
            files: [],
            pictures: [],
            adType: false,
            imageArray: [],
            images: [],
            payoutintialvalues: []
        };
    }

    /**
     * @method toggleModel
     * @description Used to cancel modal
     */
    toggleButton = () => {
        //const history = useHistory();
        this.props.history.push('/gsettings');
        //this.props.onCancel();
    };

    /** 
     * @method componentDidMount
     * @description called before rendering the component
     */
    componentDidMount() {
        this.props.getPayoutSettings(superAdminId, res => {
            this.setState({ payoutintialvalues: res.data });
        });
    }


    /**
     * Submit the login form
     * @param values
     * @desc Calling a login actions && redirecting page based on response and set session Items
     */
    onSubmit(values) {
        const { bankInformation } = this.props;
        this.setState({ isSubmitted: false });
        if (bankInformation && bankInformation !== undefined) {
            this.props.updatePayoutInformation(values, (res) => {
                toastr.success(MESSAGES.DATA_UPDATED);
                this.props.getPayoutSettings(superAdminId, res => { });
                this.setState({ isSubmitted: true })
            })
        }
    }

    render() {
        const { handleSubmit, loading, selectedPayout } = this.props;
        const { isSubmitted, payoutintialvalues } = this.state;
        return (
            <>
                {
                    payoutintialvalues.GATEWAY_URL ? <Row>
                        <Container>
                            <form
                                noValidate
                                className='form'
                                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                            >
                                <Row>
                                    <Col md='4'>
                                        <Field
                                            name='gateway_url'
                                            label='Gateway URL'
                                            validate={[required, gminLength, maxLength100]}
                                            required={true}
                                            placeholder='Enter Gateway URL'
                                            component={renderText}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <Field
                                            name='username'
                                            label='Username'
                                            validate={[required, gminLength, maxLength100]}
                                            required={true}
                                            placeholder='Enter Username'
                                            component={renderText}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <Field
                                            name='syspass'
                                            label='Syspass'
                                            validate={[required, minLength2, maxLength100]}
                                            required={true}
                                            placeholder='Enter Syspass'
                                            component={renderText}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='4'>
                                        <Field
                                            name='sub_id'
                                            label='Sub ID'
                                            validate={[required, minLength2, maxLength100]}
                                            required={true}
                                            placeholder='Enter Sub ID'
                                            component={renderText}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <Field
                                            name='parent_id'
                                            label='Parent ID'
                                            validate={[required, gminLength, maxLength100]}
                                            required={true}
                                            placeholder='Enter Parent ID'
                                            component={renderText}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <Field
                                            name='password'
                                            label='Password'
                                            validate={[required, gminLength, maxLength100]}
                                            maxLength={101}
                                            required={true}
                                            placeholder='Enter Password'
                                            component={renderText}
                                        />
                                    </Col>
                                </Row>
                                <Row className='float-right'>
                                    <Col md='12'>
                                        <Button onClick={this.toggleButton} style={{
                                            margin: 10, background: '#808080',
                                            border: 'none',
                                            background: 'black',
                                            width: 100
                                        }} size='md' >Cancel</Button>
                                        <Button
                                            disabled={isSubmitted ? false : true}
                                            style={{
                                                margin: 10,
                                                background: 'black',
                                                border: 'none',
                                                width: 100
                                            }}
                                            size='md'
                                            type='submit'
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Row> :
                        <div>Please Wait......................</div>
                }

            </>
        );
    }
}

const mapStateToProps = store => {
    const { commonDuck } = store;
    const { loading, selectedPayout } = commonDuck;

    let initialValues = {};
    if (selectedPayout && selectedPayout !== undefined) {
        initialValues = {
            gateway_url: selectedPayout.GATEWAY_URL,
            username: selectedPayout.username,
            syspass: selectedPayout.syspass,
            sub_id: selectedPayout.sub_id,
            parent_id: selectedPayout.parent_id,
            password: selectedPayout.password
        };
    }
    return {
        loggedInUser: store.auth.user,
        authToken: store.auth.authToken,
        bankInformation: selectedPayout,
        loading,
        initialValues,
        selectedPayout
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        { ...commonDuck.actions, ...settingsDuck.actions }
    )(
        reduxForm({
            // Assign unique name for the form
            form: 'UpdateEmailsettings',
            enableReinitialize: true,
            destroyOnUnmount: false,
        })(UpdatePayoutsettings)
    )
);
