import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { langs } from "../../../../../config/localization";
import { LayoutContextConsumer } from "../../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../../store/ducks/Common.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { convertISOToUtcDateformate1 } from "../../../../../common";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import { PAGES_25, PAGES_50, PAGES_100 } from "../../../../../config/constant";
import { ViewIconLink } from "../../../../../common/Buttons";

const tableStyle = {
  border: "none",
  boxShadow: "none",
  width: "100%",
};

class RecievedOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: [],
      searchTempResult: [],
      pageSize: PAGES_25,
      inputFocus: false,
      pageNo: 0,
      page: 1,
      sortFlag: {
        id: { sort: false },
        order_date: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getOrderList(this.state.pageSize, 1, "", "", "");
  }

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getOrderList = (row, page, sort, column, searchKey) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sort,
      column: column,
      searchKey: searchKey,
      status: "Pending",
      customer_id: "",
    };
    this.props.getAllOrderList(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        let pages = res.data.count / this.state.pageSize;
        this.setState({
          orderList: res.data.rows,
          totalCount: Math.ceil(pages),
        });
      }
    });
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    this.setState({ searchParam: [...this.state.searchParam, e.target.value] });
    this.props.handelSearch(e);
  };

  handleSort = (state, rowInfo, column, instance) => {
    const { sortFlag } = this.state;
    return {
      style: {
        height: "auto",
        whiteSpace: "normal",
        textAlign: "left",
      },
      onClick: (e) => {
        if (column.sortable !== false) {
          let temp = sortFlag;
          temp[column.id].sort = !sortFlag[column.id].sort;
          let sortBy = sortFlag[column.id].sort
            ? langs.sortBy.ASC
            : langs.sortBy.DESC;
          this.setState({ sortFlag: temp });
          column.sortable !== false && instance.sortColumn(column);
          column.sortable !== false &&
            this.getOrderList(this.state.pageSize, 1, sortBy, column.id, "");
        }
      },
    };
  };

  /**
   * @method renderPageSize
   * @description used to render page size
   */
  renderPageSize = () => {
    const { pageSize } = this.state;
    const { data } = this.props;
    if (data && data.length) {
      if (pageSize > data.length) {
        return data.length;
      } else {
        return pageSize;
      }
    } else {
      return 10;
    }
  };

  /**
   * @method renderPageSize
   * @description used to render page size
   */
  renderPageSize = () => {
    const { pageSize } = this.state;
    const { data } = this.props;
    if (data && data.length) {
      if (pageSize > data.length) {
        return data.length;
      } else {
        return pageSize;
      }
    } else {
      return 10;
    }
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getOrderList(this.state.pageSize, page + 1, "", "", "");
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getOrderList(pageSize, this.state.page, "", "", "");
    });
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Order #",
        accessor: "id",
        Cell: (row) => {
          const { original } = row;
          return original.id;
        },
      },
      {
        Header: "Date",
        accessor: "order_date", // String-based value accessors!
        Cell: (row) => {
          const { original } = row;
          return convertISOToUtcDateformate1(original.order_date);
        },
      },
      {
        Header: (props) => <span>Action</span>, // Custom header components!
        sortable: false,
        Cell: (row) => {
          const { original } = row;
          const { newspaper_ads_plan } = original;
          if (!newspaper_ads_plan) return "";
          const adType =
            Array.isArray(newspaper_ads_plan.advertisements) &&
            newspaper_ads_plan.advertisements[0].ad_type;
          let url =
            adType === langs.adType.Display
              ? `/displayOrderDetail/${row.original.id}`
              : `/classifiedOrderDetail/${row.original.id}`;
          return (
            <div style={{ padding: 5 }}>
              <ViewIconLink
                to={`recievedOrderListingDetail/${original.id}`}
                title="View Order"
              />
            </div>
          );
        },
      },
    ];
    return columnOrder;
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { pageSize, pageNo, totalCount } = this.state;
    const { orderList } = this.props;
    return (
      <div>
        <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row">
              <h3 className="kt-subheader__title col-md-6">
                <span style={{ color: "black" }}>
                  {orderList.length} New orders!
                </span>
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
        <div className="row mt-5">
          <div className="col-12">
            <div className=" row my-3">
              <div className="col-xl-6">
                <div className="row px-4">
                  <div className="kt-widget26 col-xl-1 ">
                    <div className="kt-widget26__content">
                      <span
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className="kt-widget26__desc"
                      >
                        Show
                      </span>
                    </div>
                  </div>
                  <div>
                    <select
                      className="form-control"
                      style={{ width: 70, height: 40 }}
                      value={pageSize}
                      onChange={(e) => {
                        this.setState({ pageSize: e.target.value });
                        this.onPageSizeChange(e.target.value);
                      }}
                    >
                      <option value={PAGES_25}>{PAGES_25}</option>
                      <option value={PAGES_50}>{PAGES_50}</option>
                      <option value={PAGES_100}>{PAGES_100}</option>
                    </select>
                  </div>
                  <div className="kt-widget26 col-xl-7">
                    <div className="kt-widget26__content">
                      <span className="kt-widget26__desc">entries</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 my-5" style={{ color: "black" }}>
            <ReactTable
              disableResizing={false}
              data={orderList}
              columns={this.renderRecords()}
              showPagination={
                pageNo > 0
                  ? true
                  : Array.isArray(orderList) &&
                    orderList.length &&
                    orderList.length >= pageSize
                  ? true
                  : false
              }
              pages={totalCount}
              showPageSizeOptions={false}
              onPageChange={this.onPageChange}
              pageSize={this.renderPageSize()}
              manual
              getTheadThProps={this.handleSort}
              style={tableStyle}
              className="noborder -striped -highlight"
              noDataText={"No Records Found"}
              resizable={false}
              sortable={false}
              styles={{
                border: "none",
                boxShadow: "none",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { loading, orderList } = store.commonDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    orderList: Array.isArray(orderList) ? orderList : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, commonDuck.actions)(RecievedOrder)
);
