/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useState,useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/user-types/HomePage";
import AuthPage from "../pages/auth/AuthPage";
import PricingPage from "../pages/auth/PricingPage";
import Registration from "../pages/auth/Registration";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import WelcomePage from "../pages/errors/WelcomePage";
import ContactForm from "../pages/errors/ContactForm";
import ContactUs from "../pages/errors/ContactUs";

import PijenLanding from "../pages/errors/PijenLanding";
import BankInfo from "../pages/errors/BankInfo";
import { UnAuthorized } from "../pages/errors/UnAuthorized";
import { langs } from "../config/localization";
import BankInfoTwo from "../pages/errors/BankInfoTwo";
import * as utils from "../../_metronic/utils/utils";
import Faq from "../pages/errors/landingpage/components/Faq";
import CalenderPage from "../pages/errors/landingpage/components/CalenderPage";

export const Routes = withRouter(({ Layout, history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );
  const [isSessionExpires, setIsSessionExpires] = useState(false);
  const [isExistsPath, setIsExistsPath] = useState(false);

  const checkPermission = (key, permit) => {
    const serializedState = localStorage.getItem("persist:demo1-auth");
    let loginRes = JSON.parse(serializedState);
    if (loginRes.user === undefined) {
      setIsSessionExpires(true);
    } else {
      let user = JSON.parse(loginRes.user);
      if (user.user_detail.role_Data === undefined) {
        setIsSessionExpires(true);
      } else {
        let permissions = user.user_detail.role_Data.permissions;
        let role = user.user_detail.role_Data.name;
        if (role === "superadmin") {
          return true;
        } else {
          return permissions[key][permit].value;
        }
      }
    }
  };

  const pathArray = [
    "/incomplete-subscription",
    "/active-subscription",
    "/cancelled-subscription"
  ]
  const localStorageLastLocationKey = "demo1-lastLocation";
  let lastURLLocation;
  useEffect(()=>{
    const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
    if(localStorateLocations && localStorateLocations.length>0){
      lastURLLocation = JSON.parse(localStorateLocations)[JSON.parse(localStorateLocations).length-1];
      setIsExistsPath(pathArray.includes(`${lastURLLocation}`));
    }
  })

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Switch>
            { isExistsPath && <Redirect from="/" exact={true} to="/auth/login" />}
            <Route exact path="/" component={WelcomePage} />
            <Route exact path="/publisher-benefit" component={PijenLanding} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/calendar" component={CalenderPage} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/bankinforequest/:id" component={BankInfo} />

            <Route exact path="/bankinfo/:id" component={BankInfoTwo} />
            {/* <Route exact path='/bankinforequest/:id' render={(props) => {
              console.warn(props)
              if (checkPermission(langs.permissionKeys.create)) {
                return <BankInfo {...props} />
              }
              return <UnAuthorized />
            }} /> */}

            <Route exact path="/auth/login" component={AuthPage} />
            {/* <Route path='/auth/u1/login' component={AuthPage} /> */}
            <Route exact path="/auth/pricing" component={PricingPage} />

            <Route path="/auth/registration" component={Registration} />
            <Route
              exact={true}
              path="/auth/forgot-password"
              component={AuthPage}
            />
            <Route
              exact={true}
              path="/reset-password/:token"
              component={AuthPage}
            />
            {/* <Route path='/error' component={ErrorPage2} /> */}
            <Switch>
              <Redirect from="*" to="/" />
            </Switch>
          </Switch>
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        {/* <Route path='/error' component={ErrorsPage} /> */}
        <Route path="/logout" component={LogoutPage} />
        <Route
          exact={true}
          path="/reset-password/:token"
          component={AuthPage}
        />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <HomePage userLastLocation={"/"} />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
