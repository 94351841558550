import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import { DATE_RANGE } from "../../../../../config/constant";
import * as payoutSummaryDuck from "../../../../../store/ducks/super-admin/report-management/PayoutSummary.duck";
import { STATUS_CODES } from "../../../../../common/StatusCode";
import * as paymentReportDuck from "../../../../../store/ducks/npc/PaymentReport.duck";
import StatementTable from "./StatementTable";
import { PROFILE_MEDIA_URL } from "../../../../../config";
import MasterCard from "../../../../../../assets/images/mastercard.png";
import "./Pijensummary.scss";

class StatementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      duration: DATE_RANGE,
      defaultStatementId: "",
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    this.getTransactionList();
  }

  /**
   * @method componentWillReceiveProps
   * @description receive props from component
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.statementId !== nextProps.statementId) {
      this.getTransactionList();
    }
  }

  /**
   * @method getTransactionList
   * @description  render transaction list
   */
  getTransactionList = () => {
    let requestData = {
      data: "",
      type: 3,
      row: "",
      page: "",
      sort: "",
      column: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      search: "",
      statement: "",
    };
    this.setData(requestData);
  };

  /**
   * @method setData
   * @description  set requestdata
   */
  setData = (requestData) => {
    this.props.getPayoutSummaryDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        console.warn("I am checking");
      }
    });
  };

  /**
   * @method getCompanyList
   * @description  used to get all admin listing
   */
  getPayoutRecords = (id) => {
    const { statementId } = this.props;
    let requestData = {
      data: "",
      type: 4,
      row: "",
      page: "",
      sort: "",
      column: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      search: "",
      statement: statementId !== undefined ? statementId : id,
    };
    this.props.getPayoutSummaryDetail(requestData, (res) => {
      if (res.status === STATUS_CODES.OK) {
        //const  npc_id = res.data.statementHeader[0].newspaper_id;
        const npc_id = res.data.statementHeader[0].id;
        this.getStatementList(id, npc_id);
      }
    });
  };

  /**
   * @method getStatementList
   * @description  used to get all order  statement listing
   */
  getStatementList = (id, npcId) => {
    let requestData = {
      npc_id: npcId,
      row: 25,
      page: 1,
      sort: "",
      column: "",
      month: "",
      year: "",
      from_date: "",
      to_date: "",
      type: 5,
      statement: id,
    };

    this.props.getStatementDetail(requestData, (res) => {});
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { statementData, orderData, totaldata } = this.props;
    console.warn("Total", totaldata);
    return (
      <div className="column" style={{ margin: 5, height: "auto" }}>
        <div className="box_publisherSummary">
          <div className="row">
            {/* <div className="row">
                <div className="col-3">
                  <img
                    src={
                      imageURL
                        ? `${PROFILE_MEDIA_URL}/${imageURL}`
                        : require("../../../../../../assets/images/defaultUser.png")
                    }
                    alt=""
                    style={{
                      border: "1px solid",
                      borderRadius: 100,
                      height: 40,
                      width: 40,
                    }}
                  />
                </div>
                <div className="col-9" style={{ textAlign: "initial" }}>
                  <h6>{statementData.newspaper_name}</h6>
                  <h6 className="text-truncate">
                    {statementData.first_name &&
                      statementData.last_name &&
                      statementData.first_name + " " + statementData.last_name}
                  </h6>
                  <h6>{statementData.name}</h6>
                </div>
              </div> */}

            {/* <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-6 col-md-12 d-flex-center">
                  <h6>Statement Id</h6>
                  <h6 className="ml-3">
                    {statementData.statement_id}
                  </h6>
                </div>
                <div className="col-6 col-md-12 d-flex-center">
                  <h6>Payout</h6>
                  <h6 className="ml-3">
                    {statementData.payout && `$${statementData.payout}`}
                  </h6>
                </div>
              </div>            
            </div> */}
            <div className="col-md-5">
              <div className="pijen_summaryheading">
                <h3>Transaction Information</h3>
              </div>
            </div>
            <div className="col-md-7">
              <div className="pijen_summaryheading">
                <div>
                  <h3>Transaction Details</h3>
                  <p>(Order #123454215)</p>
                </div>
                <div>
                  <h5> <Link
                to={`//`}
                style={{
                  borderBottom: "1px solid",
                }}>
                    <i class="fa fa-file-pdf-o mr-2" aria-hidden="true"></i>
                    Download Pdf
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="transaction_left">
              <h4>Jack her shey</h4>
              <p>jack@gmail.com</p>
              </div>
              <div className="transaction_left2">
                <img src={MasterCard} alt="master-logo"/>
              <h4>**** **** **** 2445</h4>
              <p>Expiration Date: 10/2025</p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="transaction_right">
                <h4>PUBLISHER</h4>
                <p>$176.00</p>
              </div>
              <div className="transaction_right">
                <h4>AGENT COMMISSION</h4>
                <p>$32.00</p>
              </div>
              <div className="transaction_right">
                <h4>PIJEN</h4>
                <p>$12.00</p>
              </div>
              <div className="transaction_righttotal">
                <h4>TOTAL AMOUNT</h4>
                <p>$220.00</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading } = store.commonDuck;
  const { payoutSummaryDuck, paymentReportDuck } = store;
  const { statementDeta } = payoutSummaryDuck;
  const { statementDetail } = paymentReportDuck;
  const { auth } = store;
  return {
    loggedInUser: auth.user,
    loading,
    orderData: statementDeta !== undefined ? statementDeta.agent_data : [],
    totaldata: statementDeta !== undefined ? statementDeta.result[0].amount : 0,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...payoutSummaryDuck.actions,
    ...paymentReportDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "StatementList",
    })(StatementList)
  )
);
