import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import C3Chart from 'react-c3js';
import { toastr } from 'react-redux-toastr';
import '../Chart.scss';
import { convertISOToUtcDateformate3, convertISOToUtcDateformate2 } from '../../../../../common';
import FilterRecords from '../FilterRecords';
import * as paymentReportDuck from '../../../../../store/ducks/npc/PaymentReport.duck';
import { STATUS_CODES } from '../../../../../common/StatusCode';
import { selectedFilterValue, getCurrentMonth } from '../../../../../common/CommonFunction';
import BlankChart from '../../../../../common/BlankChart'
import { MESSAGES } from '../../../../../config/message'

let label = getCurrentMonth()
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: ['x'],
      totalRevenue: ['Sum Of Revenue'],
      totalEarning: ['Sum Of Earning'],
      totalOrder: ['Count_of_Orders'],
      selectedOption: {
        option: 0,
        startDate: '',
        endDate: ''
      }
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component 
   */
  componentDidMount() {
    //this.getData()
  }

  /**
   * @method getData
   * @description  det payout report detail
   */
  getData = () => {
    let data = this.props.financeDetail;
    const { totalEarning, totalOrder, totalRevenue, date } = this.state
    let temp1 = totalRevenue
    let temp2 = totalEarning
    let temp3 = totalOrder
    let temp4 = date
    data && data.graphData.map((item, i) => {
      temp1.push(item.total_revenue);
      temp2.push(item.paid_amount)
      temp3.push(item.total_orders)
      temp4.push(convertISOToUtcDateformate3(item.createdAt))
    })
    this.setState({
      totalRevenue: temp1,
      totalEarning: temp2,
      totalOrder: temp3,
      date: temp4
    })
  }

  /**
   * @method FilterRecords
   * @description  called after rendering the component 
   */
  FilterRecords = (values, b) => {
    this.applyFilter(values, this.props)
  }

  /**
   * @method applyFilter
   * @description  called to apply filter 
   */
  applyFilter(values, props) {
    this.setState({
      date: ['x'],
      totalRevenue: ['Sum Of Revenue'],
      totalEarning: ['Sum Of Earning'],
      totalOrder: ['Count_of_Orders']
    })
    if ((values.from && !values.to) || (values.to && !values.from)) {
      return toastr.error(MESSAGES.DATE_VALIDATION)
    }
    const { user_detail } = this.props.loggedInUser;
    const { type } = this.props;
    let date = '';
    let date2 = '';
    const this_month = (values.date_range) === '0' ? selectedFilterValue(values.date_range) : '';
    const this_year = (values.date_range) === '5' || (values.date_range) === '6' ? selectedFilterValue(values.date_range) : '';
    if (values.date_range === '2' || values.date_range === '7') {
      date = selectedFilterValue(values.date_range)
      date2 = selectedFilterValue(values.date_range)
      label = selectedFilterValue(values.date_range)
      this.props.callNext(label)
    } else if (values.date_range === '3') {
      let data = selectedFilterValue(values.date_range);
      date = data.firstday
      date2 = data.lastday
      label = data.firstday + ' to ' + data.lastday
      this.props.callNext(label)
    } else if (values.date_range === '4') {
      date = selectedFilterValue(values.date_range)
      date2 = selectedFilterValue(values.date_range)
      label = selectedFilterValue(values.date_range) + ' to ' + selectedFilterValue(values.date_range)
      this.props.callNext(label)
    }
    else if (values.from || values.to) {
      let firstDate = values.from !== '' ? convertISOToUtcDateformate2(values.from) : '';
      let lastDate = values.to !== '' ? convertISOToUtcDateformate2(values.to) : '';
      let temp = values.from !== '' && values.to !== '' ? 'to' : '';
      date = convertISOToUtcDateformate2(values.from)
      date2 = convertISOToUtcDateformate2(values.to)
      label = `${firstDate} ${temp} ${lastDate}`
      this.props.callNext(label)
    } else {
      date = ''
      date2 = ''
    }
    if ((values.date_range) === '5' || (values.date_range) === '6') {
      label = selectedFilterValue(values.date_range)
      this.props.callNext(label)
    }
    const requestData = {
      npc_id: user_detail.newspaper.id,
      row: 25,
      page: 1,
      sort: '',
      column: '',
      month: this_month,
      year: this_year,
      type: type,
      from_date: date,
      to_date: date2,
    }

    if (type === '2') {
      this.props.getPayoutOrderListM2(requestData, res => {
        if (res.status === STATUS_CODES.OK) {
          let data = res.data;
          const { totalEarning, totalOrder, totalRevenue, date } = this.state
          if (Array.isArray(data.graphData) && data.graphData.length) {
            data.graphData.map((item, i) => {
              totalRevenue.push(item.total_revenue);
              totalEarning.push(item.paid_amount)
              totalOrder.push(item.total_orders)
              date.push(convertISOToUtcDateformate3(item.createdAt))
              this.setState({
                selectedOption: {
                  option: values.date_range,
                  startDate: values.from,
                  endDate: values.to
                }
              })
            })
          } else {
            this.setState({
              date: ['x'],
              totalRevenue: ['Sum Of Revenue'],
              totalEarning: ['Sum Of Earning'],
              totalOrder: ['Count_of_Orders'],
              selectedOption: {
                option: values.date_range,
                startDate: values.from,
                endDate: values.to
              }
            })
          }
          this.props.onCancel()
        }
      })
    }
  }

  /**
  * @method render
  * @description  render component 
  */
  render() {
    const { totalEarning, totalOrder, totalRevenue, date, selectedOption } = this.state
    const { openModal } = this.props
    return (
        <div>
         {totalRevenue.length > 1 || totalOrder.length > 1  || totalEarning.length > 1 ?
         <div>
        <C3Chart
          axis={{
            x: {
              type: 'timeseries',
              tick: {
                format: '%Y-%m-%d'
              }
            },
            y: {
              label: {
                text: 'Sum of revenue',
                position: 'outer-middle'
              }
            },
            y2: {
              show: true,
              label: {
                text: 'Count of order date2',
                position: 'outer-middle'
              },
              max: 90,
              min: 0
            }
          }}
          unloadBeforeLoad={true}
          data={{
            x: 'x',
            columns: [
              date,
              totalRevenue,
              totalEarning,
              totalOrder
            ],
            axes: {
              'Count_of_Orders': 'y2'
            },
            type: 'bar',
            types: {
              Count_of_Orders: 'line'
            },
          }
          }
        /></div>: <div style={{ marginTop: 30 }} className='row w-100'><BlankChart /></div>}
        <label style={{ marginLeft: 120 }}>
          <span className="squaredot2"></span>&nbsp;&nbsp;Sum of Revenue&nbsp;&nbsp;
          <span className="squaredot1"></span>&nbsp;&nbsp;Sum of Earning &nbsp;&nbsp;
          <span className="squaredot3"></span>&nbsp;&nbsp;Count of Orders&nbsp;&nbsp;
        </label>


        {openModal && <FilterRecords
          applyFilter={this.FilterRecords}
          isOpen={openModal}
          selectedOption={selectedOption}
          onCancel={this.props.onCancel}
          type={2}
        />}
      </div>
    );
  }
}
const mapStateToProps = store => {
  const { loading } = store.commonDuck;
  const { paymentReportDuck } = store;
  const { auth } = store;
  return ({
    loggedInUser: auth.user,
    loading,
    financeDetail: paymentReportDuck.financeDetail
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    paymentReportDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'Chart',
      destroyOnUnmount: false
    })(Chart)
  )
);
