import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import * as customerAccessDuck from "../../../../store/ducks/npc/CustomerAccess.duck";
import { injectIntl } from "react-intl";
import StandardTable from "../../StandardTable";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import RequestModal from "./RequestCustomer";
import { PAGES_25, TOTAL_COUNT } from "../../../../config/constant";
import { langs } from "../../../../config/localization";
import { STATUS_CODES } from "../../../../common/StatusCode";
import moment from 'moment';
import Switch from "react-switch";
import { toastr } from "react-redux-toastr";
import {
  DeclineIconButton,
  StatusLabel,
  AddIconLink,
} from "../../../../common/Buttons";
import './Agentdiscount.scss'

const tableStyle = {
  border: "none",
  boxShadow: "none",
};
let flag = false;
class Agentdiscount extends Component {
  constructor(props, context) {


    super(props, context);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isConfirm: false,
      pageSize: PAGES_25,
      totalCount: TOTAL_COUNT,
      customdata:[],
      page: 1,
      sortFlag: {
        agent_name: { sort: false },
        discount_percent: { sort: false },
        createdAt: { sort: false },
      },
    };
  }

  /**
   * @method componentDidMount
   * @description called after mounting the component
   */
  componentDidMount() {
    this.getCustomerList(this.state.pageSize, 1, "", "");
  }
  
  handleChange(id) {

    let newChecked = !this.state.checked
    this.setState({checked: newChecked})
    console.warn("checking state", id);
    let requestData = {
      newChecked: newChecked,
      id: id,
    };

    this.props.enableandDisablediscount(requestData, (res) => {
     
      if (res.status === STATUS_CODES.OK) {
      
        this.setState({checked: res.data.rows.is_active});
        this.getCustomerList(this.state.pageSize, 1, "", "");

        toastr.success("Data updated successfully");
        
      }
    });


  }

  /**
   * @method getCustomerList
   * @description get customer list
   */
  getCustomerList = (row, page, sortBy, column) => {
    const { user_detail } = this.props.loggedInUser;
    let requestData = {
      npc_id: user_detail.newspaper.id,
      search: "",
      row: row !== undefined ? row : 25,
      page: page !== undefined ? page : 1,
      sort: sortBy,
      column: column,
    };
    this.props.getAgentDiscountList(requestData, (res) => {
     
      if (res.status === STATUS_CODES.OK) {
        console.warn("state value", this.state);
        let pages = res.data.count / this.state.pageSize;
        this.setState({ totalCount: Math.ceil(pages) });
      }
    });
  };

  /**
   * @method onPageSizeChange
   * @description called on change of page size
   */
  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize }, () => {
      this.getCustomerList(pageSize, this.state.page, "", "");
    });
  };

  /**
   * @method onPageChange
   * @description haldle pagination
   */
  onPageChange = (page) => {
    this.setState({ page: page + 1 });
    this.getCustomerList(this.state.pageSize, page + 1, "", "");
  };

  /**
   * @method handleSearch
   * @description used to handle search
   */
  handleSearch = (e) => {
    if (!e.target.value || e.target.value.length <= 2) {
      this.setState({ showSearchResults: false });
      this.getCustomerList(this.state.pageSize, 1, "", "");
    } else if (e.target.value.length >= 2) {
      flag = true;
      this.setState({ showSearchResults: true });
      const { user_detail } = this.props.loggedInUser;
      let requestData = {
        npc_id: user_detail.newspaper.id,
        column: "",
        page: "",
        row: this.state.pageSize,
        sort: "",
        search: encodeURIComponent(
          e.target.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        ),
      };
      this.props.getAgentDiscountList(requestData, (res) => {});
    }
  };

  /**
   * @method handleSort
   * @description perform sorting
   */
  handleSort = (e, { sorted }, rowInfo, column, instance) => {
    if (column.sortable !== false) {
      const { sortFlag } = this.state;
      let temp = sortFlag;
      temp[column.id].sort = !sortFlag[column.id].sort;
      let sortBy = sortFlag[column.id].sort
        ? langs.sortBy.ASC
        : langs.sortBy.DESC;
      this.setState({ sortFlag: temp });
      column.sortable !== false && instance.sortColumn(column);
      column.sortable !== false &&
        this.getCustomerList(this.state.pageSize, this.state.page, sortBy, column.id);
    }
  };

  /**
   * @method renderRecords
   * @description render all order list
   */
  renderRecords = () => {
    const columnOrder = [
      {
        Header: "Agent Name",
        accessor: "agent_name",
        Cell: (row) => {
          const { original } = row;
          return original.agent_name;
          //return original.user.first_name+' '+original.user.last_name;
        }, // String-based value accessors!
      },
      {
        Header: "Discount",
        accessor: "discount_percent",
      },
      {
        Header: "Date Modified",
        accessor: "createdAt",
        Cell: (row) => {
          const { original } = row;
          let date = original.createdAt;
          return <span>{date ? moment(date).format("M/D/YYYY") : ""}</span>;
        },
      },
      {
        Header: (props) => <span>Actions</span>,
        // Custom header components!
        sortable: false,
        minWidth: 120,
        Cell: (row) => {
          const { original } = row;
          // if (original && original.status === "Pending") {
          //   return <StatusLabel title="Pending" variant="light" />;
          // } else if (original && original.status === "Declined") {
          //   return <DeclineIconButton onClick={() => {}} />;
          // } else if (original && original.status === "Approved") {
          //   return (
          //     <AddIconLink
          //       title="Add Discount"
          //       to={{
          //         pathname: `/create-discount/${row.original.permission_id}`,
          //         state: {
          //           customerDetail: row.original,
          //         },
          //       }}
          //     />
          //   );
          // } else {
          //   return <div></div>;
          // }
          return (
            <label>
            
              <Switch onChange={()=>this.handleChange(original.id)} checked={original.is_active} onColor='#c9f7f5' offColor='#ffcccb' className="toggle_switch_custom" height="24" width="60"  uncheckedIcon= "No"

checkedIcon= "Yes" />
            </label>
          );
        },
      },
    ];
    return columnOrder;
  };
  /**
   * @method render
   * @description Renders the component
   */
  render() {
    const { requestList } = this.props;
    const { openModal, totalCount, agentdiscountList } = this.state;
    return (
      <div>
        
        <Portlet fluidHeight={true}>
          <PortletBody>
          <LayoutContextConsumer className="row">
          {({ subheader: { title } }) => (
            <div className="row kt-subheader__title custom_title">
              <h3 className=" col-md-12">
                Agent Discount List
              </h3>
            </div>
          )}
        </LayoutContextConsumer>
            {/* <div className="row my-3">
              <div className="col-sm-6 order-1 order-sm-0">
                <InputGroup
                  size="sm"
                  className="d-flex justify-content-end search-bar w-100"
                  onFocus={() => {
                    this.setState({ inputFocus: true });
                  }}
                >
                  <FormControl
                    style={{ marginRight: 20, height: 35 }}
                    placeholder="Search"
                    aria-label="search"
                    aria-describedby="basic-addon1"
                    onChange={(e) => this.handleSearch(e)}
                  />
                  <i className="flaticon-search"></i>
                </InputGroup>
              </div>
              <h5
                className="col-sm-6 order-0 order-sm-1 mb-4 mb-md-0 d-flex-center j-end"
                style={{ textAlign: "right", cursor: "pointer" }}
                onClick={() => this.setState({ openModal: true })}
              >
                + Request Customer Access
              </h5>
            </div> */}
            {requestList && requestList.count > 0 ? (
            
              <StandardTable
                columnOrder={this.renderRecords()}
                showSearch={false}
                totalCount={totalCount}
                data={requestList.rows}
                showPageSizeOptions={true}
                handelSearch={this.handleSearch}
                handleSort={this.handleSort}
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                //isVisible={false}
                showPagination={false}
              />
            ) : (
              <div>
                <div className="row">
                  <div className="col-xl-12 px-0" style={{ marginTop: 20 }}>
                    <ReactTable
                      disableResizing={false}
                      columns={this.renderRecords()}
                      manual
                      style={tableStyle}
                      className={"noborder"}
                      showPagination={false}
                      NoDataComponent={() => {
                        return (
                          <div className="float-right flex-center align-items-end h-100">
                            <div
                              className="text-center"
                              style={{
                                marginBottom: 300,
                                marginTop: -500,
                                textAlign: "initial",
                                cursor: "pointer",
                              }}
                            >
                              
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </PortletBody>
        </Portlet>
       
      </div>
    );
  }
}

/**
 * @method mapStateToProps
 * @description return state to component as props
 * @param {*} state
 */

const mapStateToProps = (store) => {
  const { auth, customerAccessDuck, authToken } = store;
  const { agentdiscountList } = customerAccessDuck;

 // console.warn("Store checking",agentdiscountList );
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    requestList: agentdiscountList,
  };
};

export default injectIntl(
  connect(mapStateToProps, customerAccessDuck.actions)(Agentdiscount)
);
