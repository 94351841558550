import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import EmailCredentials from "./EmailCredentials";
import WhatsappCredentials from "./WhatsappCredentials";
import PayoutCredentials from "./PayoutCredentials";
import CustomURLCredentials from "./CustomURLCredentials";
import SendEmailList from "./SendEmailList";

class GeneralSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: "",
      adTypeFlag: false,
      colorFlag: false,
      name: ""
    };
  }

  /**
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    const requestData = {};
    this.props.getemailSettings(requestData, (res) => { });
    this.props.getWhatsappSettings(requestData, (res) => { });
    this.props.getPayoutSettings(requestData, (res) => { });
    this.props.getCustomURLSettings(requestData, (res) => { });
    this.props.getSendEmailList(requestData, (res) => { });
  }

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { selectedEmail, selectedWhatsapp, selectedPayout, selectedCustomURL, selectedSendEmailList } = this.props;
    return (
      <div className="row my-5">
        <div className="col-md-12">
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div className="row">
                <h5 className="kt-subheader__title col-md-6">
                  <span>General Settings</span>
                </h5>
              </div>
            )}
          </LayoutContextConsumer>
          <EmailCredentials data={selectedEmail} />
          <WhatsappCredentials data={selectedWhatsapp} />
          <PayoutCredentials data={selectedPayout} />
          <CustomURLCredentials data={selectedCustomURL} />
          <SendEmailList data={selectedSendEmailList} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth, superAdminDuck, authToken, commonDuck } = store;
  return {
    loggedInUser: auth.user,
    authToken: authToken,
    selectedEmail: commonDuck.selectedEmail ? commonDuck.selectedEmail : undefined,
    selectedWhatsapp: commonDuck.selectedWhatsapp ? commonDuck.selectedWhatsapp : undefined,
    selectedPayout: commonDuck.selectedPayout ? commonDuck.selectedPayout : undefined,
    selectedCustomURL: commonDuck.selectedCustomURL ? commonDuck.selectedCustomURL : undefined,
    selectedSendEmailList: commonDuck.selectedSendEmailList ? commonDuck.selectedSendEmailList : undefined,
    selectedCompany: superAdminDuck.selectedCompany
      ? superAdminDuck.selectedCompany
      : undefined,
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...commonDuck.actions })(
    reduxForm({
      // Assign unique name for the form
      form: "GeneralSettings",
    })(GeneralSettings)
  )
);
