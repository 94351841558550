import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Card } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import * as commonDuck from '../../../../store/ducks/Common.duck'
import * as settingsDuck from '../../../../store/ducks/super-admin/Settings.duck';
import '../ad-management/AdManagement.scss';
import ConformModel from '../ad-management/ConfirmModel';
import { langs } from '../../../../config/localization';
import { renderText } from '../../../../common/layout/FormInput';
import { required, gminLength, maxLengthC100 } from '../../../../config/validation';
import { MESSAGES } from '../../../../config/message';
import { toastr } from 'react-redux-toastr';
import { checkPermission } from '../../../../common/CheckPermissions';
import { STATUS_CODES } from '../../../../common/StatusCode'

class RejectionReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      isUpdateModel: false,
      message: '',
      adTypeFlag: false,
      colorFlag: false,
      reject_reason: '',
      key: '',
      rejectionMessage: ''
    };
  }

  /** 
   * @method componentDidMount
   * @description called before rendering the component
   */
  componentDidMount() {
    this.props.getAllReasonList(langs.keyname.reject_reason)
  }

  /** 
   * @method cancelDelete
   * @description cancelDelete bought out part
   */
  cancelDelete = () => {
    this.setState({ showConfirmModal: false });
  };

  /**
  * @method confirmDelete
  * @description confirm delete bought out part
  */
  confirmDelete = () => {
    const deletedItem = this.state.deletedItem;
    this.props.deleteRejectReason(langs.keyname.reject_reason, deletedItem, (res) => {
      if (res.status === STATUS_CODES.NO_CONTENT) {
        this.props.getAllReasonList(langs.keyname.reject_reason)
        toastr.success(MESSAGES.DELETE_REJECT_REASON_SUCCESS)
        this.setState({
          showConfirmModal: false,
        });
      }
    })
  }

  /**
  * @method addReason
  * @description called api to add reason
  */
  addReason = (e) => {
    const { reject_reason } = this.state;
    const match = reject_reason !== '' && reject_reason.trim() !== '' && reject_reason.length >= 2 && reject_reason.length <= 100
    if (match) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        const requestData = {
          name: reject_reason
        }
        this.props.commonAddAPI(langs.keyname.reject_reason, requestData, (res) => {
          if (res.status === STATUS_CODES.CREATED || res.status === STATUS_CODES.OK) {
            this.props.getAllReasonList(langs.keyname.reject_reason)
            toastr.success(MESSAGES.ADD_REJECT_REASON_SUCCESS)
            this.setState({ reject_reason: '' })
            document.activeElement.blur();
            this.props.dispatch(untouch('RejectionReason', 'reject_reason'));
            this.props.dispatch(change('RejectionReason', 'reject_reason', ''));

          }
        })
      }
    }
  }

  /**
   * @method renderCardItem
   * @description render the card item
   */
  renderCardItem = (typeOfListing, per) => {
    if (typeOfListing && typeOfListing !== undefined) {
      return typeOfListing.map((data, i) => {
        return (
          <Card key={i} bg='light' style={{ width: 'auto', height: '3.5rem', marginRight: 10, marginBottom: 10 }} >
            <Card.Body>
              <Card.Title><label>{data.name}</label>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                {per && <i className='fa fa-close crossIcon' onClick={() =>
                  this.setState({ showConfirmModal: true, deletedItem: data.id, key: 'reason', rejectionMessage: 'Are you sure, you want to remove this reason?' })
                }></i>}
              </Card.Title>
            </Card.Body>
          </Card>
        );
      });
    }
  };


  /**
   * @method render
   * @description render the component
   */
  render() {
    const { reasonList, handleSubmit } = this.props;
    const { showInput, showConfirmModal, rejectionMessage, key } = this.state;
    const viewPermission = checkPermission(langs.permissionKeys.reasonforRejection, langs.permissionKeys.view)
    const createPermission = checkPermission(langs.permissionKeys.reasonforRejection, langs.permissionKeys.create)
    const deletePermission = checkPermission(langs.permissionKeys.reasonforRejection, langs.permissionKeys.delete)
    if (!viewPermission && !createPermission && !deletePermission) {
      return <div></div>
    }
    return (
      <div className="row">
        <div className='col-md-12'>
          <Portlet fluidHeight={true}>
            <PortletBody >
              {(viewPermission || createPermission) && <Form.Row>
                <Form.Group as={Col} md='12' controlId='validationCustom01'>
                  <h5
                    style={{ color: '#5d5757', marginBottom: '100' }}
                  >
                    Add Default Reason for Rejection of Company
                </h5>
                </Form.Group>
              </Form.Row>}
              <div className='cardWrapper'>
                {viewPermission ? this.renderCardItem(reasonList, deletePermission) :  <div className='text-center' style={{ marginTop: '10px' }}>
                  <h4>{MESSAGES.PERMISSION_ACCESS}</h4>
                </div>}
              </div>
              <div
                className='row'
                style={{
                  borderBottom: '1px solid #ccc', marginTop: 25,
                  marginLeft: 2
                }}
              >
                {createPermission && <label style={{ color: 'black', marginTop: 25, cursor: 'pointer' }} onClick={() => this.setState({ showInput: !this.state.showInput })} data-toggle='collapse'><i className='fa fa-plus' aria-hidden='true'></i>&nbsp;&nbsp;Add More
                  </label>}
              </div>
              {showInput &&
                <form
                  noValidate
                  className='form'
                  onSubmit={handleSubmit(this.addReason.bind(this))}
                >
                  <Row>
                    <Col md='6'>
                      <Field
                        name='reject_reason'
                        validate={[required, gminLength, maxLengthC100]}
                        placeholder='Enter Reason Of Rejection'
                        component={renderText}
                        onChange={e => this.setState({ reject_reason: e.target.value.trim() })}
                        onKeyDown={this.addReason}
                      />
                    </Col>
                  </Row>
                </form>
              }
            </PortletBody>
          </Portlet>
          <ConformModel
            isOpen={showConfirmModal}
            onCancelClick={this.cancelDelete}
            onOkClick={this.confirmDelete}
            key={key}
            rejectionMessage={rejectionMessage}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { auth, commonDuck, authToken } = store;
  return ({
    loggedInUser: auth.user,
    authToken: authToken,
    reasonList: Array.isArray(commonDuck.reasonList) ? commonDuck.reasonList : [],
  })
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...commonDuck.actions, ...settingsDuck.actions }
  )(
    reduxForm({
      // Assign unique name for the form
      form: 'RejectionReason'
    })(RejectionReason)
  )
);
