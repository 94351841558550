import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
    API
} from '../../../config';
import {
    CALL_LOADING
} from '../../../config/constant';
import { apiErrors } from '../../../common/HandleAPIErrors'


export const actionTypes = {
    SetTemplateList: "[SetTemplateList] Action",
    SetActionList: "[SetActionList] Action",
    SetSearchResult: "[SetSearchResultEmailTemplate] Action",
};

const headers = {
    'Content-Type': 'application/json',
}

const initialAuthState = {
    templateList: [],
    actionList: [],
};

/** set data of api's into reducer */
export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {

            /** set data for customer Request List */
            case actionTypes.SetTemplateList: {
                const { templateList } = action.payload;
                return { ...state, templateList: [...templateList] };
            }

            /** set data for customer Request List */
            case actionTypes.SetActionList: {
                console.warn("pppp:",action.payload);
                const { actionList } = action.payload;
                console.warn("actionlist:",actionList);
                return { ...state, actionList: [...actionList] };
            }

            case actionTypes.SetSearchResult: {
                const { templateList } = action.payload;
                return { ...state, searchResults: [...templateList] };
            }

            /** set default state */
            default:
                return state;
        }
    }
);

/** Actions creator */
export const actions = {

    /**
   * @method getTemplateList
   * @description get all template listing
   */
    getTemplateList: (requestData, callback) => {
        const { page, is_deleted, row, name, id, sort, column } = requestData
        const request = axios.get(`${API.emailTemplate}?id=${id ? id : ''}&row=${row}&page=${page}&is_deleted=${is_deleted}&name=${name ? name : ''}&sort=${sort ? sort : ''}&column=${column ? column : ''}`, {
            headers
        });
        return (dispatch) => {
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                //dispatch({ type: CALL_LOADING })
                callback(response)
                return dispatch({ type: actionTypes.SetTemplateList, payload: { templateList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error)
                //dispatch({ type: CALL_LOADING })
            });
        };
    },

     /**
   * @method getTemplateList
   * @description get all template listing
   */
      getActionList: (req, callback) => {
        const request = axios.get(`${API.actionTemplate}`, {
            headers
        });
        return (dispatch) => {
           
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                console.warn("Res", response)
                //dispatch({ type: CALL_LOADING })
                // console.warn(1);
                callback(response)
                console.warn("Res:",response);
                return dispatch({ type: actionTypes.SetActionList, payload: { actionList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error)
                //dispatch({ type: CALL_LOADING })
            });
        };
    },

      /**
   * @method getTemplateList
   * @description get all template listing
   */
       getActionListById: (req, callback) => {
           console.warn("Req", req);
        const request = axios.get(`${API.actionTemplateById}?id=${req.id}`, req, {
            headers
        });
        return (dispatch) => {
           
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                console.warn("Res", response)
                //dispatch({ type: CALL_LOADING })
                // console.warn(1);
                callback(response)
                // console.warn("Res:",response);
                // return dispatch({ type: actionTypes.SetActionList, payload: { actionList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error)
                //dispatch({ type: CALL_LOADING })
            });
        };
    },

      /**
   * @method getTemplateList
   * @description get all template listing
   */
       saveActionList: (req, callback) => {
        const request = axios.post(`${API.actionTemplate}`, req, {
            headers
        });
        return (dispatch) => {
           
            //dispatch({ type: CALL_LOADING })
            request.then((response) => {
                console.warn("Res", response)
                callback(response)
                // console.warn("Res:",response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error)
                //dispatch({ type: CALL_LOADING })
            });
        };
    },

       /**
   * @method getTemplateList
   * @description get all template listing
   */
        updateActionList: (req, callback) => {
            const request = axios.put(`${API.actionTemplate}`, req, {
                headers
            });
            return (dispatch) => {
               
                //dispatch({ type: CALL_LOADING })
                request.then((response) => {
                    console.warn("Res", response)
                    callback(response)
                    // console.warn("Res:",response);
                }).catch((error) => {
                    apiErrors(error, dispatch)
                    // tokenCheck(dispatch, error)
                    callback(error)
                    //dispatch({ type: CALL_LOADING })
                });
            };
        },
    

    /**
    * @method searchInTemplateList
    * @description search teplate records
    */
    searchInTemplateList: (requestData) => {

        const { page, is_deleted, row, name, id, sort, column } = requestData
        const request = axios.get(`${API.emailTemplate}?id=${id ? id : ''}&row=${row}&page=${page}&is_deleted=${is_deleted}&name=${name}&sort=${sort ? sort : ''}&column=${column ? column : ''}`, {
            headers
        });
        return (dispatch) => {
            request.then((response) => {
                return dispatch({ type: actionTypes.SetSearchResult, payload: { templateList: response.data.rows } })

            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                return error;
            });
        };
    },

    /**
     * @method updateEmailTempalte
     * @description Update email template details 
     */
    updateEmailTempalte: (requestData, callback) => {
        let request = axios.put(`${API.emailTemplate}`, requestData, {
            headers
        });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                callback(response);
                dispatch({ type: CALL_LOADING })
            }).catch((error) => {
                apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
                callback(error);
                dispatch({ type: CALL_LOADING })
            });
        };
    },

    /**
    * @method addTestEmail
    * @description Add test email 
    */
    addTestEmail: (requestData, callback) => {
        const request = axios.post(API.testEmail, requestData, { headers });
        return (dispatch) => {
            dispatch({ type: CALL_LOADING })
            request.then((response) => {
                dispatch({ type: CALL_LOADING })
                callback(response);
            }).catch((error) => {
                apiErrors(error, dispatch)
                dispatch({ type: CALL_LOADING })
                callback(error);
                // apiErrors(error, dispatch)
                // tokenCheck(dispatch, error)
            });
        };
    },
};

export function* saga() {
    yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {
    });
}





