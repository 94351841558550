import React, { useState, useEffect } from "react";
import { Paper, Switch, Grid } from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import { Multiselect } from 'multiselect-react-dropdown';
import ImgsViewer from "react-images-viewer";
import { connect } from "react-redux";
import CheckActivity from "./CheckActivity";
import CampaignModal from "./CampaignModal";
import moment from 'moment';
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
  change as changeFieldValue,
  untouch as untouchField,
} from "redux-form";
import { injectIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import NavigationPrompt from "react-router-navigation-prompt";
import {
  required,
  maxLengthC100,
  number,
  greaterThanZeroWithTwoDecimalPlace,
  minLengthC3,
  normalizeCharacterLimit
} from "../../../../config/validation";
import {
  renderText,
  renderSelectField,
  renderNumberField,
  renderTextInputField,
} from "../../../../common/layout/FormInput";
import * as NPCDuck from "../../../../store/ducks/npc.duck";
import * as commonDuck from '../../../../store/ducks/Common.duck';
import { PROFILE_MEDIA_URL } from "../../../../config";
import { STATUS_CODES } from "../../../../common/StatusCode";
import { MESSAGES } from "../../../../config/message";
import NavigateModal from "../../NavigateModal";
import { MakeTable, getDays, getTimes } from "../../../../config/utils";
import AddColorForm from "./AddColorForm";
import AddCharacterForm from "./AddCharacterForm";
import AddLineForm from "./AddLineForm";
import AddPaymentDiscountOptionForm from "./AddPaymentDiscountOptionForm";
import { langs } from "../../../../config/localization";
import { NoContentFound } from "../../../../common/NoContentFound";
import CheckboxRP from "../../../../common/CheckboxRP";
import { ButtonRP } from "../../../../common/Buttons";
import RadioButtonRP from "../../../../common/RadioButtonRP";
import { Form } from "react-bootstrap";
import AddValueForm from "./AddValueForm";

const EditPlan = ({
  formValues,
  handleSubmit,
  submitting,
  pristine,

  priceRunTimeCategoryList,
  sizeOfAdCategoryList,
  adPlacementCategoryList,
  adFeatureCategoryList,
  adTypeCategoryList,
  socialMediaCategoryList,
  /** store states */
  loggedInUser,
  /**Actions */
  getAllPriceRunTimeCategoryList,
  getAllSizeOfAdCategoryList,
  getAllAdPlacementCategoryList,
  getAllAdFeatureCategoryList,
  getAllAdTypeCategoryList,
  getAllSocialMediaCategoryList,

  getfromNPCSetting,
  getAllAdType,
  updateSocialPlan,
  getSocialPlanById,
  getAllPaperType,
  getAllAccount,
  resetPlan,
  changeFieldValue,
  untouchField,
  reset,
  socialPlanUpdate,
  getVersionsForSocial,
  getPlanByversionIdForSocial
}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [val, setVal] = useState();
  const [fmval, setfmVal] = useState();

  let cancelDelete = () => {
    setShowModal(false);
  };

  let confirmDelete = () => {
    let classified_conditional_errors = {};
    let display_height_width_errors = {};
    if (val.isColoredText && !val.color_text_cost) {
      classified_conditional_errors.color_text_cost =
        langs.validation_messages.required;
    }
    if (val.isBold && !val.bold_cost) {
      classified_conditional_errors.bold_cost =
        langs.validation_messages.required;
    }
    if (val.isAddImage && !val.image_cost) {
      classified_conditional_errors.image_cost =
        langs.validation_messages.required;
    }
    if (fmval.discount && paymentDiscountOptions.length === 0) {
      setPaymentDiscountOptionError("Please add payment discount options");
      return;
    }
    let data1 = [];
    if (selectedDayMulti.length > 0) {
      for (let i in selectedDayMulti) {
        if (selectedDayMulti[i].value) {
          data1.push(selectedDayMulti[i].value);
        } else if (selectedDayMulti[i].id) {
          data1.push(selectedDayMulti[i].id);
        }

      }
    }
    let data2 = [];
    if (setectedWebAdonType.length > 0) {
      for (let i in setectedWebAdonType) {
        if (setectedWebAdonType[i].value) {
          data2.push(setectedWebAdonType[i].value);
        } else if (setectedWebAdonType[i].id) {
          data2.push(setectedWebAdonType[i].id);
        }

      }
    }

    let data3 = [];

    if (selectedSize.length > 0) {
      for (let i in selectedSize) {
        if (selectedSize[i].value) {
          data3.push(selectedSize[i].value);
        } else if (selectedSize[i].id) {
          data3.push(selectedSize[i].id);
        }

      }
    }

    let data = {
      id: val.id,
      npc_id: user_detail.newspaper.id,
      ad_type: val.ad_type,
      location: data1.toString(),
      web_adon_type: data2.toString(),
      size_of_ad: data3.toString(),
      deadline_price: String(val.deadline_price).trim() !== "" ? val.deadline_price : 0,
      discount: val.discount,
      discount_option: val.discount
        ? JSON.stringify(paymentDiscountOptions)
        : JSON.stringify([]),
      active: !val.active,
      version: val.version,
      versionId: val.versionId,
      width: 0,
      height: 0,
      m_width: 0,
      m_height: 0,
      v_width: 0,
      v_height: 0,
      additional_values: JSON.stringify([]),
      color_text_cost: 0,
      bold_cost: 0,
      image_cost: 0,
    };
    if (val.m_width == undefined && val.m_height == undefined) {
      val.m_width = 0;
      val.m_height = 0;
    }
    data = {
      ...data,
      width: String(val.width).trim() !== "" ? val.width : 0,
      height: String(val.height).trim() !== "" ? val.height : 0,
      m_width: String(val.m_width).trim() !== "" ? val.m_width : 0,
      m_height: String(val.m_height).trim() !== "" ? val.m_height : 0,
      additional_values: JSON.stringify(displayAdColors),
    };
    data = {
      ...data,
      color_text_cost:
        fmval.isColoredText &&
          String(val.color_text_cost).trim() !== ""
          ? parseFloat(val.color_text_cost)
          : 0,
      bold_cost:
        fmval.isBold && String(val.bold_cost).trim() !== ""
          ? parseFloat(val.bold_cost)
          : 0,
      image_cost:
        fmval.isAddImage && String(val.image_cost).trim() !== ""
          ? parseFloat(val.image_cost)
          : 0,
      is_desktop: fmval.isDesktop == true ? true : false,
      is_mobile: fmval.isMobile == true ? true : false,
    }
    data = {
      ...data,
      web_ad_type: val.web_ad_type != "" ? val.web_ad_type : "",
      v_width: String(val.v_width).trim() !== "" ? val.v_width : 0,
      v_height: String(val.v_height).trim() !== "" ? val.v_height : 0,
      insta_video_length: String(val.insta_video_length).trim() !== "" ? val.insta_video_length : 0,
      insta_posts: String(val.insta_posts).trim() !== "" ? val.insta_posts : 0,

      price_run_time: val.price_run_time === undefined ? "" : val.price_run_time,
      cpm: val.cpm === undefined ? 0 : val.cpm,
      description: val.description === undefined ? "" : val.description,
      format: val.format === undefined ? "" : val.format,
      max_size: val.max_size === undefined ? "" : val.max_size
    }

    let total_price = 0.00;

    total_price = parseFloat(data.deadline_price)

    for (let i = 0; i < paymentDiscountOptions.length; i++) {
      if (paymentDiscountOptions[i].typeOfDiscount == "Fixed Amount") {
        if (total_price < parseFloat(paymentDiscountOptions[i].discountAmount)) {
          const errors = {};
          errors.discountAmount = 'Discount can not be more than the ad price';
          toastr.error("Error", 'Discount can not be more than the ad price');
          throw new SubmissionError(errors);
        }
      }
    }


    // update a plan action
    socialPlanUpdate(data, (res) => {
      setShowModal(false);
      if (!res.data.showmodal) {
        if (
          String(res.status) === String(STATUS_CODES.CREATED) ||
          res.status === STATUS_CODES.OK
        ) {
          setIsChanged(false);
          setIsSubmitSuccess(true);
          toastr.success("Success", res.data);
          history.replace(`/ourPlans`);
        }
      }
      else {
        setMessage(res.data.message);

        setShowModal(true);
      }
    });
  }

  // fetch plan id from url
  const { planId } = useParams();

  // fetch logged in user details
  const { user_detail } = loggedInUser;

  sizeOfAdCategoryList.sort((x, y) => {
    let fa = x.name.toLowerCase(),
      fb = y.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  socialMediaCategoryList.sort((x, y) => {
    let fa1 = x.name.toLowerCase(),
      fb1 = y.name.toLowerCase();

    if (fa1 < fb1) {
      return -1;
    }
    if (fa1 > fb1) {
      return 1;
    }
    return 0;
  });

  adPlacementCategoryList.sort((x, y) => {
    let fa2 = x.name.toLowerCase(),
      fb2 = y.name.toLowerCase();

    if (fa2 < fb2) {
      return -1;
    }
    if (fa2 > fb2) {
      return 1;
    }
    return 0;
  });

  adFeatureCategoryList.sort((x, y) => {
    let fa3 = x.name.toLowerCase(),
      fb3 = y.name.toLowerCase();

    if (fa3 < fb3) {
      return -1;
    }
    if (fa3 > fb3) {
      return 1;
    }
    return 0;
  });

  adTypeCategoryList.sort((x, y) => {
    let fa4 = x.name.toLowerCase(),
      fb4 = y.name.toLowerCase();

    if (fa4 < fb4) {
      return -1;
    }
    if (fa4 > fb4) {
      return 1;
    }
    return 0;
  });

  const ad_type = [
    {
      name: "Browser Push Notification",
      id: 8
    },
    {
      name: "Email Marketing",
      id: 6
    },
    {
      name: "Email Blast",
      id: 13
    },
    {
      name: "Facebook Post",
      id: 11
    },
    {
      name: "Instagram Stories",
      id: 9
    },
    {
      name: "Instagram Post",
      id: 3
    },
    {
      name: "In App Ad",
      id: 12
    },
    {
      name: "Sponsored Article",
      id: 7
    },
    {
      name: "Sponsored Posts",
      id: 10
    },
    {
      name: "Twitter Post",
      id: 2
    },
    {
      name: "WebsiteAd",
      id: 1
    },
    {
      name: "Whatsapp Post",
      id: 4
    },
    {
      name: "Whatsapp Status",
      id: 5
    }
  ]

  const web_ad_type = [
    {
      name: "Banner",
      id: 16
    },
    {
      name: "Crawler",
      id: 8
    },
    {
      name: "Double Post",
      id: 17
    },
    {
      name: "Footer",
      id: 2
    },
    {
      name: "Full Ad",
      id: 11
    },
    {
      name: "Header",
      id: 1
    },

    {
      name: "Mid-Page",
      id: 3
    },
    {
      name: "Newsletter Ad",
      id: 13
    },
    {
      name: "Overlay",
      id: 7
    },
    {
      name: "Page Reel",
      id: 15
    },
    {
      name: "Post",
      id: 18
    },
    {
      name: "Quarter",
      id: 10
    },
    {
      name: "right/Left Near Logo",
      id: 12
    },
    {
      name: "Site Take Over",
      id: 4
    },
    {
      name: "Strip",
      id: 9
    },
    {
      name: "Tower Ad",
      id: 5
    },
    {
      name: "Triple Post",
      id: 19
    },
    {
      name: "Video Ad",
      id: 6
    },
    {
      name: "Wallpaper",
      id: 14
    },
  ]

  const web_adons = [
    {
      label: "Dedicated",
      id: 10
    },
    {
      label: "Home Page Article",
      id: 9
    },
    {
      label: "Main Site",
      id: 7
    },
    {
      label: "Parallex",
      id: 2
    },
    {
      label: "Pop Up",
      id: 4
    },
    {
      label: "Premium Spot",
      id: 6
    },
    {
      label: "Premium Slot",
      id: 11
    },
    {
      label: "Right/Left",
      id: 8
    },
    {
      label: "Rotating",
      id: 5
    },
    {
      label: "Static",
      id: 3
    },
    {
      label: "Sticky Banner",
      id: 1
    }
  ]

  const location_of_ad = [
    {
      label: "Between Posts",
      id: 13
    },
    {
      label: "Homepage",
      id: 8
    },
    {
      label: "Left",
      id: 2
    },
    {
      label: "Left Side",
      id: 15
    },
    {
      label: "Lower Side",
      id: 11
    },
    {
      label: "Near logo",
      id: 6
    },
    {
      label: "Premium spot",
      id: 7
    },
    {
      label: "Right",
      id: 1
    },
    {
      label: "Right Side",
      id: 14
    },
    {
      label: "ROS",
      id: 12
    },
    {
      label: "Slot 1",
      id: 3
    },
    {
      label: "Slot 2",
      id: 4
    },
    {
      label: "Slot 3",
      id: 5
    },
    {
      label: "Top",
      id: 10
    },
    {
      label: "Upper side",
      id: 9
    }
  ]

  const price_run_time = [
    {
      name: "1 Day",
      id: 1
    },
    {
      name: "24 hrs",
      id: 2
    },
    {
      name: "48 hrs",
      id: 3
    },
    {
      name: "1 Week",
      id: 4
    },
    {
      name: "2 Weeks",
      id: 5
    },
    {
      name: "1 Month",
      id: 6
    },
    {
      name: "6 Months",
      id: 7
    },
    {
      name: "3 Months",
      id: 8
    },
    {
      name: "1 Year",
      id: 9
    }
  ]

  const size_of_ad = [
    {
      label: "Extra-large",
      id: 4
    },
    {
      label: "Full",
      id: 8
    },
    {
      label: "Half",
      id: 6
    },
    {
      label: "Large",
      id: 3
    },
    {
      label: "Medium",
      id: 2
    },
    {
      label: "Quarter",
      id: 5
    },
    {
      label: "Small",
      id: 1
    },
    {
      label: "Three-quarter",
      id: 7
    }
  ]

  /** state to detect is discount_option and color_option changed */
  const [mob, setMob] = useState(true);
  const [desk, setDesk] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [characterLimit, setDeadlineCharacter] = useState(0);
  const [lineLimit, setDeadlineLine] = useState(0);
  const [deadlinePrice, setDeadlinePrice] = useState(0.00);
  const [noOfInstaPosts, setNoOfInstaPosts] = useState(0)


  const [selectedWebAdon, setSelectedWebAdon] = useState("")
  // Ad Type states
  // all the advertisements type list of the logged in NPC user
  const [advertisementsTypeList, setAdvertisementsTypeList] = useState([]);
  // selected Ad Type state
  const [selectedAdType, setSelectedAdType] = useState("");
  const [selectedWebAdType, setSelectedWebAdType] = useState("")
  const [instaVidLength, setInstaVidLength] = useState(0)
  const [showResults, setShowResults] = useState(false);

  // selected Ad Type type state i.e. Display, classified etc.
  const [typeOfSelectedAdType, setTypeOfSelectedAdType] = useState("");

  // selected Ad Type image state
  const [selectedAdTypeImage, setSelectedAdTypeImage] = useState("");

  const [allAddtype, setAllAddType] = useState([]);
  const [accountType, setAccountType] = useState([]);
  // Edition list state
  const [editions, setEditions] = useState([]);
  const [editionsName, setEditionsName] = useState("");

  // image preview state
  const [openImage, setOpenImage] = useState(false);

  const [paperOption, setPaperOption] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [preselected, setPreselected] = useState([]);
  const [preselected1, setPreselected1] = useState([]);
  const [preselected2, setPreselected2] = useState([]);
  const [paperTypeData, setPaperTypeData] = useState("");
  const [paperTypeCost, setPaperTypeCost] = useState("");
  const [bleedwidth, setBleedwidth] = useState("");
  const [bleedheight, setBleedheight] = useState("");
  const [check, setcheck] = useState(true);
  const [displayAdColors, setDisplayAdColors] = useState([]);
  const [displayAdCharacter, setDisplayAdCharacter] = useState([]);
  const [displayAdLine, setDisplayAdLine] = useState([]);

  const [selectedDayMulti, setSelectedDayMulti] = useState([])
  const [setectedWebAdonType, setSelectedWebAdonType] = useState([])
  const [selectedSize, setSelectedSize] = useState([])
  const [cpm, setCpm] = useState(0);
  const [description, setDescription] = useState("");
  const [format, setFormat] = useState("")
  const [max_size, setMaxSize] = useState(0)
  const [selectedRunTime, setSelectedRunTime] = useState("")
  const [planVersion, setPlanVersion] = useState([]);
  const [disableFormField, setDisableFormField] = useState(false);
  const [invalidPlan, setInvalidPlan] = useState(false);
  const [versionId, setVersionId] = useState(0.0);
  const [planActivity, setPlanActivity] = useState();
  const [planDeatils, setPlanDeatils] = useState({});

  var letestVersion;
  /** payment discount options states */
  const [paymentDiscountOptions, setPaymentDiscountOptions] = useState([]);
  const [paymentDiscountOptionError, setPaymentDiscountOptionError] = useState(
    null
  );

  const [AddLinerError, setAddLinerError] = useState(
    null
  );
  const [AddCharacterError, setAddCharacterError] = useState(
    null
  );

  // redirect state when no data found
  const [isRedirect, setIsRedirect] = useState(false);

  /**
   * @method getAdType
   * @description return type of selected Ad Type
   */
  const getAdType = (advertisementType = "") => {
    const add = socialMediaCategoryList.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    if (!add) return "";
    switch (add.id) {
      case 1:
        return "WEBSITE_AD";
      case 2:
        return "INSTAGRAM_POST";
      case 3:
        return "INSTAGRAM_STORIES";
      case 4:
        return "TWITTER_POST";
      case 5:
        return "WHATSAPP_POST";
      case 6:
        return "WHATSAPP_STORIES";
      case 7:
        return "EMAIL_MARKETING";
      case 8:
        return "SPONSORED_ARTICLE";
      case 9:
        return "SPONSORED_POSTS"
      case 10:
        return "BROWSER_PUSH_NOTIFICATION";
      case 11:
        return "FACEBOOK_POST";
      case 12:
        return "IN_APP_AD";
      default:
        return "";
    }
  };

  /**
   * @method getAdTypeOptions
   * @description return dropdown options for Ad Type
   */
  const getAdTypeOptions = () => {
    return socialMediaCategoryList.length > 0
      ? socialMediaCategoryList.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  const getLocation = () => {

    return Array.isArray(adPlacementCategoryList) &&
      adPlacementCategoryList.length > 0
      ? adPlacementCategoryList.map((ad) => ({
        name: ad.name,
        value: ad.id,
      }))
      : [];
  };

  const getSize = () => {

    return Array.isArray(sizeOfAdCategoryList) &&
      sizeOfAdCategoryList.length > 0
      ? sizeOfAdCategoryList.map((ad) => ({
        name: ad.name,
        value: ad.id,
      }))
      : [];
  };



  const getWebAdon = () => {

    return Array.isArray(adFeatureCategoryList) &&
      adFeatureCategoryList.length > 0
      ? adFeatureCategoryList.map((ad) => ({
        name: ad.name,
        value: ad.id,
      }))
      : [];
  };

  const changeVersions = (value) => {
    getPlanByversionIdForSocial(({ value: value, versionId: versionId }), (res) => {
      history.push(`${res.data.rows[0].id}`);
    });
  }

  const onClickNew = () => {
    if (showResults) {
      setShowResults(false);
      changeFieldValue("edit-plan", "bleed_width", "");
      changeFieldValue("edit-plan", "bleed_height", "");
      untouchField("edit-plan", "bleed_width");
      untouchField("edit-plan", "bleed_height");
    } else {
      setShowResults(true);
    }
  };

  /**
   * @method getEditionOptions
   * @description returns drop down options for edition
   */
  const getEditionOptions = () => {
    return editions.length > 0
      ? editions.map((ad) => ({
        label: ad.name,
        value: ad.id,
      }))
      : [];
  };

  let getEditionName = (id) => {

    let add = editions.find(
      (ad) => parseInt(ad.id) === parseInt(id)
    );
    if (add.name != "") {
      setEditionsName(add.name);
    }

  };

  /**
   * @method handleAddDiscountOptions
   * @description handle add a discount option
   */
  const handleAddDiscountOptions = (v) => {
    setIsChanged(true);
    setPaymentDiscountOptionError(null);
    // set discount options to state
    setPaymentDiscountOptions([...paymentDiscountOptions, v]);
  };

  /**
   * @method handleDeleteDiscountOptions
   * @description handle delete discount option from table
   * @param {*} option
   */
  const handleDeleteDiscountOptions = (payDisOpt) => {
    setIsChanged(true);
    const updatedPaymentDiscountOptions = paymentDiscountOptions.filter(
      (p) =>
        !(
          p.runs == payDisOpt.runs &&
          p.within == payDisOpt.within &&
          p.typeOfDiscount == payDisOpt.typeOfDiscount &&
          p.discountAmount == payDisOpt.discountAmount
        )
    );
    setPaymentDiscountOptions(updatedPaymentDiscountOptions);
  };

  /**
   * @method handleAddDisplayAdColor
   * @description handle add color for display Ad
   */
  const handleAddDisplayAdColor = (color) => {
    setIsChanged(true);
    setDisplayAdColors([...displayAdColors, color]);
  };

  const handleAddDisplayAdCharacter = (character) => {
    // set character option to state
    setDisplayAdCharacter([...displayAdCharacter, character]);
    setAddCharacterError(null);
  };
  const handleAddDisplayAdLine = (line) => {
    // set character option to state
    setDisplayAdLine([...displayAdLine, line]);
    setAddLinerError(null);
  };

  let getCharacterLimit = (value) => {
    setDeadlineCharacter(value);
  }
  let getlineLimit = (value) => {
    setDeadlineLine(value);
  }
  let getdeadlineprice = (value) => {
    setDeadlinePrice(value);
  }
  let getVideoLength = (value) => {
    setInstaVidLength(value)
  }

  let getNoOfInstaPosts = (value) => {
    setNoOfInstaPosts(value)
  }

  let getCpm = (value) => {
    setCpm(value)
  }

  let getDescription = (value) => {
    setDescription(value)
  }

  let getFormat = (value) => {
    setFormat(value)
  }

  let getMaxSize = (value) => {
    setMaxSize(value)
  }

  /**
   * @method handleDeleteDisplayAdColor
   * @description handle delete display Ad color
   * @param {*} disAdCol
   */
  const handleDeleteDisplayAdColor = (disAdCol) => {
    setIsChanged(true);
    const updatedColors = displayAdColors.filter(
      (c) => !(c.value === disAdCol.value && c.cost === disAdCol.cost)
    );
    setDisplayAdColors(updatedColors);
  };

  const handleDeleteDisplayAdCharacter = (disAdChar) => {
    const updatedChar = displayAdCharacter.filter(
      (c) => !(c.character === disAdChar.character && c.cost === disAdChar.cost)
    );
    setDisplayAdCharacter(updatedChar);
  };

  const handleDeleteDisplayAdLine = (disAdLine) => {
    const updatedLine = displayAdLine.filter(
      (c) => !(c.line === disAdLine.line && c.cost === disAdLine.cost)
    );
    setDisplayAdLine(updatedLine);
  };

  /**
   * @method onSubmitForm
   * @description handle on submit form
   * @param {*} values
   */
  const onSubmitForm = (values) => {
    setVal(values);
    setfmVal(formValues);
    // const selectedEdition = editions && editions.length === 1 ? editions[0].id : values.edition_id;
    let classified_conditional_errors = {};
    let display_height_width_errors = {};
    /**
     * checking conditional mandatory fields
     */
    if (values.isColoredText && !values.color_text_cost) {
      classified_conditional_errors.color_text_cost =
        langs.validation_messages.required;
    }
    if (values.isBold && !values.bold_cost) {
      classified_conditional_errors.bold_cost =
        langs.validation_messages.required;
    }
    if (values.isAddImage && !values.image_cost) {
      classified_conditional_errors.image_cost =
        langs.validation_messages.required;
    }

    /**
     * check and set error if payment discount option is YES but
     * no payment discount option is added
     */
    if (formValues.discount && paymentDiscountOptions.length === 0) {
      setPaymentDiscountOptionError("Please add payment discount options");
      return;
    }

    let data1 = [];
    if (selectedDayMulti.length > 0) {
      for (let i in selectedDayMulti) {
        if (selectedDayMulti[i].value) {
          data1.push(selectedDayMulti[i].value);
        } else if (selectedDayMulti[i].id) {
          data1.push(selectedDayMulti[i].id);
        }

      }
    }

    let data2 = [];

    if (setectedWebAdonType.length > 0) {
      for (let i in setectedWebAdonType) {
        if (setectedWebAdonType[i].value) {
          data2.push(setectedWebAdonType[i].value);
        } else if (setectedWebAdonType[i].id) {
          data2.push(setectedWebAdonType[i].id);
        }

      }
    }

    let data3 = [];

    if (selectedSize.length > 0) {
      for (let i in selectedSize) {
        if (selectedSize[i].value) {
          data3.push(selectedSize[i].value);
        } else if (selectedSize[i].id) {
          data3.push(selectedSize[i].id);
        }

      }
    }

    let data = {
      id: values.id,
      npc_id: user_detail.newspaper.id,
      ad_type: values.ad_type,
      versionId: values.versionId,
      version: planVersion.length > 0 && planVersion[0].version,
      location: data1.toString(),
      web_adon_type: data2.toString(),
      size_of_ad: data3.toString(),
      deadline_price: String(values.deadline_price).trim() !== "" ? values.deadline_price : 0,
      discount: values.discount,
      discount_option: values.discount
        ? JSON.stringify(paymentDiscountOptions)
        : JSON.stringify([]),
      active: !values.active,

      /** optional */
      width: 0,
      height: 0,
      m_width: 0,
      m_height: 0,
      v_width: 0,
      v_height: 0,
      additional_values: JSON.stringify([]),
      color_text_cost: 0,
      bold_cost: 0,
      image_cost: 0,
    };
    if (values.m_width == undefined && values.m_height == undefined) {
      values.m_width = 0;
      values.m_height = 0;
    }
    data = {
      ...data,
      width: String(values.width).trim() !== "" ? values.width : 0,
      height: String(values.height).trim() !== "" ? values.height : 0,
      m_width: String(values.m_width).trim() !== "" ? values.m_width : 0,
      m_height: String(values.m_height).trim() !== "" ? values.m_height : 0,
      additional_values: JSON.stringify(displayAdColors),
    };

    data = {
      ...data,
      color_text_cost:
        formValues.isColoredText &&
          String(values.color_text_cost).trim() !== ""
          ? parseFloat(values.color_text_cost)
          : 0,
      bold_cost:
        formValues.isBold && String(values.bold_cost).trim() !== ""
          ? parseFloat(values.bold_cost)
          : 0,
      image_cost:
        formValues.isAddImage && String(values.image_cost).trim() !== ""
          ? parseFloat(values.image_cost)
          : 0,
      is_desktop: formValues.isDesktop == true ? true : false,
      is_mobile: formValues.isMobile == true ? true : false,
    }

    data = {
      ...data,
      web_ad_type: values.web_ad_type != "" ? values.web_ad_type : "",
      v_width: String(values.v_width).trim() !== "" ? values.v_width : 0,
      v_height: String(values.v_height).trim() !== "" ? values.v_height : 0,
      insta_video_length: String(values.insta_video_length).trim() !== "" ? values.insta_video_length : 0,
      insta_posts: String(values.insta_posts).trim() !== "" ? values.insta_posts : 0,

      price_run_time: values.price_run_time === undefined ? "" : values.price_run_time,
      cpm: values.cpm === undefined ? 0 : values.cpm,
      description: values.description === undefined ? "" : values.description,
      format: values.format === undefined ? "" : values.format,
      max_size: values.max_size === undefined ? "" : values.max_size
    }

    let total_price = 0.00;

    total_price = parseFloat(data.deadline_price)

    for (let i = 0; i < paymentDiscountOptions.length; i++) {
      if (paymentDiscountOptions[i].typeOfDiscount == "Fixed Amount") {
        if (total_price < parseFloat(paymentDiscountOptions[i].discountAmount)) {
          const errors = {};
          errors.discountAmount = 'Discount can not be more than the ad price';
          toastr.error("Error", 'Discount can not be more than the ad price');
          throw new SubmissionError(errors);
        }
      }
    }

    // update a plan action
    updateSocialPlan(data, (res) => {
      if (!res.data.showmodal) {
        if (
          String(res.status) === String(STATUS_CODES.CREATED) ||
          res.status === STATUS_CODES.OK
        ) {
          setIsChanged(false);
          setIsSubmitSuccess(true);
          toastr.success("Success", res.data);
          history.replace(`/ourPlans`);
        }
      }
      else {
        setMessage(res.data.message);

        setShowModal(true);
      }
    });
  };

  var onR = [];
  const onRemove = e => {
    while (selectedDayMulti.length > 0) {
      selectedDayMulti.pop();
    }
    for (let i = 0; i < e.length; i++) {
      onR.push(e[i])
      setSelectedDayMulti(onR);
    }
  }

  var onR1 = [];
  const onRemove1 = e => {
    while (setectedWebAdonType.length > 0) {
      setectedWebAdonType.pop();
    }
    for (let i = 0; i < e.length; i++) {
      onR1.push(e[i])
      setSelectedWebAdonType(onR1);
    }
  }

  var onR2 = [];
  const onRemove2 = e => {
    while (selectedSize.length > 0) {
      selectedSize.pop();
    }
    for (let i = 0; i < e.length; i++) {
      onR2.push(e[i])
      setSelectedSize(onR2);
    }
  }

  /**
   * @method fetchPlan
   * @description fetch plan details
   */
  const fetchPlan = () => {
    const requestData = {
      id: planId,
      npc_id: user_detail.newspaper.id,
    };

    /** get single plan using same api to get all the plans by sending single plan id */
    getSocialPlanById(requestData, (res) => {
      if (res && String(res.status) === String(200)) {
        const plan = res.data.rows[0];

        const color_options =
          plan.additional_values.trim() !== ""
            ? JSON.parse(JSON.parse(plan.additional_values))
            : [];
        const discount_options =
          plan.discount_option.trim() !== "" && plan.discount
            ? JSON.parse(JSON.parse(plan.discount_option))
            : [];


        let list1 = [];
        if (plan.location.length > 1) {
          let new_arr = plan.location.split(",")
          for (let i = 0; i < new_arr.length; i++) {
            list1.push(new_arr[i])
          }
        }
        else if (plan.location.length == 1) {
          list1.push(plan.location)
        }
        let arr = []

        for (let i = 0; i < adPlacementCategoryList.length; i++) {
          for (let j = 0; j < list1.length; j++) {
            if (adPlacementCategoryList[i].id == list1[j]) {
              arr.push(adPlacementCategoryList[i])
            }
          }
        }

        setPreselected(arr)
        setSelectedDayMulti(arr);

        let list2 = [];
        if (plan.web_adon_type.length > 1) {
          let new_arr1 = plan.web_adon_type.split(",")
          for (let i = 0; i < new_arr1.length; i++) {
            list2.push(new_arr1[i])
          }
        }
        else if (plan.web_adon_type.length == 1) {
          list2.push(plan.web_adon_type)
        }

        let arr1 = []

        for (let i = 0; i < adFeatureCategoryList.length; i++) {
          for (let j = 0; j < list2.length; j++) {
            if (adFeatureCategoryList[i].id == list2[j]) {
              arr1.push(adFeatureCategoryList[i])
            }
          }
        }

        setPreselected1(arr1)
        setSelectedWebAdonType(arr1)

        let list3 = [];
        if (plan.size_of_ad.length > 1) {
          let new_arr2 = plan.size_of_ad.split(",")
          for (let i = 0; i < new_arr2.length; i++) {
            list3.push(new_arr2[i])
          }
        }
        else if (plan.size_of_ad.length == 1) {
          list3.push(plan.size_of_ad)
        }

        let arr2 = []

        for (let i = 0; i < sizeOfAdCategoryList.length; i++) {
          for (let j = 0; j < list3.length; j++) {
            if (sizeOfAdCategoryList[i].id == list3[j]) {
              arr2.push(sizeOfAdCategoryList[i])
            }
          }
        }

        setPreselected2(arr2)
        setSelectedSize(arr2)
        setDisplayAdColors(color_options);
        setPaymentDiscountOptions(discount_options);
        setDeadlinePrice(plan.deadline_price);
        setNoOfInstaPosts(plan.insta_posts);
        setInstaVidLength(plan.insta_video_length);

        // fetching all versions
        getVersionsForSocial(plan.versionId, (res) => {
          setPlanVersion(res.data);
          let allPlans = res.data.map(version => parseFloat(version.version));
          letestVersion = allPlans.length > 0 && Math.max(...allPlans);
          if (parseFloat(plan.version) != letestVersion || !plan.active) {
            setDisableFormField(true);
            if (!plan.active)
              setInvalidPlan(true);
          }
        });
        setVersionId(plan.versionId);
        setPlanActivity(plan.active);
        setPlanDeatils({ npc_id: plan.npc_id, id: plan.id, type: 1 });
      } else if (res) {
        setIsRedirect(true);
      }
    });
  };

  const getAllVersions = () => {
    return planVersion && planVersion.map(version => ({ label: `v.${version.version}`, value: version.version })
    )
  }

  /**
   * runs on mount to fetch necessary data
   */
  useEffect(() => {

    // calling the action to get price run time
    getAllPriceRunTimeCategoryList(langs.keyname.priceruntimecategory);

    // calling the action to get size of ad
    getAllSizeOfAdCategoryList(langs.keyname.sizeofadcategory)

    // calling the action to get ads placement category
    getAllAdPlacementCategoryList(langs.keyname.adplacementcategory);

    // calling the action to get ads features category
    getAllAdFeatureCategoryList(langs.keyname.adfeaturecategory);

    // calling the action to get ad types category
    getAllAdTypeCategoryList(langs.keyname.adtypecategory);

    // calling the action to get social media category
    getAllSocialMediaCategoryList(langs.keyname.socialmediacategory);

    getAllAdType('', (res) => {

      if (res.status === 200) {
        const allAddType = res.data;
        setAllAddType(allAddType);
      }
    });

    getAllPaperType('0', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.paper_type_name,
            };
          });
        setPaperTypes(advertisements);

      }
    });
    getAllAccount('0', (res) => {
      if (res.status === 200) {
        const allAddType = res.data;
        let advertisements = allAddType;
        advertisements = advertisements.filter((ad) => ad.is_deleted === false);

        advertisements =
          Array.isArray(advertisements) &&
          advertisements.map((ad) => {
            return {
              value: ad.id,
              label: ad.account_name,
            };
          });
        setAccountType(advertisements);

      }
    });
    // fetching single plan to edit
    fetchPlan();
  }, []);

  /**
   * run when either of any dependency value changes
   */
  useEffect(() => {
    /**
     *  on change of selected Ad Type or change in list of Ad Type
     *  find type of selected Ad Type ad set its state and respected image
     */
    const type = getAdType();
    setTypeOfSelectedAdType(type);
    // advertisementsTypeList
    let selectedAd = ad_type.find(
      (ad) => parseInt(ad.id) === parseInt(selectedAdType)
    );
    if (selectedAd) {
      setSelectedAdTypeImage(selectedAd.selectedImage);
    }
  }, [selectedAdType, allAddtype]); // [selectedAdType,advertisementsTypeList]

  /**
   * run when either of any dependency value changes
   */
  useEffect(() => {
    setSelectedAdType(formValues.ad_type);
  }, [formValues.ad_type]);

  /**
   * runs on change in dependency
   */
  useEffect(() => {
    if (!formValues.discount) {
      setPaymentDiscountOptionError(null);
    }
  }, [formValues.discount]);

  useEffect(() => {
    if (displayAdLine.length == 0) {
      setAddLinerError(null);
    }
  }, [displayAdLine]);

  useEffect(() => {
    if (displayAdCharacter.length == 0) {
      setAddCharacterError(null);
    }
  }, [displayAdCharacter]);

  useEffect(() => {
    if (!pristine) {
      if (!formValues.isColoredText) {
        changeFieldValue("edit-plan", "color_text_cost", "");
        untouchField("edit-plan", "color_text_cost");
      }
      if (!formValues.isBold) {
        changeFieldValue("edit-plan", "bold_cost", "");
        untouchField("edit-plan", "bold_cost");
      }
      if (!formValues.isAddImage) {
        changeFieldValue("edit-plan", "image_cost", "");
        untouchField("edit-plan", "image_cost");
      }
      if (!formValues.isAddBorder) {
        changeFieldValue("edit-plan", "border_cost", "");
        untouchField("edit-plan", "border_cost");
      }
      if (!formValues.isAddBackgroundColor) {
        changeFieldValue("edit-plan", "background_cost", "");
        untouchField("edit-plan", "background_cost");
      }
    }
  }, [
    formValues.isColoredText,
    formValues.isBold,
    formValues.isAddImage,
    formValues.isAddBorder,
    formValues.isAddBackgroundColor,
  ]);

  if (isRedirect) {
    return (
      <div>
        <NoContentFound />
      </div>
    );
  }

  const addpaperTypes = () => {
    if (paperTypeData !== "" && paperTypeCost !== "") {
      setcheck(true);
      const index = paperTypes.findIndex(obj => obj.value == parseInt(paperTypeData));
      if (index !== -1) {
        let data = { id: paperOption.length + 1, label: paperTypes[index].label, cost: paperTypeCost };
        setPaperOption([...paperOption, data]);
        changeFieldValue("edit-plan", "paper_type", "");
        changeFieldValue("edit-plan", "paper_cost", "");
        setPaperTypeData("");
        setPaperTypeCost("");
      }
    }
  }

  const deletePaperType = (id) => {
    const index = paperOption.findIndex(obj => obj.id == id);
    if (index !== -1) {
      setPaperOption(paperOption.filter(item => item.id !== id));
    }
    if (paperOption.length === 1) {
      setcheck(false);
    }
  }
  return (
    <>
      <h4>
        <Link to="/ourPlans">
          <i className="fa fa-arrow-left mr-3" aria-hidden="true"></i>
        </Link>
        Edit Plan
      </h4>
      <Paper>
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div
                  className={`form-row`}
                >
                  <div className="col-md-4">
                    <Field
                      name="ad_type"
                      component={renderSelectField}
                      label="Social Media Category"
                      options={getAdTypeOptions()}
                      optionValue={"value"}
                      disabled={disableFormField}
                      optionLabel={"label"}
                      validate={required}
                      required={true}
                      value={selectedAdType}
                      onChange={(e) => {
                        setSelectedAdType(e.target.value);

                      }}
                      isSelect={false}
                    />
                  </div>

                  <div className="col-md-4">
                    <Field
                      name="web_ad_type"
                      component={renderSelectField}
                      disabled={disableFormField}
                      label="Ad Type"
                      options={adTypeCategoryList}
                      optionValue={"id"}
                      validate={required}
                      required={true}
                      optionLabel={"name"}
                      value={selectedWebAdType}
                      onChange={(e) => setSelectedWebAdType(e.target.value)}

                      isSelect={false}
                    />
                  </div>

                  {/* )} */}


                  <div className="form-row col-md-4 ml-1 mt-4">
                    <div className="col-md-4 p-2 pl-3">
                      <RadioButtonRP>
                        <Field
                          name="isDesktop"
                          component="input"
                          disabled={disableFormField}
                          type="checkbox"
                          id="coloredText"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="coloredText"
                        >
                          Desktop
                        </label>
                      </RadioButtonRP>
                    </div>
                    <div className="col-md-4 p-2 pl-3">
                      <RadioButtonRP>
                        <Field
                          name="isMobile"
                          component="input"
                          disabled={disableFormField}
                          type="checkbox"
                          id="coloredText"
                          className="form-check-input"
                        // onSelect={ setMob(true) }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="coloredText"
                        >
                          Mobile
                        </label>
                      </RadioButtonRP>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  {/* Website Ad Post size */}
                  <>
                    <div className="col-md-4">
                      <Field
                        name="width"
                        label="Desktop Ad Size (Pixels)"
                        component={renderNumberField}
                        placeholder="Width"
                        disabled={disableFormField}
                        normalize={normalizeCharacterLimit}
                        min="0.01"
                        step=".01"
                        helpText={
                          "Only Numeric value (Max 4)"
                        }
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <Field
                        name="height"
                        component={renderNumberField}
                        placeholder="Height"
                        disabled={disableFormField}
                        normalize={normalizeCharacterLimit}
                        min="0.01"
                        step=".01"
                        helpText={
                          "Only Numeric value (Max 4)"
                        }
                      />
                    </div>
                  </>
                  {/* Amount of Instagram Posts */}

                  {/* {typeOfSelectedAdType === "INSTAGRAM_POST" && ( */}
                  <div className="col-md-4">
                    <Field
                      name="insta_posts"
                      label="Amount"
                      value={noOfInstaPosts}
                      component={renderNumberField}
                      disabled={disableFormField}
                      required={true}
                      min="0"
                      step=".01"
                      helpText={
                        "Only Numeric value with no decimal places"
                      }
                      onChange={(e) => getNoOfInstaPosts(e.target.value)}
                    />
                  </div>

                </div>

                {/* ============ Mobile Ad Size =========== */}

                <div className="form-row">
                  <div className="col-md-4">
                    <Field
                      name="m_width"
                      label="Mobile Ad Size(Pixels)"
                      component={renderNumberField}
                      disabled={disableFormField}
                      normalize={normalizeCharacterLimit}
                      placeholder="Width"
                      min="0.01"
                      step=".01"
                      helpText={
                        "Only Numeric value (Max 4)"
                      }
                    />
                  </div>
                  <div className="col-md-4 mt-2">
                    <Field
                      name="m_height"
                      component={renderNumberField}
                      placeholder="Height"
                      min="0.01"
                      disabled={disableFormField}
                      normalize={normalizeCharacterLimit}
                      step=".01"
                      helpText={
                        "Only Numeric value (Max 4)"
                      }
                    />
                  </div>
                </div>

                <div className="form-row">

                  <>
                    <div className="col-md-4">
                      <Field
                        name="v_width"
                        label="Video Size(Pixels)"
                        component={renderNumberField}
                        required={true}
                        placeholder="Width"
                        min="0"
                        step=".01"
                        disabled={disableFormField}
                        helpText={
                          "Only Numeric value"
                        }
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <Field
                        name="v_height"
                        component={renderNumberField}
                        placeholder="Height"
                        min="0"
                        disabled={disableFormField}
                        step=".01"
                        helpText={
                          "Only Numeric value"
                        }
                      />
                    </div>
                  </>

                  <div className="col-md-4">
                    <Field
                      name="insta_video_length"
                      label="Video Length"
                      value={instaVidLength}
                      component={renderNumberField}
                      disabled={disableFormField}
                      required={true}
                      min="0"
                      step=".01"
                      helpText={
                        "Only Numeric value with no decimal places"
                      }
                      onChange={(e) => getVideoLength(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-4">
                    <Field
                      name="description"
                      disabled={disableFormField}
                      label="Description"
                      value={description}
                      component={renderTextInputField}
                      onChange={(e) => getDescription(e.target.value)}
                    />
                  </div>
                  {/* Format */}
                  <div className="col-md-4">
                    <Field
                      name="format"
                      label="Format"
                      value={format}
                      disabled={disableFormField}
                      component={renderTextInputField}
                      onChange={(e) => getFormat(e.target.value)}
                    />

                  </div>
                  {/* Maximum size */}
                  <div className="col-md-4">
                    <Field
                      name="max_size"
                      label="Maximum Size"
                      disabled={disableFormField}
                      value={max_size}
                      component={renderNumberField}
                      onChange={(e) => getMaxSize(e.target.value)}
                    />

                  </div>
                </div>

                <div className="form-row">
                  {/* Location of Ad */}
                  <div className="col-md-4">
                    <Form.Label>
                      Ad Placement
                    </Form.Label>
                    <Multiselect
                      onRemove={onRemove}
                      name="location"
                      displayValue="name"
                      isSelect={false}
                      disable={disableFormField}
                      options={getLocation()}

                      showCheckbox={true} // Options to display in the dropdown

                      selectedValues={preselected}

                      onSelect={(selectedValues) => {
                        setSelectedDayMulti(selectedValues)
                      }} // Function will trigger on select event


                    />
                  </div>
                  {/* Website Ad Post Adons Type */}
                  <div className="col-md-4">

                    <Form.Label>
                      Ad Features
                    </Form.Label>
                    <Multiselect
                      onRemove={onRemove1}
                      name="web_adon_type"
                      disable={disableFormField}
                      displayValue="name"
                      isSelect={false}
                      options={getWebAdon()}


                      showCheckbox={true} // Options to display in the dropdown
                      selectedValues={preselected1}

                      onSelect={(selectedValues) => {
                        setSelectedWebAdonType(selectedValues)
                      }} // Function will trigger on select event


                    />
                  </div>
                  {/* Size of Ad */}
                  <div className="col-md-4">
                    <Form.Label>
                      Size
                    </Form.Label>
                    <Multiselect
                      onRemove={onRemove2}
                      name="size_of_ad"
                      displayValue="name"
                      isSelect={false}
                      disable={disableFormField}
                      options={getSize()}


                      showCheckbox={true} // Options to display in the dropdown

                      selectedValues={preselected2}

                      onSelect={(selectedValues) => {
                        setSelectedSize(selectedValues)
                      }}

                    />
                  </div>
                </div>

                <br /><br />

                <div className="form-row">
                  {/* Website Ad Price Post */}
                  <div className="col-md-2">
                    <Field
                      name="deadline_price"
                      label="Price"
                      value={deadlinePrice}
                      component={renderNumberField}
                      required={true}
                      disabled={disableFormField}
                      min="0"
                      step=".01"
                      helpText={
                        "Only Numeric value with up to 2 decimal places"
                      }
                      onChange={(e) => getdeadlineprice(e.target.value)}
                    />
                  </div>

                  {/* Price Run Time */}

                  <div className="col-md-2">
                    <Field
                      name="price_run_time"
                      component={renderSelectField}
                      disabled={disableFormField}
                      label="Price Run Time"
                      options={priceRunTimeCategoryList}
                      optionValue={"id"}
                      optionLabel={"name"}
                      value={selectedRunTime}
                      onChange={(e) => setSelectedRunTime(e.target.value)}

                      isSelect={false}
                    />
                  </div>

                  {/* CPM */}
                  <div className="col-md-2">
                    <Field
                      name="cpm"
                      label="CPM"
                      disabled={disableFormField}
                      value={cpm}
                      component={renderNumberField}
                      onChange={(e) => getCpm(e.target.value)}
                    />
                  </div>

                  <>
                    <AddValueForm
                      onAddColor={(c) => handleAddDisplayAdColor(c)}
                      disableFormField={disableFormField}
                    />

                    {displayAdColors.length > 0 && (
                      <MakeTable column={["Value", "Cost", "Action"]}>
                        {displayAdColors.length &&
                          displayAdColors.map((disAdCol, i) => (
                            <tr key={i}>
                              <td>{disAdCol.value}</td>
                              <td>{disAdCol.cost}</td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDisplayAdColor(disAdCol)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                </div>





                {typeOfSelectedAdType === "EMAIL_MARKETING" && (
                  <>
                    <div className="form-row">
                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isColoredText"
                            component="input"
                            disabled={disableFormField}
                            type="checkbox"
                            id="coloredText"
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="coloredText"
                          >
                            Additional Link?
                          </label>
                        </div>
                        {formValues.isColoredText && (
                          <Field
                            name="color_text_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isBold"
                            component="input"
                            type="checkbox"
                            id="bold"
                            disabled={disableFormField}
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="bold">
                            Add Embed Video?
                          </label>
                        </div>
                        {formValues.isBold && (
                          <Field
                            name="bold_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                      <div className="form-group col-md-3">
                        <div className="form-group form-check mb-0">
                          <Field
                            name="isAddImage"
                            component="input"
                            type="checkbox"
                            id="addImage"
                            disabled={disableFormField}
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="addImage"
                          >
                            Add Image?
                          </label>
                        </div>
                        {formValues.isAddImage && (
                          <Field
                            name="image_cost"
                            component={renderNumberField}
                            validate={[
                              required,
                              number,
                              greaterThanZeroWithTwoDecimalPlace,
                            ]}
                            disabled={disableFormField}
                            placeholder="Cost"
                            min="0"
                            step=".01"
                            helpText={
                              "Only Numeric value with up to 2 decimal places"
                            }
                          />
                        )}
                      </div>

                    </div>
                  </>
                )}

                { /* ------------------------------Discount Section --------------------------------- */}
                <div className="form-row align-items-center mt-4">
                  <div className="form-group col-md-3">
                    <label htmlFor="color">Payment Discount Option</label>
                  </div>

                  <div className="form-group col-md-3">
                    <div className="radio-inline">
                      <RadioButtonRP label="Yes">
                        <Field
                          component="input"
                          type="radio"
                          name="discount"
                          disabled={disableFormField}
                          label="Yes"
                          value={true}
                          normalize={(value) => value && true}
                          id="paymentDiscountOptionYes"
                        />
                      </RadioButtonRP>
                      <RadioButtonRP label="No">
                        <Field
                          component="input"
                          type="radio"
                          name="discount"
                          disabled={disableFormField}
                          label="No"
                          value={false}
                          normalize={(value) => value && false}
                          id="paymentDiscountOptionNo"
                        />
                      </RadioButtonRP>
                    </div>


                  </div>
                </div>

                {formValues.discount && (
                  <>
                    <AddPaymentDiscountOptionForm
                      deadlinePrice={deadlinePrice}
                      onAddPayDiscountOption={(v) =>
                        handleAddDiscountOptions(v)
                      }
                      paymentDiscountOptions={paymentDiscountOptions}
                      disableFormField={disableFormField}
                    />
                    {paymentDiscountOptionError && (
                      <div className="error text-help mb-2">
                        {paymentDiscountOptionError}
                      </div>
                    )}

                    {paymentDiscountOptions.length > 0 && (
                      <MakeTable
                        column={[
                          "#",
                          "Repetition",
                          "Within",
                          "Type of Discount",
                          "Amount",
                          "Action",
                        ]}
                      >
                        {paymentDiscountOptions.length &&
                          paymentDiscountOptions.map((payDisAmt, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{payDisAmt.runs}</td>
                              <td>{payDisAmt.within}</td>
                              <td>{payDisAmt.typeOfDiscount}</td>
                              <td>
                                {payDisAmt.discountAmount}
                                {payDisAmt.typeOfDiscount === "Percentage"
                                  ? "%"
                                  : ""}
                              </td>
                              <td>
                                <span
                                  className="btn btn-default btn-sm"
                                  onClick={() =>
                                    handleDeleteDiscountOptions(payDisAmt)
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          ))}
                      </MakeTable>
                    )}
                  </>
                )}

                <div className="form-group" style={{ display: "inline-block" }}>
                  <CheckActivity disableFormField={disableFormField} planActivity={planActivity} planDeatils={planDeatils} invalidPlan={invalidPlan} />
                </div>

                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-3">
                      <Field
                        name="allPlanVersion"
                        component={renderSelectField}
                        label="Version"
                        options={getAllVersions()}
                        optionValue={"value"}
                        optionLabel={"label"}
                        onChange={(event) => { changeVersions(event.target.value) }}
                        isSelect={planVersion.length > 0 ? true : false}
                      />
                    </div>
                    <div className="col-md-3">
                      <Field
                        name="version_create_date"
                        label="Created Date"
                        component={renderText}
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>

                <ButtonRP
                  type="submit"
                  disabled={(isChanged === false && (submitting)) || disableFormField}
                  className="float-right"
                  rounded={false}
                  text="Save"
                />
              </form>
            </div>
          </div>
        </div>
      </Paper>

      <CampaignModal
        isOpen={showModal}
        onCancelClick={cancelDelete}
        onOkClick={confirmDelete}
        keyname={"Plan"}
        message={message}
      />

      <NavigationPrompt
        when={isSubmitSuccess === false && (!pristine || isChanged === true) && !disableFormField}
      >
        {(props) => {
          const { onConfirm, onCancel } = props;
          return (
            <NavigateModal
              show={true}
              alertHeading={"Changes made will be lost."}
              cancleButtonText="Keep Changes"
              okButtonText="Discard Changes"
              onOkClick={() => {
                resetPlan();
                onConfirm();
              }}
              onClickCancel={() => {
                onCancel();
              }}
              showCancel={true}
            />
          );
        }}
      </NavigationPrompt>
    </>
  );
};

/** redux form value selector */
const formValSelector = formValueSelector("edit-plan");

/**
 * @method mapStateToProps
 * @description map redux state to props
 * @param {*} state
 */
const mapStateToProps = (state) => {
  const { auth, npcDuck, commonDuck } = state;
  const { npcSettingList, npcPlan } = npcDuck;
  const { priceRunTimeCategoryList, sizeOfAdCategoryList, adPlacementCategoryList, adFeatureCategoryList, adTypeCategoryList, socialMediaCategoryList } = commonDuck;
  const { color, edition, advertisement } = npcSettingList || {};

  const advertisements = advertisement
    ? advertisement.rows[0].advertisements.filter(
      (ad) => ad.is_deleted === false
    )
    : [];

  /** find is the Ad Type is not deleted by super admin*/
  let isAdTypeNotDeleted = undefined;
  if (advertisements.length > 0) {
    isAdTypeNotDeleted = advertisements.find(
      (ad) => parseInt(npcPlan.ad_type) === parseInt(ad.id)
    );
  }



  let initialValues = {
    ad_type: "",
    edition_id: edition && edition.length === 1 ? edition[0].name : "",
    paper_type: "",
    paper_cost: ""
  };

  if (Object.keys(npcPlan).length > 0) {

    initialValues = {
      ...npcPlan,
      ad_type: npcPlan.ad_type,
      active: !npcPlan.active,
      isColoredText: npcPlan.color_text_cost || false,
      isBold: npcPlan.bold_cost || false,
      isAddBorder: npcPlan.border_cost || false,
      isAddImage: npcPlan.image_cost || false,
      isAddBackgroundColor: npcPlan.background_cost || false,
      isDesktop: npcPlan.is_desktop,
      isMobile: npcPlan.is_mobile,
      edition_id: edition && edition.length === 1 ? edition[0].name : npcPlan.edition_id,
      bleed_width: npcPlan.bleed_width || false,
      bleed_height: npcPlan.bleed_height || false,
      character_limit: npcPlan.character_limit,
      lines_limit: npcPlan.lines_limit,
      isAddCharacter: npcPlan.isAddCharacter,
      isAddLine: npcPlan.isAddLine,
      account_type: npcPlan.account_type,
      version_create_date: moment(npcPlan.createdAt).format('YYYY-MM-DD'),
      versionId: npcPlan.versionId,
      allPlanVersion: npcPlan.version
    };

  }

  // fetching form fields states
  const formValues = formValSelector(
    state,
    "discount_option",
    "color_option",
    "discount",
    "ad_type",
    "color_text_cost",
    "bold_cost",
    "image_cost",
    "border_cost",
    "background_cost",
    "isColoredText",
    "isBold",
    "isAddBorder",
    "isAddImage",
    "isDesktop",
    "isMobile",
    "isAddBackgroundColor",
    "character_option",
    "line_option",
    "isAddCharacter",
    "isAddLine"
  );

  return {
    loggedInUser: auth.user,
    initialValues,
    colorList: color ? color : [],
    editionList: edition ? edition : [],
    advertisementList: advertisements,
    formValues,
    priceRunTimeCategoryList: priceRunTimeCategoryList ? priceRunTimeCategoryList : [],
    sizeOfAdCategoryList: sizeOfAdCategoryList ? sizeOfAdCategoryList : [],
    adPlacementCategoryList: adPlacementCategoryList ? adPlacementCategoryList : [],
    adFeatureCategoryList: adFeatureCategoryList ? adFeatureCategoryList : [],
    adTypeCategoryList: adTypeCategoryList ? adTypeCategoryList : [],
    socialMediaCategoryList: socialMediaCategoryList ? socialMediaCategoryList : [],
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...NPCDuck.actions,
    ...commonDuck.actions,
    changeFieldValue,
    untouchField,
  })(
    reduxForm({
      form: "edit-plan", // a unique identifier for this form
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(EditPlan)
  )
);
