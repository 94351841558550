import React from 'react';
import { Table } from 'reactstrap';

const NoRecordFoundContent = (props) => {
  return (
   <div className='noContentFound'>
       No Record Found
   </div>
  );
}

export default NoRecordFoundContent;