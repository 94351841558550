import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";
import axios from "axios";
import { API } from "../../../config";
import { CALL_LOADING } from "../../../config/constant";
import { apiErrors } from "../../../common/HandleAPIErrors";

export const actionTypes = {
  SetCustomerListRequest: "[SetCustomerListRequest] Action",
  SetSelectedCustomer: "[SetSelectedCustomer] Action",
  SetCustomerList: "[SetCustomerList] Action",
  SetSearchResultCustomer: "[SetSearchResultCustomer] Action",
  SetSearchResultArchieveCustomer: "[SetSearchResultArchieveCustomer] Action",
};

const headers = {
  "Content-Type": "application/json",
};

const initialAuthState = {
  customerRequestList: [],
  selectedCustomer: undefined,
  selectedAgentCompany: undefined,
  customerList: [],
  searchResultsforCustomer: [],
  searchResultsforArchieveCustomer: []
};

/** set data of api's into reducer */
export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      /** set data for customer Request List */
      case actionTypes.SetCustomerListRequest: {
        const { customerList } = action.payload;
        return { ...state, customerRequestList: [...customerList] };
      }

      /** SET a Global Message for Success & Error */
      case actionTypes.SetSelectedCustomer: {
        const { selectedCustomer,selectedAgentCompany } = action.payload;
        return {
          ...state,
          selectedCustomer: selectedCustomer,
          selectedAgentCompany: selectedAgentCompany,
        };
      }

      /** set data for customer List */
      case actionTypes.SetCustomerList: {
        const { customerList } = action.payload;
        return { ...state, customerList: [...customerList] };
      }

      /** set results from search */
      case actionTypes.SetSearchResultCustomer: {
        const { result } = action.payload;
        return { ...state, searchResultsforCustomer: result };
      }

      /** set results from search */
      case actionTypes.SetSearchResultArchieveCustomer: {
        const { result } = action.payload;
        return { ...state, searchResultsforArchieveCustomer: result };
      }

      /** set default state */
      default:
        return state;
    }
  }
);

/** Actions creator */
export const actions = {
  /**
   * @method getCustomerListing
   * @description get all records based on advance search
   */
  getCustomerListing: (requestData, callback) => {
    const { page, is_deleted, row, search } = requestData;
    const request = axios.get(
      `${API.getCustomerList}?data_table=customer&row=${row}&page=${page}&is_deleted=${is_deleted}&search=${search}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      //dispatch({ type: CALL_LOADING })
      request
        .then((response) => {
          //dispatch({ type: CALL_LOADING })
          callback(response);
          return dispatch({
            type: actionTypes.SetCustomerList,
            payload: { customerList: response.data.rows },
          });
        })
        .catch((error) => {
          //dispatch({ type: CALL_LOADING })
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },

  searchInCustomerList: (requestData) => {
    const { searchKey, is_deleted, search } = requestData;
    const request = axios.get(
      `${API.searchInCustomerList}?data_table=customer&search=${searchKey}&is_deleted=${is_deleted}&search=${search}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      request
        .then((response) => {
          if (!is_deleted) {
            return dispatch({
              type: actionTypes.SetSearchResultCustomer,
              payload: { result: response.data.rows },
            });
          } else if (is_deleted) {
            return dispatch({
              type: actionTypes.SetSearchResultArchieveCustomer,
              payload: { result: response.data.rows },
            });
          }
        })
        .catch((error) => {
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
        });
    };
  },

  /**
   * @method createCustomer
   * @description get all records based on advance search
   */
  createCustomer: (requestData, callback) => {
    let api_call = `${API.createCustomer}`;
    const request = axios.post(api_call, requestData, {
      headers,
    });
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  /**
   * @method viewCustomerById
   * @description Update admin details
   */
  viewCustomerById: (customerId, callback) => {
    const request = axios.get(
      `${API.getCustomerById}?id=${customerId}&is_deleted=${0}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
          return dispatch({
            type: actionTypes.SetSelectedCustomer,
            payload: { 
              selectedCustomer: response.data.result,
              selectedAgentCompany: response.data.companyName
            },
          });
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  /**
   * @method updateAdminProfile
   * @description Update admin details
   */
  updateCustomerProfile: (requestData, callback) => {
    let request = axios.put(`${API.genericUsers}`, requestData, {
      headers,
    });

    return (dispatch) => {
      dispatch({ type: CALL_LOADING });
      request
        .then((response) => {
          dispatch({ type: CALL_LOADING });
          callback(response);
        })
        .catch((error) => {
          dispatch({ type: CALL_LOADING });
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
  /**
   * @method deleteCustomerById
   * @description delete cutomer Bu id
   */
  deleteCustomerById: (requestData, callback) => {
    const { customerId, email, first_name } = requestData;
    const request = axios.delete(
      `${API.generalAPI}/customer?id=${customerId}&email=${email}&first_name=${first_name}`,
      {
        headers,
      }
    );
    return (dispatch) => {
      //dispatch({ type: CALL_LOADING })
      request
        .then((response) => {
          //dispatch({ type: CALL_LOADING })
          callback(response);
        })
        .catch((error) => {
          //dispatch({ type: CALL_LOADING })
          // tokenCheck(dispatch, error)
          apiErrors(error, dispatch);
          callback(error);
        });
    };
  },
};

export function* saga() {
  yield takeLatest(actionTypes.isSessionExpire, function* loginSaga() {});
}
