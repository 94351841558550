import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Form, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { Label, Input } from "reactstrap";
import { Tabs, Tab } from "@material-ui/core";
import * as NewsPaperCompanyDuck from "../../store/ducks/super-admin/NewsPaperCompany.duck";
import * as commonDuck from "../../store/ducks/Common.duck";
import {
  renderThemeText,
  renderThemeNumberInput,
} from "../../common/layout/FormInput";
import { renderSelectField } from "../../common/layout/FormInput";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import {
  required,
  PhoneNumber,
  minLength5,
  maxLength100,
  maxLengthC100,
  minLength2,
  email,
  selectRequired,
  maxLength200,
  alphabetsOnly,
  normalizePhone,
} from "../../config/validation";
import { MESSAGES } from "../../config/message";
import { renderThemeSelectOptions } from "../user-types/FormLayout";
import Notice from "../../partials/content/Notice";
import { langs } from "../../config/localization";
import { superAdminId } from "../../config";
import { Loader } from "../../common/Loader";
import { STATUS_CODES } from "../../common/StatusCode";
import CommonAlert from "../../common/CommonAlert";
import { phoneNumberFormateOnChange } from "../../common";
import CheckboxRP from "../../common/CheckboxRP";

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditFlag: false,
      notification: false,
      termsAndCondition: false,
      value: "",
      number: "",
      email: "",
      success: false,
      error: false,
      isSubmitted: true,
      companyRoles: [],
      distributionType: [
        { label: "National", value: "National" },
        { label: "Local", value: "Local" },
      ],
      message: "",
      loading: false,
      show: false,
      alwaysShow: false,
    };
  }

  /**
   * @method componentDidMount
   * @description called after render the component
   */
  componentDidMount() {
    this.getMasterData();
  }

  /**
   * @method getMasterData
   * @description get master data created by super admin
   */
  getMasterData = () => {
    this.props.getAllRoleList(langs.keyname.company);
    this.props.getfromSuperAdminSetting(
      langs.keyname.copies_range,
      superAdminId,
      (res) => {}
    );
  };

  /**
   * @method toggleChange
   * @description get master data created by super admin
   */
  toggleChange = () => {
    this.setState({
      termsAndCondition: !this.state.termsAndCondition,
    });
  };

  /**
   * Submit the login form
   * @param values
   * @desc Calling a login actions && redirecting page based on response and set session Items
   */
  onSubmit(values) {
    if (!this.state.termsAndCondition) {
      window.scrollTo(0, 0);
      this.setState({
        show: true,
        message: MESSAGES.TERMS_CONDITION,
        variant: "danger",
      });
      return true;
    }
    const { notification, number } = this.state;
    this.setState({ isSubmitted: false, loading: true });
    values.active = false;
    values.status = 0;
    values.email_distribution = notification;
    values.phone_number = number.replace(/-/g, "");
    this.props.addNewCompanyFromFrontEnd(values, (res) => {
      window.scrollTo(0, 0);
      if (res.response && res.response.data.success == false) {
        this.setState({
          alwaysShow: true,
          show: true,
          loading: false,
          success: false,
          variant: "danger",
          message: res.response.data.message,
        });
      } else if (
        res.response &&
        res.response.status === STATUS_CODES.PRECONDITION_FAILED
      ) {
        this.setState({
          show: true,
          message: res.response.data.message,
          isSubmitted: true,
          loading: false,
          variant: "danger",
        });
      } else if (res.status === STATUS_CODES.CREATED) {
        this.setState({
          show: true,
          variant: "success",
          isSubmitted: false,
          message: MESSAGES.COMPANY_ADD_SUCCESS,
          loading: false,
        });
        setTimeout(() => {
          this.props.history.push("/auth/login");
        }, 5000);
      } else {
        this.setState({
          show: true,
          message: MESSAGES.SERVER_ERROR,
          isSubmitted: true,
          loading: false,
          variant: "danger",
        });
      }
    });
  }

  /**
   * @method onChange
   * @description mobile number formatting
   */
  onChange = (e) => {
    //Filter only numbers from the input
    let number = phoneNumberFormateOnChange(e.target.value);
    this.setState({
      number,
      isEditFlag: true,
    });
  };

  setShow = () => {
    this.setState({ show: false });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { handleSubmit, companyRoles, rangeList } = this.props;
    const {
      show,
      isSubmitted,
      loading,
      message,
      number,
      distributionType,
      notification,
      termsAndCondition,
      variant,
      alwaysShow,
    } = this.state;
    return (
      <div>
        <Notice icon="flaticon-file kt-font-primary">
          {loading && <Loader />}
          <div className="row">
            <h3 style={{ marginTop: 10 }} className="col-md-4">
              <Link style={{ color: "black" }} to="/login">
                Ad-Squad-Media
              </Link>
            </h3>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="float-right col-md-8"
            >
              <Tabs component="div" className="builder-tabs ">
                <Tab label="HOME" value={0} />
                <Tab label="ABOUT US" value={0} />
                <Tab label="FAQs" value={0} />
                <Tab label="CONTACT US" value={0} />
              </Tabs>
              <Link style={{ color: "white" }} to="/auth/login">
                <Button
                  style={{
                    height: 40,
                    width: 75,
                    background: "black",
                    marginTop: 10,
                  }}
                >
                  Login
                </Button>
              </Link>
            </div>
          </div>
        </Notice>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Portlet>
              <PortletHeader
                toolbar={
                  <PortletHeaderToolbar style={{ marginLeft: "38%" }}>
                    <h3 style={{ color: "black" }}>PARTNER WITH US</h3>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody style={{ marginLeft: "25%" }}>
                <div style={{ width: "67%" }}>
                  {show && (
                    <CommonAlert
                      show={show}
                      variant={variant}
                      message={message}
                      setShow={this.setShow}
                      alwaysShow={alwaysShow}
                    />
                  )}
                </div>
                <Form
                  noValidate
                  onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="newspaper_name"
                        value={"adminProfile.first_name"}
                        validate={[required, minLength5, maxLengthC100]}
                        placeholder="Newspaper Name"
                        component={renderThemeText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="first_name"
                        value={"adminProfile.first_name"}
                        validate={[required, minLength2, maxLengthC100]}
                        placeholder="First Name"
                        component={renderThemeText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="last_name"
                        validate={[required, minLength2, maxLengthC100]}
                        placeholder=" Last Name"
                        component={renderThemeText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="role_id"
                        data={companyRoles}
                        validate={[selectRequired]}
                        isSelect={false}
                        isRegister={true}
                        component={renderThemeSelectOptions}
                        className="bottomBorder"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="email"
                        validate={[required, minLength5, maxLength100, email]}
                        type={"email"}
                        placeholder="Email"
                        component={renderThemeText}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="phone_number"
                        type="text"
                        maxLength={10}
                        autoComplete="off"
                        onChange={this.onChange}
                        Value={number}
                        validate={[required, PhoneNumber]}
                        placeholder="Phone No"
                        component={renderThemeNumberInput}
                        normalize={normalizePhone}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="distribution_type"
                        validate={[selectRequired]}
                        options={distributionType}
                        isSelect={false}
                        component={renderSelectField}
                        optionValue={"value"}
                        optionLabel={"label"}
                        placeholder="Distribution Type"
                        className="bottomBorder"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="distribution_location"
                        validate={[
                          required,
                          minLength2,
                          maxLength200,
                          alphabetsOnly,
                        ]}
                        placeholder="Location of Distribution"
                        component={renderThemeText}
                        className="form-control withoutBorder"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Field
                        name="distributed_copies"
                        validate={[required]}
                        isSelect={false}
                        component={renderSelectField}
                        copiesDistributed={true}
                        options={rangeList}
                        optionValue={"id"}
                        optionLabel={"copies_range"}
                        onChange={(e) =>
                          this.setState({ selectedCity: e.target.value })
                        }
                        className="bottomBorder"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="8"
                      controlId="validationCustom02"
                      className="d-flex justify-content-between"
                    >
                      <Form.Label>
                        Email Distribution
                        <span className="asterisk-required">*</span>
                      </Form.Label>
                      <div>
                        <div className="checkbox-inline">
                          <CheckboxRP label="Yes">
                            <Input
                              type="checkbox"
                              checked={notification ? true : ""}
                              onChange={(e) =>
                                this.setState({ notification: true })
                              }
                              name="email_distribition"
                            />
                          </CheckboxRP>
                          <CheckboxRP label="No">
                            <Input
                              type="checkbox"
                              checked={!notification ? true : ""}
                              onChange={(e) =>
                                this.setState({ notification: false })
                              }
                              name="email_distribition"
                            />
                          </CheckboxRP>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="8"
                      controlId="validationCustom02"
                      className="my-3"
                    >
                      <CheckboxRP label="I agree with Terms and Conditions">
                        <Input
                          type="checkbox"
                          id="checkbox3"
                          checked={termsAndCondition}
                          onChange={this.toggleChange}
                          className="mr-3"
                          name="terms_conditions"
                        />
                      </CheckboxRP>
                    </Form.Group>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Form.Row>
                        <Button
                          className="float-center my-3 w-100 btn btn-dark"
                          type="submit"
                          disabled={isSubmitted ? false : true}
                        >
                          Register
                        </Button>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group style={{ marginTop: 10 }}>
                          <p>
                            Already have an account?{" "}
                            <Link
                              to="/auth/login"
                              className="kt-link kt-login__link-forgot"
                            >
                              {" "}
                              Login
                            </Link>
                          </p>
                        </Form.Group>
                      </Form.Row>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { loading, roles, superAdminSettingList } = store.commonDuck;
  return {
    companyRoles: roles && roles.company ? roles.company : [],
    rangeList:
      superAdminSettingList && superAdminSettingList.copies_range
        ? superAdminSettingList.copies_range
        : [],
    loading,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    ...commonDuck.actions,
    ...NewsPaperCompanyDuck.actions,
  })(
    reduxForm({
      // Assign unique name for the form
      form: "Registration",
    })(Registration)
  )
);
