import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";
import "../../Modal.scss";
import { Loader } from "../../../../common/Loader";
import { LayoutContextConsumer } from "../../../../../_metronic/layout/LayoutContext";
import * as commonDuck from "../../../../store/ducks/Common.duck";
import OrderInfo from "./OrderInfo";
import OrderDetail from "./OrderDetailForClassified";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../partials/content/Portlet";
import { NoContentFound } from "../../../../common/NoContentFound";
import { STATUS_CODES } from "../../../../common/StatusCode";

class ClassifiedOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noContentFound: false,
    };
  }

  /**
   * @method componentDidMount
   * @description  called after rendering the component
   */
  componentDidMount() {
    const { id } = this.props.match.params;
    this.getOrderList(id);
  }

  /**
   * @method getOrderList
   * @description  used to get all order listing
   */
  getOrderList = (id) => {
    const { state } = this.props.history.location;
    let requestData = {
      id: id,
      newspaper_id: state !== undefined ? state.npc_id : "",
      user_id: state !== undefined ? state.user_id : "",
      customer_id: "",
    };
    this.props.getOrderDetail(requestData, (res) => {
      if (res.status !== STATUS_CODES.OK) {
        this.setState({ noContentFound: true });
      }
    });
  };

  /**
   * @method render
   * @description render the component
   */
  render() {
    const { loading, orderDetail } = this.props;
    const { noContentFound } = this.state;
    const { state } = this.props.history.location;

    if (state === undefined) {
      return <Redirect to="/allOrders" />;
    } else if (noContentFound) {
      return (
        <div>
          <NoContentFound />
        </div>
      );
    }
    // else if (loading) {
    //     return <Loader />
    // }
    else {
      return (
        <div>
          <LayoutContextConsumer className="row">
            {({ subheader: { title } }) => (
              <div>
                <div className="row">
                  <h3 className="kt-subheader__title col-md-6">
                    <span>
                      <Link to="/allOrders">
                        <i
                          onClick={() => {
                            return this.props.history.push("/allOrders");
                          }}
                          style={{ fontSize: 20 }}
                          className="fa fa-arrow-left"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </span>
                    <span className="ml-2">Order Detail</span>
                  </h3>
                </div>
              </div>
            )}
          </LayoutContextConsumer>
          <Portlet>
            <PortletHeader
              toolbar={
                <PortletHeaderToolbar className="w-100 row">
                  {orderDetail !== undefined && orderDetail.length && (
                    <h5 className="float-left col-md-10 ml-2">
                      Order No : {orderDetail[0].id}
                    </h5>
                  )}
                </PortletHeaderToolbar>
              }
            />
            {orderDetail !== undefined && orderDetail.length && (
              <PortletBody style={{ minHeight: 700, height: "auto" }}>
                {orderDetail.map((o, i) => (
                  <>
                    {i !== 0 && <hr className="my-3" />}
                    <div className="row p-3 pt-4 shadow-sm bg-light">
                      <div className="col-md-4">
                        <OrderInfo info={o} />
                      </div>
                      <div className="col-md-8">
                        <OrderDetail detail={o} />
                      </div>
                      <div className="col-12 mb-5 mt-3">
                        <div className="row mt-5">
                          <div className="col-md-2 text-center mb-3 font-weight-bold">
                            Ad Text:
                          </div>
                          <div className="col">
                            <div className="classifiedAdDetailText">
                              <div
                                dangerouslySetInnerHTML={{ __html: o.content }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </PortletBody>
            )}
          </Portlet>
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => {
  const { loading, orderDetail } = store.commonDuck;
  return {
    loggedInUser: store.auth.user,
    authToken: store.auth.authToken,
    orderDetail,
    loading,
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    commonDuck.actions
  )(
    reduxForm({
      // Assign unique name for the form
      form: "ClassifiedOrderDetail",
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(ClassifiedOrderDetail)
  )
);
